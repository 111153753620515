import { Dropdown } from '@approvalmax/ui';
import { Box, Text } from '@approvalmax/ui/src/components';
import { miscHelpers } from '@approvalmax/utils';
import { getTemplateDisplayNameByCode } from 'modules/common/selectors/templateSelectors';
import { domain } from 'modules/data';
import { FC, useState } from 'react';
import bemFactory from 'react-bem-factory';

import { TwoDifferentValuesWarning } from '../TwoDifferentValuesWarning/TwoDifferentValuesWarning';
import { messages } from './PriceCheckIndicator.messages';
import { ChildrenComponents, PriceCheckIndicatorProps, WarningLevel } from './PriceCheckIndicator.types';

function getWarningLevel(actualPrice?: number, expectedPrice?: number, isMultiCurrency?: boolean): WarningLevel {
    if (actualPrice == null || expectedPrice == null) {
        return WarningLevel.None;
    }

    if (isMultiCurrency) {
        return WarningLevel.Black;
    }

    const diff = actualPrice - expectedPrice;

    if (diff / expectedPrice > 0.25) {
        return WarningLevel.Red;
    }

    if (diff / expectedPrice > 0.05) {
        return WarningLevel.Orange;
    }

    if (diff / expectedPrice > 0) {
        return WarningLevel.Yellow;
    }

    return WarningLevel.None;
}

const bem = bemFactory.block('rq-price-check-indicator');

const PriceCheckIndicator: FC<PriceCheckIndicatorProps> & ChildrenComponents = (props) => {
    const {
        className,
        children,
        actualPrice,
        expectedPrice,
        disabled,
        integrationType,
        integrationCode,
        isMultiCurrency,
    } = props;

    let warningLevel = getWarningLevel(actualPrice, expectedPrice, isMultiCurrency);

    if (disabled) {
        warningLevel = WarningLevel.None;
    }

    const [isOpen, setOpen] = useState(false);

    const requestName = getTemplateDisplayNameByCode(integrationCode);

    let requestNameShort = requestName;

    switch (integrationCode) {
        case domain.IntegrationCode.XeroPo:
        case domain.IntegrationCode.QBooksPo:
        case domain.IntegrationCode.NetSuitePO:
        case domain.IntegrationCode.DearPo:
            requestNameShort = messages.purchaseOrderShort;
            break;
    }

    let systemName = '';

    switch (integrationType) {
        case domain.IntegrationType.Xero:
            systemName = messages.xeroSystemName;
            break;

        case domain.IntegrationType.NetSuite:
            systemName = messages.netSuiteSystemName;
            break;

        case domain.IntegrationType.QBooks:
            systemName = messages.qBooksSystemName;
            break;
    }

    return (
        <Dropdown
            button={(ref) => (
                <div
                    ref={ref}
                    className={bem.add(className)()}
                    onMouseEnter={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                >
                    {children({ warningLevel })}
                </div>
            )}
            isOpen={isOpen && !disabled && warningLevel !== WarningLevel.None}
            onRequestClose={miscHelpers.noop}
        >
            {isMultiCurrency ? (
                <Box spacing='16' width={230} shadow='small' color='white100'>
                    <Text font='body' fontSize='small'>
                        {messages.multiCurrencyDescription}
                    </Text>
                </Box>
            ) : (
                <TwoDifferentValuesWarning
                    value1={expectedPrice}
                    value2={actualPrice}
                    label1={messages.expectedPriceText}
                    label2={messages.actualPriceText({ requestName: requestNameShort })}
                    description={messages.description({ requestName, systemName })}
                />
            )}
        </Dropdown>
    );
};

PriceCheckIndicator.WarningLevel = WarningLevel;

export default PriceCheckIndicator;
