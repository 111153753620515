import { BaseItem, ColumnDefinition } from '@approvalmax/ui/src/components';

import { CheckboxColumnCoverType } from './Content.types';

export const radioColumnId = '__radio__';

export const checkboxColumnCover: CheckboxColumnCoverType = {
    id: radioColumnId,
    spacing: '12 8',
    width: 10,
};

export const filterTextKey = 'filterText';

export const columnsData: ColumnDefinition<BaseItem>[] = [
    { id: 'type', width: 144 },
    { id: 'company' },
    { id: 'tag', textAlign: 'right' },
];
