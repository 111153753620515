import { Mods, mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.div<StyledMods<Mods<'bordered'>>>`
    & > * + * {
        margin-top: 0.75em;
    }

    ol {
        padding: 0 1rem;
        list-style: decimal !important;
    }

    ul {
        padding: 0 1rem;
        list-style: disc !important;
    }

    ${mods.bordered.false`
        border: none !important;
        margin: 0 !important;
        padding: 0 !important;
    `}
`;
