import {
    Action,
    CREATE_ACCRUAL_REPORT,
    CREATE_ACCRUAL_REPORT_FAILURE,
    CREATE_ACCRUAL_REPORT_RESPONSE,
    DELETE_REPORT,
    DELETE_REPORT_FAILURE,
    DELETE_REPORT_RESPONSE,
    DOWNLOAD_AUDIT_REPORTS,
    DOWNLOAD_AUDIT_REPORTS_FAILURE,
    DOWNLOAD_AUDIT_REPORTS_RESPONSE,
    EXECUTE_REPORT,
    EXECUTE_REPORT_FAILURE,
    EXECUTE_REPORT_RESPONSE,
    EXPORT_AS_CSV,
    EXPORT_AS_CSV_FAILURE,
    EXPORT_AS_CSV_RESPONSE,
    SAVE_REPORT,
    SAVE_REPORT_AS_NEW,
    SAVE_REPORT_AS_NEW_FAILURE,
    SAVE_REPORT_AS_NEW_RESPONSE,
    SAVE_REPORT_FAILURE,
    SAVE_REPORT_RESPONSE,
} from '../../actions';

export enum CardCommand {
    Save = 'Save',
    SaveAsNew = 'SaveAsNew',
    DiscardChanges = 'DiscardChanges',
    Delete = 'Delete',
    DownloadAuditReports = 'DownloadAuditReports',
    DownloadAttachmentArchive = 'DownloadAttachmentArchive',
    CreateAccrualReportXero = 'CreateAccrualReportXero',
    Print = 'Print',
    ExportAsCsv = 'ExportAsCsv',
    ExecuteReport = 'ExecuteReport',
    EditFilters = 'EditFilters',
}

export type ActiveCardCommandType = CardCommand | null;

export default function activeTemplateReducer(
    state: ActiveCardCommandType = null,
    action: Action
): ActiveCardCommandType {
    switch (action.type) {
        case DELETE_REPORT:
            return CardCommand.Delete;

        case SAVE_REPORT:
            return CardCommand.Save;

        case EXPORT_AS_CSV:
            return CardCommand.ExportAsCsv;

        case SAVE_REPORT_AS_NEW:
            return CardCommand.SaveAsNew;

        case CREATE_ACCRUAL_REPORT:
            return CardCommand.CreateAccrualReportXero;

        case DOWNLOAD_AUDIT_REPORTS:
            return CardCommand.DownloadAuditReports;

        case EXECUTE_REPORT:
            return CardCommand.ExecuteReport;

        case DELETE_REPORT_RESPONSE:
        case DELETE_REPORT_FAILURE:
        case SAVE_REPORT_RESPONSE:
        case SAVE_REPORT_FAILURE:
        case EXPORT_AS_CSV_RESPONSE:
        case EXPORT_AS_CSV_FAILURE:
        case SAVE_REPORT_AS_NEW_RESPONSE:
        case SAVE_REPORT_AS_NEW_FAILURE:
        case CREATE_ACCRUAL_REPORT_RESPONSE:
        case CREATE_ACCRUAL_REPORT_FAILURE:
        case DOWNLOAD_AUDIT_REPORTS_RESPONSE:
        case DOWNLOAD_AUDIT_REPORTS_FAILURE:
        case EXECUTE_REPORT_RESPONSE:
        case EXECUTE_REPORT_FAILURE:
            return null;

        default:
            return state;
    }
}
