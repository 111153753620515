/**
 * Developer: Stepan Burguchev
 * Date: 5/18/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { Action, SHOW_DASHBOARD, SHOW_NEW_REPORT_CARD, SHOW_REPORT_CARD } from '../../actions';

export enum ActiveModule {
    Dashboard = 'Dashboard',
    Card = 'Card',
}

export type ActiveModuleType = ActiveModule;

export default function activeModuleReducer(
    state: ActiveModuleType = ActiveModule.Dashboard,
    action: Action
): ActiveModuleType {
    switch (action.type) {
        case SHOW_DASHBOARD:
            return ActiveModule.Dashboard;

        case SHOW_REPORT_CARD:
        case SHOW_NEW_REPORT_CARD:
            return ActiveModule.Card;

        default:
            return state;
    }
}
