import { font } from 'modules/styles';
import styled from 'styled-components';

export const Root = styled.div`
    flex: 1;
    overflow: hidden;
`;

export const Header = styled.div`
    ${font(14, '#000', 'semibold')}
    text-transform: uppercase;
    margin-bottom: 20px;
`;

export const Body = styled.div`
    display: flex;
    flex-flow: column;
`;

export const EditorsRow = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    margin: 20px -5px 0 -5px;
    justify-content: flex-end;

    & > * {
        margin-left: 5px;
        margin-right: 5px;
        width: 100px;
    }
`;

export const Status = styled.div`
    flex: 1;
`;

export const StatusText = styled.span`
    color: #4c8b5d;
`;

export const CustomBackendSection = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 10px;
`;

export const ApiSourceContainer = styled.div`
    min-width: 130px;
`;
