import { Reference } from '@approvalmax/types';
import { stateTree } from 'modules/data';

import { ReportConfigColumn } from '../../reportConfig/ReportConfig';

export function createCachedOptionsSelector(
    optionsSelector: (state: stateTree.State | null, column: ReportConfigColumn | null) => Reference[]
) {
    let options: Reference[] | null = null;

    return function (this: any, state: stateTree.State | null, column: ReportConfigColumn | null): Reference[] {
        if (options) {
            return options;
        }

        options = optionsSelector.call(this, state, column);

        return options!;
    };
}
