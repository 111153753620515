import { domHelpers } from '@approvalmax/utils';
import { memo, useState } from 'react';

import { hideMaintenanceNotificationKey } from './Announcement.constants';
import { useAnnouncementMessage } from './Announcement.hooks';
import { messages } from './Announcement.messages';
import { CloseButton, CloseButtonIcon, NotificationText, Root } from './Announcement.styles';

const Announcement = memo(() => {
    const [hiddenMessageId, setHiddenMessageId] = useState(() => {
        return localStorage.getItem(hideMaintenanceNotificationKey);
    });

    const { hasMessage, id, message } = useAnnouncementMessage();

    const onClose = () => {
        if (id) {
            localStorage.setItem(hideMaintenanceNotificationKey, id);

            setHiddenMessageId(id);
        }
    };

    if (!hasMessage || id === hiddenMessageId) {
        return null;
    }

    return (
        <Root>
            <NotificationText
                dangerouslySetInnerHTML={{
                    __html: domHelpers.highlightUrls(message || ''),
                }}
            />

            <CloseButton execute={onClose}>
                <CloseButtonIcon width={12} height={12} />

                {messages.closeButtonText}
            </CloseButton>
        </Root>
    );
});

export default Announcement;
