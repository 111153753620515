import { domain } from 'modules/data';
import { ImmutableObject, set, setIn, updateArrayItem } from 'modules/immutable';

import {
    Action,
    CHANGE_STANDALONE_AMOUNT,
    CHANGE_STANDALONE_CURRENCY,
    CHANGE_STANDALONE_DATE,
    CHANGE_STANDALONE_FIELD_VALUE,
} from '../../actions';

export type RequestType = ImmutableObject<domain.Request>;

export default function (state: RequestType, action: Action): RequestType {
    switch (action.type) {
        case CHANGE_STANDALONE_AMOUNT:
            return set(state, 'amount', action.payload.newAmount || 0);

        case CHANGE_STANDALONE_CURRENCY:
            return set(state, 'currency', action.payload.newCurrency ? action.payload.newCurrency.id : state.currency);

        case CHANGE_STANDALONE_DATE:
            return setIn(state, ['details', 'date'], action.payload.newDate ? action.payload.newDate : '');

        case CHANGE_STANDALONE_FIELD_VALUE:
            return setIn(
                state,
                ['details', 'fieldValues'],
                updateArrayItem(
                    (state as domain.StandaloneRequest).details.fieldValues,
                    (fv) => fv.field.id === action.payload.fieldId,
                    (fv) => ({
                        ...fv,
                        value: action.payload.newValue,
                    })
                )
            );

        default:
            return state;
    }
}
