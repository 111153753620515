import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, CANCEL_REQUEST_RESPONSE, SHOW_CANCEL_REQUEST_POPUP } from '../../../actions';

export const CANCEL_REQUEST_POPUP = 'CANCEL_REQUEST_POPUP';

export interface CancelRequestPopupData {
    id: typeof CANCEL_REQUEST_POPUP;
    requestId: Guid;
}

export type CancelRequestPopupType = ImmutableObject<CancelRequestPopupData> | null;

export default function (state: CancelRequestPopupType, action: Action): CancelRequestPopupType {
    switch (action.type) {
        case SHOW_CANCEL_REQUEST_POPUP:
            return immutable<CancelRequestPopupData>({
                id: CANCEL_REQUEST_POPUP,
                requestId: action.payload.requestId,
            });

        case CANCEL_REQUEST_RESPONSE:
            return null;

        default:
            return state;
    }
}
