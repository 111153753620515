import { HelpCircleIcon } from '@approvalmax/ui';
import {
    Box,
    Button,
    Flex,
    Form,
    Label,
    Link,
    Popup,
    Select,
    Switch,
    Text,
    TextField,
    Tooltip,
} from '@approvalmax/ui/src/components';
import { domHelpers } from '@approvalmax/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { integrationPopupPreviewImageState } from 'modules/integration/ModuleComponent.states';
import { memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { z } from 'zod';

import { ImagePreviewButton } from './components';
import { defaultValues, formFields } from './NetSuiteIntegrationPopup.config';
import { learnMoreLink } from './NetSuiteIntegrationPopup.constants';
import { useNetSuiteIntegrationPopup } from './NetSuiteIntegrationPopup.hooks';
import { messages } from './NetSuiteIntegrationPopup.messages';
import { schema } from './NetSuiteIntegrationPopup.schema';
import { FormField, FormFieldId } from './NetSuiteIntegrationPopup.types';

const qa = bemFactory.qa('netsuite-integration-popup');

export const NetSuiteIntegrationPopup = memo(() => {
    const setPreviewImageState = useSetRecoilState(integrationPopupPreviewImageState);

    const form = useForm<z.infer<typeof schema>>({
        defaultValues,
        resolver: zodResolver(schema),
        mode: 'onChange',
    });

    const { isLoading, onConnect } = useNetSuiteIntegrationPopup();

    const onSubmit = form.handleSubmit((data) => {
        onConnect(data);
    });

    const isSuiteApp = form.watch(FormFieldId.isSuiteApp);

    const handleChangeIsSuiteApp = useCallback(
        (checked: boolean) => {
            form.clearErrors([FormFieldId.consumerKey, FormFieldId.consumerSecret]);
            form.setValue(FormFieldId.isSuiteApp, checked);
        },
        [form]
    );

    const openImagePreview = useCallback(
        (field: FormField) => {
            const { title, tooltipText, imgAltText, imgSrc } = field;

            if (!imgSrc || !imgAltText) {
                return;
            }

            setPreviewImageState({
                title,
                text: tooltipText,
                src: imgSrc,
                alt: imgAltText,
                isOpen: true,
            });
        },
        [setPreviewImageState]
    );

    return (
        <Form form={form} onSubmit={onSubmit}>
            <Popup.Header
                title={messages.popupTitle}
                actions={
                    <Flex inline alignItems='center' justifyContent='space-between'>
                        <Link
                            font='label'
                            fontSize='small'
                            href={learnMoreLink}
                            onClick={domHelpers.stopPropagation}
                            external
                        >
                            {messages.learnMore}
                        </Link>

                        <Button
                            type='submit'
                            data-qa={qa('connect-button')}
                            progress={isLoading}
                            title={messages.connectButton}
                        >
                            {messages.connectButton}
                        </Button>
                    </Flex>
                }
                progress={isLoading}
            />

            <Popup.Body spacing='0 60 50 80'>
                {formFields.map((field) => {
                    if (field.id === FormFieldId.isSuiteApp) {
                        return (
                            <Switch
                                key={field.id}
                                name='isSuiteApp'
                                disabled={isLoading}
                                onChange={handleChangeIsSuiteApp}
                            >
                                {messages.suiteAppConnection}
                            </Switch>
                        );
                    }

                    if (isSuiteApp && !field.isSuiteApp) {
                        return null;
                    }

                    return (
                        <Box key={field.id} spacing='16 0 0'>
                            <Flex inline direction='column' spacing='8 0'>
                                <Flex inline spacing='8' alignItems='center'>
                                    <Label htmlFor={field.id} required={field.required}>
                                        {field.title}
                                    </Label>

                                    <Tooltip
                                        activator={<HelpCircleIcon size='16' color='midnight50' />}
                                        safePolygon
                                        width={600}
                                        maxHeight='none'
                                    >
                                        <Box spacing='8 16'>
                                            <Flex inline direction='row' spacing='24'>
                                                <Text font='body'>{field.tooltipText}</Text>

                                                <ImagePreviewButton field={field} openImagePreview={openImagePreview} />
                                            </Flex>
                                        </Box>
                                    </Tooltip>
                                </Flex>

                                {field.items ? (
                                    <Select
                                        name={field.id}
                                        items={field.items}
                                        qa={field.qa}
                                        placeholder={field.placeholder}
                                        disabled={isLoading}
                                        size='small'
                                    />
                                ) : (
                                    <TextField
                                        id={field.id}
                                        name={field.id}
                                        type={field.type}
                                        autoComplete={field.type === 'password' ? 'new-password' : 'off'}
                                        disabled={isLoading}
                                        size='small'
                                    />
                                )}
                            </Flex>
                        </Box>
                    );
                })}
            </Popup.Body>
        </Form>
    );
});

NetSuiteIntegrationPopup.displayName = 'NetSuiteIntegrationPopup';
