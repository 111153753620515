import { Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { memo, useState } from 'react';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import XeroExternalSubmitterSection from '../XeroExternalSubmitterSection/XeroExternalSubmitterSection';
import { mapTemplateToLearnMoreLink } from './XeroCreditNoteStep.constants';
import { messages } from './XeroCreditNoteStep.messages';

const XeroCreditNoteStep = memo(() => {
    const [collapsed, setCollapsed] = useState<boolean>();
    const template = useSelector(getActiveTemplate)!;
    const pluralTemplateName = selectors.template.getTemplateDisplayNameByCode(template.integrationCode, true);

    const learnMoreLink = template.integrationCode ? mapTemplateToLearnMoreLink[template.integrationCode] : undefined;

    return (
        <FixedStepContainer bubbleText={messages.bubbleText({ templateName: template.displayName })}>
            <Text font='body' fontSize='small' color='midnight70'>
                {messages.title({ pluralTemplateName })}
            </Text>

            <XeroExternalSubmitterSection
                cacheId='XeroCreditNoteStepSubmitter'
                description={messages.externalSubmitterDescriptionNonEmpty({ pluralTemplateName })}
                descriptionEmpty={messages.externalSubmitterDescriptionEmpty({ templateName: template.displayName })}
                title={messages.externalSubmitterTitle({ pluralTemplateName })}
                learnMoreLink={learnMoreLink}
                collapsed={collapsed}
                onCollapse={setCollapsed}
            />
        </FixedStepContainer>
    );
});

export default XeroCreditNoteStep;
