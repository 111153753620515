import { Guid } from '@approvalmax/types';
import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, stateTree } from 'modules/data';
import moment from 'moment';
import { defineMessages } from 'react-intl';

import { getProfileUser } from './profileSelectors';
import { ExpandedRequest, ExpandedTemplate } from './types';
import { ExpandedCompany } from './types/Company';

const i18nPrefix = 'common/selectors/requestSelectors.Xero';
const messages = defineMessages({
    lockDateOverrideWarning: {
        id: `${i18nPrefix}.lockDateOverrideWarning`,
        defaultMessage: 'When approved, the date will change to {newDate} because the lock date is set for {lockDate}',
    },
});

export interface ExpandedXeroLineItem extends domain.XeroLineItem {
    isDescriptionOnly: boolean;
}

type State = stateTree.State;

export const getLockDateInEffect = (details: domain.XeroRequestDetails) => {
    let date;

    switch (details.integrationCode) {
        case domain.IntegrationCode.XeroBillBatchPayment: {
            if (!details.lockDate) {
                return false;
            }

            date = moment.utc(details.paymentDate);
            break;
        }

        case domain.IntegrationCode.XeroBill:
        case domain.IntegrationCode.XeroCreditNotesPayable:
        case domain.IntegrationCode.XeroCreditNotesReceivable:
        case domain.IntegrationCode.XeroInvoice:
        case domain.IntegrationCode.XeroQuote:
        case domain.IntegrationCode.XeroManualJournal: {
            if (!details.lockDate) {
                return false;
            }

            date = moment.utc(details.date);
            break;
        }

        default:
            return false;
    }

    const lockDate = moment.utc(details.lockDate);
    const newDate = moment(lockDate).add(1, 'd');

    return date.isBefore(newDate);
};

export const getLockDateWarningText = (request: domain.XeroRequest) => {
    if (request.integrationCode === domain.IntegrationCode.XeroContact) {
        return null;
    }

    const details = request.details;
    const lockDateInEffect = getLockDateInEffect(details);

    if (
        [domain.RequestStatusV2.Draft, domain.RequestStatusV2.OnApproval, domain.RequestStatusV2.OnHold].includes(
            request.statusV2
        ) &&
        lockDateInEffect &&
        details.lockDatePolicy === domain.TemplateSettingsLockDatePolicy.ApproveWithNextDay
    ) {
        const lockDate = moment.utc(details.lockDate!);
        const newDate = moment(lockDate).add(1, 'd');

        return intl.formatMessage(messages.lockDateOverrideWarning, {
            newDate: newDate.format('LL'),
            lockDate: lockDate.format('LL'),
        });
    }

    return null;
};

export const expandXeroLineItem = (li: domain.XeroLineItem): ExpandedXeroLineItem => {
    const isDescriptionOnly =
        !li.amount &&
        !li.qty &&
        !li.discount &&
        !li.unitPrice &&
        !li.tax &&
        !li.item &&
        !li.account &&
        li.tracking.length === 0;

    return {
        ...li,
        isDescriptionOnly,
    };
};

const canCopyPOToBill = (request: domain.Request, companyTemplates: ExpandedTemplate[]) => {
    if (request.integrationCode !== domain.IntegrationCode.XeroPo) {
        return false;
    }

    const resolutionCondition = request.statusV2 === domain.RequestStatusV2.Approved;
    const templateCondition = companyTemplates.find(
        (template) => template.integrationCode === domain.IntegrationCode.XeroBill && template.enabled
    );
    const documentNumberCondition = !!request.details.number;

    return resolutionCondition && templateCondition && documentNumberCondition;
};

const canCopyQuoteToSalesInvoice = (request: domain.Request) => {
    if (request.integrationCode !== domain.IntegrationCode.XeroQuote) {
        return false;
    }

    return request.details.isAccepted;
};

const getCanEditAsAuthor = (request: domain.Request, me: Guid) => {
    if ([domain.RequestStatusV2.Approved, domain.RequestStatusV2.Cancelled].includes(request.statusV2)) {
        return false;
    }

    if (
        request.statusV2 === domain.RequestStatusV2.Rejected &&
        request.origin !== domain.RequestOrigin.ApprovalMax &&
        request.origin !== domain.RequestOrigin.Email
    ) {
        return false;
    }

    const createdInAmax =
        request.origin === domain.RequestOrigin.ApprovalMax ||
        request.origin === domain.RequestOrigin.PublicApi ||
        request.origin === domain.RequestOrigin.Email;

    return createdInAmax && request.authorId === me;
};

const getCanEditAsReviewerV1 = (
    request: domain.Request,
    activeStep: domain.RequestStep | null,
    company: selectors.types.ExpandedCompany,
    me: Guid
) => {
    if (request.statusV2 !== domain.RequestStatusV2.OnApproval || !activeStep) {
        return false;
    }

    const activeApprover = activeStep.participants.find(
        (participant) =>
            participant.userId === me && participant.decision === domain.RequestStepParticipantDecision.NoResponse
    );
    const delegateFor = activeApprover && activeApprover.delegateFor;
    const hasReviewerV1Beta = company.betaFeatures.includes(domain.CompanyBetaFeature.ReviewerV1);
    const editor =
        hasReviewerV1Beta && activeStep.editors.some((editor) => editor.userId === me || editor.userId === delegateFor);

    return Boolean(activeApprover && editor);
};

export const getXeroCommands = (params: {
    request: domain.XeroRequest;
    company: ExpandedCompany;
    hasCreatableTemplate: boolean;
    myDecisions: ExpandedRequest['myDecisions'];
    flags: ExpandedRequest['flags'];
    companyTemplates: ExpandedTemplate[];
    creatableTemplates: string[];
    me: string;
}): ExpandedRequest['commands'] => {
    const { request, company, hasCreatableTemplate, myDecisions, flags, companyTemplates, creatableTemplates, me } =
        params;

    const { isActiveApprover, isApprover, isAuthor, status, isCompanyManager, isActiveReviewer } = flags;
    const details = request.details;
    const isManager = company.flags.isManager;
    const hasActiveIntegration = company.flags.hasActiveIntegration;
    const isOnHold = request.statusV2 === domain.RequestStatusV2.OnHold;
    const isOnApproval = request.statusV2 === domain.RequestStatusV2.OnApproval;
    const createdInApprovalMax =
        request.origin === domain.RequestOrigin.ApprovalMax ||
        request.origin === domain.RequestOrigin.PublicApi ||
        request.origin === domain.RequestOrigin.Email;
    const canForceDecision =
        isManager &&
        (isOnApproval || isOnHold || (request.statusV2 === domain.RequestStatusV2.Rejected && createdInApprovalMax));
    const isMyDraft = isAuthor && status.isDraft;
    const lockDatePreventsApprove =
        getLockDateInEffect(details) &&
        request.integrationCode !== domain.IntegrationCode.XeroContact &&
        request.details.lockDatePolicy === domain.TemplateSettingsLockDatePolicy.LockApproval;
    const isRejected = request.statusV2 === domain.RequestStatusV2.Rejected;
    const isBatchPayment = request.integrationCode === domain.IntegrationCode.XeroBillBatchPayment;
    const isAirwallexBatchPayment = request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment;
    const isAmaxPayXeroBatchPayment = request.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment;
    const hideActionsForAdvancedFeatures = selectors.request.getHideActionsForAdvancedFeatures(
        company,
        request.integrationCode
    );

    const activeStep = selectors.request.getActiveStep(request);
    const canEditAsAuthor = getCanEditAsAuthor(request, me);
    const canEditAsReviewerV1 = getCanEditAsReviewerV1(request, activeStep, company, me);
    const canEditAsDelegatedApprover = selectors.request.getCanEditAsDelegatedApprover(me, activeStep?.participants);
    const canEditAsApprover =
        selectors.request.getCanEditAsApprover(request, activeStep, company, me) && canEditAsDelegatedApprover;

    const hasCopyToBillOptions = canCopyPOToBill(request, companyTemplates);

    const hideCopyButton =
        (isBatchPayment && !isRejected) ||
        isAirwallexBatchPayment ||
        isAmaxPayXeroBatchPayment ||
        (request.integrationCode === domain.IntegrationCode.XeroContact &&
            !status.isDraft &&
            [domain.RequestStatusV2.Approved, domain.RequestStatusV2.Draft, domain.RequestStatusV2.OnApproval].includes(
                request.statusV2
            ));

    const canEditInSecondaryActions = (canEditAsAuthor || canEditAsReviewerV1) && !(isBatchPayment && isRejected);

    const disableEdit = Boolean(
        isAirwallexBatchPayment && company.airwallexIntegration && !company.airwallexIntegration.isConnected
    );

    const creatableBillTemplateId = companyTemplates.find((companyTemplate) => {
        return companyTemplate.integrationCode === domain.IntegrationCode.XeroBill;
    })?.id;
    const hasCreatableTemplateBill = creatableBillTemplateId
        ? creatableTemplates.includes(creatableBillTemplateId)
        : false;

    const creatableSalesInvoiceTemplateId = companyTemplates.find((companyTemplate) => {
        return companyTemplate.integrationCode === domain.IntegrationCode.XeroInvoice;
    })?.id;

    const hasCreatableTemplateSalesInvoice = creatableSalesInvoiceTemplateId
        ? creatableTemplates.includes(creatableSalesInvoiceTemplateId)
        : false;

    const hasCopyToSalesInvoiceOptions = canCopyQuoteToSalesInvoice(request);
    const hideReverseButton = request.integrationCode !== domain.IntegrationCode.XeroManualJournal || hideCopyButton;

    const hasWorkflowAccessToReviewStep = selectors.template.getHasAccessToReviewStepByIntegrationCode(
        request.integrationCode,
        company.betaFeatures
    );
    const hasReviewBetaOrLicense = selectors.company.getIsReviewStepAvailable(company);
    const isReviewer = request.reviewStep?.reviewers.some((reviewer) => reviewer.id === me);
    const canEditInReview = hasReviewBetaOrLicense && hasWorkflowAccessToReviewStep && isActiveReviewer;
    const canCompleteReview = hasWorkflowAccessToReviewStep && isActiveReviewer;
    const canForceReview =
        hasWorkflowAccessToReviewStep &&
        isManager &&
        request.statusV2 === domain.RequestStatusV2.OnReview &&
        request.reviewStep &&
        !isReviewer;
    const canReturnToReview =
        hasReviewBetaOrLicense &&
        hasWorkflowAccessToReviewStep &&
        request.statusV2 === domain.RequestStatusV2.OnApproval &&
        request.reviewStep?.isCompleted &&
        (isManager || isActiveApprover || isReviewer || isAuthor);

    const isPayer = request.payers.some((payer) => payer.id === me);

    const isEditingOnApprovalAvailable = selectors.company.getIsEditingOnApprovalAvailable(
        company,
        request.integrationCode
    );
    const requiresEditingForEditOnApproval = request.requiresEditingForEditOnApproval && isEditingOnApprovalAvailable;
    const requiresEditingForReviewerV1 =
        request.requiresEditingForReviewerV1 && company.betaFeatures.includes(domain.CompanyBetaFeature.ReviewerV1);

    const getCancelIsHidden = () => {
        const hideDefault = !(isAuthor && createdInApprovalMax && status.isOpen);

        const hideForBatchPayment = isBatchPayment && isRejected;

        if (isAirwallexBatchPayment) {
            return !(
                isAuthor &&
                createdInApprovalMax &&
                isAirwallexBatchPayment &&
                (request.details.paymentStatus === domain.AirwallexPaymentStatus.AwaitingPayment ||
                    request.details.paymentStatus === domain.AirwallexPaymentStatus.None) &&
                request.statusV2 !== domain.RequestStatusV2.Cancelled &&
                request.statusV2 !== domain.RequestStatusV2.Draft
            );
        }

        if (isAmaxPayXeroBatchPayment) {
            if (request.details.status === domain.AmaxPayXeroBatchPaymentStatus.AwaitingPayment) {
                const canCancel = isAuthor || isPayer;

                return !canCancel;
            }

            if (status.isOpen) {
                const canCancel = isAuthor || isCompanyManager;

                return !canCancel;
            }

            return true;
        }

        return hideDefault || hideForBatchPayment || hideActionsForAdvancedFeatures;
    };

    return {
        approve: {
            hidden: (isOnHold ? !(isManager || isApprover) : !isActiveApprover) || hideActionsForAdvancedFeatures,
            disabled:
                lockDatePreventsApprove || requiresEditingForReviewerV1 || requiresEditingForEditOnApproval || isOnHold,
        },
        reject: {
            hidden: (isOnHold ? !(isManager || isApprover) : !isActiveApprover) || hideActionsForAdvancedFeatures,
            disabled: isOnHold,
        },
        forceApprove: {
            hidden: !canForceDecision || hideActionsForAdvancedFeatures,
            disabled: lockDatePreventsApprove,
        },
        forceReject: {
            hidden: !canForceDecision || hideActionsForAdvancedFeatures,
            disabled: isRejected,
        },
        revoke: {
            hidden:
                status.isClosed ||
                myDecisions.length === 0 ||
                (isBatchPayment && isRejected) ||
                hideActionsForAdvancedFeatures ||
                isOnHold,
            disabled: false,
        },
        submit: {
            hidden: true,
            disabled: false,
        },
        startOver: {
            hidden:
                !(
                    hasActiveIntegration &&
                    isManager &&
                    status.isOpen &&
                    request.templateVersionIsObsolete &&
                    !(isBatchPayment && isRejected)
                ) ||
                hideActionsForAdvancedFeatures ||
                isOnHold,
            disabled: false,
        },
        editSecondary: {
            hidden: !canEditInSecondaryActions || hideActionsForAdvancedFeatures || isOnHold,
            disabled: disableEdit,
        },
        delete: {
            hidden: !(isMyDraft && createdInApprovalMax) || hideActionsForAdvancedFeatures || isOnHold,
            disabled: false,
        },
        cancel: {
            hidden: getCancelIsHidden() || isOnHold,
            disabled: false,
        },
        clone: {
            hidden: !hasCreatableTemplate || hideCopyButton || hideActionsForAdvancedFeatures,
            disabled: false,
        },
        clonePOToBill: {
            hidden: !hasCreatableTemplateBill || !hasCopyToBillOptions,
            disabled: false,
        },
        cloneQuoteToSalesInvoice: {
            hidden: !hasCreatableTemplateSalesInvoice || !hasCopyToSalesInvoiceOptions,
            disabled: false,
        },
        reverse: {
            hidden: !hasCreatableTemplate || hideReverseButton || hideActionsForAdvancedFeatures,
            disabled: false,
        },
        editPrimary: {
            hidden:
                !(canEditInReview || canEditAsApprover) ||
                canEditInSecondaryActions ||
                hideActionsForAdvancedFeatures ||
                isOnHold,
            disabled: false,
        },
        completeReview: {
            hidden: !canCompleteReview || hideActionsForAdvancedFeatures,
            disabled: false,
        },
        forceReview: {
            hidden: !canForceReview || hideActionsForAdvancedFeatures,
            disabled: false,
        },
        returnToReview: {
            hidden: !canReturnToReview || hideActionsForAdvancedFeatures,
            disabled: false,
        },
    };
};

export const canMarkAsBilled = (request: ExpandedRequest, me: Guid) => {
    const isCompanyManager = request.company.managers.includes(me);

    return (
        request.integrationCode === domain.IntegrationCode.XeroPo &&
        request.statusV2 === domain.RequestStatusV2.Approved &&
        (request.flags.isAuthor || isCompanyManager)
    );
};

const canMatch = (params: {
    request: domain.Request;
    me: Guid;
    company: ExpandedCompany;
    flags: ExpandedRequest['flags'];
    activeStep: domain.RequestStep | null;
}) => {
    const { request, me, company, flags, activeStep } = params;

    const isMatchingV2 = company.flags.isXeroMatchingV2;

    if (!isMatchingV2) {
        return false;
    }

    const isCompanyManager = company.managers.includes(me);
    const isPurchaseOrder = request.integrationCode === domain.IntegrationCode.XeroPo;
    const isBill = request.integrationCode === domain.IntegrationCode.XeroBill;
    const isActiveReviewer = selectors.request.canReview(request, me);

    if (isPurchaseOrder && request.details.isBilled) {
        return false;
    }

    if (
        ![
            domain.RequestStatusV2.Draft,
            domain.RequestStatusV2.Approved,
            domain.RequestStatusV2.OnApproval,
            domain.RequestStatusV2.OnHold,
            domain.RequestStatusV2.OnReview,
        ].includes(request.statusV2) &&
        !(isCompanyManager || isActiveReviewer)
    ) {
        return false;
    }

    if (request.statusV2 === domain.RequestStatusV2.Rejected) {
        return false;
    }

    if (!(isBill && flags.status.isOpen) && !(isPurchaseOrder && flags.status.isClosed) && !isCompanyManager) {
        // Only open bills and closed PO are valid (exeption: for admin if matching v2 is enabled)
        return false;
    }

    if (flags.isAuthor || company.managers.includes(me) || isActiveReviewer) {
        return true;
    }

    if (activeStep && activeStep.participants.some((p) => p.userId === me)) {
        return true;
    }

    return false;
};

export const getXeroMatchingCommands = (state: State, request: ExpandedRequest) => {
    const me = getProfileUser(state).id;
    const isPurchaseOrder = request.integrationCode === domain.IntegrationCode.XeroPo;
    const userCanMatch = canMatch({
        request,
        me,
        company: request.company,
        flags: request.flags,
        activeStep: request.activeStep,
    });
    const userCanMarkAsBilled = canMarkAsBilled(request, me);

    return {
        changeMatchingV2: {
            hidden: !userCanMatch,
            disabled: false,
        },
        markAsBilled: {
            hidden: !(userCanMarkAsBilled && isPurchaseOrder && !request.details.isBilled),
            disabled: false,
        },
        unmarkAsBilled: {
            hidden: !(userCanMarkAsBilled && isPurchaseOrder && request.details.isBilled),
            disabled: false,
        },
    };
};
