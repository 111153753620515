import { Box, ConditionWrapper, Flex, Radio } from '@approvalmax/ui/src/components';
import { actions } from 'modules/profile';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { xeroBillToPoMatchingLearnMore } from '../../WorkflowSettingsPopupContent.constants';
import { PromotionButtons } from '../PromotionButtons/PromotionButtons';
import { SectionFeaturePromotion } from '../SectionFeaturePromotion/SectionFeaturePromotion';
import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { promoTemplateSettings } from './SectionXeroPOMatching.constants';
import { messages } from './SectionXeroPOMatching.messages';
import { AutoMarkingPoOption, SectionXeroPOMatchingProps } from './SectionXeroPOMatching.types';

export const SectionXeroPOMatching: FC<SectionXeroPOMatchingProps> = memo((props) => {
    const templateSettings = 'promoView' in props ? promoTemplateSettings : props.templateSettings;
    const readonly = 'promoView' in props ? true : props.readonly;
    const qa = 'promoView' in props ? null : props.qa;
    const onChangeAutoMarkingPOAsBilledEnabled =
        'promoView' in props ? undefined : props.onChangeAutoMarkingPOAsBilledEnabled;
    const onChangeAutoUnmarkingPOAsBilledEnabled =
        'promoView' in props ? undefined : props.onChangeAutoUnmarkingPOAsBilledEnabled;
    const company = 'promoView' in props ? props.company : undefined;
    const isPromoView = 'promoView' in props;

    const dispatch = useDispatch();

    const handleTryIt = useCallback(() => {
        if (!company) {
            return;
        }

        dispatch(actions.openConsentToStartTrialPopup(company, 'po_matching_settings'));
    }, [company, dispatch]);

    const handleChangeAutoMarkingPOAsBilledEnabled = useCallback(
        (checkedValue: AutoMarkingPoOption) => {
            switch (checkedValue) {
                case AutoMarkingPoOption.yes:
                    onChangeAutoMarkingPOAsBilledEnabled?.(true);
                    break;

                case AutoMarkingPoOption.no:
                    onChangeAutoMarkingPOAsBilledEnabled?.(false);
                    break;
            }
        },
        [onChangeAutoMarkingPOAsBilledEnabled]
    );

    const handleChangeAutoUnmarkingPOAsBilledEnabled = useCallback(
        (checkedValue: AutoMarkingPoOption) => {
            switch (checkedValue) {
                case AutoMarkingPoOption.yes:
                    onChangeAutoUnmarkingPOAsBilledEnabled?.(true);
                    break;

                case AutoMarkingPoOption.no:
                    onChangeAutoUnmarkingPOAsBilledEnabled?.(false);
                    break;
            }
        },
        [onChangeAutoUnmarkingPOAsBilledEnabled]
    );

    const purchaseOrderMatchingSettings = templateSettings.purchaseOrderMatchingSettings;

    return (
        <ConditionWrapper
            condition={isPromoView}
            wrapperIf={(children) => (
                <SectionFeaturePromotion title={messages.title} onTryIt={handleTryIt}>
                    <Box spacing='0 24 16'>
                        <Flex inline spacing='24' direction='column'>
                            {children}
                        </Flex>
                    </Box>
                </SectionFeaturePromotion>
            )}
            wrapperElse={(children) => (
                <SettingsSection title={messages.title} learnMoreLink={xeroBillToPoMatchingLearnMore}>
                    {children}
                </SettingsSection>
            )}
        >
            <>
                <SettingsSubsection
                    subtitle={messages.matchingAutoMarkingPoSubtitle}
                    color={isPromoView ? 'midnight60' : undefined}
                >
                    <Radio.Group
                        disabled={readonly}
                        value={
                            purchaseOrderMatchingSettings?.autoMarkingPurchaseOrderAsBilledEnabled
                                ? AutoMarkingPoOption.yes
                                : AutoMarkingPoOption.no
                        }
                        onChange={handleChangeAutoMarkingPOAsBilledEnabled}
                        name='autoMarkingPo'
                        block
                        data-qa={qa?.('MatchingSection-AutoMarkingPO')}
                    >
                        <Radio value={AutoMarkingPoOption.yes}>{messages.autoMarkingPoYesLabel}</Radio>

                        <Radio value={AutoMarkingPoOption.no}>{messages.autoMarkingPoNoLabel}</Radio>
                    </Radio.Group>
                </SettingsSubsection>

                {isPromoView && (
                    <PromotionButtons learnMoreLink={xeroBillToPoMatchingLearnMore} onClickTryIt={handleTryIt} />
                )}

                <SettingsSubsection
                    subtitle={messages.matchingAutoUnmarkingPoSubtitle}
                    color={isPromoView ? 'midnight60' : undefined}
                >
                    <Radio.Group
                        disabled={readonly}
                        value={
                            purchaseOrderMatchingSettings?.autoUnmarkingPurchaseOrderAsBilledEnabled
                                ? AutoMarkingPoOption.yes
                                : AutoMarkingPoOption.no
                        }
                        onChange={handleChangeAutoUnmarkingPOAsBilledEnabled}
                        name='autoUnmarkingPo'
                        block
                        data-qa={qa?.('MatchingSection-AutoUnmarkingPO')}
                    >
                        <Radio value={AutoMarkingPoOption.yes}>{messages.autoUnmarkingPoYesLabel}</Radio>

                        <Radio value={AutoMarkingPoOption.no}>{messages.autoUnmarkingPoNoLabel}</Radio>
                    </Radio.Group>
                </SettingsSubsection>
            </>
        </ConditionWrapper>
    );
});

SectionXeroPOMatching.displayName = 'SectionXeroPOMatching';
