import { RequestDataParams } from '@approvalmax/data';

import { useMutateLegacy } from '../../../api';
import { companiesApiPaths } from '../paths';
import { NetSuiteCompanyConnectRequest, NetSuiteCompanyConnectResponse } from './useConnectToNetSuiteAccount.types';

export const useConnectToNetSuiteAccount = () => {
    return useMutateLegacy<RequestDataParams<NetSuiteCompanyConnectRequest>, NetSuiteCompanyConnectResponse>(
        companiesApiPaths.connectToNetSuiteAccount
    );
};
