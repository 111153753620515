import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';

import { moduleDef } from '..';
import { RequestModuleData } from '../reducers/moduleReducer';

function getModule(state: stateTree.State) {
    return selectors.module.getModule<RequestModuleData>(state, moduleDef.id);
}

export function getActivePopup(state: stateTree.State) {
    return getModule(state).activePopup;
}

export function getNewRequestPopupData(state: stateTree.State) {
    return getModule(state).newRequestPopup;
}
