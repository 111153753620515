import { Cin7Logo, NetSuiteLogo, QBooksLogo, XeroLogo } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';

export const isSyncInProgress = (integrations: domain.ExtendedIntegration[]) => {
    return !!integrations?.some((integration) => {
        const someInProgress = integration.syncProgress.some(
            (item) => item.status === domain.IntegrationSyncStatus.InProgress
        );

        return someInProgress;
    });
};

export const getIntegrationIcon = (integrationType?: domain.IntegrationType | null) => {
    if (!integrationType) {
        return null;
    }

    switch (integrationType) {
        case domain.IntegrationType.Xero:
            return XeroLogo;

        case domain.IntegrationType.QBooks:
            return QBooksLogo;

        case domain.IntegrationType.NetSuite:
            return NetSuiteLogo;

        case domain.IntegrationType.Dear:
            return Cin7Logo;

        case domain.IntegrationType.None:
            errorHelpers.captureException(
                new Error(`[SyncDropdownContent] Invalid integrationType: ${integrationType}`)
            );

            return null;

        default:
            errorHelpers.captureException(
                new Error(`[SyncDropdownContent] Unknown integrationType: ${integrationType}`)
            );

            return null;
    }
};
