import { domain } from 'modules/data';

import entitiesReducer from './resources/reducers/entitiesReducer';
import navigationReducer from './resources/reducers/navigationReducer';
import pageReducer from './resources/reducers/pageReducer';
import autoSaveSaga from './resources/sagas/autoSaveSaga';

export enum EQueryKeys {
    Users = 'Users',
}

export const pageConfig = {
    access: [domain.CompanyUserRole.Manager, domain.CompanyUserRole.Auditor, domain.CompanyUserRole.WorkflowManager],
    store: {
        id: 'users',
        pageReducer,
        reducers: {
            entities: entitiesReducer,
            navigation: navigationReducer,
        },
        sagas: [autoSaveSaga],
    },
};
