import { Box, Button, Form, Grid, Popup, Text, TextField } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { FormContent, Header } from './components';
import { useDelegatesFilter, useDelegatesFormContent, useMyDelegatesPopupData } from './MyDelegatesPopup.hooks';
import { messages } from './MyDelegatesPopup.messages';

export const MyDelegatesPopup = memo(() => {
    const { delegates: unfilteredDelegates, filterOutUnchanged, me, myTimeZone, isLoading } = useMyDelegatesPopupData();
    const { delegates, handleSearchQueryChange, searchQuery } = useDelegatesFilter(unfilteredDelegates);
    const {
        form,
        controlledFields,
        handleSubmit,
        handleDelete,
        isLoadingUpdateDelegations,
        applyDelegateToChangeSideEffects,
    } = useDelegatesFormContent({ filterOutUnchanged, me, myTimeZone, delegates });

    const noVirtualization = delegates.length < 15;

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Popup.Header
                progress={isLoading}
                title={messages.popupTitle}
                actions={
                    <Button
                        size='medium'
                        color='blue80'
                        type='submit'
                        disabled={isLoading || isLoadingUpdateDelegations}
                    >
                        {messages.setDelegateButton}
                    </Button>
                }
            />

            <Popup.Body spacing='32' className='fs-mask'>
                <Grid gap={24}>
                    <div>
                        <Text font='body' fontSize='medium'>
                            {messages.descriptionText}
                        </Text>

                        <br />

                        <Text font='body' fontSize='medium'>
                            {messages.timezoneHintText({
                                pleaseNote: <b>{messages.pleaseNoteText}</b>,
                            })}
                        </Text>
                    </div>

                    <Box width={260}>
                        <TextField
                            placeholder={messages.searchOrgPlaceholder}
                            value={searchQuery}
                            onChange={handleSearchQueryChange}
                        />
                    </Box>

                    <div>
                        <Grid gridTemplateColumns='172px 140px 180px 170px 170px' gap={8}>
                            <Header>{messages.companyColumnTitle}</Header>

                            <Header>{messages.delegateColumnTitle}</Header>

                            <Header>{messages.timeZone}</Header>

                            <Header>{messages.startDateColumnTitle}</Header>

                            <Header>{messages.endDateColumnTitle}</Header>
                        </Grid>

                        {noVirtualization ? (
                            <Grid gap={16}>
                                {controlledFields.map((delegate, index) => {
                                    applyDelegateToChangeSideEffects(delegate, index);

                                    return (
                                        <FormContent
                                            key={delegate.company.id}
                                            delegate={delegate}
                                            index={index}
                                            onDelete={handleDelete}
                                        />
                                    );
                                })}
                            </Grid>
                        ) : (
                            <Virtuoso
                                style={{ height: '60vh' }}
                                data={controlledFields}
                                itemContent={(index, delegate) => {
                                    applyDelegateToChangeSideEffects(delegate, index);

                                    return (
                                        <Box spacing='8 0'>
                                            <FormContent delegate={delegate} index={index} onDelete={handleDelete} />
                                        </Box>
                                    );
                                }}
                            />
                        )}
                    </div>
                </Grid>
            </Popup.Body>
        </Form>
    );
});

MyDelegatesPopup.displayName = 'MyDelegatesPopup';
