import { FC, PropsWithChildren } from 'react';

import { CrashedComponentPlaceholder } from '../CrashedComponentPlaceholder/CrashedComponentPlaceholder';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import type { PageErrorBoundaryProps } from './PageErrorBoundary.types';

/**
 * This is an ABSTRACT implementation PageErrorBoundary.
 * You can't use it directly, because each app has its own implementation of
 * "contact support" popup, that's why you need to use (or create) an
 * implementation within your app
 */
export const PageErrorBoundary: FC<PropsWithChildren<PageErrorBoundaryProps>> = (props) => {
    const { children, onContactSupport, errorPlaceholder } = props;

    return (
        <ErrorBoundary
            errorPlaceholder={errorPlaceholder || <CrashedComponentPlaceholder onContactSupport={onContactSupport} />}
        >
            {children}
        </ErrorBoundary>
    );
};

PageErrorBoundary.displayName = 'PageErrorBoundary';
