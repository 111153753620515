import { PasskeysFilledIcon } from '@approvalmax/ui';
import { Button } from '@approvalmax/ui/src/components';
import { finishLoginFlow } from 'pages/auth/actions';
import { memo, useCallback, useState } from 'react';
import { notificationService } from 'services/notification';
import { useFido2Assertion, useFido2AssetionOptions } from 'shared/data';

import { getAssertionTranfer, getCredentials } from './PasskeyAuthButton.helpers';
import { messages } from './PasskeyAuthButton.messages';
import { PasskeyAuthButtonProps } from './PasskeyAuthButton.types';

export const PasskeyAuthButton = memo<PasskeyAuthButtonProps>((props) => {
    const { className, postAction, blockButton, shouldReturnToCurrentPage } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [getFido2AssertionOptions] = useFido2AssetionOptions();

    const { mutateAsync: loginWithFido2 } = useFido2Assertion();

    const handleLogin = useCallback(async () => {
        setIsLoading(true);

        try {
            const assertionOptions = await getFido2AssertionOptions({});

            const credentials = await getCredentials(assertionOptions);

            await loginWithFido2({
                data: getAssertionTranfer(credentials, assertionOptions),
            });

            finishLoginFlow({
                postAction: shouldReturnToCurrentPage ? window.location.href : postAction,
            });
        } catch {
            notificationService.showErrorToast(messages.errorText);
        }

        setIsLoading(false);
    }, [getFido2AssertionOptions, loginWithFido2, postAction, shouldReturnToCurrentPage]);

    return (
        <Button
            className={className}
            color='midnight100'
            startIcon={<PasskeysFilledIcon />}
            progress={isLoading}
            block={blockButton}
            onClick={handleLogin}
        >
            {messages.title}
        </Button>
    );
});

PasskeyAuthButton.displayName = 'PasskeyAuthButton';
