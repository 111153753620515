import { useGetRequestLegacy } from 'shared/data/api';

import { integrationsApiPaths } from '../paths';
import { mapData } from './useGetContactCisSettings.map';
import {
    UseGetContactCisSettingsParams,
    UseGetContactCisSettingsResponse,
    UseGetContactCisSettingsResponseBackend,
} from './useGetContactCisSettings.types';

export const useGetContactCisSettings = (params: UseGetContactCisSettingsParams) => {
    return useGetRequestLegacy<UseGetContactCisSettingsResponseBackend, UseGetContactCisSettingsResponse>(
        integrationsApiPaths.getContactCisSettings,
        params,
        {
            mapData,
        }
    );
};
