import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.XeroCreditNoteStep', {
    bubbleText: 'Pulling of {templateName}',
    title: `
        There are several methods to pull {pluralTemplateName} in ApprovalMax.
        Please see the options below and choose as applicable.`,
    externalSubmitterDescriptionNonEmpty: `
        {pluralTemplateName} will be created in ApprovalMax under the name of this User.
        They will be notified about approvals, rejections, and comments.`,
    externalSubmitterDescriptionEmpty: `
        Enter the user who will be notified about {templateName} status changes in ApprovalMax.\n
        A generic email address, e.g. finance@mycompany.com, can also be used.`,
    externalSubmitterTitle:
        '{pluralTemplateName} in the "Awaiting approval" status will be automatically pulled from Xero.',
});
