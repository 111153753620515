import { AccessType } from '../../../../types/matrix';
import { messages } from './ConditionHeaderColumn.messages';

export const items = [
    {
        id: AccessType.Mandatory,
        name: messages.theFieldIsMandatory,
    },
    {
        id: AccessType.Optional,
        name: messages.theFieldIsOptional,
    },
    {
        id: AccessType.Readonly,
        name: messages.theFieldIsReadonly,
    },
];

export const accessTypeTextMap = {
    [AccessType.Mandatory]: messages.alwaysMandatory,
    [AccessType.Optional]: messages.alwaysOptional,
    [AccessType.Readonly]: messages.readonly,
};
