import { FC } from 'react';

import { Box } from '../Box/Box';
import { CrashedComponentPlaceholder } from '../CrashedComponentPlaceholder/CrashedComponentPlaceholder';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { AppCrashedImage } from './components';
import { messages } from './GlobalErrorBoundary.messages';
import type { GlobalErrorBoundaryProps } from './GlobalErrorBoundary.types';

/**
 * This is an ABSTRACT implementation GlobalErrorBoundary.
 * You can't use it directly, because each app has its own implementation of
 * "contact support" popup, that's why you need to use (or create) an
 * implementation within your app
 */
export const GlobalErrorBoundary: FC<GlobalErrorBoundaryProps> = (props) => {
    const { children, contactSupportPopup, onContactSupport } = props;

    return (
        <ErrorBoundary
            errorPlaceholder={
                // 16px are body vertical paddings
                <Box height='calc(100vh - 16px)'>
                    <CrashedComponentPlaceholder
                        title={messages.appCrashed}
                        Image={AppCrashedImage}
                        onContactSupport={onContactSupport}
                    />

                    {contactSupportPopup}
                </Box>
            }
        >
            {children}
        </ErrorBoundary>
    );
};

GlobalErrorBoundary.displayName = 'GlobalErrorBoundary';
