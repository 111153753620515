import { Badge, Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { useUserProfile } from 'modules/utils';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { usePluralInvitations, useSingleInvitation } from './PracticeStaffInvitations.hooks';
import { messages } from './PracticeStaffInvitations.messages';

const PracticeStaffInvitations = memo(() => {
    const invitations = useSelector(selectors.practiceInvitations.getPracticeInvitations);
    const profile = useUserProfile();

    const { invite, onDecline, onAccept, disabled } = useSingleInvitation();
    const { openInvitationsPopup } = usePluralInvitations();

    if (!invitations.length) {
        return null;
    }

    return (
        <Box spacing='16 16 0' width={240}>
            <Badge placement='top-end' color='red100' size={8}>
                <Box spacing='8' radius='xsmall' bordered>
                    <Flex inline direction='column' spacing='8'>
                        <Text font='label' fontSize='small'>
                            {messages.title}
                        </Text>

                        {invitations.length === 1 && !profile.account?.userRole ? (
                            <>
                                <Text font='body' fontSize='small' color='midnight70' wordBreak='break-word'>
                                    {invite.practiceName
                                        ? messages.singleInvitationDescriptionWithName({
                                              companyName: (
                                                  <Text
                                                      font='body'
                                                      fontSize='small'
                                                      fontWeight='medium'
                                                      color='midnight100'
                                                  >
                                                      {invite.practiceName}
                                                  </Text>
                                              ),
                                              displayName: selectors.user.getDisplayName(
                                                  invite.inviter.firstName,
                                                  invite.inviter.lastName,
                                                  invite.inviter.userEmail
                                              ),
                                              email: invite.inviter.userEmail,
                                          })
                                        : messages.singleInvitationDescriptionWithoutName({
                                              displayName: selectors.user.getDisplayName(
                                                  invite.inviter.firstName,
                                                  invite.inviter.lastName,
                                                  invite.inviter.userEmail
                                              ),
                                              email: invite.inviter.userEmail,
                                          })}
                                </Text>

                                <Flex inline spacing='8'>
                                    <Button onClick={onDecline} disabled={disabled} size='xsmall' color='red40'>
                                        {messages.declineButton}
                                    </Button>

                                    <Button onClick={onAccept} disabled={disabled} size='xsmall' color='green80'>
                                        {messages.acceptButton}
                                    </Button>
                                </Flex>
                            </>
                        ) : (
                            <>
                                <Text font='body' fontSize='small' color='midnight70' wordBreak='break-word'>
                                    {messages.pluralInvitationDescription({ count: invitations.length })}
                                </Text>

                                <Flex inline spacing='8'>
                                    <Button onClick={openInvitationsPopup} size='xsmall' color='blue80'>
                                        {messages.chooseAndAcceptButton}
                                    </Button>
                                </Flex>
                            </>
                        )}
                    </Flex>
                </Box>
            </Badge>
        </Box>
    );
});

export default PracticeStaffInvitations;
