import { useTheme } from '@approvalmax/theme';
import { animated, useTransition } from '@react-spring/web';
import { FC, useEffect } from 'react';
import { useMountedState, useToggle } from 'react-use';
import styled from 'styled-components';

import type { FadeProps } from './Fade.types';

const AnimatedDiv = styled(animated.div as any)``;

export const Fade: FC<FadeProps> = (props) => {
    const { children, in: inProp, timeout, ...restProps } = props;

    const isMounted = useMountedState();
    const [show, toggleShow] = useToggle(inProp);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        toggleShow(inProp);

        if (inProp && timeout) {
            timer = setTimeout(() => {
                if (isMounted()) {
                    toggleShow(false);
                }
            }, timeout);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [inProp, isMounted, timeout, toggleShow]);

    const { theme } = useTheme();
    const transitions = useTransition(show ? [0] : [], {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        ...restProps,
        config: { duration: +theme.duration.medium, ...restProps.config },
    });

    return transitions((style) => <AnimatedDiv style={style}>{children}</AnimatedDiv>);
};

Fade.displayName = 'Fade';
