import { Guid } from '@approvalmax/types';
import { produce } from 'immer';
import { IntegrationSuccessRedirectPage } from 'modules/integration';

import { Action, SHOW_DEAR_INTEGRATION_POPUP } from '../../../actions';

export const DEAR_INTEGRATION_POPUP = 'DEAR_INTEGRATION_POPUP';

export interface DearIntegrationData {
    id: typeof DEAR_INTEGRATION_POPUP;
    companyId: Guid | null;
    redirectPage: IntegrationSuccessRedirectPage;
}

export default produce((draft: DearIntegrationData | null, action: Action): DearIntegrationData | null => {
    switch (action.type) {
        case SHOW_DEAR_INTEGRATION_POPUP: {
            return {
                id: DEAR_INTEGRATION_POPUP,
                companyId: action.payload.companyId,
                redirectPage: action.payload.redirectPage,
            };
        }

        default:
            return draft;
    }
}, null);
