import { ApiBaseAnswer, MutationOptions, RequestDataParams } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { budgetsApiPaths, useMutate } from 'shared/data';

import { UseQBooksBudgetSyncPathParams } from './useQBooksBudgetSync.types';

export const useQBooksBudgetSync = (
    mutationOptions?: MutationOptions<ApiBaseAnswer, RequestDataParams<{}, UseQBooksBudgetSyncPathParams>>
) => {
    const queryClient = useQueryClient();

    return useMutate<RequestDataParams<{}, UseQBooksBudgetSyncPathParams>>(budgetsApiPaths.qBooksBudgetSync, {
        method: 'post',
        apiVersion: 'v2',
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (responseData, requestData, context) => {
                const budgetsApiPath = routerHelpers.pathToUrl(budgetsApiPaths.getQBooksBudgets, {
                    companyId: requestData.pathParams?.companyId,
                });

                queryClient.invalidateQueries([budgetsApiPath]);

                mutationOptions?.onSuccess?.(responseData, requestData, context);
            },
        },
    });
};
