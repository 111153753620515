import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('profile.ProfilePopup.SecuritySection', {
    securityHeader: 'Security',
    useSessionTitle: 'Log me out after 15 minutes of inactivity',
    twoFaTitle: 'Two-factor authentication',
    twoFaStatusDisabled: '(Disabled)',
    twoFaDisabledDescription: 'As you registered via SSO and no password is set, 2FA is not available.',
    verifyDescription:
        'Backup options can be used during Two-factor authentication when you have no access to the Authenticator app',
    edit: 'Edit',
});
