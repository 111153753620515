import { font, TransparentButton } from '@approvalmax/ui';
import styled from 'styled-components';

export const BackButton = styled(TransparentButton)`
    border: 1px solid #000;
    text-align: center;
    ${font(12, '#384650', 'semibold')}
    padding: 10px 0;
    text-transform: uppercase;
`;
