import { ApiHandler } from '../types';

class BaseApiUrls {
    constructor(private _apiHandler: ApiHandler) {}
}

export default class BaseApiApi {
    public urls: BaseApiUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new BaseApiUrls(this._apiHandler);
    }
}
