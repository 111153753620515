import { ChevronRightIcon } from '@approvalmax/ui';
import { Flex } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { Fragment, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import AddNewStepButton from '../AddNewStepButton/AddNewStepButton';
import ApprovalStep from '../ApprovalStep/ApprovalStep';
import DraggableStepList from '../DraggableStepList/DraggableStepList';
import EndStepWithDescription from '../EndStepWithDescription/EndStepWithDescription';
import QBooksStartStep from '../QBooksStartStep/QBooksStartStep';
import { SingletonSteps } from '../SingletonSteps/SingletonSteps';
import { messages } from './QBooksWorkflowDetails.messages';

const QBooksWorkflowDetails = memo(() => {
    const template = useSelector(getActiveTemplate);

    const pluralTemplateName = useMemo(
        () => selectors.template.getTemplateDisplayNameByCode(template?.integrationCode ?? null, true),
        [template?.integrationCode]
    );

    if (!template) {
        return null;
    }

    return (
        <>
            <QBooksStartStep />

            <SingletonSteps />

            <DraggableStepList>
                {template.steps.map((step, index) => (
                    <Fragment key={step.id}>
                        {index === 0 && (
                            <Flex height={28} inline alignItems='center'>
                                <ChevronRightIcon size={16} color='midnight60' />
                            </Flex>
                        )}

                        <ApprovalStep stepIndex={index} index={index} step={step} />
                    </Fragment>
                ))}
            </DraggableStepList>

            <AddNewStepButton />

            <EndStepWithDescription
                color='brandQBO100'
                bubbleText={messages.endStepBubbleTextPo}
                description={messages.endStepDescriptionPo({
                    pluralTemplateName,
                })}
            />
        </>
    );
});

export default QBooksWorkflowDetails;
