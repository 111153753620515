import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

export const VerticalLine = styled.span<StyledMods<Mods<'color'>>>`
    border-left-style: solid;
    border-left-width: 1px;
    min-height: 16px;
    height: 100%;

    ${mods.color(
        (value) => css`
            border-color: ${theme.color[value]};
        `
    )};
`;

export const HorizontalLine = styled.span<StyledMods<Mods<'color'>>>`
    border-top-style: solid;
    border-top-width: 1px;
    flex-grow: 1;

    ${mods.color(
        (value) => css`
            border-color: ${theme.color[value]};
        `
    )};
`;
