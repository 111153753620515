export const decodeOAuthState = <TData>(
    state: string
): {
    backendState: string;
    uiState: TData;
} => {
    const json = JSON.parse(decodeURIComponent(atob(state)));

    return {
        backendState: json.backend,
        uiState: json.ui,
    };
};
