import { Button, Flex, Popup, toast } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useDispatch } from 'modules/react-redux';
import { isGrnAvailable } from 'modules/utils';
import { memo, useCallback, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useMount } from 'react-use';
import { usePullIntegrations } from 'shared/data';
import { emailToSupplierHelpers, isAllFeaturesTrialStartAllowed, isOcrEnabledForTemplate } from 'shared/helpers';

import { applyTemplateSettings } from '../../actions';
import { SectionCaptureViaEmail } from './components/SectionCaptureViaEmail/SectionCaptureViaEmail';
import SectionDearFraudBypassing from './components/SectionDearFraudBypassing/SectionDearFraudBypassing';
import SectionDearFraudChangesAfter from './components/SectionDearFraudChangesAfter/SectionDearFraudChangesAfter';
import SectionDearPORejectedNoteToMemo from './components/SectionDearPORejectedNoteToMemo/SectionDearPORejectedNoteToMemo';
import SectionDecisionPolicy from './components/SectionDecisionPolicy/SectionDecisionPolicy';
import { SectionEmailToSupplier } from './components/SectionEmailToSupplier/SectionEmailToSupplier';
import { SectionFraudBypassing } from './components/SectionFraudBypassing/SectionFraudBypassing';
import SectionFraudChangesAfter from './components/SectionFraudChangesAfter/SectionFraudChangesAfter';
import { SectionGoodsReceivedNote } from './components/SectionGoodsReceivedNote/SectionGoodsReceivedNote';
import SectionHistoryPushing from './components/SectionHistoryPushing/SectionHistoryPushing';
import SectionInstructionForRequester from './components/SectionInstructionForRequester/SectionInstructionForRequester';
import SectionLineItemSettings from './components/SectionLineItemSettings/SectionLineItemSettings';
import { SectionLockDate } from './components/SectionLockDate/SectionLockDate';
import SectionNetSuiteAmaxUrl from './components/SectionNetSuiteAmaxUrl/SectionNetSuiteAmaxUrl';
import SectionNetSuiteAvailableLineItemType from './components/SectionNetSuiteAvailableLineItemType/SectionNetSuiteAvailableLineItemType';
import SectionNetSuiteExpenseReportFraudChangesAfter from './components/SectionNetSuiteExpenseReportFraudChangesAfter/SectionNetSuiteExpenseReportFraudChangesAfter';
import SectionNetSuiteFraudBypassing from './components/SectionNetSuiteFraudBypassing/SectionNetSuiteFraudBypassing';
import SectionNetSuiteFraudChangesAfter from './components/SectionNetSuiteFraudChangesAfter/SectionNetSuiteFraudChangesAfter';
import SectionPostingPreferencesType from './components/SectionPostingPreferencesType/SectionPostingPreferencesType';
import SectionPriceChecker from './components/SectionPriceChecker/SectionPriceChecker';
import { SectionQboBillMatching } from './components/SectionQboBillMatching/SectionQboBillMatching';
import SectionQboFraudBypassing from './components/SectionQboFraudBypassing/SectionQboFraudBypassing';
import { SectionQboFraudChangesAfter } from './components/SectionQboFraudChangesAfter/SectionQboFraudChangesAfter';
import SectionSafetyCatch from './components/SectionSafetyCatch/SectionSafetyCatch';
import SectionSupplierBankAccountRequired from './components/SectionSupplierBankAccountRequired/SectionSupplierBankAccountRequired';
import SectionTerms from './components/SectionTerms/SectionTerms';
import SectionUrlOverriding from './components/SectionUrlOverriding/SectionUrlOverriding';
import SectionUseRejectedPrefix from './components/SectionUseRejectedPrefix/SectionUseRejectedPrefix';
import { SectionXeroBillMatching } from './components/SectionXeroBillMatching/SectionXeroBillMatching';
import { SectionXeroPOMatching } from './components/SectionXeroPOMatching/SectionXeroPOMatching';
import { settingsApplicabilityMx } from './WorkflowSettingsPopupContent.constants';
import { getNewApprovalDisregardDetectionSettings } from './WorkflowSettingsPopupContent.helpers';
import {
    useEffectiveDateLimits,
    useFetchAndApplyWorkflow,
    useWorkflowSettingsPopupData,
} from './WorkflowSettingsPopupContent.hooks';
import { messages } from './WorkflowSettingsPopupContent.messages';
import { CheckValidFraudBypassing, ESettingsSection } from './WorkflowSettingsPopupContent.types';

const qa = bemFactory.qa('wfc-settings-popup');

const WorkflowSettingsPopupContent = memo(() => {
    const dispatch = useDispatch();
    const { mutateAsync: pullIntegrations } = usePullIntegrations();
    const { company, template, savedTemplateSettings, readonly, isSaveDisabled } = useWorkflowSettingsPopupData();
    const effectiveDateLimits = useEffectiveDateLimits(savedTemplateSettings);

    const [templateSettings, setTemplateSettings] = useState(savedTemplateSettings);

    const fetchAndApplyWorkflow = useFetchAndApplyWorkflow(company, template);

    useMount(() => {
        const supplierEmailSettings = { ...templateSettings.supplierEmailSettings };

        if (
            supplierEmailSettings.state === null ||
            !Object.values(domain.TemplateSettingsEmailToSupplierState).includes(supplierEmailSettings.state)
        ) {
            supplierEmailSettings.state = domain.TemplateSettingsEmailToSupplierState.Enabled;
        }

        setTemplateSettings({ ...templateSettings, supplierEmailSettings });
    });

    const enabledSettings = settingsApplicabilityMx[template.integrationCode || 'null'];

    const title = messages.title({
        templateName: template.displayName,
    });
    const buttonText = messages.saveButton;
    const buttonTitle = messages.saveButtonTitle({
        templateName: template.displayName,
    });
    const isCaptureViaEmailEnable =
        enabledSettings.includes(ESettingsSection.CaptureViaEmail) &&
        isOcrEnabledForTemplate(company.betaFeatures, company.licenseFeatures, template.integrationCode);

    const links = selectors.links.getLinksByIntegration(company.integration?.integrationType, template.integrationCode);

    const checkValidFraudBypassing: CheckValidFraudBypassing = useCallback(
        (integrationType) => {
            let fraudBypassing;

            switch (integrationType) {
                case domain.IntegrationType.Xero:
                    fraudBypassing = templateSettings.approvalDisregardDetectionSettings;
                    break;

                case domain.IntegrationType.QBooks:
                    fraudBypassing = templateSettings.qBooksApprovalDisregardDetectionSettings;
                    break;

                case domain.IntegrationType.NetSuite:
                    fraudBypassing = templateSettings.netSuiteApprovalDisregardDetectionSettings;
                    break;

                case domain.IntegrationType.Dear:
                    fraudBypassing = templateSettings.dearApprovalDisregardDetectionSettings;
                    break;
            }

            const invalidFraudBypassing = Boolean(
                fraudBypassing && fraudBypassing.enabled && !fraudBypassing.effectiveDate
            );

            return !invalidFraudBypassing;
        },
        [templateSettings]
    );

    const applyAndClose = useCallback(() => {
        if (
            !checkValidFraudBypassing(domain.IntegrationType.Xero) ||
            !checkValidFraudBypassing(domain.IntegrationType.QBooks) ||
            !checkValidFraudBypassing(domain.IntegrationType.NetSuite)
        ) {
            toast.error(messages.requiredFieldsNotification);

            return;
        }

        const enabledSettings = settingsApplicabilityMx[template.integrationCode || 'null'];
        const newSettings = {
            templateId: template.id,
            approveAllStepsEnabled: templateSettings.approveAllStepsEnabled,
            appUrlOverridingEnabled: templateSettings.appUrlOverridingEnabled,
        } as domain.TemplateSettings;

        if (enabledSettings.includes(ESettingsSection.LockDate)) {
            newSettings.lockDatePolicySettings = templateSettings.lockDatePolicySettings;
        }

        if (enabledSettings.includes(ESettingsSection.SafetyCatch)) {
            newSettings.autoDecisionPolicySettings = templateSettings.autoDecisionPolicySettings;
        }

        if (enabledSettings.includes(ESettingsSection.HistoryPushingType)) {
            newSettings.historyEventsPushingSettings = templateSettings.historyEventsPushingSettings;
        }

        if (enabledSettings.includes(ESettingsSection.SupplierBankAccountRequired)) {
            newSettings.isSupplierBankAccountRequired = templateSettings.isSupplierBankAccountRequired;
        }

        if (enabledSettings.includes(ESettingsSection.FraudBypassing)) {
            newSettings.approvalDisregardDetectionSettings = getNewApprovalDisregardDetectionSettings(
                savedTemplateSettings,
                templateSettings,
                domain.IntegrationType.Xero
            );
        }

        if (enabledSettings.includes(ESettingsSection.FraudChangesAfter)) {
            newSettings.postApprovalChangeDetectionSettings = {
                ...templateSettings.postApprovalChangeDetectionSettings!,
                notifyAdmins: true,
            };
        }

        if (enabledSettings.includes(ESettingsSection.XeroBillMatching)) {
            if (templateSettings.billMatchingSettings) {
                const isEnabledInsufficientBudgetMatch =
                    templateSettings.billMatchingSettings.allowApprovalOfNotMatchedBills ===
                        domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold ||
                    templateSettings.billMatchingSettings.allowApprovalOfNotMatchedBills ===
                        domain.CompanyMatchingSettingsBillApprovalPolicy.Never;

                if (!isEnabledInsufficientBudgetMatch) {
                    templateSettings.billMatchingSettings = {
                        ...templateSettings.billMatchingSettings,
                        insufficientBudgetMatchBillsApprovalAllowed: domain.ApprovalAllowanceType.Always,
                        insufficientBudgetMatchBillsApprovalThreshold: 0,
                        insufficientBudgetMatchBillsApprovalPercentageThreshold: 0,
                    };
                }
            }

            newSettings.billMatchingSettings = templateSettings.billMatchingSettings;
        }

        if (enabledSettings.includes(ESettingsSection.XeroPOMatching)) {
            newSettings.purchaseOrderMatchingSettings = templateSettings.purchaseOrderMatchingSettings;
        }

        if (enabledSettings.includes(ESettingsSection.QBooksBillMatching)) {
            newSettings.billMatchingSettings = templateSettings.billMatchingSettings;
        }

        if (enabledSettings.includes(ESettingsSection.Instruction)) {
            newSettings.requesterInstructionSettings = templateSettings.requesterInstructionSettings;
        }

        if (enabledSettings.includes(ESettingsSection.EmailToSupplierSetting)) {
            let subject = emailToSupplierHelpers.removeRootParagraph(
                templateSettings.supplierEmailSettings.subject || ''
            );

            subject = emailToSupplierHelpers.cleanupSubjectString(subject);
            subject = emailToSupplierHelpers.returnExpressions(subject);

            newSettings.supplierEmailSettings = {
                state:
                    templateSettings.supplierEmailSettings.state || domain.TemplateSettingsEmailToSupplierState.Enabled,
                cc: templateSettings.supplierEmailSettings.cc || [],
                subject: emailToSupplierHelpers.removeMentionExtensionTags(subject),
                body: emailToSupplierHelpers.removeMentionExtensionTags(
                    templateSettings.supplierEmailSettings.body || ''
                ),
            };
        }

        if (enabledSettings.includes(ESettingsSection.LineItemSettings)) {
            newSettings.lineItemSettings = templateSettings.lineItemSettings;
        }

        if (enabledSettings.includes(ESettingsSection.NetSuiteFraudBypassing)) {
            newSettings.netSuiteApprovalDisregardDetectionSettings = getNewApprovalDisregardDetectionSettings(
                savedTemplateSettings,
                templateSettings,
                domain.IntegrationType.NetSuite
            );
        }

        if (enabledSettings.includes(ESettingsSection.NetSuiteFraudChangesAfter)) {
            newSettings.netSuitePostApprovalChangeDetectionSettings = {
                ...templateSettings.netSuitePostApprovalChangeDetectionSettings!,
                notifyAdmins: true,
            };
        }

        if (enabledSettings.includes(ESettingsSection.NetSuiteExpenseReportFraudChangesAfter)) {
            newSettings.netSuitePostApprovalChangeDetectionSettings = {
                ...templateSettings.netSuitePostApprovalChangeDetectionSettings!,
                notifyAdmins: true,
            };
        }

        if (enabledSettings.includes(ESettingsSection.QBooksFraudBypassing)) {
            newSettings.qBooksApprovalDisregardDetectionSettings = getNewApprovalDisregardDetectionSettings(
                savedTemplateSettings,
                templateSettings,
                domain.IntegrationType.QBooks
            );

            const isPullingEnabledOld = savedTemplateSettings.qBooksApprovalDisregardDetectionSettings?.enabled;
            const isPullingEnabledNew = newSettings.qBooksApprovalDisregardDetectionSettings?.enabled;

            if (isPullingEnabledOld !== isPullingEnabledNew && isPullingEnabledNew && company.integrationId)
                pullIntegrations({
                    companyId: company.id,
                    integrationIds: [company.integrationId],
                });
        }

        if (enabledSettings.includes(ESettingsSection.QBooksFraudChangesAfter)) {
            newSettings.qBooksPostApprovalChangeDetectionSettings = {
                ...templateSettings.qBooksPostApprovalChangeDetectionSettings!,
                notifyAdmins: true,
            };
        }

        if (enabledSettings.includes(ESettingsSection.DearFraudBypassing)) {
            newSettings.dearApprovalDisregardDetectionSettings = getNewApprovalDisregardDetectionSettings(
                savedTemplateSettings,
                templateSettings,
                domain.IntegrationType.Dear
            );
        }

        if (enabledSettings.includes(ESettingsSection.DearFraudChangesAfter)) {
            newSettings.dearPostApprovalChangeDetectionSettings = {
                ...templateSettings.dearPostApprovalChangeDetectionSettings!,
                notifyAdmins: true,
            };
        }

        if (enabledSettings.includes(ESettingsSection.NetSuiteAvailableLineItemType)) {
            newSettings.netSuiteAvailableLineItemType =
                templateSettings.netSuiteAvailableLineItemType || domain.NetSuiteAvailableLineItemType.All;
        }

        if (enabledSettings.includes(ESettingsSection.DearPORejectedNoteToMemo)) {
            newSettings.sendRejectedNoteToDear = templateSettings.sendRejectedNoteToDear;
        }

        if (enabledSettings.includes(ESettingsSection.GoodsReceivedNote)) {
            newSettings.isGrnEnabled = templateSettings.isGrnEnabled;
        }

        if (enabledSettings.includes(ESettingsSection.PostingPreferencesType)) {
            newSettings.postingPreferencesType =
                templateSettings.postingPreferencesType || domain.PostingPreferencesType.AsDraft;
        }

        if (enabledSettings.includes(ESettingsSection.UseRejectedPrefix)) {
            newSettings.useRejectedPrefix = templateSettings.useRejectedPrefix || false;
        }

        if (enabledSettings.includes(ESettingsSection.Terms)) {
            newSettings.terms = templateSettings.terms;
        }

        if (enabledSettings.includes(ESettingsSection.PushApprovalMaxUrlToNetSuite)) {
            newSettings.pushApprovalMaxURLToNetSuite = templateSettings.pushApprovalMaxURLToNetSuite || false;
        }

        if (enabledSettings.includes(ESettingsSection.PriceChecker)) {
            newSettings.isPriceCheckerEnabled = templateSettings.isPriceCheckerEnabled;
        }

        if (enabledSettings.includes(ESettingsSection.CaptureViaEmail)) {
            newSettings.ocrEmailAddress = templateSettings.ocrEmailAddress;
            newSettings.ocrRequestInitialStatus = templateSettings.ocrRequestInitialStatus;
        }

        isCaptureViaEmailEnable
            ? dispatch(applyTemplateSettings(newSettings, fetchAndApplyWorkflow))
            : dispatch(applyTemplateSettings(newSettings));
    }, [
        checkValidFraudBypassing,
        template.integrationCode,
        template.id,
        templateSettings,
        isCaptureViaEmailEnable,
        dispatch,
        fetchAndApplyWorkflow,
        savedTemplateSettings,
        company.integrationId,
        company.id,
        pullIntegrations,
    ]);

    const changeLockDatePolicy = useCallback((newValue: domain.TemplateSettingsLockDatePolicy) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            lockDatePolicySettings: {
                lockDatePolicy: newValue,
            },
        }));
    }, []);

    const changeSafetyCatchPolicy = useCallback((newValue: domain.TemplateSettingsAutoDecisionPolicyType) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            autoDecisionPolicySettings: {
                autoDecisionPolicy: newValue,
            },
        }));
    }, []);

    const changeDecisionPolicy = useCallback((newValue: domain.TemplateSettingsDecisionPolicyType) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            approveAllStepsEnabled: newValue,
        }));
    }, []);

    const changeHistoryPushingType = useCallback((newValue: domain.HistoryPushingType) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            historyEventsPushingSettings: { historyEventsPushingType: newValue },
        }));
    }, []);

    const changeSupplierBankAccountRequired = useCallback((newValue: boolean) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            isSupplierBankAccountRequired: newValue,
        }));
    }, []);

    const changeUrlOverridingPolicy = useCallback((newValue: boolean) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            appUrlOverridingEnabled: newValue,
        }));
    }, []);

    const changeMatchingSettings = useCallback((newValue: boolean) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            billMatchingSettings: {
                ...prevTemplateSettings.billMatchingSettings!,
                manualMatchingEnabled: newValue,
            },
        }));
    }, []);

    const changeNotMatchedApproval = useCallback((newValue: domain.CompanyMatchingSettingsBillApprovalPolicy) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            billMatchingSettings: {
                ...prevTemplateSettings.billMatchingSettings!,
                allowApprovalOfNotMatchedBills: newValue,
            },
        }));
    }, []);

    const changeAutoMarkingPOAsBilledEnabled = useCallback((newValue: boolean) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            purchaseOrderMatchingSettings: {
                ...prevTemplateSettings.purchaseOrderMatchingSettings!,
                autoMarkingPurchaseOrderAsBilledEnabled: newValue,
            },
        }));
    }, []);

    const changeAutoUnmarkingPOAsBilledEnabled = useCallback((newValue: boolean) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            purchaseOrderMatchingSettings: {
                ...prevTemplateSettings.purchaseOrderMatchingSettings!,
                autoUnmarkingPurchaseOrderAsBilledEnabled: newValue,
            },
        }));
    }, []);

    const changeApprovalDisregardEnabled = useCallback((newValue: boolean) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            approvalDisregardDetectionSettings: {
                ...prevTemplateSettings.approvalDisregardDetectionSettings!,
                enabled: newValue,
            },
        }));
    }, []);

    const changeApprovalDisregardNotifyAdmins = useCallback((newValue: boolean) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            approvalDisregardDetectionSettings: {
                ...prevTemplateSettings.approvalDisregardDetectionSettings!,
                notifyAdmins: newValue,
            },
        }));
    }, []);

    const changeApprovalDisregardEffectiveDate = useCallback((newValue: string) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            approvalDisregardDetectionSettings: {
                ...prevTemplateSettings.approvalDisregardDetectionSettings!,
                effectiveDate: newValue,
            },
        }));
    }, []);

    const changeQBooksApprovalDisregardEffectiveDate = useCallback((newValue: string) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            qBooksApprovalDisregardDetectionSettings: {
                ...prevTemplateSettings.qBooksApprovalDisregardDetectionSettings!,
                effectiveDate: newValue,
            },
        }));
    }, []);

    const changeQBooksApprovalDisregardEnabled = useCallback((newValue: boolean) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            qBooksApprovalDisregardDetectionSettings: {
                ...prevTemplateSettings.qBooksApprovalDisregardDetectionSettings!,
                enabled: newValue,
            },
        }));
    }, []);

    const changeQBooksPostApprovalTarget = useCallback(
        (newTargets: domain.TemplateSettingsQBooksPostApprovalChangeDetectionTarget[]) => {
            setTemplateSettings((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                qBooksPostApprovalChangeDetectionSettings: {
                    ...prevTemplateSettings.qBooksPostApprovalChangeDetectionSettings!,
                    targets: newTargets,
                },
            }));
        },
        []
    );

    const changePostApprovalTarget = useCallback(
        (newTargets: domain.TemplateSettingsPostApprovalChangeDetectionTarget[]) => {
            setTemplateSettings((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                postApprovalChangeDetectionSettings: {
                    ...prevTemplateSettings.postApprovalChangeDetectionSettings!,
                    targets: newTargets,
                },
            }));
        },
        []
    );

    const changeLineItemAvailableType = useCallback((newValue: domain.TemplateSettingsQBooksEnabledLineItemType) => {
        setTemplateSettings((prevTemplateSettings) => ({
            ...prevTemplateSettings,
            lineItemSettings: {
                qbooksEnabledLineItemType: newValue,
            },
        }));
    }, []);

    const changeNetSuiteAvailableLineItemType = useCallback(
        (newValue: domain.NetSuiteAvailableLineItemType) => {
            setTemplateSettings((current) => ({
                ...current,
                netSuiteAvailableLineItemType: newValue,
            }));
        },
        [setTemplateSettings]
    );

    const changeNetSuiteAmaxUrlSetting = useCallback((newValue: boolean) => {
        setTemplateSettings((current) => ({
            ...current,
            pushApprovalMaxURLToNetSuite: newValue,
        }));
    }, []);

    const changePostingPreferencesType = useCallback((newValue: domain.PostingPreferencesType) => {
        setTemplateSettings((current) => ({
            ...current,
            postingPreferencesType: newValue,
        }));
    }, []);

    const changeUseRejectedPrefix = useCallback((newValue: boolean) => {
        setTemplateSettings((current) => ({
            ...current,
            useRejectedPrefix: newValue,
        }));
    }, []);

    const changeOcrEmailAddress = useCallback((newValue: string) => {
        setTemplateSettings((current) => ({
            ...current,
            ocrEmailAddress: newValue,
        }));
    }, []);

    const changeOcrRequestInitialStatus = useCallback((newValue: domain.RequestStatusV2) => {
        setTemplateSettings((current) => ({
            ...current,
            ocrRequestInitialStatus: newValue,
        }));
    }, []);

    const changeInstructionSettings = useCallback((newValue: string) => {
        setTemplateSettings((prevState) => ({
            ...prevState,
            requesterInstructionSettings: {
                instruction: newValue,
            },
        }));
    }, []);

    const changeEmailToSupplierState = useCallback((newValue: domain.TemplateSettingsEmailToSupplierState) => {
        setTemplateSettings((prevState) => ({
            ...prevState,
            supplierEmailSettings: {
                ...prevState.supplierEmailSettings,
                state: newValue,
            },
        }));
    }, []);

    const changeEmailToSupplierCC = useCallback((newValue: string[]) => {
        setTemplateSettings((prevState) => ({
            ...prevState,
            supplierEmailSettings: {
                ...prevState.supplierEmailSettings,
                cc: newValue,
            },
        }));
    }, []);

    const changeEmailToSupplierSubject = useCallback((newValue: string) => {
        setTemplateSettings((prevState) => ({
            ...prevState,
            supplierEmailSettings: {
                ...prevState.supplierEmailSettings,
                subject: newValue,
            },
        }));
    }, []);

    const changeEmailToSupplierBody = useCallback((newValue: string) => {
        setTemplateSettings((prevState) => ({
            ...prevState,
            supplierEmailSettings: {
                ...prevState.supplierEmailSettings,
                body: newValue,
            },
        }));
    }, []);

    const changeTerms = useCallback((newValue: string) => {
        setTemplateSettings((current) => ({
            ...current,
            terms: newValue,
        }));
    }, []);

    const showSafetyCatchSetting = company.betaFeatures.includes(domain.CompanyBetaFeature.SafetyCatch);

    const canShowFeaturesPromo =
        (company.integration?.integrationType === domain.IntegrationType.Xero ||
            company.integration?.integrationType === domain.IntegrationType.QBooks) &&
        isAllFeaturesTrialStartAllowed(company, company.integration?.integrationType);

    const isQboBillMatchingAvailable = selectors.company.getQBOMatchingIsAvailable(company);

    const isXeroPOMatchingAvailable = company.flags.isXeroMatchingV2;
    const isXeroBillMatchingAvailable = company.flags.isXeroMatchingV2 && templateSettings.billMatchingSettings;

    return (
        <>
            <Popup.Header
                title={title}
                actions={
                    <Button
                        color='blue10'
                        onClick={applyAndClose}
                        title={buttonTitle}
                        disabled={readonly || isSaveDisabled}
                        size='medium'
                        data-qa={qa('apply-and-close-button')}
                    >
                        {buttonText}
                    </Button>
                }
            />

            <Popup.Body>
                <Flex inline spacing='32'>
                    {isCaptureViaEmailEnable && (
                        <SectionCaptureViaEmail
                            templateSettings={templateSettings}
                            readonly={readonly}
                            links={links}
                            onChangeOcrEmailAddress={changeOcrEmailAddress}
                            onChangeOcrRequestInitialStatus={changeOcrRequestInitialStatus}
                            qa={qa}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.LockDate) && (
                        <SectionLockDate
                            qa={qa}
                            company={company}
                            links={links}
                            template={template}
                            templateSettings={templateSettings}
                            onChangeLockDatePolicy={changeLockDatePolicy}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.PostingPreferencesType) && (
                        <SectionPostingPreferencesType
                            qa={qa}
                            links={links}
                            templateSettings={templateSettings}
                            readonly={readonly}
                            onChange={changePostingPreferencesType}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.SafetyCatch) && showSafetyCatchSetting && (
                        <SectionSafetyCatch
                            qa={qa}
                            links={links}
                            template={template}
                            templateSettings={templateSettings}
                            onChangeSafetyCatchPolicy={changeSafetyCatchPolicy}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.LineItemSettings) && (
                        <SectionLineItemSettings
                            qa={qa}
                            links={links}
                            templateSettings={templateSettings}
                            onChangeLineItemAvailableType={changeLineItemAvailableType}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.QBooksFraudBypassing) && (
                        <SectionQboFraudBypassing
                            qa={qa}
                            links={links}
                            settings={templateSettings.qBooksApprovalDisregardDetectionSettings}
                            template={template}
                            savedTemplateSettings={savedTemplateSettings}
                            effectiveDateLimits={effectiveDateLimits}
                            checkValidFraudBypassing={checkValidFraudBypassing}
                            onChangeApprovalDisregardEffectiveDate={changeQBooksApprovalDisregardEffectiveDate}
                            onChangeApprovalDisregardEnabled={changeQBooksApprovalDisregardEnabled}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.QBooksFraudChangesAfter) && (
                        <SectionQboFraudChangesAfter
                            qa={qa}
                            company={company}
                            settings={templateSettings.qBooksPostApprovalChangeDetectionSettings}
                            template={template}
                            onChangePostApprovalTarget={changeQBooksPostApprovalTarget}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.FraudBypassing) && (
                        <SectionFraudBypassing
                            company={company}
                            template={template}
                            settings={templateSettings.approvalDisregardDetectionSettings}
                            savedTemplateSettings={savedTemplateSettings}
                            effectiveDateLimits={effectiveDateLimits}
                            checkValidFraudBypassing={checkValidFraudBypassing}
                            onChangeApprovalDisregardEnabled={changeApprovalDisregardEnabled}
                            onChangeApprovalDisregardNotifyAdmins={changeApprovalDisregardNotifyAdmins}
                            onChangeApprovalDisregardEffectiveDate={changeApprovalDisregardEffectiveDate}
                            qa={qa}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.NetSuiteFraudBypassing) && (
                        <SectionNetSuiteFraudBypassing
                            template={template}
                            templateSettings={templateSettings}
                            savedTemplateSettings={savedTemplateSettings}
                            effectiveDateLimits={effectiveDateLimits}
                            checkValidFraudBypassing={checkValidFraudBypassing}
                            onChange={setTemplateSettings}
                            qa={qa}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.DearFraudBypassing) && (
                        <SectionDearFraudBypassing
                            template={template}
                            links={links}
                            templateSettings={templateSettings}
                            savedTemplateSettings={savedTemplateSettings}
                            effectiveDateLimits={effectiveDateLimits}
                            checkValidFraudBypassing={checkValidFraudBypassing}
                            onChange={setTemplateSettings}
                            qa={qa}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.FraudChangesAfter) && (
                        <SectionFraudChangesAfter
                            company={company}
                            settings={templateSettings.postApprovalChangeDetectionSettings}
                            onChangePostApprovalTarget={changePostApprovalTarget}
                            qa={qa}
                            readonly={readonly}
                        />
                    )}

                    {templateSettings.netSuitePostApprovalChangeDetectionSettings &&
                        enabledSettings.includes(ESettingsSection.NetSuiteExpenseReportFraudChangesAfter) && (
                            <SectionNetSuiteExpenseReportFraudChangesAfter
                                company={company}
                                templateSettings={templateSettings}
                                onChange={setTemplateSettings}
                                qa={qa}
                                readonly={readonly}
                            />
                        )}

                    {templateSettings.netSuitePostApprovalChangeDetectionSettings &&
                        enabledSettings.includes(ESettingsSection.NetSuiteFraudChangesAfter) && (
                            <SectionNetSuiteFraudChangesAfter
                                company={company}
                                integrationCode={template.integrationCode}
                                templateSettings={templateSettings}
                                onChange={setTemplateSettings}
                                qa={qa}
                                readonly={readonly}
                            />
                        )}

                    {templateSettings.dearPostApprovalChangeDetectionSettings &&
                        enabledSettings.includes(ESettingsSection.DearFraudChangesAfter) && (
                            <SectionDearFraudChangesAfter
                                links={links}
                                company={company}
                                templateSettings={templateSettings}
                                onChange={setTemplateSettings}
                                qa={qa}
                                readonly={readonly}
                            />
                        )}

                    {enabledSettings.includes(ESettingsSection.HistoryPushingType) && (
                        <SectionHistoryPushing
                            settings={templateSettings.historyEventsPushingSettings}
                            onChangeHistoryPushingType={changeHistoryPushingType}
                            qa={qa}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.XeroBillMatching) && (
                        <>
                            {isXeroBillMatchingAvailable && (
                                <SectionXeroBillMatching
                                    templateSettings={templateSettings}
                                    qa={qa}
                                    readonly={readonly}
                                    onChange={setTemplateSettings}
                                    company={company}
                                />
                            )}

                            {!isXeroBillMatchingAvailable && canShowFeaturesPromo && (
                                <SectionXeroBillMatching promoView company={company} />
                            )}
                        </>
                    )}

                    {enabledSettings.includes(ESettingsSection.XeroPOMatching) && (
                        <>
                            {isXeroPOMatchingAvailable && (
                                <SectionXeroPOMatching
                                    templateSettings={templateSettings}
                                    onChangeAutoMarkingPOAsBilledEnabled={changeAutoMarkingPOAsBilledEnabled}
                                    onChangeAutoUnmarkingPOAsBilledEnabled={changeAutoUnmarkingPOAsBilledEnabled}
                                    qa={qa}
                                    readonly={readonly}
                                />
                            )}

                            {!isXeroPOMatchingAvailable && canShowFeaturesPromo && (
                                <SectionXeroPOMatching promoView company={company} />
                            )}
                        </>
                    )}

                    {enabledSettings.includes(ESettingsSection.Instruction) && (
                        <SectionInstructionForRequester
                            template={template}
                            instruction={templateSettings.requesterInstructionSettings?.instruction || ''}
                            links={links}
                            onChangeInstructionSettings={changeInstructionSettings}
                            readonly={readonly}
                            qa={qa}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.EmailToSupplierSetting) && (
                        <SectionEmailToSupplier
                            qa={qa}
                            company={company}
                            links={links}
                            template={template}
                            onChangeStatus={changeEmailToSupplierState}
                            onChangeCC={changeEmailToSupplierCC}
                            onChangeSubject={changeEmailToSupplierSubject}
                            onChangeBody={changeEmailToSupplierBody}
                            supplierEmailSettings={templateSettings.supplierEmailSettings}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.Terms) && (
                        <SectionTerms
                            qa={qa}
                            onChangeTerms={changeTerms}
                            templateSettings={templateSettings}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.DecisionPolicy) && (
                        <SectionDecisionPolicy
                            links={links}
                            settings={templateSettings.approveAllStepsEnabled}
                            onChangeDecisionPolicy={changeDecisionPolicy}
                            qa={qa}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.OverrideGoToBtnInXero) && (
                        <SectionUrlOverriding
                            isAppUrlOverridingEnabled={templateSettings.appUrlOverridingEnabled}
                            onChangeUrlOverridingPolicy={changeUrlOverridingPolicy}
                            qa={qa}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.UseRejectedPrefix) && (
                        <SectionUseRejectedPrefix
                            templateSettings={templateSettings}
                            readonly={readonly}
                            links={links}
                            onChange={changeUseRejectedPrefix}
                            qa={qa}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.QBooksBillMatching) && (
                        <>
                            {isQboBillMatchingAvailable && (
                                <SectionQboBillMatching
                                    templateSettings={templateSettings}
                                    onChangeMatchingSettings={changeMatchingSettings}
                                    onChangeNotMatchedApproval={changeNotMatchedApproval}
                                    readonly={readonly}
                                    qa={qa}
                                />
                            )}

                            {!isQboBillMatchingAvailable && canShowFeaturesPromo && (
                                <SectionQboBillMatching promoView company={company} />
                            )}
                        </>
                    )}

                    {enabledSettings.includes(ESettingsSection.SupplierBankAccountRequired) && (
                        <SectionSupplierBankAccountRequired
                            isSupplierBankAccountRequired={Boolean(templateSettings.isSupplierBankAccountRequired)}
                            onChangeSupplierBankAccountRequired={changeSupplierBankAccountRequired}
                            qa={qa}
                            links={links}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.NetSuiteAvailableLineItemType) && (
                        <SectionNetSuiteAvailableLineItemType
                            templateSettings={templateSettings}
                            qa={qa}
                            readonly={readonly}
                            onChange={changeNetSuiteAvailableLineItemType}
                            learnMoreLink={links[selectors.types.Link.LineItems]}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.DearPORejectedNoteToMemo) && (
                        <SectionDearPORejectedNoteToMemo
                            templateSettings={templateSettings}
                            onChange={setTemplateSettings}
                            readonly={readonly}
                            qa={qa}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.GoodsReceivedNote) && (
                        <>
                            {isGrnAvailable(company) && (
                                <SectionGoodsReceivedNote
                                    templateSettings={templateSettings}
                                    onChange={setTemplateSettings}
                                    links={links}
                                    readonly={readonly}
                                    qa={qa}
                                />
                            )}

                            {!isGrnAvailable(company) && canShowFeaturesPromo && (
                                <SectionGoodsReceivedNote promoView company={company} links={links} />
                            )}
                        </>
                    )}

                    {enabledSettings.includes(ESettingsSection.PushApprovalMaxUrlToNetSuite) && (
                        <SectionNetSuiteAmaxUrl
                            templateSettings={templateSettings}
                            qa={qa}
                            learnMoreLink={links[selectors.types.Link.AmaxUrlFieldUpdate]}
                            onChange={changeNetSuiteAmaxUrlSetting}
                            readonly={readonly}
                        />
                    )}

                    {enabledSettings.includes(ESettingsSection.PriceChecker) && (
                        <SectionPriceChecker
                            templateSettings={templateSettings}
                            onChange={setTemplateSettings}
                            readonly={readonly}
                            qa={qa}
                            links={links}
                        />
                    )}
                </Flex>
            </Popup.Body>
        </>
    );
});

WorkflowSettingsPopupContent.displayName = 'WorkflowSettingsPopupContent';

export default WorkflowSettingsPopupContent;
