import { FC, memo } from 'react';

import { Navigation } from '../Navigation/Navigation';
import { useRequestsLinkItems } from './AllRequestsMenu.hooks';
import { AllRequestsMenuProps } from './AllRequestsMenu.types';

export const AllRequestsMenu: FC<AllRequestsMenuProps> = memo((props) => {
    const { company, onClick } = props;

    const requestsLinkItems = useRequestsLinkItems(company);

    return <Navigation items={requestsLinkItems} onClick={onClick} />;
});

AllRequestsMenu.displayName = 'AllRequestsMenu';
