import { FC, memo } from 'react';

import { Root } from './List.styles';
import { ListProps } from './List.types';

/**
 * Container for render `Tabs.Tab` components
 */
const List: FC<ListProps> = memo((props) => {
    const { bordered = true, overflowX = 'auto', children, ...restProps } = props;

    return (
        <Root $bordered={bordered} $overflowX={overflowX} role='tablist' {...restProps}>
            {children}
        </Root>
    );
});

export default List;
