import * as common from 'modules/common';
import { useSelector } from 'react-redux';
import { routingService } from 'services/routing';
import { getPath, Path } from 'urlBuilder';

export const useNoCompanies = () => {
    const noCompanies = useSelector(common.selectors.company.getCompanies).length === 0;

    if (noCompanies) {
        routingService.push(getPath(Path.noCompanies));
    }

    return noCompanies;
};
