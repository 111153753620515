import styled from 'styled-components';

import { font } from '../../../styles';

export const Title = styled.h1`
    ${font(16, '#333', 'semibold')}
    margin-top: 30px;
`;

export const Description = styled.div`
    ${font(12, '#000')}
    margin-top: 20px;
    text-align: center;
`;

export const Actions = styled.div`
    margin-top: 30px;
`;
