import { compareHelpers, typeGuardHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { asMutable } from 'modules/immutable';

import FilterType from './FilterType';

export interface UserListFilter {
    type: FilterType.UserList;
    emails: string[];
}

export interface UserListByIdFilter {
    type: FilterType.UserIdsList;
    ids: string[];
}

export const createUserListFilter = (...emails: string[]): UserListFilter => {
    return {
        type: FilterType.UserList,
        emails,
    };
};

export const createUserListByIdFilter = (...ids: string[]): UserListByIdFilter => {
    return {
        type: FilterType.UserIdsList,
        ids,
    };
};

export const createAlwaysTrueFilter = () => {
    return createUserListFilter();
};

export const createAlwaysTrueFilterById = () => {
    return createUserListByIdFilter();
};

export const parseFilter = (filterAnswer: any): UserListFilter => {
    return {
        type: FilterType.UserList,
        emails: filterAnswer || [],
    };
};

export const parseByIdFilter = (filterAnswer: any): UserListByIdFilter => {
    return {
        type: FilterType.UserIdsList,
        ids: filterAnswer || [],
    };
};

export const getFilterTransfer = (filter: UserListFilter): any => {
    return filter.emails.length > 0 ? filter.emails : null;
};

export const getFilterByIdTransfer = (filter: UserListByIdFilter): any => {
    return filter.ids.length > 0 ? filter.ids : null;
};

export const getFilterPreviewText = (
    filter: UserListFilter,
    companyTeam: selectors.types.ExpandedCompanyUser[]
): string => {
    return asMutable(filter.emails)
        .map((userEmail) => companyTeam.find((u) => u.userEmail === userEmail))
        .filter((x) => x)
        .map((x) => x!.displayName)
        .sort(compareHelpers.stringComparator2AscI)
        .join(', ');
};

export const getFilterByIdPreviewText = (
    filter: UserListByIdFilter,
    companyTeam: selectors.types.ExpandedCompanyUser[]
): string => {
    return asMutable(filter.ids)
        .map((userId) => companyTeam.find((user) => user.databaseId === userId))
        .filter(typeGuardHelpers.isTruthy)
        .map((user) => user.displayName)
        .sort(compareHelpers.stringComparator2AscI)
        .join(', ');
};
