import { Action, ActionMeta, ThunkAction } from '../types/Action';

export interface TypedCreateSelectingActionFn<TState, TEntities> {
    <T extends string, P, M extends ActionMeta>(
        type: T,
        payload: (state: TState) => P,
        entities?: TEntities,
        meta?: M,
        error?: Error
    ): ThunkAction<TState, Action<T, P, M, TEntities>> & {
        $actions: Action<T, P, M, TEntities>;
    };
}

// eslint-disable-next-line max-params
export function createSelectingAction<T extends string, P, M extends ActionMeta, TState, TEntities>(
    type: T,
    payload: (state: TState) => P,
    entities?: TEntities,
    meta?: M,
    error?: Error
): ThunkAction<TState, Action<T, P, M, TEntities>> & {
    $actions: Action<T, P, M, TEntities>;
} {
    let result: ThunkAction<TState, Action<T, P, M, TEntities>> = (dispatch, getState) => {
        let result: any = {
            type,
            payload: payload(getState()),
        };

        if (entities) {
            result.entities = entities;
        }

        if (meta) {
            result.meta = meta;
        }

        if (error) {
            result.error = error;
        }

        dispatch(result);

        return result;
    };

    // cast to avoid settings the $actions pseudoproperty
    return result as any;
}
