import { atom } from 'recoil';

import { BulkCreationPopupContentState } from './BulkCreationPopupContent.types';

export const bulkCreationPopupOpenState = atom({
    key: 'bulkCreationPopupOpenState',
    default: false,
});

export const bulkCreationPopupContentState = atom<BulkCreationPopupContentState>({
    key: 'bulkCreationPopupContentState',
    default: {
        companyId: '',
        templateIntegrationCode: null,
    },
});
