import { useIsMutating } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useCounter } from 'react-use';
import { useApiActiveOperations } from 'services/api/hooks';

export const useIsSaving = () => {
    const [activeOperationsCount, { dec, inc }] = useCounter(0);
    const activeMutationsCount = useIsMutating();

    useApiActiveOperations(
        useCallback(
            ({ type }) => {
                switch (type) {
                    case 'increase':
                        inc();
                        break;

                    case 'decrease':
                        dec();
                        break;
                }
            },
            [dec, inc]
        )
    );

    const isSaving = activeMutationsCount > 0;
    const isSavingLegacy = activeOperationsCount > 0;

    return isSaving || isSavingLegacy;
};
