import { useMutation } from '@tanstack/react-query';
import { backend, schemas } from 'modules/data';
import { useSetRecoilState } from 'recoil';
import { api } from 'services/api';
import { fileUploadTimestampsState } from 'shared/states';

import { UseUploadAttachmentOptions } from './useUploadAttachment.types';

export const useUploadAttachment = (options: UseUploadAttachmentOptions = {}) => {
    const setFileUploadTimestamps = useSetRecoilState(fileUploadTimestampsState);

    return useMutation(
        async ({ file }) => {
            setFileUploadTimestamps((value) => [...value, new Date().toISOString()]);

            const formData = new FormData();

            formData.append('file', file);

            return api.postFormData('requests/attachFile', formData).then((response: backend.AttachmentListAnswer) => ({
                data: response.Attachments.map(schemas.request.mapAttachment),
                dataBackend: response,
            }));
        },
        {
            ...options,
        }
    );
};
