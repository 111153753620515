import { domain } from 'modules/data';

export const xeroWorkflowsWithReviewStep = [
    domain.IntegrationCode.XeroBill,
    domain.IntegrationCode.XeroPo,
    domain.IntegrationCode.XeroContact,
    domain.IntegrationCode.XeroInvoice,
    domain.IntegrationCode.XeroManualJournal,
    domain.IntegrationCode.XeroQuote,
];
