import { oDropdownPanel } from '@approvalmax/ui';
import styled from 'styled-components';

export const InfoIconContainer = styled.div`
    width: 12px;
    height: 12px;
    cursor: pointer;

    & > svg {
        width: 12px;
        height: 12px;
    }
`;

export const DropdownPanel = styled.div`
    ${oDropdownPanel()}
`;
