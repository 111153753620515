import { Guid } from '@approvalmax/types';
import { schemas } from 'modules/data';
import { normalize } from 'normalizr';
import { api } from 'services/api';

export async function connectToIntegration(options: { callbackUrl: string }) {
    const response = await api.companies.enableXeroOAuth2Integration({
        callbackUrl: options.callbackUrl,
    });

    return {
        redirectUrl: response.AuthorizeUrl,
    };
}

export async function completeIntegrationAuth(options: { companyId: Guid; state: string; tenantId: string }) {
    const result = await api.companies.finishXeroIntegration({
        companyId: options.companyId,
        state: options.state,
        realmId: options.tenantId,
    });
    const entities = normalize(result.Companies[0], schemas.companySchemaLegacy).entities as any;

    return {
        entities,
    };
}
