import { useCallback, useState } from 'react';

import { useTwoFaActionWizardContext } from '../../TwoFaActionWizard.context';
import { TwoFaActionWizardContext } from '../../TwoFaActionWizard.types';

export const useMethodSelectPopup = () => {
    const { setActiveScreen } = useTwoFaActionWizardContext();

    const [method, setMethod] = useState<TwoFaActionWizardContext['activeScreen']>('authenticatorAppCode');

    const onSubmit = useCallback(() => {
        setActiveScreen(method);
    }, [method, setActiveScreen]);

    return {
        method,
        onChange: setMethod,
        onSubmit,
    };
};
