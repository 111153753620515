import { MatchingRequester } from 'modules/common/selectors/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SHOW_MATCHING_PO_REQUESTERS_POPUP } from '../../../actions';
export const MATCHING_PO_REQUESTERS_POPUP = 'MATCHING_PO_REQUESTERS_POPUP';

export interface MatchingPORequestersPopupData {
    id: typeof MATCHING_PO_REQUESTERS_POPUP;
    requesters: MatchingRequester[];
}

export type MatchingPORequestersPopupType = ImmutableObject<MatchingPORequestersPopupData> | null;

export default function (
    state: MatchingPORequestersPopupType,
    action: Action
): MatchingPORequestersPopupType | MatchingPORequestersPopupData {
    switch (action.type) {
        case SHOW_MATCHING_PO_REQUESTERS_POPUP:
            return immutable<MatchingPORequestersPopupData>({
                id: MATCHING_PO_REQUESTERS_POPUP,
                requesters: action.payload.requesters,
            });

        default:
            return state;
    }
}
