import { errorHelpers } from '@approvalmax/utils';
import { useEffect } from 'react';

import { redirectAndNotify } from '../helpers/redirectAndNotify';

export const usePageError = (pageError?: Error | null, pathToRedirect?: string) => {
    useEffect(() => {
        if (pageError) {
            errorHelpers.captureException(pageError);
            redirectAndNotify({ pathToRedirect });
        }
    }, [pageError, pathToRedirect]);
};
