import { Guid } from '@approvalmax/types';
import { createAction, ExtractActions } from 'modules/react-redux';

export const SHOW_XERO_REQUEST_BUDGET_POPUP = 'REQUESTLIST/SHOW_XERO_REQUEST_BUDGET_POPUP';
export const showXeroRequestBudgetPopup = (requestId: Guid, budgetId: Guid) =>
    createAction(SHOW_XERO_REQUEST_BUDGET_POPUP, {
        requestId,
        budgetId,
    });

export type Action = ExtractActions<typeof showXeroRequestBudgetPopup>;
