import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { hasChangesState } from 'shared/states';

export const useInitHistoryBlock = () => {
    const history = useHistory();
    const hasChanges = useRecoilValue(hasChangesState);

    useEffect(() => {
        const unblock = history.block(() => (hasChanges ? '' : undefined));

        return () => {
            unblock();
        };
    }, [hasChanges, history]);
};
