import { selectors } from 'modules/common';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { mobileAppService } from 'services/mobileApp';
import { storageService } from 'services/storage';

import { initAnonymousExternalServices } from '../helpers/initAnonymousExternalServices';
import { initExternalServices } from '../helpers/initExternalServices';

export const useServicesAfterFetchingContext = () => {
    const authenticated = useSelector(selectors.session.authenticated);
    const contextInitializationCompleted = useSelector(selectors.session.contextInitializationCompleted);
    const isTwoFaHardEnforcementPage = storageService.getIsTwoFaHardEnforcement();

    useEffect(() => {
        if (contextInitializationCompleted) {
            if (authenticated && !isTwoFaHardEnforcementPage) {
                initExternalServices();
            } else {
                initAnonymousExternalServices();
            }

            mobileAppService.checkAndRedirectToMobile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextInitializationCompleted]);
};
