import { mods, theme } from '@approvalmax/theme';
import { Grid } from '@approvalmax/ui/src/components';
import type { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const InputBox = styled(Grid)<StyledMods<{ sticky?: boolean }>>`
    ${mods('sticky', true)`
        background-color: ${theme.color.white100};
        position: sticky;
        top: 0;
        z-index: 1;
    `}
`;
