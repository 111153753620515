import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SELECT_XERO_DELIVERY_ADDRESS, SHOW_XERO_CONTACT_ADDRESS_SEARCH_POPUP } from '../../../actions';

export const XERO_CONTACT_ADDRESS_SEARCH_POPUP = 'XERO_CONTACT_ADDRESS_SEARCH_POPUP';

export interface XeroContactAddressSearchPopupData {
    id: typeof XERO_CONTACT_ADDRESS_SEARCH_POPUP;
}

export type XeroContactAddressSearchPopupType = ImmutableObject<XeroContactAddressSearchPopupData> | null;

export default function (state: XeroContactAddressSearchPopupType, action: Action): XeroContactAddressSearchPopupType {
    switch (action.type) {
        case SHOW_XERO_CONTACT_ADDRESS_SEARCH_POPUP:
            return immutable<XeroContactAddressSearchPopupData>({
                id: XERO_CONTACT_ADDRESS_SEARCH_POPUP,
            });

        case SELECT_XERO_DELIVERY_ADDRESS:
            return null;

        default:
            return state;
    }
}
