import { toast } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { defineMessages } from 'react-intl';
import { routingService } from 'services/routing';
import { getDefaultPath } from 'urlBuilder';

const i18nPrefix = 'page.hooks.usePageError';
const messages = defineMessages({
    pageError: {
        id: `${i18nPrefix}.pageError`,
        defaultMessage: 'Page load error.',
    },
});

export const redirectAndNotify = (options?: { pathToRedirect?: string; errorMessage?: string }) => {
    toast.error(options?.errorMessage || intl.formatMessage(messages.pageError));

    routingService.push(options?.pathToRedirect || getDefaultPath());
};
