import { ExpandedCompany } from 'modules/common/selectors/types';
import { domain } from 'modules/data';

export const isOcrEnableForCompany = (company: ExpandedCompany) => {
    if (
        company.integration?.integrationType === domain.IntegrationType.NetSuite &&
        company.betaFeatures.includes(domain.CompanyBetaFeature.CaptureNetSuiteBill)
    ) {
        return true;
    }

    if (
        company.integration?.integrationType === domain.IntegrationType.Xero &&
        (company.betaFeatures.includes(domain.CompanyBetaFeature.XeroOcr) ||
            company.betaFeatures.includes(domain.CompanyBetaFeature.CaptureXeroSalesInvoice) ||
            company.licenseFeatures.includes(domain.CompanyLicenseFeature.Capture))
    ) {
        return true;
    }

    if (
        company.integration?.integrationType === domain.IntegrationType.QBooks &&
        company.betaFeatures.includes(domain.CompanyBetaFeature.CaptureQBOBill)
    ) {
        return true;
    }

    return false;
};
