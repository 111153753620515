/**
 * Developer: Stepan Burguchev
 * Date: 6/16/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { Guid } from '@approvalmax/types';
import { errorHelpers, intl, objectHelpers } from '@approvalmax/utils';
import map from 'lodash/map';
import { domain, stateTree } from 'modules/data';
import { getSelectArgument1, getSelectArgument2, getSelectArgument3 } from 'modules/react-redux';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { defineMessages } from 'react-intl';
import { createSelector } from 'reselect';

import { getCompanies, getExpiredTrialCompanies, getTrialCompaniesExpiringIn14Days } from './companySelectors';
import { ExpandedCompany, ExpandedSubscription, ExpandedUser } from './types';
import { getUsers } from './userSelectors';

const i18nPrefix = 'common/selectors/licenseSelectors';
const messages = defineMessages({
    billingNameAnnual: {
        id: `${i18nPrefix}.billingNameAnnual`,
        defaultMessage: 'Yearly',
    },
    billingNameBiAnnually: {
        id: `${i18nPrefix}.billingNameBiAnnually`,
        defaultMessage: 'Bi-Yearly',
    },
    billingNameMonthly: {
        id: `${i18nPrefix}.billingNameMonthly`,
        defaultMessage: 'Monthly',
    },
    billingNameQuarterly: {
        id: `${i18nPrefix}.billingNameQuarterly`,
        defaultMessage: 'Quarterly',
    },
    billingNameOneTime: {
        id: `${i18nPrefix}.billingNameOneTime`,
        defaultMessage: 'One time',
    },
    planNameRetired: {
        id: `${i18nPrefix}.planNameRetired`,
        defaultMessage: 'Retired',
    },
    planNameProfessional: {
        id: `${i18nPrefix}.planNameProfessional`,
        defaultMessage: 'ApprovalMax Professional',
    },
    planNameXeroPremium: {
        id: `${i18nPrefix}.planNameXeroPremium`,
        defaultMessage: 'ApprovalMax for Xero Premium',
    },
    planNameQBooksPremium: {
        id: `${i18nPrefix}.planNameQBooksPremium`,
        defaultMessage: 'ApprovalMax for QuickBooks Online Premium',
    },
    planNameNetSuitePremium: {
        id: `${i18nPrefix}.planNameNetSuitePremium`,
        defaultMessage: 'ApprovalMax for NetSuite Premium',
    },
    planNameXeroAdvanced: {
        id: `${i18nPrefix}.planNameXeroAdvanced`,
        defaultMessage: 'ApprovalMax for Xero Advanced',
    },
    planNameQBooksAdvanced: {
        id: `${i18nPrefix}.planNameQBooksAdvanced`,
        defaultMessage: 'ApprovalMax for QuickBooks Online Advanced',
    },
    planNameNetSuiteAdvanced: {
        id: `${i18nPrefix}.planNameNetSuiteAdvanced`,
        defaultMessage: 'ApprovalMax for NetSuite Advanced',
    },
    planNameXero: {
        id: `${i18nPrefix}.planNameXero`,
        defaultMessage: 'ApprovalMax for Xero Standard',
    },
    planNameQBooks: {
        id: `${i18nPrefix}.planNameQBooks`,
        defaultMessage: 'ApprovalMax for QuickBooks Online Standard',
    },
    planNameNetSuite: {
        id: `${i18nPrefix}.planNameNetSuite`,
        defaultMessage: 'ApprovalMax for NetSuite Standard',
    },
    planNameTrial: {
        id: `${i18nPrefix}.planNameTrial`,
        defaultMessage: 'ApprovalMax Professional Trial',
    },
    planNamePartner: {
        id: `${i18nPrefix}.planNamePartner`,
        defaultMessage: 'ApprovalMax for Partners',
    },
    planNamePaymentRequired: {
        id: `${i18nPrefix}.planNamePaymentRequired`,
        defaultMessage: 'ApprovalMax Payment Required',
    },
});

type State = stateTree.State;

function getNextBillingInDays(subscription: domain.Subscription) {
    if (!subscription.nextBillingDate) {
        return null;
    }

    const nextBillingDate = moment.utc(subscription.nextBillingDate);
    const now = moment.utc();
    const days = Math.floor(moment.duration(nextBillingDate.diff(now)).as('days')) + 1;

    return Math.max(0, days);
}

function getGraceEndsInDays(subscription: domain.Subscription) {
    if (!subscription.dueDate || subscription.status !== domain.SubscriptionStatus.Grace) {
        return null;
    }

    const GRACE_DURATION = 14;
    const nextBillingDate = moment.utc(subscription.dueDate).add(GRACE_DURATION, 'days');
    const now = moment.utc();
    const days = Math.floor(moment.duration(nextBillingDate.diff(now)).as('days')) + 1;

    return Math.max(0, days);
}

function getBillingCycleName(billing: domain.SubscriptionBillingCycle) {
    switch (billing) {
        case domain.SubscriptionBillingCycle.Annually:
            return intl.formatMessage(messages.billingNameAnnual);

        case domain.SubscriptionBillingCycle.Monthly:
            return intl.formatMessage(messages.billingNameMonthly);

        case domain.SubscriptionBillingCycle.OneTime:
            return intl.formatMessage(messages.billingNameOneTime);

        case domain.SubscriptionBillingCycle.BiAnnually:
            return intl.formatMessage(messages.billingNameBiAnnually);

        case domain.SubscriptionBillingCycle.Quarterly:
            return intl.formatMessage(messages.billingNameQuarterly);

        default:
            return errorHelpers.assertNever(billing);
    }
}

export function getPlanShortName(plan: domain.CompanyPlan) {
    switch (plan) {
        case domain.CompanyPlan.Professional:
            return intl.formatMessage(messages.planNameProfessional);

        case domain.CompanyPlan.XeroPremium:
            return intl.formatMessage(messages.planNameXeroPremium);

        case domain.CompanyPlan.QBooksPremium:
            return intl.formatMessage(messages.planNameQBooksPremium);

        case domain.CompanyPlan.NetSuitePremium:
            return intl.formatMessage(messages.planNameNetSuitePremium);

        case domain.CompanyPlan.XeroAdvanced:
            return intl.formatMessage(messages.planNameXeroAdvanced);

        case domain.CompanyPlan.QBooksAdvanced:
            return intl.formatMessage(messages.planNameQBooksAdvanced);

        case domain.CompanyPlan.NetSuiteAdvanced:
            return intl.formatMessage(messages.planNameNetSuiteAdvanced);

        case domain.CompanyPlan.Xero:
            return intl.formatMessage(messages.planNameXero);

        case domain.CompanyPlan.QBooks:
            return intl.formatMessage(messages.planNameQBooks);

        case domain.CompanyPlan.NetSuite:
            return intl.formatMessage(messages.planNameNetSuite);

        case domain.CompanyPlan.Trial:
            return intl.formatMessage(messages.planNameTrial);

        case domain.CompanyPlan.PaymentRequired:
            return intl.formatMessage(messages.planNamePaymentRequired);

        case domain.CompanyPlan.Partner:
            return intl.formatMessage(messages.planNamePartner);

        case domain.CompanyPlan.Retired:
            return intl.formatMessage(messages.planNameRetired);

        case domain.CompanyPlan.ReceiptBankProfessional:
            return intl.formatMessage({
                id: `${i18nPrefix}.ReceiptBankProfessional`,
                defaultMessage: 'Dext Prepare Professional',
            });

        case domain.CompanyPlan.ReceiptBankQuickBooksOnlineStandard:
            return intl.formatMessage({
                id: `${i18nPrefix}.ReceiptBankQuickBooksOnlineStandard`,
                defaultMessage: 'Dext Prepare QuickBooks Online Standard',
            });

        case domain.CompanyPlan.ReceiptBankQuickBooksOnlineAdvanced:
            return intl.formatMessage({
                id: `${i18nPrefix}.ReceiptBankQuickBooksOnlineAdvanced`,
                defaultMessage: 'Dext Prepare QuickBooks Online Advanced',
            });

        case domain.CompanyPlan.ReceiptBankQuickBooksOnlinePremium:
            return intl.formatMessage({
                id: `${i18nPrefix}.ReceiptBankQuickBooksOnlinePremium`,
                defaultMessage: 'Dext Prepare QuickBooks Online Premium',
            });

        case domain.CompanyPlan.ReceiptBankXeroStandard:
            return intl.formatMessage({
                id: `${i18nPrefix}.ReceiptBankXeroStandard`,
                defaultMessage: 'Dext Prepare Xero Standard',
            });

        case domain.CompanyPlan.ReceiptBankXeroAdvanced:
            return intl.formatMessage({
                id: `${i18nPrefix}.ReceiptBankXeroAdvanced`,
                defaultMessage: 'Dext Prepare Xero Advanced',
            });

        case domain.CompanyPlan.ReceiptBankXeroPremium:
            return intl.formatMessage({
                id: `${i18nPrefix}.ReceiptBankXeroPremium`,
                defaultMessage: 'Dext Prepare Xero Premium',
            });

        case domain.CompanyPlan.SmallBusinessPackage:
            return intl.formatMessage({
                id: `${i18nPrefix}.SmallBusinessPackage`,
                defaultMessage: 'ApprovalMax Small Business Package',
            });

        case domain.CompanyPlan.Cin7CorePremium:
            return intl.formatMessage({
                id: `${i18nPrefix}.Cin7CorePremium`,
                defaultMessage: 'ApprovalMax Cin7 Core Premium',
            });

        default:
            return errorHelpers.assertNever(plan);
    }
}

export function getSubscriptionDisplayName(subscription: domain.Subscription, systemName: string) {
    let name = getPlanShortName(subscription.plan);
    let billing = getBillingCycleName(subscription.billingCycle);

    return `${name} ${billing} (${systemName})`;
}

export function isActivePaidSubscription(subscription: domain.Subscription) {
    return (
        (subscription.status === domain.SubscriptionStatus.Active ||
            subscription.status === domain.SubscriptionStatus.Grace ||
            subscription.status === domain.SubscriptionStatus.PendingCancellation) &&
        subscription.plan !== domain.CompanyPlan.Retired
    );
}

const expandSubscription: (
    subscription: domain.Subscription,
    companies: ExpandedCompany[],
    users: ExpandedUser[]
) => ExpandedSubscription = createCachedSelector(
    getSelectArgument1<domain.Subscription>(),
    getSelectArgument2<ExpandedCompany[]>(),
    getSelectArgument3<ExpandedUser[]>(),
    (subscription, companies, users) => {
        const isActivePaid = isActivePaidSubscription(subscription);
        const accountOwner = users.find((u) => u.id === subscription.accountOwnerId)!;
        const systemName = `AMS-${subscription.systemId}`;
        const shortName = getPlanShortName(subscription.plan);
        const displayName = getSubscriptionDisplayName(subscription, systemName);
        const graceEndsInDays = getGraceEndsInDays(subscription);
        const nextBillingInDays = getNextBillingInDays(subscription);
        const companies2 = subscription.companyIds.map((cId) => companies.find((c) => c.id === cId)!);
        const result: ExpandedSubscription = {
            ...subscription,
            systemName,
            displayName,
            accountOwner,
            graceEndsInDays,
            shortName,
            nextBillingInDays,
            isGrace: subscription.status === domain.SubscriptionStatus.Grace,
            isActivePaid,
            companies: companies2,
        };

        return result;
    }
)((license: domain.Subscription) => license.id);

export function findCompanySubscription(state: State, companyId: Guid): ExpandedSubscription | null {
    return getAllSubscriptions(state).find((s) => s.companyIds.includes(companyId)) || null;
}

export const getAllSubscriptions: (state: State) => ExpandedSubscription[] = createSelector(
    (state: State) => state.entities.subscriptions || objectHelpers.emptyObject(),
    (state: State) => getCompanies(state),
    (state: State) => getUsers(state),
    (subscriptions, companies, users) => {
        return map(subscriptions, (l) => expandSubscription(l, companies, users));
    }
);

export function getSubscriptionById(state: State, id: string): ExpandedSubscription | null {
    const subscription = getAllSubscriptions(state).find((s) => s.id === id);

    if (!subscription) {
        return null;
    }

    return subscription;
}

/**
 * Checks if there is an active paid subscription that:
 * - can be used to add new companies
 * - can be prolongated
 */
export function hasActivePaidSubscription(state: State) {
    return getAllSubscriptions(state).some((s) => s.isActivePaid);
}

/**
 * Returns grace subscriptions which are related to companies where I'm a manager.
 */
export function getGraceSubscriptions(state: State) {
    return getAllSubscriptions(state).filter(
        (l) => l.status === domain.SubscriptionStatus.Grace && l.companies.some((c) => c.flags.isManager)
    );
}

/**
 * Returns expired subscriptions which are related to companies where I'm a manager.
 */
export function getExpiredSubscriptions(state: State) {
    return getAllSubscriptions(state).filter(
        (l) => l.status === domain.SubscriptionStatus.Expired && l.companies.some((c) => c.flags.isManager)
    );
}

export function hasLicensingProblems(state: State) {
    return (
        getTrialCompaniesExpiringIn14Days(state).length > 0 ||
        getExpiredTrialCompanies(state).length > 0 ||
        [...getGraceSubscriptions(state), ...getExpiredSubscriptions(state)].some((l) => l.companies.length > 0)
    );
}
