import { Reference } from '@approvalmax/types';
import { selectors } from 'modules/common';
import moment from 'moment';

export const printReferenceCell = (value: Reference | null) => {
    if (!value) {
        return '';
    }

    return value.text;
};

export const printDateCell = (value: string) => {
    // Server format: 2018-06-09
    return moment(value).format('ll');
};

export const printDateTimeCell = (value: string) => {
    // Server format: 2018-06-09 16:26:56
    return moment(value, 'YYYY-MM-DD HH:mm:ss').format('lll');
};

export const printUserCell = (user: selectors.types.ExpandedUser) => user.displayName;

export const printCellNoop = (value: string) => value;

export const printOutstandingCell = (value: { text: string; days: number }) => value.text;
