import { errorHelpers } from '@approvalmax/utils';
import { delay, put, takeEvery } from 'redux-saga/effects';

import { $addToast, $removeToast, ADD_TOAST } from '../actions';

export default function* () {
    yield takeEvery(ADD_TOAST, function* (action: ReturnType<typeof $addToast>): any {
        try {
            yield delay(action.payload.timeout);
            yield put($removeToast(action.payload.id));
        } catch (e) {
            errorHelpers.captureException(e);
        }
    });
}
