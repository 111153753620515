import { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data';

import { companiesApiPaths } from '../paths';
import {
    UseGetCompaniesRequestParams,
    UseGetCompaniesResponse,
    UseGetCompaniesResponseBackend,
} from './useGetCompanies.types';

export const useGetCompanies = (
    params: UseGetCompaniesRequestParams,
    queryOptions?: QueryOptions<UseGetCompaniesResponseBackend, UseGetCompaniesResponse>
) => {
    return useGetRequestLegacy<UseGetCompaniesResponseBackend, UseGetCompaniesResponse>(
        companiesApiPaths.select,
        params,
        {
            queryOptions,
        }
    );
};
