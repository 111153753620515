import { messages } from './NetSuiteVendorCreationConditionCell.messages';
import { ConditionTypeValue, conditionTypeValues } from './NetSuiteVendorCreationConditionCell.types';

export const conditionItems: { id: ConditionTypeValue; name: string }[] = [
    {
        id: conditionTypeValues.AllVendors,
        name: messages.anyExistingVendorDropdownText,
    },
    {
        id: conditionTypeValues.ExactValues,
        name: messages.contactsExactValuesDropdownText,
    },
    {
        id: conditionTypeValues.NegativeExactValues,
        name: messages.contactsNotExactValuesDropdownText,
    },
];
