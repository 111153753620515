import { Button, Field, font, oPopupPadding, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled(Popup.DefaultContent)`
    width: 520px;
`;

export const Content = styled.div`
    ${oPopupPadding()}
    display: flex;
    flex-flow: column;
`;

export const Title = styled.div`
    ${font(16, '#000', 'semibold')}
    margin-top: 30px;
`;

export const Description = styled.div`
    ${font(13, '#000', 'standard')}
    margin-top: 20px;
`;

export const CodeField = styled(Field)`
    margin-top: 20px;
`;

export const ButtonNext = styled(Button)`
    margin-top: 30px;
`;
