import { PolymorphicPropsWithRef } from '@approvalmax/types';
import {
    Children,
    type ElementType,
    type ForwardedRef,
    forwardRef,
    type KeyboardEventHandler,
    memo,
    useCallback,
    useContext,
    useMemo,
    useRef,
} from 'react';

import { ChevronDownIcon } from '../../../../icons';
import ConditionWrapper from '../../../ConditionWrapper/ConditionWrapper';
import { Tooltip } from '../../../Tooltip/Tooltip';
import { MenuContext } from '../../Menu.context';
import { List } from '../List/List';
import { useAnimation, useItem } from './Item.hooks';
import {
    AnimatedBox,
    ClickableLayer,
    Content,
    EndIcon,
    EndText,
    Inner,
    Label,
    Labels,
    OpenIcon,
    Root,
    StartIcon,
    SubItems,
    SubLabel,
} from './Item.styles';
import { ItemComponent, ItemProps } from './Item.types';

/* The Item component allows you to create lists with clickable items */
export const Item = memo(
    forwardRef(
        <Component extends ElementType = 'button'>(
            props: PolymorphicPropsWithRef<Component, ItemProps>,
            ref: ForwardedRef<HTMLLIElement>
        ) => {
            const {
                subItems,
                name,
                subName,
                endText,
                startIcon,
                endIcon,
                onClick,
                children,
                open,
                divider,
                color = 'silver80',
                size = 'medium',
                id,
                dataItemActive,
                onOpen,
                as,
                active,
                selected,
                disabled,
                title,
                tooltip,
                tooltipProps,
                tabIndex,
                disableFocusVisible,
                ...restProps
            } = props;

            const { 'data-qa': dataQa, 'data-qa-name': dataQaName } = restProps;

            const itemContextProps = useContext(MenuContext);
            const { isOpenSubItems, handleClickLabel } = useItem({ open, onClick, id, onOpen });
            const hasSubItems = useMemo(
                () => Boolean(subItems?.items || Children.toArray(children).filter(Boolean).length),
                [children, subItems?.items]
            );
            const As = as || 'button';
            const buttonRef = useRef<HTMLButtonElement | null>(null);
            const { subItemsRef, subItemsStyles } = useAnimation(isOpenSubItems);

            const handleKeyDown = useCallback<KeyboardEventHandler<HTMLLIElement>>(
                (event) => {
                    if (!disableFocusVisible && event.key === 'Enter') {
                        // don't want to stop propagation here, but we have to do this
                        // because we are listening not on a child button but on a parent
                        // thus, event will be drilled up and will affect parent
                        // (in subitems case)
                        event.stopPropagation();

                        buttonRef.current?.click();
                    }
                },
                [disableFocusVisible]
            );

            return (
                <Root
                    title={tooltip ? undefined : title}
                    data-id={id}
                    data-item-active={dataItemActive}
                    ref={ref}
                    $divider={itemContextProps.divider || divider}
                    $color={itemContextProps.color || color}
                    $size={itemContextProps.size || size}
                    $open={isOpenSubItems}
                    $active={active}
                    $selected={selected}
                    $disabled={disabled}
                    aria-selected={selected}
                    aria-disabled={disabled}
                    aria-expanded={hasSubItems ? isOpenSubItems : undefined}
                    aria-haspopup={hasSubItems ? 'menu' : undefined}
                    role='presentation'
                    data-qa={`${dataQa}__container`}
                    data-qa-name={`${dataQaName}__container`}
                    tabIndex={tabIndex}
                    onKeyDown={handleKeyDown}
                >
                    <ConditionWrapper
                        condition={tooltip}
                        wrapperIf={(children) => (
                            <Tooltip activator={children} {...tooltipProps}>
                                {title}
                            </Tooltip>
                        )}
                    >
                        <Inner>
                            {startIcon && <StartIcon>{startIcon}</StartIcon>}

                            <Content>
                                <Labels>
                                    <Label aria-labelledby='name'>{name}</Label>

                                    {subName && <SubLabel>{subName}</SubLabel>}
                                </Labels>

                                {endText && <EndText $disabled={disabled}>{endText}</EndText>}
                            </Content>

                            {endIcon && <EndIcon>{endIcon}</EndIcon>}

                            {hasSubItems && (
                                <OpenIcon>
                                    <ChevronDownIcon size={20} />
                                </OpenIcon>
                            )}

                            {disabled ? (
                                <ClickableLayer {...restProps} disabled={disabled} role='menuitem' />
                            ) : (
                                <ClickableLayer
                                    {...restProps}
                                    as={As}
                                    ref={buttonRef}
                                    role='menuitem'
                                    onClick={handleClickLabel}
                                />
                            )}
                        </Inner>
                    </ConditionWrapper>

                    {hasSubItems && (
                        <AnimatedBox style={subItemsStyles}>
                            <SubItems ref={subItemsRef}>
                                {subItems ? <List {...subItems} role='group' tabIndex={-1} /> : children}
                            </SubItems>
                        </AnimatedBox>
                    )}
                </Root>
            );
        }
    )
) as ItemComponent;

Item.displayName = 'Item';
