import { ApiCallOptions, ApiHandler } from '../types';

export default class ReportsApi {
    constructor(private _apiHandler: ApiHandler) {}

    private _doApiCall(
        data: any,
        action: string,
        method: ApiCallOptions['method'],
        allowsAnonymous: boolean
    ): Promise<any> {
        return this._apiHandler.doApiCall({
            apiSource: 'webApp',
            data: data,
            action: action,
            method: method,
            allowsAnonymous: allowsAnonymous,
        });
    }

    /**
     * @deprecated Use {@link useGetReportRows}
     */
    public get(transfer: { companyId: string; reportId: string }): Promise<any> {
        return this._doApiCall(transfer, `reports/get`, 'POST', false);
    }

    /**
     * @deprecated Use {@link useGetReports}
     */
    public select(transfer: { companyId: string; types: string[] }): Promise<any> {
        return this._doApiCall(transfer, `reports/select`, 'POST', false);
    }

    public execute(transfer: { companyId: string; reportId: string; settings: any }): Promise<any> {
        return this._doApiCall(transfer, `reports/execute`, 'POST', false);
    }

    public executeById(transfer: { companyId: string; reportId: string }): Promise<any> {
        return this._doApiCall(transfer, `reports/executeById`, 'POST', false);
    }

    public edit(transfer: { companyId: string; reportId: string; settings: any }): Promise<any> {
        return this._doApiCall(transfer, `reports/edit`, 'POST', false);
    }

    public create(transfer: { companyId: string; settings: any }): Promise<any> {
        return this._doApiCall(transfer, `reports/create`, 'POST', false);
    }

    public delete(transfer: { companyId: string; reportId: string }): Promise<any> {
        return this._doApiCall(transfer, `reports/delete`, 'POST', false);
    }

    public exportByConfig(transfer: { reportId: string }): string {
        return `${this._apiHandler.getApiUrlPrefix()}reports/export?reportId=${transfer.reportId}`;
    }
}
