import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('old.components.RichEditor', {
    boldTitle: 'Bold (Ctrl/Cmd+B)',
    italicTitle: 'Italic (Ctrl/Cmd+I)',
    underlineTitle: 'Underline (Ctrl/Cmd+U)',
    orderedListTitle: 'Number list (Ctrl/Cmd+Shift+7)',
    bulletListTitle: 'Bullet list (Ctrl/Cmd+Shift+8)',
    eraserTitle: 'Clear formatting',
});
