import {
    UseRequestTFADisablingEmailCodeResponse,
    UseRequestTFADisablingEmailCodeResponseBackend,
} from './useRequestTFADisablingEmailCode.types';

export const mapData = (
    data: UseRequestTFADisablingEmailCodeResponseBackend
): UseRequestTFADisablingEmailCodeResponse => ({
    stateId: data.StateId,
});
