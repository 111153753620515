import { domHelpers, hooks } from '@approvalmax/utils';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import CheckboxEditor from '../../form/editors/checkbox/CheckboxEditor';
import Button from '../button/Button';
import { messages } from './BackupCodes.messages';
import { Actions, BackupCode, CheckboxText, Codes, CopyButton, DownloadButton } from './BackupCodes.styles';
import { BackupCodesProps } from './BackupCodes.types';

const BackupCodes: FC<BackupCodesProps> = memo((props) => {
    const { isLoading, backupCodes, showInfoToast, onDone } = props;
    const [agreed, setAgreed] = useState(false);
    const backupCodesData = useMemo(
        () =>
            (backupCodes || [])?.map((backupCode) =>
                typeof backupCode === 'string'
                    ? {
                          code: backupCode,
                          isUsed: false,
                      }
                    : backupCode
            ),
        [backupCodes]
    );

    const copyToClipboard = hooks.useCopyToClipboard();

    const handleCopy = useCallback(() => {
        const content = backupCodesData.map((bc) => bc.code).join('\n');

        copyToClipboard(content, 'backupCodes', () => {
            showInfoToast?.(messages.copiedToast);
        });
    }, [backupCodesData, copyToClipboard, showInfoToast]);

    const onDownload = useCallback(() => {
        const content = backupCodesData.map((bc) => bc.code).join('\n');

        domHelpers.downloadBlob([content], {
            fileName: messages.fileName,
            type: 'text/plain',
            extension: 'txt',
        });
    }, [backupCodesData]);

    return (
        <>
            <Codes>
                {isLoading
                    ? Array(10)
                          .fill(null)
                          .map((v, i) => <Skeleton key={i} borderRadius={5} height='100%' />)
                    : backupCodesData?.map((backupCode) => (
                          <BackupCode key={backupCode.code} $disabled={backupCode.isUsed}>
                              {backupCode.code.replace(/(\d{4})(\d{4})/, '$1 $2')}
                          </BackupCode>
                      ))}
            </Codes>

            <Actions>
                <DownloadButton disabled={isLoading} execute={onDownload}>
                    {messages.downloadButton}
                </DownloadButton>

                <CopyButton disabled={isLoading} execute={handleCopy}>
                    {messages.copy}
                </CopyButton>
            </Actions>

            {onDone && (
                <>
                    <CheckboxEditor disabled={isLoading} value={agreed} onChange={setAgreed}>
                        <CheckboxText>{messages.checkboxText}</CheckboxText>
                    </CheckboxEditor>

                    <Button execute={onDone} disabled={!agreed || isLoading}>
                        {messages.doneButton}
                    </Button>
                </>
            )}
        </>
    );
});

export default BackupCodes;
