import {
    Action,
    ADD_USERS,
    CANCEL_ACTIVE_POPUP,
    INVITE_USERS,
    SET_OPTIMISTIC_DELEGATE,
    SHOW_ADD_USERS_POPUP,
    SHOW_INVITE_USERS_POPUP,
    SHOW_OFFBOARD_USER_POPUP,
    SHOW_SET_DELEGATE_POPUP,
} from '../../actions';

export enum PageActivePopup {
    None = 'None',
    AddUsersPopup = 'AddUsersPopup',
    InviteUsersPopup = 'InviteUsersPopup',
    SetDelegatePopup = 'SetDelegatePopup',
    OffboardUserPopup = 'OffboardUserPopup',
}

export interface AddUsersPopupData {
    type: typeof PageActivePopup.AddUsersPopup;
}

export interface SetDelegatePopupData {
    type: typeof PageActivePopup.SetDelegatePopup;
    userId: string;
}

export interface OffboardUserPopupData {
    type: typeof PageActivePopup.OffboardUserPopup;
    userEmail: string;
}

export interface InviteUsersPopupData {
    type: typeof PageActivePopup.InviteUsersPopup;
    users: string[];
}

export type ActivePopup =
    | InviteUsersPopupData
    | AddUsersPopupData
    | SetDelegatePopupData
    | OffboardUserPopupData
    | undefined;

const INITIAL_STATE = undefined;

export default (state: ActivePopup = INITIAL_STATE, action: Action): ActivePopup => {
    switch (action.type) {
        case SHOW_INVITE_USERS_POPUP:
            return {
                type: PageActivePopup.InviteUsersPopup,
                users: action.payload.users.map((x) => x.id),
            };

        case SHOW_ADD_USERS_POPUP:
            return {
                type: PageActivePopup.AddUsersPopup,
            };

        case SHOW_SET_DELEGATE_POPUP:
            return {
                type: PageActivePopup.SetDelegatePopup,
                userId: action.userId,
            };

        case SHOW_OFFBOARD_USER_POPUP:
            return {
                type: PageActivePopup.OffboardUserPopup,
                userEmail: action.payload.userEmail,
            };

        case CANCEL_ACTIVE_POPUP:
        case INVITE_USERS:
        case SET_OPTIMISTIC_DELEGATE:
        case ADD_USERS:
            return undefined;

        default:
            return state;
    }
};
