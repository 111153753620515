import { ApiBaseAnswer, MutationOptions, RequestDataParams } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import type { UseDeleteAmaxPayOpenBankingBankAccountsPathParams } from './useDeleteAmaxPayOpenBankingBankAccounts.types';

export const useDeleteAmaxPayOpenBankingBankAccounts = (
    mutationOptions: MutationOptions<
        ApiBaseAnswer,
        RequestDataParams<{}, UseDeleteAmaxPayOpenBankingBankAccountsPathParams>
    > = {}
) => {
    const queryClient = useQueryClient();

    return useMutateLegacy<RequestDataParams<{}, UseDeleteAmaxPayOpenBankingBankAccountsPathParams>>(
        paymentServicesApiPaths.crudAmaxPayOpenBankingBankAccounts,
        {
            mutationOptions: {
                ...mutationOptions,
                onSuccess: (...args) => {
                    mutationOptions.onSuccess?.(...args);

                    void queryClient.invalidateQueries([
                        routerHelpers.pathToUrl(
                            paymentServicesApiPaths.amaxPayOpenBankingBankAccounts,
                            args[1].pathParams
                        ),
                    ]);
                },
            },
            method: 'delete',
        }
    );
};
