import { ApiBaseAnswer, MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UseRespondToInvitationData } from './useRespondToInvitation.types';

export const useRespondToInvitation = (
    mutationOptions?: MutationOptions<ApiBaseAnswer, RequestDataParams<UseRespondToInvitationData>>
) => {
    return useMutateLegacy<RequestDataParams<UseRespondToInvitationData>>(companiesApiPaths.respondToInvitation, {
        mutationOptions,
    });
};
