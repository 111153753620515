import moment from 'moment/moment';

export const isBetweenDates = (
    from?: moment.Moment | string | null,
    to?: moment.Moment | string | null,
    checkDate = moment(),
    inclusiveCheck = false
): boolean => {
    const momentFrom = moment(from);
    const momentTo = moment(to);

    if (from && to) {
        return checkDate.isBetween(momentFrom, momentTo, undefined, inclusiveCheck ? '[]' : '()');
    }

    if (from && !to) {
        return checkDate.isAfter(momentFrom);
    }

    if (!from && to) {
        return checkDate.isBefore(momentTo);
    }

    return false;
};
