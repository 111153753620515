import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';

export function getActivePageId(state: stateTree.State) {
    return state.navigation.activePageId;
}

export function getInnerActivePageId(state: stateTree.State) {
    return state.navigation.innerActivePageId;
}

export function getActiveCompanyId(state: stateTree.State) {
    return state.navigation.activeCompany;
}

export function getActiveCompany(state: stateTree.State): selectors.types.ExpandedCompany {
    let companyId = state.navigation.activeCompany;

    if (!companyId) {
        throw errorHelpers.throwNotFoundError('Active company not found');
    }

    return selectors.company.getCompanyById(state, companyId);
}

export function getLoaded(state: stateTree.State) {
    return Boolean(state.navigation.activePageId);
}

export function getDiscardChangesPopupData(state: stateTree.State) {
    return state.navigation.discardChangesPopup;
}
