import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.TwoFaEnablingWizard.StepCodeVerify', {
    title: 'Two-factor authentication setup',
    subtitle: 'Enter the code from the Authenticator app',
    description: `Enter the 6-digit code generated by the Authenticator app. {br}
    If you experience problems with entering this code, please refer to our <supportlink>support article</supportlink>.`,
    wrongCodeErrorText: 'Wrong code',
    authenticatorCodeLabel: 'Authenticator code',
    authenticatorCodePlaceholder: 'Enter the Authenticator code...',
    errorSetupVerificationCode: 'Wrong code',
});
