import { SortOrder } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';

export interface AddBillInvoicesPopupProps {
    companyId: string;
    requestId: string;
    handleAddBills: (selectableBills: domain.BatchPaymentSelectableBillItem[]) => void;
    billBatchPayment: domain.XeroAmaxPayBatchPayment | domain.XeroAirwallexBatchPayment | domain.XeroBillBatchPayment;
    sortingConfig: domain.AddBillsToBatchSortingConfig;
    changeSortingConfig: (columnId: string, newSortOrder: SortOrder) => void;
    resetFilter: () => void;
    data: domain.BatchPaymentSelectableBillItem[] | undefined;
    isLoading: boolean;
    attentionText: string;
    showTotalAmountOfSelectedBills?: boolean;
}

export interface UseSelectableBills {
    billBatchPayment: domain.XeroAmaxPayBatchPayment | domain.XeroAirwallexBatchPayment | domain.XeroBillBatchPayment;
    excludeBillsWithZeroAmount: domain.AddBillsToBatchFilterData['excludeBillsWithZeroAmount'];
}

export enum BillsListType {
    List = 'List',
    GroupedBySupplier = 'GroupedBySupplier',
}
