import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.StepSubmitterSection', {
    createInApprovalMax: 'Create in ApprovalMax',
    description: 'Add users who are authorised to create Purchase Orders and submit them for approval in ApprovalMax.',
    descriptionEmpty:
        'Add at least one user who is authorised to create Purchase Orders and submit them for approval in ApprovalMax.',
    addRequester: 'Add a Requester',
    selectRequester: 'Select a Requester',
    openMatrixTitle: 'Define the Requester rights in the Requester matrix',
});
