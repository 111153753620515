import { PartyImage } from '@approvalmax/ui';
import { Button, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { getTemplateDisplayNameByCode } from 'modules/common/selectors/templateSelectors';
import { TemplateRequestListFilter } from 'pages/requestList/config';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routingService } from 'services/routing';
import { getPath, Path } from 'urlBuilder';

import { closeActiveHappyActivationPopup } from '../../actions';
import { getActiveHappyActivation, getActiveTemplate } from '../../selectors/pageSelectors';
import { messages } from './HappyWorkflowActivationPopup.messages';

const HappyWorkflowActivationPopup = memo(() => {
    const dispatch = useDispatch();
    const data = useSelector(getActiveHappyActivation);
    const activeTemplate = useSelector(getActiveTemplate);
    const template = data?.template && selectors.template.expandTemplate(data.template);

    const goToWorkflowRequests = useCallback(() => {
        dispatch(closeActiveHappyActivationPopup());

        if (data) {
            routingService.push(
                getPath(
                    Path.requestTemplateList,
                    activeTemplate?.companyId || data.template.companyId,
                    activeTemplate?.id || data.template.id,
                    TemplateRequestListFilter.OnApproval
                )
            );
        }
    }, [dispatch, data, activeTemplate?.companyId, activeTemplate?.id]);

    const onToggle = useCallback(
        (open: boolean) => {
            if (!open) {
                dispatch(closeActiveHappyActivationPopup());

                if (data) {
                    routingService.push(
                        getPath(
                            Path.workflow,
                            activeTemplate?.companyId || data.template.companyId,
                            activeTemplate?.id || data.template.id
                        )
                    );
                }
            }
        },
        [activeTemplate?.companyId, activeTemplate?.id, data, dispatch]
    );

    if (!data) return null;

    const description =
        data.template.integrationCode === null
            ? messages.descriptionStandalone({ br: <br />, workflowDisplayName: template?.displayName })
            : messages.descriptionCommon({
                  br: <br />,
                  workflowDisplayName: getTemplateDisplayNameByCode(data.template.integrationCode, true),
              });

    return (
        <Popup open={!!data} onToggle={onToggle} size='xsmall'>
            <Popup.Header />

            <Popup.Body spacing='16 32 32'>
                <Flex inline direction='column' spacing='24' alignItems='center'>
                    <PartyImage width={100} height={100} />

                    <Text font='headline' fontSize='small' fontWeight='medium'>
                        {messages.headerText}
                    </Text>

                    <Text font='body' fontSize='medium' textAlign='center'>
                        {description}
                    </Text>

                    <Button size='large' onClick={goToWorkflowRequests} color='blue80'>
                        {messages.closeButtonText}
                    </Button>
                </Flex>
            </Popup.Body>
        </Popup>
    );
});

export default HappyWorkflowActivationPopup;
