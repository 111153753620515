import { Reference } from '@approvalmax/types';
import { backend, domain, schemas } from 'modules/data';

import { parseQBooksAccount } from './QBooksAccount';
import { parseQBooksTaxCode } from './QBooksTaxCode';

function parseItemDetails(value: backend.IntegrationsQBooksQBOItemDetails | undefined): QBooksItemDetails | undefined {
    if (!value) {
        return undefined;
    }

    return {
        description: value.Description,
        unitPrice: value.UnitPrice,
        account: value.Account ? parseQBooksAccount(value.Account) : undefined,
        taxCode: value.TaxCode ? parseQBooksTaxCode(value.TaxCode) : undefined,
    };
}

export function parseQBooksItem(value: backend.IntegrationsQBooksItem): QBooksItem {
    return {
        id: value.Id,
        text: value.Name,
        code: value.Code,
        isInventory: Boolean(value.IsInventory),
        salesDetails: parseItemDetails(value.SalesDetails),
        purchaseDetails: parseItemDetails(value.PurchaseDetails),
        class: schemas.mapReferenceWithValue(value.Class),
    };
}

export interface QBooksItemDetails {
    description?: string;
    unitPrice?: number;
    account?: domain.QBooksAccount;
    taxCode?: domain.QBooksTaxCode;
}

export interface QBooksItem extends Reference {
    class: Reference | null;
    code: string;
    isInventory?: boolean;
    salesDetails?: QBooksItemDetails;
    purchaseDetails?: QBooksItemDetails;
}
