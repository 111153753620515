import { ErrorCode } from '@approvalmax/types';
import {
    Cin7DisabledLogo,
    Cin7Logo,
    NetSuiteDisabledLogo,
    NetSuiteLogo,
    QBooksDisabledLogo,
    QBooksLogo,
    XeroDisabledLogo,
    XeroLogo,
} from '@approvalmax/ui';
import { domain } from 'modules/data';
import { useMemo } from 'react';

import { messagesCin7, messagesNetSuite, messagesQBO, messagesXero } from './IntegrationHeader.messages';
import { IntegrationHeaderProps } from './IntegrationHeader.types';

export const useIntegrationParams = (props: {
    integration: IntegrationHeaderProps['company']['integration'];
    disconnected: boolean;
}) => {
    const { integration, disconnected } = props;

    return useMemo(() => {
        switch (integration?.integrationType) {
            case domain.IntegrationType.Xero:
                return {
                    Icon: disconnected ? XeroDisabledLogo : XeroLogo,
                    title: messagesXero.title,
                    descriptionConnecting: messagesXero.descriptionConnecting,
                };

            case domain.IntegrationType.QBooks:
                return {
                    Icon: disconnected ? QBooksDisabledLogo : QBooksLogo,
                    title: messagesQBO.title,
                    descriptionConnecting: messagesQBO.descriptionConnecting,
                };

            case domain.IntegrationType.Dear:
                return {
                    Icon: disconnected ? Cin7DisabledLogo : Cin7Logo,
                    title: messagesCin7.title,
                    descriptionConnecting: messagesCin7.descriptionConnecting,
                };

            case domain.IntegrationType.NetSuite:
                return {
                    Icon: disconnected ? NetSuiteDisabledLogo : NetSuiteLogo,
                    title: messagesNetSuite.title,
                    descriptionConnecting: messagesNetSuite.descriptionConnecting,
                };

            default:
                return {};
        }
    }, [disconnected, integration?.integrationType]);
};

export const useErrorLink = (props: { integration: IntegrationHeaderProps['company']['integration'] }) => {
    const { integration } = props;

    return useMemo(() => {
        switch (String(integration?.error?.statusCode)) {
            case ErrorCode.E5013_XERO_CALL_RATE_EXCEEDED:
                return 'https://support.approvalmax.com/portal/kb/articles/what-does-xero-api-call-rate-exceeded-mean';

            default:
                return '';
        }
    }, [integration?.error?.statusCode]);
};

export const useCompanyLink = (props: { integration: IntegrationHeaderProps['company']['integration'] }) => {
    const { integration } = props;

    return useMemo(
        () =>
            integration?.externalUrl ? (
                <a href={integration.externalUrl} target='_blank' rel='noopener noreferrer'>
                    {integration.integratedCompanyName}
                </a>
            ) : (
                integration?.integratedCompanyName
            ),
        [integration]
    );
};
