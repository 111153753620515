import { hooks } from '@approvalmax/utils';
import { forwardRef, MouseEventHandler, useRef } from 'react';
import { useMount } from 'react-use';

import { StyledBox } from './TimeBlock.styles';
import type { TimeBlockProps } from './TimeBlock.types';

export const TimeBlock = forwardRef<HTMLLIElement, TimeBlockProps>((props, ref) => {
    const { value, disabled, selected, title, tabIndex, onChange } = props;

    const rootRef = useRef<HTMLLIElement>(null);
    const composedRefs = hooks.useComposedRefs(ref, rootRef);

    useMount(() => {
        selected && rootRef.current?.scrollIntoView({ block: 'start' });
    });

    const handleClick: MouseEventHandler<HTMLLIElement> = (event) => {
        !disabled && onChange(value, { event });
    };

    return (
        <StyledBox
            role='option'
            $disabled={disabled}
            $selected={selected}
            aria-selected={selected}
            data-time={value}
            title={title}
            ref={composedRefs}
            tabIndex={tabIndex}
            onClick={handleClick}
        >
            {value}
        </StyledBox>
    );
});

TimeBlock.displayName = 'TimeBlock';
