import type { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data/api';

import { integrationsApiPaths } from '../paths';
import { UseXeroBankAccountsRequestPathParams, UseXeroBankAccountsResponse } from './useXeroBankAccounts.types';

export const useXeroBankAccounts = (
    pathParams: UseXeroBankAccountsRequestPathParams,
    queryOptions?: QueryOptions<UseXeroBankAccountsResponse>
) =>
    useGetRequestLegacy<UseXeroBankAccountsResponse>(integrationsApiPaths.xeroBankAccounts, pathParams, {
        pathParams,
        queryOptions,
        method: 'get',
    });
