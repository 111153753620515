import { Module } from 'modules/page';

import * as actions from './actions';
import moduleReducer from './reducers/moduleReducer';
import * as selectors from './selectors/moduleSelectors';
export * from './types';

export { actions, selectors };

export const moduleDef: Module = {
    id: 'search',
    moduleReducer: moduleReducer,
};
