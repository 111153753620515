export const getUrlProps = (as?: any, to?: string) => {
    if (!to) {
        return {
            as: 'div',
        };
    }

    return as === 'a'
        ? {
              as,
              href: to,
          }
        : {
              as,
              to,
          };
};
