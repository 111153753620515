import { useCallback } from 'react';
import { useEvent } from 'react-use';
import { useRecoilValue } from 'recoil';
import { fileUploadTimestampsState, fileUploadTimestampsStorageKey } from 'shared/states';

export const useFileUploadTimestampsSync = () => {
    const fileUploadTimestamps = useRecoilValue(fileUploadTimestampsState);

    const beforeUnloadHandler = useCallback(() => {
        localStorage.setItem(fileUploadTimestampsStorageKey, JSON.stringify(fileUploadTimestamps));
    }, [fileUploadTimestamps]);

    useEvent('beforeunload', beforeUnloadHandler);
};
