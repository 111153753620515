import { MentionedUser } from '@approvalmax/types';
import { Editor } from '@tiptap/core';
import { ReactRenderer } from '@tiptap/react';
import { FC } from 'react';
import tippy, { Instance, Props as TippyProps } from 'tippy.js';

import MentionList from '../components/MentionList/MentionList';
import { dataAttributeEmail, dataAttributeName, dataAttributeUserId } from '../mention.constants';

export const createSuggestion = (options: { mentionListComponent?: FC }) => {
    const { mentionListComponent = MentionList } = options;

    return {
        // items function should be passed to RichEditor as prop. It returns list of items matching query.
        // Here is the example:
        // mentionItems={({ query }: any) => {
        //     return team
        //         .map((user) => ({
        //             name: user.displayName,
        //             email: user.userEmail,
        //             avatar: user.avatar,
        //         }))
        //         .filter(
        //             (item) =>
        //                 item.name.toLowerCase().startsWith(query.toLowerCase()) ||
        //                 item.email.toLowerCase().startsWith(query.toLowerCase())
        //         );
        items: () => {
            return [];
        },

        render: () => {
            let component: any;
            let popup: Instance<TippyProps>[];

            return {
                onStart: (props: { editor: Editor; clientRect?: any }) => {
                    component = new ReactRenderer(mentionListComponent, {
                        props,
                        editor: props.editor,
                    });

                    if (!props.clientRect) {
                        return;
                    }

                    popup = tippy('body', {
                        getReferenceClientRect: props.clientRect,
                        appendTo: () => document.body,
                        content: component.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: 'manual',
                        placement: 'bottom-start',
                    });
                },

                onUpdate(props: { clientRect?: any }) {
                    component.updateProps(props);

                    if (!props.clientRect) {
                        return;
                    }

                    popup[0].setProps({
                        getReferenceClientRect: props.clientRect,
                    });
                },

                onKeyDown(props: { event: { key: string } }) {
                    if (props.event.key === 'Escape') {
                        popup[0].hide();

                        return true;
                    }

                    return component.ref?.onKeyDown(props);
                },

                onExit() {
                    popup[0].destroy();
                    component.destroy();
                },
            };
        },
    };
};

export const prepareCommentPayload = (comment: string) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(comment, 'text/html');

    const elements = htmlDoc.querySelectorAll('.mention');

    const mentionedUserIdsUnique = new Set<string>();
    const mentionedUserEmailsUnique = new Set<string>();

    elements.forEach((element) => {
        if (element.hasAttribute(dataAttributeUserId)) {
            const attribute = element.attributes.getNamedItem(dataAttributeUserId);

            if (attribute) {
                mentionedUserIdsUnique.add(attribute.value);
            }
        }

        if (element.hasAttribute(dataAttributeEmail)) {
            const attribute = element.attributes.getNamedItem(dataAttributeEmail);

            if (attribute) {
                mentionedUserEmailsUnique.add(attribute.value);
            }

            element.removeAttribute(dataAttributeEmail);
        }

        if (element.hasAttribute(dataAttributeName)) {
            element.removeAttribute(dataAttributeName);
        }

        if (element.hasAttribute('contenteditable')) {
            element.removeAttribute('contenteditable');
        }
    });

    const updatedCommentText = htmlDoc.body.innerHTML;
    const mentionedUserIds = mentionedUserIdsUnique.size ? [...mentionedUserIdsUnique] : [];
    const mentionedUserEmails = mentionedUserEmailsUnique.size ? [...mentionedUserEmailsUnique] : [];
    const mentionedUsers = mentionedUserIds.map<MentionedUser>((userProfileId, index) => ({
        userProfileId,
        email: mentionedUserEmails[index],
    }));

    return { updatedCommentText, mentionedUsers, mentionedUserIds, mentionedUserEmails };
};
