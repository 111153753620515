import { Route } from 'modules/page';
import React from 'react';

const Page = React.lazy(() => import(/* webpackChunkName: "manager-pages" */ './containers/noCompaniesPage/Page'));

const routes: Route[] = [
    {
        path: '/noCompanies',
        component: Page,
    },
];

export default routes;
