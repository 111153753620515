import { GlobalStylesRD } from '@approvalmax/ui';
import { memo } from 'react';
import { Helmet } from 'react-helmet';

export const Head = memo(() => {
    return (
        <>
            <GlobalStylesRD />

            <Helmet>
                <link rel='preconnect' href='https://fonts.googleapis.com' />

                <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin='anonymous' />

                <link
                    href='https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap'
                    rel='stylesheet'
                />

                <link
                    href='https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'
                    rel='stylesheet'
                />
            </Helmet>
        </>
    );
});

Head.displayName = 'Head';
