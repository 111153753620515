import { forwardRef, ForwardRefExoticComponent, memo, MemoExoticComponent, RefAttributes } from 'react';

import Box from '../Box/Box';
import { Calendar } from '../Calendar/Calendar';
import Dropdown from '../Dropdown/Dropdown';
import { Controller } from './components';
import { useCalendarValue, useCallbackActions, useDatePickerValue, useTextFieldValue } from './DatePicker.hooks';
import { Activator, Icon } from './DatePicker.styles';
import { ChildrenComponents, DatePickerProps } from './DatePicker.types';

/**
 * DatePicker allows you to select a date in an easy-to-use interface
 */
const DatePicker = memo(
    forwardRef((props, ref) => {
        const {
            placement = 'bottom-start',
            onOpen,
            initOpen,
            value,
            onChange,
            onFocus,
            onBlur,
            onClear,
            initFocus,
            view,
            clearable = true,
            minDate,
            maxDate,
            noUTCTransform,
            ...restProps
        } = props;

        const { datePickerValue, changeDatePickerValue } = useDatePickerValue({
            value,
            view,
            onChange,
            noUTCTransform,
        });
        const { calendarValue } = useCalendarValue({ datePickerValue });

        const { textFieldValue, handleTextFieldChange } = useTextFieldValue({
            datePickerValue,
            changeDatePickerValue,
            view,
            minDate,
            maxDate,
            noUTCTransform,
        });
        const {
            handleOnOpen,
            isOpen,
            handleChange,
            handleKeyDown,
            handleOnFocus,
            handleIconClick,
            isFocus,
            handleOnBlur,
            handleOnClear,
        } = useCallbackActions({
            onOpen,
            onFocus,
            onBlur,
            onClear,
            initOpen,
            initFocus,
            changeDatePickerValue,
        });

        return (
            <Dropdown
                activator={
                    <Activator
                        {...restProps}
                        value={textFieldValue}
                        onChange={handleTextFieldChange}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        onClear={handleOnClear}
                        onKeyDown={handleKeyDown}
                        focus={isFocus}
                        clearable={clearable}
                        readOnly={view === 'year'}
                        endIcon={
                            textFieldValue && clearable ? undefined : (
                                <Icon onClick={handleIconClick} color='midnight70' />
                            )
                        }
                        ref={ref}
                    />
                }
                placement={placement}
                open={isOpen}
                onOpen={handleOnOpen}
                maxHeight={0}
                width='max-content'
                isClicking={false}
                floatingFocusManagerProps={{
                    returnFocus: false,
                }}
            >
                <Box spacing='16' width={224 + 32}>
                    <Calendar
                        onChange={handleChange}
                        value={calendarValue}
                        locale='en'
                        maxDetail={view}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </Box>
            </Dropdown>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<DatePickerProps & RefAttributes<HTMLInputElement>>> &
    ChildrenComponents;

DatePicker.displayName = 'DatePicker';
DatePicker.Controller = Controller;

export default DatePicker;
