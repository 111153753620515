import { Button } from '@approvalmax/ui';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    min-width: 322px;
`;

export const BackContainter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
`;

export const BackButton = styled(Button)`
    position: absolute;
    left: 0;
`;
