import { Reference } from '@approvalmax/types';
import { objectHelpers } from '@approvalmax/utils';
import { backend } from 'modules/data';
import { Address } from 'shared/data';

export function parseQBooksAddress(value: backend.IntegrationsAddress): QBooksAddress {
    const addressText = [
        value.AddressLine1,
        value.AddressLine2,
        value.AddressLine3,
        value.AddressLine4,
        value.City,
        value.Region,
        value.PostalCode,
        value.Country,
    ]
        .filter((x) => x && x.trim())
        .map((x) => x.trim())
        .join('\n');
    const type =
        value.AddressType === backend.AddressType.Postal ? QBooksAddressType.Postal : QBooksAddressType.Physical;

    return {
        id: type + addressText,
        text: addressText,
        type,
        address: objectHelpers.pascalCaseToCamelCase(value),
        attentionTo: value.AttentionTo || '',
    };
}

export enum QBooksAddressType {
    Postal = 'Postal',
    Physical = 'Physical',
}

export interface QBooksAddress extends Reference {
    type: QBooksAddressType;
    address: Address;
    attentionTo: string;
}
