import { QueryOptions } from '@approvalmax/data';
import { useGetRequest, UseGetRequestVersionsResponse } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseGetRequestVersionsRequestParams } from './useGetRequestVersions.types';

export const useGetRequestVersions = (
    pathParams: UseGetRequestVersionsRequestParams,
    queryOptions?: QueryOptions<UseGetRequestVersionsResponse>
) => {
    return useGetRequest<UseGetRequestVersionsResponse>(requestsApiPaths.versions, {
        pathParams,
        queryOptions,
        apiVersion: 'v2',
    });
};
