import { LockIcon } from '@approvalmax/ui';
import { Flex, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { Bubble } from './FixedStepContainer.styles';
import { FixedStepContainerProps } from './FixedStepContainer.types';

const FixedStepContainer: FC<FixedStepContainerProps> = memo((props) => {
    const { children, bubbleText, color = 'brandXero100' } = props;

    return (
        <Flex inline width={230} spacing='12' height='fit-content'>
            <Bubble spacing='8' width='100%' $color={color} height={28}>
                <Flex inline spacing='4' justifyContent='space-between' alignItems='center'>
                    <Text font='label' fontSize='small'>
                        {bubbleText}
                    </Text>

                    <LockIcon size={12} />
                </Flex>
            </Bubble>

            {children}
        </Flex>
    );
});

export default FixedStepContainer;
