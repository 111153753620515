import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.UserActionsToolbar.Help', {
    learnMoreTitle: 'Learn more',
    getInTouchTitle: 'Get in touch',

    menuItemNameKnowledgeBase: 'Knowledge base',
    menuItemNameHowToVideos: 'How to videos',
    menuItemNameWebSite: 'ApprovalMax website',
    menuItemNameGetMobileApp: 'Get the mobile App',
    menuItemNameContactSupport: 'Contact Support',
    menuItemNameContactSales: 'Contact Sales',
});
