import { du } from 'modules/data';

import {
    UseGetNetSuiteAmortizationSchedulesBackend,
    UseGetNetSuiteAmortizationSchedulesResponse,
} from './useGetNetSuiteAmortizationSchedules.types';

export const mapData = (
    data: UseGetNetSuiteAmortizationSchedulesBackend
): UseGetNetSuiteAmortizationSchedulesResponse => {
    return data.Values.map(du.parseServerReference);
};
