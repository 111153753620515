/**
 * Developer: Stepan Burguchev
 * Date: 5/19/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { immutable, ImmutableObject, set } from 'modules/immutable';

import {
    Action,
    APPLY_REPORT_FILTERS,
    CHANGE_REPORT_SORTING,
    CREATE_ACCRUAL_REPORT,
    DISCARD_CHANGES,
    DOWNLOAD_AUDIT_REPORTS,
    EXECUTE_REPORT,
    EXPORT_AS_CSV,
    RENAME_ACTIVE_REPORT,
    REORDER_REPORT_COLUMNS,
    SAVE_REPORT_RESPONSE,
    SHOW_DASHBOARD,
    SHOW_NEW_REPORT_CARD,
    SHOW_REPORT_CARD,
    UPDATE_DYNAMIC_REPORT_COLUMNS,
} from '../../actions';
import { ReportConfig, updateDynamicColumns } from '../../data/reportConfig';

export type ActiveReportConfigType = ImmutableObject<ReportConfig> | null;

export default function (state: ActiveReportConfigType = null, action: Action): ActiveReportConfigType {
    switch (action.type) {
        case SHOW_DASHBOARD:
            return null;

        case SHOW_REPORT_CARD:
        case SHOW_NEW_REPORT_CARD:
            return immutable(action.reportConfig);

        case EXECUTE_REPORT:
        case DOWNLOAD_AUDIT_REPORTS:
        case EXPORT_AS_CSV:
        case CREATE_ACCRUAL_REPORT:
            return set(state, 'lastRunDate', action.payload.now);

        case RENAME_ACTIVE_REPORT:
            return set(state, 'name', action.payload.name);

        case DISCARD_CHANGES:
            return immutable(action.prestineReportConfig);

        case SAVE_REPORT_RESPONSE:
            if (action.payload.request.isNew) {
                return set(state, 'id', action.payload.reportConfig.id);
            }

            return state;

        case REORDER_REPORT_COLUMNS: {
            const transformedColumns = [...state!.columns];
            const oldColumn = action.payload.columns[action.payload.oldIndex];
            const newColumn = action.payload.columns[action.payload.newIndex];
            const oldIndex = transformedColumns.indexOf(oldColumn);
            const newIndex = transformedColumns.indexOf(newColumn);

            const movedColumn = transformedColumns[oldIndex];

            transformedColumns.splice(oldIndex, 1);
            transformedColumns.splice(newIndex, 0, movedColumn);

            return set(state, 'columns', transformedColumns);
        }

        case UPDATE_DYNAMIC_REPORT_COLUMNS: {
            if (!state) {
                return state;
            }

            const newReportConfig = updateDynamicColumns(state, {
                standaloneFields: action.standaloneFields,
                qBooksCustomFields: action.qBooksCustomFields,
                xeroTrackingFields: action.xeroTrackingFields,
            });

            return immutable(newReportConfig);
        }

        case CHANGE_REPORT_SORTING: {
            const newColumns = state!.columns.map((c) => ({
                ...c,
                sorting: action.column.kind === c.kind ? action.sorting : null,
            }));

            return set(state, 'columns', newColumns);
        }

        case APPLY_REPORT_FILTERS:
            return immutable(action.reportConfig);

        default:
            return state;
    }
}
