import { ExtractComponentProp } from '@approvalmax/types';
import { Button, Checkbox, Flex, Popup, Spacing, Tabs, toast } from '@approvalmax/ui/src/components';
import { miscHelpers } from '@approvalmax/utils';
import { actions } from 'modules/automation';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { clearCacheStorage } from 'modules/data-providers';
import { useDispatch, useSelector } from 'modules/react-redux';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { HelpStripe } from 'shared/components';

import { discardCopyTemplatePopup } from '../../actions';
import { getActiveCopyTemplate, getActiveTemplate } from '../../selectors/pageSelectors';
import { checkCopySettingsPossibility } from '../../utils/helpers';
import { MultipleForm, MultipleResultPopup, SingleForm, SingleResultPopup } from './components';
import { tabItems } from './CopyWorkflowPopup.constants';
import { useCopyLogger, useHelpStripe } from './CopyWorkflowPopup.hooks';
import { messages } from './CopyWorkflowPopup.messages';
import { MultipleWorkflowCopyStateType, SingleWorkflowCopyStateType, TabId } from './CopyWorkflowPopup.types';

const qa = bemFactory.qa('copy-workflow-popup');

const CopyWorkflowPopup = memo(() => {
    const [activeTab, setActiveTab] = useState<TabId>(TabId.SINGLE_COPY);

    const [singleCopyFormState, setSingleCopyFormState] = useState<SingleWorkflowCopyStateType>({
        selectedCompany: null,
        selectedWorkflow: null,
    });

    const [multipleCopyFormState, setMultipleCopyFormState] = useState<MultipleWorkflowCopyStateType>({
        selectedCompany: null,
        selectedWorkflows: null,
        summary: {
            copiedTemplates: [],
            templatesWithErrors: [],
        },
    });

    const resetForms = useCallback(() => {
        setSingleCopyFormState({
            selectedCompany: null,
            selectedWorkflow: null,
        });

        setMultipleCopyFormState({
            selectedCompany: null,
            selectedWorkflows: null,
            summary: {
                copiedTemplates: [],
                templatesWithErrors: [],
            },
        });
    }, []);

    const [isCopySettingsChecked, setIsCopySettingsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [showSuccessufulPopup, setShowSuccessfulPopup] = useState(false);

    const dispatch = useDispatch();

    const open = !!useSelector(getActiveCopyTemplate);
    const companies = useSelector(selectors.company.getCompanies);
    const activeWorkflow = useSelector(getActiveTemplate)!;
    const workflowCompany = useSelector((state) => selectors.company.findCompanyById(state, activeWorkflow.companyId));

    const { helpStripeId } = useHelpStripe({ activeTab, activeWorkflow });

    const availableCompanies = useMemo(() => {
        if (!workflowCompany) return [];

        return companies.filter((company) => {
            const isSameIntegrationType =
                (!company.integration && !workflowCompany.integration) ||
                company.integration?.integrationType === workflowCompany.integration?.integrationType;

            const isCompanyRetiredOrLicenseExpired = company.flags.isRetired || company.flags.isExpired;
            const isEnabledCompany = activeWorkflow.integrationCode
                ? company.flags.hasActiveIntegration && !isCompanyRetiredOrLicenseExpired
                : !isCompanyRetiredOrLicenseExpired;

            return (
                (company.myRole === domain.CompanyUserRole.Manager ||
                    company.myRole === domain.CompanyUserRole.WorkflowManager) &&
                isSameIntegrationType &&
                isEnabledCompany
            );
        });
    }, [companies, workflowCompany, activeWorkflow]);

    const copyLogger = useCopyLogger({
        activeTab,
        setMultipleCopyFormState,
        setShowSuccessfulPopup,
        setIsLoading,
    });

    const handleOpenSuccessPopup = useCallback<ExtractComponentProp<typeof Popup, 'onToggle'>>(
        (open) => !open && window.location.reload(),
        []
    );

    const handleOpenCopyPopup = useCallback<ExtractComponentProp<typeof Popup, 'onToggle'>>(
        (open) => {
            if (isLoading || open) return;

            resetForms();
            setIsCopySettingsChecked(false);
            dispatch(discardCopyTemplatePopup());
        },
        [isLoading, resetForms, dispatch]
    );

    const onTabChange = useCallback(
        (tab: TabId) => {
            if (isLoading) return;

            setActiveTab(tab);
            resetForms();
            setIsCopySettingsChecked(false);
        },
        [isLoading, resetForms]
    );

    const onSingleWorkflowCopy = useCallback(
        ({
            selectedWorkflow,
            selectedCompany,
            copySettings,
        }: {
            selectedWorkflow: selectors.types.ExpandedTemplate | null;
            selectedCompany: selectors.types.ExpandedCompany | null;
            copySettings: boolean;
        }) => {
            if (workflowCompany && selectedCompany && selectedWorkflow) {
                setIsLoading(true);

                dispatch(
                    actions.copyWorkflow({
                        fromCompany: workflowCompany,
                        fromTemplate: activeWorkflow,
                        toCompany: selectedCompany,
                        toTemplate: selectedWorkflow,
                        target: null,
                        logger: copyLogger,
                        copySettings,
                        deactivateOnCopy: false,
                        showDoneToastNotification: false,
                    })
                );
            } else {
                toast.error(messages.requiredFieldsError);
                setShowErrors(true);
            }
        },
        [dispatch, workflowCompany, activeWorkflow, copyLogger]
    );

    const onMultipleWorkflowCopy = useCallback(
        ({
            selectedWorkflows,
            copySettings,
        }: {
            selectedWorkflows: selectors.types.ExpandedTemplate[] | null;
            copySettings: boolean;
        }) => {
            if (workflowCompany && selectedWorkflows && selectedWorkflows.length) {
                setIsLoading(true);

                const targets = selectedWorkflows.map((template) => {
                    const company = availableCompanies.find((company) => company.id === template.companyId);

                    if (!company) {
                        return null;
                    }

                    return {
                        company,
                        template,
                    };
                });

                dispatch(
                    actions.copyWorkflow({
                        fromCompany: workflowCompany,
                        fromTemplate: activeWorkflow,
                        toCompany: null,
                        toTemplate: null,
                        target: targets.filter(miscHelpers.notEmptyFilter),
                        logger: copyLogger,
                        copySettings,
                        deactivateOnCopy: false,
                        showDoneToastNotification: false,
                    })
                );
            } else {
                toast.error(messages.requiredFieldsError);
                setShowErrors(true);
            }
        },
        [workflowCompany, activeWorkflow, availableCompanies, dispatch, copyLogger]
    );

    const onCopyWorkflow = useCallback(() => {
        switch (activeTab) {
            case TabId.SINGLE_COPY: {
                onSingleWorkflowCopy({
                    selectedCompany: singleCopyFormState.selectedCompany,
                    selectedWorkflow: singleCopyFormState.selectedWorkflow,
                    copySettings: isCopySettingsChecked,
                });

                break;
            }

            case TabId.MULTIPLE_COPY: {
                onMultipleWorkflowCopy({
                    selectedWorkflows: multipleCopyFormState.selectedWorkflows,
                    copySettings: isCopySettingsChecked,
                });
                break;
            }
        }
    }, [
        activeTab,
        isCopySettingsChecked,
        multipleCopyFormState.selectedWorkflows,
        onMultipleWorkflowCopy,
        onSingleWorkflowCopy,
        singleCopyFormState.selectedCompany,
        singleCopyFormState.selectedWorkflow,
    ]);

    const onCopySettingsCheckboxChange = useCallback(() => {
        setIsCopySettingsChecked((isChecked) => !isChecked);
    }, []);

    const isCopySettingsPossible = useMemo(
        () =>
            activeTab === TabId.SINGLE_COPY
                ? checkCopySettingsPossibility(activeWorkflow, singleCopyFormState.selectedWorkflow)
                : multipleCopyFormState.selectedWorkflows?.some((template) =>
                      checkCopySettingsPossibility(activeWorkflow, template)
                  ),
        [activeTab, activeWorkflow, multipleCopyFormState.selectedWorkflows, singleCopyFormState.selectedWorkflow]
    );

    useEffect(() => {
        dispatch(clearCacheStorage((storageId) => String(storageId).startsWith('singleWorkflowCopy')));
        dispatch(clearCacheStorage((storageId) => String(storageId).startsWith('multiWorkflowCopy')));
    }, [dispatch]);

    useEffect(() => {
        if (!isCopySettingsPossible) {
            setIsCopySettingsChecked(false);
        }
    }, [isCopySettingsPossible]);

    const onMultipleWorkflowStateChange = useCallback((newState: Partial<MultipleWorkflowCopyStateType>) => {
        setMultipleCopyFormState((current) => ({
            ...current,
            ...newState,
        }));
    }, []);

    const singleCopySuccessData = useMemo(() => {
        if (
            activeTab !== TabId.SINGLE_COPY ||
            !singleCopyFormState.selectedCompany ||
            !singleCopyFormState.selectedWorkflow
        ) {
            return null;
        }

        return {
            targets: [
                {
                    company: singleCopyFormState.selectedCompany,
                    template: singleCopyFormState.selectedWorkflow,
                },
            ],
        };
    }, [activeTab, singleCopyFormState.selectedCompany, singleCopyFormState.selectedWorkflow]);

    const multiplyCopySuccessData = useMemo(() => {
        if (activeTab !== TabId.MULTIPLE_COPY) return null;

        const matchWorkflows = (
            workflowsSummary: {
                company: domain.Company;
                template: domain.Template;
            }[]
        ) => {
            const matchedList: {
                company: selectors.types.ExpandedCompany;
                template: selectors.types.ExpandedTemplate;
            }[] = [];

            workflowsSummary.forEach((item) => {
                const expandedCompany = availableCompanies.find((company) => company.id === item.company.id);
                const expandedWorkflows =
                    multipleCopyFormState.selectedWorkflows &&
                    multipleCopyFormState.selectedWorkflows.find((template) => template.id === item.template.id);

                if (expandedCompany && expandedWorkflows) {
                    matchedList.push({
                        company: expandedCompany,
                        template: expandedWorkflows,
                    });
                }
            });

            return matchedList;
        };

        const successfullyCopiedWorkflows = matchWorkflows(multipleCopyFormState.summary.copiedTemplates);

        const copiedWithMatrixErrorWorkflows = matchWorkflows(
            multipleCopyFormState.summary.templatesWithErrors.filter((template) => !template.copySettingsError)
        );

        const copiedWithSettingsErrorWorkflows = matchWorkflows(
            multipleCopyFormState.summary.templatesWithErrors.filter((template) => template.copySettingsError)
        );

        return {
            successfullyCopiedWorkflows,
            copiedWithMatrixErrorWorkflows,
            copiedWithSettingsErrorWorkflows,
        };
    }, [
        activeTab,
        availableCompanies,
        multipleCopyFormState.selectedWorkflows,
        multipleCopyFormState.summary.copiedTemplates,
        multipleCopyFormState.summary.templatesWithErrors,
    ]);

    const isShowSuccessPopup =
        showSuccessufulPopup && workflowCompany && (singleCopySuccessData || multiplyCopySuccessData);

    return isShowSuccessPopup ? (
        <>
            {activeTab === TabId.SINGLE_COPY && singleCopySuccessData && (
                <SingleResultPopup
                    sourceCompany={workflowCompany}
                    sourceWorkflow={activeWorkflow}
                    targets={singleCopySuccessData.targets}
                    skipCompanyName={workflowCompany.id === singleCopyFormState.selectedCompany?.id}
                    open={open}
                    onToggle={handleOpenSuccessPopup}
                />
            )}

            {activeTab === TabId.MULTIPLE_COPY && multiplyCopySuccessData && (
                <MultipleResultPopup
                    sourceCompany={workflowCompany}
                    sourceWorkflow={activeWorkflow}
                    successfullyCopiedWorkflows={multiplyCopySuccessData.successfullyCopiedWorkflows}
                    copiedWithMatrixErrorWorkflows={multiplyCopySuccessData.copiedWithMatrixErrorWorkflows}
                    copiedWithSettingsErrorWorkflows={multiplyCopySuccessData.copiedWithSettingsErrorWorkflows}
                    open={open}
                    onToggle={handleOpenSuccessPopup}
                />
            )}
        </>
    ) : (
        <Popup open={open} onToggle={handleOpenCopyPopup} preventAutoClose>
            <Popup.Header
                title={messages.popupTitle}
                progress={isLoading}
                actions={
                    <Flex inline alignItems='center'>
                        <HelpStripe.ToggleButton id={helpStripeId} />

                        <Button
                            size='medium'
                            color='blue10'
                            disabled={isLoading}
                            onClick={onCopyWorkflow}
                            data-qa={qa('submit')}
                            title={messages.submitButton}
                        >
                            {messages.submitButton}
                        </Button>
                    </Flex>
                }
            />

            <Popup.Body>
                <HelpStripe id={helpStripeId} hideTraining />

                <Tabs items={tabItems} onChange={onTabChange} value={activeTab}>
                    <Spacing height={24} />

                    <Checkbox
                        disabled={!isCopySettingsPossible}
                        onChange={onCopySettingsCheckboxChange}
                        checked={isCopySettingsChecked}
                    >
                        {messages.copyWorkflowSettingsFieldLabel}
                    </Checkbox>

                    <Spacing height={24} />

                    <Tabs.Content value={TabId.SINGLE_COPY}>
                        <SingleForm
                            activeWorkflow={activeWorkflow}
                            availableCompanies={availableCompanies}
                            showErrors={showErrors}
                            onStateChange={setSingleCopyFormState}
                            {...singleCopyFormState}
                        />
                    </Tabs.Content>

                    <Tabs.Content value={TabId.MULTIPLE_COPY}>
                        <MultipleForm
                            activeWorkflow={activeWorkflow}
                            availableCompanies={availableCompanies}
                            showErrors={showErrors}
                            onStateChange={onMultipleWorkflowStateChange}
                            {...multipleCopyFormState}
                        />
                    </Tabs.Content>
                </Tabs>
            </Popup.Body>
        </Popup>
    );
});

export default CopyWorkflowPopup;
