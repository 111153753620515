import { domain } from 'modules/data';
import { amplitudeService } from 'services/amplitude';

export const addUserEvent = (userRole: string, integrationCode: domain.IntegrationCode | null, newUser?: boolean) => {
    amplitudeService.sendData('workflow: add user', {
        workflow: integrationCode?.toLocaleLowerCase() || 'standalone',
        'user type': newUser ? 'new user' : 'existing user',
        'user role': userRole,
    });
};

export const removeUserEvent = (userRole: string, integrationCode: domain.IntegrationCode | null) => {
    amplitudeService.sendData('workflow: remove user', {
        workflow: integrationCode?.toLocaleLowerCase() || 'standalone',
        'user role': userRole,
    });
};

export const togglePullingFromXero = (integrationCode: domain.IntegrationCode | null, enabled: boolean) => {
    amplitudeService.sendData('workflow: set up pulling from xero', {
        'action type': enabled ? 'enable' : 'disable',
        workflow: integrationCode?.toLocaleLowerCase(),
    });
};

export const addDefaultApprover = (integrationCode: domain.IntegrationCode | null) => {
    amplitudeService.sendData('workflow: add default approver', {
        workflow: integrationCode?.toLocaleLowerCase() || 'standalone',
        'action source': 'workfow',
    });
};

export const addDefaultReviewer = (integrationCode: domain.IntegrationCode | null) => {
    amplitudeService.sendData('workflow: add default reviewer', {
        workflow: integrationCode?.toLocaleLowerCase() || 'standalone',
        'action source': 'workfow',
    });
};
