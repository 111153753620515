import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.CopyWorkflowPopup.SingleForm', {
    selectOrganisation: 'Select organisation',
    selectWorkflow: 'Select workflow',
    toCompanyFieldLabel: 'Copy Workflow to Organisation',
    workflowFieldLabel: 'Workflow you want to copy to',
    copyWorkflowSettingsFieldLabel: 'Copy workflow settings',
    requiredFieldsError: 'Please fill in required fields',
});
