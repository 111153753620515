import { useUpdateEffect } from 'react-use';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';

import { openingDuration } from './Drawer.constants';
import { collapseDrawerState, displayingDrawerState } from './Drawer.states';

export const useDisplaying = (): [boolean, SetterOrUpdater<boolean>] => {
    const isDrawerCollapsed = useRecoilValue(collapseDrawerState);
    const [isDrawerDisplaying, setIsDrawerDisplaying] = useRecoilState(displayingDrawerState);

    useUpdateEffect(() => {
        setIsDrawerDisplaying(true);

        setTimeout(() => {
            setIsDrawerDisplaying(false);
        }, openingDuration);
    }, [isDrawerCollapsed]);

    return [isDrawerDisplaying, setIsDrawerDisplaying];
};
