import { CheckCircleFilledIcon } from '@approvalmax/ui';
import { FC } from 'react';

import { useStepperContext } from '../../Stepper.context';
import { Circle, SVGText } from './Icon.styles';
import type { IconProps } from './Icon.types';

export const Icon: FC<IconProps> = (props) => {
    const { active, completed, icon } = props;

    const { color } = useStepperContext();

    if (typeof icon !== 'number' && typeof icon !== 'string') {
        return icon;
    }

    if (completed) {
        return <CheckCircleFilledIcon width={24} height={24} color={color} />;
    }

    return (
        <svg width='24' height='24' viewBox='0 0 24 24'>
            <Circle
                cx='12'
                cy='12'
                r='11.5'
                fill='transparent'
                $color={active ? color : 'midnight50'}
                strokeWidth='1'
            />

            <SVGText
                font='label'
                fontSize='small'
                x='12'
                y='12'
                textAnchor='middle'
                dominantBaseline='central'
                $color={active ? color : 'midnight50'}
            >
                {icon}
            </SVGText>
        </svg>
    );
};

Icon.displayName = 'Step.Icon';
