import { FC, memo } from 'react';
import styled from 'styled-components';

import BaseImage from './BaseImage';

const Wordless = styled(BaseImage)`
    background-position: 0 -695px;
`;

const ImageWordless: FC = () => {
    return <Wordless />;
};

export default memo(ImageWordless);
