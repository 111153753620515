export enum AddonTypes {
    Pay = 'pay',
    Capture = 'capture',
}

export enum AddonUsageThresholds {
    Less70Percentage = 'less70-percentage',
    Threshold70Percentage = 'threshold70-percentage',
    Threshold95Percentage = 'threshold95-percentage',
    ThresholdReached = 'threshold-reached',
    HighOverused = 'high-overused',
}

export enum AddOnStatuses {
    Draft = 'draft',
    Incomplete = 'incomplete',
    Active = 'active',
    PendingCancellation = 'pending-cancellation',
    Cancelled = 'cancelled',
}
