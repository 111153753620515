import { immutable } from 'modules/immutable';

import { Action } from '../actions/index';
import loadingReducer from './page/loadingReducer';
import moduleReducer, { ActiveModule, NoneModuleData, PageActiveModule } from './page/moduleReducer';

export interface Page {
    module: ActiveModule;
    loading: boolean;
}

const INITIAL_STATE = immutable<Page>({
    module: immutable<NoneModuleData>({
        type: PageActiveModule.None,
    }),
    loading: false,
});

export default function (state: Page = INITIAL_STATE, action: Action): Page {
    return {
        module: moduleReducer(state.module, action),
        loading: loadingReducer(state.loading, action),
    };
}
