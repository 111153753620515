import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';
import { createSelector } from 'reselect';
import { storageService } from 'services/storage';

export const needToShowPracticeInvitationPopup: (state: stateTree.State) => boolean = createSelector(
    (state: stateTree.State) => selectors.session.getStartupData(state).needsToCompleteSignupWizard === false,
    (state: stateTree.State) => selectors.practiceInvitations.getPracticeInvitations(state),
    (doNotNeedToCompleteSignupWizard, practiceInvitations) => {
        const viewedIds = storageService.getViewedPracticeInvitationKeys();
        const isThereSomeUnshownInvite = practiceInvitations.some(
            (invite) => !viewedIds.includes(invite.id + invite.dispatchDate)
        );

        return doNotNeedToCompleteSignupWizard && isThereSomeUnshownInvite;
    }
);
