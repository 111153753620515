const chars = /[&<>]/g;

const escapeChar = (chr: string) => {
    chr = chr.replace(/&/g, '&amp;');
    chr = chr.replace(/</g, '&lt;');
    chr = chr.replace(/>/g, '&gt;');

    return chr;
};

export const escapeExpressions = (text: string) => {
    return text.replace(chars, escapeChar);
};
