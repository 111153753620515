import { domain } from 'modules/data';

export enum BillableAccountType {
    Expense = 'Expense',
    OtherExpense = 'OtherExpense',
    OtherAsset = 'OtherAsset',
    OtherCurrentAsset = 'OtherCurrentAsset',
    FixedAsset = 'FixedAsset',
    OtherCurrentLiability = 'OtherCurrentLiability',
    CostofGoodsSold = 'CostofGoodsSold',
}

export interface ExpandedQBooksAccountLineItem extends domain.QBooksAccountLineItem {
    isNew: boolean;
    empty: boolean;
    canBeBillable: boolean;
    valid: boolean;
    hideRemove: boolean;
    hideClone: boolean;
}
