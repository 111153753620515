import { useGetRequestLegacy } from 'shared/data/api';

import type {
    UseAmaxPayOpenBankingBankAccountsResponse,
    UseAmaxPayOpenBankingBankAccountsResponseBackend,
} from './useAmaxPayOpenBankingBankAccounts.types';

type Options = NonNullable<
    Parameters<
        typeof useGetRequestLegacy<
            UseAmaxPayOpenBankingBankAccountsResponseBackend,
            UseAmaxPayOpenBankingBankAccountsResponse
        >
    >[2]
>;

export const mapData: NonNullable<Options['mapData']> = (data) => ({
    ...data,
    items: data.items.map(({ consent, identification, ...item }) => {
        return {
            ...item,
            ...identification,
            consentId: consent.id,
            status: consent.status,
        };
    }),
});
