import { Alert, Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo } from 'react';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { Integrations } from './components';
import { messages } from './IntegrationConnection.messages';

const IntegrationConnection = memo(() => {
    const company = useSelector(selectors.navigation.getActiveCompany);
    const isRetired = company.licenseProductPlan === domain.CompanyPlan.Retired || company.flags.isRetired;

    return (
        <Box spacing='24'>
            <Flex inline direction='column'>
                <Text font='headline' fontSize='xsmall' as='h1'>
                    {messages.title}
                </Text>

                <Text font='body' fontSize='small' color='midnight70'>
                    {messages.description}
                </Text>

                {isRetired && (
                    <Alert color='midnight20'>
                        <Flex inline alignItems='center' justifyContent='space-between' spacing='8'>
                            <Text font='body' fontSize='large'>
                                {messages.readOnly}
                            </Text>

                            <Button
                                color='cobalt80'
                                as='a'
                                href={getAccountUrl(AccountPath.subscriptions, { origin: 'wa_integration_connection' })}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {messages.manageSubscriptions}
                            </Button>
                        </Flex>
                    </Alert>
                )}

                <Integrations />
            </Flex>
        </Box>
    );
});

export default IntegrationConnection;
