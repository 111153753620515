import { FC, memo } from 'react';

import { Logo, LogoContainer, Root, Title } from './ConnectionButton.styles';
import { ConnectionButtonProps } from './ConnectionButton.types';

const ConnectionButton: FC<ConnectionButtonProps> = memo((props) => {
    const { title, logo, isLoading, alignLogoTop, onClick, children, width = 245 } = props;

    return (
        <Root execute={onClick} $width={width} disabled={isLoading}>
            <LogoContainer $alignStart={alignLogoTop}>
                <Logo as={logo} width={40} height={40} />

                <Title>{title}</Title>
            </LogoContainer>

            {children}
        </Root>
    );
});

export default ConnectionButton;
