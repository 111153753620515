import { mods } from '@approvalmax/theme';
import { TextButton } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
`;

export const ButtonsContainter = styled.div`
    display: flex;
`;

export const StyledTextButton = styled(TextButton)<StyledMods<{ toRight?: boolean }>>`
    ${mods('toRight', true)`
        margin-left: auto;
    `}
`;
