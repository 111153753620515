import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.UserActionsToolbar.UserCommonMenu', {
    accountName: 'Billing & Subscriptions',
    accountTitle: 'Manage subscriptions and Business Account details',

    developerName: 'Developer portal',
    developerTitle: 'Sign up and manage your 3rd party applications to get access to your data via Public API',

    publicApiConnectionsListName: 'Connections List',
    publicApiConnectionsListTitle:
        'Manage your connections to maintain access to your data that is used by 3rd party applications',

    partnerName: 'Partner portal',
    partnerTitle: 'Access to certification and partner-only materials',

    customerFeedbackName: 'Customer feedback',
    customerFeedbackTitle: 'Customer feedback and voting tool',

    practiceStaffName: 'Practice Staff',
    practiceStaffTitle: 'Manage staff members',
});
