export function getAllowAccessUrl(options: { callbackUrl: string; accessToken: string }) {
    const url = new URL(options.callbackUrl);

    let searchString = `?access_token=${options.accessToken}&dimension1=dimension1&dimension2=dimension2`;

    if (url.search) {
        searchString += `&${url.search.substring(1)}`;
    }

    return `${url.origin}${url.pathname}${searchString}`;
}

export function getGoBackUrl(options: { callbackUrl: string }) {
    return options.callbackUrl;
}
