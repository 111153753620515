import { Guid } from '@approvalmax/types';
import { backend } from 'modules/data';

import { ApiHandler } from '../types';

export default class DearApi {
    constructor(private _apiHandler: ApiHandler) {}

    connectToAccount(data: { companyId: Guid; accountId: string; applicationKey: string }): Promise<{
        Companies: backend.CompanyAnswer[];
    }> {
        return this._apiHandler.doApiCall({
            data,
            action: 'companies/finishDearIntegration',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
