import { Guid } from '@approvalmax/types';
import { domain } from 'modules/data';

export function getConditionsByFieldSystemPurposeOrId(
    authorRules: domain.MatrixCondition[],
    fieldSystemPurposeOrId: domain.FieldSystemPurpose | Guid
) {
    return authorRules.filter((rule) => {
        return rule.fieldSystemPurpose === fieldSystemPurposeOrId || rule.fieldId === fieldSystemPurposeOrId;
    });
}

export function getPositiveValuesFromConditions(conditions: domain.MatrixCondition[]) {
    if (!Array.isArray(conditions)) return [];

    return conditions
        .filter((condition) => condition.conditionType === domain.ConditionType.ExactValuesCondition)
        .flatMap((condition: domain.ExactValuesCondition) => condition.exactValues);
}

export function getNegativeValuesFromConditions(conditions: domain.MatrixCondition[]) {
    if (!Array.isArray(conditions)) return [];

    return conditions
        .filter((condition) => condition.conditionType === domain.ConditionType.NegativeExactValuesCondition)
        .flatMap((condition: domain.ExactValuesCondition) => condition.exactValues);
}

export function checkFieldValueWithRules(
    authorRules: domain.MatrixCondition[],
    fieldSystemPurposeOrId: domain.FieldSystemPurpose | Guid,
    fieldValueId: Guid
): boolean {
    const conditions = getConditionsByFieldSystemPurposeOrId(authorRules, fieldSystemPurposeOrId);

    if (conditions.length === 0) {
        // No conditions default to valid
        return true;
    }

    const positiveExactValues = getPositiveValuesFromConditions(conditions);
    const negativeExactValues = getNegativeValuesFromConditions(conditions);

    const negativeConditions = conditions.filter(
        (c) => c.conditionType === domain.ConditionType.NegativeExactValuesCondition
    );

    if (positiveExactValues.some((v) => v.id === fieldValueId)) {
        // Positive match overrides negative
        return true;
    }

    if (negativeConditions.length > 0 && !negativeExactValues.some((v) => v.id === fieldValueId)) {
        // Negative conditions exist and all valid
        return true;
    }

    return false;
}

export function checkFieldValueWithEditingPermissions(
    editPermissions: domain.MatrixCondition[],
    fieldSystemPurposeOrId: domain.FieldSystemPurpose | Guid,
    fieldValueId: Guid
): boolean {
    const conditions = getConditionsByFieldSystemPurposeOrId(editPermissions, fieldSystemPurposeOrId);

    const positiveExactValues = getPositiveValuesFromConditions(conditions);
    const negativeExactValues = getNegativeValuesFromConditions(conditions);

    if (
        conditions.length === 0 ||
        editPermissions.length === 0 ||
        (positiveExactValues.length === 0 && negativeExactValues.length === 0)
    ) {
        return true;
    }

    return checkFieldValueWithRules(editPermissions, fieldSystemPurposeOrId, fieldValueId);
}
