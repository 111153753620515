import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.HappyWorkflowActivationPopup', {
    closeButtonText: 'Go to requests',
    headerText: 'All done!',
    descriptionCommon: `The workflow is activated. New {workflowDisplayName} will now be approved according to the rules you have set up.{br}{br}
    {workflowDisplayName} submitted for approval can be found in the list of requests.`,
    descriptionStandalone: `The workflow is activated. New {workflowDisplayName} requests will now be approved according to the rules you have set up.{br}{br}
    {workflowDisplayName} requests submitted for approval can be found in the list of requests.`,
});
