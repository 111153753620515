import { ComponentType, memo, MemoExoticComponent, useMemo } from 'react';

import { Box } from '../../../Box/Box';
import { useStepperContext } from '../../Stepper.context';
import { Connector } from '../Connector/Connector';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';
import { StepContext } from './Step.context';
import { IconWrapper, Root, StepContentWrapper } from './Step.styles';
import type { StepProps } from './Step.types';

export const Step = memo((props) => {
    const {
        active: activeProp,
        completed: completedProp,
        disabled: disabledProp,
        icon: iconProps,
        index = 0,
        last = false,
        label,
        children,
    } = props;
    const { activeStep, direction } = useStepperContext();

    const active = activeProp ?? activeStep === index;
    const completed = completedProp ?? activeStep > index;
    const disabled = disabledProp ?? (!active && activeStep < index);
    const icon = iconProps ?? (index ?? 0) + 1;
    const first = index === 0;

    const contextValue = useMemo(
        () => ({ active, completed, disabled, first, last, icon }),
        [active, completed, disabled, first, icon, last]
    );

    if (children && direction === 'horizontal') {
        throw new Error('Children is only designed for use with the vertical stepper.');
    }

    return (
        <StepContext.Provider value={contextValue}>
            <Root $direction={direction}>
                <IconWrapper
                    $direction={direction}
                    $middle={direction === 'horizontal' && !first && !last}
                    $last={direction === 'horizontal' && last}
                >
                    {direction === 'horizontal' && (!first || last) && <Connector highlighted={active} />}

                    <Icon completed={completed} active={active} icon={icon} />

                    {last || <Connector />}
                </IconWrapper>

                <StepContentWrapper>
                    <Label>{label}</Label>

                    {children && direction === 'vertical' && <Box spacing='0 0 24'>{children}</Box>}
                </StepContentWrapper>
            </Root>
        </StepContext.Provider>
    );
}) as MemoExoticComponent<ComponentType<StepProps>> & { displayName: 'Step' };

Step.displayName = 'Step';
