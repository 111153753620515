import { RichEditor, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { FC, memo } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionInstructionForRequester.messages';
import { SectionInstructionForRequesterProps } from './SectionInstructionForRequester.types';

const SectionInstructionForRequester: FC<SectionInstructionForRequesterProps> = memo((props) => {
    const { template, instruction, links, onChangeInstructionSettings, readonly, qa } = props;

    return (
        <SettingsSection
            title={messages.title}
            learnMoreLink={links[selectors.types.Link.InstructionForRequesters]}
            data-qa={qa('instruction-for-requester')}
        >
            <SettingsSubsection
                subtitle={messages.subtitle({
                    templateName: template.displayName || messages.defaultTemplateName,
                })}
            >
                <RichEditor
                    placeholder={messages.placeholder}
                    value={instruction}
                    onChange={onChangeInstructionSettings}
                    changeOnBlur
                    readOnly={readonly}
                />

                <Text font='body' fontSize='medium' color='midnight70'>
                    {messages.instruction}
                </Text>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionInstructionForRequester;
