import { Mods, mods, theme } from '@approvalmax/theme';
import { Button } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled, { keyframes } from 'styled-components';

const hideKeyframes = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
`;

export const StyledButton = styled(Button)<StyledMods<Mods<'hide'>>>`
    ${mods.hide.true`
        animation: ${hideKeyframes} ${theme.duration.slow}ms ease-in-out;
    `};

    ${mods.hide.false`
        color: ${theme.color.midnight50};

        &:focus {
            color: ${theme.color.midnight50}
        }
    `}
`;
