import { Guid } from '@approvalmax/types';
import { schemas } from 'modules/data';
import { normalize } from 'normalizr';
import { api } from 'services/api';

export async function connectToIntegration(options: { callbackUrl: string }) {
    const response = await api.companies.enableQuickBooksIntegration({
        callbackUrl: options.callbackUrl,
    });

    return {
        redirectUrl: response.AuthorizeUrl,
    };
}

export async function completeIntegrationAuth(options: {
    companyId: Guid;
    code: string;
    state: string;
    realmId: string | null;
}) {
    const result = await api.companies.finishQuickBooksIntegration({
        companyId: options.companyId,
        code: options.code,
        state: options.state,
        org: options.realmId,
        realmId: options.realmId,
    });
    const entities = normalize(result.Companies[0], schemas.companySchemaLegacy).entities as any;

    return {
        entities,
    };
}
