import './referenceValueFilter.scss';

import { Reference } from '@approvalmax/types';
import { DropdownEditor } from '@approvalmax/ui';
import { PlainDataProvider } from 'modules/data-providers';
import React from 'react';
import bemFactory from 'react-bem-factory';

import * as referenceValueFilter from '../../data/filters/referenceValueFilter';

interface Props {
    options: Reference[];
    filter: referenceValueFilter.ReferenceValueFilter;
    onFilterChange(filter: referenceValueFilter.ReferenceValueFilter): void;
}

interface State {}

class ReferenceValueFilter extends React.Component<Props, State> {
    public render() {
        const { options, filter } = this.props;
        const bem = bemFactory.block('rpt-reference-value-filter');

        return (
            <div className={bem()}>
                <PlainDataProvider items={options}>
                    <DropdownEditor
                        value={filter.value || null}
                        buttonComponent={DropdownEditor.NoFilterButton}
                        className={bem('dropdown')}
                        onChange={this._onChange}
                    />
                </PlainDataProvider>
            </div>
        );
    }

    private _onChange = (value: Reference | null) => {
        this.props.onFilterChange({
            ...this.props.filter,
            value: value!,
        });
    };
}

export default ReferenceValueFilter;
