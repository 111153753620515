import { pageConfig as loginPageConfig } from 'app/(auth)/login/config';
import { pageConfig as verifyEmailPageConfig } from 'app/(auth)/verifyEmail/config';
import { pageConfig as budgetsPageConfig } from 'app/(workspace)/[companyId]/budgets/config';
import { pageConfig as companyInfoPageConfig } from 'app/(workspace)/[companyId]/companyInfo/config';
import { pageConfig as reportPageConfig } from 'app/(workspace)/[companyId]/reports/config';
import { pageConfig as usersPageConfig } from 'app/(workspace)/[companyId]/users/config';
import { pageConfig as workflowPageConfig } from 'app/(workspace)/[companyId]/workflows/[workflowId]/config';
import { pageConfig as workflowsPageConfig } from 'app/(workspace)/[companyId]/workflows/config';
import { PageSlice } from 'modules/page';
import { page as authPage } from 'pages/auth';
import { page as customFieldsPage } from 'pages/customFields';
import { page as noCompaniesPage } from 'pages/noCompanies';
import { page as reportsPage } from 'pages/reports';
import { page as requestFormPage } from 'pages/requestForm';
import { page as requestListPage } from 'pages/requestList';

const allPagesSlices: PageSlice[] = [
    authPage,
    customFieldsPage,
    noCompaniesPage,
    reportsPage,
    requestFormPage,
    requestListPage,
    budgetsPageConfig.store,
    workflowsPageConfig.store,
    workflowPageConfig.store,
    usersPageConfig.store,
    companyInfoPageConfig.store,
    reportPageConfig.store,
    loginPageConfig.store,
    verifyEmailPageConfig.store,
];

export default allPagesSlices;
