import { domain } from 'modules/data';

export enum EmailToSupplierIsSent {
    Yes = 'Yes',
    No = 'No',
}

export interface EmailToSupplier {
    from: string;
    replyTo: string;
    to: string[];
    cc: string[];
    subject: string;
    body: string;
    attachments: domain.RequestAttachment[];
    emailHasToBeSent?: boolean;
}

export interface EmailToSupplierDetails extends Pick<EmailToSupplier, 'to' | 'cc' | 'body' | 'subject'> {}
