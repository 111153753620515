import { Popup } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { discardInviteUsersPopup } from '../../actions';
import { getActiveInviteUsers } from '../../selectors/pageSelectors';
import { Content } from './components';

const InviteUsersPopup = memo(() => {
    const dispatch = useDispatch();
    const popupData = useSelector(getActiveInviteUsers);

    const closePopup = useCallback(() => dispatch(discardInviteUsersPopup()), [dispatch]);
    const onToggle = useCallback((open: boolean) => !open && closePopup(), [closePopup]);

    return (
        <Popup open={Boolean(popupData)} onToggle={onToggle} size='large'>
            <Content />
        </Popup>
    );
});

InviteUsersPopup.displayName = 'InviteUsersPopup';

export default InviteUsersPopup;
