import { messages } from './formatDateTime.messages';

export const formatDuration = (
    duration: moment.Duration,
    options: {
        omitSeconds?: boolean;
        omitMinutes?: boolean;
        omitHours?: boolean;
        omitDays?: boolean;
    } = {}
) => {
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let fragments = [];

    if (days && !options.omitDays) {
        fragments.push(messages.durationDaysFragment({ days }));
    }

    if (hours && !options.omitHours) {
        fragments.push(messages.durationHoursFragment({ hours }));
    }

    if (minutes && !options.omitMinutes) {
        fragments.push(messages.durationMinutesFragment({ minutes }));
    }

    if (seconds && !options.omitSeconds) {
        fragments.push(messages.durationSecondsFragment({ seconds }));
    }

    if (fragments.length === 0) {
        return messages.lessThanSecond;
    }

    return fragments.join(' ');
};
