import { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import {
    UseGetAmaxPayXeroBankAccountsPathParams,
    UseGetAmaxPayXeroBankAccountsResponse,
} from './useGetAmaxPayXeroBankAccounts.types';

export const useGetAmaxPayXeroBankAccounts = (
    pathParams: UseGetAmaxPayXeroBankAccountsPathParams,
    queryOptions?: QueryOptions<UseGetAmaxPayXeroBankAccountsResponse>
) =>
    useGetRequestLegacy<UseGetAmaxPayXeroBankAccountsResponse>(
        requestsApiPaths.amaxPayXeroBankAccounts,
        {},
        {
            ...queryOptions,
            pathParams,
            method: 'get',
        }
    );
