import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import type {
    UseAmaxPayBillCancelData,
    UseAmaxPayBillCancelPathParams,
    UseAmaxPayBillCancelResponse,
} from './useAmaxPayBillCancel.types';

export const useAmaxPayBillCancel = (
    mutationOptions: MutationOptions<
        UseAmaxPayBillCancelResponse,
        RequestDataParams<UseAmaxPayBillCancelData, UseAmaxPayBillCancelPathParams>
    > = {}
) => {
    return useMutateLegacy<
        RequestDataParams<UseAmaxPayBillCancelData, UseAmaxPayBillCancelPathParams>,
        UseAmaxPayBillCancelResponse
    >(requestsApiPaths.amaxPayBillCancel, {
        mutationOptions,
    });
};
