import { memo } from 'react';

import { WarnIcon } from '../../../sprites';
import { Icon, Root, Text } from './WarningBlock.styles';
import { WarningBlockProps } from './WarningBlock.types';

const WarningBlock = memo<WarningBlockProps>((props) => {
    const { className, children } = props;

    return (
        <Root className={className}>
            <Icon>
                <WarnIcon />
            </Icon>

            <Text>{children}</Text>
        </Root>
    );
});

export default WarningBlock;
