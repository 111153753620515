import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.SingletonSteps.AutoApprovalStep', {
    header: 'Auto-approval Step',
    removeStepTitle: 'Remove auto-approval step',
    settingsButton: 'Auto-approval settings',
    note: 'Please note: the auto-approval step is the first step that’s triggered. So, if a request matches one of the rules specified in this step, it will be approved automatically – and bypass all further approval steps.',
    willBeApprovedAutomaticallyIf: '{requestType} will be approved automatically if ',
    and: 'and',
    or: 'or',
});
