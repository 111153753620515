import { QueryOptions } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetRequestLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import {
    UseGetNetSuiteVendorCategoriesParams,
    UseGetNetSuiteVendorCategoriesResponse,
    UseGetNetSuiteVendorCategoriesResponseBackend,
} from './useGetNetSuiteVendorCategories.types';

export const useGetNetSuiteVendorCategories = (
    params: UseGetNetSuiteVendorCategoriesParams,
    queryOptions: QueryOptions<UseGetNetSuiteVendorCategoriesResponseBackend, UseGetNetSuiteVendorCategoriesResponse>
) => {
    const { enabled = true } = queryOptions;

    return useGetRequestLegacy<UseGetNetSuiteVendorCategoriesResponseBackend, UseGetNetSuiteVendorCategoriesResponse>(
        integrationsApiPaths.getNetSuiteVendorCategories,
        {},
        {
            queryOptions: {
                ...queryOptions,
                enabled: !!params.companyId && enabled,
            },
            params,
            mapData: objectHelpers.pascalCaseToCamelCase,
            method: 'get',
        }
    );
};
