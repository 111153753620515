import { useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';

import { closedPromotionStorageKey } from './Promotion.constants';
import { PromotionProps } from './Promotion.types';

export const useClosePromotion = (id: PromotionProps['company']['id']) => {
    const closedCompaniesPromotion = useMemo(
        () => JSON.parse(localStorage.getItem(closedPromotionStorageKey) || '[]'),
        []
    );

    const [isClosed, setIsClosed] = useToggle(closedCompaniesPromotion.includes(id));

    const close = useCallback(() => {
        setIsClosed(true);
        localStorage.setItem(closedPromotionStorageKey, JSON.stringify([...closedCompaniesPromotion, id]));
    }, [closedCompaniesPromotion, id, setIsClosed]);

    return {
        isClosed,
        close,
    };
};
