import { SearchIcon } from '@approvalmax/ui';
import { Button } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { getPath, Path } from 'urlBuilder';

const qa = bemFactory.qa('reql-page');

const Search = memo(() => {
    const location = useLocation();
    const isSearchPage = matchPath(location.pathname, getPath(Path.searchRequests));
    const isNoCompaniesPage = matchPath(location.pathname, getPath(Path.noCompanies));

    if (isSearchPage || isNoCompaniesPage) return null;

    return (
        <Button icon noPadding as={Link} to={getPath(Path.searchRequests)} data-qa={qa('new-search-button')}>
            <SearchIcon size={24} />
        </Button>
    );
});

export default Search;
