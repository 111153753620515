import { TextButton } from '@approvalmax/ui';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

import { useTwoFaEnablingWizardContext } from '../../TwoFaEnablingWizard.context';
import AnotherMethodButton from '../AnotherMethodButton/AnotherMethodButton';
import ProgressFlow2fa from '../ProgressFlow2fa/ProgressFlow2fa';
import { useStepEmailConfirm } from './StepEmailConfirm.hooks';
import { messages } from './StepEmailConfirm.messages';
import {
    ButtonNext,
    ButtonResend,
    CodeField,
    CodeFieldEditor,
    CodeFieldRow,
    Content,
    Description,
    Root,
    Title,
    TitleEmail,
    TitleRow,
} from './StepEmailConfirm.styles';

const qa = bemFactory.qa('pro-two-fa-setup-email-confirm');

const StepEmailConfirm: FC = memo(() => {
    const { setActiveStep, setSelectedBackupOption } = useTwoFaEnablingWizardContext();

    const onPrev = useCallback(() => {
        setActiveStep('emailEnter');
    }, [setActiveStep]);

    const onNext = useCallback(() => {
        setActiveStep('success');
        setSelectedBackupOption('email');
    }, [setActiveStep, setSelectedBackupOption]);

    const { inputRef, email, emailCode, onChange, onSubmit, onResend, isError, isLoading, canSubmit } =
        useStepEmailConfirm(onNext);

    return (
        <Root qa={qa()} title={messages.popupTitle}>
            <Content>
                <ProgressFlow2fa activeStepIndex={2} />

                <Title>{messages.title}</Title>

                <TitleRow>
                    <TitleEmail>{email}</TitleEmail>

                    <TextButton execute={onPrev} disabled={isLoading} upperCased={false} underlined>
                        {messages.changeEmailButton}
                    </TextButton>
                </TitleRow>

                <Description>{messages.description}</Description>

                <CodeField title={messages.codeFieldTitle} errorText={isError && messages.wrongCode}>
                    <CodeFieldRow>
                        <CodeFieldEditor
                            focusOnMount
                            disabled={isLoading}
                            value={emailCode}
                            onChange={onChange}
                            placeholder={messages.emailCodePlaceholder}
                            invalid={isError}
                            ref={inputRef}
                            onEnter={onSubmit}
                        />

                        <ButtonResend execute={onResend} disabled={isLoading}>
                            {messages.resendCodeButton}
                        </ButtonResend>
                    </CodeFieldRow>
                </CodeField>

                <ButtonNext execute={onSubmit} disabled={!canSubmit}>
                    {messages.nextButton}
                </ButtonNext>

                <AnotherMethodButton />
            </Content>
        </Root>
    );
});

export default StepEmailConfirm;
