import { Reference } from '@approvalmax/types';
import { BaseEditorProps, DropdownEditor } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { PlainDataProvider } from 'modules/data-providers';
import React, { FC } from 'react';

const i18nPrefix = 'common/ui/monthPickerEditor/MonthPickerEditor';

export interface MonthReference extends Reference {
    monthIndex: number;
}

const monthNames = [
    intl.formatMessage({
        id: `${i18nPrefix}.monthJanuary`,
        defaultMessage: 'January',
    }),
    intl.formatMessage({ id: `${i18nPrefix}.monthFebruary`, defaultMessage: 'February' }),
    intl.formatMessage({ id: `${i18nPrefix}.monthMarch`, defaultMessage: 'March' }),
    intl.formatMessage({ id: `${i18nPrefix}.monthApril`, defaultMessage: 'April' }),
    intl.formatMessage({ id: `${i18nPrefix}.monthMay`, defaultMessage: 'May' }),
    intl.formatMessage({ id: `${i18nPrefix}.monthJune`, defaultMessage: 'June' }),
    intl.formatMessage({ id: `${i18nPrefix}.monthJuly`, defaultMessage: 'July' }),
    intl.formatMessage({ id: `${i18nPrefix}.monthAugust`, defaultMessage: 'August' }),
    intl.formatMessage({ id: `${i18nPrefix}.monthSeptember`, defaultMessage: 'September' }),
    intl.formatMessage({ id: `${i18nPrefix}.monthOctober`, defaultMessage: 'October' }),
    intl.formatMessage({ id: `${i18nPrefix}.monthNovember`, defaultMessage: 'November' }),
    intl.formatMessage({ id: `${i18nPrefix}.monthDecember`, defaultMessage: 'December' }),
];

export const allMonthReferences: MonthReference[] = monthNames.map((name, i) => ({
    id: String(i + 1),
    monthIndex: i + 1,
    text: name,
}));

interface MonthPickerEditorProps extends BaseEditorProps<MonthReference | null> {
    buttonComponent?: React.ComponentType<any>;
}

export const MonthPickerEditor: FC<MonthPickerEditorProps> = (props) => {
    return (
        <PlainDataProvider items={allMonthReferences}>
            <DropdownEditor
                css='width: 100px'
                placeholder={intl.formatMessage({
                    id: `${i18nPrefix}.placeholder`,
                    defaultMessage: 'Month',
                })}
                {...props}
            />
        </PlainDataProvider>
    );
};
