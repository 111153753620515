import { ChevronRightIcon } from '@approvalmax/ui';
import { Flex, Select, Spacing } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { FC, memo, useCallback } from 'react';

import { useWorkflows } from '../../CopyWorkflowPopup.hooks';
import WarningList from '../WarningList/WarningList';
import { messages } from './SingleForm.messages';
import { SingleFormProps } from './SingleForm.types';

const SingleForm: FC<SingleFormProps> = memo((props) => {
    const { activeWorkflow, availableCompanies, showErrors, selectedCompany, selectedWorkflow, onStateChange } = props;

    const { workflows, isFetching: isFetchingWorkflows } = useWorkflows(selectedCompany, activeWorkflow);

    const onCompanyChange = useCallback(
        (value: string, items: selectors.types.ExpandedCompany[]) => {
            const company = items.find(({ id }) => id === value);

            onStateChange({
                selectedCompany: company || null,
                selectedWorkflow: null,
            });
        },
        [onStateChange]
    );

    const onWorkflowChange = useCallback(
        (value: string, items: selectors.types.ExpandedTemplate[]) => {
            const selectedWorkflow = items.find(({ id }) => id === value);

            onStateChange({
                selectedCompany,
                selectedWorkflow: selectedWorkflow || null,
            });
        },
        [selectedCompany, onStateChange]
    );

    return (
        <>
            <Flex inline alignItems='end'>
                <Flex grow={1}>
                    <Select
                        label={messages.toCompanyFieldLabel}
                        placeholder={messages.selectOrganisation}
                        items={availableCompanies}
                        itemNameKey='displayName'
                        invalid={showErrors && !selectedCompany}
                        value={selectedCompany?.id}
                        onChange={onCompanyChange}
                        autocomplete
                        required
                    />
                </Flex>

                <Flex shrink={0}>
                    <ChevronRightIcon size={16} />

                    <Spacing height={8} />
                </Flex>

                <Flex grow={1}>
                    <Select
                        label={messages.workflowFieldLabel}
                        placeholder={messages.selectWorkflow}
                        items={workflows}
                        itemNameKey='displayName'
                        invalid={showErrors && !selectedWorkflow}
                        value={selectedWorkflow?.id}
                        onChange={onWorkflowChange}
                        progress={isFetchingWorkflows}
                        autocomplete
                        required
                    />
                </Flex>
            </Flex>

            {selectedWorkflow && <WarningList activeWorkflow={activeWorkflow} selectedWorkflows={[selectedWorkflow]} />}
        </>
    );
});

export default SingleForm;
