import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetRequestLegacy } from 'shared/data';

import { authApiPaths } from '../paths';
import {
    UseGetGoogleOAuthUrlQueryParams,
    UseGetGoogleOAuthUrlResponse,
    UseGetGoogleOAuthUrlResponseBackend,
} from './useGetGoogleOAuthUrl.types';

export const useGetGoogleOAuthUrl = (
    params: QueryParams<UseGetGoogleOAuthUrlQueryParams>,
    queryOptions?: QueryOptions<UseGetGoogleOAuthUrlResponseBackend, UseGetGoogleOAuthUrlResponse>
) => {
    return useGetRequestLegacy<UseGetGoogleOAuthUrlResponseBackend, UseGetGoogleOAuthUrlResponse>(
        authApiPaths.googleOAuthUrl,
        params,
        {
            mapData: objectHelpers.pascalCaseToCamelCase,
            queryOptions: {
                ...queryOptions,
                enabled: enabledByParams<UseGetGoogleOAuthUrlQueryParams>(params, queryOptions),
            },
        }
    );
};
