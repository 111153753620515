import { errorHelpers } from '@approvalmax/utils';
import unionBy from 'lodash/unionBy';
import { stateTree } from 'modules/data';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import {
    Action,
    FETCH_DATA_FAILURE,
    FETCH_DATA_RESPONSE,
    fetchDataFailure,
    fetchDataRequest,
    fetchDataResponse,
    LOAD_ITEMS,
    loadItems,
} from '../actions';
import { getCachedData, getStorage } from '../reducers';
import { DataItem } from '../typings/DataItem';

type LoadItemsType = ReturnType<typeof loadItems>;

const EXCLUDED_ITEMS_RESERVE = 15;

function withoutExcluded(data: DataItem[], excludedIds: string[]) {
    return data.filter((x) => !excludedIds.includes(x.id));
}

export function* handleLoadItems(action: LoadItemsType) {
    try {
        const state: stateTree.State = yield select();
        const fieldData = getCachedData(
            getStorage(state.dataProviders, action.payload.cacheStorageId),
            action.payload.filterText
        );

        // Check if another loading is already in progress
        if (fieldData.loading) {
            // Even though we data is being loaded, another request may load not enough data to fulfill this one.
            // So we restart the saga to possibly load even more data (or return it from cache)
            yield take(
                (a: Action) =>
                    (a.type === FETCH_DATA_RESPONSE || a.type === FETCH_DATA_FAILURE) &&
                    a.payload.cacheStorageId === action.payload.cacheStorageId &&
                    a.payload.filterText === action.payload.filterText
            );
            yield call(handleLoadItems as any, action);

            return;
        }

        // Check if there's enough data in cache OR there's just no more data to request
        let cachedData: DataItem[] = fieldData.items || [];
        let filteredData = withoutExcluded(cachedData, action.payload.excludedIds);

        if ((filteredData.length >= action.payload.pageSize || fieldData.hasMore === false) && !fieldData.error) {
            return;
        }

        // Ok, let's load some data
        // 1. request (1 page + EXCLUDED_ITEMS_RESERVE + 1 /!* to check has more *!/) at a time
        // 2. finish when there's enough data to fill a full filtered page or when there's no more data to load
        yield put(
            fetchDataRequest({
                cacheStorageId: action.payload.cacheStorageId,
                filterText: action.payload.filterText,
            })
        );

        try {
            const count = action.payload.pageSize + EXCLUDED_ITEMS_RESERVE + 1;

            let hasMore = true;

            while (filteredData.length < action.payload.pageSize && hasMore) {
                const data: DataItem[] = yield call(
                    action.payload.onLoad,
                    action.payload.filterText || null,
                    cachedData.length,
                    count
                );

                const newCachedData = unionBy(cachedData, data, 'id');

                const isSameData = newCachedData.length === cachedData.length;

                hasMore = isSameData ? false : data.length >= count;
                filteredData = unionBy(filteredData, withoutExcluded(data, action.payload.excludedIds), 'id');

                cachedData = newCachedData; // NOTE: do not inline - fixes a stack overflow bug in TS analyzer
            }

            yield put(
                fetchDataResponse({
                    cacheStorageId: action.payload.cacheStorageId,
                    filterText: action.payload.filterText,
                    items: cachedData,
                    hasMore,
                })
            );
        } catch (error) {
            errorHelpers.captureException(error);
            yield put(
                fetchDataFailure({
                    cacheStorageId: action.payload.cacheStorageId,
                    filterText: action.payload.filterText,
                    error,
                })
            );
        }
    } catch (e) {
        errorHelpers.captureException(e);
    }
}

export default function* loadItemsSaga() {
    yield takeEvery(LOAD_ITEMS, handleLoadItems);
}
