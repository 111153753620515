import { domain, stateTree } from 'modules/data';

import { selectors } from '..';

export function getCreatableTemplates(state: stateTree.State) {
    return state.meta.creatableTemplates;
}

export function getViewableTemplates(state: stateTree.State) {
    return state.meta.viewableTemplates;
}

export function getTemplateInfoById(state: stateTree.State, templateId: string) {
    const templateInfo = state.meta.allWorkflows.find((t) => t.id === templateId);

    return templateInfo || null;
}

export function getViewableTemplatesByCompanyId(state: stateTree.State, companyId: string) {
    return getViewableTemplates(state).filter((t) => t.companyId === companyId);
}

export function getCreatableTemplatesByCompanyId(state: stateTree.State, companyId?: string) {
    return getCreatableTemplates(state)
        .map((id) => selectors.template.getTemplateById(state, id))
        .filter((template) => {
            const company = selectors.company.findCompanyById(state, template.companyId);

            if (company) {
                if (
                    template.integrationCode === domain.IntegrationCode.NetSuiteSalesOrder ||
                    template.integrationCode === domain.IntegrationCode.NetSuiteVRA ||
                    template.integrationCode === domain.IntegrationCode.NetSuiteRA ||
                    template.integrationCode === domain.IntegrationCode.NetSuiteBillPayment
                ) {
                    return false;
                }
            }

            return companyId ? template.companyId === companyId : true;
        });
}
