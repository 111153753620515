import { produce } from 'immer';
import { Entities } from 'modules/data';

import { Action } from '../actions';

export default produce((draft: Entities, action: Action): Entities => {
    switch (action.type) {
        default:
            return draft;
    }
});
