import { FC, memo, useCallback } from 'react';

import { useTwoFaEnablingWizardContext } from '../../TwoFaEnablingWizard.context';
import { messages } from './AnotherMethodButton.messages';
import { BackButton } from './AnotherMethodButton.styles';

const AnotherMethodButton: FC = memo(() => {
    const { setActiveStep } = useTwoFaEnablingWizardContext();

    const toBackupMethods = useCallback(() => {
        setActiveStep('backupMethods');
    }, [setActiveStep]);

    return <BackButton execute={toBackupMethods}>{messages.anotherMethodButton}</BackButton>;
});

export default AnotherMethodButton;
