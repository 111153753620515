import { toast } from '@approvalmax/ui/src/components';
import difference from 'lodash/difference';
import { factories, selectors } from 'modules/common';
import { backend, domain, schemas, State, stateTree } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions, ThunkAction } from 'modules/react-redux';
import { api } from 'services/api';

import { messagesActions as messages } from '../messages';

export const LOAD_PAGE_DATA = 'USERS/LOAD_PAGE_DATA';
export const loadPageData = (companyId: string, entities: Partial<stateTree.Entities>) =>
    createAction(LOAD_PAGE_DATA, { companyId, entities });

export const CHANGE_USER_ROLE = 'USERS/CHANGE_USER_ROLE';
export const changeUserRole = (
    companyId: string,
    user: selectors.types.ExpandedCompanyUser,
    role: domain.CompanyUserRole
) =>
    createAction(CHANGE_USER_ROLE, {
        companyId,
        user,
        role,
    });

export const SHOW_ADD_USERS_POPUP = 'USERS/SHOW_ADD_USERS_POPUP';
export const showAddUsersPopup = () => createAction(SHOW_ADD_USERS_POPUP, {});

export const SHOW_INVITE_USERS_POPUP = 'USERS/SHOW_INVITE_USERS_POPUP';
export const showInviteUsersPopup = (users: domain.User[]) =>
    createAction(SHOW_INVITE_USERS_POPUP, {
        users,
    });

export const SHOW_SET_DELEGATE_POPUP = 'USERS/SHOW_SET_DELEGATE_POPUP';

interface ShowSetDelegatePopupAction {
    type: typeof SHOW_SET_DELEGATE_POPUP;
    userId: string;
}

export function showSetDelegatePopup(userId: string): ThunkAction<State> {
    return (dispatch) => {
        dispatch<ShowSetDelegatePopupAction>({
            type: SHOW_SET_DELEGATE_POPUP,
            userId,
        });
    };
}

export const SHOW_OFFBOARD_USER_POPUP = 'USERS/SHOW_OFFBOARD_USER_POPUP';
export const showOffboardUserPopup = (userEmail: string) =>
    createAction(SHOW_OFFBOARD_USER_POPUP, {
        userEmail,
    });

export const SHOW_DELETE_USERS_POPUP = 'USERS/SHOW_DELETE_USERS_POPUP';
export const showDeleteUsersPopup = (userIds: string[]) =>
    createAction(SHOW_DELETE_USERS_POPUP, {
        users: userIds,
    });

export const CANCEL_ACTIVE_POPUP = 'USERS/CANCEL_ACTIVE_POPUP';
export const cancelActivePopup = () => createAction(CANCEL_ACTIVE_POPUP, {});

export const ADD_USERS = 'USERS/ADD_USERS';
export const ADD_USERS_RESPONSE = 'USERS/ADD_USERS_RESPONSE';
export const ADD_USERS_FAILURE = 'USERS/ADD_USERS_FAILURE';
export const addUsers = ({
    company,
    userEmailsWithRoles,
    doInvite,
    invitationText,
}: {
    company: domain.Company;
    userEmailsWithRoles: Record<string, domain.CompanyUserRole>;
    doInvite: boolean;
    invitationText?: string;
}) =>
    createAsyncAction({
        request: (state: State) => {
            const team = selectors.company.getCompanyById(state, company.id).allMembers;
            const emails = Object.keys(userEmailsWithRoles);
            const newUsers = difference(
                emails,
                team.map((user) => user.userEmail)
            ).map((email) => ({ ...factories.user.createUser(email), role: userEmailsWithRoles[email] }));
            const existingUsers = team
                .filter((user) => emails.includes(user.userEmail) && user.status !== domain.CompanyUserStatus.Active)
                // role might be reassigned while inviting
                .map((user) => ({ ...user, role: userEmailsWithRoles[user.id] }));

            return createAction(ADD_USERS, {
                company,
                newUsers,
                existingUsers,
                doInvite,
                invitationText,
            });
        },

        response: async (request) => {
            const response: { Participants: backend.CompanyParticipantAnswer[] } = {
                Participants: [],
            };

            if (request.newUsers.length > 0) {
                const result = await api.companies.addParticipants({
                    companyId: company.id,
                    userEmailsWithRoles: Object.fromEntries(
                        request.newUsers.map((user) => [user.userEmail, user.role])
                    ),
                    doInvite,
                    invitationText,
                });

                response.Participants.push(...result.Participants);
            }

            if (doInvite && request.existingUsers.length > 0) {
                const result = await api.companies.inviteParticipants({
                    companyId: company.id,
                    participantUserIdsWithRoles: Object.fromEntries(
                        request.existingUsers.map((user) => [user.databaseId, user.role])
                    ),
                    invitationText,
                });

                response.Participants.push(...result.Participants);
            }

            return createAction(ADD_USERS_RESPONSE, {
                request,
                userStatuses: schemas.company.extractUserStatusFromAnswerArray(response.Participants, company.author),
                raw: response,
                doInvite,
            });
        },

        failure: (error, request) => createErrorAction(ADD_USERS_FAILURE, error, request),

        schema: {
            raw: { Participants: [schemas.userSchema] },
        },

        willDispatchResponse: (request) => {
            const count = request.existingUsers.length + request.newUsers.length;

            if (doInvite) {
                toast.success(messages.usersInvitedNotification({ count }));
            } else {
                toast.success(messages.usersAddedNotification({ count }));
            }
        },
    });

export const SET_OPTIMISTIC_DELEGATE = 'USERS/SET_OPTIMISTIC_DELEGATE';
export const setOptimisticDelegate = (params: {
    companyId: string;
    user: domain.User;
    delegateUserId?: string;
    delegateUserProfileId?: string;
    delegateDateFrom?: string | null;
    delegateDateTo?: string | null;
}) => createAction(SET_OPTIMISTIC_DELEGATE, params);

export const INVITE_USERS = 'USERS/INVITE_USERS';
export const INVITE_USERS_RESPONSE = 'USERS/INVITE_USERS_RESPONSE';
export const INVITE_USERS_FAILURE = 'USERS/INVITE_USERS_FAILURE';
export const inviteUsers = (company: domain.Company, invitationMessage: string, users: domain.User[]) =>
    createAsyncAction({
        request: () =>
            createAction(INVITE_USERS, {
                company,
                users: users.map((u) => u.id),
            }),

        response: async (request) => {
            const response = await api.companies.inviteParticipants({
                companyId: company.id,
                participantUserIdsWithRoles: Object.fromEntries(
                    users
                        .map((user) => (company.tempUsers || {})[user.id] || user)
                        .map((user) => [user.databaseId, selectors.company.getCompanyUserRole(company, user.id)])
                ),
                invitationText: invitationMessage,
            });

            return createAction(INVITE_USERS_RESPONSE, {
                request,
                raw: response,
                userStatuses: schemas.company.extractUserStatusFromAnswerArray(response.Participants, company.author),
            });
        },

        failure: (error) => createErrorAction(INVITE_USERS_FAILURE, error, {}),

        schema: {
            raw: { Participants: [schemas.userSchema] },
        },

        willDispatchResponse: () => {
            toast.success(messages.usersInvitedNotification({ count: users.length }));
        },
    });

export type Action =
    | ExtractActions<
          | typeof addUsers
          | typeof cancelActivePopup
          | typeof changeUserRole
          | typeof inviteUsers
          | typeof loadPageData
          | typeof setOptimisticDelegate
          | typeof showAddUsersPopup
          | typeof showDeleteUsersPopup
          | typeof showInviteUsersPopup
          | typeof showOffboardUserPopup
      >
    | ShowSetDelegatePopupAction;
