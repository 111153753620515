import { ExactAsyncConditionBaseProps } from '../ExactAsyncCondition/ExactAsyncCondition.types';

export type XeroSupplierCreationConditionCellProps = ExactAsyncConditionBaseProps;

export const conditionTypeValues = {
    AllContacts: 'AllContacts',
    SuppliersOnly: 'SuppliersOnly',
    CustomersOnly: 'CustomersOnly',
    ExactValues: 'ExactValues',
    NegativeExactValues: 'NegativeExactValues',
} as const;

export type ValueOf<T> = T[keyof T];

export type ConditionTypeValue = ValueOf<typeof conditionTypeValues>;
