import { Box, List, Text } from '@approvalmax/ui/src/components';
import uniq from 'lodash/uniq';
import { FC, memo, useMemo } from 'react';

import { getWarnings } from './WarningList.helpers';
import { messages } from './WarningList.messages';
import { WarningListProps } from './WarningList.types';

const WarningList: FC<WarningListProps> = memo((props) => {
    const { activeWorkflow, selectedWorkflows } = props;

    const warningList = useMemo(() => {
        const items = selectedWorkflows.reduce<string[]>(
            (acc, template) => [...acc, ...getWarnings(activeWorkflow, template)],
            []
        );

        return uniq(items);
    }, [activeWorkflow, selectedWorkflows]);

    if (!activeWorkflow || !selectedWorkflows.length) return null;

    return (
        <Box spacing='24 0 0'>
            <Text font='headline' fontSize='xsmall' as='h3' spacing='0 0 12'>
                {messages.warningTitle}
            </Text>

            {warningList.length > 0 ? (
                <List removeLastSpacing>
                    {warningList.map((warning) => (
                        <List.Item key={warning}>{`– ${warning}`}</List.Item>
                    ))}
                </List>
            ) : (
                <Text font='body' color='midnight70'>
                    {messages.noWarnings}
                </Text>
            )}
        </Box>
    );
});

export default WarningList;
