import { colorTextByBg, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { AvatarProps } from './Avatar.types';

export const Image = styled.img<StyledMods<Pick<AvatarProps, 'size' | 'shape'>>>`
    border-radius: 50%;
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    display: inline-block;
    background-color: ${theme.color.midnight30};
    object-fit: cover;

    ${mods('shape', 'square')`
        border-radius: ${theme.radius.xsmall};
    `};
`;

export const Root = styled.div<StyledMods<Pick<AvatarProps, 'size' | 'color' | 'shape'>>>`
    border-radius: 50%;
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    font-size: ${({ $size }) => $size && $size / 2.5}px;
    line-height: 1;
    text-transform: uppercase;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    ${mods.color(
        (value) => css`
            background-color: ${theme.color[value]};
            color: ${theme.color[colorTextByBg[value]]};
        `
    )};

    ${mods('shape', 'square')`
        border-radius: ${theme.radius.xsmall};
    `};
`;
