import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { timeBlockSize } from '../../DigitalClock.constants';

export const StyledBox = styled.li<StyledMods<Mods<'selected' | 'disabled'>>>`
    width: ${timeBlockSize}px;
    height: ${timeBlockSize}px;
    border-radius: ${theme.radius.xsmall};
    color: ${theme.color.midnight80};
    user-select: none;
    cursor: pointer;
    transition:
        color ${theme.duration.fast}ms ease-in-out,
        background-color ${theme.duration.fast}ms ease-in-out;

    display: flex;
    justify-content: center;
    align-items: center;

    ${mods.selected.true`
        color: ${theme.color.white100};
        background-color: ${theme.color.blue80};

        ${mods.disabled.false`
            &:hover,:focus,:focus-visible {
                color: ${theme.color.white100};
                background-color: ${theme.color.blue100};
            }
        `}
    `}

    ${mods.selected.false`
        ${mods.disabled.false`
            &:hover,:focus,:focus-visible {
                color: ${theme.color.midnight100};
                background-color: ${theme.color.silver80};
            }
        `}
    `}

    ${mods.disabled.true`
        color: ${theme.color.midnight50};
        cursor: default;
    `}
`;
