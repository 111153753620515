import styled from 'styled-components';

export const Root = styled.div`
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    height: 60px;
`;

export const Icon = styled.div`
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

export const Text = styled.div`
    padding-right: 40px;
`;
