import { domain } from 'modules/data';
import { StartTrialPopupOrigin } from 'modules/profile/actions';

export function workflowPaywallOrigin(code: domain.IntegrationCode) {
    let origin: StartTrialPopupOrigin = code;

    switch (code) {
        case domain.IntegrationCode.XeroAirwallexBatchPayment:
            origin = 'airwallex_batch_payments_wf';
            break;

        case domain.IntegrationCode.XeroAmaxPayBatchPayment:
            origin = 'amax_pay_batch_payments_wf';
            break;

        case domain.IntegrationCode.XeroContact:
            origin = 'contact_approvals_wf';
            break;

        case domain.IntegrationCode.XeroBillBatchPayment:
            origin = 'batch_payments_wf';
            break;

        case domain.IntegrationCode.XeroQuote:
            origin = 'quote_worflow_wf';
            break;

        case domain.IntegrationCode.XeroManualJournal:
            origin = 'manual_journal_wf';
            break;

        case domain.IntegrationCode.QBooksVendor:
            origin = 'vendor_approvals_wf';
            break;

        case domain.IntegrationCode.QBooksJournalEntry:
            origin = 'journal_entry_wf';
            break;
    }

    return origin;
}
