import { LoadingBar, TextButton } from '@approvalmax/ui';
import React, { memo, useCallback } from 'react';

import { messages } from './SyncButton.messages';
import { Cell, LoadingBarContainer } from './SyncButton.styles';
import { SyncButtonProps } from './SyncButton.types';

const SyncButton = memo<SyncButtonProps>((props) => {
    const { meta, item } = props;

    const { syncCacheType, cacheTypesDisabled } = meta;

    const onButtonClick = useCallback(() => {
        syncCacheType(item.refDataType);
    }, [syncCacheType, item]);

    const isCacheTypeDisabled = React.useMemo(() => {
        return Boolean(cacheTypesDisabled.find((type) => type === item.refDataType));
    }, [cacheTypesDisabled, item]);

    return (
        <Cell {...props}>
            {item.isPulling || isCacheTypeDisabled ? (
                <LoadingBarContainer>
                    <LoadingBar />
                </LoadingBarContainer>
            ) : (
                <TextButton execute={onButtonClick}>{messages.syncNow}</TextButton>
            )}
        </Cell>
    );
});

export default SyncButton;
