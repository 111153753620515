import { FC, memo } from 'react';

import { Navigation } from '../Navigation/Navigation';
import { useWorkflowsLinkItems } from './WorkflowsMenu.hooks';
import { WorkflowsMenuProps } from './WorkflowsMenu.types';

export const WorkflowsMenu: FC<WorkflowsMenuProps> = memo((props) => {
    const { company, onClick } = props;

    const workflowsLinkItems = useWorkflowsLinkItems(company);

    return <Navigation items={workflowsLinkItems} onClick={onClick} />;
});

WorkflowsMenu.displayName = 'WorkflowsMenu';
