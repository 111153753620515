import { useEffect } from 'react';

import { api } from './index';
import { ApiServiceActiveOperationEvent, ApiServiceRequestErrorEvent, ApiServiceRequestSuccessEvent } from './types';

export const useApiActiveOperations = (onRequestError: (data: ApiServiceActiveOperationEvent) => void) => {
    useEffect(() => {
        api.addEventListener('activeOperation', onRequestError);

        return () => api.removeEventListener('activeOperation', onRequestError);
    }, [onRequestError]);
};

export const useApiRequestError = (onRequestError: (data: ApiServiceRequestErrorEvent) => void) => {
    useEffect(() => {
        api.addEventListener('requestError', onRequestError);

        return () => api.removeEventListener('requestError', onRequestError);
    }, [onRequestError]);
};

export const useApiRequestSuccess = (onRequestSuccess: (data: ApiServiceRequestSuccessEvent) => void) => {
    useEffect(() => {
        api.addEventListener('requestSuccess', onRequestSuccess);

        return () => api.removeEventListener('requestSuccess', onRequestSuccess);
    }, [onRequestSuccess]);
};
