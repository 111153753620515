import { miscHelpers } from '@approvalmax/utils';
import { State, stateTree } from 'modules/data';
import { AnyAction, combineReducers } from 'redux';

import allModules from '../helpers/allModules';
import allPagesSlices from '../helpers/allPagesSlices';

const composeReducers = <T>(reducers: Array<(state: T, action: AnyAction) => T>, initialState: T) => {
    const composedReducer = (state: T | undefined, action: AnyAction): T => {
        if (!state) {
            state = initialState;
        }

        for (let r of reducers) {
            state = r(state, action);
        }

        return state;
    };

    return composedReducer;
};

const getReducers = (key: keyof State): Array<(state: State[typeof key], action: AnyAction) => State[typeof key]> => {
    return [...allModules, ...allPagesSlices].map((x) => (x.reducers || {})[key]).filter(miscHelpers.notEmptyFilter);
};

const pagesReducer = combineReducers<stateTree.State['pages']>(
    allPagesSlices.reduce<{ [pageId: string]: (state: unknown, action: AnyAction) => unknown }>((acc, reducer) => {
        if (reducer.id && reducer.pageReducer) {
            acc[reducer.id] = reducer.pageReducer;
        }

        return acc;
    }, {})
);

const modulesReducer = combineReducers<stateTree.State['modules']>(
    allModules.reduce<{
        [moduleId: string]: (state: unknown, action: AnyAction) => unknown;
    }>((acc, reducer) => {
        if (reducer.moduleReducer) {
            acc[reducer.id] = reducer.moduleReducer;
        }

        return acc;
    }, {})
);

const entitiesReducer = composeReducers<State['entities']>(getReducers('entities'), {
    companies: {},
    companyInvitations: {},
    practiceInvitations: {},
    integrations: {},
    subscriptions: {},
    users: {},
    requests: {},
    fields: {},
    templates: {},
    reportConfigs: {},
});
const metaReducer = composeReducers<State['meta']>(getReducers('meta'), {
    trackingFieldsOrder: {},
    loadedFields: [],
    creatableTemplates: [],
    viewableTemplates: [],
    allWorkflows: [],
});
const sessionReducer = composeReducers<State['session']>(getReducers('session'), {
    authenticated: false,
    startup: {
        needsToCompleteSignupWizard: undefined,
    },
    contextInitializationCompleted: false,
});
const profileReducer = composeReducers<State['profile']>(getReducers('profile'), null);
const dataProvidersReducer = composeReducers<State['dataProviders']>(getReducers('dataProviders'), {});
const navigationReducer = composeReducers<State['navigation']>(getReducers('navigation'), {
    activePageId: null,
    activeCompany: null,
    discardChangesPopup: null,
});
const userPreferencesReducer = composeReducers<State['userPreferences']>(getReducers('userPreferences'), {});
const uiReducer = composeReducers<State['ui']>(getReducers('ui'), {
    showGlobalLoadingCounter: 0,
    isBlockedCounter: 0,
    asyncOperationsInProgress: [],
    toasts: [],
    popup: null,
});

const rootReducer = (state: State | undefined, action: AnyAction): State => {
    return {
        entities: entitiesReducer(state?.entities, action),
        meta: metaReducer(state?.meta, action),
        session: sessionReducer(state?.session, action),
        profile: profileReducer(state?.profile, action),
        dataProviders: dataProvidersReducer(state?.dataProviders, action),
        pages: pagesReducer(state?.pages, action),
        modules: modulesReducer(state?.modules, action),
        navigation: navigationReducer(state?.navigation, action),
        userPreferences: userPreferencesReducer(state?.userPreferences, action),
        ui: uiReducer(state?.ui, action),
    };
};

export default rootReducer;
