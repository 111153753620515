import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetRequestLegacy } from 'shared/data';

import { authApiPaths } from '../paths';
import {
    UseGetXeroOAuthUrlQueryParams,
    UseGetXeroOAuthUrlResponse,
    UseGetXeroOAuthUrlResponseBackend,
} from './useGetXeroOAuthUrl.types';

export const useGetXeroOAuthUrl = (
    params: QueryParams<UseGetXeroOAuthUrlQueryParams>,
    queryOptions?: QueryOptions<UseGetXeroOAuthUrlResponseBackend, UseGetXeroOAuthUrlResponse>
) => {
    return useGetRequestLegacy<UseGetXeroOAuthUrlResponseBackend, UseGetXeroOAuthUrlResponse>(
        authApiPaths.xeroOAuthUrl,
        params,
        {
            mapData: objectHelpers.pascalCaseToCamelCase,
            queryOptions: {
                ...queryOptions,
                enabled: enabledByParams<UseGetXeroOAuthUrlQueryParams>(params, queryOptions),
            },
        }
    );
};
