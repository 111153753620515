import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, CREATE_QBOOKS_VENDOR_RESPONSE, SHOW_BOOKS_VENDOR_CREATION_POPUP } from '../../../actions';
import { EditableQBooksVendor } from '../../../data/qbooks/EditableQBooksCounterparty';

export const QBOOKS_CONTACT_CREATION_POPUP = 'QBOOKS_CONTACT_CREATION_POPUP';

export interface QBooksContactCreationPopupData {
    id: typeof QBOOKS_CONTACT_CREATION_POPUP;
    initVendor: EditableQBooksVendor;
}

export type QBooksContactCreationPopupType = ImmutableObject<QBooksContactCreationPopupData> | null;

function qbooksVendorCreationPopupReducer(
    state: QBooksContactCreationPopupType,
    action: Action
): QBooksContactCreationPopupType {
    switch (action.type) {
        case SHOW_BOOKS_VENDOR_CREATION_POPUP:
            return immutable<QBooksContactCreationPopupData>({
                id: QBOOKS_CONTACT_CREATION_POPUP,
                initVendor: action.payload.vendor,
            });

        case CREATE_QBOOKS_VENDOR_RESPONSE:
            return null;

        default:
            return state;
    }
}

export default qbooksVendorCreationPopupReducer;
