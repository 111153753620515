import { Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { Root } from './FeatureButton.styles';
import { FeatureButtonProps } from './FeatureButton.types';

export const FeatureButton: FC<FeatureButtonProps> = memo((props) => {
    const { title, active, disabled, onClick } = props;

    return (
        <Root $active={active} $disabled={disabled} onClick={onClick}>
            <Text font='label' fontSize='small'>
                {title}
            </Text>
        </Root>
    );
});

FeatureButton.displayName = 'FeatureButton';
