import { EventHandler } from './types';

export class EventRouterService<TEventMap> {
    #listeners: Record<any, Array<any>> = {};

    private getHandlersForEvent<TType extends keyof TEventMap, THandler = EventHandler<TEventMap[TType]>>(
        eventType: TType
    ) {
        let eventHandlers = this.#listeners[eventType] as THandler[];

        if (!eventHandlers) {
            eventHandlers = [];
            this.#listeners[eventType] = eventHandlers;
        }

        return eventHandlers;
    }

    constructor() {
        this.addEventListener = this.addEventListener.bind(this);
        this.removeEventListener = this.removeEventListener.bind(this);
    }

    public addEventListener<TType extends keyof TEventMap, THandler = EventHandler<TEventMap[TType]>>(
        eventType: TType,
        handler: THandler
    ) {
        const handlerList = this.getHandlersForEvent<TType, THandler>(eventType);
        const index = handlerList.indexOf(handler);

        if (index !== -1) {
            return;
        }

        handlerList.push(handler);
    }

    public removeEventListener<TType extends keyof TEventMap, THandler = EventHandler<TEventMap[TType]>>(
        eventType: TType,
        handler: THandler
    ) {
        const handlerList = this.getHandlersForEvent<TType, THandler>(eventType);
        const index = handlerList.indexOf(handler);

        if (index === -1) {
            return;
        }

        handlerList.splice(index, 1);
    }

    public trigger<TType extends keyof TEventMap>(eventType: TType, event: TEventMap[TType]) {
        const handlerList = this.getHandlersForEvent<TType>(eventType);

        handlerList.forEach((handler) => handler(event));
    }

    public clearListeners() {
        this.#listeners = {};
    }
}
