import { Guid } from '@approvalmax/types';

import { Action, LOAD_PAGE_DATA } from '../../actions';

export type ActiveTemplateIdType = Guid | null;

const INITIAL_STATE: ActiveTemplateIdType = null;

export default function (state = INITIAL_STATE, action: Action): ActiveTemplateIdType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return action.payload.templateId;

        default:
            return state;
    }
}
