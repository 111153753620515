import { RequestDataParams } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { UseEditXeroBudgetData, UseEditXeroBudgetResponse } from './useEditXeroBudget.types';

export const useEditXeroBudget = () => {
    const queryClient = useQueryClient();

    return useMutateLegacy<RequestDataParams<UseEditXeroBudgetData>, UseEditXeroBudgetResponse>(
        budgetsApiPaths.editXeroBudget,
        {
            mutationOptions: {
                onSettled: async (_data, _error, requestData) => {
                    await queryClient.invalidateQueries([
                        budgetsApiPaths.editXeroBudget,
                        { companyId: requestData.data.companyId },
                    ]);
                },
            },
        }
    );
};
