import { State } from 'modules/data';
import * as redux from 'redux';

export enum ActionKind {
    Standard = 'Standard',
    AsyncRequest = 'AsyncRequest',
    AsyncResponse = 'AsyncResponse',
    AsyncFailure = 'AsyncFailure',
}

export interface ActionMeta {
    kind?: ActionKind;
    /**
     * The ID of the operation. Used so that the UI can detect a particular
     * operation in progress and block the right section of the UI.
     */
    operationId?: string;
    /**
     * Indicates whether this async action should activate the global loading bar
     * @default true
     */
    showAsyncLoadingBar?: boolean;
    /**
     * Indicates whether this async action should block the whole UI.
     * Examples of blocking actions:
     * - "Delete Company" button (affects all UI)
     * - "Apply settings" button inside a popup (blocks the whole popup => all UI for simplicity)
     * @default false
     */
    isBlocking?: boolean;
}

export interface Action<T extends string, P, M extends ActionMeta, TEntities> {
    type: T;
    payload: P;
    error?: Error;
    meta?: M;
    entities?: TEntities;
}

export type ThunkAction<TState = State, TReturnType = void> = (
    dispatch: <TAction>(action: TAction) => any,
    getState: () => TState
) => Promise<TReturnType> | TReturnType;

export type AnyAction = redux.AnyAction;
