import type { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data/api';
import type {
    UseAmaxPayConsentPathParams,
    UseAmaxPayConsentResponse,
} from 'shared/data/queries/paymentServices/useAmaxPayConsent/useAmaxPayConsent.types';

import { paymentServicesApiPaths } from '../paths';

export const useAmaxPayConsent = (
    pathParams: UseAmaxPayConsentPathParams,
    queryOptions: QueryOptions<UseAmaxPayConsentResponse>
) =>
    useGetRequestLegacy<UseAmaxPayConsentResponse>(paymentServicesApiPaths.getAmaxPayConsent, pathParams, {
        pathParams,
        queryOptions,
        method: 'get',
    });
