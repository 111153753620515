import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.NewRequestPopup.Content', {
    title: 'Create new',
    headerButton: 'Create',
    searchPlaceholder: 'Search...',
    readonlyBadge: 'Read-only',
    integrationDisconnected: 'Disconnected',
    airwallexDisconnected: 'Disconnected from Airwallex',
    amCaptureBadge: 'ApprovalMax Capture',
});
