import { useTheme } from '@approvalmax/theme';
import { AmLogo, usePopupContext } from '@approvalmax/ui';
import { CloseIcon } from 'modules/sprites';
import { FC, memo } from 'react';
import { useMedia } from 'react-use';

import { messages } from './HeaderWithLogo.messages';
import {
    LogoContainer,
    LogoText,
    Root,
    StyledCloseButton,
    StyledLoadingBar,
    Title,
    TitleLine,
} from './HeaderWithLogo.styles';
import { HeaderWithLogoProps } from './HeaderWithLogo.types';

const HeaderWithLogo: FC<HeaderWithLogoProps> = memo((props) => {
    const { className, loading, title, showCloseButton, buttons } = props;

    const { onRequestClose } = usePopupContext();

    const { theme } = useTheme();
    const isMobileScreen = useMedia(`(max-width: ${theme.container.breakpoint.xsmall})`);

    const logoSize = isMobileScreen ? { width: 200, height: 39 } : { width: 85, height: 16 };

    return (
        <Root className={className}>
            {showCloseButton && (
                <StyledCloseButton execute={onRequestClose} disabled={loading}>
                    <CloseIcon />
                </StyledCloseButton>
            )}

            <LogoContainer>
                {!isMobileScreen && <LogoText>{messages.logoText}</LogoText>}

                <AmLogo width={logoSize.width} height={logoSize.height} />
            </LogoContainer>

            <TitleLine>
                <Title>{title}</Title>

                {buttons}
            </TitleLine>

            <StyledLoadingBar $active={loading} />
        </Root>
    );
});

export default HeaderWithLogo;
