import { dataProviders } from 'modules/common';

import { messages } from './BoolConditionCell.messages';
import { BooleanCellOptions } from './BoolConditionCell.types';

export const items = [
    dataProviders.FieldDataProvider.AnyValue,
    {
        id: BooleanCellOptions.Enabled,
        text: messages.enabled,
    },
    {
        id: BooleanCellOptions.Disabled,
        text: messages.disabled,
    },
];
