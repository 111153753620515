import { FC, memo } from 'react';
import { useRecoilValue } from 'recoil';

import { AddIcon } from '../../../../icons';
import { Box } from '../../../Box/Box';
import { Button } from '../../../Button/Button';
import { Menu } from '../../../Menu/Menu';
import { collapseDrawerState, displayingDrawerState, openDrawerState, pinDrawerState } from '../../Drawer.states';
import { tooltipProps } from './Navigation.constants';
import { useLink, useSaveOpenedSections } from './Navigation.hooks';
import { NavigationProps } from './Navigation.types';

const Navigation: FC<NavigationProps> = memo((props) => {
    const { items, onClick, getActiveStatus, ...restProps } = props;

    const { routerPush, checkIfSubItemActive } = useLink({ onClick, getActiveStatus });
    const { checkIsOpenSection, toggleOpenSection } = useSaveOpenedSections();
    const isDrawerPinned = useRecoilValue(pinDrawerState);
    const isDrawerOpen = useRecoilValue(openDrawerState);
    const isDrawerCollapsed = useRecoilValue(collapseDrawerState);
    const isDrawerDisplaying = useRecoilValue(displayingDrawerState);

    const isShowSubItems = isDrawerPinned || (!isDrawerDisplaying && isDrawerOpen);

    if (!items.length) return null;

    return (
        <Menu color='midnight80' {...restProps}>
            {items.map(
                ({
                    name,
                    id: sectionId,
                    title,
                    to: originalTo,
                    dataQa,
                    dataQaName,
                    disabled,
                    disabledFullWidth,
                    disabledComponent,
                    subItems,
                    startIcon: StartIcon,
                    addButton,
                    available,
                    onClick,
                }) => {
                    if (!(available ?? true)) return null;

                    const to =
                        !isDrawerDisplaying && isDrawerCollapsed
                            ? originalTo || (subItems && subItems[0].to) || ''
                            : originalTo || '';

                    const isOpenSection = checkIsOpenSection(sectionId);
                    const isActiveSection = originalTo
                        ? getActiveStatus(originalTo)
                        : !isOpenSection && checkIfSubItemActive(subItems);
                    const sectionTitle =
                        isDrawerCollapsed && subItems && subItems.length
                            ? `${title || name}: ${subItems[0].name}`
                            : title || name;

                    let sectionName = isDrawerDisplaying || !isDrawerCollapsed ? name : '\u00A0';

                    return (
                        <Menu.Item
                            id={sectionId}
                            name={sectionName}
                            title={sectionTitle}
                            tooltip={!isDrawerDisplaying && isDrawerCollapsed}
                            tooltipProps={tooltipProps}
                            as={to ? 'a' : undefined}
                            href={to}
                            onClick={routerPush(to, onClick)}
                            active={isActiveSection}
                            open={isOpenSection}
                            onOpen={toggleOpenSection}
                            startIcon={<StartIcon size={24} color='midnight70' />}
                            endIcon={
                                addButton && (
                                    <Button
                                        as='a'
                                        href={addButton.to}
                                        onClick={routerPush(addButton.to)}
                                        title={addButton.title}
                                        icon
                                        noPadding
                                        color='transparent'
                                    >
                                        <AddIcon size={16} />
                                    </Button>
                                )
                            }
                            data-qa={dataQa}
                            data-qa-id={sectionId}
                            data-qa-name={dataQaName || name}
                            data-item-active={isActiveSection}
                            key={sectionId}
                            aria-current={to ? 'page' : undefined}
                        >
                            {isShowSubItems ? (
                                disabled ? (
                                    <Box spacing={disabledFullWidth ? '8 12' : '8 12 8 48'}>{disabledComponent}</Box>
                                ) : (
                                    subItems &&
                                    subItems.length > 0 && (
                                        <Box spacing='0 0 0 36'>
                                            <Menu color='midnight80'>
                                                {subItems?.map(
                                                    (
                                                        { to, name, id, title, available, dataQa, dataQaName, onClick },
                                                        index
                                                    ) =>
                                                        (available ?? true) && (
                                                            <Menu.Item
                                                                name={name}
                                                                title={title}
                                                                as='a'
                                                                href={to}
                                                                onClick={routerPush(to, onClick)}
                                                                active={isOpenSection && getActiveStatus(to)}
                                                                key={id || name + index}
                                                                data-qa={dataQa}
                                                                data-qa-name={dataQaName || id}
                                                                data-item-active={isOpenSection && getActiveStatus(to)}
                                                                aria-current='page'
                                                            />
                                                        )
                                                )}
                                            </Menu>
                                        </Box>
                                    )
                                )
                            ) : null}
                        </Menu.Item>
                    );
                }
            )}
        </Menu>
    );
});

Navigation.displayName = 'Navigation';

export default Navigation;
