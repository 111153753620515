import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { requestsApiPaths, useGetRequest } from 'shared/data';

import {
    UseGetRequestDuplicatesNetSuiteParams,
    UseGetRequestDuplicatesNetSuitePathParams,
    UseGetRequestDuplicatesNetSuiteResponse,
} from './useGetRequestDuplicatesNetSuite.types';

export const useGetRequestDuplicatesNetSuite = (
    pathParams: UseGetRequestDuplicatesNetSuitePathParams,
    params: QueryParams<UseGetRequestDuplicatesNetSuiteParams>,
    queryOptions?: QueryOptions<UseGetRequestDuplicatesNetSuiteResponse>
) => {
    return useGetRequest<UseGetRequestDuplicatesNetSuiteResponse>(requestsApiPaths.duplicatesNetSuiteBill, {
        pathParams,
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<UseGetRequestDuplicatesNetSuiteParams>(params, queryOptions),
        },
        apiVersion: 'v2',
    });
};
