import { TextButton } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { loadContactSupportPopup } from 'modules/profile';
import { useSelector } from 'modules/react-redux';
import { BigWarnIcon } from 'modules/sprites';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { cancelCompanyConnectionErrorPopup } from '../../actions';
import { getActiveCompanyConnectionErrorPopup } from '../../selectors/moduleSelectors';
import { messages } from './AnotherCompanyIntegratedPopup.messages';
import { Buttons, Description, LeftCol, RightCol, Root, Title } from './AnotherCompanyIntegratedPopup.styles';

const AnotherCompanyIntegratedPopup = memo(() => {
    const dispatch = useDispatch();
    const data = useSelector(getActiveCompanyConnectionErrorPopup);

    const showContactSupportPopup = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();

            dispatch(loadContactSupportPopup());
        },
        [dispatch]
    );

    const onCancel = useCallback(() => dispatch(cancelCompanyConnectionErrorPopup()), [dispatch]);

    if (!data) {
        return null;
    }

    const integrationName = selectors.integration.getIntegrationTypeName(data.integrationType);

    return (
        <Root>
            <LeftCol>
                <BigWarnIcon width={70} height={60} />
            </LeftCol>

            <RightCol>
                <Title>{messages.title({ integrationName })}</Title>

                <Description>
                    {messages.description({
                        integrationName,
                        supportlink: (chunks: any) => <a onClick={showContactSupportPopup}>{chunks}</a>,
                    })}
                </Description>

                <Buttons>
                    <TextButton execute={onCancel} colorTheme='dark-grey'>
                        {messages.cancelButtonText}
                    </TextButton>
                </Buttons>
            </RightCol>
        </Root>
    );
});

export default AnotherCompanyIntegratedPopup;
