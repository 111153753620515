import { ExactAsyncConditionBaseProps } from '../ExactAsyncCondition/ExactAsyncCondition.types';

export interface QBooksCustomerCreationConditionCellProps extends ExactAsyncConditionBaseProps {}

export enum ConditionTypeValues {
    AllContacts = 'AllContacts',
    CustomersOnly = 'CustomersOnly',
    ExactValues = 'ExactValues',
    NegativeExactValues = 'NegativeExactValues',
}
