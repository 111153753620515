import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { budgetsApiPaths, UseGetQBooksBudgetsResponse, useMutate } from 'shared/data';

import {
    UseUpdateQBooksBudgetSettingsData,
    UseUpdateQBooksBudgetSettingsPathParams,
    UseUpdateQBooksBudgetSettingsResponse,
} from './useUpdateQBooksBudgetSettings.types';

export const useUpdateQBooksBudgetSettings = (
    mutationOptions?: MutationOptions<
        UseUpdateQBooksBudgetSettingsResponse,
        RequestDataParams<UseUpdateQBooksBudgetSettingsData, UseUpdateQBooksBudgetSettingsPathParams>
    >
) => {
    const queryClient = useQueryClient();

    return useMutate<
        RequestDataParams<UseUpdateQBooksBudgetSettingsData, UseUpdateQBooksBudgetSettingsPathParams>,
        UseUpdateQBooksBudgetSettingsResponse
    >(budgetsApiPaths.qBooksBudget, {
        method: 'patch',
        apiVersion: 'v2',
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (responseData, requestData, context) => {
                const budgetListApiUrl = routerHelpers.pathToUrl(budgetsApiPaths.getQBooksBudgets, {
                    companyId: requestData.pathParams?.companyId,
                });

                queryClient.setQueryData([budgetListApiUrl], (current: UseGetQBooksBudgetsResponse) => {
                    return {
                        ...current,
                        items: current.items.map((item) => {
                            if (item.budgetId === responseData.budgetId) {
                                return responseData;
                            }

                            return item;
                        }),
                    };
                });

                mutationOptions?.onSuccess?.(responseData, requestData, context);
            },
        },
    });
};
