import { Box, Table, Text } from '@approvalmax/ui/src/components';
import { ColumnDefinition } from '@approvalmax/ui/src/components/Table/Table.types';
import { selectors } from 'modules/common';

import CopyRuleWorkflowStepsCell from '../CopyRuleWorkflowStepCell/CopyRuleWorkflowStepCell';
import { messages } from './CopyRulePopup.messages';
import { TableOptions } from './CopyRulePopup.types';

export const getTableColumns = (options: TableOptions): ColumnDefinition<selectors.types.ExpandedCompanyUser>[] => {
    const { meta, onAction, errors, withWorkflowSteps } = options;

    const result: ColumnDefinition<selectors.types.ExpandedCompanyUser>[] = [
        {
            id: 'name',
            name: messages.name,
            cellComponent: (props) => (
                <Table.Cell {...props}>
                    <Box width={152} title={props.item.displayName}>
                        <Text font='label' ellipsis={1}>
                            {props.item.displayName}
                        </Text>
                    </Box>
                </Table.Cell>
            ),
        },
        {
            id: 'email',
            name: messages.email,
            cellComponent: (props) => (
                <Table.Cell {...props}>
                    <Box width={152} title={props.item.userEmail}>
                        <Text font='label' ellipsis={1}>
                            {props.item.userEmail}
                        </Text>
                    </Box>
                </Table.Cell>
            ),
        },
        {
            id: 'role',
            name: messages.role,
            cellComponent: (props) => {
                const user = props.item;
                const isAccountOwner = user.id === meta.company.author;

                const roleText = isAccountOwner
                    ? messages.accountOwner
                    : selectors.company.getCompanyUserRoleText(user.role);

                return (
                    <Table.Cell {...props}>
                        <Box width={120} title={roleText}>
                            <Text font='label' ellipsis={1}>
                                {roleText}
                            </Text>
                        </Box>
                    </Table.Cell>
                );
            },
        },
    ];

    if (withWorkflowSteps) {
        result.push({
            id: 'workflowSteps',
            name: messages.workflowSteps,
            cellComponent: (props) => {
                return <CopyRuleWorkflowStepsCell {...props} onAction={onAction} errors={errors} meta={meta} />;
            },
            width: 300,
        });
    }

    return result;
};
