import { Guid } from '@approvalmax/types';
import { addArrayItem, immutable, ImmutableArray, removeArrayItem } from 'modules/immutable';

import {
    Action,
    CANCEL_REQUEST,
    CANCEL_REQUEST_FAILURE,
    CANCEL_REQUEST_RESPONSE,
    CLONE_REQUEST,
    CLONE_REQUEST_FAILURE,
    CLONE_REQUEST_RESPONSE,
    DELETE_REQUEST,
    DELETE_REQUEST_FAILURE,
    DELETE_REQUEST_RESPONSE,
    EDIT_STEP_PARTICIPANTS,
    EDIT_STEP_PARTICIPANTS_FAILURE,
    EDIT_STEP_PARTICIPANTS_RESPONSE,
    MAKE_DECISION,
    MAKE_DECISION_FAILURE,
    MAKE_DECISION_RESPONSE,
    MAKE_FORCE_DECISION,
    MAKE_FORCE_DECISION_FAILURE,
    MAKE_FORCE_DECISION_RESPONSE,
    REASSIGN_REQUEST,
    REASSIGN_REQUEST_FAILURE,
    REASSIGN_REQUEST_RESPONSE,
    RELOAD_REQUEST,
    RELOAD_REQUEST_FAILURE,
    RELOAD_REQUEST_RESPONSE,
    RELOAD_REQUEST_SYNC,
    RELOAD_REQUEST_SYNC_FAILURE,
    RELOAD_REQUEST_SYNC_RESPONSE,
    REVOKE_DECISION,
    REVOKE_DECISION_FAILURE,
    REVOKE_DECISION_RESPONSE,
    START_OVER_REQUEST,
    START_OVER_REQUEST_FAILURE,
    START_OVER_REQUEST_RESPONSE,
    SUBMIT_REQUEST,
    SUBMIT_REQUEST_FAILURE,
    SUBMIT_REQUEST_RESPONSE,
} from '../../actions';

export type LockedRequestsType = ImmutableArray<Guid>;

const INITIAL_STATE: LockedRequestsType = immutable([]);

export default function (state = INITIAL_STATE, action: Action): LockedRequestsType {
    switch (action.type) {
        case RELOAD_REQUEST:
        case RELOAD_REQUEST_SYNC:
        case CLONE_REQUEST:
        case CANCEL_REQUEST:
        case DELETE_REQUEST:
        case SUBMIT_REQUEST:
        case START_OVER_REQUEST:
        case REVOKE_DECISION:
        case EDIT_STEP_PARTICIPANTS:
        case REASSIGN_REQUEST:
            return addArrayItem(state, action.payload.requestId);

        case MAKE_DECISION:
        case MAKE_FORCE_DECISION:
            return addArrayItem(state, action.payload.target.id);

        case RELOAD_REQUEST_RESPONSE:
        case RELOAD_REQUEST_SYNC_RESPONSE:
        case RELOAD_REQUEST_FAILURE:
        case RELOAD_REQUEST_SYNC_FAILURE:
        case CLONE_REQUEST_RESPONSE:
        case CLONE_REQUEST_FAILURE:
        case MAKE_DECISION_RESPONSE:
        case MAKE_DECISION_FAILURE:
        case MAKE_FORCE_DECISION_RESPONSE:
        case MAKE_FORCE_DECISION_FAILURE:
        case CANCEL_REQUEST_RESPONSE:
        case CANCEL_REQUEST_FAILURE:
        case DELETE_REQUEST_RESPONSE:
        case DELETE_REQUEST_FAILURE:
        case SUBMIT_REQUEST_RESPONSE:
        case SUBMIT_REQUEST_FAILURE:
        case START_OVER_REQUEST_RESPONSE:
        case START_OVER_REQUEST_FAILURE:
        case REVOKE_DECISION_RESPONSE:
        case REVOKE_DECISION_FAILURE:
        case EDIT_STEP_PARTICIPANTS_RESPONSE:
        case EDIT_STEP_PARTICIPANTS_FAILURE:
        case REASSIGN_REQUEST_RESPONSE:
        case REASSIGN_REQUEST_FAILURE:
            return removeArrayItem(state, action.payload.requestId);

        default:
            return state;
    }
}
