import { Mods, mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { font } from '../../styles';

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const FlowItemsContainer = styled.div`
    display: flex;
`;

export const TextsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Text = styled.div<StyledMods<Mods<'last' | 'first'> & { future: boolean; maxWidthPercent: number }>>`
    margin-top: 8px;
    ${font(12, '#477753', 'semibold')}
    text-align: center;

    ${mods('future', true)`
      ${font(12, '#5e5c5c')}
    `};

    ${mods.last.true`
      text-align: right;
    `};

    ${mods.first.true`
      text-align: left;
    `};

    ${(props) => css`
        max-width: ${props.$maxWidthPercent}%;
    `}
`;
