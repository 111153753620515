import { Checkbox, Spacing } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

import { ExactAsyncConditionCell } from '../ExactAsyncConditionCell/ExactAsyncConditionCell';
import { ExactAsyncConditionCellProps } from '../ExactAsyncConditionCell/ExactAsyncConditionCell.types';
import { messages } from './QBooksVendorCreationConditionCell.messages';

const qa = bemFactory.qa('wfc-qbooks-vendor-condition-cell');

const QBooksVendorCreationConditionCell: FC<ExactAsyncConditionCellProps> = memo((props) => {
    const { rule, field, readonly, lineId, condition, onConditionChange } = props;

    const isServerCondition = condition.conditionType === domain.ConditionType.ServerCondition;
    const isAlwaysTrueCondition = condition.conditionType === null;

    const onChange = useCallback(
        (checked: boolean) => {
            const newCondition: domain.ServerCondition | domain.AlwaysTrueCondition = checked
                ? {
                      fieldId: condition.fieldId,
                      fieldName: condition.fieldName,
                      fieldSystemPurpose: condition.fieldSystemPurpose,
                      conditionType: domain.ConditionType.ServerCondition,
                      serverConditionType: domain.ServerConditionType.AllContacts,
                      allowCreation: true,
                  }
                : {
                      fieldId: condition.fieldId,
                      fieldName: condition.fieldName,
                      fieldSystemPurpose: condition.fieldSystemPurpose,
                      conditionType: null,
                  };

            onConditionChange(lineId, rule, field, newCondition);
        },
        [condition, field, lineId, onConditionChange, rule]
    );

    return (
        <div>
            <ExactAsyncConditionCell {...props} dataQa={qa()} />

            <Spacing height={4} />

            {(isAlwaysTrueCondition || isServerCondition) && (
                <Checkbox
                    onChange={!readonly ? onChange : undefined}
                    checked={condition.allowCreation}
                    disabled={readonly}
                    size='xsmall'
                    data-qa={qa('allow-creation')}
                >
                    {messages.allowToCreateNewVendors}
                </Checkbox>
            )}
        </div>
    );
});

export default QBooksVendorCreationConditionCell;
