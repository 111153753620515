import { errorHelpers } from '@approvalmax/utils';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'localforage';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { createChameleonMiddleware } from 'services/chameleon/middleware';
import { createSentryMiddleware } from 'services/sentry/middleware';

import rootReducer from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';

export { allModuleComponents } from './helpers/allModules';
export { default as allPagesSlices } from './helpers/allPagesSlices';

const sagaMiddleware = createSagaMiddleware({
    onError(error) {
        errorHelpers.captureException(error);
    },
});
const sentryMiddleware = createSentryMiddleware({
    stateTransformer: () => {
        // At the moment we strip the state completely from the error report
        return {};
    },
});

const reducer = persistReducer(
    {
        key: 'root',
        storage,
        whitelist: ['userPreferences'],
    },
    rootReducer
);

const middlewares = [
    ...(window.ApprovalMax.flags.useExternalTutorials ? [createChameleonMiddleware()] : []),
    thunk,
    ...(process.env.DEBUG ? [createLogger({ collapsed: true })] : []),
    sagaMiddleware,
    sentryMiddleware,
];

const environment = window.ApprovalMax.environment;

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ serializableCheck: false }), ...middlewares],
    devTools: !['production', 'staging'].includes(environment),
});

/**
 * for tests
 */
export const setupStore = (preloadedState?: Partial<RootState>) => {
    const store = configureStore({
        reducer,
        middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ serializableCheck: false }), ...middlewares],
        preloadedState,
        devTools: !['production', 'staging'].includes(environment),
    });

    return store;
};

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export const clearPersistStore = async () => {
    await persistor.purge();
    await persistor.flush();
    await persistor.pause();
    await persistor.persist();
};

window.ApprovalMax.app = {
    getStore: () => store,
};

export type RootState = ReturnType<typeof reducer>;
