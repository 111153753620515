import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { redirectAndNotify } from '../helpers/redirectAndNotify';

export const useProtectedIntegrationType = (integrationTypes?: domain.IntegrationType[]) => {
    const [isProtected, setIsProtected] = useState(!integrationTypes);

    const { companyId } = useParams<{ companyId: string }>();

    const company = useSelector((state: State) => selectors.company.findCompanyById(state, companyId));
    const companyIntegration = company?.integration;

    useEffect(() => {
        if (companyId && integrationTypes && companyIntegration) {
            if (!companyIntegration.integrationType || !integrationTypes.includes(companyIntegration.integrationType)) {
                redirectAndNotify();
            } else {
                setIsProtected(true);
            }
        }
    }, [integrationTypes, companyIntegration, companyId]);

    return isProtected;
};
