import { ElementFactory } from 'react-bem-factory';

import { ChangeHandler } from '../../WorkflowSettingsPopupContent.types';

export interface SectionUrlOverridingProps {
    isAppUrlOverridingEnabled: boolean;
    onChangeUrlOverridingPolicy: ChangeHandler<boolean>;
    qa: ElementFactory;
    readonly: boolean;
}

export enum SectionUrlOverridingOption {
    yes = 'yes',
    no = 'no',
}
