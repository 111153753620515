import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.CopyWorkflowPopup.MultipleResultPopup', {
    successfulMessage: ' has been successfully copied to ',
    successfulNumber: '{number} workflows.',
    settingsErrorHeader: 'The copy of the workflow settings has failed for the following workflows:',
    matrixErrorHeader: 'The copy of the workflow matrix has failed for the following workflows:',
    activateWorkflow: "NOTE: Don't forget to activate your workflow!",
    done: 'Done',
});
