import { Reference } from '@approvalmax/types';
import { domain } from 'modules/data';
import { PaymentDetails } from 'shared/data';

export interface BillInvoiceTableGroupedBySupplierProps {
    companyId: string;
    selectableBills: domain.BatchPaymentSelectableBillItem[];
    toggleBill: (id: string) => void;
    toggleAllBills: () => void;
    toggleAllBillsBySupplierId: (supplierId: string) => void;
}

export enum TableColumn {
    Name = 'Name',
    Date = 'Date',
    DueDate = 'DueDate',
    PlannedDate = 'PlannedDate',
    Current = 'Current',
    LessThanOneMonth = 'LessThanOneMonth',
    OneMonth = 'OneMonth',
    TwoMonths = 'TwoMonths',
    ThreeMonths = 'ThreeMonths',
    Older = 'Older',
    AmountDue = 'AmountDue',
}

export enum ItemRole {
    SupplierName = 'SupplierName',
    BillItem = 'BillItem',
}

export interface SelectableSupplierBillItem
    extends Omit<domain.BatchPaymentSelectableBillItem, 'amaxPayBankAccount' | 'paymentDetails' | 'paymentReference'> {
    itemRole: ItemRole;
    tableColumn?: TableColumn;
    amaxPayBankAccount?: Reference | null;
    paymentDetails?: Omit<PaymentDetails, 'isDefault'> | null;
    paymentReference?: string | null;
}

export interface GetColumnsParams {
    companyId: string;
    toggleAllBills: BillInvoiceTableGroupedBySupplierProps['toggleAllBills'];
    allBillsAreSelected: boolean;
    toggleBill: BillInvoiceTableGroupedBySupplierProps['toggleBill'];
    toggleAllBillsBySupplierId: BillInvoiceTableGroupedBySupplierProps['toggleAllBillsBySupplierId'];
}
