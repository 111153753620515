import { FC, memo, PropsWithChildren, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { routeParamsState, routeState } from 'shared/states';

const RouterPageWrapper: FC<PropsWithChildren> = memo((props) => {
    const { children } = props;

    const route = useLocation();
    const params = useParams();

    const setRoute = useSetRecoilState(routeState);
    const setRouteParams = useSetRecoilState(routeParamsState);

    useEffect(() => setRouteParams(params), [params, setRouteParams]);
    useEffect(() => setRoute(route), [route, setRoute]);

    return <>{children}</>;
});

export default RouterPageWrapper;
