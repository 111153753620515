import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.SelectRBSubmitter', {
    bubbleText: 'Receive from Dext Prepare',
    submitterRequiredValidationText: 'Please select a Requester',
    learnMore: 'Learn more',
    addRequester: 'Add a Requester',
    emptyStepDescription:
        '{templateName} published via connected Dext Prepare account will be processed in ApprovalMax and published to {integrationName} after the final approval.',
    stepDescription:
        'All {templateName} received from Dext Prepare will be created in ApprovalMax under the name of this User.',
    noSubmitterErrorText: 'Specify a Dext submitter.',
    xeroCompany: 'Xero Organisation',
    qboCompany: 'QuickBooks Online Company',
});
