import type { ApiError, ApiVersion } from '@approvalmax/types';
import { routerHelpers } from '@approvalmax/utils';
import { useQuery } from '@tanstack/react-query';
import { ResponseType } from 'axios';

import { Env } from '../configs';
import { getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import rootApi from '../rootApi';
import { ApiSource, QueryOptions, QueryParams } from '../types';

export const createUseGetRequest = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    return <ResponseData, MappedResponseData = ResponseData>(
        path: string,
        options: {
            pathParams?: QueryParams<Record<string, string>>;
            mapData?: (data: ResponseData) => MappedResponseData;
            apiSource?: ApiSource;
            apiVersion?: ApiVersion;
            params?: QueryParams<Record<string, any>>;
            queryOptions?: QueryOptions<ResponseData, MappedResponseData>;
            responseType?: ResponseType;
        } = {}
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            params,
            queryOptions,
            pathParams,
            responseType,
            mapData,
        } = options;
        const url = routerHelpers.testPathByParams(path, pathParams) ? routerHelpers.pathToUrl(path, pathParams) : '';
        const apiUrl = getApiUrl(apiSource, defaultEnv, apiVersion);
        const Authorization = getAuthorization(defaultApiSource, defaultEnv);
        const handleApiCall = useHandleApiCall(mapData);

        const enabledFromQueryOptions = queryOptions?.enabled ?? true;

        return useQuery<MappedResponseData, ApiError>(
            [url, params].filter(Boolean),
            () =>
                handleApiCall(
                    rootApi({
                        baseURL: apiUrl,
                        method: 'GET',
                        url,
                        params,
                        headers: { ...(Authorization && { Authorization }) },
                        responseType,
                    })
                ),
            {
                ...queryOptions,
                enabled: enabledFromQueryOptions && Boolean(url),
                meta: {
                    meta: queryOptions?.meta,
                    path,
                    params,
                    pathParams,
                },
            }
        );
    };
};
