import { domain } from 'modules/data';
import { immutable } from 'modules/immutable';

import { Action, LOAD_PAGE_DATA, UPLOAD_ATTACHMENTS_RESPONSE } from '../../actions';
import { AttachmentKind } from '../../data/AttachmentKind';

export type SupplierAttachmentsType = domain.RequestAttachment[];

const INITIAL_STATE: SupplierAttachmentsType = [];

export default function (state = INITIAL_STATE, action: Action): SupplierAttachmentsType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return immutable([]);

        case UPLOAD_ATTACHMENTS_RESPONSE: {
            if (action.payload.attachmentKind === AttachmentKind.EmailToSupplierAttachment) {
                return [...state, ...action.payload.accepted];
            }

            return state;
        }

        default:
            return state;
    }
}
