import FilterType from './FilterType';

export interface NoneFilter {
    type: FilterType.None;
}

export function createAlwaysTrueFilter(): NoneFilter {
    return {
        type: FilterType.None,
    };
}
