import { errorHelpers } from '@approvalmax/utils';
import amplitude from 'amplitude-js';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { storageService } from 'services/storage';

class AmplituteService {
    public init(state: State) {
        try {
            const apiKey = window.ApprovalMax.amplitudeApiKey;

            if (!apiKey) {
                return;
            }

            const authenticated = selectors.session.authenticated(state);
            const isAdmin = selectors.profile.isManagerAtAnyOrganization(state);
            const isTwoFaHardEnforcementPage = storageService.getIsTwoFaHardEnforcement();
            const profile = selectors.profile.findProfile(state);

            const isSignUpSourceApp = profile?.signUpSource === domain.SignUpSource.App;

            /*
             * Amplitude is initialized during app launch for two cases:
             * 1. Either user is an admin in some company
             * 2. Or a user is trying to sign up and create a new account.
             * In this case, we need to filter out users who come via a company or practice
             * staff invitation because in the First Start Wizard,
             * all new users are equal, and we don’t know the context of origin.
             */
            if (authenticated && !isTwoFaHardEnforcementPage && !isAdmin && !isSignUpSourceApp) {
                return;
            }

            amplitude.getInstance().init(apiKey);

            if (authenticated && !isTwoFaHardEnforcementPage && (isSignUpSourceApp || isAdmin) && profile) {
                const companies = selectors.company.getCompanies(state);
                const isOwner = companies.some((l) => l.author === profile.email);
                const integrations = selectors.integration.getIntegrations(state);
                const hasXeroIntegrationType = integrations.some(
                    (item) => item.integrationType === domain.IntegrationType.Xero
                );
                const hasQBooksIntegrationType = integrations.some(
                    (item) => item.integrationType === domain.IntegrationType.QBooks
                );

                const userProperties = {
                    userProfileId: profile.id,
                    userProfileCreatedDate: profile.createdDate,
                    userRole: isOwner ? 'Owner' : 'Administrator',
                    accountType: profile.account?.accountType,
                    hasXeroConnection: hasXeroIntegrationType,
                    hasQBooksConnection: hasQBooksIntegrationType,
                    lifecycle: profile.isTrialUser ? 'trial' : 'paid',
                    accountId: profile.account?.accountId,
                };

                amplitude.getInstance().setUserProperties(userProperties);
                amplitude.getInstance().setUserId(profile.id);
            }
        } catch (error) {
            errorHelpers.captureException(error);
        }
    }

    public sendData(eventType: string, eventProperties?: any) {
        amplitude.getInstance().logEvent(eventType, eventProperties);
    }
}

export const amplitudeService = new AmplituteService();
