/**
 * @function
 * @param a Argument A.
 * @param b Argument B.
 * @return Result as follows:
 * <ul>
 *     <li><code>-1</code> - if A &lt; B.</li>
 *     <li><code>0</code> - if A == B.</li>
 *     <li><code>1</code> - if A &gt; B.</li>
 * </ul>
 * */
export const booleanComparator2Asc = (a: boolean | undefined, b: boolean | undefined) => {
    // true goes first
    if (a) {
        return b ? 0 : -1;
    }

    return b ? 1 : 0;
};
