import { backend, domain } from 'modules/data';
import { createAction, ExtractActions } from 'modules/react-redux';
import { emailToSupplierHelpers } from 'shared/helpers';

import { parseNetSuiteContext } from '../data/netsuite/NetSuiteContext';

export const loadNetSuiteRequestContext = (response: backend.RequestsRequestEditingContext) => {
    const context = parseNetSuiteContext(response.netSuiteContext);

    if (response.supplierEmailSettings) {
        context.supplierEmailSettings = {
            ...response.supplierEmailSettings,
            subject: emailToSupplierHelpers.escapeExpressions(response.supplierEmailSettings.subject || ''),
        };
    }

    if (response.documentFields) {
        context.documentFields = domain.schemas.template.mapDocumentFields(response.documentFields || []);
    }

    return context;
};

export const CHANGE_NETSUITE_REQUEST = 'REQUESTFORM/CHANGE_NETSUITE_REQUEST';
export const changeNetSuiteRequest = () => createAction(CHANGE_NETSUITE_REQUEST, {});

export type Action = ExtractActions<typeof changeNetSuiteRequest>;
