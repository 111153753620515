import { Popup } from '@approvalmax/ui/src/components';
import { closeActivePopup } from 'modules/company/actions';
import { ActivePopup } from 'modules/company/reducers/moduleReducer';
import { getActivePopup } from 'modules/company/selectors/moduleSelectors';
import { useDispatch, useSelector } from 'modules/react-redux';
import { memo, useCallback } from 'react';

import { Content } from './components';

export const SelectSubscriptionPopup = memo(() => {
    const dispatch = useDispatch();
    const activePopup = useSelector(getActivePopup);

    const handleCancelActivePopup = useCallback(
        (isOpen: boolean) => {
            if (!isOpen) {
                dispatch(closeActivePopup());
            }
        },
        [dispatch]
    );

    return (
        <Popup
            open={activePopup === ActivePopup.SelectSubscriptionPopup}
            onToggle={handleCancelActivePopup}
            size='medium'
            preventAutoClose
            closable={false}
        >
            <Content />
        </Popup>
    );
});

SelectSubscriptionPopup.displayName = 'SelectSubscriptionPopup';
