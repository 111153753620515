import { produce } from 'immer';
import { stateTree } from 'modules/data';

import { Action, LOAD_PAGE_DATA } from '../actions';

export default produce((draft: stateTree.Navigation, action: Action): stateTree.Navigation => {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            draft.activePageId = null;
            draft.activeCompany = action.payload.companyId;

            return draft;

        default:
            return draft;
    }
});
