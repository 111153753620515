import { AnyAction } from './types/Action';

/**
 * Creates a reducer function that sequentially calls reducers passed as parameters.
 * Common use case of this is when you want to separate one complex reducer into a set of smaller ones.
 * */
export function composeReducers(initialState: any, ...reducers: Array<(state: any, action: AnyAction) => any>) {
    return (state: any, action: AnyAction) => {
        if (state === undefined) {
            state = initialState;
        }

        for (let reducer of reducers) {
            state = reducer(state, action);
        }

        return state;
    };
}
