import { errorHelpers } from '@approvalmax/utils';
import groupBy from 'lodash/groupBy';
import { backend, domain, stateTree } from 'modules/data';
import { formatDelegateFrom, formatDelegateTo } from 'modules/delegates';
import { mergeDeep, mergeIn, updateIn } from 'modules/immutable';
import moment from 'moment';

import {
    Action,
    ADD_USERS_RESPONSE,
    CHANGE_USER_ROLE,
    INVITE_USERS_RESPONSE,
    LOAD_PAGE_DATA,
    SET_OPTIMISTIC_DELEGATE,
} from '../actions';

const getRoleProperty = (role: domain.CompanyUserRole): string => {
    switch (role) {
        case domain.CompanyUserRole.Participant:
            return 'participants';

        case domain.CompanyUserRole.Auditor:
            return 'auditors';

        case domain.CompanyUserRole.Manager:
            return 'managers';

        case domain.CompanyUserRole.WorkflowManager:
            return 'workflowManagers';

        case domain.CompanyUserRole.None:
        default: {
            throw errorHelpers.invalidOperationError();
        }
    }
};

const roleToStorePath = <const>{
    [domain.CompanyUserRole.Auditor]: 'auditors',
    [domain.CompanyUserRole.Manager]: 'managers',
    [domain.CompanyUserRole.Participant]: 'participants',
    [domain.CompanyUserRole.WorkflowManager]: 'workflowManagers',
};

const rolePaths = Object.values(roleToStorePath);

export default (state: stateTree.Entities, action: Action): stateTree.Entities => {
    switch (action.type) {
        case LOAD_PAGE_DATA: {
            const { users = {}, ...entities } = action.payload.entities;

            // split to make things safer. We don't want to use `merger` logic for all other
            // entities except `users`, because this potentially may introduce a bug, when we
            // clean some field, and it overrides by old, non-empty value
            const updatedEntitiesState = mergeDeep(state, entities);

            return mergeDeep(
                updatedEntitiesState,
                { users },
                // @see https://github.com/crudh/seamless-immutable-mergers?tab=readme-ov-file#custom-mergers
                (current, next) => {
                    // always prefer filled `users` fields over empty
                    if (typeof current === 'string' && typeof next === 'string') {
                        if (current.length && !next.length) {
                            return current;
                        }
                    }

                    return undefined;
                }
            );
        }

        /**
         *  1 - merge new users
         *  2 - add users to the company
         *  3 - update users statuses
         */
        case ADD_USERS_RESPONSE: {
            const existingUsers = action.payload.doInvite ? action.payload.request.existingUsers : [];
            const groupedByRole = groupBy([...action.payload.request.newUsers, ...existingUsers], (user) => user.role);

            const updatedStatusesState = mergeIn(
                mergeDeep(state, action.entities),
                ['companies', action.payload.request.company.id, 'userStatuses'],
                action.payload.userStatuses
            );

            const cleanedRolesOfExistingUsersState = existingUsers.reduce((accState, user) => {
                const role = rolePaths.find((role) =>
                    accState.companies[action.payload.request.company.id][role].includes(user.id)
                );

                if (!role) {
                    return accState;
                }

                return updateIn(accState, ['companies', action.payload.request.company.id, role], (usersInRole) =>
                    usersInRole.filter((email) => email !== user.id)
                );
            }, updatedStatusesState);

            const distributedByRoleState = Object.entries(groupedByRole).reduce((accState, [role, users]) => {
                if (!users.length) {
                    return accState;
                }

                return updateIn(
                    accState,
                    [
                        'companies',
                        action.payload.request.company.id,
                        roleToStorePath[role as Exclude<domain.CompanyUserRole, domain.CompanyUserRole.None>],
                    ],
                    (usersInRole) => usersInRole.concat(users.map((user) => user.id))
                );
            }, cleanedRolesOfExistingUsersState);

            return updateIn(
                distributedByRoleState,
                ['companies', action.payload.request.company.id, 'userPermissionsSettings'],
                (current: domain.CompanyUserPermissionsSettings) => {
                    const newUserPermissionsSettings = {
                        ...current,
                    };

                    action.payload.raw.Participants.forEach((participant: backend.CompanyParticipantAnswer) => {
                        newUserPermissionsSettings[participant.UserId] = participant.Permissions ?? [];
                    });

                    return newUserPermissionsSettings;
                }
            );
        }

        case INVITE_USERS_RESPONSE:
            // - merge new users
            // - update users statuses
            return mergeIn(
                mergeDeep(state, action.entities),
                ['companies', action.payload.request.company.id, 'userStatuses'],
                action.payload.userStatuses
            );

        case SET_OPTIMISTIC_DELEGATE: {
            const { companyId, user, delegateUserId, delegateDateFrom, delegateDateTo } = action.payload;

            return updateIn(state, ['companies', companyId, 'delegates'], (delegates: domain.CompanyDelegate[]) => {
                const pureDelegates = delegates.filter((delegate) => delegate.userId !== user.id);

                return !delegateUserId
                    ? pureDelegates
                    : pureDelegates.concat([
                          {
                              userId: user.id,
                              delegateUserId,
                              delegateFrom: delegateDateFrom ? formatDelegateFrom(moment(delegateDateFrom)) : undefined,
                              delegateTo: delegateDateTo ? formatDelegateTo(moment(delegateDateTo)) : undefined,
                          },
                      ]);
            });
        }

        case CHANGE_USER_ROLE: {
            const sourceProperty = getRoleProperty(action.payload.user.role);
            const targetProperty = getRoleProperty(action.payload.role);
            const newState = updateIn(state, ['companies', action.payload.companyId, sourceProperty], (x: string[]) =>
                x.filter((uId: string) => uId !== action.payload.user.id)
            );

            return updateIn(newState, ['companies', action.payload.companyId, targetProperty], (x: string[]) =>
                x.concat(action.payload.user.id)
            );
        }

        default:
            return state;
    }
};
