import { QueryOptions } from '@approvalmax/data';
import { useGetLazyRequest } from 'shared/data/api';

import { authApiPaths } from '../paths';
import { UseFido2AssertionOptionsResponse } from './useFido2AssertionOptions.types';

export const useFido2AssetionOptions = (queryOptions?: QueryOptions<UseFido2AssertionOptionsResponse>) => {
    return useGetLazyRequest<{}, UseFido2AssertionOptionsResponse>(authApiPaths.fido2AssertionOptions, {
        apiVersion: 'v2',
        queryOptions,
    });
};
