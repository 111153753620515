import { composeReducers } from 'modules/react-redux';

import newReportPopupReducer, { NewReportPopupData } from './newReportPopupReducer';
import printPreviewPopupReducer, { PrintPreviewPopupData } from './printPreviewPopupReducer';
import reportFiltersPopupReducer, { ReportFiltersPopupData } from './reportFiltersPopupReducer';
import xeroAccrualReportPopupReducer, { XeroAccrualReportPopupData } from './xeroAccrualReportPopupReducer';

export type ActivePopupData =
    | ReportFiltersPopupData
    | XeroAccrualReportPopupData
    | NewReportPopupData
    | PrintPreviewPopupData;

export type ActivePopupType = ActivePopupData;

const INITIAL_STATE = null;

export default composeReducers(
    INITIAL_STATE,
    xeroAccrualReportPopupReducer,
    reportFiltersPopupReducer,
    newReportPopupReducer,
    printPreviewPopupReducer
);
