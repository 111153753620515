import { Guid } from '@approvalmax/types';

export function mapReference<T extends Guid>(
    value: ({ Id?: T; Name?: string } & { id?: T; name?: string }) | null | undefined
): {
    id: T;
    text: string;
} | null {
    const id = value?.Id || value?.id;
    const name = value?.Name || value?.name;

    if (!id || !name) {
        return null;
    }

    return {
        id,
        text: name,
    };
}

export function mapReferenceWithValue<T extends Guid>(
    reference:
        | ({
              Value?: T;
              Name?: string;
          } & { value?: T; name?: string })
        | undefined
): {
    id: T;
    text: string;
} | null {
    const value = reference?.Value || reference?.value;
    const name = reference?.Name || reference?.name;

    if (!reference || !value || !name) {
        return null;
    }

    return {
        id: value,
        text: name,
    };
}
