import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { messages } from './TwoFaSoftEnforcementPopup.messages';
import { Content, Root, Title } from './TwoFaSoftEnforcementPopup.styles';

const TwoFaSoftEnforcementPopupSkeleton = memo(() => {
    return (
        <Root title={<Title>{messages.title}</Title>}>
            <Content>
                <Skeleton height={125} />

                <Skeleton height={35} />

                <Skeleton height={36} />
            </Content>
        </Root>
    );
});

export default TwoFaSoftEnforcementPopupSkeleton;
