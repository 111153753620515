import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data/api';

import { authApiPaths } from '../paths';
import { useCompleteRegistrationData } from './useCompleteRegistration.types';

export const useCompleteRegistration = (
    mutationOptions?: MutationOptions<any, RequestDataParams<useCompleteRegistrationData>>
) => {
    return useMutate<RequestDataParams<useCompleteRegistrationData>>(authApiPaths.completeRegistration, {
        apiVersion: 'v2',
        mutationOptions,
    });
};
