import { FC, memo } from 'react';

import { ProgressLine, Root } from './SignUpProgress.styles';
import { SignUpProgressProps } from './SignUpProgress.types';

const SignUpProgress: FC<SignUpProgressProps> = memo((props) => {
    const { currentStep, ...otherProps } = props;

    return (
        <Root {...otherProps}>
            {[1, 2, 3].map((num) => (
                <ProgressLine
                    key={num}
                    $width={currentStep === num ? 100 : 30}
                    $isCurrent={currentStep === num}
                    $isPrev={num < currentStep}
                />
            ))}
        </Root>
    );
});

export default SignUpProgress;
