import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.AddBillInvoicesPopup.BillInvoiceTableGroupedBySupplier', {
    nameColumnName: 'Name',
    dateColumnName: 'Date',
    dueDateColumnName: 'Due date',
    plannedDateColumnName: 'Planned date',
    currentColumnName: 'Current',
    lessThanOneMonthColumnName: '< 1 Month',
    oneMonthColumnName: '1 month',
    twoMonthsColumnName: '2 Months',
    threeMonthsColumnName: '3 Months',
    olderColumnName: 'Older',
    amountDueColumnName: 'Amount due',
});
