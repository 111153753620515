import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Root = styled(Link)<StyledMods<Mods<'active'>>>`
    transition: ${theme.duration.fast}ms ease-in-out;
    padding: 12px 0;
    display: block;

    &:hover {
        color: ${theme.color.blue100};
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    ${mods.active.true`
        color: ${theme.color.midnight100};
    `};

    ${mods.active.false`
        color: ${theme.color.midnight70};
    `};
`;

export const Circle = styled.div<StyledMods<Mods<'active'>>>`
    width: 12px;
    height: 12px;
    border-radius: 50%;

    ${mods.active.true`
        background-color: ${theme.color.green100};
    `};

    ${mods.active.false`
        background-color: ${theme.color.midnight50};
    `};
`;
