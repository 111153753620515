import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.SingletonSteps.SingletonStepPromotion', {
    enableAutoApprovalOrReviewStep: 'Enable Auto-Approval or Review Step',
    enableAutoApprovalStep: 'Enable Auto-Approval Step',
    descriptionAutoApprovalLabel: 'Auto-Approval Step: ',
    descriptionAutoApproval: '{templateName} matching the defined criteria will be automatically approved.',
    descriptionReviewLabel: 'Review Step: ',
    descriptionReview: '{templateName} matching the defined criteria will be editable by Reviewers.',
    learnMore: 'Learn more',
    hide: 'Hide',
    tryNow: 'Try now',
});
