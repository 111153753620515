import { domain } from 'modules/data';
import { immutable, merge } from 'modules/immutable';

import { Action, LOAD_INITIAL_APP_DATA, UPDATE_PROFILE } from '../actions';

export default function (state: domain.Profile, action: Action): domain.Profile {
    switch (action.type) {
        case LOAD_INITIAL_APP_DATA:
            return immutable(action.payload.profile);

        case UPDATE_PROFILE:
            return merge(state, action.payload.profilePartial);

        default:
            return state;
    }
}
