import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionXeroPOMatching', {
    title: 'Purchase order matching',
    matchingAutoMarkingPoSubtitle:
        'Can a Purchase Order get automatically “Marked as Billed” on the approval of the last Bill that was matched to this Purchase Order?',
    matchingAutoUnmarkingPoSubtitle:
        'Can a Purchase Order get automatically “Unmarked as Billed” if one of the Bills matched to it is unmatched?',
    autoMarkingPoYesLabel:
        'Yes, if the Purchase Order remaining balance settings are met (e.g. Purchase Order remaining balance is less than or equal to 0)',
    autoMarkingPoNoLabel: 'No, never',
    autoUnmarkingPoYesLabel: 'Yes, revoke its marking as “Billed”',
    autoUnmarkingPoNoLabel: 'No, never',
});
