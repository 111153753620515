import { WarningImage } from '@approvalmax/ui';
import { Button, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { messages } from './RemoveConfirmationPopupContent.messages';
import { RemoveConfirmationPopupContentProps } from './RemoveConfirmationPopupContent.types';

export const RemoveConfirmationPopupContent = memo<RemoveConfirmationPopupContentProps>((props) => {
    const { onCancel, onSubmit } = props;

    return (
        <Popup.Body spacing='16 32 32'>
            <Flex inline direction='column' spacing='24' alignItems='center'>
                <WarningImage width={100} height={100} />

                <Text font='headline' fontSize='small' fontWeight='medium' color='midnight80' textAlign='center'>
                    {messages.areYouSure}
                </Text>

                <Flex inline spacing='24'>
                    <Button color='midnight80' size='large' onClick={onCancel}>
                        {messages.cancel}
                    </Button>

                    <Button color='red40' size='large' onClick={onSubmit}>
                        {messages.submit}
                    </Button>
                </Flex>
            </Flex>
        </Popup.Body>
    );
});

RemoveConfirmationPopupContent.displayName = 'RemoveConfirmationPopupContent';
