import { Divider, Grid } from '@approvalmax/ui/src/components';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { PaymentDetailsAudit, PaymentDetailsBankAccountInfo, PaymentDetailsStatus } from 'shared/components';
import { noValue } from 'shared/constants';
import { useBillRequestPaymentDetails, useGetXeroCounterpartyPaymentDetailsForRequest } from 'shared/data';

import { PaymentDetailsInfoProps } from './PaymentDetailsInfo.types';

export const PaymentDetailsInfo = memo<PaymentDetailsInfoProps>((props) => {
    const {
        status,
        companyId,
        requestId,
        billRequestId,
        counterpartyId,
        paymentDetailsId,
        skipBillRequest,
        paymentDetailsDiscrepancy,
    } = props;

    const { data: paymentDetailsByBillId, isFetching: isFetchingByBillId } = useBillRequestPaymentDetails(
        {
            companyId,
            requestId,
            billRequestId,
        },
        {
            enabled:
                !paymentDetailsDiscrepancy?.paymentDetailsDiscrepancy.lastUsedPaymentDetailsProperties &&
                // skip request until batch is created
                !skipBillRequest,
        }
    );

    const { data: listOfPaymentDetailsByCounterpartyId, isFetching: isFetchingByCounterpartyId } =
        useGetXeroCounterpartyPaymentDetailsForRequest({
            companyId,
            requestId,
            counterpartyId,
        });

    const isFetching = isFetchingByBillId || isFetchingByCounterpartyId;

    const paymentDetailsByCounterpartyId = useMemo(
        () => listOfPaymentDetailsByCounterpartyId?.items.find((item) => item.id === paymentDetailsId),
        [listOfPaymentDetailsByCounterpartyId, paymentDetailsId]
    );

    const bankAccountDetails =
        paymentDetailsByBillId?.data.bankAccountDetails || paymentDetailsByCounterpartyId?.bankAccount;

    const normalizedPaymentDetailsAudit = useMemo(() => {
        const createdBy =
            paymentDetailsByBillId?.data.createdBy || paymentDetailsByCounterpartyId?.createdBy || noValue;
        const createdAt =
            (paymentDetailsByBillId?.data.createdAt && moment(paymentDetailsByBillId.data.createdAt).format('lll')) ||
            (paymentDetailsByCounterpartyId?.createdAt &&
                moment(paymentDetailsByCounterpartyId.createdAt).format('lll')) ||
            noValue;
        const modifiedBy = paymentDetailsByBillId?.data.modifiedBy || paymentDetailsByCounterpartyId?.modifiedBy;
        const modifiedAt =
            (paymentDetailsByBillId?.data.modifiedAt && moment(paymentDetailsByBillId.data.modifiedAt).format('lll')) ||
            (paymentDetailsByCounterpartyId?.modifiedAt &&
                moment(paymentDetailsByCounterpartyId.modifiedAt).format('lll')) ||
            noValue;

        return {
            createdBy,
            createdAt,
            modifiedBy,
            modifiedAt,
        };
    }, [paymentDetailsByBillId, paymentDetailsByCounterpartyId]);

    return (
        <Grid gap={12} padding='16'>
            <PaymentDetailsStatus status={status} />

            <PaymentDetailsBankAccountInfo
                status={status}
                bankAccount={bankAccountDetails}
                lastUsedPaymentDetailsProperties={
                    paymentDetailsDiscrepancy?.paymentDetailsDiscrepancy.lastUsedPaymentDetailsProperties ||
                    paymentDetailsByBillId?.data.lastUsedPaymentDetailsProperties
                }
            />

            <Divider spacing='2' />

            {isFetching ? <PaymentDetailsAudit.Loading /> : <PaymentDetailsAudit {...normalizedPaymentDetailsAudit} />}
        </Grid>
    );
});

PaymentDetailsInfo.displayName = 'PaymentDetailsInfo';
