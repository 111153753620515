import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { UseUploadXeroBudgetData, UseUploadXeroBudgetResponse } from './useUploadXeroBudget.types';

export const useUploadXeroBudget = () => {
    return useMutateLegacy<RequestDataParams<UseUploadXeroBudgetData>, UseUploadXeroBudgetResponse>(
        budgetsApiPaths.uploadXeroBudget
    );
};
