import { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import { mapData } from './useGetNetSuiteApiFilter.map';
import {
    UseGetNetSuiteApiFilterRequestParams,
    UseGetNetSuiteApiFilterResponse,
    UseGetNetSuiteApiFilterResponseBackend,
} from './useGetNetSuiteApiFilter.types';

export const useGetNetSuiteApiFilter = (
    params: UseGetNetSuiteApiFilterRequestParams,
    queryOptions?: QueryOptions<UseGetNetSuiteApiFilterResponseBackend, UseGetNetSuiteApiFilterResponse>
) => {
    return useGetRequestLegacy<UseGetNetSuiteApiFilterResponseBackend, UseGetNetSuiteApiFilterResponse>(
        integrationsApiPaths.getNetSuiteApiFilter,
        params,
        {
            queryOptions,
            mapData,
        }
    );
};
