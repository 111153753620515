import { SupportIcon } from '@approvalmax/ui';
import { Box, Button, Dropdown, Menu, Spacing, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useToggle } from 'react-use';
import { amplitudeService } from 'services/amplitude';

import { useGetInTouchMenuItems, useLearnMoreMenuItems } from './Help.hooks';
import { messages } from './Help.messages';

const qa = bemFactory.qa('page-nav-bar');

const Help = memo(() => {
    const [openDropdown, setOpenDropdown] = useToggle(false);

    const learnMoreMenuItems = useLearnMoreMenuItems();
    const getInTouchMenuItems = useGetInTouchMenuItems();

    const handleClickItem = (itemName: string, url?: string, callback?: () => void) => () => {
        setOpenDropdown(false);

        amplitudeService.sendData('help: click on top nav help item', {
            url,
            'item name': itemName,
        });

        callback && callback();
    };

    return (
        <Dropdown
            width='max-content'
            maxHeight={0}
            open={openDropdown}
            onOpen={setOpenDropdown}
            display='inline-flex'
            activator={
                <Button icon noPadding data-qa={qa('help-menu-button')}>
                    <SupportIcon size={24} />
                </Button>
            }
        >
            <Box spacing='12 12 20'>
                <Text font='label' fontSize='small' fontWeight='bold' spacing='12 8'>
                    {messages.learnMoreTitle}
                </Text>

                <Menu size='small'>
                    {learnMoreMenuItems.map(({ id, name, href, dataQa }) => (
                        <Menu.Item
                            key={id}
                            name={name}
                            id={id}
                            as='a'
                            href={href}
                            target='_blank'
                            rel='noopener noreferrer'
                            data-qa={dataQa}
                            onClick={handleClickItem(id, href)}
                        />
                    ))}
                </Menu>

                {getInTouchMenuItems.length > 0 && (
                    <>
                        <Spacing height={28} />

                        <Text font='label' fontSize='small' fontWeight='bold' spacing='12 8'>
                            {messages.getInTouchTitle}
                        </Text>

                        <Menu size='small'>
                            {getInTouchMenuItems.map(({ id, name, onClick, dataQa }) => (
                                <Menu.Item
                                    key={id}
                                    name={name}
                                    id={id}
                                    onClick={handleClickItem(id, undefined, onClick)}
                                    data-qa={dataQa}
                                />
                            ))}
                        </Menu>
                    </>
                )}
            </Box>
        </Dropdown>
    );
});

export default Help;
