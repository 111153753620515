import { Reference } from '@approvalmax/types';

import { domain } from '..';
import { TemplateSettingsLockDatePolicy } from '.';
import { BaseRequest } from './RequestBase';

export enum DearOrderStatus {
    Unknown = 'Unknown',
    NotAvailable = 'NotAvailable',
    Draft = 'Draft',
    Authorised = 'Authorised',
    Voided = 'Voided',
    AuthorisedWithoutAllocation = 'AuthorisedWithoutAllocation',
    Received = 'Received',
    Fulfilled = 'Fulfilled',
    Closed = 'Closed',
}

export enum DearPurchaseOrderDetailsApproach {
    Unknown = 'Unknown',
    InvoiceFirst = 'InvoiceFirst',
    StockFirst = 'StockFirst',
}

export enum DearPurchaseStatus {
    Unknown = 'Unknown',
    Draft = 'Draft',
    Voided = 'Voided',
    Ordering = 'Ordering',
    Ordered = 'Ordered',
    Receiving = 'Receiving',
    Received = 'Received',
    Invoiced = 'Invoiced',
    Credited = 'Credited',
    ReceivingCredited = 'ReceivingCredited',
    ReceivedCredited = 'ReceivedCredited',
    PartiallyInvoiced = 'PartiallyInvoiced',
    Completed = 'Completed',
    CompletedCreditNoteClosed = 'CompletedCreditNoteClosed',
}

export enum DearTaxCalculation {
    Unknown = 'Unknown',
    TaxInclusive = 'TaxInclusive',
    TaxExclusive = 'TaxExclusive',
}

/**
 * BACKEND INTERFACES
 */

export interface DearProductBackend {
    Id: string;
    Name: string;
    DisplayName: string;
    SKU: string | null;
}

interface DearPurchaseOrderLineBackend {
    Quantity: number;
    Price: number;
    Discount: number;
    TaxRule: string | null;
    Tax: number;
    Total: number;
}

export interface DearAdditionalChargeLineBackend extends DearPurchaseOrderLineBackend {
    Description: string | null;
    Reference: string | null;
}

export interface DearPurchaseOrderItemLineBackend extends DearPurchaseOrderLineBackend {
    Product: DearProductBackend | null;
    Comment: string | null;
    SupplierSKU: string | null;
}

export interface DearPurchaseOrderSupplierBackend {
    Id: string;
    Name: string;
}

export interface DearPurchaseOrderDetailsBackend {
    InternalId: string;
    PurchaseStatus: DearPurchaseStatus;
    OrderStatus: DearOrderStatus;
    Supplier: DearPurchaseOrderSupplierBackend | null;
    Contact: string | null;
    Phone: string | null;
    BillingAddress: string | null;
    Approach: DearPurchaseOrderDetailsApproach;
    Terms: string | null;
    RequiredBy: string | null;
    InventoryAccount: string | null;
    CurrencyRate: number | null;
    BaseCurrency: string | null;
    SupplierCurrency: string | null;
    TaxCalculation: DearTaxCalculation;
    TaxRule: string | null;
    OrderNumber: string | null;
    OrderDate: string | null;
    Location: string | null;
    ShippingAddress: string | null;
    Note: string | null;
    Memo: string | null;
    TotalBeforeTax: number | null;
    Tax: number | null;
    Total: number | null;
    ItemLines: DearPurchaseOrderItemLineBackend[];
    AdditionalChargeLines: DearAdditionalChargeLineBackend[];
    CreatedDate: string | null;
    LastUpdatedDate: string | null;
    Url?: string | null;
}

/**
 * FRONTEND INTERFACES
 */

export interface DearProduct {
    id: string;
    name: string;
    displayName: string;
    sku: string | null;
}

interface DearLine {
    quantity: number;
    price: number;
    discount: number;
    taxRule: string | null;
    tax: number;
    total: number;
}

export interface DearItemLine extends DearLine {
    product: DearProduct | null;
    comment: string | null;
    supplierSku: string | null;
}

export interface DearAdditionalChargeLine extends DearLine {
    description: string | null;
    reference: string | null;
}

export interface DearPurchaseOrderDetails {
    integrationCode: domain.IntegrationCode.DearPo;
    integrationType: domain.IntegrationType.Dear;
    internalId: string;
    purchaseStatus: DearPurchaseStatus;
    contact: string | null;
    phone: string | null;
    billingAddress: string | null;
    approach: DearPurchaseOrderDetailsApproach;
    terms: string | null;
    requiredByDate: string | null;
    inventoryAccount: string | null;
    currencyRate: number | null;
    baseCurrency: string | null;
    supplierCurrency: string | null;
    supplier: Reference | null;
    orderNumber: string | null;
    orderStatus: DearOrderStatus;
    orderDate: string | null;
    location: string | null;
    shippingAddress: string | null;
    note: string | null;
    memo: string | null;
    totalAmount: number | null;
    netAmount: number | null;
    taxAmount: number | null;
    taxCalculation: DearTaxCalculation;
    taxRule: string | null;
    itemLines: DearItemLine[];
    additionalChargeLines: DearAdditionalChargeLine[];
    date: string | null;
    dateLastUpdate: string | null;
    lockDate: string | null;
    lockDatePolicy: TemplateSettingsLockDatePolicy;
    transactionDate: string | null;
    url: string | null;
    number: string;
}

export type DearRequestDetails = DearPurchaseOrderDetails;

export type DearPoRequestSpecifics = {
    integrationType: domain.IntegrationType.Dear;
    integrationCode: domain.IntegrationCode.DearPo;
    details: DearPurchaseOrderDetails;
};

export type DearPoRequest = BaseRequest & DearPoRequestSpecifics;

export type DearRequestSpecifics = DearPoRequestSpecifics;

export type DearRequest = DearPoRequest;
