import { ChevronDownIcon } from '@approvalmax/ui';
import { Button, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { ActivatorProps } from './Activator.types';

const Activator: FC<ActivatorProps> = memo((props) => {
    const { name } = props;

    return (
        <Button uppercase={false} size='small' fontWeight='regular' endIcon={<ChevronDownIcon color='midnight80' />}>
            <Text font='label' color='midnight80'>
                {name}
            </Text>
        </Button>
    );
});

export default Activator;
