import { Popup } from '@approvalmax/ui';
import { FC, memo, useCallback, useState } from 'react';

import StepEmailConfirm from './components/StepEmailConfirm/StepEmailConfirm';
import StepEmailEnter from './components/StepEmailEnter/StepEmailEnter';
import { UpdateBackupEmailPopupProps } from './UpdateBackupEmailPopup.type';

const UpdateBackupEmailPopup: FC<UpdateBackupEmailPopupProps> = memo((props) => {
    const { onClose, title } = props;
    const [stateId, setStateId] = useState('');
    const [email, setEmail] = useState('');
    const [activeStep, setActiveStep] = useState<'emailEnter' | 'emailConfirm'>('emailEnter');

    const onNext = useCallback(() => {
        setActiveStep('emailConfirm');
    }, []);

    const onPrev = useCallback(() => {
        setActiveStep('emailEnter');
    }, []);

    return (
        <Popup isOpen onRequestClose={onClose}>
            {
                {
                    emailEnter: (
                        <StepEmailEnter
                            onNext={onNext}
                            setStateId={setStateId}
                            email={email}
                            setEmail={setEmail}
                            title={title}
                        />
                    ),
                    emailConfirm: (
                        <StepEmailConfirm
                            onPrev={onPrev}
                            stateId={stateId}
                            onClose={onClose}
                            email={email}
                            setStateId={setStateId}
                            title={title}
                        />
                    ),
                }[activeStep]
            }
        </Popup>
    );
});

export default UpdateBackupEmailPopup;
