import { recoilHelpers } from '@approvalmax/utils';
import { setChameleonOrgId } from 'modules/utils/helpers/chameleon';
import { atom, selector } from 'recoil';

import { routeParamsState, routeState } from './routerParams';

const storageKey = 'activeCompanyId';

const activeCompanyIdWithLocalStoreState = atom({
    key: 'activeCompanyIdWithLocalStoreState',
    default: '',
    effects: [
        recoilHelpers.connectToLocalStorage(storageKey),
        ({ onSet }) => {
            onSet((orgId) => {
                setChameleonOrgId(orgId);
            });
        },
    ],
});

export const activeCompanyIdState = selector({
    key: 'activeCompanyIdState',
    get: ({ get }) => {
        const { pathname } = get(routeState);
        const { companyId } = get<{ companyId?: string }>(routeParamsState);

        const regExp = RegExp(`^/${companyId}`);
        const isRootCompanyId = regExp.test(pathname || '');

        if (isRootCompanyId && companyId) {
            localStorage.setItem(storageKey, JSON.stringify(companyId));

            return companyId;
        }

        return get(activeCompanyIdWithLocalStoreState);
    },
    set: ({ set }, newValue) => {
        set(activeCompanyIdWithLocalStoreState, newValue);
    },
});
