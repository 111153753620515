import { useCallback } from 'react';
import { createGlobalState } from 'react-use';

import { TwoFaActionWizardActiveScreen, TwoFaActionWizardContext } from './TwoFaActionWizard.types';

const initialContext: TwoFaActionWizardContext = {
    activeScreen: 'authenticatorAppCode',
};

const useContext = createGlobalState<TwoFaActionWizardContext>(initialContext);

export const useTwoFaActionWizardContext = () => {
    const [context, setContext] = useContext();

    const setActiveScreen = useCallback(
        (activeScreen: TwoFaActionWizardActiveScreen) => {
            setContext((prevContext) => ({ ...prevContext, activeScreen }));
        },
        [setContext]
    );

    const resetContext = useCallback(() => {
        setContext(initialContext);
    }, [setContext]);

    return {
        setActiveScreen,
        activeScreen: context.activeScreen,
        resetContext,
    };
};
