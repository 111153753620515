import * as common from 'modules/common';
import { immutable, ImmutableObject, merge, setIn } from 'modules/immutable';

import {
    Action,
    CLOSE_ACTIVE_POPUP,
    COPY_WORKFLOW,
    COPY_WORKFLOW_RESPONSE,
    RESTORE_WORKFLOW,
    RESTORE_WORKFLOW_RESPONSE,
    SHOW_DEV_TOOLS_POPUP,
} from '../actions/index';

export enum ActivePopup {
    DevTools = 'DevTools',
}

export interface DevToolsPopupData {
    loading: boolean;
}

export interface AutomationModuleData {
    activePopup: ActivePopup | null;
    devToolsPopup: DevToolsPopupData | null;
}

const INITIAL_STATE = immutable<AutomationModuleData>({
    activePopup: null,
    devToolsPopup: null,
});

export default function (
    state: ImmutableObject<AutomationModuleData> = INITIAL_STATE,
    action: Action | common.actions.Action
): ImmutableObject<AutomationModuleData> {
    switch (action.type) {
        case SHOW_DEV_TOOLS_POPUP:
            return merge(state, {
                activePopup: ActivePopup.DevTools,
                devToolsPopup: {
                    loading: false,
                },
            });

        case CLOSE_ACTIVE_POPUP:
            return merge(state, {
                activePopup: null,
                devToolsPopup: null,
            });

        case COPY_WORKFLOW:
            return setIn(state, ['devToolsPopup', 'loading'], true);

        case COPY_WORKFLOW_RESPONSE:
            return setIn(state, ['devToolsPopup', 'loading'], false);

        case RESTORE_WORKFLOW:
            return setIn(state, ['devToolsPopup', 'loading'], true);

        case RESTORE_WORKFLOW_RESPONSE:
            return setIn(state, ['devToolsPopup', 'loading'], false);

        default:
            return state;
    }
}
