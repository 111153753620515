import reduce from 'lodash/reduce';
import uniqBy from 'lodash/uniqBy';
import { domain } from 'modules/data';
import moment from 'moment';

import { ItemRole, SelectableSupplierBillItem, TableColumn } from './BillInvoiceTableGroupedBySupplier.types';

const getColumnByDueDate = (dueDate: string): TableColumn => {
    const dueDateMoment = moment(dueDate);
    const today = moment();

    if (dueDateMoment.isSameOrAfter(today, 'days')) {
        return TableColumn.Current;
    } else if (today.diff(dueDateMoment, 'days') <= 30) {
        return TableColumn.LessThanOneMonth;
    } else if (today.diff(dueDateMoment, 'days') <= 60) {
        return TableColumn.OneMonth;
    } else if (today.diff(dueDateMoment, 'days') <= 90) {
        return TableColumn.TwoMonths;
    } else if (today.diff(dueDateMoment, 'days') <= 120) {
        return TableColumn.ThreeMonths;
    } else {
        return TableColumn.Older;
    }
};

export const mapToSelectableSupplierBillItems = (
    selectableBills: domain.BatchPaymentSelectableBillItem[]
): SelectableSupplierBillItem[] => {
    const uniqueSuppliers = uniqBy(selectableBills, (bill) => bill.contact?.id);

    return reduce(
        uniqueSuppliers,
        (accumData: SelectableSupplierBillItem[], selectableBill: domain.BatchPaymentSelectableBillItem) => {
            const allBillsBySupplier = selectableBills
                .filter((bill) => bill.contact?.id === selectableBill.contact?.id)
                .map((bill) => ({
                    ...bill,
                    itemRole: ItemRole.BillItem,
                    tableColumn: getColumnByDueDate(bill.dueDate),
                }));

            const supplierNameItem = {
                ...selectableBill,
                itemRole: ItemRole.SupplierName,
                xeroBillInvoiceRequestId: `${selectableBill.xeroBillInvoiceRequestId}--name`,
                id: `${selectableBill.contact?.id}`,
                selected: allBillsBySupplier.every((item) => item.selected),
            };

            return [...accumData, supplierNameItem, ...allBillsBySupplier];
        },
        []
    );
};
