import { statics } from 'modules/common';
import BemFactory from 'react-bem-factory';

import accountIdImage from './assets/accountId.png';
import consumerKeysImage from './assets/consumerKeys.png';
import subsidiaryIdImage from './assets/subsidiaryId.png';
import timeZoneImage from './assets/timeZone.png';
import tokenKeysImage from './assets/tokenKeys.png';
import { messages } from './NetSuiteIntegrationPopup.messages';
import { FormField, FormFieldId } from './NetSuiteIntegrationPopup.types';

const qa = BemFactory.qa('netsuite-integration-popup');

export const fieldNames = {
    [FormFieldId.isSuiteApp]: messages.suiteAppConnection,
    [FormFieldId.accountId]: messages.accountIdFieldTitle,
    [FormFieldId.consumerKey]: messages.consumerKeyFieldTitle,
    [FormFieldId.consumerSecret]: messages.consumerSecretFieldTitle,
    [FormFieldId.tokenId]: messages.tokenIdFieldTitle,
    [FormFieldId.tokenSecret]: messages.tokenSecretFieldTitle,
    [FormFieldId.subsidiaryId]: messages.subsidiaryIdFieldTitle,
    [FormFieldId.timeZone]: messages.timeZoneFieldTitle,
};

export const formFields: FormField[] = [
    {
        id: FormFieldId.isSuiteApp,
        required: false,
        isSuiteApp: true,
        title: messages.suiteAppConnection,
        type: 'switch',
    },
    {
        id: FormFieldId.accountId,
        required: true,
        isSuiteApp: true,
        title: messages.accountIdFieldTitle,
        tooltipText: messages.accountIdFieldTooltipText,
        imgAltText: messages.imageAltText({ fieldName: fieldNames[FormFieldId.accountId] }),
        imgSrc: accountIdImage,
        type: 'text',
    },
    {
        id: FormFieldId.consumerKey,
        required: true,
        isSuiteApp: false,
        title: messages.consumerKeyFieldTitle,
        tooltipText: messages.consumerKeyFieldTooltipText,
        imgAltText: messages.imageAltText({ fieldName: fieldNames[FormFieldId.consumerKey] }),
        imgSrc: consumerKeysImage,
        type: 'text',
    },
    {
        id: FormFieldId.consumerSecret,
        required: true,
        isSuiteApp: false,
        title: messages.consumerSecretFieldTitle,
        tooltipText: messages.consumerSecretFieldTooltipText,
        imgAltText: messages.imageAltText({ fieldName: fieldNames[FormFieldId.consumerSecret] }),
        imgSrc: consumerKeysImage,
        type: 'password',
    },
    {
        id: FormFieldId.tokenId,
        required: true,
        isSuiteApp: true,
        title: messages.tokenIdFieldTitle,
        tooltipText: messages.tokenIdFieldTooltipText,
        imgAltText: messages.imageAltText({ fieldName: fieldNames[FormFieldId.tokenId] }),
        imgSrc: tokenKeysImage,
        type: 'text',
    },
    {
        id: FormFieldId.tokenSecret,
        required: true,
        isSuiteApp: true,
        title: messages.tokenSecretFieldTitle,
        tooltipText: messages.tokenSecretFieldTooltipText,
        imgAltText: messages.imageAltText({ fieldName: fieldNames[FormFieldId.tokenSecret] }),
        imgSrc: tokenKeysImage,
        type: 'password',
    },
    {
        id: FormFieldId.subsidiaryId,
        required: true,
        isSuiteApp: true,
        title: messages.subsidiaryIdFieldTitle,
        tooltipText: messages.subsidiaryIdFieldTooltipText,
        imgAltText: messages.imageAltText({ fieldName: fieldNames[FormFieldId.subsidiaryId] }),
        imgSrc: subsidiaryIdImage,
        type: 'text',
    },
    {
        id: FormFieldId.timeZone,
        required: true,
        isSuiteApp: true,
        title: messages.timeZoneFieldTitle,
        tooltipText: messages.timeZoneFieldTooltipText,
        imgAltText: messages.imageAltText({ fieldName: fieldNames[FormFieldId.timeZone] }),
        imgSrc: timeZoneImage,
        items: statics.timeZone.timeZoneReferences.map(({ id, text }) => ({
            id,
            name: text,
        })),
        qa: qa('timezone-dropdown'),
        type: 'select',
    },
];

export const defaultValues = {
    [FormFieldId.isSuiteApp]: true,
    [FormFieldId.accountId]: '',
    [FormFieldId.consumerKey]: '',
    [FormFieldId.consumerSecret]: '',
    [FormFieldId.tokenId]: '',
    [FormFieldId.tokenSecret]: '',
    [FormFieldId.subsidiaryId]: '',
    [FormFieldId.timeZone]: '',
};
