import './accountSetupPopup.scss';

import { Reference } from '@approvalmax/types';
import { DropdownEditor, Field, LoadingBar, Popup, RadioGroupEditor, TextButton, TextEditor } from '@approvalmax/ui';
import { intl, miscHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { PlainDataProvider } from 'modules/data-providers';
import HeaderWithLogo from 'modules/first-start/components/HeaderWithLogo/HeaderWithLogo';
import { integrationActions } from 'modules/integration';
import { useSelector } from 'modules/react-redux';
import React, { FC, memo, useEffect, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { notificationService } from 'services/notification';

import { CREATE_ACCOUNT, createAccount, skipOrganisationCreation } from '../../actions';
import { allCompanyCountOptions } from '../../data/account';
import { getAccountSetupData } from '../../selectors/moduleSelectors';
import ConnectToPanel from './components/ConnectToPanel/ConnectToPanel';
import NavBar from './components/NavBar/NavBar';

const i18nPrefix = 'first-start/containers/AccountSetupPopup';

const bem = bemFactory.block('first-start-account-setup-popup');

const AccountSetupPopup: FC = () => {
    const dispatch = useDispatch();

    const data = useSelector(getAccountSetupData)!;
    const isLoading = useSelector((state) => selectors.ui.isOperationInProgress(state, CREATE_ACCOUNT));
    const hasAccount = useSelector((state) => Boolean(selectors.profile.getProfile(state).account));

    type ActiveStep = 'info' | 'connect';

    const [activeStep, setActiveStep] = useState<ActiveStep>('info');
    const [isPartner, setIsPartner] = useState(false);
    const [companyCount, setCompanyCount] = useState(allCompanyCountOptions[0]);
    const [clientCount, setClientCount] = useState(allCompanyCountOptions[0]);
    const [practiceName, setPracticeName] = useState('');
    const [showValidationErrors, setShowValidationErrors] = useState(false);

    function onSkipOrganisationCreation() {
        dispatch(skipOrganisationCreation());
    }

    useEffect(() => {
        if (hasAccount && data) {
            // Step 1 completed.
            const integrationType = data.oauthProvider && domain.getIntegrationTypeByOAuthProvider(data.oauthProvider);

            if (integrationType) {
                // Auto-connect to the given accounting software
                dispatch(
                    integrationActions.connectToIntegration({
                        integrationType,
                        companyId: null,
                        finalRedirectToPage: 'workflow',
                        showGlobalProgress: false,
                    })
                );
            } else {
                // Transition to step 2.
                setActiveStep('connect');
            }
        }
    }, [data, data.oauthProvider, dispatch, hasAccount]);

    const stepInfo: {
        [key in ActiveStep]: {
            title: string;
            navBarTitle: string;
            onNext?: () => void;
            onBack?: () => void;
            content: React.ReactNode;
        };
    } = {
        info: {
            onNext: async () => {
                let isValid = !isPartner || Boolean(practiceName.trim());

                if (!isValid) {
                    setShowValidationErrors(true);
                    notificationService.showErrorToast(
                        intl.formatMessage({
                            id: `${i18nPrefix}.validationError`,
                            defaultMessage: 'Please provide the name of your practice.',
                        })
                    );

                    return;
                }

                setShowValidationErrors(false);
                await dispatch(
                    createAccount({
                        isPartner,
                        practiceName,
                        companyCount: companyCount.id,
                        clientCount: clientCount.id,
                    })
                );
            },
            title: intl.formatMessage({
                id: `${i18nPrefix}.infoTitle`,
                defaultMessage: 'ApprovalMax account setup',
            }),
            navBarTitle: intl.formatMessage({
                id: `${i18nPrefix}.infoNavBarTitle`,
                defaultMessage: 'Your business details',
            }),
            content: (
                <section className={bem('info-panel')}>
                    <Field
                        title={intl.formatMessage({
                            id: `${i18nPrefix}.infoAccountPartner`,
                            defaultMessage: 'I am going to use this account:',
                        })}
                        required
                    >
                        <RadioGroupEditor
                            className={bem('info-partner-radio')}
                            value={isPartner}
                            onChange={setIsPartner}
                        >
                            <RadioGroupEditor.Item id={false}>
                                <FormattedMessage
                                    id={`${i18nPrefix}.accountTargetInternalAccounting`}
                                    defaultMessage='For my own internal accounting'
                                />
                            </RadioGroupEditor.Item>

                            <RadioGroupEditor.Item id={true}>
                                <FormattedMessage
                                    id={`${i18nPrefix}.accountTargetProvideServices`}
                                    defaultMessage='To provide services for my clients'
                                />
                            </RadioGroupEditor.Item>
                        </RadioGroupEditor>
                    </Field>

                    {!isPartner ? (
                        <div className={bem('info-fields')}>
                            <Field
                                title={intl.formatMessage({
                                    id: `${i18nPrefix}.companyCount`,
                                    defaultMessage:
                                        'How many legal entities do you have (how many accounts in your Accounting software do you manage)?',
                                })}
                                required
                                wrapLabel
                            >
                                <PlainDataProvider items={allCompanyCountOptions}>
                                    <DropdownEditor
                                        buttonComponent={DropdownEditor.NoFilterButton}
                                        value={companyCount}
                                        onChange={(value: Reference) => setCompanyCount(value)}
                                        disabled={isLoading}
                                    />
                                </PlainDataProvider>
                            </Field>
                        </div>
                    ) : (
                        <div className={bem('info-fields')}>
                            <Field
                                title={intl.formatMessage({
                                    id: `${i18nPrefix}.clientCount`,
                                    defaultMessage: 'How many clients are you planning to use ApprovalMax for?',
                                })}
                                required
                                wrapLabel
                            >
                                <PlainDataProvider items={allCompanyCountOptions}>
                                    <DropdownEditor
                                        buttonComponent={DropdownEditor.NoFilterButton}
                                        value={clientCount}
                                        onChange={(value: Reference) => setClientCount(value)}
                                        disabled={isLoading}
                                    />
                                </PlainDataProvider>
                            </Field>

                            <Field
                                title={intl.formatMessage({
                                    id: `${i18nPrefix}.practiceName`,
                                    defaultMessage: 'Please provide your practice name',
                                })}
                                required
                                wrapLabel
                            >
                                <TextEditor
                                    invalid={showValidationErrors && !practiceName.trim()}
                                    value={practiceName}
                                    onChange={setPracticeName}
                                    placeholder={intl.formatMessage({
                                        id: `${i18nPrefix}.practiceNamePlaceholder`,
                                        defaultMessage: 'Enter practice name...',
                                    })}
                                    disabled={isLoading}
                                />
                            </Field>
                        </div>
                    )}
                </section>
            ),
        },
        connect: {
            title: intl.formatMessage({
                id: `${i18nPrefix}.connectTitle`,
                defaultMessage: 'Create your first organisation',
            }),
            navBarTitle: intl.formatMessage({
                id: `${i18nPrefix}.connectNavBarTitle`,
                defaultMessage: 'Connect your Accounting software',
            }),
            content: (
                <section className={bem('connect-panel')}>
                    <div className={bem('connect-description')}>
                        <FormattedMessage
                            id={`${i18nPrefix}.connectDescription`}
                            defaultMessage='Create your first ApprovalMax Organisation which would 1-1 connect to your file in Accounting system. You can create more Organisations later if required. If you are using Xero you can connect either to your production Organisation or try connecting to Demo first (this action would require to create new Organisation later).'
                        />
                    </div>

                    <ConnectToPanel className={bem('connect-tiles')} />

                    <div className={bem('or-separator-block')}>
                        <div className={bem('or-separator-line')} />

                        <div className={bem('or-separator-text')}>
                            <FormattedMessage id={`${i18nPrefix}.orSeparatorText`} defaultMessage='Or' />
                        </div>

                        <div className={bem('or-separator-line')} />
                    </div>

                    <div className={bem('connect-actions')}>
                        <TextButton execute={onSkipOrganisationCreation} upperCased={false} underlined={true}>
                            <FormattedMessage
                                id={`${i18nPrefix}.skipOrganisationCreationButton`}
                                defaultMessage='Skip Organisation creation for now'
                            />
                        </TextButton>
                    </div>
                </section>
            ),
        },
    };
    const stepData = stepInfo[activeStep];

    if (data?.firstStart) {
        return (
            <Popup.EmptyContent className={bem('first-start-popup')}>
                <HeaderWithLogo loading={isLoading} title={stepData.title} showCloseButton />

                <NavBar
                    disabled={isLoading}
                    className={bem('nav-bar')}
                    showBack={Boolean(stepData.onBack)}
                    showNext={Boolean(stepData.onNext)}
                    onBack={stepData.onBack || miscHelpers.noop}
                    onNext={stepData.onNext || miscHelpers.noop}
                    showFinish={false}
                    onFinish={miscHelpers.noop}
                    title={stepData.navBarTitle}
                    stepNumber={1}
                />

                <div className={bem('body')}>{stepData.content}</div>
            </Popup.EmptyContent>
        );
    } else {
        return (
            <Popup.DefaultContent
                className={bem('standalone-popup')}
                title={stepData.title}
                buttons={
                    stepData.onNext && (
                        <Popup.DefaultContent.Button execute={stepData.onNext} disabled={isLoading}>
                            <FormattedMessage id={`${i18nPrefix}.nextButtonText`} defaultMessage='Next' />
                        </Popup.DefaultContent.Button>
                    )
                }
            >
                <div className={bem('standalone-content')}>
                    <LoadingBar className={bem('standalone-loading', { active: isLoading })} />

                    <div className={bem('standalone-body')}>{stepData.content}</div>
                </div>
            </Popup.DefaultContent>
        );
    }
};

export default memo(AccountSetupPopup);
