import { PartyImage } from '@approvalmax/ui';
import { Button, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, Fragment, memo, useCallback, useMemo } from 'react';
import { routingService } from 'services/routing';
import { getPath, Path } from 'urlBuilder';

import { messages } from './SingleResultPopup.messages';
import { SingleResultPopupProps } from './SingleResultPopup.types';

const SingleResultPopup: FC<SingleResultPopupProps> = memo((props) => {
    const { sourceCompany, sourceWorkflow, targets, skipCompanyName, onToggle, open } = props;

    const getWorkflowName = useCallback((integrationCode: domain.IntegrationCode | null, defaultName: string) => {
        return integrationCode ? selectors.template.getTemplateWorkflowNameByCode(integrationCode) : defaultName;
    }, []);

    const sourceWorkflowName = getWorkflowName(sourceWorkflow.integrationCode, sourceWorkflow.displayName);
    const hasEnabledWorkflow = useMemo(() => targets.some((target) => target.template.enabled), [targets]);
    const targetWorkflows = useMemo(
        () =>
            targets.map((target) => ({
                ...target,
                companyName: skipCompanyName ? undefined : `${target.company.displayName} `,
                workflowHref: getPath(Path.workflow, target.company.id, target.template.id),
                workflowName: getWorkflowName(target.template.integrationCode, target.template.displayName),
            })),
        [getWorkflowName, skipCompanyName, targets]
    );

    const done = useCallback(() => onToggle?.(false), [onToggle]);

    return (
        <Popup open={open} onToggle={onToggle} size='xsmall'>
            <Popup.Body spacing='16 32 32'>
                <Flex inline direction='column' alignItems='center' spacing='16'>
                    <PartyImage width={100} height={100} />

                    <Text font='body' fontSize='large' fontWeight='medium' textAlign='center'>
                        {!skipCompanyName && <div>{sourceCompany.displayName}</div>}

                        {sourceWorkflowName}

                        {messages.successfulMessage}

                        {targetWorkflows.map(({ template, workflowHref, workflowName, companyName }, index) => (
                            <Fragment key={template.id}>
                                {companyName}

                                <a
                                    href={workflowHref}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        routingService.reloadToUrl(workflowHref);
                                    }}
                                >
                                    {workflowName}
                                </a>

                                {index < targetWorkflows.length - 1 ? ', ' : '.'}
                            </Fragment>
                        ))}
                    </Text>

                    <Text font='label' color='midnight60' textAlign='center'>
                        {hasEnabledWorkflow ? messages.existingRequestsNode : messages.activateWorkflow}
                    </Text>

                    <Button onClick={done} size='large' color='blue80'>
                        {messages.done}
                    </Button>
                </Flex>
            </Popup.Body>
        </Popup>
    );
});

export default SingleResultPopup;
