import { QueryOptions, QueryParams } from '@approvalmax/data';
import { budgetsApiPaths, useGetRequest } from 'shared/data';

import { UseQBooksBudgetsImpactPathParams, UseQBooksBudgetsImpactResponse } from './useQBooksBudgetsImpact.types';

export const useQBooksBudgetsImpact = (
    pathParams: QueryParams<UseQBooksBudgetsImpactPathParams>,
    queryOptions?: QueryOptions<UseQBooksBudgetsImpactResponse>
) => {
    return useGetRequest<UseQBooksBudgetsImpactPathParams, UseQBooksBudgetsImpactResponse>(
        budgetsApiPaths.qBooksBudgetsImpact,
        {
            pathParams,
            queryOptions,
            apiVersion: 'v2',
        }
    );
};
