import { FC, memo } from 'react';
import styled from 'styled-components';

const ToggleContainer = styled.div`
    padding: 8px 0;
    cursor: pointer;
`;

const Line = styled.div<{ on: 1 | 0; disabled?: boolean }>`
    height: 14px;
    width: 36px;
    border-radius: 7px;
    position: relative;
    background: #b6b6b6;
    transition: background-color 200ms linear;

    ${(props) => (props.on ? 'background: #4c8a5c;' : '')}
    ${(props) => (props.disabled ? 'background: #e9eef0;' : '')}
`;

const Circle = styled.div<{ on: 1 | 0 }>`
    border-radius: 50%;
    background: #fff;
    position: absolute;
    height: 20px;
    width: 20px;
    top: -3px;
    left: 0;
    transition: left 200ms ease-in-out;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);

    ${(props) => (props.on ? 'left: calc(100% - 20px);' : '')}
`;

interface ToggleProps {
    on: boolean;
    onChange: () => void;
    dataQa: string;
    dataQaId: boolean;
    disabled?: boolean;
}

const Toggle: FC<ToggleProps> = (props) => {
    const { on, onChange, dataQa, dataQaId, disabled } = props;

    return (
        <ToggleContainer data-qa={dataQa} data-qa-id={dataQaId} onClick={disabled ? undefined : onChange}>
            <Line on={on ? 1 : 0} disabled={disabled}>
                <Circle on={on ? 1 : 0} />
            </Line>
        </ToggleContainer>
    );
};

export default memo(Toggle);
