import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import {
    UseCreateXeroBankAccountsData,
    UseCreateXeroBankAccountsPathParams,
    UseCreateXeroBankAccountsResponse,
} from './useCreateXeroBankAccounts.types';

export const useCreateXeroBankAccounts = (
    mutationOptions?: MutationOptions<
        UseCreateXeroBankAccountsResponse,
        RequestDataParams<UseCreateXeroBankAccountsData, UseCreateXeroBankAccountsPathParams>
    > &
        Pick<NonNullable<Parameters<typeof useMutateLegacy>[1]>, 'skipInvalidateQueries'>
) => {
    const { skipInvalidateQueries, ...restMutationOptions } = mutationOptions || {};

    return useMutateLegacy(integrationsApiPaths.xeroBankAccounts, {
        skipInvalidateQueries,
        mutationOptions: restMutationOptions,
    });
};
