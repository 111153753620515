import { stateTree } from 'modules/data';
import { ImmutableObject } from 'modules/immutable';

import { Action } from '../actions';

export default function (state: ImmutableObject<stateTree.Ui>, action: Action): ImmutableObject<stateTree.Ui> {
    switch (action.type) {
        default:
            return state;
    }
}
