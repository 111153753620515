import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionFraudChangesAfter', {
    title: 'Fraud detection — changes made after approval',
    subtitle:
        'Which changes made in Xero after the approval in ApprovalMax should trigger the marking as "Changed after approval" and a notification to the Organisation\'s Administrators?',
    contactIdLabel: 'Contact',
    netAmountLabel: 'Net total amount (more than 0.1 {companyCurrencyCode} change)',
    trackingCategoriesLabel: 'Tracking category',
    lineItemAccountCodeLabel: 'Account',
    lineItemCodeLabel: 'Item',
    lineItemDescriptionLabel: 'Description (more than 20% difference)',
    currencyLabel: 'Currency',
});
