import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    UseVerifyTFAEnablingEmailCodeRequest,
    UseVerifyTFAEnablingEmailCodeResponse,
} from './useVerifyTFAEnablingEmailCode.types';

export const useVerifyTFAEnablingEmailCode = (
    mutationOptions?: MutationOptions<
        UseVerifyTFAEnablingEmailCodeResponse,
        RequestDataParams<UseVerifyTFAEnablingEmailCodeRequest>
    >
) => {
    return useMutateLegacy<
        RequestDataParams<UseVerifyTFAEnablingEmailCodeRequest>,
        UseVerifyTFAEnablingEmailCodeResponse
    >(twoFaApiPaths.enablingEmailCodeVerify, { mutationOptions });
};
