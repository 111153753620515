import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.AccrualReportsBanner', {
    title: 'Check Out Our Brand New Accruals Report!',
    netGross: 'Net/Gross Amount Selection',
    netGrossDescription: 'Easily switch between net and gross amounts for a clearer financial overview.',
    poBalance: 'PO Remaining Balance',
    poBalanceDescription: 'Stay on top of your purchase orders with the newly added remaining balance feature.',
    autoPushing: 'Auto-Pushing to Xero',
    autoPushingDescription: 'Streamline your workflow by automatically syncing your accruals with Xero.',
    approvalsAdded: 'Approvals Added',
    approvalsAddedDescription:
        'Improve your accruals approval process with built-in functionality for faster and more efficient approvals.',
    learnMore: 'Learn More',
    tryForFree: 'Try it for free',
});
