import { ForwardedRef, forwardRef, memo } from 'react';
import { useController } from 'react-hook-form';

import Select from '../../Select';
import { ControllerProps } from './Controller.types';

/**
 * The `Select.Controller` allows you to use the `Select` with the `react-hook-form` library.
 * They take the same properties as the `Select` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
const Controller = memo(
    forwardRef(
        <Item extends Record<string, any>, Multiple extends boolean | undefined = undefined>(
            props: ControllerProps<Item, Multiple>,
            ref: ForwardedRef<HTMLDivElement>
        ) => {
            const { control, name, rules, defaultValue, validate, ...restProps } = props;

            const { field, fieldState } = useController({
                control,
                name: name || 'select',
                rules: { required: validate ? undefined : restProps.required, validate, ...rules },
                defaultValue,
            });

            const hintPlaceholder = validate ? <span>&nbsp;</span> : undefined;

            return (
                <Select
                    {...field}
                    {...fieldState}
                    {...restProps}
                    ref={ref}
                    hint={fieldState.error?.message || restProps.hint || hintPlaceholder}
                />
            );
        }
    )
);

export default Controller;
