import { Action, SHOW_DASHBOARD, SHOW_NEW_REPORT_CARD, SHOW_REPORT_CARD } from '../../actions';

export enum ActiveModule {
    Dashboard = 'Dashboard',
    Card = 'Card',
}

export type ActiveModuleType = ActiveModule;

export default function activeModuleReducer(
    state: ActiveModuleType = ActiveModule.Dashboard,
    action: Action
): ActiveModuleType {
    switch (action.type) {
        case SHOW_DASHBOARD:
            return ActiveModule.Dashboard;

        case SHOW_REPORT_CARD:
        case SHOW_NEW_REPORT_CARD:
            return ActiveModule.Card;

        default:
            return state;
    }
}
