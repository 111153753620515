import { Text } from '@approvalmax/ui/src/components';
import { useSelector } from 'modules/react-redux';
import { memo, useState } from 'react';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import StepSubmitterSection from '../StepSubmitterSection/StepSubmitterSection';
import { messages } from './XeroContactStartStep.messages';

const XeroContactStartStep = memo(() => {
    const [collapsed, setCollapsed] = useState<boolean>();
    const template = useSelector(getActiveTemplate)!;
    const pluralTemplateName = template.displayNamePlural;

    return (
        <FixedStepContainer bubbleText={messages.bubbleText({ templateName: template.displayName })}>
            <Text font='body' fontSize='small' color='midnight70'>
                {messages.title({ pluralTemplateName })}
            </Text>

            <StepSubmitterSection
                cacheId='XeroContactStartStepSubmitter'
                headerText={messages.createInApprovalMax}
                description={messages.description}
                required
                noMatrix
                collapsed={collapsed}
                onCollapse={setCollapsed}
            />
        </FixedStepContainer>
    );
});

export default XeroContactStartStep;
