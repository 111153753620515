import { Mods, mods, theme } from '@approvalmax/theme';
import { Box } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
`;

export const Table = styled.table`
    min-width: 100%;
`;

export const Header = styled.div<StyledMods<Mods<'sticky'>>>`
    background-color: ${theme.color.white100};
    padding: 0 40px 0 0;

    ${mods.sticky.true`
        box-shadow: ${theme.shadow.small};
    `};
`;

export const AddRuleNameContainer = styled(Box)`
    position: sticky;
    left: 0;
`;
