import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data/api';

import { authApiPaths } from '../paths';
import { UseFido2CredentialCreateData } from './useFido2CredentialCreate.types';

export const useFido2CredentialCreate = (
    mutationOptions?: MutationOptions<any, RequestDataParams<UseFido2CredentialCreateData>>
) => {
    return useMutate<RequestDataParams<UseFido2CredentialCreateData>>(authApiPaths.fido2Credentials, {
        apiVersion: 'v2',
        mutationOptions,
    });
};
