import { ErrorCode } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { produce } from 'immer';
import { selectors } from 'modules/common';
import { domain, Entities } from 'modules/data';
import { mergeDeep } from 'modules/immutable';

import {
    Action,
    COMPLETE_INTEGRATION_AUTH,
    COMPLETE_INTEGRATION_AUTH_FAILURE,
    COMPLETE_INTEGRATION_AUTH_RESPONSE,
    CONNECT_TO_INTEGRATION,
    CONNECT_TO_INTEGRATION_FAILURE,
    DISCONNECT_FROM_INTEGRATION,
    DISCONNECT_FROM_INTEGRATION_FAILURE,
    GET_INTEGRATION_CACHE_STATUS_RESPONSE,
    INTEGRATION_ERROR,
    INVALIDATE_FIELDS,
    LOAD_FIELDS_RESPONSE,
    SYNC_INTEGRATION,
    SYNC_INTEGRATION_FAILURE,
    SYNC_INTEGRATION_RESPONSE,
    UPDATE_INTEGRATION_CACHE,
    UPDATE_INTEGRATION_CACHE_RESPONSE,
} from '../actions';

const reducer = produce((draft: Entities, action: Action): Entities => {
    switch (action.type) {
        case INVALIDATE_FIELDS:
            for (let f of Object.values(draft.fields)) {
                if (f.companyId === action.payload.companyId) {
                    delete draft.fields[f.id];
                }
            }

            return draft;

        case CONNECT_TO_INTEGRATION:
            if (action.payload.integrationFirst) {
                return draft;
            }

            if (action.payload.integrationId) {
                // Reconnect to existing
                draft.integrations[action.payload.integrationId].status = domain.IntegrationStatus.Connecting;
            } else {
                if (!draft.companies[action.payload.companyId]) {
                    // QuickBooks case: signup with SSO + immediately start integration
                    return draft;
                }

                const integrationId = action.payload.newIntegration.id;

                draft.integrations[integrationId] = action.payload.newIntegration;
                draft.companies[action.payload.companyId].integrationId = integrationId;
            }

            return draft;

        case CONNECT_TO_INTEGRATION_FAILURE:
            if (action.payload.request.integrationFirst) {
                return draft;
            }

            if (action.payload.request.integrationId) {
                // Reconnect to existing
                draft.integrations[action.payload.request.integrationId].status = domain.IntegrationStatus.Disconnected;

                if (selectors.integration.isIntegrationError(action.error)) {
                    const integration = draft.integrations[action.payload.request.integrationId];

                    integration.errorStatusCode = Number(action.error?.code);
                    integration.errorStatusMessage = action.error?.title;
                }
            } else {
                if (!draft.companies[action.payload.request.companyId]) {
                    // QuickBooks case: signup with SSO + immediately start integration
                    return draft;
                }

                const integrationId = action.payload.request.newIntegration.id;

                delete draft.integrations[integrationId];
                draft.companies[action.payload.request.companyId].integrationId = null;
            }

            return draft;

        case DISCONNECT_FROM_INTEGRATION:
            draft.integrations[action.payload.integrationId].status = domain.IntegrationStatus.Disconnecting;

            return draft;

        case DISCONNECT_FROM_INTEGRATION_FAILURE: {
            const integration = draft.integrations[action.payload.request.integrationId];

            integration.status = domain.IntegrationStatus.Connected;

            if (selectors.integration.isIntegrationError(action.error)) {
                integration.errorStatusCode = Number(action.error?.code);
                integration.errorStatusMessage = action.error?.title;
                integration.status = domain.IntegrationStatus.Disconnected;
            }

            return draft;
        }

        case COMPLETE_INTEGRATION_AUTH:
            if (action.payload.existingIntegrationId) {
                // Reconnection case
                draft.integrations[action.payload.existingIntegrationId].status =
                    domain.IntegrationStatus.ConnectingFinalizing;
            } else {
                // First-connection case
                const integrationId = action.payload.newIntegration!.id;

                draft.integrations[integrationId] = action.payload.newIntegration!;
                draft.companies[action.payload.companyId].integrationId = integrationId;
            }

            return draft;

        case COMPLETE_INTEGRATION_AUTH_RESPONSE: {
            const firstTimeConnection = !action.payload.request.existingIntegrationId;

            if (firstTimeConnection) {
                // First-connection case => remove temporary integration object
                delete draft.integrations[action.payload.request.newIntegration!.id];

                // Set firstTimeConnection  = true
                const newIntegrationId = Object.values(action.payload.entities.integrations)[0].id;

                draft.integrations[newIntegrationId].firstTimeConnection = true;
            }

            return draft;
        }

        case COMPLETE_INTEGRATION_AUTH_FAILURE:
            if (action.payload.request.existingIntegrationId) {
                const errorCode = errorHelpers.getErrorCode(action.error);

                // Reconnection case
                let integration = draft.integrations[action.payload.request.existingIntegrationId];

                if (errorCode === ErrorCode.E5030_XERO_COMPANY_HAS_ALREADY_BEEN_INTEGRATED_BEFORE) {
                    integration.status = domain.IntegrationStatus.Connected;

                    return draft;
                }

                integration.status = domain.IntegrationStatus.Disconnected;

                if (selectors.integration.isIntegrationError(action.error)) {
                    integration.errorStatusCode = Number(action.error?.code);
                    integration.errorStatusMessage = action.error?.title;
                }
            } else {
                // First-connection case
                const integrationId = action.payload.request.newIntegration!.id;

                delete draft.integrations[integrationId];
                draft.companies[action.payload.request.companyId].integrationId = null;
            }

            return draft;

        case SYNC_INTEGRATION:
        case SYNC_INTEGRATION_RESPONSE:
            return draft;

        case SYNC_INTEGRATION_FAILURE: {
            // - Revert integration object to the previous state
            // - Set error when needed
            let newIntegration = action.payload.request.integration;

            if (selectors.integration.isIntegrationError(action.error)) {
                const errorStatusCode = Number(action.error?.code);
                const errorStatusMessage = action.error?.title;

                newIntegration = produce(newIntegration, (integrationDraft) => {
                    integrationDraft.errorStatusCode = errorStatusCode;
                    integrationDraft.errorStatusMessage = errorStatusMessage;
                    integrationDraft.status = domain.IntegrationStatus.Disconnected;
                });
            }

            draft.integrations[action.payload.request.integration.id] = newIntegration;

            return draft;
        }

        case INTEGRATION_ERROR:
            if (selectors.integration.isIntegrationError(action.error)) {
                const integration = draft.integrations[action.payload.integrationId];

                integration.errorStatusCode = Number(action.error?.code);
                integration.errorStatusMessage = action.error?.title;
                integration.status = domain.IntegrationStatus.Disconnected;
            }

            return draft;

        case GET_INTEGRATION_CACHE_STATUS_RESPONSE:
            draft.integrations[action.payload.request.integrationId].cacheItems = action.payload.cacheObjects;

            return draft;

        case UPDATE_INTEGRATION_CACHE: {
            const integration = draft.integrations[action.payload.integrationId];

            if (integration.cacheItems) {
                integration.cacheItems = integration.cacheItems.map((x) => {
                    if (action.payload.cacheTypes.includes(x.cacheType)) {
                        return {
                            ...x,
                            loadingInProgress: true,
                        };
                    } else {
                        return x;
                    }
                });
            }

            return draft;
        }

        case UPDATE_INTEGRATION_CACHE_RESPONSE: {
            const integration = draft.integrations[action.payload.request.integrationId];

            if (integration.cacheItems) {
                let cacheObjects = action.payload.cacheObjects;

                integration.cacheItems = integration.cacheItems.map((it) => {
                    const newItem = cacheObjects.find((x) => x.cacheType === it.cacheType)!;

                    return {
                        ...newItem,
                        loadingInProgress: newItem.loadingInProgress || it.loadingInProgress,
                    };
                });
            }

            return draft;
        }

        default:
            return draft;
    }
});

export default function (state: Entities, action: Action): Entities {
    switch (action.type) {
        case LOAD_FIELDS_RESPONSE: {
            return mergeDeep(state, action.entities, (target: Entities['fields'], newValues: Entities['fields']) => {
                const targetFields = {
                    ...target,
                };

                /**
                 * Remove NetSuite Custom fields from current state
                 * to ensure they will be replaced with actual custom fields
                 */
                Object.keys(targetFields).forEach((fieldId) => {
                    if (
                        targetFields[fieldId] &&
                        targetFields[fieldId].systemPurpose === domain.FieldSystemPurpose.NetSuiteCustom
                    ) {
                        delete targetFields[fieldId];
                    }
                });

                return {
                    ...targetFields,
                    ...newValues,
                };
            });
        }

        case COMPLETE_INTEGRATION_AUTH_RESPONSE:
            /* First merge new entities, then reducer */
            state = mergeDeep(state, action.payload.entities);

            return reducer(state, action);

        case GET_INTEGRATION_CACHE_STATUS_RESPONSE:
        case UPDATE_INTEGRATION_CACHE_RESPONSE:
            /* First reducer, then merge new entities */
            state = reducer(state, action);

            if (!action.entities) {
                return state;
            }

            return mergeDeep(state, action.entities);

        default:
            return reducer(state, action);
    }
}
