import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { ReactNode, useMemo } from 'react';

export const useWorkflowListItem = (
    workflows: selectors.types.ExpandedTemplate[],
    meta: {
        code: domain.IntegrationCode;
        available?: boolean;
        isBeta?: boolean;
        isPremium?: boolean;
        icon?: ReactNode;
    }[]
) => {
    return useMemo(
        () =>
            meta.reduce<
                {
                    workflow: selectors.types.ExpandedTemplate;
                    isBeta?: boolean;
                    isPremium?: boolean;
                    icon?: ReactNode;
                }[]
            >((acc, { code, available, isBeta, isPremium, icon }) => {
                if (!(available ?? true)) return acc;

                const targetWorkflow = workflows.find(({ integrationCode }) => integrationCode === code);

                if (targetWorkflow) {
                    acc.push({
                        workflow: targetWorkflow,
                        isPremium: isPremium ?? false,
                        isBeta: isBeta ?? false,
                        icon,
                    });
                }

                return acc;
            }, []),
        [workflows, meta]
    );
};
