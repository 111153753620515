import { immutable, ImmutableObject, merge, set } from 'modules/immutable';

import {
    Action,
    APPLY_REPORT_FILTERS,
    CANCEL_ACTIVE_POPUP,
    SHOW_REPORT_FILTERS_POPUP,
    UPDATE_DYNAMIC_REPORT_COLUMNS,
    UPDATE_FILTERS_POPUP_REPORT_CONFIG,
} from '../../actions';
import { ReportConfig, updateDynamicColumns } from '../../data/reportConfig';

export const REPORT_FILTERS_POPUP_ID = 'REPORTS/REPORT_FILTERS';

export interface ReportFiltersPopupData {
    id: typeof REPORT_FILTERS_POPUP_ID;
    reportConfig: ReportConfig;
    modified: boolean;
}

export type ReportFiltersPopupStateType = ImmutableObject<ReportFiltersPopupData> | null;

export default function (state: ReportFiltersPopupStateType, action: Action): ReportFiltersPopupStateType {
    switch (action.type) {
        case APPLY_REPORT_FILTERS:
        case CANCEL_ACTIVE_POPUP:
            return null;

        case SHOW_REPORT_FILTERS_POPUP:
            return immutable<ReportFiltersPopupData>({
                id: REPORT_FILTERS_POPUP_ID,
                reportConfig: action.payload.reportConfig,
                modified: false,
            });

        case UPDATE_DYNAMIC_REPORT_COLUMNS: {
            if (!state) {
                return state;
            }

            const newReportConfig = updateDynamicColumns(state.reportConfig, {
                standaloneFields: action.standaloneFields,
                qBooksCustomFields: action.qBooksCustomFields,
                xeroTrackingFields: action.xeroTrackingFields,
            });

            // commit changes
            if (state.reportConfig !== newReportConfig) {
                state = set(state, 'reportConfig', newReportConfig);
                state = set(state, 'modified', true);

                return state;
            }

            return state;
        }

        case UPDATE_FILTERS_POPUP_REPORT_CONFIG:
            if (!state) {
                return state;
            }

            return merge(state, {
                reportConfig: action.payload.reportConfig,
                modified: true,
            });

        default:
            return state;
    }
}
