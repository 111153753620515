import { TextEditor } from '@approvalmax/ui';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

import { useTwoFaEnablingWizardContext } from '../../TwoFaEnablingWizard.context';
import AnotherMethodButton from '../AnotherMethodButton/AnotherMethodButton';
import ProgressFlow2fa from '../ProgressFlow2fa/ProgressFlow2fa';
import { useStepEmailEnter } from './StepEmailEnter.hooks';
import { messages } from './StepEmailEnter.messages';
import { ButtonNext, Content, Description, EmailField, Root, Title } from './StepEmailEnter.styles';

const qa = bemFactory.qa('pro-two-fa-setup-email-enter');

const StepEmailEnter: FC = memo(() => {
    const { setActiveStep } = useTwoFaEnablingWizardContext();

    const onNext = useCallback(() => {
        setActiveStep('emailConfirm');
    }, [setActiveStep]);

    const { email, inputRef, onChange, onSubmit, canSubmit, isLoading, errorMessage } = useStepEmailEnter(onNext);

    return (
        <Root qa={qa()} title={messages.popupTitle}>
            <Content>
                <ProgressFlow2fa activeStepIndex={2} />

                <Title>{messages.title}</Title>

                <Description>{messages.description}</Description>

                <EmailField title={messages.codeFieldTitle} errorText={errorMessage}>
                    <TextEditor
                        ref={inputRef}
                        onEnter={onSubmit}
                        focusOnMount
                        disabled={isLoading}
                        value={email || ''}
                        onChange={onChange}
                        placeholder={messages.emailPlaceholder}
                        invalid={!!errorMessage}
                    />
                </EmailField>

                <ButtonNext execute={onSubmit} disabled={!canSubmit}>
                    {messages.nextButton}
                </ButtonNext>

                <AnotherMethodButton />
            </Content>
        </Root>
    );
});

export default StepEmailEnter;
