import {
    UseStartAddingTFABackupCodesBackend,
    UseStartAddingTFABackupCodesResponse,
} from './useStartAddingTFABackupCodes.types';

export const mapData = (response: UseStartAddingTFABackupCodesBackend): UseStartAddingTFABackupCodesResponse => {
    return {
        stateId: response.StateId,
        backupCodes: response.BackupCodes,
    };
};
