import FilterType from './FilterType';

export interface StringFilter {
    type: FilterType.String;
    value: string | null;
}

export function createAlwaysTrueFilter(): StringFilter {
    return {
        type: FilterType.String,
        value: null,
    };
}

export function getFilterTransfer(filter: StringFilter): string | null {
    return filter.value?.trim() || null;
}

export function parseFilter(filterAnswer: any): StringFilter {
    return {
        type: FilterType.String,
        value: filterAnswer || null,
    };
}

export function getFilterPreviewText(filter: StringFilter): string | null {
    return filter.value;
}
