import { font } from '@approvalmax/ui';
import styled from 'styled-components';

import SsoPanel from '../SsoPanel/SsoPanel';

export const Root = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.h1`
    ${font(16, '#000', 'semibold')}
    margin-left: 20px;
    text-align: left;
`;

export const Description = styled.div`
    ${font(12, '#000')}
    margin-top: 20px;
`;

export const PasswordRow = styled.div`
    display: flex;
    align-items: center;

    & > :last-child {
        margin-left: 10px;
    }
`;

export const StyledSsoPanel = styled(SsoPanel)`
    margin-left: 40px;
    padding: 30px;
    background-color: #f3f3f3;
`;

export const LoginButtonWrapper = styled.div`
    margin-top: 10px;
    align-self: flex-start;
`;

export const TrustThisDeviceContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
`;
