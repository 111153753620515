import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.button<StyledMods<Mods<'active' | 'disabled'>>>`
    min-height: 32px;
    padding: 10px 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: ${theme.color.silver80};
    user-select: none;
    border: none;
    text-align: left;

    &:hover {
        ${mods.disabled.false`
            cursor: pointer;

            ${mods.active.false`
                background-color: ${theme.color.silver100};
                color: ${theme.color.blue100};
            `};
        `};
    }

    ${mods.active.true`
        color: ${theme.color.white100};
        background-color: ${theme.color.midnight80};
    `};

    ${mods.disabled.true`
        box-shadow: inset 0 0 0 1px ${theme.color.midnight60};
        background-color: ${theme.color.white100};
    `};
`;
