import { selectors } from 'modules/common';
import moment from 'moment';

const dateForNewUser = moment('2010-08-14');

export const isNewUserForTrialExperiment = (createDate: string) => dateForNewUser.isSameOrBefore(createDate);

export const getCanShowSubscribeAfterTrialButton = (
    company?: selectors.types.ExpandedCompany,
    profile?: selectors.types.ExpandedProfile | null
) => {
    if (!profile || !company) {
        return false;
    }

    return (
        isNewUserForTrialExperiment(profile.createdDate) &&
        company.isReadonly &&
        company.flags.isAccountOwner &&
        company.flags.isExpired &&
        company.flags.isTrialLicense
    );
};
