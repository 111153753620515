import { DeleteIcon, HelpCircleIcon } from '@approvalmax/ui';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Flex,
    Form,
    Link,
    Popup,
    Select,
    Text,
    TextField,
} from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { XeroIcon } from 'modules/sprites';
import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import {
    useCreateXeroCounterpartyPaymentDetails,
    useDeleteXeroCounterpartyPaymentDetails,
    useEditXeroCounterpartyPaymentDetails,
    useGetXeroCounterparties,
} from 'shared/data';
import { convertBillingAddressToString } from 'shared/helpers';
import { useCompanyBasedOnUrl } from 'shared/hooks/useCompanyBasedOnUrl';
import { z } from 'zod';

import { contactListPageLimit } from '../../../app/(workspace)/[companyId]/contactPaymentDetails/resources/constants';
import { UseCreateXeroCounterpartyPaymentDetailsResponse } from '../../data/queries/integrations/useCreateXeroCounterpartyPaymentDetails/useCreateXeroCounterpartyPaymentDetails.types';
import { UseEditXeroCounterpartyPaymentDetailsResponseBackend } from '../../data/queries/integrations/useEditXeroCounterpartyPaymentDetails/useEditXeroCounterpartyPaymentDetails.types';
import { RemoveConfirmationPopupContent } from './components/RemoveConfirmationPopupContent/RemoveConfirmationPopupContent';
import { countries, currencies } from './EditPaymentDetailsPopupContent.constants';
import { useInitialFormValues } from './EditPaymentDetailsPopupContent.hooks';
import { messages } from './EditPaymentDetailsPopupContent.messages';
import { schema } from './EditPaymentDetailsPopupContent.schema';
import { EditPaymentDetailsPopupContentProps } from './EditPaymentDetailsPopupContent.types';

export const EditPaymentDetailsPopupContent = memo<EditPaymentDetailsPopupContentProps>((props) => {
    const {
        contact: contactByProp,
        contactQueryData,
        paymentDetails,
        paymentDetailsId,
        requestId,
        onPaymentDetailsChanged,
    } = props;

    const [isRemovePopupOpened, toggleIsRemovePopupOpened] = useToggle(false);

    const company = useCompanyBasedOnUrl();

    const { data, isFetching: isFetchingContactByQuery } = useGetXeroCounterparties(
        { companyId: company.id },
        { nameFilter: contactQueryData?.name ?? null, offset: 0, limit: 1 },
        (lastPage, pages) => {
            if (!lastPage.hasMore) {
                return;
            }

            const allItemsCount = pages.reduce<number>((count, page) => {
                return count + page.items.length;
            }, 0);

            return {
                params: {
                    offset: allItemsCount,
                    limit: contactListPageLimit,
                },
            };
        },
        { enabled: !!contactQueryData }
    );

    const contactByQuery = data?.pages[0]?.items[0];

    const contact = contactByProp || contactByQuery;

    const { mutateAsync: createPaymentDetails, isLoading: isCreatingDetails } =
        useCreateXeroCounterpartyPaymentDetails();

    const { mutateAsync: editPaymentDetails, isLoading: isUpdatingDetails } = useEditXeroCounterpartyPaymentDetails();

    const { mutateAsync: deletePaymentDetails, isLoading: isDeletingDetails } =
        useDeleteXeroCounterpartyPaymentDetails();

    const initialFormValues = useInitialFormValues({
        paymentDetailsId,
        companyId: company.id,
        contact: contact || contactByQuery || null,
        preloadedListOfPaymentDetails: paymentDetails,
        requestId,
    });

    const form = useForm<z.infer<typeof schema>>({
        values: initialFormValues,
        resolver: zodResolver(schema),
    });

    const formSubmit = form.handleSubmit(async (data) => {
        if (!contact) {
            return;
        }

        const { isDefault, ...bankAccount } = data;

        const transfer = {
            isDefault: data.isDefault,
            bankAccount,
        };

        let response:
            | UseCreateXeroCounterpartyPaymentDetailsResponse
            | UseEditXeroCounterpartyPaymentDetailsResponseBackend;

        if (paymentDetailsId) {
            response = await editPaymentDetails({
                data: transfer,
                pathParams: {
                    companyId: company.id,
                    counterpartyId: contact.counterpartyId,
                    paymentDetailsId,
                },
            });
        } else {
            response = await createPaymentDetails({
                data: transfer,
                pathParams: {
                    companyId: company.id,
                    counterpartyId: contact.counterpartyId,
                },
            });
        }

        onPaymentDetailsChanged(response.paymentDetails);
    });

    const handleRemoveCancel = useCallback(() => {
        toggleIsRemovePopupOpened(false);
    }, [toggleIsRemovePopupOpened]);

    const handleRemoveSubmit = useCallback(async () => {
        toggleIsRemovePopupOpened(false);

        if (!contact || !paymentDetailsId) {
            return;
        }

        await deletePaymentDetails({
            data: {},
            pathParams: {
                companyId: company.id,
                counterpartyId: contact.counterpartyId,
                paymentDetailsId,
            },
        });

        onPaymentDetailsChanged();
    }, [
        deletePaymentDetails,
        toggleIsRemovePopupOpened,
        onPaymentDetailsChanged,
        paymentDetailsId,
        company.id,
        contact,
    ]);

    const isLoading = isCreatingDetails || isUpdatingDetails || isDeletingDetails || isFetchingContactByQuery;

    const hasFormErrors = Object.keys(form.formState.errors).length > 0;

    const noEditChanges = Boolean(paymentDetailsId && !form.formState.isDirty);

    const country = form.watch('country');
    const currency = form.watch('currency');

    return (
        <Form form={form} onSubmit={formSubmit}>
            <Popup.Header
                title={paymentDetailsId ? messages.titleEdit : messages.titleNew}
                actions={
                    <Button
                        disabled={isLoading || hasFormErrors || noEditChanges}
                        type='submit'
                        color='blue80'
                        size='large'
                    >
                        {messages.save}
                    </Button>
                }
                progress={isLoading}
            />

            <Popup.Body>
                <Flex inline direction='column' spacing='24'>
                    <Alert color='midnight20' closable alignItems='center' startIcon={<HelpCircleIcon />}>
                        <Flex inline direction='column' spacing='4'>
                            <Text font='body' fontSize='small' color='midnight80'>
                                {messages.helpText}
                            </Text>

                            <Link
                                external
                                font='label'
                                fontSize='small'
                                href='https://support.approvalmax.com/portal/en/kb/articles/what-is-contact-payment-details'
                            >
                                {messages.learnMore}
                            </Link>
                        </Flex>
                    </Alert>

                    {contact && (
                        <Box color='midnight30' spacing='8' radius='small'>
                            <Flex inline alignItems='center' spacing='8'>
                                <XeroIcon />

                                <Flex inline direction='column' spacing='4'>
                                    <Text font='label' fontSize='medium' fontWeight='medium'>
                                        {contact.name}
                                    </Text>

                                    {contact.billingAddress && (
                                        <Text font='label' fontSize='small' fontWeight='regular' color='midnight70'>
                                            {convertBillingAddressToString(contact.billingAddress, ', ')}
                                        </Text>
                                    )}
                                </Flex>
                            </Flex>
                        </Box>
                    )}

                    <Flex inline direction='column' spacing='24'>
                        <Checkbox name='isDefault' disabled={isLoading}>
                            {messages.defaultCheckbox}
                        </Checkbox>

                        <Flex container spacing='24'>
                            <Flex size={12}>
                                <Select
                                    name='country'
                                    label={messages.bankAccountCountry}
                                    required
                                    size='medium'
                                    placeholder={messages.selectPlaceholder}
                                    tabIndex={1}
                                    items={countries}
                                    value={country}
                                />
                            </Flex>

                            <Flex size={12}>
                                <Select
                                    name='currency'
                                    label={messages.bankAccountCurrency}
                                    required
                                    size='medium'
                                    placeholder={messages.selectPlaceholder}
                                    tabIndex={2}
                                    items={currencies}
                                    value={currency}
                                />
                            </Flex>
                        </Flex>

                        <div>
                            <TextField
                                name='name'
                                label={messages.accountName}
                                required
                                size='medium'
                                placeholder={messages.accountNamePlaceholder}
                                tabIndex={3}
                                maxLength={200}
                                disabled={isLoading}
                            />
                        </div>

                        <Flex container spacing='24'>
                            <Flex size={12}>
                                <TextField
                                    name='sortCode'
                                    label={messages.sortCode}
                                    required
                                    size='medium'
                                    placeholder={messages.inputPlaceholder}
                                    maxLength={6}
                                    tabIndex={4}
                                    disabled={isLoading}
                                />
                            </Flex>

                            <Flex size={12}>
                                <TextField
                                    name='accountNumber'
                                    label={messages.bankAccountNumber}
                                    required
                                    size='medium'
                                    placeholder={messages.inputPlaceholder}
                                    maxLength={8}
                                    tabIndex={5}
                                    disabled={isLoading}
                                />
                            </Flex>
                        </Flex>
                    </Flex>

                    <Box spacing='8 0 0 0'>
                        <Button
                            color='red40'
                            size='medium'
                            startIcon={<DeleteIcon />}
                            disabled={!paymentDetailsId || isLoading}
                            onClick={toggleIsRemovePopupOpened}
                        >
                            {messages.remove}
                        </Button>
                    </Box>
                </Flex>

                <Popup open={isRemovePopupOpened} size='xsmall' onToggle={toggleIsRemovePopupOpened}>
                    <RemoveConfirmationPopupContent onCancel={handleRemoveCancel} onSubmit={handleRemoveSubmit} />
                </Popup>
            </Popup.Body>
        </Form>
    );
});

EditPaymentDetailsPopupContent.displayName = 'EditPaymentDetailsPopupContent';
