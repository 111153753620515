import { objectHelpers } from '@approvalmax/utils';

import {
    UseCreateNetSuiteVendorResponse,
    UseCreateNetSuiteVendorResponseBackend,
} from './useCreateNetSuiteVendor.types';

export const mapData = (data: UseCreateNetSuiteVendorResponseBackend): UseCreateNetSuiteVendorResponse => {
    return objectHelpers.pascalCaseToCamelCase(data);
};
