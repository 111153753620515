import { theme } from '@approvalmax/theme';
import { Box } from '@approvalmax/ui/src/components';
import styled from 'styled-components';

export const Root = styled(Box)`
    position: sticky;
    left: 0;
    z-index: 1;
    background: ${theme.color.white100};
`;
