import uniqueId from 'lodash/uniqueId';
import { domain } from 'modules/data';

const prefix = 'lineItem-';

export function isNewXeroLineItem(li: domain.XeroLineItem) {
    return li.id.startsWith(prefix);
}

export function createXeroLineItem(): domain.XeroLineItem {
    return {
        id: uniqueId(prefix),
        description: '',
        tracking: [],
        isInventory: false,
    };
}

export function cloneXeroLineItem(li: domain.XeroLineItem): domain.XeroLineItem {
    return {
        id: uniqueId(prefix),
        description: li.description,
        tracking: li.tracking.map((t) => ({ ...t })),
        isInventory: li.isInventory,
        item: li.item,
        account: li.account,
        tax: li.tax,
        qty: li.qty,
        unitPrice: li.unitPrice,
        discount: li.discount,
    };
}
