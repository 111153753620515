import { init as initFullStory } from '@fullstory/browser';
import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';
import { SignUpSource } from 'modules/data/domain/Profile';
import { api } from 'services/api';

const orgId = 'o-1RSWV4-na1';

class FullstoryService {
    public async init(state: stateTree.State) {
        const environment = window.ApprovalMax.environment;

        if (environment !== 'production') {
            return;
        }

        const profile = selectors.profile.findProfile(state);

        if (profile?.signUpSource !== SignUpSource.App) {
            return;
        }

        const subscriptions = selectors.subscription.getAllSubscriptions(state);

        if (subscriptions.length !== 0) {
            return;
        }

        const potentialTeammates = await api.auth.getPotentialTeammates();

        if (potentialTeammates.emails.length !== 0) {
            return;
        }

        initFullStory({ orgId });

        if (profile.id && typeof window.FS === 'function') {
            window.FS('setIdentity', { uid: profile.id });
        }
    }
}
export const fullstoryService = new FullstoryService();
