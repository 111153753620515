import { useLocation } from 'react-router-dom';
import { atom } from 'recoil';

export const routeState = atom<Partial<ReturnType<typeof useLocation>>>({
    key: 'routeState',
    default: {
        pathname: '',
        search: '',
        hash: '',
    },
});

export const routeParamsState = atom<Record<string, any>>({
    key: 'routeParamsState',
    default: {},
});
