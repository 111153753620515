import { createPopupReducer } from 'modules/react-redux';

import { CANCEL_ACTIVE_POPUP } from '../../actions';
import cancelRequestPopupReducer, { CancelRequestPopupType } from './activePopup/cancelRequestPopupReducer';
import declineCustomerDecisionPopupReducer, {
    DeclineCustomerDecisionPopupType,
} from './activePopup/declineCustomerDecisionPopupReducer';
import editParticipantsPopupReducer, { EditParticipantsPopupType } from './activePopup/editParticipantsPopupReducer';
import editReviewersPopupReducer, { EditReviewersPopupType } from './activePopup/editReviewersPopupReducer';
import editWatchersPopupReducer, { EditWatchersPopupType } from './activePopup/editWatchersPopupReducer';
import forceDecisionPopupReducer, { ForceDecisionPopupType } from './activePopup/forceDecisionPopupReducer';
import matchingPopupReducer, { MatchingPopupType } from './activePopup/matchingPopupReducer';
import matchingPORequestersPopupReducer, {
    MatchingPORequestersPopupType,
} from './activePopup/matchingPORequestersPopupReducer';
import operationResultPopupReducer, { OperationResultPopupType } from './activePopup/operationResultPopupReducer';
import rejectRequestPopupReducer, { RejectRequestPopupType } from './activePopup/rejectRequestPopupReducer';
import requestReassignPopupReducer, { RequestReassignPopupType } from './activePopup/requestReassignPopupReducer';
import requestSearchPopupReducer, { RequestSearchPopupType } from './activePopup/requestSearchPopupReducer';
import revokeDecisionPopupReducer, { RevokeDecisionPopupType } from './activePopup/revokeDecisionPopupReducer';
import startOverPopupReducer, { StartOverPopupType } from './activePopup/startOverPopupReducer';
import xeroRequestBudgetPopupReducer, { XeroRequestBudgetPopupType } from './activePopup/xeroRequestBudgetPopupReducer';

export type ActivePopupType =
    | RejectRequestPopupType
    | DeclineCustomerDecisionPopupType
    | ForceDecisionPopupType
    | OperationResultPopupType
    | CancelRequestPopupType
    | StartOverPopupType
    | RevokeDecisionPopupType
    | RequestReassignPopupType
    | EditParticipantsPopupType
    | EditReviewersPopupType
    | EditWatchersPopupType
    | XeroRequestBudgetPopupType
    | RequestSearchPopupType
    | MatchingPopupType
    | MatchingPORequestersPopupType;

export default createPopupReducer(
    CANCEL_ACTIVE_POPUP,
    rejectRequestPopupReducer,
    declineCustomerDecisionPopupReducer,
    forceDecisionPopupReducer,
    operationResultPopupReducer,
    cancelRequestPopupReducer,
    startOverPopupReducer,
    revokeDecisionPopupReducer,
    requestReassignPopupReducer,
    editParticipantsPopupReducer,
    editReviewersPopupReducer,
    editWatchersPopupReducer,
    xeroRequestBudgetPopupReducer,
    requestSearchPopupReducer,
    matchingPopupReducer,
    matchingPORequestersPopupReducer
);
