import { errorHelpers, mathService } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import moment from 'moment';
import { createSelector } from 'reselect';

import * as qbooksAccountLineItem from '../../data/qbooks/QBooksAccountLineItem';
import { QBooksContext } from '../../data/qbooks/QBooksContext';
import { Context } from '../../reducers/page/contextReducer';
import { getContext } from '../pageSelectors';
import { getRequestEditMode, getRequiredFields, RequestEditMode } from '../requestSelectors';
import { BillableAccountType, ExpandedQBooksAccountLineItem } from '../types/ExpandedQBooksAccountLineItem';
import { QBooksLineItemsSummary } from './lineItemSelectors';
import { getQBooksContext } from './qbooksSelectors';
import { calculateAccountLineItemsTaxSummary } from './taxSelectors';

export function isEmptyQBooksAccountLineItem(li: domain.QBooksAccountLineItem): boolean {
    return !li.account && li.amount == null && !li.description && !li.customer && !li.class;
}

function isValidQBooksAccountLineItem(
    li: domain.QBooksAccountLineItem,
    lineAmountType: domain.LineAmountType,
    context: Context,
    qbooksContext: QBooksContext,
    requiredFields: ReturnType<typeof getRequiredFields>
): boolean {
    if (isEmptyQBooksAccountLineItem(li)) {
        return true;
    }

    let hasAllMandatoryFields = Boolean(li.amount != null && li.account);

    if (lineAmountType !== domain.LineAmountType.NoTax && qbooksContext.isTrackingTaxesEnabled) {
        hasAllMandatoryFields = hasAllMandatoryFields && Boolean(li.taxCode);
    }

    if (
        qbooksContext.hasClassFeature &&
        qbooksContext.classField &&
        requiredFields.fieldIds.includes(qbooksContext.classField.id)
    ) {
        hasAllMandatoryFields = hasAllMandatoryFields && Boolean(li.class);
    }

    const customerField = context.fields.find((f) => f.systemPurpose === domain.FieldSystemPurpose.QBooksCustomer);

    if (qbooksContext.hasCustomerFeature && customerField && requiredFields.fieldIds.includes(customerField.id)) {
        hasAllMandatoryFields = hasAllMandatoryFields && Boolean(li.customer);
    }

    return hasAllMandatoryFields;
}

export const expandQBooksAccountLineItem = (options: {
    lineAmountType: domain.LineAmountType;
    date: string;
    accountLineItem: domain.QBooksAccountLineItem;
    context: Context;
    qbooksContext: QBooksContext;
    requiredFields: ReturnType<typeof getRequiredFields>;
    editMode: RequestEditMode;
}) => {
    const { lineAmountType, accountLineItem, context, qbooksContext, requiredFields, editMode } = options;

    const isNew = qbooksAccountLineItem.isNewQBooksAccountLineItem(accountLineItem);
    const isEditorMode = editMode === RequestEditMode.Editor;
    const isApproverMode = editMode === RequestEditMode.Approver;
    const accountType =
        qbooksContext.accounts.find((account) => account.id === accountLineItem.account?.id)?.type || '';

    return {
        ...accountLineItem,
        canBeBillable: accountType in BillableAccountType,
        isNew,
        empty: isEmptyQBooksAccountLineItem(accountLineItem),
        valid: isValidQBooksAccountLineItem(accountLineItem, lineAmountType, context, qbooksContext, requiredFields),
        hideRemove: (isApproverMode && !isNew) || (isEditorMode && !isNew),
        hideClone: isApproverMode,
    };
};

type QBooksDocumentRequest =
    | domain.QBooksPoRequest
    | domain.QBooksBillRequest
    | domain.QBooksExpenseRequest
    | domain.QBooksInvoiceRequest;

export const getQBooksAccountLineItems: (
    state: State,
    request: QBooksDocumentRequest
) => ExpandedQBooksAccountLineItem[] = createSelector(
    (state: State, request: QBooksDocumentRequest) => {
        if ('accountLineItems' in request.details) {
            return request.details.accountLineItems;
        }

        return [];
    },
    (state: State, request: QBooksDocumentRequest) => {
        return request.details.lineAmountType;
    },
    (state: State, request: QBooksDocumentRequest) => {
        return request.details.date;
    },
    (state: State, _request: QBooksDocumentRequest) => getContext(state),
    (state: State, _request: QBooksDocumentRequest) => getQBooksContext(state),
    (state: State, request: QBooksDocumentRequest) => getRequiredFields(state, request),
    (state: State, request: QBooksDocumentRequest) => getRequestEditMode(state, request),
    (lineItems, lineAmountType, date, context, qbooksContext, requiredFields, editMode) => {
        if (!lineItems) {
            return [];
        }

        return lineItems.map((li) =>
            expandQBooksAccountLineItem({
                lineAmountType,
                date: date || moment.utc().startOf('day').toISOString(),
                accountLineItem: li,
                context,
                qbooksContext,
                requiredFields,
                editMode,
            })
        );
    }
);

export const getQBooksAccountLineItemById = (
    state: State,
    request: domain.QBooksPoRequest | domain.QBooksBillRequest | domain.QBooksExpenseRequest,
    lineItemId: string
) => {
    const accountLineItem = getQBooksAccountLineItems(state, request).find((li) => li.id === lineItemId);

    if (!accountLineItem) {
        throw errorHelpers.notFoundError(`Account line item ${lineItemId}`);
    }

    return accountLineItem;
};

export function getQBooksAccountLineItemsSummary(
    state: State,
    request: domain.QBooksPoRequest | domain.QBooksBillRequest | domain.QBooksExpenseRequest
): QBooksLineItemsSummary {
    const qbooksContext = getQBooksContext(state);
    const lineItems = getQBooksAccountLineItems(state, request);
    const details = request.details;
    const lineAmountType = details.lineAmountType;

    let subtotalAmount = 0;

    lineItems.forEach((li) => {
        if (li.amount) {
            subtotalAmount = mathService.add(subtotalAmount, li.amount || 0);
        }
    });

    const taxSummary = calculateAccountLineItemsTaxSummary(
        lineAmountType,
        details.date || moment.utc().startOf('day').toISOString(),
        lineItems,
        qbooksContext
    );
    const defaultCurrency = selectors.company.getCompanyById(state, request.companyId).defaultCurrency;
    const nonDefaultCurrency = request.currency !== defaultCurrency;
    const totalAmount =
        lineAmountType == domain.LineAmountType.TaxInclusive
            ? subtotalAmount
            : mathService.add(subtotalAmount, taxSummary.totalTax);

    const exchangeRate = request.currencyExchangeRate || request.exchangeRate;

    const totalInDefaultCurrency = nonDefaultCurrency && exchangeRate ? totalAmount / exchangeRate : totalAmount;

    return {
        subtotalAmount,
        taxComponents: taxSummary.taxComponents,
        totalAmount,
        defaultCurrency,
        nonDefaultCurrency,
        totalInDefaultCurrency,
    };
}
