export const highlightElement = (
    element?: HTMLElement,
    styleFn?: VoidFunction,
    resetFn?: VoidFunction,
    options: {
        delay?: number;
        duration?: number;
    } = {
        delay: 500,
        duration: 500,
    }
) => {
    if (!element || !styleFn || !resetFn) return;

    const { delay, duration } = options;

    setTimeout(() => {
        element.style.transition = `${duration}ms ease-in-out`;
        styleFn();

        setTimeout(() => {
            resetFn();

            setTimeout(() => {
                element.style.cssText = '';
            }, duration);
        }, duration);
    }, delay);
};
