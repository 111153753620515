import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, CREATE_QBOOKS_CUSTOMER_RESPONSE, SHOW_QBOOKS_CUSTOMER_CREATION_POPUP } from '../../../actions';
import { EditableQBooksCustomer } from '../../../data/qbooks/EditableQBooksCounterparty';

export const QBOOKS_CUSTOMER_CREATION_POPUP = 'QBOOKS_CUSTOMER_CREATION_POPUP';

export interface QBooksCustomerCreationPopupData {
    id: typeof QBOOKS_CUSTOMER_CREATION_POPUP;
    initCustomer: EditableQBooksCustomer;
}

export type QBooksCustomerCreationPopupType = ImmutableObject<QBooksCustomerCreationPopupData> | null;

export const qbooksCustomerCreationPopupReducer = (
    state: QBooksCustomerCreationPopupType,
    action: Action
): QBooksCustomerCreationPopupType => {
    switch (action.type) {
        case SHOW_QBOOKS_CUSTOMER_CREATION_POPUP:
            return immutable<QBooksCustomerCreationPopupData>({
                id: QBOOKS_CUSTOMER_CREATION_POPUP,
                initCustomer: action.payload.customer,
            });

        case CREATE_QBOOKS_CUSTOMER_RESPONSE:
            return null;

        default:
            return state;
    }
};
