import { Box, Dropdown, RichEditorView, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { getTextContentFromHTML } from './HtmlCell.helpers';
import { Content } from './HtmlCell.styles';
import { HtmlCellProps } from './HtmlCell.types';

export const HtmlCell = memo<HtmlCellProps>((props) => {
    const { value } = props;

    const valueParts = getTextContentFromHTML(value);
    const valuePreview = valueParts.length === 0 ? '' : `${valueParts[0]}${valueParts.length !== 1 ? '...' : ''}`;

    return (
        <Dropdown
            width='max-content'
            maxHeight={170}
            activator={
                <Text font='body' ellipsis={1}>
                    {valuePreview}
                </Text>
            }
        >
            <Content>
                <Box width='max-content' spacing='8 16'>
                    <RichEditorView html={value} highlightUrls />
                </Box>
            </Content>
        </Dropdown>
    );
});

HtmlCell.displayName = 'HtmlCell';
