import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';

import { useGetRequestLegacy } from '../../../api';
import { reportsApiPaths } from '../paths';
import {
    UseGetReportRowsParams,
    UseGetReportRowsResponse,
    UseGetReportRowsResponseBackend,
} from './useGetReportRows.types';

export const useGetReportRows = (
    params: QueryParams<UseGetReportRowsParams>,
    queryOptions?: QueryOptions<UseGetReportRowsResponseBackend, UseGetReportRowsResponse>
) => {
    return useGetRequestLegacy<UseGetReportRowsResponseBackend, UseGetReportRowsResponse>(
        reportsApiPaths.getReportRows,
        params,
        {
            queryOptions: {
                ...queryOptions,
                enabled: enabledByParams<UseGetReportRowsParams>(
                    {
                        reportType: params.reportType,
                        companyId: params.companyId,
                        reportId: params.reportId,
                    },
                    queryOptions
                ),
            },
        }
    );
};
