import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import { mapData } from './useCreateRequest.map';
import {
    UseCreateRequestData,
    UseCreateRequestResponse,
    UseCreateRequestResponseBackend,
} from './useCreateRequest.types';

export const useCreateRequest = () => {
    return useMutateLegacy<
        RequestDataParams<UseCreateRequestData>,
        UseCreateRequestResponseBackend,
        UseCreateRequestResponse
    >(requestsApiPaths.create, {
        mapData,
    });
};
