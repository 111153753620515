import { Route } from 'modules/page';
import React from 'react';

const CreateRequestPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './containers/CreateRequestPage')
);
const EditRequestPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './containers/EditRequestPage')
);

const routes: Route[] = [
    {
        path: '/:companyId/requests/:requestId/edit',
        component: EditRequestPage,
    },
    {
        path: '/:companyId/requests/create',
        component: CreateRequestPage,
    },
];

export default routes;
