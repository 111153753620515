import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { ElementFactory } from 'react-bem-factory';

import { ChangeHandler, CheckValidFraudBypassing } from '../../WorkflowSettingsPopupContent.types';

export interface SectionFraudBypassingProps {
    company: selectors.types.ExpandedCompany;
    template: selectors.types.ExpandedTemplate;
    settings: domain.TemplateSettings['approvalDisregardDetectionSettings'];
    savedTemplateSettings: domain.TemplateSettings;
    effectiveDateLimits: selectors.types.EffectiveDateLimits | null;
    checkValidFraudBypassing: CheckValidFraudBypassing;
    onChangeApprovalDisregardEnabled: ChangeHandler<boolean>;
    onChangeApprovalDisregardNotifyAdmins: ChangeHandler<boolean>;
    onChangeApprovalDisregardEffectiveDate: ChangeHandler<string>;
    qa: ElementFactory;
    readonly: boolean;
}

export enum FraudBypassingOption {
    Pull = 'Pull',
    DoNotPull = 'DoNotPull',
}
