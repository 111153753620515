/**
 * Developer: Stepan Burguchev
 * Date: 6/18/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { State } from 'modules/data';

export function getModule<TModule>(state: State, moduleId: string): TModule {
    return state.modules[moduleId] as TModule;
}
