import { backend } from 'modules/data';
import { stringify } from 'query-string';

import { ApiHandler } from '../types';

class IntegrationUrls {
    constructor(private _apiHandler: ApiHandler) {}

    public getContacts(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getContacts?${stringify(transfer)}`;
    }

    public getItems(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getItems?${stringify(transfer)}`;
    }

    public getAccounts(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getAccounts?${stringify(transfer)}`;
    }

    public getTaxCodes(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getTaxCodes?${stringify(transfer)}`;
    }

    public getBrandingThemes(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getBrandingThemes?${stringify(transfer)}`;
    }

    public getCompanyAddresses(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getCompanyAddresses?${stringify(transfer)}`;
    }

    public getTrackingCategories(transfer: backend.transfers.GetTrackingCategoryTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getTrackingCategories?${stringify(transfer)}`;
    }

    public getAllContacts(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getAllContacts?${stringify(transfer)}`;
    }

    public getQBooksVendors(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getQBooksVendors?${stringify(transfer)}`;
    }

    public getQBooksPayeeVendors(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getQBooksPayeeVendors?${stringify(transfer)}`;
    }

    public getQBooksPayeeEmployees(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getQBooksPayeeEmployees?${stringify(transfer)}`;
    }

    public getQBooksCustomers(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getQBooksCustomers?${stringify(transfer)}`;
    }

    public getQBooksPayeeCustomers(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getQBooksPayeeCustomers?${stringify(transfer)}`;
    }

    public getQBooksCompanyAddresses(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getQBooksCompanyAddresses?${stringify(transfer)}`;
    }

    public getQBooksItems(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getQBooksItems?${stringify(transfer)}`;
    }

    public getQBooksAccounts(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getQBooksAccounts?${stringify(transfer)}`;
    }

    public getQBooksPaymentAccounts(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getQBooksPaymentAccounts?${stringify(transfer)}`;
    }

    public getQBooksCustomFields(transfer: backend.transfers.GetIntegrationDataTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getQBooksCustomFields?${stringify(transfer)}`;
    }

    public getIntegrationCacheStatus(transfer: backend.transfers.GetIntegrationCacheStatusTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}integration/getIntegrationCacheStatus?${stringify(transfer)}`;
    }
}

export default class IntegrationApi {
    public urls: IntegrationUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new IntegrationUrls(this._apiHandler);
    }

    public getContacts(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsXeroGetContactsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getContacts',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getItems(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsXeroGetItemsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getItems',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getAccounts(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsXeroGetAccountsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getAccounts',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getBankAccounts(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsXeroGetBankAccountsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getBankAccounts',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getTaxCodes(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsXeroGetTaxCodesAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getTaxCodes',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getBrandingThemes(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsXeroGetBrandingThemesAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getBrandingThemes',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getCompanyAddresses(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.GetAddressesAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getCompanyAddresses',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getTrackingCategories(
        transfer: backend.transfers.GetTrackingCategoryTransfer
    ): Promise<backend.GetTrackingCategoriesAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getTrackingCategories',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getAllContacts(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsXeroGetContactsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getAllContacts',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getQBooksVendors(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsQBooksGetContactsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getQBooksVendors',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getQBooksPayeeVendors(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsQBooksGetContactsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getQBooksPayeeVendors',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getQBooksCustomers(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsQBooksGetContactsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getQBooksCustomers',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getQBooksInvoiceCustomers(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsQBooksGetContactsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getQBooksInvoiceCustomers',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getQBooksPayeeCustomers(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsQBooksGetContactsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getQBooksPayeeCustomers',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getQBooksPayeeEmployees(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsQBooksGetEmployeesAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getQBooksPayeeEmployees',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getQBooksCompanyAddresses(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.GetAddressesAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getQBooksCompanyAddresses',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getQBooksPurchaseItems(
        transfer: backend.transfers.QBooksGetPurchaseItems
    ): Promise<backend.IntegrationsQBooksGetItemsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getQBooksPurchaseItems',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getQBooksSalesItems(
        transfer: backend.transfers.QBooksGetSalesItems
    ): Promise<backend.IntegrationsQBooksGetItemsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getQBooksSalesItems',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getQBooksAccounts(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsQBooksGetAccountsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getQBooksAccounts',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getQBooksPaymentAccounts(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsQBooksGetAccountsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getQBooksPaymentAccounts',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getQBooksCustomFields(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsQBooksGetCustomFieldsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getQBooksCustomFields',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public createCounterparty(
        transfer: backend.transfers.IntegrationXeroCreateCounterpartyTransfer
    ): Promise<backend.IntegrationsXeroCreateCounterpartyAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/createCounterparty',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public createXeroCounterparty(
        transfer: backend.transfers.IntegrationXeroCreateCounterpartyTransfer
    ): Promise<backend.IntegrationsXeroCreateCounterpartyAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/createXeroCounterparty',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public createQBooksVendor(
        createVendor: backend.QBooksCompanyIntegrationsCommandsCreateVendor
    ): Promise<backend.QBooksCompanyIntegrationsCommandsCreateVendorAnswer> {
        return this._apiHandler.doApiCall({
            data: createVendor,
            action: 'integration/createQBooksVendor',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public createQBooksCustomer(
        createCustomer: backend.QBooksCompanyIntegrationsCommandsCreateCustomer
    ): Promise<backend.QBooksCompanyIntegrationsCommandsCreateCustomerAnswer> {
        return this._apiHandler.doApiCall({
            data: createCustomer,
            action: 'integration/createQBooksCustomer',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getIntegrationCacheStatus(
        transfer: backend.transfers.GetIntegrationCacheStatusTransfer
    ): Promise<backend.Answer<backend.IntegrationsIntegrationCacheStatus>> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getIntegrationCacheStatus',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public refreshIntegrationCache(
        transfer: backend.transfers.IntegrationXeroRefreshIntegrationCacheTransfer
    ): Promise<backend.Answer<backend.IntegrationsIntegrationCacheStatus>> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/refreshIntegrationCache',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getNetSuiteVendors(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsNetSuiteVendors> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getNetSuiteVendors',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getNetSuiteItems(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsNetSuiteItems> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getNetSuiteItems',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getNetSuiteExpenseCategories(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsNetSuiteExpenseCategories> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getNetSuiteExpenseCategories',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getNetSuiteCustomers(
        transfer: backend.transfers.GetNetSuiteCustomersTransfer
    ): Promise<backend.IntegrationsNetSuiteCustomers> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getNetSuiteCustomers',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getNetSuiteAmortizationSchedules(transfer: backend.transfers.GetNetSuiteCustomersTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getNetSuiteAmortizationSchedules',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getNetSuiteCustomRecords(
        transfer: backend.transfers.GetIntegrationNetSuiteCustomRecordsTransfer
    ): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getNetSuiteCustomRecords',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getNetSuiteIncoterms(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.IntegrationsNetSuiteIncoterms> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getNetSuiteIncoterms',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getNetSuiteAccounts(
        transfer: backend.transfers.GetIntegrationDataTransfer & {
            accountTypes?: string[];
            doNoApplySubsidiaryFilter?: boolean;
        }
    ): Promise<backend.IntegrationsNetSuiteAccounts> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'integration/getNetSuiteAccounts',
            method: 'GET',
            allowsAnonymous: false,
        });
    }
}
