import { theme } from '@approvalmax/theme';
import { Button } from '@approvalmax/ui/src/components';
import styled from 'styled-components';

export const TooltipImageButton = styled(Button)`
    cursor: zoom-in;
`;

export const TooltipImage = styled.img`
    width: 100%;
    border: thin solid ${theme.color.midnight50};
    cursor: zoom-in;
`;
