import { Guid } from '@approvalmax/types';
import { domain, Entities } from 'modules/data';
import { createAction, ExtractActions } from 'modules/react-redux';

import { IntegrationSuccessRedirectPage } from '../types/IntegrationSuccessRedirectPage';

export const SHOW_INTEGRATION_ERROR_POPUP = 'INTEGRATION/SHOW_INTEGRATION_ERROR_POPUP';
export const showIntegrationErrorPopup = (companyId: Guid) => createAction(SHOW_INTEGRATION_ERROR_POPUP, { companyId });

export const CANCEL_ACTIVE_POPUP = 'REACT-INTEGRATION/CANCEL_ACTIVE_POPUP';
export const cancelActivePopup = () => createAction(CANCEL_ACTIVE_POPUP, {});

export const CANCEL_COMPANY_CONNECTION_ERROR_POPUP = 'INTEGRATION/CANCEL_COMPANY_CONNECTION_ERROR_POPUP';
export const cancelCompanyConnectionErrorPopup = () => createAction(CANCEL_COMPANY_CONNECTION_ERROR_POPUP, {});

export const SHOW_XERO_INTEGRATION_SELECT_POPUP = 'REACT-INTEGRATION/SHOW_XERO_INTEGRATION_SELECT_POPUP';
export const showXeroIntegrationSelectPopup = (options: {
    companyId: Guid | null;
    tenants: domain.XeroTenantInfo[];
    users: domain.User[];
    backendState: string;
}) =>
    createAction(SHOW_XERO_INTEGRATION_SELECT_POPUP, { ...options }, {
        users: options.users.reduce((m, a) => {
            m[a.id] = a;

            return m;
        }, {} as any),
    } as Entities);

export const SHOW_NETSUITE_INTEGRATION_POPUP = 'INTEGRATION/SHOW_NETSUITE_INTEGRATION_POPUP';
export const showNetSuiteIntegrationPopup = (companyId: Guid | null, redirectPage: IntegrationSuccessRedirectPage) =>
    createAction(SHOW_NETSUITE_INTEGRATION_POPUP, { companyId, redirectPage });

export const SHOW_DEAR_INTEGRATION_POPUP = 'INTEGRATION/SHOW_DEAR_INTEGRATION_POPUP';
export const showDearIntegrationPopup = (companyId: Guid | null, redirectPage: IntegrationSuccessRedirectPage) =>
    createAction(SHOW_DEAR_INTEGRATION_POPUP, { companyId, redirectPage });

export const SHOW_COMPANY_MISMATCH_POPUP = 'REACT-INTEGRATION/SHOW_COMPANY_MISMATCH_POPUP';
export const showCompanyMismatchPopup = (integrationType: domain.IntegrationType) =>
    createAction(SHOW_COMPANY_MISMATCH_POPUP, { integrationType });

export const SHOW_ANOTHER_COMPANY_INTEGRATED_POPUP = 'INTEGRATION/SHOW_ANOTHER_COMPANY_INTEGRATED_POPUP';
export const showAnotherCompanyIntegratedPopup = (integrationType: domain.IntegrationType, companyId?: string) =>
    createAction(SHOW_ANOTHER_COMPANY_INTEGRATED_POPUP, { integrationType, companyId });

export type Action = ExtractActions<
    | typeof cancelActivePopup
    | typeof cancelCompanyConnectionErrorPopup
    | typeof showAnotherCompanyIntegratedPopup
    | typeof showCompanyMismatchPopup
    | typeof showDearIntegrationPopup
    | typeof showIntegrationErrorPopup
    | typeof showNetSuiteIntegrationPopup
    | typeof showXeroIntegrationSelectPopup
>;
