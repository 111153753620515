import './selectSubscriptionPopup.scss';

import { CheckboxEditor, Popup, RadioGroupEditor, ScrollableArea } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { backend, domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useMemo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useCompanyBasedOnUrl } from 'shared/hooks/useCompanyBasedOnUrl';

import { commitSubscriptionData, SelectSubscriptionOption } from '../actions';
import NoSubscription from '../components/GrayArea/NoSubscription';
import OtherSubscriptionOptions from '../components/OtherSubscriptionOptions/OtherSubscriptionOptions';
import SubscriptionsHeader from '../components/SubscriptionsHeader/SubscriptionsHeader';
import { getApplicableSubscriptions, getPricingOptions, PriceOption } from '../selectors/moduleSelectors';

const i18nPrefix = 'company/containers/SelectSubscriptionPopup';

const AvailableBillingSystems = [domain.SubscriptionBillingSystem.Avangate, domain.SubscriptionBillingSystem.Stripe];

const GetPriceOptionForSubscription = (
    subscription: domain.Subscription,
    pricingOptions: PriceOption[],
    qty: number
) => {
    if (subscription) {
        const desiredPlan =
            subscription.plan === domain.CompanyPlan.Professional ? domain.CompanyPlan.XeroPremium : subscription.plan;

        const pricingOption = pricingOptions.find(
            (option) =>
                option.edition === subscription?.edition &&
                option.cycle === subscription?.billingCycle &&
                option.plan === desiredPlan &&
                option.currency === (subscription.nextBillingCurrency ?? domain.NextBillingCurrency.USD)
        );

        if (pricingOption) {
            const subscriptionPrice = pricingOption.ranges.find(
                (range) => range.minOrganisations <= qty && range.maxOrganisations > qty
            );

            if (subscriptionPrice) {
                return {
                    price: subscriptionPrice.pricePerOrganisation,
                    currency: pricingOption.currency,
                };
            }
        }
    }

    return null;
};

const GetSubscriptionOptionsAfterUpgrade = (
    subscription: domain.Subscription | null,
    pricingOptions: PriceOption[]
) => {
    if (!subscription) {
        return null;
    }

    const qty = subscription.quantity + 1;
    const priceOption = GetPriceOptionForSubscription(subscription, pricingOptions, qty);

    if (priceOption) {
        return { qty, price: priceOption.price, currency: priceOption.currency };
    }

    return null;
};

const AvailableForUpgrade = (subscription: domain.Subscription) => {
    return subscription.capacity === 0;
};

const availableForUpgrade = (subscription: selectors.types.ExpandedSubscription) => {
    if (subscription.isFrozenForUpgrade && subscription.capacity === 0) {
        return false;
    }

    return subscription.capacity > 0 || AvailableBillingSystems.includes(subscription.billingSystem);
};

const bem = bemFactory.block('company-select-subscription-popup');
const qa = bemFactory.qa('company-select-subscription-popup');

interface SelectSubscriptionPopupProps {
    className?: string;
}

const SelectSubscriptionPopup: FC<SelectSubscriptionPopupProps> = (props) => {
    const { className } = props;

    const dispatch = useDispatch();
    const pricingOptions = useSelector(getPricingOptions);
    const account = useSelector(selectors.profile.getProfileAccount);

    const organisation = useCompanyBasedOnUrl();
    const applicableSubscriptions = useSelector(getApplicableSubscriptions).filter(availableForUpgrade);

    const hasApplicableSubscriptions = useMemo(
        () => applicableSubscriptions.length > 0,
        [applicableSubscriptions.length]
    );

    const [option, setOption] = useState<SelectSubscriptionOption>(
        hasApplicableSubscriptions
            ? SelectSubscriptionOption.ExistingSubscription
            : SelectSubscriptionOption.NewSubscription
    );
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string | null>(null);
    const [confirmWarningUpgradeSubscription, setConfirmWarningUpgradeSubscription] = useState<boolean>(false);

    const isPartnerAccount = useMemo(() => account.accountType === backend.AccountType.Partner, [account]);

    const selectedSubscription = useMemo(
        () => applicableSubscriptions.find((subscription) => subscription.id === selectedSubscriptionId) || null,
        [applicableSubscriptions, selectedSubscriptionId]
    );

    const needsToSubscriptionUpgrade = useMemo(
        () => !!selectedSubscription && AvailableForUpgrade(selectedSubscription),
        [selectedSubscription]
    );

    const showUpgradeWarningMessage = useMemo(
        () => needsToSubscriptionUpgrade && isPartnerAccount,
        [isPartnerAccount, needsToSubscriptionUpgrade]
    );

    const disabledButtonSelect = useMemo(() => {
        switch (option) {
            case SelectSubscriptionOption.NewSubscription:
            case SelectSubscriptionOption.Trial:
            case SelectSubscriptionOption.ExistingPlan:
                return false;

            case SelectSubscriptionOption.ExistingSubscription:
                return showUpgradeWarningMessage ? !confirmWarningUpgradeSubscription : !selectedSubscription;

                break;

            default:
                throw errorHelpers.assertNever(option);
        }
    }, [confirmWarningUpgradeSubscription, option, selectedSubscription, showUpgradeWarningMessage]);

    const subscriptionOptionsAfterUpgrade = useMemo(
        () => GetSubscriptionOptionsAfterUpgrade(selectedSubscription, pricingOptions),
        [pricingOptions, selectedSubscription]
    );

    const onSelectSubscription = () => {
        const upgradeSubscriptionData = needsToSubscriptionUpgrade
            ? {
                  subscriptionId: selectedSubscription!.id,
                  currency: selectedSubscription!.nextBillingCurrency,
                  plan: selectedSubscription!.plan,
                  billingCycle: selectedSubscription!.billingCycle,
                  qty: subscriptionOptionsAfterUpgrade!.qty,
                  organisationIds: [organisation?.id ?? ''],
              }
            : null;

        dispatch(
            commitSubscriptionData({
                option,
                subscriptionId: selectedSubscription?.id,
                billingSystem: selectedSubscription?.billingSystem,
                upgradeSubscriptionData: upgradeSubscriptionData,
                isPartnerAccount: account.isPartnerAccount,
            })
        );
    };

    const onChangeSubscription = (subscriptionId: string) => {
        setSelectedSubscriptionId(subscriptionId);
        setOption(SelectSubscriptionOption.ExistingSubscription);
    };

    if (!organisation) {
        return null;
    }

    let trialOptionText;

    if (isPartnerAccount) {
        trialOptionText = (
            <FormattedMessage
                id={`${i18nPrefix}.newTrialOptionForPartner`}
                defaultMessage='Create new Organisation as trial and add to subscription later'
            />
        );
    } else {
        trialOptionText = (
            <FormattedMessage
                id={`${i18nPrefix}.newTrialOptionForEndUser`}
                defaultMessage='Start with three days free of charge to set up a new Organisation, and add it to a subscription later'
            />
        );
    }

    return (
        <Popup.EmptyContent className={bem.add(className)()} qa={qa()}>
            <SubscriptionsHeader
                header={<FormattedMessage id={`${i18nPrefix}.header`} defaultMessage='Select Subscription' />}
                onNext={onSelectSubscription}
                isDisabledButton={disabledButtonSelect}
            />

            <ScrollableArea className={bem('body-wrp')}>
                <div className={bem('body')}>
                    {hasApplicableSubscriptions ? (
                        <>
                            <div className={bem('subscriptions-title')}>
                                <FormattedMessage
                                    id={`${i18nPrefix}.subscriptionsTitle`}
                                    defaultMessage='Please select which subscription this organisation should be added to. Some subscriptions might be out of the allowed quantity. In this case you’ll need to upgrade the subscription first.'
                                />
                            </div>

                            <div className={bem('subscriptions-area')} data-qa={qa('subscriptions-area')}>
                                <RadioGroupEditor value={selectedSubscriptionId} onChange={onChangeSubscription}>
                                    {applicableSubscriptions.map((subscription) => (
                                        <div key={subscription.id} className={bem('radio-group-element')}>
                                            <RadioGroupEditor.Item id={subscription.id}>
                                                <FormattedMessage
                                                    id={`${i18nPrefix}.subscriptionName`}
                                                    defaultMessage='{name} | {freeSlots} more organisations can be added {upgradeText}'
                                                    values={{
                                                        name: selectors.subscription.getSubscriptionDisplayName(
                                                            subscription,
                                                            `AMS-${subscription.systemId}`
                                                        ),
                                                        freeSlots: `${subscription.capacity}`,
                                                        upgradeText: AvailableForUpgrade(subscription) ? (
                                                            <i>Can be upgraded</i>
                                                        ) : (
                                                            ''
                                                        ),
                                                    }}
                                                />
                                            </RadioGroupEditor.Item>

                                            {showUpgradeWarningMessage &&
                                                subscription.id === selectedSubscriptionId && (
                                                    <div className={bem('upgrade-panel')}>
                                                        <div>
                                                            <FormattedMessage
                                                                id={`${i18nPrefix}.upgradePanelMessage`}
                                                                defaultMessage='This will upgrade your subscription to {qtyOfSlotsAfterUpgrade} organisations.{br}Your monthly fee will be adjusted accordingly in the next billing cycle. You won’t need to go through checkout or do a pro-rated upgrade.'
                                                                values={{
                                                                    qtyOfSlotsAfterUpgrade: (
                                                                        <b>{subscriptionOptionsAfterUpgrade?.qty}</b>
                                                                    ),
                                                                    br: <br />,
                                                                }}
                                                            />
                                                        </div>

                                                        <div className={bem('upgrade-panel-message-amount')}>
                                                            <FormattedMessage
                                                                id={`${i18nPrefix}.upgradePanelMessageAmount`}
                                                                defaultMessage='Your newly Monthly billing amount will be {amountStr} (before taxes).'
                                                                values={{
                                                                    amountStr: (
                                                                        <b>
                                                                            {`${subscriptionOptionsAfterUpgrade?.price} ${subscriptionOptionsAfterUpgrade?.currency}`}
                                                                        </b>
                                                                    ),
                                                                }}
                                                            />
                                                        </div>

                                                        <div>
                                                            <CheckboxEditor
                                                                onChange={setConfirmWarningUpgradeSubscription}
                                                                value={confirmWarningUpgradeSubscription}
                                                            >
                                                                <FormattedMessage
                                                                    id={`${i18nPrefix}.confirmText`}
                                                                    defaultMessage='I understand that the checkout will be skipped'
                                                                />
                                                            </CheckboxEditor>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                                </RadioGroupEditor>
                            </div>
                        </>
                    ) : (
                        <NoSubscription />
                    )}

                    <OtherSubscriptionOptions
                        showTrialOption
                        trialText={trialOptionText}
                        showTitle={hasApplicableSubscriptions}
                        option={option}
                        onChangeSelect={() => {
                            setSelectedSubscriptionId(null);
                        }}
                        setOption={setOption}
                        showNewOrganisationOption
                    />
                </div>
            </ScrollableArea>
        </Popup.EmptyContent>
    );
};

export default memo(SelectSubscriptionPopup);
