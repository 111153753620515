/**
 * Creates and returns a new function that maps the passed comparator onto the specified attribute of an object.
 * Look at the example for details.
 * @example
 * var referenceComparator = core.utils.helpers.comparatorFor(core.utils.comparators.stringComparator2Asc, 'text');
 * var a = { id: 2, text: '1' };
 * var b = { id: 1, text: '2' };
 * // returns -1
 * var result = referenceComparator(a, b);
 * @param {Function} comparatorFn Wrapped comparator function. 1 or 2 arguments.
 * @param {String} propertyName of the object to which the function is mapped.
 * @return {Function} Result function.
 */
export const comparatorFor = <T>(comparatorFn: any, propertyName: keyof T) => {
    if (comparatorFn.length === 1) {
        return (a: T) => comparatorFn(a[propertyName]);
    } else if (comparatorFn.length === 2) {
        return (a: T, b: T) => comparatorFn(a[propertyName], b[propertyName]);
    } else {
        throw new Error('Invalid arguments count in comparator function.');
    }
};
