import { User } from './types';

export const widgetCode = window.ApprovalMax.widgetCodeApp;

export const readyFunction = (user: User) => () => {
    setTimeout(() => {
        window.$zoho.salesiq.floatbutton.coin.hidetooltip();
    }, 15000);

    if ('visitor' in window.$zoho.salesiq) {
        window.$zoho.salesiq.visitor.email(user.email);
        window.$zoho.salesiq.visitor.name(user.name);
    }
};
