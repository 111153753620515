import { font, oPopupPadding, Popup, TextButton, TextEditor } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled(Popup.DefaultContent)`
    width: 520px;
`;

export const Content = styled.div`
    ${oPopupPadding()}
    display: flex;
    flex-flow: column;
    gap: 20px;
`;

export const SubTitle = styled.div`
    ${font(16, '#000', 'semibold')}
`;

export const EmailRow = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const Email = styled.div`
    ${font(16, '#000', 'bold')}
    max-width: 100%;
    margin-right: 5px;
`;

export const Description = styled.div`
    ${font(13, '#000', 'standard')}
`;

export const CodeFieldRow = styled.div`
    display: flex;
    align-items: center;
`;

export const CodeFieldEditor = styled(TextEditor)`
    flex: 1;
`;

export const ButtonResend = styled(TextButton)`
    margin-left: 10px;
`;
