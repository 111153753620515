import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import { ui } from 'modules/common';
import styled from 'styled-components';

export const Mention = styled.span<StyledMods<{ isOffboardedUser: boolean; userHasAccess: boolean }>>`
    max-width: 100%;
    overflow: hidden;
    border-radius: 5px;
    background: #ffffff;
    color: #767575;
    font-size: 10px;
    font-weight: 600;
    padding: 2px 4px;
    cursor: pointer;
    border: 1px solid #cacccd;
    white-space: nowrap;
    text-overflow: ellipsis;

    ${mods('userHasAccess', true)`
            background: #77aa84;
            color: #fff;
            border-color: transparent;
        `}

    ${mods('isOffboardedUser', true)`
            background: #384650;
            color: #a4a0a0;
            border-color: transparent;
        `}
`;

export const StyledUserHover = styled(ui.UserHover)`
    display: inline;
`;
