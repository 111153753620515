import { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data';

import { companiesApiPaths } from '../paths';
import {
    UseGetAllParticipantWorkflowVersionsRequestParams,
    UseGetAllParticipantWorkflowVersionsResponse,
} from './useGetAllParticipantWorkflowVersions.types';

export const useGetAllParticipantWorkflowVersions = (
    params: UseGetAllParticipantWorkflowVersionsRequestParams,
    queryOptions?: QueryOptions<UseGetAllParticipantWorkflowVersionsResponse>
) => {
    return useGetRequestLegacy<UseGetAllParticipantWorkflowVersionsResponse>(
        companiesApiPaths.getAllParticipantWorkflowVersions,
        params,
        { queryOptions }
    );
};
