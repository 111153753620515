export const integrationsApiPaths = {
    getNetSuiteApiFilter: 'integration/getNetSuiteApiFilter',
    updateNetSuiteFilter: 'integration/updateNetSuiteFilter',
    getNetSuiteCacheStatus: 'integration/getNetSuiteCacheStatus',
    refreshNetSuiteCache: 'integration/refreshNetSuiteCache',
    getContactCisSettings: 'integration/getContactCisSettings',
    getCustomerContacts: 'integration/getCustomerContacts',
    checkDearConnection: 'integration/checkDearConnection',
    checkNetSuiteConnection: 'integration/checkNetSuiteConnection',
    getNetSuiteExchangeRates: 'integration/getNetSuiteExchangeRates',
    getNetSuiteTaxCodes: 'integration/getNetSuiteTaxCodes',
    getNetSuiteAccounts: 'integration/netsuite/dictionaries/accounts',
    getNetSuiteAmortizationSchedules: 'integration/getNetSuiteAmortizationSchedules',
    getNetSuiteTerms: 'integration/netsuite/dictionaries/terms',
    getNetSuiteSubsidiary: 'integration/netsuite/dictionaries/subsidiaries',
    getNetSuiteCountries: 'integration/netsuite/dictionaries/countries',
    getNetSuiteVendorCategories: 'integration/netsuite/dictionaries/vendor-categories',
    createNetSuiteVendor: 'integration/netsuite/vendors',
    getXeroCounterparties: 'integration/companies/:companyId/xero/counterparties',
    getXeroCounterpartyPaymentDetailsForRequest:
        'integration/companies/:companyId/requests/:requestId/xero/counterparties/:counterpartyId/paymentDetails',
    xeroBankAccounts: 'integration/companies/:companyId/xero/bankAccounts',
    xeroCounterpartyPaymentDetails:
        'integration/companies/:companyId/xero/counterparties/:counterpartyId/paymentDetails',
    updateXeroCounterpartyPaymentDetails:
        'integration/companies/:companyId/xero/counterparties/:counterpartyId/paymentDetails/:paymentDetailsId',
    deleteXeroCounterpartyPaymentDetails:
        'integration/companies/:companyId/xero/counterparties/:counterpartyId/paymentDetails/:paymentDetailsId',
    getContacts: 'integration/getContacts',
};
