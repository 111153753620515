import { Box } from '@approvalmax/ui/src/components';
import { FC, useContext } from 'react';

import { TabsContext } from '../../Tabs.context';
import { Root } from './Content.styles';
import { ContentProps } from './Content.types';

/**
 * Contain a content of tabs
 */
const Content: FC<ContentProps> = (props) => {
    const { children, value = 0, ...restProps } = props;

    const { value: selectedValue, spacing, forceRender } = useContext(TabsContext);
    const active = value === selectedValue;

    if (!forceRender && !active) return null;

    return (
        <Root
            as={Box}
            spacing={spacing}
            role='tabpanel'
            aria-labelledby={String(value)}
            $active={active}
            {...restProps}
        >
            {children}
        </Root>
    );
};

export default Content;
