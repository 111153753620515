/**
 * Developer: Stepan Burguchev
 * Date: 4/23/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './textAreaEditor.scss';

import { hooks } from '@approvalmax/utils';
import React, {
    FocusEvent,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import bemFactory from 'react-bem-factory';
import { TextareaHeightChangeMeta } from 'react-textarea-autosize';

import { FieldContext } from '../../field/Field';
import { getDisabled } from '../../helpers';
import { BaseEditorProps } from '../EditorBase';
import { messages } from './TextAreaEditor.messages';
import { TextAreaStyled } from './TextAreaEditor.styles';

export const ALL_TEXT_AREA_THEMES = ['form', 'transparent'] as const;
export type TextAreaTheme = (typeof ALL_TEXT_AREA_THEMES)[number];

export const ALL_TEXT_AREA_SIZES = [
    'auto' /** see minHeight, maxHeight options */,
    'fixed' /** see height option */,
] as const;
export type TextAreaSize = (typeof ALL_TEXT_AREA_SIZES)[number];

interface Props extends BaseEditorProps<string> {
    placeholder?: string;
    maxLength?: number;
    size?: TextAreaSize;
    theme?: TextAreaTheme;
    className?: string;
    height?: number;
    minHeight?: number;
    maxHeight?: number;
    onCtrlEnter?: () => void;
    changeOnBlur?: boolean;
    onFocus?: (e?: FocusEvent<HTMLTextAreaElement>) => void;
    title?: string;
}

export interface TextAreaEditorElement {
    focus(): void;
    blur(): void;
}

const TextAreaEditor = forwardRef<TextAreaEditorElement, Props>((props, ref) => {
    const {
        className,
        theme = 'form',
        size = 'auto',
        invalid,
        qa,
        placeholder = messages.defaultPlaceholder,
        maxLength,
        height = 4,
        minHeight = 2,
        maxHeight = 20,
        changeOnBlur,
        focusOnMount,
        title,
    } = props;
    const bem = bemFactory.block('form-text-area-editor').themed(theme as any);

    const disabled = getDisabled(props.disabled, props.command);

    const [stateValue, setStateValue] = useState(props.value || '');
    const value = changeOnBlur ? stateValue : props.value || '';

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'Enter' && props.onCtrlEnter) {
            props.onCtrlEnter();
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (changeOnBlur) {
            setStateValue(e.target.value);
        } else {
            props.onChange(e.target.value);
        }
    };

    const onFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
        if (props.onFocus) {
            props.onFocus(e);
        }
    };

    const onBlur = () => {
        if (changeOnBlur) {
            props.onChange(stateValue);
        }

        if (props.onBlur) {
            props.onBlur();
        }
    };

    const { fieldId } = useContext(FieldContext);

    hooks.useFocusOnMount(focusOnMount, textAreaRef);

    // Update the stateValue if props.value changes
    useEffect(() => {
        if (changeOnBlur) {
            setStateValue(props.value);
        }
    }, [changeOnBlur, props.value]);

    useImperativeHandle(
        ref,
        () => ({
            focus() {
                textAreaRef.current!.focus();
            },
            blur() {
                textAreaRef.current!.blur();
            },
        }),
        [textAreaRef]
    );

    const textAreaProps = {
        className: bem.add(className)(null, {
            invalid,
        }),
        value,
        onKeyDown,
        onChange,
        onFocus,
        onBlur,
        disabled,
        placeholder,
        maxLength,
        title,
        rows: size === 'auto' ? minHeight : height,
    };

    const [currentHeight, setCurrentHeight] = useState(0);
    const [rowHeight, setRowHeight] = useState(20);

    const onHeightChange = useCallback(
        (height: number, meta: TextareaHeightChangeMeta) => {
            setCurrentHeight(height);

            if (meta.rowHeight !== rowHeight) {
                setRowHeight(meta.rowHeight);
            }
        },
        [rowHeight]
    );

    // Prevents the appearance of a scroll bar until the maximum height is reached
    const hideScroll = currentHeight < maxHeight * rowHeight;

    return (
        <TextAreaStyled
            onHeightChange={onHeightChange}
            $hideScroll={size === 'auto' ? hideScroll : false}
            minRows={size === 'auto' ? minHeight : height}
            maxRows={size === 'auto' ? maxHeight : height}
            id={fieldId}
            ref={textAreaRef}
            data-qa={qa}
            {...textAreaProps}
        />
    );
});

export default TextAreaEditor;
