import { stateTree } from 'modules/data';
import { ImmutableObject, merge } from 'modules/immutable';

import { Action, LOAD_PAGE_DATA } from '../actions';
import { PAGE_ID } from '../config';

export default function (
    state: ImmutableObject<stateTree.Navigation>,
    action: Action
): ImmutableObject<stateTree.Navigation> {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return merge(state, {
                activePageId: PAGE_ID,
                activeCompany: action.payload.companyId,
                innerActivePageId: action.payload.innerActivePageId,
            });

        default:
            return state;
    }
}
