import { PascalCaseToCamelCase } from '@approvalmax/types';
import { schema } from 'normalizr';

import * as backend from '../../backend';
import { User } from '../User';

export function mapUser(value: backend.UserAnswer | PascalCaseToCamelCase<backend.UserAnswer>): User {
    if ('userEmail' in value) {
        return {
            id: value.userEmail!,
            databaseId: value.userId,
            avatar: value.avatarHttps,
            firstName: value.firstName,
            lastName: value.lastName,
            userEmail: value.userEmail,
            ...(typeof value.isTFAEnabled === 'boolean' && { isTFAEnabled: value.isTFAEnabled }),
        };
    }

    return {
        id: value.UserEmail,
        databaseId: value.UserId,
        avatar: value.AvatarHttps,
        firstName: value.FirstName,
        lastName: value.LastName,
        userEmail: value.UserEmail,
        ...(typeof value.IsTFAEnabled === 'boolean' && { isTFAEnabled: value.IsTFAEnabled }),
    };
}

export default new schema.Entity(
    'users',
    {},
    {
        idAttribute: (value: backend.UserAnswer | PascalCaseToCamelCase<backend.UserAnswer>) =>
            'UserEmail' in value ? value.UserEmail : value.userEmail,
        processStrategy: mapUser,
    }
);
