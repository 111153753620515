import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionInstructionForRequester',
    {
        title: 'Instruction for requesters',
        subtitle: 'You can specify a message that will be shown to Requesters when they create a new {templateName}.',
        placeholder: 'Enter message text...',
        instruction: 'Leave the message blank if you don’t what to show Requesters an instruction.',
        defaultTemplateName: 'request',
    }
);
