import { Button, Popup } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { font } from 'modules/styles';
import React, { FC, memo, useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { amplitudeService } from 'services/amplitude';
import styled from 'styled-components';

import Answer from './components/Answer/Answer';

const i18nPrefix = 'company/containers/XeroDemoOrganisationPopup/components/ChoicePopup';
const messages = defineMessages({
    title: {
        id: `${i18nPrefix}.title`,
        defaultMessage: 'Prepopulating a Demo organisation with sample data',
    },
    description: {
        id: `${i18nPrefix}.description`,
        defaultMessage:
            'We noticed that you have connected to a demo company in Xero. Would you like to prepopulate the demo company with sample data?',
    },
    prepopulateData: {
        id: `${i18nPrefix}.prepopulateData`,
        defaultMessage: 'Yes, prepopulate with sample data',
    },
    notPrepopuluteData: {
        id: `${i18nPrefix}.notPrepopuluteData`,
        defaultMessage: 'No, I will set up everything myself',
    },
});

const StyledPopup = styled(Popup.EmptyContent)`
    width: 520px;
`;

const Root = styled.div`
    padding: 60px 100px;
`;

const Title = styled.div`
    margin-bottom: 10px;
    ${font(16, '#000', 'semibold')}
`;

const Description = styled.div`
    margin-bottom: 30px;
    ${font(12, '#5e5c5c')}
`;

const ProceedButton = styled(Button)`
    width: 100%;
    margin-top: 30px;
`;

interface ChoicePopupProps {
    onSubmit: (shouldPrepopulateData: boolean) => void;
}

const ChoicePopup: FC<ChoicePopupProps> = (props) => {
    const { onSubmit } = props;

    const [shouldPrepopulateData, setShouldPrepopulateData] = React.useState(true);

    const onProceed = useCallback(() => {
        amplitudeService.sendData('signup: decide on demo data', {
            decision: shouldPrepopulateData ? 'use demo data' : 'do not use demo data',
        });
        onSubmit(shouldPrepopulateData);
    }, [shouldPrepopulateData, onSubmit]);

    const onAnswerClick = useCallback((value: boolean) => {
        setShouldPrepopulateData(value);
    }, []);

    return (
        <StyledPopup>
            <Root>
                <Title>
                    <FormattedMessage
                        id={`${i18nPrefix}.title`}
                        defaultMessage='Prepopulating a Demo organisation with sample data'
                    />
                </Title>

                <Description>
                    <FormattedMessage
                        id={`${i18nPrefix}.description`}
                        defaultMessage='We noticed that you have connected to a demo company in Xero. Would you like to prepopulate the demo company with sample data?'
                    />
                </Description>

                <Answer
                    checked={shouldPrepopulateData}
                    text={intl.formatMessage(messages.prepopulateData)}
                    value={true}
                    onClick={onAnswerClick}
                />

                <Answer
                    checked={!shouldPrepopulateData}
                    text={intl.formatMessage(messages.notPrepopuluteData)}
                    value={false}
                    onClick={onAnswerClick}
                />

                <ProceedButton execute={onProceed}>Proceed</ProceedButton>
            </Root>
        </StyledPopup>
    );
};

export default memo(ChoicePopup);
