import moment, { Duration } from 'moment/moment';

/**
 * @function
 * @param {String|Object} a Argument A. An ISO8601 duration string ('P1Y2M3DT4H5M6S'),
 * a string separated by colons like '7.23:59:59.999'
 * or MomentJS object like <code>{ seconds: 2, minutes: 2, hours: 2, days: 2, weeks: 2, months: 2, years: 2 }</code>.
 * @param {String|Object} b Argument B. An ISO8601 duration string ('P1Y2M3DT4H5M6S'),
 * a string separated by colons like '7.23:59:59.999'
 * or MomentJS object like <code>{ seconds: 2, minutes: 2, hours: 2, days: 2, weeks: 2, months: 2, years: 2 }</code>.
 * @return {Number} Result as follows:
 * <ul>
 *     <li><code>-1</code> - if A &lt; B.</li>
 *     <li><code>0</code> - if A == B.</li>
 *     <li><code>1</code> - if A &gt; B.</li>
 * </ul>
 * */
export const durationComparator2Asc = (
    a: Duration | string | null | undefined,
    b: Duration | string | null | undefined
) => {
    if (a) {
        a = moment.duration(a);
    }

    if (b) {
        b = moment.duration(b);
    }

    if (a) {
        return b ? (a as any) - (b as any) : 1;
    }

    return b ? -1 : 0;
};
