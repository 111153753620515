import { WarningAmIcon } from '@approvalmax/ui';
import { Button, Flex, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './SoftEnforcementEnd.messages';
import { SoftEnforcementEndProps } from './SoftEnforcementEnd.types';

const qa = bemFactory.qa('pro-two-fa-setup-success-popup');

export const SoftEnforcementEnd = memo<SoftEnforcementEndProps>((props) => {
    const { onFinish } = props;

    return (
        <Flex inline direction='column' alignItems='center' spacing='24' data-qa={qa()}>
            <WarningAmIcon />

            <Text font='headline' fontSize='small' fontWeight='medium' textAlign='center'>
                {messages.title}
            </Text>

            <Text font='body' fontSize='medium' fontWeight='regular' textAlign='center'>
                {messages.description}
            </Text>

            <Button color='blue80' size='large' onClick={() => onFinish()}>
                {messages.button}
            </Button>
        </Flex>
    );
});

SoftEnforcementEnd.displayName = 'HardEnforcementEnd';
