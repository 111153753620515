import { Box, Flex, Spacing, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { InfoCardProps } from './InfoCard.types';

const InfoCard = memo<InfoCardProps>((props) => {
    const { title, beforeTitle, afterTitle, actions, color, children } = props;

    return (
        <Box spacing='16 24' bordered radius='small' color={color}>
            <Flex inline alignItems='center' justifyContent='space-between'>
                <Flex inline grow={1} spacing='8'>
                    {beforeTitle}

                    {typeof title === 'string' ? (
                        <Text font='headline' fontSize='xxsmall' fontWeight='medium'>
                            {title}
                        </Text>
                    ) : (
                        title
                    )}

                    {afterTitle}
                </Flex>

                {actions}
            </Flex>

            <Spacing height={16} />

            {children}
        </Box>
    );
});

export default InfoCard;
