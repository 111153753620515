import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data/api';

import { authApiPaths } from '../paths';
import { UseFido2CredentialLabelEditData } from './useFido2CredentialLabelEdit.types';

export const useFido2CredentialLabelEdit = (
    mutationOptions?: MutationOptions<any, RequestDataParams<UseFido2CredentialLabelEditData>>
) => {
    return useMutate<RequestDataParams<UseFido2CredentialLabelEditData>>(authApiPaths.fido2CredentialsLabel, {
        method: 'patch',
        apiVersion: 'v2',
        mutationOptions,
    });
};
