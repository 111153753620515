import { Popup, ScrollableArea } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { useUserProfile } from 'modules/utils';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

import CertificationSection from './components/CertificationSection/CertificationSection';
import NotificationSection from './components/NotificationSection/NotificationSection';
import ProfileInformationSection from './components/ProfileInformationSection/ProfileInformationSection';
import SecuritySection from './components/SecuritySection/SecuritySection';
import SetPasswordSection from './components/SetPasswordSection/SetPasswordSection';
import { ProfileContextProvider, useProfileContext } from './ProfileContext';

const i18nPrefix = 'profile/containers/ProfilePopup';
const messages = defineMessages({
    popupTitle: {
        id: `${i18nPrefix}.popupTitle`,
        defaultMessage: 'Edit profile',
    },
    saveButton: {
        id: `${i18nPrefix}.saveButton`,
        defaultMessage: 'Save',
    },
    saveButtonTitle: {
        id: `${i18nPrefix}.saveButtonTitle`,
        defaultMessage: 'Save profile',
    },
});

const DefaultContent = styled(Popup.DefaultContent)`
    width: 810px;
`;

const StyledScrollableArea = styled(ScrollableArea)`
    display: flex;
    flex-flow: column;
    overflow: hidden;
`;

const qa = bemFactory.qa('profile-popup');

const ProfilePopupBase: FC = memo(() => {
    const { saveAndSubmitProfile, setShowErrors, isLoading } = useProfileContext();

    const saveProfile = () => {
        setShowErrors(true);

        saveAndSubmitProfile();
    };

    return (
        <DefaultContent
            qa={qa()}
            title={intl.formatMessage(messages.popupTitle)}
            buttons={
                <Popup.DefaultContent.Button
                    execute={saveProfile}
                    disabled={isLoading}
                    title={intl.formatMessage(messages.saveButtonTitle)}
                >
                    {intl.formatMessage(messages.saveButton)}
                </Popup.DefaultContent.Button>
            }
        >
            <StyledScrollableArea>
                <ProfileInformationSection />

                <CertificationSection />

                <NotificationSection />

                <SecuritySection />

                <SetPasswordSection />
            </StyledScrollableArea>
        </DefaultContent>
    );
});

const ProfilePopup: FC = () => {
    const defaultProfile = useUserProfile();

    return (
        <ProfileContextProvider profile={defaultProfile}>
            <ProfilePopupBase />
        </ProfileContextProvider>
    );
};

export default memo(ProfilePopup);
