import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { mapData } from './useStartAddingTFAAlternativeEmail.map';
import {
    UseStartAddingTFAAlternativeEmailBackend,
    UseStartAddingTFAAlternativeEmailRequest,
    UseStartAddingTFAAlternativeEmailResponse,
} from './useStartAddingTFAAlternativeEmail.types';

export const useStartAddingTFAAlternativeEmail = () => {
    return useMutateLegacy<
        RequestDataParams<UseStartAddingTFAAlternativeEmailRequest>,
        UseStartAddingTFAAlternativeEmailBackend,
        UseStartAddingTFAAlternativeEmailResponse
    >(twoFaApiPaths.startAddingTFAAlternativeEmail, {
        mapData,
    });
};
