import uniqueId from 'lodash/uniqueId';

import * as constants from '../../../common/constants';
import { Template, TemplateStep, TemplateStepType } from '../Template';
import { messages } from './templateFactory.messages';

const { TEMPLATE_ID_PREFIX } = constants.commonConstants;

export function createStep(creatorUserId: string, stepName?: string): TemplateStep {
    return {
        id: uniqueId('step.'),
        name: stepName || messages.defaultStepName,
        participantMatrix: [{ lineId: creatorUserId, rules: [], isBackup: false }],
        editorMatrix: [],
        editingMatrix: [],
        defaultDuration: '',
        deadlineRule: null,
        generalFieldOrder: [],
        requiredFieldIds: [],
        readonlyFieldIds: [],
        editPermissionsRequiredFieldIds: [],
        type: TemplateStepType.DecisionStep,
        approvalCount: null,
    };
}

export function createStandaloneTemplate(companyId: string, authorUserId: string): Template {
    return {
        id: uniqueId(TEMPLATE_ID_PREFIX),
        companyId,
        templateName: messages.defaultWorkflowName,
        author: authorUserId,
        integrationCode: null,
        receiptBankExternalSubmitter: null,
        steps: [createStep(authorUserId)],
        externalSubmitter: null,
        emailExternalSubmitter: null,
        submitterMatrix: [
            {
                lineId: authorUserId,
                rules: [],
            },
        ],
        submitterRuleOrders: [],
        requiredFieldIds: [],
        modifiedDate: null,
        enabled: false,
        hasOutdatedRequests: false,
        autoApprovalRules: [],
        isNew: true,
        reviewStep: {
            readonlyFieldIds: [],
            requiredFieldIds: [],
            reviewers: [],
            deadlineRule: null,
        },
    };
}
