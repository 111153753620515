import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseGetRequestVersionRequestParams, UseGetRequestVersionResponse } from './useGetRequestVersion.types';

export const useGetRequestVersion = (
    pathParams: UseGetRequestVersionRequestParams,
    queryOptions?: QueryOptions<UseGetRequestVersionResponse>
) => {
    return useGetRequest<UseGetRequestVersionResponse>(requestsApiPaths.requestVersion, {
        pathParams,
        queryOptions,
        apiVersion: 'v2',
    });
};
