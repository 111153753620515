import { messages } from './SubscribeAfterTrialButton.messages';
import { SubscribeAfterTrialButtonType } from './SubscribeAfterTrialButton.types';

export const getButtonSize = (type: SubscribeAfterTrialButtonType) => {
    if (type === SubscribeAfterTrialButtonType.Approve || type === SubscribeAfterTrialButtonType.Reject) {
        return 'medium';
    }

    return 'small';
};

export const getButtonColor = (type: SubscribeAfterTrialButtonType) => {
    if (type === SubscribeAfterTrialButtonType.Reject) {
        return 'midnight40';
    }

    return 'mint80';
};

export const getOrigin = (type: SubscribeAfterTrialButtonType) => {
    switch (type) {
        case SubscribeAfterTrialButtonType.NewRequest:
            return 'new_request';
        case SubscribeAfterTrialButtonType.AddUser:
            return 'add_users';
        case SubscribeAfterTrialButtonType.WorkflowActivation:
            return 'wf_activation';
        case SubscribeAfterTrialButtonType.WorkflowStandalone:
            return 'sa_wf_creation';
        case SubscribeAfterTrialButtonType.Approve:
        case SubscribeAfterTrialButtonType.Reject:
            return 'approval_decision';
        default:
            return '';
    }
};

export const getText = (type: SubscribeAfterTrialButtonType) => {
    switch (type) {
        case SubscribeAfterTrialButtonType.NewRequest:
            return messages.newRequest;
        case SubscribeAfterTrialButtonType.AddUser:
            return messages.addUser;
        case SubscribeAfterTrialButtonType.WorkflowActivation:
            return messages.workflowActivation;
        case SubscribeAfterTrialButtonType.WorkflowStandalone:
            return messages.workflowStandalone;
        case SubscribeAfterTrialButtonType.Approve:
            return messages.Approve;
        case SubscribeAfterTrialButtonType.Reject:
            return messages.Reject;
        default:
            return '';
    }
};

export const getDescription = (type: SubscribeAfterTrialButtonType) => {
    switch (type) {
        case SubscribeAfterTrialButtonType.NewRequest:
            return messages.newRequestDescription;
        case SubscribeAfterTrialButtonType.AddUser:
            return messages.addUserDescription;
        case SubscribeAfterTrialButtonType.WorkflowActivation:
            return messages.workflowActivationDescription;
        case SubscribeAfterTrialButtonType.WorkflowStandalone:
            return messages.workflowStandaloneDescription;
        case SubscribeAfterTrialButtonType.Approve:
        case SubscribeAfterTrialButtonType.Reject:
            return messages.approvalDecisionDescription;
        default:
            return '';
    }
};
