import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.StartOverPopup.RequestList', {
    friendlyNameColumnText: 'Name',
    dateColumnText: 'Date',
    paymentDateColumnText: 'Payment date',
    requesterColumnText: 'Requester',
    workflowStepColumnText: 'Workflow step',
    amountColumnText: 'Amount',
    statusColumnText: 'Status',
    requestAmountEmptyValue: '0 {currency}',
    receivedFromReceiptBankOrigin: 'Received from Dext Prepare',
    pulledFromOrigin: 'Pulled from {integrationName}',
    onApproval: 'On approval',
    rejected: 'Rejected',
    approved: 'Approved',
    cancelled: 'Cancelled',
    onReview: 'On review',
    onHold: 'On hold',
    reviewStepText: 'Review step',
});
