import { AmCircleLogo } from '@approvalmax/ui/src/logos';
import styled from 'styled-components';

export const Root = styled.div`
    position: relative;
`;

export const AppLogo = styled(AmCircleLogo)`
    position: absolute;
    left: 8px;
    bottom: 16px;
`;
