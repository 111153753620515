import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('firstStart.CreateOrganisationPopup.ConnectToOrganisationButton', {
    connectToOrganisation: 'Connect your {systemName} organisation',
    connectToOrgHeader: 'Perfect for a quick start on live {systemName} data.',
    connectToOrgDescribe1: "ApprovalMax won't change any data in {systemName} until you start approving the documents.",
    connectToOrgDescribe2: 'Your data is safe with us.',
    tooltip1:
        'The ApprovalMax connection itself doesn’t affect any data in your accounting system. When you activate a workflow and approve or reject documents, ApprovalMax will update the status in your accounting system.',
    tooltip2:
        'Protecting financial information is our highest priority. ApprovalMax is ISO 27001 and GDPR-compliant and undergoes regular security checks. We’re trusted by more than 11,000 clients, including KPMG, Mazars, PWC, Deloitte. More information on security is available in our <trustCenterLink>Trust Center</trustCenterLink>.',
});
