import { useEffect, useState } from 'react';
import { usePrevious, useUpdateEffect } from 'react-use';

import { UserCardProps } from './UserCard.types';

export const useOpen = (props: Pick<UserCardProps, 'open' | 'onOpen'>) => {
    const { open: controlledOpen, onOpen } = props;

    const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
    const preUncontrolledOpen = usePrevious(uncontrolledOpen);

    useEffect(() => {
        if (controlledOpen !== undefined) {
            setUncontrolledOpen(controlledOpen);
        }
    }, [controlledOpen]);

    useUpdateEffect(() => {
        if (preUncontrolledOpen !== uncontrolledOpen) {
            onOpen?.(uncontrolledOpen);
        }
    }, [onOpen, uncontrolledOpen]);

    return { isOpen: uncontrolledOpen, setOpen: setUncontrolledOpen };
};
