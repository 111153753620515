import {
    Action,
    APPLY_REPORT_FILTERS,
    CHANGE_REPORT_SORTING,
    DELETE_REPORT,
    DISCARD_CHANGES,
    RENAME_ACTIVE_REPORT,
    REORDER_REPORT_COLUMNS,
    SAVE_REPORT_RESPONSE,
    SHOW_DASHBOARD,
    SHOW_NEW_REPORT_CARD,
    SHOW_REPORT_CARD,
} from '../../actions';

export type ModifiedType = boolean;

export default function activeTemplateReducer(state: ModifiedType = false, action: Action): ModifiedType {
    switch (action.type) {
        case SHOW_DASHBOARD:
        case SHOW_REPORT_CARD:
        case SHOW_NEW_REPORT_CARD:
        case DELETE_REPORT:
        case DISCARD_CHANGES:
        case SAVE_REPORT_RESPONSE:
            return false;

        case RENAME_ACTIVE_REPORT:
        case REORDER_REPORT_COLUMNS:
        case CHANGE_REPORT_SORTING:
        case APPLY_REPORT_FILTERS:
            return true;

        default:
            return state;
    }
}
