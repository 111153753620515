import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('requestForm.selectors.qbooks.validationSelectors', {
    mandatoryFieldsErrorText: 'Please fill in the mandatory fields',
    poNegativeAmountErrorText: 'The total amount of the Purchase Order cannot be negative.',
    salesInvoiceNegativeAmountErrorText: 'The total amount of the Sales Invoice cannot be negative.',
    billNegativeAmountErrorText: 'The total amount of the Bill cannot be negative.',
    expenseNegativeAmountErrorText: 'The total amount of the Expense cannot be negative',
    poExceededAmountErrorText: 'The total amount of the Purchase Order cannot exceed 99,999,999,999.',
    salesInvoiceExceededAmountErrorText: 'The total amount of the Sales Invoice cannot exceed 99,999,999,999.',
    billExceededAmountErrorText: 'The total amount of the Bill cannot exceed 99,999,999,999.',
    expenseExceededAmountErrorText: 'The total amount of the Expense cannot exceed 99,999,999,999.',
    exceededAmountInCurrencyErrorText: 'The total amount in {currency} cannot exceed 99,999,999,999.',
    customerNotSpecifiedErrorText: 'You must select a Customer for each split line which is billable.',
    billableNotSpecifiedErrorText: 'The tax cannot be set without the "Billable" status.',
    emailToVendorLengthExceeded:
        'Please note that a maximum of {charactersNumber} characters can be used for all To email addresses in the Email to the Vendor form. Exclude excessive characters to fit into the QuickBooks Online limit.',
    emailToVendorCcLengthExceeded:
        'Please note that a maximum of {charactersNumber} characters can be used for cc email addresses in the Email to the Vendor form. Exclude excessive characters to fit into the QuickBooks Online limit.',
    emailToVendorToEmpty: 'Please specify Email to address',
    companyNameLengthErrorText: 'The company name cannot exceed 100 characters.',
    displayNameLengthErrorText: 'The display name cannot exceed 500 characters.',
    titleLengthErrorText: 'The title cannot exceed 16 characters.',
    firstNameLengthErrorText: 'The first name cannot exceed 100 characters.',
    middleNameLengthErrorText: 'The middle name cannot exceed 100 characters.',
    lastNameLengthErrorText: 'The last name cannot exceed 100 characters.',
    suffixLengthErrorText: 'The suffix cannot exceed 16 characters.',
    emailAddressLengthErrorText: 'The email cannot exceed 100 characters.',
    websiteLengthErrorText: 'The website name cannot exceed 78 characters.',
    phoneLengthErrorText: 'Phone cannot exceed 30 characters.',
    mobileLengthErrorText: 'Mobile cannot exceed 30 characters.',
    faxLengthErrorText: 'Fax cannot exceed 30 characters.',
    addressStreetLengthErrorText: 'Address cannot exceed 255 characters.',
    cityLengthErrorText: 'City/Town cannot exceed 100 characters.',
    regionLengthErrorText: 'Country/State/Province cannot exceed 100 characters.',
    postalCodeLengthErrorText: 'Postcode  cannot exceed 30 characters.',
    countryLengthErrorText: 'Country cannot exceed 100 characters.',
    accountNumberLengthErrorText: 'Account no. cannot exceed 100 characters.',
    unbillibleAccount: `
        For billable expenses, you must use an Account that's one of the following account types: expenses,
        other expenses, other current liabilities, other current assets, other assets, fixed assets, or COGS.`,
    expenseBillableAccount: `
        For billable expenses, you must use an account of one of the following account types: expense, other expense,
        other current liabilities, other current assets, other assets, fixed assets, or COGS.`,
    displayNameNotSpecifiedErrorText: "Display name can't be empty.",
    invalidDisplayNameErrorText: 'Vendor name cannot contain the following symbols: ":", "&lt;", "&gt;", "&amp;#"',
    noEmojiInContactName: 'Emojis are not allowed in the name',
    wrongEmailErrorText: 'Incorrect email format.',
    wrongWebsiteErrorText: 'Please check URL format.',
    wrongPhoneErrorText: 'Incorrect phone format.',
    isRequiredField: '{field} is required',
    bankAccountName: 'Bank account name',
    bankBranchIdentifier: 'BSB',
    bsbIncorrectFormat: 'Incorrect BSB number format. It should look like xxx-xxx, where x is a digit.',
    bankAccountNumber: 'Bank account number',
    statement: 'Statement text',
});
