import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { paymentServicesApiPaths } from '../paths';
import type {
    UseXeroBatchPaymentBillPathParams,
    UseXeroBatchPaymentBillResponse,
} from './useXeroBatchPaymentBill.types';

export const useXeroBatchPaymentBill = (
    pathParams: UseXeroBatchPaymentBillPathParams,
    queryOptions?: QueryOptions<UseXeroBatchPaymentBillResponse>
) =>
    useGetRequest<UseXeroBatchPaymentBillResponse>(paymentServicesApiPaths.xeroBatchPaymentBill, {
        pathParams,
        queryOptions,
        apiVersion: 'v2',
    });
