import { stateTree } from 'modules/data';
import { ImmutableObject, mergeDeep } from 'modules/immutable';

import { Action, LOAD_PAGE_DATA } from '../actions';

export default function (
    state: ImmutableObject<stateTree.Entities>,
    action: Action
): ImmutableObject<stateTree.Entities> {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return mergeDeep(state, { requests: { [action.payload.request.id]: action.payload.request } });

        default:
            return state;
    }
}
