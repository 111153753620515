import { Button, font, oFormInput, oPopupPadding, Popup, uTransparentInput } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled(Popup.DefaultContent)`
    width: 520px;
    user-select: unset;
    cursor: unset;
`;

export const Content = styled.div`
    ${oPopupPadding()};
    display: flex;
    flex-flow: column;
    overflow-y: auto;
`;

export const Description = styled.div`
    ${font(13, '#000', 'standard')};
`;

export const QrCode = styled.div`
    margin-top: 5px;
    text-align: center;
`;

export const QrCodeImage = styled.img`
    width: 212px;
    height: 212px;
    margin: 15px;
`;

export const CodeSetup = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
`;

export const CodeSetupInput = styled.input`
    ${oFormInput};
    ${uTransparentInput};
    ${font(16, '#000', 'standard')};
    align-self: stretch;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
`;

export const ButtonWrap = styled.div`
    margin-top: 30px;
`;

export const ButtonNext = styled(Button)`
    width: 100%;
`;
