import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { NetSuiteCacheManagementPanel, xero } from 'modules/integration';
import { useSelector } from 'modules/react-redux';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useCacheManagement = () => {
    const { companyId = '' } = useParams<{ companyId?: string }>();

    const company = useSelector((state) => selectors.company.findCompanyById(state, companyId));

    const integrationType = company?.integration?.integrationType;

    const isXeroIntegration = integrationType === domain.IntegrationType.Xero;

    const isNetSuiteIntegration =
        integrationType === domain.IntegrationType.NetSuite &&
        company?.betaFeatures.includes(domain.CompanyBetaFeature.NetSuiteRefDataCache);

    const isCompanyEditable = company?.isReadonly === false;

    const isAvailable = companyId && isCompanyEditable && (isXeroIntegration || isNetSuiteIntegration);

    const integrationContent = useMemo(() => {
        if (companyId && isXeroIntegration) {
            return <xero.CacheManagementPanel companyId={companyId} />;
        }

        if (companyId && isNetSuiteIntegration) {
            return <NetSuiteCacheManagementPanel companyId={companyId} />;
        }

        return null;
    }, [companyId, isNetSuiteIntegration, isXeroIntegration]);

    return {
        isAvailable,
        integrationContent,
    };
};
