import Cookies from 'js-cookie';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

const SESSION_NOTIFICATION_PREFIX = 'SESSION_NOTIFICATION_HIDDEN';
const SESSION_NOTIFICATION_OPTIONS = {
    domain: window.location.hostname,
    path: '/',
};

export const useSessionNotification = (notificationId: string, hoursHideLimit: number = 72) => {
    const cookieId = `${SESSION_NOTIFICATION_PREFIX}.${notificationId}`;

    const [isHidden, setIsHidden] = useState<boolean>(true);

    const handleHide = useCallback(() => {
        Cookies.set(cookieId, moment().toString(), SESSION_NOTIFICATION_OPTIONS);
        setIsHidden(true);
    }, [cookieId]);

    const handleShow = useCallback(() => {
        Cookies.remove(cookieId, SESSION_NOTIFICATION_OPTIONS);
        setIsHidden(false);
    }, [cookieId]);

    useEffect(() => {
        const dateWhenHidden = Cookies.get(cookieId);

        if (dateWhenHidden && moment().diff(moment(dateWhenHidden), 'hours') < hoursHideLimit) {
            setIsHidden(true);
        } else {
            handleShow();
        }
    }, [cookieId, hoursHideLimit, handleShow]);

    return {
        isHidden,
        hide: handleHide,
        show: handleShow,
    };
};

export const clearSessionNotifications = () => {
    const cookiesKeys = Object.keys(Cookies.get());

    cookiesKeys.map((item) => {
        if (item.startsWith(SESSION_NOTIFICATION_PREFIX)) {
            Cookies.remove(item, SESSION_NOTIFICATION_OPTIONS);
        }
    });
};
