import { DateTimeIsoString, DeepNullablePartial, Guid } from '@approvalmax/types';
import { backend, domain } from 'modules/data';
import { AmaxPayOpenBankingBankAccount, PaymentDetails, Permission } from 'shared/data';

export enum MatchingAmountType {
    Gross = 'Gross',
    Net = 'Net',
}

export enum DiscountType {
    Amount = 'Amount',
    Rate = 'Rate',
    PreRate = 'PreRate',
    PreAmount = 'PreAmount',
}

/**
 * @deprecated
 *
 * @see data/queries/useGetMatchingSettings
 */
export interface AccountingCompaniesBillMatchingSettings {
    addMatchedPOsRequestersAsApproversForBill: domain.AddMatchedPOsRequestersAsApproversForBillStatuses;
    manualMatchingEnabled: boolean;
    autoMatchingEnabled: boolean;
    notMatchedBillsApprovalAllowed: CompanyMatchingSettingsBillApprovalPolicy;
    notMatchedBillsApprovalThreshold: number;
    overbudgetMatchBillsApprovalAllowed: CompanyMatchingSettingsBillApprovalPolicy;
    overbudgetMatchBillsApprovalThreshold: number;
    overbudgetMatchBillsApprovalPercentageThreshold: number;
    retrospectivePOMatchingAllowed: boolean;
    amountType: MatchingAmountType;
    insufficientBudgetMatchBillsApprovalAllowed: ApprovalAllowanceType;
    insufficientBudgetMatchBillsApprovalThreshold: number;
    insufficientBudgetMatchBillsApprovalPercentageThreshold: number;
}

/**
 * @deprecated
 *
 * @see data/queries/useGetMatchingSettings
 */
export interface AccountingCompaniesPurchaseOrderMatchingSettings {
    autoMarkingPurchaseOrderAsBilledEnabled: boolean;
    autoUnmarkingPurchaseOrderAsBilledEnabled: boolean;
}

export interface AccountingCompaniesXeroPurchaseOrderSettings {
    IsGrnEnabled: boolean;
}

export interface AccountingCompaniesQboPurchaseOrderSettings {
    IsGrnEnabled: boolean;
}

export interface AccountingCompaniesQboPriceCheckerSettings {
    IsEnabledForBills: boolean;
    IsEnabledForExpenses: boolean;
    IsEnabledForPurchaseOrders: boolean;
}

export interface AccountingDocumentsLineAmountType extends ArdalisSmartEnumSmartEnum {}

export interface AccountingTemplatesLockDatePolicySettings {
    lockDatePolicy: AccountingTemplatesLockDatePolicyType;
}

export enum AccountingTemplatesLockDatePolicyType {
    LockApproval = 'LockApproval',
    ApproveWithNextDay = 'ApproveWithNextDay',
}

export interface AccountsAccountAnswer {
    AccountId: Guid;
    OwnerUserProfileId: Guid;
    AccountType: AccountType;
}

export enum AccountsPlanEdition {
    Standard = 'Standard',
    NotForProfit = 'NotForProfit',
}

export interface AccountsSubscriptionAnswer {
    Id: Guid;
    SystemId: number;
    AccountOwner: UserAnswer;
    Plan: string;
    DueDate?: DateTimeIsoString | undefined;
    NextBillingDate?: DateTimeIsoString | undefined;
    NextBillingCurrency?: AccountsSubscriptionNextBillingCurrency | undefined;
    Edition: AccountsPlanEdition;
    Status: AccountsSubscriptionStatus;
    BillingCycle: AccountsSubscriptionBillingCycle;
    BillingSystem: AccountsSubscriptionBillingSystem;
    Quantity: number;
    Capacity: number;
    IsFrozenForUpgrade: boolean;
    AddonStatistics: [
        {
            SubscriptionId: string;
            SubscriptionAddonId: string;
            Status: domain.AddOnStatuses;
            Feature: domain.AddonTypes;
            UsageThreshold: domain.AddonUsageThresholds;
        },
    ];
}

export enum AccountsSubscriptionBillingCycle {
    Monthly = 'Monthly',
    Annually = 'Annually',
    OneTime = 'OneTime',
    BiAnnually = 'BiAnnually',
    Quarterly = 'Quarterly',
}

export enum AccountsSubscriptionBillingSystem {
    None = 'None',
    Direct = 'Direct',
    Avangate = 'Avangate',
    Stripe = 'Stripe',
    XeroAppStore = 'XeroAppStore',
}

export enum AccountsSubscriptionNextBillingCurrency {
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
    AUD = 'AUD',
    NZD = 'NZD',
    SGD = 'SGD',
    ZAR = 'ZAR',
}

export interface AccountsSubscriptionsAnswer extends BaseAnswer {
    Subscriptions: AccountsSubscriptionAnswer[];
}

export enum AccountsSubscriptionStatus {
    Active = 'Active',
    Grace = 'Grace',
    Expired = 'Expired',
    Canceled = 'Canceled',
    PendingCancellation = 'PendingCancellation',
}

export enum AccountType {
    EndUser = 'EndUser',
    Unknown = 'Unknown',
    Partner = 'Partner',
    ReceiptBankPartner = 'ReceiptBankPartner',
}

export enum AddressType {
    Postal = 0,
    Physical = 1,
}

export interface AMBackendControllersv1BaseApiTransfer {
    ApiAuthToken: string;
}

export enum AMBackendControllersv1POStatus {
    Draft = 0,
    Submitted = 1,
    Approved = 2,
    Rejected = 3,
    Cancelled = 4,
}

export interface AMBackendControllersv1PurchaseOrderCreateDraftAnswer extends BaseAnswer {
    poId: Guid;
    editLink: string;
    viewLink: string;
}

export interface AMBackendControllersv1PurchaseOrderCreateDraftTransfer extends AMBackendControllersv1BaseApiTransfer {
    companyId?: Guid | undefined;
    requesterEmail: string;
}

export interface AMBackendControllersv1PurchaseOrderRequesterAnswer {
    poNumber: string;
    requesterEmail: string;
    xeroId: Guid;
    approvalMaxId: Guid;
}

export interface AMBackendControllersv1PurchaseOrderRequestersAnswer extends BaseAnswer {
    requesters: AMBackendControllersv1PurchaseOrderRequesterAnswer[];
}

export interface AMBackendControllersv1PurchaseOrderRequesterTransfer {
    poNumbers: string[];
}

export interface AMBackendControllersv1PurchaseOrderStatusAnswer extends BaseAnswer {
    requesterEmail: string;
    poId: Guid;
    status: AMBackendControllersv1POStatus;
}

export interface AMBackendControllersv1PurchaseOrderSummaryStatusAnswer extends BaseAnswer {
    PurchaseOrdersStatus: AMBackendControllersv1PurchaseOrderStatusAnswer[];
}

export interface AMBackendControllersv1PurchaseOrderSummaryStatusTransfer
    extends AMBackendControllersv1BaseApiTransfer {
    poIds: Guid[];
}

export interface AMBackendControllersv1ReportTransfer extends AMBackendControllersv1BaseApiTransfer {
    reportId: Guid;
    csv?: boolean | undefined;
}

export enum AmountConditionType {
    Gross = 0,
    Net = 1,
}

export interface Answer<T> {
    data: T;
}

export enum CompanyMatchingSettingsBillApprovalPolicy {
    Always = 'Always',
    WithPercentageThreshold = 'WithPercentageThreshold',
    WithThreshold = 'WithThreshold',
    Never = 'Never',
}

export enum ApprovalAllowanceType {
    Always = 'Always',
    WithPercentageThreshold = 'WithPercentageThreshold',
    WithThreshold = 'WithThreshold',
    Never = 'Never',
}

export interface ArdalisSmartEnumSmartEnum {}

export interface AttachmentAnswer {
    AttachmentId: Guid;
    Name: string;
    Size: number;
    CreatedDate: string;
    Author: UserAnswer;
    AttachmentType: AttachmentType;
    UploadResult?: FilesFileUploadResult | undefined;
    ShouldSerializeUploadResult: boolean;
    ExternalId?: string;
    IsOcrOngoing?: boolean;
    OcrStatus?: domain.OcrStatus;
}

export interface AttachmentListAnswer extends BaseAnswer {
    Attachments: AttachmentAnswer[];
}

export enum AttachmentType {
    General = 0,
    PurchaseOrderPDF = 1,
}

export interface AuthGoogleOAuthGetUrlAnswer {
    GoogleOAuthUrl: string;
}

export interface AuthQBooksOAuthGetUrlAnswer {
    QBooksOAuthUrl: string;
}

export interface AuthMicrosoftOAuthGetUrlAnswer {
    MicrosoftOAuthUrl: string;
}

export interface AuthXeroOAuthGetUrlAnswer {
    XeroOAuthUrl: string;
}

export enum AutoDecisionPolicyType {
    AutoApprove = 'AutoApprove',
    AutoReject = 'AutoReject',
}

export interface BaseAnswer {
    Result?: string | undefined;
    ErrorCode?: string | undefined;
    ErrorMsg?: string | undefined;
    ErrorDetails?: string | undefined;
    ValidationErrors?: Array<string | undefined>;
}

export interface MatchingMetadata {
    hasMatchedV2Documents: boolean;
}

export interface CompaniesCompanyContextAnswer {
    Company: CompanyAnswer;
    /**
     * Indicates if there are any workflow within this company where user is a payer
     */
    HasActiveWorkflowWithUserAsPayer: boolean;
    TemplatesForEdit: TemplateAnswer[];
    TemplatesForRequest: TemplateAnswer[];
    WorkflowsForView: Array<{
        id: string;
        companyId: string;
        name?: string;
        integrationCode?: string;
        enabled: boolean;
    }>;
    Workflows: Array<{
        id: string;
        companyId: string;
        name?: string;
        integrationCode?: string;
        enabled: boolean;
    }>;
    BetaFeatures: CompaniesConfigurationKeyAnswer[];
    BillMatchingSettings: AccountingCompaniesBillMatchingSettings;
    PurchaseOrderMatchingSettings: AccountingCompaniesPurchaseOrderMatchingSettings;
    MatchingMetadata: MatchingMetadata;
    XeroPurchaseOrderSettings: AccountingCompaniesXeroPurchaseOrderSettings;
    QBooksPurchaseOrderSettings: AccountingCompaniesQboPurchaseOrderSettings;
    QBooksPriceCheckerSettings: AccountingCompaniesQboPriceCheckerSettings;
}

export interface CompaniesCompanyParticipantInvitation extends CompaniesParticipantInvitation {
    companyId: Guid;
    companyName: string;
}

export interface PraciceStaffInvitation extends CompaniesParticipantInvitation {
    accountId: Guid;
    practiceName: string;
}

export interface CompaniesConfigurationKeyAnswer {
    Key: string;
    Name: string;
}

export interface CompaniesDelegateInfo {
    delegateUserProfileId?: string;
    delegateFrom?: string;
    delegateTo?: string;
}

export interface CompaniesFieldHeader {
    fieldId?: Guid | undefined;
    fieldName: string;
    fieldPurpose: FieldSystemPurpose;
    fieldType: FieldType;
    netSuiteField?: NetSuiteField;
}

export interface CompaniesParticipant {
    participantUserId?: Guid;
    participantRole?: CompanyRole | undefined;
    participantDelegate?: CompaniesDelegateInfo;
}

export interface CompaniesParticipantInvitation {
    dispatchDate?: DateTimeIsoString | undefined;
    responseDate?: DateTimeIsoString | undefined;
    response?: ResponseToInvitation | undefined;
    inviter: UserAnswer;
}

export interface CompaniesReceiptBankIntegrationAnswer {
    companyId: Guid;
    integrationId: Guid;
    modifiedDate: string;
    createdDate: string;
    isConnected: boolean;
}

export interface CompaniesResponseToParticipantInvitation {
    companyId: Guid;
    response: ResponseToInvitation;
}

export interface CompaniesSelectAnswer extends BaseAnswer {
    Companies: CompanyAnswer[];
}

export interface CompaniesUserCompaniesContext {
    CompanyContexts: CompaniesCompanyContextAnswer[];
    Invitations: CompaniesCompanyParticipantInvitation[];
    PracticeInvitations: PraciceStaffInvitation[];
    Subscriptions: AccountsSubscriptionAnswer[];
    UserProfile: ProfileGetAnswer;
    Account: AccountsAccountAnswer;
}

export type CompaniesUserCompaniesContextAnswer = CompaniesUserCompaniesContext & BaseAnswer;

export interface CompanyCreatedInfo {
    CompanyId: Guid;
    LicenseId: Guid;
    LicenseProductPlan: domain.CompanyPlan;
}

export interface CompanyAnswer {
    CompanyId: Guid;
    Name: string;
    DefaultCurrency: string;
    TimeZone: string;
    Author: UserAnswer;
    Managers: CompanyParticipantAnswer[];
    Participants: CompanyParticipantAnswer[];
    Auditors: CompanyParticipantAnswer[];
    WorkflowManagers: CompanyParticipantAnswer[];
    Integrations: IntegrationAnswer[];
    ReceiptBankIntegration: CompaniesReceiptBankIntegrationAnswer;
    LicenseExpires?: DateTimeIsoString | undefined;
    LicenseId: string;
    IsReadOnly?: boolean;
    SubscriptionId?: Guid | undefined;
    ProductPlanId: string;
    ProductPlanFeatures: string[];
    BetaFeatures: CompaniesConfigurationKeyAnswer[];
    AllFeaturesTrialStatus: string;
    AllFeaturesTrialEndDate: string;
    EnforcementTfaType: domain.TwoFaEnforcementType;
}

export interface CompanyDelegateAnswer {
    CompanyId: Guid;
    Delegate: UserAnswer;
    DelegateFrom: string;
    DelegateTo: string;
}

export interface PermissionsSettings {
    permission: Permission;
    isEditable: boolean;
    isEnabled: boolean;
}

export interface CompanyParticipantAnswer extends UserAnswer {
    Role: CompanyRole;
    Delegate: UserAnswer;
    DelegateFrom: string;
    DelegateTo: string;
    Invitation: CompaniesParticipantInvitation;
    Permissions?: PermissionsSettings[];
}

export interface CompanyParticipantsAnswer extends BaseAnswer {
    Participants: CompanyParticipantAnswer[];
}

export enum CompanyRole {
    Participant = 0,
    Manager = 1,
    Auditor = 2,
    WorkflowManager = 3,
}

export interface DateRangeAnswer {
    Type: DateRangeType;
    Custom: RangeAnswer<DateTimeIsoString | undefined>;
}

export enum DateRangeType {
    Custom = 0,
    Today = 1,
    Yesterday = 2,
    Tomorrow = 3,
    ThisWeek = 4,
    LastWeek = 5,
    NextWeek = 6,
    ThisMonth = 7,
    LastMonth = 8,
    NextMonth = 9,
}

export enum DocumentTaxType {
    TaxExclusive = 0,
    TaxInclusive = 1,
    NoTax = 2,
}

export enum EditRoleForRequest {
    Submitter = 0,
    Editor = 1,
    Reviewer = 2,
    Approver = 3,
    ExternalSubmitter = 4,
}

export interface EmailAnswer {
    Email: string;
    DefaultEmail: boolean;
    Verified: boolean;
    ExpirationDate: string;
}

export interface ExactConstraintAnswer {
    Id: string;
    Value: string;
}

export interface ExchangeCodeForAccessTokenAnswer extends BaseAnswer {
    tenants: TenantInfo[];
}

export interface NetSuiteField {
    Field: {
        ScriptId: string;
        Id: string;
        Name: string;
    };
    Level: string;
    ReferenceRecordType?: ReferenceAnswer | null;
    Order: number;
    Type: string;
}

export interface FieldWorkflowItem {
    WorkflowId: string;
    IsActive: boolean;
    IsEditable: boolean;
    IsMandatory: boolean;
}

export interface FieldAnswer {
    FieldId: Guid;
    Name: string;
    Type: FieldType;
    SystemPurpose: FieldSystemPurpose;
    CurrencyISOCode: string;
    ExactValues: FieldValueAnswer[];
    HasMore?: boolean | undefined;
    SubmitterApplicable: boolean;
    NetSuiteField?: NetSuiteField;
    IsArchived?: boolean;
    Workflows?: FieldWorkflowItem[];
    ReferenceRecordFieldSystemPurpose: number | null;
}

export interface FieldChangedData {
    FieldName: string;
    OldValue: string;
    NewValue: string;
    OldDetails: string;
    NewDetails: string;
}

export interface FieldsAnswer extends BaseAnswer {
    Fields: FieldAnswer[];
}

export enum FieldSystemPurpose {
    General = 0,
    Amount = 1,
    XeroTracking = 2,
    XeroSupplier = 3,
    XeroCustomer = 4,
    XeroAccount = 5,
    XeroItem = 6,
    Requester = 7,
    XeroBranding = 8,
    XeroTax = 9,
    QBooksVendor = 10,
    QBooksPoItem = 11,
    QBooksDepartment = 12,
    QBooksClass = 13,
    QBooksCustomer = 14,
    QBooksCustom = 15,
    QBooksAccount = 16,
    QBooksTerm = 17,
    XeroBankAccount = 18,
    NetSuiteSubsidiary = 19,
    NetSuiteVendor = 20,
    NetSuiteAccount = 21,
    NetSuiteExpenseCategory = 22,
    NetSuiteItem = 23,
    NetSuiteDepartment = 24,
    NetSuiteClass = 25,
    NetSuiteLocation = 26,
    QBooksPaymentMethod = 27,
    QBooksPaymentType = 28,
    QBooksPaymentAccount = 29,
    QBooksPayeeCustomer = 30,
    QBooksPayeeVendor = 31,
    QBooksPayeeEmployee = 32,
    XeroManualExchangeRate = 33,
    QBooksManualExchangeRate = 34,
    NetSuiteCurrency = 35,
    QBooksDocumentStatus = 36,
    DearSupplier = 37,
    DearAccount = 38,
    DearProduct = 39,
    DearProductCategory = 40,
    DearProductFamily = 41,
    DearBrand = 42,
    DearLocation = 43,
    NetSuiteCustom = 44,
    AirwallexBeneficiary = 45,
    AirwallexCurrency = 46,
    AirwallexPaymentFee = 47,
    AirwallexPaymentPurpose = 48,
    AirwallexPaymentMethod = 49,
    AirwallexSourceAmount = 50,
    AirwallexFeeAmount = 51,
    AirwallexBatchPaymentStatus = 52,
    AirwallexPaymentItemStatus = 53,
    QBooksCurrency = 54,
    NetSuiteExpenseReportEmployee = 55,
    NetSuiteCustomer = 56,
    NetSuiteTaxCode = 57,
    QBooksTax = 58,
    AmaxPayBankAccount = 59,
    XeroDate = 60,
    XeroDueDate = 61,
    XeroLineDescription = 62,
    XeroLineDiscount = 63,
    NoteForApprovers = 64,
    EmailToSupplier = 65,
    XeroCurrency = 66,
    XeroReference = 67,
    XeroAttachment = 68,
    XeroLineAmountType = 69,
    XeroLineQty = 70,
    XeroLineUnitPrice = 71,
    XeroLineTaxAmount = 72,
    XeroDeliveryAddress = 73,
    XeroContactName = 74,
    XeroContactAccountNumber = 75,
    XeroContactPrimaryPersonFirstName = 76,
    XeroContactPrimaryPersonLastName = 77,
    XeroContactPrimaryPersonEmail = 78,
    XeroContactPersons = 79,
    XeroContactPhone = 80,
    XeroContactFax = 81,
    XeroContactMobile = 82,
    XeroContactDirectDial = 83,
    XeroContactSkypeNameOrNumber = 84,
    XeroContactPostalAddress = 85,
    XeroContactStreetAddress = 86,
    XeroContactSalesAccount = 87,
    XeroContactSalesTrackingCategories = 88,
    XeroContactPurchaseAccount = 89,
    XeroContactPurchaseTrackingCategories = 90,
    XeroContactTaxNumber = 91,
    XeroContactCompanyNumber = 92,
    XeroContactSalesTax = 93,
    XeroContactPurchaseTax = 94,
    XeroContactDefaultCurrency = 95,
    XeroContactBankAccountDetails = 96,
    XeroQuoteNumber = 97,
    XeroQuoteTitle = 98,
    XeroQuoteSummary = 99,
    XeroQuoteTerms = 100,
    XeroQuoteExpiry = 101,
    XeroDeliveryDate = 102,
    NetSuitePayee = 103,
    QBooksEmployee = 104,
    QBooksSalesItem = 105,
    QBooksInvoiceCustomer = 106,
    QBooksInvoiceCustom = 107,
    XeroBatchPaymentItems = 108,
}

export enum FieldType {
    NumericRange = 0,
    ExactValueRange = 1,
    ExactValueRangeNegative = 2,
}

export interface FieldValueAnswer {
    FieldValueId: string;
    Value: string;
}

export enum FilesFileUploadResult {
    OK = 0,
    TooBig = 1,
    RestrictedFileType = 2,
    VirusFound = 3,
}

export interface FilteredRequestReportColumnAnswer<T> extends RequestReportColumnAnswer {
    FilteringValue: T;
}

export interface GetAddressesAnswer extends BaseAnswer {
    Addresses: IntegrationsAddress[];
}

export interface GetTrackingCategoriesAnswer extends BaseAnswer {
    TrackingCategories: IntegrationsXeroTracking[];
}

export enum OrganisationVersion {
    GLOBAL = 'Global',
    UK = 'UnitedKingdom',
    NZ = 'NewZealand',
    AU = 'Australia',
    US = 'UnitedStates',
    QBO_AU = 'AU',
    QBO_US = 'US',
}

export interface IntegrationAnswer {
    CompanyId: Guid;
    IntegrationId: Guid;
    IntegrationType: string;
    IntegratedCompanyName: string;
    IntegratedCompanyId: string;
    LockDate: string;
    FinancialYearEnd?:
        | {
              day: number;
              month: number;
          }
        | string;
    ModifiedDate: string;
    CreatedDate: string;
    Enabled: boolean;
    ErrorStatusCode?: number | undefined;
    ErrorStatusMessage: string;
    externalUrl: string;
    templatesSyncProgress: RequestsSyncProgressAnswer[];
    IsBudgetSynchronizationEnabled: boolean;
    IntegrationExtraInfo: string;
    Version: OrganisationVersion;
}

export interface IntegrationsAddress {
    AddressType: AddressType;
    AddressLine1: string;
    AddressLine2: string;
    AddressLine3: string;
    AddressLine4: string;
    City: string;
    Region: string;
    PostalCode: string;
    Country: string;
    AttentionTo: string;
}

export interface IntegrationsAnswer extends BaseAnswer {
    Integrations: IntegrationAnswer[];
}

export interface IntegrationsCacheStatus {
    cacheType: XeroClientCachesCacheType;
    lastStartDate: string;
    lastEndDate: string;
    loadingInProgress: boolean;
    cachedObjectCount?: number | undefined;
}

export interface IntegrationsContact {
    Id: string;
    Name: string;
}

export interface IntegrationsContactPerson {
    FirstName: string;
    LastName: string;
    EmailAddress: string;
    IncludeInEmails?: boolean | undefined;
}

export interface IntegrationsCurrency {
    ISOCode: string;
    Name: string;
    RateToCompanyCurrency?: number | undefined;
}

export interface EmailToContact {
    EmailCC: string | null;
    EmailBCC: string | null;
    EmailFrom: string | null;
    EmailReplyTo: string | null;
    EmailSubject: string | null;
    EmailBody: string | null;
    EmailHasToBeSent: boolean;
    EmailIsSent: boolean;
    EmailTo: string | null;
    Attachments: AttachmentAnswer[];
}

export interface IntegrationsIntegrationCacheStatus {
    cacheObjects: IntegrationsCacheStatus[];
}

export interface IntegrationsQBOCounterparty {
    Id: string;
    ParentId: string | null;
    Name: string;
    Addresses: IntegrationsAddress[];
    ContactPersons: IntegrationsContactPerson[];
    Currency: IntegrationsCurrency;
    TaxId: string | null;
    TermId: string | null;
}

export interface IntegrationsQBooksCustomer {
    Id: string;
    Name: string;
    CurrencyCode: string;
    Currency: IntegrationsCurrency;
    Email: string;
    Addresses: IntegrationsAddress[];
    TaxNumber: string;
}

export interface IntegrationsQBooksAccount {
    Id: string;
    Code: string;
    Name: string;
    FullyQualifiedName: string;
    ParentId: string;
    TaxCode: IntegrationsQBooksTaxCode;
    Currency: IntegrationsCurrency;
    CurrencyCode: string;
    Type: string;
    Classification: string;
}

export interface IntegrationsQBooksEmployee {
    Id: string;
    Name: string;
    PrimaryAddr: string;
    Email: string;
}

export enum IntegrationQBooksPaymentTypeId {
    Cash = 'Cash',
    Check = 'Check',
    CreditCard = 'CreditCard',
}

export interface IntegrationsQBooksPaymentType {
    Id: IntegrationQBooksPaymentTypeId;
    Name: string;
}

export interface IntegrationsQBooksAccountBasedLineAnswer extends IntegrationsQBooksLineItemAnswer {
    AccountId: string;
    Account: string;
}

export interface IntegrationsQBooksBillInvoiceAnswer extends IntegrationsQBooksDocumentAnswer {
    Date: string;
    DueDate: string;
    BillInvoiceNumber: string;
    Vendor: IntegrationsContact;
    Term: IntegrationsQBooksTerm;
    Department: IntegrationsQBooksDepartment;
    DepartmentTerminology: string | null;
    MailingAddress: string;
    Memo: string;
    TotalTax?: number | undefined;
    SubTotal: number;
    TaxComponents: IntegrationsQBooksTaxComponent[];
    LineAmountType: string;
    ItemBasedLines: IntegrationsQBooksItemBasedLineAnswer[];
    AccountBasedLines: IntegrationsQBooksAccountBasedLineAnswer[];
    IsPaid: boolean;
    EditUrl?: string;
    Url?: string;
}

export interface IntegrationsQBooksExpenseAnswer extends IntegrationsQBooksDocumentAnswer {
    Date: string;
    ExpenseNumber: string;
    Vendor: IntegrationsContact;
    Department: IntegrationsQBooksDepartment;
    DepartmentTerminology: string | null;
    MailingAddress: string;
    Memo: string;
    TotalTax?: number | undefined;
    SubTotal: number;
    TaxComponents: IntegrationsQBooksTaxComponent[];
    LineAmountType: string;
    ItemBasedLines: IntegrationsQBooksItemBasedLineAnswer[];
    AccountBasedLines: IntegrationsQBooksAccountBasedLineAnswer[];
    Payee: IntegrationsQBooksPayee;
    PayeeType: IntegrationsQBooksPayeeType;
    PaymentAccount: IntegrationsQBooksPaymentAccount;
    PaymentMethod: IntegrationsQBooksPaymentMethod;
    PaymentType: IntegrationsQBooksPaymentType | null;
    EditUrl?: string;
    Url?: string;
}

export interface IntegrationsQBooksJournalEntryAnswer
    extends Omit<IntegrationsQBooksDocumentAnswer, 'TemplateLockDatePolicy'> {
    Date: string;
    Number?: string;
    Memo?: string;
    LineAmountType: string;
    DebitSubTotal: number;
    CreditSubTotal: number;
    DebitTotalTax: number;
    CreditTotalTax: number;
    Lines?: {
        LineId: string;
        QBooksDocumentId: string;
        Account: ReferenceAnswer | null;
        Amount: number | null;
        AmountSide: domain.QBooksJournalEntryLineAmountType | null;
        Description: string | null;
        PayeeType: IntegrationsQBooksPayeeTypes | null;
        Payee: ReferenceAnswer | null;
        TaxCode: ReferenceAnswer | null;
        TaxApplicableOnType: domain.TaxApplicableOnType | null;
        TaxRate: IntegrationsQBooksTaxRate | null;
        Class: ReferenceAnswer | null;
        Department: ReferenceAnswer | null;
    }[];
    TaxComponents: IntegrationsQBooksJournalEntryTaxComponent[];
    Url: string | null;
    EditUrl: string | null;
    IsStatusPushPending: boolean;
    TemplateLockDatePolicy: domain.TemplateSettingsLockDatePolicy;
}

export interface IntegrationsStandaloneAnswer {
    Name: string;
    Description: string;
    Date: string | null;
    FieldValues: any[];
}

export interface IntegrationsQBooksPayee {
    Id: string;
    Name: string;
    Currency?: IntegrationsCurrency;
    Addresses?: IntegrationsAddress[];
    ContactPersons?: IntegrationsContactPerson[];
    TaxId?: string;
    Email?: string;
}

export enum IntegrationsQBooksPayeeTypes {
    Vendor = 'Vendor',
    Customer = 'Customer',
    Employee = 'Employee',
}

export interface IntegrationsQBooksPayeeType {
    Value: IntegrationsQBooksPayeeTypes;
    Name: string;
}

export interface IntegrationsQBooksPaymentMethod {
    Id: string;
    Name: string;
}

export interface IntegrationsQBooksPaymentAccount {
    Id: string;
    Name: string;
    Currency?: IntegrationsCurrency;
}

export interface IntegrationsQBooksClass {
    Id: string;
    Name: string;
}

export interface IntegrationsQBooksCustomField {
    Id: string;
    Name: string;
    Value: string;
}

export interface IntegrationsQBooksDepartment {
    Id: string;
    Name: string;
}

export interface IntegrationsQBooksDocumentAnswer {
    IsStatusPushPending: boolean;
    LockDate: string | null;
    CompanyLockDate: string | null;
    TemplateLockDatePolicy: AccountingTemplatesLockDatePolicyType;
}

export interface IntegrationsQBooksDueTerm {
    Type: IntegrationsQBooksDueTermType;
    Value?: number;
}

export enum IntegrationsQBooksDueTermType {
    DayOfMonthDue = 'DayOfMonthDue',
    DiscountDayOfMonth = 'DiscountDayOfMonth',
    DiscountDays = 'DiscountDays',
    DueDays = 'DueDays',
    DueNextMonthDays = 'DueNextMonthDays',
}

export interface IntegrationsQBooksEffectiveRate {
    TaxRate: number;
    StartDate?: DateTimeIsoString | undefined;
    EndDate?: DateTimeIsoString | undefined;
}

export interface IntegrationsQBooksGetAccountsAnswer extends BaseAnswer {
    Accounts: IntegrationsQBooksAccount[];
    HasMore: boolean;
}

export interface IntegrationsQBooksGetEmployeesAnswer extends BaseAnswer {
    Employees: IntegrationsQBooksEmployee[];
    HasMore: boolean;
}

export interface IntegrationsQBooksGetContactsAnswer extends BaseAnswer {
    Contacts: IntegrationsQBOCounterparty[];
    HasMore: boolean;
}

export interface IntegrationsQBooksGetCustomFieldsAnswer extends BaseAnswer {
    CustomFields: IntegrationsQBooksCustomField[];
}

export interface IntegrationsQBooksGetItemsAnswer extends BaseAnswer {
    Items: IntegrationsQBooksItem[];
    HasMore: boolean;
}

export interface IntegrationsQBooksItem {
    Id: string;
    Code: string;
    Name: string;
    SalesDetails: IntegrationsQBooksQBOItemDetails;
    PurchaseDetails: IntegrationsQBooksQBOItemDetails;
    IsInventory: boolean;
    Class?: IntegrationsQBooksClass;
}

export interface IntegrationsQBooksItemBasedLineAnswer extends IntegrationsQBooksLineItemAnswer {
    ItemId: Guid;
    Item: string;
    IsInventory?: boolean;
    Qty?: number;
    UnitPrice?: number;
    CatalogUnitPrice?: number | null;
    AccountId?: Guid;
    Account?: string;
}

export interface IntegrationsQBooksLineItemAnswer {
    Amount?: number | undefined;
    Class: IntegrationsQBooksClass;
    Customer: IntegrationsContact;
    Description?: string;
    IsBillable?: boolean | undefined;
    IsMatched: boolean;
    IsTaxable?: boolean | undefined;
    LineItemId: Guid;
    RemainingBalance?: number;
    TaxCode: IntegrationsQBooksTaxCode;
    UnitPriceGross?: number | undefined;
}

export interface IntegrationsQBooksPurchaseOrderAnswer extends IntegrationsQBooksDocumentAnswer {
    PurchaseOrderNumber: string;
    Vendor: IntegrationsContact;
    Department: IntegrationsQBooksDepartment;
    DepartmentTerminology: string | null;
    MailingAddress: string;
    ShippingAddress: string;
    ShippingMethod: string;
    Date: string;
    Memo: string;
    VendorMessage: string;
    LineItems: IntegrationsQBooksItemBasedLineAnswer[];
    AccountBasedLines: IntegrationsQBooksAccountBasedLineAnswer[];
    CustomFields: IntegrationsQBooksCustomField[];
    LineAmountType: string;
    TotalTax?: number | undefined;
    SubTotal: number;
    TaxComponents: IntegrationsQBooksTaxComponent[];
    Status?: domain.QBooksPurchaseOrderStatus;
    GrnStatus?: domain.GoodsReceivedNotesStatus;
    EditUrl?: string;
    Url?: string;
}

export interface IntegrationsQBooksInvoiceAnswer extends IntegrationsQBooksDocumentAnswer {
    ApplyTaxAfterDiscount?: boolean;
    Balance?: number;
    BillingAddress?: string;
    CreatedDate: string;
    CustomFields?: IntegrationsQBooksCustomField[];
    Customer: IntegrationsContact;
    CustomerMemo?: string;
    Date: string;
    Deposit?: number;
    Department: IntegrationsQBooksDepartment;
    Discount?: number;
    DiscountAmount?: number;
    DueDate?: string;
    EditUrl?: string;
    IsPaid?: boolean | null;
    IsStatusPushPending: boolean;
    LineAmountType: domain.LineAmountType;
    LineItems: IntegrationsQBooksSalesInvoiceItemBasedLineAnswer[];
    Memo?: string;
    ModifiedDate: string;
    SalesInvoiceNumber?: string;
    Shipping?: number;
    ShippingAddress?: string;
    ShippingDate?: string;
    ShippingFromAddress?: string;
    ShippingMethod?: string;
    ShippingTaxAmount?: number;
    ShippingTaxCode: IntegrationsQBooksTaxCode;
    Status: domain.QBooksDocumentStatus;
    SubTotal: number;
    TaxCode: IntegrationsQBooksTaxCode;
    TaxComponents: IntegrationsQBooksTaxComponent[];
    Term: IntegrationsQBooksTerm;
    TotalTax?: number;
    TrackingNumber?: string;
    Url?: string;
    UseAutoTaxes?: boolean;
}

export interface IntegrationsQBooksSalesInvoiceItemBasedLineAnswer extends IntegrationsQBooksLineItemAnswer {
    CatalogUnitPrice?: number;
    Discount?: number;
    DiscountAmount?: number;
    Item?: string;
    ItemId?: string;
    Qty?: number;
    ServiceDate?: string;
    UnitPrice?: number;
}

export interface IntegrationsQBooksQBOItemDetails {
    Description: string;
    UnitPrice?: number | undefined;
    Account: IntegrationsQBooksAccount;
    TaxCode: IntegrationsQBooksTaxCode;
}

export enum IntegrationsQBooksQBOSpecialTaxType {
    None = 'None',
    ZeroRated = 'ZeroRated',
    ForeignTax = 'ForeignTax',
    ReverseCharge = 'ReverseCharge',
    AdjustmentRate = 'AdjustmentRate',
}

export interface IntegrationsQBooksTaxCode {
    Id: string;
    Name: string;
    TaxCodeId: string;
    PurchaseTaxRateList: IntegrationsQBooksTaxRateRef[];
    SalesTaxRateList?: IntegrationsQBooksTaxRateRef[];
}

export interface IntegrationsQBooksTaxComponent {
    TaxAmount: number;
    TaxPercent: number;
    TaxableAmount: number;
    TaxRateId: string;
    Hidden: boolean;
}

export interface IntegrationsQBooksJournalEntryTaxComponent extends IntegrationsQBooksTaxComponent {
    TaxCodeId: string;
    TaxCodeName: string;
    TaxApplicableOnType: domain.TaxApplicableOnType | null;
    TaxDebitAmount: number;
    TaxCreditAmount: number;
}

export interface IntegrationsQBooksTaxRate {
    Id: string;
    Name: string;
    TaxRateId: string;
    EffectiveTaxRate: IntegrationsQBooksEffectiveRate[];
    Hidden: boolean;
    SpecialTaxType: IntegrationsQBooksQBOSpecialTaxType;
}

export interface IntegrationsQBooksTaxRateRef {
    TaxRateId: string;
    TaxApplicable: IntegrationsQBooksTaxTypeApplicable;
    TaxOnTaxOrder: number;
}

export enum IntegrationsQBooksTaxTypeApplicable {
    TaxOnAmount = 0,
    TaxOnAmountPlusTax = 1,
    TaxOnTax = 2,
}

export interface IntegrationsQBooksTerm {
    Id: string;
    Name: string;
}

export interface IntegrationsQBooksTermDetails {
    Id: string;
    Name: string;
    DueTerms: IntegrationsQBooksDueTerm[];
}

export interface IntegrationsReceiptBankCreateReceiptBankIntegrationAnswer extends BaseAnswer {
    AccessToken: string;
}

export interface AirwallexIntegrationAnswer extends BaseAnswer {
    AuthorizeUrl: string;
}

export interface IntegrationsXeroAccount {
    Id: string;
    Code: string;
    Name: string;
    TaxCode: IntegrationsXeroTaxCode;
    AccountClass: IntegrationsXeroAccountClass;
    CurrencyCode: string;
    SystemAccount?: domain.XeroSystemAccountType;
}

export enum IntegrationsXeroAccountClass {
    Asset = 'Asset',
    Equity = 'Equity',
    Expense = 'Expense',
    Liability = 'Liability',
    Revenue = 'Revenue',
}

export interface IntegrationsXeroBrandingTheme {
    Id: string;
    Name: string;
}

export enum IntegrationsXeroPaymentTermType {
    FollowingMonth = 'FollowingMonth',
    CurrentMonth = 'CurrentMonth',
    AfterBillDate = 'AfterBillDate',
    AfterBillMonth = 'DaysAfterBillMonth',
}

export interface IntegrationsXeroPaymentTermDetails {
    Day: number;
    TermType: IntegrationsXeroPaymentTermType;
}

export interface IntegrationsXeroPaymentTerms {
    Bills?: IntegrationsXeroPaymentTermDetails;
    Sales?: IntegrationsXeroPaymentTermDetails;
}

export interface IntegrationsXeroCounterparty {
    Id: string;
    Name: string;
    Addresses: IntegrationsAddress[];
    ContactPersons: IntegrationsContactPerson[];
    SalesTaxCode: IntegrationsXeroTaxCode;
    PurchaseTaxCode: IntegrationsXeroTaxCode;
    SalesAccount: IntegrationsXeroAccount;
    PurchaseAccount: IntegrationsXeroAccount;
    BrandingTheme: IntegrationsXeroBrandingTheme;
    DefaultCurrency: string;
    PurchaseTrackingCategories: IntegrationsXeroTracking[];
    SalesTrackingCategories: IntegrationsXeroTracking[];
    PaymentTerms: IntegrationsXeroPaymentTerms;
    AccountNumber?: string;
    TaxNumber?: string;
    EmailAddress?: string;
}

export interface IntegrationsXeroCreateCounterpartyAnswer extends BaseAnswer {
    Counterparty: IntegrationsXeroCounterparty;
}

export interface IntegrationsXeroCreditNoteAnswer extends IntegrationsXeroGLDocumentAnswer {
    CreditNoteId: Guid;
    CreditNoteType: string;
    IntegrationEnabled: boolean;
    Contact: IntegrationsContact;
    Date: string;
    LineAmountType: domain.LineAmountType;
    LineItems: IntegrationsXeroLineItemAnswer[];
    SubTotal?: number | undefined;
    InvoiceTaxes: IntegrationsXeroInvoiceTaxAnswer[];
    CreditNoteNumber: string;
    CreditNoteReference: string;
    RemainingCredit: number;
    Url: string;
    UrlCData: any;
    EditUrl: string;
    EditUrlCData: any;
    Status?: domain.XeroObjectStatus | undefined;
    BrandingTheme: string;
    BrandingThemeId?: Guid | undefined;
    CisTaxAmount?: number | null;
    HasCisDeduction?: boolean;
}

export interface IntegrationsXeroDocumentAnswer {
    IsStatusPushPending: boolean;
}

export interface IntegrationsXeroGetAccountsAnswer extends BaseAnswer {
    Accounts: IntegrationsXeroAccount[];
}

export interface IntegrationsXeroGetBankAccountsAnswer extends BaseAnswer {
    Accounts: IntegrationsXeroAccount[];
}

export interface AirwallexBeneficiaryBankDetails {
    accountName: string;
    accountNumber: string;
    accountingRoutingType1?: string;
    accountingRoutingType2?: string;
    accountingRoutingValue1?: string;
    accountingRoutingValue2?: string;
    accountRoutingType1?: string;
    accountRoutingType2?: string;
    accountRoutingValue1?: string;
    accountRoutingValue2?: string;
    bankBranch: string;
    bankCountryCode: string;
    bankName: string;
    iban: string;
    swiftCode: string;
    localClearingSystem: string;
}

export interface AirwallexBeneficiary {
    airwallexBeneficiaryId: string;
    name: string;
    accountCurrency: string;
    bankDetails: AirwallexBeneficiaryBankDetails;
    paymentMethod?: string;
    paymentFee?: string;
    version: number;
}

export interface AirwallexBeneficiariesAnswer extends BaseAnswer {
    items: AirwallexBeneficiary[];
}

interface AirwallexBenefeciarySchemaCondition {
    account_currency: string;
    bank_country_code: string;
    entity_type: string;
    payment_method: string;
    local_clearing_system?: string;
}

export interface AirwallexBenefeciarySchemaField {
    enabled: boolean;
    path: string;
    required: boolean;
    rule?: {
        type: string;
        pattern: string;
    };
    field: {
        key: string;
        options?: Array<{
            label: string;
            value: string;
        }>;
        default: string;
        description: string;
        label: string;
        placeholder: string;
        refresh: boolean;
        tip: string;
        type: string;
    };
}

interface AirwallexBenefeciarySchemaFieldsData {
    condition: AirwallexBenefeciarySchemaCondition;
    fields: AirwallexBenefeciarySchemaField[];
}

export interface AirwallexBeneficiarySchemaAnswer extends BaseAnswer {
    schema: AirwallexBenefeciarySchemaFieldsData;
    key: string;
}

export interface AirwallexCreateBeneficiaryAnswer extends BaseAnswer {
    Beneficiary: AirwallexBeneficiary;
}

export interface IntegrationsXeroGetBrandingThemesAnswer extends BaseAnswer {
    BrandingThemes: IntegrationsXeroBrandingTheme[];
}

export interface IntegrationsXeroGetContactsAnswer extends BaseAnswer {
    Contacts: IntegrationsXeroCounterparty[];
    HasMore: boolean;
}

export interface IntegrationsXeroGetItemsAnswer extends BaseAnswer {
    Items: IntegrationsXeroItem[];
    HasMore: boolean;
}

export interface IntegrationsXeroGetTaxCodesAnswer extends BaseAnswer {
    TaxCodes: IntegrationsXeroTaxCode[];
}

export interface IntegrationsXeroGLDocumentAnswer extends IntegrationsXeroDocumentAnswer {
    LockDate: string;
    CompanyLockDate: string;
    TemplateLockDatePolicy: domain.TemplateSettingsLockDatePolicy;
}

export interface XeroInvoiceMatchedPO {
    RequestId: string;
    Name: string;
    OverBudget: boolean;
    Amount: { Value: number; Currency: string };
    MatchedBillsAmount: { Value: number; Currency: string };
    MatchedBills: { RequestId: string; Name: string; Amount: { Value: number; Currency: string } }[];
}

export interface IntegrationsXeroInvoiceAnswer extends IntegrationsXeroGLDocumentAnswer {
    InvoiceId: Guid;
    InvoiceType: string;
    IntegrationEnabled: boolean;
    Contact: IntegrationsContact;
    Date: string;
    DueDate: string;
    LineAmountType: domain.LineAmountType;
    LineItems: IntegrationsXeroLineItemAnswer[];
    SubTotal?: number | undefined;
    InvoiceTaxes: IntegrationsXeroInvoiceTaxAnswer[];
    InvoiceNumber: string;
    InvoiceReference: string;
    Url: string;
    UrlCData: any;
    EditUrl: string;
    EditUrlCData: any;
    Status?: domain.XeroObjectStatus;
    BrandingTheme: string;
    BrandingThemeId?: Guid | undefined;
    PaymentDetails: IntegrationsXeroPaymentDetailsAnswer;
    CisTaxAmount?: number | null;
    HasCisDeduction?: boolean;
    MatchedPO?: XeroInvoiceMatchedPO;
}

export interface IntegrationsXeroInvoiceTaxAnswer {
    TaxRatePercent: number;
    TaxValue: number;
    TaxRateString: string;
}

export interface IntegrationsXeroItem {
    Id: string;
    Code: string;
    Name: string;
    SalesDetails: IntegrationsXeroItemDetails;
    PurchaseDetails: IntegrationsXeroItemDetails;
    IsInventory: boolean;
}

export interface IntegrationsXeroItemDetails {
    Description: string;
    UnitPrice?: number | undefined;
    Account: IntegrationsXeroAccount;
    TaxCode: IntegrationsXeroTaxCode;
}

export interface IntegrationXeroInvoiceAnswer {
    amountDue: number;
    contact: { Id: string; Name: string };
    date: string;
    dueDate: string;
    friendlyName: string;
    note: string | null;
    xeroBillInvoiceRequestId: string;
    currency: string;
    bankAccountNumber: string | null;
    defaultBankAccountNumber: string | null;
    details: string | null;
    amountOnApproval: number;
    amountAwaitingPayment: number;
    amountInProcessing: number;
    amount: number | null;
    reference: string | null;
    code: string | null;
    particulars: string | null;
    xeroBillInvoiceReference: string | null;
    attachments?: backend.AttachmentAnswer[];
    outstandingBalance: number | null;
    overdueBalance: number | null;
}

export interface IntegrationXeroBillBatchPaymentAnswer {
    xeroBillBatchPaymentId: string;
    paymentDate: string | null;
    narrative: string | null;
    details: string | null;
    bankAccount: { Id: string; Name: string } | null;
    items: IntegrationXeroInvoiceAnswer[];
    reference: string | null;
    code: string | null;
    particulars: string | null;
    workflowIsSupplierBankAccountRequired: boolean;
    isStatusPushPending: boolean;
    url: string | null;
    editUrl: string | null;
    lockDate: string | null;
    workflowLockDatePolicy: domain.TemplateSettingsLockDatePolicy | null;
}

export interface XeroAirwallexInvoiceAnswer {
    xeroBillInvoiceRequestId: string;
    amount: number;
    beneficiary?: AirwallexBeneficiary;
    contact?: { id: string; name: string };
    paymentReference: string;
    paymentPurpose?: string;
    sourceCurrency?: string;
    amountDue: number;
    date: string;
    dueDate: string;
    friendlyName: string;
    currency: string;
    bankAccount?: { Id: string; Name: string; Code?: string };
    amountOnApproval?: number;
    amountInProcessing?: number;
    amountAwaitingPayment?: number;
    attachments?: backend.AttachmentAnswer[];
    paymentFee?: string;
    sourceAmount?: number;
    feeAmount?: number;
    status?: domain.AirwallexInvoiceStatus;
    errorMessage?: string;
    statusUpdatedAt?: string;
    xeroUrl?: string;
    airwallexUrl?: string;
    beneficiaryPaymentStatus: domain.BeneficiaryPaymentStatus | null;
    beneficiaryDiscrepancy: domain.BeneficiaryLastPaymentDiscrepancy | null;
}

export interface IntegrationXeroAirwallexBatchPaymentAnswer {
    xeroAirwallexBatchPaymentId: string;
    items: XeroAirwallexInvoiceAnswer[];
    status: domain.AirwallexPaymentStatus | null;
    paymentProcessingDetails?: domain.AirwallexPaymentProcessingDetails;
    scheduledPaymentDate?: string;
    scheduledPaymentFailed?: boolean;
}

export interface XeroAmaxPayInvoicePaymentDetailsAnswer {
    id: string;
    name: string;
    createdAt: string;
    createdBy: string;
    modifiedAt: string | null;
    modifiedBy: string | null;
    versionId: string;
}

export interface XeroAmaxPayInvoiceAnswer {
    xeroBillInvoiceRequestId: string;
    amaxPayBankAccount?: { id: string; name: string };
    amount: number;
    amountDue: number;
    amountOnApproval?: number;
    amountInProcessing?: number;
    amountAwaitingPayment?: number;
    billInvoiceAmount: number;
    date: string;
    dueDate: string;
    friendlyName: string;
    paymentDetails: XeroAmaxPayInvoicePaymentDetailsAnswer;
    paymentReference?: string;
    attachments?: AttachmentAnswer[];
    contact: {
        id: string;
        name: string;
    };
    currency: string;
    paymentStatus: domain.AmaxPayPaymentStatus | null;
    paymentDetailsPaymentStatus: domain.PaymentDetailsPaymentStatus | null;
    xeroUrl?: string;
    statusUpdatedAt?: string;
}

export interface IntegrationXeroAmaxPayBatchPaymentAnswer {
    amaxPayXeroBatchPaymentId: string;
    items: XeroAmaxPayInvoiceAnswer[];
    status: domain.AmaxPayXeroBatchPaymentStatus | null;
    isMergedBySupplier: boolean;
}

export interface IntegrationsXeroLineItemAnswer {
    LineItemId?: Guid | undefined;
    Item: string;
    Description: string;
    Qty?: number | undefined;
    UnitPrice?: number | undefined;
    CatalogUnitPrice?: number | undefined;
    Account: string;
    TaxRateString: string;
    TaxCode: string;
    AccountId?: Guid | undefined;
    ItemCode: string;
    ItemId?: Guid | undefined;
    TaxRate?: number | undefined;
    TaxRateEffective?: number | undefined;
    Amount?: number | undefined;
    TaxAmount?: number | undefined;
    Discount?: number | undefined;
    DiscountAmount?: number;
    DiscountType?: DiscountType;
    Tracking: IntegrationsXeroTrackingCategoryAnswer[];
    IsInventory?: boolean | undefined;
    CisRate?: number | null;
    CisTaxAmount?: number | null;
}

export interface IntegrationsXeroPaymentDetailsAnswer {
    Date: string;
    BankAccountId: string;
    BankAccountName: string;
    BankAccountNumber: string;
    Amount: number;
    BankAccountCode: string;
    BankAccountCurrencyCode: string;
}

export interface IntegrationsXeroPurchaseOrderAnswer extends IntegrationsXeroDocumentAnswer {
    PurchaseOrderId: Guid;
    PurchaseOrderNumber: string;
    PurchaseOrderReference: string;
    IntegrationEnabled: boolean;
    Contact: IntegrationsContact;
    Date: string;
    DeliveryDate: string;
    LineAmountType: domain.LineAmountType;
    LineItems: IntegrationsXeroLineItemAnswer[];
    SubTotal?: number | undefined;
    InvoiceTaxes: IntegrationsXeroInvoiceTaxAnswer[];
    DeliveryAddress: string;
    AttentionTo: string;
    Phone: string;
    DeliveryInstructions: string;
    Url: string;
    UrlCData: any;
    EditUrl: string;
    EditUrlCData: any;
    BrandingTheme: string;
    BrandingThemeId?: Guid;
    IsBilled?: boolean;
    Status?: domain.XeroObjectStatus;
    GrnStatus?: domain.GoodsReceivedNotesStatus;
}

export interface IntegrationsXeroQuoteAnswer extends IntegrationsXeroDocumentAnswer {
    QuoteId: Guid;
    QuoteNumber: string;
    QuoteReference: string;
    IntegrationEnabled: boolean;
    Contact: IntegrationsContact;
    Date: string;
    ExpiryDate: string;
    LineAmountType: domain.LineAmountType;
    LineItems: IntegrationsXeroLineItemAnswer[];
    SubTotal?: number;
    Total?: number;
    TotalDiscount?: number;
    TotalTax?: number;
    Summary?: string;
    Terms?: string;
    Title?: string;
    InvoiceTaxes: IntegrationsXeroInvoiceTaxAnswer[];
    Url: string;
    EditUrl: string;
    BrandingTheme: string;
    BrandingThemeId?: Guid;
    Status?: domain.XeroQuoteStatus;
    IsAccepted: boolean;
}

export interface IntegrationsXeroTaxCode {
    Id: string;
    Name: string;
    TaxType: string;
    TaxRate?: number | undefined;
    TaxRateEffective?: number | undefined;
    ApplicableToAccountClasses: IntegrationsXeroAccountClass[];
}

export interface IntegrationsXeroTracking {
    Id: string;
    Name: string;
    Options: IntegrationsXeroTrackingCategoryOption[];
}

export interface IntegrationsXeroTrackingCategoryAnswer {
    CategoryId: string;
    CategoryName: string;
    OptionId: string;
    OptionName: string;
    OrderNumber: number;
}

export interface IntegrationsXeroTrackingCategoryOption {
    Id: string;
    Value: string;
}

export interface IntegrationsXeroCisSettings {
    ContractorEnabled: boolean;
    SubContractorEnabled: boolean;
    CompanyRate?: number | null;
}

export interface IntegrationsNetSuiteVendors {
    Values: {
        Id: string;
        Name: string;
        VatNumber: string | null;
    }[];
}

export interface IntegrationsNetSuitePayees {
    Values: {
        Id: string;
        Name: string;
        Type: domain.NetSuitePayeeType;
        Address: domain.BillingAddressBackend[] | null;
        CurrencyIds: string[] | null;
        DefaultBillingAddress: domain.BillingAddressBackend | null;
        DefaultPayablesAccount: ReferenceAnswer | null;
        DefaultCurrencyId: string | null;
    }[];
}

export interface IntegrationsNetSuiteIncoterms {
    Values: ReferenceAnswer[];
}

export interface IntegrationsNetSuiteAccounts {
    Values: ReferenceAnswer[];
}

export interface NetSuiteBillingAddress {
    Id: string;
    FullAddress: string;
}

export interface IntegrationsNetSuiteVendor {
    Vendor: {
        Id: string;
        Name: string;
        VatNumber: string | null;
        Currency: ReferenceAnswer | null;
        CurrencyIds: string[] | null;
        Address: NetSuiteBillingAddress[] | null;
        DefaultBillingAddress: NetSuiteBillingAddress | null;
        DefaultExpenseAccount: ReferenceAnswer | null;
        DefaultPayablesAccount: ReferenceAnswer | null;
        Email: string | null;
        Incoterm: ReferenceAnswer | null;
        Terms: ReferenceAnswer | null;
    };
}

export interface IntegrationsNetSuiteEmployee {
    Employee: {
        Id: string;
        Name: string;
        VatNumber: string | null;
        Currency: ReferenceAnswer | null;
        CurrencyIds: string[] | null;
        Address: NetSuiteBillingAddress[] | null;
        DefaultBillingAddress: NetSuiteBillingAddress | null;
        DefaultExpenseAccount: ReferenceAnswer | null;
        DefaultPayablesAccount: ReferenceAnswer | null;
        Email: string | null;
        Incoterm: ReferenceAnswer | null;
        Terms: ReferenceAnswer | null;
    };
}

export interface NetSuiteUnitAnswer extends ReferenceAnswer {
    CatalogUnitPrice: number | null;
    IsItemPrimaryPurchaseUnit: boolean;
}

export interface IntegrationsNetSuiteItems {
    Values: {
        Id: string;
        Name: string;
        VendorName: string | null;
        Units: NetSuiteUnitAnswer[] | null;
        PurchasePrices: number | null;
        PurchaseDescription: string | null;
    }[];
}

export interface IntegrationsNetSuiteExpenseCategories {
    Values: {
        Id: string;
        Name: string;
        AccountId: string | null;
        Rates: {
            CurrencyInternalId: string;
            DefaultRate: number | null;
        }[];
    }[];
}

export interface IntegrationsNetSuiteCustomers {
    Values: {
        Id: string;
        Name: string;
    }[];
}

export interface XeroBillInvoiceRequest {
    xeroBillInvoiceRequestId: string;
    friendlyName: string;
    contact: { id: string; name: string };
    note: null | string;
    date: string;
    dueDate: string;
    plannedDate: string | null;
    amountDue: number;
    currency: string;
    bankAccountNumber: string | null;
    details: string | null;
    amountOnApproval: number;
    amountAwaitingPayment: number;
    amountInProcessing: number;
    reference: string | null;
    code: string | null;
    particulars: string | null;
    xeroBillInvoiceReference: string | null;
    attachments?: backend.AttachmentAnswer[];
    outstandingBalance: number | null;
    overdueBalance: number | null;
}

export interface XeroAmaxPayBillInvoiceRequest extends XeroBillInvoiceRequest {
    amaxPayBankAccount: {
        id: string;
        text: string;
    } | null;
    defaultPaymentDetails: Omit<PaymentDetails, 'isDefault'> | null;
}

export interface XeroAirwallexBillInvoiceRequest extends XeroBillInvoiceRequest {
    lastUsedBeneficiary?: AirwallexBeneficiary;
    plannedDate: string | null;
    sourceAmount?: number;
    feeAmount?: number;
    status?: domain.AirwallexInvoiceStatus;
    errorMessage?: string;
    statusUpdatedAt?: string;
    xeroUrl?: string;
    airwallexUrl?: string;
    amountInProcessing: number;
    amountAwaitingPayment: number;
}

export interface BillRequestsForBatchPaymentAnswer extends BaseAnswer {
    items: XeroBillInvoiceRequest[];
}

export interface BillRequestsForAmaxPayBatchPaymentAnswer extends BaseAnswer {
    items: XeroAmaxPayBillInvoiceRequest[];
}

export interface BillRequestsForAirwallexBatchPaymentAnswer extends BaseAnswer {
    items: XeroAirwallexBillInvoiceRequest[];
}

export interface NamedObjectAnswer<T> {
    Id: T;
    Name: string;
}

export interface NamedObjectTransfer<T> {
    id: T;
    name: string;
}

export interface OAuthIntegrationAnswer extends BaseAnswer {
    AuthorizeUrl: string;
}

export enum PartnerStatus {
    Undefined = 'Undefined',
    Applied = 'Applied',
    StatusVerified = 'StatusVerified',
    DocsSubmitted = 'DocsSubmitted',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Ignored = 'Ignored',
}

export interface ProfileDeletePushConsumerAnswer extends BaseAnswer {}

export interface ProfileDetailsAnswer extends BaseAnswer {
    UserId: Guid;
    Name: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Skype: string;
    Phone: string;
    AvatarHttps: string;
}

export interface ProfileEditAnswer extends BaseAnswer {}

export interface ProfileForgetPushConsumerAnswer extends BaseAnswer {}

export interface ProfileGetAnswer extends ProfileDetailsAnswer {
    Emails: EmailAnswer[];
    CreatedDate: string;
    TimeZoneForEmail: string;
    SendApprovalChangeNotifications: boolean;
    SendCommentsNotifications: boolean;
    SendWorkflowNotifications: boolean;
    SendNewRequestsNotifications: boolean;
    SendSummaryNotifications: boolean;
    IsSessionEnabled: boolean;
    SummaryNotificationsSentOnDays: string[];
    SummaryNotificationsSentAtTime: string;
    IsSubscribedToAd: boolean;
    IsTFAAlternativeEmailEnabled: boolean;
    IsTFABackupCodesEnabled: boolean;
    EnableXeroSurvey: boolean;
    EnableQBOSurvey: boolean;
    IsPasswordSet: boolean;
    HasSSOPassword: boolean;
    PushConsumers: ProfileRegisterPushConsumerAnswer[];
    CanCreateNewCompanies: boolean;
    BetaFeatures: string[];
    Delegates: CompanyDelegateAnswer[];
    IsTFAEnabled: boolean;
    IsSsoLogin: boolean;
    RequiredEnforcementTfaType: domain.TwoFaEnforcementType;
    Certifications: Array<{
        id: string;
        name: string;
        date: string;
    }>;
}

export interface ProfileIsPasswordSetAnswer extends BaseAnswer {
    PasswordIsSet: boolean;
}

export interface ProfileRegisterPushConsumerAnswer extends BaseAnswer {
    UserPushConsumerId: Guid;
    RegistrationId: string;
    RegistrationHandler: string;
    DeviceName: string;
    DevicePlatform: PushPlatform;
    CreatedDate: string;
}

export interface ProfileSetAvatarAnswer extends BaseAnswer {
    UrlHttps: string;
}

export interface ProfileSetDelegateAnswer extends BaseAnswer {}

export interface ProfileSetTimeZoneForEmailAnswer extends BaseAnswer {}

export enum PushPlatform {
    Apple = 0,
    Google = 1,
    WinPhone = 2,
}

export interface QBooksCompanyIntegrationsCommandsCreateVendor {
    CompanyId: Guid;
    Vendor: DeepNullablePartial<QBooksDomainVendor>;
}

export interface QBooksCompanyIntegrationsCommandsCreateVendorAnswer extends BaseAnswer {
    Vendor: IntegrationsQBOCounterparty;
}

export interface QBooksDomainAddress {
    Street: string;
    City: string;
    Region: string;
    PostCode: string;
    Country: string;
}

export interface QBooksDomainVendor {
    Title: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Suffix: string;
    DisplayName: string;
    PrintOnCheckName: string;
    CompanyName: string;
    Address: QBooksDomainAddress;
    Notes: string;
    EmailAddress: string;
    Phone: string;
    Mobile: string;
    Fax: string;
    Other: string;
    WebSite: string;
    BillingRate?: number | undefined;
    TermId: string;
    OpeningBalance?: number | undefined;
    OpeningBalanceDate?: DateTimeIsoString | undefined;
    AccountNumber: string;
    BusinessNumber: string;
    Vendor1099?: boolean | undefined;
    CurrencyCode: string;
}

export interface QBooksCompanyIntegrationsCommandsCreateCustomer {
    CompanyId: Guid;
    Customer: DeepNullablePartial<QBooksDomainCustomer>;
}

export interface QBooksCompanyIntegrationsCommandsCreateCustomerAnswer extends BaseAnswer {
    Customer: IntegrationsQBOCounterparty;
}

export interface QBooksDomainCustomer {
    AccountNumber: string;
    BillWithParent?: boolean | null;
    BillingAddress: QBooksDomainAddress;
    BusinessNumber: string;
    CompanyName: string;
    CurrencyCode: string;
    DefaultTaxCodeId?: string | null;
    DisplayName: string;
    EmailAddress: string;
    Fax: string;
    FirstName: string;
    LastName: string;
    MiddleName: string;
    Mobile: string;
    Notes: string;
    OpeningBalance?: number | undefined;
    OpeningBalanceDate?: DateTimeIsoString | undefined;
    Other: string;
    ParentId?: string | null;
    Phone: string;
    PreferredDeliveryMethod?: string | null;
    PrimaryTaxIdentifier?: string | null;
    PrintOnCheckName: string;
    ShippingAddress: QBooksDomainAddress;
    Suffix: string;
    TermId: string;
    Title: string;
    WebSite: string;
}

export enum QBooksTemplatesAvailableLineItemType {
    None = 'None',
    ItemBasedLines = 'ItemBasedLines',
    AccountBasedLines = 'AccountBasedLines',
    All = 'All',
}

export interface QBooksTemplatesLineItemSettings {
    availableLineItemType: QBooksTemplatesAvailableLineItemType;
}

export interface RangeAnswer<T> {
    GreaterOrEqual: T;
    LessOrEqual: T;
}

export interface RequestAddCommentAnswer extends BaseAnswer {
    Comment: RequestCommentAnswer;
}

export interface PossibleDuplicatesAnswer {
    FriendlyName: string;
    RequestId: Guid;
}

export interface RequestReviewerAnswer extends UserAnswer {
    AddedBy: UserAnswer | null;
    Source: domain.RequestStepReviewerSource;
    DelegateFor?: UserAnswer;
}

export type RequestReviewStepAnswer = {
    ReviewStepId: string;
    Reviewers: RequestReviewerAnswer[];
    CompletedAt: string | null;
    CompletedByUser: UserAnswer | null;
    IsCompleted: boolean;
};

export interface XeroManualJournalLine {
    LineItemId: string;
    Description: string;
    Account: string;
    TaxRateString: string;
    TaxCode: string;
    AccountId: string;
    TaxRate: number;
    TaxRateEffective: number;
    Amount: number;
    AmountSide: domain.XeroManualJournalLineAmountType;
    TaxAmount: number;
    Tracking: IntegrationsXeroTrackingCategoryAnswer[];
    IsInventory: boolean | null;
}

export interface IntegrationsXeroManualJournalAnswer {
    IsStatusPushPending: boolean;
    LockDate: string | null;
    CompanyLockDate: string | null;
    TemplateLockDatePolicy: domain.TemplateSettingsLockDatePolicy;
    ManualJournalId: string;
    ManualJournalNumber: string;
    IntegrationEnabled: boolean;
    Date: string | null;
    Narration: string;
    ShowOnCashBasisReports: boolean;
    Status: string;
    LineAmountType: domain.LineAmountType;
    LineItems: XeroManualJournalLine[];
    InvoiceTaxes: IntegrationsXeroInvoiceTaxAnswer[];
    Url: string;
    EditUrl: string;
    CreatedDate: string;
    ModifiedDate: string;
    DebitSubTotal: number;
    DebitTotalTax: number;
    CreditSubTotal: number;
    CreditTotalTax: number;
}

export interface RequestAnswer {
    Version: number;
    RequestId: Guid;
    TemplateId: Guid;
    TemplateName: string;
    TemplateVersionIsActual: boolean;
    CompanyId: Guid;
    CompanyName: string;
    Author: UserAnswer;
    AuthorRules: RuleAnswer[];
    Origin: RequestsRequestOrigin;
    FriendlyName: string;
    Name: string;
    Description: string;
    RequestNote: string;
    IntegrationCode: domain.IntegrationCode | null;
    DescriptionCData: any;
    CreatedDate: string;
    ModifiedDate: string;
    StatusV2: domain.RequestStatusV2;
    Resolution: RequestsRequestResolution;
    ResolutionOrigin: RequestsRequestResolutionOrigin;
    ReviewStep: RequestReviewStepAnswer | null;
    ResolutionDate: string;
    FraudulentActivity: backend.RequestFraudulentActivity[];
    ReadByMe: boolean;
    Attachments: AttachmentAnswer[];
    Steps: RequestStepAnswer[];
    History: RequestHistoryEventAnswer[];
    Amount: number;
    Currency: string;
    CurrencyExchangeRate: number | null;
    CompanyCurrency: string;
    FieldsValues: RequestFieldValueAnswer[];
    AuditReportUrl: string;
    EmailToContact?: EmailToContact;
    XeroInvoiceDetails: IntegrationsXeroInvoiceAnswer;
    XeroPurchaseOrderDetails: IntegrationsXeroPurchaseOrderAnswer;
    XeroQuoteDetails: IntegrationsXeroQuoteAnswer;
    XeroSalesInvoiceDetails: IntegrationsXeroInvoiceAnswer;
    XeroCreditNoteDetails: IntegrationsXeroCreditNoteAnswer;
    XeroContactDetails: any;
    XeroBillBatchPaymentDetails: IntegrationXeroBillBatchPaymentAnswer;
    XeroManualJournalDetails: IntegrationsXeroManualJournalAnswer;
    AirwallexXeroBatchPaymentDetails: IntegrationXeroAirwallexBatchPaymentAnswer;
    AmaxPayXeroBatchPaymentDetails: IntegrationXeroAmaxPayBatchPaymentAnswer;
    QBooksPurchaseOrderDetails: IntegrationsQBooksPurchaseOrderAnswer;
    QBooksSalesInvoiceDetails: IntegrationsQBooksInvoiceAnswer;
    QBooksBillInvoiceDetails: IntegrationsQBooksBillInvoiceAnswer;
    QBooksExpenseDetails: IntegrationsQBooksExpenseAnswer;
    QBooksJournalEntryDetails: IntegrationsQBooksJournalEntryAnswer;
    QBooksVendorDetails: any;
    DearPurchaseOrderDetails?: domain.DearPurchaseOrderDetailsBackend;
    NetSuiteVendorBillDetails?: domain.NetSuiteVendorBillDetailsBackend;
    NetSuitePurchaseOrderDetails?: domain.NetSuitePurchaseOrderDetailsBackend;
    NetSuiteExpenseReportDetails?: any;
    NetSuiteSalesOrderDetails?: domain.NetSuiteSalesOrderDetailsBackend;
    NetSuiteVendorReturnAuthorizationDetails?: domain.NetSuiteVRADetailsBackend;
    NetSuiteBillPaymentDetails?: domain.NetSuiteBillPaymentDetailsBackend;
    NetSuiteInvoiceDetails?: domain.NetSuiteInvoiceDetailsBackend;
    StandaloneDetails: IntegrationsStandaloneAnswer;
    RequiredFieldIds: Guid[];
    RequiredFields: FieldAnswer[];
    IMustEdit?: boolean;
    IMustEditForEditOnApproval?: boolean;
    EditPermissionsRequiredFieldsThatShouldBeFilled?: FieldAnswer[];
    ExchangeRate?: number | undefined;
    ExchangeRateDate: string;
    ExchangeRateSource?: domain.ExchangeRateSource | null;
    IsManualExchangeRate: boolean;
    Payers: UserAnswer[] | null;
    DeadlineDate: string | null;
    Watchers: WatchersAnswer[] | null;
    WorkflowVersionId: Guid;
}

export interface RequestAuditReportDetailsAnswer extends BaseAnswer {
    Status: RequestAuditReportsRequestAuditReportStatus;
    State: RequestAuditReportsRequestAuditReportState;
}

export enum RequestAuditReportsRequestAuditReportState {
    Complete = 0,
    Underway = 1,
    Reserved = 2,
}

export enum RequestAuditReportsRequestAuditReportStatus {
    Ok = 0,
    Error = 1,
    Dirty = 2,
}

export interface RequestChangeData {
    NewName?: string;
    NewDescription?: string;
    FieldsChanges?: FieldChangedData[];
    AmountFieldChange?: FieldChangedData;
    AddedAttachments?: AttachmentAnswer[];
    RemovedAttachments?: AttachmentAnswer[];
    StepsChanges?: StepChangeData[];
    ReviewStepsChanges?: ReviewStepChangeData;
}

export interface RequestChangeParticipantsAsnwer extends BaseAnswer {
    Request: RequestAnswer;
}

export interface RequestCurrentBalanceAsnwer extends BaseAnswer {
    balances: domain.RequestCurrentBalance[];
}

export interface RequestCommentAnswer {
    CommentId: Guid;
    Text: string;
    CreatedDate: string;
    Author: UserAnswer;
    CommentAttachments: AttachmentAnswer[];
    MentionedUsers: MentionedUserAnswer[];
}

export interface RequestCopyAnswer extends BaseAnswer {
    requestId: Guid;
}

export interface RequestCreateAnswer extends BaseAnswer {
    RequestId: Guid;
}

export interface RequestDeleteAnswer extends BaseAnswer {}

export interface RequestPayAnswer extends BaseAnswer {
    Requests: RequestAnswer[];
}

export interface RequestDeleteCommentAnswer extends BaseAnswer {}

export interface RequestFieldValueAnswer extends FieldAnswer {
    Values: FieldValueAnswer[];
}

export interface RequestGetCommentsAnswer extends BaseAnswer {
    Comments: RequestCommentAnswer[];
}

export interface RequestHistoryAnswer extends BaseAnswer {
    HistoryEvents: RequestHistoryEventAnswer[];
}

export interface RequestHistoryEventAnswer {
    EventId: Guid;
    EventDate: string;
    EventType: backend.RequestHistoryEventType;
    Description?: string;
    Step?: Guid | undefined;
    NextStep?: Guid | undefined;
    StepName: string;
    NextStepName: string;
    Author?: UserAnswer;
    OnBehalfOf?: UserAnswer;
    Comment: RequestCommentAnswer;
    Changes: RequestChangeData;
    IsSystem: boolean;
    UserPlatform?: domain.RequestHistoryUserPlatform;
}

export enum RequestHistoryEvents {
    StatusChanged = 0,
    RequestReset = 1,
    ChangedName = 4,
    ChangedDescription = 5,
    ChangedDeadline = 6,
    Commented = 7,
    DeletedComment = 8,
    AttachedFile = 9,
    DeletedAttachment = 10,
    AddedApprover = 11,
    RemovedApprover = 12,
    ApproverApproved = 13,
    ApproverRejected = 14,
    ApproverRevoked = 15,
    StepNameChanged = 16,
    StepStatusChanged = 17,
    StepResolutionChanged = 18,
    RequestStatusChandged = 19,
    RequestResolutionChanged = 20,
}

export enum RequestMatchingStatus {
    AnyValue = 0,
    Matched = 1,
    NotMatched = 2,
}

export interface RequestOriginDeepLinkAnswer extends BaseAnswer {
    originDeepLink: string;
}

export interface CounterpartyDetails {
    emailAddress: string;
    addressLine1: string | null;
    addressLine2: string | null;
    addressLine3: string | null;
    city: string;
    region: string;
    postalCode: string;
    country: string;
    taxNumber: string | null;
    bankAccountDetails: string;
    accountNumber: string | null;
}

export interface RequestDetails {
    counterparty: CounterpartyDetails;
    possibleDuplicates: PossibleDuplicatesAnswer[];
}

export interface RequestGetRequestDetailsAnswer extends RequestDetails, BaseAnswer {}

export interface RequestReportCollectAnswer extends BaseAnswer {
    CollectionId: Guid;
    AuthorEmail: string;
    FileName: string;
}

export interface RequestReportColumnAnswer {
    Name: string;
    Viewable?: boolean;
    ViewingOrder?: number;
    SortingOrder?: number;
    SortingType?: SortingType;
}

export interface RequestReportColumnsAnswer {
    Name: RequestReportColumnAnswer;
    TemplateId: FilteredRequestReportColumnAnswer<RequestReportTemplateIdAnswer[]>;
    ActiveStepName: FilteredRequestReportColumnAnswer<string>;
    Resolution: FilteredRequestReportColumnAnswer<RequestsRequestResolution[]>;
    ResolutionOrigin: FilteredRequestReportColumnAnswer<RequestsRequestResolutionOrigin[]>;
    ResolutionTime: RequestReportColumnAnswer;
    FraudulentActivity: FilteredRequestReportColumnAnswer<Array<backend.RequestFraudulentActivity | undefined>>;
    CreationDate: FilteredRequestReportColumnAnswer<DateRangeAnswer>;
    ResolutionDate: FilteredRequestReportColumnAnswer<DateRangeAnswer>;
    DueDate: FilteredRequestReportColumnAnswer<DateRangeAnswer>;
    DeliveryDate: FilteredRequestReportColumnAnswer<DateRangeAnswer>;
    PaymentDate: FilteredRequestReportColumnAnswer<DateRangeAnswer>;
    ExpectedDate: FilteredRequestReportColumnAnswer<DateRangeAnswer>;
    DocumentDate: FilteredRequestReportColumnAnswer<DateRangeAnswer>;
    DocumentNumber: RequestReportColumnAnswer;
    Amount: FilteredRequestReportColumnAnswer<RangeAnswer<number | undefined>>;
    NetAmount: FilteredRequestReportColumnAnswer<RangeAnswer<number | undefined>>;
    TaxAmount: FilteredRequestReportColumnAnswer<RangeAnswer<number | undefined>>;
    Currency: FilteredRequestReportColumnAnswer<string[]>;
    ExchangeRate: RequestReportColumnAnswer;
    TaxType: FilteredRequestReportColumnAnswer<string[]>;
    Requesters: FilteredRequestReportColumnAnswer<string[]>;
    ParticipantsApproved: FilteredRequestReportColumnAnswer<string[]>;
    ParticipantsRejected: FilteredRequestReportColumnAnswer<string[]>;
    ParticipantsNonResponded: FilteredRequestReportColumnAnswer<string[]>;
    Attachment: RequestReportColumnAnswer;
    Contact: FilteredRequestReportColumnAnswer<Array<NamedObjectAnswer<string>>>;
    Reference: FilteredRequestReportColumnAnswer<string>;
    Branding: FilteredRequestReportColumnAnswer<Array<NamedObjectAnswer<Guid>>>;
    SentToSupplier: FilteredRequestReportColumnAnswer<boolean | undefined>;
    IsBilled: FilteredRequestReportColumnAnswer<boolean | undefined>;
    IsPaid: FilteredRequestReportColumnAnswer<boolean | undefined>;
    LineItemAccounts: FilteredRequestReportColumnAnswer<Array<NamedObjectAnswer<string>>>;
    QBooksLineItemAccounts: FilteredRequestReportColumnAnswer<Array<NamedObjectAnswer<string>>>;
    LineItemTrackings1: FilteredRequestReportColumnAnswer<Array<NamedObjectAnswer<string>>>;
    LineItemTrackings2: FilteredRequestReportColumnAnswer<Array<NamedObjectAnswer<string>>>;
    AuditReportStatus: FilteredRequestReportColumnAnswer<RequestAuditReportsRequestAuditReportState[]>;
    MatchedBills: FilteredRequestReportColumnAnswer<RequestMatchingStatus>;
    MatchedPurchaseOrders: FilteredRequestReportColumnAnswer<RequestMatchingStatus>;
    PurchaseOrderBalance: FilteredRequestReportColumnAnswer<RangeAnswer<number | undefined>>;
    RetrospectivePurchaseOrder: FilteredRequestReportColumnAnswer<RetrospectivePurchaseOrder>;
    Location: FilteredRequestReportColumnAnswer<Array<NamedObjectAnswer<string>>>;
    LineItemClasses: FilteredRequestReportColumnAnswer<Array<NamedObjectAnswer<string>>>;
    LineItemContacts: FilteredRequestReportColumnAnswer<Array<NamedObjectAnswer<string>>>;
    CustomFields: Array<RequestReportCustomFieldColumnAnswer<Array<NamedObjectAnswer<string>>>>;
    /**
     * @deprecated
     */
    QBooksCustomFields: RequestReportCustomFieldColumnAnswer<string[]>[];
    QBooksPurchaseOrderCustomFields: RequestReportCustomFieldColumnAnswer<string[]>[];
    QBooksSalesInvoiceCustomFields: RequestReportCustomFieldColumnAnswer<string[]>[];
}

export interface RequestReportConfigAnswer {
    Id: Guid;
    Name: string;
    LastRunDate?: DateTimeIsoString | undefined;
    CreatedDate: DateTimeIsoString;
    CompanyId: Guid;
    Columns: RequestReportColumnsAnswer;
}

export interface RequestReportCreateAnswer extends BaseAnswer {
    ReportConfigId: Guid;
}

export interface RequestReportCustomFieldColumnAnswer<T> extends FilteredRequestReportColumnAnswer<T> {
    FieldId: string;
}

export interface RequestReportExecuteAnswer extends BaseAnswer {
    rows: any[][];
}

export interface RequestReportGetAnswer extends BaseAnswer {
    Config: RequestReportConfigAnswer;
}

export interface RequestReportSelectAnswer extends BaseAnswer {
    Configs: RequestReportConfigAnswer[];
}

export interface RequestReportTemplateIdAnswer {
    TemplateId?: Guid | undefined;
    TemplateName: string;
    IntegrationCode: string;
}

export interface RequestSelectQuantityForTemplateResetAnswer {
    Quantity: number;
}

export interface RequestSelectAnswer extends BaseAnswer {
    Requests: RequestAnswer[];
    HasMore: boolean;
}

export interface RequestSelectV2Answer extends BaseAnswer {
    Requests: RequestAnswer[];
    ContinuationToken?: string;
}

export interface RequestsQBooksEditingContext {
    Accounts: IntegrationsQBooksAccount[];
    availableLineItemType: QBooksTemplatesAvailableLineItemType;
    classFieldId?: Guid | undefined;
    classTrackingEnabled: boolean;
    classes: IntegrationsQBooksClass[];
    currencies: ReferenceAnswer[];
    customerFieldId?: Guid | undefined;
    customerTrackingEnabled: boolean;
    customers?: IntegrationsQBooksCustomer[];
    defaultLineAmountType: string;
    defaultPurchaseTermId?: string | null;
    defaultSalesTermId?: string | null;
    departmentFieldId?: Guid | undefined;
    departmentTerminology: string;
    departmentTrackingEnabled: boolean;
    departments: IntegrationsQBooksDepartment[];
    hasTaxes: boolean;
    invoiceCustomer?: IntegrationsQBOCounterparty;
    isAutoTaxesEnabled: boolean;
    isBillableTrackingEnabled: boolean;
    isMultiCurrencyEnabled: boolean;
    isTrackingTaxesEnabled: boolean;
    isUsingSalesTaxEnabled: boolean;
    payee?: IntegrationsQBooksPayee;
    payeeTypes: IntegrationsQBooksPayeeType[];
    paymentAccount?: IntegrationsQBooksPaymentAccount;
    paymentMethodFieldId?: Guid | undefined;
    paymentMethods: IntegrationsQBooksPaymentMethod[];
    paymentTypeFieldId?: string | null;
    paymentTypes: IntegrationsQBooksPaymentType[];
    taxCodes: IntegrationsQBooksTaxCode[];
    taxRates: IntegrationsQBooksTaxRate[];
    termFieldId: Guid | undefined;
    terms: IntegrationsQBooksTermDetails[];
    vendor?: IntegrationsQBOCounterparty;
}

export interface RequestsRequestEditingContext {
    request: RequestAnswer;
    xeroContext: RequestsXeroEditingContext;
    qbooksContext: RequestsQBooksEditingContext;
    airwallexContext: RequestsAirwallexEditingContext;
    netSuiteContext: RequestsNetSuiteEditingContext;
    requesterInstruction: string;
    templateSettings: TemplatesEmailTemplateSettingsList;
    fieldHeaders: FieldAnswer[];
    templateInstantiationVerison: number;
    requiredFieldsIds: Guid[];
    readonlyFieldsIds: Guid[];
    attachmentMaxSize: number;
    attachmentMaxCount: number;
    supplierEmailSettings: SupplierEmailSettings;
    terms?: string;
    documentFields?: TemplateDocumentField[];
}

export enum RequestsRequestOrigin {
    ApprovalMax = 0,
    Xero = 1,
    QBooks = 2,
    ReceiptBank = 3,
    NetSuite = 4,
    Dear = 5,
    PublicApi = 6,
    Email = 7,
}

export enum RequestsRequestResolution {
    'on-approval' = 0,
    rejected = 1,
    approved = 2,
    cancelled = 3,
    'on-review' = 4,
    'on-hold' = 5,
}

export enum RequestsRequestResolutionOrigin {
    ResolvedRegularly = 0,
    EnforcedExternally = 1,
    EnforcedByAdmin = 2,
    ResolvedAutomatically = 3,
    ChangedApprovers = 4,
    ResolvedExternally = 5,
}

export interface RequestsSyncProgressAnswer {
    templateId: Guid;
    templateIntegrationCode: string;
    status: SyncProgressStatus;
    lastSyncDate: string;
}

export interface RequestStepAnswer {
    StepId: Guid;
    Name: string;
    Type: StepType;
    CreatedDate: string;
    ModifiedDate: string;
    State: backend.RequestStepState;
    Resolution: StepResolution;
    ResolutionDate: string;
    DueDate: string;
    RequiredFieldIds: Guid[];
    EditPermissionsRequiredFieldIds: Guid[];
    RequiredFields: FieldAnswer[];
    Participants: RequestStepParticipantAnswer[];
    TemplateParticipants: RequestTemplateParticipantAnswer[];
    Editors: RequestStepEditorAnswer[];
    ApprovalCount: number | null;
}

export interface RequestStepEditorAnswer extends UserAnswer {
    Rules: RuleAnswer[];
}

export interface RequestStepParticipantAnswer extends UserWithDelegateAnswer {
    Decision: backend.RequestStepParticipantDecision;
    DecisionDate: string;
    EnabledAccordingToRule?: boolean | undefined;
    Required: boolean;
    Source: backend.RequestStepParticipantOrigin;
    AddedBy: UserAnswer;
    CanEdit: boolean;
    EditPermissions?: RuleAnswer[];
}

export interface RequestsAirwallexEditingContext {
    paymentPurposes: string[];
    paymentFees: string[];
    currencies: string[];
    lastUsedXeroBankAccounts?: {
        Currency: string;
        XeroBankAccountCode: string;
        XeroBankAccountId: string;
        XeroBankAccountName: string;
    }[];
}

export interface RequestsAmaxPayEditingContext {
    bankAccounts: AmaxPayOpenBankingBankAccount[];
}

export interface RequestsXeroEditingContext {
    counterparty: IntegrationsXeroCounterparty;
    trackingCategories: IntegrationsXeroTracking[];
    emptyTaxCode: IntegrationsXeroTaxCode;
    defaultLineAmountType: domain.LineAmountType;
    brandingThemes: IntegrationsXeroBrandingTheme[];
    accounts: IntegrationsXeroAccount[];
    taxCodes: IntegrationsXeroTaxCode[];
    currencies: IntegrationsCurrency[];
    addresses: IntegrationsAddress[];
    cisSettings?: IntegrationsXeroCisSettings;
    defaultPaymentTerms?: IntegrationsXeroPaymentTerms;
}

export interface RequestTemplateParticipantAnswer extends UserAnswer {
    Required: boolean;
    Rules: RuleAnswer[];
    IsBackup: boolean;
}

export enum ResponseToInvitation {
    Accepted = 'Accepted',
    Declined = 'Declined',
}

export enum RetrospectivePurchaseOrder {
    AnyValue = 0,
    IsRetrospective = 1,
    IsNotRetrospective = 2,
}

export interface RuleAnswer {
    Conditions: RuleConditionAnswer[];
}

export interface AutoApprovalRuleAnswer extends RuleAnswer {
    Name: string;
}

export interface RuleConditionAnswer {
    FieldId: Guid;
    Name: string;
    SystemPurpose: FieldSystemPurpose;
    ConditionType: FieldType;
    AmountType: AmountConditionType;
    ExactConstraint: ExactConstraintAnswer[] | null;
    RangeConstraintLess?: number | undefined;
    RangeConstraintGreaterEquals?: number | undefined;
    AllowCreation?: boolean | undefined;
    AllowEditing?: boolean | undefined;
    FieldValuesFilterType?: number | undefined;
    ExactBooleanConstraint?: boolean;
    NetSuiteField?: { Type: string };
}

export interface RulesExactConstraint {
    id: string;
    value: string;
}

export interface SearchCompanySearchContext {
    CompanyId: Guid;
    CompanyName: string;
    CompanyIntegrationType: string;
    CompanyIntegrationEnabled?: boolean | undefined;
    Templates: SearchTemplateInfo[];
    SearchFields: CompaniesFieldHeader[];
}

export interface SearchSearchContextAnswer extends BaseAnswer {
    CompanySearchContexts: SearchCompanySearchContext[];
}

export interface SearchTemplateInfo {
    TemplatId: Guid;
    IntegrationCode: string;
    TemplateName: string;
}

export enum SortingType {
    Ascending = 0,
    Descending = 1,
}

export interface StepChangeData {
    StepId: Guid;
    StepName: string;
    Name: string;
    Deadline: string;
    DeadlineWasDeleted?: boolean | undefined;
    AddedApprovers: UserWithDelegateAnswer[];
    RemovedApprovers: UserWithDelegateAnswer[];
}

export interface ReviewStepChangeData {
    StepId: Guid;
    AddedReviewers: UserAnswer[];
    RemovedReviewers: UserAnswer[];
}

export enum StepResolution {
    NoResolution = 0,
    Rejected = 1,
    Approved = 2,
}

export enum StepType {
    DecisionStep = 0,
    AnyOfDecisionStep = 1,
}

export enum SyncProgressStatus {
    Unknown = 'Unknown',
    Pending = 'Pending',
    InProgress = 'InProgress',
    Failed = 'Failed',
    Succeeded = 'Succeeded',
}

export interface TemplateAnswer {
    TemplateId: Guid;
    CompanyId: Guid;
    TemplateName: string | null;
    IntegrationCode: string;
    CreateDate: string;
    ModifiedDate: string;
    HasOutdatedRequests?: boolean | undefined;
    Enabled: boolean;
    Version: number;
    Submitters: TemplateUserAnswer[];
    SubmitterRuleOrders: Guid[] | null;
    ExternalSubmitter: UserAnswer | null;
    EmailExternalSubmitter: UserAnswer | null;
    OcrEmailAddress?: string;
    OcrRequestInitialStatus?: domain.RequestStatusV2;
    ReceiptBankExternalSubmitter?: UserAnswer;
    Steps: TemplateStepAnswer[];
    RequiredFieldIds: Guid[];
    IsRequestCreationAvailable?: boolean;
    ReviewStep: TemplateReviewStepAnswer | null;
    Author: UserAnswer | null;
    Payers: UserAnswer[] | null;
    AutoApprovalRules: AutoApprovalRuleAnswer[];
    DocumentFields: TemplateDocumentField[];
    IsActual: boolean;
    Comment: string | null;
}

export interface TemplateCreateAnswer extends BaseAnswer {
    TemplateId: Guid;
    Template: TemplateAnswer;
}

export interface TemplateDeleteAnswer extends BaseAnswer {}

export interface TemplateEditAnswer extends BaseAnswer {
    Template: TemplateAnswer;
}

export interface TemplatesAutoDecisionPolicySettings {
    autoDecisionPolicy: AutoDecisionPolicyType;
}

export interface TemplateSelectAnswer extends BaseAnswer {
    Templates: TemplateAnswer[];
}

export interface SelectWorkflowVersionsAnswer extends BaseAnswer {
    Templates: WorkflowVersionAnswer[];
    HasMore: boolean;
}

export interface WorkflowVersionAnswer {
    TemplateVersionId: Guid;
    Version: number;
    IsActual: boolean;
    CreateDate: string;
    Author: UserAnswer;
    Comment: string;
}

export interface TemplatesEmailTemplateSettings {
    templateName: string;
    subject: string;
    body: string;
}

export interface TemplatesEmailTemplateSettingsList {
    templates: TemplatesEmailTemplateSettings[];
}

export interface TemplatesRequesterInstructionSettings {
    instruction: string;
}

export interface TemplatesTemplateId {
    templateId: Guid;
    companyId: Guid;
}

export interface SupplierEmailSettings {
    body: string | null;
    cc: string[];
    subject: string | null;
    state: domain.TemplateSettingsEmailToSupplierState | null;
}

export interface TemplatesTemplateSettings {
    templateId: Guid;
    companyId: Guid;
    lockDatePolicySettings?: AccountingTemplatesLockDatePolicySettings;
    autoDecisionPolicySettings?: TemplatesAutoDecisionPolicySettings;
    approvalDisregardDetectionSettings?: domain.ApprovalDisregardDetectionSettings;
    postApprovalChangeDetectionSettings?: IPostApprovalChangeDetectionSettings<domain.TemplateSettingsPostApprovalChangeDetectionTarget>;
    matchingSettings?: AccountingCompaniesBillMatchingSettings | AccountingCompaniesPurchaseOrderMatchingSettings;
    requesterInstructionSettings?: TemplatesRequesterInstructionSettings;
    lineItemSettings?: QBooksTemplatesLineItemSettings;
    approveAllStepsEnabled: boolean;
    appUrlOverridingEnabled: boolean;
    netSuiteApprovalDisregardDetectionSettings?: domain.ApprovalDisregardDetectionSettings;
    netSuitePostApprovalChangeDetectionSettings?: IPostApprovalChangeDetectionSettings<domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget>;
    supplierEmailSettings: SupplierEmailSettings;
    qBooksApprovalDisregardDetectionSettings?: Omit<domain.ApprovalDisregardDetectionSettings, 'notifyAdmins'>;
    qBooksPostApprovalChangeDetectionSettings?: IPostApprovalChangeDetectionSettings<domain.TemplateSettingsQBooksPostApprovalChangeDetectionTarget>;
    dearApprovalDisregardDetectionSettings?: domain.ApprovalDisregardDetectionSettings;
    dearPostApprovalChangeDetectionSettings?: IPostApprovalChangeDetectionSettings<domain.TemplateSettingsDearPostApprovalChangeDetectionTarget>;
    isSupplierBankAccountRequired?: boolean;
    netSuiteAvailableLineItemType?: domain.NetSuiteAvailableLineItemType;
    isGrnEnabled?: boolean;
    isPriceCheckerEnabled?: boolean;
    postingPreferencesType?: domain.PostingPreferencesType;
    useRejectedPrefix?: boolean;
    terms?: string;
    sendRejectedNoteToDear?: boolean;
    historyEventsPushingSettings?: { historyEventsPushingType: domain.HistoryPushingType };
    pushApprovalMaxURLToNetSuite?: boolean;
    ocrEmailAddress?: string;
    ocrRequestInitialStatus?: domain.RequestStatusV2;
}

export type TemplatesTemplateSettingsTransfer = Omit<TemplatesTemplateSettings, 'supplierEmailSettings'> & {
    supplierEmailSettings?: SupplierEmailSettings;
};

export interface DeadlineRuleAnswer {
    Calculator: domain.DeadlineRuleType | null;
    Duration: string;
}

export interface TemplateStepAnswer {
    StepId: Guid | null;
    Name: string;
    Type: StepType;
    CreateDate: string;
    ModifiedDate: string;
    DefaultDuration: string;
    DeadlineRule: DeadlineRuleAnswer | null;
    RuleOrder: Guid[];
    RequiredFieldIds: Guid[];
    ReadonlyFieldIds: Guid[];
    EditPermissionsRequiredFieldIds: Guid[];
    Participants: TemplateUserAnswer[];
    Editors: TemplateUserAnswer[];
    ApprovalCount: number | null;
}

export interface TemplateReviewStepAnswer {
    Reviewers: TemplateUserAnswer[];
    RequiredFieldIds: Guid[];
    ReadonlyFieldIds: Guid[];
    RuleOrder: Guid[];
    DeadlineRule: DeadlineRuleAnswer | null;
}

export interface TemplateUserAnswer extends UserAnswer {
    Rules: RuleAnswer[];
    IsBackup?: boolean;
    Required?: boolean;
    EditPermissions: RuleAnswer[];
}

export interface TenantInfo {
    tenantId: string;
    tenantName: string;
    connectedOrgName: string;
    connectedIntegrationId: string;
    isConnected: boolean;
    isMember: boolean;
    isManager: boolean;
    owner: UserAnswer;
}

export interface UserAnswer {
    UserId: Guid;
    UserName: string;
    FirstName: string;
    LastName: string;
    UserEmail: string;
    AvatarHttps: string;
    LoggedIn: boolean;
    IsInvited: boolean;
    IsTFAEnabled?: boolean;
}

export interface MentionedUserAnswer {
    AvatarUri: string;
    Email: string;
    FirstName: string;
    LastName: string;
    Phone: string;
    UserProfileId: Guid;
}

export interface UserWithDelegateAnswer extends UserAnswer {
    DelegateFor: UserAnswer;
}

export interface XeroClientCachesCacheType extends ArdalisSmartEnumSmartEnum {}

export interface XeroDomainAddressInfo {
    Address: string;
    City: string;
    Region: string;
    PostalCode: string;
    Country: string;
    AttentionTo: string;
}

export interface XeroDomainPhone {
    CountryCode: string;
    AreaCode: string;
    Number: string;
}

export interface XeroDomainTrackingCategory {
    Name: string;
    Option: string;
}

export interface IPostApprovalChangeDetectionSettings<T> {
    targets: T[];
    notifyAdmins: boolean;
}

export interface ReferenceAnswer {
    Id: string;
    Name: string;
}

export interface NetSuiteEmployeeAnswer extends ReferenceAnswer {
    DefaultCurrencyId: string;
    CurrencyIds: string[];
}

export interface RequestsNetSuiteEditingContext {
    accounts: ReferenceAnswer[];
    expenseCategories: Array<
        ReferenceAnswer & {
            AccountId: string | null;
            Rates: {
                CurrencyInternalId: string;
                DefaultRate: number | null;
            }[];
        }
    >;
    departments: ReferenceAnswer[];
    classes: ReferenceAnswer[];
    locations: ReferenceAnswer[];
    items: Array<
        ReferenceAnswer & {
            VendorName: string | null;
            Units: NetSuiteUnitAnswer[] | null;
            PurchasePrices: number | null;
            PurchaseDescription: string | null;
        }
    >;
    currencies: Array<
        ReferenceAnswer & {
            ISOCode: string | null;
            RateToCompanyCurrency: number | null;
        }
    >;
    terms: Array<
        ReferenceAnswer & {
            DaysUntilExpiry: number;
            DaysUntilNetDue: number;
            DiscountPercent: number;
        }
    >;
    accountingPeriods: ReferenceAnswer[];
    customers: ReferenceAnswer[];
    salesTaxItems: Array<
        ReferenceAnswer & {
            Rate: number | null;
        }
    >;
    employees: NetSuiteEmployeeAnswer[];
    availableLineItemType: domain.NetSuiteAvailableLineItemType;
    customFields: FieldAnswer[];
    documentFields?: TemplateDocumentField[];
}

export interface NetSuiteCustomFieldWorkflowData {
    WorkflowId: string;
    IsActive: boolean;
    IsUsed: boolean;
    IsEditable: boolean;
    IsMandatory: boolean;
}

export interface NetSuiteCompanyCustomField {
    FieldId: string;
    Name?: string;
    FieldType: backend.FieldType;
    ExactValues: [];
    NetSuiteField: NetSuiteField;
    Workflows: NetSuiteCustomFieldWorkflowData[];
    IsArchived: boolean;
    ReferenceRecordFieldSystemPurpose: number | null;
}

export interface SelectNetSuiteCustomCompanyFieldsAnswer {
    CompanyId: string;
    Fields: NetSuiteCompanyCustomField[];
}

export interface NetSuiteApiFilterAnswer {
    ApiFilter: {
        IsExpandAccount: boolean;
        TaxCodeListsInclude: string;
    };
}

export interface NetSuiteBillPaymentBillsFilterTransferData {
    reference?: string;
    amountDueFrom?: string;
    amountDueTo?: string;
    originalAmountFrom?: string;
    originalAmountTo?: string;
    dateFrom?: string;
    dateTo?: string;
    dueDateFrom?: string;
    dueDateTo?: string;
    sortColumn?: string;
    sortOrder?: string;
}

export interface NetSuiteBillAvailableForBillPayment {
    currencyName: string | null;
    discountAvailable: number | null;
    discountDate: string | null;
    transactionId: string | null;
    amountDue: number | null;
    originalAmount: number | null;
    dateDue: string | null;
    type: string | null;
    reference: string | null;
}

export interface BillRequestsForNetSuiteBillPaymentAnswer extends BaseAnswer {
    items: NetSuiteBillAvailableForBillPayment[];
}

export interface XeroMatchingInfoDocument {
    requestId: Guid;
    isMatched: boolean;
    allocatedAmount: number;
    name: string;
    friendlyName: string;
    status: string;
    totalAmount: number;
    currency: string;
    date: string;
    requester: UserAnswer | null;
    reference: string;
    matchedBy: UserAnswer;
}

export interface XeroPOMatchingInfoDocument extends XeroMatchingInfoDocument {
    resolution?: string;
    statusV2?: domain.RequestStatusV2;
}

export interface XeroBillMatchingInfoDocument extends XeroMatchingInfoDocument {
    isBilled?: boolean;
}

export interface XeroBillMatchingInfo {
    currency: string;
    totalAllocatedAmount: number;
    totalAmount: number;
    documents: XeroBillMatchingInfoDocument[];
}

export interface XeroPOMatchingInfo {
    currency: string;
    totalAllocatedAmount: number;
    totalAmount: number;
    documents: XeroPOMatchingInfoDocument[];
}

export interface XeroMatchingContext {
    currency: string;
    totalAllocatedAmount: number;
    totalAmount: number;
    documents: XeroBillMatchingInfoDocument[];
}

export interface XeroMatchingAllocations {
    allocations: XeroPOMatchingInfoDocument[];
}

export interface QBOMatchingInfo {
    Id: Guid;
    Date: string;
    FriendlyName: string;
    TotalAmount: number;
    Currency: string;
    AccountBasedLine: IntegrationsQBooksAccountBasedLineAnswer;
    ItemBasedLine: IntegrationsQBooksItemBasedLineAnswer;
    RemainingBalance: number;
}

export interface WatchersAnswer {
    AddedBy: UserAnswer;
    CreatedDate: string;
    Watcher: UserAnswer;
}

export interface LoginAnswer extends BaseAnswer {
    RequiresTFA: boolean;
    TFAStateId: string;
    IsTFAAlternativeEmailEnabled: boolean | null;
    IsTFABackupCodesEnabled: boolean | null;
}

export interface TemplateDocumentField {
    name: string;
    state: domain.DocumentFieldState;
    workflowVersionId: string;
    purpose: string;
    defaultOrder: number;
    defaultState: domain.DocumentFieldState;
    availableStates: domain.DocumentFieldState[];
}

export interface BackupCode {
    Code: string;
    IsUsed: boolean;
}
