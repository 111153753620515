import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Badge = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 20px;
    margin: 0 5px;
    background-color: #000;
    border-radius: 5px;

    ${font(10, '#fff', 'bold')}
`;
