import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.UsersPage', {
    title: 'Users | {companyName}',
});

export const messagesActions = defineMessages('app.UsersPage.actions', {
    usersInvitedNotification: '{count, plural, one {User invited} other {Users invited}}',
    usersAddedNotification: '{count, plural, one {User added} other {Users added}}',
    theUserIsDelegateHimselfNotification: '{name} cannot be set a substitute because he/she is a substitute themself.',
});

export const messagesSagas = defineMessages('app.UsersPage.sagas', {
    changesSavedNotificationMessage: 'Changes saved',
    delegateSetNotificationMessage: 'Substitute set up',
    delegateClearNotificationMessage: 'Substitute removed',
    roleChangedNotificationMessage: 'User role has been changed',
});
