import { domain } from 'modules/data';
import { useMemo } from 'react';
import { useGetGoogleOAuthUrl, useGetMicrosoftOAuthUrl, useGetQBooksOAuthUrl, useGetXeroOAuthUrl } from 'shared/data';
import { getUrl, Path } from 'urlBuilder';

import { ReactComponent as GoogleSsoImage } from './assets/google-sso.svg';
import { ReactComponent as IntuitSsoImage } from './assets/intuit-sso.svg';
import { ReactComponent as MicrosoftSsoImage } from './assets/microsoft-sso.svg';
import { ReactComponent as XeroSsoImage } from './assets/xero-sso.svg';
import { redirectToSso, sendEvents } from './SsoPanel.helpers';
import { SsoPanelProps } from './SsoPanel.types';

export const useSsoAuth = (props: SsoPanelProps) => {
    const {
        variant,
        app,
        afterLoginRedirectUrl,
        afterSignUpRedirectUrl,
        shouldReturnToCurrentPage = false,
        allowedEmail,
    } = props;

    const ssoRequestParams = {
        redirectUrl: getUrl(Path.oauth2Redirect),
        ssoStartingPoint: domain.SsoStartingPoint.ApprovalMax,
        uiState: encodeURIComponent(
            JSON.stringify({
                app,
                afterLoginRedirectUrl: shouldReturnToCurrentPage ? window.location.href : afterLoginRedirectUrl,
                afterSignUpRedirectUrl,
                startingPoint: domain.SsoStartingPoint.ApprovalMax,
            })
        ),
    };

    const { refetch: refetchXeroOAuthUrl, isFetching: isFetchingXeroOAuth } = useGetXeroOAuthUrl(ssoRequestParams, {
        enabled: false,
    });
    const { refetch: refetchQBooksOAuthUrl, isFetching: isFetchingQBooksOAuth } = useGetQBooksOAuthUrl(
        ssoRequestParams,
        { enabled: false }
    );
    const { refetch: refetchGoogleOAuthUrl, isFetching: isFetchingGoogleOAuth } = useGetGoogleOAuthUrl(
        ssoRequestParams,
        { enabled: false }
    );
    const { refetch: refetchMicrosoftOAuthUrl, isFetching: isFetchingMicrosoftOAuth } = useGetMicrosoftOAuthUrl(
        ssoRequestParams,
        { enabled: false }
    );

    const progress = isFetchingXeroOAuth || isFetchingQBooksOAuth || isFetchingGoogleOAuth || isFetchingMicrosoftOAuth;

    const onLogin = async (oauthProvider: domain.OAuthProvider) => {
        sendEvents(oauthProvider, variant);

        switch (oauthProvider) {
            case domain.OAuthProvider.Xero: {
                const { data } = await refetchXeroOAuthUrl();

                redirectToSso(oauthProvider, data?.data.xeroOAuthUrl, allowedEmail);
                break;
            }

            case domain.OAuthProvider.QBooks: {
                const { data } = await refetchQBooksOAuthUrl();

                redirectToSso(oauthProvider, data?.data.qBooksOAuthUrl, allowedEmail);
                break;
            }

            case domain.OAuthProvider.Google: {
                const { data } = await refetchGoogleOAuthUrl();

                redirectToSso(oauthProvider, data?.data.googleOAuthUrl, allowedEmail);
                break;
            }

            case domain.OAuthProvider.Microsoft: {
                const { data } = await refetchMicrosoftOAuthUrl();

                redirectToSso(oauthProvider, data?.data.microsoftOAuthUrl, allowedEmail);
                break;
            }
        }
    };

    return {
        progress,
        onLogin,
    };
};

export const useSsoItems = () => {
    return useMemo(
        () => [
            {
                provider: domain.OAuthProvider.Xero,
                Image: XeroSsoImage,
            },
            {
                provider: domain.OAuthProvider.QBooks,
                Image: IntuitSsoImage,
            },
            {
                provider: domain.OAuthProvider.Google,
                Image: GoogleSsoImage,
            },
            {
                provider: domain.OAuthProvider.Microsoft,
                Image: MicrosoftSsoImage,
            },
        ],
        []
    );
};
