import { domain } from 'modules/data';

import { messages } from './AllowEditingCell.messages';
import { ConditionTypeValue } from './AllowEditingCell.types';

export const getConditionItems = (fieldName: string): { id: ConditionTypeValue; name: string }[] => {
    return [
        {
            id: ConditionTypeValue.All,
            name: messages.anyField({ fieldName }),
        },
        {
            id: ConditionTypeValue.ExactValues,
            name: messages.canChangeFieldTo({ fieldName }),
        },
        {
            id: ConditionTypeValue.NegativeExactValues,
            name: messages.cannotChangeFieldTo({ fieldName }),
        },
    ];
};

export const conditionTypeToConditionTypeValue = (condition: domain.MatrixCondition): ConditionTypeValue => {
    switch (condition.conditionType) {
        case domain.ConditionType.ExactValuesCondition:
            return ConditionTypeValue.ExactValues;

        case domain.ConditionType.NegativeExactValuesCondition:
            return ConditionTypeValue.NegativeExactValues;

        default:
            return ConditionTypeValue.All;
    }
};
