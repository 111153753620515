import { miscHelpers } from '@approvalmax/utils';
import { ForwardedRef, forwardRef, ReactElement, RefAttributes } from 'react';

import { Root } from './Box.styles';
import { BoxProps } from './Box.types';

/**
 * The Box component serves as a wrapper component for the most popular styles that apply to wrappers.
 */
export const Box = forwardRef(
    <Element extends HTMLElement = HTMLDivElement>(props: BoxProps<Element>, ref: ForwardedRef<Element>) => {
        const {
            spacing,
            shadow,
            radius,
            color,
            width,
            height,
            maxWidth,
            minHeight,
            maxHeight,
            bordered,
            borderInside,
            borderColor,
            overflowX,
            overflowY,
            children,
            ...restProps
        } = props;

        return (
            <Root
                {...restProps}
                $bordered={bordered}
                $borderInside={borderInside}
                $borderColor={borderColor}
                $shadow={shadow}
                $color={color}
                $radius={radius}
                $spacing={miscHelpers.spacingPropToCss(spacing)}
                $width={width}
                $height={height}
                $maxWidth={maxWidth}
                $minHeight={minHeight}
                $maxHeight={maxHeight}
                $overflowX={overflowX}
                $overflowY={overflowY}
                ref={ref}
            >
                {children}
            </Root>
        );
    }
) as (<Element extends HTMLElement = HTMLDivElement>(
    props: BoxProps<Element> & RefAttributes<Element>
) => ReactElement) & {
    displayName?: string;
};

Box.displayName = 'Box';

export default Box;
