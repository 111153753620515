import { ErrorCode } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { produce } from 'immer';
import { domain } from 'modules/data';

import { Action, COMPLETE_INTEGRATION_AUTH_FAILURE, SHOW_COMPANY_MISMATCH_POPUP } from '../../../actions';

export const COMPANY_MISMATCH_POPUP = 'COMPANY_MISMATCH_POPUP';

export interface CompanyMismatchPopupData {
    id: typeof COMPANY_MISMATCH_POPUP;
    integrationType: domain.IntegrationType;
}

export default produce((draft: CompanyMismatchPopupData | null, action: Action): CompanyMismatchPopupData | null => {
    switch (action.type) {
        case COMPLETE_INTEGRATION_AUTH_FAILURE: {
            const errorCode = errorHelpers.getErrorCode(action.error);

            if (errorCode === ErrorCode.E5016_BOUND_COMPANY_ABOUT_TO_CHANGE) {
                return {
                    id: COMPANY_MISMATCH_POPUP,
                    integrationType: action.payload.request.integrationType,
                };
            }

            return draft;
        }

        case SHOW_COMPANY_MISMATCH_POPUP:
            return {
                id: COMPANY_MISMATCH_POPUP,
                integrationType: action.payload.integrationType,
            };

        default:
            return draft;
    }
}, null);
