import { createContext, useContext } from 'react';

import type { StepperContextParams } from './Stepper.types';

export const StepperContext = createContext<Required<StepperContextParams>>({} as any);

StepperContext.displayName = 'StepperContext';

export const useStepperContext = () => {
    const context = useContext(StepperContext);

    if (!context) {
        throw new Error('The component must be a child of Stepper component');
    }

    return context;
};
