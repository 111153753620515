import { errorHelpers } from '@approvalmax/utils';
import { all, call, delay } from 'redux-saga/effects';
import { chameleonSagas } from 'services/chameleon/sagas';

import allModules from '../helpers/allModules';
import allPagesSlices from '../helpers/allPagesSlices';

const modulesSagas = allModules.reduce((acc, module) => {
    return module.sagas ? [...acc, ...module.sagas] : acc;
}, []);

const pagesSagas = allPagesSlices.reduce((acc, page) => {
    return page.sagas ? [...acc, ...page.sagas] : acc;
}, []);

function* rootSaga() {
    while (true) {
        try {
            yield all([...modulesSagas, ...pagesSagas, chameleonSagas].map((s) => call(s)));
        } catch (e) {
            errorHelpers.captureException(e);
            yield delay(1000);
        }
    }
}

export default rootSaga;
