import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UseGetCompanyCurrenciesRequestParams, UseGetCompanyCurrenciesResponse } from './useGetCompanyCurrencies.types';

export const useGetCompanyCurrencies = (
    pathParams: UseGetCompanyCurrenciesRequestParams,
    queryOptions?: QueryOptions<UseGetCompanyCurrenciesResponse>
) => {
    return useGetRequest<UseGetCompanyCurrenciesResponse>(companiesApiPaths.currencies, {
        pathParams,
        queryOptions,
        apiVersion: 'v2',
    });
};
