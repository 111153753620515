import { progress } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { ActionKind, ActionMeta, AnyAction } from 'modules/react-redux';
import { call, takeEvery } from 'redux-saga/effects';

export default function* () {
    yield takeEvery(
        (action: AnyAction) => {
            return Boolean(action.meta);
        },
        function* (action: AnyAction): any {
            try {
                const meta: ActionMeta = action.meta;

                if (meta.kind === ActionKind.AsyncRequest && meta.showAsyncLoadingBar !== false) {
                    yield call([progress, progress.inc]);
                } else if (
                    (meta.kind === ActionKind.AsyncResponse || meta.kind === ActionKind.AsyncFailure) &&
                    meta.showAsyncLoadingBar !== false
                ) {
                    yield call([progress, progress.dec]);
                }
            } catch (e) {
                errorHelpers.captureException(e);
            }
        }
    );
}
