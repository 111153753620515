import { FC, memo } from 'react';

import { Label, Root } from './Badge.styles';
import { BadgeProps } from './Badge.types';

/**
 * Small numerical value or status descriptor for UI elements.
 */
const Badge: FC<BadgeProps> = memo((props) => {
    const { label, size = 16, color = 'green100', placement, as, children, ...restProps } = props;

    return (
        <Root as={as} {...restProps}>
            {children}

            <Label $size={size} $color={color} $placement={placement}>
                {label}
            </Label>
        </Root>
    );
});

export default Badge;
