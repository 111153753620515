import { domain } from 'modules/data';

import { ExpandedCompany } from './types/Company';
import { ExpandedRequest } from './types/Request';

export const getStandaloneCommands = (params: {
    request: domain.StandaloneRequest;
    company: ExpandedCompany;
    hasCreatableTemplate: boolean;
    myDecisions: ExpandedRequest['myDecisions'];
    flags: ExpandedRequest['flags'];
}): ExpandedRequest['commands'] => {
    const { request, company, hasCreatableTemplate, myDecisions, flags } = params;

    const { isApprover, isActiveApprover, status, isAuthor } = flags;
    const isOnHold = request.statusV2 === domain.RequestStatusV2.OnHold;
    const canForceDecision =
        company.flags.isManager &&
        (request.statusV2 === domain.RequestStatusV2.OnApproval ||
            isOnHold ||
            (request.statusV2 === domain.RequestStatusV2.Rejected &&
                request.origin === domain.RequestOrigin.ApprovalMax));
    const isMyDraft = isAuthor && status.isDraft;
    const isManager = company.flags.isManager;

    return {
        editPrimary: {
            hidden: true,
            disabled: true,
        },
        completeReview: {
            hidden: true,
            disabled: true,
        },
        approve: {
            hidden: isOnHold ? !(isManager || isApprover) : !isActiveApprover,
            disabled: isOnHold,
        },
        reject: {
            hidden: isOnHold ? !(isManager || isApprover) : !isActiveApprover,
            disabled: isOnHold,
        },
        forceApprove: {
            hidden: !canForceDecision,
            disabled: false,
        },
        forceReview: {
            hidden: true,
            disabled: true,
        },
        forceReject: {
            hidden: !canForceDecision,
            disabled: request.statusV2 === domain.RequestStatusV2.Rejected,
        },
        revoke: {
            hidden: status.isClosed || myDecisions.length === 0 || isOnHold,
            disabled: false,
        },
        submit: {
            hidden: true,
            disabled: false,
        },
        startOver: {
            hidden: true,
            disabled: false,
        },
        editSecondary: {
            hidden: status.isClosed || !isAuthor || isOnHold,
            disabled: false,
        },
        delete: {
            hidden: !isMyDraft || isOnHold,
            disabled: false,
        },
        cancel: {
            hidden: !(isAuthor && status.isOpen) || isOnHold,
            disabled: false,
        },
        clone: {
            hidden: !hasCreatableTemplate,
            disabled: false,
        },
        clonePOToBill: {
            hidden: true,
            disabled: true,
        },
        cloneQuoteToSalesInvoice: {
            hidden: true,
            disabled: true,
        },
        reverse: {
            hidden: true,
            disabled: true,
        },
        returnToReview: {
            hidden: true,
            disabled: true,
        },
    };
};
