import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.twoFa.TwoFaDisablingPopup.ScreenBackupCode', {
    description: 'Enter the backup code to confirm your action.',
    codeFieldTitle: 'Enter the backup code',
    wrongCodeErrorText: 'Wrong code',
    userCodePlaceholder: 'Enter the backup code...',
    confirmButton: 'Confirm',
    anotherMethodButton: 'Use another authentication method',
    trustThisDevice: 'Trust this device',
});
