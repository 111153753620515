import { Alert, Box, Button, Flex, Grid, List, TextField } from '@approvalmax/ui/src/components';
import { stringHelpers } from '@approvalmax/utils';
import take from 'lodash/take';
import { FC, memo, useCallback, useState } from 'react';
import { useKey } from 'react-use';

import { messages } from './AddUsersList.messages';
import { InputBox } from './AddUsersList.styles';
import { AddUsersListProps } from './AddUsersList.types';
import { EmailListItem } from './components/EmailListItem/EmailListItem';

export const AddUsersList: FC<AddUsersListProps> = memo((props) => {
    const { items, readOnlyItems, withStickyInput, noRoles, onAddEmails, onRoleChange, onDeleteEmail } = props;

    const [inputText, setInputText] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [invalidEmails, setInvalidEmail] = useState<string[]>([]);

    const addToList = useCallback(() => {
        const emails = inputText.split(/[\s,;]+/).filter((x) => Boolean(x));

        let valid: string[] = [];
        let invalid: string[] = [];

        emails.forEach((text) => {
            const email = stringHelpers.parseEmail(text);

            if (email) {
                if (!valid.includes(email)) {
                    valid.push(email);
                }
            } else if (!invalid.includes(text)) {
                invalid.push(text);
            }
        });

        onAddEmails(valid);
        setInvalidEmail(invalid);

        if (invalid.length > 0) {
            setShowAlert(true);
        }

        setInputText('');
    }, [inputText, onAddEmails]);

    useKey('Enter', addToList, undefined, [addToList]);

    const onCloseAlert = useCallback(() => {
        setShowAlert(false);
    }, []);

    return (
        <Grid gap={16} className='fs-mask'>
            <InputBox $sticky={withStickyInput} gridTemplateColumns='auto max-content' gap={8} width='100%'>
                <TextField
                    size='large'
                    value={inputText}
                    onChange={setInputText}
                    placeholder={messages.inputPlaceholder}
                />

                <Button color='blue10' size='large' onClick={addToList}>
                    {messages.addButtonText}
                </Button>
            </InputBox>

            {invalidEmails.length > 0 && showAlert && (
                <Flex size={24}>
                    <Alert color='midnight20' invalid size='small' onClose={onCloseAlert} closable open={showAlert}>
                        {messages.invalidEmailsDescription}

                        {take(invalidEmails, 2).map((email) => {
                            return <div key={email}>{email}</div>;
                        })}

                        <div>
                            {invalidEmails.length > 2 &&
                                messages.invalidEmailsText({ count: invalidEmails.length - 2 })}
                        </div>
                    </Alert>
                </Flex>
            )}

            {((readOnlyItems && readOnlyItems.length > 0) || items.length > 0) && (
                <Flex size={24}>
                    <Box bordered radius='small' height='100%' spacing='4'>
                        <List>
                            {(readOnlyItems || []).map(({ email, role, disabled }) => {
                                return (
                                    <EmailListItem
                                        key={email}
                                        email={email}
                                        role={role}
                                        disabled={disabled}
                                        noRole={noRoles}
                                    />
                                );
                            })}

                            {items.map(({ email, role, disabled }) => {
                                return (
                                    <EmailListItem
                                        key={email}
                                        email={email}
                                        role={role}
                                        disabled={disabled}
                                        noRole={noRoles}
                                        onRoleChange={(role) => onRoleChange?.(role, email)}
                                        deleteEmail={onDeleteEmail}
                                    />
                                );
                            })}
                        </List>
                    </Box>
                </Flex>
            )}
        </Grid>
    );
});

AddUsersList.displayName = 'AddUsersList';
