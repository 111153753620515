import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { ReactNode, useMemo } from 'react';

export const usePromotionListItem = (
    company: selectors.types.ExpandedCompany,
    meta: { code: domain.IntegrationCode; available?: boolean; icon?: ReactNode }[]
) => {
    const isPromotionOfAdvancedFeatures =
        company.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.AwaitingActivation;

    return useMemo(
        () => (isPromotionOfAdvancedFeatures ? meta.filter(({ available }) => available ?? true) : []),
        [isPromotionOfAdvancedFeatures, meta]
    );
};
