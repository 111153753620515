import { ExtractComponentProp } from '@approvalmax/types';
import { Button, Collapse, Tag, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback, useState } from 'react';

import { messages } from './SectionFeaturePromotion.messages';
import { SectionFeaturePromotionProps } from './SectionFeaturePromotion.types';

export const SectionFeaturePromotion: FC<SectionFeaturePromotionProps> = memo((props) => {
    const { title, children, onTryIt } = props;

    const [collapsed, setCollapsed] = useState<boolean | undefined>(true);

    const handleTryIt = useCallback<ExtractComponentProp<typeof Button, 'onClick'>>(
        (e) => {
            e.stopPropagation();
            onTryIt();
        },
        [onTryIt]
    );

    return (
        <Collapse
            name={title}
            collapsed={collapsed}
            onCollapse={setCollapsed}
            headerInRow
            bordered
            radius='small'
            headerContent={
                collapsed ? (
                    <>
                        <Text font='label' fontSize='small' color='midnight70'>
                            {messages.label}
                        </Text>

                        <Button size='xsmall' color='mint80' onClick={handleTryIt}>
                            {messages.tryIt}
                        </Button>
                    </>
                ) : (
                    <Tag>{messages.label}</Tag>
                )
            }
        >
            {children}
        </Collapse>
    );
});

SectionFeaturePromotion.displayName = 'SectionFeaturePromotion';
