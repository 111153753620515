import { ConditionWrapperProps } from '@approvalmax/ui/src/components/ConditionWrapper/ConditionWrapper.types';
import { FC, memo } from 'react';

const ConditionWrapper: FC<ConditionWrapperProps> = memo((props) => {
    const { condition, wrapperIf, wrapperElse, children } = props;

    if (!children) return null;

    if (condition) {
        return wrapperIf(children);
    }

    if (wrapperElse) {
        return wrapperElse(children);
    }

    return <>{children}</>;
});

export default ConditionWrapper;
