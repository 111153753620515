import { domain } from 'modules/data';

export const promoTemplateSettings = {
    billMatchingSettings: {
        allowApprovalOfNotMatchedBills: domain.CompanyMatchingSettingsBillApprovalPolicy.Always,
        manualMatchingEnabled: true,
        notMatchedBillsApprovalThreshold: 0,
        insufficientBudgetMatchBillsApprovalAllowed: domain.ApprovalAllowanceType.Always,
        insufficientBudgetMatchBillsApprovalThreshold: 0,
        insufficientBudgetMatchBillsApprovalPercentageThreshold: 0,
        overbudgetBillsApprovalPercentageThreshold: 0,
        allowApprovalOfOverbudgetBills: domain.CompanyMatchingSettingsBillApprovalPolicy.Always,
        overbudgetBillsApprovalThreshold: 0,
        addMatchedPOsRequestersAsApproversForBill: domain.AddMatchedPOsRequestersAsApproversForBillStatuses.None,
        canMatchBillsWithRetrospectivePurchaseOrders: true,
        amountType: domain.MatchingAmountType.Gross,
    },
};
