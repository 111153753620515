import { FC, memo } from 'react';

import { Navigation } from '../Navigation/Navigation';
import { useSettingsLinkItems } from './SettingsMenu.hooks';
import { SettingsMenuProps } from './SettingsMenu.types';

export const SettingsMenu: FC<SettingsMenuProps> = memo((props) => {
    const { company, onClick } = props;

    const settingsLinkItems = useSettingsLinkItems(company);

    return <Navigation items={settingsLinkItems} onClick={onClick} />;
});

SettingsMenu.displayName = 'SettingsMenu';
