import { selectors } from 'modules/common';
import TwoFaActionWizard from 'modules/components/TwoFaActionWizard/TwoFaActionWizard';
import { FC, memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useSendTFAVerifyingEmailCode,
    useVerifyTFAWithBackupCode,
    useVerifyTFAWithEmailCode,
    useVerifyTFAWithOneTimePassword,
} from 'shared/data';

import { messages } from './BackupOptionsVerification.messages';
import { BackupOptionsVerificationProps } from './BackupOptionsVerification.types';

const BackupOptionsVerification: FC<BackupOptionsVerificationProps> = memo((props) => {
    const { isOpen, onSuccess, onClose } = props;
    const [stateId, setStateId] = useState('');
    const profile = useSelector(selectors.profile.getProfile);
    const { mutateAsync: requestEmailCode } = useSendTFAVerifyingEmailCode();
    const { mutateAsync: verifyEmailCode } = useVerifyTFAWithEmailCode();
    const { mutateAsync: verifyAppCode } = useVerifyTFAWithOneTimePassword();
    const { mutateAsync: verifyBackupCode } = useVerifyTFAWithBackupCode();

    const onEnterAppCode = useCallback(
        async (appCode: string) => {
            await verifyAppCode({ data: { oneTimePassword: appCode } });
        },
        [verifyAppCode]
    );

    const onSendCodeViaEmail = useCallback(async () => {
        const response = await requestEmailCode({ data: {} });

        setStateId(response.stateId);
    }, [requestEmailCode]);

    const onEnterEmailCode = useCallback(
        async (emailCode: string) => {
            await verifyEmailCode({ data: { stateId, emailCode } });
        },
        [verifyEmailCode, stateId]
    );

    const onEnterBackupCode = useCallback(
        async (backupCode: string) => {
            await verifyBackupCode({ data: { backupCode } });
        },
        [verifyBackupCode]
    );

    return (
        <TwoFaActionWizard
            title={messages.title}
            isOpen={isOpen}
            onEnterAppCode={onEnterAppCode}
            onEnterBackupCode={onEnterBackupCode}
            onEnterEmailCode={onEnterEmailCode}
            onSendCodeViaEmail={onSendCodeViaEmail}
            onSuccess={onSuccess}
            isTFAAlternativeEmailEnabled={profile.isTFAAlternativeEmailEnabled}
            isTFABackupCodesEnabled={profile.isTFABackupCodesEnabled}
            isTrustThisDeviceEnabled={false}
            onClose={onClose}
        />
    );
});

export default BackupOptionsVerification;
