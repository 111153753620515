import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useAccess = () => {
    const companies = useSelector(selectors.company.getCompanies);

    return useMemo(
        () =>
            companies.some(({ flags, subscriptionId, licenseProductPlan }) => {
                const isAvailablePlan = [domain.CompanyPlan.Partner, domain.CompanyPlan.Trial].includes(
                    licenseProductPlan
                );

                return flags.isManager && (subscriptionId || isAvailablePlan);
            }),
        [companies]
    );
};
