import { ConfirmationFullIcon, ConfirmationGreyIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import { FC, memo, ReactNode } from 'react';
import styled from 'styled-components';

interface BulletProps {
    checked: boolean;
    text: ReactNode;
}

const Root = styled.div`
    margin-bottom: 15px;
    display: flex;
    align-items: center;
`;

const BulletText = styled.div<{ checked: boolean }>`
    margin-left: 10px;
    ${(props) => (props.checked ? font(13, '#477753', 'semibold') : font(13, '#477753'))}
`;

const Bullet: FC<BulletProps> = (props) => {
    const { checked, text } = props;

    return (
        <Root>
            {checked ? (
                <ConfirmationFullIcon width={26} height={26} />
            ) : (
                <ConfirmationGreyIcon width={26} height={26} />
            )}

            <BulletText checked={checked}>{text}</BulletText>
        </Root>
    );
};

export default memo(Bullet);
