import { UseCase } from 'pages/auth/components/QualificationQuestions/QualificationQuestions.types';

import { messages } from './LicensingNotification.messages';

export const mapTextByUseCases = {
    [UseCase.StreamlineAuditProcesses]: messages.expiredTrialUseCaseStreamlineAuditProcesses,
    [UseCase.EstablishInternalFinancialControls]: messages.expiredTrialUseCaseEstablishInternalFinancialControls,
    [UseCase.AutomateApprovalWorkflows]: messages.expiredTrialUseCaseAutomateApprovalWorkflows,
    [UseCase.SpeedupTheApprovalProcess]: messages.expiredTrialUseCaseSpeedupTheApprovalProcess,

    [UseCase.StreamlineAuditProcesses + ',' + UseCase.EstablishInternalFinancialControls]:
        messages.expiredTrialUseCaseStreamlineAuditProcessesAndEstablishInternalFinancialControls,
    [UseCase.StreamlineAuditProcesses + ',' + UseCase.AutomateApprovalWorkflows]:
        messages.expiredTrialUseCaseStreamlineAuditProcessesAndAutomateApprovalWorkflows,
    [UseCase.StreamlineAuditProcesses + ',' + UseCase.SpeedupTheApprovalProcess]:
        messages.expiredTrialUseCaseStreamlineAuditProcessesAndSpeedupTheApprovalProcess,
    [UseCase.EstablishInternalFinancialControls + ',' + UseCase.AutomateApprovalWorkflows]:
        messages.expiredTrialUseCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflows,
    [UseCase.EstablishInternalFinancialControls + ',' + UseCase.SpeedupTheApprovalProcess]:
        messages.expiredTrialUseCaseEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess,
    [UseCase.AutomateApprovalWorkflows + ',' + UseCase.SpeedupTheApprovalProcess]:
        messages.expiredTrialUseCaseAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess,

    [UseCase.StreamlineAuditProcesses +
    ',' +
    UseCase.EstablishInternalFinancialControls +
    ',' +
    UseCase.AutomateApprovalWorkflows]:
        messages.expiredTrialUseCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndAutomateApprovalWorkflows,

    [UseCase.StreamlineAuditProcesses +
    ',' +
    UseCase.EstablishInternalFinancialControls +
    ',' +
    UseCase.SpeedupTheApprovalProcess]:
        messages.expiredTrialUseCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess,

    [UseCase.StreamlineAuditProcesses +
    ',' +
    UseCase.AutomateApprovalWorkflows +
    ',' +
    UseCase.SpeedupTheApprovalProcess]:
        messages.expiredTrialUseCaseStreamlineAuditProcessesAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess,

    [UseCase.EstablishInternalFinancialControls +
    ',' +
    UseCase.AutomateApprovalWorkflows +
    ',' +
    UseCase.SpeedupTheApprovalProcess]:
        messages.expiredTrialUseCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess,

    [UseCase.StreamlineAuditProcesses +
    ',' +
    UseCase.EstablishInternalFinancialControls +
    ',' +
    UseCase.AutomateApprovalWorkflows +
    ',' +
    UseCase.SpeedupTheApprovalProcess]: messages.expiredTrialUseCaseDefault,
};
