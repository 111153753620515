import { errorHelpers } from '@approvalmax/utils';
import { useCallback, useState } from 'react';
import { notificationService } from 'services/notification';
import { useFido2CredentialCreate, useFido2CredentialOptions } from 'shared/data';

import { createPublicKey, getAttestationResponseTransfer } from './PasskeysSettings.helpers';
import { messages } from './PasskeysSettings.messages';

export const useCreatePasskey = (options: { onSuccess: VoidFunction }) => {
    const { onSuccess } = options;

    const [isCreatingPasskey, setIsCreatingPasskey] = useState(false);

    const [getCredentialOptions] = useFido2CredentialOptions();

    const { mutate: saveCredential } = useFido2CredentialCreate({
        onSuccess,
    });

    const createPasskey = useCallback(
        async (label: string) => {
            setIsCreatingPasskey(true);

            try {
                const credentialOptions = await getCredentialOptions({});

                const credential = await createPublicKey(credentialOptions);

                saveCredential({
                    data: {
                        attestationResponse: getAttestationResponseTransfer(credential, credentialOptions),
                        label,
                    },
                });
            } catch (err) {
                if (!errorHelpers.isApiError(err)) {
                    console.error('[Credentials create error]', err);

                    notificationService.showErrorToast(messages.errorText);
                }
            }

            setIsCreatingPasskey(false);
        },
        [getCredentialOptions, saveCredential]
    );

    return {
        isCreatingPasskey,
        createPasskey,
    };
};
