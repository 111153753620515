/**
 * @function
 * @param {Boolean} a Argument A.
 * @param {Boolean} b Argument B.
 * @return {Number} Result as follows:
 * <ul>
 *     <li><code>-1</code> - if A &lt; B.</li>
 *     <li><code>0</code> - if A == B.</li>
 *     <li><code>1</code> - if A &gt; B.</li>
 * </ul>
 * */
export const booleanComparator2Desc = (a: any, b: any) => {
    if (a) {
        return b ? 0 : 1;
    }

    return b ? -1 : 0;
};
