import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { mapData } from './useGenerateTFABackupCodes.map';
import {
    UseGenerateTFABackupCodesBackend,
    UseGenerateTFABackupCodesRequest,
    UseGenerateTFABackupCodesResponse,
} from './useGenerateTFABackupCodes.types';

export const useGenerateTFABackupCodes = () => {
    return useMutateLegacy<
        RequestDataParams<UseGenerateTFABackupCodesRequest>,
        UseGenerateTFABackupCodesBackend,
        UseGenerateTFABackupCodesResponse
    >(twoFaApiPaths.generateTFABackupCodes, {
        mapData,
    });
};
