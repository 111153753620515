import { compareHelpers, intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { set, updateArrayItem } from 'modules/immutable';
import { defineMessages } from 'react-intl';

import LineItemColumnKind from '../../config/lineItem/lineItemColumnKind';
import { getScopedColumns } from '../../config/standard/columnDefinitions';
import ColumnKind from '../../config/standard/columnKind';
import { CustomFields } from '../../selectors/types';
import { ReportConfig } from '../../types/ReportConfig';
import { ReportConfigColumn } from '../../types/ReportConfigColumn';
import { createAlwaysTrueFilter } from '../filters';
import FilterType from '../filters/FilterType';
import { createCustomColumn, createQBooksCustomColumn } from './reportConfigFactory';

export * from './reportConfigFactory';
export * from './reportConfigSchema';

const i18nPrefix = 'reports.data.ReportConfig';
const messages = defineMessages({
    trackingColumnTitleText: {
        id: `${i18nPrefix}.trackingColumnTitleText`,
        defaultMessage: '{fieldName} used in line items (at least one line item should match)',
    },
});

const updateTrackingColumn = (
    columns: ReportConfigColumn[],
    trackingColumn: ReportConfigColumn | undefined,
    trackingField: domain.Field | null
) => {
    if (trackingColumn) {
        if (trackingField && trackingColumn.name !== trackingField.name) {
            columns = updateArrayItem(columns, trackingColumn, {
                ...trackingColumn,
                name: trackingField.name,
                title: intl.formatMessage(messages.trackingColumnTitleText, {
                    fieldName: trackingField.name,
                }),
            });
        }

        if (!trackingField) {
            columns = updateArrayItem(columns, trackingColumn, {
                ...trackingColumn,
                visible: false,
            });
        }
    }

    return columns;
};

const updateCustomColumns = (
    columns: ReportConfigColumn[],
    customFields: domain.Field[],
    columnKind: ColumnKind
): [columns: ReportConfigColumn[], modified: boolean] => {
    const oldLength = columns.length;

    columns = columns.filter((column) => column.kind !== columnKind || customFields.some(({ id }) => id === column.id));

    const modified = oldLength !== columns.length;

    const newCustomColumns = customFields
        .filter((field) => !columns.some((column) => column.id === field.id))
        .map((field) => createQBooksCustomColumn(field, columnKind))
        .sort(compareHelpers.comparatorFor<ReportConfigColumn>(compareHelpers.stringComparator2AscI, 'name'));

    return [columns.concat(newCustomColumns), modified || newCustomColumns.length > 0];
};

export const updateDynamicColumns = (
    reportConfig: ReportConfig,
    {
        qBooksPurchaseOrderCustomFields,
        qBooksSalesInvoiceCustomFields,
        standaloneFields,
        xeroTrackingFields,
    }: CustomFields
) => {
    // update standalone columns
    let newColumns: ReportConfigColumn[] = [];
    let modified = false;

    if (reportConfig.reportType === domain.ReportType.Request) {
        newColumns = reportConfig.columns.filter(
            (c) => c.kind !== ColumnKind.CustomField || standaloneFields.some((f) => f.id === c.id)
        );

        modified = reportConfig.columns.length !== newColumns.length;

        const newStandaloneColumns = standaloneFields
            .filter((f) => !newColumns.some((c) => c.id === f.id))
            .map((f) => createCustomColumn(f))
            .sort(compareHelpers.comparatorFor<ReportConfigColumn>(compareHelpers.stringComparator2AscI, 'name'));

        modified = modified || newStandaloneColumns.length > 0;
        newColumns = newColumns.concat(newStandaloneColumns);
    } else {
        newColumns = reportConfig.columns;
    }

    let changed;

    // Update QBooks Purchase Order Custom Columns
    [newColumns, changed] = updateCustomColumns(
        newColumns,
        qBooksPurchaseOrderCustomFields,
        ColumnKind.QBooksPurchaseOrderCustomFields
    );
    modified = modified || changed;

    // Update QBooks Sales Invoice Custom Columns
    [newColumns, changed] = updateCustomColumns(
        newColumns,
        qBooksSalesInvoiceCustomFields,
        ColumnKind.QBooksSalesInvoiceCustomFields
    );
    modified = modified || changed;

    // update xero-tracking columns
    const newColumnsSnapshot = newColumns;

    newColumns = updateTrackingColumn(
        newColumns,
        newColumns.find((c) => c.kind === ColumnKind.LineItemTrackings1 || c.kind === LineItemColumnKind.Tracking1),
        xeroTrackingFields[0]
    );
    newColumns = updateTrackingColumn(
        newColumns,
        newColumns.find((c) => c.kind === ColumnKind.LineItemTrackings2 || c.kind === LineItemColumnKind.Tracking2),
        xeroTrackingFields[1]
    );
    modified = modified || newColumnsSnapshot !== newColumns;

    // commit changes
    if (modified) {
        return set(reportConfig, 'columns', newColumns);
    } else {
        return reportConfig;
    }
};

export const updateColumnsOutOfScope = (reportConfig: ReportConfig, company: selectors.types.ExpandedCompany) => {
    const scopedColumnIds = (
        reportConfig.reportType === domain.ReportType.Request
            ? getScopedColumns(reportConfig.columns, company)
            : reportConfig.columns
    ).map((c) => c.id);

    let modified = false;

    const newColumns = reportConfig.columns.map((c) => {
        if (scopedColumnIds.includes(c.id)) {
            return c;
        } else {
            let newCol = c;

            // ensure visible = false
            if (c.visible) {
                newCol = {
                    ...c,
                    visible: false,
                };
            }

            // ensure filter = any value
            if (c.filter.type !== FilterType.None) {
                newCol = {
                    ...newCol,
                    filter: createAlwaysTrueFilter(newCol.filter.type),
                };
            }

            if (newCol !== c) {
                modified = true;
            }

            return newCol;
        }
    });

    if (modified) {
        return set(reportConfig, 'columns', newColumns);
    } else {
        return reportConfig;
    }
};
