import { PartyImage } from '@approvalmax/ui';
import { Button, Flex, List, Popup, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { FC, memo, useCallback, useMemo } from 'react';

import { messages } from './MultipleResultPopup.messages';
import { MultipleResultPopupProps } from './MultipleResultPopup.types';

const MultipleResultPopup: FC<MultipleResultPopupProps> = memo((props) => {
    const {
        sourceCompany,
        sourceWorkflow,
        successfullyCopiedWorkflows,
        copiedWithMatrixErrorWorkflows,
        copiedWithSettingsErrorWorkflows,
        onToggle,
        open,
    } = props;

    const hasSuccessfullyCopiedWorkflows = successfullyCopiedWorkflows.length > 0;

    const sourceWorkflowName = sourceWorkflow.integrationCode
        ? selectors.template.getTemplateWorkflowNameByCode(sourceWorkflow.integrationCode)
        : sourceWorkflow.displayName;

    const copiedErrors = useMemo(
        () => [
            {
                title: messages.matrixErrorHeader,
                items: copiedWithMatrixErrorWorkflows,
            },
            {
                title: messages.settingsErrorHeader,
                items: copiedWithSettingsErrorWorkflows,
            },
        ],
        [copiedWithMatrixErrorWorkflows, copiedWithSettingsErrorWorkflows]
    );

    const done = useCallback(() => onToggle?.(false), [onToggle]);

    return (
        <Popup open={open} onToggle={onToggle} size='xsmall'>
            <Popup.Body spacing='16 32 32'>
                <Flex inline direction='column' alignItems='center' spacing='16'>
                    {hasSuccessfullyCopiedWorkflows && (
                        <>
                            <PartyImage width={100} height={100} />

                            <Text font='headline' fontSize='small' fontWeight='medium'>
                                {`${sourceCompany.displayName} `}

                                {sourceWorkflowName}

                                {messages.successfulMessage}

                                {messages.successfulNumber({
                                    number: successfullyCopiedWorkflows.length,
                                })}
                            </Text>
                        </>
                    )}

                    {copiedErrors.map(
                        ({ title, items }, index) =>
                            items.length > 0 && (
                                <div key={index}>
                                    <Text font='label' fontWeight='medium' color='midnight80' spacing='0 0 12'>
                                        {title}
                                    </Text>

                                    <List removeLastSpacing>
                                        {items.map((errorItem) => (
                                            <List.Item key={`${errorItem.company.id}-${errorItem.template.id}`}>
                                                <Text font='body' color='midnight80'>
                                                    {` • ${errorItem.company.displayName} - ${errorItem.template.displayName}`}
                                                </Text>
                                            </List.Item>
                                        ))}
                                    </List>
                                </div>
                            )
                    )}

                    {hasSuccessfullyCopiedWorkflows && (
                        <Text font='label' color='midnight60' textAlign='center'>
                            {messages.activateWorkflow}
                        </Text>
                    )}

                    <Button onClick={done} size='large' color='blue80'>
                        {messages.done}
                    </Button>
                </Flex>
            </Popup.Body>
        </Popup>
    );
});

export default MultipleResultPopup;
