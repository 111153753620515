import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import { mapData } from './useGetNetSuiteAccounts.map';
import {
    NetSuiteAccount,
    UseGetNetSuiteAccountsParams,
    UseGetNetSuiteAccountsResponseBackend,
} from './useGetNetSuiteAccounts.types';

export const useGetNetSuiteAccounts = (
    queryParams: UseGetNetSuiteAccountsParams,
    queryOptions: QueryOptions<UseGetNetSuiteAccountsResponseBackend, NetSuiteAccount[]>
) => {
    const { enabled = true } = queryOptions;

    const params: Record<string, string> = {
        companyId: queryParams.companyId,
        group: queryParams.group,
    };

    if (queryParams.query != null) {
        params.query = queryParams.query;
    }

    if (queryParams.pageSize != null) {
        params.pageSize = queryParams.pageSize.toString();
    }

    if (queryParams.subsidiaryId != null) {
        params.subsidiaryId = queryParams.subsidiaryId;
    }

    return useGetRequest<UseGetNetSuiteAccountsResponseBackend, NetSuiteAccount[]>(
        integrationsApiPaths.getNetSuiteAccounts,
        {
            queryOptions: {
                ...queryOptions,
                enabled: !!params.companyId && enabled,
            },
            params,
            apiVersion: 'v1',
            mapData,
        }
    );
};
