import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.TwoFaEnablingWizard.StepBackupMethods', {
    title: 'Two-factor authentication setup',
    subtitle: 'Add another option to fall back on',
    description:
        "For using the Authenticator app, you'll be prompted to provide an alternative 2FA option so that we can verify it's you",
    methodBackupCodes: 'Backup codes',
    methodBackupEmail: 'Backup email',
});
