import { Popup } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import moment from 'moment';
import { memo, useCallback, useEffect, useState } from 'react';

import { showAfterDays, showBeforeDays } from './AfterAllFeatureTrialPopup.constant';
import { getParsedHiddenCompaniesIds, saveHiddenCompaniesIds } from './AfterAllFeatureTrialPopup.helpers';
import { AfterAllFeatureTrialPopupContent } from './components';

export const AfterAllFeatureTrialPopup = memo(() => {
    const companyId = useSelector(selectors.navigation.getActiveCompanyId);
    const company = useSelector((state) => selectors.company.findCompanyById(state, companyId || ''));
    const profile = useSelector(selectors.profile.findProfile);
    const subscription = useSelector((state) =>
        company?.subscriptionId ? selectors.subscription.getSubscriptionById(state, company.subscriptionId) : null
    );

    const [companyWithExpirationDate, setCompanyWithExpirationDate] = useState<selectors.types.ExpandedCompany | null>(
        null
    );
    const [isBeforeExpiration, setIsBeforeExpiration] = useState(false);
    const [hiddenCompanyIds, setHiddenCompanyIds] = useState(getParsedHiddenCompaniesIds);

    const handleTogglePopup = useCallback(
        (open: boolean) => {
            if (!open) {
                setCompanyWithExpirationDate(null);

                if (companyWithExpirationDate?.id) {
                    const newHiddenList = hiddenCompanyIds.concat([companyWithExpirationDate.id]);

                    setHiddenCompanyIds(newHiddenList);
                    saveHiddenCompaniesIds(newHiddenList);
                }
            }
        },
        [companyWithExpirationDate?.id, hiddenCompanyIds]
    );

    useEffect(() => {
        if (!company || !profile || company.author !== profile.email) {
            return;
        }

        if (
            company.allFeaturesTrialStatus !== domain.AllFeaturesTrialStatus.Active &&
            company.allFeaturesTrialStatus !== domain.AllFeaturesTrialStatus.Expired
        ) {
            return;
        }

        if (hiddenCompanyIds.includes(company.id)) {
            return;
        }

        const expirationDate = moment.utc(company.allFeaturesTrialEndDate);
        const now = moment.utc();
        const days = moment.duration(expirationDate.diff(now)).as('days');

        if (company.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.Active && days <= showBeforeDays) {
            setCompanyWithExpirationDate(company);
            setIsBeforeExpiration(true);
        } else if (company.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.Expired && days >= -showAfterDays) {
            setCompanyWithExpirationDate(company);
            setIsBeforeExpiration(false);
        }
    }, [company, hiddenCompanyIds, profile]);

    if (!companyWithExpirationDate) {
        return null;
    }

    return (
        <Popup open={!!companyWithExpirationDate} onToggle={handleTogglePopup} size='xsmall' closable>
            <AfterAllFeatureTrialPopupContent
                company={companyWithExpirationDate}
                isBeforeExpiration={isBeforeExpiration}
                upgradeEnabled={!subscription?.isFrozenForUpgrade}
            />
        </Popup>
    );
});

AfterAllFeatureTrialPopup.displayName = 'AfterAllFeatureTrialPopup';
