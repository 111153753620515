import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { useGetRequest } from 'shared/data/api';

import { companiesApiPaths } from '../paths';
import {
    UseGetXeroCounterpartyPaymentDetailsHistoryPathParams,
    UseGetXeroCounterpartyPaymentDetailsHistoryResponse,
} from './useGetXeroCounterpartyPaymentDetailsHistory.types';

export const useGetXeroCounterpartyPaymentDetailsHistory = (
    pathParams: QueryParams<UseGetXeroCounterpartyPaymentDetailsHistoryPathParams>,
    queryOptions?: QueryOptions<UseGetXeroCounterpartyPaymentDetailsHistoryResponse>
) => {
    return useGetRequest<UseGetXeroCounterpartyPaymentDetailsHistoryResponse>(
        companiesApiPaths.xeroCounterpartyPaymentDetailsHistory,
        {
            pathParams,
            queryOptions: {
                ...queryOptions,
                enabled: enabledByParams<UseGetXeroCounterpartyPaymentDetailsHistoryPathParams>(
                    pathParams,
                    queryOptions
                ),
            },
            apiVersion: 'v2',
        }
    );
};
