import { memo } from 'react';
import { noValue } from 'shared/constants';

import { EmptyCellProps } from './EmptyCell.types';

export const EmptyCell = memo<EmptyCellProps>((props) => {
    const { value = noValue, className } = props;

    return <div className={className}>{value}</div>;
});

EmptyCell.displayName = 'EmptyCell';
