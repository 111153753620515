import { useTheme } from '@approvalmax/theme';
import { GoogleLogo, TransparentButton } from '@approvalmax/ui';
import { Flex } from '@approvalmax/ui/src/components';
import { GlobalLoadingBar } from 'modules/page';
import { SignInMicrosoft, SignInQbo, XeroWhiteIcon } from 'modules/sprites';
import { FC, memo } from 'react';
import { useMedia } from 'react-use';
import { PasskeyAuthButton } from 'shared/components';

import { useSsoPanel } from './SsoPanel.hooks';
import { Caption, Container, GoogleButton, IntuitButton, MicrosoftButton, XeroButton } from './SsoPanel.styles';
import { ButtonType, SsoPanelProps } from './SsoPanel.types';

const SsoPanel: FC<SsoPanelProps> = memo((props) => {
    const {
        className,
        caption,
        width,
        vertical,
        showOnlyButtons,
        buttonHeight = 40,
        withWhiteButtons,
        showPasskey,
        shouldReturnToCurrentPage,
    } = props;

    const {
        isLoading,
        xeroAuthText,
        googleAuthText,
        onSignInXero,
        onSignInIntuit,
        onSignInGoogle,
        onSignInMicrosoft,
        authCommand,
    } = useSsoPanel(props);

    const { theme } = useTheme();
    const isMobileScreen = useMedia(`(max-width: ${theme.container.breakpoint.xsmall})`);

    return (
        <Container className={className}>
            <GlobalLoadingBar isLoading={isLoading} />

            {caption && <Caption>{caption}</Caption>}

            <Flex container spacing='12' width={width ?? (isMobileScreen ? 236 : 470)}>
                {showPasskey && (
                    <Flex size={isMobileScreen || vertical ? 24 : 12}>
                        <PasskeyAuthButton blockButton shouldReturnToCurrentPage={shouldReturnToCurrentPage} />
                    </Flex>
                )}

                {(!showOnlyButtons || showOnlyButtons.includes(ButtonType.Xero)) && (
                    <Flex size={isMobileScreen || vertical ? 24 : 12}>
                        <TransparentButton command={authCommand} execute={onSignInXero}>
                            {({ elementProps }) => (
                                <XeroButton $height={buttonHeight} {...elementProps}>
                                    <div>{xeroAuthText}</div>

                                    <XeroWhiteIcon width={28} height={28} />
                                </XeroButton>
                            )}
                        </TransparentButton>
                    </Flex>
                )}

                {(!showOnlyButtons || showOnlyButtons.includes(ButtonType.Intuit)) && (
                    <Flex size={isMobileScreen || vertical ? 24 : 12}>
                        <TransparentButton command={authCommand} execute={onSignInIntuit}>
                            {({ elementProps }) => (
                                <IntuitButton $height={buttonHeight} {...elementProps}>
                                    <SignInQbo />
                                </IntuitButton>
                            )}
                        </TransparentButton>
                    </Flex>
                )}

                {(!showOnlyButtons || showOnlyButtons.includes(ButtonType.Google)) && (
                    <Flex size={isMobileScreen || vertical ? 24 : 12}>
                        <TransparentButton command={authCommand} execute={onSignInGoogle}>
                            {({ elementProps }) => (
                                <GoogleButton
                                    $height={buttonHeight}
                                    $withWhiteButtons={withWhiteButtons}
                                    {...elementProps}
                                >
                                    <GoogleLogo width={28} />

                                    <div>{googleAuthText}</div>
                                </GoogleButton>
                            )}
                        </TransparentButton>
                    </Flex>
                )}

                {(!showOnlyButtons || showOnlyButtons.includes(ButtonType.Microsoft)) && (
                    <Flex size={isMobileScreen || vertical ? 24 : 12}>
                        <TransparentButton command={authCommand} execute={onSignInMicrosoft}>
                            {({ elementProps }) => (
                                <MicrosoftButton
                                    $height={buttonHeight}
                                    $withWhiteButtons={withWhiteButtons}
                                    {...elementProps}
                                >
                                    <SignInMicrosoft />
                                </MicrosoftButton>
                            )}
                        </TransparentButton>
                    </Flex>
                )}
            </Flex>
        </Container>
    );
});

export default SsoPanel;
