export enum SubscribeAfterTrialButtonType {
    NewRequest = 'NewRequest',
    AddUser = 'AddUser',
    WorkflowStandalone = 'WorkflowStandalone',
    WorkflowActivation = 'WorkflowActivation',
    Approve = 'Approve',
    Reject = 'Reject',
}

export interface SubscribeAfterTrialButtonProps {
    type: SubscribeAfterTrialButtonType;
    companyId: string;
    profileId: string;
    text?: string | null;
}
