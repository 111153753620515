import { domain } from 'modules/data';

export enum WorkflowHelpItemType {
    Card = 'Workflow',
    Matrix = 'ApprovalMatrix',
    ApprovalMatrixReviewAndCoding = 'ApprovalMatrix-Review-And-Coding',
    AutoApprovalMatrix = 'Auto-ApprovalMatrix',
    ReviewerMatrix = 'ReviewerMatrix',
}

export function createHelpItemId(
    type: WorkflowHelpItemType,
    integrationCode: domain.IntegrationCode | null,
    postfix?: string
) {
    const result = `${type}-${integrationCode || 'standalone'}`;

    return postfix ? `${result}-${postfix}` : result;
}

export function createHelpItemIdByIntegrationType(type: WorkflowHelpItemType, integrationType: domain.IntegrationType) {
    return `${type}-${integrationType}`;
}
