import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import { selectors } from 'modules/common';
import { useUserProfile } from 'modules/utils';
import { useMemo } from 'react';

import { PermissionKey, UsePermissionsResult } from './usePermissions.types';

export const usePermissions = (company?: selectors.types.ExpandedCompany): UsePermissionsResult => {
    const me = useUserProfile();

    return useMemo(() => {
        const permissions = company?.userPermissionsSettings?.[me.id];

        const result = (permissions ?? []).reduce<UsePermissionsResult>((acc, item) => {
            const permissionKey = `is${upperFirst(camelCase(item.permission))}Enabled` as PermissionKey;

            acc[permissionKey] = item.isEnabled;

            return acc;
        }, {});

        return result;
    }, [company, me]);
};
