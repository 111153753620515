import { QueryOptions } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetRequestLegacy } from 'shared/data';

import { authApiPaths } from '../paths';
import { UseGetCannyIoTokenResponse, UseGetCannyIoTokenResponseBackend } from './useGetCannyIoToken.types';

export const useGetCannyIoToken = (
    queryOptions?: QueryOptions<UseGetCannyIoTokenResponseBackend, UseGetCannyIoTokenResponse>
) => {
    return useGetRequestLegacy<UseGetCannyIoTokenResponseBackend, UseGetCannyIoTokenResponse>(
        authApiPaths.cannyIoToken,
        {},
        {
            mapData: objectHelpers.pascalCaseToCamelCase,
            queryOptions,
        }
    );
};
