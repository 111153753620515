import { Tag } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { messages } from './TagBadge.messages';
import { TagBadgeProps } from './TagBadge.types';

export const TagBadge = memo((props: TagBadgeProps) => {
    const { showAmCaptureBadge, integrationDisconnected } = props;

    return (
        <>
            {showAmCaptureBadge && (
                <Tag size='xsmall' color='blue20' outline wrap={false}>
                    {messages.amCaptureBadge}
                </Tag>
            )}

            {integrationDisconnected && (
                <Tag size='xsmall' color='midnight40' rounded={false} wrap={false}>
                    {messages.integrationDisconnected}
                </Tag>
            )}
        </>
    );
});

TagBadge.displayName = 'TagBadge';
