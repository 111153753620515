import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.SubscribeAfterTrialButton', {
    newRequest: 'New request',
    addUser: 'Add new users',
    workflowActivation: 'Activate the workflow',
    workflowStandalone: 'Create',
    Reject: 'Reject',
    Approve: 'Approve',

    workflowStandaloneDescription: 'Purchase a subscription to create new workflows.',
    newRequestDescription: 'Purchase a subscription to create new approval requests.',
    workflowActivationDescription: 'Purchase a subscription to activate workflows.',
    approvalDecisionDescription: 'Purchase a subscription to make approval decisions.',
    addUserDescription: 'Purchase a subscription to add new users.',
});
