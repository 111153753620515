import { Radio } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionNetSuiteAmaxUrl.messages';
import { NetSuiteAmaxUrlOption, SectionNetSuiteAmaxUrlProps } from './SectionNetSuiteAmaxUrl.types';

const SectionNetSuiteAmaxUrl = memo<SectionNetSuiteAmaxUrlProps>((props) => {
    const { templateSettings, onChange, qa, readonly, learnMoreLink } = props;

    const handleChange = useCallback(
        (selectedValue: NetSuiteAmaxUrlOption) => {
            onChange(selectedValue === NetSuiteAmaxUrlOption.AfterSync);
        },
        [onChange]
    );

    const value = templateSettings.pushApprovalMaxURLToNetSuite
        ? NetSuiteAmaxUrlOption.AfterSync
        : NetSuiteAmaxUrlOption.AfterApproval;

    return (
        <SettingsSection title={messages.title} learnMoreLink={learnMoreLink}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={value}
                    onChange={handleChange}
                    name='netSuiteAmaxUrl'
                    block
                    data-qa={qa('netsuite-amax-url-settings-section')}
                >
                    <Radio value={NetSuiteAmaxUrlOption.AfterApproval}>{messages.afterApproval}</Radio>

                    <Radio value={NetSuiteAmaxUrlOption.AfterSync}>{messages.afterSync}</Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionNetSuiteAmaxUrl;
