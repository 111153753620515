import { Popup } from '@approvalmax/ui';
import { GlobalErrorBoundary as GlobalErrorBoundaryUi } from '@approvalmax/ui/src/components';
import { ContactSupportPopup } from 'modules/profile';
import { FC, PropsWithChildren, useCallback, useState } from 'react';

export const GlobalErrorBoundary: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    const [isContactSupportPopupOpen, setIsContactSupportPopupOpen] = useState(false);

    const openContactSupportPopup = useCallback(() => {
        setIsContactSupportPopupOpen(true);
    }, []);

    const closeContactSupportPopup = useCallback(() => {
        setIsContactSupportPopupOpen(false);
    }, []);

    return (
        <GlobalErrorBoundaryUi
            onContactSupport={openContactSupportPopup}
            contactSupportPopup={
                <Popup isOpen={isContactSupportPopupOpen} onRequestClose={closeContactSupportPopup}>
                    <ContactSupportPopup />
                </Popup>
            }
        >
            {children}
        </GlobalErrorBoundaryUi>
    );
};
