import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('firstStart.NewOrganisationPopup', {
    title: 'New Organisation',
    contentTitle: 'Which accounting system would you like to connect to your new ApprovalMax organisation?',

    dearButton: 'Connect to Cin7 Core',
    dearTitle: 'Why connection matters:',
    dearDetailsText: `
        <item>1. Connecting ApprovalMax to Cin7 Core allows you to set up approval workflows for Purchase Orders.</item>
        <item>2. Activate a workflow to approve documents that get pulled from the Cin7 Core in the “Draft” status.</item>
        <item>3. Once the documents have been approved, they will be pushed to Cin7 Core immediately.</item>
    `,

    netSuiteButton: 'Connect to\nOracle NetSuite',
    netSuiteTitle: 'Why connection matters:',
    netSuiteDetailsText: `
        <item>1. Connecting ApprovalMax to Oracle NetSuite allows you to set up approval workflows for Vendor Bills and Purchase Orders.</item>
        <item>2. Activate a workflow to approve documents that get pulled from the Oracle NetSuite in the “Pending Approval” status, or are created directly in ApprovalMax.</item>
        <item>3. Once the documents have been approved, they will be pushed to Oracle NetSuite immediately.</item>
    `,

    xeroButton: 'Connect to Xero',
    xeroTitle: 'Why connection matters:',
    xeroDetailsText: `
        <item>1. Connecting ApprovalMax to Xero allows you to set up approval workflows for Bills, Purchase Orders, Sales Invoices and Credit Notes.</item>
        <item>2. Activate a workflow to approve documents that get pulled from Xero in the “Awaiting Approval” status, or are created directly in ApprovalMax.</item>
        <item>3. Once the documents have been approved, their status will be updated in Xero immediately.</item>
    `,
    xeroProTip: '⚡️ Pro tip:',
    xeroProTipText:
        'Consider connecting to a Xero Demo organisation. It goes with dummy data and you won’t affect your production data. <learnmore>Learn more</learnmore>',
    xeroProTipLink:
        'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-try-approvalmax-with-xero-demo-organisation',

    qboButton: 'Connect to\nQuickBooks Online',
    qboTitle: 'Why connection matters:',
    qboDetailsText: `
        <item>1. Connecting ApprovalMax to QuickBooks Online allows you to set up approval workflows for Bills and Purchase Orders.</item>
        <item>2. Activate a workflow to create documents directly in ApprovalMax that will be routed to designated approvers.</item>
        <item>3. Once the documents have been approved, they will be pushed to QuickBooks Online immediately.</item>
    `,

    connectLaterButton: 'Connect later',
    connectLaterTitle: 'What you can do without a connection:',
    connectLaterDetailsText: `
        <item>1. You can only set up stand-alone approval workflows for generic documents which exist solely in ApprovalMax and are not synced with an accounting system.</item>
        <item>2. Other than that, stand-alone workflows work exactly as connected workflows for Bills and Purchase Orders do.</item>
        <item>3. You can always connect to Xero or QuickBooks Online later, and the stand-alone workflows will remain available after making the connection.</item>
    `,

    nextButton: 'Next',
});
