import { AddIcon } from '@approvalmax/ui';
import { Alert, Button, drawerHeaderHeight, openDrawerState, Select } from '@approvalmax/ui/src/components';
import { hooks } from '@approvalmax/utils';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useToggle } from 'react-use';
import { useRecoilValue } from 'recoil';

import { useChangeCompany, useCreateCompany } from './CompanySelector.hooks';
import { messages } from './CompanySelector.messages';
import { Root } from './CompanySelector.styles';
import { CompanySelectorProps } from './CompanySelector.types';

const qa = bemFactory.qa('navigation-drawer');

export const CompanySelector: FC<CompanySelectorProps> = memo((props) => {
    const { root, companies, canCreateNewCompanies, ...restProps } = props;

    const isDrawerOpen = useRecoilValue(openDrawerState);
    const { sticky, stickyRef } = hooks.useSticky({ root, rootMargin: -drawerHeaderHeight + 'px' });

    const [selectOpen, setSelectOpen] = useToggle(false);
    const { createCompany, createCompanyButtonProps } = useCreateCompany({ setSelectOpen });
    const { activeCompanyId, changeCompany } = useChangeCompany();

    return (
        <>
            <div ref={stickyRef} />

            <Root $sticky={sticky} $open={isDrawerOpen} data-qa={qa('organisation-selector')} {...restProps}>
                {companies.length ? (
                    <Select
                        autocomplete
                        hideNoData
                        clearable={false}
                        value={activeCompanyId}
                        placeholder={messages.selectCompany}
                        readOnly={companies.length === 1 && !canCreateNewCompanies}
                        items={companies}
                        onChange={changeCompany}
                        actionItemProps={canCreateNewCompanies ? createCompanyButtonProps : undefined}
                        open={selectOpen}
                        onOpen={setSelectOpen}
                        data-qa={qa('organisation-select')}
                    />
                ) : canCreateNewCompanies ? (
                    <Button
                        onClick={createCompany}
                        endIcon={<AddIcon />}
                        color='green80'
                        outline
                        block
                        justifyContent='space-between'
                        data-qa={qa('add-new-company-button')}
                    >
                        {messages.addNewCompany}
                    </Button>
                ) : (
                    <Alert color='midnight20' size='small'>
                        {messages.noCompanyNoAdd}
                    </Alert>
                )}
            </Root>
        </>
    );
});

CompanySelector.displayName = 'CompanySelector';
