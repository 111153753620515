import { mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { ListProps } from './List.types';

export const Root = styled.ul<StyledMods<Pick<ListProps, 'bordered' | 'overflowX'>>>`
    display: flex;
    align-items: center;
    overflow-x: ${({ $overflowX }) => $overflowX};

    ${mods.bordered.true`
        box-shadow: inset 0 -1px 0 ${theme.color.midnight50};
    `};
`;
