import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowsPage.IntegrationConnection', {
    title: 'Connected workflows',
    description: `
        Connecting ApprovalMax to an accounting platform enables you to set up
        approval workflows for the finance documents that are managed on this platform.
        Please note: each ApprovalMax Organisation can be connected
        to only one Organisation in Xero or QuickBooks Online.
    `,
    readOnly: "This Organisation is read-only because it's not assigned to a subscription. ",
    manageSubscriptions: 'Manage subscriptions',
});
