import { AddIcon } from '@approvalmax/ui';
import { Box, Button, Dropdown, Flex, TextField } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './RuleNameButton.messages';
import { RuleNameButtonProps } from './RuleNameButton.types';

const qa = bemFactory.qa('rule-name-button');

export const RuleNameButton: FC<RuleNameButtonProps> = memo((props) => {
    const { title, ruleNames, defaultValue = '', onSubmit, children, onOpen, isOpen: isOpenProps, readonly } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState(defaultValue);
    const [showErrors, setShowErrors] = useState(false);
    const lowerCaseRuleNames = useMemo(() => ruleNames.map((ruleName) => ruleName.toLocaleLowerCase()), [ruleNames]);

    const resetState = useCallback(() => {
        setName(defaultValue);
        setShowErrors(false);
    }, [defaultValue]);

    const submit = useCallback(() => {
        if (!name || (lowerCaseRuleNames.includes(name.toLocaleLowerCase()) && name !== defaultValue)) {
            setShowErrors(true);

            return;
        }

        onSubmit(name);
        resetState;
        setIsOpen(false);
    }, [defaultValue, lowerCaseRuleNames, name, onSubmit, resetState]);

    const handleOpen = useCallback(
        (open: boolean) => {
            resetState();

            if (onOpen) {
                onOpen(open);
            } else {
                setIsOpen(open);
            }
        },
        [onOpen, resetState]
    );

    const openn = typeof isOpenProps == 'boolean' ? isOpenProps : isOpen;
    const invalidName = showErrors && (lowerCaseRuleNames.includes(name.toLocaleLowerCase()) || !name.trim());

    if (readonly) {
        return null;
    }

    return (
        <Dropdown
            open={openn}
            onOpen={handleOpen}
            isClicking
            width='auto'
            data-qa={qa()}
            activator={
                children || (
                    <Button
                        color='transparent'
                        size='small'
                        title={title}
                        startIcon={<AddIcon size={24} color='blue100' />}
                        uppercase={false}
                        data-qa={qa('button')}
                    >
                        {messages.addRule}
                    </Button>
                )
            }
        >
            <Box width={250} spacing='8'>
                <Flex inline spacing='12' alignItems='center' direction='row' wrap={false}>
                    <TextField
                        placeholder={messages.placeholder}
                        size='small'
                        initFocus
                        width='fill-available'
                        value={name}
                        onChange={setName}
                        required
                        invalid={invalidName}
                        onEnter={submit}
                        data-qa={qa('text-field')}
                    />

                    <Button onClick={submit} size='small' color='blue80' type='submit' disabled={invalidName}>
                        {messages.buttonText}
                    </Button>
                </Flex>
            </Box>
        </Dropdown>
    );
});

RuleNameButton.displayName = 'RuleNameButton';
