import { Box, Checkbox, DatePicker, Radio, Spacing, Text } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionFraudBypassing.messages';
import { FraudBypassingOption, SectionFraudBypassingProps } from './SectionFraudBypassing.types';

export const SectionFraudBypassing: FC<SectionFraudBypassingProps> = memo((props) => {
    const {
        template,
        settings,
        savedTemplateSettings,
        effectiveDateLimits,
        checkValidFraudBypassing,
        onChangeApprovalDisregardEnabled,
        onChangeApprovalDisregardEffectiveDate,
        onChangeApprovalDisregardNotifyAdmins,
        readonly,
        qa,
    } = props;

    const handleChangeApprovalDisregardEnabled = useCallback(
        (checkedValue?: FraudBypassingOption) => {
            switch (checkedValue) {
                case FraudBypassingOption.Pull:
                    onChangeApprovalDisregardEnabled(true);
                    break;

                case FraudBypassingOption.DoNotPull:
                    onChangeApprovalDisregardEnabled(false);
                    break;
            }
        },
        [onChangeApprovalDisregardEnabled]
    );

    if (!settings) {
        return null;
    }

    const disabled = !template.externalSubmitter;
    const dateTimeDisabled =
        disabled ||
        !settings.enabled ||
        Boolean(
            savedTemplateSettings.approvalDisregardDetectionSettings &&
                savedTemplateSettings.approvalDisregardDetectionSettings.effectiveDate
        );

    return (
        <SettingsSection
            title={messages.title}
            learnMoreLink='https://support.approvalmax.com/portal/kb/articles/what-is-fraud-detection-bypassing-approval-workflow-setting'
        >
            <SettingsSubsection subtitle={messages.subtitle} warning={disabled ? messages.warning : undefined}>
                <Radio.Group
                    disabled={disabled || readonly}
                    value={settings.enabled ? FraudBypassingOption.Pull : FraudBypassingOption.DoNotPull}
                    onChange={handleChangeApprovalDisregardEnabled}
                    name='approvalDisregard'
                    block
                    data-qa={qa('FraudDetectionBypassing-enabled')}
                >
                    <Radio value={FraudBypassingOption.DoNotPull}>{messages.doNotPullDocumentLabel}</Radio>

                    <Radio value={FraudBypassingOption.Pull}>{messages.pullDocumentLabel}</Radio>
                </Radio.Group>

                <Box spacing='0 0 0 28'>
                    <Text font='body' color='midnight70' fontSize='medium'>
                        {messages.pullDocumentDescription({
                            templateName: template.displayName,
                        })}
                    </Text>

                    <Spacing height={12} />

                    <Box width={150}>
                        <DatePicker
                            value={settings.effectiveDate || undefined}
                            invalid={!checkValidFraudBypassing(domain.IntegrationType.Xero)}
                            minDate={new Date(effectiveDateLimits!.minDate)}
                            maxDate={new Date(effectiveDateLimits!.maxDate)}
                            onChange={onChangeApprovalDisregardEffectiveDate}
                            disabled={dateTimeDisabled || readonly}
                            placeholder={messages.datePlaceholder}
                            data-qa={qa('FraudDetectionBypassing-effective-date')}
                        />
                    </Box>
                </Box>

                <Checkbox
                    disabled={!settings.enabled || disabled || readonly}
                    checked={Boolean(settings.notifyAdmins)}
                    onChange={onChangeApprovalDisregardNotifyAdmins}
                    data-qa={qa('FraudDetectionBypassing-notify-admins')}
                >
                    {messages.notifyCheckboxText}
                </Checkbox>
            </SettingsSubsection>
        </SettingsSection>
    );
});

SectionFraudBypassing.displayName = 'SectionFraudBypassing';
