import { CrashedComponentPlaceholder, useLayout } from '@approvalmax/ui/src/components';
import * as profileModule from 'modules/profile';
import { useDispatch } from 'react-redux';

export const ErrorPlaceholder = () => {
    const dispatch = useDispatch();

    // We decided to always show these vvv elements even though
    // original page doesn't have it
    useLayout({ hasDrawer: true, hasHeader: true });

    const showContactSupportPopup = () => dispatch(profileModule.loadContactSupportPopup());

    return <CrashedComponentPlaceholder onContactSupport={showContactSupportPopup} />;
};
