import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, DISCARD_INVITE_USERS_POPUP, INVITE_USERS, OPEN_INVITE_USERS_POPUP } from '../../actions';

export interface InviteUsersData {
    users: string[];
    preselected: string[];
}

export type ActiveInviteUsers = ImmutableObject<InviteUsersData> | null;

export default function activeInviteUsersReducer(state: ActiveInviteUsers = null, action: Action): ActiveInviteUsers {
    switch (action.type) {
        case OPEN_INVITE_USERS_POPUP:
            return immutable<InviteUsersData>({
                users: action.users,
                preselected: action.preselectedUsers,
            });

        case INVITE_USERS:
        case DISCARD_INVITE_USERS_POPUP:
            return null;

        default:
            return state;
    }
}
