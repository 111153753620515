import { backend } from 'modules/data';
import { stringify } from 'query-string';

import { ApiHandler } from '../types';

class ProfileUrls {
    constructor(private _apiHandler: ApiHandler) {}

    public get(transfer: backend.transfers.ProfileGetTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}profile/get?${stringify(transfer)}`;
    }

    public contactDetails(transfer: backend.transfers.ProfileGetTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}profile/contactDetails?${stringify(transfer)}`;
    }

    public isPasswordSet(): string {
        return `${this._apiHandler.getApiUrlPrefix()}profile/isPasswordSet`;
    }
}

export default class ProfileApi {
    public urls: ProfileUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new ProfileUrls(this._apiHandler);
    }

    public get(transfer: backend.transfers.ProfileGetTransfer): Promise<backend.ProfileGetAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'profile/get',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public contactDetails(transfer: backend.transfers.ProfileGetTransfer): Promise<backend.ProfileDetailsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'profile/contactDetails',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    /**
     * @deprecated
     * @use useProfileEdit
     */
    public edit(transfer: backend.transfers.ProfileEditTransfer): Promise<backend.ProfileEditAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'profile/edit',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public setAvatar(): Promise<backend.ProfileSetAvatarAnswer> {
        return this._apiHandler.doApiCall({
            data: null,
            action: 'profile/setAvatar',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public setTimeZoneForEmail(
        timezoneTransfer: backend.transfers.ProfileSetTimeZoneForEmailTransfer
    ): Promise<backend.ProfileSetTimeZoneForEmailAnswer> {
        return this._apiHandler.doApiCall({
            data: timezoneTransfer,
            action: 'profile/setTimeZoneForEmail',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public isPasswordSet(): Promise<backend.ProfileIsPasswordSetAnswer> {
        return this._apiHandler.doApiCall({
            data: null,
            action: 'profile/isPasswordSet',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public registerPushConsumer(
        pushTransfer: backend.transfers.ProfileRegisterPushConsumerTransfer
    ): Promise<backend.ProfileRegisterPushConsumerAnswer> {
        return this._apiHandler.doApiCall({
            data: pushTransfer,
            action: 'profile/registerPushConsumer',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public forgetPushConsumer(
        pushTransfer: backend.transfers.ProfileDeletePushConsumerTransfer
    ): Promise<backend.ProfileForgetPushConsumerAnswer> {
        return this._apiHandler.doApiCall({
            data: pushTransfer,
            action: 'profile/forgetPushConsumer',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public deletePushConsumer(
        pushTransfer: backend.transfers.ProfileDeletePushConsumerTransfer
    ): Promise<backend.ProfileDeletePushConsumerAnswer> {
        return this._apiHandler.doApiCall({
            data: pushTransfer,
            action: 'profile/deletePushConsumer',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public setDelegates(
        delegateTransfer: backend.transfers.ProfileSetDelegateTranfer
    ): Promise<backend.ProfileSetDelegateAnswer> {
        return this._apiHandler.doApiCall({
            data: delegateTransfer,
            action: 'profile/setDelegates',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    /**
     * @deprecated
     * @use useSetProfilePassword
     */
    public setPassword(transfer: backend.transfers.AuthSetPwdTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'profile/setPassword',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public updatePassword(transfer: backend.transfers.AuthUpdatePwdTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'profile/updatePassword',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public startResetPassword(transfer: { linkCode: string }): Promise<{
        StateId: string;
        RequiresTFA: boolean;
        IsTFAAlternativeEmailEnabled: boolean | null;
        IsTFABackupCodesEnabled: boolean | null;
    }> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'profile/startResetPassword',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public sendResetPasswordTFAEmailCode(transfer: { stateId: string }): Promise<{}> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'profile/sendResetPasswordTFAEmailCode',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public verifyResetPasswordTFACode(transfer: { stateId: string; code: string }): Promise<{}> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'profile/verifyResetPasswordTFACode',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public finishResetPassword(transfer: { stateId: string; newPassword: string }): Promise<{}> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'profile/finishResetPassword',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
