/**
 * Developer: Stepan Burguchev
 * Date: 3/3/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { ErrorType } from '../const';
import { messages } from './messages';

export default function required(value: any) {
    let error = {
        type: ErrorType.Required,
        message: messages.requiredField,
    };

    if (!value && value !== 0) {
        return error;
    }

    return undefined;
}
