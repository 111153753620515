import { Route } from 'modules/page';
import React from 'react';

const ProfilePage = React.lazy(() => import(/* webpackChunkName: "manager-pages" */ './ProfilePage'));
const NewOrganisationPage = React.lazy(() => import(/* webpackChunkName: "manager-pages" */ './NewOrganisationPage'));
const DownloadAuditReportCollectionPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './DownloadAuditReportCollectionPage')
);
const AcceptInvitationPage = React.lazy(() => import(/* webpackChunkName: "manager-pages" */ './AcceptInvitationPage'));

const routes: Route[] = [
    {
        path: '/acceptCompanyInvitation/:companyId',
        component: AcceptInvitationPage,
    },
    {
        path: '/:companyId/downloadArchiveCollection/:collectionId',
        component: DownloadAuditReportCollectionPage,
    },
    {
        path: '/profile',
        component: ProfilePage,
    },
    {
        path: '/newOrganisation',
        component: NewOrganisationPage,
    },
];

export default routes;
