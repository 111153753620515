import { Reference } from '@approvalmax/types';
import { BaseEditorProps, DropdownEditor } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { PlainDataProvider } from 'modules/data-providers';
import { FC, memo } from 'react';

const i18nPrefix = 'common/ui/dayPickerEditor/DayPickerEditor';

export interface DayReference extends Reference {
    dayIndex: number;
}

export const allDayReferences = (daysAmount: number = 28): DayReference[] =>
    new Array(daysAmount).fill(0).map((x, dayIndex) => ({
        id: String(dayIndex + 1),
        text: intl.formatMessage(
            {
                id: `${i18nPrefix}.dayLabel`,
                defaultMessage: 'day {num}',
            },
            {
                num: dayIndex + 1,
            }
        ),
        dayIndex: dayIndex + 1,
    }));

interface DayPickerEditorProps extends BaseEditorProps<DayReference | null> {
    daysAmount?: number;
}

export const DayPickerEditor: FC<DayPickerEditorProps> = memo((props) => {
    const { daysAmount } = props;

    return (
        <PlainDataProvider items={allDayReferences(daysAmount)}>
            <DropdownEditor
                css='width: 75px;'
                placeholder={intl.formatMessage({
                    id: `${i18nPrefix}.placeholder`,
                    defaultMessage: 'Day',
                })}
                {...props}
            />
        </PlainDataProvider>
    );
});
