import { du } from 'modules/data';

import { NetSuiteAccount, UseGetNetSuiteAccountsResponseBackend } from './useGetNetSuiteAccounts.types';

export const mapData = (data: UseGetNetSuiteAccountsResponseBackend): NetSuiteAccount[] => {
    return data.Values.map((value) => ({
        id: value.Id,
        text: value.Name,
        currency: value.Currency ? du.parseServerReference(value.Currency) : null,
    }));
};
