import { Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useState } from 'react';
import { isOcrEnabledForTemplate } from 'shared/helpers';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import CollapseAllButton from '../CollapseAllButton/CollapseAllButton';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import { ReceiveOverEmailSection } from '../ReceiveOverEmailSection/ReceiveOverEmailSection';
import StepSubmitterSection from '../StepSubmitterSection/StepSubmitterSection';
import XeroExternalSubmitterSection from '../XeroExternalSubmitterSection/XeroExternalSubmitterSection';
import { learnMoreLink } from './XeroInvoiceStartStep.constants';
import { messages } from './XeroInvoiceStartStep.messages';

const XeroInvoiceStartStep = memo(() => {
    const template = useSelector(getActiveTemplate)!;
    const pluralTemplateName = template.displayNamePlural;
    const [collapsedSubmitterSection, setCollapsedSubmitterSection] = useState<boolean>();
    const [collapsedPullingSection, setCollapsedPullingSection] = useState<boolean>();
    const [collapsedReceiveOverEmailSection, setCollapsedReceiveOverEmailSection] = useState<boolean>();

    const company = useSelector((state) => selectors.company.getCompanyById(state, template.companyId));

    const onCollapse = useCallback((collapsed: boolean) => {
        setCollapsedSubmitterSection(collapsed);
        setCollapsedPullingSection(collapsed);
    }, []);

    const receiveOverEmailSection = isOcrEnabledForTemplate(
        company.betaFeatures,
        company.licenseFeatures,
        domain.IntegrationCode.XeroInvoice
    );

    return (
        <FixedStepContainer bubbleText={messages.bubbleText({ templateName: template.displayName })}>
            <Text font='body' fontSize='small' color='midnight70'>
                {messages.title({ pluralTemplateName })}
            </Text>

            <CollapseAllButton
                collapsed={Boolean(collapsedSubmitterSection && collapsedPullingSection)}
                onCollapse={onCollapse}
            />

            <StepSubmitterSection
                cacheId='XeroInvoiceStartStepSubmitter'
                headerText={messages.createInApprovalMax}
                description={messages.description({ pluralTemplateName })}
                required
                collapsed={collapsedSubmitterSection}
                onCollapse={setCollapsedSubmitterSection}
            />

            <XeroExternalSubmitterSection
                cacheId='XeroInvoiceStartStepExternalSubmitter'
                collapsed={collapsedPullingSection}
                onCollapse={setCollapsedPullingSection}
                description={messages.externalSubmitterDescriptionNonEmpty({ pluralTemplateName })}
                descriptionEmpty={messages.externalSubmitterDescriptionEmpty({ pluralTemplateName })}
                title={messages.externalSubmitterTitle({ pluralTemplateName })}
                learnMoreLink={learnMoreLink}
            />

            {receiveOverEmailSection && (
                <ReceiveOverEmailSection
                    collapsed={collapsedReceiveOverEmailSection}
                    onCollapse={setCollapsedReceiveOverEmailSection}
                    cacheId='XeroInvoiceStartStepReceiveOverEmail'
                    description={messages.emailExternaSubmitterDescriptionNonEmpty({ pluralTemplateName })}
                    descriptionEmpty={messages.emailExternalSubmitterDescriptionEmpty({
                        templateName: template.displayName,
                    })}
                    title={messages.emailExternalSubmitterTitle}
                />
            )}
        </FixedStepContainer>
    );
});

export default XeroInvoiceStartStep;
