import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { TooltipProps } from './Tooltip.types';

export const Content = styled.div<StyledMods<Pick<TooltipProps, 'size'>>>`
    ${mods.size.small` padding: 4px; `};
    ${mods.size.medium` padding: 8px; `};
    ${mods.size.large` padding: 16px; `};
`;
