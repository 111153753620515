import { RequestDataParams } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import {
    UseSetReviewedXeroBudgetTrackingData,
    UseSetReviewedXeroBudgetTrackingResponse,
} from './useSetReviewedXeroBudgetTracking.types';

export const useSetReviewedXeroBudgetTracking = () => {
    const queryClient = useQueryClient();

    return useMutateLegacy<
        RequestDataParams<UseSetReviewedXeroBudgetTrackingData>,
        UseSetReviewedXeroBudgetTrackingResponse
    >(budgetsApiPaths.setReviewedXeroBudgetTracking, {
        mutationOptions: {
            onSettled: async (_data, _error, requestData) => {
                await queryClient.invalidateQueries([
                    routerHelpers.pathToUrl(budgetsApiPaths.select, requestData.pathParams),
                    { companyId: requestData.data.companyId },
                ]);
            },
        },
    });
};
