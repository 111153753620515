import { domain } from 'modules/data';

export enum OcrFileContentType {
    DocumentPerFile = 'DocumentPerFile',
    MultiDocumentFile = 'MultiDocumentFile',
}

export type UseBulkUploadToOcrPathParams = {
    documentType: domain.IntegrationCode;
    companyId: string;
};

export interface UseBulkUploadToOcrData {
    fileUploadOptions: {
        fileContentType: OcrFileContentType;
    };
    fileRecordIds: string[];
}
