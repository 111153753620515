import { Reference } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { backend, domain } from 'modules/data';

import { IntegrationCode } from '../Integration';
import {
    DearAdditionalChargeLine,
    DearAdditionalChargeLineBackend,
    DearItemLine,
    DearProduct,
    DearProductBackend,
    DearPurchaseOrderDetails,
    DearPurchaseOrderDetailsBackend,
    DearPurchaseOrderItemLineBackend,
    DearPurchaseOrderSupplierBackend,
    DearRequestSpecifics,
} from '../Request.Dear';
import { TemplateSettingsLockDatePolicy } from '../TemplateSettings';

const mapProduct = (product: DearProductBackend): DearProduct => ({
    id: product.Id,
    name: product.Name,
    displayName: product.DisplayName,
    sku: product.SKU,
});

const mapItemLine = (line: DearPurchaseOrderItemLineBackend): DearItemLine => ({
    quantity: line.Quantity,
    price: line.Price,
    discount: line.Discount,
    taxRule: line.TaxRule || null,
    tax: line.Tax,
    total: line.Total,
    product: line.Product ? mapProduct(line.Product) : null,
    comment: line.Comment || null,
    supplierSku: line.SupplierSKU || null,
});

const mapSupplier = (supplier: DearPurchaseOrderSupplierBackend): Reference => ({
    id: supplier.Id,
    text: supplier.Name,
});

const mapAdditionalChargeLines = (line: DearAdditionalChargeLineBackend): DearAdditionalChargeLine => ({
    price: line.Price,
    quantity: line.Quantity,
    tax: line.Tax,
    taxRule: line.TaxRule || null,
    total: line.Total,
    discount: line.Discount || 0,
    description: line.Description || null,
    reference: line.Reference || '',
});

const mapPurchaseOrderDetails = (details?: DearPurchaseOrderDetailsBackend): DearPurchaseOrderDetails => {
    if (!details) {
        throw errorHelpers.formatError('[requestSchema.Dear] PO details is undefined.');
    }

    return {
        integrationCode: domain.IntegrationCode.DearPo,
        integrationType: domain.IntegrationType.Dear,
        number: '',
        internalId: details.InternalId,
        purchaseStatus: details.PurchaseStatus,
        contact: details.Contact || null,
        phone: details.Phone || null,
        billingAddress: details.BillingAddress || null,
        approach: details.Approach,
        terms: details.Terms || null,
        requiredByDate: details.RequiredBy || null,
        inventoryAccount: details.InventoryAccount || null,
        currencyRate: details.CurrencyRate || null,
        baseCurrency: details.BaseCurrency || null,
        supplierCurrency: details.SupplierCurrency || null,
        supplier: details.Supplier ? mapSupplier(details.Supplier) : null,
        orderNumber: details.OrderNumber || null,
        orderStatus: details.OrderStatus,
        orderDate: details.OrderDate || null,
        location: details.Location || null,
        shippingAddress: details.ShippingAddress || null,
        note: details.Note || null,
        memo: details.Memo || null,
        totalAmount: details.Total || null,
        netAmount: details.TotalBeforeTax || null,
        taxAmount: details.Tax || null,
        taxCalculation: details.TaxCalculation,
        taxRule: details.TaxRule || null,
        itemLines: details.ItemLines ? details.ItemLines.map(mapItemLine) : [],
        additionalChargeLines: details.AdditionalChargeLines
            ? details.AdditionalChargeLines.map(mapAdditionalChargeLines)
            : [],
        date: details.CreatedDate || null,
        dateLastUpdate: details.LastUpdatedDate || null,
        lockDate: null,
        lockDatePolicy: TemplateSettingsLockDatePolicy.LockApproval,
        transactionDate: null,
        url: details.Url || null,
    };
};

export const getDearRequestSpecifics = (request: backend.RequestAnswer): DearRequestSpecifics => {
    switch (request.IntegrationCode) {
        case IntegrationCode.DearPo:
            return {
                details: mapPurchaseOrderDetails(request.DearPurchaseOrderDetails),
                integrationType: domain.IntegrationType.Dear,
                integrationCode: request.IntegrationCode,
            };

        default:
            throw errorHelpers.notSupportedError();
    }
};
