import { Reference } from '@approvalmax/types';
import { intl } from '@approvalmax/utils';
import { defineMessages } from 'react-intl';

const i18nPrefix = 'reports.data.domain';
const messages = defineMessages({
    anyValueReferenceText: {
        id: `${i18nPrefix}.anyValueReferenceText`,
        defaultMessage: 'Any value',
    },
});

export enum TaxType {
    TaxExclusive = 'Tax Exclusive',
    TaxInclusive = 'Tax Inclusive',
    NoTax = 'No Tax',
}

export enum RequestResolutionOrigin {
    ResolvedResolvedRegularly = 'ResolvedResolvedRegularly',
    EnforcedExternally = 'EnforcedExternally',
    EnforcedByAdmin = 'EnforcedByAdmin',
    ResolvedAutomatically = 'ResolvedAutomatically',
    ChangedApprovers = 'ChangedApprovers',
    ResolvedExternally = 'ResolvedExternally',
}

export enum RequestsFraudulentActivity {
    None = 'None',
    PostApprovalChange = 'PostApprovalChange',
    ExternalResolving = 'ExternalResolving',
    AutomaticResolving = 'AutomaticResolving',
    AdminEnforcedResolving = 'AdminEnforcedResolving',
    DroppingOffApprovers = 'DroppingOffApprovers',
}

export enum RequestAuditReportState {
    Complete = 'Complete',
    Underway = 'Underway',
    Reserved = 'Reserved',
}

export const ANY_VALUE_REFERENCE_ID = 'Any';

export const ANY_VALUE_REFERENCE: Reference = {
    id: ANY_VALUE_REFERENCE_ID,
    text: intl.formatMessage(messages.anyValueReferenceText),
};

export enum NullableBoolean {
    Yes = 'Yes',
    No = 'No',
}

export enum RequestMatchingStatus {
    Matched = 'Matched',
    NotMatched = 'NotMatched',
}

export enum RetrospectivePurchaseOrder {
    IsRetrospective = 'IsRetrospective',
    IsNotRetrospective = 'IsNotRetrospective',
}
