import { selectors } from 'modules/common';
import { dataApi, domain, schemas, State, stateTree } from 'modules/data';
import {
    createAction,
    createAsyncAction,
    createErrorAction,
    createSelectingAction,
    ExtractActions,
} from 'modules/react-redux';
import { normalize } from 'normalizr';

export async function loadTemplates({ companyId }: { companyId: string }) {
    const result = await dataApi.templates.select({ companyId });
    const entities = normalize(result, { Templates: [schemas.templateSchema] }).entities as unknown;

    return entities as stateTree.Entities;
}

export const INITIALIZE_MODULE = 'WORKFLOW_TEMPLATES/INITIALIZE_MODULE';
export const initializeModule = () => createAction(INITIALIZE_MODULE, {});

export const ADD_TEMP_USER = 'WORKFLOW_TEMPLATES/ADD_TEMP_USER';
export const addTempUser = (newUser: domain.User) =>
    createSelectingAction(ADD_TEMP_USER, (state) => {
        return {
            companyId: selectors.navigation.getActiveCompanyId(state)!,
            newUser,
        };
    });

export const LEAVE_PAGE = 'WORKFLOW_TEMPLATES/LEAVE_PAGE';
export const leavePage = () => createAction(LEAVE_PAGE, {});

export const RELOAD_TEMPLATES = 'WORKFLOWS/RELOAD_TEMPLATES';
export const RELOAD_TEMPLATES_RESPONSE = 'WORKFLOWS/RELOAD_TEMPLATES_RESPONSE';
export const RELOAD_TEMPLATES_FAILURE = 'WORKFLOWS/RELOAD_TEMPLATES_FAILURE';
export const reloadTemplates = () =>
    createAsyncAction({
        request: (state: State) =>
            createAction(RELOAD_TEMPLATES, {
                companyId: selectors.navigation.getActiveCompanyId(state)!,
            }),

        response: async (request) => {
            const entities = await loadTemplates({
                companyId: request.companyId,
            });

            return createAction(
                RELOAD_TEMPLATES_RESPONSE,
                {
                    request,
                },
                entities
            );
        },

        failure: (error) => createErrorAction(RELOAD_TEMPLATES_FAILURE, error, {}),
    });

export type Action = ExtractActions<
    typeof addTempUser | typeof initializeModule | typeof leavePage | typeof reloadTemplates
>;
