import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowsPage.StandaloneUpgrade', {
    title: 'Stand-alone workflows',
    description: `
        ApprovalMax also enables the setup of stand-alone approval workflows
        that have no connection to an accounting platform.
        Such workflows are suitable for the approval of legal contracts,
        CapEx, expenses and reimbursements, sales quotes, etc.
    `,
    learnMore: 'Learn more',
    close: 'Close',
    tryNow: 'Try now for 14 days',
    upgradeSubscription: 'Upgrade a subscription',
});
