import { Checkbox } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { learnMoreLink } from './SectionQboFraudChangesAfter.constants';
import { messages } from './SectionQboFraudChangesAfter.messages';
import { SectionQboFraudChangesAfterProps } from './SectionQboFraudChangesAfter.types';

export const SectionQboFraudChangesAfter: FC<SectionQboFraudChangesAfterProps> = memo((props) => {
    const { company, settings, template, onChangePostApprovalTarget, readonly, qa } = props;

    const {
        NetAmount,
        VendorId,
        Payee,
        PaymentDetails,
        LineItemProduct,
        LineItemCategory,
        Location,
        LineItemClass,
        LineItemDescription,
        Currency,
    } = domain.TemplateSettingsQBooksPostApprovalChangeDetectionTarget;

    if (!settings) {
        return null;
    }

    const isQBooksExpense = template.integrationCode === domain.IntegrationCode.QBooksExpense;
    const isQBooksInvoice = template.integrationCode === domain.IntegrationCode.QBooksInvoice;

    return (
        <SettingsSection title={messages.title} learnMoreLink={learnMoreLink}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <Checkbox.Group
                    name='postApprovalTarget'
                    disabled={readonly}
                    onChange={onChangePostApprovalTarget}
                    value={settings.targets}
                    block
                >
                    <Checkbox value={NetAmount} data-qa={qa(`QBooksFraudDetectionChangesAfterSection-${NetAmount}`)}>
                        {messages.optionNetAmount({
                            companyCurrencyCode: company.defaultCurrencyText,
                        })}
                    </Checkbox>

                    {!isQBooksInvoice && (
                        <Checkbox value={VendorId} data-qa={qa(`QBooksFraudDetectionChangesAfterSection-${VendorId}`)}>
                            {messages.optionVendorId}
                        </Checkbox>
                    )}

                    {isQBooksExpense && (
                        <Checkbox value={Payee} data-qa={qa(`QBooksFraudDetectionChangesAfterSection-${Payee}`)}>
                            {messages.optionPayee}
                        </Checkbox>
                    )}

                    {isQBooksExpense && (
                        <Checkbox
                            value={PaymentDetails}
                            data-qa={qa(`QBooksFraudDetectionChangesAfterSection-${PaymentDetails}`)}
                        >
                            {messages.optionPaymentDetails}
                        </Checkbox>
                    )}

                    <Checkbox
                        value={LineItemProduct}
                        data-qa={qa(`QBooksFraudDetectionChangesAfterSection-${LineItemProduct}`)}
                    >
                        {messages.optionLineItemProduct}
                    </Checkbox>

                    {!isQBooksInvoice && (
                        <>
                            <Checkbox
                                value={LineItemCategory}
                                data-qa={qa(`QBooksFraudDetectionChangesAfterSection-${LineItemCategory}`)}
                            >
                                {messages.optionLineItemCategory}
                            </Checkbox>

                            <Checkbox
                                value={Location}
                                data-qa={qa(`QBooksFraudDetectionChangesAfterSection-${Location}`)}
                            >
                                {messages.optionLocation}
                            </Checkbox>

                            <Checkbox
                                value={LineItemClass}
                                data-qa={qa(`QBooksFraudDetectionChangesAfterSection-${LineItemClass}`)}
                            >
                                {messages.optionLineItemClass}
                            </Checkbox>
                        </>
                    )}

                    <Checkbox
                        value={LineItemDescription}
                        data-qa={qa(`QBooksFraudDetectionChangesAfterSection-${LineItemDescription}`)}
                    >
                        {messages.optionLineItemDescription}
                    </Checkbox>

                    <Checkbox value={Currency} data-qa={qa(`QBooksFraudDetectionChangesAfterSection-${Currency}`)}>
                        {messages.optionCurrency}
                    </Checkbox>
                </Checkbox.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

SectionQboFraudChangesAfter.displayName = 'SectionQboFraudChangesAfter';
