import React from 'react';

const LoginPage = React.lazy(() => import(/* webpackChunkName: "manager-pages" */ './pages/LoginPage/LoginPage'));
const SignUpPage = React.lazy(() => import(/* webpackChunkName: "manager-pages" */ './pages/SignUpPage/SignUpPage'));
const EmailConfirmationPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/EmailConfirmationPage/EmailConfirmationPage')
);
const ResetPasswordPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/ResetPasswordPage/ResetPasswordPage')
);
const VerifyEmailPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/VerifyEmailPage/VerifyEmailPage')
);
const ResetPasswordFinalStepPage = React.lazy(
    () =>
        import(/* webpackChunkName: "manager-pages" */ './pages/ResetPasswordFinalStepPage/ResetPasswordFinalStepPage')
);
const MobileInfoPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/MobileInfoPage/MobileInfoPage')
);

const ReceiptBankAuthPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/ReceiptBankAuthPage/ReceiptBankAuthPage')
);
const ProfileSetupEmailPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/ProfileSetupEmailPage/ProfileSetupEmailPage')
);
const ProfileSetupSsoPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/ProfileSetupSsoPage/ProfileSetupSsoPage')
);
const OAuth2RedirectPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/OAuth2RedirectPage/OAuth2RedirectPage')
);

const SignUpWithFinalizePage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/SignUpWithFinalizePage/SignUpWithFinalizePage')
);
const SignInWithStartPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/SignInWithStartPage/SignInWithStartPage')
);
const SignInWithStartFinalizeQboPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: "manager-pages" */ './pages/SignInWithStartFinalizeQboPage/SignInWithStartFinalizeQboPage'
        )
);
const SignInWithDisconnectPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/SignInWithDisconnectPage/SignInWithDisconnectPage')
);
const LoginWithXeroLauncherPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/LoginWithXeroLauncherPage/LoginWithXeroLauncherPage')
);

const AirwallexOauthRedirectPage = React.lazy(
    () =>
        import(/* webpackChunkName: "manager-pages" */ './pages/AirwallexOauthRedirectPage/AirwallexOauthRedirectPage')
);

const SlackAuthConnectPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/SlackAuthConnectPage/SlackAuthConnectPage')
);

const SlackAuthInstallPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/SlackAuthInstallPage/SlackAuthInstallPage')
);

const SlackAuthConnectUserPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/SlackAuthConnectUserPage/SlackAuthConnectUserPage')
);

export default [
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/register',
        component: SignUpPage,
    },
    {
        path: '/emailConfirmation',
        component: EmailConfirmationPage,
    },
    {
        path: '/resetPassword',
        component: ResetPasswordPage,
    },
    {
        path: '/resetPassVerifyEmail',
        component: ResetPasswordFinalStepPage,
    },
    {
        path: '/verifyEmail',
        component: VerifyEmailPage,
    },
    {
        path: '/mobile',
        component: MobileInfoPage,
    },
    {
        path: '/receiptBank/auth',
        component: ReceiptBankAuthPage,
    },
    {
        path: '/profileSetup/email',
        component: ProfileSetupEmailPage,
    },
    {
        path: '/profileSetup/sso',
        component: ProfileSetupSsoPage,
    },
    {
        path: '/oauth2/redirect',
        component: OAuth2RedirectPage,
    },
    {
        path: '/signUpWith/finalize',
        component: SignUpWithFinalizePage,
    },
    {
        path: '/signInWith/start',
        component: SignInWithStartPage,
    },
    {
        path: '/oauth2/startFinalizeQbo',
        component: SignInWithStartFinalizeQboPage,
    },
    {
        path: '/signInWith/disconnect',
        component: SignInWithDisconnectPage,
    },
    {
        path: '/xero-sign-in',
        component: LoginWithXeroLauncherPage,
    },
    {
        path: '/awx_oauth_redir',
        component: AirwallexOauthRedirectPage,
    },
    {
        path: '/slack/auth/connect-to-am',
        component: SlackAuthConnectPage,
    },
    {
        path: '/slack/auth/install-to-ws',
        component: SlackAuthInstallPage,
    },
    {
        path: '/slack/auth/connect-user-to-am',
        component: SlackAuthConnectUserPage,
    },
];
