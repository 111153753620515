import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { requestsApiPaths } from '../paths';
import {
    UseGetAmaxPayTransactionDetailsPathParams,
    UseGetAmaxPayTransactionDetailsResponse,
} from './useGetAmaxPayTransactionDetails.types';

export const useGetAmaxPayTransactionDetails = (
    pathParams: UseGetAmaxPayTransactionDetailsPathParams,
    queryOptions?: QueryOptions<UseGetAmaxPayTransactionDetailsResponse>
) =>
    useGetRequest<UseGetAmaxPayTransactionDetailsResponse>(requestsApiPaths.amaxPayXeroTransactionDetails, {
        ...queryOptions,
        pathParams,
        apiVersion: 'v2',
    });
