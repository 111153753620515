import { errorHelpers } from '@approvalmax/utils';

export const getLocalStorageParsedValue = <T>(key: string) => {
    try {
        const item = localStorage.getItem(key);

        return item ? (JSON.parse(item) as T) : undefined;
    } catch (error) {
        errorHelpers.captureException(error);

        return undefined;
    }
};
