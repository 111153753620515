import { Dropdown } from '@approvalmax/ui';
import { RichEditorView } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback, useState } from 'react';

import { getTextContentFromHTML } from './HTMLCell.helpers';
import { HTMLContent, Root } from './HTMLCell.styles';
import { HTMLCellProps } from './HTMLCell.types';

const HTLMCell: FC<HTMLCellProps> = memo((props) => {
    const { value, ...otherProps } = props;

    const [isOpen, setOpen] = useState(false);

    const textContent = getTextContentFromHTML(value);
    const firstTextContent =
        textContent.length === 0 ? '' : `${textContent[0]}${textContent.length !== 1 ? '...' : ''}`;
    const open = useCallback(() => {
        setOpen(true);
    }, []);

    const close = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <Dropdown
            panelFlow='center'
            button={(ref) => (
                <HTMLContent ref={ref} onClick={open}>
                    {firstTextContent}
                </HTMLContent>
            )}
            isOpen={isOpen}
            onRequestClose={close}
        >
            <Root {...otherProps}>
                <RichEditorView html={value} highlightUrls />
            </Root>
        </Dropdown>
    );
});

export default HTLMCell;
