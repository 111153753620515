import { Box, Button, Divider, Grid, Progress, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import moment from 'moment';
import { FC, Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { noValue } from 'shared/constants';

import { getIntegrationIcon } from '../../SyncDropdownContent.helpers';
import { messages } from './ConnectedCompany.messages';
import { ConnectedCompanyProps } from './ConnectedCompany.types';

export const ConnectedCompany: FC<ConnectedCompanyProps> = memo((props) => {
    const { company, onSyncIntegration, syncInProgress } = props;

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const IntegrationIcon = getIntegrationIcon(company.integration?.integrationType);

    const [now] = useState(moment().toISOString());

    const syncForCompany = useMemo(
        () => (syncInProgress || []).find((item) => item.companyId === company.id),
        [company.id, syncInProgress]
    );

    const hasActiveSync = useMemo(
        () =>
            syncForCompany?.syncProgress.some(
                (templateSync) => templateSync.status === domain.IntegrationSyncStatus.InProgress
            ),
        [syncForCompany?.syncProgress]
    );

    const companySyncDisabled = company.isReadonly || isButtonDisabled || hasActiveSync;

    useEffect(() => {
        setIsButtonDisabled(false);
    }, [hasActiveSync]);

    const getNow = useCallback(() => {
        return moment.max(moment(now), moment());
    }, [now]);

    const onSyncClick = useCallback(() => {
        setIsButtonDisabled(true);

        onSyncIntegration(company.id);
    }, [onSyncIntegration, company.id]);

    return (
        <Box spacing='16 24 0'>
            <Grid gridTemplateColumns='auto 1fr auto' alignItems='center' gap={12}>
                {IntegrationIcon && <IntegrationIcon width={44} height={44} />}

                <Text font='body' fontSize='small' fontWeight='medium' color='midnight100'>
                    {company.displayName}
                </Text>

                <Button size='small' color='blue80' disabled={companySyncDisabled} onClick={onSyncClick}>
                    {messages.buttonSync}
                </Button>
            </Grid>

            {syncForCompany?.syncProgress.map((syncItem, index) => (
                <Fragment key={syncItem.templateId}>
                    <Grid
                        gridTemplateColumns='auto minmax(160px, auto)'
                        justifyContent='space-between'
                        alignItems='center'
                        gap={16}
                        minHeight={36}
                    >
                        <Text font='label' fontSize='small' color='midnight100'>
                            {selectors.template.getTemplateDisplayNameByCode(syncItem.templateIntegrationCode, true)}
                        </Text>

                        {
                            {
                                [domain.IntegrationSyncStatus.InProgress]: (
                                    <Grid
                                        gridTemplateColumns='auto auto'
                                        justifyContent='space-between'
                                        alignItems='center'
                                        gap={16}
                                    >
                                        <Text font='label' fontSize='small' color='midnight70'>
                                            {messages.inProgressStatusText}
                                        </Text>

                                        <Progress shape='circle' size='xsmall' />
                                    </Grid>
                                ),
                                [domain.IntegrationSyncStatus.Error]: (
                                    <Text font='label' fontSize='small' fontWeight='medium' color='red100'>
                                        {messages.errorStatusText}
                                    </Text>
                                ),
                                [domain.IntegrationSyncStatus.Done]: (
                                    <Text font='label' fontSize='small' color='midnight100'>
                                        {syncItem.lastSyncDate
                                            ? moment.min(moment(syncItem.lastSyncDate), moment()).from(getNow())
                                            : noValue}
                                    </Text>
                                ),
                            }[syncItem.status]
                        }
                    </Grid>

                    {index !== syncForCompany?.syncProgress.length - 1 && <Divider />}
                </Fragment>
            ))}
        </Box>
    );
});

ConnectedCompany.displayName = 'ConnectedCompany';
