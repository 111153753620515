import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { profileApiPaths } from '../paths';
import { UseSetProfilePasswordData } from './useSetProfilePassword.types';

export const useSetProfilePassword = (
    mutationOptions?: MutationOptions<never, RequestDataParams<UseSetProfilePasswordData>>
) => {
    return useMutateLegacy<RequestDataParams<UseSetProfilePasswordData>>(profileApiPaths.setPassword, {
        mutationOptions,
    });
};
