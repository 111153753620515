import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css, keyframes } from 'styled-components';

import { DropzoneProps } from './Dropzone.types';

const showZoneAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const Zone = styled.div<StyledMods<Mods<'active' | 'hide' | 'drag'> & Pick<DropzoneProps, 'height' | 'width'>>>`
    border-radius: ${theme.radius.medium};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: ${theme.color.white100};
    border: 1px solid ${theme.color.silver80};
    transition: ${theme.duration.medium}ms ease-in-out;

    ${mods.hide.true`
        animation: ${showZoneAnimation} ${theme.duration.medium}ms ease-in-out;
        ${mixins.position.absoluteFill};
        z-index: 100;
        
        ${mods.drag.false`
            display: none;
        `};
    `};

    ${mods.drag.true`
        border-color: ${theme.color.blue70};
        border-style: dashed;
    `};

    ${mods.active.true`
        background-color: ${theme.color.silver100};
        border-color: ${theme.color.blue70};
        border-style: solid;
        box-shadow: 0 0 0 1px ${theme.color.blue70};
    `};

    ${mods('height')(
        (value) => css`
            height: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('width')(
        (value) => css`
            width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};
`;

export const Root = styled.div<
    StyledMods<Pick<DropzoneProps, 'size' | 'height' | 'width' | 'bordered'> & Mods<'hide' | 'drag'>>
>`
    position: relative;

    ${mods.hide.true`
        ${mods.drag.true`
            ${mods.size.small` min-height: 122px; `};
            ${mods.size.medium` min-height: 134px; `};
        `};
    `};

    ${mods('height')(
        (value) => css`
            height: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('width')(
        (value) => css`
            width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods.bordered.true`
        border-radius: ${theme.radius.medium};
        border: 1px dashed ${theme.color.blue70};
    `};
`;
