import { actions, selectors } from 'modules/common';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useHelpStripe = (helpItemId: string | null = null) => {
    const dispatch = useDispatch();

    const hiddenItems = useSelector(selectors.userPreferences.getHiddenHelpItems);

    const onShow = useCallback(() => {
        if (helpItemId) {
            dispatch(actions.updateHiddenHelpItem(helpItemId, false));
        }
    }, [dispatch, helpItemId]);

    const onHide = useCallback(() => {
        if (helpItemId) {
            dispatch(actions.updateHiddenHelpItem(helpItemId, true));
        }
    }, [dispatch, helpItemId]);

    return {
        onShow,
        onHide,
        isVisible: helpItemId && !hiddenItems.includes(helpItemId),
    };
};
