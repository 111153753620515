/**
 * Developer: Stepan Burguchev
 * Date: 3/3/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

export enum ErrorType {
    Required = 'Required',
    Email = 'Email',
    Password = 'Password',
}

export enum PasswordValidationCriterion {
    Length = 'Length',
    UseNumber = 'UserNumber',
    UseUpperLowerCase = 'UseUpperLowerCase',
    UseSymbol = 'UseSymbol',
}
