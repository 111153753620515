import { Popup } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { discardSettingsPopup } from '../../actions';
import { getActiveSettings } from '../../selectors/pageSelectors';
import WorkflowSettingsPopupContent from '../WorkflowSettingsPopupContent/WorkflowSettingsPopupContent';

const WorkflowSettingsPopup = memo(() => {
    const dispatch = useDispatch();
    const activeSettings = useSelector(getActiveSettings);

    const closeSettingsPopup = useCallback((open: boolean) => !open && dispatch(discardSettingsPopup()), [dispatch]);

    return (
        <Popup open={Boolean(activeSettings)} onToggle={closeSettingsPopup} size='large' preventAutoClose>
            <WorkflowSettingsPopupContent />
        </Popup>
    );
});

WorkflowSettingsPopup.displayName = 'WorkflowSettingsPopup';

export default WorkflowSettingsPopup;
