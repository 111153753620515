import { useGetRequestLegacy } from 'shared/data/api';

import { integrationsApiPaths } from '../paths';
import { mapData } from './useGetNetSuiteCacheStatus.map';
import {
    NetSuiteCacheObject,
    UseGetNetSuiteCacheStatusParams,
    UseGetNetSuiteCacheStatusResponse,
    UseGetNetSuiteCacheStatusResponseBackend,
} from './useGetNetSuiteCacheStatus.types';

export const useGetNetSuiteCacheStatus = (
    params: UseGetNetSuiteCacheStatusParams,
    queryOptions?: {
        refetchInterval?: (data: NetSuiteCacheObject[]) => number | false;
    }
) => {
    return useGetRequestLegacy<UseGetNetSuiteCacheStatusResponseBackend, UseGetNetSuiteCacheStatusResponse>(
        integrationsApiPaths.getNetSuiteCacheStatus,
        params,
        {
            mapData,
            queryOptions,
        }
    );
};
