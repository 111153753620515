import type { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';
import type { UseAmaxPayConsentCreateResponse } from 'shared/data/queries/paymentServices/useAmaxPayConsentCreate/useAmaxPayConsentCreate.types';

import { paymentServicesApiPaths } from '../paths';
import {
    UseReconnectAmaxPayOpenBankingBanksPathParams,
    UseReconnectAmaxPayOpenBankingBanksResponse,
} from './useReconnectAmaxPayOpenBankingBankAccounts.types';

export const useReconnectAmaxPayOpenBankAccounts = (
    mutationOptions: MutationOptions<
        UseReconnectAmaxPayOpenBankingBanksResponse,
        RequestDataParams<{}, UseReconnectAmaxPayOpenBankingBanksPathParams>
    > = {}
) =>
    useMutateLegacy<
        RequestDataParams<{}, UseReconnectAmaxPayOpenBankingBanksPathParams>,
        UseAmaxPayConsentCreateResponse
    >(paymentServicesApiPaths.reconnectAmaxPayOpenBankingBankAccounts, {
        mutationOptions,
    });
