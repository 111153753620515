import { DataTable, font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 16px;
`;

export const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
`;

export const HeaderTitle = styled.div`
    margin-left: 10px;

    ${font(13, '#000', 'semibold')}
`;

export const TableContainer = styled.div`
    display: flex;
    height: 300px;
`;

export const StyledTable = styled(DataTable)`
    flex: 1;
    overflow: hidden;
`;

export const LoadingBarContainer = styled.div`
    position: absolute;
    top: 0px;
    width: 100%;
`;

export const StyledDefaultColumnHeader = styled(DataTable.DefaultColumnHeader)`
    ${font(11, '#565656', 'bold')}
`;
