import { Dropdown } from '@approvalmax/ui';
import { Flex, Text } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { InfoIconGray, InfoIconGreen, InfoIconOrange, InfoIconRed } from 'modules/sprites';
import { memo, useMemo } from 'react';
import { useToggle } from 'react-use';

import { PaymentDetailsInfo } from './components/PaymentDetailsInfo/PaymentDetailsInfo';
import { DropdownPanel, InfoIconContainer } from './PaymentDetailsCell.styles';
import { PaymentDetailsCellProps } from './PaymentDetailsCell.types';

export const PaymentDetailsCell = memo<PaymentDetailsCellProps>((props) => {
    const {
        status,
        bankName,
        companyId,
        requestId,
        billRequestId,
        counterpartyId,
        total,
        paymentDetailsId,
        skipBillRequest,
        paymentDetailsDiscrepancy,
    } = props;

    const [isDropdownOpen, toggleDropdown] = useToggle(false);

    const icon = useMemo(() => {
        switch (status) {
            case domain.PaymentDetailsPaymentStatus.NotChecked:
                return <InfoIconGray />;

            case domain.PaymentDetailsPaymentStatus.NeverPaid:
                return <InfoIconOrange />;

            case domain.PaymentDetailsPaymentStatus.PaidPreviously:
                return <InfoIconGreen />;

            case domain.PaymentDetailsPaymentStatus.PaymentDetailsChanged:
                return <InfoIconRed />;
        }
    }, [status]);

    return (
        <Flex inline alignItems='center' spacing='4'>
            <Dropdown
                isOpen={isDropdownOpen}
                onRequestClose={toggleDropdown}
                button={<InfoIconContainer onClick={toggleDropdown}>{icon}</InfoIconContainer>}
                panelFlow='center'
            >
                <DropdownPanel>
                    <PaymentDetailsInfo
                        status={status}
                        companyId={companyId}
                        requestId={requestId}
                        billRequestId={billRequestId}
                        counterpartyId={counterpartyId}
                        paymentDetailsId={paymentDetailsId}
                        skipBillRequest={skipBillRequest}
                        paymentDetailsDiscrepancy={paymentDetailsDiscrepancy}
                    />
                </DropdownPanel>
            </Dropdown>

            <Text font='label' fontWeight={total ? 'medium' : 'regular'}>
                {bankName}
            </Text>
        </Flex>
    );
});

PaymentDetailsCell.displayName = 'PaymentDetailsCell';
