import { messages } from './CompanyAlerts.messages';
import { CompanyAlertsProps } from './CompanyAlerts.types';

export const getAlertData = (company: CompanyAlertsProps['company']): { text: string; qaId: string } => {
    const { flags, isReadonly } = company;

    if (flags.isRetired) return { text: messages.retired, qaId: 'retired' };

    if (flags.isExpired && isReadonly && flags.isTrialLicense)
        return { text: messages.trialExpiredAndReadonly, qaId: 'trialExpired' };

    if (flags.isExpired && isReadonly) return { text: messages.expiredAndReadonly, qaId: 'subscriptionExpired' };

    return { text: '', qaId: '' };
};
