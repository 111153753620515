import styled from 'styled-components';

export const Root = styled.div`
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    min-width: 300px;
    max-width: 680px;
    max-height: 170px;
    padding: 8px 16px 8px;
    overflow-y: auto;
`;

export const HTMLContent = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
