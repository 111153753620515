import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { myAccountApiPaths } from '../paths';
import { UseTakeOverOrganisationData, UseTakeOverOrganisationGetParams } from './useTakeOverOrganisation.types';

export const useTakeOverOrganisation = () => {
    return useMutateLegacy<RequestDataParams<UseTakeOverOrganisationData, undefined, UseTakeOverOrganisationGetParams>>(
        myAccountApiPaths.takeOverOrganisation,
        {
            apiSource: 'myAccount',
        }
    );
};
