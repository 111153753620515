import { RequestDataParams } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { UseUpdateBudgetSettingsRequestData } from './useUpdateBudgetSettings.types';

export const useUpdateBudgetSettings = () => {
    const queryClient = useQueryClient();

    return useMutateLegacy<RequestDataParams<UseUpdateBudgetSettingsRequestData>>(budgetsApiPaths.setBudgetSettings, {
        mutationOptions: {
            onSettled: async (_data, _error, requestData) => {
                await queryClient.invalidateQueries([
                    budgetsApiPaths.getBudgetSettings,
                    { companyId: requestData.data.companyId },
                ]);
            },
        },
    });
};
