import { dateTimeHelpers } from '@approvalmax/utils';
import moment from 'moment';

import { textFieldMonthFormat } from './DateTimePicker.constants';
import type { DateTimePickerProps, ValueOrString } from './DateTimePicker.types';

export const parseEnteredTextFieldValue = (text: string | undefined) => {
    if (!text || (text && !text.trim())) {
        return undefined;
    }

    const newValue = moment(text, textFieldMonthFormat);

    if (!newValue.isValid()) {
        return undefined;
    }

    return newValue.toISOString();
};

export const formatTextFieldValue = <Value extends Date | string | undefined>(value: Value): ValueOrString<Value> => {
    if (!value) {
        return undefined as ValueOrString<Value>;
    }

    const date = moment(new Date(value));

    return date.format(textFieldMonthFormat) as ValueOrString<Value>;
};

/**
 * this is more robust way to format date-time, because moment.format('lll')
 * has a bug w/ 12h -> 24h formatting, so we should avoid using it
 */
export const formatTextFieldValueToHumanReadable = <Value extends Date | string | undefined>(
    value: Value
): ValueOrString<Value> => {
    if (!value) {
        return undefined as ValueOrString<Value>;
    }

    return new Date(value).toLocaleString(undefined, {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }) as ValueOrString<Value>;
};

export const parseValueToDate = <Value extends DateTimePickerProps['value']>(
    value: Value
): Value extends Date | string ? Date : undefined =>
    (value ? moment(value).toDate() : undefined) as Value extends Date | string ? Date : undefined;

/**
 * We are accepting a Date withing a local TZ, and we have to shift the date
 * so that it look like a UTC date but in local TZ
 */
export const convertLocalToUTC = (value: Date | string | null | undefined) => {
    if (!value) {
        return;
    }

    // we use must use get `getTimezoneOffset` from value to ensure that
    // TZ reflects the one from date. TZ of `dateTime-local` input can
    // differ local TZ
    const offset = typeof value === 'string' ? new Date().getTimezoneOffset() : value.getTimezoneOffset();

    return dateTimeHelpers.addOffset(parseValueToDate(value), offset).toDate();
};

export const convertUTCToLocal = (value: Date | undefined) => {
    // we use must use get `getTimezoneOffset` from value to ensure that
    // TZ reflects the one from date. TZ of `dateTime-local` input can
    // differ local TZ
    return value ? dateTimeHelpers.subtractOffset(value, value.getTimezoneOffset()).toDate() : value;
};

/**
 * Change the year, month, day, but not hh:mm:ss
 */
export const normalizeDatePickerValue = (
    value: string | Date | [Date | null, Date | null] | null | undefined,
    prevValue: Date | null | undefined
) => {
    const preparedValue = Array.isArray(value) ? value[0] : value;

    if (preparedValue) {
        const date = moment(preparedValue);

        if (prevValue) {
            prevValue?.setFullYear(date.year());
            prevValue?.setMonth(date.month());
            prevValue?.setDate(date.date());

            return prevValue;
        }

        return date.toDate();
    }

    return undefined;
};
