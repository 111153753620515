import { domain, schemas } from 'modules/data';
import { useGetRequestLegacy } from 'shared/data/api';

import { workflowsApiPaths } from '../paths';
import {
    UseGetWorkflowVersionDocumentFieldsParams,
    UseGetWorkflowVersionDocumentFieldsResponse,
} from './useGetWorkflowVersionDocumentFields.types';

export const useGetWorkflowVersionDocumentFields = (params: UseGetWorkflowVersionDocumentFieldsParams) => {
    return useGetRequestLegacy<UseGetWorkflowVersionDocumentFieldsResponse, domain.DocumentField[]>(
        workflowsApiPaths.getWorkflowVersionDocumentFields,
        params,
        {
            mapData: (value) => schemas.template.mapDocumentFields(value.data || []),
        }
    );
};
