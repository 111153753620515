import { domain } from 'modules/data';

import { ConditionTypeValue, conditionTypeValues } from './NetSuiteVendorCreationConditionCell.types';

export const conditionTypeToConditionTypeValue = (
    condition: domain.ExactValuesCondition | domain.AlwaysTrueCondition | domain.ServerCondition
): ConditionTypeValue => {
    switch (condition.conditionType) {
        case null:
            return conditionTypeValues.AllVendors;

        case domain.ConditionType.ExactValuesCondition:
            return conditionTypeValues.ExactValues;

        case domain.ConditionType.NegativeExactValuesCondition:
            return conditionTypeValues.NegativeExactValues;

        case domain.ConditionType.ServerCondition:
            switch (condition.serverConditionType) {
                case domain.ServerConditionType.AllContacts:
                    return conditionTypeValues.AllVendors;
            }
    }

    return conditionTypeValues.AllVendors;
};
