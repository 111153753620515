import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionQboFraudChangesAfter', {
    title: 'Fraud detection — changes made after approval',
    subtitle:
        'Which changes made in QuickBooks Online after the approval in ApprovalMax should trigger the marking as "Changed after approval" and a notification to the Organisation\'s Administrators?',
    optionNetAmount: 'Net total amount (more than 0.1 {companyCurrencyCode} change)',
    optionVendorId: 'Vendor',
    optionPayee: 'Payee',
    optionPaymentDetails: 'Payment details',
    optionLineItemProduct: 'Product/Service',
    optionLineItemCategory: 'Category',
    optionLocation: 'Location',
    optionLineItemClass: 'Class',
    optionLineItemDescription: 'Description (more than 20% difference)',
    optionCurrency: 'Currency',
});
