import { domHelpers, hooks } from '@approvalmax/utils';
import { FocusEventHandler, forwardRef, type KeyboardEventHandler, useCallback, useRef } from 'react';

import { timeBlockSize } from '../../DigitalClock.constants';
import { TimeBlock } from '../TimeBlock/TimeBlock';
import { StyledGrid } from './TimeList.styles';
import { TimeListProps } from './TimeList.types';

export const TimeList = forwardRef<HTMLUListElement, TimeListProps>((props, ref) => {
    const { value, values, maxHeight, onChange } = props;

    const rootRef = useRef<HTMLUListElement>(null);
    const composedRefs = hooks.useComposedRefs(ref, rootRef);
    const childRefs = useRef<HTMLLIElement[]>([]);

    const selectTimeItem = useCallback(
        (index: number) => {
            if (childRefs.current[index]) {
                onChange(values[index].value);
            }
        },
        [values, onChange]
    );

    const setRef = (index: number) => (node: HTMLLIElement) => {
        childRefs.current[index] = node;
    };

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLUListElement>>(
        (event) => {
            if (!rootRef.current) {
                return;
            }

            const currentFocus = rootRef.current.ownerDocument.activeElement;

            if (!currentFocus) {
                return;
            }

            switch (event.key) {
                case 'ArrowUp':
                case 'ArrowLeft': {
                    event.preventDefault();

                    // this fixes the backward Chrome scrolling issue.
                    // it scrolls selected element to the middle of the list
                    rootRef.current?.scrollBy({ top: -timeBlockSize });

                    domHelpers.moveFocus(rootRef.current, currentFocus, true, domHelpers.previousFocusItem);
                    break;
                }

                case 'ArrowDown':
                case 'ArrowRight': {
                    event.preventDefault();

                    domHelpers.moveFocus(rootRef.current, currentFocus, true, domHelpers.nextFocusItem);
                    break;
                }

                case 'Enter': {
                    // stop propagation because date time input also listens `Enter` pressing
                    event.stopPropagation();

                    const index = values.findIndex((item) => item.value === currentFocus.getAttribute('data-time'));

                    selectTimeItem(index);

                    const sibling = rootRef.current.nextElementSibling;

                    if (sibling?.getAttribute('role') !== 'listbox') {
                        break;
                    }

                    const selectedItem = sibling?.querySelector('[aria-selected="true"]');

                    // move to the next time list and focus first or active element there
                    if (selectedItem) {
                        domHelpers.focusElement(selectedItem);
                    } else if (sibling?.firstElementChild) {
                        domHelpers.focusElement(sibling.firstElementChild);
                    }

                    break;
                }

                default:
                    break;
            }
        },
        [values, selectTimeItem]
    );

    const handleFocus = useCallback<FocusEventHandler<HTMLUListElement>>((event) => {
        if (event.target !== rootRef.current) {
            return;
        }

        // select first or selected element on getting focus
        domHelpers.moveFocus(rootRef.current, undefined, true, domHelpers.nextFocusItem);
    }, []);

    return (
        <StyledGrid
            role='listbox'
            tabIndex={-1}
            ref={composedRefs}
            $maxHeight={maxHeight}
            onKeyDown={handleKeyDown}
            onFocusCapture={handleFocus}
        >
            {values.map((timeItem, index) => (
                <TimeBlock
                    key={timeItem.value}
                    ref={setRef(index)}
                    value={timeItem.value}
                    selected={timeItem.value === value}
                    disabled={timeItem.disabled}
                    title={timeItem.title}
                    tabIndex={timeItem.value === value ? 0 : !value && index === 0 ? 0 : -1}
                    onChange={onChange}
                />
            ))}
        </StyledGrid>
    );
});

TimeList.displayName = 'TimeList';
