import { createPopupReducer } from 'modules/react-redux';

import { CANCEL_COMPANY_CONNECTION_ERROR_POPUP } from '../../actions';
import anotherCompanyIntegratedPopupReducer from './companyConnectionErrorPopup/anotherCompanyIntegratedPopupReducer';
import companyMismatchPopupReducer from './companyConnectionErrorPopup/companyMismatchPopupReducer';

export default createPopupReducer(
    CANCEL_COMPANY_CONNECTION_ERROR_POPUP,
    anotherCompanyIntegratedPopupReducer,
    companyMismatchPopupReducer
);
