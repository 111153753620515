import {
    DatePickerProps,
    MonthModeValue,
    YearModeValue,
} from '@approvalmax/ui/src/components/DatePicker/DatePicker.types';
import moment from 'moment';

import { textFieldMonthFormat, textFieldYearFormat } from './DatePicker.constants';
import { useDatePickerValue } from './DatePicker.hooks';

export const parseEnteredTextFieldValue = (text: string | undefined, noUTCTransform: boolean | undefined) => {
    if (!text || (text && !text.trim())) return undefined;

    const newValue = noUTCTransform ? moment(text, textFieldMonthFormat) : moment.utc(text, textFieldMonthFormat);

    if (!newValue.isValid()) return undefined;

    return newValue.toISOString();
};

export const formatTextFieldValue = (
    value: ReturnType<typeof useDatePickerValue>['datePickerValue'] | undefined,
    view: DatePickerProps['view'] | undefined,
    noUTCTransform: boolean | undefined
) => {
    if (!value) return;

    const date = noUTCTransform ? moment(value) : moment.utc(value);

    switch (view) {
        case 'year':
            return date.format(textFieldYearFormat);

        default:
            return date.format(textFieldMonthFormat);
    }
};

export const formatCalendarValue = (value?: ReturnType<typeof useDatePickerValue>['datePickerValue']) => {
    return value ? moment(value).toDate() : undefined;
};

export const formatDatePickerValue = (
    value: Date | [Date | null, Date | null] | null | MonthModeValue | YearModeValue | undefined,
    noUTCTransform: boolean | undefined
) => {
    const preparedValue = Array.isArray(value) ? value[0] : value;

    if (!preparedValue) return undefined;

    if (preparedValue instanceof Date) {
        const date = moment(preparedValue);

        if (noUTCTransform) {
            return date.toISOString();
        }

        return moment
            .utc({
                year: date.year(),
                month: date.month(),
                date: date.date(),
            })
            .toISOString();
    }

    if (preparedValue && typeof preparedValue === 'object') {
        if (noUTCTransform) {
            return moment({
                year: preparedValue.year,
                month: preparedValue.month - 1,
            }).toISOString();
        }

        return moment
            .utc({
                year: preparedValue.year,
                month: preparedValue.month - 1,
            })
            .toISOString();
    }

    if (noUTCTransform) {
        return moment(preparedValue).toISOString();
    }

    return moment.utc(preparedValue).toISOString();
};
