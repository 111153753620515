import { ExactAsyncConditionBaseProps } from '../ExactAsyncCondition/ExactAsyncCondition.types';

export type NetSuiteVendorCreationConditionCellProps = ExactAsyncConditionBaseProps;

export const conditionTypeValues = {
    AllVendors: 'AllVendors',
    ExactValues: 'ExactValues',
    NegativeExactValues: 'NegativeExactValues',
} as const;

export type ValueOf<T> = T[keyof T];

export type ConditionTypeValue = ValueOf<typeof conditionTypeValues>;
