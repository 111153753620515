import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { workflowsApiPaths } from '../paths';
import {
    UseDeleteReviewerV1BetaData,
    UseDeleteReviewerV1BetaPathParams,
    UseDeleteReviewerV1BetaResponse,
} from './useDeleteReviewerV1Beta.types';

export const useDeleteReviewerV1Beta = (
    mutationOptions?: MutationOptions<
        UseDeleteReviewerV1BetaResponse,
        RequestDataParams<UseDeleteReviewerV1BetaData, UseDeleteReviewerV1BetaPathParams>
    >
) => {
    return useMutate<
        RequestDataParams<UseDeleteReviewerV1BetaData, UseDeleteReviewerV1BetaPathParams>,
        UseDeleteReviewerV1BetaResponse
    >(workflowsApiPaths.workflowsReviewerV1, {
        apiVersion: 'v2',
        mutationOptions,
        method: 'delete',
    });
};
