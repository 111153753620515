import { Checkbox, Spacing } from '@approvalmax/ui/src/components';
import { arrayHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';

import { RenderCellOptions } from '../../../../config/matrixDefinitions.shared';
import { MatrixType } from '../../../../types/matrix';
import { ExactAsyncConditionCell } from '../ExactAsyncConditionCell/ExactAsyncConditionCell';
import { messages } from './XeroSupplierEditingConditionCell.messages';

const XeroSupplierEditingConditionCell: FC<RenderCellOptions> = memo((props) => {
    const {
        rule,
        field,
        readonly,
        lineId,
        condition,
        onConditionChange,
        integrationCode,
        templateSubmitters,
        requiredFieldIds,
        matrixType,
    } = props;

    const onChange = useCallback(
        (checked: boolean) => {
            const newCondition = {
                ...condition,
                allowEditing: checked,
            };

            onConditionChange(lineId, rule, field, newCondition);
        },
        [condition, field, lineId, onConditionChange, rule]
    );

    const handleConditionChange: RenderCellOptions['onConditionChange'] = useCallback(
        (lineId, rule, field, newCondition) => {
            onConditionChange(lineId, rule, field, { ...newCondition, allowEditing: Boolean(condition.allowEditing) });
        },
        [condition.allowEditing, onConditionChange]
    );

    return (
        <div>
            <ExactAsyncConditionCell
                rule={rule}
                field={field}
                readonly={readonly}
                integrationCode={integrationCode}
                lineId={lineId}
                onConditionChange={handleConditionChange}
                condition={condition as domain.ExactValuesCondition}
                templateSubmitters={
                    matrixType !== MatrixType.Requester ? templateSubmitters : arrayHelpers.emptyArray()
                }
                requiredFieldIds={requiredFieldIds}
                matrixType={matrixType}
            />

            <Spacing height={4} />

            <Checkbox
                onChange={!readonly ? onChange : undefined}
                checked={Boolean(condition.allowEditing)}
                disabled={readonly}
                size='xsmall'
            >
                {messages.allowToEditBankAccount}
            </Checkbox>
        </div>
    );
});

export default XeroSupplierEditingConditionCell;
