import { PartyImage } from '@approvalmax/ui';
import { Button, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { cancelActivePopup } from '../../actions';
import { messages } from './TrialPeriodStartedPopup.messages';
import { TrialPeriodStartedPopupProps } from './TrialPeriodStartedPopup.types';

export const TrialPeriodStartedPopup: FC<TrialPeriodStartedPopupProps> = memo((props) => {
    const { isOpen, onClose } = props;

    const dispatch = useDispatch();

    const handleToggle = useCallback(
        (isOpen: boolean) => {
            if (!isOpen) {
                onClose();
            }
        },
        [onClose]
    );

    const closePopup = useCallback(() => {
        dispatch(cancelActivePopup());
    }, [dispatch]);

    return (
        <Popup size='xsmall' open={isOpen} onToggle={handleToggle}>
            <Popup.Body spacing='16 32 32'>
                <Flex inline direction='column' alignItems='center' spacing='0'>
                    <PartyImage width={100} height={100} />

                    <Text
                        font='headline'
                        fontSize='small'
                        fontWeight='medium'
                        color='midnight80'
                        textAlign='center'
                        spacing='24 0'
                    >
                        {messages.successMessage}
                    </Text>

                    <Button onClick={closePopup} size='large' color='blue80'>
                        {messages.close}
                    </Button>
                </Flex>
            </Popup.Body>
        </Popup>
    );
});

TrialPeriodStartedPopup.displayName = 'TrialPeriodStartedPopup';
