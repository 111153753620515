import { defineMessages } from '@approvalmax/utils';

export const resolutionColumnMessages = defineMessages('app.ReportsPage.ColumnLineItemDefinitions.resolutionColumn', {
    onReview: 'On review',
    onApproval: 'On approval',
    rejected: 'Rejected',
    approved: 'Approved',
    cancelled: 'Cancelled',
    onHold: 'On hold',
});

export const qboPOStatusColumnMessages = defineMessages('app.ReportsPage.ColumnLineItemDefinitions.qboPOStatusColumn', {
    qboPurchaseOrderStatusOptionOpen: 'Open',
    qboPurchaseOrderStatusOptionClosed: 'Closed',
});

export const messages = defineMessages('app.ReportsPage.ColumnLineItemDefinitions', {
    qboTaxColumn: 'Amounts are',
    taxColumn: 'Amounts are (Tax)',
    taxAmount: 'Tax amount',
    vendor: 'Vendor',
    contact: 'Contact',
    customer: 'Customer',
    employee: 'Employee',
    payeeCustomer: 'Payee: Customer',
    payeeEmployee: 'Payee: Employee',
    paymentAccount: 'Payment account',
    paymentType: 'Payment type',
    paymentMethod: 'Payment method',
    paymentStatus: 'Payment status',
    requestCurrency: 'Currency',
    documentCreatedDate: 'Created date',
    documentDate: 'Date',
    serviceDate: 'Service date',
    documentDateColumnTitle: 'Document date as shown in the accounting system',
    location: 'Location',
    name: 'Name',
    nameColumnTitle:
        'Approval request full name. For Xero and QuickBooks requests it is derived from key request parameters such supplier, number of reference',
    resolutionBatch: 'Batch approval status',
    resolution: 'Status',
    resolutionColumnTitle: 'Status of approval request in the workflow (open / approved / rejected / cancelled)',
    isBilled: 'Billed',
    isBilledColumnTitle: 'Indicates if Purchase Order has been marked as Fully Billed in ApprovalMax or in Xero',
    deliveryDate: 'Delivery date',
    deliveryDateColumnTitle: 'Delivery date for the Purchase Order',
    dueDate: 'Due date',
    dueDateColumnTitle: 'Document due date as shown in the accounting system',
    isPaid: 'Paid',
    isPaidColumnTitle: 'Indicates if Bill has been marked as Paid in Xero',
    plannedPaymentDate: 'Planned payment date',
    plannedPaymentDateColumnTitle: 'Planned payment date set in Xero',
    productService: 'Product/Service',
    item: 'Item',
    description: 'Description',
    itemDescriptionTitle: 'Item Description',
    quantity: 'Qty',
    rate: 'Rate',
    unitPrice: 'Unit price',
    discount: 'Discount',
    poNumber: 'Purchase Order number',
    class: 'Class',
    category: 'Category',
    account: 'Account',
    taxRate: 'Tax rate',
    lineItemTrackings1: 'Tracking Category 1',
    lineItemTrackings2: 'Tracking Category 2',
    amount: 'Amount',
    totalAmount: 'Total amount',
    customers: 'Customers',
    lineItemIsMatched: 'Line item matched',
    matched: 'Matched',
    poStatus: 'Purchase order status',
    batchPaymentStatus: 'Batch payment status',
    beneficiary: 'Beneficiary',
    billCurrency: 'Bill currency',
    sourceCurrency: 'Source currency',
    purpose: 'Purpose',
    wagesOrSalary: 'Wages or salary',
    xeroBankAccount: 'Xero bank account',
    feeAmount: 'Fee amount',
    sourceAmount: 'Source amount',
    bill: 'Bill',
    billAmount: 'Bill amount',
    feeType: 'Fee type',
    paymentReference: 'Payment reference',
    contactColumnTitle: 'Supplier / Vendor in case of accounts payable and Customers in case of accounts receivable.',

    isAcceptedColumnName: 'Customer decision',
    isAcceptedColumnTitle: 'Customer decision',
    isAcceptedTrue: 'Accepted',
    isAcceptedFalse: 'Declined',

    sumOfAllocationsName: 'Sum of all Allocations',
    sumOfAllocationsTitle: 'Sum of all Allocations',

    purchaseOrderBalanceColumnName: 'PO Remaining Balance',
    purchaseOrderBalanceColumnTitle:
        'Amount of the Purchase Order minus amount of all matched open and approved Bills. Indicates what is left to be paid.',

    matchedBillsColumnName: 'Matched Bills',
    matchedBillsColumnTitle: 'Bills matched with the Purchase Order',

    matchedBillsBalanceName: 'Bill remaining balance',
    matchedBillsBalanceTitle: 'Bill remaining balance',

    matchedPurchaseOrdersColumnName: 'Matched Purchase Orders',
    matchedPurchaseOrdersColumnTitle: 'Purchase Orders matched with the Bill',

    grnColumnName: 'GRN status',
    grnColumnTitle: 'GRN status',
    grnNotReceived: 'Not received',
    grnPartiallyReceived: 'Partially received',
    grnFullyReceived: 'Fully received',

    retrospectivePurchaseOrderColumnName: 'Is Retrospective',
    retrospectivePurchaseOrderColumnTitle:
        'This field shows if there is at least one Bill matched with the PO that has been dated prior the PO date',

    netRemainingBalanceName: 'Net remaining balance',
    netRemainingBalanceTitle: 'Net remaining balance',

    grossRemainingBalanceName: 'Gross remaining balance',
    grossRemainingBalanceTitle: 'Gross remaining balance',

    netAmountName: 'Net amount',
    netAmountTitle: 'Net amount',

    grossAmountName: 'Gross amount',
    grossAmountTitle: 'Gross amount',

    paymentAmount: 'Payment amount',
    paymentDate: 'Payment date',
    bankAccount: 'Bank account',
    paymentDetails: 'Payment details',

    amaxPayBatchPaymentStatusNone: 'None',
    amaxPayBatchPaymentStatusAwaitingPayment: 'Awaiting payment',
    amaxPayBatchPaymentStatusProcessing: 'Processing',
    amaxPayBatchPaymentStatusPartiallyPaid: 'Partially paid',
    amaxPayBatchPaymentStatusPaid: 'Paid',
    amaxPayBatchPaymentStatusFailed: 'Failed',
    amaxPayBatchPaymentStatusCancelled: 'Cancelled',
    amaxPayBatchPaymentBillCurrency: 'Bill currency',
    amaxPayLinePaymentStatus: 'Payment status',
    amaxPayLinePaymentStatusNone: 'None',
    amaxPayLinePaymentStatusReadyToPay: 'Ready to pay',
    amaxPayLinePaymentStatusProcessing: 'Processing',
    amaxPayLinePaymentStatusPaid: 'Paid',
    amaxPayLinePaymentStatusFailed: 'Failed',
    amaxPayLinePaymentStatusCancelled: 'Cancelled',

    narrationColumnName: 'Narration',
    narrationColumnTitle: 'Narration',

    debitAmountName: 'Debit amount',
    debitAmountTitle: 'Debit amount',

    creditAmountName: 'Credit amount',
    creditAmountTitle: 'Credit amount',

    showOnCashBasisReportsColumnName: 'Show journal on cash basis reports',
    showOnCashBasisReportsColumnTitle: 'Show journal on cash basis reports',

    yes: 'Yes',
    no: 'No',

    totalCreditTaxColumnName: 'Total credit tax',
    totalCreditTaxColumnTitle: 'Total credit tax',

    totalDebitTaxColumnName: 'Total debit tax',
    totalDebitTaxColumnTitle: 'Total debit tax',

    debitTaxColumnName: 'Debit tax',
    debitTaxColumnTitle: 'Debit tax',

    creditTaxColumnName: 'Credit tax',
    creditTaxColumnTitle: 'Credit tax',

    taxNameColumnName: 'Tax name',
    taxNameColumnTitle: 'Tax name',

    numberColumnName: 'Number',
    numberColumnTitle: 'Number',
});
