import { QueryOptions } from '@approvalmax/data';
import { domain } from 'modules/data';
import { useGetRequestLegacy } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { UseGetBudgetsRequestParams, UseGetBudgetsResponse } from './useGetBudgets.types';

export const useGetBudgets = (
    params: UseGetBudgetsRequestParams,
    integrationType: domain.IntegrationType,
    queryOptions?: QueryOptions<UseGetBudgetsResponse>
) => {
    return useGetRequestLegacy<UseGetBudgetsResponse>(budgetsApiPaths.select, params, {
        queryOptions,
        pathParams: {
            integrationType,
        },
    });
};
