import './link.scss';

import React, { FC, PropsWithChildren, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

const bem = bemFactory.block('ui-link');

export interface LinkProps extends PropsWithChildren {
    href: string;
    targetBlank?: boolean;
    className?: string;
    onClick?: () => void;
    title?: string;
}

const Link: FC<LinkProps> = (props) => {
    const { targetBlank, href, className, children, onClick, title } = props;

    let blankProps;

    if (targetBlank) {
        blankProps = {
            target: '_blank',
            rel: 'noopener noreferrer',
        };
    }

    return (
        <a
            href={href}
            className={bem.add(className)()}
            title={title}
            {...blankProps}
            onClick={useCallback(
                (e: React.MouseEvent<any>) => {
                    if (onClick) {
                        onClick();
                    }

                    e.stopPropagation();
                },
                [onClick]
            )}
        >
            {children}
        </a>
    );
};

export default Link;
