import ImageParty from 'modules/components/ImageIcons/ImageParty';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { useTwoFaEnablingWizardContext } from '../../TwoFaEnablingWizard.context';
import { messages } from './StepSuccess.messages';
import { ButtonFinish, Description, Root, Title } from './StepSuccess.styles';

const qa = bemFactory.qa('pro-two-fa-setup-success-popup');

const StepSuccess = memo(() => {
    const { hideWithSuccessWizard } = useTwoFaEnablingWizardContext();

    return (
        <Root qa={qa()}>
            <ImageParty />

            <Title>{messages.title}</Title>

            <Description>{messages.description}</Description>

            <ButtonFinish execute={hideWithSuccessWizard}>{messages.button}</ButtonFinish>
        </Root>
    );
});

export default StepSuccess;
