import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { ComponentType } from 'react';
import { useSelector } from 'react-redux';

import { useProtectedAuth } from '../hooks/useProtectedAuth';
import { useProtectedIntegrationType } from '../hooks/useProtectedIntegrationType';
import { useProtectedQueryParams } from '../hooks/useProtectedQueryParams';
import { useProtectedRole } from '../hooks/useProtectedRole';

interface WithProtectedProps {
    Page: ComponentType;
    roles?: domain.CompanyUserRole[];
    queryParams?: string[];
    integrationTypes?: domain.IntegrationType[];
}

export const withProtected = ({ Page, roles, integrationTypes, queryParams }: WithProtectedProps) => {
    return () => {
        const authenticated = useProtectedAuth();
        const isRoleAllowed = useProtectedRole(roles);
        const queryParamsMatch = useProtectedQueryParams(queryParams);
        const isIntegrationTypeAllowed = useProtectedIntegrationType(integrationTypes);
        const profile = useSelector(selectors.profile.findProfile);

        if (!authenticated || !profile || !isRoleAllowed || !queryParamsMatch || !isIntegrationTypeAllowed) {
            return null;
        }

        return <Page />;
    };
};
