import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { mapData } from './useRequestTFADisablingEmailCode.map';
import {
    UseRequestTFADisablingEmailCodeRequest,
    UseRequestTFADisablingEmailCodeResponse,
    UseRequestTFADisablingEmailCodeResponseBackend,
} from './useRequestTFADisablingEmailCode.types';

export const useRequestTFADisablingEmailCode = () => {
    return useMutateLegacy<
        RequestDataParams<UseRequestTFADisablingEmailCodeRequest>,
        UseRequestTFADisablingEmailCodeResponseBackend,
        UseRequestTFADisablingEmailCodeResponse
    >(twoFaApiPaths.disablingRequestEmailCode, { mapData });
};
