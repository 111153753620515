import { FC, PropsWithChildren } from 'react';
import { SortableContainer as sortableContainer } from 'react-sortable-hoc';

const SortableContainer: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    return <>{children}</>;
};

export default sortableContainer<PropsWithChildren>(SortableContainer);
