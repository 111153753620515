import { Flex, Link, Text } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { amplitudeService } from 'services/amplitude';

import { ReactComponent as AppleAppStore } from './assets/apple-app-store.svg';
import { ReactComponent as GooglePlay } from './assets/google-play.svg';
import { appUrls } from './MobileApps.constants';
import { messages } from './MobileApps.messages';

export const MobileApps = memo(() => {
    const location = useLocation();

    const sendDecisionToAmplitude = useCallback(
        (os: string) => {
            amplitudeService.sendData(`help: click on mobile app link on "${location.pathname}" screen`, {
                'mobile app type': os,
            });
        },
        [location.pathname]
    );

    const handleDownloadIOS = useCallback(() => {
        sendDecisionToAmplitude('ios');
    }, [sendDecisionToAmplitude]);

    const handleDownloadAndroid = useCallback(() => {
        sendDecisionToAmplitude('android');
    }, [sendDecisionToAmplitude]);

    return (
        <Flex inline alignItems='center' justifyContent='center'>
            <Text font='body'>{messages.intro}</Text>

            <Flex inline wrap={false}>
                <Link font='body' onClick={handleDownloadIOS} external target='_blank' href={appUrls.ios}>
                    <AppleAppStore width={105} height={30} />
                </Link>

                <Link font='body' onClick={handleDownloadAndroid} external target='_blank' href={appUrls.android}>
                    <GooglePlay width={105} height={30} />
                </Link>
            </Flex>
        </Flex>
    );
});

MobileApps.displayName = 'MobileApps';
