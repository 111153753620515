import { Guid } from '@approvalmax/types';
import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { defineMessages } from 'react-intl';

import { PAGE_ID } from '../config';
import { Page } from '../reducers/pageReducer';

const i18nPrefix = 'requestForm/selectors/pageSelectors';
const messages = defineMessages({
    createRequestPageTitleText: {
        id: `${i18nPrefix}.createRequestPageTitleText`,
        defaultMessage: 'Create {templateName} in "{companyName}"',
    },
    editRequestPageTitleText: {
        id: `${i18nPrefix}.editRequestPageTitleText`,
        defaultMessage: 'Edit {templateName} in "{companyName}"',
    },
    newRequestDocumentTitleText: {
        id: `${i18nPrefix}.newRequestDocumentTitleText`,
        defaultMessage: 'New {requestName}',
    },
});

export function getPage(state: State): Page {
    return selectors.page.getPage(state, PAGE_ID);
}

export function checkRequestLoaded(state: State, requestId: Guid): boolean {
    const isActive = selectors.navigation.getActivePageId(state) === PAGE_ID;

    if (!isActive) {
        return false;
    }

    return getRequest(state).id === requestId;
}

export function getDocumentTitle(state: State): string {
    const request = getRequest(state);
    const templateName =
        selectors.template.getTemplateDisplayNameByCode(request.integrationCode) || request.template.text;

    if (request.statusV2 === domain.RequestStatusV2.Draft) {
        return intl.formatMessage(messages.newRequestDocumentTitleText, {
            requestName: templateName,
        });
    } else {
        return request.displayName || templateName;
    }
}

export function hasChanges(state: State) {
    return getPage(state).modified;
}

export function getRequest<TRequest extends domain.Request = domain.Request>(state: State): TRequest {
    return getPage(state).request as any as TRequest;
}

export function getContext(state: State) {
    return getPage(state).context;
}

export function isLoading(state: State) {
    return selectors.ui.isBlocked(state);
}

export function getActivePopupId(state: State): string | null {
    const activePopup = getPage(state).activePopup;

    return activePopup ? activePopup.id : null;
}

export function getActivePopup<TPopupData>(state: State): TPopupData {
    return getPage(state).activePopup as any as TPopupData;
}

export function getNewAttachments(state: State) {
    return getPage(state).newAttachments;
}

export function getNewSupplierAttachments(state: State) {
    return getPage(state).newSupplierAttachments;
}

export function getShowValidationErrors(state: State) {
    return getPage(state).showValidationErrors;
}

export function getSupplierAttachments(state: State) {
    return getPage(state).supplierAttachments;
}
