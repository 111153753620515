import { Button, Flex, Text, Tooltip } from '@approvalmax/ui/src/components';
import { ColumnDefinition } from '@approvalmax/ui/src/components/Table/Table.types';
import { errorHelpers, intl, numberHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { noValue } from 'shared/constants';
import { getPath, Path } from 'urlBuilder';

import { getRequesterFromRequest, renderDate, renderPaymentDate } from './RequestList.helpers';
import { messages } from './RequestList.messages';
import { ColumnId } from './RequestList.types';

export const friendlyNameColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.FriendlyName,
    name: messages.friendlyNameColumnText,
    value: (request) => (
        <Flex inline alignItems='center'>
            <Tooltip
                activator={
                    <Button
                        as='a'
                        target='_blank'
                        uppercase={false}
                        size='small'
                        href={getPath(Path.request, request.id, request.companyId)}
                        noPadding
                    >
                        <Text font='body' ellipsis={1}>
                            {request.displayName}
                        </Text>
                    </Button>
                }
            >
                {request.displayName}
            </Tooltip>
        </Flex>
    ),
    width: 300,
};

export const statusColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.Status,
    name: messages.statusColumnText,
    value: (request: selectors.types.ExpandedRequest) => {
        switch (request.statusV2) {
            case domain.RequestStatusV2.OnApproval:
                return messages.onApproval;

            case domain.RequestStatusV2.Rejected:
                return messages.rejected;

            case domain.RequestStatusV2.Approved:
                return messages.approved;

            case domain.RequestStatusV2.Cancelled:
                return messages.cancelled;

            case domain.RequestStatusV2.OnReview:
                return messages.onReview;

            case domain.RequestStatusV2.OnHold:
                return messages.onHold;

            case domain.RequestStatusV2.Draft:
            default:
                throw errorHelpers.throwError('Unexpected value of request.statusV2');
        }
    },
};

export const dateColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.Date,
    name: messages.dateColumnText,
    value: (request) => renderDate(request),
};

export const requesterColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.Requester,
    name: messages.requesterColumnText,
    value: (request: selectors.types.ExpandedRequest) => {
        const requester = getRequesterFromRequest(request);

        return (
            <Flex inline alignItems='center'>
                <Tooltip
                    activator={
                        <Text font='body' ellipsis={1}>
                            {requester}
                        </Text>
                    }
                >
                    {requester}
                </Tooltip>
            </Flex>
        );
    },
    width: 125,
};

export const amountColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.Amount,
    name: messages.amountColumnText,
    headerTextAlign: 'right',
    textAlign: 'right',
    value: (request: selectors.types.ExpandedRequest) => {
        const { amount } = request;

        const amountText = numberHelpers.isNumber(amount)
            ? intl.formatCurrency(amount, request.currency)
            : messages.requestAmountEmptyValue({
                  currency: request.currency,
              });

        return (
            <Text font='body' wordBreak='break-word'>
                {amountText}
            </Text>
        );
    },
};

export const workflowStepColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.WorkflowStep,
    name: messages.workflowStepColumnText,
    value: (request) => {
        const workflow =
            !request.reviewStep || request.reviewStep.isCompleted
                ? request.activeStep?.name || noValue
                : messages.reviewStepText;

        return (
            <Flex inline alignItems='center'>
                <Tooltip
                    activator={
                        <Text font='body' ellipsis={1}>
                            {workflow}
                        </Text>
                    }
                >
                    {workflow}
                </Tooltip>
            </Flex>
        );
    },
    width: 100,
};

export const paymentDateColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.Date,
    name: messages.paymentDateColumnText,
    value: (request) => renderPaymentDate(request),
};
