import { Mods, mods, theme } from '@approvalmax/theme';
import { Box } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

export const Click = styled.div`
    flex-grow: 1;
`;

export const Content = styled(Box)<StyledMods<Mods<'active' | 'checked'>>>`
    border: 1px solid ${theme.color.white100};

    &:hover {
        cursor: pointer;
        background: ${theme.color.silver80};
        ${mods.checked.false`
            border: 1px solid ${theme.color.silver80};
        `}
    }

    ${mods(['active', 'checked'] as const)(({ active, checked }) => {
        if (!checked) {
            return css`
                border: 1px solid ${theme.color.white100};
            `;
        }

        return css`
            border: 1px solid ${active ? theme.color.green100 : theme.color.midnight60};
        `;
    })}
`;

export const StatusBadge = styled.div<StyledMods<Mods<'color' | 'hide'>>>`
    height: fit-content;
    padding: 4px 8px;
    ${mods.color(
        (value) => css`
            border: 1px solid ${theme.color[value]};
            color: ${theme.color[value]};
        `
    )};
    ${mods.hide.true`
        opacity: 0;
    `};
`;
