import { domain } from 'modules/data';

export const promoTemplateSettings = {
    billMatchingSettings: {
        manualMatchingEnabled: true,
        allowApprovalOfNotMatchedBills: domain.CompanyMatchingSettingsBillApprovalPolicy.Always,
    },
};

export const qbooksBillToPoLearnMore =
    'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-quickbooks-online/matching';
