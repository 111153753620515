import { ReloadIcon } from '@approvalmax/ui';
import { memo } from 'react';

import { messages } from './ExchangeRatesRetry.messages';
import { ButtonText, Root, StyledButton } from './ExchangeRatesRetry.styles';
import { ExchangeRatesRetryProps } from './ExchangeRatesRetry.types';

const ExchangeRatesRetry = memo<ExchangeRatesRetryProps>((props) => {
    const { isLoading, onRetry } = props;

    return (
        <Root>
            <StyledButton disabled={isLoading} execute={onRetry}>
                <ReloadIcon />

                <ButtonText>{messages.retryRate}</ButtonText>
            </StyledButton>
        </Root>
    );
});

export default ExchangeRatesRetry;
