import { Flex } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

const WorkflowDetailsLoading = memo(() => {
    return (
        <Flex inline wrap={false} spacing='80'>
            <Flex inline direction='column' spacing='24'>
                <Flex inline>
                    <Skeleton width={230} height={28} borderRadius={4} />
                </Flex>

                <Flex inline>
                    <Skeleton width={200} height={12} count={3} />
                </Flex>

                <Flex inline>
                    <Skeleton width={120} height={20} />
                </Flex>

                <Flex inline spacing='12'>
                    <Skeleton width={30} height={30} borderRadius={50} />

                    <Skeleton width={100} height={30} />
                </Flex>

                <Flex inline>
                    <Skeleton width={230} height={180} />
                </Flex>
            </Flex>

            <Flex inline direction='column' spacing='24'>
                <Flex inline>
                    <Skeleton width={230} height={28} borderRadius={4} />
                </Flex>

                <Flex inline>
                    <Skeleton width={200} height={20} />
                </Flex>

                <Flex inline>
                    <Skeleton width={120} height={20} />
                </Flex>

                <Flex inline spacing='12'>
                    <Skeleton width={30} height={30} borderRadius={50} />

                    <Skeleton width={100} height={30} />
                </Flex>

                <Flex inline spacing='12'>
                    <Skeleton width={30} height={30} borderRadius={50} />

                    <Skeleton width={100} height={30} />
                </Flex>

                <Flex inline>
                    <Skeleton width={230} height={30} />
                </Flex>

                <Flex inline>
                    <Skeleton width={130} height={20} />
                </Flex>

                <Flex inline>
                    <Skeleton width={230} height={12} count={3} />
                </Flex>
            </Flex>

            <Flex inline direction='column' spacing='24'>
                <Flex inline>
                    <Skeleton width={230} height={28} borderRadius={4} />
                </Flex>

                <Flex inline>
                    <Skeleton width={200} height={20} />
                </Flex>

                <Flex inline>
                    <Skeleton width={120} height={20} />
                </Flex>

                <Flex inline spacing='12'>
                    <Skeleton width={30} height={30} borderRadius={50} />

                    <Skeleton width={100} height={30} />
                </Flex>

                <Flex inline>
                    <Skeleton width={230} height={30} />
                </Flex>

                <Flex inline>
                    <Skeleton width={130} height={20} />
                </Flex>

                <Flex inline>
                    <Skeleton width={230} height={12} count={3} />
                </Flex>
            </Flex>

            <Flex inline direction='column' spacing='24'>
                <Flex inline>
                    <Skeleton width={230} height={28} borderRadius={4} />
                </Flex>

                <Flex inline>
                    <Skeleton width={200} height={12} count={3} />
                </Flex>
            </Flex>
        </Flex>
    );
});

export default WorkflowDetailsLoading;
