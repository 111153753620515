import { uAbsoluteFill } from '@approvalmax/ui';
import { Flex } from '@approvalmax/ui/src/components';
import styled from 'styled-components';

export const ContentLoading = styled(Flex)`
    ${uAbsoluteFill()}
    z-index: -1;
    margin-left: auto;
    margin-right: auto;
`;
