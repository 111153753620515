import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseTakeOffHoldData, UseTakeOffHoldPathParams, UseTakeOffHoldResponse } from './useTakeOffHold.types';

export const useTakeOffHold = (
    mutationOptions?: MutationOptions<
        UseTakeOffHoldResponse,
        RequestDataParams<UseTakeOffHoldData, UseTakeOffHoldPathParams>
    >
) => {
    return useMutate<RequestDataParams<UseTakeOffHoldData, UseTakeOffHoldPathParams>, UseTakeOffHoldResponse>(
        requestsApiPaths.takeOffHold,
        {
            apiVersion: 'v2',
            mutationOptions,
        }
    );
};
