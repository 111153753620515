import { useSelector } from 'modules/react-redux';
import { memo } from 'react';

import { isPageReadonly } from '../../selectors/pageSelectors';
import { AutoApprovalStep } from './components/AutoApprovalStep/AutoApprovalStep';
import { ReviewStep } from './components/ReviewStep/ReviewStep';
import { StepSelector } from './components/StepSelector/StepSelector';

export const SingletonSteps = memo(() => {
    const isReadonly = useSelector(isPageReadonly);

    return (
        <>
            {!isReadonly && <StepSelector />}

            <ReviewStep />

            <AutoApprovalStep />
        </>
    );
});

SingletonSteps.displayName = 'SingletonSteps';
