import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('twoFactorAuth.SetupStartHardEnforcement', {
    title: 'Enable two-factor authentication now',
    descriptionAdministratorEnforcement:
        'To continue using ApprovalMax you must set up two-factor authentication. This is mandatory as we’re following Xero’s requirements. You will not be able to log in to your ApprovalMax account until you complete 2FA setup.',
    descriptionCompanyEnforcement:
        'Your organisation’s administrator has decided to enable two-factor authentication (2FA) for the whole organisation.  You will not be able to log in to your ApprovalMax account until you complete 2FA setup. Two-factor authentication provides an extra layer of protection for your account and your organisation. Set up 2FA now.',
    button: 'Set up Two-Factor Authentication',
    logOut: 'Log out',
    moreInformation:
        'For more information, please, contact your administrator or our <supportlink>support team</supportlink>.',
});
