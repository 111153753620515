import { Route } from 'modules/page';
import React from 'react';

const ReportsListPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './containers/ReportsListPage')
);
const LineItemReportsListPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './containers/LineItemReportsListPage')
);
const ReportPage = React.lazy(() => import(/* webpackChunkName: "manager-pages" */ './containers/ReportPage'));
const LineItemReportPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './containers/LineItemReportPage')
);

const routes: Route[] = [
    {
        path: '/:companyId/reports',
        component: ReportsListPage,
    },
    {
        path: '/:companyId/reports/request',
        component: ReportsListPage,
    },
    {
        path: '/:companyId/reports/request/:reportId',
        component: ReportPage,
    },

    {
        path: '/:companyId/reports/lineItem',
        component: LineItemReportsListPage,
    },
    {
        path: '/:companyId/reports/lineItem/:reportId',
        component: LineItemReportPage,
    },
];

export default routes;
