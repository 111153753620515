import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { integrationsApiPaths } from '../paths';
import { NetSuiteCompanyConnectCheckRequest } from './useCheckNetSuiteConnection.types';

export const useCheckNetSuiteConnection = () => {
    return useMutateLegacy<RequestDataParams<NetSuiteCompanyConnectCheckRequest>>(
        integrationsApiPaths.checkNetSuiteConnection
    );
};
