import { FC, memo } from 'react';

import Dropdown from '../../../Dropdown/Dropdown';
import { useDropdownOpen } from './DropdownMenu.hooks';
import { DropdownMenuProps } from './DropdownMenu.types';

/**
 * Dropdown menu for the Select component
 */
const DropdownMenu: FC<DropdownMenuProps> = memo((props) => {
    const { children, activator, initOpen, onOpen, open, ...restProps } = props;

    const [dropdownOpen, setDropdownOpen] = useDropdownOpen({ open, onOpen, initOpen });

    return (
        <Dropdown
            activator={activator}
            open={dropdownOpen}
            onOpen={setDropdownOpen}
            isClicking={false}
            hasArrow={false}
            initOpen={initOpen}
            {...restProps}
        >
            {children}
        </Dropdown>
    );
});

DropdownMenu.displayName = 'DropdownMenu';

export default DropdownMenu;
