import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { organisationsApiPaths } from '../paths';
import {
    UseActivateTrialOfAllFeaturesData,
    UseActivateTrialOfAllFeaturesGetParams,
} from './useActivateTrialOfAllFeatures.types';

export const useActivateTrialOfAllFeatures = () => {
    return useMutateLegacy<
        RequestDataParams<UseActivateTrialOfAllFeaturesData, undefined, UseActivateTrialOfAllFeaturesGetParams>
    >(organisationsApiPaths.activateTrialOfAllFeatures, {
        apiSource: 'myAccount',
    });
};
