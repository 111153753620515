import { Action, ActionMeta } from '../types/Action';

export interface TypedCreateActionFn<TEntities> {
    <T extends string, P, M extends ActionMeta>(
        type: T,
        payload?: P,
        entities?: TEntities,
        meta?: M
    ): Action<T, P, M, TEntities>;
}

export function createAction<T extends string, P, M extends ActionMeta, TEntities>(
    type: T,
    payload?: P,
    entities?: TEntities,
    meta?: M
): Action<T, P, M, TEntities> {
    let result: any = {
        type,
        payload: payload || {},
    };

    if (entities) {
        result.entities = entities;
    }

    if (meta) {
        result.meta = meta;
    }

    return result;
}
