import { Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { ReferenceCellProps } from './ReferenceCell.types';

export const ReferenceCell = memo<ReferenceCellProps>((props) => {
    const { value, className } = props;

    return (
        <Text font='body' className={className} ellipsis={1}>
            {value ? value.text : null}
        </Text>
    );
});

ReferenceCell.displayName = 'ReferenceCell';
