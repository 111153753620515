import { errorHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';

import { MatrixType } from '../../../../types/matrix';
import { messages } from './MatrixLine.messages';
import { MatrixLineProps } from './MatrixLine.types';

export const useMatrixLine = (props: Pick<MatrixLineProps, 'matrix' | 'line' | 'template'>) => {
    const { matrix, line, template } = props;

    const showRemoveRuleAction = line.rules.length > 1;
    const matrixType = matrix.type;

    switch (matrixType) {
        case MatrixType.Approval:
            return {
                ifText: messages.ifTextApprovalMatrix,
                orIfText: messages.orTextApprovalMatrix,
                removeUserText: messages.removeUserTextApprovalMatrix,
                removeUserTitle: messages.removeUserTitleApprovalMatrix,
                allowMultipleRules: !(
                    template.integrationCode === domain.IntegrationCode.XeroContact ||
                    template.integrationCode === domain.IntegrationCode.QBooksVendor
                ),
                showRemoveUser: true,
                showRemoveRuleAction,
                showCopyRule: true,
            };

        case MatrixType.Editor:
            return {
                ifText: messages.ifTextEditorMatrix,
                orIfText: messages.orTextEditorMatrix,
                removeUserText: messages.removeUserTextEditorMatrix,
                removeUserTitle: messages.removeUserTitleEditorMatrix,
                showRemoveUser: true,
                showRemoveRuleAction,
                allowMultipleRules: false,
                showCopyRule: true,
            };

        case MatrixType.Requester:
            return {
                ifText: messages.ifTextRequesterMatrix,
                orIfText: messages.orTextRequesterMatrix,
                removeUserText: messages.removeUserTextRequesterMatrix,
                removeUserTitle: messages.removeUserTitleRequesterMatrix,
                showRemoveUser: true,
                showRemoveRuleAction,
                allowMultipleRules: false,
                showCopyRule: true,
            };

        case MatrixType.AutoApproval:
            return {
                ifText: messages.ifTextRequesterMatrix,
                orIfText: messages.orTextRequesterMatrix,
                removeUserText: messages.removeUserTextRequesterMatrix,
                removeUserTitle: messages.removeUserTitleRequesterMatrix,
                showRemoveUser: false,
                showRemoveRuleAction: true,
                allowMultipleRules: false,
                showCopyRule: true,
            };

        case MatrixType.Reviewer:
            return {
                ifText: messages.ifTextReviewerMatrix,
                orIfText: messages.orTextReviewerMatrix,
                removeUserText: messages.removeUserTextEditorMatrix,
                removeUserTitle: messages.removeUserTitleEditorMatrix,
                showRemoveUser: true,
                showRemoveRuleAction,
                allowMultipleRules: true,
                showCopyRule: true,
            };

        case MatrixType.Editing:
            return {
                ifText: messages.ifTextEditingMatrix,
                orIfText: '',
                removeUserText: '',
                removeUserTitle: '',
                showRemoveUser: false,
                showRemoveRuleAction: false,
                allowMultipleRules: false,
                showCopyRule: matrix.data.length > 1,
            };

        default:
            throw errorHelpers.assertNever(matrixType);
    }
};
