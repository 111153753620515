import { notFoundError } from './notFoundError';

/**
 * Throws NotFoundError. The exception should be thrown when a requested object could not be found.
 * For example: we looked up in the database and could find a person with requested id.
 * @param {String} [message='Object not found'] Error message.
 * */
export const throwNotFoundError = (message?: string): never => {
    throw notFoundError(message);
};
