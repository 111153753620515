import { immutable } from 'modules/immutable';

import { Action, LOAD_PAGE_DATA } from '../actions';
import activePopupReducer, { ActivePopup } from './page/activePopupReducer';
import loadingReducer from './page/loadingReducer';

export interface Page {
    loading: boolean;
    activePopup: ActivePopup;
}

const INITIAL_STATE = immutable<Page>({
    loading: false,
    activePopup: undefined,
});

export default (state: Page = INITIAL_STATE, action: Action): Page => {
    if (action.type === LOAD_PAGE_DATA) {
        state = INITIAL_STATE;
    }

    return {
        loading: loadingReducer(state.loading, action),
        activePopup: activePopupReducer(state.activePopup, action),
    };
};
