import { RequestDataParams } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { notificationService } from 'services/notification';
import { useMutateLegacy } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UseDeleteCompanyData } from './useDeleteCompany.types';

export const useDeleteCompany = () => {
    const queryClient = useQueryClient();

    return useMutateLegacy<RequestDataParams<UseDeleteCompanyData>>(companiesApiPaths.delete, {
        mutationOptions: {
            onSuccess: (_response, requestData) => {
                queryClient.invalidateQueries([companiesApiPaths.select, { companyId: requestData.data.companyId }]);
            },
            onError: (error) => {
                notificationService.showErrorToast(error.message);
            },
        },
    });
};
