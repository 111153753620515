import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('profile.AllFeatureTrialPaywallPopup.FeaturesList', {
    advanced: 'Advanced',
    premium: 'Premium',
    qboNote: '*Priority support is available on the Premium plan, but not included in the trial.',
    xeroNote: 'The features below are available on the Premium plan, but not included in the trial.',
    allAdvancedFeatures: 'All Advanced features',
    addOnsTitle: 'Add-ons',
});
