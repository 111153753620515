import { createPath } from 'history';
import { selectors } from 'modules/common';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { routingService } from 'services/routing';
import { getPath, Path } from 'urlBuilder';

export const useProtectedAuth = () => {
    const authenticated = useSelector(selectors.session.authenticated);
    const location = useLocation();

    useEffect(() => {
        if (!authenticated) {
            routingService.replace(getPath(Path.login, { redirectPath: createPath(location) }));
        }
    }, [authenticated, location]);

    return authenticated;
};
