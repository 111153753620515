import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { profileApiPaths } from '../paths';
import { UseProfileEditData } from './useProfileEdit.types';

export const useProfileEdit = (mutationOptions?: MutationOptions<never, RequestDataParams<UseProfileEditData>>) => {
    return useMutateLegacy<RequestDataParams<UseProfileEditData>>(profileApiPaths.edit, {
        mutationOptions,
    });
};
