import { useDocumentTitle } from './useDocumentTitle';
import { useNoCompanies } from './useNoCompanies';
import { useNonExistentCompanyError } from './useNonExistentCompanyError';
import { usePageError } from './usePageError';
import { useProtectedAuth } from './useProtectedAuth';
import { useProtectedIntegrationType } from './useProtectedIntegrationType';
import { useProtectedQueryParams } from './useProtectedQueryParams';
import { useProtectedRole } from './useProtectedRole';

export const hooks = {
    usePageError,
    useProtectedQueryParams,
    useProtectedAuth,
    useProtectedRole,
    useProtectedIntegrationType,
    useNonExistentCompanyError,
    useNoCompanies,
    useDocumentTitle,
};
