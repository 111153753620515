import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useMutateLegacy } from 'shared/data';

import { profileApiPaths } from '../paths';
import {
    UseStartResetPasswordData,
    UseStartResetPasswordResponse,
    UseStartResetPasswordResponseBackend,
} from './useStartResetPassword.types';

export const useStartResetPassword = (
    mutationOptions?: MutationOptions<UseStartResetPasswordResponse, RequestDataParams<UseStartResetPasswordData>>
) => {
    return useMutateLegacy<
        RequestDataParams<UseStartResetPasswordData>,
        UseStartResetPasswordResponseBackend,
        UseStartResetPasswordResponse
    >(profileApiPaths.startResetPassword, {
        mapData: objectHelpers.pascalCaseToCamelCase,
        mutationOptions,
    });
};
