import { Table } from '@approvalmax/ui/src/components';
import xor from 'lodash/xor';
import { selectors } from 'modules/common';
import { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { columnDefinitions } from './UsersList.config';
import { InviteUser, UsersListProps } from './UsersList.types';

export const UsersList: FC<UsersListProps> = memo((props) => {
    const { users, checkedUsers, setCheckedUsers, onChangeRole } = props;

    const company = useSelector(selectors.navigation.getActiveCompany);
    const allUsers = useSelector(selectors.user.getUsers);

    const inviteUsers = useMemo(() => {
        return users.map(({ id, role }) => {
            const user = allUsers.find((user) => user.id === id)!;
            const status = selectors.company.getCompanyUserStatus(company, id);

            return {
                ...user,
                role,
                status,
                statusText: selectors.company.getCompanyUserStatusText(status),
            };
        });
    }, [allUsers, company, users]);

    const columns = useMemo(() => columnDefinitions({ onChangeRole }), [onChangeRole]);

    const onRowClick = useCallback(
        (user: InviteUser) => {
            setCheckedUsers(xor(checkedUsers, [user.id]));
        },
        [checkedUsers, setCheckedUsers]
    );

    return (
        <Table
            checkedItems={checkedUsers}
            onCheckedItemsChange={setCheckedUsers}
            data={inviteUsers}
            columns={columns}
            headerColor='white100'
            onRowClick={onRowClick}
        />
    );
});

UsersList.displayName = 'UsersList';
