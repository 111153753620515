import { Drawer } from '@approvalmax/ui/src/components';
import { ComponentProps, FC, memo, useCallback } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { NavigationProps } from './Navigation.types';

export const Navigation: FC<NavigationProps> = memo((props) => {
    const { onClick, ...otherProps } = props;

    const { pathname } = useLocation();
    const { push } = useHistory();

    const getActiveStatus = useCallback((to: string) => Boolean(matchPath(pathname, to)), [pathname]);

    const handleClick = useCallback<Required<ComponentProps<typeof Drawer.Navigation>>['onClick']>(
        (to) => {
            const isOnSamePage = Boolean(
                matchPath(pathname, {
                    path: to,
                    exact: true,
                })
            );

            if (!to || isOnSamePage) {
                return;
            }

            push(to);
            onClick?.();
        },
        [onClick, pathname, push]
    );

    return <Drawer.Navigation {...otherProps} onClick={handleClick} getActiveStatus={getActiveStatus} />;
});

Navigation.displayName = 'Navigation';
