import { errorHelpers, hooks } from '@approvalmax/utils';
import { constants } from 'modules/common';
import { domain } from 'modules/data';
import { useMemo, useState } from 'react';

import { ExpandedMatrixLine } from '../../../../selectors/pageSelectors';
import { ActiveMatrixData } from '../../../../types/activeMatrixData';
import { ActiveWorkflow } from '../../../../types/activeWorkflow';
import { MatrixType } from '../../../../types/matrix';
import { messages } from './Matrix.messages';
import { FilteredMatrixLine } from './Matrix.types';

const { netSuiteConstants } = constants;

export const useUserColumnTitle = (matrixType: MatrixType) => {
    switch (matrixType) {
        case MatrixType.Approval:
            return messages.approvers;

        case MatrixType.Reviewer:
        case MatrixType.Editor:
            return messages.reviewers;

        case MatrixType.Requester:
            return messages.requesters;

        case MatrixType.AutoApproval:
            return messages.ruleDescription;

        case MatrixType.Editing:
            return messages.editors;

        default:
            throw errorHelpers.assertNever(matrixType);
    }
};

export const useUnusedFields = ({
    integrationType,
    fields,
    matrix,
    template,
}: {
    integrationType: domain.IntegrationType;
    fields: domain.Field[];
    matrix: ActiveMatrixData<ExpandedMatrixLine>;
    template: ActiveWorkflow;
}) => {
    return useMemo(() => {
        if (matrix.type === MatrixType.AutoApproval || integrationType === domain.IntegrationType.None) {
            return fields.filter(
                (f) =>
                    f.systemPurpose === domain.FieldSystemPurpose.General &&
                    matrix.generalFieldOrder.every((fieldId) => f.id !== fieldId)
            );
        }

        if (integrationType === domain.IntegrationType.NetSuite) {
            const result = fields.filter(
                (field) =>
                    field.systemPurpose === domain.FieldSystemPurpose.NetSuiteCustom &&
                    !field.isArchived &&
                    field.workflows?.some((workflow) => workflow.workflowId === template.id && workflow.isActive) &&
                    matrix.generalFieldOrder.every((fieldId) => field.id !== fieldId)
            );

            return matrix.type === MatrixType.Requester
                ? result
                : result.filter(
                      (field) =>
                          field.netSuiteField?.type &&
                          netSuiteConstants.REFERENCE_CUSTOM_FIELDS.includes(field.netSuiteField.type)
                  );
        }

        return [];
    }, [fields, integrationType, matrix.generalFieldOrder, matrix.type, template.id]);
};

export const useRequiredFieldIds = ({
    matrixType,
    template,
    requiredFieldIds,
}: {
    matrixType: MatrixType;
    template: ActiveWorkflow;
    requiredFieldIds: string[];
}) => {
    return useMemo(() => {
        switch (matrixType) {
            case MatrixType.Approval:
                return template.requiredFieldIds;

            case MatrixType.Editor:
                return template.requiredFieldIds;

            case MatrixType.Requester:
                return requiredFieldIds;

            case MatrixType.AutoApproval:
                return template.requiredFieldIds;

            case MatrixType.Reviewer:
                return template.reviewStep.requiredFieldIds;

            case MatrixType.Editing:
                return template.requiredFieldIds;

            default:
                throw errorHelpers.assertNever(matrixType);
        }
    }, [matrixType, requiredFieldIds, template.requiredFieldIds, template.reviewStep.requiredFieldIds]);
};

export const useFilteredData = (data: ExpandedMatrixLine[]) => {
    const [fieldFilters, setFieldFilters] = useState<{
        [fieldId: string]: string;
    }>({});
    const fieldFilterDebounced = hooks.useDebounce(fieldFilters, 300);

    const [userFilter, setUserFilter] = useState('');
    const userFilterDebounced = hooks.useDebounce(userFilter, 300);

    const filteredData = useMemo<FilteredMatrixLine[]>(() => {
        const fieldFilteresEntries = Object.entries(fieldFilterDebounced);
        const fieldFiltersEmpty = fieldFilteresEntries.every(([, value]) => !value);

        if (!userFilterDebounced && fieldFiltersEmpty) {
            return data.map((row, index) => ({ ...row, lineIndex: index }));
        }

        const search = userFilterDebounced.trim().toLowerCase();

        const filteredRows: FilteredMatrixLine[] = [];

        for (let lineIndex = 0; lineIndex < data.length; lineIndex++) {
            const row = data[lineIndex];

            if (
                !row.user.displayName.toLowerCase().includes(search) &&
                !row.user.userEmail.toLowerCase().includes(search)
            ) {
                continue;
            }

            if (fieldFiltersEmpty) {
                filteredRows.push({
                    ...row,
                    lineIndex,
                });

                continue;
            }

            const filteredRules: ExpandedMatrixLine['rules'] = [];

            for (const rule of row.rules) {
                if (
                    fieldFilteresEntries.every(([fieldId, filterValue]) => {
                        const preparedFilterValue = filterValue?.trim().toLowerCase();

                        if (!preparedFilterValue) {
                            return true;
                        }

                        const condition = rule.conditions.find((condition) => condition.fieldId === fieldId);

                        if (!condition) {
                            return false; // filter set but condition not found
                        }

                        if (
                            condition.conditionType !== domain.ConditionType.ExactValuesCondition &&
                            condition.conditionType !== domain.ConditionType.NegativeExactValuesCondition
                        ) {
                            return true; // filter set but condition type is not supported
                        }

                        return condition.exactValues.some(
                            (value) =>
                                value.id.toLowerCase().includes(preparedFilterValue) ||
                                value.text.toLowerCase().includes(preparedFilterValue)
                        );
                    })
                ) {
                    filteredRules.push(rule);
                }
            }

            if (filteredRules.length > 0) {
                filteredRows.push({
                    ...row,
                    rules: filteredRules,
                    lineIndex,
                });
            }
        }

        return filteredRows;
    }, [data, userFilterDebounced, fieldFilterDebounced]);

    return {
        filteredData,
        userFilter,
        setUserFilter,
        fieldFilters,
        setFieldFilters,
    };
};
