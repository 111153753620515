import { Form } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { useTwoFaSetupWizard } from './TwoFaEnablingWizard.hooks';
import { TwoFaEnablingWizardProps } from './TwoFaEnablingWizard.types';

export const TwoFaEnablingWizard = memo<TwoFaEnablingWizardProps>((props) => {
    const { form, handleSubmit, formStep } = useTwoFaSetupWizard(props);

    return (
        <Form form={form} onSubmit={handleSubmit}>
            {formStep}
        </Form>
    );
});
