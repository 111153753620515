import { ErrorType, PasswordValidationCriterion } from '../const';

const digitsMatcher = /\d+/;
const lowerCaseMatcher = /[a-z]+/;
const upperCaseMatcher = /[A-Z]+/;
const specialMatcher = /[[\]{}()<>\\/+*^\-_=!?.,:;|$€£¥¢#@&%"']+/;

export default function password(value: string | undefined | null) {
    let unmetCriteria = [];

    if (!value || value.length < 8) {
        unmetCriteria.push(PasswordValidationCriterion.Length);
    }

    if (!value || !digitsMatcher.test(value)) {
        unmetCriteria.push(PasswordValidationCriterion.UseNumber);
    }

    if (!value || !lowerCaseMatcher.test(value) || !upperCaseMatcher.test(value)) {
        unmetCriteria.push(PasswordValidationCriterion.UseUpperLowerCase);
    }

    if (!value || !specialMatcher.test(value)) {
        unmetCriteria.push(PasswordValidationCriterion.UseSymbol);
    }

    if (unmetCriteria.length === 0) {
        return undefined;
    }

    return {
        type: ErrorType.Password,
        unmetCriteria,
        message: '',
    };
}
