import { GearsIcon } from '@approvalmax/ui';
import { domain } from 'modules/data';
import { useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { usePermissions } from 'shared/hooks/usePermissions';
import { getPath, Path } from 'urlBuilder';

import { messages } from './SettingsMenu.messages';
import { SettingsMenuProps } from './SettingsMenu.types';

const qa = bemFactory.qa('settingsDropdown');

export const useSettingsLinkItems = (company: SettingsMenuProps['company']) => {
    const { isPaymentDetailsViewEnabled } = usePermissions(company);

    const isAdvancedUser = company?.flags.isAuditor || company?.flags.isManager || company?.flags.isWorkflowManager;

    const hasPayOpenBankingUKBetaFeature = company.betaFeatures.includes(domain.CompanyBetaFeature.PayOpenBankingUK);
    const hasPayLicenceFeature = company.licenseFeatures.includes(domain.CompanyLicenseFeature.Pay);

    const isPayAvailable = hasPayOpenBankingUKBetaFeature || hasPayLicenceFeature;

    return useMemo(
        () => [
            {
                id: 'settings',
                name: messages.settingsName,
                startIcon: GearsIcon,
                dataQa: qa(),
                subItems: [
                    {
                        id: Path.companyWorkflows,
                        name: messages.workflowsName,
                        available: isAdvancedUser,
                        to: getPath(Path.companyWorkflows, company.id),
                        dataQa: qa('navigationItem'),
                        dataQaName: 'approvalWorkflows',
                    },
                    {
                        id: Path.companyUsers,
                        name: messages.usersName,
                        available: isAdvancedUser,
                        to: getPath(Path.companyUsers, company.id),
                        dataQa: qa('navigationItem'),
                        dataQaName: 'users',
                    },
                    {
                        id: Path.customFields,
                        name: messages.customFieldsName,
                        available:
                            company.flags.isManager &&
                            company.integration?.integrationType === domain.IntegrationType.NetSuite,
                        to: getPath(Path.customFields, company.id),
                        dataQa: qa('navigationItem'),
                        dataQaName: 'fields',
                    },
                    {
                        id: Path.companyInfo,
                        name:
                            !company.integration || company.integration.integrationType === domain.IntegrationType.None
                                ? messages.organisationSettingsStandaloneName
                                : messages.organisationSettingsName,
                        available: company.flags.isManager || company.flags.isWorkflowManager,
                        to: getPath(Path.companyInfo, company.id),
                        dataQa: qa('navigationItem'),
                        dataQaName: 'organisationSettings',
                    },
                    ...(isPayAvailable && company.integration?.integrationType === domain.IntegrationType.Xero
                        ? [
                              {
                                  id: Path.bankAccounts,
                                  name: messages.bankAccounts,
                                  available: company.flags.isManager || company.flags.isAuditor,
                                  to: getPath(Path.bankAccounts, company.id),
                                  dataQa: qa('navigationItem'),
                                  dataQaName: 'bankAccounts',
                              },
                              {
                                  id: Path.contactPaymentDetails,
                                  name: messages.contactPaymentDetails,
                                  available: isPaymentDetailsViewEnabled ?? false,
                                  to: getPath(Path.contactPaymentDetails, company.id),
                                  dataQa: qa('navigationItem'),
                                  dataQaName: 'contactDetails',
                              },
                          ]
                        : []),
                ],
            },
        ],
        [
            isAdvancedUser,
            company.id,
            company.flags.isManager,
            company.flags.isWorkflowManager,
            company.flags.isAuditor,
            company.integration,
            isPaymentDetailsViewEnabled,
            isPayAvailable,
        ]
    );
};
