import { errorHelpers, intl } from '@approvalmax/utils';
import { domain, State } from 'modules/data';
import moment from 'moment';
import { defineMessages } from 'react-intl';

import { XeroAddress, XeroAddressType } from '../../data/xero/XeroAddress';
import { XeroContext } from '../../data/xero/XeroContext';
import { createXeroLineItem } from '../../data/xero/XeroLineItem';
import { getPage } from '../pageSelectors';
import { getRequestEditMode, RequestEditMode } from '../requestSelectors';
import { getComputedLineAmount, getComputedLineTaxAmount, isEmptyLikeXeroLineItem } from './lineItemSelectors';

const i18nPrefix = 'requestForm/selectors/xeroSelectors';
const messages = defineMessages({
    postalAddressType: {
        id: `${i18nPrefix}.postalAddressType`,
        defaultMessage: 'Postal',
    },
    physicalAddressType: {
        id: `${i18nPrefix}.physicalAddressType`,
        defaultMessage: 'Delivery',
    },
});

export function getInitialXeroRequest(
    state: State,
    request: domain.XeroPoRequest | domain.XeroBillRequest | domain.XeroQuoteRequest | domain.XeroInvoiceRequest,
    xeroContext: XeroContext
) {
    const details = request.details;
    const editMode = getRequestEditMode(state, request);
    const isEditorMode = editMode === RequestEditMode.Editor;
    const isApproverMode = editMode === RequestEditMode.Approver;

    if (!isEditorMode) {
        details.contact = xeroContext.counterparty;
    }

    if (!isEditorMode && !isApproverMode && details.lineItems.length === 0) {
        details.lineItems.push(createXeroLineItem());
    }

    if (!isEditorMode && !xeroContext.hasValidLineAmountType) {
        details.lineAmountType = xeroContext.defaultLineAmountType;
    }

    if (!isEditorMode && !details.date) {
        details.date = moment().startOf('day').utcOffset(0, true).toISOString();
    }

    if (!details.brandingTheme) {
        details.brandingTheme = xeroContext.brandingThemes[0];
    }

    if (!isApproverMode) {
        details.lineItems.forEach((li) => {
            if (li.account) {
                li.account = xeroContext.accounts.find((a) => a.id === li.account!.id);
            }

            if (li.tax) {
                li.tax = xeroContext.taxCodes.find((t) => t.id === li.tax!.id);
            }
        });
    }

    return request;
}

export function getXeroContext(state: State) {
    return getPage(state).xeroContext;
}

export function getXeroAddressTypeText(a: XeroAddress) {
    switch (a.type) {
        case XeroAddressType.Postal:
            return intl.formatMessage(messages.postalAddressType);

        case XeroAddressType.Physical:
            return intl.formatMessage(messages.physicalAddressType);

        default:
            throw errorHelpers.assertNever(a.type);
    }
}

export function requiresXeroTaxAdjustment(state: State, request: domain.Request) {
    if (request.integrationCode !== domain.IntegrationCode.XeroBill) {
        return false;
    }

    const details = request.details as domain.XeroSharedDetails;
    const lineAmountType = details.lineAmountType;

    return details.lineItems.some((li) => {
        const computedTaxAmount = getComputedLineTaxAmount(li, lineAmountType, getComputedLineAmount(li));

        return Boolean(!isEmptyLikeXeroLineItem(li, lineAmountType) && li.tax && computedTaxAmount !== li.taxAmount);
    });
}
