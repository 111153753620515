/**
 * Returns a date time of the end of the day (LOCAL TIME) for
 * the given date
 */
export const getEndOfLocalDay = (dateString: string) => {
    const date = new Date(dateString);

    date.setHours(23, 59, 59, 999);

    return date;
};
