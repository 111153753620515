import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { createSelector } from 'reselect';

import { pageConfig } from '../../config';
import { ActiveEditingMatrix } from '../reducers/page/activeEditingMatrixReducer';
import { ActiveMatrix } from '../reducers/page/activeMatrixReducer';
import { Page } from '../reducers/pageReducer';
import { ActiveEditingMatrixData, ActiveMatrixData } from '../types/activeMatrixData';
import { ActiveWorkflow } from '../types/activeWorkflow';
import { MatrixType } from '../types/matrix';

export const getPage = (state: State): Page => selectors.page.getPage(state, pageConfig.store.id);

export const getActiveTemplate = (state: State): ActiveWorkflow | null => {
    const template = getPage(state).activeTemplate;

    return template
        ? {
              ...selectors.template.expandTemplate(template),
              showAutoApprovalStepWithoutRules: template.showAutoApprovalStepWithoutRules,
              showReviewStepWithoutReviewers: template.showReviewStepWithoutReviewers,
          }
        : null;
};

export interface ExpandedMatrixLine extends domain.MatrixLine {
    user: selectors.types.ExpandedUser;
}

export const getActiveMatrix: (state: State) => ActiveMatrixData<ExpandedMatrixLine> | null = createSelector(
    (state: State) => getPage(state).activeMatrix,
    (state: State) => selectors.user.getUsers(state),
    (state: State) => selectors.profile.getProfileUser(state),
    (matrix: ActiveMatrix, users: selectors.types.ExpandedUser[], profile) => {
        if (!matrix) {
            return null;
        }

        return {
            ...matrix,
            data: matrix.data.map((x) => {
                const user = matrix.type === MatrixType.AutoApproval ? profile : users.find((u) => u.id === x.lineId);

                if (!user) {
                    throw errorHelpers.notFoundError(`The user '${x.lineId}' is missing in the store.`);
                }

                return {
                    ...x,
                    user,
                };
            }),
        };
    }
);

export const getActiveEditingMatrix: (state: State) => ActiveEditingMatrixData<ExpandedMatrixLine> | null =
    createSelector(
        (state: State) => getPage(state).activeEditingMatrix,
        (state: State) => selectors.user.getUsers(state),
        (matrix: ActiveEditingMatrix, users: selectors.types.ExpandedUser[]) => {
            if (!matrix) {
                return null;
            }

            return {
                ...matrix,
                data: matrix.data.map((rule) => {
                    const user = users.find((u) => u.id === rule.lineId);

                    if (!user) {
                        throw errorHelpers.notFoundError(`The user '${rule.lineId}' is missing in the store.`);
                    }

                    return { ...rule, user };
                }),
            };
        }
    );

export function getActiveStartOver(state: State) {
    return getPage(state).activeStartOver;
}

export function getVersionHistory(state: State) {
    return getPage(state).versionHistory;
}

export function pageLoading(state: State) {
    return getPage(state).loading;
}

export function showCardValidationErrors(state: State) {
    return getPage(state).showCardValidationErrors;
}

export function getActiveSettings(state: State) {
    return getPage(state).activeSettings;
}

export function getActiveHappyActivation(state: State) {
    return getPage(state).activeHappyActivation;
}

export function getActiveInviteUsers(state: State) {
    return getPage(state).activeInviteUsers;
}

export function getActiveVersionConflict(state: State) {
    return getPage(state).activeVersionConflict;
}

export function getExternalSubmitterEnabled(state: State) {
    return getPage(state).externalSubmitterEnabled;
}

export const getActiveFieldSettings = (state: State) => {
    return getPage(state).activeFieldSettings;
};

export function addingNewStep(state: State) {
    return getPage(state).addingNewStep;
}

export function isPageReadonly(state: State) {
    const company = selectors.navigation.getActiveCompany(state);
    const template = getPage(state)?.activeTemplate;

    const integrationErrorPreventsEditingTemplate = Boolean(
        !company.flags.hasActiveIntegration && template && template.integrationCode
    );

    const isTemplateNotActual = Boolean(template && !(template.isActual ?? true));
    // INFO: template.isActual is not defined if it's a new (not ever been saved) standalone template

    const isAirwallexTemplate = template?.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment;
    const hasConnectedAirwallex = company.airwallexIntegration?.isConnected;

    return (
        !selectors.company.canUpdateActiveCompanySettings(state) ||
        integrationErrorPreventsEditingTemplate ||
        company.isReadonly ||
        isTemplateNotActual ||
        (isAirwallexTemplate && !hasConnectedAirwallex) ||
        selectors.ui.isWorkflowWizardPreview(state)
    );
}

export function getActiveCopyTemplate(state: State) {
    return getPage(state).activeCopyTemplate;
}

export function getAddCommentPopup(state: State) {
    return getPage(state).addCommentPopup;
}
