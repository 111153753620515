import * as Sentry from '@sentry/browser';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';

import { LineItemReportColumnDefinition } from '../config/lineItem/columnLineItemDefinitions';
import { ReportColumnDefinition } from '../config/standard/columnDefinitions';
import { RowMeta } from '../config/types';
import { getDefinitionByKind, getUniqColumnsInScope } from '../selectors/reportConfigSelectors';
import { ReportConfig } from './reportConfig';
import { ReportRowData } from './ReportData';

export default function (dataRows: any[][], state: State, reportConfig: ReportConfig): ReportRowData[] {
    function parseCellValue(
        value: any,
        meta: RowMeta,
        columnDefinition: ReportColumnDefinition | LineItemReportColumnDefinition,
        columnIndex: number
    ): any {
        // 1. Array values are never passed into cell parsers
        if (Array.isArray(value)) {
            return value.map((value2) => parseCellValue(value2, meta, columnDefinition, columnIndex));
        }

        // 2. Null values are never passed into cell parsers
        if (value === null) {
            return null;
        }

        if (!columnDefinition) {
            return null;
        }

        // 3. Do the parsing
        return columnDefinition.parseCellValue(value, meta, state, reportConfig.companyId);
    }

    const company = selectors.navigation.getActiveCompany(state);

    const colDefinitions = getUniqColumnsInScope(reportConfig, company, true)
        .filter((column) => column.visible)
        .map((column) => getDefinitionByKind(column.kind, reportConfig, company));

    return dataRows.map(({ cells }: any) => {
        if (reportConfig.reportType === domain.ReportType.LineItem) {
            const [lineId, , requestId, ...rowData] = cells;

            const meta = { lineId, requestId };

            return {
                id: lineId,
                cells: rowData.map((value: any, i: number) => {
                    if (!colDefinitions[i]) {
                        Sentry.captureException(
                            new Error('Line item reports columnDefinition is undefined'),
                            (scope) => {
                                scope.setContext('Report line info', {
                                    value: JSON.stringify(value),
                                    reportLineId: meta.lineId,
                                    requestId: meta.requestId,
                                    columnIndex: i,
                                    cells: JSON.stringify(cells),
                                    colDefinitions: JSON.stringify(colDefinitions),
                                });

                                return scope;
                            }
                        );
                    }

                    return parseCellValue(value, meta, colDefinitions[i], i);
                }),
            };
        }

        const [requestId, ...rowData] = cells;
        const meta = { requestId };

        return {
            id: requestId,
            cells: rowData.map((value: any, i: number) => {
                if (!colDefinitions[i]) {
                    Sentry.captureException(new Error('Reports columnDefinition is undefined'), (scope) => {
                        scope.setContext('Report line info', {
                            value: JSON.stringify(value),
                            requestId: meta.requestId,
                            columnIndex: i,
                            cells: JSON.stringify(cells),
                            colDefinitions: JSON.stringify(colDefinitions),
                        });

                        return scope;
                    });
                }

                return parseCellValue(value, meta, colDefinitions[i], i);
            }),
        };
    });
}
