import { ElementType } from 'react';

import { externalProps } from './Link.constants';
import { Root } from './Link.styles';
import type { LinkProps } from './Link.types';

export const Link = <Component extends ElementType = 'a'>(props: LinkProps<Component>) => {
    const { as, external, ...restProps } = props;

    return <Root forwardedAs={as ?? 'a'} {...(external && externalProps)} {...restProps} />;
};
