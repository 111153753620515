import { selectors } from 'modules/common';
import { domain } from 'modules/data';

const checkIsWorkflowSameTypeCopy = (integrationCode: domain.IntegrationCode | null) => {
    return [
        null, // standalone workflow
        domain.IntegrationCode.XeroContact,
        domain.IntegrationCode.QBooksVendor,
        domain.IntegrationCode.QBooksExpense,
        domain.IntegrationCode.XeroAmaxPayBatchPayment,
        domain.IntegrationCode.XeroBillBatchPayment,
        domain.IntegrationCode.XeroAirwallexBatchPayment,
        domain.IntegrationCode.QBooksJournalEntry,
        domain.IntegrationCode.XeroManualJournal,
    ].includes(integrationCode);
};

export const getAvailableTemplates = (
    baseCompany: selectors.types.ExpandedCompany | null,
    baseTemplate: selectors.types.ExpandedTemplate,
    allTemplates: selectors.types.ExpandedTemplate[]
) => {
    if (!baseCompany) {
        return [];
    }

    const isActiveTemplateSameTypeCopyOnly = checkIsWorkflowSameTypeCopy(baseTemplate.integrationCode);

    return allTemplates.filter((item) => {
        const { isManager, isWorkflowManager } = baseCompany.flags;
        const hasRights = isManager || isWorkflowManager;

        if (item.id === baseTemplate.id || baseCompany.id !== item.companyId) {
            return false;
        }

        if (
            item.integrationCode === domain.IntegrationCode.NetSuiteRA &&
            !baseCompany.betaFeatures.includes(domain.CompanyBetaFeature.NetSuiteRA)
        ) {
            return false;
        }

        if (item.integrationCode === domain.IntegrationCode.XeroContact) {
            return (
                hasRights &&
                baseCompany.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroContactWorkflows) &&
                item.integrationCode === baseTemplate.integrationCode
            );
        }

        if (item.integrationCode === domain.IntegrationCode.XeroManualJournal) {
            const hasManualJournalLicenseFeature =
                hasRights && baseCompany.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroManualJournals);

            const hasManualJournalBetaFeature = baseCompany.betaFeatures.includes(
                domain.CompanyBetaFeature.XeroManualJournal
            );

            return (
                (hasManualJournalLicenseFeature || hasManualJournalBetaFeature) &&
                item.integrationCode === baseTemplate.integrationCode
            );
        }

        if (item.integrationCode === domain.IntegrationCode.QBooksVendor) {
            return (
                hasRights &&
                baseCompany.licenseFeatures.includes(domain.CompanyLicenseFeature.QBOVendorWorkflows) &&
                item.integrationCode === baseTemplate.integrationCode
            );
        }

        if (item.integrationCode === domain.IntegrationCode.QBooksJournalEntry) {
            return (
                isManager &&
                baseCompany.licenseFeatures.includes(domain.CompanyLicenseFeature.QBOJournalEntryWorkflows) &&
                item.integrationCode === baseTemplate.integrationCode
            );
        }

        if (item.integrationCode === domain.IntegrationCode.XeroBillBatchPayment) {
            return (
                hasRights &&
                baseCompany.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroBillBatchPayments) &&
                item.integrationCode === baseTemplate.integrationCode
            );
        }

        const isItemSameTypeCopyOnly = checkIsWorkflowSameTypeCopy(item.integrationCode);

        if (isItemSameTypeCopyOnly || isActiveTemplateSameTypeCopyOnly) {
            return item.integrationCode === baseTemplate.integrationCode;
        }

        return true;
    });
};
