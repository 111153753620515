import { constants } from 'modules/common';
import { Moment } from 'moment';

const { DATE_FORMAT_DELEGATES, DATE_FORMAT_DELEGATES_CALENDAR } = constants.commonConstants;

export const formatDelegateFrom = (date: Moment): string => {
    return date.startOf('day').format(DATE_FORMAT_DELEGATES);
};

export const formatDelegateTo = (date: Moment): string => {
    return date.endOf('day').format(DATE_FORMAT_DELEGATES);
};

export const formatForCalendar = (date: Moment): string => {
    return date.format(DATE_FORMAT_DELEGATES_CALENDAR);
};
