import { parse } from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { routingService } from 'services/routing';
import { getDefaultPath } from 'urlBuilder';

export const useProtectedQueryParams = (queryParams?: string[]) => {
    const [isProtected, setIsProtected] = useState(!queryParams);
    const search = useLocation().search;
    const query = parse(search);

    useEffect(() => {
        if (queryParams) {
            const queryParamsMatch = queryParams.every((queryParam) => query[queryParam]);

            if (queryParamsMatch) {
                setIsProtected(true);
            } else {
                routingService.push(getDefaultPath());
            }
        }
    }, [query, queryParams]);

    return isProtected;
};
