import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';

import { moduleDef } from '..';
import { Module } from '../reducers/moduleReducer';
import { SearchContext } from '../types';

type State = stateTree.State;

function getModule(state: State) {
    return selectors.module.getModule<Module>(state, moduleDef.id);
}

export function getSearchContext(state: State): SearchContext | null {
    return getModule(state).searchContext;
}
