import moment, { Moment } from 'moment/moment';

/**
 * @function
 * @param {Date|String|moment} a Argument A. Javascript <code>Date</code>, date string in ISO8691 or momentJS date.
 * @param {Date|String|moment} b Argument B. Javascript <code>Date</code>, date string in ISO8691 or momentJS date.
 * @return {Number} Result as follows:
 * <ul>
 *     <li><code>-1</code> - if A &lt; B.</li>
 *     <li><code>0</code> - if A == B.</li>
 *     <li><code>1</code> - if A &gt; B.</li>
 * </ul>
 * */
export const dateComparator2Asc = (a: Moment | string | null | undefined, b: Moment | string | null | undefined) => {
    if (a) {
        a = moment(a);
    }

    if (b) {
        b = moment(b);
    }

    if (a) {
        return b ? (a as Moment).valueOf() - (b as Moment).valueOf() : 1;
    }

    return b ? -1 : 0;
};
