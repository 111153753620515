import { HelpCircleIcon } from '@approvalmax/ui';
import { FC, memo, useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { hideHelpStripeState } from '../../HelpStripe.states';
import { StyledButton } from './ToggleButton.styles';
import { ToggleButtonProps } from './ToggleButton.types';

const ToggleButton: FC<ToggleButtonProps> = memo((props) => {
    const { id } = props;

    const [hideHelpStripeIds, setHideHelpStripeIds] = useRecoilState(hideHelpStripeState);
    const isHideHelpStripe = useMemo(() => hideHelpStripeIds.includes(id), [hideHelpStripeIds, id]);

    const toggleHelpStripe = useCallback(
        () =>
            setHideHelpStripeIds((ids) => {
                if (ids.includes(id)) {
                    return ids.filter((x) => x !== id);
                }

                return [...ids, id];
            }),
        [id, setHideHelpStripeIds]
    );

    return (
        <StyledButton icon noPadding onClick={toggleHelpStripe} $hide={isHideHelpStripe}>
            <HelpCircleIcon size={24} />
        </StyledButton>
    );
});

export default ToggleButton;
