import { Reference } from '@approvalmax/types';
import { backend, domain, du } from 'modules/data';
import { emailToSupplierHelpers } from 'shared/helpers';

import { IntegrationsXeroTracking } from '../../../../modules/data/backend';
import { checkFieldValueWithRules } from '../Request';
import { parseXeroAccount } from './XeroAccount';
import { parseXeroAddress, XeroAddress } from './XeroAddress';
import { parseXeroContact, XeroContact } from './XeroContact';
import { parseXeroCurrency, XeroCurrency } from './XeroCurrency';
import { parseXeroPaymentTerm } from './XeroPaymentTerm';
import { parseXeroTaxCode } from './XeroTaxCode';

const trackingCategorySortFn = (a: IntegrationsXeroTracking, b: IntegrationsXeroTracking) => {
    return a.Name > b.Name ? 1 : -1;
};

export function parseXeroContext(
    value: backend.RequestsRequestEditingContext,
    request: domain.XeroRequest
): XeroContext {
    const { xeroContext, requesterInstruction, terms, supplierEmailSettings } = value;

    // Hack: it should actually always come from the server and be set to defaultLineAmountType.
    const hasValidLineAmountType = Boolean(
        [
            value.request.XeroCreditNoteDetails,
            value.request.XeroInvoiceDetails,
            value.request.XeroPurchaseOrderDetails,
            value.request.XeroQuoteDetails,
            value.request.XeroSalesInvoiceDetails,
            value.request.XeroContactDetails,
            value.request.XeroBillBatchPaymentDetails,
            value.request.AirwallexXeroBatchPaymentDetails,
            value.request.XeroManualJournalDetails,
        ].find((x) => Boolean(x))!.LineAmountType
    );
    const emptyTaxCode = xeroContext.emptyTaxCode ? parseXeroTaxCode(xeroContext.emptyTaxCode) : ({} as any);
    const noTaxIsInvalid = !checkFieldValueWithRules(
        request.authorRules,
        domain.FieldSystemPurpose.XeroTax,
        emptyTaxCode.Id
    );

    return {
        brandingThemes: xeroContext.brandingThemes?.map(du.parseServerReference),
        trackingCategories: xeroContext.trackingCategories?.sort(trackingCategorySortFn).map((x) => {
            return {
                category: du.parseServerReference(x),
                options: x.Options.map(du.parseServerReference),
            };
        }),
        emptyTaxCode,
        defaultLineAmountType: xeroContext.defaultLineAmountType || domain.LineAmountType.TaxExclusive,
        defaultPaymentTerms: {
            bills: xeroContext.defaultPaymentTerms?.Bills
                ? parseXeroPaymentTerm(xeroContext.defaultPaymentTerms.Bills)
                : undefined,
            sales: xeroContext.defaultPaymentTerms?.Sales
                ? parseXeroPaymentTerm(xeroContext.defaultPaymentTerms.Sales)
                : undefined,
        },
        hasValidLineAmountType,
        accounts: (xeroContext.accounts || []).map(parseXeroAccount),
        currencies: (xeroContext.currencies || []).map(parseXeroCurrency),
        taxCodes: (xeroContext.taxCodes || []).map(parseXeroTaxCode),
        companyAddresses: (xeroContext.addresses || []).map(parseXeroAddress),
        counterparty: xeroContext.counterparty ? parseXeroContact(xeroContext.counterparty) : null,
        requiresTaxAdjustments: request.integrationCode === domain.IntegrationCode.XeroBill,
        unchangedRequestLineItems: 'lineItems' in request.details ? request.details.lineItems : [],
        noTaxIsInvalid,
        requesterInstruction,
        terms,
        cisSettings: {
            contractorEnabled: Boolean(xeroContext.cisSettings?.ContractorEnabled),
            subContractorEnabled: Boolean(xeroContext.cisSettings?.SubContractorEnabled),
            companyRate: xeroContext.cisSettings?.CompanyRate,
        },
        supplierEmailSettings: supplierEmailSettings && {
            ...supplierEmailSettings,
            subject: emailToSupplierHelpers.escapeExpressions(supplierEmailSettings.subject || ''),
        },
    };
}

export interface XeroContext {
    accounts: domain.XeroAccount[];
    brandingThemes: Reference[];
    cisSettings?: domain.XeroCisSettings;
    companyAddresses: XeroAddress[];
    counterparty: XeroContact | null;
    currencies: XeroCurrency[];
    defaultLineAmountType: domain.LineAmountType;
    defaultPaymentTerms?: domain.XeroPaymentTerms;
    emptyTaxCode: domain.XeroTaxCode;
    hasValidLineAmountType: boolean;
    noTaxIsInvalid: boolean;
    requesterInstruction: string | null;
    requiresTaxAdjustments: boolean;
    supplierEmailSettings?: backend.SupplierEmailSettings;
    taxCodes: domain.XeroTaxCode[];
    terms?: string;
    trackingCategories: Array<{
        category: Reference;
        options: Reference[];
    }>;
    unchangedRequestLineItems: domain.XeroLineItem[];
}
