import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionDearPORejectedNoteToMemo',
    {
        title: 'Add “REJECTED” note to Cin7 Core PO Memo',
        subtitle:
            'Automatically add a rejected note to the Cin7 Core PO Memo field field when a request is rejected in ApprovalMax',
        optionTrue: 'Yes, add the prefix.',
        optionFalse: 'No, do not add the prefix.',
    }
);
