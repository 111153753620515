import { Popup, TextButton } from '@approvalmax/ui';
import { Flex, Text } from '@approvalmax/ui/src/components';
import { CloseIcon } from '@approvalmax/ui/src/icons';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useUserProfile } from 'modules/utils';
import { memo, useCallback, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

import ContactSupportPopup from '../../profile/containers/ContactSupportPopup/ContactSupportPopup';
import {
    addonWarningThresholds,
    canManageSubscriptionsRoles,
    mapAddonTypeToName,
} from './AddonsWarningBanner.constants';
import { isSubscriptionOwner } from './AddonsWarningBanner.helpers';
import { messages } from './AddonsWarningBanner.messages';
import { Root } from './AddonsWarningBanner.styles';
import { AddonsWarningSubscriptionNames } from './AddonsWarningBanner.types';

export const AddonsWarningBanner = memo(() => {
    const profile = useUserProfile();
    const subscriptions = useSelector(selectors.subscription.getAllSubscriptions);

    const [isNotificationOpen, setIsNotificationOpen] = useState({
        [domain.AddonTypes.Capture]: true,
        [domain.AddonTypes.Pay]: true,
    });

    const [isSupportPopupOpen, toggleIsSupportPopupOpen] = useToggle(false);

    const closeNotification = useCallback((type: domain.AddonTypes) => {
        setIsNotificationOpen((isOpenState) => ({
            ...isOpenState,
            [type]: false,
        }));
    }, []);

    const isAccountOwnerOrManager = useMemo(() => {
        return profile.account && canManageSubscriptionsRoles.includes(profile.account.userRole!);
    }, [profile.account]);

    const addonsStatistics = useMemo(() => {
        if (!isAccountOwnerOrManager) return;

        const subscriptionWarningsByType = subscriptions
            // filter only subscriptions that own to the user and filter nonempty addon statistics:
            .filter(
                (subscription) =>
                    isSubscriptionOwner(subscription, profile?.account) && Boolean(subscription.addonStatistics.length)
            )
            // convert to AddonsWarningSubscriptionNames type:
            .reduce<AddonsWarningSubscriptionNames>(
                (totalStatistic, currentSubscription) => {
                    currentSubscription.addonStatistics.forEach((statisticItem) => {
                        if (addonWarningThresholds.includes(statisticItem.usageThreshold)) {
                            totalStatistic[statisticItem.feature].push(`AMS-${currentSubscription.systemId}`);
                        }
                    });

                    return totalStatistic;
                },
                { [domain.AddonTypes.Capture]: [], [domain.AddonTypes.Pay]: [] }
            );

        // convert it to an array and join subscription names to render them easy:
        return Object.keys(subscriptionWarningsByType).map((type) => ({
            type: type as domain.AddonTypes,
            subscriptionsListString: subscriptionWarningsByType[type as domain.AddonTypes].join(', '),
        }));
    }, [isAccountOwnerOrManager, profile?.account, subscriptions]);

    if (!addonsStatistics) return null;

    return (
        <>
            {addonsStatistics.map(({ type, subscriptionsListString }) => {
                if (!subscriptionsListString.length || !isNotificationOpen[type]) return null;

                return (
                    <Root key={type}>
                        <Flex inline justifyContent='space-between' alignItems='center' wrap={false}>
                            <Text font='body' color='white100'>
                                {messages.warning({
                                    addonTitle: (
                                        <Text font='body' fontWeight='bold' as='span'>
                                            {mapAddonTypeToName[type]}
                                        </Text>
                                    ),
                                    subscriptionCodes: (
                                        <Text font='body' fontWeight='bold' as='span'>
                                            {subscriptionsListString}
                                        </Text>
                                    ),
                                })}
                            </Text>

                            <Flex
                                inline
                                justifyContent='end'
                                alignItems='center'
                                spacing='0 16'
                                wrap={false}
                                shrink={0}
                            >
                                <TextButton execute={toggleIsSupportPopupOpen} colorTheme='white' size='small1'>
                                    {messages.contactUsText}
                                </TextButton>

                                <TextButton
                                    execute={() => closeNotification(type)}
                                    colorTheme='white'
                                    size='small1'
                                    underlined
                                >
                                    <CloseIcon color='white100' />
                                </TextButton>
                            </Flex>
                        </Flex>
                    </Root>
                );
            })}

            <Popup isOpen={isSupportPopupOpen} onRequestClose={toggleIsSupportPopupOpen}>
                <ContactSupportPopup />
            </Popup>
        </>
    );
});

AddonsWarningBanner.displayName = 'AddonsWarningBanner';
