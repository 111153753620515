import { errorHelpers } from '@approvalmax/utils';

import { localStorageKey } from './AfterAllFeatureTrialPopup.constant';

export const getParsedHiddenCompaniesIds = () => {
    try {
        const list = localStorage.getItem(localStorageKey);

        if (list) {
            const parsed = JSON.parse(list);

            if (Array.isArray(parsed)) {
                return parsed;
            }
        }
    } catch (error) {
        errorHelpers.captureException(error);

        return [];
    }

    return [];
};

export const saveHiddenCompaniesIds = (list: string[]) => {
    try {
        localStorage.setItem(localStorageKey, JSON.stringify(list));
    } catch (error) {
        errorHelpers.captureException(error);
    }
};
