import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { storageService } from 'services/storage';

export const getGtag = () => (window as any).gtag;

export const initGtag = (state: State) => {
    if (!window.ApprovalMax.flags.useGoogleAnalytics) {
        return;
    }

    const authenticated = selectors.session.authenticated(state);
    const isTwoFaHardEnforcementPage = storageService.getIsTwoFaHardEnforcement();

    const gtag = getGtag();

    if (gtag) {
        gtag('config', window.ApprovalMax.googleAnalyticsId, {
            send_page_view: false,
        });

        if (authenticated && !isTwoFaHardEnforcementPage) {
            const me = selectors.profile.getProfileUser(state);
            const profile = selectors.profile.getProfile(state);
            const isManager = selectors.profile.isManagerAtAnyOrganization(state);

            gtag('set', {
                user_id: me.databaseId,
            });

            const userProperties = {
                am_role: profile.account ? 'account_owner' : isManager ? 'manager' : 'regular_user',
                am_user_lifecycle: profile.isTrialUser ? 'trial' : 'paid',
                am_user_id: profile.id,
            };

            gtag('set', {
                user_properties: userProperties,
            });

            gtag('event', 'login', {
                user_properties: userProperties,
                user_id: me.databaseId,
            });
        }
    }
};
