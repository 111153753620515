import { AmCaptureImage } from '@approvalmax/ui';
import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { bulkCreationPopupContentState, bulkCreationPopupOpenState } from 'modules/request';
import { closeActivePopup } from 'modules/request/actions';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSetRecoilState } from 'recoil';

import { messages } from './BulkCreationBanner.messages';
import { BulkCreationBannerProps } from './BulkCreationBanner.types';

export const BulkCreationBanner: FC<BulkCreationBannerProps> = memo((props) => {
    const { companyId, templateIntegrationCode } = props;

    const dispatch = useDispatch();

    const setBulkCreationPopupOpen = useSetRecoilState(bulkCreationPopupOpenState);
    const setBulkCreationPopupContent = useSetRecoilState(bulkCreationPopupContentState);

    const onCreate = useCallback(() => {
        dispatch(closeActivePopup());
        setBulkCreationPopupContent({ companyId, templateIntegrationCode });
        setBulkCreationPopupOpen(true);
    }, [companyId, dispatch, setBulkCreationPopupContent, setBulkCreationPopupOpen, templateIntegrationCode]);

    return (
        <Box width={280} height='fit-content' spacing='24' color='midnight20'>
            <Flex inline direction='column' alignItems='center' spacing='16'>
                <AmCaptureImage height={48} width={126} />

                <Text textAlign='center' font='headline' fontSize='xxsmall' fontWeight='medium'>
                    {messages.description}
                </Text>

                <Button onClick={onCreate} color='mint80'>
                    {messages.uploadButtonText}
                </Button>
            </Flex>
        </Box>
    );
});

BulkCreationBanner.displayName = 'BulkCreationBanner';
