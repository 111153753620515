import { compareHelpers } from '@approvalmax/utils';
import { getActiveTemplate } from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/selectors/pageSelectors';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { store } from 'modules/store';
import { PAGE_ID as requestFormPageId } from 'pages/requestForm/config';
import { getRequest } from 'pages/requestForm/selectors/pageSelectors';
import { PAGE_ID as requestListPageId } from 'pages/requestList/config';
import { getActiveRequest } from 'pages/requestList/selectors/pageSelectors';
import { getRecoil } from 'recoil-nexus';
import { activeCompanyIdState } from 'shared/states';

import { ChameleonHelpers } from './types';

export const defineFunctions = () => {
    const chameleon: ChameleonHelpers = {} as ChameleonHelpers;

    const getCurrentRequest = () => {
        const state = store.getState();
        const pageId = selectors.navigation.getActivePageId(state);

        if (pageId === requestListPageId) {
            return getActiveRequest(state);
        } else if (pageId === requestFormPageId) {
            return getRequest(state);
        }

        return null;
    };

    chameleon.get_gl_name = () => {
        const state = store.getState();
        const company = selectors.navigation.getActiveCompany(state);
        const currentIntegration = company.integration?.integrationType;

        if (currentIntegration) {
            if (currentIntegration === domain.IntegrationType.None) {
                return 'ApprovalMax';
            }

            if (currentIntegration === domain.IntegrationType.QBooks) {
                return 'QuickBooks';
            }

            return currentIntegration; // Xero
        }

        return 'ApprovalMax';
    };

    chameleon.get_request_type_in_workflow = () => {
        const state = store.getState();

        const template = getActiveTemplate(state);

        if (template) {
            switch (template.integrationCode) {
                case domain.IntegrationCode.QBooksExpense:
                    return 'expense';

                case domain.IntegrationCode.QBooksBill:
                case domain.IntegrationCode.XeroBill:
                    return 'bill';

                case domain.IntegrationCode.QBooksPo:
                case domain.IntegrationCode.XeroPo:
                    return 'PO';

                case domain.IntegrationCode.XeroCreditNotesPayable:
                case domain.IntegrationCode.XeroCreditNotesReceivable:
                    return 'credit note';

                case domain.IntegrationCode.XeroInvoice:
                    return 'sales invoice';

                default:
                    return 'request';
            }
        }

        return '';
    };

    chameleon.get_request_type_in_request = () => {
        let integrationCode = getCurrentRequest()?.integrationCode;

        if (integrationCode) {
            switch (integrationCode) {
                case domain.IntegrationCode.QBooksExpense:
                    return 'expense';

                case domain.IntegrationCode.QBooksBill:
                case domain.IntegrationCode.XeroBill:
                    return 'bill';

                case domain.IntegrationCode.QBooksPo:
                case domain.IntegrationCode.XeroPo:
                    return 'PO';

                case domain.IntegrationCode.XeroCreditNotesPayable:
                case domain.IntegrationCode.XeroCreditNotesReceivable:
                    return 'credit note';

                case domain.IntegrationCode.XeroInvoice:
                    return 'sales invoice';

                default:
                    return 'request';
            }
        }

        return '';
    };

    chameleon.get_request_type_in_workflow_capitalized = () => {
        const type = chameleon.get_request_type_in_workflow();

        if (type) {
            return type.charAt(0).toUpperCase() + type.slice(1);
        }

        return '';
    };

    chameleon.get_request_type_in_request_capitalized = () => {
        const type = chameleon.get_request_type_in_request();

        if (type) {
            return type.charAt(0).toUpperCase() + type.slice(1);
        }

        return '';
    };

    chameleon.get_request_creation_step_text = () => {
        const state = store.getState();

        try {
            const template = getActiveTemplate(state);

            if (template) {
                switch (template.integrationCode) {
                    case domain.IntegrationCode.QBooksExpense:
                        return 'Expense are created in ApprovalMax and are pushed to QuickBooks upon approval.\n\nHere you can add users who will be able to create expenses in ApprovalMax.';

                    case domain.IntegrationCode.QBooksBill:
                        return 'Bills are created in ApprovalMax and are pushed to QuickBooks upon approval.\n\nHere you can add users who will be able to create bills in ApprovalMax.';

                    case domain.IntegrationCode.XeroBill:
                        return 'Bills can be created in ApprovalMax or pulled from Xero.\n\nHere you can add users who will be able to create bills in ApprovalMax';

                    case domain.IntegrationCode.QBooksPo:
                        return 'POs are created in ApprovalMax and are pushed to QuickBooks upon approval.\n\n Here you can add users who will be able to create POs in ApprovalMax.';

                    case domain.IntegrationCode.XeroPo:
                        return 'Purchase orders can be created in ApprovalMax or pulled from Xero.\n\nHere you can add users who will be able to create POs in ApprovalMax.';

                    case domain.IntegrationCode.XeroCreditNotesPayable:
                    case domain.IntegrationCode.XeroCreditNotesReceivable:
                        return 'Credit notes are pulled from Xero.';

                    case domain.IntegrationCode.XeroInvoice:
                        return 'Sales invoices are pulled from Xero.';

                    default:
                        return 'Standalone requests are created and stored in ApprovalMax only after approval.';
                }
            }
        } catch {
            // empty
        }

        return '';
    };

    chameleon.get_request_approval_actions = () => {
        let integrationCode = getCurrentRequest()?.integrationCode;

        if (integrationCode) {
            switch (integrationCode) {
                case domain.IntegrationCode.XeroBill:
                case domain.IntegrationCode.XeroPo:
                case domain.IntegrationCode.XeroCreditNotesPayable:
                case domain.IntegrationCode.XeroCreditNotesReceivable:
                case domain.IntegrationCode.XeroInvoice:
                    return 'pushed to Xero';

                case domain.IntegrationCode.QBooksBill:
                case domain.IntegrationCode.QBooksExpense:
                case domain.IntegrationCode.QBooksPo:
                    return 'pushed to QBO';

                default:
                    return 'stored at ApprovalMax';
            }
        }

        return '';
    };

    chameleon.get_request_approval_results = () => {
        let integrationCode = getCurrentRequest()?.integrationCode;

        if (integrationCode) {
            switch (integrationCode) {
                case domain.IntegrationCode.XeroBill:
                case domain.IntegrationCode.XeroPo:
                case domain.IntegrationCode.XeroCreditNotesPayable:
                case domain.IntegrationCode.XeroCreditNotesReceivable:
                case domain.IntegrationCode.XeroInvoice:
                    return 'data has been synced with Xero';

                case domain.IntegrationCode.QBooksBill:
                case domain.IntegrationCode.QBooksExpense:
                case domain.IntegrationCode.QBooksPo:
                    return 'data has been synced with QBO';

                default:
                    return 'request has been stored in ApprovalMax';
            }
        }

        return '';
    };

    chameleon.get_latest_activated_workflow_name = () => {
        const state = store.getState();

        const lastActiveTemplate = selectors.template
            .getAllTemplates(state)
            .filter((item) => item.enabled)
            .sort(
                compareHelpers.comparatorFor<selectors.types.ExpandedTemplate>(
                    compareHelpers.dateComparator2Desc,
                    'modifiedDate'
                )
            )[0];

        if (!lastActiveTemplate) {
            return '';
        }

        const integrationCode = lastActiveTemplate.integrationCode;

        if (integrationCode) {
            switch (integrationCode) {
                case domain.IntegrationCode.QBooksExpense:
                    return 'expense';

                case domain.IntegrationCode.QBooksBill:
                case domain.IntegrationCode.XeroBill:
                    return 'bill';

                case domain.IntegrationCode.QBooksPo:
                case domain.IntegrationCode.XeroPo:
                    return 'PO';

                case domain.IntegrationCode.XeroCreditNotesPayable:
                case domain.IntegrationCode.XeroCreditNotesReceivable:
                    return 'credit note';

                case domain.IntegrationCode.XeroInvoice:
                    return 'sales invoice';

                default:
                    return 'request';
            }
        }

        return '';
    };

    chameleon.get_latest_activated_workflow_gl = () => {
        const state = store.getState();

        const lastActiveTemplate = selectors.template
            .getAllTemplates(state)
            .filter((item) => item.enabled)
            .sort(
                compareHelpers.comparatorFor<selectors.types.ExpandedTemplate>(
                    compareHelpers.dateComparator2Desc,
                    'modifiedDate'
                )
            )[0];

        if (!lastActiveTemplate) {
            return 'ApprovalMax';
        }

        const integrationCode = lastActiveTemplate.integrationCode;

        if (integrationCode) {
            switch (integrationCode) {
                case domain.IntegrationCode.XeroBill:
                case domain.IntegrationCode.XeroPo:
                case domain.IntegrationCode.XeroCreditNotesPayable:
                case domain.IntegrationCode.XeroCreditNotesReceivable:
                case domain.IntegrationCode.XeroInvoice:
                    return 'Xero';

                case domain.IntegrationCode.QBooksBill:
                case domain.IntegrationCode.QBooksExpense:
                case domain.IntegrationCode.QBooksPo:
                    return 'QBO';

                default:
                    return 'ApprovalMax';
            }
        }

        return '';
    };

    chameleon.get_latest_activated_workflow_creation_type = () => {
        const state = store.getState();

        const lastActiveTemplate = selectors.template
            .getAllTemplates(state)
            .filter((item) => item.enabled)
            .sort(
                compareHelpers.comparatorFor<selectors.types.ExpandedTemplate>(
                    compareHelpers.dateComparator2Desc,
                    'modifiedDate'
                )
            )[0];

        if (lastActiveTemplate) {
            const integrationType = domain.getIntegrationTypeByCode(lastActiveTemplate.integrationCode);

            if (integrationType === domain.IntegrationType.QBooks) {
                return 'Manual';
            }

            const isPulling = !!lastActiveTemplate.externalSubmitter;
            const isPushing = !!lastActiveTemplate.submitterMatrix.length;

            if (isPulling && isPushing) {
                return 'Manual+Pulling';
            }

            if (isPulling) {
                return 'Pulling';
            }

            if (isPushing) {
                return 'Manual';
            }
        }

        return '';
    };

    chameleon.get_server_url = () => {
        return window.location.origin;
    };

    chameleon.oldest_org = () => {
        const state = store.getState();
        const oldestCompany = selectors.company
            .getCompanies(state)
            .map((company) => ({
                id: company.id,
                createdDate: company.integration?.createdDate || new Date().toISOString(),
            }))
            .sort(
                compareHelpers.comparatorFor<{ id: string; createdDate: string }>(
                    compareHelpers.dateComparator2Desc,
                    'createdDate'
                )
            )[0];

        return oldestCompany;
    };

    chameleon.get_org_id = () => {
        return getRecoil(activeCompanyIdState) || '';
    };

    chameleon.show_workflow_first_start_tutorial = () => {
        const isStandAlone = chameleon.get_gl_name() === 'ApprovalMax';
        const id = isStandAlone ? '5f984bc8586ac000113c6993' : '5f4800fdb76f51001184d017';

        window.chmln.show(id, {
            use_segmentation: true,
            once: true,
            redirect: false,
            skip_triggers: false,
            skip_url_match: false,
        });
    };

    chameleon.show_workflow_onboarding_checklist = () => {
        const id = window.ApprovalMax.chameleonId;

        window.chmln.show(id, { open: true });
    };

    window.chameleon = chameleon;
};
