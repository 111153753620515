import { ComponentErrorBoundary as ComponentErrorBoundaryUi } from '@approvalmax/ui/src/components';
import * as profileModule from 'modules/profile';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { ComponentErrorBoundaryProps } from './ComponentErrorBoundary.types';

export const ComponentErrorBoundary: FC<ComponentErrorBoundaryProps> = (props) => {
    const { onError, children } = props;

    const dispatch = useDispatch();

    const showContactSupportPopup = () => dispatch(profileModule.loadContactSupportPopup());

    return (
        <ComponentErrorBoundaryUi onContactSupport={showContactSupportPopup} onError={onError}>
            {children}
        </ComponentErrorBoundaryUi>
    );
};

ComponentErrorBoundary.displayName = 'ComponentErrorBoundary';
