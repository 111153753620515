import { PageSlice } from 'modules/page';

import { PAGE_ID } from './config';
import navigationReducer from './navigationReducer';

export const page: PageSlice = {
    id: PAGE_ID,
    reducers: {
        navigation: navigationReducer,
    },
};
