import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { integrationActions, IntegrationSuccessRedirectPage } from 'modules/integration';
import { useDispatch, useSelector } from 'modules/react-redux';
import { memo } from 'react';
import { amplitudeService } from 'services/amplitude';
import { IntegrationCard } from 'shared/components';

const Integrations = memo(() => {
    const dispatch = useDispatch();

    const company = useSelector(selectors.navigation.getActiveCompany);
    const integration = useSelector((state: State) =>
        selectors.integration.getIntegrationByCompanyId(state, company.id)
    );
    const { isConnected, isDisconnected } = selectors.integration.getExplainedIntegrationState(integration);

    const isRetired = company.licenseProductPlan === domain.CompanyPlan.Retired;

    const connect = (integrationType: domain.IntegrationType) => {
        amplitudeService.sendData('workflows list: initiate connection', {
            'connection type': integrationType.toLocaleLowerCase(),
        });

        switch (integrationType) {
            case domain.IntegrationType.NetSuite:
                return dispatch(
                    integrationActions.showNetSuiteIntegrationPopup(company.id, IntegrationSuccessRedirectPage.Workflow)
                );

            case domain.IntegrationType.Dear:
                return dispatch(
                    integrationActions.showDearIntegrationPopup(company.id, IntegrationSuccessRedirectPage.Workflow)
                );

            default:
                return dispatch(
                    integrationActions.connectToIntegration({
                        integrationType,
                        finalRedirectToPage: 'workflow',
                        companyId: company.id,
                        showGlobalProgress: true,
                    })
                );
        }
    };

    const availableIntegrations = isRetired
        ? [domain.IntegrationType.Xero, domain.IntegrationType.QBooks, domain.IntegrationType.NetSuite]
        : company.availableIntegrations;

    return (
        <>
            {availableIntegrations.map((integrationType) => (
                <IntegrationCard
                    key={integrationType}
                    integrationType={integrationType}
                    onClick={connect}
                    disabled={integrationType === integration?.integrationType && !isConnected && !isDisconnected}
                />
            ))}
        </>
    );
});

export default Integrations;
