import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetRequestLegacy } from 'shared/data';

import { authApiPaths } from '../paths';
import {
    UseGetMicrosoftOAuthUrlQueryParams,
    UseGetMicrosoftOAuthUrlResponse,
    UseGetMicrosoftOAuthUrlResponseBackend,
} from './useGetMicrosoftOAuthUrl.types';

export const useGetMicrosoftOAuthUrl = (
    params: QueryParams<UseGetMicrosoftOAuthUrlQueryParams>,
    queryOptions?: QueryOptions<UseGetMicrosoftOAuthUrlResponseBackend, UseGetMicrosoftOAuthUrlResponse>
) => {
    return useGetRequestLegacy<UseGetMicrosoftOAuthUrlResponseBackend, UseGetMicrosoftOAuthUrlResponse>(
        authApiPaths.microsoftOAuthUrl,
        params,
        {
            mapData: objectHelpers.pascalCaseToCamelCase,
            queryOptions: {
                ...queryOptions,
                enabled: enabledByParams<UseGetMicrosoftOAuthUrlQueryParams>(params, queryOptions),
            },
        }
    );
};
