import { domain } from 'modules/data';
import { useMemo } from 'react';

import { SetWorkflowSettingsHandler } from '../../WorkflowSettingsPopupContent.types';
import { RetrospectivePoMatchingOption } from './SectionXeroBillMatching.types';

export const useSectionXeroBillMatching = (onChange?: SetWorkflowSettingsHandler) => {
    return useMemo(() => {
        const onChangeManualMatch = (value: boolean) => {
            onChange?.((settings) => ({
                ...settings,
                billMatchingSettings: {
                    ...settings.billMatchingSettings!,
                    manualMatchingEnabled: value,
                },
            }));
        };

        const onChangeNotMatchedApproval = (value: domain.CompanyMatchingSettingsBillApprovalPolicy) => {
            onChange?.((settings) => ({
                ...settings,
                billMatchingSettings: {
                    ...settings.billMatchingSettings!,
                    allowApprovalOfNotMatchedBills: value,
                },
            }));
        };

        const onChangeNotMatchedThreshold = (value?: number | null) => {
            if (typeof value !== 'number') {
                return;
            }

            onChange?.((settings) => ({
                ...settings,
                billMatchingSettings: {
                    ...settings.billMatchingSettings!,
                    notMatchedBillsApprovalThreshold: value,
                },
            }));
        };

        const onChangeOverbudgetApproval = (value: domain.CompanyMatchingSettingsBillApprovalPolicy) => {
            onChange?.((settings) => ({
                ...settings,
                billMatchingSettings: {
                    ...settings.billMatchingSettings!,
                    allowApprovalOfOverbudgetBills: value,
                },
            }));
        };

        const onChangeOverbudgetPercent = (value?: number | null) => {
            if (typeof value !== 'number') {
                return;
            }

            onChange?.((settings) => ({
                ...settings,
                billMatchingSettings: {
                    ...settings.billMatchingSettings!,
                    overbudgetBillsApprovalPercentageThreshold: value,
                },
            }));
        };

        const onChangeOverbudgetAmount = (value?: number | null) => {
            if (value == null) {
                return;
            }

            onChange?.((settings) => ({
                ...settings,
                billMatchingSettings: {
                    ...settings.billMatchingSettings!,
                    overbudgetBillsApprovalThreshold: value,
                },
            }));
        };

        const onChangeRetrospectivePoMatching = (value: RetrospectivePoMatchingOption) => {
            onChange?.((settings) => ({
                ...settings,
                billMatchingSettings: {
                    ...settings.billMatchingSettings!,
                    canMatchBillsWithRetrospectivePurchaseOrders: value === RetrospectivePoMatchingOption.True,
                },
            }));
        };

        const onChangeAmountType = (value: domain.MatchingAmountType) => {
            onChange?.((settings) => ({
                ...settings,
                billMatchingSettings: {
                    ...settings.billMatchingSettings!,
                    amountType: value,
                },
            }));
        };

        const onChangeInsufficientAllowanceType = (value: domain.ApprovalAllowanceType) => {
            onChange?.((settings) => ({
                ...settings,
                billMatchingSettings: {
                    ...settings.billMatchingSettings!,
                    insufficientBudgetMatchBillsApprovalAllowed: value,
                },
            }));
        };

        const onChangeInsufficientThreshold = (value?: number | null) => {
            if (typeof value !== 'number') {
                return;
            }

            onChange?.((settings) => ({
                ...settings,
                billMatchingSettings: {
                    ...settings.billMatchingSettings!,
                    insufficientBudgetMatchBillsApprovalThreshold: value,
                },
            }));
        };

        const onChangeInsufficientPercentageThreshold = (value?: number | null) => {
            if (typeof value !== 'number') {
                return;
            }

            onChange?.((settings) => ({
                ...settings,
                billMatchingSettings: {
                    ...settings.billMatchingSettings!,
                    insufficientBudgetMatchBillsApprovalPercentageThreshold: value,
                },
            }));
        };

        const onChangeAddMatchedPOsRequestersAsApproversForBill = (
            value: domain.AddMatchedPOsRequestersAsApproversForBillStatuses
        ) => {
            onChange?.((settings) => ({
                ...settings,
                billMatchingSettings: {
                    ...settings.billMatchingSettings!,
                    addMatchedPOsRequestersAsApproversForBill: value,
                },
            }));
        };

        return {
            onChangeManualMatch,
            onChangeNotMatchedApproval,
            onChangeNotMatchedThreshold,
            onChangeOverbudgetApproval,
            onChangeOverbudgetPercent,
            onChangeOverbudgetAmount,
            onChangeRetrospectivePoMatching,
            onChangeAmountType,
            onChangeInsufficientAllowanceType,
            onChangeInsufficientThreshold,
            onChangeInsufficientPercentageThreshold,
            onChangeAddMatchedPOsRequestersAsApproversForBill,
        };
    }, [onChange]);
};
