import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { meta } from './IntegrationCard.constants';
import { messages } from './IntegrationCard.messages';
import { IntegrationCardProps } from './IntegrationCard.types';

const IntegrationCard: FC<IntegrationCardProps> = memo((props) => {
    const { integrationType, disabled = false, onClick } = props;

    const integrationMeta = meta[integrationType];

    if (!integrationMeta) return null;

    const { logo: Logo, button, title, description, learnMoreUrl } = integrationMeta;

    return (
        <Box
            height='100%'
            color='silver80'
            radius='xsmall'
            spacing='24'
            data-qa='wfl-empty-card__integration-block'
            data-qa-id={integrationType}
        >
            <Flex inline wrap={false} spacing='16' alignItems='start'>
                <Flex shrink={0}>
                    <Logo size={40} />
                </Flex>

                <Flex inline direction='column' spacing='8' grow={1}>
                    <Flex inline spacing='8' alignItems='start'>
                        <Flex grow={1}>
                            <Text font='headline' fontSize='xxsmall' as='h3'>
                                {title({
                                    br: <br />,
                                })}
                            </Text>
                        </Flex>

                        <Button
                            disabled={disabled}
                            data-qa='wfl-empty-card__connect-button'
                            onClick={() => onClick(integrationType)}
                            color={button.color}
                            title={button.title}
                            noPadding={Boolean(button.component || button.componentDisabled)}
                            icon={Boolean(button.component || button.componentDisabled)}
                        >
                            {button.label || (disabled ? button.componentDisabled : button.component)}
                        </Button>
                    </Flex>

                    <Text font='body' fontSize='small' color='midnight80'>
                        {description}
                    </Text>

                    <Text font='label' fontSize='small'>
                        <a href={learnMoreUrl} target='_blank' rel='noopener noreferrer'>
                            {messages.learnMore}
                        </a>
                    </Text>
                </Flex>
            </Flex>
        </Box>
    );
});

export default IntegrationCard;
