import { FC } from 'react';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { ComponentErrorBoundaryProps } from './ComponentErrorBoundary.types';
import { ErrorPlaceholder } from './components';

/**
 * This is an ABSTRACT implementation ComponentErrorBoundary.
 * You can't use it directly, because each app has each own implementation of
 * "contact support" popup, that's why you need to use (or create) an
 * implementation within your app
 */
export const ComponentErrorBoundary: FC<ComponentErrorBoundaryProps> = (props) => {
    const { onContactSupport, onError, children } = props;

    return (
        <ErrorBoundary errorPlaceholder={<ErrorPlaceholder onContactSupport={onContactSupport} />} onError={onError}>
            {children}
        </ErrorBoundary>
    );
};

ComponentErrorBoundary.displayName = 'ComponentErrorBoundary';
