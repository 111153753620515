import { progress } from '@approvalmax/ui';
import { useEffect } from 'react';

export const useProgressLoading = (isLoading: boolean) => {
    useEffect(() => {
        if (isLoading) {
            progress.inc();
        }

        return () => {
            if (isLoading) {
                progress.dec();
            }
        };
    }, [isLoading]);
};
