import { Reference } from '@approvalmax/types';
import keyBy from 'lodash/keyBy';
import { State } from 'modules/data';

import { ReportColumnDefinition } from '../../config/standard/columnDefinitions';
import FilterType from './FilterType';
import { Filter } from './index';

export interface ReferenceListFilter {
    type: FilterType.ReferenceList;
    values: Reference[];
}

export function createReferenceListFilter(...values: Reference[]): Filter {
    return {
        type: FilterType.ReferenceList,
        values,
    };
}

export function createAlwaysTrueFilter() {
    return createReferenceListFilter();
}

export const idMappers = {
    createParseFilter() {
        return function (
            this: ReportColumnDefinition,
            filterAnswer: any,
            state: State,
            companyId: string
        ): ReferenceListFilter {
            const optionsById = keyBy(this.getOptions!(state, null, companyId), 'id');
            const values = (filterAnswer || []).map((val: any) => optionsById[val]);

            return {
                type: FilterType.ReferenceList,
                values,
            };
        };
    },
    createGetFilterTransfer(): any {
        return (filter: ReferenceListFilter) => {
            return filter.values.map((v) => v.id);
        };
    },
};

export const enumMappers = {
    createParseFilter(
        backendEnum: any,
        options: {
            nullValue?: string;
            preTransform?: (filterAnswer: any) => any;
            postTransform?: (filter: ReferenceListFilter) => ReferenceListFilter;
        } = {}
    ) {
        return function (
            this: ReportColumnDefinition,
            filterAnswer: any,
            state: State,
            companyId: string
        ): ReferenceListFilter {
            const optionsById = keyBy(this.getOptions!(state, null, companyId), 'id');

            filterAnswer = filterAnswer || [];

            if (options.preTransform) {
                filterAnswer = options.preTransform(filterAnswer);
            }

            const values = filterAnswer
                .map((val: any) => {
                    if (options.nullValue && val === null) {
                        return optionsById[options.nullValue];
                    }

                    return optionsById[backendEnum[val]];
                })
                // hack: for qbo array can contain undefined values that break ui.
                // Maybe the right decision was a fix db but Andrey Kuzmenko said he with Peret decided it is too hard
                .filter((v: string) => v);

            let result: ReferenceListFilter = {
                type: FilterType.ReferenceList,
                values,
            };

            if (options.postTransform) {
                result = options.postTransform(result);
            }

            return result;
        };
    },
    createGetFilterTransfer(
        backendEnum: any,
        options: { nullValue?: string; postTransform?: (filterTransfer: any) => any } = {}
    ): any {
        return (filter: ReferenceListFilter) => {
            let filterTransfer = filter.values.map((v) => {
                if (options.nullValue && v.id === options.nullValue) {
                    return null;
                }

                return backendEnum[v.id];
            });

            if (options.postTransform) {
                filterTransfer = options.postTransform(filterTransfer);
            }

            return filterTransfer;
        };
    },
};

export const referenceMappers = {
    parseFilter(filterAnswer: any): ReferenceListFilter {
        const values = (filterAnswer || []).map((x: any) => ({
            id: x.Id,
            text: x.Name,
        }));

        return {
            type: FilterType.ReferenceList,
            values,
        };
    },
    getFilterTransfer(filter: ReferenceListFilter): any {
        if (filter.values.length === 0) {
            return null;
        }

        return filter.values.map((x: any) => ({
            id: x.id,
            name: x.text,
        }));
    },
};

export function getFilterPreviewText(filter: ReferenceListFilter): string | null {
    if (filter.values.length === 0) {
        return null;
    }

    return filter.values.map((v) => v.text).join(', ');
}
