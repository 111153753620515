import './passwordValidationSection.scss';

import { PasswordValidationCriterion, validators } from '@approvalmax/ui';
import { ApprovedListIcon, EmptyListIcon, FailedListIcon } from 'modules/sprites';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';

const i18nPrefix = 'profile.PasswordValidationSection';

interface PasswordValidationSectionProps {
    password: string;
    highlightInvalidCriteria?: boolean;
    className?: string;
    layout?: 'horizontal' | 'vertical';
    hidePassed?: boolean;
}

function passwordMeetsCriterion(password: string, criterion: PasswordValidationCriterion) {
    const validationError = validators.password(password);

    return !validationError || !validationError.unmetCriteria.includes(criterion);
}

const bem = bemFactory.block('common-ui-password-validation-section');

const PasswordValidationSection: FC<PasswordValidationSectionProps> = (props) => {
    const { password, highlightInvalidCriteria, className, layout = 'horizontal', hidePassed } = props;

    const meetsLength = passwordMeetsCriterion(password, PasswordValidationCriterion.Length);
    const meetsNumber = passwordMeetsCriterion(password, PasswordValidationCriterion.UseNumber);
    const meetsUpperLowerCase = passwordMeetsCriterion(password, PasswordValidationCriterion.UseUpperLowerCase);
    const meetsSymbol = passwordMeetsCriterion(password, PasswordValidationCriterion.UseSymbol);

    return (
        <div className={bem.add(className)(null, layout)}>
            {(!hidePassed || !meetsLength) && (
                <div
                    className={bem('password-validation-item', {
                        pass: meetsLength,
                        invalid: highlightInvalidCriteria && !meetsLength,
                    })}
                >
                    {meetsLength ? (
                        <ApprovedListIcon width={18} height={18} />
                    ) : highlightInvalidCriteria ? (
                        <FailedListIcon width={18} height={18} />
                    ) : (
                        <EmptyListIcon width={18} height={18} />
                    )}

                    <div className={bem('password-validation-text')}>
                        <FormattedMessage
                            id={`${i18nPrefix}.passwordValidationTextLength`}
                            defaultMessage='Use 8 or more characters'
                        />
                    </div>
                </div>
            )}

            {(!hidePassed || !meetsNumber) && (
                <div
                    className={bem('password-validation-item', {
                        pass: meetsNumber,
                        invalid: highlightInvalidCriteria && !meetsNumber,
                    })}
                >
                    {meetsNumber ? (
                        <ApprovedListIcon width={18} height={18} />
                    ) : highlightInvalidCriteria ? (
                        <FailedListIcon width={18} height={18} />
                    ) : (
                        <EmptyListIcon width={18} height={18} />
                    )}

                    <div className={bem('password-validation-text')}>
                        <FormattedMessage
                            id={`${i18nPrefix}.passwordValidationTextUseNumber`}
                            defaultMessage='Use a number (e.g. 1234)'
                        />
                    </div>
                </div>
            )}

            {(!hidePassed || !meetsUpperLowerCase) && (
                <div
                    className={bem('password-validation-item', {
                        pass: meetsUpperLowerCase,
                        invalid: highlightInvalidCriteria && !meetsUpperLowerCase,
                    })}
                >
                    {meetsUpperLowerCase ? (
                        <ApprovedListIcon width={18} height={18} />
                    ) : highlightInvalidCriteria ? (
                        <FailedListIcon width={18} height={18} />
                    ) : (
                        <EmptyListIcon width={18} height={18} />
                    )}

                    <div className={bem('password-validation-text')}>
                        <FormattedMessage
                            id={`${i18nPrefix}.passwordValidationTextUseUpperLowerCase`}
                            defaultMessage='Upper and lower case letters (e.g. Aa)'
                        />
                    </div>
                </div>
            )}

            {(!hidePassed || !meetsSymbol) && (
                <div
                    className={bem('password-validation-item', {
                        pass: meetsSymbol,
                        invalid: highlightInvalidCriteria && !meetsSymbol,
                    })}
                >
                    {meetsSymbol ? (
                        <ApprovedListIcon width={18} height={18} />
                    ) : highlightInvalidCriteria ? (
                        <FailedListIcon width={18} height={18} />
                    ) : (
                        <EmptyListIcon width={18} height={18} />
                    )}

                    <div className={bem('password-validation-text')}>
                        <FormattedMessage
                            id={`${i18nPrefix}.passwordValidationTextUseSymbol`}
                            defaultMessage='Use a symbol (e.g. !@#$)'
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(PasswordValidationSection);
