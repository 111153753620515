import { Guid, Reference } from '@approvalmax/types';
import { domain } from 'modules/data';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, LOAD_PAGE_DATA } from '../../actions';

export interface Context {
    attachmentMaxSize: number;
    attachmentMaxCount: number;
    fields: Array<
        Reference & {
            systemPurpose: domain.FieldSystemPurpose;
        }
    >;
    requiredFieldIds: Guid[];
    readonlyFieldIds: Guid[];
    requesterInstruction: string | null;
    templateVersion: number;
}

export type ContextType = ImmutableObject<Context>;

const INITIAL_STATE: ContextType = undefined as any;

export default function (state = INITIAL_STATE, action: Action): ContextType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return immutable(action.payload.context);

        default:
            return state;
    }
}
