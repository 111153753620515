import { useCallback, useEffect } from 'react';
import { useVerifyTFAEnablingCode } from 'shared/data';

import { fieldNameSetupVerificationCode, fieldNameStateId } from '../../TwoFaEnablingWizard.constants';
import {
    useTwoFaWizardController,
    useTwoFaWizardFormContext,
    useTwoFaWizardStep,
} from '../../TwoFaEnablingWizard.hooks';
import { messages } from './StepCodeVerify.messages';

export const useStepCodeVerify = () => {
    const form = useTwoFaWizardFormContext();
    const { setWizardStep } = useTwoFaWizardStep();
    const { setWizardController } = useTwoFaWizardController();

    const stateId = form.watch(fieldNameStateId);
    const verificationCode = form.watch(fieldNameSetupVerificationCode);

    const { mutate: verifyCode, isLoading } = useVerifyTFAEnablingCode({
        onSuccess: () => {
            setWizardStep('backupMethods');
        },
        onError: () => {
            form.setError(
                fieldNameSetupVerificationCode,
                { message: messages.errorSetupVerificationCode },
                { shouldFocus: true }
            );
        },
    });

    const handleVerify = useCallback(async () => {
        const isValid = await form.trigger();

        if (!isValid || !stateId || (!verificationCode.trim() && !isLoading)) return;

        verifyCode({
            data: {
                stateId,
                userCode: verificationCode.trim(),
            },
        });
    }, [form, isLoading, stateId, verificationCode, verifyCode]);

    useEffect(() => {
        setWizardController({
            title: messages.title,
            onPrev: () => {
                form.setValue(fieldNameSetupVerificationCode, '');

                setWizardStep('codeScan');
            },
            onNext: async () => {
                await handleVerify();
            },
        });
    }, [form, handleVerify, setWizardController, setWizardStep]);
};
