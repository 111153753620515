const dayMs = 24 * 60 * 60 * 1000;

export const isEnoughDaysAfter = (days: number, timestamp?: string | null) => {
    if (!timestamp) {
        return true;
    }

    const parsedVal = parseInt(timestamp);

    return !parsedVal || parsedVal + dayMs * days < Date.now();
};
