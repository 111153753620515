export const getImageDimensions = (url: string) => {
    return new Promise<{ width: number; height: number }>((resolve, reject) => {
        const img = new Image();

        img.onload = function () {
            resolve({ width: img.width, height: img.height });
        };

        img.onerror = function () {
            reject('Failed to load image');
        };

        img.src = url;
    });
};
