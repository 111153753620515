import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SAVE_AND_RESUBMIT_REQUEST_RESPONSE, SHOW_RESUBMIT_REQUEST_POPUP } from '../../../actions';

export const RESUBMIT_REQUEST_POPUP = 'RESUBMIT_REQUEST_POPUP';

export interface ResubmitRequestPopupData {
    id: typeof RESUBMIT_REQUEST_POPUP;
}

export type ResubmitRequestPopupType = ImmutableObject<ResubmitRequestPopupData> | null;

export default function (state: ResubmitRequestPopupType, action: Action): ResubmitRequestPopupType {
    switch (action.type) {
        case SHOW_RESUBMIT_REQUEST_POPUP:
            return immutable<ResubmitRequestPopupData>({
                id: RESUBMIT_REQUEST_POPUP,
            });

        case SAVE_AND_RESUBMIT_REQUEST_RESPONSE:
            return null;

        default:
            return state;
    }
}
