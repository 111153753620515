import { font, LoadingBar, oPopupBorderBottom, uTextEllipsis } from '@approvalmax/ui';
import styled from 'styled-components';

export const TitleBlock = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 40px;
    flex: none;
    ${oPopupBorderBottom()};
`;

export const TitleText = styled.div`
    flex: 1;
    ${uTextEllipsis};
    ${font(13, '#000', 'semibold')};
`;

export const ContentBlock = styled.div`
    flex: 1;
    overflow: auto;
`;

export const ContentScrollable = styled.div`
    display: flex;
    flex-flow: column;
    padding-bottom: 30px;
`;

export const StyledLoadingBar = styled(LoadingBar)<{ showLoading: boolean }>`
    position: absolute;
    left: 0;
    right: 0;
    opacity: ${(props) => (props.showLoading ? 1 : 0)};
    transition: opacity 500ms ease-in-out;
    bottom: -1px;
    z-index: 1;
`;
