import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import {
    UseCreateAmaxPayBatchPaymentFromBillData,
    UseCreateAmaxPayBatchPaymentFromBillPathParams,
    UseCreateAmaxPayBatchPaymentFromBillResponse,
} from './useCreateAmaxPayBatchPaymentFromBill.types';

export const useCreateAmaxPayBatchPaymentFromBill = (
    mutationOptions?: MutationOptions<
        UseCreateAmaxPayBatchPaymentFromBillResponse,
        RequestDataParams<UseCreateAmaxPayBatchPaymentFromBillData, UseCreateAmaxPayBatchPaymentFromBillPathParams>
    >
) => {
    return useMutate<
        RequestDataParams<UseCreateAmaxPayBatchPaymentFromBillData, UseCreateAmaxPayBatchPaymentFromBillPathParams>,
        UseCreateAmaxPayBatchPaymentFromBillResponse
    >(requestsApiPaths.amaxPayBatchPaymentFromBill, { mutationOptions, apiVersion: 'v2' });
};
