export const sendChameleonEvent = (eventType: string) => {
    if (typeof window.chmln?.track === 'function') {
        window.chmln.track(eventType);
    }
};

export const setChameleonOrgId = (orgId: string) => {
    if (window.chameleon?.identifyData && typeof window.chameleon?.identify === 'function') {
        window.chameleon.identifyData.org_id = orgId;
        window.chameleon.identify();
    }
};
