import { Button, Flex, Form, Spacing, Text, TextField } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { fieldNameEmailVerificationCode } from '../../TwoFaEnablingWizard.constants';
import { AnotherMethodButton } from '../AnotherMethodButton/AnotherMethodButton';
import { StepperTwoFaWizard } from '../StepperTwoFaWizard/StepperTwoFaWizard';
import { useStepBackupEmail } from './StepBackupEmailVerify.hooks';
import { messages } from './StepBackupEmailVerify.messages';

const qa = bemFactory.qa('pro-two-fa-setup-email-confirm');

export const StepBackupEmailVerify = memo(() => {
    const { email, isLoading, handleGoStepEmail, handleRequestCode } = useStepBackupEmail();

    return (
        <Form.Part>
            <Flex inline direction='column' spacing='24' data-qa={qa()}>
                <StepperTwoFaWizard activeStep={2} />

                <Flex inline direction='column' spacing='16'>
                    <Flex inline direction='column' spacing='8'>
                        <Text font='label' fontSize='large' fontWeight='regular'>
                            {messages.title}
                        </Text>

                        <Flex inline direction='row' spacing='8'>
                            <Text font='headline' fontSize='xsmall' fontWeight='medium' spacing='0'>
                                {email}
                            </Text>

                            <Button
                                noPadding
                                type='button'
                                color='transparent'
                                size='medium'
                                onClick={handleGoStepEmail}
                                disabled={isLoading}
                                uppercase={false}
                                fontWeight='regular'
                            >
                                {messages.buttonChangeAddress}
                            </Button>
                        </Flex>
                    </Flex>

                    <Text font='body' fontSize='medium' fontWeight='regular'>
                        {messages.description}
                    </Text>
                </Flex>

                <Flex inline direction='row' spacing='32'>
                    <TextField
                        width={240}
                        name={fieldNameEmailVerificationCode}
                        required
                        initFocus
                        disabled={isLoading}
                        label={messages.emailCodeLabel}
                        placeholder={messages.emailCodePlaceholder}
                    />

                    <Flex inline direction='column' spacing='0'>
                        <Spacing height={16} />

                        <Button
                            type='button'
                            color='transparent'
                            size='medium'
                            onClick={handleRequestCode}
                            disabled={isLoading}
                        >
                            {messages.buttonResendCode}
                        </Button>
                    </Flex>
                </Flex>

                <Flex inline direction='column' spacing='16'>
                    <Button type='submit' size='large' color='blue80' disabled={!email.trim()}>
                        {messages.buttonNext}
                    </Button>

                    <AnotherMethodButton />
                </Flex>
            </Flex>
        </Form.Part>
    );
});

StepBackupEmailVerify.displayName = 'StepBackupEmailVerify';
