import { RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { slackApiPaths } from '../paths';
import { UseSlackAuthCompleteParams, UseSlackAuthCompleteResponse } from './useSlackAuthComplete.types';

export const useSlackAuthComplete = () => {
    return useMutate<RequestDataParams<UseSlackAuthCompleteParams, {}>, UseSlackAuthCompleteResponse>(
        slackApiPaths.authComplete,
        {
            apiSource: 'slack',
        }
    );
};
