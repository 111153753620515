import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SHOW_XERO_REQUEST_BUDGET_POPUP } from '../../../actions';

export const XERO_REQUEST_BUDGET_POPUP = 'XERO_REQUEST_BUDGET_POPUP';

export interface XeroRequestBudgetPopupData {
    id: typeof XERO_REQUEST_BUDGET_POPUP;
    requestId: Guid;
    budgetId: Guid;
}

export type XeroRequestBudgetPopupType = ImmutableObject<XeroRequestBudgetPopupData> | null;

export default function (state: XeroRequestBudgetPopupType, action: Action): XeroRequestBudgetPopupType {
    switch (action.type) {
        case SHOW_XERO_REQUEST_BUDGET_POPUP:
            return immutable<XeroRequestBudgetPopupData>({
                id: XERO_REQUEST_BUDGET_POPUP,
                requestId: action.payload.requestId,
                budgetId: action.payload.budgetId,
            });

        default:
            return state;
    }
}
