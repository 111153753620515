import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { ElementFactory } from 'react-bem-factory';

import { ChangeHandler } from '../../WorkflowSettingsPopupContent.types';

export interface SectionUseRejectedPrefixProps {
    templateSettings: domain.TemplateSettings;
    onChange: ChangeHandler<boolean>;
    readonly: boolean;
    links: selectors.types.LinksCollection;
    qa: ElementFactory;
}

export enum RejectedPrefixOption {
    yes = 'yes',
    no = 'no',
}
