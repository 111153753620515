import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { ImmutableObject } from 'modules/immutable';

import { pageConfig } from '../../config';
import { ActiveModule } from '../reducers/page/moduleReducer';
import { Page } from '../reducers/pageReducer';

export const getPage = (state: State): Page => selectors.page.getPage(state, pageConfig.id);

export const getModule = <T extends ActiveModule = ActiveModule>(state: State): ImmutableObject<T> =>
    getPage(state).module as ImmutableObject<T>;

export const getLoading = (state: State): boolean => getPage(state).loading;
