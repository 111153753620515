import Mention from '@tiptap/extension-mention';

import {
    dataAttributeEmail,
    dataAttributeName,
    dataAttributePlaceholderName,
    dataAttributeUserId,
} from './mention.constants';

const CustomMention = Mention.extend({
    addAttributes() {
        return {
            userId: {
                default: null,
                parseHTML: (element) => element.getAttribute(dataAttributeUserId),
                renderHTML: (attributes) =>
                    attributes.userId
                        ? {
                              [dataAttributeUserId]: attributes.userId,
                          }
                        : {},
            },
            email: {
                default: null,
                parseHTML: (element) => element.getAttribute(dataAttributeEmail),
                renderHTML: (attributes) =>
                    attributes.email
                        ? {
                              [dataAttributeEmail]: attributes.email,
                          }
                        : {},
            },
            name: {
                default: null,
                parseHTML: (element) => element.getAttribute(dataAttributeName),
                renderHTML: (attributes) =>
                    attributes.name
                        ? {
                              [dataAttributeName]: attributes.name,
                          }
                        : {},
            },
            placeholderName: {
                default: null,
                parseHTML: (element) => element.getAttribute(dataAttributePlaceholderName),
                renderHTML: (attributes) =>
                    attributes.placeholderName
                        ? {
                              [dataAttributePlaceholderName]: attributes.placeholderName,
                          }
                        : {},
            },
        };
    },
});

export default CustomMention;
