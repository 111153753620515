import { PascalCaseToCamelCase } from '@approvalmax/types';
import { backend, domain } from 'modules/data';

export const isSalesInvoiceDetails = (
    details: domain.QBooksRequestDetails
): details is domain.QBooksSalesInvoiceDetails => {
    return details.integrationCode === domain.IntegrationCode.QBooksInvoice;
};

export const isSalesInvoiceLineItemAnswer = (
    lineItem: PascalCaseToCamelCase<
        backend.IntegrationsQBooksItemBasedLineAnswer | backend.IntegrationsQBooksSalesInvoiceItemBasedLineAnswer
    >
): lineItem is PascalCaseToCamelCase<backend.IntegrationsQBooksSalesInvoiceItemBasedLineAnswer> => {
    return 'discount' in lineItem || 'discountAmount' in lineItem || 'serviceDate' in lineItem;
};
