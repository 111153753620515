import { Guid } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';

import { CompanyRequestListFilter, PAGE_ID, RequestListFilter, TemplateRequestListFilter } from '../config';
import { ActiveFilterType } from '../reducers/page/activeFilterReducer';
import { Page } from '../reducers/pageReducer';
import { messages } from './pageSelectors.messages';

export function getPage(state: State): Page {
    return selectors.page.getPage(state, PAGE_ID);
}

export function getPageSafe(state: State): Page | null {
    return selectors.page?.getPageSafe(state, PAGE_ID) || null;
}

export function getIsRequestListPaginationReloading(state: State): boolean {
    if (!state.pages) {
        return false;
    }

    const pageRequestList = selectors.page.getPage<Page>(state, PAGE_ID);

    return Boolean(pageRequestList?.pagination.reloading);
}

export function checkFilterLoaded(
    state: State,
    options: {
        companyId: string | null;
        templateId: string | null;
        filter: RequestListFilter | CompanyRequestListFilter | TemplateRequestListFilter;
    }
): boolean {
    const isActive = selectors.navigation.getActivePageId(state) === PAGE_ID;

    if (!isActive) {
        return false;
    }

    if (options.templateId) {
        // Validate template match
        const activeTemplateId = getActiveTemplateId(state);

        if (activeTemplateId !== options.templateId) {
            return false;
        }
    } else {
        // Validate company match
        const activeCompanyId = selectors.navigation.getActiveCompanyId(state);

        if (activeCompanyId !== options.companyId) {
            return false;
        }
    }

    return options.filter === getPage(state).activeFilter;
}

export function checkRequestLoaded(state: State, requestId: string): boolean {
    const isActive = selectors.navigation.getActivePageId(state) === PAGE_ID;

    if (!isActive) {
        return false;
    }

    return getPage(state).requests.includes(requestId);
}

export function getActiveFilter(state: State) {
    return getPage(state).activeFilter;
}

export function getActiveTemplateId(state: State): Guid | null {
    return getPage(state).activeTemplateId;
}

export function getActiveTemplate(state: State): domain.TemplateInfo | null {
    const templateId = getActiveTemplateId(state);

    if (templateId) {
        const template = selectors.meta.getTemplateInfoById(state, templateId);

        return template;
    }

    return null;
}

export function getActiveRequestId(state: State): Guid | null {
    return getPage(state).activeRequestId;
}

export function getActiveRequestIdSafe(state: State): Guid | null {
    return getPageSafe(state)?.activeRequestId || null;
}

export function getActiveRequest<T extends selectors.types.ExpandedRequest = selectors.types.ExpandedRequest>(
    state: State
) {
    const requestId = getActiveRequestId(state);

    if (!requestId) {
        throw errorHelpers.notFoundError();
    }

    return selectors.request.getRequestById<T>(state, requestId);
}

export function getActiveRequestSafe(state: State) {
    const requestId = getActiveRequestIdSafe(state);

    if (!requestId) {
        return null;
    }

    return selectors.request.findRequestById(state, requestId);
}

export function isActiveRequestLocked(state: State) {
    const activeRequestId = getActiveRequestId(state);

    if (!activeRequestId) {
        return false;
    }

    return getPage(state).lockedRequests.includes(activeRequestId);
}

export function getActivePopupId(state: State): string | null {
    const activePopup = getPage(state).activePopup;

    return activePopup ? activePopup.id : null;
}

export function getActivePopup<TPopupData>(state: State): TPopupData {
    return getPage(state).activePopup as any as TPopupData;
}

export function isEmptyList(state: State): boolean {
    const page = getPage(state);

    return Boolean(!page.activeRequestId && page.requests.length === 0 && !page.requestSearch);
}

export function isSearchingNow(state: State): boolean {
    const page = getPage(state);

    return Boolean(page.requestSearch && !page.requestSearch.loaded);
}

export function isSearchNothingFound(state: State): boolean {
    const page = getPage(state);

    return Boolean(page.requestSearch && page.requestSearch.loaded && page.requests.length === 0);
}

export function isInSearchMode(state: State) {
    const page = getPage(state);

    return Boolean(page.requestSearch);
}

export function getSearchParams(state: State) {
    const page = getPage(state);

    if (!page.requestSearch) {
        return null;
    }

    return page.requestSearch.searchParams;
}

export function getDocumentTitle(state: State): string {
    if (isInSearchMode(state)) {
        return messages.searchModeTitleText;
    }

    const page = getPage(state);
    const requestId = page.activeRequestId;

    if (requestId) {
        const request = selectors.request.getRequestById(state, requestId);

        return request.displayName;
    }

    let companyName = '';

    if (selectors.navigation.getActiveCompanyId(state)) {
        companyName = selectors.navigation.getActiveCompany(state).displayName;
    }

    let title;

    switch (page.activeFilter) {
        case RequestListFilter.MyDecisionRequired:
            title = messages.pageMyDecisionRequiredTitleText;
            break;

        case RequestListFilter.MyReviewRequired:
            title = messages.pageMyReviewRequiredTitleText;
            break;

        case RequestListFilter.MyOcrRequests:
            title = messages.pageMyOcrRequestsTitleText;
            break;

        case RequestListFilter.MyOnlyOpen:
            title = messages.pageMyOpenTitleText;
            break;

        case RequestListFilter.MyReadyToPay:
            title = messages.pageMyReadyToPayTitleText;
            break;

        case RequestListFilter.MyDraft:
            title = messages.pageMyDraftTitleText;
            break;

        case CompanyRequestListFilter.CompanyMyDecisionRequired:
            title = messages.pageCompanyDecisionRequiredTitleText({
                companyName,
            });
            break;

        case CompanyRequestListFilter.CompanyMyReviewRequired:
            title = messages.pageCompanyReviewRequiredTitleText({
                companyName,
            });
            break;

        case CompanyRequestListFilter.CompanyMyOpen:
            title = messages.pageCompanyMyOpenTitleText({
                companyName,
            });
            break;

        case CompanyRequestListFilter.CompanyAllOpen:
            title = messages.pageCompanyAllOpenTitleText({
                companyName,
            });
            break;

        case CompanyRequestListFilter.CompanyAllOnHold:
            title = messages.pageTitleRequestsOnHold({
                companyName,
            });
            break;

        case CompanyRequestListFilter.CompanyAll:
            title = messages.pageCompanyAllTitleText({
                companyName,
            });
            break;

        case TemplateRequestListFilter.AllByType:
            title = messages.pageTitleRequestsAll({
                companyName,
            });
            break;

        case TemplateRequestListFilter.OnApproval:
            title = messages.pageTitleRequestsOnApproval({
                companyName,
            });
            break;

        case TemplateRequestListFilter.OnHold:
            title = messages.pageTitleRequestsOnHold({
                companyName,
            });
            break;

        case TemplateRequestListFilter.OnReview:
            title = messages.pageTitleRequestsOnReview({ companyName });
            break;

        case TemplateRequestListFilter.Approved:
            title = messages.pageTitleApprovedRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.Cancelled:
            title = messages.pageTitleCancelledRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.Rejected:
            title = messages.pageTitleRejectedRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.Paid:
            title = messages.pageTitlePaidRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.Billed:
            title = messages.pageTitleBilledRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.NotBilled:
            title = messages.pageTitleNotBilledRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.AwaitingPayment:
            title = messages.pageTitleAwaitingPaymentRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.PartiallyPaid:
            title = messages.pageTitlePartiallyPaidRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.Failed:
            title = messages.pageTitleFailedRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.Processing:
            title = messages.pageTitleProcessingRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.GrnNotReceived:
            title = messages.pageTitleGrnNotReceivedRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.GrnPartiallyReceived:
            title = messages.pageTitleGrnPartiallyReceivedRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.GrnFullyReceived:
            title = messages.pageTitleGrnFullyReceivedRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.Accepted:
            title = messages.pageTitleAcceptedRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.Declined:
            title = messages.pageTitleDeclinedRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.Credited:
            title = messages.pageTitleCreditedRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.PartiallyReceived:
            title = messages.pageTitlePartiallyReceivedRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.PendingBill:
            title = messages.pageTitlePendingBillRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.PendingReceipt:
            title = messages.pageTitlePendingReceiptRequests({
                companyName,
            });
            break;

        case TemplateRequestListFilter.Closed:
            title = messages.pageTitleClosedRequests({
                companyName,
            });
            break;

        case null:
            throw errorHelpers.notSupportedError();

        default:
            throw errorHelpers.assertNever(page.activeFilter);
    }

    return title;
}

export function getActiveTemplateName(state: State): string {
    const template = getActiveTemplate(state);

    let templateName = '';

    if (template) {
        const integrationCode = template.integrationCode;

        templateName = selectors.template.getTemplateDisplayNameByCode(integrationCode, true) || template.name || '';
    }

    return templateName;
}

export function isActiveTemplateIsCreatable(state: State): boolean {
    const template = getActiveTemplate(state);

    if (template && template.enabled) {
        const integrationCode = template.integrationCode;

        return ![
            domain.IntegrationCode.XeroCreditNotesPayable,
            domain.IntegrationCode.XeroCreditNotesReceivable,
            domain.IntegrationCode.NetSuiteSalesOrder,
            domain.IntegrationCode.NetSuiteVRA,
            domain.IntegrationCode.NetSuiteRA,
            domain.IntegrationCode.NetSuiteBillPayment,
        ].includes(integrationCode!);
    }

    return false;
}

export function getActiveTemplateIsStandalone(state: State): boolean {
    const template = getActiveTemplate(state);

    let isStandalone = false;

    if (template) {
        const integrationCode = template.integrationCode;

        isStandalone = selectors.integration.getIntegrationType(integrationCode) === domain.IntegrationType.None;
    }

    return isStandalone;
}

export function getActiveTemplateIntegrationCode(state: State): domain.IntegrationCode | null {
    const template = getActiveTemplate(state);

    return template?.integrationCode || null;
}

export function getRequests(state: State) {
    const page = getPage(state);

    return page.requests.map((id) => selectors.request.getRequestById(state, id));
}

export function getPagination(state: State) {
    const page = getPage(state);

    return page.pagination;
}

export function getFilterAmplitudeEventName(filter: ActiveFilterType): string {
    let amplitudeEventName = '';

    switch (filter) {
        case RequestListFilter.MyDecisionRequired:
            amplitudeEventName = 'requires my decision';
            break;

        case RequestListFilter.MyDraft:
            amplitudeEventName = 'drafts';
            break;

        case RequestListFilter.MyOnlyOpen:
            amplitudeEventName = 'created by me';
            break;

        case CompanyRequestListFilter.CompanyMyDecisionRequired:
            amplitudeEventName = 'company requires my decision';
            break;

        case CompanyRequestListFilter.CompanyMyReviewRequired:
            amplitudeEventName = 'company requires my review';
            break;

        case CompanyRequestListFilter.CompanyAllOnHold:
            amplitudeEventName = 'company on hold';
            break;

        case TemplateRequestListFilter.AllByType:
            amplitudeEventName = 'all';
            break;

        case TemplateRequestListFilter.OnApproval:
            amplitudeEventName = 'on approval';
            break;

        case TemplateRequestListFilter.Approved:
            amplitudeEventName = 'approved';
            break;

        case TemplateRequestListFilter.Cancelled:
            amplitudeEventName = 'cancelled';
            break;

        case TemplateRequestListFilter.Rejected:
            amplitudeEventName = 'rejected';
            break;

        case TemplateRequestListFilter.Paid:
            amplitudeEventName = 'paid';
            break;

        case TemplateRequestListFilter.Billed:
            amplitudeEventName = 'billed';
            break;
    }

    return amplitudeEventName;
}
