import {
    Action,
    LOGIN,
    LOGIN_FAILURE,
    LOGIN_RESPONSE,
    RESET_PASSWORD,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_RESPONSE,
    SIGN_IN_WITH,
    SIGN_IN_WITH_FAILURE,
    SIGNUP,
    SIGNUP_FAILURE,
    SIGNUP_RESPONSE,
} from '../../actions/index';

const INITIAL_STATE = false;

export default function (state = INITIAL_STATE, action: Action): boolean {
    switch (action.type) {
        case LOGIN:
        case SIGNUP:
        case RESET_PASSWORD:
        case SIGN_IN_WITH:
            return true;

        case LOGIN_RESPONSE:
        case LOGIN_FAILURE:
        case SIGNUP_RESPONSE:
        case SIGNUP_FAILURE:
        case RESET_PASSWORD_RESPONSE:
        case RESET_PASSWORD_FAILURE:
        case SIGN_IN_WITH_FAILURE:
            // Only on failure, on success we keep waiting for redirect with disabled controls
            return false;

        default:
            return state;
    }
}
