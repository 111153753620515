import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.StepDeadline.DeadlineButton', {
    workflowApprovalStepEmptyDurationH3: 'Set a decision due date for Approvers',
    workflowReviewStepEmptyDurationH3: 'Set a decision due date for Reviewers',
    workflowStepDurationH3: 'The decision due date for this step is in',
    durationDaysPlural: `{count, plural, one {# d} other {# d}}`,
    durationHoursPlural: `{count, plural, one {# h} other {# h}}`,
    typeLabelNotSet: 'Not set',
    typeLabelBasedOnSubmission: 'Based on Submission',
    typeLabelBasedOnApproval: 'Based on Approval',
    typeLabelBasedOnReview: 'Based on the start of the review',
});
