import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { paymentServicesApiPaths } from '../paths';
import { UseGetDextTransactionRequestParams, UseGetDextTransactionResponse } from './useGetDextTransaction.types';

export const useGetDextTransaction = (
    pathParams: UseGetDextTransactionRequestParams,
    queryOptions?: QueryOptions<UseGetDextTransactionResponse>
) => {
    return useGetRequest<UseGetDextTransactionResponse>(paymentServicesApiPaths.getDextTransaction, {
        pathParams,
        queryOptions,
        apiVersion: 'v2',
    });
};
