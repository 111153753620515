import {
    UseStartAddingTFAAlternativeEmailBackend,
    UseStartAddingTFAAlternativeEmailResponse,
} from './useStartAddingTFAAlternativeEmail.types';

export const mapData = (
    response: UseStartAddingTFAAlternativeEmailBackend
): UseStartAddingTFAAlternativeEmailResponse => {
    return {
        stateId: response.StateId,
    };
};
