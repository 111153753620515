import { ErrorCode } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { backend, domain, schemas } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { normalize } from 'normalizr';
import { api } from 'services/api';
import { routingService } from 'services/routing';
import { storageService } from 'services/storage';
import { getDefaultPath, getPartnerUrl, getPath, Path } from 'urlBuilder';

export const VERIFY_EMAIL = 'AUTH/VERIFY_EMAIL';
export const VERIFY_EMAIL_RESPONSE = 'AUTH/VERIFY_EMAIL_RESPONSE';
export const VERIFY_EMAIL_FAILURE = 'AUTH/VERIFY_EMAIL_FAILURE';

interface CodeVerifyEmailOptions {
    email: string;
    code: string;
    app?: domain.SsoLoginApplication;
    postAction?: string;
}

interface LinkCodeVerifyEmailOptions {
    linkCode: string;
    app?: domain.SsoLoginApplication;
    postAction?: string;
}

export const verifyEmail = (options: CodeVerifyEmailOptions | LinkCodeVerifyEmailOptions) => {
    storageService.setAuthenticated(false);

    const apiOptions: backend.transfers.AuthVerifyTransfer = {
        linkCode: '',
        eternal: true,
    };

    if ('email' in options) {
        apiOptions.email = options.email;
        apiOptions.verificationCode = options.code;
    } else {
        apiOptions.linkCode = options.linkCode;
    }

    return createAsyncAction({
        request: () =>
            createAction(VERIFY_EMAIL, {
                ...options,
            }),

        response: async (request) => {
            const response = await api.auth.verify(apiOptions);
            const context = await api.companies.getUserContext({});

            const { entities } = normalize(response, { User: schemas.userSchema });
            const user = entities.users ? Object.values(entities.users)[0] : undefined;

            return createAction(VERIFY_EMAIL_RESPONSE, {
                request,
                user,
                context,
            });
        },

        failure: (error) => createErrorAction(VERIFY_EMAIL_FAILURE, error),

        willDispatchResponse: (
            _,
            response: { user: domain.User; context?: backend.CompaniesUserCompaniesContextAnswer }
        ) => {
            storageService.setAuthenticated(true);

            if (options.app) {
                let appUrl;

                switch (options.app) {
                    case domain.SsoLoginApplication.Account:
                        routingService.reloadToUrl(getDefaultPath());

                        return;

                    case domain.SsoLoginApplication.Partner:
                        if (!response.context?.PracticeInvitations.length && !response.context?.Invitations.length) {
                            appUrl = getPartnerUrl();
                        } else {
                            routingService.reloadToUrl(getDefaultPath());

                            return;
                        }

                        break;

                    default:
                        throw errorHelpers.assertNever(options.app);
                }

                if (options.postAction) {
                    appUrl += options.postAction.startsWith('/') ? options.postAction : `/${options.postAction}`;
                }

                routingService.reloadToUrl(appUrl);
            } else {
                routingService.reloadToUrl(options.postAction || getDefaultPath());
            }
        },

        willDispatchError: (_, error) => {
            const errorCode = errorHelpers.getErrorCode(error);

            switch (errorCode) {
                case ErrorCode.E4002_WRONG_CONFIRMATION_CODE:
                    routingService.push(
                        getPath(Path.login, {
                            workspace: options.app,
                            redirectPath: options.postAction,
                        })
                    );

                    return;

                case ErrorCode.E4081_CONFIRMATION_CODE_EXPIRED:
                    routingService.push(
                        getPath(Path.login, {
                            workspace: options.app,
                            redirectPath: options.postAction,
                        })
                    );

                    return;

                default:
                    routingService.push(
                        getPath(Path.login, {
                            workspace: options.app,
                            redirectPath: options.postAction,
                        })
                    );

                    return;
            }
        },
    });
};

export type Action = ExtractActions<typeof verifyEmail>;
