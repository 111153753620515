import { Radio } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionSafetyCatch.messages';
import { SectionSafetyCatchProps } from './SectionSafetyCatch.types';

const SectionSafetyCatch: FC<SectionSafetyCatchProps> = memo((props) => {
    const { templateSettings, onChangeSafetyCatchPolicy, readonly, qa } = props;

    const value =
        (templateSettings.autoDecisionPolicySettings! || {}).autoDecisionPolicy ||
        domain.TemplateSettingsAutoDecisionPolicyType.AutoApprove;

    return (
        <SettingsSection title={messages.title} data-qa={qa('safety-catch')}>
            <SettingsSubsection subtitle={messages.subtitle} warning={messages.warningText}>
                <Radio.Group
                    disabled={readonly}
                    value={value}
                    onChange={onChangeSafetyCatchPolicy}
                    name='safetyCatchPolicy'
                    block
                >
                    <Radio value={domain.TemplateSettingsAutoDecisionPolicyType.AutoApprove}>
                        {messages.optionApprove}
                    </Radio>

                    <Radio value={domain.TemplateSettingsAutoDecisionPolicyType.AutoReject}>
                        {messages.optionReject}
                    </Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionSafetyCatch;
