import './emptyBlock.scss';

import ImageWordless from 'modules/components/ImageIcons/ImageWordless';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';

import ProfileSection from './profileSection/ProfileSection';

const i18nPrefix = '@NoCompanies/no-invites';

const bem = bemFactory.block('no-inv-block');

const EmptyBlock = () => {
    return (
        <div className={bem('')}>
            <ImageWordless />

            <div className={bem('no-env-title')}>
                <FormattedMessage
                    id={`${i18nPrefix}.titleText`}
                    defaultMessage='Looks like you do not have {br}any pending invitations'
                    values={{
                        br: <br />,
                    }}
                />
            </div>

            <div className={bem('no-env-description')}>
                <FormattedMessage
                    id={`${i18nPrefix}.descriptionText`}
                    defaultMessage={
                        'Please ask your Administrator to invite you to an active {br}' +
                        'ApprovalMax Organisations and check your emails.'
                    }
                    values={{
                        br: <br />,
                    }}
                />
            </div>

            <ProfileSection />
        </div>
    );
};

export default EmptyBlock;
