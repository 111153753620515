import { AddIcon, ChevronDownIcon, ChevronUpIcon, OcrIcon } from '@approvalmax/ui';
import { Box, Button, Dropdown, Flex, Menu, Text } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';
import { useToggle } from 'react-use';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import { splitViewGoToBarSettingsState } from '../SplitViewGoToBar/SplitViewGoToBar.states';
import { messages } from './FileMenuDropdown.messages';
import { FileMenuProps } from './FileMenuDropdown.types';

export const FileMenuDropdown: FC<FileMenuProps> = memo((props) => {
    const { title, attachments, request, showAddButton = true } = props;

    const [contentSplitView, setContentSplitView] = useRecoilState(contentSplitViewState);
    const setGoToBarSettings = useSetRecoilState(splitViewGoToBarSettingsState);
    const [open, toggleOpen] = useToggle(false);

    const onUploadFIles = useCallback(() => {
        setGoToBarSettings({
            show: true,
            targetContent: contentSplitView,
            targetContentName: title,
        });
        setContentSplitView({ mode: ContentSplitViewMode.Empty });
        toggleOpen(false);
    }, [contentSplitView, setContentSplitView, setGoToBarSettings, title, toggleOpen]);

    return (
        <Dropdown
            open={open}
            onOpen={toggleOpen}
            width='max-content'
            activator={
                attachments.length >= 1 ? (
                    <Button icon outline color='white100' fontWeight='regular' uppercase={false}>
                        <Flex inline spacing='4' wrap={false} alignItems='center'>
                            <Text font='body' ellipsis={1}>
                                {title}
                            </Text>

                            <Box width={16} height={16}>
                                {open ? <ChevronUpIcon size={16} /> : <ChevronDownIcon size={16} />}
                            </Box>
                        </Flex>
                    </Button>
                ) : (
                    title
                )
            }
        >
            {attachments.length >= 1 && (
                <Menu>
                    {attachments.map((attachment) => {
                        const isOcrAttachment = request?.attachments.some(
                            (item) => attachment.id === item.id && item.ocrStatus === domain.OcrStatus.Succeeded
                        );

                        return (
                            <Menu.Item
                                name={attachment.name}
                                title={attachment.name}
                                endIcon={isOcrAttachment ? <OcrIcon size='16' /> : undefined}
                                onClick={() => {
                                    setContentSplitView((prevState) => ({ ...prevState, attachmentId: attachment.id }));
                                    toggleOpen(false);
                                }}
                            />
                        );
                    })}

                    {showAddButton && (
                        <Menu.Item
                            onClick={onUploadFIles}
                            startIcon={<AddIcon size={16} />}
                            name={messages.uploadFilesButtonText}
                            title={messages.uploadFilesButtonText}
                        />
                    )}
                </Menu>
            )}
        </Dropdown>
    );
});

FileMenuDropdown.displayName = 'FileMenuDropdown';
