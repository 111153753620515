import { mathService } from '@approvalmax/utils';
import { backend, domain, State } from 'modules/data';
import { ExpandedXeroLineItem } from 'pages/requestForm/selectors/types/ExpandedXeroLineItem';

import { getXeroLineItems } from './lineItemSelectors';
import { getXeroLineItemTransfer } from './transferSelectors';
import { getXeroContext } from './xeroSelectors';

export function calculateLineCisDeduction(
    lineItem: ExpandedXeroLineItem,
    cisRate: number,
    lineAmountType: domain.LineAmountType
) {
    if (typeof lineItem.computedAmount !== 'number') {
        return;
    }

    const amount = mathService.round(lineItem.computedAmount, 2);
    const cisRateRatio = mathService.divide(cisRate, 100);

    let deduction = 0;

    if (lineAmountType === domain.LineAmountType.TaxInclusive) {
        const taxAmount =
            (typeof lineItem.taxAmount === 'number' ? lineItem.taxAmount : lineItem.computedTaxAmount) ?? 0;

        deduction = (amount - taxAmount) * cisRateRatio;
    } else {
        deduction = amount * cisRateRatio;
    }

    return mathService.round(deduction, 2);
}

export function calculateCisDeductionBill(
    lineItems: ExpandedXeroLineItem[],
    cisRate: number,
    lineAmountType: domain.LineAmountType
) {
    return calculateCisDeduction(lineItems, cisRate, lineAmountType, domain.XeroSystemAccountType.CisLabour);
}

export function calculateCisDeductionInvoice(
    lineItems: ExpandedXeroLineItem[],
    cisRate: number,
    lineAmountType: domain.LineAmountType
) {
    return calculateCisDeduction(lineItems, cisRate, lineAmountType, domain.XeroSystemAccountType.CisLabourIncome);
}

function calculateCisDeduction(
    lineItems: ExpandedXeroLineItem[],
    cisRate: number,
    lineAmountType: domain.LineAmountType,
    /**
     * use CisLabour for Bill
     * use CisLabourIncome for Invoice
     */
    cisAccountCode: domain.XeroSystemAccountType.CisLabour | domain.XeroSystemAccountType.CisLabourIncome
) {
    const sum = lineItems.reduce((deduction, lineItem) => {
        const account: domain.XeroAccount | undefined = lineItem.account;

        if (account?.systemAccount !== cisAccountCode) {
            return deduction;
        }

        const lineCisDeduction = calculateLineCisDeduction(lineItem, cisRate, lineAmountType);

        if (typeof lineCisDeduction === 'number') {
            return mathService.add(deduction ?? 0, lineCisDeduction);
        }

        return deduction;
    }, undefined);

    if (typeof sum === 'number') {
        return mathService.round(sum, 2);
    }

    return sum;
}

export const addCisDeduction = (
    invoiceDetailsTransfer: backend.Transfer<backend.transfers.IntegrationXeroInvoiceTransfer>,
    request: domain.XeroBillRequest | domain.XeroInvoiceRequest,
    state: State,
    cisRate: number,
    cisAccountCode: domain.XeroSystemAccountType.CisLabour | domain.XeroSystemAccountType.CisLabourIncome
) => {
    const details = request.details;

    const lineItemsWithCIS = getXeroLineItems(state, request).filter((li) => !li.empty);

    const cisTaxAmount = calculateCisDeduction(lineItemsWithCIS, cisRate, details.lineAmountType, cisAccountCode);

    const lineItemsTransferWithCIS = lineItemsWithCIS.map((li) => {
        const transferLine = getXeroLineItemTransfer(state, li, request);

        if (li.descriptionOnly) {
            return transferLine;
        }

        const account: domain.XeroAccount | undefined = li.account;

        if (account?.systemAccount !== cisAccountCode) {
            return transferLine;
        }

        const lineCisAmount = calculateLineCisDeduction(li, cisRate, details.lineAmountType);

        if (typeof lineCisAmount === 'number') {
            transferLine.cisTaxRate = cisRate;
            transferLine.cisTaxAmount = lineCisAmount;
        }

        return transferLine;
    });

    invoiceDetailsTransfer.cisTaxAmount = cisTaxAmount;
    invoiceDetailsTransfer.lineItems = lineItemsTransferWithCIS;

    return invoiceDetailsTransfer;
};

export const addBillOrInvoiceCisDeductionIfNeed = (
    invoiceDetailsTransfer: backend.Transfer<backend.transfers.IntegrationXeroInvoiceTransfer>,
    state: State,
    request: domain.XeroBillRequest | domain.XeroInvoiceRequest
) => {
    const details = request.details;

    if (
        (details.integrationCode !== domain.IntegrationCode.XeroBill &&
            details.integrationCode !== domain.IntegrationCode.XeroInvoice) ||
        !invoiceDetailsTransfer.lineItems ||
        invoiceDetailsTransfer.lineItems.length === 0
    ) {
        return invoiceDetailsTransfer;
    }

    const xeroContext = getXeroContext(state);
    const organizationCisSettings = xeroContext.cisSettings;

    if (request.integrationCode === domain.IntegrationCode.XeroBill) {
        if (!organizationCisSettings?.contractorEnabled) {
            return invoiceDetailsTransfer;
        }

        if (
            !details.contactCisSettings ||
            !details.contactCisSettings.isCisEnabled ||
            typeof details.contactCisSettings.cisRate !== 'number'
        ) {
            return invoiceDetailsTransfer;
        }

        const cisRate = details.contactCisSettings.cisRate;

        return addCisDeduction(invoiceDetailsTransfer, request, state, cisRate, domain.XeroSystemAccountType.CisLabour);
    } else {
        if (!organizationCisSettings?.subContractorEnabled) {
            return invoiceDetailsTransfer;
        }

        if (typeof organizationCisSettings.companyRate !== 'number') {
            return invoiceDetailsTransfer;
        }

        const cisRate = organizationCisSettings.companyRate;

        return addCisDeduction(
            invoiceDetailsTransfer,
            request,
            state,
            cisRate,
            domain.XeroSystemAccountType.CisLabourIncome
        );
    }
};
