import { companyHeaderState, Header as HeaderUI } from '@approvalmax/ui/src/components';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { backHeaderState } from 'shared/components';
import { getDefaultPath } from 'urlBuilder';

export const useResetHeaderStates = () => {
    const history = useHistory();
    const [previousLocation, setPreviousLocation] = useState('');
    const [isResetState, setIsResetState] = useState(false);

    useEffect(() => {
        const unlisten = history.listen(({ pathname }) => {
            /**
             * Reset header states on location start change
             */
            if (previousLocation && previousLocation !== pathname) {
                setIsResetState(true);

                /**
                 * Return isResetState to default state for trigger reset is isResetState next also true
                 */
                setTimeout(() => setIsResetState(false), 0);
            }

            setPreviousLocation(pathname);
        });

        return () => unlisten();
    });

    const resetState = useCallback<Required<ComponentProps<typeof HeaderUI>>['resetState']>(
        (reset) => {
            if (!isResetState) return;

            reset();
        },
        [isResetState]
    );

    return { resetState };
};

export const useBackHeader = (
    fallbackPath: string = getDefaultPath(),
    offHistoryBack?: boolean,
    preBackAction?: () => Promise<any>
) => {
    const history = useHistory();
    const setBackHeaderState = useSetRecoilState(backHeaderState);

    const onBack = useCallback(async () => {
        preBackAction && (await preBackAction());

        if (history.length > 1 && !offHistoryBack) {
            return history.goBack();
        }

        history.push(fallbackPath);
    }, [preBackAction, history, offHistoryBack, fallbackPath]);

    useEffect(() => {
        // commented due to release block
        // We need setTimeout to set back header state after useResetHeaderStates
        // setTimeout(() => setBackHeaderState(() => onBack), 0);
        // history.location.pathname
        setBackHeaderState(() => onBack);

        return () => setBackHeaderState(undefined);
    }, [onBack, setBackHeaderState]);
};

export const useHideCompanyHeader = () => {
    const toggleCompany = useSetRecoilState(companyHeaderState);

    useEffect(() => {
        toggleCompany(false);

        return () => {
            toggleCompany(true);
        };
    }, [toggleCompany]);
};
