import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingPopup.SectionEmailToSupplier', {
    defaultSection: 'Email to the Supplier settings',
    defaultTitle: 'Email to Supplier section',
    defaultDescription: 'Select how the Email to the Supplier section will behave by default:',
    defaultTemplateDescription: 'Specify the default subject and body of the email to the Supplier.',

    qBooksPoSection: 'Email to the Vendor settings',
    qBooksPoTitle: 'Email to Vendor section',
    qBooksPoDescription: 'Select how the Email to the Vendor section will behave by default:',
    qBooksPoTemplateDescription: 'Specify the default subject and body of the email to the Vendor.',

    qBooksSalesInvoiceSection: 'Email to the Customer settings',
    qBooksSalesInvoiceTitle: 'Email to Customer section',
    qBooksSalesInvoiceDescription: 'Select how the Email to the Customer section will behave by default:',
    qBooksSalesInvoiceTemplateDescription: 'Specify the default subject and body of the email to the Customer.',

    xeroQuoteSection: 'Email to the Customer settings',
    xeroQuoteTitle: 'Email to Customer section',
    xeroQuoteDescription: 'Select how the Email to the Customer section will behave by default:',
    xeroQuoteTemplateDescription: 'Specify the default subject and body of the email to the Customer.',

    xeroSalesInvoiceSection: 'Email to the Customer settings',
    xeroSalesInvoiceTitle: 'Email to Customer section',
    xeroSalesInvoiceDescription: 'Select how the Email to the Customer section will behave by default:',
    xeroSalesInvoiceTemplateDescription: 'Specify the default subject and body of the email to the Customer.',

    defaultCCTitle: 'Default CC email address',
    defaultCCDescription: 'Enter the email address(es) of the Organisation to be added as CC:',
    emailAddressesForCCOptionTitle: 'Email address(es) for CC option:',
    emailAddressesForCCOptionPlaceholder: 'Enter email',
    emailAddressesForCCOptionEmptyListPlaceholder: 'Type in a valid email address and press Enter',
    templateTitle: 'Email template',
    templateInstruction: 'You can use the following variables to specify this information in subject and body:',
    templateLegendCopy: 'COPY',
    templateSubject: 'Subject',
    templateSubjectPlaceholder: 'Enter a default email subject',
    templateBody: 'Body',
    templateBodyPlaceholder: 'Enter a default email body',
    companyNameLegendItemDescription: 'Organisation name.',
    contactPersonNameLegendItemDescription: 'Contact person name.',
    poNumberLegendItemDescription: 'Purchase order number.',
    poTotalAmountLegendItemDescription: 'Total amount of the purchase order.',
    quoteTotalAmountLegendItemDescription: 'Total amount of the quote.',
    quoteNumberLegendItemDescription: 'Quote number.',
    quoteTermsLegendItemDescription: 'Terms of quote.',
    quoteDecisionMakingWebPageLinkLegendItemDescription: 'Link to quote on the Customer Decision page',
    salesInvoiceNumberLegendItemDescription: 'Sales Invoice number.',
    salesInvoiceTotalAmountLegendItemDescription: 'Total amount of Sales Invoice.',
    supplierNameLegendItemDescription: 'Supplier name.',
    vendorNameLegendItemDescription: 'Vendor name.',
    customerNameLegendItemDescription: 'Customer name.',
    clientNameLegendItemDescription: 'Client name.',
    copiedToClipboard: 'Copied to clipboard!',
    copy: 'Copy',
    qboEmailToVendorEmailCcLimitExceeded:
        'Please note that maximum of {charactersNumber} characters can be used for all cc email addresses in the Email to the Vendor form. Exclude excessive characters to fit into the QuickBooks Online limit.',
    enabled: 'Enabled',
    disabled: 'Disabled',
    enabledAndChecked: 'Enabled and checked',
});
