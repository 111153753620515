import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { VerifyTFAWithBackupCodeRequest, VerifyTFAWithBackupCodeResponse } from './useVerifyTFAWithBackupCode.types';

export const useVerifyTFAWithBackupCode = () => {
    return useMutateLegacy<RequestDataParams<VerifyTFAWithBackupCodeRequest>, VerifyTFAWithBackupCodeResponse>(
        twoFaApiPaths.verifyTFAWithBackupCode
    );
};
