import { ErrorCode } from '@approvalmax/types';

export const authErrorCodes: string[] = [
    ErrorCode.E2002_USER_NOT_FOUND,
    ErrorCode.E3001_INVALID_TOKEN,
    ErrorCode.E3002_EMAIL_NOT_VERIFIED,
    ErrorCode.E3007_USER_LOGIN_FAILED_DUE_TO_LOCKOUT,
];

export const ignoreErrorCodes: string[] = [
    ErrorCode.E5016_BOUND_COMPANY_ABOUT_TO_CHANGE,
    ErrorCode.E4123_ANOTHER_COMPANY_IS_ALREADY_INTEGRATED,
    ErrorCode.E5043_XERO_QUOTE_UNAVAILABLE_FOR_CUSTOMER_DECISION,
    ErrorCode.E7711_BENEFICIARY_CREATION_VALIDATION_ERROR,
    ErrorCode.E4050_RESPONSE_OUTDATED,
];
