import { Box } from '@approvalmax/ui/src/components';
import { OracleNetsuiteLogo } from 'modules/sprites';
import { FC, memo } from 'react';

import { ConnectToOrganisationButton, PopupFooter, PopupTitle } from '..';
import { messages } from './NetSuiteContent.messages';
import { NetSuiteContentProps } from './NetSuiteContent.types';

const NetSuiteContent: FC<NetSuiteContentProps> = memo((props) => {
    const { isLoading, onConnectToOrganisation, onSkip, onBack } = props;

    return (
        <>
            <PopupTitle />

            <Box spacing='20 0 32' width={300}>
                <ConnectToOrganisationButton
                    onClick={onConnectToOrganisation}
                    logo={OracleNetsuiteLogo}
                    systemName={messages.netSuiteName}
                    width={300}
                    isLoading={isLoading}
                />
            </Box>

            <PopupFooter isLoading={isLoading} withBackButton onBack={onBack} onSkip={onSkip} />
        </>
    );
});

export default NetSuiteContent;
