import { statics } from 'modules/common';
import { domain } from 'modules/data';

import { MatrixDefinition } from '../../../../config/matrixDefinitions.shared';
import { getConditionDislplayText } from '../../../../selectors/conditionSelector';
import { sortConditions } from '../../../../selectors/stepSelectors';
import { ActiveWorkflow } from '../../../../types/activeWorkflow';
import { qbooksPayeeFields } from './AutoApprovalStep.constants';
import { messages } from './AutoApprovalStep.messages';

export const getConditionsMessages = (
    conditions: domain.MatrixCondition[],
    template: ActiveWorkflow,
    defaultCurrency: string,
    matrixDefinition?: MatrixDefinition
) => {
    const conditionList = sortConditions(conditions, matrixDefinition);

    const conditionsMessages = conditionList.map((condition, index, array) => {
        let connectionMessage = messages.and;

        if (
            template.integrationCode === domain.IntegrationCode.QBooksExpense ||
            template.integrationCode === domain.IntegrationCode.QBooksJournalEntry
        ) {
            const nextCondition = index + 1 < array.length ? array[index + 1] : undefined;

            if (
                nextCondition &&
                qbooksPayeeFields.includes(condition.fieldSystemPurpose) &&
                qbooksPayeeFields.includes(nextCondition.fieldSystemPurpose)
            ) {
                connectionMessage = messages.or;
            }
        }

        const showConnectionMessage = array.length !== 1 && array.length - 1 !== index && connectionMessage;
        const conditionDislplayText = getConditionDislplayText(
            condition,
            defaultCurrency || statics.currency.defaultCurrency,
            template.integrationCode
        );

        return conditionDislplayText + (showConnectionMessage ? ` ${connectionMessage} ` : '');
    });

    return conditionsMessages;
};
