import { Spacing, Text } from '@approvalmax/ui/src/components';
import SignUpProgress from 'pages/auth/components/SignUpProgress/SignUpProgress';
import { FC, memo } from 'react';

import { messages } from './PopupTitle.messages';
import { PopupTitleProps } from './PopupTitle.types';

const PopupTitle: FC<PopupTitleProps> = memo((props) => {
    const { isInitial } = props;

    return (
        <>
            {!isInitial && (
                <>
                    <SignUpProgress currentStep={3} />

                    <Spacing height={28} />
                </>
            )}

            <Text font='headline' fontSize='small' textAlign='center' spacing='0 0 30'>
                {!isInitial && <div>{messages.lastStep}</div>}

                <div>{messages.title}</div>
            </Text>

            <Text font='body' textAlign='center'>
                <div>{messages.description1}</div>

                <b>{messages.description2}</b>
            </Text>
        </>
    );
});

export default PopupTitle;
