import { TwoFaSoftEnforcementPopup } from '@approvalmax/ui';
import { useTwoFaEnablingWizardContext } from 'modules/components';
import { FC, memo, useCallback } from 'react';
import { useGetTFAEnforcementInformation } from 'shared/data';

import { TwoFaSoftEnforcementProps } from './TwoFaSoftEnforcement.types';

/**
 * @deprecated Old Design
 */
const TwoFaSoftEnforcement: FC<TwoFaSoftEnforcementProps> = memo((props) => {
    const { isOpen, onClose } = props;
    const { showWizard } = useTwoFaEnablingWizardContext();
    const { data, isLoading } = useGetTFAEnforcementInformation({ enabled: isOpen });
    const isDefaultEnforcement = Boolean(data?.tfaEnforcementIntegrationTypes.length);

    const setUpTwoFa = useCallback(() => {
        showWizard();
        onClose(!isOpen);
    }, [showWizard, onClose, isOpen]);

    const handleClose = useCallback(() => {
        onClose(!isOpen);
    }, [isOpen, onClose]);

    return (
        <TwoFaSoftEnforcementPopup
            onSetUpTwoFa={setUpTwoFa}
            isOpen={isOpen}
            onClose={handleClose}
            isDefaultEnforcement={isDefaultEnforcement}
            isLoading={isLoading}
        />
    );
});

export default TwoFaSoftEnforcement;
