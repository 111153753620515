import { mapCompany } from 'modules/data/domain/schemas/companySchema';

import {
    UseFinishConnectionToAirwallexResponse,
    UseFinishConnectionToAirwallexResponseBackend,
} from './useFinishConnectionToAirwallex.types';

export const mapData = (
    data: UseFinishConnectionToAirwallexResponseBackend
): UseFinishConnectionToAirwallexResponse => {
    return data.Companies.map(mapCompany);
};
