import { Dropdown } from '@approvalmax/ui';
import { intl, miscHelpers } from '@approvalmax/utils';
import { FC, useMemo, useState } from 'react';

import { messages } from './AmountDueIndicator.messages';
import { AmountDueIndicatorIcon, AmountLine, Description, Panel, Root } from './AmountDueIndicator.styles';
import { AmountDueIndicatorProps } from './AmountDueIndicator.types';

const AmountDueIndicator: FC<AmountDueIndicatorProps> = (props) => {
    const { children, amountInProcessing = 0, amountAwaitingPayment = 0, amountOnApproval, currency, disabled } = props;

    const [isOpen, setOpen] = useState(false);
    const hasWarning = amountOnApproval > 0 || amountInProcessing > 0 || amountAwaitingPayment > 0;

    const descriptionText = useMemo(() => {
        const amountOnApprovalText = amountOnApproval ? messages.amountOnApproval : null;
        const amountAwaitingPaymentText = amountAwaitingPayment ? messages.amountAwaitingPayment : null;
        const amountInProcessingText = amountInProcessing ? messages.amountInProcessing : null;

        return (
            <>
                <div>{messages.header}</div>

                {amountOnApprovalText && (
                    <AmountLine>
                        {amountOnApprovalText}

                        <b>&nbsp;{intl.formatCurrency(amountOnApproval, currency)}</b>
                    </AmountLine>
                )}

                {amountAwaitingPaymentText && (
                    <AmountLine>
                        {amountAwaitingPaymentText}

                        <b>&nbsp;{intl.formatCurrency(amountAwaitingPayment, currency)}</b>
                    </AmountLine>
                )}

                {amountInProcessingText && (
                    <AmountLine>
                        {amountInProcessingText}

                        <b>&nbsp;{intl.formatCurrency(amountInProcessing, currency)}</b>
                    </AmountLine>
                )}
            </>
        );
    }, [amountAwaitingPayment, amountInProcessing, amountOnApproval, currency]);

    return (
        <Dropdown
            button={(ref) => (
                <Root ref={ref} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
                    {children}

                    {hasWarning && <AmountDueIndicatorIcon />}
                </Root>
            )}
            isOpen={isOpen && !disabled && hasWarning}
            onRequestClose={miscHelpers.noop}
        >
            <Panel>
                <Description>{descriptionText}</Description>
            </Panel>
        </Dropdown>
    );
};

export default AmountDueIndicator;
