import ImmutableInstance from 'seamless-immutable';

const { from, isImmutable, asMutable, merge, set, setIn, update, updateIn, without } = ImmutableInstance.static;

export type ImmutableArray<T> = ImmutableInstance.static.ImmutableArray<T>;
export type ImmutableObject<T> = ImmutableInstance.static.ImmutableObject<T>;
export type Immutable<T> = ImmutableInstance.static.Immutable<T>;
export type DeepPartial<T> = ImmutableInstance.static.DeepPartial<T>;

export { asMutable, from as immutable, isImmutable, merge, set, setIn, update, updateIn, without };

export * from './extensions';
