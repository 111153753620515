import { backend } from 'modules/data';

import { ApiHandler } from '../types';

class SupportUrls {
    constructor(private _apiHandler: ApiHandler) {}
}

export default class SupportApi {
    public urls: SupportUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new SupportUrls(this._apiHandler);
    }

    public requestToSales(data: any): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: data,
            action: 'support/requestToSales?accountId=' + data.accountId,
            method: 'POST',
            apiSource: 'myAccount',
            allowsAnonymous: false,
        });
    }
}
