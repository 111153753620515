import { UpgradeIcon } from '@approvalmax/ui';
import { Button, Popup } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';
import { useToggle } from 'react-use';
import { amplitudeService } from 'services/amplitude';

import { PopupContent } from './components';
import { getButtonColor, getButtonSize, getDescription, getOrigin, getText } from './SubscribeAfterTrialButton.helpers';
import { SubscribeAfterTrialButtonProps } from './SubscribeAfterTrialButton.types';

export const SubscribeAfterTrialButton: FC<SubscribeAfterTrialButtonProps> = memo((props) => {
    const { type, companyId, profileId, text } = props;

    const [open, toggleOpen] = useToggle(false);

    const handleOpen = useCallback(() => {
        toggleOpen(true);

        amplitudeService.sendData('billing: land on trial expiration pop-up after clicking on action button', {
            user_id: profileId,
            org_id: companyId,
            origin: getOrigin(type),
        });
    }, [companyId, profileId, toggleOpen, type]);

    const handleClose = useCallback(() => {
        toggleOpen(false);
    }, [toggleOpen]);

    return (
        <>
            <Button
                size={getButtonSize(type)}
                color={getButtonColor(type)}
                endIcon={<UpgradeIcon />}
                onClick={handleOpen}
            >
                {text ?? getText(type)}
            </Button>

            <Popup open={open} onToggle={toggleOpen} size='xsmall'>
                <PopupContent companyId={companyId} description={getDescription(type)} onClose={handleClose} />
            </Popup>
        </>
    );
});

SubscribeAfterTrialButton.displayName = 'SubscribeAfterTrialButton';
