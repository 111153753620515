import { Reference } from '@approvalmax/types';
import { toast } from '@approvalmax/ui/src/components';
import { defineMessages, domHelpers, reactWindowService } from '@approvalmax/utils';
import cloneDeep from 'lodash/cloneDeep';
import uniqueId from 'lodash/uniqueId';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions, ThunkAction } from 'modules/react-redux';
import moment from 'moment';
import { api } from 'services/api';
import { routingService } from 'services/routing';
import { getClonedReportName } from 'shared/helpers';
import { getDefaultPath, getPath, Path } from 'urlBuilder';

import { printReportMaxSize, reportPageSize } from '../../request/config';
import parseReportData from '../data/parseReportData';
import { createReportConfig, updateColumnsOutOfScope } from '../data/reportConfig';
import { getActiveReport, getActiveReportConfig, getReportType, hasChanges } from '../selectors/pageSelectors';
import { findReportConfigById, getReportConfigTransfer } from '../selectors/reportConfigSelectors';
import { ReportConfig } from '../types/ReportConfig';
import { ReportConfigColumn } from '../types/ReportConfigColumn';
import { SortingDirection } from '../types/SortingDirection';

const messages = defineMessages('reports.actions.report', {
    reportSavedMessage: 'The report has been created.',
    reportUpdatedMessage: 'The report has been updated.',
    saveAsNewSuccessMessage: 'The report has been copied.',
    auditReportScheduledSuccess: 'The audit report archive will be sent to you by email once ready.',
    attachmentArchiveScheduledSuccess: 'The attachment archive will be sent to you by email once it is ready.',
    attachmentArchiveScheduledAlready: 'The attachment archive is already being prepared',
    deleteReportSuccess: 'The report has been deleted.',
});

export const RENAME_ACTIVE_REPORT = 'REPORTS/RENAME_ACTIVE_REPORT';
export const renameActiveReport = (name: string) =>
    createAction(RENAME_ACTIVE_REPORT, {
        name,
    });

export const DELETE_REPORT = 'REPORTS/DELETE_REPORT';
export const DELETE_REPORT_RESPONSE = 'REPORTS/DELETE_REPORT_RESPONSE';
export const DELETE_REPORT_FAILURE = 'REPORTS/DELETE_REPORT_FAILURE';
export const deleteReport = () =>
    createAsyncAction({
        shouldSendRequest: (state: State) => {
            const reportConfig = getActiveReportConfig(state)!;

            return !reportConfig.isNew;
        },

        request: (state: State) => {
            const reportConfig = getActiveReportConfig(state)!;
            const companyId = selectors.navigation.getActiveCompanyId(state);
            const reportType = getReportType(state);

            return createAction(DELETE_REPORT, {
                reportConfigId: reportConfig.id,
                companyId,
                reportType,
            });
        },

        response: async (request) => {
            await api.reports.delete({
                reportId: request.reportConfigId,
                companyId: request.companyId!,
            });

            return createAction(DELETE_REPORT_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(DELETE_REPORT_FAILURE, error, {}),

        didDispatchResponse: (request) => {
            if (request.companyId) {
                routingService.push(getPath(Path.reportsDashboard, request.companyId, request.reportType));

                toast.success(messages.deleteReportSuccess);
            } else {
                routingService.push(getDefaultPath());
            }
        },
    });

export const DISCARD_CHANGES = 'REPORTS/DISCARD_CHANGES';

interface DiscardChangesAction {
    type: typeof DISCARD_CHANGES;
    prestineReportConfig: ReportConfig;
}

export function discardChanges(skipExecute = false): ThunkAction<State> {
    return (dispatch, getState) => {
        const activeReportConfig = getActiveReportConfig(getState());

        if (!activeReportConfig) {
            return;
        }

        const prestineReportConfig = findReportConfigById(getState(), activeReportConfig.id);

        if (prestineReportConfig) {
            dispatch<DiscardChangesAction>({
                type: DISCARD_CHANGES,
                prestineReportConfig,
            });

            if (!skipExecute) {
                dispatch(executeReport());
            }
        }
    };
}

export const SAVE_REPORT = 'REPORTS/SAVE_REPORT';
export const SAVE_REPORT_RESPONSE = 'REPORTS/SAVE_REPORT_RESPONSE';
export const SAVE_REPORT_FAILURE = 'REPORTS/SAVE_REPORT_FAILURE';
export const saveReport = () =>
    createAsyncAction({
        request: (state: State) => {
            const companyId = selectors.navigation.getActiveCompanyId(state)!;
            const reportConfig = getActiveReportConfig(state)!;
            const reportType = getReportType(state);
            const reportConfigTransfer = getReportConfigTransfer(state, reportConfig);

            return createAction(SAVE_REPORT, {
                isNew: reportConfig.isNew,
                reportConfig,
                reportConfigTransfer,
                companyId,
                reportType,
            });
        },

        response: async (request) => {
            let reportConfigId: string | undefined;

            if (request.isNew) {
                const response = await api.reports.create(request.reportConfigTransfer);

                reportConfigId = response.ReportConfigId;
            } else {
                await api.reports.edit({
                    ...request.reportConfigTransfer,
                    reportId: request.reportConfig.id,
                });
            }

            return createAction(SAVE_REPORT_RESPONSE, {
                request,
                reportConfig: request.isNew
                    ? {
                          ...request.reportConfig,
                          id: reportConfigId!,
                      }
                    : request.reportConfig,
            });
        },

        failure: (error) => createErrorAction(SAVE_REPORT_FAILURE, error, {}),

        didDispatchResponse: (request, response) => {
            if (request.reportConfig.isNew) {
                toast.success(messages.reportSavedMessage);
                routingService.replace(getPath(Path.report, response.data.id, request.companyId, request.reportType));
            } else {
                toast.success(messages.reportUpdatedMessage);
            }
        },
    });

export const EXPORT_AS_CSV = 'REPORTS/EXPORT_AS_CSV';
export const EXPORT_AS_CSV_RESPONSE = 'REPORTS/EXPORT_AS_CSV_RESPONSE';
export const EXPORT_AS_CSV_FAILURE = 'REPORTS/EXPORT_AS_CSV_FAILURE';
export const exportAsCsv = () =>
    createAsyncAction({
        request: (state: State) => {
            const reportConfig = getActiveReportConfig(state)!;
            const reportConfigTransfer: any = getReportConfigTransfer(state, reportConfig);

            reportConfigTransfer.asExportSource = true;

            const now = moment.utc().toISOString();

            return createAction(EXPORT_AS_CSV, {
                reportConfigId: reportConfig.id,
                now,
                reportConfigTransfer,
            });
        },

        response: async (request) => {
            const response = await api.reports.create(request.reportConfigTransfer);

            return createAction(EXPORT_AS_CSV_RESPONSE, {
                request,
                preparedReportConfigId: response.data.id,
            });
        },

        failure: (error) => createErrorAction(EXPORT_AS_CSV_FAILURE, error, {}),

        willDispatchResponse: (request, response) => {
            const url = api.reports.exportByConfig({
                reportId: response.preparedReportConfigId,
            });

            domHelpers.downloadUrl(url);
        },
    });

export const SAVE_REPORT_AS_NEW = 'REPORTS/SAVE_REPORT_AS_NEW';
export const SAVE_REPORT_AS_NEW_RESPONSE = 'REPORTS/SAVE_REPORT_AS_NEW_RESPONSE';
export const SAVE_REPORT_AS_NEW_FAILURE = 'REPORTS/SAVE_REPORT_AS_NEW_FAILURE';
export const saveReportAsNew = () =>
    createAsyncAction({
        request: (state: State) => {
            const reportConfig = cloneDeep(getActiveReportConfig(state)!);

            reportConfig.name = getClonedReportName(reportConfig.name);

            const companyId = selectors.navigation.getActiveCompanyId(state)!;
            const reportType = getReportType(state)!;

            reportConfig.createdDate = moment.utc().toISOString();

            let reportConfigTransfer = getReportConfigTransfer(state, reportConfig);

            return createAction(SAVE_REPORT_AS_NEW, {
                reportConfig,
                reportConfigTransfer,
                companyId,
                reportType,
            });
        },

        response: async (request) => {
            const response = await api.reports.create(request.reportConfigTransfer);

            return createAction(SAVE_REPORT_AS_NEW_RESPONSE, {
                request,
                reportConfig: {
                    ...request.reportConfig,
                    id: response.data.id,
                },
            });
        },

        failure: (error) => createErrorAction(SAVE_REPORT_AS_NEW_FAILURE, error, {}),

        didDispatchResponse: () => {
            toast.success(messages.saveAsNewSuccessMessage);
        },
    });

export const CREATE_NEW_REPORT = 'REPORTS/CREATE_NEW_REPORT';
export const CREATE_NEW_REPORT_RESPONSE = 'REPORTS/CREATE_NEW_REPORT_RESPONSE';
export const CREATE_NEW_REPORT_FAILURE = 'REPORTS/CREATE_NEW_REPORT_FAILURE';
export const createNewReport = (reportConfig: ReportConfig) =>
    createAsyncAction({
        request: (state: State) => {
            const companyId = selectors.navigation.getActiveCompanyId(state)!;
            const company = selectors.company.getCompanyById(state, companyId);

            reportConfig = updateColumnsOutOfScope(reportConfig, company);

            let reportConfigTransfer = getReportConfigTransfer(state, reportConfig, true);

            const reportType = getReportType(state)!;

            return createAction(CREATE_NEW_REPORT, {
                reportConfig,
                reportConfigTransfer,
                companyId,
                reportType,
            });
        },

        response: async (request) => {
            const response = await api.reports.create(request.reportConfigTransfer);

            routingService.push(getPath(Path.report, response.data.id, request.companyId, request.reportType));

            return createAction(CREATE_NEW_REPORT_RESPONSE, {
                request,
                reportConfig: {
                    ...request.reportConfig,
                    id: response.data.id,
                },
            });
        },

        failure: (error) => createErrorAction(CREATE_NEW_REPORT_FAILURE, error, {}),

        didDispatchResponse: () => {
            toast.success(messages.reportSavedMessage);
        },
    });

export const DOWNLOAD_AUDIT_REPORTS = 'REPORTS/DOWNLOAD_AUDIT_REPORTS';
export const DOWNLOAD_AUDIT_REPORTS_RESPONSE = 'REPORTS/DOWNLOAD_AUDIT_REPORTS_RESPONSE';
export const DOWNLOAD_AUDIT_REPORTS_FAILURE = 'REPORTS/DOWNLOAD_AUDIT_REPORTS_FAILURE';
export const downloadAuditReports = () =>
    createAsyncAction({
        request: (state: State) => {
            const reportConfig = getActiveReportConfig(state)!;
            const reportConfigTransfer: any = getReportConfigTransfer(state, reportConfig);

            reportConfigTransfer.asExportSource = true;

            const now = moment.utc().toISOString();

            return createAction(DOWNLOAD_AUDIT_REPORTS, {
                reportConfigId: reportConfig.id,
                now,
                reportConfigTransfer,
            });
        },

        response: async (request) => {
            await api.requestReports.exportAuditReports(request.reportConfigTransfer);

            return createAction(DOWNLOAD_AUDIT_REPORTS_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(DOWNLOAD_AUDIT_REPORTS_FAILURE, error, {}),
        willDispatchResponse: () => {
            toast.success(messages.auditReportScheduledSuccess, { delay: 7000 });
        },
    });

export const DOWNLOAD_ATTACHMENT_ARCHIVE = 'REPORTS/DOWNLOAD_ATTACHMENT_ARCHIVE';
export const DOWNLOAD_ATTACHMENT_ARCHIVE_RESPONSE = 'REPORTS/DOWNLOAD_ATTACHMENT_ARCHIVE_RESPONSE';
export const DOWNLOAD_ATTACHMENT_ARCHIVE_FAILURE = 'REPORTS/DOWNLOAD_ATTACHMENT_ARCHIVE_FAILURE';
export const downloadAttachmentArchive = () =>
    createAsyncAction({
        request: (state: State) => {
            const reportConfig = getActiveReportConfig(state)!;
            const reportConfigTransfer = {
                ...getReportConfigTransfer(state, reportConfig),
                asExportSource: true,
            };

            const now = moment.utc().toISOString();

            return createAction(DOWNLOAD_ATTACHMENT_ARCHIVE, {
                reportConfigId: reportConfig.id,
                now,
                reportConfigTransfer,
            });
        },

        response: async (request) => {
            await api.requestReports.exportAttachments(request.reportConfigTransfer);

            return createAction(DOWNLOAD_ATTACHMENT_ARCHIVE_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(DOWNLOAD_ATTACHMENT_ARCHIVE_FAILURE, error, {}),
        willDispatchResponse: () => {
            toast.success(messages.attachmentArchiveScheduledSuccess, { delay: 7000 });
        },
    });

export const SHOW_XERO_ACCRUAL_REPORT_POPUP = 'REPORTS/SHOW_XERO_ACCRUAL_REPORT_POPUP';
export const showXeroAccrualReportPopup = () => createAction(SHOW_XERO_ACCRUAL_REPORT_POPUP, {});

export const CANCEL_ACTIVE_POPUP = 'REPORTS/CANCEL_ACTIVE_POPUP';
export const cancelActivePopup = () => createAction(CANCEL_ACTIVE_POPUP, {});

export const CREATE_ACCRUAL_REPORT = 'REPORTS/CREATE_ACCRUAL_REPORT';
export const CREATE_ACCRUAL_REPORT_RESPONSE = 'REPORTS/CREATE_ACCRUAL_REPORT_RESPONSE';
export const CREATE_ACCRUAL_REPORT_FAILURE = 'REPORTS/CREATE_ACCRUAL_REPORT_FAILURE';
export const createAccrualReport = ({
    narration,
    account,
    date,
}: {
    narration: string;
    account: Reference;
    date: string;
}) =>
    createAsyncAction({
        request: (state: State) => {
            const reportConfig = getActiveReportConfig(state)!;
            const reportConfigTransfer: any = getReportConfigTransfer(state, reportConfig);

            reportConfigTransfer.asExportSource = true;

            const now = moment.utc().toISOString();

            return createAction(CREATE_ACCRUAL_REPORT, {
                reportConfigId: reportConfig.id,
                now,
                reportConfigTransfer,
                narration,
                account,
                date,
            });
        },

        response: async (request) => {
            const response = await api.reports.create(request.reportConfigTransfer);

            return createAction(CREATE_ACCRUAL_REPORT_RESPONSE, {
                request,
                preparedReportConfigId: response.data.id,
            });
        },

        failure: (error) => createErrorAction(CREATE_ACCRUAL_REPORT_FAILURE, error, {}),

        willDispatchResponse: (request, response) => {
            const url = api.requestReports.urls.getAccrualReport({
                reportConfigId: response.preparedReportConfigId,
                narration,
                accrualAccountId: account.id,
                forwardDate: date,
            });

            domHelpers.downloadUrl(url);
        },
    });

export const EXECUTE_REPORT = 'REPORTS/EXECUTE_REPORT';
export const EXECUTE_REPORT_RESPONSE = 'REPORTS/EXECUTE_REPORT_RESPONSE';
export const EXECUTE_REPORT_FAILURE = 'REPORTS/EXECUTE_REPORT_FAILURE';
export const EXECUTE_REPORT_FAILURE_IGNORE = 'REPORTS/EXECUTE_REPORT_FAILURE_IGNORE';
export const executeReport = (options: { pageIndex?: number } = {}) =>
    createAsyncAction({
        request: (state: State) => {
            const pageIndex = options.pageIndex === undefined ? getActiveReport(state).pageIndex : options.pageIndex;
            const reportConfig = getActiveReportConfig(state)!;
            const modified = hasChanges(state);

            let reportConfigTransfer: any = {
                companyId: reportConfig.companyId,
                reportId: reportConfig.id,
                type: reportConfig.reportCode,
            };

            if (modified) {
                reportConfigTransfer = getReportConfigTransfer(state, reportConfig);
            }

            reportConfigTransfer.startRow = pageIndex * reportPageSize;
            reportConfigTransfer.rowCount = reportPageSize + 1;

            const now = moment.utc().toISOString();

            return createAction(EXECUTE_REPORT, {
                reportConfigId: reportConfig.id,
                now,
                pageIndex,
                reportConfigTransfer,
                modified,
                lastRequestId: uniqueId(),
            });
        },

        response: async (request, getState: () => State) => {
            const targetConfig = getActiveReportConfig(getState())!;

            let response: any;

            if (request.modified) {
                response = await api.reports.execute(request.reportConfigTransfer);
            } else {
                response = await api.reports.executeById(request.reportConfigTransfer);
            }

            const payload = {
                request,
                rows: parseReportData(response.data.rows, getState(), targetConfig),
            };

            return createAction(EXECUTE_REPORT_RESPONSE, payload);
        },

        failure: (error, request, state: State) => {
            if (getActiveReport(state).lastRequestId !== request.lastRequestId) {
                // ignore old request
                return createErrorAction(EXECUTE_REPORT_FAILURE_IGNORE, error, {});
            }

            return createErrorAction(EXECUTE_REPORT_FAILURE, error, {});
        },

        showAsyncLoadingBar: false,
    });

export const REORDER_REPORT_COLUMNS = 'REPORTS/REORDER_REPORT_COLUMNS';
export const reorderReportColumns = ({
    columns,
    oldIndex,
    newIndex,
}: {
    columns: ReportConfigColumn[];
    oldIndex: number;
    newIndex: number;
}) =>
    createAction(REORDER_REPORT_COLUMNS, {
        columns,
        oldIndex,
        newIndex,
    });

export const CHANGE_REPORT_SORTING = 'REPORTS/CHANGE_REPORT_SORTING';

interface ChangeReportSortingAction {
    type: typeof CHANGE_REPORT_SORTING;
    column: ReportConfigColumn;
    sorting: SortingDirection;
}

export function changeReportSorting({
    column,
    sorting,
}: {
    column: ReportConfigColumn;
    sorting: SortingDirection;
}): ThunkAction<State> {
    return (dispatch) => {
        dispatch<ChangeReportSortingAction>({
            type: CHANGE_REPORT_SORTING,
            column,
            sorting,
        });

        dispatch(executeReport());
    };
}

export const SHOW_REPORT_FILTERS_POPUP = 'REPORTS/SHOW_REPORT_FILTERS_POPUP';
export const showReportFiltersPopup = ({ reportConfig }: { reportConfig: ReportConfig }) =>
    createAction(SHOW_REPORT_FILTERS_POPUP, {
        reportConfig,
    });

export const APPLY_REPORT_FILTERS = 'REPORTS/APPLY_REPORT_FILTERS';

interface ApplyReportFiltersAction {
    type: typeof APPLY_REPORT_FILTERS;
    reportConfig: ReportConfig;
}

export function applyReportFilters(reportConfig: ReportConfig): ThunkAction<State> {
    return (dispatch, getState) => {
        const company = selectors.company.getCompanyById(getState(), reportConfig.companyId);

        dispatch<ApplyReportFiltersAction>({
            type: APPLY_REPORT_FILTERS,
            reportConfig: updateColumnsOutOfScope(reportConfig, company),
        });
        dispatch(executeReport());
    };
}

export const UPDATE_DYNAMIC_REPORT_COLUMNS = 'REPORTS/UPDATE_DYNAMIC_REPORT_COLUMNS';

interface UpdateDynamicReportColumnsAction {
    type: typeof UPDATE_DYNAMIC_REPORT_COLUMNS;
    standaloneFields: domain.Field[];
    qBooksPurchaseOrderCustomFields: domain.Field[];
    qBooksSalesInvoiceCustomFields: domain.Field[];
    xeroTrackingFields: domain.Field[];
}

export function updateDynamicReportColumns({ reportConfig }: { reportConfig: ReportConfig }): ThunkAction<State> {
    return (dispatch, getState) => {
        let state = getState();

        const xeroTrackingFields = selectors.field.getXeroTrackingFields(state, reportConfig.companyId);
        const standaloneFields = selectors.field.getFieldsBySystemPurpose(
            state,
            reportConfig.companyId,
            domain.FieldSystemPurpose.General
        );
        const qBooksPurchaseOrderCustomFields = selectors.field.getFieldsBySystemPurpose(
            state,
            reportConfig.companyId,
            domain.FieldSystemPurpose.QBooksCustom
        );
        const qBooksSalesInvoiceCustomFields = selectors.field.getFieldsBySystemPurpose(
            state,
            reportConfig.companyId,
            domain.FieldSystemPurpose.QBooksInvoiceCustom
        );

        dispatch<UpdateDynamicReportColumnsAction>({
            type: UPDATE_DYNAMIC_REPORT_COLUMNS,
            standaloneFields,
            qBooksPurchaseOrderCustomFields,
            qBooksSalesInvoiceCustomFields,
            xeroTrackingFields,
        });
    };
}

export const UPDATE_FILTERS_POPUP_REPORT_CONFIG = 'REPORTS/UPDATE_FILTERS_POPUP_REPORT_CONFIG';
export const updateFiltersPopupReportConfig = (reportConfig: ReportConfig) =>
    createAction(UPDATE_FILTERS_POPUP_REPORT_CONFIG, {
        reportConfig,
    });

export const SHOW_NEW_REPORT_POPUP = 'REPORTS/SHOW_NEW_REPORT_POPUP';
export const showNewReportPopup = ({ companyId, reportType }: { companyId: string; reportType: domain.ReportType }) =>
    createAction(SHOW_NEW_REPORT_POPUP, {
        reportConfig: createReportConfig(companyId, reportType),
    });

export const SHOW_PRINT_PREVIEW = 'REPORTS/SHOW_PRINT_PREVIEW';
export const SHOW_PRINT_PREVIEW_RESPONSE = 'REPORTS/SHOW_PRINT_PREVIEW_RESPONSE';
export const SHOW_PRINT_PREVIEW_FAILURE = 'REPORTS/SHOW_PRINT_PREVIEW_FAILURE';

export const showPrintPreview = () => {
    // See NewWindow component for detailed description of why we do things like this.
    const printWindow = reactWindowService.openNewWindow();

    return createAsyncAction({
        shouldSendRequest: () => {
            if (!printWindow) {
                return false;
            }

            printWindow.focus();

            return true;
        },

        request: (state: State) => {
            let reportConfig = getActiveReportConfig(state)!;

            const modified = hasChanges(state);

            let reportConfigTransfer: any = {
                companyId: reportConfig.companyId,
                reportId: reportConfig.id,
                type: reportConfig.reportCode,
            };

            if (modified) {
                reportConfigTransfer = getReportConfigTransfer(state, reportConfig);
            }

            reportConfigTransfer.startRow = 0;
            reportConfigTransfer.rowCount = printReportMaxSize;

            return createAction(SHOW_PRINT_PREVIEW, {
                reportConfig,
                reportConfigTransfer,
                modified,
            });
        },

        response: async (request, getState: () => State) => {
            let response: any;

            if (request.modified) {
                response = await api.reports.execute(request.reportConfigTransfer);
            } else {
                response = await api.reports.executeById(request.reportConfigTransfer);
            }

            return createAction(SHOW_PRINT_PREVIEW_RESPONSE, {
                request,
                rows: parseReportData(response.data.rows, getState(), request.reportConfig),
            });
        },

        failure: (error) => createErrorAction(SHOW_PRINT_PREVIEW_FAILURE, error, {}),

        didDispatchResponse: () => {
            setTimeout(() => {
                if (!printWindow!.closed) {
                    printWindow!.print();
                }
            }, 100);
        },
    });
};

export type Action =
    | ExtractActions<
          | typeof cancelActivePopup
          | typeof createAccrualReport
          | typeof createNewReport
          | typeof deleteReport
          | typeof downloadAttachmentArchive
          | typeof downloadAuditReports
          | typeof executeReport
          | typeof exportAsCsv
          | typeof renameActiveReport
          | typeof reorderReportColumns
          | typeof saveReport
          | typeof saveReportAsNew
          | typeof showNewReportPopup
          | typeof showPrintPreview
          | typeof showReportFiltersPopup
          | typeof showXeroAccrualReportPopup
          | typeof updateFiltersPopupReportConfig
      >
    | DiscardChangesAction
    | ChangeReportSortingAction
    | ApplyReportFiltersAction
    | UpdateDynamicReportColumnsAction;
