import { BillingAddress } from '../data/types';

export const convertBillingAddressToString = (address: BillingAddress, separator = '\n'): string => {
    return [
        address.addressLine1,
        address.addressLine2,
        address.addressLine3,
        address.addressLine4,
        address.city,
        address.region,
        address.postalCode,
        address.country,
    ]
        .reduce<string[]>((acc, line) => {
            const value = line?.trim();

            if (value) {
                acc.push(value);
            }

            return acc;
        }, [])
        .join(separator);
};
