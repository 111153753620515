import { theme } from '@approvalmax/theme';
import styled from 'styled-components';

export const Link = styled.a`
    color: ${theme.color.midnight100};
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-flex;

    // Fix for ellipsis overflow hidden that cuts off bottom of the text
    padding-bottom: 1px;

    &:focus,
    &:hover {
        text-decoration: none;
    }
`;

export const Item = styled.li`
    display: flex;
    flex-direction: column;
`;
