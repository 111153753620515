import { actions, selectors } from 'modules/common';
import { put, select, takeEvery } from 'redux-saga/effects';

import { reloadRequestList } from '../actions';
import { PAGE_ID } from '../config';

export default function* () {
    yield takeEvery(actions.COMMON_RELOAD_REQUEST_LIST, function* () {
        const pageId: ReturnType<typeof selectors.navigation.getActivePageId> = yield select(
            selectors.navigation.getActivePageId
        );

        if (pageId === PAGE_ID) {
            yield put(reloadRequestList());
        }
    });
}
