import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { timeBlockSize } from '../../DigitalClock.constants';

export const StyledGrid = styled.ul<StyledMods<Pick<CSSProperties, 'maxHeight'>>>`
    display: grid;
    grid-auto-rows: max-content;
    overflow-y: auto;
    overflow-x: hidden;
    /* this fixes the bug https://approvalmax.atlassian.net/browse/AM-20354 */
    scroll-behavior: smooth;

    ${mods('maxHeight')(
        (value) => css`
            max-height: ${typeof value === 'number' ? `${value}px` : value};

            /* after the list we need to reserve more space to allow scroll
            the selected element to the top of the list even though it is at
            the end of the list */
            &:after {
                display: block;
                content: '';
                height: calc(100% + ${typeof value === 'number' ? `${value}px` : value} - ${timeBlockSize}px);
            }
        `
    )};
`;
