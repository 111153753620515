import { Guid } from '@approvalmax/types';

import { IntegrationCode, TemplateInfo } from '../domain';

export enum FieldSetType {
    TemplateFields,
    AllIntegrationFields,
}

export interface FieldSetMeta {
    type: FieldSetType;
    companyId: string;
    templateIntegrationCode?: IntegrationCode | null;
    loading: boolean;
    stamp: string;
}

export interface Meta {
    trackingFieldsOrder: {
        [companyId: string]: Guid[];
    };
    loadedFields: FieldSetMeta[];
    creatableTemplates: Guid[];
    viewableTemplates: TemplateInfo[];
    allWorkflows: TemplateInfo[];
}
