import { errorHelpers } from '@approvalmax/utils';
import { State } from 'modules/data';

import { getGtag, initGtag } from './helpers';

const maxSendTimeout = 1000;

class GaService {
    private _prevPage: string | null = null;

    private isInitiated: boolean = false;

    public init(state: State) {
        try {
            if (!this.isInitiated) {
                initGtag(state);

                this.isInitiated = true;
            }
        } catch (error) {
            errorHelpers.captureException(error);
        }
    }

    public sendPageView(page: string) {
        const gtag = getGtag();

        if (!gtag || this._prevPage === page) {
            return;
        }

        this._prevPage = page;

        gtag('event', 'page_view');
    }

    public sendEvent(eventName: string, eventParameters?: Record<string, string>): Promise<void> {
        const gtag = getGtag();

        if (!gtag) {
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            setTimeout(resolve, maxSendTimeout);

            gtag('event', eventName, {
                event_callback: () => {
                    resolve();
                },
                event_timeout: maxSendTimeout,
                ...eventParameters,
            });
        });
    }

    public setProperty(propertyName: string, value: unknown) {
        const gtag = getGtag();

        if (!gtag) {
            return;
        }

        gtag('set', propertyName, value);
    }
}
export const gaService = new GaService();
