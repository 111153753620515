import { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data';

import { companiesApiPaths } from '../paths';
import {
    UseGetAllOpenRequestsWithParticipantDecisionRequiredRequestParams,
    UseGetAllOpenRequestsWithParticipantDecisionRequiredResponse,
} from './useGetAllOpenRequestsWithParticipantDecisionRequired.types';

export const useGetAllOpenRequestsWithParticipantDecisionRequired = (
    params: UseGetAllOpenRequestsWithParticipantDecisionRequiredRequestParams,
    queryOptions?: QueryOptions<UseGetAllOpenRequestsWithParticipantDecisionRequiredResponse>
) => {
    return useGetRequestLegacy<UseGetAllOpenRequestsWithParticipantDecisionRequiredResponse>(
        companiesApiPaths.getAllOpenRequestsWithParticipantDecisionRequired,
        params,
        { queryOptions }
    );
};
