import minBy from 'lodash/minBy';
import { selectors, useSessionNotification } from 'modules/common';
import * as profileModule from 'modules/profile';
import { useDispatch, useSelector } from 'modules/react-redux';
import { BubbleCloseIcon } from 'modules/sprites';
import { FC, memo, useCallback } from 'react';
import { routingService } from 'services/routing';

import { messages } from './UpgradeAdvancedPlanNotification.messages';
import {
    CloseButton,
    ContactSalesLink,
    Container,
    HideButton,
    Message,
    Notification,
    UpgradeButton,
} from './UpgradeAdvancedPlanNotification.styles';

interface UpgradeAdvancedPlanNotificationProps {
    companies: selectors.types.ExpandedCompany[];
}

export const UpgradeAdvancedPlanNotification: FC<UpgradeAdvancedPlanNotificationProps> = memo((props) => {
    const { companies } = props;

    const dispatch = useDispatch();
    const company = minBy(companies, 'trialOfAdvancedExpiresInDays');
    const isMultiple = companies.length > 1;

    const banner = useSessionNotification(`UPGRADE_ADVANCED_PLAN.${companies.map((c) => c.id).join('.')}`);
    const link = useSelector((state) => selectors.links.getPricingLinkOfAdvancedPlan(state, company, isMultiple));

    const handleUpgrade = useCallback(() => {
        routingService.navigateToExternalUrlInNewTab(link);
    }, [link]);

    const handleHide = useCallback(() => {
        banner.hide();
    }, [banner]);

    const handleContactSales = () => {
        dispatch(profileModule.loadContactSalesPopup());
    };

    if (banner.isHidden || companies.length === 0) {
        return null;
    }

    return (
        <Notification>
            <Container>
                <Message>
                    {isMultiple
                        ? messages.trialOfAllFeaturesMultiple({
                              count: companies.length,
                              expiryDays: company?.trialOfAdvancedExpiresInDays || 0,
                          })
                        : messages.trialOfAllFeatures({
                              companyName: company?.displayName || '',
                              expiryDays: company?.trialOfAdvancedExpiresInDays || 0,
                          })}
                </Message>

                <ContactSalesLink onClick={handleContactSales}>{messages.linkText}</ContactSalesLink>

                <UpgradeButton execute={handleUpgrade} colorTheme='purple' preset='small'>
                    {messages.seePricing}
                </UpgradeButton>

                <CloseButton execute={handleHide}>
                    <BubbleCloseIcon width={12} height={12} />
                </CloseButton>

                <HideButton execute={handleHide}>{messages.hide}</HideButton>
            </Container>
        </Notification>
    );
});

UpgradeAdvancedPlanNotification.displayName = 'UpgradeAdvancedPlanNotification';
