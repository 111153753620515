import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.PromotionOfMigrationFromReviewerV1', {
    title: 'Bill Editing by Approvers',
    editingOnApprovalIsNowAvailable: 'Editing on Approval is now available!',
    description:
        'Reviewers in first approval step have been improved and the improved version is available for all approval steps!',
    learnMore: 'Learn more',
    migrateNow: 'Migrate now',
    popupDescription: 'To continue with migration, you need to rather save the changes or discard them.',
    confirmButtonText: 'Discard changes',
    cancelButtonText: 'Continue editing',
});
