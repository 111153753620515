import { ChevronRightIcon } from '@approvalmax/ui';
import { Flex } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import AddNewStepButton from '../AddNewStepButton/AddNewStepButton';
import AirwallexPaymentStep from '../AirwallexPaymentStep/AirwallexPaymentStep';
import ApprovalStep from '../ApprovalStep/ApprovalStep';
import DraggableStepList from '../DraggableStepList/DraggableStepList';
import EndStepWithDescription from '../EndStepWithDescription/EndStepWithDescription';
import { PromotionOfMigrationFromReviewerV1 } from '../PromotionOfMigrationFromReviewerV1/PromotionOfMigrationFromReviewerV1';
import { SingletonSteps } from '../SingletonSteps/SingletonSteps';
import { XeroAmaxPayBatchPaymentPaymentStep } from '../XeroAmaxPayBatchPaymentPaymentStep/XeroAmaxPayBatchPaymentPaymentStep';
import XeroApproveReviewStep from '../XeroApproveReviewStep/XeroApproveReviewStep';
import { useXeroWorkflowDetailsData } from './XeroWorkflowDetails.hooks';

const XeroWorkflowDetails = memo(() => {
    const template = useSelector(getActiveTemplate);
    const company = useSelector((state: State) => selectors.company.findCompanyById(state, template?.companyId || ''));
    const { startStep, endStepBubbleText, endStepDescription, actions } = useXeroWorkflowDetailsData(
        template?.integrationCode
    );
    const isAirwallex = template?.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment;
    const isXeroBill = template?.integrationCode === domain.IntegrationCode.XeroBill;
    const isAmaxPay = template?.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment;
    const isReviewerV1Available = isXeroBill && company?.betaFeatures.includes(domain.CompanyBetaFeature.ReviewerV1);

    if (!template) {
        return null;
    }

    return (
        <>
            {startStep}

            <SingletonSteps />

            {company && <PromotionOfMigrationFromReviewerV1 company={company} template={template} />}

            <DraggableStepList>
                {template.steps.map((step, index) => (
                    <Fragment key={step.id}>
                        {index === 0 && (
                            <Flex height={28} inline alignItems='center'>
                                <ChevronRightIcon size={16} color='midnight60' />
                            </Flex>
                        )}

                        {index === 0 && isReviewerV1Available ? (
                            <XeroApproveReviewStep key={step.id} stepIndex={index} index={index} step={step} />
                        ) : (
                            <ApprovalStep key={step.id} stepIndex={index} index={index} step={step} />
                        )}
                    </Fragment>
                ))}
            </DraggableStepList>

            <AddNewStepButton />

            {isAirwallex && (
                <>
                    <AirwallexPaymentStep />

                    <Flex height={28} inline alignItems='center'>
                        <ChevronRightIcon size={16} color='midnight60' />
                    </Flex>
                </>
            )}

            {isAmaxPay && (
                <>
                    <XeroAmaxPayBatchPaymentPaymentStep />

                    <Flex height={28} inline alignItems='center'>
                        <ChevronRightIcon size={16} color='midnight60' />
                    </Flex>
                </>
            )}

            <EndStepWithDescription bubbleText={endStepBubbleText} description={endStepDescription}>
                {actions}
            </EndStepWithDescription>
        </>
    );
});

export default XeroWorkflowDetails;
