import styled from 'styled-components';

import sprite from './resources/sprites1x.png';
import sprite2x from './resources/sprites2x.png';

const BaseImage = styled.div`
    background: url(${sprite}) no-repeat top left;
    width: 40px;
    height: 40px;

    @media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 144dpi) {
        background: url(${sprite2x}) no-repeat top left;
        background-size: 81px 782px;
    }
`;

export default BaseImage;
