import { BudgetCheckPeriod, BudgetZeroAmountHandlingType } from 'shared/data/types';

export type UseGetQBooksBudgetsPathParams = {
    companyId: string;
};

export enum QBooksBudgetSynchronizationStatus {
    InProgress = 'in-progress',
    Succeeded = 'succeeded',
    Failed = 'failed',
}

export enum QBooksBudgetDimensionType {
    None = 'none',
    Class = 'class',
    Customer = 'customer',
    Location = 'location',
}

export interface QbooksBudgetSubdividedBy {
    type: QBooksBudgetDimensionType;
    value?: string;
    label?: string;
}

export interface QBooksBudget {
    synchronizationStatus: QBooksBudgetSynchronizationStatus;
    lastSyncDate: string;
    subdividedBy?: QbooksBudgetSubdividedBy;
    budgetId: string;
    name: string;
    modifiedDate: string;
    checkPeriod: BudgetCheckPeriod;
    checkPeriodFirstMonth?: number;
    periodFrom?: string;
    periodTill?: string;
    isEnabled: boolean;
    zeroAmountHandlingType: BudgetZeroAmountHandlingType;
}

export type UseGetQBooksBudgetsResponse = {
    items: QBooksBudget[];
    isSyncEnabled: boolean;
};
