export enum ParticipantRole {
    Requester = 'Requester',
    ExternalRequester = 'ExternalRequester',
    DextExternalRequester = 'DextExternalRequester',
    Editor = 'Editor',
    Reviewer = 'Reviewer',
    Approver = 'Approver',
    DefaultApprover = 'DefaultApprover',
    EmailExternalRequester = 'EmailExternalRequester',
}
