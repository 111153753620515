import { PageErrorBoundary as PageErrorBoundaryUi } from '@approvalmax/ui/src/components';
import { FC, PropsWithChildren } from 'react';

import { ErrorPlaceholder } from './components';

export const PageErrorBoundary: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    return <PageErrorBoundaryUi errorPlaceholder={<ErrorPlaceholder />}>{children}</PageErrorBoundaryUi>;
};

PageErrorBoundary.displayName = 'PageErrorBoundary';
