import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface CardProps extends PropsWithChildren {
    className?: string;
    minWidth?: number;
}

const Root = styled.div<{ minWidth?: number }>`
    min-width: ${(props) => props.minWidth || 0}px;
    display: flex;
    flex-flow: column;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
`;

const Card: FC<CardProps> = (props) => {
    const { className, children, minWidth } = props;

    return (
        <Root className={className} minWidth={minWidth}>
            {children}
        </Root>
    );
};

export default Card;
