/**
 * Developer: Stepan Burguchev
 * Date: 2/17/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './toolbarButton.scss';

import { FC } from 'react';
import bemFactory from 'react-bem-factory';

import TransparentButton from './TransparentButton';
import { BaseButtonProps } from './types';

const bem = bemFactory.block('ui-toolbar-button');

export const ALL_TOOLBAR_BUTTON_THEMES = ['default', 'dark'] as const;
export type ToolbarButtonTheme = (typeof ALL_TOOLBAR_BUTTON_THEMES)[number];
export const ALL_TOOLBAR_BUTTON_SIZES = ['default', 'small', 'auto'] as const;
export type ToolbarButtonSize = (typeof ALL_TOOLBAR_BUTTON_SIZES)[number];

export interface ToolbarButtonProps extends BaseButtonProps {
    theme?: ToolbarButtonTheme;
    size?: ToolbarButtonSize;
}

const ToolbarButton: FC<ToolbarButtonProps> = (props) => {
    const { className, children, type = 'button', theme = 'default', size = 'default', ...buttonProps } = props;

    return (
        <TransparentButton {...buttonProps}>
            {({ elementProps }) => (
                <button {...elementProps} type={type} className={bem.add(className)(null, theme, size)}>
                    {children}
                </button>
            )}
        </TransparentButton>
    );
};

export default ToolbarButton;
