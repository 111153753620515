import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import {
    UseCreateXeroCounterpartyPaymentDetailsData,
    UseCreateXeroCounterpartyPaymentDetailsPathParams,
    UseCreateXeroCounterpartyPaymentDetailsResponse,
    UseCreateXeroCounterpartyPaymentDetailsResponseBackend,
} from './useCreateXeroCounterpartyPaymentDetails.types';

export const useCreateXeroCounterpartyPaymentDetails = (
    mutationOptions?: MutationOptions<
        UseCreateXeroCounterpartyPaymentDetailsResponse,
        RequestDataParams<
            UseCreateXeroCounterpartyPaymentDetailsData,
            UseCreateXeroCounterpartyPaymentDetailsPathParams
        >
    >
) => {
    return useMutateLegacy<
        RequestDataParams<
            UseCreateXeroCounterpartyPaymentDetailsData,
            UseCreateXeroCounterpartyPaymentDetailsPathParams
        >,
        UseCreateXeroCounterpartyPaymentDetailsResponseBackend,
        UseCreateXeroCounterpartyPaymentDetailsResponse
    >(integrationsApiPaths.xeroCounterpartyPaymentDetails, {
        mutationOptions,
    });
};
