import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { FC, PropsWithChildren } from 'react';

import { Root } from './RootLayout.styles';

const RootLayout: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    /*
     * Something crazy, but if you delete this line,
     * you will get an error that the domain is undefined and the application will not load.
     *
     * Will fix it after redesigning all pages, because this component will be deleted.
     */
    useSelector(selectors.session.authenticated);

    return <Root>{children}</Root>;
};

export default RootLayout;
