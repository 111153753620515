import { GetTFAAlternativeMethodsBackend, GetTFAAlternativeMethodsResponse } from './useGetTFAAlternativeMethods.types';

export const mapData = (response: GetTFAAlternativeMethodsBackend): GetTFAAlternativeMethodsResponse => {
    return {
        alternativeEmail: response.AlternativeEmail,
        backupCodes:
            response.BackupCodes?.map(({ Code, IsUsed }) => ({
                code: Code,
                isUsed: IsUsed,
            })) || null,
    };
};
