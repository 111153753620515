import { RequestDataParams } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UseEditCompanyData, UseEditCompanyResponse } from './useEditCompany.types';

export const useEditCompany = () => {
    const queryClient = useQueryClient();

    return useMutateLegacy<RequestDataParams<UseEditCompanyData>, UseEditCompanyResponse>(companiesApiPaths.edit, {
        mutationOptions: {
            onSuccess: (_response, requestData) => {
                queryClient.invalidateQueries([companiesApiPaths.select, { companyId: requestData.data.companyId }]);
            },
        },
    });
};
