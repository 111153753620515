import { domain } from 'modules/data';

import { messages } from './XeroSupplierCreationConditionCell.messages';
import { ConditionTypeValue, conditionTypeValues } from './XeroSupplierCreationConditionCell.types';

export const getConditionItems = (
    integrationCode: domain.IntegrationCode | null
): {
    id: ConditionTypeValue;
    name: string;
}[] => {
    const isCustomerOnly =
        integrationCode !== null &&
        [domain.IntegrationCode.XeroInvoice, domain.IntegrationCode.XeroQuote].includes(integrationCode);

    return [
        {
            id: conditionTypeValues.AllContacts,
            name: messages.anyExistingContactDropdownText,
        },
        {
            id: isCustomerOnly ? conditionTypeValues.CustomersOnly : conditionTypeValues.SuppliersOnly,
            name: isCustomerOnly ? messages.onlyCustomersDropdownText : messages.onlySuppliersDropdownText,
        },
        {
            id: conditionTypeValues.ExactValues,
            name: messages.contactsExactValuesDropdownText,
        },
        {
            id: conditionTypeValues.NegativeExactValues,
            name: messages.contactsNotExactValuesDropdownText,
        },
    ];
};

export const conditionTypeToConditionTypeValue = (
    condition: domain.ExactValuesCondition | domain.AlwaysTrueCondition | domain.ServerCondition
): ConditionTypeValue => {
    switch (condition.conditionType) {
        case null:
            return conditionTypeValues.AllContacts;

        case domain.ConditionType.ExactValuesCondition:
            return conditionTypeValues.ExactValues;

        case domain.ConditionType.NegativeExactValuesCondition:
            return conditionTypeValues.NegativeExactValues;

        case domain.ConditionType.ServerCondition:
            switch (condition.serverConditionType) {
                case domain.ServerConditionType.AllContacts:
                    return conditionTypeValues.AllContacts;

                case domain.ServerConditionType.SuppliersOnly:
                    return conditionTypeValues.SuppliersOnly;

                case domain.ServerConditionType.CustomersOnly:
                    return conditionTypeValues.CustomersOnly;
            }
    }

    return conditionTypeValues.AllContacts;
};
