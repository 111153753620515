import isFunction from 'lodash/isFunction';

import { composeReducers } from '../composeReducers';
import { AnyAction } from '../types/Action';

export function createPopupReducer(
    cancelPopupActionTypeOrPredicate: string | null | ((state: any, action: AnyAction) => boolean),
    ...reducers: any[]
): (state: any, action: AnyAction) => any {
    const composedReducer = composeReducers(null, ...reducers);

    if (!cancelPopupActionTypeOrPredicate) {
        return composedReducer;
    }

    return (state, action) => {
        if (
            action.type === cancelPopupActionTypeOrPredicate ||
            (isFunction(cancelPopupActionTypeOrPredicate) && cancelPopupActionTypeOrPredicate(state, action))
        ) {
            return null;
        }

        return composedReducer(state, action);
    };
}
