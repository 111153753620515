import uniqueId from 'lodash/uniqueId';
import { stateTree } from 'modules/data';
import { createAction, ExtractActions } from 'modules/react-redux';

export const ADD_TOAST = 'COMMON/ADD_TOAST';
export const $addToast = (options: {
    id: string;
    message: React.ReactNode;
    timeout: number;
    kind: stateTree.ToastNotificationKind;
}) => createAction(ADD_TOAST, { ...options });

export const REMOVE_TOAST = 'COMMON/REMOVE_TOAST';
export const $removeToast = (id: string) => createAction(REMOVE_TOAST, { id });

export const addInfoToast = (
    message: React.ReactNode,
    options: {
        timeout?: number;
        id?: string;
    } = {}
) => {
    return $addToast({
        id: options.id || uniqueId('toast-'),
        message,
        timeout: options.timeout || 5000,
        kind: stateTree.ToastNotificationKind.Info,
    });
};

export const addErrorToast = (
    message: React.ReactNode,
    options: {
        timeout?: number;
        id?: string;
        dangerouslySetInnerHTML?: boolean;
    } = {}
) => {
    return $addToast({
        id: options.id || uniqueId('toast-'),
        message,
        timeout: options.timeout || 5000,
        kind: stateTree.ToastNotificationKind.Error,
    });
};

(window as any).newInfo = (message: string) => {
    window.ApprovalMax.app.getStore().dispatch(addInfoToast(message));
};

(window as any).newError = (message: string) => {
    window.ApprovalMax.app.getStore().dispatch(addErrorToast(message));
};

export type Action = ExtractActions<typeof $addToast | typeof $removeToast>;
