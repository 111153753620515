import { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data';

import { paymentServicesApiPaths } from '../paths';
import {
    UseGetBillLinkedToBatchPaymentBackend,
    UseGetBillLinkedToBatchPaymentParams,
} from './useGetBillLinkedToBatchPayment.types';

export const useGetBillLinkedToBatchPayment = (
    params: UseGetBillLinkedToBatchPaymentParams,
    queryOptions?: QueryOptions<UseGetBillLinkedToBatchPaymentBackend>
) =>
    useGetRequestLegacy<UseGetBillLinkedToBatchPaymentBackend>(
        paymentServicesApiPaths.getBillLinkedToBatchPayment,
        params,
        {
            queryOptions,
        }
    );
