import './userHover.scss';

import { Dropdown, LoadingSpinner } from '@approvalmax/ui';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { User40x40Icon } from 'modules/sprites';
import React, { FC, useCallback, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { loadUserProfileData } from '../../actions';
import { hasAccessToUserProfileData } from '../../selectors/profileSelectors';
import { expandUser, findUserById } from '../../selectors/userSelectors';
import Avatar from '../avatar/Avatar';
import UserHoverAnchor from './UserHoverAnchor';

const i18nPrefix = 'common.ui.UserHover';

interface UserHoverProps {
    user: domain.User;
    className?: string;
    qa?: string;
    noteText?: React.ReactNode;
    title?: string;
    children:
        | React.ReactNode
        | ((
              // An HTMLElement reference is expected. Any is used to avoid type casting problems in callbacks
              buttonRef: React.Ref<any>
          ) => React.ReactNode);
}

const bem = bemFactory.block('common-ui-user-hover');

const UserHover: FC<UserHoverProps> = (props) => {
    const { className, noteText, title, qa, children } = props;

    const dispatch = useDispatch();
    const user = useSelector((state) => findUserById(state, props.user.id)) || expandUser(props.user);
    const hasAccessToUserProfile = useSelector((state) => hasAccessToUserProfileData(state, user.id));
    const loading = hasAccessToUserProfile && !user.profileInfo;

    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => {
        setIsOpen(true);

        if (hasAccessToUserProfile && !user.profileInfo) {
            dispatch(loadUserProfileData(user.id));
        }
    }, [dispatch, hasAccessToUserProfile, user.id, user.profileInfo]);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    let footer;

    if (noteText) {
        footer = <div className={bem('footer')}>{noteText}</div>;
    }

    let content;

    if (!hasAccessToUserProfile) {
        content = (
            <div className={bem.add('fs-mask')('panel')}>
                <div className={bem('header')}>
                    <div className={bem('avatar')}>
                        <div className={bem('inactive-user-avatar')}>
                            <User40x40Icon width={17} height={16} />
                        </div>
                    </div>

                    <div className={bem('display-name')}>{user.userEmail}</div>
                </div>

                <div className={bem('list')}>
                    <div className={bem('row')}>
                        <div className={bem('col-left')}>
                            <FormattedMessage id={`${i18nPrefix}.emailLabelText`} defaultMessage='Email:' />
                        </div>

                        <div className={bem('col-right')}>
                            <a
                                href={`mailto:${user.userEmail}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                title={user.userEmail}
                            >
                                {user.userEmail}
                            </a>
                        </div>
                    </div>
                </div>

                {footer}
            </div>
        );
    } else if (loading) {
        content = (
            <div className={bem('panel', { loading: true })}>
                <LoadingSpinner />
            </div>
        );
    } else {
        const profileInfo = user.profileInfo!;

        content = (
            <div className={bem.add('fs-mask')('panel')}>
                <div className={bem('header')}>
                    <div className={bem('avatar')}>
                        <Avatar user={user} size={44} />
                    </div>

                    <div className={bem('display-name')}>{user.displayName}</div>
                </div>

                <div className={bem('list')}>
                    <div className={bem('row')}>
                        <div className={bem('col-left')}>
                            <FormattedMessage id={`${i18nPrefix}.emailLabelText`} defaultMessage='Email:' />
                        </div>

                        <div className={bem('col-right')}>
                            <a
                                href={`mailto:${user.userEmail}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                title={user.userEmail}
                            >
                                {user.userEmail}
                            </a>
                        </div>
                    </div>

                    {profileInfo.skype && (
                        <div className={bem('row')}>
                            <div className={bem('col-left')}>
                                <FormattedMessage id={`${i18nPrefix}.skypeLabelText`} defaultMessage='Skype:' />
                            </div>

                            <div className={bem('col-right')}>
                                <a
                                    href={`skype:${profileInfo.skype}?chat`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    title={profileInfo.skype}
                                >
                                    {profileInfo.skype}
                                </a>
                            </div>
                        </div>
                    )}

                    {profileInfo.phone && (
                        <div className={bem('row')}>
                            <div className={bem('col-left')}>
                                <FormattedMessage id={`${i18nPrefix}.phoneLabelText`} defaultMessage='Phone:' />
                            </div>

                            <div className={bem('col-right')}>
                                <a
                                    href={`tel:${profileInfo.phone}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    title={profileInfo.phone}
                                >
                                    {profileInfo.phone}
                                </a>
                            </div>
                        </div>
                    )}
                </div>

                {footer}
            </div>
        );
    }

    return (
        <Dropdown
            panelFlow='center'
            button={(ref) =>
                typeof children === 'function' ? (
                    (children as (arg: unknown) => any)(ref) // Typing problem here
                ) : (
                    <div
                        ref={ref}
                        className={bem.add(className)()}
                        data-qa={qa}
                        data-qa-id={qa ? user.id : undefined}
                        data-qa-name={qa ? user.displayName : undefined}
                        title={title}
                        onClick={open}
                    >
                        {children}
                    </div>
                )
            }
            isOpen={isOpen}
            onRequestClose={close}
        >
            {content}
        </Dropdown>
    );
};

(UserHover as any).Anchor = UserHoverAnchor;

export default UserHover as typeof UserHover & {
    Anchor: typeof UserHoverAnchor;
};
