import { errorHelpers } from '@approvalmax/utils';
import { MatrixType } from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/types/matrix';
import filter from 'lodash/filter';
import { backend, domain, stateTree } from 'modules/data';
import moment, { Moment } from 'moment';
import { createSelector } from 'reselect';

import { hasActiveIntegration } from './integrationSelectors';

export function getFieldById(state: stateTree.State, fieldId: string): domain.Field {
    if (!state.entities.fields) {
        throw errorHelpers.notFoundError();
    }

    const field = state.entities.fields[fieldId];

    if (!field) {
        throw errorHelpers.notFoundError();
    }

    return field;
}

export function findFieldById(state: stateTree.State, fieldId: string): domain.Field | null {
    if (!state.entities.fields) {
        return null;
    }

    return state.entities.fields[fieldId] || null;
}

export function getFieldsBySystemPurpose(
    state: stateTree.State,
    companyId: string,
    fieldSystemPurpose: domain.FieldSystemPurpose
): domain.Field[] {
    return Object.values(state.entities.fields).filter(
        (f) => f.companyId === companyId && f.systemPurpose === fieldSystemPurpose
    );
}

export function getXeroTrackingFields(state: stateTree.State, companyId: string): domain.Field[] {
    if (!hasActiveIntegration(state, companyId) || !state.meta.trackingFieldsOrder[companyId]) {
        return [];
    }

    const fields = state.meta.trackingFieldsOrder[companyId].map((fieldId) => findFieldById(state, fieldId));

    return fields.filter((field): field is domain.Field => field !== null);
}

export const getFieldsByCompanyId: (state: stateTree.State, companyId: string) => domain.Field[] = createSelector(
    (state: stateTree.State) => state.entities.fields,
    (state: stateTree.State, companyId: string) => companyId,
    (fields, companyId) => filter(fields, (field) => field.companyId === companyId)
);

export function allowsEmptyValue(field: domain.Field, matrixType: MatrixType, isOptional?: boolean): boolean {
    switch (matrixType) {
        case MatrixType.Reviewer:
        case MatrixType.Requester:
        case MatrixType.Approval: {
            if (
                [
                    domain.FieldSystemPurpose.QBooksPayeeVendor,
                    domain.FieldSystemPurpose.QBooksPayeeCustomer,
                    domain.FieldSystemPurpose.QBooksPayeeEmployee,
                ].includes(field.systemPurpose)
            ) {
                return true;
            }

            if (
                isOptional &&
                ![
                    domain.FieldSystemPurpose.Requester,
                    domain.FieldSystemPurpose.Amount,
                    domain.FieldSystemPurpose.QBooksVendor,
                    domain.FieldSystemPurpose.QBooksPoItem,
                    domain.FieldSystemPurpose.QBooksAccount,
                    domain.FieldSystemPurpose.QBooksVendor,
                    domain.FieldSystemPurpose.QBooksPaymentAccount,
                    domain.FieldSystemPurpose.QBooksPaymentType,
                    domain.FieldSystemPurpose.QBooksCurrency,
                    domain.FieldSystemPurpose.XeroSupplier,
                    domain.FieldSystemPurpose.XeroBranding,
                    domain.FieldSystemPurpose.XeroBankAccount,
                    domain.FieldSystemPurpose.NetSuiteAccount,
                    domain.FieldSystemPurpose.General,
                ].includes(field.systemPurpose)
            ) {
                return true;
            }

            return false;
        }

        case MatrixType.Editor:
        case MatrixType.AutoApproval:
        case MatrixType.Editing:
            return false;

        default:
            throw errorHelpers.assertNever(matrixType);
    }
}

const FIELDS_CACHE_EXPIRED_TIMEOUT_MIN = 5;

function getFieldSetMeta(
    state: stateTree.State,
    options: {
        type: stateTree.FieldSetType;
        companyId: string;
        templateIntegrationCode?: domain.IntegrationCode | null;
    }
) {
    return state.meta.loadedFields.find(
        (x) =>
            x.type === options.type &&
            x.companyId === options.companyId &&
            x.templateIntegrationCode === options.templateIntegrationCode
    );
}

export function fieldSetExpired(
    state: stateTree.State,
    options: {
        type: stateTree.FieldSetType;
        companyId: string;
        templateIntegrationCode?: domain.IntegrationCode | null;
    },
    now: Moment = moment()
): boolean {
    const indexObject = getFieldSetMeta(state, options);

    if (!indexObject || !indexObject.stamp || indexObject.templateIntegrationCode !== options.templateIntegrationCode) {
        return true;
    }

    const elapsedMinutes = moment.duration(now.diff(indexObject.stamp)).asMinutes();

    return elapsedMinutes > FIELDS_CACHE_EXPIRED_TIMEOUT_MIN;
}

export function fieldSetIsLoading(
    state: stateTree.State,
    options: {
        type: stateTree.FieldSetType;
        companyId: string;
        templateIntegrationCode?: domain.IntegrationCode | null;
    }
): boolean {
    const indexObject = getFieldSetMeta(state, options);

    if (!indexObject) {
        return true;
    }

    return indexObject.loading;
}

export function getSupportRegionalFieldByCountryCode(
    countryCode: backend.OrganisationVersion,
    regionField: domain.RegionalField
) {
    const availableRegionFieldsByCountryCode: {
        [key in backend.OrganisationVersion]?: domain.RegionalField[];
    } = {
        [backend.OrganisationVersion.GLOBAL]: [domain.RegionalField.Details],
        [backend.OrganisationVersion.US]: [domain.RegionalField.Details],
        [backend.OrganisationVersion.UK]: [domain.RegionalField.Details, domain.RegionalField.Narrative],
        [backend.OrganisationVersion.AU]: [domain.RegionalField.Reference],
        [backend.OrganisationVersion.NZ]: [
            domain.RegionalField.Particulars,
            domain.RegionalField.Code,
            domain.RegionalField.Reference,
        ],
    };

    return availableRegionFieldsByCountryCode[countryCode]?.includes(regionField);
}

export const getFieldNameBySystemPurpose = (
    systemPurpose: domain.FieldSystemPurpose,
    integrationCode: domain.IntegrationCode | null,
    defaultName: string
) => {
    if (integrationCode === domain.IntegrationCode.QBooksJournalEntry) {
        switch (systemPurpose) {
            case domain.FieldSystemPurpose.QBooksPayeeCustomer:
                return 'Customer';

            case domain.FieldSystemPurpose.QBooksPayeeEmployee:
                return 'Employee';

            case domain.FieldSystemPurpose.QBooksPayeeVendor:
                return 'Vendor';

            case domain.FieldSystemPurpose.QBooksAccount:
                return 'Account';

            default:
                return defaultName;
        }
    }

    if (integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment) {
        switch (systemPurpose) {
            case domain.FieldSystemPurpose.AmaxPayBankAccount:
                return 'Bank account';

            default:
                return defaultName;
        }
    }

    return defaultName;
};
