import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, CANCEL_ACTIVE_POPUP, CREATE_ACCRUAL_REPORT, SHOW_XERO_ACCRUAL_REPORT_POPUP } from '../../actions';

export const XERO_ACCRUAL_REPORT_POPUP_ID = 'REPORTS/XERO_ACCRUAL_REPORT';

export interface XeroAccrualReportPopupData {
    id: typeof XERO_ACCRUAL_REPORT_POPUP_ID;
}

export type XeroAccrualReportPopupStateType = ImmutableObject<XeroAccrualReportPopupData> | null;

export default function (state: XeroAccrualReportPopupStateType, action: Action): XeroAccrualReportPopupStateType {
    switch (action.type) {
        case SHOW_XERO_ACCRUAL_REPORT_POPUP:
            return immutable<XeroAccrualReportPopupData>({
                id: XERO_ACCRUAL_REPORT_POPUP_ID,
            });

        case CREATE_ACCRUAL_REPORT:
        case CANCEL_ACTIVE_POPUP:
            return null;

        default:
            return state;
    }
}
