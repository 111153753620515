import { Guid, Reference } from '@approvalmax/types';
import { arrayHelpers } from '@approvalmax/utils';
import { selectors, statics } from 'modules/common';
import { domain } from 'modules/data';
import React from 'react';

import AirwallexBenificiaryCreationConditionCell from '../components/MatrixPopupContent/components/AirwallexBenificiaryCreationConditionCell/AirwallexBenificiaryCreationConditionCell';
import AmountConditionCell from '../components/MatrixPopupContent/components/AmountConditionCell/AmountConditionCell';
import AmountConditionHeaderColumn from '../components/MatrixPopupContent/components/AmountConditionHeader/AmountConditionHeader';
import { ConditionHeaderColumn } from '../components/MatrixPopupContent/components/ConditionHeaderColumn/ConditionHeaderColumn';
import { ExactAsyncConditionCell } from '../components/MatrixPopupContent/components/ExactAsyncConditionCell/ExactAsyncConditionCell';
import XeroSupplierEditingConditionCell from '../components/MatrixPopupContent/components/XeroSupplierEditingConditionCell/XeroSupplierEditingConditionCell';
import { AccessType, MatrixType } from '../types/matrix';

export interface RenderOptions {
    matrixType: MatrixType;
    integrationCode: domain.IntegrationCode | null;
    field: domain.Field;
    readonly: boolean;
    company: domain.Company;
    withFilter?: boolean;
    filter?: string;
    setFilter?: (value: string) => void;
}

export interface RenderCellOptions {
    matrixType: MatrixType;
    integrationCode: domain.IntegrationCode | null;
    field: domain.Field;
    readonly: boolean;
    rule: domain.MatrixRule;
    condition: domain.MatrixCondition;
    onConditionChange(
        lineId: string,
        rule: domain.MatrixRule,
        field: domain.Field,
        newCondition: domain.MatrixCondition
    ): void;
    lineId: string;
    company: domain.Company;
    templateSubmitters: selectors.types.ExpandedCompanyUser[];
    onCreateFieldOption(field: domain.Field, newOption: Reference): void;
    requiredFieldIds: Guid[];
    noEmptyValue?: boolean;
}

export type MatrixDefinitionColumn = {
    systemPurpose: domain.FieldSystemPurpose;
    renderHeader: (options: RenderOptions) => React.ReactNode;
    renderCell: (options: RenderCellOptions) => React.ReactNode;
};

export interface MatrixDefinition {
    columns: Array<MatrixDefinitionColumn>;
}

export interface InternalMatrixDefinitionMap {
    [integrationCode: string]: {
        [matrixType: string]: MatrixDefinition;
    };
}

export function renderDefaultHeader(
    { field, readonly, matrixType, withFilter, filter, setFilter }: RenderOptions,
    config?: {
        fieldName?: string;
    }
) {
    let fieldName = field.name;

    if (config && config.fieldName) {
        fieldName = config.fieldName;
    }

    return (
        <ConditionHeaderColumn
            fieldId={field.id}
            fieldName={fieldName}
            readonly={readonly}
            matrixType={matrixType}
            withFilter={withFilter}
            filter={filter}
            setFilter={setFilter}
        />
    );
}

export const renderAmountHeader = ({ readonly, company }: RenderOptions) => {
    const currencyText = statics.currency.getCurrencyShortText(company.defaultCurrency);

    return <AmountConditionHeaderColumn currency={currencyText} canChangeAmount readonly={readonly} />;
};

export const renderAmountHeaderWithStaticAmount = ({ readonly, company }: RenderOptions) => {
    const currencyText = statics.currency.getCurrencyShortText(company.defaultCurrency);

    return <AmountConditionHeaderColumn currency={currencyText} canChangeAmount={false} readonly={readonly} />;
};

export function renderHeaderWithAccessType(
    { field, readonly, matrixType }: RenderOptions,
    config: {
        accessTypeOptions: AccessType[];
        defaultAccessType: AccessType;
    }
) {
    return (
        <ConditionHeaderColumn
            hasAccessTypeBlock
            fieldId={field.id}
            fieldName={field.name}
            readonly={readonly}
            matrixType={matrixType}
            {...config}
        />
    );
}

interface RenderAmountCellOptions extends RenderCellOptions {
    minValue?: number;
    maxValue?: number;
    showCurrency?: boolean;
}

export function renderAmountCell({
    rule,
    field,
    readonly,
    integrationCode,
    lineId,
    condition,
    onConditionChange,
    company,
    minValue,
    maxValue,
    showCurrency = true,
}: RenderAmountCellOptions) {
    return (
        <AmountConditionCell
            rule={rule}
            field={field}
            readonly={readonly}
            integrationCode={integrationCode}
            lineId={lineId}
            onConditionChange={onConditionChange}
            condition={condition as domain.NumericRangeCondition}
            companyDefaultCurrency={company.defaultCurrency}
            minValue={minValue}
            maxValue={maxValue}
            showCurrency={showCurrency}
        />
    );
}

export function renderExactAsyncCell({
    rule,
    field,
    readonly,
    integrationCode,
    matrixType,
    lineId,
    condition,
    onConditionChange,
    templateSubmitters,
    requiredFieldIds,
    noEmptyValue,
}: RenderCellOptions) {
    return (
        <ExactAsyncConditionCell
            rule={rule}
            field={field}
            readonly={readonly}
            integrationCode={integrationCode}
            lineId={lineId}
            onConditionChange={onConditionChange}
            condition={condition as domain.ExactValuesCondition | domain.AlwaysTrueCondition}
            templateSubmitters={matrixType !== MatrixType.Requester ? templateSubmitters : arrayHelpers.emptyArray()}
            requiredFieldIds={requiredFieldIds}
            matrixType={matrixType}
            noEmptyValue={Boolean(noEmptyValue)}
        />
    );
}

export function getFilteredDefinitions(
    matrixDefinitions: InternalMatrixDefinitionMap,
    options: {
        integrationCode: domain.IntegrationCode | null;
        matrixType: MatrixType;
    }
): MatrixDefinition {
    const def = matrixDefinitions[options.integrationCode || 'null'][options.matrixType];

    return { columns: def?.columns.filter(Boolean) || [] };
}

export function renderXeroSupplierEditingConditionCell(options: RenderCellOptions) {
    return <XeroSupplierEditingConditionCell {...options} />;
}

export function renderAirwallexBenificaryCreationConditionCell(options: RenderCellOptions) {
    return <AirwallexBenificiaryCreationConditionCell {...options} />;
}
