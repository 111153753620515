import { Collapse, Flex, List, Spacing } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { IntegrationHeader } from 'shared/components';
import { activeCompanyIdState } from 'shared/states';

import { useWorkflowWizard } from '../../hooks/useWorkflowWizard';
import PromotionListItem from '../PromotionListItem/PromotionListItem';
import { usePromotionListItem } from '../PromotionListItem/PromotionListItem.hooks';
import WorkflowListItem from '../WorkflowListItem/WorkflowListItem';
import { useWorkflowListItem } from '../WorkflowListItem/WorkflowListItem.hooks';
import { messages } from './QBooksWorkflowList.messages';

const qa = bemFactory.qa('wfl-quick-books-card');

export const QBooksWorkflowList = memo(() => {
    const companyId = useRecoilValue(activeCompanyIdState);
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, companyId));
    const workflows = useSelector((state: State) =>
        selectors.template.getIntegrationTemplatesByCompanyId(state, companyId)
    );

    const { isAuditor, isManager, isWorkflowManager } = company.flags;
    const isValidRole = isAuditor || isManager || isWorkflowManager;

    const isQBOSalesInvoicesBeta = company.betaFeatures.includes(domain.CompanyBetaFeature.QBOSalesInvoices);

    const isVendorAvailable = company.licenseFeatures.includes(domain.CompanyLicenseFeature.QBOVendorWorkflows);
    const isJournalEntryAvailable = company.licenseFeatures.includes(
        domain.CompanyLicenseFeature.QBOJournalEntryWorkflows
    );

    const payableWorkflows = useWorkflowListItem(workflows, [
        {
            code: domain.IntegrationCode.QBooksPo,
        },
        {
            code: domain.IntegrationCode.QBooksBill,
        },
        {
            code: domain.IntegrationCode.QBooksExpense,
        },
    ]);

    const receivableWorkflows = useWorkflowListItem(workflows, [
        {
            code: domain.IntegrationCode.QBooksInvoice,
            available: isQBOSalesInvoicesBeta,
            isBeta: isQBOSalesInvoicesBeta,
        },
    ]);

    const otherWorkflows = useWorkflowListItem(workflows, [
        {
            code: domain.IntegrationCode.QBooksVendor,
            available: isVendorAvailable && isValidRole,
        },
        {
            code: domain.IntegrationCode.QBooksJournalEntry,
            available: isJournalEntryAvailable && isValidRole,
        },
    ]);

    const promotionAdvanceableWorkflows = usePromotionListItem(company, [
        {
            code: domain.IntegrationCode.QBooksVendor,
            available: !(isVendorAvailable && isValidRole),
        },
        {
            code: domain.IntegrationCode.QBooksJournalEntry,
            available: !(isJournalEntryAvailable && isValidRole),
        },
    ]);

    useWorkflowWizard(
        [...payableWorkflows, ...receivableWorkflows, ...otherWorkflows].map((el) => el.workflow),
        company
    );

    return (
        <>
            <IntegrationHeader company={company} />

            <Flex inline direction='column' spacing='16'>
                {payableWorkflows.length > 0 && (
                    <Collapse
                        name={messages.payableGroup}
                        spacing='0 24 0 52'
                        localStorageKey={`${company.id}-payable`}
                        data-qa={qa('list-group-item-payable')}
                        radius='small'
                    >
                        <List divider removeLastDivider>
                            {payableWorkflows.map((workflowItemProps) => (
                                <List.Item key={workflowItemProps.workflow.integrationCode}>
                                    <WorkflowListItem
                                        data-qa={qa(`list-item-${workflowItemProps.workflow.integrationCode}`)}
                                        {...workflowItemProps}
                                    />
                                </List.Item>
                            ))}
                        </List>
                    </Collapse>
                )}

                {receivableWorkflows.length > 0 && (
                    <Collapse
                        name={messages.receivableGroup}
                        spacing='0 24 0 52'
                        localStorageKey={`${company.id}-receivable`}
                        data-qa={qa('list-group-item-receivable')}
                        radius='small'
                    >
                        <List divider removeLastDivider>
                            {receivableWorkflows.map((workflowItemProps) => (
                                <List.Item key={workflowItemProps.workflow.integrationCode}>
                                    <WorkflowListItem
                                        data-qa={qa(`list-item-${workflowItemProps.workflow.integrationCode}`)}
                                        {...workflowItemProps}
                                    />
                                </List.Item>
                            ))}
                        </List>
                    </Collapse>
                )}

                {(otherWorkflows.length > 0 || promotionAdvanceableWorkflows.length > 0) && (
                    <Collapse
                        name={messages.otherGroup}
                        spacing='0 24 0 52'
                        localStorageKey={`${company.id}-other`}
                        data-qa={qa('list-group-item-other')}
                        radius='small'
                    >
                        <List divider removeLastDivider>
                            {otherWorkflows.map((workflowItemProps) => (
                                <List.Item key={workflowItemProps.workflow.integrationCode}>
                                    <WorkflowListItem
                                        data-qa={qa(`list-item-${workflowItemProps.workflow.integrationCode}`)}
                                        {...workflowItemProps}
                                    />
                                </List.Item>
                            ))}

                            {promotionAdvanceableWorkflows.map(({ code }) => (
                                <List.Item key={code}>
                                    <PromotionListItem company={company} integrationCode={code} />
                                </List.Item>
                            ))}
                        </List>
                    </Collapse>
                )}
            </Flex>

            <Spacing height={16} />
        </>
    );
});

QBooksWorkflowList.displayName = 'QBooksWorkflowList';
