import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import { UseChangeWatchersData, UseChangeWatchersResponse } from './useChangeWatchers.types';

export const useChangeWatchers = () => {
    return useMutateLegacy<RequestDataParams<UseChangeWatchersData>, UseChangeWatchersResponse>(
        requestsApiPaths.changeWatchers
    );
};
