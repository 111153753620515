import { escapeExpression } from '../string/escapeExpression';

export const highlightUrls = (text: string, skipEscapingExpression = false) => {
    if (!text) {
        return text;
    }

    if (!skipEscapingExpression) {
        text = escapeExpression(text);
    }

    const URL_REG_EXP = /(https?:\/\/[^\s<"]+|www\.[^\s<"]+)(?![^<]*?>)/gi;

    return text.replace(
        URL_REG_EXP,
        (url) =>
            `<a href="${
                url.startsWith('http') ? url : 'https://' + url
            }" target="_blank" rel="noopener noreferrer">${url}</a>`
    );
};
