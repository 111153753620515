import { miscHelpers } from '@approvalmax/utils';
import { FC, memo } from 'react';

import { Root } from './Divider.styles';
import { DividerProps } from './Divider.types';

/**
 * A divider line separates different content.
 */
const Divider: FC<DividerProps> = memo((props) => {
    const { color = 'midnight40', spacing = '0', orientation = 'horizontal', ...restProps } = props;

    return (
        <Root
            $color={color}
            $orientation={orientation}
            $spacing={miscHelpers.spacingPropToCss(spacing)}
            {...restProps}
        />
    );
});

export default Divider;
