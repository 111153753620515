import type { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import { mapData } from './useAmaxPayOpenBankingBankAccounts.map';
import {
    UseAmaxPayOpenBankingBankAccountsRequestPathParams,
    UseAmaxPayOpenBankingBankAccountsResponse,
    UseAmaxPayOpenBankingBankAccountsResponseBackend,
} from './useAmaxPayOpenBankingBankAccounts.types';

export const useAmaxPayOpenBankingBankAccounts = (
    pathParams: UseAmaxPayOpenBankingBankAccountsRequestPathParams,
    queryOptions?: QueryOptions<
        UseAmaxPayOpenBankingBankAccountsResponseBackend,
        UseAmaxPayOpenBankingBankAccountsResponse
    >
) =>
    useGetRequestLegacy<UseAmaxPayOpenBankingBankAccountsResponseBackend, UseAmaxPayOpenBankingBankAccountsResponse>(
        paymentServicesApiPaths.amaxPayOpenBankingBankAccounts,
        pathParams,
        {
            pathParams,
            queryOptions: {
                ...queryOptions,
                staleTime: 5 * 1000,
            },
            method: 'get',
            mapData,
        }
    );
