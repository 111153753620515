import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, CLOSE_FIELD_SETTINGS_POPUP, OPEN_FIELD_SETTINGS_POPUP } from '../../actions';

export interface FieldSettingsData {}

export type ActiveFieldSettings = ImmutableObject<FieldSettingsData> | null;

export default function activeFieldSettingsReducer(
    state: ActiveFieldSettings = null,
    action: Action
): ActiveFieldSettings {
    switch (action.type) {
        case OPEN_FIELD_SETTINGS_POPUP:
            return immutable<FieldSettingsData>({});

        case CLOSE_FIELD_SETTINGS_POPUP:
            return null;

        default:
            return state;
    }
}
