import { QueryOptions, QueryParams } from '@approvalmax/data';
import { useGetRequest } from 'shared/data/api';

import { budgetsApiPaths } from '../paths';
import { UseGetQBooksBudgetsPathParams, UseGetQBooksBudgetsResponse } from './useGetQBooksBudgets.types';

export const useGetQBooksBudgets = (
    pathParams: QueryParams<UseGetQBooksBudgetsPathParams>,
    queryOptions?: QueryOptions<UseGetQBooksBudgetsResponse>
) => {
    return useGetRequest<UseGetQBooksBudgetsPathParams, UseGetQBooksBudgetsResponse>(budgetsApiPaths.getQBooksBudgets, {
        pathParams,
        queryOptions,
        apiVersion: 'v2',
    });
};
