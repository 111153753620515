import type { ItemProps } from './components';

export const computeTabIndex = ({
    active,
    selected,
    disabled,
    tabIndex,
    index,
}: Pick<ItemProps, 'tabIndex' | 'active' | 'selected' | 'disabled'> & { index: number }) => {
    if (disabled) {
        return -1;
    }

    if (typeof active === 'boolean') {
        return active ? 0 : -1;
    }

    if (typeof selected === 'boolean') {
        return selected ? 0 : -1;
    }

    if (typeof tabIndex === 'number') {
        return tabIndex;
    }

    return index === 0 ? 0 : -1;
};
