import { useMutation } from '@tanstack/react-query';
import { api } from 'services/api';

import { UsePullIntegrationsOptions } from './usePullIntegrations.types';

export const usePullIntegrations = (options: UsePullIntegrationsOptions = {}) => {
    return useMutation(
        ({ companyId, integrationIds }) => api.companies.pullIntegrations({ companyId, integrationIds }),
        { ...options }
    );
};
