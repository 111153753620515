import { produce } from 'immer';
import { domain } from 'modules/data';

import { Action, LOGIN_RESPONSE, SHOW_LOGIN_MODULE } from '../../actions';

export enum PageActiveModule {
    LoginModule = 'LoginModule',
    TwoFaLoginModuleData = 'TwoFaLoginModuleData',
}

export interface LoginModuleData {
    type: typeof PageActiveModule.LoginModule;
    email?: string;
    app?: domain.SsoLoginApplication;
    postAction?: string;
}

export interface TwoFaLoginModuleData {
    type: typeof PageActiveModule.TwoFaLoginModuleData;
    stateId: string;
    app?: domain.SsoLoginApplication;
    postAction?: string;
    isTFAAlternativeEmailEnabled: boolean;
    isTFABackupCodesEnabled: boolean;
}

export type ActiveModule = LoginModuleData | TwoFaLoginModuleData;

export default produce((draft: ActiveModule, action: Action): ActiveModule => {
    switch (action.type) {
        case SHOW_LOGIN_MODULE:
            return {
                type: PageActiveModule.LoginModule,
                email: action.payload.email,
                app: action.payload.app,
                postAction: action.payload.postAction,
            };

        case LOGIN_RESPONSE: {
            if (!action.payload.is2faEnabled) {
                return draft;
            }

            const oldState = draft as LoginModuleData;

            return {
                type: PageActiveModule.TwoFaLoginModuleData,
                stateId: action.payload.stateId,
                app: oldState.app,
                postAction: oldState.postAction,
                isTFAAlternativeEmailEnabled: action.payload.isTFAAlternativeEmailEnabled,
                isTFABackupCodesEnabled: action.payload.isTFABackupCodesEnabled,
            };
        }

        default:
            return draft;
    }
});
