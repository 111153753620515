import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetRequestLegacy } from 'shared/data/api';

import { matchingApiPaths } from '../paths';
import {
    UseQBooksMatchingContextParams,
    UseQBooksMatchingContextResponse,
    UseQBooksMatchingContextResponseBackend,
} from './useQBooksMatchingContext.types';

export const useQBooksMatchingContext = (
    params: QueryParams<UseQBooksMatchingContextParams>,
    queryOptions?: QueryOptions<UseQBooksMatchingContextResponseBackend, UseQBooksMatchingContextResponse>
) =>
    useGetRequestLegacy<UseQBooksMatchingContextResponseBackend, UseQBooksMatchingContextResponse>(
        matchingApiPaths.qboMatchingContext,
        params,
        {
            queryOptions: { ...queryOptions, enabled: enabledByParams(params, queryOptions) },
            mapData: objectHelpers.pascalCaseToCamelCase,
        }
    );
