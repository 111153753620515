import { getParamsFromPath } from './getParamsFromPath';

export const clearParamsByPath = <Params extends Record<string, any>>(path: string, params?: Params) => {
    const cleanedParams = { ...params };
    const pathParams = getParamsFromPath(path);

    for (const param of pathParams) {
        delete cleanedParams[param];
    }

    return cleanedParams;
};
