import { AddIcon, CloseCircleFilledIcon, SearchIcon } from '@approvalmax/ui';
import { Box, Button, Flex, List, Spacing, Text, TextField } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { Link } from 'react-router-dom';
import { amplitudeService } from 'services/amplitude';
import { FeatureBadge, SubscribeAfterTrialButton } from 'shared/components';
import { SubscribeAfterTrialButtonType } from 'shared/components/SubscribeAfterTrialButton/SubscribeAfterTrialButton.types';
import { getCanShowSubscribeAfterTrialButton } from 'shared/helpers/experiments';
import { getPath, Path } from 'urlBuilder';

import WorkflowListItem from '../WorkflowListItem/WorkflowListItem';
import { useSearch } from './StandaloneWorkflowList.hooks';
import { messages } from './StandaloneWorkflowList.messages';

const qa = bemFactory.qa('wfl-standalone-card');

const StandaloneWorkflowList = memo(() => {
    const company = useSelector(selectors.navigation.getActiveCompany);
    const canUpdateCompanySettings = useSelector((state) => selectors.company.canUpdateCompanySettings(state, company));
    const integration = useSelector((state: State) =>
        selectors.integration.getIntegrationByCompanyId(state, company.id)
    );
    const workflows = useSelector((state: State) =>
        selectors.template.getStandaloneTemplatesByCompanyId(state, company.id)
    );
    const profile = useSelector(selectors.profile.findProfile);

    const { isConnected, isDisconnected, neverConnectedBefore } =
        selectors.integration.getExplainedIntegrationState(integration);
    const isPremium = company.flags.isAllFeaturesTrialActive || company.flags.isTrialLicense;
    const readOnly = !canUpdateCompanySettings || company.isReadonly;
    const isCreateDisabled = readOnly || (!isConnected && !isDisconnected);

    const canShowSubscribeAfterTrialButton = getCanShowSubscribeAfterTrialButton(company, profile);

    const createButtonCommonProps = useMemo(
        () =>
            ({
                size: 'small',
                color: 'blue10',
                startIcon: <AddIcon size='12' />,
                'data-qa': qa('create-template-button'),
            }) as const,
        []
    );

    const { filteredWorkflows, isShownSearch, toggleSearch, onFilterTextChange } = useSearch(workflows);

    const onClickCreate = useCallback(() => {
        amplitudeService.sendData('workflows list: create standalone workflow');
    }, []);

    return (
        <Box spacing='24'>
            <Flex inline direction='column'>
                <Flex inline alignItems='start' wrap={false}>
                    <Flex grow={1}>
                        {isShownSearch ? (
                            <TextField
                                size='small'
                                initFocus
                                startIcon={<SearchIcon />}
                                endIcon={<CloseCircleFilledIcon pointer onClick={toggleSearch} />}
                                clearable={false}
                                placeholder={messages.searchPlaceholder}
                                onChange={onFilterTextChange}
                                data-qa={qa('search-input')}
                            />
                        ) : (
                            <Text
                                font='headline'
                                fontSize='xsmall'
                                as='h2'
                                data-starter-guide={neverConnectedBefore ? 'wf_no_gl_stand_alone' : 'choose_wf_type_sa'}
                            >
                                {messages.title}
                            </Text>
                        )}
                    </Flex>

                    <Flex inline alignItems='center' wrap={false}>
                        {workflows.length > 0 && !isShownSearch && (
                            <Button onClick={toggleSearch} noPadding icon data-qa={qa('toggle-filter-button')}>
                                <SearchIcon size={24} />
                            </Button>
                        )}

                        {isCreateDisabled ? (
                            canShowSubscribeAfterTrialButton && profile ? (
                                <SubscribeAfterTrialButton
                                    type={SubscribeAfterTrialButtonType.WorkflowStandalone}
                                    profileId={profile.id}
                                    companyId={company.id}
                                />
                            ) : (
                                <Button {...createButtonCommonProps} disabled>
                                    {messages.buttonCreateText}
                                </Button>
                            )
                        ) : (
                            <Button
                                {...createButtonCommonProps}
                                as={Link}
                                to={getPath(Path.newWorkflow, company.id)}
                                onClick={onClickCreate}
                            >
                                {messages.buttonCreateText}
                            </Button>
                        )}
                    </Flex>
                </Flex>

                {isPremium && (
                    <div>
                        <FeatureBadge premium />
                    </div>
                )}

                <Text font='body' fontSize='small' color='midnight70'>
                    {company.flags.isTrialLicense ? messages.descriptionTrial : messages.descriptionNormal}

                    <Spacing height={8} />

                    <a
                        href='https://support.approvalmax.com/portal/kb/articles/is-it-possible-to-use-approvalmax-as-a-stand-alone-solution'
                        target='_blank'
                        rel='nofollow noreferrer'
                    >
                        {messages.learnMore}
                    </a>
                </Text>

                {workflows.length > 0 && (
                    <List divider removeLastDivider>
                        {filteredWorkflows.length ? (
                            filteredWorkflows?.map((workflow) => (
                                <List.Item key={workflow.id}>
                                    <WorkflowListItem
                                        workflow={workflow}
                                        data-qa={qa('template-item')}
                                        data-qa-id={workflow.id}
                                        data-qa-name={workflow.templateName}
                                    />
                                </List.Item>
                            ))
                        ) : (
                            <List.Item>
                                <Text font='body' fontSize='small' color='midnight70'>
                                    {messages.noFound}
                                </Text>
                            </List.Item>
                        )}
                    </List>
                )}
            </Flex>
        </Box>
    );
});

export default StandaloneWorkflowList;
