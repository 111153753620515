import { Action, CHANGE_EXTERNAL_SUBMITTER_ENABLED, SAVE_TEMPLATE_RESPONSE, SHOW_WORKFLOW_PAGE } from '../../actions';
import { ActiveTemplate } from './activeTemplateReducer';

const INITIAL_STATE = false;

export default function externalSubmitterEnabled(
    state: boolean = INITIAL_STATE,
    action: Action,
    template: ActiveTemplate
): boolean {
    let nextState = state || Boolean(template && template.externalSubmitter);

    switch (action.type) {
        case CHANGE_EXTERNAL_SUBMITTER_ENABLED:
            return action.payload.enabled;

        case SHOW_WORKFLOW_PAGE:
            return Boolean(template && template.externalSubmitter);

        case SAVE_TEMPLATE_RESPONSE:
            return Boolean(template && template.externalSubmitter && state);

        default:
            return nextState;
    }
}
