enum FilterType {
    None = 'None',
    DateRange = 'DateRange',
    NumberRange = 'NumberRange',
    String = 'String',
    ReferenceList = 'ReferenceList',
    ReferenceValue = 'ReferenceValue',
    UserList = 'UserList',
    UserIdsList = 'UserIdsList',
}

export default FilterType;
