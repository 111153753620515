import { DataTable } from '@approvalmax/ui';
import styled from 'styled-components';

export const Cell = styled(DataTable.DefaultCell)`
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
`;

export const LoadingBarContainer = styled.div`
    width: 70px;
`;
