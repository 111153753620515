import { Button, CheckboxEditor, ExternalLink, Field, OrSeparator, TextButton, TextEditor } from '@approvalmax/ui';
import { constants } from 'modules/common';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';
import TrustThisDeviceHint from 'shared/components/TrustThisDeviceHint/TrustThisDeviceHint';

import { useTwoFaActionWizardContext } from '../../TwoFaActionWizard.context';
import { TwoFaActionWizardProps } from '../../TwoFaActionWizard.types';
import { messages } from './ScreenAppCode.messages';
import { AnotherAction, Content, Description, Root, TrustThisDeviceContainer } from './ScreenAppCode.styles';

const qa = bemFactory.qa('pro-app-code-popup');

const ScreenAppCode = memo<TwoFaActionWizardProps>((props) => {
    const {
        onEnterAppCode,
        isTFAAlternativeEmailEnabled,
        isTFABackupCodesEnabled,
        isTrustThisDeviceEnabled,
        onSuccess,
        title,
        description,
    } = props;

    const { setActiveScreen } = useTwoFaActionWizardContext();
    const inputRef = useRef<HTMLInputElement>(null);
    const [appCode, setAppCode] = useState('');
    const [trustThisDevice, setTrustThisDevice] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const hasAlternativeMethods = isTFAAlternativeEmailEnabled || isTFABackupCodesEnabled;

    const canSubmit = Boolean(appCode.trim()) && !isLoading;

    useEffect(() => {
        if (isError) {
            inputRef.current!.focus();
        }
    }, [isError]);

    const onSubmit = useCallback(async () => {
        if (!canSubmit) return;

        setIsLoading(true);

        try {
            await onEnterAppCode(appCode.trim(), trustThisDevice);
            onSuccess?.();
        } catch {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [canSubmit, onEnterAppCode, appCode, trustThisDevice, onSuccess]);

    const onSwitchScreen = useCallback(() => {
        setActiveScreen('methodSelect');
    }, [setActiveScreen]);

    return (
        <Root qa={qa()} title={title}>
            <Content>
                <div>
                    <Description>{description || messages.description}</Description>

                    <Description>
                        {messages.supportText({
                            supportlink: (chunks) => (
                                <ExternalLink href={constants.xeroConstants.XERO_TWO_FA_SUPPORT_LINK}>
                                    {chunks}
                                </ExternalLink>
                            ),
                        })}
                    </Description>
                </div>

                <Field title={messages.codeFieldTitle} errorText={isError && messages.wrongCodeErrorText}>
                    <TextEditor
                        focusOnMount
                        disabled={isLoading}
                        value={appCode}
                        onChange={setAppCode}
                        placeholder={messages.userCodePlaceholder}
                        invalid={isError}
                        ref={inputRef}
                        onEnter={onSubmit}
                    />
                </Field>

                {isTrustThisDeviceEnabled && (
                    <TrustThisDeviceContainer>
                        <CheckboxEditor onChange={setTrustThisDevice} value={trustThisDevice}>
                            {messages.trustThisDevice}
                        </CheckboxEditor>

                        <TrustThisDeviceHint />
                    </TrustThisDeviceContainer>
                )}

                <Button execute={onSubmit} disabled={!canSubmit}>
                    {messages.confirmButton}
                </Button>

                {hasAlternativeMethods && (
                    <>
                        <OrSeparator />

                        <AnotherAction>
                            <TextButton disabled={isLoading} execute={onSwitchScreen}>
                                {messages.anotherMethodButton}
                            </TextButton>
                        </AnotherAction>
                    </>
                )}
            </Content>
        </Root>
    );
});

export default ScreenAppCode;
