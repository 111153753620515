import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateUserContext } from '../../../actions';
import { NoCompaniesPageStep } from '../constants';
import EmptyBlock from '../emptyBlock/EmptyBlock';
import InvitationsListBlock from '../invitationsListBlock/InvitationsListBlock';

const Steps = () => {
    const pendingInvitations = useSelector(selectors.companyInvitation.getPendingCompanyInvitations);

    const [currentStep, setStep] = useState<NoCompaniesPageStep>(() => {
        if (pendingInvitations.length) {
            return NoCompaniesPageStep.CompanyInvitation;
        }

        return NoCompaniesPageStep.EmptyBlock;
    });
    const dispatch = useDispatch();

    const next = () => {
        let nextStep = NoCompaniesPageStep.EmptyBlock;

        switch (currentStep) {
            case NoCompaniesPageStep.EmptyBlock: {
                break;
            }

            case NoCompaniesPageStep.CompanyInvitation:
                dispatch(updateUserContext());
                break;

            default:
                throw errorHelpers.notSupportedError();
        }

        setStep(nextStep);
    };

    switch (currentStep) {
        case NoCompaniesPageStep.EmptyBlock:
            return <EmptyBlock />;

        case NoCompaniesPageStep.CompanyInvitation:
            return <InvitationsListBlock pendingInvitations={pendingInvitations} next={next} />;

        default:
            return <EmptyBlock />;
    }
};

export default Steps;
