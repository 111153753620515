import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import type { Dispatch, SetStateAction } from 'react';

import type { RoleCellProps } from '../RoleCell/RoleCell.types';

export enum ColumnId {
    UserEmail = 'userEmail',
    Role = 'role',
    StatusText = 'statusText',
}

export type InviteUser = selectors.types.ExpandedUser & {
    role: domain.CompanyUserRole;
    status: domain.CompanyUserStatus;
    statusText: string;
};

export interface UsersListProps extends RoleCellProps {
    users: { id: string; role: domain.CompanyUserRole }[];
    checkedUsers: string[];
    setCheckedUsers: Dispatch<SetStateAction<string[]>>;
}
