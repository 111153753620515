import type { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import type { UseUpdateDelegationsData, UseUpdateDelegationsResponse } from './useUpdateDelegations.types';

export const useUpdateDelegations = (
    mutationOptions?: MutationOptions<UseUpdateDelegationsResponse, RequestDataParams<UseUpdateDelegationsData>>
) =>
    useMutate<RequestDataParams<UseUpdateDelegationsData>, UseUpdateDelegationsResponse>(
        companiesApiPaths.delegations,
        {
            mutationOptions,
            apiVersion: 'v2',
        }
    );
