import { domain } from 'modules/data';
import { immutable } from 'modules/immutable';

import {
    Action,
    LOAD_PAGE_DATA,
    UPLOAD_ATTACHMENTS,
    UPLOAD_ATTACHMENTS_FAILURE,
    UPLOAD_ATTACHMENTS_RESPONSE,
} from '../../actions';
import { AttachmentKind } from '../../data/AttachmentKind';

export type NewSupplierAttachmentsType = domain.RequestAttachment[];

const INITIAL_STATE: NewSupplierAttachmentsType = [];

export default function (state = INITIAL_STATE, action: Action): NewSupplierAttachmentsType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return immutable([]);

        case UPLOAD_ATTACHMENTS:
            return action.payload.attachmentKind === AttachmentKind.EmailToSupplierAttachment
                ? state.concat(action.payload.newAttachments)
                : state;

        case UPLOAD_ATTACHMENTS_RESPONSE:
        case UPLOAD_ATTACHMENTS_FAILURE:
            return action.payload.attachmentKind === AttachmentKind.EmailToSupplierAttachment
                ? state.filter((a) => !action.payload.request.newAttachments.some((x) => x.id === a.id))
                : state;

        default:
            return state;
    }
}
