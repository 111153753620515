import { Mods, mods, theme } from '@approvalmax/theme';
import Button from '@approvalmax/ui/src/components/Button/Button';
import { StyledMods } from '@styled-kit/mods';
import styled, { keyframes } from 'styled-components';

const showCloseButton = keyframes({
    from: {
        opacity: 0,
        transform: 'scale(0.5)',
    },
    to: {
        opacity: 1,
        transform: 'scale(1)',
    },
});

export const CloseButton = styled(Button)`
    animation: ${showCloseButton} ${theme.duration.slow}ms ease-in-out;
`;

export const Root = styled.header<StyledMods<Mods<'divider'>>>`
    position: sticky;
    top: 0;
    background-color: ${theme.color.white100};
    padding: 12px ${theme.container.spacing.large};
    height: 52px;
    display: flex;
    width: 100%;
    z-index: 10;

    ${mods.divider.true`
        border-bottom: 1px solid ${theme.color.midnight50};
    `};
`;
