import { backend } from 'modules/data';
import { atom } from 'recoil';

// Created and unsaved requests are considered non-modified; all others are considered modified.
export const isModifiedRequestState = atom<boolean>({
    key: 'isModifiedRequestState',
    default: true,
});

export const customGetRequestTransferState = atom<
    (() => backend.Transfer<backend.transfers.RequestEditTransfer>) | null
>({
    key: 'customGetRequestTransferState',
    default: null,
});
