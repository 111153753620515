import { memo, NamedExoticComponent } from 'react';

import { Content, List, Tab } from './components';
import { TabsContext, useContextValues } from './Tabs.context';
import { ChildrenComponents, TabsProps } from './Tabs.types';

/**
 * Tabs make it easy to explore and switch between different views.
 * You can render tabs from an array with tabs data or manually.
 */
const Tabs = memo<TabsProps>((props) => {
    const {
        items,
        value = 0,
        onChange,
        color = 'blue100',
        size = 'medium',
        spacing,
        forceRender,
        bordered,
        divider = true,
        overflowX,
        children,
        ...restProps
    } = props;

    const contextValues = useContextValues({ value, spacing, color, size, onChange, forceRender });

    return (
        <TabsContext.Provider value={contextValues}>
            <div {...restProps}>
                {items ? (
                    <>
                        <List bordered={bordered} overflowX={overflowX}>
                            {items.map((item, index) => (
                                <Tab
                                    {...item}
                                    value={item.value || index}
                                    key={item.value || index}
                                    divider={divider}
                                />
                            ))}
                        </List>

                        {children}

                        {items.map((item, index) => (
                            <Content value={item.value || index} key={item.value || index}>
                                {item.children}
                            </Content>
                        ))}
                    </>
                ) : (
                    children
                )}
            </div>
        </TabsContext.Provider>
    );
}) as NamedExoticComponent<TabsProps> & ChildrenComponents;

Tabs.displayName = 'Tabs';
Tabs.Tab = Tab;
Tabs.List = List;
Tabs.Content = Content;

export default Tabs;
