import { Mods, mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { font } from '../../styles';
import TransparentButton from '../button/TransparentButton';

export const CheckboxText = styled.span`
    ${font(12)}
`;

export const ActionButton = styled(TransparentButton)`
    border: 1px solid #4c8a5c;
    text-align: center;
    ${font(13, '#477753', 'semibold')}
    padding: 10px;
    text-transform: uppercase;
`;

export const DownloadButton = styled(ActionButton)`
    width: 70%;
`;

export const CopyButton = styled(ActionButton)`
    width: 30%;
`;

export const Codes = styled.div`
    display: grid;
    height: 240px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 40px;
    column-gap: 15px;
    row-gap: 10px;
`;

export const BackupCode = styled.div<StyledMods<Mods<'disabled'>>>`
    border-radius: 5px;
    border: 1px solid #cacccd;
    text-align: center;
    padding: 10px 20px;
    ${font(14)}

    ${mods.disabled.true`
        background-color: #eff0f0;
        border-color: #eff0f0;
    `};
`;

export const Actions = styled.div`
    display: flex;
    gap: 10px;
`;
