import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.StepTypeEditor.ChoiceTypePopupContent', {
    title: 'Approval condition',
    doneButton: 'Done',
    description: `
        It can be selected whether all or only one of the assigned Approvers
        is required to approve the request to complete this Approval step.
    `,
    learnMore: 'Learn more',
    stepTypeDefaultTitle: 'All',
    stepTypeDefaultDescription: `
        If this option is chosen, all assigned Approvers will have
        to approve the request in this step
    `,
    stepTypeAnyOfTitle: 'Any of',
    stepTypeAnyOfDescription: `
        If this option is chosen, specified number of assigned Approvers
        will have to approve the request in this step.
    `,
    stepTypeAnyOfDescriptionImportantLabel: 'Important:',
    stepTypeAnyOfDescriptionImportantNote: `
        if number of approvers for exact condition is less than set then all of approvers
        of this step must approve this step.
    `,
    numberOfApproversLabel: 'Number of approvers',
});
