import { TextButton } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    margin-top: 4px;
`;

export const StyledButton = styled(TextButton)`
    display: inline-flex;
    align-items: center;
`;

export const ButtonText = styled.span`
    margin-left: 4px;
`;
