import { ProgressFlow } from '@approvalmax/ui';
import { memo } from 'react';

import { steps } from './ProgressFlow2fa.config';
import { ProgressFlow2faProps } from './ProgressFlow2fa.types';

const ProgressFlow2fa = memo<ProgressFlow2faProps>((props) => {
    const { activeStepIndex } = props;

    return <ProgressFlow steps={steps} activeStepIndex={activeStepIndex} />;
});

export default ProgressFlow2fa;
