import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.HelpStripe.TrainingContent', {
    title: `What's included:`,
    description: `
        • Requirements and scoping session{br}
        • Help with workflow implementation{br}
        • User and Administrator training{br}
        {br}
        A standard package, yet customised to your needs!
    `,
    contactUs: 'Contact us',
});
