import { mods } from '@approvalmax/theme';
import { TransparentButton } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

export const Title = styled.div``;

export const Root = styled(TransparentButton)<StyledMods<{ width: number }>>`
    box-shadow: 0 0 0 1px #dfdfdf inset;
    border-radius: 8px;
    padding: 14px;
    min-height: 70px;
    display: inline-flex;
    flex-direction: column;
    transition: box-shadow 0.2s;

    ${mods('width')(
        (value) => css`
            width: ${value}px;
        `
    )}

    &:hover {
        box-shadow: 0 0 0 2px #384650 inset;

        ${Title} {
            text-decoration: underline;
        }
    }
`;

export const LogoContainer = styled.div<StyledMods<{ alignStart?: boolean }>>`
    display: flex;
    align-items: center;

    ${mods('alignStart', true)`
        align-items: flex-start;
    `}
`;

export const Logo = styled.div`
    flex: none;
    margin-right: 10px;
`;
