import { PageSlice } from 'modules/page';

import { PAGE_ID } from './config';
import entitiesReducer from './reducers/entitiesReducer';
import navigationReducer from './reducers/navigationReducer';
import pageReducer from './reducers/pageReducer';
import { hasChanges } from './selectors/pageSelectors';

export const page: PageSlice = {
    id: PAGE_ID,
    pageReducer: pageReducer,
    reducers: {
        entities: entitiesReducer,
        navigation: navigationReducer,
    },
    hasChanges,
};
