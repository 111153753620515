import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data/api';

import { authApiPaths } from '../paths';
import { UseFido2CredentialsListResponse } from './useFido2CredentialsList.types';

export const useFido2CredentialsList = (queryOptions?: QueryOptions<UseFido2CredentialsListResponse>) => {
    return useGetRequest<{}, UseFido2CredentialsListResponse>(authApiPaths.fido2Credentials, {
        apiVersion: 'v2',
        queryOptions,
    });
};
