import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import * as profileModule from 'modules/profile';
import bemFactory from 'react-bem-factory';
import { useDispatch, useSelector } from 'react-redux';

import { messages } from './Help.messages';

const qa = bemFactory.qa('page-nav-bar');

export const useLearnMoreMenuItems = () => {
    return [
        {
            id: 'knowledge-base',
            name: messages.menuItemNameKnowledgeBase,
            href: 'https://support.approvalmax.com',
            dataQa: qa('action-kb-button'),
        },
        {
            id: 'how-to-videos',
            name: messages.menuItemNameHowToVideos,
            href: 'https://www.youtube.com/playlist?list=PLxK--FeEubedarj3IH4HhvXAISnOxBPCy',
            dataQa: qa('action-videos-button'),
        },
        {
            id: 'approvalmax-website',
            name: messages.menuItemNameWebSite,
            href: 'https://www.approvalmax.com/?stay',
            dataQa: qa('action-go-to-website-button'),
        },
        {
            id: 'get-the-mobile-app',
            name: messages.menuItemNameGetMobileApp,
            href: 'https://approvalmax.com/mobile-approvals',
            dataQa: qa('action-go-to-mobile-app'),
        },
    ];
};

export const useGetInTouchMenuItems = () => {
    const dispatch = useDispatch();

    const companies = useSelector(selectors.company.getCompanies);
    const profile = useSelector(selectors.profile.getProfile);
    const pendingInvitations = useSelector(selectors.companyInvitation.getPendingCompanyInvitations);

    const isAccountOwnerOrManager =
        profile?.account?.userRole &&
        [domain.AccountMemberRole.Administrator, domain.AccountMemberRole.Manager].includes(profile.account.userRole);
    const isMember = profile.account?.userRole === domain.AccountMemberRole.Member;
    const isUserManager = profile.account?.userRole === domain.AccountMemberRole.UserManager;
    const isPotentialAccountOwner =
        companies.length === 0 &&
        pendingInvitations.length === 0 &&
        profile?.account?.userRole &&
        !isMember &&
        !isUserManager;

    const isContactSupportAvailable = companies.some(({ flags }) => flags.isManager);
    const isContactSalesAvailable = Boolean(isAccountOwnerOrManager || isPotentialAccountOwner);

    const showContactSupportPopup = () => dispatch(profileModule.loadContactSupportPopup());
    const showContactSalesPopup = () => dispatch(profileModule.loadContactSalesPopup());

    return [
        {
            id: 'contact-support',
            name: messages.menuItemNameContactSupport,
            available: isContactSupportAvailable,
            dataQa: qa('action-contact-support-button'),
            onClick: showContactSupportPopup,
        },
        {
            id: 'contact-sales',
            name: messages.menuItemNameContactSales,
            available: isContactSalesAvailable,
            dataQa: qa('action-contact-sale-button'),
            onClick: showContactSalesPopup,
        },
    ].filter(({ available }) => available ?? true);
};
