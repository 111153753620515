import { Popup } from '@approvalmax/ui';
import { memo, useEffect } from 'react';

import ScreenAppCode from './components/ScreenAppCode/ScreenAppCode';
import ScreenBackupCode from './components/ScreenBackupCode/ScreenBackupCode';
import ScreenEmailCode from './components/ScreenEmailCode/ScreenEmailCode';
import ScreenMethodSelect from './components/ScreenMethodSelect/ScreenMethodSelect';
import { useTwoFaActionWizardContext } from './TwoFaActionWizard.context';
import { TwoFaActionWizardProps } from './TwoFaActionWizard.types';

const TTwoFaActionWizard = memo<TwoFaActionWizardProps>((props) => {
    const { isOpen, onClose, disableAutoClose } = props;

    const { activeScreen, resetContext } = useTwoFaActionWizardContext();

    useEffect(() => {
        if (!isOpen) {
            resetContext();
        }
    }, [isOpen, resetContext]);

    return (
        <Popup isOpen={isOpen} onRequestClose={onClose} disableAutoClose={disableAutoClose}>
            {
                {
                    authenticatorAppCode: <ScreenAppCode {...props} />,
                    emailCode: <ScreenEmailCode {...props} />,
                    backupCode: <ScreenBackupCode {...props} />,
                    methodSelect: <ScreenMethodSelect {...props} />,
                }[activeScreen]
            }
        </Popup>
    );
});

export default TTwoFaActionWizard;
