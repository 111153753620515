import { Action, LOGIN, LOGIN_FAILURE, LOGIN_RESPONSE } from '../../actions';

export default function (state = false, action: Action): boolean {
    switch (action.type) {
        case LOGIN:
            return true;

        case LOGIN_RESPONSE:
        case LOGIN_FAILURE:
            return false;

        default:
            return state;
    }
}
