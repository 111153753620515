import { toast } from '@approvalmax/ui/src/components';
import { dateTimeHelpers } from '@approvalmax/utils';
import { actions as automationActions } from 'modules/automation';
import { actions } from 'modules/common';
import { openTrialPeriodStartedPopup } from 'modules/profile/actions';
import { useDispatch } from 'modules/react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { amplitudeService } from 'services/amplitude';
import { useCreateTrialRequest, useGetCompanies, useGetUserContext } from 'shared/data';

import { checkActivateTrialInterval, maxReRequestsWaitActivate } from './StartTrialPeriodPopup.constants';
import { trialIsActive } from './StartTrialPeriodPopup.helpers';
import { messages } from './StartTrialPeriodPopup.messages';

export const useWaitActivateTrial = () => {
    const [companyId, setCompanyId] = useState('');

    const [isTrialActive, setIsTrialActive] = useState(false);

    const pollingCountRef = useRef(0);

    const {
        data: companies,
        isSuccess: isGetCompaniesSuccess,
        isError: isGetCompaniesError,
        fetchStatus: fetchStatusGetCompanies,
    } = useGetCompanies(
        { companyId },
        {
            enabled: !!companyId,
            refetchInterval: (data) => {
                if (!data || trialIsActive(data)) {
                    return false;
                }

                return checkActivateTrialInterval;
            },
        }
    );

    useEffect(() => {
        if (companyId && isGetCompaniesSuccess && fetchStatusGetCompanies === 'idle') {
            if (trialIsActive(companies)) {
                setIsTrialActive(true);
            } else {
                pollingCountRef.current++;

                if (pollingCountRef.current > maxReRequestsWaitActivate) {
                    toast.error(messages.trialActivationTimeoutError);

                    setCompanyId('');
                    pollingCountRef.current = 0;
                }
            }
        }
    }, [companyId, companies, fetchStatusGetCompanies, isGetCompaniesSuccess]);

    useEffect(() => {
        if (companyId && isGetCompaniesError) {
            setCompanyId('');
            pollingCountRef.current = 0;
        }
    }, [companyId, isGetCompaniesError]);

    const dispatch = useDispatch();

    const {
        isSuccess: isGetUserContextSuccess,
        isError: isGetUserContextError,
        data: context,
        fetchStatus: fetchStatusGetUserContext,
    } = useGetUserContext({
        enabled: isTrialActive,
    });

    useEffect(() => {
        if (isTrialActive && isGetUserContextSuccess && fetchStatusGetUserContext === 'idle') {
            dispatch(actions.loadInitialAppData({ context }));

            if (companyId) {
                dispatch(automationActions.loadCompanyTemplates({ companyId }));
            }

            dispatch(openTrialPeriodStartedPopup());
        }
    }, [companyId, context, dispatch, fetchStatusGetUserContext, isGetUserContextSuccess, isTrialActive]);

    useEffect(() => {
        if (isTrialActive && isGetUserContextError) {
            setCompanyId('');
            pollingCountRef.current = 0;
            setIsTrialActive(false);
        }
    }, [isTrialActive, isGetUserContextError]);

    return { companyId, setCompanyId };
};

export const useNotifyAdmins = (profileId: string) => {
    const [timeoutsByCompanyId, setTimeoutsByCompanyId] = useState<Record<string, number>>({});
    const { mutate: mutateTrialRequest, isLoading: isLoadingCreateTrialRequest } = useCreateTrialRequest({
        onSuccess: () => {
            toast.success(messages.notifyAdminSuccess);
        },
    });

    const handleNotifyAdmins = useCallback(
        (companyId: string) => {
            setTimeoutsByCompanyId((current) => ({
                ...current,
                [companyId]: 60,
            }));

            amplitudeService.sendData('billing: clicked on "notify admin" on AFT paywall', {
                user_id: profileId,
                org_id: companyId,
            });

            mutateTrialRequest({
                pathParams: {
                    companyId,
                },
                data: {},
            });
        },
        [mutateTrialRequest, profileId]
    );

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (Object.values(timeoutsByCompanyId).every((timeout) => !timeout || timeout <= 0)) {
                return;
            }

            const newTimeouts = Object.entries(timeoutsByCompanyId).reduce<Record<string, number>>(
                (result, [companyId, timeout]) => {
                    if (timeout) {
                        const nextTimeout = timeout - 1;

                        if (nextTimeout > 0) {
                            result[companyId] = nextTimeout;
                        }
                    }

                    return result;
                },
                {}
            );

            setTimeoutsByCompanyId(newTimeouts);
        }, 1000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [timeoutsByCompanyId]);

    const notifyAdminTimeoutsByCompanyId = useMemo(() => {
        return Object.entries(timeoutsByCompanyId).reduce<Record<string, string>>((result, [companyId, timeout]) => {
            result[companyId] = dateTimeHelpers.formatTime(timeout * 1000);

            return result;
        }, {});
    }, [timeoutsByCompanyId]);

    return { handleNotifyAdmins, notifyAdminTimeoutsByCompanyId, isLoadingCreateTrialRequest };
};
