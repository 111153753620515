import { produce } from 'immer';
import { domain } from 'modules/data';

import {
    Action,
    LOGIN_RESPONSE,
    RESET_PASSWORD_RESPONSE,
    SHOW_EMAIL_CONFIRMATION_MODULE,
    SHOW_FIRST_TIME_SETUP_MODULE,
    SHOW_LOGIN_MODULE,
    SHOW_MOBILE_INFO_MODULE,
    SHOW_PASSWORD_RESET_FINAL_STEP_MODULE,
    SHOW_PASSWORD_RESET_MODULE,
    SHOW_PROFILE_SETUP_MODULE,
    SHOW_RECEIPT_BANK_AUTH_MODULE,
    SHOW_SIGNUP_MODULE,
    SHOW_SLACK_AUTH_MODULE,
    SHOW_VERIFY_EMAIL_MODULE,
    SIGNUP_RESPONSE,
} from '../../actions';

export enum MobileDeviceId {
    Android = 'android',
    IPhone = 'iphone',
}

export enum PageActiveModule {
    None = 'None',
    LoginModule = 'LoginModule',
    TwoFaLoginModuleData = 'TwoFaLoginModuleData',
    PasswordResetModule = 'PasswordResetModule',
    PasswordResetFinalStepModule = 'PasswordResetFinalStepModule',
    SignupModule = 'SignupModule',
    EmailConfirmationModule = 'EmailConfirmationModule',
    ProfileSetupModule = 'ProfileSetupModule',
    VerifyEmailModule = 'VerifyEmailModule',
    MobileInfoModule = 'MobileInfoModule',
    ReceiptBankAuthModule = 'ReceiptBankAuthModule',
    FirstTimeSetupModule = 'FirstTimeSetupModule',
    SlackAuthModule = 'SlackAuthModule',
}

export interface VerifyEmailCodeData {
    email: string;
    code: string;
    postAction?: string;
    innerPageId?: string;
}

export function isVerifyEmailCodeData(params: any): params is VerifyEmailCodeData {
    return params.email !== undefined;
}

export interface VerifyEmailLinkCodeData {
    linkCode: string;
    postAction?: string;
    innerPageId?: string;
}

export interface NoneModuleData {
    type: typeof PageActiveModule.None;
}

export interface LoginModuleData {
    type: typeof PageActiveModule.LoginModule;
    email?: string;
    app?: domain.SsoLoginApplication;
    postAction?: string;
}

export interface TwoFaLoginModuleData {
    type: typeof PageActiveModule.TwoFaLoginModuleData;
    stateId: string;
    app?: domain.SsoLoginApplication;
    postAction?: string;
    isTFAAlternativeEmailEnabled: boolean;
    isTFABackupCodesEnabled: boolean;
}

export interface PasswordResetModuleData {
    type: typeof PageActiveModule.PasswordResetModule;
    email?: string;
    success: boolean;
}

export interface PasswordResetFinalStepModuleData {
    type: typeof PageActiveModule.PasswordResetFinalStepModule;
    stateId: string;
    tfaEnabled: boolean;
    postAction?: string;
    isTFAAlternativeEmailEnabled: boolean;
    isTFABackupCodesEnabled: boolean;
}

export interface SignupModuleData {
    type: typeof PageActiveModule.SignupModule;
    success: boolean;
    app?: domain.SsoLoginApplication;
    email?: string;
    token?: string;
}

export interface EmailConfirmationModuleData {
    type: typeof PageActiveModule.EmailConfirmationModule;
}

export interface ProfileSetupModuleData {
    type: typeof PageActiveModule.ProfileSetupModule;
}

export interface VerifyEmailModuleData {
    type: typeof PageActiveModule.VerifyEmailModule;
    data: VerifyEmailCodeData | VerifyEmailLinkCodeData;
}

export interface ReceiptBankAuthModuleData {
    type: typeof PageActiveModule.ReceiptBankAuthModule;
    callbackUrl: string;
}

export interface MobileInfoModuleData {
    type: typeof PageActiveModule.MobileInfoModule;
    device: MobileDeviceId;
}

export interface FirstTimeSetupModuleData {
    type: typeof PageActiveModule.FirstTimeSetupModule;
}

export interface SlackAuthModuleData {
    type: typeof PageActiveModule.SlackAuthModule;
}

export type ActiveModule =
    | NoneModuleData
    | LoginModuleData
    | TwoFaLoginModuleData
    | PasswordResetModuleData
    | PasswordResetFinalStepModuleData
    | SignupModuleData
    | EmailConfirmationModuleData
    | ProfileSetupModuleData
    | ReceiptBankAuthModuleData
    | VerifyEmailModuleData
    | MobileInfoModuleData
    | FirstTimeSetupModuleData
    | SlackAuthModuleData;

export default produce((draft: ActiveModule, action: Action): ActiveModule => {
    switch (action.type) {
        case SHOW_LOGIN_MODULE:
            return {
                type: PageActiveModule.LoginModule,
                email: action.payload.email,
                app: action.payload.app,
                postAction: action.payload.postAction,
            };

        case SHOW_SIGNUP_MODULE:
            return {
                type: PageActiveModule.SignupModule,
                success: false,
                app: action.payload.app,
                email: action.payload.email,
                token: action.payload.token,
            };

        case SHOW_EMAIL_CONFIRMATION_MODULE:
            return {
                type: PageActiveModule.EmailConfirmationModule,
            };

        case SHOW_PROFILE_SETUP_MODULE:
            return {
                type: PageActiveModule.ProfileSetupModule,
            };

        case SHOW_VERIFY_EMAIL_MODULE:
            return {
                type: PageActiveModule.VerifyEmailModule,
                data: action.payload.data,
            };

        case SHOW_RECEIPT_BANK_AUTH_MODULE:
            return {
                type: PageActiveModule.ReceiptBankAuthModule,
                callbackUrl: action.payload.callbackUrl,
            };

        case SHOW_SLACK_AUTH_MODULE:
            return {
                type: PageActiveModule.SlackAuthModule,
            };

        case SHOW_PASSWORD_RESET_MODULE:
            return {
                type: PageActiveModule.PasswordResetModule,
                email: action.payload.email,
                success: false,
            };

        case SHOW_PASSWORD_RESET_FINAL_STEP_MODULE:
            return {
                type: PageActiveModule.PasswordResetFinalStepModule,
                stateId: action.payload.stateId,
                tfaEnabled: action.payload.tfaEnabled,
                postAction: action.payload.postAction,
                isTFAAlternativeEmailEnabled: action.payload.isTFAAlternativeEmailEnabled,
                isTFABackupCodesEnabled: action.payload.isTFABackupCodesEnabled,
            };

        case SHOW_MOBILE_INFO_MODULE:
            return {
                type: PageActiveModule.MobileInfoModule,
                device: action.payload.device,
            };

        case SHOW_FIRST_TIME_SETUP_MODULE:
            return {
                type: PageActiveModule.FirstTimeSetupModule,
            };

        case SIGNUP_RESPONSE:
            (draft as SignupModuleData).success = true;

            return draft;

        case RESET_PASSWORD_RESPONSE:
            (draft as PasswordResetModuleData).success = true;

            return draft;

        case LOGIN_RESPONSE: {
            if (!action.payload.is2faEnabled) {
                return draft;
            }

            const oldState = draft as LoginModuleData;

            return {
                type: PageActiveModule.TwoFaLoginModuleData,
                stateId: action.payload.stateId!,
                app: oldState.app,
                postAction: oldState.postAction,
                isTFAAlternativeEmailEnabled: action.payload.isTFAAlternativeEmailEnabled,
                isTFABackupCodesEnabled: action.payload.isTFABackupCodesEnabled,
            };
        }

        default:
            return draft;
    }
});
