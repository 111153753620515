import { stateTree } from 'modules/data';
import { ImmutableObject, merge } from 'modules/immutable';

import { Action, LOAD_PAGE_DATA } from '../actions';

export default (
    state: ImmutableObject<stateTree.Navigation>,
    action: Action
): ImmutableObject<stateTree.Navigation> => {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return merge(state, {
                activePageId: null,
                activeCompany: action.payload.companyId,
            });

        default:
            return state;
    }
};
