import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SHOW_START_OVER_POPUP, START_OVER_REQUEST_RESPONSE } from '../../../actions';

export const START_OVER_POPUP = 'START_OVER_POPUP';

export interface StartOverPopupData {
    id: typeof START_OVER_POPUP;
    requestId: Guid;
}

export type StartOverPopupType = ImmutableObject<StartOverPopupData> | null;

export default function (state: StartOverPopupType, action: Action): StartOverPopupType {
    switch (action.type) {
        case SHOW_START_OVER_POPUP:
            return immutable<StartOverPopupData>({
                id: START_OVER_POPUP,
                requestId: action.payload.requestId,
            });

        case START_OVER_REQUEST_RESPONSE:
            return null;

        default:
            return state;
    }
}
