import { backend } from 'modules/data';

import { ApiHandler } from '../types';

class AirwallexUrls {
    constructor(private _apiHandler: ApiHandler) {}
}

export default class AirwallexApi {
    public urls: AirwallexUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new AirwallexUrls(this._apiHandler);
    }

    public getBeneficiaries(
        transfer: backend.transfers.GetIntegrationDataTransfer
    ): Promise<backend.AirwallexBeneficiariesAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'paymentservices/airwallex/getBeneficiaries',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getBeneficiarySchema(
        transfer: backend.transfers.AirwallexBeneficiarySchemaTransfer
    ): Promise<backend.AirwallexBeneficiarySchemaAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'paymentservices/airwallex/getBeneficiarySchema',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public createBeneficiary(
        transfer: backend.transfers.AirwallexCreateBeneficiaryTransfer
    ): Promise<backend.AirwallexCreateBeneficiaryAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'paymentservices/airwallex/createBeneficiary',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
