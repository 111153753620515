import { Tooltip } from '@approvalmax/ui';
import { InfoIconGray } from 'modules/sprites';
import { memo } from 'react';

import { messages } from './TrustThisDeviceHint.messages';

const TrustThisDeviceHint = memo(() => {
    return (
        <Tooltip tooltip={messages.tooltip}>
            <InfoIconGray />
        </Tooltip>
    );
});

export default TrustThisDeviceHint;
