import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.profile.ProfilePopup.UpdateBackupCodesPopup', {
    popupTitle: 'Update backup codes',
    title: 'Backup codes',
    downloadButton: 'Download codes (txt file)',
    description: 'NOTE: current backup codes will be deleted after you generate the new codes.',
    generateNewCodes: 'Generate new codes',
    onSuccess: 'New backup codes were generated',
});
