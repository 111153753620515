import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { ElementFactory } from 'react-bem-factory';

import { SetWorkflowSettingsHandler } from '../../WorkflowSettingsPopupContent.types';

export type SectionXeroBillMatchingProps = {
    company: selectors.types.ExpandedCompany;
} & (
    | {
          templateSettings: domain.TemplateSettings;
          onChange: SetWorkflowSettingsHandler;
          readonly?: boolean;
          qa: ElementFactory;
      }
    | {
          promoView: true;
      }
);

export enum RetrospectivePoMatchingOption {
    True = 'True',
    False = 'False',
}
