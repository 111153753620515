import { Reference } from '@approvalmax/types';
import { selectors } from 'modules/common';
import moment from 'moment';

export function printReferenceCell(value: Reference | null) {
    if (!value) {
        return '';
    }

    return value.text;
}

export function printDateCell(value: string) {
    // Server format: 2018-06-09
    return moment(value).format('ll');
}

export function printDateTimeCell(value: string) {
    // Server format: 2018-06-09 16:26:56
    return moment(value, 'YYYY-MM-DD HH:mm:ss').format('lll');
}

export function printUserCell(user: selectors.types.ExpandedUser) {
    return user.displayName;
}

export function printCellNoop(value: string) {
    return value;
}
