import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetRequestLegacy } from 'shared/data';

import { authApiPaths } from '../paths';
import {
    UseGetQBooksOAuthUrlQueryParams,
    UseGetQBooksOAuthUrlResponse,
    UseGetQBooksOAuthUrlResponseBackend,
} from './useGetQBooksOAuthUrl.types';

export const useGetQBooksOAuthUrl = (
    params: QueryParams<UseGetQBooksOAuthUrlQueryParams>,
    queryOptions?: QueryOptions<UseGetQBooksOAuthUrlResponseBackend, UseGetQBooksOAuthUrlResponse>
) => {
    return useGetRequestLegacy<UseGetQBooksOAuthUrlResponseBackend, UseGetQBooksOAuthUrlResponse>(
        authApiPaths.qBooksOAuthUrl,
        params,
        {
            mapData: objectHelpers.pascalCaseToCamelCase,
            queryOptions: {
                ...queryOptions,
                enabled: enabledByParams<UseGetQBooksOAuthUrlQueryParams>(params, queryOptions),
            },
        }
    );
};
