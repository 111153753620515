import { Guid } from '@approvalmax/types';

import { DearRequest, DearRequestDetails, DearRequestSpecifics } from './Request.Dear';
import { NetSuiteRequest, NetSuiteRequestDetails, NetSuiteRequestSpecifics } from './Request.NetSuite';
import { QBooksRequest, QBooksRequestDetails, QBooksRequestSpecifics } from './Request.QBooks';
import { StandaloneRequest, StandaloneRequestDetails, StandaloneRequestSpecifics } from './Request.Standalone';
import { XeroRequest, XeroRequestDetails, XeroRequestSpecifics } from './Request.Xero';

export * from './Request.Dear';
export * from './Request.NetSuite';
export * from './Request.QBooks';
export * from './Request.Standalone';
export * from './Request.Xero';
export * from './RequestBase';

export enum RequestAuditReportStatus {
    Unknown = 'Unknown',
    Error = 'Error',
    ConnectionError = 'ConnectionError',
    Pending = 'Pending',
    Ready = 'Ready',
}

export interface PossibleDuplicateItem {
    friendlyName: string;
    requestId: Guid;
}

export enum DeadlineRuleType {
    onSubmission = 'on-request-submission',
    onApproval = 'on-request-step-change',
    onStartOfReview = 'on-start-of-review',
}

export interface DeadlineRule {
    calculator: DeadlineRuleType | null;
    duration: string;
}

export enum StepType {
    Approval = 'Approval',
    Review = 'Review',
}

export type PossibleDuplicates = PossibleDuplicateItem[];

export enum LineAmountType {
    TaxExclusive = 'Tax Exclusive',
    TaxInclusive = 'Tax Inclusive',
    NoTax = 'No Tax',
}

export type Request = StandaloneRequest | XeroRequest | QBooksRequest | NetSuiteRequest | DearRequest;

export type RequestSpecifics =
    | StandaloneRequestSpecifics
    | XeroRequestSpecifics
    | QBooksRequestSpecifics
    | NetSuiteRequestSpecifics
    | DearRequestSpecifics;

export type RequestDetails =
    | StandaloneRequestDetails
    | XeroRequestDetails
    | QBooksRequestDetails
    | NetSuiteRequestDetails
    | DearRequestDetails;

export enum SupplierEmailReplacements {
    companyName = 'COMPANYNAME',
    contactPersonName = 'CONTACTPERSONNAME',
    customerName = 'CUSTOMERNAME',
    decisionMakingWebPageLink = 'DECISIONMAKINGWEBPAGELINK',
    invoiceNumber = 'INVOICENUMBER',
    poNumber = 'PONUMBER',
    quoteNumber = 'QUOTENUMBER',
    supplierName = 'SUPPLIERNAME',
    terms = 'TERMS',
    totalAmount = 'TOTALAMOUNT',
}

export interface RequestCurrentBalance {
    currencyIsoCode: string;
    availableAmount: number;
    toBePaidAmount: number;
}

export enum GoodsReceivedNotesStatus {
    NotReceived = 'NotReceived',
    PartiallyReceived = 'PartiallyReceived',
    FullyReceived = 'FullyReceived',
}
