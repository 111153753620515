import { Mods, mods } from '@approvalmax/theme';
import {
    ConfirmationEmptyTransparentIcon,
    ConfirmationFullIcon,
    ConfirmationMarkTransparentIcon,
} from '@approvalmax/ui/src/old/icons';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.div<StyledMods<Mods<'last'>>>`
    display: flex;
    align-items: center;

    ${mods.last.false`
        flex: 1;
    `};
`;

export const Line = styled.div<StyledMods<Mods<'last' | 'done'>>>`
    height: 2px;
    background: #e4e4e4;
    margin: 0 2px;
    flex: 1;

    ${mods.done.true`
      background: #4c8a5c;
    `};

    ${mods.last.true`
      visibility: hidden;
    `};
`;

export const MarkIcon = styled(ConfirmationMarkTransparentIcon)`
    fill: #4c8a5c;
`;

export const EmptyIcon = styled(ConfirmationEmptyTransparentIcon)`
    fill: #cacccd;
`;

export const DoneIcon = styled(ConfirmationFullIcon)``;
