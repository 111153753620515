import { moduleDef as authModuleDef } from 'modules/auth';
import { moduleDef as automationModuleDef } from 'modules/automation';
import { moduleDef as commonModuleDef } from 'modules/common';
import { moduleDef as companyModuleDef } from 'modules/company';
import { moduleDef as dataProvidersModuleDef } from 'modules/data-providers';
import { moduleDef as firstStartModuleDef } from 'modules/first-start';
import { moduleDef as integrationModuleDef } from 'modules/integration';
import { Module } from 'modules/page';
import { moduleDef as profileModuleDef } from 'modules/profile';
import { moduleDef as reactReduxModuleDef } from 'modules/react-redux';
import { moduleDef as requestModuleDef } from 'modules/request';
import { moduleDef as searchModuleDef } from 'modules/search';

const allModules: Module[] = [
    authModuleDef,
    automationModuleDef,
    commonModuleDef,
    companyModuleDef,
    dataProvidersModuleDef,
    firstStartModuleDef,
    integrationModuleDef,
    profileModuleDef,
    reactReduxModuleDef,
    requestModuleDef,
    searchModuleDef,
];

export const allModuleComponents = allModules.reduce(
    (acc, module) => (module.moduleComponent ? [...acc, module.moduleComponent] : acc),
    []
);

export default allModules;
