import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { ElementFactory } from 'react-bem-factory';

import { ChangeHandler } from '../../WorkflowSettingsPopupContent.types';

export type SectionXeroPOMatchingProps =
    | {
          templateSettings: domain.TemplateSettings;
          onChangeAutoMarkingPOAsBilledEnabled: ChangeHandler<boolean>;
          onChangeAutoUnmarkingPOAsBilledEnabled: ChangeHandler<boolean>;
          qa: ElementFactory;
          readonly: boolean;
      }
    | {
          company: selectors.types.ExpandedCompany;
          promoView: true;
      };

export enum AutoMarkingPoOption {
    yes = 'yes',
    no = 'no',
}
