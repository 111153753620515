import { backend } from 'modules/data';
import { schema } from 'normalizr';

import { mapUser } from './userSchema';

export function mapPracticeStaffInvitation(value: backend.PraciceStaffInvitation) {
    let invitation: any = {};

    invitation.id = value.accountId;
    invitation.dispatchDate = value.dispatchDate;
    invitation.practiceName = value.practiceName;
    invitation.inviter = mapUser(value.inviter);

    return invitation;
}

export default new schema.Entity(
    'practiceInvitations',
    {},
    {
        idAttribute: 'accountId',
        processStrategy: mapPracticeStaffInvitation,
    }
);
