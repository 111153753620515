import { atom } from 'recoil';

import { IntegrationPopupPreviewImageState } from './ModuleComponent.types';

export const integrationPopupPreviewImageState = atom<IntegrationPopupPreviewImageState>({
    key: 'integrationPopupPreviewImageState',
    default: {
        isOpen: false,
        src: '',
        alt: '',
    },
});
