import { domain } from 'modules/data';

export interface ReplacementOption {
    template: domain.SupplierEmailReplacements;
    replacement: string | null;
}

export const replaceSuplierEmailPlaceholders = (text: string, replacements: ReplacementOption[]) => {
    return replacements.reduce((result, replaceOption) => {
        return replaceOption.replacement !== null
            ? result.replaceAll(replaceOption.template, replaceOption.replacement)
            : result;
    }, text);
};

export function isEmailToSupplierEmpty(emailToSupplier: domain.EmailToSupplier | null) {
    if (emailToSupplier === null) {
        return true;
    }

    return (
        emailToSupplier.attachments.length === 0 &&
        !emailToSupplier.body &&
        emailToSupplier.cc.length === 0 &&
        !emailToSupplier.replyTo &&
        !emailToSupplier.subject &&
        emailToSupplier.to.length === 0
    );
}
