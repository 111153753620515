import { errorHelpers } from '@approvalmax/utils';

import * as dateRangeFilter from './dateRangeFilter';
import { FilterContext } from './FilterContext';
import FilterType from './FilterType';
import * as noneFilter from './noneFilter';
import * as numberRangeFilter from './numberRangeFilter';
import * as referenceListFilter from './referenceListFilter';
import * as referenceValueFilter from './referenceValueFilter';
import * as stringFilter from './stringFilter';
import * as userListFilter from './userListFilter';

export type Filter =
    | referenceListFilter.ReferenceListFilter
    | referenceValueFilter.ReferenceValueFilter
    | userListFilter.UserListFilter
    | userListFilter.UserListByIdFilter
    | dateRangeFilter.DateRangeFilter
    | numberRangeFilter.NumberRangeFilter
    | noneFilter.NoneFilter
    | stringFilter.StringFilter;

export { FilterType };

export function getFilterPreviewText(filter: Filter, context: FilterContext): string | null {
    switch (filter.type) {
        case FilterType.ReferenceList:
            return referenceListFilter.getFilterPreviewText(filter);

        case FilterType.ReferenceValue:
            return referenceValueFilter.getFilterPreviewText(filter);

        case FilterType.DateRange:
            return dateRangeFilter.getFilterPreviewText(filter);

        case FilterType.NumberRange:
            return numberRangeFilter.getFilterPreviewText(filter);

        case FilterType.UserList:
            return userListFilter.getFilterPreviewText(filter, context.companyTeam);

        case FilterType.UserIdsList:
            return userListFilter.getFilterByIdPreviewText(filter, context.companyTeam);

        case FilterType.None:
            return null;

        case FilterType.String:
            return stringFilter.getFilterPreviewText(filter);

        default:
            throw errorHelpers.invalidOperationError();
    }
}

export function getFilterValue(filter: Filter) {
    switch (filter.type) {
        case FilterType.ReferenceValue:
            return filter.value?.id;

        case FilterType.String:
            return filter.value;

        case FilterType.ReferenceList:
        case FilterType.DateRange:
        case FilterType.NumberRange:
        case FilterType.UserList:
            throw errorHelpers.notSupportedError();

        case FilterType.None:
        default:
            return null;
    }
}

export function createAlwaysTrueFilter(filterType: FilterType): Filter {
    switch (filterType) {
        case FilterType.ReferenceList:
            return referenceListFilter.createAlwaysTrueFilter();

        case FilterType.ReferenceValue:
            return referenceValueFilter.createAlwaysTrueFilter();

        case FilterType.DateRange:
            return dateRangeFilter.createAlwaysTrueFilter();

        case FilterType.NumberRange:
            return numberRangeFilter.createAlwaysTrueFilter();

        case FilterType.UserList:
            return userListFilter.createAlwaysTrueFilter();

        case FilterType.UserIdsList:
            return userListFilter.createAlwaysTrueFilterById();

        case FilterType.None:
            return noneFilter.createAlwaysTrueFilter();

        case FilterType.String:
            return stringFilter.createAlwaysTrueFilter();

        default:
            throw errorHelpers.invalidOperationError();
    }
}
