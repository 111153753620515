import { Popup } from '@approvalmax/ui/src/components';
import { useDispatch, useSelector } from 'modules/react-redux';
import { ActivePopup, closeActivePopup, getActivePopup } from 'modules/request';
import { memo, useCallback } from 'react';

import { Content } from './components/index';

export const NewRequestPopup = memo(() => {
    const dispatch = useDispatch();
    const activePopup = useSelector(getActivePopup);

    const handleCancelActivePopup = useCallback(
        (isOpen: boolean) => {
            if (!isOpen) {
                dispatch(closeActivePopup());
            }
        },
        [dispatch]
    );

    return (
        <Popup open={activePopup === ActivePopup.NewRequest} onToggle={handleCancelActivePopup} size='large'>
            <Content />
        </Popup>
    );
});

NewRequestPopup.displayName = 'NewRequestPopup';
