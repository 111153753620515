/**
 * Developer: Stepan Burguchev
 * Date: 2/9/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { Entities } from 'modules/data';
import { ImmutableObject } from 'modules/immutable';

import { Action } from '../actions';

export default function (state: ImmutableObject<Entities>, action: Action): ImmutableObject<Entities> {
    switch (action.type) {
        default:
            return state;
    }
}
