import { errorHelpers } from '@approvalmax/utils';
import { schema } from 'normalizr';

import * as backend from '../../backend';
import { getBeautifiedEntity } from '../../utils';
import {
    AirwallexIntegration,
    Company,
    CompanyBillMatchingSettings,
    CompanyLicenseFeature,
    CompanyPlan,
    CompanyPlanEdition,
    CompanyPurchaseOrderMatchingSettings,
    CompanyQboPriceCheckerSettings,
    CompanyQboPurchaseOrderSettings,
    CompanyUserPermissionsSettings,
    CompanyUserStatus,
    CompanyXeroPurchaseOrderSettings,
    ReceiptBankIntegration,
} from '../Company';
import integrationSchema from './integrationSchema';
import userSchema, { mapUser } from './userSchema';

export function mapCompanyPlanEdition(value: backend.AccountsPlanEdition) {
    switch (value) {
        case backend.AccountsPlanEdition.Standard:
            return CompanyPlanEdition.Standard;

        case backend.AccountsPlanEdition.NotForProfit:
            return CompanyPlanEdition.NotForProfit;

        default:
            throw errorHelpers.assertNever(value);
    }
}

export function mapCompanyPlan(value: string): CompanyPlan {
    return value as CompanyPlan;
}

function extractUserStatusFromAnswer(answer: any, companyAuthorId: string): CompanyUserStatus {
    if (companyAuthorId === answer.UserEmail) {
        return CompanyUserStatus.Active;
    } else if (!answer.Invitation) {
        return CompanyUserStatus.NotInvited;
    } else if (!answer.Invitation.response) {
        return CompanyUserStatus.Invited;
    } else if (answer.Invitation.response === 'Accepted') {
        return CompanyUserStatus.Active;
    } else {
        return CompanyUserStatus.Rejected;
    }
}

export function extractUserStatusFromAnswerArray(
    answers: any[],
    companyAuthorId: string
): {
    [userId: string]: CompanyUserStatus;
} {
    return answers.reduce((m, a) => {
        m[a.UserEmail] = extractUserStatusFromAnswer(a, companyAuthorId);

        return m;
    }, {} as any);
}

export function mapCompanyBillMatchingSettings(
    value: backend.AccountingCompaniesBillMatchingSettings | backend.AccountingCompaniesPurchaseOrderMatchingSettings
): CompanyBillMatchingSettings | undefined {
    if ('autoMatchingEnabled' in value) {
        return {
            addMatchedPOsRequestersAsApproversForBill: value.addMatchedPOsRequestersAsApproversForBill,
            autoMatchingEnabled: value.autoMatchingEnabled,
            manualMatchingEnabled: value.manualMatchingEnabled,
            canMatchBillsWithRetrospectivePurchaseOrders: value.retrospectivePOMatchingAllowed || false,
            allowApprovalOfNotMatchedBills: value.notMatchedBillsApprovalAllowed,
            notMatchedBillsApprovalThreshold: value.notMatchedBillsApprovalThreshold,
            allowApprovalOfOverbudgetBills: value.overbudgetMatchBillsApprovalAllowed,
            overbudgetBillsApprovalThreshold: value.overbudgetMatchBillsApprovalThreshold,
            overbudgetBillsApprovalPercentageThreshold: value.overbudgetMatchBillsApprovalPercentageThreshold,
            amountType: value.amountType,
            insufficientBudgetMatchBillsApprovalAllowed: value.insufficientBudgetMatchBillsApprovalAllowed,
            insufficientBudgetMatchBillsApprovalThreshold: value.insufficientBudgetMatchBillsApprovalThreshold,
            insufficientBudgetMatchBillsApprovalPercentageThreshold:
                value.insufficientBudgetMatchBillsApprovalPercentageThreshold,
        };
    } else return undefined;
}

export function mapCompanyBillMatchingSettingsBack(
    value?: CompanyBillMatchingSettings
): backend.AccountingCompaniesBillMatchingSettings | undefined {
    if (value) {
        return {
            addMatchedPOsRequestersAsApproversForBill: value.addMatchedPOsRequestersAsApproversForBill,
            autoMatchingEnabled: value.autoMatchingEnabled,
            manualMatchingEnabled: value.manualMatchingEnabled,
            retrospectivePOMatchingAllowed: value.canMatchBillsWithRetrospectivePurchaseOrders,
            notMatchedBillsApprovalAllowed: value.allowApprovalOfNotMatchedBills,
            notMatchedBillsApprovalThreshold: value.notMatchedBillsApprovalThreshold,
            overbudgetMatchBillsApprovalAllowed: value.allowApprovalOfOverbudgetBills,
            overbudgetMatchBillsApprovalThreshold: value.overbudgetBillsApprovalThreshold,
            overbudgetMatchBillsApprovalPercentageThreshold: value.overbudgetBillsApprovalPercentageThreshold,
            amountType: value.amountType,
            insufficientBudgetMatchBillsApprovalAllowed: value.insufficientBudgetMatchBillsApprovalAllowed,
            insufficientBudgetMatchBillsApprovalThreshold: value.insufficientBudgetMatchBillsApprovalThreshold,
            insufficientBudgetMatchBillsApprovalPercentageThreshold:
                value.insufficientBudgetMatchBillsApprovalPercentageThreshold,
        };
    } else return undefined;
}

export function mapCompanyPurchaseOrderMatchingSettings(
    value: backend.AccountingCompaniesBillMatchingSettings | backend.AccountingCompaniesPurchaseOrderMatchingSettings
): CompanyPurchaseOrderMatchingSettings | undefined {
    if ('autoMarkingPurchaseOrderAsBilledEnabled' in value) {
        return {
            autoMarkingPurchaseOrderAsBilledEnabled: value.autoMarkingPurchaseOrderAsBilledEnabled,
            autoUnmarkingPurchaseOrderAsBilledEnabled: value.autoUnmarkingPurchaseOrderAsBilledEnabled,
        };
    } else return undefined;
}

export function mapCompanyXeroPurchaseOrderSettings(
    value: backend.AccountingCompaniesXeroPurchaseOrderSettings
): CompanyXeroPurchaseOrderSettings | undefined {
    if ('IsGrnEnabled' in value) {
        return {
            isGrnEnabled: value.IsGrnEnabled,
        };
    }

    return undefined;
}

export function mapCompanyQboPurchaseOrderSettings(
    value: backend.AccountingCompaniesQboPurchaseOrderSettings
): CompanyQboPurchaseOrderSettings | undefined {
    if ('IsGrnEnabled' in value) {
        return {
            isGrnEnabled: value.IsGrnEnabled,
        };
    }

    return undefined;
}

export function mapCompanyQboPriceCheckerSettings(
    value: backend.AccountingCompaniesQboPriceCheckerSettings
): CompanyQboPriceCheckerSettings | undefined {
    if (value) {
        return {
            isEnabledForBills: value.IsEnabledForBills,
            isEnabledForExpenses: value.IsEnabledForExpenses,
            isEnabledForPurchaseOrders: value.IsEnabledForPurchaseOrders,
        };
    }

    return undefined;
}

export function mapCompanyPurchaseOrderMatchingSettingsBack(
    value?: backend.AccountingCompaniesPurchaseOrderMatchingSettings
): CompanyPurchaseOrderMatchingSettings | undefined {
    if (value) {
        return {
            autoMarkingPurchaseOrderAsBilledEnabled: value.autoMarkingPurchaseOrderAsBilledEnabled,
            autoUnmarkingPurchaseOrderAsBilledEnabled: value.autoUnmarkingPurchaseOrderAsBilledEnabled,
        };
    } else return undefined;
}

function mapReceiptBankIntegration(value: any): ReceiptBankIntegration | null {
    if (!value) {
        return null;
    }

    return {
        id: value.integrationId,
        companyId: value.companyId,
        createdDate: value.createdDate,
        modifiedDate: value.modifiedDate,
        isConnected: value.isConnected,
    };
}

function mapAirwallexIntegration(value: any): AirwallexIntegration | null {
    if (!value) {
        return null;
    }

    return {
        id: value.integrationId,
        companyId: value.companyId,
        createdDate: value.createdDate,
        modifiedDate: value.modifiedDate,
        isConnected: value.isConnected,
        integratedAccountName: value.integratedAccountName,
        connectedByUser: value.connectedByUser ? mapUser(value.connectedByUser) : null,
    };
}

export const mapCompany = (value: backend.CompanyAnswer): Company => {
    let company: any = getBeautifiedEntity(value, {
        CompanyId: 'id',
        Integrations: 'integration',
    });

    company.integrationId = company.integration.length > 0 ? company.integration[0] : null;
    company.delegates = [];
    company.userStatuses = {};

    if (company.billMatchingSettings) {
        company.billMatchingSettings = mapCompanyBillMatchingSettings(company.billMatchingSettings);
    }

    if (company.purchaseOrderMatchingSettings) {
        company.purchaseOrderMatchingSettings = mapCompanyPurchaseOrderMatchingSettings(
            company.purchaseOrderMatchingSettings
        );
    }

    if (company.xeroPurchaseOrderSettings) {
        company.xeroPurchaseOrderSettings = mapCompanyXeroPurchaseOrderSettings(company.xeroPurchaseOrderSettings);
    }

    if (company.qBooksPurchaseOrderSettings) {
        company.qBooksPurchaseOrderSettings = mapCompanyQboPurchaseOrderSettings(company.qBooksPurchaseOrderSettings);
    }

    if (company.qBooksPriceCheckerSettings) {
        company.qBooksPriceCheckerSettings = mapCompanyQboPriceCheckerSettings(company.qBooksPriceCheckerSettings);
    }

    const userPermissionsSettings: CompanyUserPermissionsSettings = {};

    [...company.auditors, ...company.managers, ...company.participants, ...company.workflowManagers].forEach((user) => {
        if (user.Delegate) {
            company.delegates.push({
                userId: user.UserEmail,
                delegateUserId: user.Delegate,
                delegateFrom: user.DelegateFrom || null,
                delegateTo: user.DelegateTo || null,
            });
            delete user.Delegate;
        }

        company.userStatuses[user.UserEmail] = extractUserStatusFromAnswer(user, company.author.UserEmail);

        userPermissionsSettings[user.UserId] = user.Permissions || [];
    });

    const result: Company = {
        id: value.CompanyId,
        name: company.name,
        author: company.author,
        defaultCurrency: company.defaultCurrency,
        betaFeatures: company.betaFeatures ? company.betaFeatures.map((x: any) => x.Key) : [],
        licenseFeatures: value.ProductPlanFeatures as CompanyLicenseFeature[],
        licenseProductPlan: mapCompanyPlan(value.ProductPlanId),
        licenseExpires: value.LicenseExpires || null,
        licenseId: value.LicenseId,
        isReadonly: Boolean(value.IsReadOnly),
        subscriptionId: value.SubscriptionId || null,
        delegates: company.delegates,
        userStatuses: company.userStatuses,
        auditors: company.auditors,
        managers: company.managers,
        participants: company.participants,
        workflowManagers: company.workflowManagers,
        integrationId: company.integrationId,
        timeZone: company.timeZone,
        billMatchingSettings: company.billMatchingSettings,
        purchaseOrderMatchingSettings: company.purchaseOrderMatchingSettings,
        receiptBankIntegration: mapReceiptBankIntegration(company.receiptBankIntegration),
        airwallexIntegration: mapAirwallexIntegration(company.airwallexIntegration),
        tempUsers: {},
        allFeaturesTrialStatus: company.allFeaturesTrialStatus,
        allFeaturesTrialEndDate: company.allFeaturesTrialEndDate,
        matchingMetadata: company.matchingMetadata,
        enforcementTfaType: company.enforcementTfaType,
        xeroPurchaseOrderSettings: company.xeroPurchaseOrderSettings,
        qboPurchaseOrderSettings: company.qBooksPurchaseOrderSettings,
        qboPriceCheckerSettings: company.qBooksPriceCheckerSettings,
        hasActiveWorkflowWithUserAsPayer: company.hasActiveWorkflowWithUserAsPayer,
        userPermissionsSettings,
    };

    // When company is loaded separately (like through company/get), some fields might be missing
    // and they should not override existing data
    if (!result.billMatchingSettings) {
        delete (result as Partial<Company>).billMatchingSettings;
    }

    if (!result.purchaseOrderMatchingSettings) {
        delete (result as Partial<Company>).purchaseOrderMatchingSettings;
    }

    if (!result.xeroPurchaseOrderSettings || result.xeroPurchaseOrderSettings.isGrnEnabled === undefined) {
        delete (result as Partial<Company>).xeroPurchaseOrderSettings;
    }

    if (!result.qboPurchaseOrderSettings || result.qboPurchaseOrderSettings.isGrnEnabled === undefined) {
        delete (result as Partial<Company>).qboPurchaseOrderSettings;
    }

    if (!result.qboPriceCheckerSettings) {
        delete (result as Partial<Company>).qboPriceCheckerSettings;
    }

    if (!result.matchingMetadata) {
        delete (result as Partial<Company>).matchingMetadata;
    }

    return result;
};

export const companySchema = new schema.Entity(
    'companies',
    {
        auditors: new schema.Array(userSchema),
        author: userSchema,
        managers: new schema.Array(userSchema),
        integrationId: integrationSchema,
        participants: new schema.Array(userSchema),
        workflowManagers: new schema.Array(userSchema),
        delegates: new schema.Array({
            delegateUserId: userSchema,
        }),
    },
    {
        idAttribute: 'id',
    }
);

export const companySchemaLegacy = new schema.Entity(
    'companies',
    {
        auditors: new schema.Array(userSchema),
        author: userSchema,
        managers: new schema.Array(userSchema),
        integrationId: integrationSchema,
        participants: new schema.Array(userSchema),
        workflowManagers: new schema.Array(userSchema),
        delegates: new schema.Array({
            delegateUserId: userSchema,
        }),
    },
    {
        idAttribute: 'CompanyId',
        processStrategy: mapCompany,
    }
);
