import { backend, domain } from 'modules/data';

export const parseQBooksPayee = (value: backend.IntegrationsQBooksPayee): domain.QBooksPayee => {
    const { Currency, Addresses = [], ContactPersons, Id, Name, TaxId, Email } = value;

    const targetAddress = Addresses[0] || {};
    const { AddressLine1, AddressLine2, AddressLine3, AddressLine4, City, Region, PostalCode, Country } = targetAddress;
    const addressFirstLine = [AddressLine1, AddressLine2, AddressLine3, AddressLine4].filter(Boolean).join(', ');
    const addressSecondLine = [City, Region, PostalCode, Country].filter(Boolean).join(', ');
    const address = [addressFirstLine, addressSecondLine].filter(Boolean);
    const email = Email ?? (ContactPersons?.length ? ContactPersons[0].EmailAddress : undefined);

    return {
        id: Id,
        text: Name,
        currency: Currency ? { id: Currency.ISOCode, text: Currency.ISOCode } : undefined,
        address,
        email,
        taxId: TaxId,
    };
};
