import styled from 'styled-components';

export const Root = styled.div`
    flex: 1;
    overflow: hidden;

    & > * {
        height: 100%;
        width: 100%;
    }
`;
