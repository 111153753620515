import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionDearFraudChangesAfter', {
    title: 'Fraud detection — changes after approval',
    subtitle:
        'What changes made in Cin7 Core after the approval should trigger addition of mark "Changed after approval" and a notification to company Administrators?',
    optionSupplier: 'Supplier',
    optionAmount: 'Total amount (more than 0.1 {companyCurrencyCode} change)',
    optionDate: 'Date',
    optionLocation: 'Location',
    optionMemo: 'Memo',
    optionItemLine: 'Item Line',
    optionAdditionalChargeLine: 'Category',
});
