import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { UseFido2AssertionData } from './useFido2Assertion.types';

export const useFido2Assertion = (mutationOptions?: MutationOptions<any, RequestDataParams<UseFido2AssertionData>>) => {
    return useMutate<RequestDataParams<UseFido2AssertionData>>(authApiPaths.fido2Assertion, {
        apiVersion: 'v2',
        mutationOptions,
    });
};
