import addOnCaptureImg from '!!file-loader!./assets/AddOnCapture.png';
import addonPayImg from '!!file-loader!./assets/AddonPay.png';
import qboAdvancedBillToPOMatching from '!!file-loader!./assets/QboAdvancedBillToPOMatching.png';
import qboAdvancedGoodsReceivedNotes from '!!file-loader!./assets/QboAdvancedGoodsReceivedNotes.png';
import qboAdvancedWorkflowVersionHistory from '!!file-loader!./assets/QboAdvancedWorkflowVersionHistory.png';
import xeroAdvancedBillToPOMatching from '!!file-loader!./assets/XeroAdvancedBillToPOMatching.png';
import xeroAdvancedBudgetChecking from '!!file-loader!./assets/XeroAdvancedBudgetChecking.png';
import xeroAdvancedGoodsReceivedNotes from '!!file-loader!./assets/XeroAdvancedGoodsReceivedNotes.png';
import xeroAdvancedWorkflowVersionHistory from '!!file-loader!./assets/XeroAdvancedWorkflowVersionHistory.png';
import xeroPremiumBatchPaymentApprovals from '!!file-loader!./assets/XeroPremiumBatchPaymentApprovals.png';
import xeroPremiumPaymentsWithAirwallex from '!!file-loader!./assets/XeroPremiumPaymentsWithAirwallex.png';

import { TrialFeature } from '../../AllFeatureTrialPaywallPopup.types';
import { messages } from './FeatureDescription.messages';
import { FeatureDescription } from './FeatureDescription.types';

export const descriptions: Record<TrialFeature, FeatureDescription> = {
    [TrialFeature.XeroAdvancedBillToPOMatching]: {
        title: messages.xeroAdvancedBillToPOMatchingTitle,
        subtitle: messages.xeroAdvancedBillToPOMatchingSubtitle,
        list: [
            messages.xeroAdvancedBillToPOMatchingList1,
            messages.xeroAdvancedBillToPOMatchingList2,
            messages.xeroAdvancedBillToPOMatchingList3,
        ],
        image: xeroAdvancedBillToPOMatching,
    },
    [TrialFeature.XeroAdvancedBudgetChecking]: {
        title: messages.xeroAdvancedBudgetCheckingTitle,
        list: [
            messages.xeroAdvancedBudgetCheckingList1,
            messages.xeroAdvancedBudgetCheckingList2,
            messages.xeroAdvancedBudgetCheckingList3,
        ],
        image: xeroAdvancedBudgetChecking,
    },
    [TrialFeature.XeroPremiumBatchPaymentApprovals]: {
        title: messages.xeroPremiumBatchPaymentApprovalsTitle,
        list: [
            messages.xeroPremiumBatchPaymentApprovalsList1,
            messages.xeroPremiumBatchPaymentApprovalsList2,
            messages.xeroPremiumBatchPaymentApprovalsList3,
        ],
        image: xeroPremiumBatchPaymentApprovals,
    },
    [TrialFeature.XeroAdvancedContactApprovals]: {
        title: messages.xeroAdvancedContactApprovalsTitle,
        list: [
            messages.xeroAdvancedContactApprovalsList1,
            messages.xeroAdvancedContactApprovalsList2,
            messages.xeroAdvancedContactApprovalsList3,
        ],
    },
    [TrialFeature.XeroPremiumPaymentsWithAirwallex]: {
        title: messages.xeroPremiumPaymentsWithAirwallexTitle,
        list: [
            messages.xeroPremiumPaymentsWithAirwallexList1,
            messages.xeroPremiumPaymentsWithAirwallexList2,
            messages.xeroPremiumPaymentsWithAirwallexList3,
        ],
        endnote: messages.xeroPremiumPaymentsWithAirwallexEndnote,
        image: xeroPremiumPaymentsWithAirwallex,
    },
    [TrialFeature.XeroAdvancedAutoApprovals]: {
        title: messages.xeroAdvancedAutoApprovalsTitle,
        list: [messages.xeroAdvancedAutoApprovalsList1, messages.xeroAdvancedAutoApprovalsList2],
    },
    [TrialFeature.XeroAdvancedSalesQuoteApprovals]: {
        title: messages.xeroAdvancedSalesQuoteApprovalsTitle,
        list: [
            messages.xeroAdvancedSalesQuoteApprovalsList1,
            messages.xeroAdvancedSalesQuoteApprovalsList2,
            messages.xeroAdvancedSalesQuoteApprovalsList3,
        ],
    },
    [TrialFeature.XeroAdvancedManualJournalApprovals]: {
        title: messages.xeroAdvancedManualJournalApprovalsTitle,
        list: [messages.xeroAdvancedManualJournalApprovalsList1, messages.xeroAdvancedManualJournalApprovalsList2],
    },
    [TrialFeature.XeroPremiumStandAloneWorkflows]: {
        title: messages.xeroPremiumStandAloneWorkflowsTitle,
        subtitle: messages.xeroPremiumStandAloneWorkflowsSubtitle,
    },
    [TrialFeature.XeroPremiumPublicAPI]: {
        title: messages.xeroPremiumPublicAPITitle,
        subtitle: messages.xeroPremiumPublicAPISubtitle,
    },
    [TrialFeature.XeroAdvancedGoodsReceivedNotes]: {
        title: messages.xeroAdvancedGoodsReceivedNotesTitle,
        list: [
            messages.xeroAdvancedGoodsReceivedNotesList1,
            messages.xeroAdvancedGoodsReceivedNotesList2,
            messages.xeroAdvancedGoodsReceivedNotesList3,
        ],
        image: xeroAdvancedGoodsReceivedNotes,
    },
    [TrialFeature.XeroAdvancedWorkflowVersionHistory]: {
        title: messages.xeroAdvancedWorkflowVersionHistoryTitle,
        list: [
            messages.xeroAdvancedWorkflowVersionHistoryList1,
            messages.xeroAdvancedWorkflowVersionHistoryList2,
            messages.xeroAdvancedWorkflowVersionHistoryList3,
        ],
        image: xeroAdvancedWorkflowVersionHistory,
    },
    [TrialFeature.XeroAdvancedRequestReviewStep]: {
        title: messages.xeroAdvancedRequestReviewStepTitle,
        list: [messages.xeroAdvancedRequestReviewStepList1, messages.xeroAdvancedRequestReviewStepList2],
    },
    [TrialFeature.XeroPremiumPrioritySupport]: {
        title: messages.xeroPremiumPrioritySupportTitle,
        subtitle: messages.xeroPremiumPrioritySupportSubtitle,
    },
    [TrialFeature.QboAdvancedBillToPOMatching]: {
        title: messages.qboAdvancedBillToPOMatchingTitle,
        subtitle: messages.qboAdvancedBillToPOMatchingSubtitle,
        list: [
            messages.qboAdvancedBillToPOMatchingList1,
            messages.qboAdvancedBillToPOMatchingList2,
            messages.qboAdvancedBillToPOMatchingList3,
        ],
        image: qboAdvancedBillToPOMatching,
    },
    [TrialFeature.QboAdvancedVendorApprovals]: {
        title: messages.qboAdvancedVendorApprovalsTitle,
        list: [
            messages.qboAdvancedVendorApprovalsList1,
            messages.qboAdvancedVendorApprovalsList2,
            messages.qboAdvancedVendorApprovalsList3,
        ],
    },
    [TrialFeature.QboAdvancedAutoApprovals]: {
        title: messages.qboAdvancedAutoApprovalsTitle,
        list: [messages.qboAdvancedAutoApprovalsList1, messages.qboAdvancedAutoApprovalsList2],
    },
    [TrialFeature.QboAdvancedJournalEntryApprovals]: {
        title: messages.qboAdvancedJournalEntryApprovalsTitle,
        list: [messages.qboAdvancedJournalEntryApprovalsList1, messages.qboAdvancedJournalEntryApprovalsList2],
    },
    [TrialFeature.QboPremiumStandAloneWorkflows]: {
        title: messages.qboPremiumStandAloneWorkflowsTitle,
        subtitle: messages.qboPremiumStandAloneWorkflowsSubtitle,
    },
    [TrialFeature.QboAdvancedGoodsReceivedNotes]: {
        title: messages.qboAdvancedGoodsReceivedNotesTitle,
        list: [
            messages.qboAdvancedGoodsReceivedNotesList1,
            messages.qboAdvancedGoodsReceivedNotesList2,
            messages.qboAdvancedGoodsReceivedNotesList3,
        ],
        image: qboAdvancedGoodsReceivedNotes,
    },
    [TrialFeature.QboAdvancedWorkflowVersionHistory]: {
        title: messages.qboAdvancedWorkflowVersionHistoryTitle,
        list: [
            messages.qboAdvancedWorkflowVersionHistoryList1,
            messages.qboAdvancedWorkflowVersionHistoryList2,
            messages.qboAdvancedWorkflowVersionHistoryList3,
        ],
        image: qboAdvancedWorkflowVersionHistory,
    },
    [TrialFeature.QboAdvancedRequestReviewStep]: {
        title: messages.qboAdvancedRequestReviewStepTitle,
        list: [messages.qboAdvancedRequestReviewStepList1, messages.qboAdvancedRequestReviewStepList2],
    },
    [TrialFeature.QboPremiumPrioritySupport]: {
        title: messages.qboPremiumPrioritySupportTitle,
        subtitle: messages.qboPremiumPrioritySupportSubtitle,
    },
    [TrialFeature.AddonsCapture]: {
        title: messages.addOnsCaptureTitle,
        subtitle: messages.addOnsCaptureSubTitle,
        list: [messages.addOnCaptureList1, messages.addOnCaptureList2, messages.addOnCaptureList3],
        endnoteLarge: messages.addOnCaptureEndNote,
        image: addOnCaptureImg,
    },
    [TrialFeature.AddonsPay]: {
        title: messages.addOnsPayTitle,
        subtitle: messages.addOnsPaySubTitle,
        list: [
            messages.addOnPayList1,
            messages.addOnPayList2,
            messages.addOnPayList3,
            messages.addOnPayList4,
            messages.addOnPayList5,
        ],
        image: addonPayImg,
    },
    [TrialFeature.XeroAdvancedAccrualReports]: {
        title: messages.xeroAdvancedAccrualReportsTitle,
        list: [
            messages.xeroAdvancedAccrualReportsList1,
            messages.xeroAdvancedAccrualReportsList2,
            messages.xeroAdvancedAccrualReportsList3,
            messages.xeroAdvancedAccrualReportsList4,
        ],
    },
};
