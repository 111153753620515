import { Box, Button, Flex, Form, Popup, Radio, Spacing, Text } from '@approvalmax/ui/src/components';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useSelectableBills } from './AddBillInvoicesPopup.hooks';
import { messages } from './AddBillInvoicesPopup.messages';
import { AddBillInvoicesPopupProps, BillsListType } from './AddBillInvoicesPopup.types';
import { BillInvoiceFilter } from './components/BillInvoiceFilter/BillInvoiceFilter';
import { BillInvoiceTable } from './components/BillInvoiceTable/BillInvoiceTable';
import { BillInvoiceTableGroupedBySupplier } from './components/BillInvoiceTableGroupedBySupplier/BillInvoiceTableGroupedBySupplier';
import { EmptyState } from './components/EmptyState/EmptyState';

export const AddBillInvoicesPopup: FC<AddBillInvoicesPopupProps> = memo((props) => {
    const {
        billBatchPayment,
        companyId,
        requestId,
        handleAddBills,
        sortingConfig,
        changeSortingConfig,
        resetFilter,
        data,
        isLoading,
        attentionText,
        showTotalAmountOfSelectedBills,
    } = props;

    const formContext = useFormContext();

    const excludeBillsWithZeroAmount = formContext.watch('excludeBillsWithZeroAmount');
    const billsListType = formContext.watch('billsListType');

    const {
        selectableBills,
        setSelectableBills,
        selectedCount,
        updateSelectableBills,
        toggleAllBills,
        toggleBill,
        toggleBills,
        toggleAllBillsBySupplierId,
        totalAmountOfSelectedBillsFormatted,
    } = useSelectableBills({
        billBatchPayment,
        excludeBillsWithZeroAmount,
    });

    useEffect(() => {
        if (data) {
            updateSelectableBills(data);
        }
    }, [data, updateSelectableBills]);

    const addBillsToBatch = useCallback(() => {
        handleAddBills(selectableBills);
        setSelectableBills([]);
    }, [handleAddBills, selectableBills, setSelectableBills]);

    return (
        <>
            <Popup.Header
                title={messages.popupTitle}
                actions={
                    <Button size='medium' color='blue80' onClick={addBillsToBatch} disabled={!selectedCount}>
                        {messages.addButtonText}
                    </Button>
                }
                progress={isLoading}
            />

            <Popup.Body spacing='32'>
                <Spacing height={16} />

                <BillInvoiceFilter
                    companyId={companyId}
                    requestId={requestId}
                    resetFilter={resetFilter}
                    attentionText={attentionText}
                />

                <Spacing height={20} />

                <Flex inline justifyContent='space-between' alignItems='center'>
                    <Form.Part>
                        <Radio.Group name='billsListType'>
                            <Radio value={BillsListType.List}>{messages.list}</Radio>

                            <Radio value={BillsListType.GroupedBySupplier}>{messages.groupBySupplier}</Radio>
                        </Radio.Group>
                    </Form.Part>

                    {showTotalAmountOfSelectedBills ? (
                        <Flex inline justifyContent='space-between' spacing='4'>
                            <Text font='label'>{messages.totalAmountOfSelectedBills}</Text>

                            <Box width={140} textAlign='right'>
                                <Text font='label' fontWeight='bold'>
                                    {totalAmountOfSelectedBillsFormatted}
                                </Text>
                            </Box>
                        </Flex>
                    ) : null}
                </Flex>

                <Spacing height={20} />

                {billsListType === BillsListType.List && (
                    <BillInvoiceTable
                        selectableBills={selectableBills}
                        toggleBills={toggleBills}
                        sortingConfig={sortingConfig}
                        changeSortingConfig={changeSortingConfig}
                        companyId={companyId}
                    />
                )}

                {billsListType === BillsListType.GroupedBySupplier && (
                    <BillInvoiceTableGroupedBySupplier
                        companyId={companyId}
                        selectableBills={selectableBills}
                        toggleBill={toggleBill}
                        toggleAllBills={toggleAllBills}
                        toggleAllBillsBySupplierId={toggleAllBillsBySupplierId}
                    />
                )}

                <EmptyState billRequestsLength={selectableBills.length} isLoading={isLoading} />
            </Popup.Body>
        </>
    );
});

AddBillInvoicesPopup.displayName = 'AddBillInvoicesPopup';
