import * as companyModule from 'modules/company';
import { domain } from 'modules/data';
import { integrationActions, IntegrationSuccessRedirectPage } from 'modules/integration';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { amplitudeService } from 'services/amplitude';
import { routingService } from 'services/routing';
import { getPath, Path } from 'urlBuilder';

export const useSelectIntegrationType = () => {
    const [integrationType, setIntegrationType] = useState<domain.IntegrationType>(domain.IntegrationType.Xero);

    const onSelectXero = useCallback(() => {
        setIntegrationType(domain.IntegrationType.Xero);
    }, []);

    const onSelectQbooks = useCallback(() => {
        setIntegrationType(domain.IntegrationType.QBooks);
    }, []);

    const onSelectNetSuite = useCallback(() => {
        setIntegrationType(domain.IntegrationType.NetSuite);
    }, []);

    const onSelectDear = useCallback(() => {
        setIntegrationType(domain.IntegrationType.Dear);
    }, []);

    const onSelectStandAlone = useCallback(() => {
        setIntegrationType(domain.IntegrationType.None);
    }, []);

    return {
        integrationType,
        onSelectXero,
        onSelectQbooks,
        onSelectNetSuite,
        onSelectDear,
        onSelectStandAlone,
    };
};

export const useNextStep = (onPopupClose: () => void) => {
    const dispatch = useDispatch();
    const createStandaloneOrganisation = useCreateStandaloneOrganisation();
    const createNetSuiteOrganisation = useCreateNetSuiteOrganisation(onPopupClose);
    const createDearOrganisation = useCreateDearOrganisation(onPopupClose);

    return useCallback(
        (integrationType: domain.IntegrationType) => {
            amplitudeService.sendData('signup: confirm connection type', {
                'connection type': integrationType.toLocaleLowerCase(),
            });

            switch (integrationType) {
                case domain.IntegrationType.None:
                    createStandaloneOrganisation();
                    break;

                case domain.IntegrationType.NetSuite:
                    createNetSuiteOrganisation();
                    break;

                case domain.IntegrationType.Dear:
                    createDearOrganisation();
                    break;

                default:
                    dispatch(
                        integrationActions.connectToIntegration({
                            integrationType,
                            companyId: null,
                            finalRedirectToPage: IntegrationSuccessRedirectPage.Workflow,
                            showGlobalProgress: false,
                        })
                    );
                    break;
            }
        },
        [createDearOrganisation, createNetSuiteOrganisation, createStandaloneOrganisation, dispatch]
    );
};

const useCreateNetSuiteOrganisation = (onPopupClose: () => void) => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(integrationActions.showNetSuiteIntegrationPopup(null, IntegrationSuccessRedirectPage.Workflow));

        onPopupClose();
    }, [dispatch, onPopupClose]);
};

const useCreateDearOrganisation = (onPopupClose: () => void) => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(integrationActions.showDearIntegrationPopup(null, IntegrationSuccessRedirectPage.Workflow));

        onPopupClose();
    }, [dispatch, onPopupClose]);
};

const useCreateStandaloneOrganisation = () => {
    const dispatch = useDispatch();

    return useCallback(async () => {
        const companyCreatedInfo = await dispatch(companyModule.createOrganisation(null));

        if (companyCreatedInfo) {
            amplitudeService.sendData('signup: created org');
            routingService.push(getPath(Path.companyWorkflows, companyCreatedInfo.CompanyId));

            await dispatch(companyModule.loadFetchActionsForNewCompany({ companyId: companyCreatedInfo.CompanyId }));
        }
    }, [dispatch]);
};
