import { AnyAction } from '../types/Action';

/**
 * @param reducers Object map of reducer functions. The new page state is passed as the third parameter,
 * be aware though that it's half-constructed and it's up to you not to use missing values.
 * @param resetActionType Action type that should reload the page data. Normally it's `LOAD_PAGE_DATA`.
 * @example createPageReducer({ activeModule, requests }, LOAD_PAGE_DATA)
 */
export function createPageReducer<
    S,
    A extends AnyAction = AnyAction,
    T extends {
        [reducerName in keyof S]: (state: any, action: A, page: S, prevPage: S | undefined) => any;
    } = {
        [reducerName in keyof S]: (state: any, action: A, page: S, prevPage: S | undefined) => any;
    },
    K = { [P in keyof T]: ReturnType<T[P]> },
>(reducers: T, resetActionType: string): (state: any, action: A) => Readonly<K> {
    // Reducers which don't depend on the state/prevState come first
    let reducerList = (Object.entries(reducers) as Array<[keyof T, T[keyof T]]>).sort(
        (a, b) => a[1].length - b[1].length
    );

    return (state: any, action: A) => {
        const prevState = state;

        if (!state || action.type === resetActionType) {
            // Initial value is set when state is undefined OR when resetActionType is fired
            state = {};
        }

        return reducerList.reduce((m, p) => {
            const [k, v] = p;

            m[k] = v(state[k], action, m, prevState);

            return m;
        }, {} as any) as any;
    };
}
