import { selectors } from 'modules/common';
import { domain } from 'modules/data';

import { xeroWorkflowsWithReviewStep } from './WarningList.constants';
import { messages } from './WarningList.messages';

const getQBooksWarnings = (
    fromWorkflow: selectors.types.ExpandedTemplate,
    toWorkflow: selectors.types.ExpandedTemplate
): string[] => {
    if (fromWorkflow.companyId === toWorkflow.companyId) {
        return [messages.reviewerStepWarning];
    }

    const QBooksWarningList: string[] = [];

    if (fromWorkflow.companyId !== toWorkflow.companyId) {
        QBooksWarningList.push(messages.QBooksDifferentValues, messages.reviewerStepWarning);
    }

    return QBooksWarningList;
};

const getXeroWarnings = (
    fromWorkflow: selectors.types.ExpandedTemplate,
    toWorkflow: selectors.types.ExpandedTemplate
): string[] => {
    const combinedIntegrationCode = `${fromWorkflow.integrationCode}/${toWorkflow.integrationCode}`;

    const warningList: string[] = [];

    if (fromWorkflow.companyId === toWorkflow.companyId) {
        switch (combinedIntegrationCode) {
            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroPo}`:
                warningList.push(messages.xeroDextRequesterWarning, messages.xeroNoDefaultSubmitterWarning);
                break;

            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
                warningList.push(
                    messages.xeroDextRequesterWarning,
                    messages.xeroCreateBillRequestersWarning,
                    messages.xeroMatrixRulesWarning,
                    messages.xeroNoDefaultSubmitterWarning
                );
                break;

            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroBill}`:
                warningList.push(messages.xeroNoDefaultSubmitterWarning, messages.xeroNoCreateBillRequestersWarning);
                break;

            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroPo}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroPo}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroPo}`:
                warningList.push(messages.xeroNoDefaultSubmitterWarning, messages.xeroNoCreatePORequestersWarning);
                break;

            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
                warningList.push(messages.xeroNoDefaultSubmitterWarning);
                break;

            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
                warningList.push(
                    messages.xeroCreatePORequestersWarning,
                    messages.xeroMatrixRulesWarning,
                    messages.xeroNoDefaultSubmitterWarning
                );
                break;
        }
    }

    if (fromWorkflow.companyId !== toWorkflow.companyId) {
        switch (combinedIntegrationCode) {
            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroPo}`:
                warningList.push(
                    messages.differentValuesInTargetOrgWarning,
                    messages.xeroDextRequesterWarning,
                    messages.xeroNoDefaultSubmitterWarning
                );
                break;

            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
                warningList.push(
                    messages.differentValuesInTargetOrgWarning,
                    messages.xeroDextRequesterWarning,
                    messages.xeroCreateBillRequestersWarning,
                    messages.xeroMatrixRulesWarning,
                    messages.xeroNoDefaultSubmitterWarning
                );
                break;

            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroBill}`:
                warningList.push(
                    messages.differentValuesInTargetOrgWarning,
                    messages.xeroNoDefaultSubmitterWarning,
                    messages.xeroNoCreateBillRequestersWarning
                );
                break;

            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroPo}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroPo}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroPo}`:
                warningList.push(
                    messages.differentValuesInTargetOrgWarning,
                    messages.xeroNoDefaultSubmitterWarning,
                    messages.xeroNoCreatePORequestersWarning
                );
                break;

            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
                warningList.push(messages.differentValuesInTargetOrgWarning, messages.xeroNoDefaultSubmitterWarning);
                break;

            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
                warningList.push(
                    messages.differentValuesInTargetOrgWarning,
                    messages.xeroCreatePORequestersWarning,
                    messages.xeroMatrixRulesWarning,
                    messages.xeroNoDefaultSubmitterWarning
                );
                break;

            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroPo}`:
                warningList.push(messages.differentValuesInTargetOrgWarning);
                break;
        }
    }

    if (fromWorkflow.integrationCode && xeroWorkflowsWithReviewStep.includes(fromWorkflow.integrationCode)) {
        warningList.push(messages.reviewerStepWarning);
    }

    return warningList;
};

export const getWarnings = (
    fromWorkflow: selectors.types.ExpandedTemplate | null,
    toWorkflow: selectors.types.ExpandedTemplate | null
): string[] => {
    if (!fromWorkflow || !toWorkflow) {
        return [];
    }

    if (!fromWorkflow.integrationCode && !toWorkflow.integrationCode) {
        return [];
    }

    const warningList: string[] = [];

    if (fromWorkflow.integrationCode !== toWorkflow.integrationCode) {
        warningList.push(messages.applicableWorkflowSettingsCopyWarning);
    }

    if (fromWorkflow.integrationCode?.startsWith('QBooks')) {
        return [...warningList, ...getQBooksWarnings(fromWorkflow, toWorkflow)];
    }

    if (fromWorkflow.integrationCode?.startsWith('Xero')) {
        return [...warningList, ...getXeroWarnings(fromWorkflow, toWorkflow)];
    }

    return warningList;
};
