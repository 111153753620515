import { DeepNullablePartial } from '@approvalmax/types';
import { validators } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { useMutation } from '@tanstack/react-query';
import { selectors } from 'modules/common';
import { backend, domain } from 'modules/data';
import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { useDispatch } from 'react-redux';
import { api } from 'services/api';
import { notificationService } from 'services/notification';

import { applySavedProfileChanges, cancelActivePopup, changeProfilePopupModified } from '../../actions';

const i18nPrefix = 'profile/containers/ProfilePopup/hooks';
const messages = defineMessages({
    mandatoryFirstNameErrorText: {
        id: `${i18nPrefix}.mandatoryFirstNameErrorText`,
        defaultMessage: 'First name cannot be empty.',
    },
    exceededFirstNameErrorText: {
        id: `${i18nPrefix}.exceededFirstNameErrorText`,
        defaultMessage: 'First name cannot be longer than 500 characters.',
    },
    mandatoryLastNameErrorText: {
        id: `${i18nPrefix}.mandatoryLastNameErrorText`,
        defaultMessage: 'Last name cannot be empty.',
    },
    exceededLastNameErrorText: {
        id: `${i18nPrefix}.exceededLastNameErrorText`,
        defaultMessage: 'Last name cannot be longer than 500 characters.',
    },
    mandatoryPhoneErrorText: {
        id: `${i18nPrefix}.mandatoryPhoneErrorText`,
        defaultMessage: 'Phone number cannot be empty.',
    },
    notValidPhoneErrorText: {
        id: `${i18nPrefix}.notValidPhoneErrorText`,
        defaultMessage: 'Phone number must contain 11 digits.',
    },
    mandatoryTimeZoneErrorText: {
        id: `${i18nPrefix}.mandatoryTimeZoneErrorText`,
        defaultMessage: 'Time zone cannot be empty.',
    },
    savedMessage: {
        id: `${i18nPrefix}.savedMessage`,
        defaultMessage: 'Your profile was saved.',
    },
});

const getProfileTransfer = (
    profile: selectors.types.ExpandedProfile
): DeepNullablePartial<backend.transfers.ProfileEditTransfer> => {
    return {
        firstName: profile.firstName?.trim() || '',
        lastName: profile.lastName?.trim() || '',
        phone: profile.phone,
        timeZoneForEmail: profile.timeZoneForEmail,
        sendApprovalChangeNotifications: profile.sendApprovalChangeNotifications,
        sendCommentsNotifications: profile.sendCommentsNotifications,
        sendWorkflowNotifications: profile.sendWorkflowNotifications,
        sendNewRequestsNotifications: profile.sendNewRequestsNotifications,
        sendSummaryNotifications: profile.sendSummaryNotifications,
        summaryNotificationsSentOnDays: profile.summaryNotificationsSendOnDays,
        summaryNotificationsSentAtTime: profile.summaryNotificationsSendAtTime,
        useSession: profile.useSession,
    };
};

const isValidProfile = (profile: selectors.types.ExpandedProfile): string[] => {
    const isMandatoryPhone = profile.account?.userRole === domain.AccountMemberRole.Administrator;

    const errors: string[] = [];

    if (!profile.firstName || !profile.firstName?.trim()) {
        errors.push(intl.formatMessage(messages.mandatoryFirstNameErrorText));
    }

    if (profile.firstName && profile.firstName.length > 500) {
        errors.push(intl.formatMessage(messages.exceededFirstNameErrorText));
    }

    if (!profile.lastName) {
        errors.push(intl.formatMessage(messages.mandatoryLastNameErrorText));
    }

    if (profile.lastName && profile.lastName.length > 500) {
        errors.push(intl.formatMessage(messages.exceededLastNameErrorText));
    }

    if (isMandatoryPhone && !profile.phone) {
        errors.push(intl.formatMessage(messages.mandatoryPhoneErrorText));
    }

    if (profile.phone && !validators.phone(profile.phone)) {
        errors.push(intl.formatMessage(messages.notValidPhoneErrorText));
    }

    if (!profile.timeZoneForEmail) {
        errors.push(intl.formatMessage(messages.mandatoryTimeZoneErrorText));
    }

    return errors;
};

export const useSaveAndSubmitRequestMutation = (profile: selectors.types.ExpandedProfile) => {
    const dispatch = useDispatch();
    const { mutate, isLoading } = useMutation(
        async (transfer: DeepNullablePartial<backend.transfers.ProfileEditTransfer>) => {
            await api.profile.edit(transfer);
            dispatch(cancelActivePopup());
            dispatch(applySavedProfileChanges({ newProfile: profile }));
            dispatch(changeProfilePopupModified(false));
            notificationService.showInfoToast(intl.formatMessage(messages.savedMessage));
        }
    );

    const saveAndSubmitProfile = useCallback(() => {
        const errors = isValidProfile(profile);

        if (errors.length > 0) {
            errors.forEach((error) => {
                notificationService.showErrorToast(error);
            });

            return;
        }

        const transfer = getProfileTransfer(profile);

        mutate(transfer);
    }, [mutate, profile]);

    return { saveAndSubmitProfile, isLoading };
};
