import {
    Action,
    ADD_USERS,
    ADD_USERS_FAILURE,
    ADD_USERS_RESPONSE,
    INVITE_USERS_FAILURE,
    INVITE_USERS_RESPONSE,
} from '../../actions';

const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action: Action): boolean => {
    switch (action.type) {
        case ADD_USERS:
            return true;

        case INVITE_USERS_RESPONSE:
        case INVITE_USERS_FAILURE:
        case ADD_USERS_RESPONSE:
        case ADD_USERS_FAILURE:
            return false;

        default:
            return state;
    }
};
