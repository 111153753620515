import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.MatrixPopupContent.FirstUserCell', {
    addAlternativeRule: 'Add an alternative rule',
    addAlternativeRuleTitle: 'Add another rule block for Approvers which will be treated as an "or" condition.',
    removeRule: 'Remove a rule',
    removeRuleTitle: 'Remove this rule block for Approvers.',
    copyRuleToUser: 'Copy rule to user',
    copyRuleToUserTitle: 'Copy rule to user',
});
