import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.MigrationFromReviewerV1PopupContent', {
    title: 'Editing on Approval is now available!',
    description:
        'As you are currently using our old Reviewer in the Bill workflow, you cannot use the new enhanced Editing on Approval. If you wish to use Editing on Approval, the old Reviewer will become unavailable.',
    learnMore: 'Learn more',
    role: 'Role',
    migrationResults: 'Migration results',
    approverAndReviewer: '<b>Approver AND Reviewer</b>',
    onlyReviewerAndNotApprover: 'Only <b>Reviewer and NOT Approver</b>',
    approverAndReviewerMigrationResult1:
        'Can edit <b>Description, Quantity, Unit Price, Tax Rate, Tax Amount and Attachment</b>',
    approverAndReviewerMigrationResult2:
        'Can edit <b>Item, Accounts and Tracking categories if those are not marked as Read-only</b>',
    approverAndReviewerMigrationResult3:
        'Can set values <b>with same restrictions as Old Reviewer step for Item, Accounts and Tracking categories</b>',
    onlyReviewerAndNotApproverMigrationResult: 'No editing permissions',
    noReviewersDescription: 'No Reviewers are set, no migration is required.',
    warning:
        'The migration is not reversible, once you migrate to Editing On Approval, you cannot go back to Old Reviewers.',
    countReviewersDescription: '<b>{count}</b> Reviewer(s) will have their data migrated to Editing On Approval.',
    doYouWantToReplaceOldReviewerByEditingOnApproval: 'Do you want to replace old Reviewer by Editing on Approval?',
    yes: 'Let’s migrate now',
    no: 'No, I don’t want to migrate',
});
