import { Box, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { HeaderTitleProps } from './HeaderTitle.types';

export const HeaderTitle: FC<HeaderTitleProps> = memo((props) => {
    const { title, subtitle, subtitle2, spacing } = props;

    return (
        <Box spacing={spacing}>
            <Text font='headline' fontSize='small' fontWeight='medium' color='midnight80'>
                {title}
            </Text>

            {subtitle && (
                <Text spacing='8 0 0' font='body' fontSize='large' color='midnight70'>
                    {subtitle}
                </Text>
            )}

            {subtitle2 && (
                <Text font='body' fontSize='large' color='midnight70'>
                    {subtitle2}
                </Text>
            )}
        </Box>
    );
});

HeaderTitle.displayName = 'HeaderTitle';
