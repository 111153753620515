import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    width: 500px;
    padding: 40px 60px 50px;
    background-color: #fff;
    display: flex;
    flex-flow: column;
    gap: 15px;
`;

export const Header = styled.div`
    ${font(16, '#000', 'semibold')};
    line-height: 1.38;
`;

export const Descriprtion = styled.div`
    ${font(13, '#000', 'standard')};
    line-height: 1.38;
`;
