export * from './airwallexSchema';
export { default as companyInvitationSchema } from './companyInvitationSchema';
export { companySchema, companySchemaLegacy } from './companySchema';
export { default as fieldSchema } from './fieldSchema';
export { default as integrationSchema, mapExtendedIntegration, mapIntegrationSyncProgress } from './integrationSchema';
export { mapPracticeStaffInvitation, default as practiceStaffInvitationSchema } from './practiceStaffInvitationSchema';
export { mapProfileAccount, default as profileSchema } from './profileSchema';
export { mapMentionedUser, default as requestSchema } from './requestSchema';
export { mapAccountLineItem, mapLineItem } from './requestSchema.QBooks';
export { default as subscriptionSchema } from './subscriptionSchema';
export { default as templateSchema } from './templateSchema';
export { mapUser, default as userSchema } from './userSchema';
export { mapReference, mapReferenceWithValue } from './utils';

import {
    extractUserStatusFromAnswerArray,
    mapCompanyBillMatchingSettings,
    mapCompanyBillMatchingSettingsBack,
} from './companySchema';
import { mapField, mapFieldSystemPurpose, mapNetSuiteField } from './fieldSchema';
import { mapAttachment, mapPossibleDuplicateItem } from './requestSchema';
import { mapQboBillV2, mapQboExpenseV2, mapQboPurchaseOrderV2 } from './requestSchema.QBooks';
import {
    mapAirwallexBatchPaymentDetails,
    mapCreditNoteV2,
    mapLineItem,
    mapXeroBillBatchPaymentDetailsV2,
    mapXeroBillDetailsV2,
    mapXeroContactDetails,
    mapXeroInvoiceV2,
    mapXeroManualJournalDetailsV2,
    mapXeroPurchaseOrderV2,
    mapXeroQuoteDetailsV2,
} from './requestSchema.Xero';
import {
    mapDocumentFields,
    mapMatrixCondition,
    mapQBooksEnabledLineItemType,
    mapQBooksEnabledLineItemTypeBack,
    mapTemplate,
    mapTemplateSettings,
    mapTemplateSettingsBack,
    mapWorkflowVersionAnswer,
} from './templateSchema';

export const company = {
    extractUserStatusFromAnswerArray,
    mapCompanyBillMatchingSettings,
    mapCompanyBillMatchingSettingsBack,
};

export const template = {
    mapDocumentFields,
    mapTemplate,
    mapTemplateSettings,
    mapTemplateSettingsBack,
    mapWorkflowVersionAnswer,
    mapMatrixCondition,
    mapQBooksEnabledLineItemType: mapQBooksEnabledLineItemType,
    mapQBooksEnabledLineItemTypeBack: mapQBooksEnabledLineItemTypeBack,
};

export const field = {
    mapFieldSystemPurpose,
    mapField,
    mapNetSuiteField,
};

export const request = {
    mapAttachment,
    mapPossibleDuplicateItem,
    mapLineItem,
    mapXeroBillDetailsV2,
    mapXeroPurchaseOrderV2,
    mapXeroInvoiceV2,
    mapCreditNoteV2,
    mapXeroBillBatchPaymentDetailsV2,
    mapXeroContactDetails,
    mapAirwallexBatchPaymentDetails,
    mapXeroQuoteDetailsV2,
    mapXeroManualJournalDetailsV2,
    mapQboPurchaseOrderV2,
    mapQboBillV2,
    mapQboExpenseV2,
};
