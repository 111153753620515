import styled from 'styled-components';

import { font, oPopupPadding } from '../../styles';
import Popup from '../popup/Popup';

export const Root = styled(Popup.DefaultContent)`
    width: 500px;
`;

export const Content = styled.div`
    ${oPopupPadding()}
    display: flex;
    flex-flow: column;
    gap: 20px;
`;

export const Title = styled.div`
    white-space: normal;
`;

export const Description = styled.div`
    ${font(13, '#000', 'standard')};
    line-height: 1.38;
`;
