import { immutable } from 'modules/immutable';

import { Action } from '../actions';
import loadingReducer from './page/loadingReducer';
import moduleReducer, { ActiveModule, LoginModuleData, PageActiveModule } from './page/moduleReducer';

export interface Page {
    module: ActiveModule;
    loading: boolean;
}

const initialState = immutable<Page>({
    module: immutable<LoginModuleData>({
        type: PageActiveModule.LoginModule,
    }),
    loading: false,
});

export default function (state: Page = initialState, action: Action): Page {
    return {
        module: moduleReducer(state.module, action),
        loading: loadingReducer(state.loading, action),
    };
}
