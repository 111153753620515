import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.selectors.templateSelectors', {
    submitterRulesPreviewFieldNotValuePattern: '"{value}"',
    submitterRulesPreviewFieldValuePattern: '"{value}"',
    submitterRulesPreviewNotFieldPattern: '{fieldName} that does not match {values}',
    submitterRulesPreviewFieldPattern: '{fieldName} that matches {values}',
    submitterRulesPreviewSubmitsWith: 'can submit with {rules}',
    supplierFieldAnyContactText: 'any existing Contact',
    submitterRulesPreviewAlwaysSubmit: 'without any restrictions',
    supplierFieldAnyContactOrNewText: 'any existing or new Contact',
    supplierFieldAnySupplierText: 'any Contact marked as Supplier',
    customerFieldAnyCustomerText: 'any Contact marked as Customer',
    submitterRulesPreviewAndText: '{left} and {right}',
    submitterRulesPreviewOrText: '{left} or {right}',
    vendorFieldAnyText: 'any existing Vendor',
    vendorFieldAnyOrNewText: 'any existing or new Vendor',
    beneficiaryFieldAnyText: 'any existing Beneficiary',
    beneficiaryFieldAnyOrNewText: 'any existing or new Beneficiary',
});
