import { useCallback, useId } from 'react';
import { useUnmount } from 'react-use';
import { useSetRecoilState } from 'recoil';

import { confirmationManagerState } from './ConfirmationManager.states';
import type { ConfirmationParams } from './ConfirmationManager.types';

export const useConfirmation = (props: ConfirmationParams = {}) => {
    const key = useId();
    const setConfirmations = useSetRecoilState(confirmationManagerState);

    useUnmount(() => {
        setConfirmations((prevState) => (prevState ?? []).filter((confirmation) => confirmation.key !== key));
    });

    const confirmation = useCallback(
        (params: ConfirmationParams = {}) => {
            return new Promise<void>((resolve, reject) => {
                setConfirmations((prevState) => {
                    if (prevState.some((confirmationState) => confirmationState.open)) {
                        return prevState;
                    }

                    const newConfirmation = {
                        key,
                        open: true,
                        payload: {
                            resolve,
                            reject,
                            params: { ...props, ...params },
                        },
                    };

                    // keep only one record for the one instance of `useConfirmation`.
                    // Also, it is important to override resolved promises with a fresh ones
                    if (prevState.some((confirmationState) => confirmationState.key === key)) {
                        return [
                            ...prevState.filter((confirmationState) => confirmationState.key !== key),
                            newConfirmation,
                        ];
                    }

                    return [...prevState, newConfirmation];
                });
            });
        },
        [key, props, setConfirmations]
    );

    return { confirmation };
};
