import { AlertIcon } from '@approvalmax/ui';
import { Alert, Button, Flex, Link, Popup, Table, Text } from '@approvalmax/ui/src/components';
import { integrationActions } from 'modules/integration';
import { useDispatch } from 'modules/react-redux';
import { FC, memo, ReactNode, useCallback, useState } from 'react';
import { useMount } from 'react-use';

import { columnsDefinition, tableData } from './MigrationFromReviewerV1PopupContent.constants';
import { useMigrate } from './MigrationFromReviewerV1PopupContent.hooks';
import { messages } from './MigrationFromReviewerV1PopupContent.messages';
import { MigrationFromReviewerV1PopupContentProps } from './MigrationFromReviewerV1PopupContent.types';

export const MigrationFromReviewerV1PopupContent: FC<MigrationFromReviewerV1PopupContentProps> = memo((props) => {
    const { onClose, onDone, template } = props;

    const dispatch = useDispatch();
    const [isFetchingFields, setIsFetchingFields] = useState(true);
    const { migrate, reviewersToMigrate } = useMigrate(template);

    useMount(() => {
        dispatch(
            integrationActions.loadAllCompanyFields({
                companyId: template.companyId,
                callback: () => setIsFetchingFields(false),
            })
        );
    });

    const handleMigrate = useCallback(async () => {
        try {
            await migrate();
            onDone();
        } catch {
            onClose();
        }
    }, [migrate, onDone, onClose]);

    return (
        <>
            <Popup.Header title={messages.title} />

            <Popup.Body>
                <Flex inline spacing='40' wrap={false} direction='column'>
                    {reviewersToMigrate.length === 0 ? (
                        <Flex inline spacing='16' wrap={false} direction='column'>
                            <Text font='body' fontSize='large'>
                                {messages.noReviewersDescription}
                            </Text>

                            <Alert size='small' invalid color='midnight20' startIcon={<AlertIcon />}>
                                {messages.warning}
                            </Alert>

                            <Text font='headline' fontSize='xxsmall' fontWeight='medium'>
                                {messages.doYouWantToReplaceOldReviewerByEditingOnApproval}
                            </Text>
                        </Flex>
                    ) : (
                        <>
                            <Flex inline spacing='16' wrap={false} direction='column'>
                                <div>
                                    <Text font='body' fontSize='medium'>
                                        {messages.description}
                                    </Text>

                                    <Link
                                        font='label'
                                        fontSize='medium'
                                        href='https://support.approvalmax.com/portal/en/kb/articles/what-is-happening-with-the-review-functionality'
                                        external
                                    >
                                        {messages.learnMore}
                                    </Link>
                                </div>

                                <Text font='label' fontSize='medium'>
                                    {messages.countReviewersDescription({
                                        count: reviewersToMigrate.length,
                                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                                    })}
                                </Text>

                                <Table width='100%' columns={columnsDefinition} data={tableData} />
                            </Flex>

                            <Alert size='small' invalid color='midnight20' startIcon={<AlertIcon />}>
                                {messages.warning}
                            </Alert>

                            <Text font='headline' fontSize='xxsmall' fontWeight='medium'>
                                {messages.doYouWantToReplaceOldReviewerByEditingOnApproval}
                            </Text>
                        </>
                    )}

                    <Flex inline spacing='24' wrap={false}>
                        <Button
                            color='midnight40'
                            size='large'
                            onClick={onClose}
                            disabled={isFetchingFields}
                            wrap={false}
                        >
                            {messages.no}
                        </Button>

                        <Button color='green80' size='large' block onClick={handleMigrate} disabled={isFetchingFields}>
                            {messages.yes}
                        </Button>
                    </Flex>
                </Flex>
            </Popup.Body>
        </>
    );
});

MigrationFromReviewerV1PopupContent.displayName = 'MigrationFromReviewerV1PopupContent';
