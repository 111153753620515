import { useMemo } from 'react';

import { BackupCode } from './BackupCodes.types';

export const useBackupCodesPrepare = (backupCodes: string[] | BackupCode[] = []) => {
    return useMemo(() => {
        return backupCodes.map((backupCode) => {
            return typeof backupCode === 'string'
                ? {
                      code: backupCode,
                      isUsed: false,
                  }
                : backupCode;
        });
    }, [backupCodes]);
};
