import { factories } from 'modules/data';
import { FC, Fragment, memo, useMemo } from 'react';

import { MatrixLineCellsContainer } from '../MatrixLineContainer/MatrixLineCellsContainer';
import { RuleNameCell } from '../RuleNameCell/RuleNameCell';
import { MatrixLineAutoApprovalProps } from './MatrixLineAutoApproval.types';

export const MatrixLineAutoApproval: FC<MatrixLineAutoApprovalProps> = memo((props) => {
    const {
        definition,
        cellFields,
        line,
        lineIndex,
        ruleNames,
        integrationCode,
        matrixType,
        readonly,
        company,
        createOnConditionChange,
        templateSubmitters,
        onCreateFieldOption,
        requiredFieldIds,
        highlight,
    } = props;

    const cellsData = useMemo(() => {
        return definition.columns.flatMap((colDef) => ({
            fields: cellFields.filter((field) => field.systemPurpose === colDef.systemPurpose),
            colDef,
        }));
    }, [cellFields, definition.columns]);

    return (
        <td>
            {line.rules.map((rule, ruleIndex) => (
                <MatrixLineCellsContainer
                    highlight={highlight}
                    divider={ruleIndex === line.rules.length - 1}
                    key={ruleIndex}
                >
                    <RuleNameCell
                        readonly={readonly}
                        ruleName={line.lineId}
                        ruleIndex={lineIndex}
                        ruleNames={ruleNames}
                    />

                    {cellsData.map(({ fields, colDef }) =>
                        fields.map((field) => (
                            <Fragment key={field.id}>
                                {colDef.renderCell({
                                    field,
                                    integrationCode,
                                    matrixType,
                                    readonly,
                                    company,
                                    condition:
                                        rule.conditions.find((c) => c.fieldId === field.id) ||
                                        factories.condition.createCondition(field),
                                    rule,
                                    lineId: line.lineId,
                                    templateSubmitters,
                                    onConditionChange: createOnConditionChange(ruleIndex),
                                    onCreateFieldOption,
                                    requiredFieldIds,
                                })}
                            </Fragment>
                        ))
                    )}
                </MatrixLineCellsContainer>
            ))}
        </td>
    );
});

MatrixLineAutoApproval.displayName = 'MatrixLineAutoApproval';
