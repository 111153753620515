export const budgetsApiPaths = {
    select: 'budgets/:integrationType/select',
    toggleBudgetsSync: 'budgets/:integrationType/toggleBudgetsSync',
    syncBudgets: 'budgets/:integrationType/syncBudgets',
    syncBudget: 'budgets/:integrationType/syncBudget',
    setCheckPeriod: 'budgets/:integrationType/setCheckPeriod',
    toggleBudget: 'budgets/:integrationType/toggleBudget',
    setZeroAmountHandlingType: 'budgets/:integrationType/setZeroAmountHandlingType',
    editXeroBudget: 'budgets/xero/edit',
    createXeroBudget: 'budgets/xero/create',
    deleteXeroBudget: 'budgets/xero/delete',
    setReviewedXeroBudgetTracking: 'budgets/xero/setReviewedBudgetTracking',
    uploadXeroBudget: 'budgets/xero/uploadBudget',
    downloadXeroBudgetSourceFile: 'budgets/xero/downloadBudgetSourceFile',
    getBudgetImpacts: 'budgets/getBudgetImpacts',
    getBudgetSettings: 'companies/getBudgetSettings',
    setBudgetSettings: 'companies/setBudgetSettings',
    getQBooksBudgets: 'companies/:companyId/qbooks/budgets',
    qBooksBudgetsSync: 'companies/:companyId/qbooks/budgets/sync',
    qBooksBudget: 'companies/:companyId/qbooks/budgets/:budgetId',
    qBooksBudgetSync: 'companies/:companyId/qbooks/budgets/:budgetId/sync',
    qBooksBudgetsImpact: 'companies/:companyId/qbooks/requests/:requestId/budgets',
};
