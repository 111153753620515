import { CompanyPlans } from '@approvalmax/ui/src/components/complex';
import { domain } from 'modules/data';

export const maxReRequestsWaitActivate = 20;
export const checkActivateTrialInterval = 3000;

export const plansMapper: Partial<Record<domain.CompanyPlan, CompanyPlans>> = {
    [domain.CompanyPlan.Xero]: 'standard',
    [domain.CompanyPlan.XeroAdvanced]: 'advanced',
    [domain.CompanyPlan.XeroPremium]: 'premium',

    [domain.CompanyPlan.QBooks]: 'standard',
    [domain.CompanyPlan.QBooksAdvanced]: 'advanced',
    [domain.CompanyPlan.QBooksPremium]: 'premium',
};
