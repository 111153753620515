import { Guid } from '@approvalmax/types';
import { defineMessages, errorHelpers, fileHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, stateTree } from 'modules/data';
import moment, { Moment } from 'moment';
import createCachedSelector, { LruObjectCache } from 're-reselect';

import { getCompanyById, getCompanyUserById } from './companySelectors';
import { getIntegrationType, getIntegrationTypeName } from './integrationSelectors';
import { getProfileUser } from './profileSelectors';
import { getDearCommands } from './requestSelectors.Dear';
import { getNetSuiteCommands } from './requestSelectors.NetSuite';
import { getQBooksCommands } from './requestSelectors.QBooks';
import { getStandaloneCommands } from './requestSelectors.Standalone';
import { getXeroCommands } from './requestSelectors.Xero';
import { getTemplatesByCompanyId } from './templateSelectors';
import {
    BaseExpandedRequest,
    DueDateWarning,
    ExpandedRequest,
    ExpandedRequestAttachment,
    FileType,
} from './types/Request';

export { getQBooksMatchingCommands, getQbooksMatchingValidationError } from './requestSelectors.QBooks';
export { getXeroMatchingCommands } from './requestSelectors.Xero';

const messages = defineMessages('common.selectors.requestSelectors', {
    fraudExternalResolvingApprovedText:
        'Please note: this request has been approved directly in ' +
        '{integrationName} without going through the ApprovalMax workflow.',
    qBooksFraudExternalResolvingApprovedText:
        'Please note: this request was created directly in ' +
        '{integrationName}, without going through the ApprovalMax workflow.',
    fraudExternalResolvingCancelledText:
        'Please note that this request has been cancelled by {integrationName}. See audit log for details.',
    fraudExternalResolvingRejectedText:
        'Please note that this request has been rejected by {integrationName}. See audit log for details.',
    fraudPostApprovalChangeText: 'Please note that this request has been changed in {integrationName} after approval.',
    externalNetSuiteRejectedText:
        'Please note: this request has been rejected directly in Oracle NetSuite, without going through the ApprovalMax workflow.',
});

type State = stateTree.State;

export function getActiveStep(request: domain.Request): domain.RequestStep | null {
    return request.steps.find((s) => s.state === domain.RequestStepState.Active) || null;
}

export const getParticipantWithEditPermission = (me: string, participants: domain.RequestStepParticipant[] = []) => {
    const participant = participants.find(
        (participant) => participant.userId === me && !participant.delegateFor && participant.canEdit
    );
    const delegaterParticipant = participants.find(
        (participant) => participant.userId === me && participant.delegateFor && participant.canEdit
    );

    return participant || delegaterParticipant;
};

export const getCanEditAsDelegatedApprover = (me: string, participants: domain.RequestStepParticipant[] = []) => {
    const participantCanEdit = participants.find(
        (participant) => participant.userId === me && !participant.delegateFor && participant.canEdit
    );

    if (participantCanEdit) return true;

    const delegaterParticipantsCanEdit = participants.filter(
        (participant) => participant.userId === me && participant.delegateFor && participant.canEdit
    );

    return delegaterParticipantsCanEdit.length === 1;
};

export const getCanEditAsApprover = (
    request: domain.Request,
    activeStep: domain.RequestStep | null,
    company: selectors.types.ExpandedCompany,
    me: Guid
) => {
    if (request.statusV2 !== domain.RequestStatusV2.OnApproval || !activeStep) {
        return false;
    }

    const participantWithEditPermission = getParticipantWithEditPermission(me, activeStep.participants);
    const isEditingOnApprovalAvailable = selectors.company.getIsEditingOnApprovalAvailable(
        company,
        request.integrationCode
    );

    return Boolean(
        isEditingOnApprovalAvailable &&
            participantWithEditPermission?.canEdit &&
            participantWithEditPermission?.decision !== domain.RequestStepParticipantDecision.Approve
    );
};

function canApprove(request: domain.Request, activeStep: domain.RequestStep | null, me: Guid) {
    if (request.statusV2 !== domain.RequestStatusV2.OnApproval) {
        return false;
    }

    return Boolean(
        activeStep &&
            activeStep.participants.some(
                (p) => p.userId === me && p.decision === domain.RequestStepParticipantDecision.NoResponse
            )
    );
}

/**
 * Important: in most cases, use the ExpandedRequest model instead by getting it with getRequestById() or expandRequest().
 * This function only exists for when store doesn't yet have all the data to expand the request.
 */
export function isActiveApprover(request: domain.Request, me: Guid) {
    const activeStep = getActiveStep(request);

    return canApprove(request, activeStep, me);
}

export const canReview = (request: domain.Request, me: Guid) => {
    if (request.statusV2 !== domain.RequestStatusV2.OnReview || !request.reviewStep) {
        return false;
    }

    return Boolean(
        !request.reviewStep.isCompleted && request.reviewStep.reviewers.some((reviewer) => reviewer.id === me)
    );
};

const isDelegateApprover = (request: domain.Request, activeStep: domain.RequestStep | null, me: Guid) => {
    if (!canApprove(request, activeStep, me) || !activeStep) {
        return false;
    }

    const participantInfo = activeStep.participants.find(
        (participant) => participant.userId === me && participant.delegateFor
    );

    return Boolean(participantInfo?.delegateFor);
};

const isDelegateReviewer = (request: domain.Request, me: Guid) => {
    const { reviewStep } = request;

    if (!canReview(request, me) || !reviewStep) {
        return false;
    }

    const reviewer = reviewStep.reviewers.find((reviewer) => reviewer.id === me && reviewer.delegateFor);

    return Boolean(reviewer?.delegateFor);
};

const getFraudExternalResolvingApprovedText = (integrationType: domain.IntegrationType) => {
    let integrationName = getIntegrationTypeName(integrationType);

    switch (integrationType) {
        case domain.IntegrationType.QBooks:
            return messages.qBooksFraudExternalResolvingApprovedText({
                integrationName,
            });

        default:
            return messages.fraudExternalResolvingApprovedText({
                integrationName,
            });
    }
};

function majorFraudTexts(request: domain.Request): string[] {
    let result = [];

    if (request.fraudulentActivity.includes(domain.RequestFraudulentActivity.ExternalResolving)) {
        let message;

        const integrationType = domain.getIntegrationTypeByCode(request.integrationCode);

        let integrationName = getIntegrationTypeName(integrationType);

        switch (request.statusV2) {
            case domain.RequestStatusV2.Approved:
                message = getFraudExternalResolvingApprovedText(integrationType);
                break;

            case domain.RequestStatusV2.Cancelled:
                message = messages.fraudExternalResolvingCancelledText({
                    integrationName,
                });
                break;

            case domain.RequestStatusV2.Rejected: {
                if (integrationType === domain.IntegrationType.NetSuite) {
                    if (
                        request.resolutionOrigin === domain.RequestResolutionOrigin.EnforcedExternally ||
                        request.resolutionOrigin === domain.RequestResolutionOrigin.ResolvedExternally
                    ) {
                        message = messages.externalNetSuiteRejectedText;
                    }
                } else {
                    message = messages.fraudExternalResolvingRejectedText({
                        integrationName,
                    });
                }

                break;
            }

            case domain.RequestStatusV2.Draft:
            case domain.RequestStatusV2.OnApproval:
            case domain.RequestStatusV2.OnHold:
                break;

            default:
                throw errorHelpers.invalidOperationError(request.statusV2);
        }

        if (message) {
            result.push(message);
        }
    }

    if (request.fraudulentActivity.includes(domain.RequestFraudulentActivity.PostApprovalChange)) {
        result.push(
            messages.fraudPostApprovalChangeText({
                integrationName: getIntegrationTypeName(getIntegrationType(request.integrationCode)),
            })
        );
    }

    return result;
}

function getMyDecisions(request: domain.Request, me: Guid) {
    return request.steps.reduce(
        (m, s) => {
            const participant = s.participants.find((p) => p.userId === me);

            if (participant && participant.decision !== domain.RequestStepParticipantDecision.NoResponse) {
                m.push({
                    step: s,
                    decision: participant.decision,
                });
            }

            return m;
        },
        [] as Array<{
            step: domain.RequestStep;
            decision: domain.RequestStepParticipantDecision.Approve | domain.RequestStepParticipantDecision.Reject;
        }>
    );
}

function getMyFinalDecision(myDecisions: ExpandedRequest['myDecisions'], activeApprover: boolean) {
    if (activeApprover) {
        return null;
    }

    return myDecisions.reduce((m, s) => {
        if (m === domain.RequestStepParticipantDecision.Reject) {
            // Reject overrules any approval
            return m;
        }

        return s.decision;
    }, null);
}

function getAmountInBaseCurrency(request: domain.Request) {
    if (request.exchangeRate) {
        return request.amount / request.exchangeRate;
    } else {
        return request.amount;
    }
}

export const getIsApprover = (request: domain.Request, me: string) => {
    return request.steps.some((step) => step.participants.some((participant) => participant.userId === me));
};

export const expandRequest: (state: State, request: domain.Request) => ExpandedRequest = createCachedSelector(
    (state: State, request: domain.Request) => request,
    (state: State, _request: domain.Request) => getProfileUser(state).id,
    (state: State, request: domain.Request) => getCompanyById(state, request.companyId),
    (state: State, request: domain.Request) =>
        getCompanyUserById(state, getCompanyById(state, request.companyId), request.authorId),
    (state: State, _request: domain.Request) => {
        return selectors.meta.getCreatableTemplates(state);
    },
    (state: State, request: domain.Request) => {
        return getTemplatesByCompanyId(state, request.companyId);
    },
    // eslint-disable-next-line max-params
    (request, me, company, author, creatableTemplates, companyTemplates): ExpandedRequest => {
        const activeStep = getActiveStep(request);
        const closed =
            request.statusV2 === domain.RequestStatusV2.Approved ||
            request.statusV2 === domain.RequestStatusV2.Cancelled ||
            (request.statusV2 === domain.RequestStatusV2.Rejected &&
                request.origin !== domain.RequestOrigin.ApprovalMax &&
                request.origin !== domain.RequestOrigin.Email);
        const flags: ExpandedRequest['flags'] = {
            isAuthor: request.authorId === me,
            isCompanyManager: company.managers.includes(me),
            isActiveApprover: canApprove(request, activeStep, me),
            isApprover: getIsApprover(request, me),
            isActiveReviewer: canReview(request, me),
            isDelegateApprover: isDelegateApprover(request, activeStep, me),
            isDelegateReviewer: isDelegateReviewer(request, me),
            isActualVersion: true,
            status: {
                isOpen: !closed && request.statusV2 !== domain.RequestStatusV2.Draft,
                isDraft: request.statusV2 === domain.RequestStatusV2.Draft,
                isClosed: closed,
            },
        };
        const amountInBaseCurrency = getAmountInBaseCurrency(request);
        const myDecisions = getMyDecisions(request, me);
        const myFinalDecision = getMyFinalDecision(myDecisions, flags.isActiveApprover);

        const hasCreatableTemplate = creatableTemplates.includes(request.template.id);

        let commands;

        switch (request.integrationType) {
            case domain.IntegrationType.Xero:
                commands = getXeroCommands({
                    request,
                    company,
                    hasCreatableTemplate,
                    myDecisions,
                    flags,
                    companyTemplates,
                    creatableTemplates,
                    me,
                });
                break;

            case domain.IntegrationType.QBooks:
                commands = getQBooksCommands({
                    request,
                    company,
                    hasCreatableTemplate,
                    myDecisions,
                    flags,
                    companyTemplates,
                    creatableTemplates,
                    me,
                });
                break;

            case domain.IntegrationType.NetSuite:
                commands = getNetSuiteCommands({
                    request,
                    company,
                    hasCreatableTemplate,
                    myDecisions,
                    flags,
                    companyTemplates,
                    creatableTemplates,
                });
                break;

            case domain.IntegrationType.Dear:
                commands = getDearCommands({ request, company, myDecisions, flags });
                break;

            case domain.IntegrationType.None:
                commands = getStandaloneCommands({ request, company, hasCreatableTemplate, myDecisions, flags });
                break;

            default:
                throw errorHelpers.notSupportedError();
        }

        const baseExpandedRequest: BaseExpandedRequest = {
            amountInBaseCurrency,
            author,
            activeStep,
            company,
            flags,
            commands,
            myDecisions,
            myFinalDecision,
            majorFraudulentActivityTexts: majorFraudTexts(request),
        };

        return {
            ...baseExpandedRequest,
            ...request,
        };
    }
)((state: State, request: domain.Request) => request.id, {
    cacheObject: new LruObjectCache({ cacheSize: 256 }),
});

export function findRequestById(state: State, requestId: string) {
    const request = state.entities.requests[requestId];

    if (!request) {
        return null;
    }

    return expandRequest(state, request);
}

export function getRequestById<T extends selectors.types.ExpandedRequest = selectors.types.ExpandedRequest>(
    state: State,
    requestId: string
): T {
    const request = findRequestById(state, requestId);

    if (!request) {
        throw errorHelpers.notFoundError(`Request not found.`);
    }

    return request as T;
}

export function getDueDateWarning(request: ExpandedRequest, now: Moment): DueDateWarning | null {
    const hasDeadline = Boolean(!request.flags.status.isClosed && request.deadlineDate);

    const onApproval = request.statusV2 === domain.RequestStatusV2.OnApproval;
    const onHold = request.statusV2 === domain.RequestStatusV2.OnHold;
    const onReview = request.statusV2 === domain.RequestStatusV2.OnReview;

    if (!hasDeadline || !(onApproval || onReview || onHold)) {
        return null;
    }

    const dueDate = moment(request.deadlineDate);

    if (now.isAfter(dueDate)) {
        return DueDateWarning.Overdue;
    }

    if (dueDate.diff(now, 'days') < 1) {
        return DueDateWarning.LessThanOneDay;
    }

    return null;
}

const docTypes: {
    [extension: string]: FileType;
} = {
    png: FileType.Image,
    jpg: FileType.Image,
    jpeg: FileType.Image,
    pdf: FileType.Pdf,
};

export function getDocumentFileType(fileName: string) {
    const extension = fileHelpers.getFileExtension(fileName);

    return docTypes[extension] || FileType.Other;
}

export function getAllCommentAttachments(request: domain.Request): domain.RequestAttachment[] {
    return request.history.flatMap((h) => h.comment?.attachments || []);
}

export function expandRequestAttachment(attachment: domain.RequestAttachment): ExpandedRequestAttachment {
    const extension = fileHelpers.getFileExtension(attachment.name);

    return {
        ...attachment,
        fileType: getDocumentFileType(attachment.name),
        extension,
    };
}

export const getHideActionsForAdvancedFeatures = (
    company: selectors.types.ExpandedCompany,
    integrationCode: domain.IntegrationCode | null
): boolean => {
    const isXeroContactAvailable = company.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroContactWorkflows);
    const isQbooksVendorAvailable = company.licenseFeatures.includes(domain.CompanyLicenseFeature.QBOVendorWorkflows);
    const isXeroBatchPaymentAvailable = company.licenseFeatures.includes(
        domain.CompanyLicenseFeature.XeroBillBatchPayments
    );
    const isXeroManualJournalAvailable =
        company.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroManualJournals) ||
        company.betaFeatures.includes(domain.CompanyBetaFeature.XeroManualJournal);

    switch (integrationCode) {
        case domain.IntegrationCode.XeroContact:
            return !isXeroContactAvailable;

        case domain.IntegrationCode.XeroBillBatchPayment:
            return !isXeroBatchPaymentAvailable;

        case domain.IntegrationCode.XeroManualJournal:
            return !isXeroManualJournalAvailable;

        case domain.IntegrationCode.QBooksVendor:
            return !isQbooksVendorAvailable;

        default:
            return false;
    }
};
