import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { UseGetQuoteForCustomerDecisionResponseBackend, useGetRequestLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import {
    UseGetQuoteForCustomerDecisionParams,
    UseGetQuoteForCustomerDecisionResponse,
} from './useGetQuoteForCustomerDecision.types';

export const useGetQuoteForCustomerDecision = (
    params: QueryParams<UseGetQuoteForCustomerDecisionParams>,
    queryOptions?: QueryOptions<UseGetQuoteForCustomerDecisionResponseBackend, UseGetQuoteForCustomerDecisionResponse>
) => {
    return useGetRequestLegacy<UseGetQuoteForCustomerDecisionResponseBackend, UseGetQuoteForCustomerDecisionResponse>(
        requestsApiPaths.getQuoteForCustomerDecision,
        params,
        {
            queryOptions: {
                ...queryOptions,
                enabled: enabledByParams<UseGetQuoteForCustomerDecisionParams>(
                    {
                        companyId: params.companyId,
                        requestId: params.requestId,
                        code: params.code,
                    },
                    queryOptions
                ),
            },
            mapData: (data) => data.data,
        }
    );
};
