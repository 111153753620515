import { backend } from 'modules/data';

import { ApiHandler } from '../types';

class ReceiptBankUrls {
    constructor(private _apiHandler: ApiHandler) {}
}

export default class ReceiptBankApi {
    public urls: ReceiptBankUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new ReceiptBankUrls(this._apiHandler);
    }

    public integrateCompany(
        transfer: backend.transfers.CompanyTransfer
    ): Promise<backend.IntegrationsReceiptBankCreateReceiptBankIntegrationAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'receiptBank/integrateCompany',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
