import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.Dropzone', {
    selectFileButton: 'Upload {file}',
    dragFileMessage: 'Drag & drop to upload',
    acceptedExtensions: 'Accepted file types: ',
    maxSizeText: 'Max {maxSize}',
    hint: 'Release the mouse button and drop the {file}',
    file: 'file',
    files: 'files',
});

export const errorMessages = defineMessages('components.Dropzone.errorMessages', {
    fileInvalidType: 'Invalid file type',
    fileTooLarge: 'File size more than {maxSize}',
    fileTooSmall: 'File size less than {maxSize}',
    tooManyFiles: 'Only one file can be uploaded',
    tooManyFilesInList: 'Sorry, only {maxFilesInList} files can be uploaded at a time',
    duplicateFile:
        'Please re-upload the file with a different name, as a file with "{fileName}" name is already added.',
    unknownError: 'Unknown error',
});
