import uniqueId from 'lodash/uniqueId';
import { domain } from 'modules/data';

const prefix = 'accountLineItem-';

export function isNewQBooksAccountLineItem(li: domain.QBooksAccountLineItem) {
    return li.id.startsWith(prefix);
}

export function createQBooksAccountLineItem(): domain.QBooksAccountLineItem {
    return {
        id: uniqueId(prefix),
        description: '',
        isMatched: false,
    };
}

export function cloneQBooksAccountLineItem(li: domain.QBooksAccountLineItem): domain.QBooksAccountLineItem {
    return {
        id: uniqueId(prefix),
        account: li.account,
        description: li.description,
        amount: li.amount,
        customer: li.customer,
        class: li.class,
        taxCode: li.taxCode,
        isMatched: false,
        isBillable: li.isBillable,
        isTaxable: li.isTaxable,
    };
}
