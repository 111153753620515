import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, LOAD_PAGE_DATA } from '../../actions';
import { XeroContext } from '../../data/xero/XeroContext';

export type XeroContextType = ImmutableObject<XeroContext>;

const INITIAL_STATE: XeroContextType = undefined as any;

export default function (state = INITIAL_STATE, action: Action): XeroContextType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return immutable(action.payload.xeroContext!);

        default:
            return state;
    }
}
