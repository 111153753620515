import { Guid } from '@approvalmax/types';
import { backend, domain } from 'modules/data';

import { ApiHandler } from '../types';

export default class NetSuiteApi {
    constructor(private _apiHandler: ApiHandler) {}

    connectToAccount(data: {
        companyId: Guid;
        accountId: string;
        consumerKey: string;
        consumerSecret: string;
        tokenId: string;
        tokenSecret: string;
        subsidiaryId: string;
    }): Promise<{
        Companies: backend.CompanyAnswer[];
    }> {
        return this._apiHandler.doApiCall({
            data,
            action: 'companies/finishNetSuiteIntegration',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    getNetSuiteCustomFields(data: { companyId: string }): Promise<{
        Values: {
            Field: {
                ScriptId: string;
                Id: string;
                Name: string;
            };
            Level: 'Header' | 'Lines' | 'Header And Lines';
            ReferenceRecordType: {
                Id: string;
                Name: string;
            } | null;
            Type: string;
        }[];
    }> {
        return this._apiHandler.doApiCall({
            data,
            action: 'integration/getNetSuiteFields',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    selectCustomCompanyFields(data: { companyId: Guid }): Promise<backend.SelectNetSuiteCustomCompanyFieldsAnswer> {
        return this._apiHandler.doApiCall({
            data,
            action: 'companies/selectNetSuiteCustomCompanyFields',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    addOrUpdateNetSuiteFields(data: backend.transfers.AddOrUpdateNetSuiteFieldsTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data,
            action: 'companies/addOrUpdateNetSuiteFields',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    getNetSuiteApiFilter(data: { companyId: Guid }): Promise<backend.NetSuiteApiFilterAnswer> {
        return this._apiHandler.doApiCall({
            data,
            action: 'integration/getNetSuiteApiFilter',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    updateNetSuiteFilter(data: {
        companyId: Guid;
        apiFilter: domain.NetSuiteApiFilter;
    }): Promise<backend.NetSuiteApiFilterAnswer> {
        return this._apiHandler.doApiCall({
            data,
            action: 'integration/updateNetSuiteFilter',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
