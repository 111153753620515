import './tooltip.scss';

import { Dropdown, DropdownPanelPosition } from '@approvalmax/ui/src/old/drop';
import { eventHelpers, miscHelpers } from '@approvalmax/utils';
import { FC, HTMLAttributes, ReactNode, Ref, useCallback, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';

const TRANSITION_TIMEOUT = 200;

const bem = bemFactory.block('ui-tooltip');

export interface TooltipProps {
    className?: string;
    qa?: string;
    tooltip?: ReactNode;
    position?: DropdownPanelPosition;
    children:
        | ReactNode
        | ((options: {
              elementProps: {
                  ref: Ref<any>;
                  className: string;
                  'data-qa'?: string;
                  onMouseOver: any;
                  onMouseOut: any;
              };
          }) => ReactNode);
}

const Tooltip: FC<TooltipProps> = (props) => {
    const { className, qa, children, tooltip, position = 'up' } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const delayedRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const panelElRef = useRef<HTMLDivElement | null>(null);
    const hoverCounterRef = useRef(0);

    const onOpen = useCallback(() => {
        setIsOpen(true);

        if (delayedRef.current) {
            // Just cancel hiding the Dropdown panel
            clearTimeout(delayedRef.current);
        }

        delayedRef.current = setTimeout(() => {
            setIsActive(true);
            delayedRef.current = null;
        }, 0);
    }, []);

    const onClose = useCallback(() => {
        setIsActive(false);

        if (delayedRef.current) {
            // Immediately cancel the opening event
            setIsOpen(false);
            clearTimeout(delayedRef.current);
        } else {
            delayedRef.current = setTimeout(() => {
                setIsOpen(false);
                delayedRef.current = null;
            }, TRANSITION_TIMEOUT);
        }
    }, []);

    const onMouseOver = useCallback(
        (e: any) => {
            if (panelElRef.current && (panelElRef.current === e.target || panelElRef.current.contains(e.target))) {
                return;
            }

            if (hoverCounterRef.current === 0) {
                onOpen();
            }

            hoverCounterRef.current++;
        },
        [onOpen]
    );
    const onMouseOut = useCallback(
        (e: any) => {
            if (panelElRef.current && (panelElRef.current === e.target || panelElRef.current.contains(e.target))) {
                return;
            }

            setTimeout(() => {
                hoverCounterRef.current--;

                if (hoverCounterRef.current === 0) {
                    onClose();
                }
            }, 0);
        },
        [onClose]
    );

    return (
        <Dropdown
            panelFlow='center'
            panelPosition={position}
            isOpen={isOpen}
            onRequestClose={miscHelpers.noop}
            button={(ref: Ref<HTMLDivElement & HTMLButtonElement>) => {
                const elementProps: HTMLAttributes<HTMLDivElement> & {
                    ref: Ref<HTMLButtonElement>;
                    'data-qa'?: string;
                } = {
                    ref,
                    className: bem.add(className)(),
                    'data-qa': qa,
                    onMouseOver: onMouseOver,
                    onMouseOut: onMouseOut,
                };

                return typeof children === 'function' ? (
                    (children as any)({
                        elementProps,
                    })
                ) : (
                    <div
                        ref={ref}
                        className={bem.add(className)()}
                        data-qa={qa}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    >
                        {children}
                    </div>
                );
            }}
        >
            <div
                ref={panelElRef}
                className={bem('panel', { active: isActive })}
                onMouseDown={eventHelpers.preventDefaultCallback}
            >
                <div className={bem('panel-arrow')} />

                <div className={bem('panel-content')}>{tooltip}</div>
            </div>
        </Dropdown>
    );
};

export default Tooltip;
