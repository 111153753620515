import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { messages } from './SetupStartHardEnforcement.messages';
import { Header, Root } from './SetupStartHardEnforcement.styles';

const SetupStartHardEnforcementSkeleton = memo(() => {
    return (
        <Root>
            <Header>{messages.title}</Header>

            <Skeleton height={110} />

            <Skeleton height={36} />

            <Skeleton height={36} />
        </Root>
    );
});

export default SetupStartHardEnforcementSkeleton;
