import { stateTree } from 'modules/data';
import { set } from 'modules/immutable';

import { Action, CANCEL_DISCARD_CHANGES_POPUP, SHOW_DISCARD_CHANGES_POPUP } from '../actions/index';

export default function (state: stateTree.Navigation, action: Action): stateTree.Navigation {
    switch (action.type) {
        case SHOW_DISCARD_CHANGES_POPUP:
            return set(state, 'discardChangesPopup', {
                unsavedChangesConfirmationCallback: action.payload.unsavedChangesConfirmationCallback,
            });

        case CANCEL_DISCARD_CHANGES_POPUP:
            return set(state, 'discardChangesPopup', null);

        default:
            return state;
    }
}
