import { ADD_USERS_RESPONSE } from 'app/(workspace)/[companyId]/users/resources/actions';
import { pageConfig } from 'app/(workspace)/[companyId]/workflows/[workflowId]/config';
import {
    ADD_AUTO_APPROVAL_STEP,
    ADD_PAYER,
    ADD_RB_SUBMITTER,
    ADD_STEP,
    ADD_STEP_EDITOR,
    ADD_STEP_PARTICIPANT,
    APPLY_MATRIX,
    ASSIGN_EXTERNAL_REQUESTER,
    CHANGE_TEMPLATE_STEP_APPROVAL_COUNT,
    CHANGE_TEMPLATE_STEP_TYPE,
    COPY_RULES_TO_ANOTHER_STEP_USERS,
    DELETE_AUTO_APPROVAL_STEP,
    FINALIZE_ADD_STEP,
    REMOVE_EXTERNAL_REQUESTER,
    REMOVE_PAYER,
    REMOVE_RB_SUBMITTER,
    REMOVE_STEP,
    REMOVE_STEP_EDITOR,
    REMOVE_STEP_PARTICIPANT,
    REMOVE_SUBMITTER,
    RENAME_STEP,
    RENAME_TEMPLATE,
    REORDER_STEPS,
    SAVE_TEMPLATE_RESPONSE,
    SHOW_WORKFLOW_PAGE,
} from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/actions';
import { Page } from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/reducers/pageReducer';
import { selectors } from 'modules/common';
import { LOAD_INITIAL_APP_DATA } from 'modules/common/actions';
import { SHOW_ORGANISATION_POST_CREATION_WIZARD_RESPONSE } from 'modules/company/actions';
import { COMPLETE_FIRST_START_SETUP_RESPONSE } from 'modules/first-start/actions';
import {
    COMPLETE_REVIEW_RESPONSE,
    LOAD_PAGE_DATA,
    MAKE_DECISION_RESPONSE,
    RETURN_TO_REVIEW,
} from 'pages/requestList/actions';
import { getActiveRequestSafe } from 'pages/requestList/selectors/pageSelectors';
import { AnyAction, Middleware } from 'redux';

import { chameleonService } from './index';
import { IdentifyData } from './types';

const modifiedWfEvents = new Set([
    RENAME_STEP,
    ADD_STEP,
    APPLY_MATRIX,
    COPY_RULES_TO_ANOTHER_STEP_USERS,
    RENAME_TEMPLATE,
    REMOVE_SUBMITTER,
    ADD_PAYER,
    REMOVE_PAYER,
    ASSIGN_EXTERNAL_REQUESTER,
    REMOVE_EXTERNAL_REQUESTER,
    ADD_RB_SUBMITTER,
    REMOVE_RB_SUBMITTER,
    ADD_STEP_PARTICIPANT,
    REMOVE_STEP_PARTICIPANT,
    ADD_STEP_EDITOR,
    REMOVE_STEP_EDITOR,
    ADD_AUTO_APPROVAL_STEP,
    DELETE_AUTO_APPROVAL_STEP,
    FINALIZE_ADD_STEP,
    REMOVE_STEP,
    REORDER_STEPS,
    CHANGE_TEMPLATE_STEP_TYPE,
    CHANGE_TEMPLATE_STEP_APPROVAL_COUNT,
]);

export const createChameleonMiddleware = (): Middleware => {
    return (store) => {
        let lastAction: AnyAction | undefined;

        const visitedWorkflow: any = {};

        let lastModifiedWF: string | undefined;

        return (next) => async (action: AnyAction) => {
            let actionResult = await next(action);

            if (window.chmln) {
                if (!window.chameleon.identifyData) {
                    window.chameleon.identifyData = {} as IdentifyData;
                }

                if (action.type === SHOW_WORKFLOW_PAGE) {
                    const template = selectors.page.getPageSafe<Page>(
                        store.getState(),
                        pageConfig.store.id
                    )?.activeTemplate;

                    if (template) {
                        if (!visitedWorkflow[template.integrationCode!]) {
                            window.chmln.track('viewed_workflow');
                            visitedWorkflow[template.integrationCode!] = true;
                        }

                        window.chameleon.identifyData.last_visited_workflow_id = template.id;
                        window.chameleon.identify();
                    }

                    return actionResult;
                }

                if (action.type === SAVE_TEMPLATE_RESPONSE && action.payload?.request?.template?.enabled) {
                    window.chmln.track('activated_workflow');
                    window.chameleon.identifyData.latest_activated_workflow_name =
                        window.chameleon.get_latest_activated_workflow_name();
                    window.chameleon.identifyData.latest_activated_workflow_gl =
                        window.chameleon.get_latest_activated_workflow_gl();
                    window.chameleon.identifyData.latest_activated_workflow_creation_type =
                        window.chameleon.get_latest_activated_workflow_creation_type();

                    window.chameleon.identify();

                    return actionResult;
                }

                if (action.type === LOAD_PAGE_DATA) {
                    try {
                        const activeRequest = getActiveRequestSafe(store.getState());

                        if (activeRequest) {
                            window.chmln.track('viewed_request');
                        }
                    } catch (error) {
                        console.error(error);
                    }

                    return actionResult;
                }

                if (action.type === MAKE_DECISION_RESPONSE) {
                    window.chmln.track('approved_request');
                }

                if (action.type === COMPLETE_REVIEW_RESPONSE) {
                    window.chmln.track('completed_review_request');
                }

                if (action.type === RETURN_TO_REVIEW) {
                    window.chmln.track('return_to_review_request');
                }

                if (action.type === ADD_USERS_RESPONSE) {
                    window.chmln.track('added_user');
                }

                if (modifiedWfEvents.has(action.type)) {
                    const template = selectors.page.getPageSafe<Page>(
                        store.getState(),
                        pageConfig.store.id
                    )?.activeTemplate;

                    // track only first event for reduce requests count
                    if (lastModifiedWF !== template?.id) {
                        window.chmln.track('modified_WF');
                        lastModifiedWF = template?.id;
                    }
                }
            } else if (
                action.type === SHOW_ORGANISATION_POST_CREATION_WIZARD_RESPONSE ||
                (action.type === LOAD_INITIAL_APP_DATA &&
                    lastAction &&
                    lastAction.type === COMPLETE_FIRST_START_SETUP_RESPONSE)
            ) {
                chameleonService.init();

                return actionResult;
            }

            lastAction = action;

            return actionResult;
        };
    };
};
