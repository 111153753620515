import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    UseFinishAddingTFABackupCodesRequest,
    UseFinishAddingTFABackupCodesResponse,
} from './useFinishAddingTFABackupCodes.types';

export const useFinishAddingTFABackupCodes = () => {
    return useMutateLegacy<
        RequestDataParams<UseFinishAddingTFABackupCodesRequest>,
        UseFinishAddingTFABackupCodesResponse
    >(twoFaApiPaths.finishAddingTFABackupCodes);
};
