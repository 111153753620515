export interface LicenseDetails {
    prolongationShortTrialStatus: ProlongationShortTrialStatus;
}

export enum ProlongationShortTrialStatus {
    NotSupported = 'NotSupported',
    NotRequested = 'NotRequested',
    Requested = 'Requested',
    Approved = 'Approved',
}
