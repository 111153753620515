import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.request.xero.BulkCreationPopupContent.AdditionalOptions', {
    additionalOptionsTitle: 'Additional options',
    editButtonTitle: 'Edit',
    companySelectTitle: 'Organisation',
    templateSelectTitle: 'Type',
    cancelButtonTitle: 'Cancel',
    saveButtonTitle: 'Save',
});
