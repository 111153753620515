import { Button, Grid, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';
import { useMount } from 'react-use';
import { amplitudeService } from 'services/amplitude';

import { messages } from './InitialStep.messages';
import { InitialStepProps } from './InitialStep.types';

export const InitialStep: FC<InitialStepProps> = memo((props) => {
    const { amplitudeParams, onNext } = props;

    useMount(() => {
        amplitudeService.sendData('Workflow: land on wizard: greeting step', amplitudeParams);
    });

    return (
        <Grid justifyItems='center' gap={24}>
            <Text font='headline' fontSize='small' fontWeight='medium' textAlign='center' as='h3'>
                {messages.title}
            </Text>

            <Text font='body' fontSize='medium' textAlign='center'>
                {messages.description}
            </Text>

            <Button onClick={onNext} size='large' color='blue80'>
                {messages.button}
            </Button>
        </Grid>
    );
});
InitialStep.displayName = 'InitialStep';
