import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, schemas, State, stateTree } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions, ThunkAction } from 'modules/react-redux';
import moment from 'moment';
import { api } from 'services/api';

import { possibleIntegrationError } from './integration';

export const LOAD_FIELDS = 'COMMON/LOAD_FIELDS';
export const LOAD_FIELDS_RESPONSE = 'COMMON/LOAD_FIELDS_RESPONSE';
export const LOAD_FIELDS_FAILURE = 'COMMON/LOAD_FIELDS_FAILURE';
export const loadFields = (options: {
    type: stateTree.FieldSetType;
    companyId: string;
    templateIntegrationCode?: domain.IntegrationCode | null;
    force?: boolean;
}) =>
    createAsyncAction({
        shouldSendRequest: (state) => {
            if (!selectors.field.fieldSetExpired(state, options) && !options.force) {
                // already up-to-date
                return false;
            }

            const requestedIntegrationFields =
                options.type === stateTree.FieldSetType.AllIntegrationFields || options.templateIntegrationCode;
            const hasActiveIntegration = selectors.integration.hasActiveIntegration(state, options.companyId);

            if (requestedIntegrationFields && !hasActiveIntegration) {
                // cannot perform the fetch
                return false;
            }

            return true;
        },
        request: () => {
            return createAction(LOAD_FIELDS, options);
        },
        response: async (request) => {
            let requestOptions: any = {
                companyId: request.companyId,
            };

            switch (request.type) {
                case stateTree.FieldSetType.TemplateFields:
                    requestOptions.withValues = !request.templateIntegrationCode;
                    requestOptions.integrationCode = request.templateIntegrationCode;
                    break;

                case stateTree.FieldSetType.AllIntegrationFields:
                    requestOptions.withValues = false;
                    requestOptions.allFields = true;
                    break;

                default:
                    throw errorHelpers.invalidOperationError();
            }

            const response = await api.companies.selectFields(requestOptions);
            const stamp = moment().toISOString();

            return createAction(LOAD_FIELDS_RESPONSE, {
                request,
                stamp,
                raw: {
                    Fields: response.Fields.map((field) => ({
                        ...field,
                        companyId: options.companyId,
                    })),
                },
            });
        },
        failure: (error) => createErrorAction(LOAD_FIELDS_FAILURE, error, {}),

        schema: { raw: { Fields: [schemas.fieldSchema] } },

        willDispatchError: (request, error, state, dispatch) => {
            dispatch(possibleIntegrationError(options.companyId, error));
        },
    });

export const INVALIDATE_FIELDS = 'COMMON/INVALIDATE_FIELDS';
export const invalidateFields = (options: { companyId: string }) => createAction(INVALIDATE_FIELDS, { ...options });

export function loadTemplateFieldsByCode({
    companyId,
    integrationCode,
    force,
}: {
    companyId: string;
    integrationCode: domain.IntegrationCode | null;
    force?: boolean;
}) {
    return loadFields({
        type: stateTree.FieldSetType.TemplateFields,
        companyId,
        templateIntegrationCode: integrationCode,
        force,
    });
}

export function loadAllIntegrationFields({ companyId }: { companyId: string }) {
    return loadFields({
        type: stateTree.FieldSetType.AllIntegrationFields,
        companyId,
    });
}

export function loadAllCompanyFields({
    companyId,
    callback,
}: {
    companyId: string;
    callback?: (state: State) => void;
}): ThunkAction {
    return async (dispatch, getState) => {
        await dispatch(loadTemplateFieldsByCode({ companyId, integrationCode: null }));
        await dispatch(loadAllIntegrationFields({ companyId }));

        const state = getState();

        callback?.(state);
    };
}

export type Action = ExtractActions<typeof invalidateFields | typeof loadFields>;
