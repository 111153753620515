import { domain } from 'modules/data';

import { ConditionTypeValues } from './QBooksCustomerCreationConditionCell.types';

export const conditionTypeToConditionTypeValue = (
    condition: domain.ExactValuesCondition | domain.AlwaysTrueCondition | domain.ServerCondition
): ConditionTypeValues => {
    switch (condition.conditionType) {
        case domain.ConditionType.ExactValuesCondition:
            return ConditionTypeValues.ExactValues;

        case domain.ConditionType.NegativeExactValuesCondition:
            return ConditionTypeValues.NegativeExactValues;

        case domain.ConditionType.ServerCondition:
            switch (condition.serverConditionType) {
                case domain.ServerConditionType.CustomersOnly:
                    return ConditionTypeValues.CustomersOnly;

                default:
                    return ConditionTypeValues.AllContacts;
            }

        default:
            return ConditionTypeValues.AllContacts;
    }
};
