import { stateTree } from 'modules/data';
import { ImmutableObject, merge } from 'modules/immutable';

import {
    Action,
    SHOW_EMAIL_CONFIRMATION_MODULE,
    SHOW_FIRST_TIME_SETUP_MODULE,
    SHOW_LOGIN_MODULE,
    SHOW_MOBILE_INFO_MODULE,
    SHOW_PASSWORD_RESET_FINAL_STEP_MODULE,
    SHOW_PASSWORD_RESET_MODULE,
    SHOW_PROFILE_SETUP_MODULE,
    SHOW_RECEIPT_BANK_AUTH_MODULE,
    SHOW_SIGNUP_MODULE,
    SHOW_SLACK_AUTH_MODULE,
    SHOW_VERIFY_EMAIL_MODULE,
} from '../actions/index';
import { PAGE_ID } from '../config';

export default function (
    state: ImmutableObject<stateTree.Navigation>,
    action: Action
): ImmutableObject<stateTree.Navigation> {
    switch (action.type) {
        case SHOW_LOGIN_MODULE:
        case SHOW_PASSWORD_RESET_MODULE:
        case SHOW_FIRST_TIME_SETUP_MODULE:
        case SHOW_PROFILE_SETUP_MODULE:
        case SHOW_MOBILE_INFO_MODULE:
        case SHOW_SIGNUP_MODULE:
        case SHOW_EMAIL_CONFIRMATION_MODULE:
        case SHOW_VERIFY_EMAIL_MODULE:
        case SHOW_PASSWORD_RESET_FINAL_STEP_MODULE:
        case SHOW_RECEIPT_BANK_AUTH_MODULE:
        case SHOW_SLACK_AUTH_MODULE:
            return merge(state, {
                activePageId: PAGE_ID,
                innerActivePageId: action.payload.innerPageId || '',
                activeCompany: null,
            });

        default:
            return state;
    }
}
