import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { routingService } from 'services/routing';

export const useInitRoutingService = () => {
    const history = useHistory();

    useEffect(() => {
        routingService.initialize({
            history,
        });
    }, [history]);
};
