import Cookies from 'js-cookie';

const cookieName = 'wrongEmailSso';

export const isWrongEmailSso = () => {
    return Cookies.get(cookieName) === 'true';
};

export const setWrongEmailSso = () => {
    Cookies.set(cookieName, 'true');
};

export const removeWrongEmailSso = () => {
    Cookies.remove(cookieName);
};
