import { RequestDataParams } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useMutateLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import { UseGetNetSuiteTaxCodesParams, UseGetNetSuiteTaxResponse } from './useGetNetSuiteTaxCodes.types';

export const useGetNetSuiteTaxCodes = () => {
    return useMutateLegacy<RequestDataParams<UseGetNetSuiteTaxCodesParams>, UseGetNetSuiteTaxResponse>(
        integrationsApiPaths.getNetSuiteTaxCodes,
        { mapData: objectHelpers.pascalCaseToCamelCase }
    );
};
