import type { ApiError } from '@approvalmax/types';
import type { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { HEADER_SESSION_EXPIRATION_DATE, HEADER_WEB_APP_VERSION, useApiContext } from '../../api';
import type { ApiBaseAnswer } from '../types';

export const useHandleApiCall = <ResponseData, MappedResponseData>(
    mapData?: (data: ResponseData) => MappedResponseData
) => {
    const { setApiError, setSessionExpirationDate, setWebAppVersion } = useApiContext();

    return useCallback(
        (promise: Promise<AxiosResponse<ResponseData & ApiBaseAnswer>>) => {
            return promise
                .then((res) => {
                    const newSessionExpirationDate = res.headers[HEADER_SESSION_EXPIRATION_DATE];

                    if (newSessionExpirationDate) {
                        setSessionExpirationDate(newSessionExpirationDate);
                    }

                    const newWebAppVersion = res.headers[HEADER_WEB_APP_VERSION];

                    if (newWebAppVersion) {
                        setWebAppVersion(newWebAppVersion);
                    }

                    return mapData?.(res.data) || (res.data as MappedResponseData);
                })
                .catch((error: ApiError) => {
                    setApiError(error);

                    throw error;
                });
        },
        [mapData, setApiError, setSessionExpirationDate, setWebAppVersion]
    );
};
