import { PageSlice } from 'modules/page';

import { PAGE_ID } from './config';
import navigationReducer from './reducers/navigationReducer';

export { default as Page } from './containers/noCompaniesPage/Page';

export const page: PageSlice = {
    id: PAGE_ID,
    reducers: {
        navigation: navigationReducer,
    },
};
