const findFocusableParent = (list: Element, element: Element | null | undefined) => {
    if (element?.parentElement?.hasAttribute('tabindex')) {
        return element.parentElement;
    }

    return findFocusableParent(list, element?.parentElement);
};

export const nextFocusItem = (list: Element, item: Element | undefined) => {
    if (list === item) {
        return list.firstElementChild;
    }

    if (item && item.nextElementSibling) {
        return item.nextElementSibling;
    }

    return list.firstElementChild;
};

export const nextFocusTreeItem = (list: Element, item: Element | undefined) => {
    if (list === item) {
        return list.firstElementChild;
    }

    if (item) {
        const expanded = item.getAttribute('aria-expanded');

        if (expanded === 'true') {
            const subTree = item.querySelector('ul[role="group"]');

            if (subTree) {
                return subTree?.firstElementChild ?? null;
            }
        }
    }

    if (item && item.nextElementSibling) {
        return item.nextElementSibling;
    }

    // the element is the last one in the subtree. We have to find its focusable
    // parent and continue traversing from there
    if (item && ![...list.children].some((child) => child === item)) {
        const focusableParent = findFocusableParent(list, item.parentElement);

        if (focusableParent?.nextElementSibling) {
            return focusableParent.nextElementSibling;
        }
    }

    return list.firstElementChild;
};

export const previousFocusItem = (list: Element, item: Element | undefined) => {
    if (list === item) {
        return list.lastElementChild;
    }

    if (item && item.previousElementSibling) {
        return item.previousElementSibling;
    }

    return list.lastElementChild;
};

export const previousFocusTreeItem = (list: Element, item: Element | undefined) => {
    if (item && item.previousElementSibling) {
        const expanded = item.previousElementSibling.getAttribute('aria-expanded');

        if (expanded === 'true') {
            const subTree = item.previousElementSibling.querySelector('ul[role="group"]');

            return subTree?.lastElementChild ?? null;
        }

        return item.previousElementSibling;
    }

    // the element is the first one in the subtree. We have to find its focusable
    // parent and continue traversing from there
    if (item && ![...list.children].some((child) => child === item)) {
        const focusableParent = findFocusableParent(list, item.parentElement);

        if (focusableParent) {
            return focusableParent;
        }
    }

    return list.lastElementChild;
};

/**
 * Allows to traverse through elements list via Up and Down keyboard buttons rather than
 * tabulation
 */
export const moveFocus = (
    list: Element,
    currentFocus: Element | undefined,
    disabledItemsFocusable: boolean,
    traversalFunction: typeof nextFocusItem
) => {
    let wrappedOnce = false;

    let nextFocus = traversalFunction(list, currentFocus);

    while (nextFocus) {
        // Prevent infinite loop.
        if (nextFocus === list.firstElementChild) {
            if (wrappedOnce) {
                return false;
            }

            wrappedOnce = true;
        }

        const nextFocusDisabled = disabledItemsFocusable
            ? false
            : ('disabled' in nextFocus && nextFocus.disabled) || nextFocus.getAttribute('aria-disabled') === 'true';

        if (!nextFocus.hasAttribute('tabindex') || nextFocusDisabled) {
            // Move to the next element.
            nextFocus = traversalFunction(list, nextFocus);
        } else {
            if ('focus' in nextFocus && typeof nextFocus.focus === 'function') {
                nextFocus.focus();
            }

            return true;
        }
    }

    return false;
};
