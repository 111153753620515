import { RequestDataParams } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import {
    UseCheckAirwallexBeneficiariesBackend,
    UseCheckAirwallexBeneficiariesData,
    UseCheckAirwallexBeneficiariesResponse,
} from './useCheckAirwallexBeneficiaries.types';

export const useCheckAirwallexBeneficiaries = () => {
    return useMutateLegacy<
        RequestDataParams<UseCheckAirwallexBeneficiariesData>,
        UseCheckAirwallexBeneficiariesBackend,
        UseCheckAirwallexBeneficiariesResponse
    >(requestsApiPaths.checkAirwallexBeneficiaries, {
        mapData: objectHelpers.pascalCaseToCamelCase,
    });
};
