import { domain } from 'modules/data';

import { Action, LOAD_PAGE_DATA } from '../../actions';

export default function reportTypeReducer(state: domain.ReportType, action: Action): domain.ReportType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return action.payload.reportType || state;

        default:
            return state;
    }
}
