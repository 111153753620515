import type { ExtractComponentProp } from '@approvalmax/types';
import { Menu, Select } from '@approvalmax/ui/src/components';
import { compareHelpers } from '@approvalmax/utils';
import { selectors, statics } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { useMyTimeZone } from 'modules/utils';
import { useCallback, useMemo } from 'react';

import { messages } from './useTimeZoneSelect.messages';

/**
 * This approach is better than introducing `TimeZoneSelect` is better because
 * we don't know whether Select will be used within Form context or not. In case of yes,
 * we have to additionally cover it by Form.Part within and if case of not it will throw
 */
export const useTimeZoneSelect = (companyId: string) => {
    const myTimeZone = useMyTimeZone();
    const company = useSelector((state) => selectors.company.getCompanyById(state, companyId));

    const items = useMemo(() => {
        // we have to spread, because the structure is Immutable on prod
        const enrichedTimeZones = [...statics.timeZone.timeZoneReferences].map((timeZone) => {
            if (timeZone.id === myTimeZone) {
                return {
                    ...timeZone,
                    endText: messages.myTz,
                    order: 0,
                };
            }

            if (timeZone.id === company.timeZone) {
                return {
                    ...timeZone,
                    endText: messages.orgTz,
                    order: 1,
                };
            }

            return { ...timeZone, order: 2 };
        });

        return enrichedTimeZones.sort(compareHelpers.comparatorFor(compareHelpers.numberComparator2Asc, 'order'));
    }, [myTimeZone, company.timeZone]);

    const customMenuItem = useCallback<ExtractComponentProp<typeof Select, 'customMenuItem'>>(({ key, ...params }) => {
        return <Menu.Item key={key} {...params} />;
    }, []);

    return {
        itemNameKey: 'text',
        items,
        customMenuItem,
    };
};
