import { theme } from '@approvalmax/theme';
import styled from 'styled-components';

export const Picture = styled.picture`
    border-radius: ${theme.radius.small};
    overflow: hidden;
    border: 1px solid ${theme.color.midnight40};
    display: block;

    source,
    img {
        width: 100%;
        display: block;
    }
`;
