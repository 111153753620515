import { maxReportNameLength } from 'shared/constants';

import { messages } from './getClonedReportName.messages';

export const getClonedReportName = (baseReportName: string) => {
    const postfix = messages.copyPostfix;
    const maxLength = maxReportNameLength - postfix.length;

    if (baseReportName.length > maxLength) {
        return baseReportName.substring(0, maxLength) + postfix;
    }

    return baseReportName + postfix;
};
