import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import type {
    UseAmaxPayConsentCreateData,
    UseAmaxPayConsentCreatePathParams,
    UseAmaxPayConsentCreateResponse,
} from './useAmaxPayConsentCreate.types';

export const useAmaxPayConsentCreate = (
    mutationOptions: MutationOptions<
        UseAmaxPayConsentCreateResponse,
        RequestDataParams<UseAmaxPayConsentCreateData, UseAmaxPayConsentCreatePathParams>
    > = {}
) => {
    return useMutateLegacy<
        RequestDataParams<UseAmaxPayConsentCreateData, UseAmaxPayConsentCreatePathParams>,
        UseAmaxPayConsentCreateResponse
    >(paymentServicesApiPaths.amaxPayOpenBankingBankAccounts, {
        mutationOptions,
    });
};
