import { Box, Text, Tooltip } from '@approvalmax/ui/src/components';
import { FC, isValidElement } from 'react';

import { Container } from './WarningIndicator.styles';
import { WarningIndicatorProps } from './WarningIndicator.types';

export const WarningIndicator: FC<WarningIndicatorProps> = (props) => {
    const { show, content, children } = props;

    return (
        <Tooltip disabled={!show} placement='top' width='max-content' activator={<Container>{children}</Container>}>
            {isValidElement(content) ? (
                content
            ) : (
                <Box spacing='8'>
                    <Text font='body' fontSize='small'>
                        {content}
                    </Text>
                </Box>
            )}
        </Tooltip>
    );
};

WarningIndicator.displayName = 'WarningIndicator';
