import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.XeroManualJournalStartStep', {
    bubbleText: 'Creation of {templateName}',
    title: `
        There are several methods to create {pluralTemplateName} in ApprovalMax.
        Please see the options below and choose as applicable.
    `,
    createInApprovalMax: 'Create in ApprovalMax',
    description: 'Add users authorised to create {pluralTemplateName} and submit them for approval in ApprovalMax.',
});
