import { enabledByParams, QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import {
    UseAmaxPayBankAccountBalancePathParams,
    UseAmaxPayBankAccountBalanceResponse,
} from './useAmaxPayBankAccountBalance.types';

export const useAmaxPayBankAccountBalance = (
    pathParams: UseAmaxPayBankAccountBalancePathParams,
    queryOptions?: QueryOptions<UseAmaxPayBankAccountBalanceResponse>
) =>
    useGetRequest<UseAmaxPayBankAccountBalanceResponse>(paymentServicesApiPaths.bankAccountBalance, {
        pathParams,
        queryOptions: {
            enabled: enabledByParams<UseAmaxPayBankAccountBalancePathParams>(pathParams),
            ...queryOptions,
        },
        apiVersion: 'v2',
    });
