import { Box, Dropdown, Menu } from '@approvalmax/ui/src/components';
import { actions } from 'modules/common';
import { clearPersistStore } from 'modules/store';
import { memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';

import PracticeStaffInvitations from '../PracticeStaffInvitations/PracticeStaffInvitations';
import UserCommonMenu from '../UserCommonMenu/UserCommonMenu';
import UserMenuActivator from '../UserMenuActivator/UserMenuActivator';
import UserSettingsMenu from '../UserSettingsMenu/UserSettingsMenu';
import { useHideDropdownOnClickItem } from './User.hooks';
import { messages } from './User.messages';

const qa = bemFactory.qa('page-nav-bar');

const User = memo(() => {
    const dispatch = useDispatch();
    const { isDropdownOpen, toggleOpenDropdown, handleClickMenuItem } = useHideDropdownOnClickItem();

    const logout = useCallback(() => dispatch(actions.logout(clearPersistStore)), [dispatch]);

    return (
        <Dropdown
            open={isDropdownOpen}
            onOpen={toggleOpenDropdown}
            closeOnClickInnerButton
            closeOnClickInnerAnchor
            width='max-content'
            maxHeight={0}
            spacing='4'
            activator={<UserMenuActivator toggleOpenDropdown={toggleOpenDropdown} />}
        >
            <UserSettingsMenu onClickItem={handleClickMenuItem} />

            <PracticeStaffInvitations />

            <UserCommonMenu onClickItem={handleClickMenuItem} />

            <Box spacing='16 8'>
                <Menu size='small'>
                    <Menu.Item name={messages.logoutName} data-qa={qa('action-logout-button')} onClick={logout} />
                </Menu>
            </Box>
        </Dropdown>
    );
});

export default User;
