import { Guid } from '@approvalmax/types';
import { produce } from 'immer';
import { domain } from 'modules/data';

import { Action, SHOW_XERO_INTEGRATION_SELECT_POPUP } from '../../../actions';

export const XERO_INTEGRATION_SELECT_POPUP = 'XERO_INTEGRATION_SELECT_POPUP';

export interface XeroIntegrationSelectPopupData {
    id: typeof XERO_INTEGRATION_SELECT_POPUP;
    tenants: domain.XeroTenantInfo[];
    companyId: Guid | null;
    backendState: string;
}

export default produce(
    (draft: XeroIntegrationSelectPopupData | null, action: Action): XeroIntegrationSelectPopupData | null => {
        switch (action.type) {
            case SHOW_XERO_INTEGRATION_SELECT_POPUP:
                return {
                    id: XERO_INTEGRATION_SELECT_POPUP,
                    tenants: action.payload.tenants,
                    companyId: action.payload.companyId,
                    backendState: action.payload.backendState,
                };

            default:
                return draft;
        }
    },
    null
);
