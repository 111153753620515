import { useApiContext } from '@approvalmax/data';
import { ErrorCode } from '@approvalmax/types';
import { ExternalLink } from '@approvalmax/ui';
import { toast } from '@approvalmax/ui/src/components';
import { typeGuardHelpers } from '@approvalmax/utils';
import { ReactNode, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { routingService } from 'services/routing';
import { storageService } from 'services/storage';
import { getPath, Path } from 'urlBuilder';

import { useApiRequestError } from '../api/hooks';
import { ignoreErrorCodes } from './constants';
import { commonErrorMessages, errorMessages } from './messages';

export const useGlobalApiErrors = () => {
    const { apiError, setApiError } = useApiContext();
    const dispatch = useDispatch();

    useApiRequestError(
        useCallback(
            ({ error }) => {
                setApiError(error);
            },
            [setApiError]
        )
    );

    useEffect(() => {
        if (!apiError) return;

        if (apiError.status === 500) {
            toast.error(commonErrorMessages.somethingWentWrong);

            return;
        }

        if (storageService.authenticated() && apiError.status === 401 && apiError.code === ErrorCode.UNKNOWN_ERROR) {
            // INFO: because sometimes we call getUserContext when we shouldn't.
            // TODO: fix by adding expiration date for "authenticated" cookie.
            return;
        }

        if (ignoreErrorCodes.includes(apiError.code)) {
            return;
        }

        switch (apiError.code) {
            case ErrorCode.E4181_TWO_FA_HARD_REQUIRED:
                toast.error(commonErrorMessages.twoFAMustBeEnabled);

                setTimeout(() => {
                    routingService.reloadToUrl(getPath(Path.twoFaHardEnforcement));
                }, 1500);
                break;

            case ErrorCode.E4132_TWO_FA_ALREADY_ENABLED:
                toast.error(commonErrorMessages.twoFAIsAlreadyEnabled);
                setTimeout(() => {
                    routingService.reloadToDefaultPath();
                }, 1500);
                break;

            case ErrorCode.E2012_REQUEST_STEP_NOT_FOUND:
            case ErrorCode.E4030_OPERATION_VALID_FOR_OPEN_ONLY:
                toast.error(commonErrorMessages.requestHasBeenUpdated);
                break;

            case ErrorCode.E2006_ATTACHMENT_IS_INVALID:
                toast.error(commonErrorMessages.invalidAttachment);
                break;

            case ErrorCode.E4086_ATTACHMENT_FILENAME_IS_INVALID:
                toast.error(commonErrorMessages.invalidFileName({ fileName: apiError.detail }));
                break;

            case ErrorCode.E4092_ATTACHMENT_FILENAME_CONTAINS_RESTRICTED_SUBSTRING:
                toast.error(
                    commonErrorMessages.restrictedFileName({
                        substring: apiError.detail,
                    })
                );
                break;

            case ErrorCode.E4089_ATTACHMENT_COUNT_EXCEEDS_LIMIT: {
                let result = (apiError.detail || '').split('/');

                const cur = result.length > 0 ? result[0] : 1;
                const max = result.length > 1 ? result[1] : 1;

                toast.error(
                    commonErrorMessages.attachmentExceedsLimit({
                        cur,
                        max,
                    })
                );
                break;
            }

            case ErrorCode.E5017_XERO_VALIDATION_ERROR: {
                let errors = apiError.validationErrors || [];

                if (errors.length === 0) {
                    toast.error(commonErrorMessages.xeroValidationError);

                    return;
                }

                for (let errorMessage of errors) {
                    toast.error(commonErrorMessages.xeroError({ errorMessage }));
                }

                break;
            }

            case ErrorCode.E7003_QBOOKS_VALIDATION_ERROR: {
                let errors = apiError.validationErrors || [];

                if (errors.length === 0) {
                    toast.error(commonErrorMessages.qboValidationError);

                    return;
                }

                const regExpEndingWithId = / : Id=[0-9]+$/;

                for (let errMessage of errors) {
                    const errorMessage = errMessage.replace(regExpEndingWithId, '');

                    toast.error(commonErrorMessages.qboError({ errorMessage }));
                }

                break;
            }

            case ErrorCode.E3004_PASSWORD_IS_TOO_SHORT:
            case ErrorCode.E3006_PASSWORD_IS_TOO_WEAK:
                toast.error(
                    errorMessages.error3004({
                        br: <br />,
                    })
                );

                break;

            case ErrorCode.E5013_XERO_CALL_RATE_EXCEEDED:
                toast.error(
                    errorMessages.error5013({
                        errorDetail: apiError.detail,
                        supportlink: (chunks: ReactNode[]) => (
                            <ExternalLink href='https://support.approvalmax.com/portal/kb/articles/what-does-xero-api-call-rate-exceeded-mean'>
                                {chunks}
                            </ExternalLink>
                        ),
                    })
                );
                break;

            case ErrorCode.E5031_XERO_BUDGET_FILE_CORRUPTED:
            case ErrorCode.E8308_PAYMENT_CANNOT_BE_CANCELLED_BECAUSE_IN_PROGRESS:
            case ErrorCode.E4025_REQUEST_CANNOT_BE_CANCELLED:
                toast.error(apiError.message);
                break;

            case ErrorCode.E8120_GROSS_NET_SETTING_CANNOT_BE_CHANGED:
            case ErrorCode.E4142_OUTDATED_OFFBOARD_DATA:
            case ErrorCode.E1004_TOO_MANY_REQUESTS:
            case ErrorCode.CONFLICT:
                toast.error(apiError.detail);
                break;

            case ErrorCode.E6009_REQUEST_AUDIT_REPORTS_COLLECTION_IS_STILL_BEING_PROCESSED:
                toast.info(errorMessages.error6009, { autoClose: 7000 });
                break;

            case ErrorCode.E6010_REQUEST_AUDIT_REPORTS_COLLECTION_HAS_BEEN_RECENTLY_PROCESSED:
                toast.info(errorMessages.error6010, { autoClose: 7000 });
                break;

            case ErrorCode.E6011_REQUESTS_EXPORT_DATA_NOTHING_TO_SEND:
                toast.info(errorMessages.error6011);
                break;

            case ErrorCode.E6012_EXPORTED_ATTACHMENTS_HAS_BEEN_RECENTLY_PROCESSED:
                toast.info(errorMessages.error6012, { autoClose: 7000 });
                break;

            case ErrorCode.E6013_EXPORTED_ATTACHMENTS_IS_STILL_BEING_PROCESSED:
                toast.info(errorMessages.error6013);
                break;

            case ErrorCode.E4109_REQUIRED_FIELD_IS_EMPTY:
                if (apiError.detail) {
                    toast.error(
                        errorMessages.error4109({
                            fieldList: apiError.detail,
                        })
                    );
                } else {
                    toast.error(commonErrorMessages.requiredFieldError);
                }

                break;

            case ErrorCode.E5025_XERO_ACCOUNT_NOT_FOUND:
                toast.error(apiError.detail ?? apiError.title);

                break;

            default: {
                const key = `error${apiError.code}`;

                const formattedMsg = typeGuardHelpers.isKeyOfObject(key, errorMessages) ? errorMessages[key] : '';

                if (formattedMsg) {
                    toast.error(formattedMsg);

                    return;
                }

                const validationErrors = apiError.validationErrors || [];

                if (validationErrors.length) {
                    for (const validationError of validationErrors) {
                        toast.error(validationError);
                    }
                } else {
                    if (apiError.version === 'v2') {
                        toast.error(apiError.detail);
                    } else {
                        toast.error(apiError.title);
                    }
                }
            }
        }
    }, [apiError, dispatch]);
};
