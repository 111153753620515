import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, CLOSE_DOCUMENT_PREVIEW, SHOW_DOCUMENT_PREVIEW } from '../../actions';

export enum PreviewMode {
    Attachment = 'Attachment',
    AuditReport = 'AuditReport',
}

export interface DocumentPreviewData {
    requestId: Guid;
    previewMode: PreviewMode;
    attachmentId?: Guid;
}

export type DocumentPreviewType = ImmutableObject<DocumentPreviewData> | null;

const INITIAL_STATE: DocumentPreviewType = null;

export default function (state: DocumentPreviewType = INITIAL_STATE, action: Action): DocumentPreviewType {
    switch (action.type) {
        case SHOW_DOCUMENT_PREVIEW:
            return immutable<DocumentPreviewData>({
                requestId: action.payload.requestId,
                previewMode: action.payload.previewMode,
                attachmentId: action.payload.attachmentId,
            });

        case CLOSE_DOCUMENT_PREVIEW:
            return null;

        default:
            return state;
    }
}
