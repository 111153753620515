import { useCallback, useMemo } from 'react';

import { convertDayAndHoursToIso, convertIsoToDaysAndHours } from '../../StepDeadline.helpers';

export const useConvertToDaysAndHours = (value: string) => {
    return useMemo(() => convertIsoToDaysAndHours(value), [value]);
};

export const useValues = (value: string = '', onChange: (value: string) => void) => {
    const { days, hours } = useConvertToDaysAndHours(value);

    const onChangeValue = useCallback(
        (days: number, hours: number) => {
            if (days === 0 && hours === 0) {
                onChange('');
            } else {
                onChange(convertDayAndHoursToIso(days, hours));
            }
        },
        [onChange]
    );

    const onDaysChange = useCallback(
        (days: number) => {
            onChangeValue(days, hours);
        },
        [hours, onChangeValue]
    );

    const onHoursChange = useCallback(
        (hours: number) => {
            onChangeValue(days, hours);
        },
        [days, onChangeValue]
    );

    return {
        days,
        hours,
        onDaysChange,
        onHoursChange,
    };
};
