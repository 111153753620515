import { ChevronRightIcon, CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Spacing, Text } from '@approvalmax/ui/src/components';
import * as common from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { deleteAutoApprovalStep, openAutoApproverMatrix } from '../../../../actions';
import { getMatrixDefinition } from '../../../../config/matrixDefinitions';
import { getActiveTemplate, isPageReadonly } from '../../../../selectors/pageSelectors';
import { MatrixType } from '../../../../types/matrix';
import { getConditionsMessages } from './AutoApprovalStep.helpers';
import { messages } from './AutoApprovalStep.messages';
import { DescriptionText } from './AutoApprovalStep.styles';

export const AutoApprovalStep = memo(() => {
    const template = useSelector(getActiveTemplate);
    const company = useSelector(common.selectors.navigation.getActiveCompany);
    const isActive = !!(template && (template.autoApprovalRules.length || template.showAutoApprovalStepWithoutRules));
    const dispatch = useDispatch();
    const isReadonly = useSelector(isPageReadonly);

    const enoughRightsToShowAutoApprove =
        (company.flags.isAuditor || company.flags.isManager || company.flags.isWorkflowManager) &&
        company.licenseFeatures.includes(domain.CompanyLicenseFeature.AutoApprovalStep);

    const matrixDefinition = useMemo(
        () =>
            template?.integrationCode
                ? getMatrixDefinition({
                      integrationCode: template.integrationCode,
                      betaFeatures: company.betaFeatures,
                      matrixType: MatrixType.AutoApproval,
                  })
                : undefined,
        [company.betaFeatures, template?.integrationCode]
    );

    const autoApprovalRules = useMemo(
        () =>
            (template?.autoApprovalRules || [])
                .map((rule) => ({
                    ...rule,
                    conditions: rule.rules.flatMap((rule) => rule.conditions),
                }))
                .filter((rule) => rule.conditions.length > 0),
        [template?.autoApprovalRules]
    );

    const handleDeleteAutoApprovalStep = useCallback(() => dispatch(deleteAutoApprovalStep()), [dispatch]);
    const handleOpenAutoApproverMatrix = useCallback(() => dispatch(openAutoApproverMatrix()), [dispatch]);
    const handleLineOpenAutoApproverMatrix = (lineId: string) => dispatch(openAutoApproverMatrix(lineId));

    if (
        !isActive ||
        !enoughRightsToShowAutoApprove ||
        template?.integrationCode === domain.IntegrationCode.QBooksVendor
    ) {
        return null;
    }

    return (
        <>
            <Flex height={28} inline alignItems='center'>
                <ChevronRightIcon size={16} color='midnight60' />
            </Flex>

            <Box color='midnight20' spacing='8' width={220} height='fit-content' radius='xsmall'>
                <Flex inline justifyContent='space-between' alignItems='center' wrap={false}>
                    <Text font='label' fontSize='small' color='midnight80'>
                        {messages.header}
                    </Text>

                    {(company.flags.isManager || company.flags.isWorkflowManager) && !isReadonly && (
                        <Button icon noPadding onClick={handleDeleteAutoApprovalStep}>
                            <CloseIcon size={12} color='midnight80' />
                        </Button>
                    )}
                </Flex>

                <Spacing height={16} />

                <Button
                    outline
                    color='blue80'
                    size='xsmall'
                    onClick={handleOpenAutoApproverMatrix}
                    disabled={isReadonly}
                    data-qa='wf_open_autoappoval_settings'
                >
                    {messages.settingsButton}
                </Button>

                <Spacing height={8} />

                <Text font='body' fontSize='small' color='midnight80'>
                    {messages.note}
                </Text>

                <Spacing height={8} />

                {autoApprovalRules.map((rule, index) => (
                    <div key={index}>
                        <Text font='label' fontSize='small' fontWeight='medium' color='midnight100'>
                            {rule.lineId}
                        </Text>

                        <Spacing height={2} />

                        <Button
                            color='transparent'
                            outline
                            noPadding
                            size='xsmall'
                            uppercase={false}
                            onClick={() => handleLineOpenAutoApproverMatrix(rule.lineId)}
                            disabled={isReadonly}
                        >
                            <DescriptionText font='label' fontSize='small' color='midnight70'>
                                {messages.willBeApprovedAutomaticallyIf({ requestType: template.displayName })}

                                {getConditionsMessages(
                                    rule.conditions,
                                    template,
                                    company.defaultCurrency,
                                    matrixDefinition
                                )}
                            </DescriptionText>
                        </Button>

                        <Spacing height={8} />
                    </div>
                ))}
            </Box>
        </>
    );
});

AutoApprovalStep.displayName = 'AutoApprovalStep';
