import { useTheme } from '@approvalmax/theme';
import { useEffect } from 'react';
import { useMedia } from 'react-use';
import { useSetRecoilState } from 'recoil';

import { pinDrawerState } from '../../Drawer.states';

export const useScreenAdaptation = () => {
    const setIsDrawerPinned = useSetRecoilState(pinDrawerState);
    const { theme } = useTheme();

    const isNarrowScreen = useMedia(`(max-width: ${theme.container.breakpoint.medium})`);
    const isWideScreen = useMedia(`(min-width: ${theme.container.breakpoint.large})`);
    const noForceUnpinning = localStorage.getItem('noForceUnpinning') === 'true';
    const forceUnpinDrawer = !noForceUnpinning && isNarrowScreen;

    useEffect(() => {
        if (forceUnpinDrawer) {
            setIsDrawerPinned(false);
        }

        if (isWideScreen) {
            setIsDrawerPinned(true);
        }
    }, [forceUnpinDrawer, isWideScreen, setIsDrawerPinned]);

    return {
        isHidePinButton: forceUnpinDrawer,
    };
};
