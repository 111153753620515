import { Form, Select } from '@approvalmax/ui/src/components';
import React, { FC, memo } from 'react';

import { useContacts } from './SuppliersSelect.hooks';
import { messages } from './SuppliersSelect.messages';
import { SuppliersSelectProps } from './SuppliersSelect.types';

export const SuppliersSelect: FC<SuppliersSelectProps> = memo((props) => {
    const { requestId, companyId } = props;

    const { contacts, isFetching, trigger, setQuery } = useContacts({ companyId, requestId });

    return (
        <Form.Part>
            <Select
                items={contacts}
                progress={isFetching}
                onFocus={trigger}
                onInputChange={setQuery}
                placeholder={messages.contactFieldPlaceholder}
                preventSearch
                autocomplete
                name='contactId'
            />
        </Form.Part>
    );
});

SuppliersSelect.displayName = 'SuppliersSelect';
