import moment from 'moment/moment';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import { getGlobalObject } from '../../helpers/browser/getGlobalObject';
import * as accounting from './lib/commonjs/accounting';

class Localization {
    constructor(options: { locale: string; localizationMap: any }) {
        this._intl = createIntl(
            {
                locale: options.locale || 'en',
                messages: options.localizationMap,
            },
            createIntlCache()
        );
        this.formatMessage = this._intl.formatMessage.bind(this._intl);

        this.locale = this._intl.locale;

        const locale = options.locale === 'en' ? 'en-GB' : options.locale;

        moment.locale(locale);
    }

    /**
     * Retrieves the precision of a number by removing the trailing zero.
     * @param {number | null | undefined} value - The number for which to determine precision.
     * @returns {number} - The precision of the number (2-8 digits).
     */
    public autoPrecision(value: number | null | undefined): number {
        if (!value) {
            return 2;
        }

        const maxPrecision = 8;
        const fractionalPart = value
            .toLocaleString('en-EN', {
                minimumFractionDigits: maxPrecision,
            })
            .split('.')[1];
        const precision = fractionalPart.replace(/0+$/, '').length;

        return precision > 2 ? precision : 2;
    }

    private _intl: IntlShape;

    public readonly locale: string;

    public formatMessage: IntlShape['formatMessage'];

    /**
     * Formats a number as currency.
     * @param {number|null|undefined} value - The number to format.
     * @param {string} currency - Currency ID to be used when printing the value.
     * @param {number|'auto'} [precision=2] - The number of digits to appear after the decimal
     *   point OR 'auto' to dynamically set 2-8 digits based on the value.
     * @param {boolean} [shouldUpperCaseCurrency=true] - Whether to uppercase the currency symbol.
     * @returns {string} The formatted currency string.
     */
    public formatCurrency = (
        value: number | null | undefined,
        currency: string,
        precision: number | 'auto' = 2,
        shouldUpperCaseCurrency: boolean = true
    ): string => {
        if (value && !value.toFixed) {
            // not a number
            return value.toString();
        }

        const currencyText = shouldUpperCaseCurrency ? currency.toUpperCase() : currency;

        return accounting.default.formatMoney(value || 0, {
            symbol: currencyText,
            precision: precision === 'auto' ? this.autoPrecision(value) : precision,
            thousand: ',',
            decimal: '.',
            format: {
                pos: '%v %s',
                neg: '-%v %s',
                zero: '%v %s',
            },
        });
    };

    public formatNumber = (
        value: number | null,
        precision: number | 'auto' | 'inherit' = 2,
        removeTrailingZeros = false,
        thousandSeparator = ','
    ): string => {
        if (value && !value.toFixed) {
            // not a number
            return value.toString();
        }

        let precisionSetting = precision;

        if (
            typeof value === 'number' &&
            typeof precisionSetting === 'number' &&
            precisionSetting !== 0 &&
            removeTrailingZeros
        ) {
            const fractionalPart = value
                .toLocaleString('en-EN', {
                    minimumFractionDigits: precisionSetting,
                })
                .split('.')[1];

            const fractionalDigitCount = fractionalPart.replace(/0+$/, '').length;

            if (fractionalDigitCount < precisionSetting) {
                precisionSetting = fractionalDigitCount > 2 ? fractionalDigitCount : 2;
            }
        }

        if (typeof value === 'number' && precisionSetting === 'inherit') {
            const parts = value.toString().split('.');

            if (parts[1]) {
                precisionSetting = parts[1].length;
            }
        }

        const autoPrecision = this.autoPrecision(value);

        /**
         * accounting.js v0.4.2 has a rounding bug with precision greater than 7.
         * As a temporary solution, autoPrecision is used if it is less than the specified precision.
         * @see https://github.com/openexchangerates/accounting.js/issues/192
         */
        let safePrecision;

        switch (true) {
            case typeof precisionSetting !== 'number':
            case typeof precisionSetting === 'number' && autoPrecision < precisionSetting:
                safePrecision = autoPrecision;
                break;

            default:
                safePrecision = precisionSetting;
        }

        return accounting.default.formatNumber(value || 0, {
            precision: safePrecision,
            thousand: thousandSeparator,
            decimal: '.',
        });
    };
}

export const intl = new Localization({
    localizationMap: getGlobalObject().localizationMap,
    locale: getGlobalObject().localizationLocale,
});
