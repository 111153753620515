import { QBooksLogo, XeroLogo } from '@approvalmax/ui';
import { Flex, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useMemo } from 'react';

import { TrialIntegrationType } from '../../AllFeatureTrialPaywallPopup.types';
import { messages } from './CompanyType.messages';
import { CompanyTypeProps } from './CompanyType.types';

export const CompanyType: FC<CompanyTypeProps> = memo((props) => {
    const { integrationType } = props;

    const icon = useMemo(() => {
        switch (integrationType) {
            case TrialIntegrationType.Xero:
                return <XeroLogo height={20} width={20} />;

            case TrialIntegrationType.Quickbooks:
                return <QBooksLogo height={20} width={20} />;

            default:
                return null;
        }
    }, [integrationType]);

    const name = useMemo(() => {
        switch (integrationType) {
            case TrialIntegrationType.Xero:
                return messages.xeroOrg;

            case TrialIntegrationType.Quickbooks:
                return messages.qboOrg;

            default:
                return null;
        }
    }, [integrationType]);

    return (
        <Flex inline direction='row' alignItems='center' spacing='8'>
            {icon}

            {name && (
                <Text font='label' fontSize='small' color='midnight70'>
                    {name}
                </Text>
            )}
        </Flex>
    );
});

CompanyType.displayName = 'CompanyType';
