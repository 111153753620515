import Progress from '@approvalmax/ui/src/components/Progress/Progress';
import { AttachmentIcon, CloseIcon, VisibilityOnIcon } from '@approvalmax/ui/src/icons';
import { FC, memo, useCallback } from 'react';
import { useToggle } from 'react-use';

import { Flex } from '../Flex/Flex';
import { Text } from '../Text/Text';
import { RemoveButton, Root, ShowActivationButton } from './Attachment.styles';
import { AttachmentProps } from './Attachment.types';

/**
 * The Attachment UI component displays either an uploaded or uploading file.
 */
const Attachment: FC<AttachmentProps> = memo((props) => {
    const {
        file,
        id,
        color = 'midnight20',
        size = 'medium',
        disabled,
        children,
        onRemove,
        onShow,
        progress,
        ...restProps
    } = props;

    const [hover, toggleHover] = useToggle(false);

    const iconSize = size === 'medium' ? 16 : 12;

    const onRemoveFile = useCallback(() => {
        onRemove?.(file, id);
    }, [file, id, onRemove]);

    const onShowFile = useCallback(() => {
        onShow?.(file, id);
    }, [file, id, onShow]);

    return (
        <Root
            radius='xsmall'
            spacing='12'
            color={color}
            $size={size}
            $hasShow={Boolean(onShow)}
            $disabled={disabled}
            $hover={hover}
            {...restProps}
        >
            <Flex inline alignItems='center' justifyContent='space-between' wrap={false} spacing='12'>
                <Flex inline alignItems='center' wrap={false} spacing='8'>
                    <Flex inline shrink={0}>
                        <AttachmentIcon size={iconSize} />
                    </Flex>

                    <Text font='label' ellipsis={1} fontSize={size === 'medium' ? 'medium' : 'small'}>
                        {file.name}
                    </Text>
                </Flex>

                {progress && (
                    <Flex inline shrink={0}>
                        <Progress shape='circle' size={size === 'medium' ? 'usmall' : 'uxsmall'} />
                    </Flex>
                )}

                {onRemove && !progress && (
                    <Flex inline shrink={0}>
                        <RemoveButton icon noPadding onClick={onRemoveFile}>
                            <CloseIcon size={iconSize} />
                        </RemoveButton>
                    </Flex>
                )}
            </Flex>

            {onShow && (
                <ShowActivationButton
                    onClick={onShowFile}
                    disabled={disabled}
                    onMouseEnter={toggleHover}
                    onMouseLeave={toggleHover}
                    $disabled={disabled}
                    $hover={hover}
                >
                    <VisibilityOnIcon onClick={onShowFile} size={iconSize} />
                </ShowActivationButton>
            )}
        </Root>
    );
});

export default Attachment;
