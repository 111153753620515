import { Guid } from '@approvalmax/types';
import { domain } from 'modules/data';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, MAKE_FORCE_DECISION_RESPONSE, SHOW_FORCE_DECISION_POPUP } from '../../../actions';

export const FORCE_DECISION_POPUP = 'FORCE_DECISION_POPUP';

export interface ForceDecisionPopupData {
    id: typeof FORCE_DECISION_POPUP;
    requestId: Guid;
    decision: domain.RequestStepParticipantDecision.Approve | domain.RequestStepParticipantDecision.Reject;
}

export type ForceDecisionPopupType = ImmutableObject<ForceDecisionPopupData> | null;

export default function (state: ForceDecisionPopupType, action: Action): ForceDecisionPopupType {
    switch (action.type) {
        case SHOW_FORCE_DECISION_POPUP:
            return immutable<ForceDecisionPopupData>({
                id: FORCE_DECISION_POPUP,
                decision: action.payload.decision,
                requestId: action.payload.requestId,
            });

        case MAKE_FORCE_DECISION_RESPONSE:
            return null;

        default:
            return state;
    }
}
