import { RequestDataParams } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useMutateLegacy } from 'shared/data/api';

import { requestsReportsApiPaths } from '../paths';
import {
    UseCreateManualJournalFromBillInvoiceBackend,
    UseCreateManualJournalFromBillInvoiceData,
    UseCreateManualJournalFromBillInvoiceResponse,
} from './useCreateManualJournalFromBillInvoice.types';

export const useCreateManualJournalFromBillInvoice = () => {
    return useMutateLegacy<
        RequestDataParams<UseCreateManualJournalFromBillInvoiceData>,
        UseCreateManualJournalFromBillInvoiceBackend,
        UseCreateManualJournalFromBillInvoiceResponse
    >(requestsReportsApiPaths.createManualJournalFromBillInvoice, {
        mapData: objectHelpers.pascalCaseToCamelCase,
    });
};
