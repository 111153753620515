import { objectHelpers } from '@approvalmax/utils';
import { immutable, setIn, updateIn, without } from 'modules/immutable';

import { Action, CLEAR_CACHE_STORAGE, FETCH_DATA_FAILURE, FETCH_DATA_REQUEST, FETCH_DATA_RESPONSE } from '../actions';
import { DataItem } from '../typings/DataItem';

export interface DataProviderCachedData {
    loading: boolean | null;
    hasMore: boolean | null;
    error: boolean | null;
    items: DataItem[];
}

export interface DataProviderStorage {
    [filterText: string]: DataProviderCachedData;
}

export interface DataProviders {
    [cacheStorageId: string]: DataProviderStorage;
}

export default function dataProvidersReducer(state: DataProviders, action: Action) {
    switch (action.type) {
        case FETCH_DATA_REQUEST:
            return updateIn(state, [action.payload.cacheStorageId, action.payload.filterText], (x) => {
                return !x
                    ? {
                          loading: true,
                          hasMore: null,
                          items: [],
                          error: false,
                      }
                    : {
                          ...x,
                          loading: true,
                          error: false,
                      };
            });

        case FETCH_DATA_RESPONSE:
            return setIn(state, [action.payload.cacheStorageId, action.payload.filterText], {
                items: action.payload.items,
                hasMore: action.payload.hasMore,
                loading: false,
                error: false,
            });

        case FETCH_DATA_FAILURE:
            return setIn(state, [action.payload.cacheStorageId, action.payload.filterText], {
                items: [],
                hasMore: false,
                loading: false,
                error: true,
            });

        case CLEAR_CACHE_STORAGE: {
            return without(state, (v, id) => action.payload.storageIdPredicate(id));
        }

        default:
            return state;
    }
}

export function getStorage(state: DataProviders, cacheStorageId: string): DataProviderStorage {
    return state[cacheStorageId] || objectHelpers.emptyObject();
}

const defaultCachedData = immutable({
    loading: null,
    hasMore: null,
    error: null,
    items: [],
});

export function getCachedData(storage: DataProviderStorage, filterText: string | null): DataProviderCachedData {
    return storage[filterText || ''] || defaultCachedData;
}
