import { Text } from '@approvalmax/ui/src/components';
import { ui } from 'modules/common';
import { memo } from 'react';

import { UserCellProps } from './UserCell.types';

export const UserCell = memo<UserCellProps>((props) => {
    const { value, className } = props;

    return (
        <ui.UserHover user={value} className={className}>
            <Text font='body' ellipsis={1}>
                {value.displayName}
            </Text>
        </ui.UserHover>
    );
});

UserCell.displayName = 'UserCell';
