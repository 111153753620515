import { RequestDataParams } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import { UseUpdateNetSuiteFilterData } from './useUpdateNetSuiteFilter.types';

export const useUpdateNetSuiteFilter = () => {
    const queryClient = useQueryClient();

    return useMutateLegacy<RequestDataParams<UseUpdateNetSuiteFilterData>>(integrationsApiPaths.updateNetSuiteFilter, {
        mutationOptions: {
            onSuccess: (_response, requestData) => {
                queryClient.invalidateQueries([
                    integrationsApiPaths.getNetSuiteApiFilter,
                    { companyId: requestData.data.companyId },
                ]);
            },
        },
    });
};
