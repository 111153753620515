import type { ApiError } from '@approvalmax/types';
import { useMemo, useState } from 'react';

export const useApiError = () => {
    const [apiError, setApiError] = useState<ApiError | null>(null);

    return useMemo(
        () => ({
            apiError,
            setApiError,
        }),
        [apiError]
    );
};

export const useSessionExpirationDate = () => {
    const [sessionExpirationDate, setSessionExpirationDate] = useState<string>('');

    return useMemo(
        () => ({
            sessionExpirationDate,
            setSessionExpirationDate,
        }),
        [sessionExpirationDate]
    );
};

export const useWebAppVersion = () => {
    const [webAppVersion, setWebAppVersion] = useState<string | undefined>(undefined);

    return useMemo(
        () => ({
            webAppVersion,
            setWebAppVersion,
        }),
        [webAppVersion]
    );
};
