import { selectors } from 'modules/common';
import { backend, domain, schemas, State, stateTree } from 'modules/data';
import { createAction, useDispatch, useShallowEqualSelector } from 'modules/react-redux';
import { normalize } from 'normalizr';
import { useCallback, useMemo } from 'react';
import { useGetWorkflowsBackend } from 'shared/data';

import { FETCH_AND_APPLY_TEMPLATE } from '../../actions';
import { getActiveSettings, getActiveTemplate, isPageReadonly } from '../../selectors/pageSelectors';
import { UseEffectiveDateLimits } from './WorkflowSettingsPopupContent.types';

export const useEffectiveDateLimits: UseEffectiveDateLimits = (templateSettings) => {
    return useMemo(() => {
        if (templateSettings.approvalDisregardDetectionSettings) {
            return selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                templateSettings.approvalDisregardDetectionSettings.effectiveDate
            );
        }

        if (templateSettings.netSuiteApprovalDisregardDetectionSettings) {
            return selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                templateSettings.netSuiteApprovalDisregardDetectionSettings.effectiveDate
            );
        }

        if (templateSettings.dearApprovalDisregardDetectionSettings) {
            return selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                templateSettings.dearApprovalDisregardDetectionSettings.effectiveDate
            );
        }

        if (templateSettings.qBooksApprovalDisregardDetectionSettings) {
            return selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                templateSettings.qBooksApprovalDisregardDetectionSettings.effectiveDate
            );
        }

        return null;
    }, [templateSettings]);
};

export const useWorkflowSettingsPopupData = () => {
    return useShallowEqualSelector((state: State) => {
        const template = selectors.template.getTemplateById(state, getActiveTemplate(state)!.id);
        const company = selectors.company.getCompanyById(state, template.companyId);
        const savedTemplateSettings = getActiveSettings(state)!.data;
        const readonly = isPageReadonly(state);
        const me = selectors.profile.getProfileUser(state);
        const role = selectors.company.getCompanyUserRole(company, me.id);

        const isSaveDisabled = role === domain.CompanyUserRole.Auditor;

        return {
            company,
            template,
            savedTemplateSettings,
            readonly,
            isSaveDisabled,
        };
    });
};

export const useFetchAndApplyWorkflow = (company: domain.Company, template: domain.Template) => {
    const { refetch } = useGetWorkflowsBackend(
        {
            companyId: company?.id,
            templateId: template.id,
        },
        {
            enabled: false,
        }
    );

    const dispatch = useDispatch();

    return useCallback(async () => {
        const data = await refetch();

        const { entities } = normalize<backend.TemplateSelectAnswer, stateTree.Entities>(data.data, {
            Templates: [schemas.templateSchema],
        });

        const newTemplate = entities.templates[template.id];

        dispatch(
            createAction(FETCH_AND_APPLY_TEMPLATE, {
                template: newTemplate,
                normilizedUsers: entities.users,
            })
        );
    }, [dispatch, refetch, template]);
};
