import './checkboxColumnHeader.scss';

import React from 'react';
import bemFactory from 'react-bem-factory';

import Checkbox from '../checkbox/Checkbox';
import DefaultColumnHeader from './DefaultColumnHeader';
import { DataTableColumnHeaderComponentProps, DataTablePadding } from './index';

interface CheckboxColumnHeaderComponentProps extends DataTableColumnHeaderComponentProps {
    disabled?: boolean;
    columnComponent?: React.ComponentType<DataTableColumnHeaderComponentProps>;
}

interface State {}

class CheckboxColumnHeader extends React.Component<CheckboxColumnHeaderComponentProps, State> {
    public static defaultProps = {
        disabled: false,
        padding: DataTablePadding.Default,
        columnComponent: DefaultColumnHeader,
    };

    public render() {
        const { items, className, style, checkedItems, columnDefinition, padding, qa, disabled } = this.props;
        const bem = bemFactory.block('ui-data-table-checkbox-column-header');
        const ColumnComponent = this.props.columnComponent!;

        return (
            <div
                className={bem.add(className)(null, padding!)}
                data-qa={qa}
                data-qa-id={columnDefinition.id}
                style={style}
            >
                <div className={bem('checkbox')} onClick={this._onCheck}>
                    <Checkbox
                        size='size-18'
                        disabled={disabled}
                        checked={items.length === checkedItems.length}
                        triState={checkedItems.length > 0 && items.length > checkedItems.length}
                    />
                </div>

                <ColumnComponent
                    {...this.props}
                    className={bem('default-header')}
                    style={{}}
                    padding={DataTablePadding.Default}
                />
            </div>
        );
    }

    private _onCheck = (e: React.MouseEvent<any>) => {
        if (this.props.disabled) {
            return;
        }

        let newCheckedItems;

        if (this.props.checkedItems.length === this.props.items.length) {
            newCheckedItems = [] as string[];
        } else {
            newCheckedItems = this.props.items.map((x) => x.id);
        }

        this.props.onCheckedItemsChange(newCheckedItems);
        e.preventDefault();
        e.stopPropagation();
    };
}

export default CheckboxColumnHeader;
