import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import {
    UseAmaxPayXeroRefreshByPaymentIdPathParams,
    UseAmaxPayXeroRefreshByPaymentIdResponse,
} from './useAmaxPayXeroRefreshByPaymentId.types';

export const useAmaxPayXeroRefreshByPaymentId = (
    pathParams: QueryParams<UseAmaxPayXeroRefreshByPaymentIdPathParams>,
    queryOptions?: QueryOptions<UseAmaxPayXeroRefreshByPaymentIdResponse>
) => {
    return useGetRequestLegacy<UseAmaxPayXeroRefreshByPaymentIdResponse>(
        requestsApiPaths.amaxPayXeroRefreshByPaymentId,
        {},
        {
            pathParams,
            queryOptions: {
                enabled: enabledByParams<UseAmaxPayXeroRefreshByPaymentIdPathParams>(pathParams),
                ...queryOptions,
            },
        }
    );
};
