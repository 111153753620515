import { Text } from '@approvalmax/ui/src/components';
import { openApprovalMatrix } from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/actions';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { amplitudeService } from 'services/amplitude';

import { FooterButtons, HeaderTitle, ScrollableContent } from '..';
import regularImage from './assets/Wizard_regular.gif';
import standaloneImage from './assets/Wizard_standalone.gif';
import { messages } from './ConditionsStep.messages';
import { Image } from './ConditionsStep.styles';
import { ConditionsStepProps } from './ConditionsStep.types';

export const ConditionsStep: FC<ConditionsStepProps> = memo((props) => {
    const { template, amplitudeParams, onNext } = props;

    const dispatch = useDispatch();

    useMount(() => {
        amplitudeService.sendData('Workflow: land on wizard: conditions step', amplitudeParams);
    });

    const handleApplyAndClose = useCallback(
        (index: number | null) => {
            if (index === null) {
                onNext(0);

                return;
            }

            const nextIndex = index + 1;

            if (nextIndex >= template.steps.length) {
                onNext(nextIndex);
            } else {
                dispatch(openApprovalMatrix(nextIndex, undefined, handleApplyAndClose, () => onNext(nextIndex)));
            }
        },
        [dispatch, onNext, template.steps.length]
    );

    const handleNext = useCallback(() => {
        amplitudeService.sendData(
            'Workflow: land on wizard: clicked go to conditions on conditions step',
            amplitudeParams
        );

        if (template.steps.length > 0) {
            dispatch(openApprovalMatrix(0, undefined, handleApplyAndClose, () => onNext(0)));
        } else {
            onNext(0);
        }
    }, [amplitudeParams, dispatch, handleApplyAndClose, onNext, template.steps.length]);

    const handleBack = useCallback(() => {
        onNext(0);
        amplitudeService.sendData('Workflow: land on wizard: clicked not now on conditions step', amplitudeParams);
    }, [amplitudeParams, onNext]);

    return (
        <ScrollableContent
            header={<HeaderTitle spacing='32 0' title={messages.title} subtitle={messages.subtitle} />}
            footer={
                <>
                    <Text spacing='0 0 44' font='body' fontSize='large' color='midnight70'>
                        {messages.description}
                    </Text>

                    <FooterButtons
                        onNext={handleNext}
                        onBack={handleBack}
                        nextButtonText={messages.goToConditions}
                        backButtonText={messages.notNow}
                    />
                </>
            }
        >
            <Image src={template.integrationCode === null ? standaloneImage : regularImage} alt='' />
        </ScrollableContent>
    );
});

ConditionsStep.displayName = 'ConditionsStep';
