import { Button, CheckboxEditor, Field, OrSeparator, TextButton, TextEditor } from '@approvalmax/ui';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';
import TrustThisDeviceHint from 'shared/components/TrustThisDeviceHint/TrustThisDeviceHint';

import { useTwoFaActionWizardContext } from '../../TwoFaActionWizard.context';
import { TwoFaActionWizardProps } from '../../TwoFaActionWizard.types';
import { messages } from './ScreenBackupCode.messages';
import { AnotherAction, Content, Description, Root, TrustThisDeviceContainer } from './ScreenBackupCode.styles';

const qa = bemFactory.qa('pro-backup-code-popup');

const ScreenBackupCode = memo<TwoFaActionWizardProps>((props) => {
    const { onEnterBackupCode, isTrustThisDeviceEnabled, onSuccess, title } = props;

    const { setActiveScreen } = useTwoFaActionWizardContext();
    const inputRef = useRef<HTMLInputElement>(null);
    const [backupCode, setBackupCode] = useState('');
    const [trustThisDevice, setTrustThisDevice] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const canSubmit = Boolean(backupCode.trim()) && !isLoading;

    useEffect(() => {
        if (isError) {
            inputRef.current!.focus();
        }
    }, [isError]);

    const onSubmit = useCallback(async () => {
        if (!canSubmit) return;

        setIsLoading(true);

        try {
            await onEnterBackupCode?.(backupCode.trim(), trustThisDevice);
            onSuccess?.();
        } catch {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [canSubmit, onEnterBackupCode, backupCode, trustThisDevice, onSuccess]);

    const onSwitchScreen = useCallback(() => {
        setActiveScreen('methodSelect');
    }, [setActiveScreen]);

    return (
        <Root qa={qa()} title={title}>
            <Content>
                <Description>{messages.description}</Description>

                <Field title={messages.codeFieldTitle} errorText={isError && messages.wrongCodeErrorText}>
                    <TextEditor
                        focusOnMount
                        disabled={isLoading}
                        value={backupCode}
                        onChange={setBackupCode}
                        placeholder={messages.userCodePlaceholder}
                        invalid={isError}
                        ref={inputRef}
                        onEnter={onSubmit}
                    />
                </Field>

                {isTrustThisDeviceEnabled && (
                    <TrustThisDeviceContainer>
                        <CheckboxEditor onChange={setTrustThisDevice} value={trustThisDevice}>
                            {messages.trustThisDevice}
                        </CheckboxEditor>

                        <TrustThisDeviceHint />
                    </TrustThisDeviceContainer>
                )}

                <Button execute={onSubmit} disabled={!canSubmit}>
                    {messages.confirmButton}
                </Button>

                <OrSeparator />

                <AnotherAction>
                    <TextButton disabled={isLoading} execute={onSwitchScreen}>
                        {messages.anotherMethodButton}
                    </TextButton>
                </AnotherAction>
            </Content>
        </Root>
    );
});

export default ScreenBackupCode;
