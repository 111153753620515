import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { ElementFactory } from 'react-bem-factory';

import { ChangeHandler, CheckValidFraudBypassing } from '../../WorkflowSettingsPopupContent.types';

export interface SectionQboFraudBypassingProps {
    settings: domain.TemplateSettings['qBooksApprovalDisregardDetectionSettings'];
    template: selectors.types.ExpandedTemplate;
    links: selectors.types.LinksCollection;
    savedTemplateSettings: domain.TemplateSettings;
    effectiveDateLimits: selectors.types.EffectiveDateLimits | null;
    checkValidFraudBypassing: CheckValidFraudBypassing;
    onChangeApprovalDisregardEffectiveDate: ChangeHandler<string>;
    onChangeApprovalDisregardEnabled: ChangeHandler<boolean>;
    qa: ElementFactory;
    readonly: boolean;
}

export enum ApprovalDisregardOption {
    Pull = 'Pull',
    DoNotPull = 'DoNotPull',
}
