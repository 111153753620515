import { schema } from 'normalizr';

import { getBeautifiedEntity } from '../../utils';
import { Profile, ProfileAccount, ProfileEmail } from '../Profile';
import userSchema from './userSchema';

function mapProfile(value: any): Profile {
    let profile: any = getBeautifiedEntity(value, {
        UserId: 'id',
    });

    delete profile.result;
    delete profile.errorCode;
    delete profile.errorMsg;
    delete profile.errorDetails;
    delete profile.isTfaEnabled;
    profile.summaryNotificationsSendOnDays = profile.summaryNotificationsSentOnDays;
    delete profile.summaryNotificationsSentOnDays;
    profile.summaryNotificationsSendAtTime = profile.summaryNotificationsSentAtTime;
    delete profile.summaryNotificationsSentAtTime;
    profile.databaseId = profile.id;
    profile.avatar = profile.avatarHttps;
    profile.betaFeatures = profile.betaFeatures || [];
    profile.certifications = (profile.certifications || []).map(getBeautifiedEntity);
    profile.account = mapProfileAccount(profile.account);
    profile.is2faEnabled = Boolean(value.IsTFAEnabled);
    delete profile.enableQBOSurvey;
    profile.enableQBooksSurvey = value.EnableQBOSurvey;
    delete profile.avatarHttps;
    profile.useSession = value.IsSessionEnabled;
    profile.isTFAAlternativeEmailEnabled = value.IsTFAAlternativeEmailEnabled;
    profile.isTFABackupCodesEnabled = value.IsTFABackupCodesEnabled;

    if (profile.emails) {
        profile.emails = profile.emails.map((x: ProfileEmail) => getBeautifiedEntity(x));
    }

    profile.delegates = profile.delegates.map((x: any) => getBeautifiedEntity(x, { Delegate: 'delegateUserId' }));

    delete profile.pushConsumers;

    return profile;
}

export function mapProfileAccount(value: any) {
    return value ? getBeautifiedEntity<ProfileAccount>(value) : null;
}

export default new schema.Entity(
    'profiles',
    {
        delegates: new schema.Array({
            delegateUserId: userSchema,
        }),
    },
    {
        idAttribute: 'UserId',
        processStrategy: mapProfile,
    }
);
