import { domain } from 'modules/data';
import moment from 'moment';
import { useMemo } from 'react';

import { messages } from './DeadlineButton.messages';

export const useDeadlineDurationLabel = (value: string) => {
    return useMemo(() => {
        const duration = moment.duration(value);
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();

        let fragments = [];

        if (days) {
            fragments.push(messages.durationDaysPlural({ count: days }));
        }

        if (hours) {
            fragments.push(messages.durationHoursPlural({ count: hours }));
        }

        return fragments.join(' ');
    }, [value]);
};

export const useDeadlineTypeLabel = (type: domain.DeadlineRuleType | null) => {
    switch (type) {
        case domain.DeadlineRuleType.onSubmission:
            return messages.typeLabelBasedOnSubmission;

        case domain.DeadlineRuleType.onApproval:
            return messages.typeLabelBasedOnApproval;

        case domain.DeadlineRuleType.onStartOfReview:
            return messages.typeLabelBasedOnReview;

        default:
            return messages.typeLabelNotSet;
    }
};
