import { QueryOptions } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetRequestLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import {
    NetSuiteExchangeRatesData,
    UseNetSuiteExchangeRatesRequestParams,
    UseNetSuiteExchangeRatesResponseBackend,
} from './useNetSuiteExchangeRates.types';

export const useNetSuiteExchangeRates = (
    params: UseNetSuiteExchangeRatesRequestParams,
    queryOptions?: QueryOptions<UseNetSuiteExchangeRatesResponseBackend, NetSuiteExchangeRatesData>
) => {
    return useGetRequestLegacy<UseNetSuiteExchangeRatesResponseBackend, NetSuiteExchangeRatesData>(
        integrationsApiPaths.getNetSuiteExchangeRates,
        params,
        {
            queryOptions,
            mapData: objectHelpers.pascalCaseToCamelCase,
        }
    );
};
