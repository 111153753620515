import { arrayHelpers, compareHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { useCallback, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

export const useSearch = (workflows: selectors.types.ExpandedTemplate[]) => {
    const [isShownSearch, toggleIsShownSearch] = useToggle(false);
    const [searchText, setSearchText] = useState('');

    const sortedWorkflows = arrayHelpers.arraySort(workflows, (a, b) =>
        compareHelpers.stringComparator2AscI(a.templateName, b.templateName)
    );

    const filteredWorkflows = useMemo(() => {
        if (!searchText) return sortedWorkflows;

        return sortedWorkflows.filter(({ templateName = '' }) =>
            templateName.toLowerCase().includes(searchText.toLowerCase())
        );
    }, [searchText, sortedWorkflows]);

    const toggleSearch = useCallback(() => {
        toggleIsShownSearch();
        setSearchText('');
    }, [toggleIsShownSearch]);

    const onFilterTextChange = useCallback((value: string) => setSearchText(value || ''), [setSearchText]);

    return {
        isShownSearch,
        toggleSearch,
        onFilterTextChange,
        filteredWorkflows,
    };
};
