import { theme } from '@approvalmax/theme';
import styled from 'styled-components';

import errorPattern from './error-pattern.png';
import errorPatternHovered from './error-pattern_hovered.png';

export const Root = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${errorPattern});
    background-repeat: repeat;
    border-radius: 4px;
    transition: background-image ${theme.duration.fast}ms ease-in-out;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-image: url(${errorPatternHovered});

        & #alert-icon {
            color: initial;
        }
    }

    & #alert-icon {
        transition: color ${theme.duration.fast}ms ease-in-out;
    }
`;
