import { FetchIcon } from '@approvalmax/ui';
import { Button, Dropdown } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { useCacheManagement } from './CacheManagement.hooks';

export const CacheManagement = memo(() => {
    const { integrationContent, isAvailable } = useCacheManagement();

    if (!isAvailable) return null;

    return (
        <Dropdown
            width={600}
            maxHeight={500}
            display='inline-flex'
            activator={
                <Button icon noPadding>
                    <FetchIcon />
                </Button>
            }
        >
            {integrationContent}
        </Dropdown>
    );
});

CacheManagement.displayName = 'CacheManagement';
