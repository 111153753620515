import { Guid } from '@approvalmax/types';
import { produce } from 'immer';
import { IntegrationSuccessRedirectPage } from 'modules/integration';

import { Action, SHOW_NETSUITE_INTEGRATION_POPUP } from '../../../actions';

export const NETSUITE_INTEGRATION_POPUP = 'NETSUITE_INTEGRATION_POPUP';

export interface NetSuiteIntegrationPopupData {
    id: typeof NETSUITE_INTEGRATION_POPUP;
    companyId: Guid | null;
    redirectPage: IntegrationSuccessRedirectPage;
}

export default produce(
    (draft: NetSuiteIntegrationPopupData | null, action: Action): NetSuiteIntegrationPopupData | null => {
        switch (action.type) {
            case SHOW_NETSUITE_INTEGRATION_POPUP: {
                return {
                    id: NETSUITE_INTEGRATION_POPUP,
                    companyId: action.payload.companyId,
                    redirectPage: action.payload.redirectPage,
                };
            }

            default:
                return draft;
        }
    },
    null
);
