import { schema } from 'normalizr';

import { CompanyInvitationStatus } from '../CompanyInvitation';
import userSchema from './userSchema';

function mapInvitation(value: any) {
    let invitation = Object.assign({}, value);

    invitation.id = invitation.companyId;
    invitation.status = CompanyInvitationStatus.Untouched;

    return invitation;
}

export default new schema.Entity(
    'companyInvitations',
    {
        inviter: userSchema,
    },
    {
        idAttribute: 'companyId',
        processStrategy: mapInvitation,
    }
);
