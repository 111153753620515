import { immutable, ImmutableObject } from 'modules/immutable';

import {
    Action,
    DISCARD_PAGE_STATE,
    DISCARD_START_OVER_POPUP,
    OPEN_START_OVER_POPUP_RESPONSE,
    START_OVER,
} from '../../actions';

export const NEVER_SHOW_START_OVER_ON_SAVE_USER_PREFERENCE =
    'workflows/startOver/neverShowStartOverPopupOnTemplateSave';

export enum StartOverPopupMode {
    Regular = 'Regular',
    AfterUpdate = 'AfterUpdate',
}

export type StartOverData =
    | {
          requestIds: string[];
          mode: StartOverPopupMode;
      }
    | {
          mode: StartOverPopupMode;
          isWorkflowManager: true;
          quantityRequests: number;
      };

export type ActiveStartOver = ImmutableObject<StartOverData> | null;

export default function activeStartOverReducer(state: ActiveStartOver = null, action: Action): ActiveStartOver {
    switch (action.type) {
        case OPEN_START_OVER_POPUP_RESPONSE:
            if (action.payload.isWorkflowManager && typeof action.payload.quantityRequests === 'number') {
                return immutable<StartOverData>({
                    mode: action.payload.mode,
                    isWorkflowManager: true,
                    quantityRequests: action.payload.quantityRequests,
                });
            }

            if (!action.entities?.requests) {
                return state;
            }

            return immutable<StartOverData>({
                requestIds: Object.keys(action.entities!.requests!),
                mode: action.payload.mode,
            });

        case DISCARD_PAGE_STATE:
        case START_OVER:
        case DISCARD_START_OVER_POPUP:
            return null;

        default:
            return state;
    }
}
