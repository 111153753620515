import { QueryOptions } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';

import { useGetRequestLegacy } from '../../../api';
import { workflowsApiPaths } from '../paths';
import {
    UseGetWorkflowsParams,
    UseGetWorkflowsResponse,
    UseGetWorkflowsResponseBackend,
} from './useGetWorkflows.types';

export const useGetWorkflowsBackend = (
    params: UseGetWorkflowsParams,
    queryOptions?: QueryOptions<UseGetWorkflowsResponseBackend>
) => {
    return useGetRequestLegacy<UseGetWorkflowsResponseBackend>(workflowsApiPaths.select, params, {
        queryOptions,
    });
};

export const useGetWorkflows = (
    params: UseGetWorkflowsParams,
    queryOptions?: QueryOptions<UseGetWorkflowsResponseBackend, UseGetWorkflowsResponse>
) => {
    return useGetRequestLegacy<UseGetWorkflowsResponseBackend, UseGetWorkflowsResponse>(
        workflowsApiPaths.select,
        params,
        {
            queryOptions,
            mapData: objectHelpers.pascalCaseToCamelCase,
        }
    );
};
