import { Reference } from '@approvalmax/types';
import { constants } from '@approvalmax/utils';
import { immutable } from 'modules/immutable';
import moment, { MomentInput } from 'moment/moment';

export const timeZoneReferences = process.env.DEBUG ? constants.timezones : immutable<Reference>(window.timezones);

export const findTimeZoneById = (id: string): Reference | undefined => {
    return timeZoneReferences.find((x) => x.id === id);
};

/**
 * Retrieves the UTC offset of a time zone in minutes from the time zone's text representation.
 * @returns {number} The UTC offset of the time zone in minutes. Returns 0 if the timeZone argument is undefined.
 */
export const getUtcOffset = (timeZone: Reference | undefined): number => {
    if (!timeZone) return 0;

    const regex = /\((UTC[+-])(\d{2}):(\d{2})\)/;
    const match = timeZone.text.match(regex);

    if (match) {
        const sign = match[1] === 'UTC+' ? 1 : -1;
        const hours = Number(match[2]);
        const minutes = Number(match[3]);

        return sign * (hours * 60 + minutes);
    } else {
        return 0;
    }
};

export const getLocalUtcOffset = () => new Date().getTimezoneOffset() * -1;

export const addOffset = (date: MomentInput, utcOffset: number) => {
    return moment(date).add(utcOffset, 'minutes');
};

export const subtractOffset = (date: MomentInput, utcOffset: number) => {
    return moment(date).subtract(utcOffset, 'minutes');
};

/**
 * ```
 * UTC-12:00 --------------- UTC -------------- UTC+14:00
 *  case 1:       newTz                  oldTz
 *  case 2:       oldTz                  newTz
 * ```
 */
export const getOffsetShift = (newUtcOffset: number, oldUtcOffset: number) => {
    const sign = oldUtcOffset > newUtcOffset ? -1 : 1;

    return Math.abs(oldUtcOffset - newUtcOffset) * sign;
};
