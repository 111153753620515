import {
    Action,
    DELETE_TEMPLATE,
    DISCARD_PAGE_STATE,
    HIDE_VALIDATE_ERRORS,
    OPEN_APPROVAL_MATRIX,
    OPEN_EDITORS_MATRIX,
    OPEN_REVIEWER_MATRIX,
    OPEN_SUBMITTER_MATRIX,
    SAVE_TEMPLATE,
    VALIDATE_ACTIVE_TEMPLATE,
} from '../../actions';

export default function (state: boolean = false, action: Action): boolean {
    switch (action.type) {
        case OPEN_APPROVAL_MATRIX:
        case OPEN_EDITORS_MATRIX:
        case OPEN_SUBMITTER_MATRIX:
        case DELETE_TEMPLATE:
        case SAVE_TEMPLATE:
        case HIDE_VALIDATE_ERRORS:
        case DISCARD_PAGE_STATE:
        case OPEN_REVIEWER_MATRIX:
            return false;

        case VALIDATE_ACTIVE_TEMPLATE:
            return !action.isValid;

        default:
            return state;
    }
}
