import { ChartIcon } from '@approvalmax/ui';
import { domain } from 'modules/data';
import { useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { getPath, Path } from 'urlBuilder';

import { messages } from './ReportsMenu.messages';
import { ReportsMenuProps } from './ReportsMenu.types';

const qa = bemFactory.qa('reportsDropdown');

export const useReportsLinkItems = (company: ReportsMenuProps['company']) => {
    return useMemo(
        () => [
            {
                id: 'reports',
                name: messages.reportsName,
                startIcon: ChartIcon,
                dataQa: qa(),
                subItems: [
                    {
                        id: domain.ReportType.Request,
                        name: messages.requestName,
                        to: getPath(Path.reportsDashboard, company.id, domain.ReportType.Request),
                        dataQa: qa('navigationItem'),
                        dataQaName: 'reports',
                    },
                    {
                        id: domain.ReportType.LineItem,
                        name: messages.lineItemsName,
                        available: Boolean(
                            company.integration?.integrationType &&
                                company.integration?.integrationType !== domain.IntegrationType.None &&
                                company.integration?.integrationType !== domain.IntegrationType.Dear &&
                                company.integration?.integrationType !== domain.IntegrationType.NetSuite
                        ),
                        to: getPath(Path.reportsDashboard, company.id, domain.ReportType.LineItem),
                        dataQa: qa('navigationItem'),
                        dataQaName: 'lineItemReports',
                    },
                ],
            },
        ],
        [company.id, company.integration?.integrationType]
    );
};
