import { intl } from '@approvalmax/utils';
import { useEffect } from 'react';

const i18nPrefix = 'page/contatiners/PageLayout';

export const useDocumentTitle = (title?: string | null) => {
    useEffect(() => {
        window.document.title = title
            ? intl.formatMessage(
                  {
                      id: `${i18nPrefix}.documentTitle`,
                      defaultMessage: '{title} | ApprovalMax',
                  },
                  {
                      title,
                  }
              )
            : intl.formatMessage({
                  id: `${i18nPrefix}.defaultDocumentTitle`,
                  defaultMessage: 'ApprovalMax: cloud approval workflows for finance and accounting',
              });
    }, [title]);
};
