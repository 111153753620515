import { Mods, mods, theme } from '@approvalmax/theme';
import { Button, Flex, Text } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const RemoveButton = styled(Button)`
    opacity: 0;
    transition: opacity ${theme.duration.medium}ms linear;
    align-self: start;
`;

export const UserRoot = styled(Flex)`
    &:hover {
        ${RemoveButton} {
            opacity: 1;
        }
    }
`;

export const UserName = styled(Text)<StyledMods<Mods<'removed'>>>`
    ${mods.removed.true`
        text-decoration: line-through;
    `}
`;

export const DescriptionText = styled(Text)`
    &:hover {
        color: ${theme.color.blue100};
    }
`;
