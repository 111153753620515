import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.MatrixPopupContent.MatrixLine', {
    ifTextApprovalMatrix: 'should approve if',
    ifTextReviewerMatrix: 'should review if',
    ifTextEditorMatrix: 'can set',
    ifTextRequesterMatrix: 'can submit with',
    ifTextEditingMatrix: 'can edit with',
    orTextApprovalMatrix: 'or if',
    orTextReviewerMatrix: 'or if',
    orTextEditorMatrix: 'or with',
    orTextRequesterMatrix: 'or with',
    removeUserTextApprovalMatrix: 'Remove an Approver from the step',
    removeUserTextEditorMatrix: 'Remove a Reviewer',
    removeUserTextRequesterMatrix: 'Remove a Requester',
    removeUserTitleApprovalMatrix: 'Remove an Approver from the step',
    removeUserTitleEditorMatrix: 'Remove a Reviewer from this step',
    removeUserTitleRequesterMatrix: 'Remove a Requester',
    invalidConditions: 'Please ensure that all set conditions are valid',
});
