import { TransparentButton } from '@approvalmax/ui';
import { DeleteWhiteIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding-left: 170px;
    background: #2c3942;

    a {
        color: #8bff87;
    }
`;

export const NotificationText = styled.div`
    flex: 1;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;

    ${font(13, '#fff')};
`;

export const CloseButton = styled(TransparentButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 100%;
    background: #000;
    cursor: pointer;

    ${font(13, '#fff')};
`;

export const CloseButtonIcon = styled(DeleteWhiteIcon)`
    margin-right: 15px;
    line-height: 12px;
`;
