import { errorHelpers } from '@approvalmax/utils';
import { schema } from 'normalizr';

import * as backend from '../../backend';
import {
    ExtendedIntegration,
    Integration,
    IntegrationCode,
    IntegrationStatus,
    IntegrationSyncStatus,
    IntegrationTemplateSyncProgress,
    IntegrationType,
} from '../Integration';

function mapIntegrationSyncStatus(value: backend.SyncProgressStatus): IntegrationSyncStatus {
    switch (value) {
        case backend.SyncProgressStatus.Failed:
            return IntegrationSyncStatus.Error;

        case backend.SyncProgressStatus.InProgress:
        case backend.SyncProgressStatus.Pending:
            return IntegrationSyncStatus.InProgress;

        case backend.SyncProgressStatus.Succeeded:
        case backend.SyncProgressStatus.Unknown:
            return IntegrationSyncStatus.Done;

        default:
            throw errorHelpers.notSupportedError();
    }
}

export function mapIntegrationSyncProgress(t: backend.RequestsSyncProgressAnswer): IntegrationTemplateSyncProgress {
    return {
        templateId: t.templateId,
        templateIntegrationCode: t.templateIntegrationCode as IntegrationCode,
        status: mapIntegrationSyncStatus(t.status),
        lastSyncDate: t.lastSyncDate,
    };
}

export function mapIntegration(value: backend.IntegrationAnswer): Integration {
    const status = value.Enabled ? IntegrationStatus.Connected : IntegrationStatus.Disconnected;
    const extraInfo = value.IntegrationExtraInfo ? JSON.parse(value.IntegrationExtraInfo) : {};

    let result: Integration = {
        id: value.IntegrationId!,
        companyId: value.CompanyId!,
        integrationType: value.IntegrationType as IntegrationType,
        status,
        isNew: false,
        firstTimeConnection: false,
        createdDate: value.CreatedDate!,
        modifiedDate: value.ModifiedDate!,
        lockDate: value.LockDate!,
        integratedCompanyId: value.IntegratedCompanyId!,
        integratedCompanyName: value.IntegratedCompanyName!,
        externalUrl: value.externalUrl!,
        errorStatusCode: value.ErrorStatusCode || undefined,
        errorStatusMessage: value.ErrorStatusMessage || undefined,
        isBudgetSyncEnabled: Boolean(value.IsBudgetSynchronizationEnabled),
        financialYearEnd: value.FinancialYearEnd,
        isXeroDemo: extraInfo['Class'] === 'Demo',
        countryCode: value.Version,
    };

    return result;
}

export function mapExtendedIntegration(value: backend.IntegrationAnswer): ExtendedIntegration {
    const result = mapIntegration(value);

    return {
        ...result,
        syncProgress: (value.templatesSyncProgress || []).map(mapIntegrationSyncProgress),
    };
}

export default new schema.Entity(
    'integrations',
    {},
    {
        idAttribute: 'IntegrationId',
        processStrategy: mapIntegration,
    }
);
