import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { mapData } from './useStartTFAEnabling.map';
import {
    UseStartTFAEnablingRequest,
    UseStartTFAEnablingResponse,
    UseStartTFAEnablingResponseBackend,
} from './useStartTFAEnabling.types';

export const useStartTFAEnabling = (
    mutationOptions?: MutationOptions<UseStartTFAEnablingResponse, RequestDataParams<UseStartTFAEnablingRequest>>
) => {
    return useMutateLegacy<
        RequestDataParams<UseStartTFAEnablingRequest>,
        UseStartTFAEnablingResponseBackend,
        UseStartTFAEnablingResponse
    >(twoFaApiPaths.enablingStart, { mapData, mutationOptions });
};
