import { HTMLAttributes, ReactElement } from 'react';

export enum ResolutionMode {
    Paid = 'paid',
    Approved = 'approved',
    Cancelled = 'cancelled',
    Rejected = 'rejected',
    None = '',
}

export enum StatusBadgeMode {
    Error = 'error',
    Warning = 'warning',
    Success = 'success',
    Pending = 'pending',
    Info = 'info',
    Rejected = 'rejected',
    Cancelled = 'cancelled',
}

export enum StatusBadgeSize {
    Large = 'large',
    Small = 'small',
}

export interface StatusBadgeRootProps extends HTMLAttributes<HTMLDivElement> {
    mode?: StatusBadgeMode;
    size?: StatusBadgeSize;
}

export interface StatusBadgeProps extends StatusBadgeRootProps {
    label: ReactElement | string;
    qa?: string;
    tooltip?: string;
    hasIcon?: boolean;
    containerProps?: HTMLAttributes<HTMLDivElement>;
}
