/**
 * Extract the query param "state", mixin ui-related data,
 * encode the parameter back and return the new redirect url.
 */
export const encodeOAuthState = <TData>(redirectUrl: string, uiData: TData): string => {
    const newRedirectUrl = redirectUrl.replace(/state=([^&]+)(?=(&|$))/gi, (val, stateVal) => {
        const newVal = JSON.stringify({
            backend: stateVal,
            ui: uiData,
        });

        return `state=${btoa(encodeURIComponent(newVal))}`;
    });

    return newRedirectUrl;
};
