import { domain, stateTree } from 'modules/data';
import {
    addArrayItem,
    ImmutableObject,
    mergeDeep,
    removeArrayItem,
    setIn,
    updateArrayItem,
    updateIn,
} from 'modules/immutable';

import {
    Action,
    ADD_REQUEST_COMMENT_RESPONSE,
    DELETE_COMMENT_ATTACHMENT_RESPONSE,
    LOAD_MORE_REQUESTS_RESPONSE,
    LOAD_MORE_SEARCH_RESULTS_RESPONSE,
    LOAD_PAGE_DATA,
    REFRESH_AMAX_PAY_XERO_BATCH_PAYMENT_STATUSES,
    RELOAD_REQUEST_LIST_RESPONSE,
    RELOAD_REQUEST_RESPONSE,
    RELOAD_REQUEST_SYNC_RESPONSE,
} from '../actions';

export default function (
    state: ImmutableObject<stateTree.Entities>,
    action: Action
): ImmutableObject<stateTree.Entities> {
    switch (action.type) {
        case LOAD_PAGE_DATA:
        case LOAD_MORE_REQUESTS_RESPONSE:
        case LOAD_MORE_SEARCH_RESULTS_RESPONSE:
        case RELOAD_REQUEST_RESPONSE:
        case RELOAD_REQUEST_SYNC_RESPONSE:
        case RELOAD_REQUEST_LIST_RESPONSE:
            return mergeDeep(state, action.payload.entities);

        case ADD_REQUEST_COMMENT_RESPONSE:
            return updateIn(
                state,
                ['requests', action.payload.requestId, 'history'],
                (history: domain.RequestHistoryEvent[]) => addArrayItem(history, action.payload.commentEvent)
            );

        case DELETE_COMMENT_ATTACHMENT_RESPONSE:
            return updateIn(
                state,
                ['requests', action.payload.request.requestId, 'history'],
                (history: domain.RequestHistoryEvent[]) =>
                    updateArrayItem(
                        history,
                        (e) =>
                            Boolean(
                                e.comment &&
                                    e.comment.attachments.find((a) => a.id === action.payload.request.attachmentId)
                            ),
                        (e): domain.RequestHistoryEvent => {
                            return {
                                ...e,
                                comment: {
                                    ...e.comment!,
                                    attachments: removeArrayItem(
                                        e.comment!.attachments,
                                        (a) => a.id === action.payload.request.attachmentId
                                    ),
                                },
                            };
                        }
                    )
            );

        case REFRESH_AMAX_PAY_XERO_BATCH_PAYMENT_STATUSES: {
            const newState = updateIn(
                state,
                ['requests', action.payload.requestId, 'details', 'items'],
                (items: domain.XeroAmaxPayBatchPaymentDetails['items']) => {
                    return items.map((item) => {
                        const updatedStatus = action.payload.items.find(
                            (itemStatus) => itemStatus.billInvoiceRequestId === item.xeroBillInvoiceRequestId
                        );

                        return {
                            ...item,
                            paymentStatus: updatedStatus?.status ?? item.paymentStatus,
                        };
                    });
                }
            );

            return setIn(
                newState,
                ['requests', action.payload.requestId, 'details', 'status'],
                action.payload.batchPaymentStatus
            );
        }

        default:
            return state;
    }
}
