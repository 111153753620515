import { Box, Button } from '@approvalmax/ui/src/components';
import styled from 'styled-components';

export const Root = styled(Box)`
    position: relative;
    max-height: 100vh;
    overflow: auto;
`;

export const CloseButton = styled(Button)`
    position: absolute;
    top: 32px;
    left: 44px;
`;
