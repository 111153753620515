import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const List = styled.ul`
    padding-top: 10px;
    list-style-type: disc;
    list-style-position: inside;
`;

export const ListItem = styled.li`
    margin-bottom: 5px;
    ${font(13, '#000', 'semibold')};
`;

export const ListItemText = styled.span`
    margin-right: 5px;
`;
