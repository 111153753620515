import { mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { DividerProps } from './Divider.types';

export const Root = styled.hr<StyledMods<Pick<DividerProps, 'color' | 'orientation'> & { spacing?: string }>>`
    display: flex;
    border: 0;
    padding: 0;
    margin: ${({ $spacing }) => $spacing};

    ${mods.color(
        (value) => css`
            ${mods.orientation.horizontal`
                border-top: 1px solid ${theme.color[value]};
                height: 1px;
            `}

            ${mods.orientation.vertical`
                border-right: 1px solid ${theme.color[value]};
                height: auto;
                flex-shrink: 0;
                align-self: stretch;
            `};
        `
    )};
`;
