import { Popup } from '@approvalmax/ui';
import { miscHelpers } from '@approvalmax/utils';
import { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { userBetaFeaturesStatusState } from 'shared/states';

import { cancelActivePopup } from './actions';
import AccountSetupPopup from './containers/AccountSetupPopup/AccountSetupPopup';
import CreateOrJoinPopup from './containers/CreateOrJoinPopup/CreateOrJoinPopup';
import NewOrganisationPopup from './containers/NewOrganisationPopup/NewOrganisationPopup';
import ReloginAcceptInvitationPopup from './containers/ReloginAcceptInvitationPopup/ReloginAcceptInvitationPopup';
import { ActivePopup } from './reducers/moduleReducer';
import { getActivePopup, getNewOrganisationData } from './selectors/moduleSelectors';

const FirstStart: FC = () => {
    const dispatch = useDispatch();
    const activePopup = useSelector(getActivePopup);
    const newOrgData = useSelector(getNewOrganisationData);
    const isFirstStart = Boolean(newOrgData?.firstStart);
    const fromNoCompanies = newOrgData?.fromNoCompanies;
    const { isBelgradeUINewOrg } = useRecoilValue(userBetaFeaturesStatusState);

    const handleCancelActivePopup = () => {
        dispatch(cancelActivePopup());
    };

    return (
        <>
            <Popup
                isOpen={activePopup === ActivePopup.CreateOrJoinPopup}
                onRequestClose={miscHelpers.noop}
                disableAutoClose
            >
                <CreateOrJoinPopup />
            </Popup>

            <Popup isOpen={activePopup === ActivePopup.AccountSetupPopup} onRequestClose={handleCancelActivePopup}>
                <AccountSetupPopup />
            </Popup>

            {!isBelgradeUINewOrg && (
                <Popup
                    isOpen={activePopup === ActivePopup.NewOrganisationPopup}
                    onRequestClose={handleCancelActivePopup}
                    disableAutoClose={isFirstStart}
                >
                    <NewOrganisationPopup
                        isFirstStart={isFirstStart}
                        fromNoCompanies={fromNoCompanies}
                        closePopup={handleCancelActivePopup}
                    />
                </Popup>
            )}

            <Popup
                isOpen={activePopup === ActivePopup.ReloginAcceptInvitation}
                onRequestClose={handleCancelActivePopup}
                disableAutoClose
            >
                <ReloginAcceptInvitationPopup onClose={handleCancelActivePopup} />
            </Popup>
        </>
    );
};

export default memo(FirstStart);
