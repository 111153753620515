import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { getCanShowSubscribeAfterTrialButton } from 'shared/helpers/experiments';

import { showRequestCreationState } from './UserActionsToolbar.states';

export const useHideRequestCreation = () => {
    const setShowRequestCreation = useSetRecoilState(showRequestCreationState);

    useEffect(() => {
        setShowRequestCreation(false);

        return () => {
            setShowRequestCreation(true);
        };
    }, [setShowRequestCreation]);
};

export const useShowSubscribeAfterTrialButton = () => {
    const profile = useSelector(selectors.profile.findProfile);
    const companies = useSelector(selectors.company.getCompanies);

    const canShowSubscribeAfterTrialButton =
        companies.length === 1 ? getCanShowSubscribeAfterTrialButton(companies[0], profile) : false;

    const creatableTemplates = useSelector((state: State) => {
        return selectors.meta.getCreatableTemplatesByCompanyId(state, companies[0]?.id);
    });

    return {
        showSubscribeAfterTrialButton: canShowSubscribeAfterTrialButton && creatableTemplates.length === 0,
        companyId: companies[0]?.id,
    };
};
