import { selectors } from 'modules/common';
import { useCallback } from 'react';

import { standaloneFakeId } from './components/TypeOfDocsStep/TypeOfDocsStep.constants';
import { workflowWizardOpenTemplateIdKey } from './WorkflowWizardPopup.constants';

export const useOpenStorageKey = () => {
    const removeStorageKey = useCallback(() => {
        localStorage.removeItem(workflowWizardOpenTemplateIdKey);
    }, []);

    const isOpenedTemplate = useCallback((template: selectors.types.ExpandedTemplate) => {
        const savedId = localStorage.getItem(workflowWizardOpenTemplateIdKey);

        if (template.integrationCode) {
            return savedId === template.id;
        } else {
            return savedId === standaloneFakeId;
        }
    }, []);

    const saveOpenTemplateId = useCallback((template: selectors.types.ExpandedTemplate) => {
        localStorage.setItem(
            workflowWizardOpenTemplateIdKey,
            template.integrationCode ? template.id : standaloneFakeId
        );
    }, []);

    return {
        removeStorageKey,
        isOpenedTemplate,
        saveOpenTemplateId,
    };
};
