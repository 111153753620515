import { messages } from './messages';

export const PAGE_ID = 'requestList';
export const TEMPLATE_REQUESTS_LIST_PAGE_ID = 'template_requests_list';
export const COMPANY_REQUESTS_LIST_PAGE_ID = 'company_requests_list';
export const MY_REQUESTS_LIST_PAGE_ID = 'my_requests_list';
export const SEARCH_REQUESTS_LIST_PAGE_ID = 'search_requests_list';
export const MOBILE_DECISION_PAGE_ID = 'mobile_decision';

export enum QueryKeys {
    TemplateRequestsListPage = 'TemplateRequestsListPage',
    CompanyRequestsListPage = 'CompanyRequestsListPage',
    MyRequestsListPage = 'MyRequestsListPage',
    MobileDecisionPageData = 'MobileDecisionPageData',
    OldRequestsSupportingPageData = 'OldRequestsSupportingPageData',
}

export enum RequestListFilter {
    MyDecisionRequired = 'myDecisionRequired',
    MyReviewRequired = 'MyReviewRequired',
    MyOcrRequests = 'MyOcrRequests',
    MyOnlyOpen = 'myOnlyOpen',
    MyReadyToPay = 'myReadyToPay',
    MyDraft = 'myDraft',
}

export enum CompanyRequestListFilter {
    CompanyMyDecisionRequired = 'companyMyDecisionRequired',
    CompanyMyReviewRequired = 'companyMyReviewRequired',
    CompanyMyOpen = 'companyMyOpen',
    CompanyAllOpen = 'companyAllOpen',
    CompanyAll = 'companyAll',
    CompanyAllOnHold = 'companyAllOnHold',
}

export enum TemplateRequestListFilter {
    AllByType = 'allByType',
    OnReview = 'onReview',
    OnApproval = 'onApproval',
    Approved = 'approved',
    Cancelled = 'cancelled',
    Rejected = 'rejected',
    OnHold = 'onHold',
    Paid = 'paid',
    Billed = 'billed',
    NotBilled = 'notBilled',
    AwaitingPayment = 'awaitingPayment',
    PartiallyPaid = 'partiallyPaid',
    Failed = 'failed',
    Processing = 'processing',
    GrnNotReceived = 'GrnNotReceived',
    GrnPartiallyReceived = 'GrnPartiallyReceived',
    GrnFullyReceived = 'GrnFullyReceived',
    Accepted = 'accepted',
    Declined = 'declined',
    Credited = 'credited',
    PartiallyReceived = 'PartiallyReceived',
    PendingReceipt = 'PendingReceipt',
    PendingBill = 'PendingBill',
    Closed = 'Closed',
}

export const isTemplateRequestListFilter = (value: string): value is TemplateRequestListFilter => {
    return Object.values(TemplateRequestListFilter).some((item) => item === value);
};

export const RequestListFilterMeta: Record<
    TemplateRequestListFilter,
    {
        title: string;
    }
> = {
    [TemplateRequestListFilter.AllByType]: {
        title: messages.allFilterTitle,
    },
    [TemplateRequestListFilter.OnReview]: {
        title: messages.onReviewFilterTitle,
    },
    [TemplateRequestListFilter.OnApproval]: {
        title: messages.onApprovalFilterTitle,
    },
    [TemplateRequestListFilter.Approved]: {
        title: messages.approvedFilterTitle,
    },
    [TemplateRequestListFilter.Cancelled]: {
        title: messages.cancelledFilterTitle,
    },
    [TemplateRequestListFilter.Rejected]: {
        title: messages.rejectedFilterTitle,
    },
    [TemplateRequestListFilter.OnHold]: {
        title: messages.onHoldFilterTitle,
    },
    [TemplateRequestListFilter.Billed]: {
        title: messages.billedFilterTitle,
    },
    [TemplateRequestListFilter.NotBilled]: {
        title: messages.notBilledFilterTitle,
    },
    [TemplateRequestListFilter.Paid]: {
        title: messages.paidFilterTitle,
    },
    [TemplateRequestListFilter.AwaitingPayment]: {
        title: messages.awaitingPayment,
    },
    [TemplateRequestListFilter.PartiallyPaid]: {
        title: messages.partiallyPaid,
    },
    [TemplateRequestListFilter.Failed]: {
        title: messages.failed,
    },
    [TemplateRequestListFilter.Processing]: {
        title: messages.processing,
    },
    [TemplateRequestListFilter.GrnNotReceived]: {
        title: messages.grnNotReceived,
    },
    [TemplateRequestListFilter.GrnPartiallyReceived]: {
        title: messages.grnPartiallyReceived,
    },
    [TemplateRequestListFilter.GrnFullyReceived]: {
        title: messages.grnFullyReceived,
    },
    [TemplateRequestListFilter.Accepted]: {
        title: messages.acceptedFilterTitle,
    },
    [TemplateRequestListFilter.Declined]: {
        title: messages.declinedFilterTitle,
    },
    [TemplateRequestListFilter.Credited]: {
        title: messages.creditedFilterTitle,
    },
    [TemplateRequestListFilter.PartiallyReceived]: {
        title: messages.partiallyReceivedTitle,
    },
    [TemplateRequestListFilter.PendingReceipt]: {
        title: messages.pendingReceiptTitle,
    },
    [TemplateRequestListFilter.PendingBill]: {
        title: messages.pendingBillTitle,
    },
    [TemplateRequestListFilter.Closed]: {
        title: messages.closedTitle,
    },
};
