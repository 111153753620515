export const addMentionExtensionTags = (text: string, placeholderList: string[]) => {
    let newText = text;

    placeholderList?.forEach((placeholder) => {
        newText = newText.replaceAll(
            new RegExp(`\\[(${placeholder})\\](?!</span>)`, 'g'),
            `<span data-type="mention" class="mention" data-placeholdername="${placeholder}">[${placeholder}]</span>`
        );
    });

    return newText;
};
