import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, LOAD_PAGE_DATA } from '../../actions';
import { NetSuiteContext } from '../../data/netsuite/NetSuiteContext';

export type NetSuiteContextType = ImmutableObject<NetSuiteContext>;

const INITIAL_STATE: NetSuiteContextType = undefined as any;

export default function (state = INITIAL_STATE, action: Action): NetSuiteContextType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return immutable(action.payload.netSuiteContext!);

        default:
            return state;
    }
}
