import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.div<StyledMods<Mods<'active'> & { bigTopOffset: boolean }>>`
    background: ${theme.color.white100};
    position: absolute;
    top: 54px;
    right: -320px;
    bottom: 0;
    width: 320px;
    z-index: 1;
    transition: right 0.3s;
    overflow-x: hidden;

    ${mods.active.true`
        right: 0;
        width: 320px;
        border-left: 1px solid ${theme.color.midnight50};
        border-top: 1px solid ${theme.color.midnight50};
        border-radius: 6px 0 0 0;
  `};

    ${mods('bigTopOffset', true)`
        top: 94px;
    `};
`;
