import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionQboFraudBypassing', {
    title: 'Pulling from connected organisation',
    subtitle:
        'What is to happen if ApprovalMax detects Bills in QuickBooks Online, which did not pass through the approval workflow in ApprovalMax?',
    optionDoNotPull: 'Do not pull this document to ApprovalMax',
    optionPull: 'Pull this document to ApprovalMax',
    dateDescription:
        'Select from which date onwards this control is to be enforced. All {templateName} with a date equal or later will be pulled to ApprovalMax as approved.',
    datePlaceholder: 'Pick the date...',
});
