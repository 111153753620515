import { Button, CheckboxEditor, Field, OrSeparator, TextButton, TextEditor } from '@approvalmax/ui';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';
import TrustThisDeviceHint from 'shared/components/TrustThisDeviceHint/TrustThisDeviceHint';

import { useTwoFaActionWizardContext } from '../../TwoFaActionWizard.context';
import { TwoFaActionWizardProps } from '../../TwoFaActionWizard.types';
import { messages } from './ScreenEmailCode.messages';
import { AnotherAction, Content, Description, Root, TrustThisDeviceContainer } from './ScreenEmailCode.styles';

const qa = bemFactory.qa('pro-email-code-popup');

const ScreenEmailCode = memo<TwoFaActionWizardProps>((props) => {
    const { onEnterEmailCode, onSendCodeViaEmail, isTrustThisDeviceEnabled, onSuccess, title } = props;

    const { setActiveScreen } = useTwoFaActionWizardContext();
    const inputRef = useRef<HTMLInputElement>(null);
    const [emailCode, setEmailCode] = useState('');
    const [trustThisDevice, setTrustThisDevice] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const canSubmit = Boolean(emailCode.trim()) && !isLoading;

    useEffect(() => {
        if (isError) {
            inputRef.current!.focus();
        }
    }, [isError]);

    useEffect(() => {
        onSendCodeViaEmail?.();
    }, [onSendCodeViaEmail]);

    const onSubmit = useCallback(async () => {
        if (!canSubmit) return;

        setIsLoading(true);

        try {
            await onEnterEmailCode?.(emailCode.trim(), trustThisDevice);
            onSuccess?.();
        } catch {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [canSubmit, onEnterEmailCode, emailCode, trustThisDevice, onSuccess]);

    const onSwitchScreen = useCallback(() => {
        setActiveScreen('methodSelect');
    }, [setActiveScreen]);

    return (
        <Root qa={qa()} title={title}>
            <Content>
                <Description>{messages.description}</Description>

                <Field title={messages.codeFieldTitle} errorText={isError && messages.wrongCodeErrorText}>
                    <TextEditor
                        focusOnMount
                        disabled={isLoading}
                        value={emailCode}
                        onChange={setEmailCode}
                        placeholder={messages.emailCodePlaceholder}
                        invalid={isError}
                        ref={inputRef}
                        onEnter={onSubmit}
                    />
                </Field>

                {isTrustThisDeviceEnabled && (
                    <TrustThisDeviceContainer>
                        <CheckboxEditor onChange={setTrustThisDevice} value={trustThisDevice}>
                            {messages.trustThisDevice}
                        </CheckboxEditor>

                        <TrustThisDeviceHint />
                    </TrustThisDeviceContainer>
                )}

                <Button execute={onSubmit} disabled={!canSubmit}>
                    {messages.confirmButton}
                </Button>

                <OrSeparator />

                <AnotherAction>
                    <TextButton disabled={isLoading} execute={onSwitchScreen}>
                        {messages.anotherMethodButton}
                    </TextButton>
                </AnotherAction>
            </Content>
        </Root>
    );
});

export default ScreenEmailCode;
