import { RequestDataParams } from '@approvalmax/data';
import { notificationService } from 'services/notification';
import { useMutateLegacy } from 'shared/data/api';

import { integrationsApiPaths } from '../paths';
import { messages } from './useRefreshNetSuiteCache.messages';
import { RefreshNetSuiteCacheParams } from './useRefreshNetSuiteCache.types';

export const useRefreshNetSuiteCache = () => {
    return useMutateLegacy<RequestDataParams<RefreshNetSuiteCacheParams>>(integrationsApiPaths.refreshNetSuiteCache, {
        mutationOptions: {
            onSuccess: () => {
                notificationService.showInfoToast(messages.successMessages);
            },
        },
    });
};
