import { theme } from '@approvalmax/theme';
import { Flex } from '@approvalmax/ui/src/components';
import styled from 'styled-components';

export const Root = styled(Flex)`
    position: sticky;
    left: 0;
    z-index: 1;
    padding: 0 12px 0 40px;
    background: ${theme.color.white100};
`;
