import { arrayHelpers } from '@approvalmax/utils';

export enum LoggerSeverity {
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
}

export interface LoggerMessage {
    severity: LoggerSeverity;
    message: string;
    data?: unknown;
}

export interface ILogger {
    info(message: string, data?: unknown): void;
    warn(message: string, data?: unknown): void;
    error(message: string, data?: unknown): void;
}

export default class Logger implements ILogger {
    private _messages: LoggerMessage[] = [];
    private _onLog: (messages: LoggerMessage[]) => void;

    constructor(options: { onLog(messages: LoggerMessage[]): void }) {
        this._onLog = options.onLog;
    }

    public error(message: string) {
        this._logMessage(LoggerSeverity.Error, `ERROR: ${message}`);
    }

    public warn(message: string) {
        this._logMessage(LoggerSeverity.Warning, `WARNING: ${message}`);
    }

    public info(message: string) {
        this._logMessage(LoggerSeverity.Info, message);
    }

    public getData() {
        return this._messages;
    }

    private _logMessage(severity: LoggerSeverity, message: string, data?: unknown) {
        this._messages = arrayHelpers.cloneImmutableArray(this._messages).concat({
            severity,
            message,
            data,
        });
        this._onLog(this._messages);
    }
}
