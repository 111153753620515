import { domain } from 'modules/data';

import {
    Action,
    CLEAR_VERSION_HISTORY,
    CLOSE_VERSION_HISTORY_PANEL,
    FETCH_VERSION_HISTORY,
    FETCH_VERSION_HISTORY_FAILURE,
    FETCH_VERSION_HISTORY_RESPONSE,
    OPEN_VERSION_HISTORY_PANEL,
} from '../../actions';

export interface VersionHistory {
    templates: domain.WorkflowVersion[];
    hasMore: boolean;
    isOpen: boolean;
    isFetching: boolean;
}

const versionHistoryReducer = (
    state: VersionHistory = { templates: [], hasMore: false, isOpen: false, isFetching: false },
    action: Action
): VersionHistory => {
    switch (action.type) {
        case FETCH_VERSION_HISTORY:
        case FETCH_VERSION_HISTORY_FAILURE:
            return {
                ...state,
                isFetching: true,
            };

        case FETCH_VERSION_HISTORY_RESPONSE: {
            const filteredPayloadTemplates = action.payload.templates.filter(
                (payloadTemplate) => !state.templates.includes(payloadTemplate)
            );

            return {
                ...state,
                templates: [...state.templates, ...filteredPayloadTemplates],
                hasMore: action.payload.hasMore,
                isFetching: false,
            };
        }

        case OPEN_VERSION_HISTORY_PANEL:
            return {
                ...state,
                isOpen: true,
            };

        case CLOSE_VERSION_HISTORY_PANEL:
            return {
                ...state,
                isOpen: false,
            };

        case CLEAR_VERSION_HISTORY:
            return {
                templates: [],
                hasMore: false,
                isOpen: state.isOpen,
                isFetching: false,
            };

        default:
            return state;
    }
};

export default versionHistoryReducer;
