import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    UseVerifyTFADisablingAppCodeRequest,
    UseVerifyTFADisablingAppCodeResponse,
} from './useVerifyTFADisablingAppCode.types';

export const useVerifyTFADisablingAppCode = () => {
    return useMutateLegacy<
        RequestDataParams<UseVerifyTFADisablingAppCodeRequest>,
        UseVerifyTFADisablingAppCodeResponse
    >(twoFaApiPaths.disablingVerifyAppCode);
};
