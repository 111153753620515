import { miscHelpers } from '@approvalmax/utils';
import { UseFido2AssertionOptionsResponse } from 'shared/data';

export const getCredentials = async (assertionOptions: UseFido2AssertionOptionsResponse) => {
    const publicKey: PublicKeyCredentialRequestOptions = {
        rpId: assertionOptions.relayingPartyId,
        challenge: miscHelpers.base64ToBytes(assertionOptions.challenge),
        userVerification: assertionOptions.userVerification,
        allowCredentials: assertionOptions.allowCredentials.map((credential) => ({
            ...credential,
            id: miscHelpers.base64ToBytes(credential.id),
        })),
    };

    const credentials = (await navigator.credentials.get({
        publicKey,
    })) as PublicKeyCredential | null;

    if (!credentials) {
        throw new Error('No credentials found');
    }

    return credentials;
};

export const getAssertionTranfer = (
    credentials: PublicKeyCredential,
    assertionOptions: UseFido2AssertionOptionsResponse
) => {
    const response = credentials.response as AuthenticatorAssertionResponse;

    return {
        id: credentials.id,
        type: credentials.type,
        optionsId: assertionOptions.id,
        rawId: miscHelpers.arrayBufferToBase64(credentials.rawId),
        response: {
            authenticatorData: miscHelpers.arrayBufferToBase64(response.authenticatorData),
            clientDataJson: miscHelpers.arrayBufferToBase64(credentials.response.clientDataJSON),
            signature: miscHelpers.arrayBufferToBase64(response.signature),
        },
        extensions: {},
    };
};
