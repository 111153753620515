import { Radio } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionLockDate.messages';
import { LockDateSectionProps } from './SectionLockDate.types';

export const SectionLockDate: FC<LockDateSectionProps> = memo((props) => {
    const { template, templateSettings, links, qa, onChangeLockDatePolicy, readonly } = props;

    const templateName = selectors.template.getTemplateDisplayNameByCode(template.integrationCode, true);

    const lockDatePolicy =
        (templateSettings.lockDatePolicySettings! || {}).lockDatePolicy ||
        domain.TemplateSettingsLockDatePolicy.LockApproval;

    let lockApprovalText;

    switch (template.integrationCode) {
        case domain.IntegrationCode.XeroInvoice:
        case domain.IntegrationCode.XeroCreditNotesReceivable:
        case domain.IntegrationCode.XeroCreditNotesPayable: {
            lockApprovalText = messages.optionLockDateXeroOnly;
            break;
        }

        case domain.IntegrationCode.QBooksBill:
        case domain.IntegrationCode.QBooksExpense:
        case domain.IntegrationCode.QBooksPo:
        case domain.IntegrationCode.QBooksJournalEntry:
        case domain.IntegrationCode.XeroBillBatchPayment:
        case domain.IntegrationCode.XeroManualJournal: {
            lockApprovalText = messages.optionLockDateAmaxOnly;
            break;
        }

        case domain.IntegrationCode.QBooksInvoice: {
            lockApprovalText = messages.optionLockDate({
                templateName,
                integrationName: selectors.integration.getIntegrationTypeName(domain.IntegrationType.QBooks),
            });
            break;
        }

        default:
            lockApprovalText = messages.optionLockDate({
                templateName,
                integrationName: selectors.integration.getIntegrationTypeName(domain.IntegrationType.Xero),
            });
    }

    return (
        <SettingsSection
            title={messages.title}
            learnMoreLink={links[selectors.types.Link.LockDateHandling]}
            data-qa={qa('lock-date')}
        >
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={lockDatePolicy}
                    onChange={onChangeLockDatePolicy}
                    name='lockDatePolicy'
                    block
                >
                    <Radio value={domain.TemplateSettingsLockDatePolicy.LockApproval}>{lockApprovalText}</Radio>

                    <Radio value={domain.TemplateSettingsLockDatePolicy.ApproveWithNextDay}>
                        {messages.optionApproveWithNextDay}
                    </Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

SectionLockDate.displayName = 'SectionLockDate';
