import { stateTree } from 'modules/data';
import { addArrayItem, removeArrayItem, set } from 'modules/immutable';

import { Action, UPDATE_HIDDEN_HELP_ITEM, UPDATE_USER_PREFERENCE } from '../actions';

export const HIDDEN_HELP_ITEMS_USER_PREFERENCE = 'hiddenHelpItems';

export default function (state: stateTree.UserPreferences, action: Action): stateTree.UserPreferences {
    switch (action.type) {
        case UPDATE_USER_PREFERENCE:
            if (state[action.payload.userPreferenceKey] === action.payload.value) {
                return state;
            }

            return set(state, action.payload.userPreferenceKey, action.payload.value);

        case UPDATE_HIDDEN_HELP_ITEM: {
            let hiddenItems: string[] = state[HIDDEN_HELP_ITEMS_USER_PREFERENCE];

            if (!hiddenItems) {
                hiddenItems = [];
            }

            if (!action.payload.hidden) {
                hiddenItems = removeArrayItem(hiddenItems, action.payload.itemId);
            } else {
                hiddenItems = addArrayItem(hiddenItems, action.payload.itemId);
            }

            return set(state, HIDDEN_HELP_ITEMS_USER_PREFERENCE, hiddenItems);
        }

        default:
            return state;
    }
}
