import { ClockIcon, MailIcon, UserDeletedIcon } from '@approvalmax/ui';
import { Avatar } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useMemo } from 'react';

import { defaultIconSize } from './UserAvatar.constants';
import { UserAvatarProps } from './UserAvatar.types';

const UserAvatar: FC<UserAvatarProps> = memo((props) => {
    const { user, size = 24, removed = false, title } = props;

    const status = useMemo(() => {
        let status = domain.CompanyUserStatus.Active;

        if (selectors.company.isExpandedCompanyUser(user)) {
            status = user.status;
        }

        return status;
    }, [user]);

    if (removed) {
        return (
            <Avatar size={size} color='silver100' alt={domain.CompanyUserStatus.Invited} title={title}>
                <UserDeletedIcon size={defaultIconSize[size as unknown as keyof typeof defaultIconSize]} />
            </Avatar>
        );
    }

    switch (status) {
        case domain.CompanyUserStatus.Invited: {
            return (
                <Avatar size={size} color='silver100' alt={domain.CompanyUserStatus.Invited} title={title}>
                    <ClockIcon size={defaultIconSize[size as unknown as keyof typeof defaultIconSize]} />
                </Avatar>
            );
        }

        case domain.CompanyUserStatus.NotInvited:
        case domain.CompanyUserStatus.Rejected: {
            return (
                <Avatar size={size} color='silver100' alt={domain.CompanyUserStatus.Invited} title={title}>
                    <MailIcon size={defaultIconSize[size as unknown as keyof typeof defaultIconSize]} />
                </Avatar>
            );
        }

        default:
            return <Avatar size={size} alt={user.displayName} src={user.avatar} title={title} />;
    }
});

export default UserAvatar;
