import navigationReducer from './resources/reducers/navigationReducer';
import pageReducer from './resources/reducers/pageReducer';

export const pageConfig = {
    id: 'auth',
    store: {
        id: 'login_page',
        pageReducer,
        reducers: {
            navigation: navigationReducer,
        },
    },
};
