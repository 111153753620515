import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { DefaultPageLayout } from 'modules/page';
import bemFactory from 'react-bem-factory';
import { useSelector } from 'react-redux';
import { useShouldNewOrgPopupNoCompanies } from 'shared/hooks/useShouldNewOrgPopupNoCompanies';
import styled from 'styled-components';

import { useNoCompaniesBreadcrumbs } from '../../hooks/useBreadcrumbs';
import Steps from './steps/Steps';
import WhatYouCanDoNow from './whatYouCanDoNow/WhatYouCanDoNow';

const qa = bemFactory.qa('no-companies-page');

const Card = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background: #fff;
`;

const i18nPrefix = '@NoCompanies';

const PageContent = () => {
    const startupData = useSelector(selectors.session.getStartupData);
    const pendingInvitations = useSelector(selectors.companyInvitation.getPendingCompanyInvitations);
    const shouldOpenNewOrgPopup = useShouldNewOrgPopupNoCompanies();

    useNoCompaniesBreadcrumbs();

    return (
        <DefaultPageLayout
            documentTitle={intl.formatMessage({
                id: `${i18nPrefix}.documentTitle`,
                defaultMessage: 'There is nothing here yet',
            })}
        >
            <Card data-qa={qa('card')}>
                {!startupData.needsToCompleteSignupWizard && <Steps />}

                <WhatYouCanDoNow openNewOrgPopopOnMount={pendingInvitations.length === 0 && shouldOpenNewOrgPopup} />
            </Card>
        </DefaultPageLayout>
    );
};

export default PageContent;
