import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { helpers as pageHelpers } from 'modules/page';
import { useEffect } from 'react';
import { defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';

const i18nPrefix = 'page.hooks.useNonExistentCompanyError';
const messages = defineMessages({
    companyNotFound: {
        id: `${i18nPrefix}.companyNotFound`,
        defaultMessage: 'Company not found',
    },
});

export const useNonExistentCompanyError = (companyId: string) => {
    const company = useSelector((state: State) => selectors.company.findCompanyById(state, companyId));

    useEffect(() => {
        if (!company) {
            pageHelpers.redirectAndNotify({ errorMessage: intl.formatMessage(messages.companyNotFound) });
        }
    }, [company]);

    return {
        company,
    };
};
