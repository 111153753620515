import { progress } from '@approvalmax/ui';
import { groupBy } from 'lodash';
import { actions, selectors } from 'modules/common';
import { backend, domain, stateTree } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { api } from 'services/api';
import { routingService } from 'services/routing';

export const LOAD_PAGE_DATA = 'NOCOMPANIES/LOAD_PAGE_DATA';
export const loadPageData = () => createAction(LOAD_PAGE_DATA);

export const RESPOND_TO_INVITATION = 'NOCOMPANIES/RESPOND_TO_INVITATION';
export const RESPOND_TO_INVITATION_RESPONSE = 'NOCOMPANIES/RESPOND_TO_INVITATION_RESPONSE';
export const RESPOND_TO_INVITATION_FAILURE = 'NOCOMPANIES/RESPOND_TO_INVITATION_FAILURE';

export const respondToInvitation = (responses: actions.InvitationResponse[]) => {
    const validResponses = responses.filter((r) => {
        return r.invitation.status === domain.CompanyInvitationStatus.Untouched;
    });

    return createAsyncAction({
        request: () =>
            createAction(RESPOND_TO_INVITATION, {
                responses: validResponses,
            }),

        response: async (request) => {
            progress.inc();

            const responsesByCompanyId = groupBy(validResponses, (r) => r.invitation.companyId);

            try {
                await Promise.all(
                    Object.entries(responsesByCompanyId).map(([companyId, responses]) =>
                        api.companies.respondToInvitation({
                            companyId,
                            responses: responses.map((x) => {
                                const response =
                                    x.response === domain.CompanyInvitationResponse.Accepted
                                        ? backend.ResponseToInvitation.Accepted
                                        : backend.ResponseToInvitation.Declined;

                                return {
                                    companyId: x.invitation.companyId,
                                    response,
                                };
                            }),
                        })
                    )
                );
            } finally {
                progress.dec();
            }

            return createAction(RESPOND_TO_INVITATION_RESPONSE, {
                request,
            });
        },

        failure: (error, request) =>
            createErrorAction(RESPOND_TO_INVITATION_FAILURE, error, {
                request,
            }),

        rejectOnFailure: true,
    });
};

export const RESPOND_TO_PRACTICE_INVITATION = 'NOCOMPANIES/RESPOND_TO_PRACTICE_INVITATION';
export const RESPOND_TO_PRACTICE_INVITATION_RESPONSE = 'NOCOMPANIES/RESPOND_TO_PRACTICE_INVITATION_RESPONSE';
export const RESPOND_TO_PRACTICE_INVITATION_FAILURE = 'NOCOMPANIES/RESPOND_TO_PRACTICE_INVITATION_FAILURE';
export const respondToPracticeInvitation = (selectedAccountId?: string) =>
    createAsyncAction({
        request: (state: stateTree.State) => {
            const invitations = selectors.practiceInvitations.getPracticeInvitations(state);
            const responseData = invitations.map((inite) => {
                const response =
                    inite.id === selectedAccountId
                        ? backend.transfers.PracticeInvitationResponse.Accepted
                        : backend.transfers.PracticeInvitationResponse.Rejected;

                return {
                    accountId: inite.id,
                    response,
                };
            });

            return createAction(RESPOND_TO_PRACTICE_INVITATION, {
                responseData,
            });
        },

        response: async (request) => {
            await api.account.respondToPracticeInvitation({ invitations: request.responseData });

            return createAction(RESPOND_TO_PRACTICE_INVITATION_RESPONSE, {
                request,
            });
        },

        failure: (error, request) =>
            createErrorAction(RESPOND_TO_PRACTICE_INVITATION_FAILURE, error, {
                request,
            }),

        rejectOnFailure: true,
    });

export const UPDATE_USER_CONTEXT = 'NOCOMPANIES/UPDATE_USER_CONTEXT';
export const UPDATE_USER_CONTEXT_RESPONSE = 'NOCOMPANIES/UPDATE_USER_CONTEXT_RESPONSE';
export const UPDATE_USER_CONTEXT_FAILURE = 'NOCOMPANIES/UPDATE_USER_CONTEXT_FAILURE';
export const updateUserContext = () =>
    createAsyncAction({
        request: () => createAction(UPDATE_USER_CONTEXT, {}),

        response: async (request, _, dispatch) => {
            const context = await api.companies.getUserContext({});

            dispatch(
                actions.loadInitialAppData({
                    context,
                })
            );

            return createAction(UPDATE_USER_CONTEXT_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(UPDATE_USER_CONTEXT_FAILURE, error, {}),
        didDispatchResponse: () => {
            routingService.pushToDefaultPath();
        },
    });
export type Action = ExtractActions<
    typeof loadPageData | typeof respondToInvitation | typeof respondToPracticeInvitation | typeof updateUserContext
>;
