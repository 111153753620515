import { hooks } from 'modules/page';
import { useCallback, useState } from 'react';

export const useTwoFaSoftEnforcement = () => {
    const [stage, setStage] = useState<'start' | 'wizard' | 'end'>('start');

    const authenticated = hooks.useProtectedAuth();

    const handleWizardStart = useCallback(() => {
        setStage('wizard');
    }, [setStage]);

    const handleWizardEnd = useCallback(() => {
        setStage('end');
    }, [setStage]);

    return {
        stage,
        authenticated,
        handleWizardStart,
        handleWizardEnd,
    };
};
