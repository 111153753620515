import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { ElementFactory } from 'react-bem-factory';

import { CheckValidFraudBypassing, TemplateSettingsChangeHandler } from '../../WorkflowSettingsPopupContent.types';

export interface SectionNetSuiteFraudBypassingProps {
    template: selectors.types.ExpandedTemplate;
    templateSettings: domain.TemplateSettings;
    savedTemplateSettings: domain.TemplateSettings;
    effectiveDateLimits: selectors.types.EffectiveDateLimits | null;
    checkValidFraudBypassing: CheckValidFraudBypassing;
    onChange: TemplateSettingsChangeHandler;
    qa: ElementFactory;
    readonly: boolean;
}

export enum FraudBypassingOption {
    Pull = 'Pull',
    DoNotPull = 'DoNotPull',
}
