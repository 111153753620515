import { atom } from 'recoil';

export interface ContentOcrNotificationState {
    buttonText?: string;
    description?: string;
}

export const ocrNotificationPopupOpenState = atom({
    key: 'ocrNotificationPopupOpenState',
    default: false,
});

export const contentOcrNotificationState = atom<ContentOcrNotificationState>({
    key: 'contentOcrNotificationState',
    default: {},
});
