import { browserHelpers, errorHelpers, intl } from '@approvalmax/utils';
import { actions } from 'modules/common';
import { domain } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { defineMessages } from 'react-intl';
import { api } from 'services/api';
import { gaService } from 'services/ga';
import { routingService } from 'services/routing';
import { storageService } from 'services/storage';
import { signUpOauthProviderStorageKey } from 'shared/constants';
import { AccountPath, getAccountUrl, getPartnerUrl, getPath, Path } from 'urlBuilder';

import { ReloginResult, SsoData } from '../reducers/moduleReducer';

const i18nPrefix = 'first-start/actions/index';
const messages = defineMessages({
    signupErrorGeneral: {
        id: `${i18nPrefix}.signupErrorGeneral`,
        defaultMessage: 'Oops, something went wrong. Please try again.',
    },
});

export const SHOW_SIGN_UP_CONSENT_POPUP = 'FIRST-START/SHOW_SIGN_UP_CONSENT_POPUP';
export const showSignUpConsentPopup = (options: SsoData) =>
    createAction(SHOW_SIGN_UP_CONSENT_POPUP, {
        options,
    });

export const SHOW_CREATE_OR_JOIN_POPUP = 'FIRST-START/SHOW_CREATE_OR_JOIN_POPUP';
export const showCreateOrJoinPopup = () => createAction(SHOW_CREATE_OR_JOIN_POPUP, {});

export const SHOW_RELOGIN_ACCEPT_INVITATION_POPUP = 'FIRST-START/SHOW_RELOGIN_ACCEPT_INVITATION_POPUP';
export const showReloginAcceptInvitationPopup = () => createAction(SHOW_RELOGIN_ACCEPT_INVITATION_POPUP, {});

export const SET_RELOGIN_RESULT = 'FIRST_START/SET_RELOGIN_RESULT';
export const setReloginResult = (reloginResult: ReloginResult) =>
    createAction(SET_RELOGIN_RESULT, {
        reloginResult,
    });

export const CANCEL_ACTIVE_POPUP = 'FIRST-START/CANCEL_ACTIVE_POPUP';
export const cancelActivePopup = () => createAction(CANCEL_ACTIVE_POPUP, {});

export const SIGN_UP_WITH_CODE = 'FIRST-START/SIGN_UP_WITH_CODE';
export const SIGN_UP_WITH_CODE_RESPONSE = 'FIRST-START/SIGN_UP_WITH_CODE_RESPONSE';
export const SIGN_UP_WITH_CODE_FAILURE = 'FIRST-START/SIGN_UP_WITH_CODE_FAILURE';
export const signUpWithCode = (options: {
    oauthProvider: domain.OAuthProvider;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    signUpCode: string;
    subscribe: boolean;
    app?: domain.SsoLoginApplication;
    startingPoint?: domain.SsoStartingPoint;
    redirectUrl?: string;
    qualificationQuestionsAnswers?: object;
}) =>
    createAsyncAction({
        request: () =>
            createAction(SIGN_UP_WITH_CODE, {
                ...options,
            }),

        response: async (request) => {
            const params = {
                transientToken: options.signUpCode,
                email: options.email,
                phone: options.phone,
                firstName: options.firstName,
                lastName: options.lastName,
                acceptedProductNewsEmails: options.subscribe,
                sendConfirmationLink: true,
                timeZoneForEmail: window.ApprovalMax.userTimeZone,
                qualificationQuestionAnswers: options.qualificationQuestionsAnswers,
            };

            switch (options.oauthProvider) {
                case domain.OAuthProvider.Xero:
                    await api.auth.registerViaXeroOAuth(params);
                    break;

                case domain.OAuthProvider.QBooks:
                    await api.auth.registerViaQBooksOAuth(params);
                    break;

                case domain.OAuthProvider.Google:
                    await api.auth.registerViaGoogleOAuth(params);
                    break;

                case domain.OAuthProvider.Microsoft:
                    await api.auth.registerViaMicrosoftOAuth(params);
                    break;

                default:
                    throw errorHelpers.assertNever(options.oauthProvider);
            }

            return createAction(SIGN_UP_WITH_CODE_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(SIGN_UP_WITH_CODE_FAILURE, error, {}),

        willDispatchResponse: async (request) => {
            storageService.setAuthenticated(true);

            let signUpMethod = '';

            switch (options.oauthProvider) {
                case domain.OAuthProvider.Xero: {
                    signUpMethod = 'xero_sso';

                    break;
                }

                case domain.OAuthProvider.QBooks: {
                    signUpMethod = 'qbo_sso';

                    break;
                }

                case domain.OAuthProvider.Google: {
                    signUpMethod = 'google_sso';

                    break;
                }

                case domain.OAuthProvider.Microsoft: {
                    signUpMethod = 'microsoft_sso';

                    break;
                }
            }

            if (options.oauthProvider && browserHelpers.isMobile()) {
                localStorage.setItem(signUpOauthProviderStorageKey, options.oauthProvider);
            }

            await gaService.sendEvent('sign_up', {
                method: signUpMethod,
            });

            if (request.app) {
                let url;

                switch (request.app) {
                    case domain.SsoLoginApplication.Account:
                        url = getAccountUrl(AccountPath.root);
                        break;

                    case domain.SsoLoginApplication.Partner:
                        url = getPartnerUrl();
                        break;

                    default:
                        throw errorHelpers.assertNever(request.app);
                }

                routingService.navigateToExternalUrl(url);

                return;
            }

            if (request.redirectUrl) {
                routingService.reloadToUrl(request.redirectUrl);

                return;
            }

            routingService.reloadToUrl(getPath(Path.signUpWithFinalize, options.oauthProvider));
        },

        willDispatchError: (request, error, state, dispatch) => {
            const errorCode = errorHelpers.getErrorCode(error);

            let message;

            switch (errorCode) {
                default:
                    message = intl.formatMessage(messages.signupErrorGeneral);
                    break;
            }

            dispatch(actions.addErrorToast(message));
        },
    });

export type Action = ExtractActions<
    | typeof cancelActivePopup
    | typeof setReloginResult
    | typeof showCreateOrJoinPopup
    | typeof showReloginAcceptInvitationPopup
    | typeof showSignUpConsentPopup
    | typeof signUpWithCode
>;
