import { Reference } from '@approvalmax/types';

import { BillingAddress } from './Request.NetSuite';

export enum NetSuitePayeeType {
    Vendor = 'Vendor',
    Employee = 'Employee',
}

export interface NetSuitePayee extends Reference {
    type?: NetSuitePayeeType;
    address?: BillingAddress[] | null;
    currencyIds?: string[] | null;
    defaultBillingAddress?: BillingAddress | null;
    defaultPayablesAccount?: Reference | null;
    defaultCurrencyId?: string | null;
}
