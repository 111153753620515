import { Button } from '@approvalmax/ui/src/components';
import { CloseIcon } from '@approvalmax/ui/src/icons';
import { ComponentProps, FC } from 'react';
import { CloseButton as ToastifyCloseButton } from 'react-toastify';

const CloseButton: FC<ComponentProps<typeof ToastifyCloseButton>> = (props) => {
    const { closeToast } = props;

    return (
        <Button onClick={closeToast} icon size='small' color='white100'>
            <CloseIcon size={20} />
        </Button>
    );
};

export default CloseButton;
