import { ReactNode } from 'react';

import { getStore } from './helpers';

class NotificationService {
    showInfoToast(message: ReactNode, timeout?: number) {
        const options =
            typeof timeout === 'object'
                ? timeout
                : {
                      timeout,
                  };

        import('modules/common').then((common) => getStore().dispatch(common.actions.addInfoToast(message, options)));
    }

    showErrorToast(message: ReactNode) {
        import('modules/common').then((common) => getStore().dispatch(common.actions.addErrorToast(message)));
    }
}

export const notificationService = new NotificationService();
