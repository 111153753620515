import { messages } from './AllFeatureTrialPaywallPopup.messages';
import { TrialFeature } from './AllFeatureTrialPaywallPopup.types';

export const xeroAdvancedFeatures = [
    {
        id: TrialFeature.XeroAdvancedBillToPOMatching,
        title: messages.xeroAdvancedBillToPOMatching,
    },
    {
        id: TrialFeature.XeroAdvancedBudgetChecking,
        title: messages.xeroAdvancedBudgetChecking,
    },
    {
        id: TrialFeature.XeroAdvancedContactApprovals,
        title: messages.xeroAdvancedContactApprovals,
    },
    {
        id: TrialFeature.XeroAdvancedAutoApprovals,
        title: messages.xeroAdvancedAutoApprovals,
    },
    {
        id: TrialFeature.XeroAdvancedSalesQuoteApprovals,
        title: messages.xeroAdvancedSalesQuoteApprovals,
    },
    {
        id: TrialFeature.XeroAdvancedManualJournalApprovals,
        title: messages.xeroAdvancedManualJournalApprovals,
    },
    {
        id: TrialFeature.XeroAdvancedGoodsReceivedNotes,
        title: messages.xeroAdvancedGoodsReceivedNotes,
    },
    {
        id: TrialFeature.XeroAdvancedWorkflowVersionHistory,
        title: messages.xeroAdvancedWorkflowVersionHistory,
    },
    {
        id: TrialFeature.XeroAdvancedRequestReviewStep,
        title: messages.xeroAdvancedRequestReviewStep,
    },
    {
        id: TrialFeature.XeroAdvancedAccrualReports,
        title: messages.xeroAdvancedAccrualReports,
    },
];

export const xeroPremiumFeatures = [
    {
        id: TrialFeature.XeroPremiumBatchPaymentApprovals,
        title: messages.xeroPremiumBatchPaymentApprovals,
    },
    {
        id: TrialFeature.XeroPremiumPaymentsWithAirwallex,
        title: messages.xeroPremiumPaymentsWithAirwallex,
    },
    {
        id: TrialFeature.XeroPremiumStandAloneWorkflows,
        title: messages.xeroPremiumStandAloneWorkflows,
    },
];

export const xeroNotTrialPremiumFeatures = [
    {
        id: TrialFeature.XeroPremiumPublicAPI,
        title: messages.xeroPremiumPublicAPI,
    },
    {
        id: TrialFeature.XeroPremiumPrioritySupport,
        title: messages.xeroPremiumPrioritySupport,
    },
];

export const qboAdvancedFeatures = [
    {
        id: TrialFeature.QboAdvancedBillToPOMatching,
        title: messages.qboAdvancedBillToPOMatching,
    },
    {
        id: TrialFeature.QboAdvancedVendorApprovals,
        title: messages.qboAdvancedVendorApprovals,
    },
    {
        id: TrialFeature.QboAdvancedAutoApprovals,
        title: messages.qboAdvancedAutoApprovals,
    },
    {
        id: TrialFeature.QboAdvancedJournalEntryApprovals,
        title: messages.qboAdvancedJournalEntryApprovals,
    },
    {
        id: TrialFeature.QboAdvancedGoodsReceivedNotes,
        title: messages.qboAdvancedGoodsReceivedNotes,
    },
    {
        id: TrialFeature.QboAdvancedWorkflowVersionHistory,
        title: messages.qboAdvancedWorkflowVersionHistory,
    },
    {
        id: TrialFeature.QboAdvancedRequestReviewStep,
        title: messages.qboAdvancedRequestReviewStep,
    },
];

export const qboPremiumFeatures = [
    {
        id: TrialFeature.QboPremiumStandAloneWorkflows,
        title: messages.qboPremiumStandAloneWorkflows,
    },
    {
        id: TrialFeature.QboPremiumPrioritySupport,
        title: messages.qboPremiumPrioritySupport,
    },
];

export const addOnsFeatures = [
    {
        id: TrialFeature.AddonsCapture,
        title: messages.addOnCaptureTitle,
    },
    {
        id: TrialFeature.AddonsPay,
        title: messages.addOnPayTitle,
    },
];
