import { Box, Select, Text } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateAmountTypeInActiveMatrix } from '../../../../actions';
import { getActiveMatrix } from '../../../../selectors/pageSelectors';
import { messages } from './AmountConditionHeader.messages';
import { AmountConditionHeaderProps } from './AmountConditionHeader.types';

const AmountConditionHeader: FC<AmountConditionHeaderProps> = memo((props) => {
    const { readonly, canChangeAmount, currency } = props;

    const dispatch = useDispatch();
    const amountType = useSelector(getActiveMatrix)?.amountType;

    const onChange = useCallback(
        (value: domain.AmountType) => {
            dispatch(updateAmountTypeInActiveMatrix(value));
        },
        [dispatch]
    );

    const conditionItems = useMemo(
        () => [
            {
                id: domain.AmountType.Gross,
                name: messages.totalAmount({ currency }),
            },
            {
                id: domain.AmountType.Net,
                name: messages.netAmount({ currency }),
            },
        ],
        [currency]
    );

    return (
        <Box width='172px' spacing='12 0'>
            {readonly || !canChangeAmount ? (
                <Text font='body' fontSize='small' color='midnight80' fontWeight='medium'>
                    {messages.amount({ currency })}
                </Text>
            ) : (
                <div>
                    <Select
                        value={amountType}
                        items={conditionItems}
                        textActivatorHoverColor='blue100'
                        onChange={onChange}
                        size='small'
                        noInput
                        dropdownWidth='max-content'
                        fontWeight='medium'
                    />
                </div>
            )}
        </Box>
    );
});

export default AmountConditionHeader;
