import { FC } from 'react';

import { Flex } from '../../../Flex/Flex';
import { useStepperContext } from '../../Stepper.context';
import { useStepContext } from '../Step/Step.context';
import { HorizontalLine, VerticalLine } from './Connector.styles';
import type { ConnectorProps } from './Connector.types';

export const Connector: FC<ConnectorProps> = (props) => {
    const { highlighted } = props;

    const { direction, color } = useStepperContext();
    const { completed } = useStepContext();

    const borderColor = completed || highlighted ? color : 'midnight50';

    return (
        <Flex inline alignItems='center' justifyContent='center'>
            {direction === 'horizontal' ? (
                <HorizontalLine $color={borderColor} />
            ) : (
                <VerticalLine $color={borderColor} />
            )}
        </Flex>
    );
};

Connector.displayName = 'Step.Connector';
