import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import { UseDeleteRequestData, UseDeleteRequestResponse } from './useDeleteRequest.types';

export const useDeleteRequest = (
    mutationOptions?: MutationOptions<UseDeleteRequestResponse, RequestDataParams<UseDeleteRequestData>>
) => {
    return useMutateLegacy<RequestDataParams<UseDeleteRequestData>, UseDeleteRequestResponse>(requestsApiPaths.delete, {
        mutationOptions,
    });
};
