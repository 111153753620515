import { selectors } from 'modules/common';
import { loadUserProfileData } from 'modules/common/actions';
import { findUserById } from 'modules/common/selectors/userSelectors';
import { domain } from 'modules/data';
import { useDispatch, useSelector } from 'modules/react-redux';
import { useCallback } from 'react';

export const useGetUserWithProfileInfo = (user?: selectors.types.ExpandedCompanyUser | null) => {
    const dispatch = useDispatch();
    const foundUser = useSelector((state) => findUserById(state, user?.id || ''));
    const profileInfo = foundUser?.profileInfo;

    const hasAccessToUserProfile = user?.status === domain.CompanyUserStatus.Active;

    const getUserWithProfileInfo = useCallback(() => {
        if (hasAccessToUserProfile && !user.profileInfo) {
            dispatch(loadUserProfileData(user.id));
        }

        if (!foundUser) {
            return null;
        }

        return { ...foundUser, status: user?.status };
    }, [dispatch, foundUser, hasAccessToUserProfile, user?.id, user?.profileInfo, user?.status]);

    const loading = hasAccessToUserProfile && !profileInfo;

    return { getUserWithProfileInfo, loading };
};
