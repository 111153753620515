import { Reference } from '@approvalmax/types';

export const anyValueReferenceId = 'Any';

export const anyValueReference: Reference = {
    id: anyValueReferenceId,
    text: 'Any value',
};

export enum TaxType {
    TaxExclusive = 'Tax Exclusive',
    TaxInclusive = 'Tax Inclusive',
    NoTax = 'No Tax',
}

export enum RequestResolutionOrigin {
    ResolvedResolvedRegularly = 'ResolvedResolvedRegularly',
    EnforcedExternally = 'EnforcedExternally',
    EnforcedByAdmin = 'EnforcedByAdmin',
    ResolvedAutomatically = 'ResolvedAutomatically',
    ChangedApprovers = 'ChangedApprovers',
    ResolvedExternally = 'ResolvedExternally',
}

export enum RequestsFraudulentActivity {
    None = 'None',
    PostApprovalChange = 'PostApprovalChange',
    ExternalResolving = 'ExternalResolving',
    AutomaticResolving = 'AutomaticResolving',
    AdminEnforcedResolving = 'AdminEnforcedResolving',
    DroppingOffApprovers = 'DroppingOffApprovers',
}

export enum RequestAuditReportState {
    Complete = 'Complete',
    Underway = 'Underway',
    Reserved = 'Reserved',
}

export enum NullableBoolean {
    Yes = 'Yes',
    No = 'No',
}

export enum RequestMatchingStatus {
    Matched = 'Matched',
    NotMatched = 'NotMatched',
}

export enum RetrospectivePurchaseOrder {
    IsRetrospective = 'IsRetrospective',
    IsNotRetrospective = 'IsNotRetrospective',
}
