import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { authApiPaths } from '../paths';
import { UseResetPasswordData, UseResetPasswordResponse } from './useResetPassword.types';

export const useResetPassword = (
    mutationOptions?: MutationOptions<UseResetPasswordResponse, RequestDataParams<UseResetPasswordData>>
) => {
    return useMutateLegacy<RequestDataParams<UseResetPasswordData>, UseResetPasswordResponse>(
        authApiPaths.resetPassword,
        {
            mutationOptions,
        }
    );
};
