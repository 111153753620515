import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.MatrixPopupContent.AmountConditionCell', {
    overOrEqualTo: 'Over or equal to',
    between: 'Between',
    under: 'Under',
    anyAmount: 'Any amount',
    changeTheAmountRule: 'Change the amount rule',
    overOrEqualToGv: 'Over or equal to {gv}',
    underLv: 'Under {lv}',
    betweenGvLv: 'Between {gv}-{lv}',
    notSet: 'not set',
    enterValue: 'Enter value',
});
