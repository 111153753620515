import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { allPagesSlices } from 'modules/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { hasChangesState } from 'shared/states';

export const useHasChangesLegacy = () => {
    const setHasChanges = useSetRecoilState(hasChangesState);

    const hasChangesLegacy = useSelector((state: State) => {
        const pageId = selectors.navigation.getActivePageId(state);
        const pageSlice = allPagesSlices.find((page) => page.id && page.id === pageId);

        return pageSlice?.hasChanges?.(state) || false;
    });

    useEffect(() => {
        setHasChanges(hasChangesLegacy);
    }, [hasChangesLegacy, setHasChanges]);
};
