import { statics } from 'modules/common';
import moment, { Moment } from 'moment';

/**
 * @returns True if dates are not equal
 */
export const compareDates = (
    newDate: Moment | string | undefined | null,
    originalDate: Moment | string | undefined | null
) => {
    return Boolean(
        originalDate ? (newDate && moment(newDate).toISOString()) !== moment(originalDate).toISOString() : newDate
    );
};

export const getNowAtTimeZone = (timeZone: Parameters<typeof statics.timeZone.findTimeZoneById>[0]) => {
    const tz = statics.timeZone.findTimeZoneById(timeZone);
    const tzUtcOffset = statics.timeZone.getUtcOffset(tz);

    return statics.timeZone.addOffset(new Date(), tzUtcOffset).toDate();
};

export const findDateFromMaxDate = (dateTo: Date | null) =>
    dateTo ? statics.timeZone.subtractOffset(dateTo, statics.timeZone.getLocalUtcOffset()).toDate() : undefined;

export const findDateToMinDate = (dateFrom: Date | null, todayDate: Date) => {
    if (dateFrom) {
        return statics.timeZone.subtractOffset(dateFrom, statics.timeZone.getLocalUtcOffset()).toDate();
    }

    return moment.utc(todayDate).toDate();
};
