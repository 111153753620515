import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList', {
    allFilterTitle: 'All',
    onReviewFilterTitle: 'On Review',
    onApprovalFilterTitle: 'On Approval',
    approvedFilterTitle: 'Approved',
    cancelledFilterTitle: 'Cancelled',
    rejectedFilterTitle: 'Rejected',
    onHoldFilterTitle: 'On Hold',
    billedFilterTitle: 'Billed',
    notBilledFilterTitle: 'Not Billed',
    paidFilterTitle: 'Paid',
    acceptedFilterTitle: 'Accepted',
    declinedFilterTitle: 'Declined',
    awaitingPayment: 'Awaiting payment',
    partiallyPaid: 'Partially paid',
    failed: 'Failed',
    processing: 'Processing',
    grnNotReceived: 'Not Received',
    grnPartiallyReceived: 'Partially Received',
    grnFullyReceived: 'Fully Received',
    searchResultsPageName: 'Search results',
    requestsPageName: 'Requests',
    deadlineText: 'Due {deadline}',
    deadlineTitle: 'Deadline - {deadline}',
    creditedFilterTitle: 'Credited',
    partiallyReceivedTitle: 'Partially Received',
    pendingReceiptTitle: 'Pending Receipt',
    pendingBillTitle: 'Pending Bill',
    closedTitle: 'Closed',
});
