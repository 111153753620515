import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Card = styled.div<StyledMods<Mods<'active'>>>`
    padding: 24px;
    height: 100%;
    border-radius: ${theme.radius.small};
    border: 1px solid ${theme.color.midnight50};
    display: block;
    cursor: pointer;

    ${mods.active.true`
        border-color: ${theme.color.blue100};
    `};
`;
