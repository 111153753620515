import { mods, theme } from '@approvalmax/theme';
import { Button } from '@approvalmax/ui/src/components';
import { ChevronUpIcon } from '@approvalmax/ui/src/icons';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { CollapseAllButtonProps } from './CollapseAllButton.types';

export const Icon = styled(ChevronUpIcon)`
    transition: ${theme.duration.medium}ms ease-in-out;
`;

export const CollapseButton = styled(Button)<StyledMods<Pick<CollapseAllButtonProps, 'collapsed'>>>`
    transition: ${theme.duration.medium}ms ease-in-out;

    ${mods.collapsed.true`
        ${Icon} {
            transform: rotate(180deg);
        }
    `};
`;
