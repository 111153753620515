import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Row = styled.div<{ noBorder?: boolean }>`
    display: flex;
    align-items: center;
    min-height: 50px;
    border-bottom: 1px solid #ebeaea;

    ${(props) => (props.noBorder ? 'border: none;' : '')}
`;

export const Block2faContainer = styled.div`
    width: 100%;
`;

export const Block2fa = styled.div`
    display: flex;
    flex: 1;
    padding-top: 15px;
`;

export const Block2faCol = styled.div<{ width?: string | 'auto' }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;

    ${(props) => (props.width ? `width: ${props.width};` : 'flex: 1;')}
`;

export const Block2faTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const Block2faSubtitle = styled.div`
    margin-top: 5px;
`;

export const Block2faToggle = styled.div`
    margin-top: -5px;
`;

export const Name = styled.div`
    ${font(13, '#000', 'semibold')}
`;

export const Note = styled.div`
    flex: 1;
    margin-left: 10px;
    ${font(13, '#7f7d7d')}
`;

export const Description = styled.div`
    ${font(12, '#000')}
    line-height: 16px;
`;

export const VerifyTwoFaContainer = styled.div`
    padding: 20px 30px;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
    background: #f9f9f9;
`;

export const VerifyTwoFaDescription = styled.div`
    text-align: center;
    ${font(13, '#6c6a6a')};
`;
