import { useMemo, useRef, useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import { notificationService } from 'services/notification';

import { useTwoFaEnablingWizardContext } from '../../TwoFaEnablingWizard.context';
import { messages } from './StepCodeScan.messages';

export const useStepCodeScan = () => {
    const { context } = useTwoFaEnablingWizardContext();
    const [isCodeVisible, setIsCodeVisible] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const copyToClipboard = useCopyToClipboard()[1];
    const setupCode = useCodeFormatter(context.setupCode || '');

    const onCodeShow = () => {
        setIsCodeVisible(true);
    };

    const onCopyToClipboard = () => {
        copyToClipboard(setupCode);

        notificationService.showInfoToast(messages.copyCodeResult);
    };

    return {
        inputRef,
        isCodeVisible,
        stateId: context.stateId,
        setupCode,
        onCodeShow,
        onCopyToClipboard,
    };
};

export const useCodeFormatter = (code: string) => {
    // Transform '1234567890' into '1234 5678 90'
    return useMemo(() => {
        return code
            .replace(/\s+/gi, '')
            .split('')
            .reduce((m, c, i, arr) => {
                m.push(c);

                if ((i + 1) % 4 === 0 && i < arr.length - 1) {
                    m.push(' ');
                }

                return m;
            }, [] as string[])
            .join('');
    }, [code]);
};
