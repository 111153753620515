import { Reference } from '@approvalmax/types';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import { backend } from 'modules/data';

import { messages } from './AirwallexContext.messages';

export function parseAirwallexContext(value: backend.RequestsAirwallexEditingContext): AirwallexContext {
    return {
        paymentPurposes: (value.paymentPurposes || []).map((purpose) => ({
            id: purpose,
            text: purpose === 'wages_salary' ? messages.wagesOrSalary : capitalize(lowerCase(purpose)),
        })),
        paymentFees: (value.paymentFees || []).map((fee) => ({ id: fee, text: fee })),
        requesterInstruction: null,
        currencies: value.currencies || [],
        lastUsedXeroBankAccounts: value.lastUsedXeroBankAccounts?.map((bankAccount) => ({
            currency: bankAccount.Currency,
            xeroBankAccountCode: bankAccount.XeroBankAccountCode,
            xeroBankAccountId: bankAccount.XeroBankAccountId,
            xeroBankAccountName: bankAccount.XeroBankAccountName,
        })),
    };
}

export interface AirwallexContext {
    paymentPurposes: Reference[];
    paymentFees: Reference[];
    currencies: string[];
    requesterInstruction: string | null;
    lastUsedXeroBankAccounts?: {
        currency: string;
        xeroBankAccountCode: string | null;
        xeroBankAccountId: string;
        xeroBankAccountName: string;
    }[];
}
