import { mods } from '@approvalmax/theme';
import { font } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const DisconnectedCompanyItem = styled.div`
    display: flex;
    align-items: center;
    margin: 12px 0 10px 0;
`;

export const DisconnectedCompanyIcon = styled.div`
    line-height: 0;
    margin: 0 10px 0 40px;
    flex: none;
`;

export const DisconnectedCompanyContent = styled.div<StyledMods<{ isManager: boolean }>>`
    display: flex;
    flex-flow: column;
    flex: 1;
    overflow: hidden;

    ${mods('isManager', false)`
        margin-right: 40px;
    `}
`;

export const DisconnectedCompanyTitle = styled.div`
    ${font(12, '#8c9ca7', 'semibold')}
`;

export const DisconnectedCompanyError = styled.div`
    ${font(11, '#a3341a', 'semibold')}
`;

export const DisconnectedCompanyReconnectBlock = styled.div`
    margin-left: 10px;
    margin-right: 30px;
`;
