import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';

import { moduleDef } from '..';
import { AutomationModuleData } from '../reducers/moduleReducer';

function getModule(state: stateTree.State) {
    return selectors.module.getModule<AutomationModuleData>(state, moduleDef.id);
}

export function getActivePopup(state: stateTree.State) {
    return getModule(state).activePopup;
}

export function getDevToolsPopupData(state: stateTree.State) {
    return getModule(state).devToolsPopup;
}
