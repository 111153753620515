import { Reference } from '@approvalmax/types';
import { BaseEditorProps, DropdownEditor } from '@approvalmax/ui';
import { PlainDataProvider } from 'modules/data-providers';
import moment from 'moment';
import React, { FC } from 'react';

export interface TimeReference extends Reference {
    hours: number;
    minutes: number;
}

export const allTimeReferences: TimeReference[] = new Array(24).fill(0).flatMap((x, hourIndex) =>
    [0, 30].map((minuteIndex) => ({
        id: `${String(hourIndex).padStart(2, '0')}:${String(minuteIndex).padStart(2, '0')}`,
        text: moment().set('h', hourIndex).set('m', minuteIndex).format('LT'),
        hours: hourIndex,
        minutes: minuteIndex,
    }))
);

interface TimePickerEditorProps extends BaseEditorProps<TimeReference | null> {
    buttonComponent?: React.ComponentType<any>;
}

export const TimePickerEditor: FC<TimePickerEditorProps> = (props) => {
    return (
        <PlainDataProvider items={allTimeReferences}>
            <DropdownEditor css='width: 75px;' placeholder='00:00' {...props} />
        </PlainDataProvider>
    );
};
