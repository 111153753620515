import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.XeroApproveReviewStep', {
    openReviewerMatrixButtonTitle: 'The review and coding matrix defines which values can be set by Reviewers.',
    emptyReviewerTabStepDescription:
        'Add Reviewers who will be able to change particular fields before the approval decision is made.',
    addReviewer: 'Add a Reviewer',
    approvers: 'Approvers',
    reviewers: 'Reviewers',
});
