import { random } from 'lodash';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { PaymentDetailsAudit } from './PaymentDetailsAudit';

export const Loading = memo(() => {
    return (
        <PaymentDetailsAudit
            createdBy={<Skeleton width={random(50, 150)} />}
            createdAt={<Skeleton width={70} />}
            modifiedBy={<Skeleton width={random(50, 150)} />}
            modifiedAt={<Skeleton width={70} />}
        />
    );
});

Loading.displayName = 'Loading';
