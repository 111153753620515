import { MutationOptions, RequestDataParams } from '@approvalmax/data';

import { useMutateLegacy } from '../../../api';
import { reportsApiPaths } from '../paths';
import { UseUpdateReportScheduleRequest } from './useUpdateReportSchedule.types';

export const useUpdateReportSchedule = (
    mutationOptions?: MutationOptions<never, RequestDataParams<UseUpdateReportScheduleRequest>>
) => {
    return useMutateLegacy<RequestDataParams<UseUpdateReportScheduleRequest>>(reportsApiPaths.updateReportSchedule, {
        mutationOptions,
    });
};
