import { useTheme } from '@approvalmax/theme';
import { AmLogo, AmLogo as AmLogoLightGreen, Link } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { hooks as pageHooks } from 'modules/page';
import { AppleAppStore, GooglePlay } from 'modules/sprites';
import { PageActiveModule } from 'pages/auth/reducers/page/moduleReducer';
import React, { FC, PropsWithChildren, useState } from 'react';
import { useMedia } from 'react-use';
import { amplitudeService } from 'services/amplitude';

import { messages } from './SimplePageLayout.messages';
import {
    Card,
    CardWrapper,
    Content,
    Logo,
    MobileAppLinks,
    MobileApps,
    MobileAppText,
    PageLayout,
    ScrollWrapper,
    StyledLink,
} from './SimplePageLayout.styles';

const BLINK_ANIMATION_DURATION_MS = 200;

export interface SimplePageLayoutProps extends PropsWithChildren {
    showMobileAppButtons?: boolean;
    activeModule?: PageActiveModule.LoginModule | PageActiveModule.SignupModule;
    documentTitle?: string | null;
    enableMobileAdaptive?: boolean;
    noContentWrapper?: boolean;
    oldDesign?: boolean;
}

const SimplePageLayout: FC<SimplePageLayoutProps> = (props) => {
    const {
        activeModule,
        children,
        showMobileAppButtons,
        documentTitle,
        enableMobileAdaptive,
        noContentWrapper,
        oldDesign,
    } = props;

    pageHooks.useDocumentTitle(documentTitle);

    const [fadeOut, setFadeOut] = useState(false);

    const onBlink = (callback: () => void) => {
        setFadeOut(true);

        setTimeout(() => {
            callback();
            setFadeOut(false);
            callback();
        }, BLINK_ANIMATION_DURATION_MS);
    };

    const sendDecisionToAmplitude = (os: string) => {
        amplitudeService.sendData('help: click on mobile app link on signup screen', {
            'mobile app type': os,
        });
    };

    const handleDownloadIOS = () => {
        if (activeModule === PageActiveModule.SignupModule) {
            sendDecisionToAmplitude('ios');
        }
    };

    const handleDownloadAndroid = () => {
        if (activeModule === PageActiveModule.SignupModule) {
            sendDecisionToAmplitude('android');
        }
    };

    const { theme } = useTheme();
    const isMobileScreen = useMedia(`(max-width: ${theme.container.breakpoint.xsmall})`);

    const content = React.Children.map(children, (child: React.ReactElement<any>) => {
        if (!child) {
            return undefined;
        }

        return React.cloneElement(child, {
            onBlink,
        });
    });

    return (
        <PageLayout $enableMobileAdaptive={enableMobileAdaptive} $oldDesign={oldDesign}>
            <ScrollWrapper $enableMobileAdaptive={enableMobileAdaptive}>
                {!noContentWrapper && (!enableMobileAdaptive || !isMobileScreen) ? (
                    <Content>
                        <Logo href='http://www.approvalmax.com/?stay' rel='noopener noreferrer'>
                            {oldDesign ? (
                                <AmLogo width={164} height={32} />
                            ) : (
                                <AmLogoLightGreen width={240} height={47} />
                            )}
                        </Logo>

                        <CardWrapper>
                            <Card fadeOut={fadeOut}>{content}</Card>
                        </CardWrapper>
                    </Content>
                ) : (
                    content
                )}
            </ScrollWrapper>

            {showMobileAppButtons && (
                <MobileApps $enableMobileAdaptive={enableMobileAdaptive}>
                    {(!enableMobileAdaptive || !isMobileScreen) && (
                        <MobileAppText>{intl.formatMessage(messages.downloadMobileAppsText)}</MobileAppText>
                    )}

                    <MobileAppLinks>
                        <StyledLink
                            onClick={handleDownloadIOS}
                            targetBlank
                            href={intl.formatMessage(messages.downloadIPhoneAppUrl)}
                        >
                            <AppleAppStore width={105} height={30} />
                        </StyledLink>

                        <Link
                            onClick={handleDownloadAndroid}
                            targetBlank
                            href={intl.formatMessage(messages.downloadAndroidAppUrl)}
                        >
                            <GooglePlay width={105} height={30} />
                        </Link>
                    </MobileAppLinks>
                </MobileApps>
            )}
        </PageLayout>
    );
};

export default SimplePageLayout;
