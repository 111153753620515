import { Mods, mods, theme } from '@approvalmax/theme';
import { Flex } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.div<StyledMods<Mods<'highlight'>>>`
    transition: 500ms ease-in-out;
    border-left: 0px solid ${theme.color.blue100};

    ${mods.highlight.true`
        border-left-width: 8px;
    `}
`;

export const Line = styled(Flex)`
    min-width: 100%;
    padding: 0 40px 0 0;
`;
