import { selectors } from 'modules/common';
import { createAction, ExtractActions, ThunkAction } from 'modules/react-redux';

import { MyCompanyDelegate, OptimisticSetDelegate } from '../reducers/moduleReducer';

export const SHOW_MY_DELEGATES_POPUP = 'PROFILE/SHOW_MY_DELEGATES_POPUP';

interface ShowMyDelegatesPopupAction {
    type: typeof SHOW_MY_DELEGATES_POPUP;
    delegates: MyCompanyDelegate[];
}

export function showMyDelegatesPopup(): ThunkAction {
    return (dispatch, getState) => {
        let state = getState();

        const me = selectors.profile.getProfileUser(state);
        const delegates = selectors.company.getCompanies(state).map((company) => {
            const delegatePair = company.delegates.find((delegate) => delegate.userId === me.id);
            const delegateUserProfileId = delegatePair?.delegateUserId
                ? selectors.user.getUserById(state, delegatePair.delegateUserId).databaseId
                : undefined;

            return {
                companyId: company.id,
                delegateUserProfileId,
                delegateId: delegatePair?.delegateUserId || null,
                delegateFrom: delegatePair?.delegateFrom || null,
                delegateTo: delegatePair?.delegateTo || null,
            };
        });

        dispatch<ShowMyDelegatesPopupAction>({
            type: SHOW_MY_DELEGATES_POPUP,
            delegates,
        });
    };
}

export const SET_DELEGATE = 'PROFILE/SET_DELEGATE';
export const setDelegate = (companyId: string, delegateId: string | null) =>
    createAction(SET_DELEGATE, {
        companyId,
        delegateId,
    });

export const SET_DELEGATE_FROM_DATE = 'PROFILE/SET_DELEGATE_FROM_DATE';
export const setDelegateFromDate = (companyId: string, delegateFrom: string | null) =>
    createAction(SET_DELEGATE_FROM_DATE, { companyId, delegateFrom });

export const SET_DELEGATE_TO_DATE = 'PROFILE/SET_DELEGATE_TO_DATE';
export const setDelegateToDate = (companyId: string, delegateTo: string | null) =>
    createAction(SET_DELEGATE_TO_DATE, { companyId, delegateTo });

export const APPLY_MY_DELEGATES_RESPONSE = 'PROFILE/APPLY_MY_DELEGATES_RESPONSE';
export const optimisticallySetDelegate = (request: OptimisticSetDelegate) =>
    createAction(APPLY_MY_DELEGATES_RESPONSE, {
        request,
    });

export type Action =
    | ExtractActions<
          typeof optimisticallySetDelegate | typeof setDelegate | typeof setDelegateFromDate | typeof setDelegateToDate
      >
    | ShowMyDelegatesPopupAction;
