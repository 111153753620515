import { immutable, ImmutableObject, merge, set } from 'modules/immutable';

import { reportPageSize } from '../../../request/config';
import {
    Action,
    APPLY_REPORT_FILTERS,
    CHANGE_REPORT_SORTING,
    DISCARD_CHANGES,
    EXECUTE_REPORT,
    EXECUTE_REPORT_FAILURE,
    EXECUTE_REPORT_FAILURE_IGNORE,
    EXECUTE_REPORT_RESPONSE,
    REORDER_REPORT_COLUMNS,
    SHOW_DASHBOARD,
    SHOW_NEW_REPORT_CARD,
    SHOW_REPORT_CARD,
} from '../../actions';
import { ReportRowData } from '../../types/ReportRowData';

export interface ActiveReport {
    pageIndex: number;
    lastPage: boolean;
    rows: ReportRowData[];
    loading: boolean;
    lastRequestId: string;
}

export type ActiveReportType = ImmutableObject<ActiveReport>;

const INITIAL_STATE: ActiveReportType = immutable<ActiveReport>({
    pageIndex: 0,
    lastPage: true,
    rows: [],
    loading: false,
    lastRequestId: '',
});

export default function (state = INITIAL_STATE, action: Action): ActiveReportType {
    switch (action.type) {
        case EXECUTE_REPORT:
            return merge(state, {
                loading: true,
                lastRequestId: action.payload.lastRequestId,
            });

        case EXECUTE_REPORT_RESPONSE:
            return merge(state, {
                loading: false,
                pageIndex: action.payload.request.pageIndex,
                lastPage: action.payload.rows.length <= reportPageSize,
                rows: action.payload.rows.slice(0, reportPageSize),
            });

        case EXECUTE_REPORT_FAILURE:
            return merge(state, {
                loading: false,
            });

        case EXECUTE_REPORT_FAILURE_IGNORE:
            return state;

        case REORDER_REPORT_COLUMNS: {
            // reorder data in cells
            const oldCol = action.payload.columns[action.payload.oldIndex];
            const newCol = action.payload.columns[action.payload.newIndex];
            const visibleColumns = action.payload.columns.filter((c) => c.visible);
            const localOldIndex = visibleColumns.indexOf(oldCol);
            const localNewIndex = visibleColumns.indexOf(newCol);

            const newRows = state!.rows.map((r) => {
                const transformedCells = [...r.cells];

                const movedColumn = transformedCells[localOldIndex];

                transformedCells.splice(localOldIndex, 1);
                transformedCells.splice(localNewIndex, 0, movedColumn);

                return {
                    ...r,
                    cells: transformedCells,
                };
            });

            return set(state, 'rows', newRows);
        }

        case SHOW_DASHBOARD:
        case SHOW_REPORT_CARD:
        case SHOW_NEW_REPORT_CARD:
        case CHANGE_REPORT_SORTING:
        case APPLY_REPORT_FILTERS:
        case DISCARD_CHANGES:
            // current data is invalid (columns change, report change etc), must reset
            return INITIAL_STATE;

        default:
            return state;
    }
}
