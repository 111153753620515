import { mixins, Mods, mods, RequiredMods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { TabsContextValue } from '../../Tabs.types';
import { TabBaseProps } from './Tab.types';

export const StartIcon = styled.div`
    margin-right: 8px;
`;

export const EndIcon = styled.div`
    margin-left: 8px;
`;

const rootSizeMixin = (
    fontSize: RequiredMods['fontSize'],
    paddingVertical: RequiredMods['spacing'],
    paddingHorizontal: RequiredMods['spacing']
) => css`
    padding: ${paddingVertical}px ${paddingHorizontal}px;
    ${mixins.font('label', fontSize)};

    ${mods.active.true`
        font-weight: ${theme.fontWeight.medium};
    `};
`;

export const Root = styled.li<StyledMods<Pick<TabBaseProps, 'width'>>>`
    ${mods('width')(
        (value) => css`
            width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};
`;

export const Inner = styled.div<
    StyledMods<
        Pick<TabBaseProps, 'disabled' | 'wrap' | 'divider'> &
            Mods<'active' | 'textAlign'> &
            Pick<TabsContextValue, 'color' | 'size'>
    >
>`
    cursor: pointer;
    text-transform: uppercase;
    color: ${theme.color.midnight80};
    transition: ${theme.duration.medium}ms;
    display: block;

    &:focus,
    &:hover {
        text-decoration: none;
    }

    ${mods.color(
        (value) => css`
            ${mods.disabled.false`
                &:hover,&:focus {
                    color: ${theme.color.blue100};
                    background-color: ${theme.color.blue10};

                    ${mods.active.true`
                        ${mods.divider.true`
                            box-shadow: inset 0 -2px 0 ${theme.color.blue100};
                        `}
                    `};
                }

                ${mods.active.true`
                    color: ${theme.color[value]};
                    ${mods.divider.true`
                        box-shadow: inset 0 -2px 0 ${theme.color[value]};
                    `}
                `};
            `};
        `
    )};

    ${mods.size.xsmall(rootSizeMixin('xsmall', 4, 8))};
    ${mods.size.small(rootSizeMixin('small', 8, 8))};
    ${mods.size.medium(rootSizeMixin('medium', 12, 12))};

    ${mods.disabled.true`
        pointer-events: none;
        opacity: 0.5;
    `};

    ${mods.active.true`
        border-width: 2px;
    `};

    ${mods.textAlign(mixins.textAlign)};

    ${mods.wrap.false`
        white-space: nowrap;
    `};
`;
