import { ExternalLink, RadioGroupEditor } from '@approvalmax/ui';
import { constants } from 'modules/common';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { TwoFaActionWizardProps } from '../../TwoFaActionWizard.types';
import { useMethodSelectPopup } from './ScreenMethodSelect.hooks';
import { messages } from './ScreenMethodSelect.messages';
import { ConfirmButton, Content, Note, Root } from './ScreenMethodSelect.styles';

const qa = bemFactory.qa('pro-method-select-popup');

const MethodSelectPanel = memo<TwoFaActionWizardProps>((props) => {
    const { isTFAAlternativeEmailEnabled, isTFABackupCodesEnabled } = props;
    const { method, onChange, onSubmit } = useMethodSelectPopup();

    return (
        <Root qa={qa()} title={messages.title}>
            <Content>
                <RadioGroupEditor value={method} onChange={onChange}>
                    <RadioGroupEditor.Item id='authenticatorAppCode'>{messages.appCodeRadio}</RadioGroupEditor.Item>

                    {isTFAAlternativeEmailEnabled && (
                        <RadioGroupEditor.Item id='emailCode'>{messages.emailCodeRadio}</RadioGroupEditor.Item>
                    )}

                    {isTFABackupCodesEnabled && (
                        <RadioGroupEditor.Item id='backupCode'>{messages.backupCodeRadio}</RadioGroupEditor.Item>
                    )}
                </RadioGroupEditor>

                <ConfirmButton execute={onSubmit}>{messages.nextButton}</ConfirmButton>

                <Note>
                    {messages.note({
                        supportlink: (chunks) => (
                            <ExternalLink href={constants.xeroConstants.XERO_TWO_FA_SUPPORT_LINK}>
                                {chunks}
                            </ExternalLink>
                        ),
                    })}
                </Note>
            </Content>
        </Root>
    );
});

export default MethodSelectPanel;
