import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { UseVerifyTFAEnablingCodeRequest, UseVerifyTFAEnablingCodeResponse } from './useVerifyTFAEnablingCode.types';

export const useVerifyTFAEnablingCode = (
    mutationOptions?: MutationOptions<
        UseVerifyTFAEnablingCodeResponse,
        RequestDataParams<UseVerifyTFAEnablingCodeRequest>
    >
) => {
    return useMutateLegacy<RequestDataParams<UseVerifyTFAEnablingCodeRequest>, UseVerifyTFAEnablingCodeResponse>(
        twoFaApiPaths.enablingAppCodeVerify,
        { mutationOptions }
    );
};
