import { domain } from 'modules/data';
import { immutable, ImmutableObject } from 'modules/immutable';

import {
    Action,
    APPLY_TEMPLATE_SETTINGS_RESPONSE,
    DISCARD_PAGE_STATE,
    DISCARD_SETTINGS_POPUP,
    OPEN_SETTINGS_POPUP_RESPONSE,
} from '../../actions';

export interface SettingsData {
    data: domain.TemplateSettings;
}

export type ActiveSettings = ImmutableObject<SettingsData> | null;

export default function activeSettingsReducer(state: ActiveSettings = null, action: Action): ActiveSettings {
    switch (action.type) {
        case OPEN_SETTINGS_POPUP_RESPONSE:
            return immutable<SettingsData>({
                data: action.payload.response,
            });

        case DISCARD_PAGE_STATE:
        case APPLY_TEMPLATE_SETTINGS_RESPONSE:
        case DISCARD_SETTINGS_POPUP:
            return null;

        default:
            return state;
    }
}
