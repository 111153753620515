import { Mods, useTheme } from '@approvalmax/theme';
import { Popup } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import { useMedia } from 'react-use';

import { TwoFaEnablingWizard } from '../TwoFaEnablingWizard/TwoFaEnablingWizard';
import { useTwoFaWizardController } from '../TwoFaEnablingWizard/TwoFaEnablingWizard.hooks';
import { SoftEnforcementStart } from './components';
import { SoftEnforcementActions } from './components/SoftEnforcementActions/SoftEnforcementActions';
import { SoftEnforcementEnd } from './components/SoftEnforcementEnd/SoftEnforcementEnd';
import { messages } from './components/SoftEnforcementStart/SoftEnforcementStart.messages';
import { useTwoFaSoftEnforcement } from './TwoFaSoftEnforcementTool.hooks';

export interface TwoFaSoftEnforcementToolProps extends Pick<Mods, 'open'> {
    onClose: VoidFunction;
}

export const TwoFaSoftEnforcementTool = memo<TwoFaSoftEnforcementToolProps>((props) => {
    const { open, onClose } = props;
    const { theme } = useTheme();
    const isMobileScreen = useMedia(`(max-width: ${theme.container.breakpoint.xsmall})`);

    const { authenticated, stage, handleWizardStart, handleWizardEnd } = useTwoFaSoftEnforcement();
    const { wizardController } = useTwoFaWizardController();

    if (!authenticated) {
        return null;
    }

    return (
        <Popup
            open={open}
            onToggle={onClose}
            size={stage === 'end' ? 'xsmall' : isMobileScreen ? 'small' : 'medium'}
            closable
        >
            <Popup.Header title={wizardController.title || messages.title} actions={<SoftEnforcementActions />} />

            <Popup.Body>
                {stage === 'start' && <SoftEnforcementStart onStart={handleWizardStart} />}

                {stage === 'wizard' && <TwoFaEnablingWizard onFinish={handleWizardEnd} />}

                {stage === 'end' && <SoftEnforcementEnd onFinish={onClose} />}
            </Popup.Body>
        </Popup>
    );
});

TwoFaSoftEnforcementTool.displayName = 'TwoFaSoftEnforcement';
