import { errorHelpers } from '@approvalmax/utils';
import { FC, memo, useMemo } from 'react';

import { DoneIcon, EmptyIcon, Line, MarkIcon, Root } from './ProgressFlowItem.styles';
import { ProgressFlowItemProps } from './ProgressFlowItem.types';

const ProgressFlowItem: FC<ProgressFlowItemProps> = memo((props) => {
    const { state, last } = props;

    const icon = useMemo(() => {
        switch (state) {
            case 'done':
                return <DoneIcon />;

            case 'active':
                return <MarkIcon />;

            case 'future':
                return <EmptyIcon />;

            default:
                throw errorHelpers.assertNever(state);
        }
    }, [state]);

    return (
        <Root $last={last}>
            {icon}

            {!last && <Line $last={last} $done={state === 'done'} />}
        </Root>
    );
});

export default ProgressFlowItem;
