import { EditFilledIcon } from '@approvalmax/ui';
import { Button, Grid, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { messages } from './AmaxPayBankAccountInfoCard.messages';
import { Root } from './AmaxPayBankAccountInfoCard.styles';
import { AmaxPayBankAccountInfoCardProps } from './AmaxPayBankAccountInfoCard.types';

export const AmaxPayBankAccountInfoCard = memo<AmaxPayBankAccountInfoCardProps>((props) => {
    const { name, bankLogo, iban, currencyCode, bankAccountNumber, sortCode, disconnected, onEdit } = props;

    return (
        <Root
            className='fs-exclude'
            $disconnected={disconnected}
            bordered
            borderColor='midnight60'
            radius='small'
            spacing='8 12'
            width={280}
        >
            <Grid gridTemplateColumns='max-content auto' columnGap={8}>
                {bankLogo}

                <Grid rowGap={4}>
                    <Grid gridTemplateColumns='auto max-content' alignItems='center' columnGap={4}>
                        <Text
                            color={disconnected ? 'midnight60' : 'midnight90'}
                            font='label'
                            fontSize='medium'
                            fontWeight='medium'
                            title={name}
                            ellipsis={1}
                        >
                            {name}
                        </Text>

                        {currencyCode && (
                            <Text color={disconnected ? 'midnight60' : 'midnight70'} font='label' fontSize='xsmall'>
                                {currencyCode}
                            </Text>
                        )}
                    </Grid>

                    {disconnected && (
                        <Text color='red100' font='label' fontSize='xsmall'>
                            {messages.disconnected}
                        </Text>
                    )}

                    <Grid gridTemplateColumns='auto max-content' alignItems='center' columnGap={4}>
                        <Grid rowGap={4}>
                            {bankAccountNumber && (
                                <Text color={disconnected ? 'midnight60' : 'midnight80'} font='label' fontSize='xsmall'>
                                    {messages.accountNumber({
                                        value: bankAccountNumber,
                                    })}
                                </Text>
                            )}

                            {sortCode && (
                                <Text color={disconnected ? 'midnight60' : 'midnight80'} font='label' fontSize='xsmall'>
                                    {messages.sortCode({
                                        value: sortCode,
                                    })}
                                </Text>
                            )}

                            {iban && (
                                <Text color={disconnected ? 'midnight60' : 'midnight80'} font='label' fontSize='xsmall'>
                                    {messages.iban({
                                        value: iban,
                                    })}
                                </Text>
                            )}
                        </Grid>

                        {onEdit && (
                            <Button icon onClick={onEdit} title={messages.changeBankAccount} color='midnight40'>
                                <EditFilledIcon size='16' />
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Root>
    );
});

AmaxPayBankAccountInfoCard.displayName = 'AmaxPayBankAccountInfoCard';
