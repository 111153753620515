import { Radio } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionLineItemSettings.messages';
import { SectionLineItemSettingsProps } from './SectionLineItemSettings.types';

const LineItemSettingsSection: FC<SectionLineItemSettingsProps> = memo((props) => {
    const { templateSettings, links, onChangeLineItemAvailableType, readonly, qa } = props;

    return (
        <SettingsSection
            title={messages.title}
            learnMoreLink={links[selectors.types.Link.LineItems]}
            data-qa={qa('line-item-settings-section')}
        >
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={templateSettings.lineItemSettings!.qbooksEnabledLineItemType}
                    onChange={onChangeLineItemAvailableType}
                    name='lineItemAvailableType'
                    block
                >
                    <Radio value={domain.TemplateSettingsQBooksEnabledLineItemType.All}>{messages.optionAll}</Radio>

                    <Radio value={domain.TemplateSettingsQBooksEnabledLineItemType.AccountBasedLines}>
                        {messages.optionAccountBasedLines}
                    </Radio>

                    <Radio value={domain.TemplateSettingsQBooksEnabledLineItemType.ItemBasedLines}>
                        {messages.optionItemBasedLines}
                    </Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default LineItemSettingsSection;
