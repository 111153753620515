import './invitationsListBlock.scss';

import { Button, CheckboxEditor } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { respondToInvitation } from '../../../actions';

const i18nPrefix = '@NoCompanies/no-invites';

const bem = bemFactory.block('invitations-list-block');
const qa = bemFactory.qa('invitations-list-block');

interface InvitationsListBlockProps {
    pendingInvitations: selectors.types.ExpandedCompanyInvitation[];
    next: () => void;
}

const InvitationsListBlock: FC<InvitationsListBlockProps> = (props) => {
    const { pendingInvitations, next } = props;

    const [checkedInvitations, setCheckedInvitations] = useState<string[]>([]);
    const loading = useSelector(selectors.ui.hasGlobalLoading);
    const dispatch = useDispatch();

    const onCheckedChange = (invId: string) => () => {
        const isChecked = checkedInvitations.includes(invId);

        if (isChecked) {
            setCheckedInvitations(checkedInvitations.filter((item) => item !== invId));
        } else {
            setCheckedInvitations([...checkedInvitations, invId]);
        }
    };

    const onAcceptCompanyInvitations = async () => {
        try {
            await dispatch(
                respondToInvitation(
                    checkedInvitations.map((invId) => ({
                        invitation: pendingInvitations.find((inv) => inv.id === invId)!,
                        response: domain.CompanyInvitationResponse.Accepted,
                    }))
                )
            );

            next();
        } catch {
            // empty
        }
    };

    return (
        <div className={bem()} data-qa={qa()}>
            <div className={bem('inv-list-header')}>
                <div className={bem('inv-list-title')}>
                    <FormattedMessage
                        id={`${i18nPrefix}.invListHeaderTitle`}
                        defaultMessage='You have pending invitations'
                    />
                </div>

                <Button execute={onAcceptCompanyInvitations} disabled={checkedInvitations.length === 0 || loading}>
                    <FormattedMessage id={`${i18nPrefix}.acceptInvitationsButtonText`} defaultMessage='Accept' />
                </Button>
            </div>

            <div className={bem('inv-list-list')}>
                {pendingInvitations.map((inv) => (
                    <div className={bem('inv-list-item')} key={inv.id}>
                        <div className={bem('inv-list-item-content')}>
                            <div className={bem('inv-list-item-title')}>{inv.companyName}</div>

                            <div className={bem('inv-list-item-description')}>
                                <FormattedMessage
                                    id={`${i18nPrefix}.invListItemDescriptionText`}
                                    defaultMessage='Invitation from {name}'
                                    values={{ name: inv.inviterUser.displayName }}
                                />
                            </div>
                        </div>

                        <div className={bem('inv-list-item-action')} data-qa={qa('inv-list-item-action')}>
                            <CheckboxEditor
                                value={checkedInvitations.includes(inv.id)}
                                onChange={onCheckedChange(inv.id)}
                            >
                                <FormattedMessage
                                    id={`${i18nPrefix}.invListItemCheckboxText`}
                                    defaultMessage='Accept'
                                />
                            </CheckboxEditor>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default memo(InvitationsListBlock);
