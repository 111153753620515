import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, INVALIDATE_SEARCH_CONTEXT, LOAD_SEARCH_CONTEXT_RESPONSE } from '../../actions';
import { SearchContext } from '../../types';

export type SearchContextType = ImmutableObject<SearchContext>;

const INITIAL_STATE: SearchContextType = immutable<SearchContext>({
    companies: [],
    lastLoaded: null,
});

export default function (state = INITIAL_STATE, action: Action): SearchContextType {
    switch (action.type) {
        case LOAD_SEARCH_CONTEXT_RESPONSE:
            return immutable({
                companies: action.payload.companyContexts,
                lastLoaded: action.payload.loadTime,
            });

        case INVALIDATE_SEARCH_CONTEXT:
            return immutable({
                companies: [],
                lastLoaded: null,
            });

        default:
            return state;
    }
}
