import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.BackendSection', {
    backend: 'Backend',
    active: 'Active: ',
    apply: 'Apply',
    reset: 'Reset',
    example: 'Example: localhost:3000',
    selectBackendFirst: 'Select a backend first.',
    myAccount: 'My account',
    slack: 'Slack',
    adminPanel: 'Admin panel',
    identityServer: 'Identity server',
    developerPortal: 'Developer portal',
});
