import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('profile.MyDelegatesPopup', {
    popupTitle: 'Out of Office',
    setDelegateButton: 'Set substitute',
    descriptionText: 'Specify the Substitutes who will be approving requests on your behalf',
    pleaseNoteText: 'Important:',
    timezoneHintText:
        "{pleaseNote} The dates you set are tied to the user profile's time zone. This means that the time zone you choose only affects how the saved dates are displayed. For example, if you delegate your authority to a colleague in a different time zone, you can select their time zone to ensure the dates are displayed correctly for them.",
    companyColumnTitle: 'Organisation',
    delegateColumnTitle: 'Substitute',
    startDateColumnTitle: 'Start date',
    endDateColumnTitle: 'End date',
    timeZone: 'Time zone',
    delegatesSet: 'Delegates Set',
    searchOrgPlaceholder: 'Search organisation...',
});
