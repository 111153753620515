import { Button } from '@approvalmax/ui';
import { Box, Flex, Form, Grid, Text } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useFido2CredentialsList } from 'shared/data';

import { PasskeyEdit } from '../PasskeyEdit/PasskeyEdit';
import { PasskeyItem } from '../PasskeyItem/PasskeyItem';
import { useCreatePasskey } from './PasskeysSettings.hooks';
import { Loading } from './PasskeysSettings.loading';
import { messages } from './PasskeysSettings.messages';
import { Title } from './PasskeysSettings.styles';
import { FormState } from './PasskeysSettings.types';

export const PasskeysSettings = memo(() => {
    const form = useForm<FormState>({
        defaultValues: { label: '', newKey: 'none' },
    });

    const {
        data: credentialsList,
        refetch: refetchCredentialsList,
        isLoading: isCredentialsListLoading,
    } = useFido2CredentialsList();

    const handleCancelForm = useCallback(() => {
        form.reset();
    }, [form]);

    const { isCreatingPasskey, createPasskey } = useCreatePasskey({
        onSuccess: handleCancelForm,
    });

    const handleAddPassKey = useCallback(() => {
        form.setValue('newKey', 'passkey');
    }, [form]);

    const handleAddKeySubmit = form.handleSubmit((data) => {
        const { label } = data;

        createPasskey(label);
    });

    const newKeyForm = form.watch('newKey');

    const hasCredentials = Boolean(credentialsList?.length);

    const showNewKeyForm = newKeyForm !== 'none';

    const showSetup = !hasCredentials && !showNewKeyForm;

    if (isCredentialsListLoading) {
        return <Loading />;
    }

    return (
        <Box width='100%' spacing='12 0'>
            <Flex inline alignItems='center' justifyContent='space-between' width='100%'>
                <Title>{messages.title}</Title>

                {hasCredentials && (
                    <Button theme='secondary' preset='compact' execute={handleAddPassKey}>
                        {messages.addPassKey}
                    </Button>
                )}

                {showSetup && (
                    <Button preset='compact' execute={handleAddPassKey}>
                        {messages.setup}
                    </Button>
                )}
            </Flex>

            <Grid gap={12} padding='12 0'>
                {showNewKeyForm && (
                    <Form form={form} onSubmit={handleAddKeySubmit}>
                        <Box color='midnight20' spacing='12 16'>
                            <PasskeyEdit isLoading={isCreatingPasskey} onCancel={handleCancelForm} />
                        </Box>
                    </Form>
                )}

                {credentialsList?.map((item) => {
                    return (
                        <PasskeyItem
                            key={item.credentialId}
                            id={item.credentialId}
                            label={item.label}
                            createdDate={item.createdDate}
                            onItemChange={refetchCredentialsList}
                        />
                    );
                })}

                {showSetup && (
                    <Text color='black100' font='body' fontSize='small'>
                        {messages.description}
                    </Text>
                )}
            </Grid>
        </Box>
    );
});

PasskeysSettings.displayName = 'PasskeysSettings';
