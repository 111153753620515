import { createContext, useContext } from 'react';

import type { StepContextParams } from './Step.types';

export const StepContext = createContext<StepContextParams>({} as StepContextParams);

StepContext.displayName = 'StepContext';

export const useStepContext = () => {
    const context = useContext(StepContext);

    if (!context) {
        throw new Error('The component must be a child of Step component');
    }

    return context;
};
