import { domHelpers } from '@approvalmax/utils';
import { forwardRef, memo } from 'react';

import { Root } from './RichEditorView.styled';
import { RichEditorViewProps } from './RichEditorView.types';

const RichEditorView = memo(
    forwardRef<HTMLDivElement, RichEditorViewProps>((props, ref) => {
        const { className, html: initialHtml = '', dataQa, highlightUrls = false, bordered = true } = props;

        const html = highlightUrls ? domHelpers.highlightUrls(initialHtml, true) : initialHtml;

        return (
            <Root
                ref={ref}
                className={className}
                data-qa={dataQa}
                dangerouslySetInnerHTML={{ __html: html }}
                $bordered={bordered}
            />
        );
    })
);

export default RichEditorView;
