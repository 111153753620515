import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';

import { moduleDef } from '..';
import { ModuleData } from '../reducers/moduleReducer';

export function getModule(state: State) {
    return selectors.module.getModule<ModuleData>(state, moduleDef.id);
}

export function getActivePopup(state: State) {
    return getModule(state).activePopup;
}

export function getActiveCompany(state: State) {
    const companyId = getModule(state).companyId;

    if (!companyId) {
        throw errorHelpers.notFoundError();
    }

    return selectors.company.getCompanyById(state, companyId);
}

export function getApplicableSubscriptions(state: State) {
    const subscriptionIds = getModule(state).applicableSubscriptions;

    return getModule(state).subscriptions.filter((subscription) => subscriptionIds.includes(subscription.id));
}

export interface PriceOption {
    plan: domain.CompanyPlan;
    edition: domain.CompanyPlanEdition;
    cycle: domain.SubscriptionBillingCycle;
    currency: string;
    ranges: Array<{
        minOrganisations: number;
        maxOrganisations: number;
        pricePerOrganisation: number;
    }>;
}

export function getPricingOptions(state: State): PriceOption[] {
    const pricingOptions = getModule(state).pricingOptions;

    return pricingOptions.map((option) => ({
        plan: option.newPlan,
        edition: option.edition,
        cycle: option.cycle,
        currency: option.currency,
        ranges: option.ranges.map((price) => ({
            minOrganisations: price.minOrganisations,
            maxOrganisations: price.maxOrganisations,
            pricePerOrganisation: price.pricePerOrganisation,
        })),
    }));
}

export function getLoading(state: State) {
    return getModule(state).loading;
}
