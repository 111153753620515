import { Mods, mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.div<StyledMods<Mods<'open'>>>`
    height: 100%;
    transition: padding-right 0.3s;

    ${mods.open.true`
        padding-right: 320px;
    `};
`;
