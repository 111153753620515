import { ApiBaseAnswer, MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UseCreateTrialRequestPathParams } from './useCreateTrialRequest.types';

export const useCreateTrialRequest = (
    mutationOptions?: MutationOptions<ApiBaseAnswer, RequestDataParams<{}, UseCreateTrialRequestPathParams>>
) => {
    return useMutate<RequestDataParams<{}, UseCreateTrialRequestPathParams>>(companiesApiPaths.trialRequest, {
        method: 'post',
        apiVersion: 'v2',
        mutationOptions,
    });
};
