import { FC, memo } from 'react';

import ProgressFlowItem from './components/ProgressFlowItem/ProgressFlowItem';
import { FlowItemsContainer, Root, Text, TextsContainer } from './ProgressFlow.styles';
import { ProgressFlowProps } from './ProgressFlow.types';

const ProgressFlow: FC<ProgressFlowProps> = memo((props) => {
    const { steps, activeStepIndex } = props;

    const getStateByIndex = (index: number) => {
        if (index < activeStepIndex) return 'done';

        if (index === activeStepIndex) return 'active';

        return 'future';
    };

    return (
        <Root>
            <FlowItemsContainer>
                {steps.map((s, i, arr) => (
                    <ProgressFlowItem key={i} state={getStateByIndex(i)} last={i === arr.length - 1} />
                ))}
            </FlowItemsContainer>

            <TextsContainer>
                {steps.map((step, i, arr) => (
                    <Text
                        $last={i === arr.length - 1}
                        $maxWidthPercent={100 / arr.length - 2}
                        $future={getStateByIndex(i) === 'future'}
                        $first={i === 0}
                        key={i}
                    >
                        {step.text}
                    </Text>
                ))}
            </TextsContainer>
        </Root>
    );
});

export default ProgressFlow;
