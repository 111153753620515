import { Entities, State } from 'modules/data';
import { Module } from 'modules/page';

import { createAction as createActionOriginal, TypedCreateActionFn } from './actions/createAction';
import { createAsyncAction as createAsyncActionOriginal, TypedCreateAsyncActionFn } from './actions/createAsyncAction';
import {
    createSelectingAction as createSelectingActionOriginal,
    TypedCreateSelectingActionFn,
} from './actions/createSelectingAction';
import mergeEntitiesReducer from './reducers/mergeEntitiesReducer';
import { Dispatch } from './types/Store';

export * from './actions/createErrorAction';
export * from './actions/typeHelpers';
export * from './composeReducers';
export * from './hooks/useDispatch';
export * from './hooks/useSelector';
export * from './hooks/useShallowEqualSelector';
export * from './reducers/createPageReducer';
export * from './reducers/createPopupReducer';
export * from './selectors/selectArgumentSelectors';
export * from './types/Action';
export * from './types/ConnectedProps';
export * from './types/Store';

// Typed versions of the createAction functions bound to use the app's State, Dispatch & Entities
export const createAction: TypedCreateActionFn<Entities> = createActionOriginal;
export const createSelectingAction: TypedCreateSelectingActionFn<State, Entities> = createSelectingActionOriginal;
export const createAsyncAction: TypedCreateAsyncActionFn<State, Dispatch, Entities> = createAsyncActionOriginal;

export const moduleDef: Module = {
    id: 'reactRedux',
    reducers: {
        entities: mergeEntitiesReducer,
    },
};
