import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const ButtonBox = styled('button')<StyledMods<Mods<'active'>>>`
    border: 1px solid ${theme.color.midnight40};
    border-radius: ${theme.radius.small};
    height: 100%;
    width: 100%;

    &:hover {
        outline: 1px solid ${theme.color.blue60};
        border-color: ${theme.color.blue60};
        cursor: pointer;
    }

    ${mods.active.true`
        &, &:hover {
            outline: 2px solid ${theme.color.blue100};
            border-color: ${theme.color.blue100};
        }
    `}
`;
