import { selectors } from 'modules/common';
import * as profile from 'modules/profile';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';

export const useSingleInvitation = () => {
    const dispatch = useDispatch();
    const invitations = useSelector(selectors.practiceInvitations.getPracticeInvitations);
    const invite = invitations[0];
    const [disabled, toggleDisabled] = useToggle(false);

    const onDecline = useCallback(async () => {
        try {
            toggleDisabled(true);
            await dispatch(profile.actions.respondToPracticeInvitation());
        } finally {
            toggleDisabled(false);
        }
    }, [dispatch, toggleDisabled]);

    const onAccept = useCallback(async () => {
        if (!invite) return;

        try {
            toggleDisabled(true);
            await dispatch(profile.actions.respondToPracticeInvitation(invite.id));
        } finally {
            toggleDisabled(false);
        }
    }, [dispatch, invite, toggleDisabled]);

    return {
        invite,
        disabled,
        onDecline,
        onAccept,
    };
};

export const usePluralInvitations = () => {
    const dispatch = useDispatch();

    const openInvitationsPopup = useCallback(() => {
        dispatch(profile.actions.showPracticeInvitationPopup());
    }, [dispatch]);

    return {
        openInvitationsPopup,
    };
};
