import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopup.SectionPriceChecker', {
    title: 'Price Checker',
    subtitle: `
        ApprovalMax makes it easy to prevent fraudulent pricing-related activities.
        Would you like to enable the Price Checker feature?`,
    optionTrue: 'Yes, enable this feature.',
    optionFalse: 'No, I don’t want to use this feature.',
});
