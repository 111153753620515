import { Avatar, Badge, ConditionWrapper, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { loadDelegatesPopup } from 'modules/profile';
import { useCurrentUser } from 'modules/utils';
import { FC, memo, MouseEvent, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch, useSelector } from 'react-redux';

import { useDelegates } from './UserMenuActivator.hooks';
import { messages } from './UserMenuActivator.messages';
import { Activator, DelegatesInner, DelegatesText, Root } from './UserMenuActivator.styles';
import { UserMenuActivatorProps } from './UserMenuActivator.types';

const qa = bemFactory.qa('page-nav-bar');

const UserMenuActivator: FC<UserMenuActivatorProps> = memo((props) => {
    const { toggleOpenDropdown } = props;

    const dispatch = useDispatch();
    const { displayName, avatar } = useCurrentUser();
    const invitations = useSelector(selectors.practiceInvitations.getPracticeInvitations);

    const handleClickAvatar = useCallback(() => toggleOpenDropdown(), [toggleOpenDropdown]);
    const { delegatesText } = useDelegates();

    const showDelegates = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();

            dispatch(loadDelegatesPopup());
            toggleOpenDropdown(false);
        },
        [dispatch, toggleOpenDropdown]
    );

    return (
        <Root $active={Boolean(delegatesText)}>
            <Activator
                onClick={handleClickAvatar}
                type='button'
                title={invitations.length ? messages.hasInvitations : displayName}
                data-qa={qa('avatar-menu-button')}
            >
                <ConditionWrapper
                    condition={invitations.length}
                    wrapperIf={(children) => (
                        <Badge size={8} placement='right' color='red100'>
                            {children}
                        </Badge>
                    )}
                >
                    <Avatar alt={displayName} src={avatar} size={28} color='blue20' shape='square' />
                </ConditionWrapper>
            </Activator>

            {delegatesText && (
                <DelegatesInner onClick={showDelegates}>
                    <Text font='label' fontSize='xxsmall' color='midnight80' spacing='0 0 4'>
                        {messages.delegatedTo}
                    </Text>

                    <DelegatesText>{delegatesText}</DelegatesText>
                </DelegatesInner>
            )}
        </Root>
    );
});

export default UserMenuActivator;
