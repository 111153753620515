/**
 * Developer: Stepan Burguchev
 * Date: 3/4/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { Command } from '@approvalmax/types';
import React from 'react';

export interface BaseEditorProps<TValue> {
    value: TValue;
    onChange: (value: TValue) => void;
    disabled?: boolean;
    invalid?: boolean;
    focusOnMount?: boolean;
    command?: Command;
    className?: string;
    qa?: string;
    onFocus?: () => void;
    onBlur?: () => void;
}

export interface BaseEditorState {
    focused: boolean;
}

export default abstract class EditorBase<
    TValue,
    TProps extends BaseEditorProps<TValue> = BaseEditorProps<TValue>,
    TState extends BaseEditorState = BaseEditorState,
> extends React.PureComponent<TProps, TState> {
    constructor(props: TProps) {
        super(props);
        this.state = {
            focused: false,
        } as TState;
    }

    public abstract focus(): void;

    public abstract blur(): void;

    protected _isDisabled() {
        const props: TProps = this.props;

        return props.disabled || (props.command && props.command.disabled) || false;
    }

    protected _onFocus = () => {
        const props: TProps = this.props;

        if (props.onFocus) {
            props.onFocus();
        }

        this.setState({
            focused: true,
        });
    };
}
