import './createOrganizationButton.scss';

import { selectors } from 'modules/common';
import { domain, stateTree } from 'modules/data';
import * as firstStart from 'modules/first-start';
import { NewIcon } from 'modules/sprites';
import { FC, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { CreateOrganizationButtonProps } from './CreateOrganizationButton.types';

const i18nPrefix = '@NoCompanies/no-invites';

const bem = bemFactory.block('no-companies-page-create-org-button');

const CreateOrganizationButton: FC<CreateOrganizationButtonProps> = (props) => {
    const { openNewOrgPopopOnMount } = props;

    const dispatch = useDispatch();

    const userCanCreateOrganisation = useSelector((state: stateTree.State) => {
        const profile = selectors.profile.getProfile(state);

        return (
            profile.account?.userRole !== domain.AccountMemberRole.Member &&
            profile.account?.userRole !== domain.AccountMemberRole.UserManager
        );
    });

    const onAddNewOrg = useCallback(() => {
        dispatch(
            firstStart.loadNewOrganisationSetup({
                fromNoCompanies: true,
            })
        );
    }, [dispatch]);

    useMount(() => {
        if (userCanCreateOrganisation && openNewOrgPopopOnMount) {
            onAddNewOrg();
        }
    });

    if (!userCanCreateOrganisation) {
        return null;
    }

    return (
        <div className={bem()} onClick={onAddNewOrg}>
            <div className={bem('action-button-text')}>
                <FormattedMessage
                    id={`${i18nPrefix}.buttonNewCompanyText`}
                    defaultMessage='Set up your own Organisations'
                />
            </div>

            <div className={bem('action-button-icon')}>
                <NewIcon width={32} height={32} />
            </div>
        </div>
    );
};

export default CreateOrganizationButton;
