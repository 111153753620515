import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.MatrixPopupContent.ConditionHeaderColumn', {
    alwaysMandatory: 'Always mandatory',
    alwaysOptional: 'Always optional',
    readonly: 'Read-only',
    rename: 'Rename',
    remove: 'Remove',
    theFieldIsMandatory: 'The field is mandatory',
    theFieldIsOptional: 'The field is optional',
    theFieldIsReadonly: 'The field is read-only',
});
