import { Dropdown } from '@approvalmax/ui';
import { FC, ReactElement, Ref } from 'react';

interface UserHoverAnchorProps {
    children: (ref: Ref<any>) => ReactElement;
}

const UserHoverAnchor: FC<UserHoverAnchorProps> = (props) => {
    const { children } = props;

    return <Dropdown.Anchor>{(ref) => children(ref)}</Dropdown.Anchor>;
};

export default UserHoverAnchor;
