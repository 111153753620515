import { backend } from 'modules/data';
import { LoginAnswer } from 'modules/data/backend';

import { ApiHandler } from '../types';

class AuthUrls {
    constructor(private _apiHandler: ApiHandler) {}
}

export default class AuthApi {
    public urls: AuthUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new AuthUrls(this._apiHandler);
    }

    public register(transfer: backend.transfers.AuthRegisterTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/register',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public verify(transfer: backend.transfers.AuthVerifyTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/verify',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public login(transfer: backend.transfers.AuthLoginTransfer): Promise<LoginAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/login',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public logout(): Promise<any> {
        return this._apiHandler.doApiCall({
            data: null,
            action: 'auth/logout',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public resetPwd(transfer: backend.transfers.AuthResetPwdTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/resetPwd',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public getXeroOAuthUrl(
        transfer: backend.transfers.AuthOAuthGetUrlTransfer
    ): Promise<backend.Answer<backend.AuthXeroOAuthGetUrlAnswer>> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/getXeroOAuthUrl',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public loginViaXeroOAuth(transfer: backend.transfers.AuthOAuthLoginTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/loginViaXeroOAuth',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public registerViaXeroOAuth(transfer: backend.transfers.AuthOAuthRegisterTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/registerViaXeroOAuth',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public getQBooksOAuthUrl(
        transfer: backend.transfers.AuthOAuthGetUrlTransfer
    ): Promise<backend.Answer<backend.AuthQBooksOAuthGetUrlAnswer>> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/getQBooksOAuthUrl',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public loginViaQBooksOAuth(transfer: backend.transfers.AuthOAuthLoginTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/loginViaQBooksOAuth',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public registerViaQBooksOAuth(transfer: backend.transfers.AuthOAuthRegisterTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/registerViaQBooksOAuth',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public getGoogleOAuthUrl(
        transfer: backend.transfers.AuthOAuthGetUrlTransfer
    ): Promise<backend.Answer<backend.AuthGoogleOAuthGetUrlAnswer>> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/getGoogleOAuthUrl',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public loginViaGoogleOAuth(transfer: backend.transfers.AuthOAuthLoginTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/loginViaGoogleOAuth',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public registerViaGoogleOAuth(transfer: backend.transfers.AuthOAuthRegisterTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/registerViaGoogleOAuth',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public getMicrosoftOAuthUrl(
        transfer: backend.transfers.AuthOAuthGetUrlTransfer
    ): Promise<backend.Answer<backend.AuthMicrosoftOAuthGetUrlAnswer>> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/getMicrosoftOAuthUrl',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public loginViaMicrosoftOAuth(transfer: backend.transfers.AuthOAuthLoginTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/loginViaMicrosoftOAuth',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public registerViaMicrosoftOAuth(transfer: backend.transfers.AuthOAuthRegisterTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/registerViaMicrosoftOAuth',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public getTFAEnablingQrCodeImage(stateId: string): Promise<any> {
        return this._apiHandler.doApiCall({
            data: stateId,
            action: 'auth/getTFAEnablingQrCodeImage',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public sendTFALoginEmailCode(
        transfer: backend.transfers.AuthSendTFALoginEmailCodeTransfer
    ): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/sendTFALoginEmailCode',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public finishTFALogin(transfer: backend.transfers.AuthFinishTFALoginTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/finishTFALogin',
            method: 'POST',
            allowsAnonymous: true,
        });
    }

    public extendSession(transfer: {}): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'auth/extendSession',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getPotentialTeammates(): Promise<{ emails: string[] }> {
        return this._apiHandler.doApiCall({
            data: null,
            action: 'auth/getPotentialTeammates',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getCannyIoToken(): Promise<backend.transfers.AuthCannyIoTokenTransfer> {
        return this._apiHandler.doApiCall({
            data: null,
            action: 'auth/getCannyIoToken',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
