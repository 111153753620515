import { css, keyframes } from 'styled-components';

import { colorTextByBg } from './constants';
import { mods } from './index';
import { Mods, RequiredMods } from './types';
import { theme } from './variablels';

export const link = (color: RequiredMods['color']) => css`
    text-decoration: none;
    color: ${theme.color[color]};

    &:hover,
    &:focus {
        text-decoration: underline;
        color: ${theme.color[color]};
    }
`;

export const font = (
    font: RequiredMods['font'],
    size: RequiredMods['fontSize'],
    weight?: Mods['fontWeight'],
    color?: Mods['color']
) => css`
    font-size: ${theme.fontSize[font][size]};
    line-height: ${theme.lineHeight[font][size]};

    ${font === 'headline' &&
    css`
        font-family: ${theme.fontFamily.poppins};
    `};

    ${color &&
    css`
        color: ${theme.color[color]};
    `};

    ${weight &&
    css`
        font-weight: ${theme.fontWeight[weight]};
    `};
`;

export const colorTextWithBg = (value: RequiredMods['color']) => css`
    background-color: ${theme.color[value]};
    color: ${theme.color[colorTextByBg[value]]};
`;

export const color = (value: RequiredMods['color']) => css`
    color: ${theme.color[value]};
`;

export const shadow = (value: RequiredMods['shadow']) => css`
    box-shadow: ${theme.shadow[value]};
`;

export const radius = (value: RequiredMods['radius']) => css`
    border-radius: ${theme.radius[value]};
`;

export const fontWeight = (value: RequiredMods['fontWeight']) => css`
    font-weight: ${theme.fontWeight[value]};
`;

export const textAlign = (value: RequiredMods['textAlign']) => css`
    text-align: ${value};
`;

export const inlineChild = (indent?: Mods['spacing'], wrap?: boolean) => css`
    display: flex;
    margin-right: -${indent}px;

    ${wrap
        ? css`
              flex-wrap: wrap;
              margin-bottom: -${indent}px;
          `
        : css`
              flex-wrap: inherit;
          `};

    > * {
        margin-right: ${indent}px;

        ${wrap &&
        css`
            margin-bottom: ${indent}px;
        `}
    }
`;

export const arrow = {
    top: (sizePx: number, color: RequiredMods['color']) => css`
        width: 0;
        height: 0;
        border-left: ${sizePx}px solid transparent;
        border-bottom: ${sizePx}px solid ${theme.color[color]};
        border-right: ${sizePx}px solid transparent;
    `,

    bottom: (sizePx: number, color: RequiredMods['color']) => css`
        width: 0;
        height: 0;
        border-left: ${sizePx}px solid transparent;
        border-top: ${sizePx}px solid ${theme.color[color]};
        border-right: ${sizePx}px solid transparent;
    `,

    left: (sizePx: number, color: RequiredMods['color']) => css`
        width: 0;
        height: 0;
        border-top: ${sizePx}px solid transparent;
        border-bottom: ${sizePx}px solid transparent;
        border-right: ${sizePx}px solid ${theme.color[color]};
    `,

    right: (sizePx: number, color: RequiredMods['color']) => css`
        width: 0;
        height: 0;
        border-top: ${sizePx}px solid transparent;
        border-bottom: ${sizePx}px solid transparent;
        border-left: ${sizePx}px solid ${theme.color[color]};
    `,
};

export const position = {
    absoluteCenter: css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `,

    verticalCenter: css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    `,

    horizontalCenter: css`
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    `,

    absoluteFill: css`
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    `,

    fixedFill: css`
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    `,
};

export const ellipsis = (rows: RequiredMods['ellipsis']) => css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${rows};
`;

const invalidAnimation = keyframes`
    0% {
        transform: translateX(0);
    }
    35% {
        transform: translateX(-3px);
    }
    70% {
        transform: translateX(3px);
    }
    100% {
        transform: translateX(0);
    }
`;

export const invalidShakeAnimation = css`
    animation: ${invalidAnimation} ${theme.duration.medium}ms ease-in-out;
`;

export const iconSize = (value: RequiredMods['iconSize']) => css`
    width: ${value}px;
    height: ${value}px;
`;

export const whByModValue = (
    modName: 'width' | 'height' | 'maxWidth' | 'maxHeight' | 'minHeight',
    property?: 'max-width' | 'max-height' | 'min-height'
) => css`
    ${mods(modName)(
        (value: string | number) => css`
            ${property || modName}: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};
`;
