import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionPostingPreferencesType',
    {
        title: 'Posting preferences',
        subtitle: 'How Manual Journals should be created in Xero upon the approval?',
        asDraft: 'As draft',
        asPosted: 'As posted',
    }
);
