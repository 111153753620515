export const getAMCookieOptions = () => {
    const isAMDomain = window.location.hostname.includes('approvalmax.com');
    const isAmaxinternalDomain = window.location.hostname.includes('amaxinternal.com');
    const isAmaxinternalStageDomain = window.location.hostname.includes('amaxinternalstage.com');
    const targetRootDomain = isAMDomain
        ? 'approvalmax.com'
        : isAmaxinternalDomain
          ? 'amaxinternal.com'
          : isAmaxinternalStageDomain
            ? 'amaxinternalstage.com'
            : undefined;

    return window.location.protocol === 'https:'
        ? {
              domain: targetRootDomain,
              expires: 365 * 100,
              path: '/',
              secure: true,
          }
        : {
              domain: targetRootDomain,
              expires: 365 * 100,
              path: '/',
          };
};
