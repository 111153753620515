import { stateTree } from 'modules/data';
import { ImmutableObject, mergeDeep, setIn, updateInWithout } from 'modules/immutable';

import {
    Action,
    CREATE_ACCRUAL_REPORT,
    CREATE_NEW_REPORT_RESPONSE,
    DELETE_REPORT_RESPONSE,
    DOWNLOAD_AUDIT_REPORTS,
    EXECUTE_REPORT,
    EXPORT_AS_CSV,
    LOAD_PAGE_DATA,
    SAVE_REPORT_AS_NEW_RESPONSE,
    SAVE_REPORT_RESPONSE,
} from '../actions';
import { ReportConfig, updateDynamicColumns } from '../data/reportConfig';

export interface Entities extends stateTree.Entities {
    reportConfigs: {
        [reportConfigId: string]: ReportConfig;
    };
}

export default function (state: ImmutableObject<Entities>, action: Action): ImmutableObject<Entities> {
    switch (action.type) {
        case LOAD_PAGE_DATA: {
            let entities = { ...action.payload.entities };

            if (action.payload.fields) {
                for (const reportConfigId in entities?.reportConfigs) {
                    const updatedColumns = updateDynamicColumns(
                        entities?.reportConfigs[reportConfigId],
                        action.payload.fields!
                    );

                    entities.reportConfigs[reportConfigId] = updatedColumns;
                }
            }

            return mergeDeep(state, entities);
        }

        case EXECUTE_REPORT:
        case DOWNLOAD_AUDIT_REPORTS:
        case EXPORT_AS_CSV:
        case CREATE_ACCRUAL_REPORT:
            return setIn(state, ['reportConfigs', action.payload.reportConfigId, 'lastRunDate'], action.payload.now);

        case SAVE_REPORT_RESPONSE:
            return setIn(state, ['reportConfigs', action.payload.reportConfig.id], action.payload.reportConfig);

        case SAVE_REPORT_AS_NEW_RESPONSE:
            return setIn(state, ['reportConfigs', action.payload.reportConfig.id], action.payload.reportConfig);

        case CREATE_NEW_REPORT_RESPONSE:
            return setIn(state, ['reportConfigs', action.payload.reportConfig.id], action.payload.reportConfig);

        case DELETE_REPORT_RESPONSE:
            return updateInWithout(state, ['reportConfigs'], action.payload.request.reportConfigId);

        default:
            return state;
    }
}
