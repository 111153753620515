import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, EDIT_STEP_PARTICIPANTS_RESPONSE, SHOW_EDIT_PARTICIPANTS_POPUP } from '../../../actions';

export const EDIT_PARTICIPANTS_POPUP = 'EDIT_PARTICIPANTS_POPUP';

export interface EditParticipantsPopupData {
    id: typeof EDIT_PARTICIPANTS_POPUP;
    requestId: Guid;
    stepId: Guid;
}

export type EditParticipantsPopupType = ImmutableObject<EditParticipantsPopupData> | null;

export default function (state: EditParticipantsPopupType, action: Action): EditParticipantsPopupType {
    switch (action.type) {
        case SHOW_EDIT_PARTICIPANTS_POPUP:
            return immutable<EditParticipantsPopupData>({
                id: EDIT_PARTICIPANTS_POPUP,
                requestId: action.payload.requestId,
                stepId: action.payload.stepId,
            });

        case EDIT_STEP_PARTICIPANTS_RESPONSE:
            return null;

        default:
            return state;
    }
}
