import { Action, CLOSE_ACTIVE_VERSION_CONFLICT_POPUP, OPEN_ACTIVE_VERSION_CONFLICT_POPUP } from '../../actions';

export type VersionConflictData = {
    isOpen: boolean;
    workflowIsJustDeactivated: boolean;
    workflowIsJustActivated: boolean;
    newTemplateComment: string;
};

export type ActiveVersionConflict = VersionConflictData | null;

export default function activeVersionConflictReducer(
    state: ActiveVersionConflict = null,
    action: Action
): ActiveVersionConflict {
    switch (action.type) {
        case OPEN_ACTIVE_VERSION_CONFLICT_POPUP:
            return {
                isOpen: true,
                workflowIsJustActivated: action.payload.justActivated || false,
                workflowIsJustDeactivated: action.payload.justDeactivated || false,
                newTemplateComment: action.payload.newTemplateComment || '',
            };

        case CLOSE_ACTIVE_VERSION_CONFLICT_POPUP:
            return {
                isOpen: false,
                workflowIsJustDeactivated: false,
                workflowIsJustActivated: false,
                newTemplateComment: '',
            };

        default:
            return state;
    }
}
