import moment from 'moment';

export const convertIsoToDaysAndHours = (isoValue: string) => {
    const duration = moment.duration(isoValue);

    return {
        days: Math.floor(duration.asDays()),
        hours: duration.hours(),
    };
};

export const convertDayAndHoursToIso = (days: number, hours: number) => {
    return moment.duration({ hours, days }).toISOString();
};

export const convertIsoToStringLine = (isoValue: string) => {
    const { days, hours } = convertIsoToDaysAndHours(isoValue);

    return `${days}.${hours}:00:00`;
};
