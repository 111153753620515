import { Box, Button, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { openEditingMatrix } from '../../../../actions';
import { messages } from './EditingPermissionCell.messages';
import { EditingPermissionCellProps } from './EditingPermissionCell.types';

export const EditingPermissionCell: FC<EditingPermissionCellProps> = memo((props) => {
    const { stepIndex, participantMatrix, editingMatrixLine, lineId } = props;

    const dispatch = useDispatch();

    const onOpenEditingMatrix = useCallback(() => {
        dispatch(openEditingMatrix(stepIndex, participantMatrix, lineId));
    }, [dispatch, participantMatrix, stepIndex, lineId]);

    const conditionsQty = editingMatrixLine?.rules?.[0]?.conditions.length;

    return (
        <Box width='172px'>
            <Button size='xsmall' color='blue10' onClick={onOpenEditingMatrix}>
                {messages.editingMatrix}
            </Button>

            <Text font='body' fontSize='xsmall' color='midnight70' spacing='4 0'>
                {conditionsQty ? messages.canEditXFields({ qty: conditionsQty }) : messages.cannotEditAnyField}
            </Text>
        </Box>
    );
});

EditingPermissionCell.displayName = 'EditingPermissionCell';
