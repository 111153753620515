import { throwError } from './throwError';

/**
 * Pre-validation helper that allows to check that function argument is not falsy.
 * Falsy value means that the value is one of the following: <code>undefined, null, 0, '', false</code>.
 * Throws <code>ArgumentFalsyError</code> if validation is failed.
 * @example
 * core.utils.helpers.assertArgumentNotFalsy(argument1, 'argument1');
 * @param {*} argumentValue Value to check.
 * @param {String} argumentName Name of the checked argument. Needs to specify in the exception text.
 * */
export const assertArgumentNotFalsy = (argumentValue: any, argumentName: string) => {
    if (!argumentValue) {
        throwError('Argument `' + argumentName + '` is falsy.', 'ArgumentFalsyError');
    }
};
