import { Box, Menu } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import { useMenuItems } from './UserCommonMenu.hooks';
import { UserCommonMenuProps } from './UserCommonMenu.types';

const UserCommonMenu: FC<UserCommonMenuProps> = memo((props) => {
    const { onClickItem } = props;

    const items = useMenuItems();

    if (!items.length) return null;

    return (
        <Box spacing='16 8 0'>
            <Menu size='small'>
                {items.map(({ id, name, dataQa, to, href, onClick }) => {
                    const commonProps = {
                        name,
                        onClick: onClickItem(onClick),
                        'data-qa': dataQa,
                        'data-qa-id': id,
                    };

                    if (to) {
                        return <Menu.Item key={id} as={Link} to={to} {...commonProps} />;
                    }

                    return <Menu.Item key={id} as='a' href={href} {...commonProps} />;
                })}
            </Menu>
        </Box>
    );
});

export default UserCommonMenu;
