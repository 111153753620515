import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

export const Root = styled.div<StyledMods<Mods<'invalid' | 'disabled' | 'empty'> & { minHeight: number }>>`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 4px 0;

    .rich-editor__text-field {
        box-shadow: inset 0 0 0 1px ${theme.color.midnight50};
        border-radius: ${theme.radius.xsmall};
        padding: 12px;
        overflow-wrap: anywhere;

        ${mods.invalid.true`
          color: ${theme.color.red100};
          background-color: ${theme.color.red20};
          box-shadow: inset 0px 0px 0px 1px ${theme.color.red100};
        `}

        ${mods.disabled.true`
          color: ${theme.color.midnight70};
          background-color: ${theme.color.midnight30};
        `}

        ${mods('minHeight')(
            (value) => css`
                min-height: ${value}px;
            `
        )}
    }

    // custom placeholder
    .rich-editor__placeholder {
        &::before {
            content: attr(data-placeholder);
            float: left;
            pointer-events: none;
            height: 0;
            color: ${theme.color.midnight60};
        }
    }

    .ProseMirror-focused {
        box-shadow: inset 0 0 0 1px ${theme.color.blue100};
    }

    // css for show lists
    .ProseMirror > * + * {
        margin-top: 0.75em;
    }

    .ProseMirror ol {
        padding: 0 1rem;
        list-style: decimal !important;
    }

    .ProseMirror ul {
        padding: 0 1rem;
        list-style: disc !important;
    }

    [contenteditable] {
        -webkit-user-select: text;
        user-select: text;
        cursor: text;

        ${mods(['disabled', 'empty'] as const)(({ disabled, empty }) => {
            if (disabled && empty) {
                return css`
                    -webkit-user-select: none;
                    user-select: none;
                    cursor: default;
                `;
            }

            return '';
        })};
    }

    .mention {
        padding: 2px 4px;
        border-radius: ${theme.radius.xsmall};
        color: ${theme.color.white100};
        box-shadow: inset 0 0 0 1px ${theme.color.blue80};
        background-color: ${theme.color.blue80};
        font-size: ${theme.fontSize.label.xsmall};
        white-space: nowrap !important; //override @tiptap styles
    }
`;
