import './navBar.scss';

import { Button } from '@approvalmax/ui';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';

const i18nPrefix = 'firstStart.NavBar';

interface Props {
    title: React.ReactNode;
    stepNumber: number;
    showBack: boolean;
    onBack: () => void;
    showNext: boolean;
    onNext: () => void;
    showFinish: boolean;
    onFinish: () => void;
    className?: string;
    disabled: boolean;
}

interface State {}

class NavBar extends React.Component<Props, State> {
    public render() {
        const { className, title, showBack, showNext, showFinish, onBack, onNext, onFinish, disabled } = this.props;
        const bem = bemFactory.block('first-start-nav-bar');

        return (
            <div className={bem.add(className)()}>
                <div className={bem('left-block')}>
                    <div className={bem('title')}>{title}</div>
                </div>

                {showBack && (
                    <Button disabled={disabled} execute={onBack} theme='secondary' className={bem('back-button')}>
                        <FormattedMessage id={`${i18nPrefix}.goBackButton`} defaultMessage='Go Back' />
                    </Button>
                )}

                {showNext && (
                    <Button disabled={disabled} execute={onNext} className={bem('action-button')}>
                        <FormattedMessage id={`${i18nPrefix}.nextButton`} defaultMessage='Next' />
                    </Button>
                )}

                {showFinish && (
                    <Button disabled={disabled} execute={onFinish} className={bem('action-button')}>
                        <FormattedMessage id={`${i18nPrefix}.finishButton`} defaultMessage='Next' />
                    </Button>
                )}
            </div>
        );
    }
}

export default NavBar;
