/**
 * Developer: Stepan Burguchev
 * Date: 3/28/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './userPicker.scss';

import { DropdownEditor, TransparentButton } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { PlainDataProvider } from 'modules/data-providers';
import { AddApproverIcon, DeleteIcon } from 'modules/sprites';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages } from 'react-intl';

import * as selectors from '../../selectors';
import { CompanyUserListItem } from '..';
import Avatar from '../avatar/Avatar';
import UserHover from '../userHover/UserHover';

const i18nPrefix = 'common.ui.UserPicker';
const messages = defineMessages({
    placeholder: {
        id: `${i18nPrefix}.placeholder`,
        defaultMessage: 'Enter the email address',
    },
});

enum UserPickerTheme {
    Default = 'default',
    Error = 'error',
    AlignSelectButton = 'align-select-button',
    Small = 'small',
}

interface Props {
    selectButtonText: string;
    users: selectors.types.ExpandedUser[];
    className?: string;
    qa?: string;
    value: selectors.types.ExpandedUser | null;
    theme?: UserPickerTheme;
    onChange: (value: selectors.types.ExpandedUser | null) => void;
    disableRemove?: boolean;
    disabled?: boolean;
    renderValue?: (value: selectors.types.ExpandedUser) => string | React.ReactElement;
}

interface State {
    inEdit: boolean;
}

class UserPicker extends React.Component<Props, State> {
    public static Theme = UserPickerTheme;

    public static defaultProps = {
        theme: UserPickerTheme.Default,
    };

    public state = {
        inEdit: false,
    };

    public render() {
        const {
            className,
            users,
            value,
            theme,
            selectButtonText,
            qa: qa$,
            disableRemove,
            disabled,
            renderValue,
        } = this.props;

        const bem = bemFactory.block('common-ui-user-picker');
        const qa = bemFactory.block('common-ui-user-picker');

        const alignSelectButton = theme === UserPickerTheme.AlignSelectButton;
        const small = theme === UserPickerTheme.Small;
        const error = theme === UserPickerTheme.Error;

        if (this.state.inEdit) {
            return (
                <div className={bem.add(className)()}>
                    <PlainDataProvider items={users} filterFn={this._filterUserItem}>
                        <DropdownEditor
                            className={bem('dropdown-editor', {
                                'align-to-avatar': alignSelectButton,
                            })}
                            theme='transparent'
                            ref={this._onEditorMount}
                            displayAttribute='displayName'
                            listItem={CompanyUserListItem}
                            value={value}
                            placeholder={intl.formatMessage(messages.placeholder)}
                            onChange={this._onChange}
                            onBlur={this._onBlur}
                        />
                    </PlainDataProvider>
                </div>
            );
        } else {
            if (value) {
                return (
                    <UserHover
                        user={value}
                        className={bem.add(className)(null, {
                            'with-clear-button': !(disableRemove || disabled),
                        })}
                        data-qa={qa$}
                    >
                        <Avatar
                            user={value}
                            size={40}
                            className={bem('avatar', {
                                disabled,
                            })}
                        />

                        <div title={value.displayName} className={bem('text', { disabled })} data-qa={qa('text')}>
                            {renderValue ? renderValue(value) : value.displayName}
                        </div>

                        {!(disableRemove || disabled) && (
                            <TransparentButton
                                className={bem('clear-button')}
                                execute={this._clear}
                                qa={qa('delete-button')}
                            >
                                <DeleteIcon width={8} height={8} />
                            </TransparentButton>
                        )}
                    </UserHover>
                );
            } else {
                return (
                    <div className={bem.add(className)()} onClick={this._onClick} data-qa={qa()}>
                        <div
                            className={bem('add-icon', {
                                'align-to-avatar': alignSelectButton,
                                small,
                                error,
                                disabled,
                            })}
                        >
                            <AddApproverIcon width={20} height={20} />
                        </div>

                        <div
                            className={bem('select-button', {
                                error,
                                disabled,
                            })}
                        >
                            {selectButtonText}
                        </div>
                    </div>
                );
            }
        }
    }

    private _onEditorMount = (editor: DropdownEditor | null) => {
        if (editor) {
            editor.focus();
        }
    };

    private _filterUserItem = (user: selectors.types.ExpandedCompanyUser, filterText: string) => {
        return user.displayName.toUpperCase().includes(filterText) || user.userEmail.toUpperCase().includes(filterText);
    };

    private _onClick = () => {
        if (!this.props.disabled) {
            this.setState({
                inEdit: true,
            });
        }
    };

    private _clear = () => {
        this.props.onChange(null);
    };

    private _onChange = (value: selectors.types.ExpandedCompanyUser | null) => {
        this.setState({
            inEdit: false,
        });
        this.props.onChange(value);
    };

    private _onBlur = () => {
        this.setState({
            inEdit: false,
        });
    };
}

export default UserPicker;
