import { stateTree } from 'modules/data';
import { immutable, set } from 'modules/immutable';

import { Action, CONTEXT_INITIALIZATION_COMPLETED, LOAD_INITIAL_APP_DATA, SET_AUTHENTICATED_STATE } from '../actions';

export default function (state: stateTree.Session, action: Action): stateTree.Session {
    switch (action.type) {
        case SET_AUTHENTICATED_STATE:
            return set(state, 'authenticated', action.payload.authenticated);

        case CONTEXT_INITIALIZATION_COMPLETED:
            return set(state, 'contextInitializationCompleted', true);

        case LOAD_INITIAL_APP_DATA:
            return immutable(action.payload.session);

        default:
            return state;
    }
}
