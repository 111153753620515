import { errorHelpers } from '@approvalmax/utils';
import map from 'lodash/map';
import { stateTree } from 'modules/data';

import { findCompanyById } from './companySelectors';
import { ExpandedCompanyInvitation } from './types';
import { getUserById } from './userSelectors';

export function getAllCompanyInvitations(state: stateTree.State): ExpandedCompanyInvitation[] {
    return map(state.entities.companyInvitations, (inv) => {
        return {
            ...inv,
            inviterUser: getUserById(state, inv.inviter),
        };
    });
}

export function findCompanyInvitationById(state: stateTree.State, id: string): ExpandedCompanyInvitation | null {
    const invitation = state.entities.companyInvitations[id];

    if (!invitation) {
        return null;
    }

    return {
        ...invitation,
        inviterUser: getUserById(state, invitation.inviter),
    };
}

export function getCompanyInvitationById(state: stateTree.State, id: string): ExpandedCompanyInvitation {
    const invitation = findCompanyInvitationById(state, id);

    if (!invitation) {
        throw errorHelpers.notFoundError();
    }

    return invitation;
}

export function getPendingCompanyInvitations(state: stateTree.State): ExpandedCompanyInvitation[] {
    return getAllCompanyInvitations(state).filter((invitation) => {
        const company = findCompanyById(state, invitation.companyId);

        const isCompanyReadonly = company?.isReadonly ?? false;

        return !invitation.response && !isCompanyReadonly;
    });
}
