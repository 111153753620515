import uniqueId from 'lodash/uniqueId';
import { domain } from 'modules/data';

const prefix = 'lineItem-';

export function isNewQBooksLineItem(li: domain.QBooksLineItem) {
    return li.id.startsWith(prefix);
}

export function createQBooksLineItem(): domain.QBooksLineItem {
    return {
        id: uniqueId(prefix),
        description: '',
        isInventory: false,
        isMatched: false,
    };
}

export function cloneQBooksLineItem(li: domain.QBooksLineItem): domain.QBooksLineItem {
    return {
        id: uniqueId(prefix),
        isInventory: li.isInventory,
        item: li.item,
        description: li.description,
        qty: li.qty,
        unitPrice: li.unitPrice,
        originalUnitPrice: li.originalUnitPrice,
        unitPriceGross: li.unitPriceGross,
        originalUnitPriceGross: li.originalUnitPriceGross,
        amount: li.amount,
        taxCode: li.taxCode,
        customer: li.customer,
        class: li.class,
        isMatched: false,
        isBillable: li.isBillable,
        isTaxable: li.isTaxable,
    };
}
