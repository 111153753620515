import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('UnsavedChangesModule', {
    title: 'Unsaved changes',
    description: 'There are unsaved changes on the page. Would you like to discard them or continue editing?',
    cancelButtonText: 'Discard Changes',
    cancelButtonTitle: 'Discard unsaved changes',
    confirmButtonText: 'Continue Editing',
    confirmButtonTitle: 'Stay on this page and continue editing',
});
