import * as Sentry from '@sentry/browser';
import { ExtraErrorData } from '@sentry/integrations';
import { selectors } from 'modules/common';

export const sentryService = {
    init() {
        const environment = window.ApprovalMax.environment;

        let eventCount = 0;

        const MAX_EVENT_COUNT = 15;

        if (environment === 'production' || environment === 'staging') {
            Sentry.init({
                dsn: 'https://85b23964070f4421ad92c1d5171a1ce1@sentry.io/246849',
                release: window.ApprovalMax.appVersion,
                environment,
                integrations: [new ExtraErrorData()],
                tracesSampleRate: 0.04,
                sampleRate: 0.1,
                autoSessionTracking: true,
                beforeSend: (event, hint) => {
                    eventCount++;

                    if (eventCount > MAX_EVENT_COUNT) {
                        console.warn(
                            'Too many errors have been reported from this client. Error reporting to Sentry is suppressed.'
                        );

                        return null;
                    }

                    const error = hint?.originalException as string | Error | null | undefined;

                    if (error === 'Timeout') {
                        // Google Recaptcha code bug
                        return null;
                    }

                    if (
                        error &&
                        typeof error !== 'string' &&
                        error.message &&
                        error.message.includes('Loading CSS chunk')
                    )
                        return null;

                    return event;
                },
                // See: https://gist.github.com/impressiver/5092952 for reference
                ignoreErrors: [
                    // Random plugins/extensions
                    'top.GLOBALS',
                    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                    'originalCreateNotification',
                    'canvas.contentDocument',
                    'MyApp_RemoveAllHighlights',
                    'http://tt.epicplay.com',
                    "Can't find variable: ZiteReader",
                    'jigsaw is not defined',
                    'ComboSearch is not defined',
                    'http://loading.retry.widdit.com/',
                    'atomicFindClose',
                    // Facebook borked
                    'fb_xd_fragment',
                    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
                    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
                    'bmi_SafeAddOnload',
                    'EBCallBackMessageReceived',
                    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                    'conduitPage',
                    // Network errors such as going offline or being blocked by a proxy
                    'Failed to fetch',
                    // False errors in iOS
                    'loadHomepageTiles',

                    'GetMetaTags',

                    'ChunkLoadError',
                    /Loading CSS chunk [\d]+ failed/i,
                    'e.replaceAll is not a function',
                ],
                denyUrls: [
                    // Google Analytics flakiness
                    /www\.google-analytics\.com/i,
                    // Facebook flakiness
                    /graph\.facebook\.com/i,
                    // Facebook blocked
                    /connect\.facebook\.net\/en_US\/all\.js/i,
                    // Woopra flakiness
                    /eatdifferent\.com\.woopra-ns\.com/i,
                    /static\.woopra\.com\/js\/woopra\.js/i,
                    // Chrome extensions
                    /extensions\//i,
                    /^chrome:\/\//i,
                    // Other plugins
                    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                    /webappstoolbarba\.texthelp\.com\//i,
                    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
                ],
            });

            Sentry.configureScope((scope) => {
                scope.setTag('origin', 'javascript');
            });
            Sentry.addGlobalEventProcessor((event) => {
                const state = window.ApprovalMax.app.getStore().getState();

                const getSize = (o: any) => JSON.stringify(o || {}).length;

                const MAX_LIMIT = 120000;

                event.extra = {
                    ...event.extra,
                    'startup-data': selectors.session.getStartupData(state),
                    'entities.companies': state.entities.companies,
                };

                // Gradually delete extra payload when event is too big (limit in sentry is 200KB)
                if (getSize(event) > MAX_LIMIT) {
                    delete event.extra.state;
                }

                if (getSize(event) > MAX_LIMIT) {
                    if ((event.extra.lastAction as any)?.payload) {
                        delete (event.extra.lastAction as any).payload;
                    } else {
                        delete event.extra.lastAction;
                    }
                }

                if (getSize(event) > MAX_LIMIT) {
                    delete event.extra['entities.companies'];
                }

                return event;
            });
        }
    },
};
