import { domain } from 'modules/data';

export const manualRateFields = [
    domain.FieldSystemPurpose.XeroManualExchangeRate,
    domain.FieldSystemPurpose.QBooksManualExchangeRate,
];

export const qbooksPayeeFields = [
    domain.FieldSystemPurpose.QBooksPayeeCustomer,
    domain.FieldSystemPurpose.QBooksPayeeEmployee,
    domain.FieldSystemPurpose.QBooksPayeeVendor,
];
