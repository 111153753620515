import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSetRecoilState } from 'recoil';

import { addTempUser, addUserToActiveMatrix } from '../../../../actions';
import { getActiveMatrix } from '../../../../selectors/pageSelectors';
import AddUserButton from '../../../AddUserButton/AddUserButton';
import { matrixCopyRuleState } from '../CopyRulePopup/CopyRulePopup.states';
import { useAddUserButtonTitle } from './MatrixAddUserButton.hooks';
import { Root } from './MatrixAddUserButton.styles';
import { MatrixAddUserButtonProps } from './MatrixAddUserButton.types';

const MatrixAddUserButton: FC<MatrixAddUserButtonProps> = memo((props) => {
    const { onlyAddNewUser, isForCopyRule = false, team, readonly } = props;

    const dispatch = useDispatch();
    const matrix = useSelector(getActiveMatrix)!;
    const addUserButtonTitle = useAddUserButtonTitle(matrix.type);
    const excludedUsers = useMemo(() => matrix.data.map((matrixLine) => matrixLine.user), [matrix.data]);
    const setRecoilState = useSetRecoilState(matrixCopyRuleState);

    const addUserToCopyRuleState = useCallback(
        (user: domain.User) => {
            setRecoilState((current) => ({
                ...current,
                addedUsers: [...current.addedUsers, user],
            }));
        },
        [setRecoilState]
    );

    const onAddUser = useCallback(
        (user: domain.User) => {
            dispatch(addUserToActiveMatrix(user));
        },
        [dispatch]
    );

    const onAddTempUser = useCallback(
        (user: domain.User) => {
            dispatch(addTempUser(user));

            if (isForCopyRule) {
                addUserToCopyRuleState(user);
            }
        },
        [dispatch, isForCopyRule, addUserToCopyRuleState]
    );

    if (readonly) {
        return null;
    }

    return (
        <Root spacing='16 0 16 40' width='236px'>
            <AddUserButton
                users={team}
                excludedUsers={excludedUsers}
                onlyAddNewMode={onlyAddNewUser}
                onAddNew={onAddTempUser}
                onSelect={onAddUser}
                title={addUserButtonTitle}
            />
        </Root>
    );
});

export default MatrixAddUserButton;
