import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useMemo } from 'react';

import { ExpandedMatrixLine, getActiveTemplate } from '../../../../selectors/pageSelectors';
import { ActiveMatrixData } from '../../../../types/activeMatrixData';
import { MatrixType } from '../../../../types/matrix';
import { TCopyRulePopupCheckedSteps } from '../../MatrixPopupContent.types';

export const useCopyRulesPopupCheckedUsers = (checkedSteps: TCopyRulePopupCheckedSteps) => {
    return useMemo(() => Object.keys(checkedSteps), [checkedSteps]);
};

export const useCopyRulesPopupAvailableUsers = (
    company: selectors.types.ExpandedCompany,
    matrix: ActiveMatrixData<ExpandedMatrixLine>,
    userOrigin: domain.User | null
) => {
    const companyUsers = useSelector((state) => selectors.company.getCompanyTeam(state, company));

    if (!userOrigin) {
        return [];
    }

    switch (matrix.type) {
        case MatrixType.Requester:
        case MatrixType.Editor: {
            return companyUsers.filter((user) => user.id !== userOrigin.id);
        }

        case MatrixType.Editing:
            return companyUsers.filter((user) =>
                matrix.data.some((line) => line.user.id === user.id && line.user.id !== userOrigin.id)
            );

        case MatrixType.Approval:
        case MatrixType.Reviewer:
        default: {
            return [...companyUsers];
        }
    }
};

export const useCopyRulePopupHelpStripe = () => {
    const activeTemplate = useSelector(getActiveTemplate);

    if (!activeTemplate) {
        return null;
    }

    const integrationType =
        activeTemplate.integrationCode === null
            ? 'standalone'
            : domain.getIntegrationTypeByCode(activeTemplate.integrationCode);

    return `copy-rule-${integrationType.toLowerCase()}`;
};
