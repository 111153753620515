import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.StepUser', {
    invite: 'Invite',
    inviteAgain: 'Invite again',
    editRules: 'Edit rules',
    editRulesRemovedUser: 'This user has been removed from the Organisation.',
    notInvitedUserTitle:
        '{name} has not yet been invited to this organisation.\nClick the Invite button in order to invite this person',
});
