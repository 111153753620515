import { useMutation } from '@tanstack/react-query';
import { domain } from 'modules/data';
import { api } from 'services/api';

export const useCollectNurturingData = () => {
    const { isLoading, mutateAsync: collectNurturingData } = useMutation<
        void,
        unknown,
        {
            accountId: string;
            organisationCountOption: domain.OrganisationCountOption;
        }
    >((options) => {
        return api.account.collectNurturingData(options);
    });

    return {
        isLoading,
        collectNurturingData,
    };
};
