import { theme } from '@approvalmax/theme';
import { Box, Button } from '@approvalmax/ui/src/components';
import styled from 'styled-components';

export const CloseButton = styled(Button)`
    opacity: 0;
    transition: opacity ${theme.duration.medium}ms linear;
`;

export const Root = styled(Box)`
    &:hover {
        ${CloseButton} {
            opacity: 1;
        }
    }
`;
