import { Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import { EndStepWithDescriptionProps } from './EndStepWithDescription.types';

const EndStepWithDescription: FC<EndStepWithDescriptionProps> = memo((props) => {
    const { color, bubbleText, description, children } = props;

    return (
        <FixedStepContainer bubbleText={bubbleText} color={color}>
            <Text font='body' fontSize='small' color='midnight70'>
                {description}
            </Text>

            {children}
        </FixedStepContainer>
    );
});

export default EndStepWithDescription;
