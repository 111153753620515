import { useCallback, useMemo } from 'react';
import { useMount } from 'react-use';
import { useRecoilState } from 'recoil';

import { dropdownOpenState } from './DropdowMenu.states';
import type { DropdownMenuProps } from './DropdownMenu.types';

export const useDropdownOpen = (props: Pick<DropdownMenuProps, 'open' | 'onOpen' | 'initOpen'>) => {
    const { open: controlledOpen, onOpen, initOpen = false } = props;

    const isControlledOpen = controlledOpen !== undefined;
    const [dropdownOpen, setDropdownOpen] = useRecoilState(dropdownOpenState);
    const setOpen = useCallback(
        (open: boolean) => {
            onOpen?.(open);
            setDropdownOpen(open);
        },
        [onOpen, setDropdownOpen]
    );
    const isOpen = useMemo(
        () => (isControlledOpen ? controlledOpen : dropdownOpen),
        [controlledOpen, isControlledOpen, dropdownOpen]
    );

    useMount(() => {
        if (initOpen) {
            setOpen(true);
        }
    });

    return useMemo(() => [isOpen, setOpen] as const, [isOpen, setOpen]);
};
