import { QueryOptions, RequestDataParams } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetLazyRequestLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import {
    UseGetNetSuitePayeesRequestParams,
    UseGetNetSuitePayeesResponse,
    UseGetNetSuitePayeesResponseBackend,
} from './useGetNetSuitePayees.types';

export const useLazyGetNetSuitePayees = (queryOptions?: QueryOptions<UseGetNetSuitePayeesResponse>) => {
    return useGetLazyRequestLegacy<
        RequestDataParams<UseGetNetSuitePayeesRequestParams>,
        UseGetNetSuitePayeesResponseBackend,
        UseGetNetSuitePayeesResponse
    >(requestsApiPaths.netSuitePayees, {
        queryOptions,
        mapData: objectHelpers.pascalCaseToCamelCase,
    });
};
