import { Command } from '@approvalmax/types';
import { errorHelpers, oauthHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { getSSOMethodForAnalytics } from 'pages/auth/helpers';
import { useMemo, useState } from 'react';
import { amplitudeService } from 'services/amplitude';
import { api } from 'services/api';
import { gaService } from 'services/ga';
import { notificationService } from 'services/notification';
import { routingService } from 'services/routing';
import { storageService } from 'services/storage';
import { getUrl, Path } from 'urlBuilder';

import { messages } from './SsoPanel.messages';
import { SsoPanelProps } from './SsoPanel.types';

export const useSsoPanel = (props: SsoPanelProps) => {
    const {
        variant,
        app,
        afterLoginRedirectUrl,
        afterSignUpRedirectUrl,
        shouldReturnToCurrentPage = false,
        disabled = false,
        allowedEmail,
        onSubmit,
        onError,
    } = props;

    const [isLoading, setIsLoading] = useState(false);

    const authCommand = useMemo<Command>(
        () => ({
            disabled: disabled || isLoading,
        }),
        [disabled, isLoading]
    );

    let xeroAuthText = '';
    let googleAuthText = '';

    switch (variant) {
        case 'signin':
            xeroAuthText = messages.xeroSigninText;
            googleAuthText = messages.googleSigninText;
            break;

        case 'signup':
            xeroAuthText = messages.xeroSignupText;
            googleAuthText = messages.googleSignupText;
            break;
    }

    const onSignIn = async (options: {
        oauthProvider: domain.OAuthProvider;
        startingPoint: domain.SsoStartingPoint;
    }) => {
        if (variant === 'signup') {
            amplitudeService.sendData('signup: click on sign up button', {
                'signup type': `${options.oauthProvider} sso`,
            });

            await gaService.sendEvent('sign_up', {
                method: getSSOMethodForAnalytics(options.oauthProvider),
            });
        }

        try {
            setIsLoading(true);

            onSubmit &&
                onSubmit({
                    oauthProvider: options.oauthProvider,
                    startingPoint: options.startingPoint,
                });

            const uiState = encodeURIComponent(
                JSON.stringify({
                    app,
                    afterLoginRedirectUrl: shouldReturnToCurrentPage ? window.location.href : afterLoginRedirectUrl,
                    afterSignUpRedirectUrl,
                    startingPoint: options.startingPoint,
                })
            );

            let redirectUrl = getUrl(Path.oauth2Redirect);

            switch (options.oauthProvider) {
                case domain.OAuthProvider.Xero: {
                    const ssoStartingPoint =
                        options.startingPoint === domain.SsoStartingPoint.ApprovalMax
                            ? 'approvalmax'
                            : 'xero-marketplace';

                    const response = await api.auth.getXeroOAuthUrl({
                        redirectUrl,
                        ssoStartingPoint,
                        uiState,
                    });

                    redirectUrl = response.data.XeroOAuthUrl;
                    break;
                }

                case domain.OAuthProvider.QBooks: {
                    const ssoStartingPoint =
                        options.startingPoint === domain.SsoStartingPoint.ApprovalMax
                            ? 'approvalmax'
                            : 'qbooks-marketplace';

                    const response = await api.auth.getQBooksOAuthUrl({
                        redirectUrl,
                        ssoStartingPoint,
                        uiState,
                    });

                    redirectUrl = response.data.QBooksOAuthUrl;
                    break;
                }

                case domain.OAuthProvider.Google: {
                    const response = await api.auth.getGoogleOAuthUrl({
                        redirectUrl,
                        uiState,
                        ssoStartingPoint: 'approvalmax',
                    });

                    redirectUrl = response.data.GoogleOAuthUrl;
                    break;
                }

                case domain.OAuthProvider.Microsoft: {
                    const response = await api.auth.getMicrosoftOAuthUrl({
                        redirectUrl,
                        uiState,
                        ssoStartingPoint: 'approvalmax',
                    });

                    redirectUrl = response.data.MicrosoftOAuthUrl;
                    break;
                }

                default:
                    throw errorHelpers.assertNever(options.oauthProvider);
            }

            redirectUrl = oauthHelpers.encodeOAuthState<domain.OAuthUiState>(redirectUrl, {
                type: 'sso',
                provider: options.oauthProvider,
                startingPoint: options.startingPoint,
                allowedEmail,
            });

            storageService.setAuthenticated(false);

            routingService.navigateToExternalUrl(redirectUrl);
        } catch (err) {
            setIsLoading(false);

            notificationService.showErrorToast(messages.generalErrorMessage);

            onError && onError(err);
        }
    };

    const onSignInXero = () =>
        onSignIn({
            oauthProvider: domain.OAuthProvider.Xero,
            startingPoint: domain.SsoStartingPoint.ApprovalMax,
        });

    const onSignInIntuit = () =>
        onSignIn({
            oauthProvider: domain.OAuthProvider.QBooks,
            startingPoint: domain.SsoStartingPoint.ApprovalMax,
        });

    const onSignInGoogle = () =>
        onSignIn({
            oauthProvider: domain.OAuthProvider.Google,
            startingPoint: domain.SsoStartingPoint.ApprovalMax,
        });

    const onSignInMicrosoft = () =>
        onSignIn({
            oauthProvider: domain.OAuthProvider.Microsoft,
            startingPoint: domain.SsoStartingPoint.ApprovalMax,
        });

    return {
        isLoading,
        xeroAuthText,
        googleAuthText,
        onSignInXero,
        onSignInIntuit,
        onSignInGoogle,
        onSignInMicrosoft,
        authCommand,
    };
};
