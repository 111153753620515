import { atom } from 'recoil';

export const selectInputValueState = atom({
    key: 'selectInputValueState',
    default: '',
});

export const inputValuePristineState = atom({
    key: 'inputValuePristineState',
    default: false,
});

export const selectInputFocusState = atom({
    key: 'selectInputFocusState',
    default: false,
});
