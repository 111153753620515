import { ColumnDefinition } from '@approvalmax/ui/src/components';

import { RoleCell } from '../RoleCell/RoleCell';
import { messages } from './UsersList.messages';
import { ColumnId, InviteUser, UsersListProps } from './UsersList.types';

export const columnDefinitions = ({
    onChangeRole,
}: Pick<UsersListProps, 'onChangeRole'>): ColumnDefinition<InviteUser>[] => [
    {
        id: ColumnId.UserEmail,
        name: messages.emailColumnText,
        value: (user: InviteUser) => user.userEmail,
    },
    {
        id: ColumnId.Role,
        name: messages.roleColumnText,
        sortable: true,
        width: 160,
        cellComponent: (props) => <RoleCell {...props} onChangeRole={onChangeRole} />,
    },
    {
        id: ColumnId.StatusText,
        name: messages.statusColumnText,
        value: (user: InviteUser) => user.statusText,
    },
];
