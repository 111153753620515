import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, REVOKE_DECISION_RESPONSE, SHOW_REVOKE_DECISION_POPUP } from '../../../actions';

export const REVOKE_DECISION_POPUP = 'REVOKE_DECISION_POPUP';

export interface RevokeDecisionPopupData {
    id: typeof REVOKE_DECISION_POPUP;
    requestId: Guid;
}

export type RevokeDecisionPopupType = ImmutableObject<RevokeDecisionPopupData> | null;

export default function (state: RevokeDecisionPopupType, action: Action): RevokeDecisionPopupType {
    switch (action.type) {
        case SHOW_REVOKE_DECISION_POPUP:
            return immutable<RevokeDecisionPopupData>({
                id: REVOKE_DECISION_POPUP,
                requestId: action.payload.requestId,
            });

        case REVOKE_DECISION_RESPONSE:
            return null;

        default:
            return state;
    }
}
