import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'modules/common';
import { api } from 'services/api';

export const useDextSuggestedPlansQuery = (accountId: string, companyId: string) => {
    const { isLoading, data } = useQuery([QueryKeys.DEXT_SUGGESTED_PLANS, accountId, companyId], () =>
        api.account.getReceiptBankSuggestedPlans({
            accountId: accountId,
            organisationId: companyId,
        })
    );

    return {
        suggestedPlanIds: data?.suggestedPlanIds,
        isLoading,
    };
};
