import './globalThemeProvider.scss';

import { errorHelpers } from '@approvalmax/utils';
import React, { Component, PropsWithChildren } from 'react';

export enum GlobalTheme {
    Default = 'Default',
    Dark = 'Dark',
}

const GlobalThemeContext = React.createContext<GlobalTheme>(GlobalTheme.Default);

export const GlobalThemeConsumer = GlobalThemeContext.Consumer;

interface GlobalThemeProviderProps extends PropsWithChildren {
    value?: GlobalTheme;
}

class GlobalThemeProvider extends Component<GlobalThemeProviderProps> {
    public componentDidMount() {
        this._updateTheme(this.props.value);
    }

    public componentDidUpdate(prevProps: GlobalThemeProviderProps) {
        if (this.props.value !== prevProps.value) {
            this._updateTheme(this.props.value);
        }
    }

    public componentWillUnmount() {
        this._updateTheme(null);
    }

    public render() {
        const { value, children } = this.props;

        return (
            <GlobalThemeContext.Provider value={value || GlobalTheme.Default}>{children}</GlobalThemeContext.Provider>
        );
    }

    private _updateTheme = (theme: GlobalTheme | null | undefined) => {
        theme = theme || GlobalTheme.Default;

        switch (theme) {
            case GlobalTheme.Default:
                document.body.classList.remove('theme-dark');
                break;

            case GlobalTheme.Dark:
                document.body.classList.add('theme-dark');
                break;

            default:
                throw errorHelpers.assertNever(theme);
        }
    };
}

export default GlobalThemeProvider;
