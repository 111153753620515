import { PascalCaseToCamelCase } from '@approvalmax/types';
import { backend } from 'modules/data';

export type UseGetXeroCounterpartyPaymentDetailsHistoryPathParams = {
    companyId: string;
    counterpartyId: string;
};

export enum PaymentDetailsHistoryEventType {
    Unspecified = 'unspecified',
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
}

export interface PaymentDetailsHistoryItem {
    paymentDetailsId: string;
    paymentDetailsVersionId: string;
    eventType: PaymentDetailsHistoryEventType;
    bankAccountName: string;
    createdDate: string;
    author: PascalCaseToCamelCase<backend.UserAnswer>;
}

export type UseGetXeroCounterpartyPaymentDetailsHistoryResponse = {
    items: PaymentDetailsHistoryItem[];
};
