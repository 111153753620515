import { QueryOptions, QueryParams } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data/api';

import { integrationsApiPaths } from '../paths';
import {
    UseGetXeroCounterpartyPaymentDetailsPathParams,
    UseGetXeroCounterpartyPaymentDetailsResponse,
    UseGetXeroCounterpartyPaymentDetailsResponseBackend,
} from './useGetXeroCounterpartyPaymentDetails.types';

export const useGetXeroCounterpartyPaymentDetails = (
    pathParams: QueryParams<UseGetXeroCounterpartyPaymentDetailsPathParams>,
    queryOptions?: QueryOptions<
        UseGetXeroCounterpartyPaymentDetailsResponseBackend,
        UseGetXeroCounterpartyPaymentDetailsResponse
    >
) => {
    return useGetRequestLegacy<
        UseGetXeroCounterpartyPaymentDetailsResponseBackend,
        UseGetXeroCounterpartyPaymentDetailsResponse
    >(
        integrationsApiPaths.xeroCounterpartyPaymentDetails,
        {},
        {
            pathParams,
            queryOptions,
            method: 'get',
        }
    );
};
