import { Action, LOAD_PAGE_DATA, SHOW_VALIDATION_ERRORS } from '../../actions';
import { RequestEditMode } from '../../selectors/requestSelectors';

export type ShowValidationErrorsType = boolean;

const INITIAL_STATE: ShowValidationErrorsType = false;

export default function (state = INITIAL_STATE, action: Action): ShowValidationErrorsType {
    switch (action.type) {
        case LOAD_PAGE_DATA: {
            return (
                action.payload.editMode === RequestEditMode.Editor ||
                action.payload.editMode === RequestEditMode.Approver
            );
        }

        case SHOW_VALIDATION_ERRORS:
            return true;

        default:
            return state;
    }
}
