import { backend, domain } from 'modules/data';

import { parseXeroTaxCode } from './XeroTaxCode';

export function parseXeroAccount(value: backend.IntegrationsXeroAccount): domain.XeroAccount {
    return {
        id: value.Id,
        text: `${value.Code} - ${value.Name}`,
        name: value.Name,
        accountClass: value.AccountClass as any as domain.XeroAccountClass,
        accountCode: value.Code,
        taxCode: value.TaxCode ? parseXeroTaxCode(value.TaxCode) : undefined,
        systemAccount: value.SystemAccount,
    };
}
