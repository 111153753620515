import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.components.IntegrationCard', {
    connect: 'Connect',
    learnMore: 'Learn more',

    xeroTitle: 'Approval workflows {br}for Xero',
    xeroTooltip: 'Connect to your Xero organisation',
    xeroDescription: `
        Connect to your Xero organisation in order to set up multi-step approval
        workflows for your Xero documents such as Purchase Orders, Bills,
        AP Credit Notes, Sales Invoices, AR Credit Notes.
    `,

    qboTitle: 'Approval workflows {br}for QuickBooks Online',
    qboTooltip: 'Connect to your QuickBooks Online organisation',
    qboDescription: `
        Connect to your QuickBooks Online organisation in order to set up
        multi-step approval workflows for QuickBooks Online Purchase Orders.
    `,

    netSuiteTitle: 'Approval workflows {br}for Oracle NetSuite',
    netSuiteTooltip: 'Connect to your Oracle NetSuite account',
    netSuiteDescription: `
        Connect to your Oracle NetSuite account in order to set up
        multi-step approval workflows for Oracle NetSuite Bills.
    `,

    cin7Title: 'Approval workflows {br}for Cin7 Core',
    cin7Tooltip: 'Connect to your Cin7 Core account',
    cin7Description: `
        Connect to your Cin7 Core account in order to set up
        multi-step approval workflows for Cin7 Core Bills.
    `,
});
