export enum ColumnGroup {
    LineItemField = 'LineItemField',
    KeyData = 'KeyData',
    Workflow = 'Workflow',
    Compliance = 'Compliance',
    Matching = 'Matching',
    PoDetails = 'PoDetails',
    QuoteDetails = 'QuoteDetails',
    BillDetails = 'BillDetails',
    ExpenseDetails = 'ExpenseDetails',
    SalesInvoiceDetails = 'SalesInvoiceDetails',
    CreditNotesPayableDetails = 'CreditNotesPayableDetails',
    StandaloneWorkflowFields = 'StandaloneWorkflowFields',
    GrnStatus = 'GrnStatus',
    JournalEntryDetails = 'JournalEntryDetails',
}

export interface ColumnGroupItem {
    id: ColumnGroup;
    text: string;
}
