import { domain } from 'modules/data';

export const PAGE_ID = 'auth';

export interface LoginParams {
    email?: string;
    postAction?: string;
    app?: domain.SsoLoginApplication;
}

export interface LoadSsoStep2SequenceOptions {
    oauthProvider: domain.OAuthProvider;
    code: string;
    state: string;
    allowedEmail?: string;
}

export interface LoadSsoStep3SequenceOptions {
    oauthProvider: domain.OAuthProvider;
    code: string;
    state: string;
    externalOrgId: string;
}

export enum QueryKeys {
    ResetPasswordFinalStep = 'ResetPasswordFinalStep',
}

export const LOGIN_PAGE_ID = 'login_page';
export const SIGN_UP_PAGE_ID = 'sign_up_page';
export const EMAIL_CONFIRMATION_PAGE_ID = 'email_confirmation_page';
export const PROFILE_SETUP_EMAIL_PAGE_ID = 'profile_setup_email_page';
export const PROFILE_SETUP_SSO_PAGE_ID = 'profile_setup_sso_page';
export const MOBILE_INFO_PAGE_ID = 'mobile_info_page';
export const RB_AUTH_PAGE_ID = 'rb_auth_page';
export const RESET_PASSWORD_FINAL_STEP_PAGE_ID = 'reset_password_final_step_page';
export const RESET_PASSWORD_PAGE_ID = 'reset_step_page';
export const VERIFY_EMAIL_PAGE_ID = 'verify_email_page';
export const FIRST_TIME_SETUP_PAGE_ID = 'first_time_setup_page';
export const SLACK_AUTH_CONNECT_PAGE_ID = 'slack_auth_connect_page';
export const SLACK_AUTH_CONNECT_USER_PAGE_ID = 'slack_auth_connect_user_page';
export const SLACK_AUTH_INSTALL_PAGE_ID = 'slack_auth_install_page';
