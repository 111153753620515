import {
    arrow,
    autoUpdate,
    flip,
    limitShift,
    offset,
    safePolygon,
    shift,
    size,
    useClick,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
} from '@floating-ui/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useMount } from 'react-use';

import { DropdownProps } from './Dropdown.types';

export const useOpen = (props: Pick<DropdownProps, 'open' | 'onOpen' | 'initOpen'>) => {
    const { open: controlledOpen, onOpen, initOpen = false } = props;

    const isControlledOpen = controlledOpen !== undefined;
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initOpen);
    const setOpen = useCallback(
        (open: boolean) => {
            onOpen?.(open);
            setUncontrolledOpen(open);
        },
        [onOpen]
    );
    const isOpen = useMemo(
        () => (isControlledOpen ? controlledOpen : uncontrolledOpen),
        [controlledOpen, isControlledOpen, uncontrolledOpen]
    );

    useMount(() => {
        if (initOpen) {
            setOpen(true);
        }
    });

    return useMemo(() => ({ isOpen, setOpen }), [isOpen, setOpen]);
};

export const useFloatingDropdown = (
    props: ReturnType<typeof useOpen> &
        Required<Pick<DropdownProps, 'placement' | 'isClicking' | 'isHovering' | 'hasArrow' | 'safePolygon'>> &
        Pick<DropdownProps, 'width' | 'height' | 'disabled'>
) => {
    const {
        isOpen,
        setOpen,
        placement,
        isClicking,
        isHovering,
        hasArrow,
        width,
        height,
        disabled,
        safePolygon: safePolygonProp,
    } = props;

    const setArrow = useRef<HTMLDivElement | null>(null);

    const data = useFloating({
        open: isOpen,
        onOpenChange: setOpen,
        placement,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(hasArrow ? 12 : 4),
            size({
                apply({ rects, elements }) {
                    const resultWidth = width
                        ? typeof width === 'number'
                            ? `${width}px`
                            : width
                        : `${rects.reference.width}px`;

                    const resultHeight = height ? (typeof height === 'number' ? `${height}px` : height) : undefined;

                    Object.assign(elements.floating.style, {
                        width: resultWidth,
                        height: resultHeight,
                    });
                },
            }),
            flip({
                fallbackAxisSideDirection: 'start',
                crossAxis: placement.includes('-'),
            }),
            shift({ limiter: limitShift({ offset: 16 }), padding: 12 }),
            hasArrow && arrow({ element: setArrow, padding: 4 }),
        ],
    });

    const context = data.context;

    const hover = useHover(context, {
        move: false,
        enabled: isHovering && !disabled,
        handleClose: safePolygonProp
            ? safePolygon({
                  requireIntent: false,
              })
            : undefined,
    });
    const focus = useFocus(context, {
        enabled: isHovering && !disabled,
    });
    const click = useClick(context, {
        enabled: isClicking && !disabled,
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: isHovering ? 'tooltip' : 'dialog' });

    const interactions = useInteractions([dismiss, role, click, focus, hover]);

    return useMemo(
        () => ({
            ...interactions,
            ...data,
            refs: {
                ...data.refs,
                setArrow,
            },
        }),
        [interactions, data]
    );
};
