import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { changeProfilePopupModified } from '../../actions';
import { useSaveAndSubmitRequestMutation } from './hooks';

interface ProfileContextType {
    editProfile: <TKey extends keyof domain.Profile>(key: TKey, value: domain.Profile[TKey]) => void;
    profile: selectors.types.ExpandedProfile;
    showErrors: boolean;
    setShowErrors: (newValue: boolean) => void;
    saveAndSubmitProfile: () => void;
    modified: boolean;
    isLoading: boolean;
}

const ProfileContext = React.createContext<ProfileContextType>(null as any);

interface ProfileContextProviderProps extends PropsWithChildren {
    profile: selectors.types.ExpandedProfile;
}

export const ProfileContextProvider: FC<ProfileContextProviderProps> = (props) => {
    const { children, profile: defaultProfile } = props;

    const dispatch = useDispatch();
    const [profile, setProfile] = useState(defaultProfile);
    const [showErrors, setShowErrors] = useState(false);
    const [modified, setModified] = useState(profile.modified);

    const editProfile = useCallback(
        <TKey extends keyof domain.Profile>(key: TKey, value: domain.Profile[TKey]) => {
            setProfile((prevProfile) => ({
                ...prevProfile,
                [key]: value,
            }));

            if (!defaultProfile.modified) {
                dispatch(changeProfilePopupModified(true));
            }
        },
        [defaultProfile.modified, dispatch]
    );

    useEffect(() => {
        setModified(defaultProfile.modified);
    }, [defaultProfile.modified]);

    useEffect(() => {
        setProfile((prevProfile) => ({
            ...prevProfile,
            is2faEnabled: defaultProfile.is2faEnabled,
            isPasswordSet: defaultProfile.isPasswordSet,
            isTFAAlternativeEmailEnabled: defaultProfile.isTFAAlternativeEmailEnabled,
            isTFABackupCodesEnabled: defaultProfile.isTFABackupCodesEnabled,
        }));
    }, [
        defaultProfile.is2faEnabled,
        defaultProfile.isPasswordSet,
        defaultProfile.isTFAAlternativeEmailEnabled,
        defaultProfile.isTFABackupCodesEnabled,
        editProfile,
    ]);

    const { saveAndSubmitProfile, isLoading } = useSaveAndSubmitRequestMutation(profile);

    const value = useMemo(
        () => ({
            editProfile,
            profile,
            showErrors,
            setShowErrors,
            saveAndSubmitProfile,
            modified,
            isLoading,
        }),
        [editProfile, profile, showErrors, saveAndSubmitProfile, modified, isLoading]
    );

    return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export function useProfileContext() {
    return useContext(ProfileContext);
}
