import { produce } from 'immer';
import { domain } from 'modules/data';

import {
    Action,
    CLOSE_ACTIVE_POPUP,
    COMMIT_STRIPE_SUBSCRIPTION_UPGRADE_RESPONSE,
    COMMIT_SUBSCRIPTION_UPGRADE_RESPONSE,
    SELECT_EXISTING_SUBSCRIPTION,
    SELECT_EXISTING_SUBSCRIPTION_FAILURE,
    SELECT_EXISTING_SUBSCRIPTION_RESPONSE,
    SELECT_NEW_SUBSCRIPTION,
    SELECT_TRIAL_SUBSCRIPTION,
    SET_COMPANY_PLAN_FAILURE,
    SET_COMPANY_PLAN_RESPONSE,
    SHOW_COLLECT_ACCOUNT_NURTURING_DATA_POPUP,
    SHOW_ORGANISATION_POST_CREATION_WIZARD_RESPONSE,
    SHOW_PLAN_POPUP,
    SHOW_SUBSCRIPTION_POPUP_RESPONSE,
    SHOW_XERO_DEMO_ORGANISATION_POPUP,
} from '../actions';

export enum ActivePopup {
    OrganisationNurturingPopup = 'OrganisationNurturingPopup',
    SelectSubscriptionPopup = 'SelectSubscriptionPopup',
    SelectPlanPopup = 'SelectPlanPopup',
    XeroDemoOrganisationPopup = 'XeroDemoOrganisationPopup',
    CollectAccountNurturingDataPopup = 'CollectAccountNurturingDataPopup',
}

export interface ModuleData {
    activePopup: ActivePopup | null;
    companyId: string | null;
    applicableSubscriptions: string[];
    loading: boolean;
    pricingOptions: domain.PricingOptions[];
    subscriptions: domain.Subscription[];
}

const INITIAL_STATE: ModuleData = {
    activePopup: null,
    companyId: null,
    applicableSubscriptions: [],
    loading: false,
    pricingOptions: [],
    subscriptions: [],
};

export default produce((draft: ModuleData, action: Action) => {
    switch (action.type) {
        case SHOW_ORGANISATION_POST_CREATION_WIZARD_RESPONSE:
            draft.activePopup = null;
            draft.loading = false;

            return draft;

        case SHOW_COLLECT_ACCOUNT_NURTURING_DATA_POPUP:
            draft.activePopup = ActivePopup.CollectAccountNurturingDataPopup;
            draft.companyId = action.payload.companyId;

            return draft;

        case SHOW_SUBSCRIPTION_POPUP_RESPONSE:
            draft.activePopup = ActivePopup.SelectSubscriptionPopup;
            draft.companyId = action.payload.companyId;
            draft.applicableSubscriptions = action.payload.applicableSubscriptions;
            draft.pricingOptions = action.payload.pricingOptions;
            draft.subscriptions = action.payload.subscriptions;

            return draft;

        case SHOW_PLAN_POPUP:
            draft.activePopup = ActivePopup.SelectPlanPopup;
            draft.companyId = action.payload.companyId;

            return draft;

        case SHOW_XERO_DEMO_ORGANISATION_POPUP:
            draft.activePopup = ActivePopup.XeroDemoOrganisationPopup;
            draft.companyId = action.payload.companyId;

            return draft;

        case COMMIT_STRIPE_SUBSCRIPTION_UPGRADE_RESPONSE:
        case COMMIT_SUBSCRIPTION_UPGRADE_RESPONSE:
            draft.activePopup = null;
            draft.loading = false;

            return draft;

        case SELECT_EXISTING_SUBSCRIPTION:
            draft.loading = true;

            return draft;

        case SET_COMPANY_PLAN_RESPONSE:
        case SELECT_EXISTING_SUBSCRIPTION_RESPONSE:
            draft.activePopup = null;
            draft.loading = false;

            return draft;

        case SET_COMPANY_PLAN_FAILURE:
        case SELECT_EXISTING_SUBSCRIPTION_FAILURE:
            draft.loading = false;

            return draft;

        case SELECT_TRIAL_SUBSCRIPTION:
            draft.activePopup = null;

            return draft;

        case SELECT_NEW_SUBSCRIPTION:
            draft.loading = true;

            return draft;

        case CLOSE_ACTIVE_POPUP:
            draft.activePopup = null;

            return draft;

        default:
            return draft;
    }
}, INITIAL_STATE);
