import { Reference } from '@approvalmax/types';
import { errorHelpers, intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';

const i18nPrefix = 'app.WorkflowId.selectors.conditionSelector';

const conditionText = (c: domain.MatrixCondition, integrationCode: domain.IntegrationCode | null) => {
    const isNegativeExactConstraint = c.conditionType === domain.ConditionType.NegativeExactValuesCondition;

    let values: Reference[] = (c as any).exactValues || [];

    if (values.length === 0) {
        return '';
    }

    let valuesText = values.map((value) => value.text).join(', ');

    const fieldName = selectors.field.getFieldNameBySystemPurpose(c.fieldSystemPurpose, integrationCode, c.fieldName);

    return isNegativeExactConstraint
        ? intl.formatMessage(
              {
                  id: `${i18nPrefix}.submitter_rules_preview_not_field_pattern`,
                  defaultMessage: '{fieldName} does not match {values}',
              },
              {
                  fieldName,
                  values: valuesText,
              }
          )
        : intl.formatMessage(
              {
                  id: `${i18nPrefix}.submitter_rules_preview_field_pattern`,
                  defaultMessage: '{fieldName} matches {values}',
              },
              {
                  fieldName,
                  values: valuesText,
              }
          );
};

const numericConditionText = (c: domain.NumericRangeCondition, defaultCurrency: string) => {
    const currency = defaultCurrency;

    const amountCondition = c as domain.NumericRangeCondition;
    const less = intl.formatNumber(amountCondition.numericRangeLess);
    const greaterEq = intl.formatNumber(amountCondition.numericRangeGreaterEquals);

    let amountValueText;

    switch (amountCondition.numericRangeConditionType) {
        case domain.NumericRangeConditionType.Above:
            amountValueText = intl.formatMessage(
                {
                    id: `${i18nPrefix}.participant_rules_preview_amount_pattern_above`,
                    defaultMessage: 'over or equal to {greaterEq} {currency}',
                },
                {
                    greaterEq,
                    currency,
                }
            );
            break;

        case domain.NumericRangeConditionType.Below:
            amountValueText = intl.formatMessage(
                {
                    id: `${i18nPrefix}.participant_rules_preview_amount_pattern_below`,
                    defaultMessage: 'under {less} {currency}',
                },
                {
                    less,
                    currency,
                }
            );
            break;

        case domain.NumericRangeConditionType.Within:
            amountValueText = intl.formatMessage(
                {
                    id: `${i18nPrefix}.participant_rules_preview_amount_pattern_within`,
                    defaultMessage: 'over or equal to {greaterEq} and under {less} {currency}',
                },
                {
                    less,
                    greaterEq,
                    currency,
                }
            );
            break;

        case domain.NumericRangeConditionType.Any:
            throw errorHelpers.notSupportedError();

        default:
            throw errorHelpers.assertNever(amountCondition.numericRangeConditionType);
    }

    const netAmountText = intl.formatMessage({
        id: `${i18nPrefix}.participant_rules_preview_net_amount_text`,
        defaultMessage: 'Net amount',
    });
    const totalAmountText = intl.formatMessage({
        id: `${i18nPrefix}.participant_rules_preview_total_amount_text`,
        defaultMessage: 'Total amount',
    });

    const amountName = c.amountType === domain.AmountType.Net ? netAmountText : totalAmountText;

    const fieldName = c.fieldSystemPurpose === domain.FieldSystemPurpose.Amount ? amountName : c.fieldName;

    return intl.formatMessage(
        {
            id: `${i18nPrefix}.participant_rules_preview_amount_pattern`,
            defaultMessage: '{name} is {amount}',
        },
        {
            amount: amountValueText,
            name: fieldName,
        }
    );
};

export const getConditionDislplayText = (
    condition: domain.MatrixCondition,
    defaultCurrency: string,
    integrationCode: domain.IntegrationCode | null
) => {
    switch (condition.conditionType) {
        case domain.ConditionType.NegativeExactValuesCondition:
        case domain.ConditionType.ExactValuesCondition: {
            return conditionText(condition, integrationCode);
        }

        case domain.ConditionType.NumericRangeCondition: {
            return numericConditionText(condition, defaultCurrency);
        }

        case domain.ConditionType.ServerCondition:
        case domain.ConditionType.BoolCondition: {
            throw errorHelpers.notSupportedError();
        }

        case null: {
            throw errorHelpers.notSupportedError();
        }

        default: {
            throw errorHelpers.assertNever(condition);
        }
    }
};
