import { mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { font } from '../../../styles';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    padding: 0;
    background-color: inherit;
    gap: 15px;
`;

const SignInButton = styled.button<StyledMods<{ height: number }>>`
    width: 100%;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${theme.color.white100};
    border-radius: 4px;
    transition: all 0.3s;

    ${mods('height')(
        (value) => css`
            height: ${value}px;
        `
    )}

    &:not(:disabled):active,
    &:not(:disabled):hover {
        background: darken(#003c64, 8%);
    }

    &:focus {
        @include o-focus;
    }

    &:disabled {
        cursor: default;
    }
`;

export const XeroButton = styled(SignInButton)`
    ${font(16, '#fff', 'semibold')}
    background: #003c64;

    & > :first-child {
        ${font(14, '#fff', 'semibold')}
        text-transform: none;
        margin-right: 12px;
    }
`;

export const IntuitButton = styled(SignInButton)`
    color: #0077c5;
    background: #0077c5;

    &:not(:disabled):active,
    &:not(:disabled):hover {
        color: #0365ac;
        background: #0365ac;
    }
`;

export const GoogleButton = styled(SignInButton)<StyledMods<{ withWhiteButtons?: boolean }>>`
    ${font(14, '#384650', 'semibold')}

    & > :first-child {
        margin-right: 10px;
    }

    background-color: #f3f3f3;

    ${mods('withWhiteButtons', true)`
        background-color: ${theme.color.white100};
    `};
`;

export const MicrosoftButton = styled(SignInButton)<StyledMods<{ withWhiteButtons?: boolean }>>`
    ${font(13, '#384650', 'semibold')}

    & > :first-child {
        margin-right: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    background-color: #f3f3f3;

    ${mods('withWhiteButtons', true)`
        background-color: ${theme.color.white100};
    `};

    & svg path[fill='#FFF'] {
        fill: #f3f3f3;

        ${mods('withWhiteButtons', true)`
            fill: ${theme.color.white100};
        `};
    }
`;

export const Caption = styled.div`
    ${font(11, theme.color.midnight70)}
`;
