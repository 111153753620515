import { Flex, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { messages } from './SoftEnforcementStart.messages';

export const SoftEnforcementStartSkeleton = memo(() => {
    return (
        <Flex inline direction='column'>
            <Text font='headline' fontSize='small' fontWeight='regular'>
                {messages.title}
            </Text>

            <Skeleton height={110} />

            <Skeleton height={36} />

            <Skeleton height={36} />
        </Flex>
    );
});

SoftEnforcementStartSkeleton.displayName = 'SoftEnforcementStartSkeleton';
