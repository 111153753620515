import { intl } from '@approvalmax/utils';
import { constants } from 'modules/common';
import { domain, State } from 'modules/data';
import { defineMessages } from 'react-intl';

import { getStandaloneFields } from './standaloneSelectors';

const { standaloneConstants } = constants;

const i18nPrefix = 'requestForm/selectors/standalone/validationSelectors';
const messages = defineMessages({
    mandatoryFieldsErrorText: {
        id: `${i18nPrefix}.mandatoryFieldsErrorText`,
        defaultMessage: 'Please fill in mandatory fields',
    },
    understatedAmountErrorText: {
        id: `${i18nPrefix}.understatedAmountErrorText`,
        defaultMessage: 'Amount cannot be less than 0.',
    },
    exceededAmountErrorText: {
        id: `${i18nPrefix}.exceededAmountErrorText`,
        defaultMessage: 'Amount cannot be more than 999,999,999,999,999.',
    },
    mandatoryNameErrorText: {
        id: `${i18nPrefix}.mandatoryNameErrorText`,
        defaultMessage: 'Name is mandatory.',
    },
    mandatoryDescriptionErrorText: {
        id: `${i18nPrefix}.mandatoryDescriptionErrorText`,
        defaultMessage: 'Description is mandatory.',
    },
    exceededNameLengthErrorText: {
        id: `${i18nPrefix}.exceededNameLengthErrorText`,
        defaultMessage: 'Max length of name is 255.',
    },
});

export const validateStandaloneRequest = (state: State, request: domain.StandaloneRequest): string[] => {
    const mandatoryFieldsError = Boolean(
        request.amount == null || !request.currency || getStandaloneFields(state).some((fv) => !fv.value)
    );

    let errors: string[] = [];

    if (!request.details.name?.trim()) {
        errors.push(intl.formatMessage(messages.mandatoryNameErrorText));
    }

    if (!request.details.description?.value?.trim()) {
        errors.push(intl.formatMessage(messages.mandatoryDescriptionErrorText));
    }

    if (request.details.description.errorMessages) {
        errors.push(...request.details.description.errorMessages);
    }

    if (request.details.name && request.details.name.length > standaloneConstants.REQUEST_NAME_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.exceededNameLengthErrorText));
    }

    if (request.amount != null && request.amount < standaloneConstants.TOTAL_MIN_AMOUNT) {
        errors.push(intl.formatMessage(messages.understatedAmountErrorText));
    }

    if (request.amount != null && request.amount > standaloneConstants.TOTAL_MAX_AMOUNT) {
        errors.push(intl.formatMessage(messages.exceededAmountErrorText));
    }

    if (mandatoryFieldsError) {
        errors.push(intl.formatMessage(messages.mandatoryFieldsErrorText));
    }

    return errors;
};
