import { stateTree } from 'modules/data';
import { Module } from 'modules/page';

import dataProvidersReducer, { getCachedData, getStorage } from './reducers';
import loadItemsSaga from './sagas/loadItemsSaga';

export { clearCacheStorage, clearCacheStorageAmaxPayPaymentDetails, loadItems } from './actions';
export * from './constants';
export { default as AsyncDataProvider } from './dataProviders/AsyncDataProvider';
export { default as PlainDataProvider } from './dataProviders/PlainDataProvider';
export * from './typings/DataItem';
export * from './typings/PlainDataProviderFilterFunction';

export const selectors = {
    getStorage: (state: stateTree.State, cacheStorageId: string) => getStorage(state.dataProviders, cacheStorageId),
    getCachedData,
};

export const moduleDef: Module = {
    id: 'dataProviders',
    reducers: {
        dataProviders: dataProvidersReducer,
    },
    sagas: [loadItemsSaga],
};
