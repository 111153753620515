import { stateTree } from 'modules/data';

import { HIDDEN_HELP_ITEMS_USER_PREFERENCE } from '../reducers/userPreferencesReducer';

type State = stateTree.State;

export function getHiddenHelpItems(state: State): string[] {
    return getUserPreference(state, HIDDEN_HELP_ITEMS_USER_PREFERENCE) || [];
}

export function getUserPreference<TValue = any>(state: State, userPreferenceKey: string): TValue | null;
export function getUserPreference<TValue = any>(state: State, userPreferenceKey: string, fallbackValue: TValue): TValue;

export function getUserPreference(state: State, userPreferenceKey: string, fallbackValue?: any): any {
    const fallback = fallbackValue !== undefined ? fallbackValue : null;

    if (!state.userPreferences) {
        return fallback;
    }

    return state.userPreferences[userPreferenceKey] || fallback;
}
