import { domain } from 'modules/data';

import { allowedConditionTypes } from './GeneralConditionCell.constants';
import { messages } from './GeneralConditionCell.messages';

export const useConditionItems = (
    fieldName: string
): { id: (typeof allowedConditionTypes)[number]; name: string }[] => {
    return [
        {
            id: 'any',
            name: messages.anyFieldName({ fieldName }),
        },
        {
            id: domain.ConditionType.ExactValuesCondition,
            name: messages.fieldNameMatches({ fieldName }),
        },
        {
            id: domain.ConditionType.NegativeExactValuesCondition,
            name: messages.fieldNameDoesNotMatch({ fieldName }),
        },
    ];
};
