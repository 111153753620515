import { domain } from 'modules/data';

export enum WarningLevel {
    None = 'none',
    Yellow = 'yellow',
    Orange = 'orange',
    Red = 'red',
    Black = 'black',
}

export interface ChildrenComponents {
    WarningLevel: typeof WarningLevel;
}

export interface PriceCheckIndicatorProps {
    actualPrice?: number;
    expectedPrice?: number;
    disabled?: boolean;
    className?: string;
    integrationType: domain.IntegrationType.Xero | domain.IntegrationType.NetSuite | domain.IntegrationType.QBooks;
    integrationCode: domain.IntegrationCode;
    isMultiCurrency?: boolean;
    children: (options: { warningLevel: WarningLevel }) => React.ReactNode;
}
