import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { ListProps } from './List.types';

export const Root = styled.ul<
    StyledMods<Pick<ListProps, 'as' | 'removeLastSpacing' | 'removeLastDivider' | 'listStyle'>>
>`
    ${mods('removeLastSpacing', true)`
        > li:last-child {
            padding-bottom: 0;
        }
    `};

    ${mods('removeLastDivider', true)`
        > li:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    `};

    ${mods('as', 'ol')`
        list-style: decimal;
        padding-left: 16px;
    `};

    ${mods('listStyle')(
        (value) => css`
            list-style: ${value};
        `
    )};
`;
