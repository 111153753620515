import { mods, theme } from '@approvalmax/theme';
import { Box } from '@approvalmax/ui/src/components';
import type { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled(Box)<StyledMods<{ disconnected: boolean }>>`
    ${mods('disconnected', true)`
        background-color: ${theme.color.silver80};
        border-color: ${theme.color.silver80};

        img {
            filter: grayscale(1);
        }
    `}
`;
