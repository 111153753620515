import { selectors } from 'modules/common';
import { Module } from 'modules/page';
import { ThunkAction } from 'modules/react-redux';
import { routingService } from 'services/routing';
import { isOcrEnabledForTemplate } from 'shared/helpers';
import { getPath, Path } from 'urlBuilder';

import { showActiveCompanyNewRequestPopup, showNewRequestPopup } from './actions';
import { ModuleComponent } from './ModuleComponent';
import entitiesReducer from './reducers/entitiesReducer';
import moduleReducer from './reducers/moduleReducer';

export * from './actions';
export { default as PriceCheckIndicator } from './components/PriceCheckIndicator/PriceCheckIndicator';
export * from './components/PriceCheckIndicator/PriceCheckIndicator';
export { WarningLevel } from './components/PriceCheckIndicator/PriceCheckIndicator.types';
export * from './components/TwoDifferentValuesWarning/TwoDifferentValuesWarning';
export * from './components/WarningIndicator/WarningIndicator';
export { default as AmountDueIndicator } from './components/xero/AmountDueIndicator';
export { default as BankAccountCheckIndicator } from './components/xero/BankAccountCheckIndicator';
export * from './components/xero/OcrNotificationPopupContent/OcrNotificationPopupContent.states';
export * from './reducers';
export * from './selectors';
export * from 'modules/request/components/xero/BulkCreationPopupContent/BulkCreationPopupContent.states';

export function loadNewRequestPopup(): ThunkAction {
    return (dispatch, getState) => {
        const creatableTemplates = selectors.meta.getCreatableTemplates(getState());
        const companies = selectors.company.getCompanies(getState());

        const hasCompanyWithEnabledOcr = companies.some((company) => {
            return selectors.template
                .getIntegrationTemplatesByCompanyId(getState(), company.id)
                .some(
                    (template) =>
                        isOcrEnabledForTemplate(
                            company.betaFeatures,
                            company.licenseFeatures,
                            template.integrationCode
                        ) && creatableTemplates.includes(template.id)
                );

            return false;
        });

        if (creatableTemplates.length === 1 && !hasCompanyWithEnabledOcr) {
            const firstTemplateId = creatableTemplates[0];
            const template = selectors.template.getTemplateById(getState(), firstTemplateId);

            routingService.push(getPath(Path.newRequest, firstTemplateId, template.companyId));
        } else {
            dispatch(showNewRequestPopup());
        }
    };
}

export function loadActiveCompanyNewRequestPopup(activeCompanyId: string): ThunkAction {
    return (dispatch, getState) => {
        const activeCompanyCreatableTemplates = selectors.meta
            .getCreatableTemplates(getState())
            .map((id) => selectors.template.getTemplateById(getState(), id))
            .filter((template) => template.companyId === activeCompanyId);

        if (activeCompanyCreatableTemplates.length === 1) {
            routingService.push(
                getPath(
                    Path.newRequest,
                    activeCompanyCreatableTemplates[0].id,
                    activeCompanyCreatableTemplates[0].companyId
                )
            );
        } else {
            dispatch(showActiveCompanyNewRequestPopup(activeCompanyId));
        }
    };
}

export const moduleDef: Module = {
    id: 'request',
    moduleReducer: moduleReducer,
    moduleComponent: ModuleComponent,
    reducers: {
        entities: entitiesReducer,
    },
};
