import { getDateFormatMask } from './getDateFormatMask';

export const getDateTimeFormatMask = (locale: Intl.LocalesArgument, ampm: boolean | undefined, mask?: string) => {
    const dateFormatMask = getDateFormatMask(locale, mask);
    const dateFormatter = new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: ampm ? 'h12' : undefined,
    });
    const parts = dateFormatter.formatToParts(new Date());

    const order = parts
        .map((part) => {
            if (part.type !== 'literal') {
                return part.type;
            }

            return part.value;
        })
        .join('');

    return `${dateFormatMask}${order.replace('dayPeriod', 'aa').replace('hour', ', hh').replace('minute', 'mm')}`;
};
