import { RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { slackApiPaths } from '../paths';
import { UseSlackAuthUserCompleteParams, UseSlackAuthUserCompleteResponse } from './useSlackAuthUserComplete.types';

export const useSlackAuthUserComplete = () => {
    return useMutate<RequestDataParams<UseSlackAuthUserCompleteParams, {}>, UseSlackAuthUserCompleteResponse>(
        slackApiPaths.authUserComplete,
        {
            apiSource: 'slack',
        }
    );
};
