import { DataTable } from '@approvalmax/ui';
import { memo } from 'react';

import { messages } from './LastSyncErrorCell.messages';
import { ErrorText } from './LastSyncErrorCell.styles';
import { LastSyncErrorCellProps } from './LastSyncErrorCell.types';

const LastSyncCell = memo<LastSyncErrorCellProps>((props) => {
    const { item } = props;

    const { isLastPullingFailed } = item;

    return (
        <DataTable.DefaultCell {...props}>
            {isLastPullingFailed ? <ErrorText>{messages.error}</ErrorText> : '-'}
        </DataTable.DefaultCell>
    );
});

export default LastSyncCell;
