import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { requestsApiPaths } from '../paths';
import {
    UseGetCurrencyExchangeRatePathParams,
    UseGetCurrencyExchangeRateQueryParams,
    UseGetCurrencyExchangeRateResponse,
} from './useGetCurrencyExchangeRate.types';

export const useGetCurrencyExchangeRate = (
    pathParams: UseGetCurrencyExchangeRatePathParams,
    queryParams: UseGetCurrencyExchangeRateQueryParams,
    queryOptions?: QueryOptions<UseGetCurrencyExchangeRateResponse>
) => {
    return useGetRequest<UseGetCurrencyExchangeRateResponse>(requestsApiPaths.currencyExchangeRate, {
        pathParams,
        params: queryParams,
        queryOptions,
        apiVersion: 'v2',
    });
};
