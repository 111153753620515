import { produce } from 'immer';
import { domain } from 'modules/data';

import { Action, CREATE_ACCOUNT_RESPONSE } from '../actions';

export default function (state: domain.Profile, action: Action): domain.Profile {
    switch (action.type) {
        case CREATE_ACCOUNT_RESPONSE:
            return produce(state, (draft) => {
                draft.account = {
                    accountId: action.payload.raw.data.accountId,
                    ownerUserProfileId: action.payload.raw.data.ownerInfo.id,
                    accountType: action.payload.raw.data.type,
                    isPartnerAccount: false,
                    partnerStatus: domain.ProfilePartnerStatus.Undefined,
                };
            });

        default:
            return state;
    }
}
