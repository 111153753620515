import { Environment } from '@approvalmax/types';

export const isEnvironment = (value: unknown): value is Environment => {
    if (!value || typeof value !== 'string') {
        return false;
    }

    return (
        value === 'production' ||
        value === 'staging' ||
        value === 'dev01' ||
        value === 'dev02' ||
        value === 'dev03' ||
        value === 'dev04' ||
        value === 'debug' ||
        value === 'debugweb'
    );
};
