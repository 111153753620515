import { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { UseGetBudgetSettingsResonse, UseGetBudgetsSettingsRequestParams } from './useGetBudgetsSettings.types';

export const useGetBudgetsSettings = (
    params: UseGetBudgetsSettingsRequestParams,
    queryOptions?: QueryOptions<UseGetBudgetSettingsResonse>
) => {
    return useGetRequestLegacy<UseGetBudgetSettingsResonse>(budgetsApiPaths.getBudgetSettings, params, {
        queryOptions,
    });
};
