import { Popup } from '@approvalmax/ui';
import { arrayHelpers, compareHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';
import { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateUserPreference } from './actions';
import LicenseOnGracePopup from './containers/LicenseOnGracePopup';
import ToastNotifications from './containers/ToastNotifications';

const HIDDEN_GRACE_WARNINGS_USER_PREFERENCE = 'common/ModuleComponent/hiddenGraceWarning';

const ModuleComponent: FC = () => {
    const dispatch = useDispatch();

    const getManagedSubscriptionCompanies = (license: selectors.types.ExpandedSubscription) => {
        return license.companies.filter((c) => c.flags.isManager);
    };

    const isSubscriptionManagedByMe = (subscription: selectors.types.ExpandedSubscription) => {
        // also implicitly checks that a subscription has companies in it
        return getManagedSubscriptionCompanies(subscription).length > 0;
    };

    const graceSubscription = useSelector((state: stateTree.State): selectors.types.ExpandedSubscription[] => {
        const loaded = selectors.navigation.getLoaded(state);
        const me = selectors.profile.findProfileUser(state);

        if (!me || !loaded) {
            return arrayHelpers.emptyArray();
        }

        return selectors.subscription
            .getAllSubscriptions(state)
            .filter((l) => l.isGrace && isSubscriptionManagedByMe(l));
    });

    function getGraceSubscriptionHash(subscriptions: selectors.types.ExpandedSubscription[]) {
        const minDays = subscriptions.reduce((v, s) => Math.min(s.graceEndsInDays!, v), 999);

        let severity = '2weeks';

        if (minDays < 2) {
            severity = '1day';
        } else if (minDays < 8) {
            severity = '1week';
        }

        return (
            arrayHelpers
                .arraySort(
                    subscriptions.map((s) => `${s.id}|${s.dueDate}`),
                    compareHelpers.stringComparator2AscI
                )
                .join(';') + `|${severity}`
        );
    }

    const displayGracePopup = useSelector((state: stateTree.State) => {
        if (graceSubscription.length === 0) {
            return false;
        }

        const storedSubscriptionHash = selectors.userPreferences.getUserPreference<string>(
            state,
            HIDDEN_GRACE_WARNINGS_USER_PREFERENCE,
            ''
        );
        const subscriptionHash = getGraceSubscriptionHash(graceSubscription);

        return storedSubscriptionHash !== subscriptionHash;
    });

    return (
        <>
            <ToastNotifications />

            <Popup
                isOpen={displayGracePopup}
                onRequestClose={useCallback(() => {
                    const newHash = getGraceSubscriptionHash(graceSubscription);

                    return dispatch(updateUserPreference(HIDDEN_GRACE_WARNINGS_USER_PREFERENCE, newHash));
                }, [dispatch, graceSubscription])}
            >
                <LicenseOnGracePopup subscriptions={graceSubscription} />
            </Popup>
        </>
    );
};

export default memo(ModuleComponent);
