import { intl } from '@approvalmax/utils';
import { useMutation } from '@tanstack/react-query';
import { api } from 'services/api';
import { notificationService } from 'services/notification';

const i18nPrefix = 'profile/containers/ContactSalesPopup/hooks';

export function useRequestToSalesMutation() {
    const requestToSalesMutation = useMutation<
        any,
        unknown,
        {
            phone: string;
            message: string;
            accountId: string;
        }
    >((options) => api.support.requestToSales(options), {
        onSuccess: () => {
            notificationService.showInfoToast(
                intl.formatMessage({
                    id: `${i18nPrefix}.requestToSalesSuccessMessage`,
                    defaultMessage:
                        'Your request has been submitted, our sales representative will contact you shortly.',
                })
            );
        },
        onError: () => {
            notificationService.showErrorToast(
                intl.formatMessage({
                    id: `${i18nPrefix}.requestToSalesFailedMessage`,
                    defaultMessage: 'Oops... something went wrong',
                })
            );
        },
    });

    return {
        requestToSales: requestToSalesMutation.mutateAsync,
        isLoading: requestToSalesMutation.isLoading,
    };
}
