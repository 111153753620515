import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'app.WorkflowIdPage.WorkflowSettingsPopup.SectionNetSuiteAvailableLineItemType',
    {
        title: 'Line items',
        subtitle: 'Available tables in request:',
        all: 'Both Expense and Item details tables',
        expenseLines: 'Expense details table',
        itemLines: 'Item details table',
    }
);
