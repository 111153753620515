import { QueryOptions, RequestDataParams } from '@approvalmax/data';
import { useGetLazyRequestLegacy, useGetRequestLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseGetSearchRequestsParams, UseGetSearchRequestsResponse } from './useGetSearchRequests.types';

export const useLazyGetSearchRequests = (queryOptions?: QueryOptions<UseGetSearchRequestsResponse>) =>
    useGetLazyRequestLegacy<RequestDataParams<UseGetSearchRequestsParams>, UseGetSearchRequestsResponse>(
        requestsApiPaths.searchRequests,
        { queryOptions }
    );

export const useGetSearchRequests = (
    data: UseGetSearchRequestsParams,
    queryOptions?: QueryOptions<UseGetSearchRequestsResponse>
) => useGetRequestLegacy<UseGetSearchRequestsResponse>(requestsApiPaths.searchRequests, data, { queryOptions });
