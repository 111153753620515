import { backend, domain, du } from 'modules/data';

export function parseQBooksTaxCode(value: backend.IntegrationsQBooksTaxCode): domain.QBooksTaxCode {
    return {
        id: value.TaxCodeId,
        text: value.Name,
        taxRateList: (value.PurchaseTaxRateList || []).map((x) => ({
            taxRateId: x.TaxRateId,
            taxOnTaxOrder: x.TaxOnTaxOrder,
            taxApplicable: mapQBooksTaxTypeApplicable(x.TaxApplicable),
        })),
        salesTaxRateList: (value.SalesTaxRateList || []).map((x) => ({
            taxRateId: x.TaxRateId,
            taxOnTaxOrder: x.TaxOnTaxOrder,
            taxApplicable: mapQBooksTaxTypeApplicable(x.TaxApplicable),
        })),
    };
}

const mapQBooksTaxTypeApplicable = du.getForwardEnumMapper(
    backend.IntegrationsQBooksTaxTypeApplicable,
    domain.QBooksTaxTypeApplicable
);
