import { domain } from 'modules/data';

export interface UseGetReportScheduleParams {
    companyId: string;
    reportId: string;
    type: string | null;
}

export interface UseGetReportScheduleResponseBackend {
    schedules: ReportScheduleRule[];
}

export interface UseGetReportScheduleResponse extends Array<ReportScheduleRule> {}

export interface ReportScheduleRule {
    name: string;
    emails: string[];
    validationErrors: ReportScheduleValidationErrors;
    configuration: ScheduleConfiguration;
}

export interface ReportScheduleValidationErrors {
    nameError: string;
    emailsError: string;
    weekError: string;
    longRuleName: boolean;
    emptyDay: boolean;
}

export interface ScheduleConfiguration {
    scheduleOptions: ScheduleOption;
    customSchedulePeriodicity?: CustomScheduleType;
    daysOfWeek?: domain.DayOfWeek[];
    monthlyOptions?: MonthlyOption;
    dayOfMonth?: number;
    dayOfYear?: {
        month: number;
        day: number;
    };
    time: {
        hour: number;
        minute: number;
    };
}

export enum ScheduleOption {
    EveryDay = 'EveryDay',
    EveryWeekMonday = 'EveryWeekMonday',
    EveryMonthMondayOf4thWeek = 'EveryMonthMondayOf4thWeek',
    EveryMonthLastMonday = 'EveryMonthLastMonday',
    EveryYearDecember25th = 'EveryYearDecember25th',
    EveryWorkingDay = 'EveryWorkingDay',
    Custom = 'Custom',
}

export enum CustomScheduleType {
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Annually = 'Annually',
}

export enum MonthlyOption {
    Day25th = 'Day25th',
    CustomDay = 'CustomDay',
    MondayOf4thWeek = 'MondayOf4thWeek',
    FridayOf4thWeek = 'FridayOf4thWeek',
}
