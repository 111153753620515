import { Box, Button, Select, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { memo, useCallback, useMemo } from 'react';
import { useHover } from 'react-use';

import { messages } from './EmailListItem.messages';
import { ListItemStyled } from './EmailListItem.styles';
import { EmailListItemProps } from './EmailListItem.types';

export const EmailListItem = memo<EmailListItemProps>((props) => {
    const { email, role, disabled, noRole = false, onRoleChange, deleteEmail } = props;

    const handleDelete = useCallback(() => {
        deleteEmail?.(email);
    }, [deleteEmail, email]);

    const roleItems = useMemo(
        () =>
            [
                domain.CompanyUserRole.Participant,
                domain.CompanyUserRole.Auditor,
                domain.CompanyUserRole.Manager,
                domain.CompanyUserRole.WorkflowManager,
            ].map((role) => ({
                id: role,
                name: selectors.company.getCompanyUserRoleText(role),
            })),
        []
    );

    const [hoverable] = useHover((hover) => (
        <ListItemStyled $noRole={noRole}>
            <Text font='body'>{email}</Text>

            {!noRole && <Select noInput disabled={disabled} items={roleItems} value={role} onChange={onRoleChange} />}

            {Boolean(deleteEmail) && hover ? (
                <Button color='red40' size='small' onClick={handleDelete}>
                    {messages.deleteButtonText}
                </Button>
            ) : (
                <Box width={64} />
            )}
        </ListItemStyled>
    ));

    return hoverable;
});

EmailListItem.displayName = 'EmailListItem';
