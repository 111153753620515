import { domain, stateTree } from 'modules/data';

import { isAlwaysTrueCondition } from './matrixSelectors';
import { ExpandedUser } from './types';
import { getUserById } from './userSelectors';

export function isValidIntermediateStep(step: domain.TemplateStep) {
    return step.participantMatrix.length > 0;
}

export function canAssignDefaultApprover(lines: domain.MatrixLine[]) {
    function hasRestrictiveConditions(r: domain.MatrixRule) {
        return r.conditions.some((c) => !isAlwaysTrueCondition(c));
    }

    return (
        lines.length > 0 &&
        lines.every((l) => {
            return l.isBackup || (l.rules.length > 0 && l.rules.every((r) => hasRestrictiveConditions(r)));
        })
    );
}

export function canAssignDefaultReviewer(lines: domain.MatrixLine[]) {
    function hasRestrictiveConditions(r: domain.MatrixRule) {
        return r.conditions.some((c) => !isAlwaysTrueCondition(c));
    }

    return (
        lines.length > 0 &&
        lines.every((l) => {
            return l.isBackup || (l.rules.length > 0 && l.rules.every((r) => hasRestrictiveConditions(r)));
        })
    );
}

export function getStepHasApprovalRules(step: domain.TemplateStep) {
    return step.participantMatrix.some((x) => x.rules.length > 0);
}

export function getStepHasReviewerRules(step: domain.TemplateStep) {
    return step.editorMatrix.some((x) => x.rules.length > 0);
}

export function getStepDefaultApprover(state: stateTree.State, step: domain.TemplateStep): ExpandedUser | null {
    const defaultApproverLine = step.participantMatrix.find((x) => Boolean(x.isBackup));

    if (!defaultApproverLine) {
        return null;
    }

    return getUserById(state, defaultApproverLine.lineId);
}

export function getDefaultReviewer(state: stateTree.State, reviewStep: domain.TemplateReviewStep): ExpandedUser | null {
    const defaultReviewer = reviewStep.reviewers.find((reviewer) => Boolean(reviewer.isBackup));

    if (!defaultReviewer) {
        return null;
    }

    return getUserById(state, defaultReviewer.lineId);
}
