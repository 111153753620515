import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.XeroWorkflowDetails', {
    endStepBubbleTextApproveInXero: 'Authorisation in Xero',
    endStepBubbleTextPo: 'Creation / Authorisation in Xero',
    endStepBubbleTextAmaxPayXeroBatchPayment: 'Creation in Xero',
    endStepBubbleTextBillBatchPayment: 'Push to Xero',
    endStepBubbleTextAirwalletBatchPayment: 'Creation in Xero',
    endStepDescriptionApproveInXero: '{pluralTemplateName} will be automatically authorised in Xero upon approval.',
    endStepDescriptionPo: 'Purchase Orders will be automatically created / authorised in Xero upon final approval.',
    endStepDescriptionAmaxPayXeroBatchPayment: 'Bill payments will be created in Xero for each successful payment.',
    endStepDescriptionBillBatchPayment:
        'Bulk payment will be pushed to Xero upon final approval where they could be found in Account transactions.',
    endStepDescriptionAirwallexBatchPayment:
        'Bill payments will be created in Xero for each successful payment made with Airwallex.',
    accountTransactionsButtonText: 'Go to Bank Accounts',

    endStepBubbleTextQuote: 'Creation / Authorisation in Xero',
    endStepDescriptionQuote: 'After their final approval, Quotes will be automatically created / authorised in Xero.',
    endStepBubbleTextManualJournal: 'Creation / Authorisation in Xero',
    endStepDescriptionManualJournal:
        'After their final approval, Manual Journals will be automatically created / authorised in Xero.',
});
