import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.MatrixPopupContent.Matrix', {
    approvers: 'Approvers',
    reviewers: 'Reviewers',
    requesters: 'Requesters',
    ruleDescription: 'Rule Description',
    ruleAutoApprovalButtonTitle: 'Add Auto-Approval Rule',
    editors: 'Editors',
});
