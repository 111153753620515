import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import type { StepperContextParams } from './Stepper.types';

export const Root = styled.div<StyledMods<Pick<Required<StepperContextParams>, 'direction'>>>`
    display: grid;

    ${mods('direction', 'vertical')`
        gap: 8px;
    `}

    ${mods('direction', 'horizontal')`
        grid-auto-flow: column;
    `}
`;
