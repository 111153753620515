import { Button, Field, TextEditor } from '@approvalmax/ui';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import { useStepEmailEnter } from './StepEmailEnter.hooks';
import { messages } from './StepEmailEnter.messages';
import { Content, Root } from './StepEmailEnter.styles';
import { StepEmailEnterProps } from './StepEmailEnter.types';

const qa = bemFactory.qa('pro-two-fa-setup-email-enter');

const StepEmailEnter: FC<StepEmailEnterProps> = memo((props) => {
    const { title } = props;

    const { email, inputRef, onChange, onSubmit, canSubmit, isLoading, errorMessage } = useStepEmailEnter(props);

    return (
        <Root qa={qa()} title={title}>
            <Content>
                <Field title={messages.codeFieldTitle} errorText={errorMessage}>
                    <TextEditor
                        ref={inputRef}
                        onEnter={onSubmit}
                        focusOnMount
                        disabled={isLoading}
                        value={email || ''}
                        onChange={onChange}
                        placeholder={messages.emailPlaceholder}
                        invalid={!!errorMessage}
                    />
                </Field>

                <Button execute={onSubmit} disabled={!canSubmit}>
                    {messages.nextButton}
                </Button>
            </Content>
        </Root>
    );
});

export default StepEmailEnter;
