import './newRequestPopup.scss';

import { Popup, Radiobox, SearchInput } from '@approvalmax/ui';
import { Grid } from '@approvalmax/ui/src/components';
import { arrayHelpers, compareHelpers, intl, miscHelpers } from '@approvalmax/utils';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import { constants, selectors, ui } from 'modules/common';
import { getTemplateById } from 'modules/common/selectors/templateSelectors';
import { domain } from 'modules/data';
import { asMutable } from 'modules/immutable';
import { useDispatch, useSelector } from 'modules/react-redux';
import React, { useCallback, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages, FormattedMessage } from 'react-intl';
import { routingService } from 'services/routing';
import { isOcrEnabledForTemplate } from 'shared/helpers';
import { getPath, Path } from 'urlBuilder';

import { closeActivePopup } from '../actions';
import { BulkCreationBanner } from '../components/xero/BulkCreationBanner/BulkCreationBanner';
import { getNewRequestPopupData } from '../selectors/moduleSelectors';

const { airwallexConstants } = constants;

const bem = bemFactory.block('rq-new-request-popup');
const qa = bemFactory.qa('rq-new-request-popup');

const i18nPrefix = 'request.NewRequestPopup';
const messages = defineMessages({
    popupTitle: {
        id: `${i18nPrefix}.popupTitle`,
        defaultMessage: 'Create new request',
    },
    createButtonTitle: {
        id: `${i18nPrefix}.createButtonTitle`,
        defaultMessage: 'Create request',
    },
});

const NewRequestPopup = () => {
    const dispatch = useDispatch();
    const activeCompanyId = useSelector(selectors.navigation.getActiveCompanyId);
    const allCompanies = useSelector(selectors.company.getCompanies);
    const me = useSelector(selectors.profile.getProfileUser);
    const newRequestPopupData = useSelector(getNewRequestPopupData);
    const activeCompany = useSelector((state) =>
        activeCompanyId ? selectors.company.getCompanyById(state, activeCompanyId) : null
    );
    const allIntegrations = useSelector(selectors.integration.getIntegrations);
    const creatableTemplates = useSelector((state) => {
        return selectors.meta.getCreatableTemplatesByCompanyId(state, newRequestPopupData?.activeCompanyId);
    });

    const managers = useSelector((state) => {
        if (activeCompany) {
            return activeCompany.managers.map((u) => selectors.user.getUserById(state, u));
        } else {
            return uniqBy(
                flatten(allCompanies.map((c) => c.managers.map((u) => selectors.user.getUserById(state, u)))),
                (u) => u.id
            ).sort(selectors.user.userComparatorAsc);
        }
    });

    let isAdmin;

    if (activeCompany) {
        isAdmin = activeCompany.managers.includes(me.id);
    } else {
        isAdmin = allCompanies.some((c) => c.managers.includes(me.id));
    }

    const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
    const [filterText, setFilterText] = useState('');

    const selectedTemplate = useSelector((state) =>
        selectedTemplateId ? getTemplateById(state, selectedTemplateId) : null
    );

    const selectTemplate = useCallback((templateId: string, companyId: string) => {
        setSelectedTemplateId(templateId);
        setSelectedCompanyId(companyId);
    }, []);

    const createRequest = useCallback(
        (templateId?: string, companyId?: string) => {
            if (templateId && companyId) {
                selectTemplate(templateId, companyId);
            } else {
                templateId = selectedTemplateId!;
                companyId = selectedCompanyId!;
            }

            dispatch(closeActivePopup());
            routingService.push(getPath(Path.newRequest, templateId, companyId));
        },
        [dispatch, selectTemplate, selectedCompanyId, selectedTemplateId]
    );

    const onCreateRequestButtonClick = useCallback(() => {
        createRequest();
    }, [createRequest]);

    const onChangeFilter = useCallback((filterText: string) => {
        setFilterText(filterText);
    }, []);

    const goToCompany = useCallback(
        (e: React.SyntheticEvent<any>, c: selectors.types.ExpandedCompany) => {
            e.preventDefault();
            dispatch(closeActivePopup());
            routingService.push(getPath(Path.companyWorkflows, c.id));
        },
        [dispatch]
    );

    const renderNormalContent = () => {
        const filterTextFormat = filterText.toLowerCase();

        const companiesData = creatableTemplates.reduce<{
            [templateId: string]: {
                id: string;
                displayName: string;
                isReadonly: boolean;
                disconnectedFromAirwallex: boolean;
                betaFeatures: domain.CompanyBetaFeature[];
                licenseFeatures: domain.CompanyLicenseFeature[];
            };
        }>((accumulator, template) => {
            const company = allCompanies.find((c) => c.id === template.companyId)!;

            miscHelpers.invariant(company, 'The company must exist in entities');

            const disconnectedFromAirwallex =
                template.integrationCode &&
                airwallexConstants.AIRWALLEX_INTEGRATION_CODES.includes(template.integrationCode) &&
                !company?.airwallexIntegration?.isConnected;

            accumulator[template.id] = {
                id: company.id,
                displayName: company.displayName,
                isReadonly: company.isReadonly,
                disconnectedFromAirwallex: !!disconnectedFromAirwallex,
                betaFeatures: company.betaFeatures,
                licenseFeatures: company.licenseFeatures,
            };

            return accumulator;
        }, {});

        let data = asMutable(creatableTemplates)
            .filter((x) => {
                if (!filterTextFormat) {
                    return true;
                }

                return (
                    x.displayName.toLowerCase().includes(filterTextFormat) ||
                    companiesData[x.id].displayName.toLowerCase().includes(filterTextFormat)
                );
            })
            .sort((x, y) => {
                if (x.companyId !== y.companyId) {
                    return compareHelpers.stringComparator2AscI(
                        companiesData[x.id].displayName,
                        companiesData[y.id].displayName
                    );
                }

                return compareHelpers.stringComparator2AscI(x.displayName, y.displayName);
            });

        const showBulkCreationBanner =
            selectedTemplate?.integrationCode &&
            selectedTemplateId &&
            isOcrEnabledForTemplate(
                companiesData[selectedTemplateId]?.betaFeatures,
                companiesData[selectedTemplateId]?.licenseFeatures,
                selectedTemplate?.integrationCode
            );

        return (
            <Popup.DefaultContent
                className={bem()}
                qa={qa()}
                title={intl.formatMessage(messages.popupTitle)}
                buttons={
                    <Popup.DefaultContent.Button
                        qa={qa('done-button')}
                        title={intl.formatMessage(messages.createButtonTitle)}
                        disabled={!selectedTemplateId}
                        execute={onCreateRequestButtonClick}
                    >
                        <FormattedMessage id={`${i18nPrefix}.createButtonText`} defaultMessage='Create request' />
                    </Popup.DefaultContent.Button>
                }
            >
                <div className={bem('content')}>
                    <div className={bem('search-section')}>
                        <SearchInput
                            qa={qa('search-input')}
                            value={filterText}
                            onChange={onChangeFilter}
                            theme='transparent'
                        />
                    </div>

                    <Grid
                        columnGap={showBulkCreationBanner ? 16 : 0}
                        gridTemplateColumns='auto max-content'
                        overflowY='hidden'
                        padding='0 52 0 72'
                    >
                        <div className={bem('list-section')} data-qa={qa('list-section')}>
                            {data.map((d) => {
                                const disabledIntegration = allIntegrations.find(
                                    (i) =>
                                        i.companyId === d.companyId && i.status !== domain.IntegrationStatus.Connected
                                );

                                const companyData = companiesData[d.id];

                                const inactiveTemplate =
                                    (disabledIntegration && d.integrationCode !== null) ||
                                    companyData.isReadonly ||
                                    companyData.disconnectedFromAirwallex;

                                return (
                                    <div
                                        key={d.id}
                                        data-qa={qa('list-item')}
                                        data-qa-id={d.id}
                                        data-qa-name={d.displayName}
                                        className={bem('list-item', {
                                            selected: d.id === selectedTemplateId,
                                            disabled: inactiveTemplate,
                                        })}
                                        onClick={
                                            !inactiveTemplate ? () => selectTemplate(d.id, d.companyId) : undefined
                                        }
                                        onDoubleClick={
                                            !inactiveTemplate ? () => createRequest(d.id, d.companyId) : undefined
                                        }
                                    >
                                        <div className={bem('list-item-content')}>
                                            <div className={bem('list-item-radio-wrp')}>
                                                <Radiobox
                                                    className={bem('list-item-radio')}
                                                    checked={d.id === selectedTemplateId}
                                                    disabled={Boolean(inactiveTemplate)}
                                                />
                                            </div>

                                            <div className={bem('list-item-title')} data-qa={qa('list-item-title')}>
                                                {d.displayName}
                                            </div>

                                            <div
                                                title={companyData.displayName}
                                                className={bem('list-item-company')}
                                                data-qa={qa('list-item-company')}
                                            >
                                                {companyData.displayName}
                                            </div>

                                            {companyData.isReadonly && !disabledIntegration && (
                                                <div className={bem('list-item-disabled-template-badge')}>
                                                    <FormattedMessage
                                                        id={`${i18nPrefix}.readonlyBadge`}
                                                        defaultMessage='Read-only'
                                                    />
                                                </div>
                                            )}

                                            {disabledIntegration && inactiveTemplate && (
                                                <div className={bem('list-item-disabled-template-badge')}>
                                                    {disabledIntegration.integrationType ===
                                                        domain.IntegrationType.Xero && (
                                                        <FormattedMessage
                                                            id={`${i18nPrefix}.disconnectedBadgeXero`}
                                                            defaultMessage='Disconnected from xero'
                                                        />
                                                    )}

                                                    {disabledIntegration.integrationType ===
                                                        domain.IntegrationType.QBooks && (
                                                        <FormattedMessage
                                                            id={`${i18nPrefix}.disconnectedBadgeQuickBooks`}
                                                            defaultMessage='Disconnected from QuickBooks'
                                                        />
                                                    )}

                                                    {disabledIntegration.integrationType ===
                                                        domain.IntegrationType.NetSuite && (
                                                        <FormattedMessage
                                                            id={`${i18nPrefix}.disconnectedBadgeNetSuite`}
                                                            defaultMessage='Disconnected from Oracle NetSuite'
                                                        />
                                                    )}
                                                </div>
                                            )}

                                            {companyData.disconnectedFromAirwallex && (
                                                <div className={bem('list-item-disabled-template-badge')}>
                                                    <FormattedMessage
                                                        id={`${i18nPrefix}.disconnectedBadgeAirwallex`}
                                                        defaultMessage='Disconnected from Airwallex'
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {showBulkCreationBanner && (
                            <BulkCreationBanner
                                companyId={companiesData[selectedTemplateId].id}
                                templateIntegrationCode={selectedTemplate?.integrationCode}
                            />
                        )}
                    </Grid>
                </div>
            </Popup.DefaultContent>
        );
    };

    const renderEmptyContent = () => {
        let headerTitle;
        let headerDescription;
        let footerContent;

        if (isAdmin) {
            headerTitle = (
                <FormattedMessage
                    id={`${i18nPrefix}.emptyAdminHeaderTitle`}
                    defaultMessage='Looks like you are not added as {br}requester to any approval workflow'
                    values={{ br: <br /> }}
                />
            );
            headerDescription = (
                <FormattedMessage
                    id={`${i18nPrefix}.emptyAdminHeaderDescription`}
                    defaultMessage={
                        'Please open the "Approval Workflow" page under your company {br}and add yourself as a requester.'
                    }
                    values={{ br: <br /> }}
                />
            );
            footerContent = (
                <div className={bem('empty-footer')}>
                    <div className={bem('empty-footer-companies')}>
                        {arrayHelpers
                            .arraySort(
                                allCompanies,
                                compareHelpers.comparatorFor<selectors.types.ExpandedCompany>(
                                    compareHelpers.stringComparator2AscI,
                                    'displayName'
                                )
                            )
                            .map((c) => (
                                <a
                                    key={c.id}
                                    href={getPath(Path.companyWorkflows, c.id)}
                                    className={bem('empty-footer-company-item')}
                                    onClick={(e) => goToCompany(e, c)}
                                >
                                    <FormattedMessage
                                        id={`${i18nPrefix}.goToLinkText`}
                                        defaultMessage='Go to Approval Workflows of {companyName}'
                                        values={{
                                            companyName: c.displayName,
                                        }}
                                    />
                                </a>
                            ))}
                    </div>
                </div>
            );
        } else {
            headerTitle = (
                <FormattedMessage
                    id={`${i18nPrefix}.emptyUserHeaderTitle`}
                    defaultMessage='Looks like you are not added as {br}requester to any approval workflow'
                    values={{ br: <br /> }}
                />
            );
            headerDescription = (
                <FormattedMessage
                    id={`${i18nPrefix}.emptyUserHeaderDescription`}
                    defaultMessage={
                        'If you think you should, please contact one of the {br}' +
                        'company administrators and ask them to add you {br}' +
                        'as a requester.'
                    }
                    values={{ br: <br /> }}
                />
            );
            footerContent = (
                <div className={bem('empty-footer')}>
                    <div className={bem('empty-footer-managers')}>
                        {managers.map((u) => (
                            <ui.UserHover key={u.id} user={u}>
                                <div className={bem('empty-footer-manager-item')}>
                                    <ui.UserHover.Anchor>
                                        {(ref) => <ui.Avatar ref={ref} user={u} size={40} />}
                                    </ui.UserHover.Anchor>

                                    <div className={bem('empty-footer-manager-item-name')}>{u.displayName}</div>
                                </div>
                            </ui.UserHover>
                        ))}
                    </div>
                </div>
            );
        }

        return (
            <Popup.DefaultContent className={bem(null, 'empty')} qa={qa()} buttons={[]}>
                <div className={bem('empty-content')} data-qa={qa('empty-content')}>
                    <div className={bem('empty-header')}>
                        <div className={bem('empty-header-title')}>{headerTitle}</div>

                        <div className={bem('empty-header-description')}>{headerDescription}</div>
                    </div>

                    {footerContent}
                </div>
            </Popup.DefaultContent>
        );
    };

    if (creatableTemplates.length > 0) {
        return renderNormalContent();
    } else {
        return renderEmptyContent();
    }
};

export default NewRequestPopup;
