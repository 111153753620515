import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.DearExternalSubmitterSection', {
    bubbleText: 'Pulling from Cin7 Core',
    description:
        '{templateName} in the "Draft" status will be automatically pulled from Cin7 Core. Enter the user who will be notified about purchase order status changes in ApprovalMax. A generic email address, e.g. finance@mycompany.com, can also be used.',
    learnMore: 'Learn more',
    externalSubmitterRequiredValidationText: 'Please select a Requester',
    chooseRequester: 'Choose a Requester',
});
