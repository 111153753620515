import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { requestsApiPaths, useGetRequestLegacy } from 'shared/data';

import {
    UseBillRequestPaymentDetailsPathParams,
    UseBillRequestPaymentDetailsResponse,
} from './useBillRequestPaymentDetails.types';

export const useBillRequestPaymentDetails = (
    pathParams: QueryParams<UseBillRequestPaymentDetailsPathParams>,
    queryOptions?: QueryOptions<UseBillRequestPaymentDetailsResponse>
) => {
    return useGetRequestLegacy<UseBillRequestPaymentDetailsResponse>(
        requestsApiPaths.billRequestPaymentDetails,
        {},
        {
            pathParams,
            queryOptions: {
                enabled: enabledByParams<UseBillRequestPaymentDetailsPathParams>(pathParams),
                ...queryOptions,
            },
            method: 'get',
        }
    );
};
