import { ApiSource, Env } from '@approvalmax/data';
import { defaultApiSource } from 'shared/data';

import { messages } from './BackendSection.messages';

export const mockOption = {
    id: 'localhost:44382',
    text: 'mock',
} as const;

export const customOption = {
    id: 'localhost:3000',
    text: 'custom (localhost etc)',
} as const;

export type BackendOption = { id: Env; text: string } | typeof mockOption | typeof customOption;

export const backendOptions: BackendOption[] = [
    {
        id: 'dev01',
        text: 'api-dev01',
    },
    {
        id: 'dev02',
        text: 'api-dev02',
    },
    {
        id: 'dev03',
        text: 'api-dev03',
    },
    {
        id: 'dev04',
        text: 'api-dev04',
    },
    {
        id: 'staging',
        text: 'testapi',
    },
    {
        id: 'production',
        text: 'api',
    },
    mockOption,
    customOption,
];

export type ApiSourceOption = { id: ApiSource; text: string };

export const apiSourceOptions: ApiSourceOption[] = [
    {
        id: 'webApp',
        text: messages.backend,
    },
    {
        id: 'myAccount',
        text: messages.myAccount,
    },
    {
        id: 'slack',
        text: messages.slack,
    },
    {
        id: 'adminPanel',
        text: messages.adminPanel,
    },
    {
        id: 'identity',
        text: messages.identityServer,
    },
    {
        id: 'devPortal',
        text: messages.developerPortal,
    },
];

export const defaultApiSourceOption = apiSourceOptions.find((option) => option.id === defaultApiSource) || null;
