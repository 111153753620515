import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('integration.xero.CacheManagementPanel', {
    cacheRowOrganisations: 'Organisation',
    cacheRowItems: 'Items',
    cacheRowAccounts: 'Accounts',
    cacheRowTaxRates: 'Taxes',
    cacheRowTrackingCategories: 'Tracking categories',
    cacheRowBranding: 'Themes',
    cacheRowCurrencies: 'Currencies',
    cacheRowCounterparties: 'Contacts',
    inProgressText: 'In progress',
    syncNowText: 'Sync now',
    headerText: 'Xero Data Synchronisation Status',
    fieldCol: 'Field',
    lastSyncCol: 'Last synced',
    syncDurationCol: 'Sync duration',
    syncAll: 'Sync All',
});
