import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.CopyWorkflowPopup', {
    popupTitle: 'Copy Workflow',
    submitButton: 'Done',
    toCompanyFieldLabel: 'Copy Workflow to Organisation',
    workflowFieldLabel: 'Workflow you want to copy to',
    copyWorkflowSettingsFieldLabel: 'Copy workflow settings',
    warningTitle: 'Warnings',
    requiredFieldsError: 'Please fill in required fields',
    copyToSingleWorkflowTab: 'Copy to single workflow',
    copyToMultipleWorkflowsTab: 'Copy to multiple workflows',
});
