/**
 * Returns a string with a timestamp for the end of the specified date.
 * Example: '2024-02-12T00:00:00.000Z' -> '2024-02-12T23:59:59.999Z'
 * @param dateString A string representing a date in ISO format.
 * @returns A string with a timestamp for the end of the specified date in ISO format.
 */
export const getEndOfDateTimestampUTC = (dateString: string) => {
    const date = new Date(dateString);

    date.setUTCHours(23, 59, 59, 999);

    return date.toISOString();
};
