export const twoFaApiPaths = {
    addTFAEnablingBackupCodes: 'auth/addTFAEnablingBackupCodes',
    defaultTwoFaEnforcementType: 'integration/:integrationType/default-tfa-enforcement-type',
    disableTFAWithBackupCode: 'auth/disableTFAWithBackupCode',
    disablingRequestEmailCode: 'auth/sendTFADisablingEmailCode',
    disablingVerifyAppCode: 'auth/disableTFAWithOneTimePassword',
    disablingVerifyEmailCode: 'auth/disableTFAWithEmailCode',
    enablingAppCodeVerify: 'auth/verifyTFAEnablingCode',
    enablingEmailCodeVerify: 'auth/finishTFAEnablingWithEmailCode',
    enablingEmailSet: 'auth/addTFAEnablingAlternativeEmail',
    enablingStart: 'auth/startTFAEnabling',
    finishAddingTFAAlternativeEmail: 'auth/finishAddingTFAAlternativeEmail',
    finishAddingTFABackupCodes: 'auth/finishAddingTFABackupCodes',
    finishTFAEnablingWithBackupCodes: 'auth/finishTFAEnablingWithBackupCodes',
    generateTFABackupCodes: 'auth/generateTFABackupCodes',
    getTFAAlternativeMethods: 'auth/getTFAAlternativeMethods',
    getTFAEnforcementInformation: '/auth/tfaEnforcementInformation',
    sendTFAVerifyingEmailCode: 'auth/sendTFAVerifyingEmailCode',
    startAddingTFAAlternativeEmail: 'auth/startAddingTFAAlternativeEmail',
    startAddingTFABackupCodes: 'auth/startAddingTFABackupCodes',
    verifyTFAWithBackupCode: 'auth/verifyTFAWithBackupCode',
    verifyTFAWithEmailCode: 'auth/verifyTFAWithEmailCode',
    verifyTFAWithOneTimePassword: 'auth/verifyTFAWithOneTimePassword',
};
