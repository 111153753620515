import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionLockDate', {
    title: 'Lock date handling',
    subtitle: 'How are documents to be handled if they are dated prior to the lock date?',
    optionApproveWithNextDay: 'Change their date automatically to "Lock Date + 1 day" after approval',
    optionLockDate: `
        Lock the document until the document date has been changed manually either in {integrationName}
        or in ApprovalMax (for {templateName} created in ApprovalMax)`,
    optionLockDateXeroOnly: 'Lock the document until the document date has been changed manually in Xero',
    optionLockDateAmaxOnly: 'Lock the document until the document date has been changed manually in ApprovalMax',
});
