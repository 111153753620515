import { backend } from 'modules/data';
import { stringify } from 'query-string';

import { ApiHandler } from '../types';

class TemplatesUrls {
    constructor(private _apiHandler: ApiHandler) {}

    public select(selectTransfer: backend.transfers.TemplateSelectTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}templates/select?${stringify(selectTransfer)}`;
    }

    public getSettings(transfer: backend.TemplatesTemplateId): string {
        return `${this._apiHandler.getApiUrlPrefix()}templates/getSettings?${stringify(transfer)}`;
    }
}

export default class TemplatesApi {
    public urls: TemplatesUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new TemplatesUrls(this._apiHandler);
    }

    public create(transfer: backend.transfers.TemplateCreateTransfer): Promise<backend.TemplateCreateAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'templates/create',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public edit(transfer: backend.transfers.TemplateEditTransfer): Promise<backend.TemplateEditAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'templates/edit',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public select(selectTransfer: backend.transfers.TemplateSelectTransfer): Promise<backend.TemplateSelectAnswer> {
        return this._apiHandler.doApiCall({
            data: selectTransfer,
            action: 'templates/select',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public delete(deleteTransfer: backend.transfers.TemplateDeleteTransfer): Promise<backend.TemplateDeleteAnswer> {
        return this._apiHandler.doApiCall({
            data: deleteTransfer,
            action: 'templates/delete',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public editSettings(transfer: backend.TemplatesTemplateSettingsTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'templates/editSettings',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getSettings(
        transfer: backend.TemplatesTemplateId
    ): Promise<backend.Answer<backend.TemplatesTemplateSettings>> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'templates/getSettings',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public selectWorkflowVersions(
        transfer: backend.transfers.SelectWorkflowVersionsTransfer
    ): Promise<backend.SelectWorkflowVersionsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'templates/selectWorkflowVersions',
            method: 'GET',
            allowsAnonymous: false,
        });
    }
}
