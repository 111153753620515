import { Button, font, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled(Popup.EmptyContent)`
    width: 410px;
    padding: 40px 50px;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
`;

export const Title = styled.div`
    ${font(16, '#000', 'semibold')}
    margin-top: 30px;
`;

export const Description = styled.div`
    ${font(13, '#000', 'standard')}
    margin-top: 20px;
`;

export const ButtonFinish = styled(Button)`
    margin-top: 30px;
    align-self: stretch;
`;
