import { useCallback, useRef } from 'react';
import { useVerifyTFAEnablingCode } from 'shared/data';

import { useTwoFaEnablingWizardContext } from '../../TwoFaEnablingWizard.context';

export const useStepCodeEnter = (onNext: () => void) => {
    const { context, setContext } = useTwoFaEnablingWizardContext();
    const inputRef = useRef<HTMLInputElement>(null);

    const { mutate: verifyCode, reset, isLoading, isError } = useVerifyTFAEnablingCode();

    const canSubmit = Boolean(context.authenticatorAppCode.trim()) && !isLoading;

    const onChange = useCallback(
        (value: string) => {
            if (isError) reset();

            setContext((prevContext) => ({ ...prevContext, authenticatorAppCode: value }));
        },
        [isError, reset, setContext]
    );

    const onSubmit = useCallback(() => {
        if (!canSubmit) return;

        const userCode = context.authenticatorAppCode.trim();
        const stateId = context.stateId;

        if (stateId) {
            verifyCode(
                { data: { stateId, userCode } },
                {
                    onSuccess: () => {
                        onNext();
                    },
                    onError: () => {
                        inputRef.current?.focus();
                    },
                }
            );
        }
    }, [canSubmit, context.authenticatorAppCode, context.stateId, verifyCode, onNext]);

    return {
        inputRef,
        authenticatorAppCode: context.authenticatorAppCode,
        onChange,
        onSubmit,
        isLoading,
        isError,
        canSubmit,
    };
};
