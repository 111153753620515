import { defineMessages } from 'react-intl';

const i18nPrefix = 'auth/components/DarkPageLayout';

export const messages = defineMessages({
    downloadIPhoneAppUrl: {
        id: `${i18nPrefix}.downloadIPhoneAppUrl`,
        defaultMessage: 'https://apps.apple.com/us/app/approvalmax/id1439596637',
    },
    downloadAndroidAppUrl: {
        id: `${i18nPrefix}.downloadAndroidAppUrl`,
        defaultMessage: 'https://play.google.com/store/apps/details?id=com.approvalmax',
    },
    downloadMobileAppsText: {
        id: `${i18nPrefix}.downloadMobileAppsText`,
        defaultMessage: 'Download our mobile apps',
    },
});
