import { Box, Text } from '@approvalmax/ui/src/components';
import { constants } from '@approvalmax/utils';
import { memo } from 'react';

import { messages } from './TrainingContent.messages';

const TrainingContent = memo(() => {
    return (
        <Box spacing='12'>
            <Text font='label' fontWeight='medium' spacing='0 0 8'>
                {messages.title}
            </Text>

            <Text font='body' fontSize='small' spacing='0 0 12'>
                {messages.description({
                    br: <br />,
                })}
            </Text>

            <Text font='label' fontWeight='medium'>
                <a href={`mailto:${constants.salesEmail}`}>{messages.contactUs}</a>
            </Text>
        </Box>
    );
});

export default TrainingContent;
