import { Mods, mods } from '@approvalmax/theme';
import { Popup, TransparentButton } from '@approvalmax/ui';
import { font } from 'modules/styles';
import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-flow: column;
`;

export const Title = styled.div`
    ${font(16, '#000', 'semibold')}
`;

export const Logo = styled.div`
    margin-right: 15px;
`;

export const DetailsPanel = styled.div<{ firstStart?: boolean }>`
    ${mods('firstStart', true)`
        margin: 0 -60px;
        padding: 0 60px;
    `};

    ${mods('firstStart', false)`
        margin: 0 -60px 0 -80px;
        padding: 0 60px 0 80px;
    `};

    background: #eff0f0;
    padding-top: 20px;
    padding-bottom: 40px;
`;

export const DetailsText = styled.div`
    ${font(13, '#000')}
    margin-top: 10px;
    max-width: 640px;

    & li {
        margin-top: 5px;
    }
`;

export const TipPanel = styled.div`
    background: #fff;
    padding: 10px 20px;
    margin-bottom: 20px;
`;

export const TipTitle = styled.div`
    ${font(13, '#000', 'semibold')}
`;

export const TipText = styled.div`
    margin-top: 5px;
    ${font(13, '#000')}
`;

export const ConnectionOptions = styled.div`
    padding: 25px 0;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-rows: auto;
    gap: 15px;
`;

export const ConnectionButton = styled(TransparentButton)<Mods<'active'>>`
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    border: 1px solid #dfdfdf;
    padding: 15px 20px;
    height: 70px;
    white-space: pre-line;
    ${font(14, '#000', 'semibold')};

    &:before {
        content: '';
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        position: absolute;
        border: 2px solid transparent;
        opacity: 1;
        transition: border-color 0.3s;
        pointer-events: none;
        z-index: 10;
    }

    &:hover:before {
        border-color: #dfdfdf;
    }

    ${mods.active.true`
        &:before,
        &:hover:before {
            border-color: #77aa84;
        }
    `}
`;

export const RegularPopup = styled(Popup.DefaultContent)`
    width: 960px;
    overflow: auto;
`;

export const RegularBody = styled.div`
    padding: 20px 60px 0 80px;
`;
