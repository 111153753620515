import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.UserActionsToolbar.UserSettingsMenu', {
    profileInfoName: 'My profile',
    profileInfoTitle: 'Edit your profile details and change your password.',

    profileDelegatesName: 'Out of Office',
    profileDelegatesTitle: 'Temporary approval authority as a Delegate',

    darkTheme: 'Dark theme',
});
