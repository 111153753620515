import { theme } from '@approvalmax/theme';
import styled from 'styled-components';

import { disabledClassName, selectedClassName } from '../../constants/classNames';

export const SelectableCard = styled.div`
    width: 100%;
    transition:
        background-color ${theme.duration.fast}ms ease-in-out,
        border ${theme.duration.fast}ms ease-in-out;
    padding: 0 12px;
    position: relative;
    border-radius: ${theme.radius.small};
    cursor: pointer;

    &:hover:not(.${disabledClassName}) {
        background-color: ${theme.color.blue10};

        &&:before {
            border-bottom: none;
        }
    }

    &.${selectedClassName} {
        background-color: ${theme.color.silver80};

        &&:before {
            border-bottom: none;
        }
    }

    &:not(:last-of-type)::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        margin: 0 12px;
        width: calc(100% - 2 * 12px);
        border-bottom: 1px solid ${theme.color.midnight50};
    }

    &:has(+ :not(.${disabledClassName}):hover) {
        &::before {
            border-bottom: none;
        }
    }

    &:has(+ .${selectedClassName}) {
        &::before {
            border-bottom: none;
        }
    }

    &.${disabledClassName} {
        cursor: initial;
    }
`;
