import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import { UseEditRequestData, UseEditRequestResponse } from './useEditRequest.types';

export const useEditRequest = (
    mutationOptions?: MutationOptions<UseEditRequestResponse, RequestDataParams<UseEditRequestData>>
) => {
    return useMutateLegacy<RequestDataParams<UseEditRequestData>, UseEditRequestResponse>(requestsApiPaths.edit, {
        mutationOptions,
    });
};
