import { Box, Checkbox, ConditionWrapper, Flex, Radio } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { actions } from 'modules/profile';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PromotionButtons } from '../PromotionButtons/PromotionButtons';
import { SectionFeaturePromotion } from '../SectionFeaturePromotion/SectionFeaturePromotion';
import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { promoTemplateSettings, qbooksBillToPoLearnMore } from './SectionQboBillMatching.constants';
import { messages } from './SectionQboBillMatching.messages';
import { SectionQboBillMatchingProps } from './SectionQboBillMatching.types';

export const SectionQboBillMatching: FC<SectionQboBillMatchingProps> = memo((props) => {
    const templateSettings = 'promoView' in props ? promoTemplateSettings : props.templateSettings;
    const readonly = 'promoView' in props ? true : props.readonly;
    const qa = 'promoView' in props ? null : props.qa;
    const onChangeMatchingSettings = 'promoView' in props ? undefined : props.onChangeMatchingSettings;
    const onChangeNotMatchedApproval = 'promoView' in props ? undefined : props.onChangeNotMatchedApproval;
    const company = 'promoView' in props ? props.company : null;
    const isPromoView = 'promoView' in props;

    const dispatch = useDispatch();

    const handleTryIt = useCallback(() => {
        if (!company) {
            return;
        }

        dispatch(actions.openConsentToStartTrialPopup(company, 'po_matching_settings'));
    }, [company, dispatch]);

    const billMatchingSettings = templateSettings.billMatchingSettings! || {};
    const manualMatchEnabled = billMatchingSettings.manualMatchingEnabled;

    return (
        <ConditionWrapper
            condition={isPromoView}
            wrapperIf={(children) => (
                <SectionFeaturePromotion title={messages.title} onTryIt={handleTryIt}>
                    <Box spacing='0 24 16'>
                        <Flex inline spacing='24' direction='column'>
                            {children}
                        </Flex>
                    </Box>
                </SectionFeaturePromotion>
            )}
            wrapperElse={(children) => (
                <SettingsSection title={messages.title} learnMoreLink={qbooksBillToPoLearnMore}>
                    {children}
                </SettingsSection>
            )}
        >
            <>
                <SettingsSubsection
                    subtitle={messages.manualMatchiungSubtitle}
                    color={isPromoView ? 'midnight60' : undefined}
                >
                    <Checkbox checked={manualMatchEnabled} onChange={onChangeMatchingSettings} disabled={readonly}>
                        {messages.optionAllowToMatch}
                    </Checkbox>
                </SettingsSubsection>

                {isPromoView && <PromotionButtons learnMoreLink={qbooksBillToPoLearnMore} onClickTryIt={handleTryIt} />}

                <SettingsSubsection
                    subtitle={messages.billApproveSubtitle}
                    color={isPromoView ? 'midnight60' : undefined}
                >
                    <Radio.Group
                        disabled={readonly}
                        value={billMatchingSettings.allowApprovalOfNotMatchedBills}
                        onChange={onChangeNotMatchedApproval}
                        name='notMatchedApproval'
                        block
                        data-qa={qa?.('allow-approval-of-not-matched-bills')}
                    >
                        <Radio value={domain.CompanyMatchingSettingsBillApprovalPolicy.Always}>
                            {messages.optionYes}
                        </Radio>

                        <Radio value={domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold}>
                            {messages.optionNoAtLeastOne}
                        </Radio>

                        <Radio value={domain.CompanyMatchingSettingsBillApprovalPolicy.Never}>
                            {messages.optionNoEachLine}
                        </Radio>
                    </Radio.Group>
                </SettingsSubsection>
            </>
        </ConditionWrapper>
    );
});

SectionQboBillMatching.displayName = 'SectionQboBillMatching';
