import { miscHelpers } from '@approvalmax/utils';
import { stateTree } from 'modules/data';
import { addArrayItem, removeArrayItem, set } from 'modules/immutable';
import { ActionKind } from 'modules/react-redux';

import {
    Action,
    ADD_TOAST,
    CANCEL_ACTIVE_POPUP,
    REMOVE_TOAST,
    SET_WORKFLOW_WIZARD_PREVIEW,
    SHOW_POPUP,
} from '../actions';

export default function (state: stateTree.Ui, action: Action): stateTree.Ui {
    const meta = action.meta;

    // Handling of async meta-flags
    if (meta) {
        if (meta.kind === ActionKind.AsyncRequest) {
            state = set(state, 'asyncOperationsInProgress', [...state.asyncOperationsInProgress, meta.operationId!]);

            if (meta.showAsyncLoadingBar !== false) {
                state = set(state, 'showGlobalLoadingCounter', state.showGlobalLoadingCounter + 1);
            }

            if (meta.isBlocking) {
                state = set(state, 'isBlockedCounter', state.isBlockedCounter + 1);
            }
        } else if (meta.kind === ActionKind.AsyncResponse || meta.kind === ActionKind.AsyncFailure) {
            // Remove operation id from the list. Remove only ONE entry (duplicates are allowed).
            const newAsyncOperationsInProgress = [...state.asyncOperationsInProgress];
            const index = newAsyncOperationsInProgress.indexOf(meta.operationId!);

            miscHelpers.invariant(index !== -1, 'OperationId has to be on the list.');
            newAsyncOperationsInProgress.splice(index, 1);
            state = set(state, 'asyncOperationsInProgress', newAsyncOperationsInProgress);

            if (meta.showAsyncLoadingBar !== false) {
                state = set(state, 'showGlobalLoadingCounter', state.showGlobalLoadingCounter - 1);
            }

            if (meta.isBlocking) {
                state = set(state, 'isBlockedCounter', state.isBlockedCounter - 1);
            }
        }
    }

    switch (action.type) {
        case ADD_TOAST:
            return set(
                state,
                'toasts',
                addArrayItem<stateTree.ToastNotificationItem>(
                    removeArrayItem(state.toasts, (t) => t.id === action.payload.id),
                    {
                        id: action.payload.id,
                        message: action.payload.message,
                        timeout: action.payload.timeout,
                        kind: action.payload.kind,
                    }
                )
            );

        case REMOVE_TOAST:
            return set(
                state,
                'toasts',
                removeArrayItem(state.toasts, (t) => t.id === action.payload.id)
            );

        case SHOW_POPUP: {
            let value: stateTree.PopupInfo = {
                id: action.payload.popupId,
                data: action.payload.data || {},
            };

            return set(state, 'popup', value);
        }

        case CANCEL_ACTIVE_POPUP:
            return set(state, 'popup', null);

        case SET_WORKFLOW_WIZARD_PREVIEW:
            return set(state, 'isWorkflowWizardPreview', action.payload.isWorkflowWizardPreview);

        default:
            return state;
    }
}
