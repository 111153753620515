import { DeloitteLogo, GrantThorntonLogo, KmpgLogo, MazarsLogo, PwcLogo } from '@approvalmax/ui';
import { Button, Flex, Popup, Spacing, Text } from '@approvalmax/ui/src/components';
import { intl, miscHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCalculationPrice } from 'shared/data';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { getExpiredTextByUseCase, getProductPlanId } from './PopupContent.helpers';
import { messages } from './PopupContent.messages';
import { PopupContentProps } from './PopupContent.types';

export const PopupContent: FC<PopupContentProps> = memo((props) => {
    const { companyId, accountId, onClose } = props;

    const buyLink = useMemo(
        () => getAccountUrl(AccountPath.buyNow, { companyId, origin: 'wa_big_trial_exp_popup' }),
        [companyId]
    );

    const company = useSelector((state: State) => selectors.company.findCompanyById(state, companyId));

    const currency =
        miscHelpers.castToEnum(domain.NextBillingCurrency, company?.defaultCurrency) ?? domain.NextBillingCurrency.USD;

    const { data, isLoading } = useCalculationPrice({
        accountId,
        productPlanId: getProductPlanId(company),
        currency,
        billingCycle: domain.SubscriptionBillingCycle.Annually,
        quantity: 1,
        planEdition: domain.CompanyPlanEdition.Standard,
    });

    return (
        <>
            <Popup.Header progress={isLoading} />

            <Popup.Body>
                <Flex inline direction='column' alignItems='center' spacing='32'>
                    {data && (
                        <Text
                            font='headline'
                            fontSize='medium'
                            fontWeight='medium'
                            textAlign='center'
                            color='midnight80'
                        >
                            {messages.text({
                                price: intl.formatCurrency(data.amount / 12, currency),
                                useCase: getExpiredTextByUseCase(),
                            })}
                        </Text>
                    )}

                    <Text font='headline' fontSize='small' textAlign='center' color='midnight90'>
                        {messages.description}
                    </Text>

                    <Flex inline spacing='40' alignItems='center'>
                        <DeloitteLogo />

                        <PwcLogo />

                        <KmpgLogo />

                        <GrantThorntonLogo />

                        <MazarsLogo />
                    </Flex>
                </Flex>

                <Spacing height={56} />

                <Flex inline direction='column' alignItems='center'>
                    <Button as='a' target='_blank' href={buyLink} color='blue80' size='large' onClick={onClose}>
                        {messages.buttonText}
                    </Button>
                </Flex>
            </Popup.Body>
        </>
    );
});

PopupContent.displayName = 'PopupContent';
