import { Reference } from '@approvalmax/types';
import { domain } from 'modules/data';

import { SelectableBillItemForBatchPaymentAdditionalData } from './BatchPayment';

export interface PaymentItem {
    xeroBillInvoiceRequestId: string;
    friendlyName: string;
    date: string;
    dueDate: string;
    amountDue: number;
    note: string | null;
    bankAccount: string | null;
    defaultBankAccount: string | null;
    contact: Reference;
    currency: string;
    details: string | null;
    amount: number;
    amountOnApproval: number;
    amountInProcessing: number;
    amountAwaitingPayment: number;
    reference: string | null;
    code: string | null;
    particulars: string | null;
    xeroBillInvoiceReference: string | null;
    attachments: domain.RequestAttachment[];
    outstandingBalance: number | null;
    overdueBalance: number | null;
}

export interface XeroBillBatchPayment {
    id: string;
    paymentDate: string | null;
    bankAccount: Reference | null;
    narrative: string | null;
    details: string | null;
    items: PaymentItem[];
    reference: string | null;
    code: string | null;
    particulars: string | null;
    url: string | null;
    editUrl: string | null;
}

export enum RegionalField {
    Details = 'Details',
    Reference = 'Reference',
    Particulars = 'Particulars',
    Code = 'Code',
    Narrative = 'Narrative',
}

export interface XeroBatchSelectableBillItem
    extends domain.PaymentItem,
        SelectableBillItemForBatchPaymentAdditionalData {}
