import { theme } from '@approvalmax/theme';
import styled from 'styled-components';

import { Text } from '../Text/Text';

export const Root = styled(Text)`
    text-decoration: none;
    transition: color ${theme.duration.fast}ms ease-in-out;

    &:hover {
        text-decoration: none;
        color: ${theme.color.blue100};
    }
`;
