import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    UseFinishAddingTFAAlternativeEmailRequest,
    UseFinishAddingTFAAlternativeEmailResponse,
} from './useFinishAddingTFAAlternativeEmail.types';

export const useFinishAddingTFAAlternativeEmail = () => {
    return useMutateLegacy<
        RequestDataParams<UseFinishAddingTFAAlternativeEmailRequest>,
        UseFinishAddingTFAAlternativeEmailResponse
    >(twoFaApiPaths.finishAddingTFAAlternativeEmail);
};
