import { Button, Flex, Form, Text, TextField } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { fieldNameEmail } from '../../TwoFaEnablingWizard.constants';
import { AnotherMethodButton } from '../AnotherMethodButton/AnotherMethodButton';
import { StepperTwoFaWizard } from '../StepperTwoFaWizard/StepperTwoFaWizard';
import { useStepBackupEmail } from './StepBackupEmail.hooks';
import { messages } from './StepBackupEmail.messages';

const qa = bemFactory.qa('pro-two-fa-setup-email-enter');

export const StepBackupEmail = memo(() => {
    const { inputRef, handleRequestCode, isDisabled } = useStepBackupEmail();

    return (
        <Form.Part>
            <Flex inline direction='column' spacing='24' data-qa={qa()}>
                <StepperTwoFaWizard activeStep={2} />

                <Flex inline direction='column' spacing='16'>
                    <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                        {messages.title}
                    </Text>

                    <Text font='body' fontSize='medium' fontWeight='regular'>
                        {messages.description}
                    </Text>
                </Flex>

                <TextField
                    type='email'
                    name={fieldNameEmail}
                    required
                    ref={inputRef}
                    initFocus
                    label={messages.emailLabel}
                    placeholder={messages.emailPlaceholder}
                />

                <Flex inline direction='column' spacing='16'>
                    <Button type='button' size='large' color='blue80' onClick={handleRequestCode} disabled={isDisabled}>
                        {messages.nextButton}
                    </Button>

                    <AnotherMethodButton />
                </Flex>
            </Flex>
        </Form.Part>
    );
});

StepBackupEmail.displayName = 'StepEmailEnter';
