import './dateRangeFilter.scss';

import { Reference } from '@approvalmax/types';
import { DateTimeEditor, DropdownEditor } from '@approvalmax/ui';
import { PlainDataProvider } from 'modules/data-providers';
import moment from 'moment';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';

import * as dateRangeFilter from '../../data/filters/dateRangeFilter';

const i18nPrefix = 'reports.filters.DateRangeFilter';

interface Props {
    filter: dateRangeFilter.DateRangeFilter;
    onFilterChange(filter: dateRangeFilter.DateRangeFilter): void;
}

interface State {}

const minCustomRangeDate = '1753-01-01';
const maxCustomRangeDate = '9999-12-30';

class DateRangeFilter extends React.Component<Props, State> {
    public render() {
        const { filter } = this.props;
        const bem = bemFactory.block('rpt-date-range-filter');

        let rangeType = dateRangeFilter.getRangeTypeOptions().find((opt) => opt.id === filter.rangeType)!;

        return (
            <div className={bem()}>
                <PlainDataProvider items={dateRangeFilter.getRangeTypeOptions()}>
                    <DropdownEditor
                        value={rangeType}
                        buttonComponent={DropdownEditor.NoFilterButton}
                        className={bem('dropdown')}
                        onChange={this._onRangeTypeChange}
                    />
                </PlainDataProvider>

                {filter.rangeType === dateRangeFilter.DateRangeFilterRangeType.Custom && (
                    <React.Fragment>
                        <div className={bem('from-text')}>
                            <FormattedMessage id={`${i18nPrefix}.fromText`} defaultMessage='From' />
                        </div>

                        <DateTimeEditor
                            className={bem('from-editor')}
                            value={filter.custom.greaterOrEqual}
                            minDate={minCustomRangeDate}
                            maxDate={maxCustomRangeDate}
                            onChange={this._onGreaterOrEqualChange}
                            onBlur={this._onGreaterOrEqualBlur}
                        />

                        <div className={bem('to-text')}>
                            <FormattedMessage id={`${i18nPrefix}.toText`} defaultMessage='to' />
                        </div>

                        <DateTimeEditor
                            className={bem('to-editor')}
                            value={filter.custom.lessOrEqual}
                            minDate={minCustomRangeDate}
                            maxDate={maxCustomRangeDate}
                            onChange={this._onLessOrEqualChange}
                            onBlur={this._onLessOrEqualBlur}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }

    private _onRangeTypeChange = (value: Reference | null) => {
        this.props.onFilterChange({
            ...this.props.filter,
            rangeType: value
                ? (value.id as dateRangeFilter.DateRangeFilterRangeType)
                : dateRangeFilter.DateRangeFilterRangeType.None,
        });
    };

    private _onGreaterOrEqualChange = (value: string | null) => {
        this.props.onFilterChange({
            ...this.props.filter,
            custom: {
                ...this.props.filter.custom,
                greaterOrEqual: value,
            },
        });
    };

    private _onGreaterOrEqualBlur = () => {
        let lessOrEqual = this.props.filter.custom.lessOrEqual;
        let greaterOrEqual = this.props.filter.custom.greaterOrEqual;

        if (greaterOrEqual && lessOrEqual && moment.utc(greaterOrEqual).isAfter(moment.utc(lessOrEqual))) {
            this.props.onFilterChange({
                ...this.props.filter,
                custom: {
                    ...this.props.filter.custom,
                    lessOrEqual: greaterOrEqual,
                },
            });
        }
    };

    private _onLessOrEqualChange = (value: string | null) => {
        this.props.onFilterChange({
            ...this.props.filter,
            custom: {
                ...this.props.filter.custom,
                lessOrEqual: value,
            },
        });
    };

    private _onLessOrEqualBlur = () => {
        let lessOrEqual = this.props.filter.custom.lessOrEqual;
        let greaterOrEqual = this.props.filter.custom.greaterOrEqual;

        if (greaterOrEqual && lessOrEqual && moment.utc(lessOrEqual).isBefore(moment.utc(greaterOrEqual))) {
            this.props.onFilterChange({
                ...this.props.filter,
                custom: {
                    ...this.props.filter.custom,
                    greaterOrEqual: lessOrEqual,
                },
            });
        }
    };
}

export default DateRangeFilter;
