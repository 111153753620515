import { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { mapData } from './useGetBudgetImpacts.map';
import {
    UseGetBudgetImpactsRequestParams,
    UseGetBudgetImpactsResponse,
    UseGetBudgetImpactsResponseBackend,
} from './useGetBudgetImpacts.types';

export const useGetBudgetImpacts = (
    params: UseGetBudgetImpactsRequestParams,
    queryOptions?: QueryOptions<UseGetBudgetImpactsResponseBackend, UseGetBudgetImpactsResponse>
) => {
    return useGetRequestLegacy<UseGetBudgetImpactsResponseBackend, UseGetBudgetImpactsResponse>(
        budgetsApiPaths.getBudgetImpacts,
        params,
        {
            mapData,
            queryOptions,
        }
    );
};
