import { useCallback } from 'react';

import { messages } from './FirstUserCell.messages';
import { FirstUserCellProps } from './FirstUserCell.types';

export const useActionItems = (props: FirstUserCellProps, qa: (key: string) => string) => {
    const {
        removeUserText,
        removeUserTitle,
        showAddRuleAction,
        showRemoveRuleAction,
        showRemoveUserAction,
        onAddRule,
        onRemoveRule,
        onRemoveUser,
        onCopyRule,
        user,
        rule,
        showCopyRuleAction,
    } = props;

    const addRule = useCallback(() => {
        onAddRule(user);
    }, [onAddRule, user]);

    const removeRule = useCallback(() => {
        onRemoveRule(user, rule);
    }, [onRemoveRule, rule, user]);

    const removeUser = useCallback(() => {
        onRemoveUser(user);
    }, [onRemoveUser, user]);

    const copyRule = useCallback(() => {
        onCopyRule(user);
    }, [onCopyRule, user]);

    const result: { name: string; title: string; onClick: () => void; 'data-qa': string }[] = [];

    if (showAddRuleAction) {
        result.push({
            name: messages.addAlternativeRule,
            title: messages.addAlternativeRuleTitle,
            onClick: addRule,
            'data-qa': qa('add-rule'),
        });
    }

    if (showRemoveRuleAction) {
        result.push({
            name: messages.removeRule,
            title: messages.removeRuleTitle,
            onClick: removeRule,
            'data-qa': qa('remove-rule'),
        });
    }

    if (showRemoveUserAction) {
        result.push({
            name: removeUserText,
            title: removeUserTitle,
            onClick: removeUser,
            'data-qa': qa('remove-user'),
        });
    }

    if (showCopyRuleAction) {
        result.push({
            name: messages.copyRuleToUser,
            onClick: copyRule,
            title: messages.copyRuleToUserTitle,
            'data-qa': qa('copy-rule'),
        });
    }

    return result;
};
