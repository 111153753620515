import { createBrowserHistory, History, Location } from 'history';
import { getDefaultPath } from 'urlBuilder';

import {
    authRoutes,
    authRoutesRedesign,
    publicRoutes,
    publicRoutesRedesign,
    workspaceRoutes,
    workspaceRoutesRedesign,
} from './routes';
import { RoutingServiceOptions } from './types';

class RoutingService {
    public workspaceRoutes = workspaceRoutes;
    public workspaceRoutesRedesign = workspaceRoutesRedesign;
    public authRoutes = authRoutes;
    public authRoutesRedesign = authRoutesRedesign;
    public publicRoutes = publicRoutes;
    public publicRoutesRedesign = publicRoutesRedesign;

    private _history: History = createBrowserHistory();

    public initialize({ history }: RoutingServiceOptions) {
        this._history = history;
    }

    public push(path: string, state?: any) {
        if (path.startsWith('http')) {
            this.navigateToExternalUrl(path);
        } else if (!path.startsWith('/')) {
            this._history.push(`/${path}`, state);
        } else {
            this._history.push(path, state);
        }
    }

    public replace(path: string, state?: any) {
        if (path.startsWith('http')) {
            this.navigateToExternalUrl(path);
        } else if (!path.startsWith('/')) {
            this._history.replace(`/${path}`, state);
        } else {
            this._history.replace(path, state);
        }
    }

    public reloadToUrl(path: string) {
        if (path.startsWith('#')) {
            path = `/${path.substr(1)}`;
        }

        if (!path.startsWith('http') && !path.startsWith('/')) {
            path = `/${path}`;
        }

        window.location.href = path;
    }

    public replaceToDefaultPath() {
        this.replace(getDefaultPath());
    }

    public pushToDefaultPath() {
        this.push(getDefaultPath());
    }

    public reloadToDefaultPath() {
        this.reloadToUrl(getDefaultPath());
    }

    public navigateToExternalUrl(url: string) {
        window.location.href = url;
    }

    public navigateToExternalUrlInNewTab(url: string) {
        window.open(url, '_blank');
    }

    public getLocation(): Location {
        return this._history.location;
    }
}

export const routingService = new RoutingService();
