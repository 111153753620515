import { colorTextByBg, mods, theme } from '@approvalmax/theme';
import { BadgeProps } from '@approvalmax/ui/src/components/Badge/Badge.types';
import { Placement } from '@floating-ui/react';
import { StyledMods } from '@styled-kit/mods';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

const badgePlacements: Record<Placement, FlattenSimpleInterpolation> = {
    top: css`
        top: 0;
        left: 50%;
    `,
    'top-start': css`
        top: 0;
        left: 0;
    `,
    'top-end': css`
        top: 0;
        left: 100%;
    `,
    'right-start': css`
        top: 0;
        left: 100%;
    `,
    right: css`
        top: 50%;
        left: 100%;
    `,
    'right-end': css`
        top: 100%;
        left: 100%;
    `,
    bottom: css`
        top: 100%;
        left: 50%;
    `,
    'bottom-start': css`
        top: 100%;
        left: 0;
    `,
    'bottom-end': css`
        top: 100%;
        left: 100%;
    `,
    'left-start': css`
        top: 0;
        left: 0;
    `,
    left: css`
        top: 50%;
        left: 0;
    `,
    'left-end': css`
        top: 100%;
        left: 0;
    `,
};

export const Label = styled.div<StyledMods<Pick<BadgeProps, 'size' | 'color' | 'placement'>>>`
    border-radius: ${({ $size }) => $size && $size / 2}px;
    height: ${({ $size }) => $size}px;
    min-width: ${({ $size }) => $size}px;
    font-size: ${({ $size }) => $size && $size / 1.5}px;
    line-height: 1;
    font-weight: ${theme.fontWeight.medium};
    text-transform: uppercase;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;

    ${mods('placement')(
        (value) => css`
            transform: translate(-50%, -50%);
            position: absolute;

            ${badgePlacements[value]};
        `
    )};

    ${mods.color(
        (value) => css`
            background-color: ${theme.color[value]};
            color: ${theme.color[colorTextByBg[value]]};
        `
    )};
`;

export const Root = styled.div`
    display: inline-block;
    position: relative;
    flex-shrink: 0;
`;
