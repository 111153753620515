import { theme } from '@approvalmax/theme';
import { Text } from '@approvalmax/ui/src/components';
import styled from 'styled-components/macro';

export const Title = styled(Text)`
    &:after {
        content: '*';
        color: ${theme.color.red100};
    }
`;
