import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { authApiPaths } from '../paths';

export const useLogout = (mutationOptions?: MutationOptions<never, RequestDataParams<{}>>) => {
    const result = useMutateLegacy<RequestDataParams<{}>>(authApiPaths.logout, { mutationOptions });

    return {
        ...result,
        mutate: () => result.mutate({ data: {} }),
    };
};
