import { Action, CLOSE_ADD_COMMENT_POPUP, OPEN_ADD_COMMENT_POPUP } from '../../actions';

export enum AddCommentPopupPostAction {
    Save = 'save',
    Activate = 'activate',
    Deactivate = 'deactivate',
    Update = 'update',
}

export interface AddCommentPopup {
    isActive: boolean;
    postAction: AddCommentPopupPostAction | null;
}

export default function addCommentPopupReducer(
    state: AddCommentPopup = { isActive: false, postAction: null },
    action: Action
): AddCommentPopup {
    switch (action.type) {
        case OPEN_ADD_COMMENT_POPUP:
            return { isActive: true, postAction: action.payload.postAction };

        case CLOSE_ADD_COMMENT_POPUP:
            return { isActive: false, postAction: null };

        default:
            return state;
    }
}
