import {
    Dispatch,
    FocusEvent,
    KeyboardEventHandler,
    MouseEventHandler,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import type { CalendarProps } from 'react-calendar';
import { useToggle } from 'react-use';

import type { HTMLTextFieldElement, TextFieldProps } from '../TextField/TextField.types';
import {
    convertLocalToUTC,
    convertUTCToLocal,
    formatTextFieldValue,
    formatTextFieldValueToHumanReadable,
    normalizeDatePickerValue,
    parseEnteredTextFieldValue,
    parseValueToDate,
} from './DateTimePicker.helpers';
import { DateTimePickerProps } from './DateTimePicker.types';

export const useTextFieldValue = (
    props: Pick<ReturnType<typeof useCalendarValue>, 'changeDatePickerValue' | 'calendarValue'> &
        Partial<Pick<DateTimePickerProps, 'minDate' | 'maxDate'>>
) => {
    const { calendarValue, changeDatePickerValue, minDate, maxDate } = props;

    const [textFieldValue, setTextFieldValue] = useState<string | undefined>();

    const handleTextFieldChange = useCallback<NonNullable<TextFieldProps['onChange']>>((enteredValue) => {
        setTextFieldValue(enteredValue);
    }, []);

    const commitDateTimePickerValue = useCallback(() => {
        const parsedTextFieldValue = parseEnteredTextFieldValue(textFieldValue);
        const parsedDate = parsedTextFieldValue ? new Date(parsedTextFieldValue) : undefined;

        let resultDate = parsedDate;

        if (parsedDate && minDate && parsedDate < minDate) {
            resultDate = new Date(minDate);
        }

        if (parsedDate && maxDate && parsedDate > maxDate) {
            resultDate = new Date(maxDate);
        }

        changeDatePickerValue(resultDate);

        setTextFieldValue(formatTextFieldValue(calendarValue));
    }, [changeDatePickerValue, calendarValue, maxDate, minDate, textFieldValue]);

    return {
        textFieldValue,
        setTextFieldValue,
        handleTextFieldChange,
        commitDateTimePickerValue,
    };
};

export const useTextFieldValueFormating = (
    calendarValue: ReturnType<typeof useCalendarValue>['calendarValue'],
    setTextFieldValue: Dispatch<SetStateAction<string | undefined>>,
    isHumanReadable: boolean
) => {
    useEffect(() => {
        setTextFieldValue(
            isHumanReadable ? formatTextFieldValueToHumanReadable(calendarValue) : formatTextFieldValue(calendarValue)
        );
    }, [calendarValue, isHumanReadable, setTextFieldValue]);

    useEffect(() => {
        setTextFieldValue((prevValue) =>
            prevValue
                ? isHumanReadable
                    ? formatTextFieldValueToHumanReadable(prevValue)
                    : formatTextFieldValue(prevValue)
                : prevValue
        );
    }, [isHumanReadable, setTextFieldValue]);
};

export const useCalendarValue = (
    props: Pick<DateTimePickerProps, 'value' | 'noUtc'> & Required<Pick<DateTimePickerProps, 'onChange'>>
) => {
    const { value, onChange, noUtc } = props;

    const [calendarValue, setCalendarValue] = useState<Date | undefined>();

    useEffect(() => {
        if (noUtc) {
            setCalendarValue(parseValueToDate(value));
        } else {
            setCalendarValue(convertLocalToUTC(value));
        }
    }, [value, noUtc]);

    const changeDatePickerValue = useCallback(
        (changeValue: Date | undefined) => {
            setCalendarValue(changeValue);
            onChange(noUtc ? changeValue : convertUTCToLocal(changeValue));
        },
        [onChange, noUtc]
    );

    return {
        calendarValue,
        changeDatePickerValue,
    };
};

export const useCallbackActions = (
    props: Pick<DateTimePickerProps, 'onOpen' | 'onFocus' | 'onBlur' | 'onClear' | 'initOpen' | 'initFocus'> &
        Pick<ReturnType<typeof useCalendarValue>, 'changeDatePickerValue' | 'calendarValue'> &
        Pick<ReturnType<typeof useTextFieldValue>, 'commitDateTimePickerValue'>
) => {
    const {
        calendarValue,
        changeDatePickerValue,
        commitDateTimePickerValue,
        onOpen,
        onFocus,
        onBlur,
        onClear,
        initOpen,
        initFocus,
    } = props;

    const [open, toggleOpen] = useToggle(Boolean(initOpen));
    const [focus, toggleFocus] = useToggle(Boolean(initFocus));

    const handleOnOpen = useCallback<Required<DateTimePickerProps>['onOpen']>(
        (nextOpen) => {
            toggleOpen(nextOpen);
            onOpen && onOpen(nextOpen);
        },
        [onOpen, toggleOpen]
    );

    const handleOnFocus = useCallback<Required<DateTimePickerProps>['onFocus']>(
        (event) => {
            toggleOpen(true);
            setTimeout(() => toggleFocus(true), 0);
            onFocus && onFocus(event);
        },
        [onFocus, toggleFocus, toggleOpen]
    );

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLTextFieldElement>>(
        (event) => {
            if (event.key === ' ') {
                event.preventDefault();

                if (!open) {
                    handleOnOpen(true);
                }
            }
        },
        [handleOnOpen, open]
    );

    const handleIconClick = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
        toggleOpen(true);
        setTimeout(() => toggleFocus(true), 0);

        // TODO: dispatch correct focus event via `dispatchEvent()` there
        //  event emitter will be the input element
        // change to FocusEvent<any> because can be called by html element as well as by SVG icon
        onFocus && onFocus(event as unknown as FocusEvent<HTMLTextFieldElement>);
    }, [onFocus, toggleFocus, toggleOpen]);

    const handleOnBlur = useCallback<Required<DateTimePickerProps>['onFocus']>(
        (event) => {
            // this case will handle a situation when calendar popup is closed and navigates to
            // another element. While popup opened we don't want to commit any changes on blur
            !open && commitDateTimePickerValue();
            toggleFocus(false);
            onBlur && onBlur(event);
        },
        [commitDateTimePickerValue, onBlur, open, toggleFocus]
    );

    const handleOnClear = useCallback<Required<DateTimePickerProps>['onClear']>(() => {
        changeDatePickerValue(undefined);
        onClear && onClear();
    }, [changeDatePickerValue, onClear]);

    const handleChangeValue: DateTimePickerProps['onChange'] = (value) => {
        changeDatePickerValue(value);
    };

    const handleCalendarChange: NonNullable<CalendarProps['onChange']> = (value) => {
        handleChangeValue(normalizeDatePickerValue(value, calendarValue));
    };

    return {
        open,
        handleOnOpen,
        focus,
        handleOnFocus,
        handleCalendarChange,
        handleChangeValue,
        handleKeyDown,
        handleIconClick,
        handleOnBlur,
        handleOnClear,
    };
};
