import { Box, Checkbox } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';

import { RenderCellOptions } from '../../../../config/matrixDefinitions.shared';
import { messages } from './AirwallexBenificiaryCreationConditionCell.messages';

const AirwallexBenificiaryCreationConditionCell: FC<RenderCellOptions> = memo((props) => {
    const { rule, field, readonly, lineId, condition, onConditionChange } = props;

    const onChange = useCallback(
        (checked: boolean) => {
            const newCondition: domain.ServerCondition | domain.AlwaysTrueCondition = checked
                ? {
                      fieldId: condition.fieldId,
                      fieldName: condition.fieldName,
                      fieldSystemPurpose: condition.fieldSystemPurpose,
                      conditionType: domain.ConditionType.ServerCondition,
                      serverConditionType: domain.ServerConditionType.AllContacts,
                      allowCreation: true,
                  }
                : {
                      fieldId: condition.fieldId,
                      fieldName: condition.fieldName,
                      fieldSystemPurpose: condition.fieldSystemPurpose,
                      conditionType: null,
                  };

            onConditionChange(lineId, rule, field, newCondition);
        },
        [condition.fieldId, condition.fieldName, condition.fieldSystemPurpose, field, lineId, onConditionChange, rule]
    );

    return (
        <Box width='172px'>
            <Checkbox onChange={onChange} size='small' checked={condition.allowCreation} disabled={readonly}>
                {messages.label}
            </Checkbox>
        </Box>
    );
});

export default AirwallexBenificiaryCreationConditionCell;
