export const ampmValues = <const>['AM', 'PM'];

export const timeBlockSize = 32;

export const timeBlocksInView = 7;

export const minutesStep = 5;

export const partToDateMethod = <const>{
    h: 'setHours',
    m: 'setMinutes',
    s: 'setSeconds',
    ms: 'setMilliseconds',
};
