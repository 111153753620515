import { atom } from 'recoil';

import { MatrixCopyRuleState } from './CopyRulePopup.types';

export const matrixCopyRuleState = atom<MatrixCopyRuleState>({
    key: 'matrixCopyRuleState',
    default: {
        isOpen: false,
        fromUser: null,
        addedUsers: [],
        matrixType: null,
    },
});
