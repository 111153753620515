import { Select, Table } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback, useMemo } from 'react';

import { calculateSelectedSteps } from '../../../../utils/helpers';
import { messages } from './CopyRuleWorkflowStepCell.messages';
import { CopyRuleWorkflowStepCellProps } from './CopyRuleWorkflowStepCell.types';
import { convertStepsForDropdown } from './CopyRuleWorkflowStepCell.utils';

const CopyRuleWorkflowStepsCell: FC<CopyRuleWorkflowStepCellProps> = memo((props) => {
    const { item: user, meta, onAction, errors, ...otherProps } = props;
    const { matrix, steps, checkedData } = meta;
    const selectedSteps = useMemo(
        () => steps.filter((step) => checkedData[user.id] && checkedData[user.id].includes(step.id)),
        [checkedData, steps, user.id]
    );

    const stepActiveId = useMemo(
        () => ('stepIndex' in matrix && steps[matrix.stepIndex] && steps[matrix.stepIndex].id) || null,
        [matrix, steps]
    );

    const valuesAvailable = useMemo(
        () => convertStepsForDropdown(user, meta.userFrom, meta.steps, stepActiveId),
        [user, meta.userFrom, meta.steps, stepActiveId]
    );

    const valuesSelected = useMemo(
        () =>
            checkedData[user.id] && checkedData[user.id].length > 0
                ? convertStepsForDropdown(user, meta.userFrom, selectedSteps, stepActiveId).map((i) => i.id)
                : [],
        [checkedData, user, meta.userFrom, selectedSteps, stepActiveId]
    );

    const isInvalid = useMemo(() => errors?.includes(user.id), [user, errors]);

    const handleChange = useCallback(
        (values: string[]) => {
            const stepsSelectedNext = calculateSelectedSteps(meta.steps, values);

            onAction({ [user.id]: stepsSelectedNext.map((step) => step.id) });
        },
        [user, meta.steps, onAction]
    );

    return (
        <Table.Cell {...otherProps} item={user}>
            <Select
                items={valuesAvailable}
                itemNameKey='text'
                multiple
                invalid={isInvalid}
                value={valuesSelected}
                onChange={handleChange}
                placeholder={messages.selectSteps}
                size='small'
            />
        </Table.Cell>
    );
});

export default CopyRuleWorkflowStepsCell;
