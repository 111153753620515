import { stateTree } from 'modules/data';
import { set } from 'modules/immutable';

import { Action, VERIFY_EMAIL_FAILURE, VERIFY_EMAIL_RESPONSE } from '../actions';

export type SessionType = stateTree.Session;

export default function (state: SessionType, action: Action): SessionType {
    switch (action.type) {
        case VERIFY_EMAIL_RESPONSE:
            return set(state, 'authenticated', true);

        case VERIFY_EMAIL_FAILURE:
            return set(state, 'authenticated', false);

        default:
            return state;
    }
}
