import { actions } from 'modules/common';
import { State } from 'modules/data';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationService } from 'services/notification';
import { useStartTFAEnabling } from 'shared/data';

import { useTwoFaEnablingWizardContext } from './TwoFaEnablingWizard.context';

export const useTwoFaSetupWizard = () => {
    const { mutate: startSetup } = useStartTFAEnabling();

    const { context, setContext, hideWizard, resetContext } = useTwoFaEnablingWizardContext();

    useEffect(() => {
        if (context.isOpen) {
            startSetup(
                {
                    data: {},
                },
                {
                    onSuccess: (response) => {
                        setContext((prevContext) => ({
                            ...prevContext,
                            stateId: response.stateId,
                            setupCode: response.code,
                        }));
                    },
                    onError: (err) => {
                        notificationService.showErrorToast(err.message);
                    },
                }
            );
        } else {
            resetContext();
        }
    }, [context.isOpen, resetContext, setContext, startSetup]);

    return {
        isActive: !!context.stateId,
        onCancel: hideWizard,
    };
};

/**
 * This is used for displaying My Profile toggler in a correct state right after popup opened.
 * Better to use Recoil or something the same to get rid of this code
 *
 * @see AM-14443
 */
export const useGlobalProfileEnableTwoFaSetting = () => {
    const dispatch = useDispatch();
    const profile = useSelector((state: State) => state.profile);

    return useCallback(() => {
        if (!profile?.email) {
            return;
        }

        dispatch(actions.updateProfile(profile.email, {}, { is2faEnabled: true }));
    }, [dispatch, profile?.email]);
};
