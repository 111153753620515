import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingPopup.SectionHistoryPushing', {
    title: 'Send ApprovalMax events to Xero',
    subtitle: `Choose which events that are performed in ApprovalMax need to be posted to Xero. Such events include comments, rejections, matching allocations, GRN updates. After syncing is completed this information will be available in Xero request in the History and Notes section.
        {br}{br}Please note: for the documents created in ApprovalMax, events can be send to Xero only if they have occurred after final approval of the request`,
    optionNoneText: 'Don’t send events to Xero',
    optionCommentsText: 'Send only comments',
    optionAllText: 'Send all events',
});
