import { Button, Flex } from '@approvalmax/ui/src/components';
import {
    TextEditBoldIcon,
    TextEditEraserIcon,
    TextEditItalicIcon,
    TextEditListIcon,
    TextEditNumListIcon,
    TextEditUnderlineIcon,
} from '@approvalmax/ui/src/icons';
import { FC, useCallback } from 'react';

import { messages } from './Menubar.messages';
import { MenuBarProps } from './MenuBar.types';

const MenuBar: FC<MenuBarProps> = (props) => {
    const { editor } = props;

    const toggleBold = useCallback(() => {
        editor.chain().focus().toggleBold().run();
    }, [editor]);

    const toggleItalic = useCallback(() => {
        editor.chain().focus().toggleItalic().run();
    }, [editor]);

    const toggleUnderline = useCallback(() => {
        editor.chain().focus().toggleUnderline().run();
    }, [editor]);

    const toggleOrderedList = useCallback(() => {
        editor.chain().focus().toggleOrderedList().run();
    }, [editor]);

    const toggleBulletList = useCallback(() => {
        editor.chain().focus().toggleBulletList().run();
    }, [editor]);

    const clearFormatting = useCallback(() => {
        editor.chain().focus().unsetAllMarks().run();
        editor.chain().focus().clearNodes().run();
    }, [editor]);

    const getIconColor = useCallback(
        (name: string) => (editor.isActive(name) ? 'midnight100' : 'midnight70'),
        [editor]
    );

    return (
        <Flex inline spacing='8'>
            <Button icon title={messages.boldTitle} noPadding onClick={toggleBold}>
                <TextEditBoldIcon color={getIconColor('bold')} size={20} />
            </Button>

            <Button icon title={messages.italicTitle} noPadding onClick={toggleItalic}>
                <TextEditItalicIcon color={getIconColor('italic')} size={20} />
            </Button>

            <Button icon title={messages.underlineTitle} noPadding onClick={toggleUnderline}>
                <TextEditUnderlineIcon color={getIconColor('underline')} size={20} />
            </Button>

            <Button icon title={messages.orderedListTitle} noPadding onClick={toggleOrderedList}>
                <TextEditNumListIcon color={getIconColor('orderedList')} size={20} />
            </Button>

            <Button icon title={messages.bulletListTitle} noPadding onClick={toggleBulletList}>
                <TextEditListIcon color={getIconColor('bulletList')} size={20} />
            </Button>

            <Button icon title={messages.eraserTitle} noPadding onClick={clearFormatting}>
                <TextEditEraserIcon color='midnight70' size={20} />
            </Button>
        </Flex>
    );
};

export default MenuBar;
