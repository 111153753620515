/**
 * Useful for timers or countdowns
 *
 * @param timeMs
 * @return formatted as MM:ss or HH:MM:ss or DD:HH:MM:ss time
 */
export const formatTime = (timeMs: number) => {
    const remainingTimeInSeconds = Math.floor(timeMs / 1000);

    const s = Math.floor(remainingTimeInSeconds % 60);
    const m = Math.floor((remainingTimeInSeconds / 60) % 60);
    const h = Math.floor((remainingTimeInSeconds / (60 * 60)) % 24);
    const d = Math.floor(remainingTimeInSeconds / (60 * 60 * 24));

    return [d, h, m, s]
        .reduce<string[]>((acc, cur, currentIndex) => {
            if (!acc.length && cur === 0 && currentIndex < 2) {
                return acc;
            }

            acc.push(String(cur).padStart(2, '0'));

            return acc;
        }, [])
        .join(':');
};
