import { selectors, useSessionNotification } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { BubbleCloseIcon } from 'modules/sprites';
import { FC, memo } from 'react';
import { routingService } from 'services/routing';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { messages } from './UpgradeExpiredPlanNotification.messages';
import {
    CloseButton,
    Container,
    HideButton,
    Message,
    Notification,
    UpgradeButton,
} from './UpgradeExpiredPlanNotification.styles';

interface UpgradeExpiredPlanNotificationProps {
    companies: selectors.types.ExpandedCompany[];
}

const UpgradeExpiredPlanNotification: FC<UpgradeExpiredPlanNotificationProps> = (props) => {
    const { companies } = props;

    const company = companies[0];
    const isMultiple = companies.length > 1;

    const banner = useSessionNotification(`UPGRADE_EXPIRED_PLAN.${companies.map((c) => c.id).join('.')}`);
    const link = useSelector((state) => selectors.links.getPricingLinkOfAdvancedPlan(state, company, isMultiple));

    const handleUpgrade = () => {
        routingService.navigateToExternalUrlInNewTab(
            isMultiple
                ? getAccountUrl(AccountPath.subscriptions, {
                      origin: 'wa_upgrade_expired_plan',
                  })
                : link
        );
    };

    const handleHide = () => {
        banner.hide();
    };

    if (banner.isHidden || companies.length === 0) {
        return null;
    }

    return (
        <Notification>
            <Container>
                <Message>
                    {isMultiple
                        ? messages.trialOfAllFeaturesMultiple({ count: companies.length })
                        : messages.trialOfAllFeatures({ companyName: company.displayName })}

                    <UpgradeButton execute={handleUpgrade}>
                        {isMultiple ? messages.reviewSubscriptions : messages.upgradePlan}
                    </UpgradeButton>
                </Message>

                <CloseButton execute={handleHide}>
                    <BubbleCloseIcon width={12} height={12} />
                </CloseButton>

                <HideButton execute={handleHide}>{messages.hide}</HideButton>
            </Container>
        </Notification>
    );
};

export default memo(UpgradeExpiredPlanNotification);
