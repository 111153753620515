import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.BackupCodes', {
    downloadButton: 'Download codes (txt file)',
    checkboxText: 'Yes, I have saved the backup codes',
    doneButton: 'Done',
    fileName: 'Backup_codes',
    copy: 'Copy',
    copiedToast: 'Copied to clipboard!',
});
