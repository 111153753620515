import { Link, mentionConstants } from '@approvalmax/ui/src/components';
import { domHelpers } from '@approvalmax/utils';
import DOMPurify from 'dompurify';
import parse, { DOMNode, domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { domain } from 'modules/data';
import { memo, useMemo } from 'react';

import { checkAccessToRequest } from '../../../pages/requestList/selectors/requestSelectors';
import { Mention, StyledUserHover } from './TextWithMentions.styles';
import { TextWithMentionsProps } from './TextWithMentions.types';

export const TextWithMentions = memo<TextWithMentionsProps>((props) => {
    const { html, mentionedUsers, request } = props;

    const { company } = request;

    const highlightedHtml = domHelpers.highlightUrls(html || '', true);

    const options = useMemo<HTMLReactParserOptions>(
        () => ({
            replace: (node) => {
                if ('attribs' in node && node.attribs['data-type'] === 'mention') {
                    const userId: string = node.attribs[mentionConstants.dataAttributeUserId];
                    const mentionedUser = (mentionedUsers || []).find(
                        (mentionedUser) => mentionedUser.userProfileId === userId
                    );

                    if (!mentionedUser) {
                        return null;
                    }

                    const user = company.allMembers.find((member) => member.databaseId === userId);
                    const isOffboardedUser = !user;

                    const userHasAccess = checkAccessToRequest(userId, mentionedUser.email, company, request);

                    const offboardedUser: domain.User = {
                        id: mentionedUser.email,
                        databaseId: mentionedUser.userProfileId,
                        firstName: '',
                        lastName: '',
                        userEmail: mentionedUser.email,
                    };

                    const displayName = isOffboardedUser
                        ? mentionedUser.email
                        : user.firstName && user.lastName
                          ? `${user.firstName} ${user.lastName}`
                          : user.firstName || user.lastName || user.userEmail;

                    return (
                        <StyledUserHover user={user || offboardedUser}>
                            <Mention
                                $userHasAccess={userHasAccess}
                                $isOffboardedUser={isOffboardedUser}
                                title={displayName}
                            >
                                {displayName}
                            </Mention>
                        </StyledUserHover>
                    );
                }

                if (
                    'name' in node &&
                    node.name === 'a' &&
                    'attribs' in node &&
                    typeof node.attribs?.href === 'string'
                ) {
                    return (
                        <Link font='body' href={node.attribs.href} target='_blank'>
                            {domToReact(node.children as DOMNode[])}
                        </Link>
                    );
                }

                return node;
            },
        }),
        [company, mentionedUsers, request]
    );

    const content = useMemo(() => parse(DOMPurify.sanitize(highlightedHtml), options), [highlightedHtml, options]);

    return <>{content}</>;
});

TextWithMentions.displayName = 'TextWithMentions';
