/**
 * @function
 * @param {Number} a Argument A.
 * @param {Number} b Argument B.
 * @return {Number} Result as follows:
 * <ul>
 *     <li><code>-1</code> - if A &lt; B.</li>
 *     <li><code>0</code> - if A == B.</li>
 *     <li><code>1</code> - if A &gt; B.</li>
 * </ul>
 * */
export const numberComparator2Desc = (a: number, b: number) => {
    return b - a;
};
