import { selectors } from 'modules/common';
import { domain } from 'modules/data';

export interface SingleWorkflowCopyStateType {
    selectedCompany: selectors.types.ExpandedCompany | null;
    selectedWorkflow: selectors.types.ExpandedTemplate | null;
}

export interface MultipleWorkflowCopyStateType {
    selectedCompany: selectors.types.ExpandedCompany | null;
    selectedWorkflows: selectors.types.ExpandedTemplate[] | null;
    summary: MultiCopySummary;
}

export interface MultiCopySummaryItem {
    company: domain.Company;
    template: domain.Template;
    copySettingsError: Error | null;
}

export interface MultiCopySummary {
    copiedTemplates: MultiCopySummaryItem[];
    templatesWithErrors: MultiCopySummaryItem[];
}

export const isMultiCopySummary = (data: unknown): data is MultiCopySummary => {
    return (
        Boolean(data) &&
        Object.prototype.hasOwnProperty.call(data, 'copiedTemplates') &&
        Object.prototype.hasOwnProperty.call(data, 'templatesWithErrors')
    );
};

export enum TabId {
    SINGLE_COPY = 'SINGLE_COPY',
    MULTIPLE_COPY = 'MULTIPLE_COPY',
}
