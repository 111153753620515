import { useGetRequestLegacy } from '../../../api';
import { companiesApiPaths } from '../paths';
import { mapData } from './useGetMatchingSettings.map';
import {
    UseGetMatchingSettingsParams,
    UseGetMatchingSettingsResponse,
    UseGetMatchingSettingsResponseBackend,
} from './useGetMatchingSettings.types';

export const useGetMatchingSettings = (params: UseGetMatchingSettingsParams) => {
    return useGetRequestLegacy<UseGetMatchingSettingsResponseBackend, UseGetMatchingSettingsResponse>(
        companiesApiPaths.getMatchingSettings,
        params,
        {
            mapData,
        }
    );
};
