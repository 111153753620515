import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionQboBillMatching', {
    title: 'Purchase order matching',
    manualMatchiungSubtitle: 'Enable the manual matching to Purchase Orders?',
    billApproveSubtitle: 'Can a Bill be approved if it is not yet matched to a Purchase Order?',
    optionAllowToMatch: 'Allow Users to match Bills to Purchase Orders lines',
    optionYes: 'Yes, unmatched Bills can be approved',
    optionNoAtLeastOne: 'No, at least one line should be matched to a Purchase Order',
    optionNoEachLine: 'No, each line should be matched to a Purchase Order',
});
