import { stateTree } from 'modules/data';
import { ImmutableObject, merge } from 'modules/immutable';

import { Action, LEAVE_PAGE, LOAD_PAGE_DATA } from '../actions';

export default function (
    state: ImmutableObject<stateTree.Navigation>,
    action: Action
): ImmutableObject<stateTree.Navigation> {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return merge(state, {
                activePageId: null,
                activeCompany: action.payload.companyId,
                innerActivePageId: action.payload.innerActivePageId,
            });

        case LEAVE_PAGE:
            return merge(state, {
                innerActivePageId: null,
            });

        default:
            return state;
    }
}
