import { font, oPopupPadding, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled(Popup.DefaultContent)`
    width: 520px;
`;

export const Content = styled.div`
    ${oPopupPadding()}
    display: flex;
    flex-flow: column;
    gap: 25px;
`;

export const Title = styled.div`
    ${font(16, '#000', 'semibold')}
`;

export const Description = styled.div`
    ${font(13, '#000', 'standard')}
`;
