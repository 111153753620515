import { isBrowser } from './isBrowser';

export const isIphone = () => {
    if (isBrowser()) {
        const agent = navigator.userAgent.toLowerCase();
        const isWindows = /Windows/i.test(agent);
        const isNokia = /Nokia/i.test(agent);
        const isIpad = /ipad/i.test(agent);

        return !isIpad && /iphone|ipod/i.test(agent) && !(isWindows || isNokia || isIpad);
    }

    return false;
};
