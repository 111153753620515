import { Tag } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { memo } from 'react';

import { messages } from './AmaxPayPaymentStatusTag.messages';
import { AmaxPayPaymentStatusTagProps } from './AmaxPayPaymentStatusTag.types';

export const AmaxPayPaymentStatusTag = memo<AmaxPayPaymentStatusTagProps>((props) => {
    const { paymentStatus } = props;

    switch (paymentStatus) {
        case domain.AmaxPayPaymentStatus.ReadyToPay:
            return (
                <Tag color='green100' outline size='xsmall'>
                    {messages.ready}
                </Tag>
            );

        case domain.AmaxPayPaymentStatus.Processing:
            return (
                <Tag color='blue80' outline size='xsmall'>
                    {messages.processing}
                </Tag>
            );

        case domain.AmaxPayPaymentStatus.Paid:
            return (
                <Tag color='green100' size='xsmall'>
                    {messages.paid}
                </Tag>
            );

        case domain.AmaxPayPaymentStatus.CancelledByUser:
            return (
                <Tag color='red100' size='xsmall'>
                    {messages.cancelled}
                </Tag>
            );

        case domain.AmaxPayPaymentStatus.Failed:
            return (
                <Tag color='brandDext90' size='xsmall' title={messages.failedTooltip}>
                    {messages.failed}
                </Tag>
            );

        default:
            return null;
    }
});

AmaxPayPaymentStatusTag.displayName = 'AmaxPayPaymentStatusTag';
