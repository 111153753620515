import { memo } from 'react';

import DearExternalSubmitterSection from '../DearExternalSubmitterSection/DearExternalSubmitterSection';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import { messages } from './DearPoStartStep.messages';

const DearPoStartStep = memo(() => {
    return (
        <FixedStepContainer bubbleText={messages.bubbleText} color='brandCin7100'>
            <DearExternalSubmitterSection cacheId='DearPoStartStepExternalSubmitter' />
        </FixedStepContainer>
    );
});

export default DearPoStartStep;
