import { hooks } from '@approvalmax/utils';
import { useMemo } from 'react';
import { shouldOpenNewOrgPopupNoCompaniesQueryParam, shouldOpenNewOrgPopupStorageKey } from 'shared/constants';

export const useShouldNewOrgPopupNoCompanies = () => {
    const [openNewOrg] = hooks.useQueryParam(shouldOpenNewOrgPopupNoCompaniesQueryParam);

    return useMemo(
        () => Boolean(openNewOrg) || sessionStorage.getItem(shouldOpenNewOrgPopupStorageKey) === 'true',
        [openNewOrg]
    );
};
