import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { breadcrumbsHeaderState } from 'shared/components';
import { getPath, Path } from 'urlBuilder';

import { messages } from '../messages';

export const useNoCompaniesBreadcrumbs = () => {
    const setBreadcrumbs = useSetRecoilState(breadcrumbsHeaderState);

    useEffect(() => {
        setBreadcrumbs([
            {
                name: messages.title,
                to: getPath(Path.noCompanies),
            },
        ]);
    }, [setBreadcrumbs]);
};
