import { Button, Popup } from '@approvalmax/ui';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'modules/common';
import FirstTimeSetupHeader from 'modules/first-start/components/HeaderWithLogo/HeaderWithLogo';
import { font } from 'modules/styles';
import { FC, memo, useCallback, useEffect } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { amplitudeService } from 'services/amplitude';
import { api } from 'services/api';
import styled from 'styled-components';

import { cancelActivePopup, showNewOrganisationPopup } from '../../actions';

const i18nPrefix = 'first-start/containers/CreateOrJoinPopup';

const Container = styled(Popup.EmptyContent)`
    width: 780px;
    max-width: 95%;
    max-height: 95%;
    display: flex;
    flex-flow: column;
`;

const Body = styled.div`
    overflow: hidden;
    flex: 1;
    margin: 25px 60px 50px 60px;
`;

const Header = styled.div`
    ${font(14, '#000', 'semibold')}
    margin-bottom: 14px;
`;

const EmailsContainer = styled.div``;

const Email = styled.div``;

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
`;

const qa = bemFactory.qa('first-start-create-or-join-popup');

const CreateOrJoinPopup: FC = () => {
    const dispatch = useDispatch();

    const potentialTeammatesData = useQuery([QueryKeys.POTENTIAL_TEAMMATES], async () => {
        const response = await api.auth.getPotentialTeammates();

        return response.emails;
    });

    const sendDecisionToAmplitude = (decision: string) => {
        amplitudeService.sendData('signup: check shared domain', {
            decision,
        });
    };

    const createNewOrganisation = useCallback(() => {
        sendDecisionToAmplitude('new account');
        dispatch(showNewOrganisationPopup({ firstStart: true }));
    }, [dispatch]);

    const closePopup = () => {
        sendDecisionToAmplitude('add to existing account');
        dispatch(cancelActivePopup());
    };

    useEffect(() => {
        if (
            !potentialTeammatesData.isInitialLoading &&
            (!potentialTeammatesData.data || potentialTeammatesData.data.length === 0)
        ) {
            dispatch(showNewOrganisationPopup({ firstStart: true }));
        }
    }, [dispatch, potentialTeammatesData.data, potentialTeammatesData.isInitialLoading]);

    if (
        potentialTeammatesData.isInitialLoading ||
        !potentialTeammatesData.data ||
        potentialTeammatesData.data.length === 0
    )
        return null;

    return (
        <Container>
            <FirstTimeSetupHeader
                title={
                    <FormattedMessage
                        id={`${i18nPrefix}.title`}
                        defaultMessage='Are you sure you need to set up ApprovalMax from scratch?'
                    />
                }
            />

            <Body>
                <Header>
                    <FormattedMessage
                        id={`${i18nPrefix}.header`}
                        defaultMessage='Some folks from your organization have already set up ApprovalMax. Ask them for an invite to work together!'
                    />
                </Header>

                <EmailsContainer>
                    {potentialTeammatesData.data.map((email) => (
                        <Email key={email}>{email}</Email>
                    ))}
                </EmailsContainer>

                <Actions>
                    <Button
                        execute={createNewOrganisation}
                        colorTheme='grey'
                        theme='primary'
                        padding='narrow20'
                        fontSize='small13'
                        qa={qa('create-company-button')}
                    >
                        <FormattedMessage
                            id={`${i18nPrefix}.newAccount`}
                            defaultMessage='I need to set up approvalmax from scratch'
                        />
                    </Button>

                    <Button execute={closePopup} padding='narrow20' fontSize='small13' qa={qa('join-button')}>
                        <FormattedMessage
                            id={`${i18nPrefix}.joinToExistingAccount`}
                            defaultMessage='I will ask coworkers for an invite'
                        />
                    </Button>
                </Actions>
            </Body>
        </Container>
    );
};

export default memo(CreateOrJoinPopup);
