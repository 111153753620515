import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    VerifyTFAWithOneTimePasswordRequest,
    VerifyTFAWithOneTimePasswordResponse,
} from './useVerifyTFAWithOneTimePassword.types';

export const useVerifyTFAWithOneTimePassword = () => {
    return useMutateLegacy<
        RequestDataParams<VerifyTFAWithOneTimePasswordRequest>,
        VerifyTFAWithOneTimePasswordResponse
    >(twoFaApiPaths.verifyTFAWithOneTimePassword);
};
