import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { companiesApiPaths } from '../paths';
import {
    UseEditParticipantPermissionsData,
    UseEditParticipantPermissionsPathParams,
    UseEditParticipantPermissionsResponseBackend,
} from './useEditParticipantPermissions.types';

export const useEditParticipantPermissions = () => {
    return useMutateLegacy<
        RequestDataParams<UseEditParticipantPermissionsData, UseEditParticipantPermissionsPathParams>,
        UseEditParticipantPermissionsResponseBackend
    >(companiesApiPaths.editParticipantPermissions, {
        method: 'put',
    });
};
