import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { TimeListProps } from './components';
import { ampmValues } from './DigitalClock.constants';
import {
    convertAmPmHoursToHoursLabel,
    convertHoursLabelToHours,
    convertToLabel,
    extractHoursToValue,
    extractMinutesToValue,
    flushDate,
    getAmOrPm,
    getMinutesIndex,
    hours12Values,
    hours24Values,
    maybeAdjustHoursToCompareDate,
    maybeAdjustMinutesToCompareDate,
    minutesValues,
    scrollIntoView,
} from './DigitalClock.helpers';
import { messages } from './DigitalClock.messages';
import type { Ampm, DigitalClockProps } from './DigitalClock.types';

export const useTimePickerStates = ({
    minDate,
    maxDate,
    value,
    onChange,
    ampm,
}: Pick<DigitalClockProps, 'value' | 'onChange' | 'minDate' | 'maxDate'> & { ampm: boolean }) => {
    const [hoursLabel, setHoursLabel] = useState(extractHoursToValue(value, ampm));
    const [minutesLabel, setMinutesLabel] = useState(extractMinutesToValue(value));
    const [hourCycleLabel, setHourCycleLabel] = useState<Ampm | undefined>(
        value && ampm ? getAmOrPm(value.getHours()) : undefined
    );

    const hoursListRef = useRef<HTMLUListElement>(null);
    const minutesListRef = useRef<HTMLUListElement>(null);
    const ampmListRef = useRef<HTMLUListElement>(null);

    const getDisabled = useCallback(
        (date: Date | undefined, isMinutes = false) => {
            if (!date) {
                return false;
            }

            const minDateExceeded = minDate && moment(minDate).isAfter(date);
            const maxDateExceeded =
                maxDate && moment(maxDate).isBefore(flushDate(new Date(date), isMinutes ? ['s', 'ms'] : undefined));

            return minDateExceeded || maxDateExceeded || false;
        },
        [maxDate, minDate]
    );

    const hours = useMemo(
        () =>
            (ampm ? hours12Values : hours24Values).map((hourValue) => {
                const date = value ? new Date(value) : undefined;

                if (date) {
                    date.setHours(convertHoursLabelToHours(hourValue, hourCycleLabel));
                    date.setMinutes(59);
                    date.setSeconds(59);
                }

                return {
                    value: hourValue,
                    disabled: getDisabled(date),
                    title: messages.hours({ hours: +hourValue }),
                };
            }),
        [ampm, getDisabled, hourCycleLabel, value]
    );

    const minutes = useMemo(
        () =>
            minutesValues.map((minuteValue) => {
                const date = value ? new Date(value) : undefined;

                if (date) {
                    date.setMinutes(+minuteValue);
                }

                return {
                    value: minuteValue,
                    disabled: getDisabled(date, true),
                    title: messages.minutes({ minutes: +minuteValue }),
                };
            }),
        [getDisabled, value]
    );

    const ampms = useMemo(
        () =>
            ampmValues.map((ampmValue) => {
                const date = value ? new Date(value) : undefined;

                let minDateExceeded;
                let maxDateExceeded;

                if (date) {
                    if (ampmValue === 'AM') {
                        minDateExceeded =
                            minDate && moment(minDate).isAfter(moment(date).hours(11).minutes(59).seconds(59).toDate());
                        maxDateExceeded =
                            maxDate && moment(maxDate).isBefore(moment(date).hours(0).minutes(0).seconds(0).toDate());
                    } else {
                        minDateExceeded =
                            minDate && moment(minDate).isAfter(moment(date).hours(23).minutes(59).seconds(59).toDate());
                        maxDateExceeded =
                            maxDate && moment(maxDate).isBefore(moment(date).hours(12).minutes(0).seconds(0).toDate());
                    }
                }

                return {
                    value: ampmValue,
                    disabled: minDateExceeded || maxDateExceeded || false,
                    title: ampmValue,
                };
            }),
        [maxDate, minDate, value]
    );

    const changeHours = useCallback(
        (hours: string | undefined) => {
            const correctedHours = ampm ? (hours === '12' ? '00' : hours) : hours;

            if (typeof hours === 'string' && typeof correctedHours === 'string') {
                scrollIntoView(correctedHours, hoursListRef);
            }

            setHoursLabel(hours);
        },
        [ampm]
    );

    const changeMinutes = useCallback((minutes: string | undefined) => {
        if (typeof minutes === 'string') {
            scrollIntoView(getMinutesIndex(minutes).toString(), minutesListRef);
        }

        setMinutesLabel(minutes);
    }, []);

    const changeHourCycle = useCallback((hourCycle: Ampm | undefined) => {
        hourCycle && scrollIntoView(hourCycle === 'AM' ? 0 : 1, ampmListRef);
        setHourCycleLabel(hourCycle);
    }, []);

    useEffect(() => {
        const hours = extractHoursToValue(value, ampm);
        const minutes = extractMinutesToValue(value);
        const hourCycle = value && ampm ? getAmOrPm(value.getHours()) : undefined;

        changeHourCycle(hourCycle);
        changeMinutes(minutes);
        changeHours(hours);
    }, [ampm, changeHourCycle, changeHours, changeMinutes, value]);

    const handleHourCycleChange = (newHourCycle: Ampm) => {
        changeHourCycle(newHourCycle);

        if (newHourCycle === hourCycleLabel) {
            return;
        }

        const date = value || new Date();

        if (!value) {
            // for ampm '12' is a substitution of '00'
            changeHours(ampm ? '12' : '00');
            changeMinutes('00');
            date.setHours(ampm ? (newHourCycle === 'AM' ? 0 : 12) : 0);
            date.setMinutes(0);
            date.setSeconds(0);
        } else {
            // for an existing date, we have to invert hours, let's say,
            // from  8 A.M. made 8 P.M. and so on...
            const oldHours = date.getHours();

            if (oldHours === 0 || oldHours === 12) {
                date.setHours(ampm ? (newHourCycle === 'AM' ? 0 : 12) : 0);
            } else {
                date.setHours(newHourCycle === 'AM' ? oldHours - 12 : oldHours + 12);
            }

            const adjustedToMinDateHours = maybeAdjustHoursToCompareDate(date, minDate, true);
            const adjustedToMaxDateHours = maybeAdjustHoursToCompareDate(date, maxDate);

            if (typeof adjustedToMinDateHours === 'number') {
                changeHours(convertAmPmHoursToHoursLabel(adjustedToMinDateHours));
                date.setHours(adjustedToMinDateHours);
            } else if (typeof adjustedToMaxDateHours === 'number') {
                changeHours(convertAmPmHoursToHoursLabel(adjustedToMaxDateHours));
                date.setHours(adjustedToMaxDateHours);
            }

            // apply adjustment to the date adjusted by hours. Order of ops. is important
            const adjustedToMinDateMinutes = maybeAdjustMinutesToCompareDate(date, minDate, true);
            const adjustedToMaxDateMinutes = maybeAdjustMinutesToCompareDate(date, maxDate);

            if (typeof adjustedToMinDateMinutes === 'number') {
                changeMinutes(convertToLabel(adjustedToMinDateMinutes));
                date.setMinutes(adjustedToMinDateMinutes);
            } else if (typeof adjustedToMaxDateMinutes === 'number') {
                changeMinutes(convertToLabel(adjustedToMaxDateMinutes));
                date.setMinutes(adjustedToMaxDateMinutes);
            }
        }

        onChange(date, 'hourCycle', true);
    };

    const handleMinutesChange: TimeListProps['onChange'] = (newMinutes) => {
        changeMinutes(newMinutes);

        if (newMinutes === minutesLabel) {
            return;
        }

        const date = value || new Date();

        date.setMinutes(+newMinutes);

        if (!value) {
            // for ampm '12' is a substitution of '00'
            changeHours(ampm ? '12' : '00');
            ampm && changeHourCycle('AM');
            date.setHours(0);
            date.setSeconds(0);
        }

        onChange(date, 'minutes', !ampm);
    };

    const handleHoursChange: TimeListProps['onChange'] = (newHours) => {
        changeHours(newHours);

        if (newHours === hoursLabel) {
            return;
        }

        const date = value || new Date();

        date.setHours(convertHoursLabelToHours(newHours, hourCycleLabel));

        const adjustedToMinDateMinutes = maybeAdjustMinutesToCompareDate(date, minDate, true);

        if (!value) {
            changeMinutes(convertToLabel(adjustedToMinDateMinutes || 0));
            ampm && changeHourCycle('AM');
            date.setMinutes(adjustedToMinDateMinutes || 0);
            date.setSeconds(0);
        } else {
            const adjustedToMaxDateMinutes = maybeAdjustMinutesToCompareDate(date, maxDate);

            if (typeof adjustedToMaxDateMinutes === 'number') {
                changeMinutes(convertToLabel(adjustedToMaxDateMinutes));
                date.setMinutes(adjustedToMaxDateMinutes);
            } else if (typeof adjustedToMinDateMinutes === 'number') {
                changeMinutes(convertToLabel(adjustedToMinDateMinutes));
                date.setMinutes(adjustedToMinDateMinutes);
            }
        }

        onChange(date, 'hours', false);
    };

    return {
        hoursListProps: {
            ref: hoursListRef,
            value: hoursLabel,
            values: hours,
            onChange: handleHoursChange,
        },
        minutesListProps: {
            ref: minutesListRef,
            value: minutesLabel,
            values: minutes,
            onChange: handleMinutesChange,
        },
        ampmListProps: {
            ref: ampmListRef,
            value: hourCycleLabel,
            values: ampms,
            onChange: handleHourCycleChange,
        },
    };
};
