import { Guid } from '@approvalmax/types';
import { produce } from 'immer';

import { Action, SHOW_INTEGRATION_ERROR_POPUP } from '../../../actions';

export const INTEGRATION_ERROR_POPUP = 'INTEGRATION_ERROR_POPUP';

export interface IntegrationErrorPopupData {
    id: typeof INTEGRATION_ERROR_POPUP;
    companyId: Guid;
}

export default produce((draft: IntegrationErrorPopupData | null, action: Action): IntegrationErrorPopupData | null => {
    switch (action.type) {
        case SHOW_INTEGRATION_ERROR_POPUP:
            return {
                id: INTEGRATION_ERROR_POPUP,
                companyId: action.payload.companyId,
            };

        default:
            return draft;
    }
}, null);
