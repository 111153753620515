import * as common from 'modules/common';
import { immutable, ImmutableObject, merge } from 'modules/immutable';

import {
    Action,
    CLOSE_ACTIVE_POPUP,
    SHOW_ACTIVE_COMPANY_NEW_REQUEST_POPUP,
    SHOW_NEW_REQUEST_POPUP,
} from '../actions/index';

export enum ActivePopup {
    NewRequest = 'NewRequest',
}

export interface NewRequestPopupData {
    activeCompanyId?: string;
}

export interface RequestModuleData {
    activePopup: ActivePopup | null;
    newRequestPopup: NewRequestPopupData | null;
}

const INITIAL_STATE = immutable<RequestModuleData>({
    activePopup: null,
    newRequestPopup: null,
});

export default function (
    state: ImmutableObject<RequestModuleData> = INITIAL_STATE,
    action: Action | common.actions.Action
): ImmutableObject<RequestModuleData> {
    switch (action.type) {
        case SHOW_NEW_REQUEST_POPUP:
            return merge(state, {
                activePopup: ActivePopup.NewRequest,
                newRequestPopup: {},
            });

        case SHOW_ACTIVE_COMPANY_NEW_REQUEST_POPUP:
            return merge(state, {
                activePopup: ActivePopup.NewRequest,
                newRequestPopup: {
                    activeCompanyId: action.payload.activeCompanyId,
                },
            });

        case CLOSE_ACTIVE_POPUP:
            return merge(state, {
                activePopup: null,
                newRequestPopup: null,
            });

        default:
            return state;
    }
}
