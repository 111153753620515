import { domain } from 'modules/data';

export const isOcrEnabledForTemplate = (
    companyBetaFeatures: domain.CompanyBetaFeature[],
    companyLicenseFeatures: domain.CompanyLicenseFeature[],
    templateIntegrationCode?: domain.IntegrationCode | null
) => {
    if (
        templateIntegrationCode === domain.IntegrationCode.NetSuiteBill &&
        companyBetaFeatures.includes(domain.CompanyBetaFeature.CaptureNetSuiteBill)
    ) {
        return true;
    }

    if (
        templateIntegrationCode === domain.IntegrationCode.XeroBill &&
        (companyBetaFeatures.includes(domain.CompanyBetaFeature.XeroOcr) ||
            companyLicenseFeatures.includes(domain.CompanyLicenseFeature.Capture))
    ) {
        return true;
    }

    if (
        templateIntegrationCode === domain.IntegrationCode.XeroInvoice &&
        companyBetaFeatures.includes(domain.CompanyBetaFeature.CaptureXeroSalesInvoice)
    ) {
        return true;
    }

    if (
        templateIntegrationCode === domain.IntegrationCode.QBooksBill &&
        companyBetaFeatures.includes(domain.CompanyBetaFeature.CaptureQBOBill)
    ) {
        return true;
    }

    return false;
};
