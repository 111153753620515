import { Box, Select } from '@approvalmax/ui/src/components';
import { dataProviders } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';

import { RenderCellOptions } from '../../../../config/matrixDefinitions.shared';
import { items } from './BoolConditionCell.constants';
import { BooleanCellOptions } from './BoolConditionCell.types';

const BoolConditionCell: FC<RenderCellOptions> = memo((props) => {
    const { rule, field, readonly, lineId, condition, onConditionChange } = props;

    const onChange = useCallback(
        (newValue: (typeof items)[number]['id']) => {
            let selectedValue: boolean | null = null;

            switch (newValue) {
                case BooleanCellOptions.Enabled:
                    selectedValue = true;
                    break;

                case BooleanCellOptions.Disabled:
                    selectedValue = false;
                    break;
            }

            const newCondition: domain.BoolCondition = {
                ...condition,
                conditionType: domain.ConditionType.BoolCondition,
                exactConstraintBool: selectedValue,
            };

            onConditionChange(lineId, rule, field, newCondition);
        },
        [condition, lineId, rule, field, onConditionChange]
    );

    const selectedValue = useMemo(() => {
        const exactConstraintBool = 'exactConstraintBool' in condition ? condition.exactConstraintBool : null;

        switch (exactConstraintBool) {
            case true:
                return BooleanCellOptions.Enabled;

            case false:
                return BooleanCellOptions.Disabled;

            default:
                return dataProviders.FieldDataProvider.AnyValue.id;
        }
    }, [condition]);

    return (
        <Box width='172px'>
            <Select
                items={items}
                value={selectedValue}
                onChange={onChange}
                textActivatorColor='midnight70'
                textActivatorHoverColor='blue90'
                openIconOnHover
                itemNameKey='text'
                size='small'
                noInput
                dropdownWidth='max-content'
                disabled={readonly}
            />
        </Box>
    );
});

export default BoolConditionCell;
