import { validators } from '@approvalmax/ui';
import { errorHelpers, intl } from '@approvalmax/utils';
import { constants, selectors } from 'modules/common';
import { backend, domain, State } from 'modules/data';
import { defineMessages } from 'react-intl';
import { isSortCodeValid } from 'shared/helpers';

import {
    getFieldsApprovalPermissionBySystemPurpose,
    getIsExchangeRateInputAvailable,
    getRequestEditMode,
    RequestEditMode,
} from '../requestSelectors';
import { getXeroLineItems, getXeroLineItemsSummary } from './lineItemSelectors';
import { excludeEmptyPersons, getXeroBillBatchPaymentTotalAmount } from './transferSelectors';
import { getXeroContext } from './xeroSelectors';

const { xeroConstants } = constants;

const i18nPrefix = 'requestForm/selectors/xero/validationSelectors';
const messages = defineMessages({
    mandatoryFieldsErrorText: {
        id: `${i18nPrefix}.mandatoryFieldsErrorText`,
        defaultMessage: 'Please fill in mandatory fields.',
    },
    amountMismatchErrorText: {
        id: `${i18nPrefix}.amountMismatchErrorText`,
        defaultMessage:
            "The total amount of the {requestName} doesn't match the total amount of its line items after editing.",
    },
    noTaxIsInvalidErrorText: {
        id: `${i18nPrefix}.noTaxIsInvalidErrorText`,
        defaultMessage: '"No Tax" amounts cannot be set for this request according to the rules.',
    },
    lineItemsValidationErrorText: {
        id: `${i18nPrefix}.lineItemsValidationErrorText`,
        defaultMessage: 'Please fix validation errors.',
    },
    attachmentRequiredErrorText: {
        id: `${i18nPrefix}.attachmentRequiredErrorText`,
        defaultMessage: 'At least one attachment is mandatory.',
    },
    poNegativeAmountErrorText: {
        id: `${i18nPrefix}.poNegativeAmountErrorText`,
        defaultMessage: 'The total amount of the Purchase Order cannot be negative.',
    },
    invoiceNegativeAmountErrorText: {
        id: `${i18nPrefix}.invoiceNegativeAmountErrorText`,
        defaultMessage: 'The total amount of the Sales Invoice cannot be negative.',
    },
    quoteNegativeAmountErrorText: {
        id: `${i18nPrefix}.quoteNegativeAmountErrorText`,
        defaultMessage: 'The total amount of the Quote cannot be negative.',
    },
    billNegativeAmountErrorText: {
        id: `${i18nPrefix}.billNegativeAmountErrorText`,
        defaultMessage: 'The total amount of the Bill cannot be negative.',
    },
    referencePOLengthErrorText: {
        id: `${i18nPrefix}.referencePOLengthErrorText`,
        defaultMessage: 'Reference Number cannot be longer than 4000 characters.',
    },
    numberQuoteLengthErrorText: {
        id: `${i18nPrefix}.numberQuoteLengthErrorText`,
        defaultMessage: 'Quote Number cannot be longer than 100 characters.',
    },
    titleQuoteLengthErrorText: {
        id: `${i18nPrefix}.titleQuoteLengthErrorText`,
        defaultMessage: 'Quote Title cannot be longer than 100 characters.',
    },
    summaryQuoteLengthErrorText: {
        id: `${i18nPrefix}.summaryQuoteLengthErrorText`,
        defaultMessage: 'Quote Summary cannot be longer than 3000 characters.',
    },
    referenceBillLengthErrorText: {
        id: `${i18nPrefix}.referenceBillLengthErrorText`,
        defaultMessage: 'Reference Number cannot be longer than 255 characters.',
    },
    numberInvoiceLengthErrorText: {
        id: `${i18nPrefix}.numberInvoiceLengthErrorText`,
        defaultMessage: 'Invoice Number cannot be longer than 255 characters.',
    },
    deliveryAddressLengthErrorText: {
        id: `${i18nPrefix}.deliveryAddressLengthErrorText`,
        defaultMessage: 'Delivery Address cannot be longer than 3000 characters.',
    },
    deliveryPhoneLengthErrorText: {
        id: `${i18nPrefix}.deliveryPhoneLengthErrorText`,
        defaultMessage: 'Telephone cannot be longer than 80 characters.',
    },
    deliveryPhoneInvalidErrorText: {
        id: `${i18nPrefix}.deliveryPhoneInvalidErrorText`,
        defaultMessage: 'Phone format invalid.',
    },
    deliveryAttentionToLengthErrorText: {
        id: `${i18nPrefix}.deliveryAttentionToLengthErrorText`,
        defaultMessage: 'Attention To cannot be longer than 255 characters.',
    },
    deliveryInstructionsLengthErrorText: {
        id: `${i18nPrefix}.deliveryInstructionsLengthErrorText`,
        defaultMessage: 'Delivery instructions cannot be longer than 500 characters.',
    },
    exceededMaxValueAmountErrorText: {
        id: `${i18nPrefix}.exceededMaxValueAmountErrorText`,
        defaultMessage: 'Amount cannot exceed 999,999,999,999.',
    },
    exceededMaxValueTotalAmountErrorText: {
        id: `${i18nPrefix}.exceededMaxValueTotalAmountErrorText`,
        defaultMessage: 'Total amount cannot exceed 999,999,999,999.',
    },
    emptyBrandingThemeErrorText: {
        id: `${i18nPrefix}.emptyBrandingThemeErrorText`,
        defaultMessage: 'Branding Theme cannot be empty.',
    },
    contactLengthErrorText: {
        id: `${i18nPrefix}.contactLengthErrorText`,
        defaultMessage: 'Contact name cannot be longer than 255 characters.',
    },
    firstNameLengthErrorText: {
        id: `${i18nPrefix}.firstNameLengthErrorText`,
        defaultMessage: 'First name cannot be longer than 255 characters.',
    },
    lastNameLengthErrorText: {
        id: `${i18nPrefix}.lastNameLengthErrorText`,
        defaultMessage: 'Last name cannot be longer than 255 characters.',
    },
    emailLengthErrorText: {
        id: `${i18nPrefix}.emailLengthErrorText`,
        defaultMessage: 'Email cannot be longer than 255 characters.',
    },
    phoneCountryCodeLengthErrorText: {
        id: `${i18nPrefix}.phoneCountryCodeLengthErrorText`,
        defaultMessage: 'Country code cannot be longer than 20 characters.',
    },
    phoneAreaCodeLengthErrorText: {
        id: `${i18nPrefix}.phoneAreaCodeLengthErrorText`,
        defaultMessage: 'Area code cannot be longer than 10 characters.',
    },
    phoneNumberLengthErrorText: {
        id: `${i18nPrefix}.phoneNumberLengthErrorText`,
        defaultMessage: 'Phone number cannot be longer than 50 characters.',
    },
    skypeLengthErrorText: {
        id: `${i18nPrefix}.skypeLengthErrorText`,
        defaultMessage: 'Skype cannot be longer than 255 characters.',
    },
    addressAttentionToLengthErrorText: {
        id: `${i18nPrefix}.addressAttentionToLengthErrorText`,
        defaultMessage: 'Attention To cannot be longer than 500 characters.',
    },
    addressAddressLengthErrorText: {
        id: `${i18nPrefix}.addressAddressLengthErrorText`,
        defaultMessage: 'Address cannot be longer than 255 characters.',
    },
    addressCityLengthErrorText: {
        id: `${i18nPrefix}.addressCityLengthErrorText`,
        defaultMessage: 'City/town cannot be longer than 255 characters.',
    },
    addressRegionLengthErrorText: {
        id: `${i18nPrefix}.addressRegionLengthErrorText`,
        defaultMessage: 'State/region cannot be longer than 255 characters.',
    },
    addressPostCodeLengthErrorText: {
        id: `${i18nPrefix}.addressPostCodeLengthErrorText`,
        defaultMessage: 'Postal code/ZIP code cannot be longer than 50 characters.',
    },
    addressCountryLengthErrorText: {
        id: `${i18nPrefix}.addressCountryLengthErrorText`,
        defaultMessage: 'Country cannot be longer than 50 characters.',
    },
    taxNumberLengthErrorText: {
        id: `${i18nPrefix}.taxNumberLengthErrorText`,
        defaultMessage: 'Tax ID number cannot be longer than 15 characters.',
    },
    bankAccountNumberLengthErrorText: {
        id: `${i18nPrefix}.bankAccountNumberLengthErrorText`,
        defaultMessage: 'Bank account number cannot be longer than 50 characters.',
    },
    accountNumberLengthErrorText: {
        id: `${i18nPrefix}.accountNumberLengthErrorText`,
        defaultMessage: 'Account number cannot be longer than 50 characters.',
    },
    batchPaymentReferenceLengthErrorText: {
        id: `${i18nPrefix}.batchPaymentReferenceLengthErrorText`,
        defaultMessage: 'Reference cannot be longer than 12 characters.',
    },
    batchPaymentNarrativeLengthErrorText: {
        id: `${i18nPrefix}.batchPaymentNarrativeLengthErrorText`,
        defaultMessage: 'Narrative cannot be longer than 18 characters.',
    },
    batchPaymentDetailsLengthErrorText: {
        id: `${i18nPrefix}.batchPaymentDetailsLengthErrorText`,
        defaultMessage: 'Details cannot be longer than 18 characters.',
    },
    batchPaymentParticularsLengthErrorText: {
        id: `${i18nPrefix}.batchPaymentParticularsLengthErrorText`,
        defaultMessage: 'Particulars cannot be longer than 12 characters.',
    },
    batchPaymentCodeLengthErrorText: {
        id: `${i18nPrefix}.batchPaymentCodeLengthErrorText`,
        defaultMessage: 'Code cannot be longer than 12 characters.',
    },
    batchPaymentBankAccountLengthErrorText: {
        id: `${i18nPrefix}.batchPaymentBankAccountLengthErrorText`,
        defaultMessage: 'Bank Account cannot be longer than 50 characters.',
    },
    companyRegistrationNumberLengthErrorText: {
        id: `${i18nPrefix}.companyRegistrationNumberLengthErrorText`,
        defaultMessage: 'Company Registration Number cannot be longer than 50 characters.',
    },
    anotherPersonFirstNameLengthErrorText: {
        id: `${i18nPrefix}.anotherPersonFirstNameLengthErrorText`,
        defaultMessage: 'Additional Person First Name cannot exceed 80 characters.',
    },
    anotherPersonLastNameLengthErrorText: {
        id: `${i18nPrefix}.anotherPersonLastNameLengthErrorText`,
        defaultMessage: 'Additional Person Last Name cannot exceed 80 characters.',
    },
    anotherPersonEmailLengthErrorText: {
        id: `${i18nPrefix}.anotherPersonEmailLengthErrorText`,
        defaultMessage: 'Additional Person Email cannot exceed 255 characters.',
    },
    anotherPersonWrongEmailErrorText: {
        id: `${i18nPrefix}.anotherPersonWrongEmailErrorText`,
        defaultMessage: 'Additional Person Email cannot exceed 255 characters.',
    },
    wrongEmail: {
        id: `${i18nPrefix}.wrongEmail`,
        defaultMessage: 'Incorrect email format.',
    },
    emailToSupplierToEmpty: {
        id: `${i18nPrefix}.emailToSupplierToEmpty`,
        defaultMessage: 'Please specify Email to address',
    },
    fillInMandatoryFields: {
        id: `${i18nPrefix}.fillInMandatoryFields`,
        defaultMessage: 'Please fill in the mandatory fields.',
    },
    noBills: {
        id: `${i18nPrefix}.noBills`,
        defaultMessage: 'Please add bills',
    },
    amaxPayBatchPaymentReferenceLengthErrorText: {
        id: `${i18nPrefix}.amaxPayBatchPaymentReferenceLengthErrorText`,
        defaultMessage: `Reference cannot be longer than {maxLength} characters.`,
    },
    nonExistCurrencyErrorText: {
        id: `${i18nPrefix}.nonExistCurrencyErrorText`,
        defaultMessage: 'Bill currency doesn’t exist in Xero',
    },
});

const validateXeroPurchaseOrder = (state: State, request: domain.XeroPoRequest): string[] => {
    let errors: string[] = [];

    const xeroContext = getXeroContext(state);
    const d = request.details;
    const lineItems = getXeroLineItems(state, request);
    const editMode = getRequestEditMode(state, request);
    const summary = getXeroLineItemsSummary(state, request);
    const isExchangeRateInputAvailable = getIsExchangeRateInputAvailable(request);
    const isEmailToVendorEnabled =
        xeroContext?.supplierEmailSettings?.state !== domain.TemplateSettingsEmailToSupplierState.Disabled;
    const exchangeRateValue =
        request.exchangeRateSource === domain.ExchangeRateSource.Manual ? request.exchangeRate : null;

    const [
        deliveryDateFieldPermission,
        referenceFieldPermission,
        deliveryAddressFieldPermission,
        emailToSupplierFieldPermission,
        attachmentFieldPermission,
        noteForApproversFieldPermission,
        exchangeRateFieldPermission,
    ] = getFieldsApprovalPermissionBySystemPurpose(state, request, [
        domain.FieldSystemPurpose.XeroDeliveryDate,
        domain.FieldSystemPurpose.XeroReference,
        domain.FieldSystemPurpose.XeroDeliveryAddress,
        domain.FieldSystemPurpose.EmailToSupplier,
        domain.FieldSystemPurpose.XeroAttachment,
        domain.FieldSystemPurpose.NoteForApprovers,
        domain.FieldSystemPurpose.XeroManualExchangeRate,
    ]);

    const validRequiredFields = Boolean(
        d.contact &&
            d.date &&
            (deliveryDateFieldPermission.required ? d.deliveryDate : true) &&
            (referenceFieldPermission.required ? d.reference : true) &&
            (deliveryAddressFieldPermission.required ? d.delivery.address : true) &&
            (emailToSupplierFieldPermission.required && isEmailToVendorEnabled ? d.sendToSupplier : true) &&
            (noteForApproversFieldPermission.required ? request.requestNote.value : true) &&
            (isExchangeRateInputAvailable && exchangeRateFieldPermission.required ? exchangeRateValue : true)
    );

    if (attachmentFieldPermission.required && request.attachments.length === 0) {
        errors.push(intl.formatMessage(messages.attachmentRequiredErrorText));
    }

    if (lineItems.some((li) => li.invalidAccount || li.invalidTrackingCategories.length > 0)) {
        errors.push(intl.formatMessage(messages.lineItemsValidationErrorText));
    }

    if (!d.brandingTheme) {
        errors.push(intl.formatMessage(messages.emptyBrandingThemeErrorText));
    }

    const validLineItems = lineItems.some((li) => !li.empty) && lineItems.every((li) => li.valid);

    lineItems.forEach((li) => {
        if (li.invalidAccountTaxMatch) {
            errors.push(li.invalidAccountTaxMatchText!);
        }
    });

    if (lineItems.some((li) => typeof li.computedAmount === 'number' && li.computedAmount > xeroConstants.MAX_AMOUNT)) {
        errors.push(intl.formatMessage(messages.exceededMaxValueAmountErrorText));
    }

    // Email to supplier section
    let validEmailToSupplierTo = true;
    let validEmailToSupplierBody = true;
    let validEmailToSupplierSubject = true;

    if (d.sendToSupplier) {
        validEmailToSupplierTo = Boolean(d.emailToSupplier && d.emailToSupplier.to.length > 0);
        validEmailToSupplierBody = Boolean(d.emailToSupplier && d.emailToSupplier.body);
        validEmailToSupplierSubject = Boolean(d.emailToSupplier && d.emailToSupplier.subject);
    }

    if (d.lineAmountType === domain.LineAmountType.NoTax && xeroContext.noTaxIsInvalid) {
        errors.push(intl.formatMessage(messages.noTaxIsInvalidErrorText));
    }

    if (d.reference && d.reference.length > xeroConstants.PO_REFERENCE_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.referencePOLengthErrorText));
    }

    if (d.delivery.address && d.delivery.address.length > xeroConstants.DELIVERY_ADDRESS_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.deliveryAddressLengthErrorText));
    }

    if (d.delivery.phone && d.delivery.phone.length > xeroConstants.DELIVERY_PHONE_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.deliveryPhoneLengthErrorText));
    }

    if (d.delivery.phone && !selectors.ui.isValidPhone(d.delivery.phone)) {
        errors.push(intl.formatMessage(messages.deliveryPhoneInvalidErrorText));
    }

    if (d.delivery.attentionTo && d.delivery.attentionTo.length > xeroConstants.DELIVERY_ATTENTION_TO_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.deliveryAttentionToLengthErrorText));
    }

    if (d.delivery.instructions && d.delivery.instructions.length > xeroConstants.DELIVERY_INSTRUCTIONS_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.deliveryInstructionsLengthErrorText));
    }

    if (!validRequiredFields || !validLineItems || !validEmailToSupplierBody || !validEmailToSupplierSubject) {
        errors.push(intl.formatMessage(messages.mandatoryFieldsErrorText));
    } else if (!validEmailToSupplierTo) {
        errors.push(intl.formatMessage(messages.emailToSupplierToEmpty));
    }

    if (summary.totalAmount < xeroConstants.TOTAL_MIN_AMOUNT) {
        errors.push(intl.formatMessage(messages.poNegativeAmountErrorText));
    }

    if (summary.totalAmount > xeroConstants.TOTAL_MAX_AMOUNT) {
        errors.push(intl.formatMessage(messages.exceededMaxValueTotalAmountErrorText));
    }

    if (editMode === RequestEditMode.Approver) {
        const totalAmountMatches = request.amount === getXeroLineItemsSummary(state, request).totalAmount;
        const requestName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);

        if (!totalAmountMatches) {
            errors.push(intl.formatMessage(messages.amountMismatchErrorText, { requestName }));
        }
    }

    return errors;
};

const validateXeroSalesInvoice = (state: State, request: domain.XeroInvoiceRequest): string[] => {
    const [
        referenceFieldPermission,
        emailToSupplierFieldPermission,
        attachmentFieldPermission,
        noteForApproversFieldPermission,
        exchangeRateFieldPermission,
    ] = getFieldsApprovalPermissionBySystemPurpose(state, request, [
        domain.FieldSystemPurpose.XeroReference,
        domain.FieldSystemPurpose.EmailToSupplier,
        domain.FieldSystemPurpose.XeroAttachment,
        domain.FieldSystemPurpose.NoteForApprovers,
        domain.FieldSystemPurpose.XeroManualExchangeRate,
    ]);

    const errors: string[] = [];

    const details = request.details;
    const lineItems = getXeroLineItems(state, request);
    const xeroContext = getXeroContext(state);
    const editMode = getRequestEditMode(state, request);
    const summary = getXeroLineItemsSummary(state, request);
    const isExchangeRateInputAvailable = getIsExchangeRateInputAvailable(request);
    const isEmailToVendorEnabled =
        xeroContext?.supplierEmailSettings?.state !== domain.TemplateSettingsEmailToSupplierState.Disabled;
    const exchangeRateValue =
        request.exchangeRateSource === domain.ExchangeRateSource.Manual ? request.exchangeRate : null;

    const validRequiredFields = Boolean(
        details.contact &&
            details.date &&
            details.dueDate &&
            (referenceFieldPermission.required ? details.reference : true) &&
            (emailToSupplierFieldPermission.required && isEmailToVendorEnabled ? details.sendToSupplier : true) &&
            (noteForApproversFieldPermission.required ? request.requestNote.value : true) &&
            (isExchangeRateInputAvailable && exchangeRateFieldPermission.required ? exchangeRateValue : true)
    );

    if (attachmentFieldPermission.required && request.attachments.length === 0) {
        errors.push(intl.formatMessage(messages.attachmentRequiredErrorText));
    }

    if (lineItems.some((li) => li.invalidAccount || li.invalidTrackingCategories.length > 0)) {
        errors.push(intl.formatMessage(messages.lineItemsValidationErrorText));
    }

    if (!details.brandingTheme) {
        errors.push(intl.formatMessage(messages.emptyBrandingThemeErrorText));
    }

    let validLineItems = lineItems.some((li) => !li.empty) && lineItems.every((li) => li.valid);

    lineItems.forEach((li) => {
        if (li.invalidAccountTaxMatch) {
            errors.push(li.invalidAccountTaxMatchText!);
        }
    });

    if (lineItems.some((li) => typeof li.computedAmount === 'number' && li.computedAmount > xeroConstants.MAX_AMOUNT)) {
        errors.push(intl.formatMessage(messages.exceededMaxValueAmountErrorText));
    }

    // Email to supplier section
    let validEmailToSupplierTo = true;
    let validEmailToSupplierBody = true;
    let validEmailToSupplierSubject = true;

    if (details.sendToSupplier) {
        validEmailToSupplierTo = Boolean(details.emailToSupplier && details.emailToSupplier.to.length);
        validEmailToSupplierBody = Boolean(details.emailToSupplier && details.emailToSupplier.body);
        validEmailToSupplierSubject = Boolean(details.emailToSupplier && details.emailToSupplier.subject);
    }

    if (details.lineAmountType === domain.LineAmountType.NoTax && xeroContext.noTaxIsInvalid) {
        errors.push(intl.formatMessage(messages.noTaxIsInvalidErrorText));
    }

    if (details.reference && details.reference.length > xeroConstants.INVOICE_REFERENCE_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.referenceBillLengthErrorText));
    }

    if (details.number && details.number.length > xeroConstants.INVOICE_NUMBER_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.numberInvoiceLengthErrorText));
    }

    if (!validRequiredFields || !validLineItems || !validEmailToSupplierBody || !validEmailToSupplierSubject) {
        errors.push(intl.formatMessage(messages.mandatoryFieldsErrorText));
    } else if (!validEmailToSupplierTo) {
        errors.push(intl.formatMessage(messages.emailToSupplierToEmpty));
    }

    if (summary.totalAmount < xeroConstants.TOTAL_MIN_AMOUNT) {
        errors.push(intl.formatMessage(messages.invoiceNegativeAmountErrorText));
    }

    if (summary.totalAmount > xeroConstants.TOTAL_MAX_AMOUNT) {
        errors.push(intl.formatMessage(messages.exceededMaxValueTotalAmountErrorText));
    }

    if (editMode === RequestEditMode.Approver) {
        const requestName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);
        const totalAmountMatches = request.amount === getXeroLineItemsSummary(state, request).totalAmount;

        if (!totalAmountMatches) {
            errors.push(intl.formatMessage(messages.amountMismatchErrorText, { requestName }));
        }
    }

    return errors;
};

const validateXeroQuote = (state: State, request: domain.XeroQuoteRequest): string[] => {
    const [
        expiryFieldPermission,
        numberFieldPermission,
        referenceFieldPermission,
        titleFieldPermission,
        summaryFieldPermission,
        exchangeRateFieldPermission,
        noteForApproversFieldPermission,
        termsFieldPermission,
        attachmentFieldPermission,
        emailToSupplierFieldPermission,
    ] = getFieldsApprovalPermissionBySystemPurpose(state, request, [
        domain.FieldSystemPurpose.XeroQuoteExpiry,
        domain.FieldSystemPurpose.XeroQuoteNumber,
        domain.FieldSystemPurpose.XeroReference,
        domain.FieldSystemPurpose.XeroQuoteTitle,
        domain.FieldSystemPurpose.XeroQuoteSummary,
        domain.FieldSystemPurpose.XeroManualExchangeRate,
        domain.FieldSystemPurpose.NoteForApprovers,
        domain.FieldSystemPurpose.XeroQuoteTerms,
        domain.FieldSystemPurpose.XeroAttachment,
        domain.FieldSystemPurpose.EmailToSupplier,
    ]);

    const errors: string[] = [];

    const details = request.details;
    const lineItems = getXeroLineItems(state, request);
    const summary = getXeroLineItemsSummary(state, request);
    const xeroContext = getXeroContext(state);
    const editMode = getRequestEditMode(state, request);
    const isExchangeRateInputAvailable = getIsExchangeRateInputAvailable(request);
    const isEmailToVendorEnabled =
        xeroContext?.supplierEmailSettings?.state !== domain.TemplateSettingsEmailToSupplierState.Disabled;
    const exchangeRateValue =
        request.exchangeRateSource === domain.ExchangeRateSource.Manual ? request.exchangeRate : null;

    const validRequiredFields = Boolean(
        details.contact &&
            details.date &&
            (expiryFieldPermission.required ? details.expiryDate : true) &&
            (numberFieldPermission.required ? details.number : true) &&
            (referenceFieldPermission.required ? details.reference : true) &&
            (titleFieldPermission.required ? details.title : true) &&
            (summaryFieldPermission.required ? details.summary : true) &&
            (noteForApproversFieldPermission.required ? request.requestNote.value : true) &&
            (termsFieldPermission.required ? details.terms : true) &&
            (emailToSupplierFieldPermission.required && isEmailToVendorEnabled ? details.sendToSupplier : true) &&
            (isExchangeRateInputAvailable && exchangeRateFieldPermission.required ? exchangeRateValue : true)
    );

    if (attachmentFieldPermission.required && request.attachments.length === 0) {
        errors.push(intl.formatMessage(messages.attachmentRequiredErrorText));
    }

    if (lineItems.some((li) => li.invalidAccount || li.invalidTrackingCategories.length > 0)) {
        errors.push(intl.formatMessage(messages.lineItemsValidationErrorText));
    }

    if (!details.brandingTheme) {
        errors.push(intl.formatMessage(messages.emptyBrandingThemeErrorText));
    }

    const validLineItems = lineItems.some((li) => !li.empty) && lineItems.every((li) => li.valid);

    lineItems.forEach((li) => {
        if (li.invalidAccountTaxMatch) {
            errors.push(li.invalidAccountTaxMatchText!);
        }
    });

    if (lineItems.some((li) => typeof li.computedAmount === 'number' && li.computedAmount > xeroConstants.MAX_AMOUNT)) {
        errors.push(intl.formatMessage(messages.exceededMaxValueAmountErrorText));
    }

    // Email to supplier section
    let validEmailToSupplierTo = true;
    let validEmailToSupplierBody = true;
    let validEmailToSupplierSubject = true;

    if (details.sendToSupplier) {
        validEmailToSupplierTo = Boolean(details.emailToSupplier && details.emailToSupplier.to.length);
        validEmailToSupplierBody = Boolean(details.emailToSupplier && details.emailToSupplier.body);
        validEmailToSupplierSubject = Boolean(details.emailToSupplier && details.emailToSupplier.subject);
    }

    if (details.lineAmountType === domain.LineAmountType.NoTax && xeroContext.noTaxIsInvalid) {
        errors.push(intl.formatMessage(messages.noTaxIsInvalidErrorText));
    }

    if (details.reference && details.reference.length > xeroConstants.PO_REFERENCE_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.referencePOLengthErrorText));
    }

    if (details.number && details.number.length > xeroConstants.QUOTE_NUMBER_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.numberQuoteLengthErrorText));
    }

    if (details.title && details.title.length > xeroConstants.TITLE_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.titleQuoteLengthErrorText));
    }

    if (details.summary && details.summary.length > xeroConstants.SUMMARY_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.summaryQuoteLengthErrorText));
    }

    if (!validRequiredFields || !validLineItems || !validEmailToSupplierBody || !validEmailToSupplierSubject) {
        errors.push(intl.formatMessage(messages.mandatoryFieldsErrorText));
    } else if (!validEmailToSupplierTo) {
        errors.push(intl.formatMessage(messages.emailToSupplierToEmpty));
    }

    if (summary.totalAmount < xeroConstants.TOTAL_MIN_AMOUNT) {
        errors.push(intl.formatMessage(messages.quoteNegativeAmountErrorText));
    }

    if (summary.totalAmount > xeroConstants.TOTAL_MAX_AMOUNT) {
        errors.push(intl.formatMessage(messages.exceededMaxValueTotalAmountErrorText));
    }

    if (editMode === RequestEditMode.Approver) {
        const requestName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);
        const totalAmountMatches = request.amount === getXeroLineItemsSummary(state, request).totalAmount;

        if (!totalAmountMatches) {
            errors.push(intl.formatMessage(messages.amountMismatchErrorText, { requestName }));
        }
    }

    return errors;
};

const validateXeroBill = (state: State, request: domain.XeroBillRequest) => {
    const [
        exchangeRateFieldPermission,
        referenceFieldPermission,
        noteForApproversFieldPermission,
        attachmentFieldPermission,
    ] = getFieldsApprovalPermissionBySystemPurpose(state, request, [
        domain.FieldSystemPurpose.XeroManualExchangeRate,
        domain.FieldSystemPurpose.XeroReference,
        domain.FieldSystemPurpose.NoteForApprovers,
        domain.FieldSystemPurpose.XeroAttachment,
    ]);

    let errors: string[] = [];

    const d = request.details;
    const lineItems = getXeroLineItems(state, request);
    const summary = getXeroLineItemsSummary(state, request);
    const editMode = getRequestEditMode(state, request);
    const isExchangeRateInputAvailable = getIsExchangeRateInputAvailable(request);
    const exchangeRateValue =
        request.exchangeRateSource === domain.ExchangeRateSource.Manual ? request.exchangeRate : null;

    const validRequiredFields = Boolean(
        d.contact &&
            d.date &&
            d.dueDate &&
            (referenceFieldPermission.required ? d.reference : true) &&
            (noteForApproversFieldPermission.required ? request.requestNote.value : true) &&
            (isExchangeRateInputAvailable && exchangeRateFieldPermission.required ? exchangeRateValue : true)
    );

    if (attachmentFieldPermission.required && request.attachments.length === 0) {
        errors.push(intl.formatMessage(messages.attachmentRequiredErrorText));
    }

    if (lineItems.some((li) => li.invalidAccount || li.invalidTrackingCategories.length > 0)) {
        errors.push(intl.formatMessage(messages.lineItemsValidationErrorText));
    }

    const validLineItems =
        lineItems.some((li) => !li.empty) && lineItems.filter((li) => !li.emptyLike).every((li) => li.valid);

    lineItems.forEach((li) => {
        if (li.invalidAccountTaxMatch) {
            errors.push(li.invalidAccountTaxMatchText!);
        }
    });

    if (lineItems.some((li) => typeof li.computedAmount === 'number' && li.computedAmount > 999999999999.99)) {
        errors.push(intl.formatMessage(messages.exceededMaxValueAmountErrorText));
    }

    const xeroContext = getXeroContext(state);

    if (xeroContext.currencies.every((currency) => currency.id !== request.currency)) {
        errors.push(intl.formatMessage(messages.nonExistCurrencyErrorText));
    }

    if (d.lineAmountType === domain.LineAmountType.NoTax && xeroContext.noTaxIsInvalid) {
        errors.push(intl.formatMessage(messages.noTaxIsInvalidErrorText));
    }

    if (!validRequiredFields || !validLineItems) {
        // Assumption: line items are invalid => mandatory fields error
        errors.push(intl.formatMessage(messages.mandatoryFieldsErrorText));
    }

    if (summary.totalAmount < xeroConstants.TOTAL_MIN_AMOUNT) {
        errors.push(intl.formatMessage(messages.billNegativeAmountErrorText));
    }

    if (summary.totalAmount > xeroConstants.TOTAL_MAX_AMOUNT) {
        errors.push(intl.formatMessage(messages.exceededMaxValueTotalAmountErrorText));
    }

    if (editMode === RequestEditMode.Editor || editMode === RequestEditMode.Approver) {
        const totalAmountMatches = request.amount === getXeroLineItemsSummary(state, request).totalAmount;
        const requestName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);

        if (!totalAmountMatches) {
            errors.push(intl.formatMessage(messages.amountMismatchErrorText, { requestName }));
        }
    }

    if (d.reference && d.reference.length > xeroConstants.INVOICE_REFERENCE_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.referenceBillLengthErrorText));
    }

    return errors;
};

export function isValidXeroContactName(contactName: string) {
    const regexp = new RegExp('[<>]|(&#)');

    return !regexp.test(contactName);
}

const validateXeroContact = (state: State, request: domain.XeroContactRequest) => {
    let errors: Set<string> = new Set();

    const details = request.details;

    if (!details.name?.trim()) {
        errors.add(
            intl.formatMessage({
                id: `${i18nPrefix}.contactNameIsEmpty`,
                defaultMessage: 'Please, fill the contact name field.',
            })
        );
    }

    if (details.name && !isValidXeroContactName(details.name)) {
        errors.add(
            intl.formatMessage({
                id: `${i18nPrefix}.invalidContactName`,
                defaultMessage: 'Contact name cannot contain the following symbols: "<". ">", "&#".',
            })
        );
    }

    if (details.name && /\p{Extended_Pictographic}/u.test(details.name)) {
        errors.add(
            intl.formatMessage({
                id: `${i18nPrefix}.noEmojiInContactName`,
                defaultMessage: 'Emojis are not allowed in Contact name',
            })
        );
    }

    if (details.name && details.name.length > xeroConstants.CONTACT_NAME_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.contactLengthErrorText));
    }

    if (details.firstName && details.firstName.length > xeroConstants.FIRST_NAME_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.firstNameLengthErrorText));
    }

    if (details.lastName && details.lastName.length > xeroConstants.LAST_NAME_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.lastNameLengthErrorText));
    }

    if (details.emailAddress && details.emailAddress.length > xeroConstants.EMAIL_ADDRESS_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.emailLengthErrorText));
    }

    if (details.skypeName && details.skypeName.length > xeroConstants.SKYPE_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.skypeLengthErrorText));
    }

    if (
        details.bankAccountDetails &&
        details.bankAccountDetails.length > xeroConstants.BANK_ACCOUNT_DETAILS_MAX_LENGTH
    ) {
        errors.add(intl.formatMessage(messages.bankAccountNumberLengthErrorText));
    }

    if (details.taxNumber && details.taxNumber.length > xeroConstants.TAX_NUMBER_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.taxNumberLengthErrorText));
    }

    if (details.accountNumber && details.accountNumber.length > xeroConstants.ACCOUNT_NUMBER_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.accountNumberLengthErrorText));
    }

    if (
        details.phoneNumber?.areaCode &&
        details.phoneNumber?.areaCode.length > xeroConstants.PHONE_AREA_CODE_MAX_LENGTH
    ) {
        errors.add(intl.formatMessage(messages.phoneAreaCodeLengthErrorText));
    }

    if (
        details.phoneNumber?.countryCode &&
        details.phoneNumber?.countryCode.length > xeroConstants.PHONE_COUNTRY_CODE_MAX_LENGTH
    ) {
        errors.add(intl.formatMessage(messages.phoneCountryCodeLengthErrorText));
    }

    if (details.phoneNumber?.number && details.phoneNumber?.number.length > xeroConstants.PHONE_NUMBER_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.phoneNumberLengthErrorText));
    }

    if (details.mobile?.areaCode && details.mobile?.areaCode.length > xeroConstants.PHONE_AREA_CODE_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.phoneAreaCodeLengthErrorText));
    }

    if (
        details.mobile?.countryCode &&
        details.mobile?.countryCode.length > xeroConstants.PHONE_COUNTRY_CODE_MAX_LENGTH
    ) {
        errors.add(intl.formatMessage(messages.phoneCountryCodeLengthErrorText));
    }

    if (details.mobile?.number && details.mobile?.number.length > xeroConstants.PHONE_NUMBER_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.phoneNumberLengthErrorText));
    }

    if (details.fax?.areaCode && details.fax?.areaCode.length > xeroConstants.PHONE_AREA_CODE_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.phoneAreaCodeLengthErrorText));
    }

    if (details.fax?.countryCode && details.fax?.countryCode.length > xeroConstants.PHONE_COUNTRY_CODE_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.phoneCountryCodeLengthErrorText));
    }

    if (details.fax?.number && details.fax?.number.length > xeroConstants.PHONE_NUMBER_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.phoneNumberLengthErrorText));
    }

    if (details.directDial?.number && details.directDial?.number.length > xeroConstants.PHONE_NUMBER_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.phoneNumberLengthErrorText));
    }

    if (details.directDial?.number && details.directDial?.number.length > xeroConstants.PHONE_NUMBER_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.phoneNumberLengthErrorText));
    }

    if (details.directDial?.number && details.directDial?.number.length > xeroConstants.PHONE_NUMBER_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.phoneNumberLengthErrorText));
    }

    if (details.postalAddress?.address && details.postalAddress?.address.length > xeroConstants.ADDRESS_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.addressAddressLengthErrorText));
    }

    if (
        details.postalAddress?.postalCode &&
        details.postalAddress?.postalCode.length > xeroConstants.ADDRESS_POST_CODE_MAX_LENGTH
    ) {
        errors.add(intl.formatMessage(messages.addressPostCodeLengthErrorText));
    }

    if (
        details.postalAddress?.attentionTo &&
        details.postalAddress?.attentionTo.length > xeroConstants.ADDRESS_ATTENTION_TO_MAX_LENGTH
    ) {
        errors.add(intl.formatMessage(messages.addressAttentionToLengthErrorText));
    }

    if (details.postalAddress?.city && details.postalAddress?.city.length > xeroConstants.ADDRESS_CITY_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.addressCityLengthErrorText));
    }

    if (
        details.postalAddress?.country &&
        details.postalAddress?.country.length > xeroConstants.ADDRESS_COUNTRY_MAX_LENGTH
    ) {
        errors.add(intl.formatMessage(messages.addressCountryLengthErrorText));
    }

    if (
        details.postalAddress?.region &&
        details.postalAddress?.region.length > xeroConstants.ADDRESS_REGION_MAX_LENGTH
    ) {
        errors.add(intl.formatMessage(messages.addressRegionLengthErrorText));
    }

    if (details.mailingAddress?.address && details.mailingAddress?.address.length > xeroConstants.ADDRESS_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.addressAddressLengthErrorText));
    }

    if (
        details.mailingAddress?.postalCode &&
        details.mailingAddress?.postalCode.length > xeroConstants.ADDRESS_POST_CODE_MAX_LENGTH
    ) {
        errors.add(intl.formatMessage(messages.addressPostCodeLengthErrorText));
    }

    if (
        details.mailingAddress?.attentionTo &&
        details.mailingAddress?.attentionTo.length > xeroConstants.ADDRESS_ATTENTION_TO_MAX_LENGTH
    ) {
        errors.add(intl.formatMessage(messages.addressAttentionToLengthErrorText));
    }

    if (details.mailingAddress?.city && details.mailingAddress?.city.length > xeroConstants.ADDRESS_CITY_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.addressCityLengthErrorText));
    }

    if (
        details.mailingAddress?.country &&
        details.mailingAddress?.country.length > xeroConstants.ADDRESS_COUNTRY_MAX_LENGTH
    ) {
        errors.add(intl.formatMessage(messages.addressCountryLengthErrorText));
    }

    if (
        details.mailingAddress?.region &&
        details.mailingAddress?.region.length > xeroConstants.ADDRESS_REGION_MAX_LENGTH
    ) {
        errors.add(intl.formatMessage(messages.addressRegionLengthErrorText));
    }

    if (details.companyNumber && details.companyNumber.length > xeroConstants.COMPANY_NUMBER_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.companyRegistrationNumberLengthErrorText));
    }

    details.persons.forEach((person) => {
        if (person.firstName && person.firstName.length > xeroConstants.ANOTHER_PERSON_FIRST_NAME_MAX_LENGTH) {
            errors.add(intl.formatMessage(messages.anotherPersonFirstNameLengthErrorText));
        }

        if (person.lastName && person.lastName.length > xeroConstants.ANOTHER_PERSON_LAST_NAME_MAX_LENGTH) {
            errors.add(intl.formatMessage(messages.anotherPersonLastNameLengthErrorText));
        }

        if (person.emailAddress && person.emailAddress.length > xeroConstants.EMAIL_ADDRESS_MAX_LENGTH) {
            errors.add(intl.formatMessage(messages.anotherPersonEmailLengthErrorText));
        }

        if (person.emailAddress && !validators.isEmail(person.emailAddress)) {
            errors.add(intl.formatMessage(messages.wrongEmail));
        }
    });

    const personsIsNotEmpty = details.persons.filter(excludeEmptyPersons).length > 0;

    if (personsIsNotEmpty && !details.emailAddress) {
        errors.add(
            intl.formatMessage({
                id: `${i18nPrefix}.emailAddressIsEmpty`,
                defaultMessage: 'Additional people cannot be added when the primary person has no email address set.',
            })
        );
    }

    const hasPersonWithNotValidEmail = details.persons.some(
        (person) => person.emailAddress && !validators.isEmail(person.emailAddress)
    );

    if ((details.emailAddress && !validators.isEmail(details.emailAddress)) || hasPersonWithNotValidEmail) {
        errors.add(intl.formatMessage(messages.wrongEmail));
    }

    if (details.sortCode && !isSortCodeValid(details.sortCode)) {
        errors.add(
            intl.formatMessage(
                {
                    id: `${i18nPrefix}.sortCodeError`,
                    defaultMessage: 'Sort code should be {length} digits.',
                },
                {
                    length: xeroConstants.sortCodeMaxLength,
                }
            )
        );
    }

    if (details.sortCode && (!details.bankAccountDetails || !/^\d{8}$/.test(details.bankAccountDetails))) {
        errors.add(
            intl.formatMessage({
                id: `${i18nPrefix}.bankAccountWithSortCodeError`,
                defaultMessage: 'Bank account number should be 8 digits.',
            })
        );
    }

    return [...errors];
};

const validateXeroBillBatchPayment = (state: State, request: domain.XeroBillBatchPaymentRequest) => {
    const details = request.details;
    const amount = getXeroBillBatchPaymentTotalAmount(details.items);

    const company = selectors.company.getCompanyById(state, request.companyId);

    const referenceFieldMaxLength =
        company.integration?.countryCode === backend.OrganisationVersion.AU
            ? xeroConstants.BATCH_PAYMENT_REFERENCE_AU_MAX_LENGTH
            : xeroConstants.BATCH_PAYMENT_REFERENCE_DEFAULT_MAX_LENGTH;

    let errors: Set<string> = new Set([]);

    if (details.items.some((item) => !item.amount)) {
        errors.add(
            intl.formatMessage({
                id: `${i18nPrefix}.itemWithEmptyAmount`,
                defaultMessage: 'Payment amount can not be equal to 0',
            })
        );
    }

    if (details.workflowIsSupplierBankAccountRequired && details.items.some((item) => !item.bankAccount)) {
        errors.add(
            intl.formatMessage({
                id: `${i18nPrefix}.bankAccountIsEmpty`,
                defaultMessage: 'Please, fill the bank account field',
            })
        );
    }

    if (amount > xeroConstants.TOTAL_MAX_AMOUNT) {
        errors.add(intl.formatMessage(messages.exceededMaxValueTotalAmountErrorText));
    }

    if (!details.paymentDate) {
        errors.add(
            intl.formatMessage({
                id: `${i18nPrefix}.paymentDateIsEmpty`,
                defaultMessage: 'Please, fill the payment date field',
            })
        );
    }

    if (!details.bankAccount) {
        errors.add(
            intl.formatMessage({
                id: `${i18nPrefix}.bankAccountIsEmpty`,
                defaultMessage: 'Please, fill the bank account field',
            })
        );
    }

    if (!details.items.length) {
        errors.add(intl.formatMessage(messages.noBills));
    }

    if (details.reference && details.reference.length > referenceFieldMaxLength) {
        errors.add(intl.formatMessage(messages.batchPaymentReferenceLengthErrorText));
    }

    if (details.narrative && details.narrative.length > xeroConstants.BATCH_PAYMENT_NARRATIVE_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.batchPaymentNarrativeLengthErrorText));
    }

    if (details.details && details.details.length > xeroConstants.BATCH_PAYMENT_DETAILS_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.batchPaymentDetailsLengthErrorText));
    }

    if (details.particulars && details.particulars.length > xeroConstants.BATCH_PAYMENT_PARTICULARS_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.batchPaymentParticularsLengthErrorText));
    }

    if (details.code && details.code.length > xeroConstants.BATCH_PAYMENT_CODE_MAX_LENGTH) {
        errors.add(intl.formatMessage(messages.batchPaymentCodeLengthErrorText));
    }

    details.items.forEach((invoice) => {
        if (invoice.details && invoice.details.length > xeroConstants.BATCH_PAYMENT_DETAILS_MAX_LENGTH) {
            errors.add(intl.formatMessage(messages.batchPaymentDetailsLengthErrorText));
        }

        if (invoice.particulars && invoice.particulars.length > xeroConstants.BATCH_PAYMENT_PARTICULARS_MAX_LENGTH) {
            errors.add(intl.formatMessage(messages.batchPaymentParticularsLengthErrorText));
        }

        if (invoice.code && invoice.code.length > xeroConstants.BATCH_PAYMENT_CODE_MAX_LENGTH) {
            errors.add(intl.formatMessage(messages.batchPaymentCodeLengthErrorText));
        }

        if (invoice.bankAccount && invoice.bankAccount.length > xeroConstants.BATCH_PAYMENT_BANK_ACCOUNT_MAX_LENGTH) {
            errors.add(intl.formatMessage(messages.batchPaymentBankAccountLengthErrorText));
        }
    });

    const workflowAllowEditSupplierBankAccount = request.authorRules.some(
        (rule) => rule.fieldSystemPurpose === domain.FieldSystemPurpose.XeroSupplier && rule.allowEditing
    );

    const hasChangedBankAccount = details.items.some((item) => {
        return item.defaultBankAccount && item.bankAccount !== item.defaultBankAccount;
    });

    if (!workflowAllowEditSupplierBankAccount && hasChangedBankAccount) {
        errors.add(
            intl.formatMessage({
                id: `${i18nPrefix}.forbidModifiedBankAccount`,
                defaultMessage:
                    "Approval workflow rules do not allow you to submit a Xero Batch Payment with the modified supplier's bank account.",
            })
        );
    }

    return [...errors];
};

const validateXeroAmaxPayBatchPayment = (request: domain.XeroAmaxPayBatchPaymentRequest) => {
    const details = request.details;

    const errors: string[] = [];

    if (details.items.length === 0) {
        errors.push(intl.formatMessage(messages.noBills));
    }

    const hasNotFilledRequiredField = details.items.some(
        (invoice) => !(invoice.amount && invoice.paymentDetails && invoice.paymentReference?.trim())
    );

    if (hasNotFilledRequiredField) {
        errors.push(intl.formatMessage(messages.fillInMandatoryFields));
    }

    const hasExceededLengthReference = details.items.some((invoice) => {
        return invoice.paymentReference && invoice.paymentReference.length > xeroConstants.amaxPayBatchPaymentReference;
    });

    if (hasExceededLengthReference) {
        errors.push(
            intl.formatMessage(messages.amaxPayBatchPaymentReferenceLengthErrorText, {
                maxLength: xeroConstants.amaxPayBatchPaymentReference,
            })
        );
    }

    return errors;
};

const validateXeroAirwallexBatchPayment = (state: State, request: domain.XeroAirwallexBatchPaymentRequest) => {
    const details = request.details;

    let errors: Set<string> = new Set([]);

    if (details.items.length === 0) {
        errors.add(intl.formatMessage(messages.noBills));
    }

    const hasNotFilledRequiredField = details.items.some((invoice) => {
        return !(
            invoice.amount &&
            invoice.beneficiary &&
            invoice.beneficiary.paymentMethod &&
            (invoice.beneficiary.paymentMethod.id === domain.AirwallexPaymentMethod.Local || invoice.paymentFee) &&
            invoice.sourceCurrency &&
            invoice.paymentReference &&
            invoice.paymentPurpose &&
            invoice.bankAccount
        );
    });

    if (hasNotFilledRequiredField) {
        errors.add(intl.formatMessage(messages.fillInMandatoryFields));
    }

    return [...errors];
};

const validateCreditNotes = (
    state: State,
    request: domain.XeroCreditNotesPayableRequest | domain.XeroCreditNotesReceivableRequest
) => {
    const [
        exchangeRateFieldPermission,
        referenceFieldPermission,
        noteForApproversFieldPermission,
        attachmentFieldPermission,
    ] = getFieldsApprovalPermissionBySystemPurpose(state, request, [
        domain.FieldSystemPurpose.XeroManualExchangeRate,
        domain.FieldSystemPurpose.XeroReference,
        domain.FieldSystemPurpose.NoteForApprovers,
        domain.FieldSystemPurpose.XeroAttachment,
    ]);

    let errors: string[] = [];

    const d = request.details;
    const lineItems = getXeroLineItems(state, request);
    const summary = getXeroLineItemsSummary(state, request);
    const editMode = getRequestEditMode(state, request);
    const isExchangeRateInputAvailable = getIsExchangeRateInputAvailable(request);
    const exchangeRateValue =
        request.exchangeRateSource === domain.ExchangeRateSource.Manual ? request.exchangeRate : null;

    const validRequiredFields = Boolean(
        d.contact &&
            d.date &&
            (referenceFieldPermission.required ? d.reference : true) &&
            (noteForApproversFieldPermission.required ? request.requestNote.value : true) &&
            (isExchangeRateInputAvailable && exchangeRateFieldPermission.required ? exchangeRateValue : true)
    );

    if (attachmentFieldPermission.required && request.attachments.length === 0) {
        errors.push(intl.formatMessage(messages.attachmentRequiredErrorText));
    }

    if (lineItems.some((li) => li.invalidAccount || li.invalidTrackingCategories.length > 0)) {
        errors.push(intl.formatMessage(messages.lineItemsValidationErrorText));
    }

    const validLineItems =
        lineItems.some((li) => !li.empty) && lineItems.filter((li) => !li.emptyLike).every((li) => li.valid);

    lineItems.forEach((li) => {
        if (li.invalidAccountTaxMatch) {
            errors.push(li.invalidAccountTaxMatchText!);
        }
    });

    if (lineItems.some((li) => typeof li.computedAmount === 'number' && li.computedAmount > 999999999999.99)) {
        errors.push(intl.formatMessage(messages.exceededMaxValueAmountErrorText));
    }

    const xeroContext = getXeroContext(state);

    if (xeroContext.currencies.every((currency) => currency.id !== request.currency)) {
        errors.push(intl.formatMessage(messages.nonExistCurrencyErrorText));
    }

    if (d.lineAmountType === domain.LineAmountType.NoTax && xeroContext.noTaxIsInvalid) {
        errors.push(intl.formatMessage(messages.noTaxIsInvalidErrorText));
    }

    if (!validRequiredFields || !validLineItems) {
        // Assumption: line items are invalid => mandatory fields error
        errors.push(intl.formatMessage(messages.mandatoryFieldsErrorText));
    }

    if (summary.totalAmount < xeroConstants.TOTAL_MIN_AMOUNT) {
        errors.push(intl.formatMessage(messages.billNegativeAmountErrorText));
    }

    if (summary.totalAmount > xeroConstants.TOTAL_MAX_AMOUNT) {
        errors.push(intl.formatMessage(messages.exceededMaxValueTotalAmountErrorText));
    }

    if (editMode === RequestEditMode.Editor || editMode === RequestEditMode.Approver) {
        const totalAmountMatches = request.amount === getXeroLineItemsSummary(state, request).totalAmount;
        const requestName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);

        if (!totalAmountMatches) {
            errors.push(intl.formatMessage(messages.amountMismatchErrorText, { requestName }));
        }
    }

    if (d.reference && d.reference.length > xeroConstants.INVOICE_REFERENCE_MAX_LENGTH) {
        errors.push(intl.formatMessage(messages.referenceBillLengthErrorText));
    }

    return errors;
};

export const validateXeroRequest = (state: State, request: domain.XeroRequest) => {
    switch (request.integrationCode) {
        case domain.IntegrationCode.XeroPo:
            return validateXeroPurchaseOrder(state, request);

        case domain.IntegrationCode.XeroQuote:
            return validateXeroQuote(state, request);

        case domain.IntegrationCode.XeroInvoice:
            return validateXeroSalesInvoice(state, request);

        case domain.IntegrationCode.XeroBill:
            return validateXeroBill(state, request);

        case domain.IntegrationCode.XeroContact:
            return validateXeroContact(state, request);

        case domain.IntegrationCode.XeroBillBatchPayment:
            return validateXeroBillBatchPayment(state, request);

        case domain.IntegrationCode.XeroAirwallexBatchPayment:
            return validateXeroAirwallexBatchPayment(state, request);

        case domain.IntegrationCode.XeroAmaxPayBatchPayment:
            return validateXeroAmaxPayBatchPayment(request);

        case domain.IntegrationCode.XeroCreditNotesPayable:
        case domain.IntegrationCode.XeroCreditNotesReceivable:
            return validateCreditNotes(state, request);

        case domain.IntegrationCode.XeroManualJournal:
            return [];

        default:
            throw errorHelpers.notSupportedError();
    }
};
