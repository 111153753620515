import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.request.xero.BulkCreationPopupContent', {
    title: 'Upload documents',
    uploadButtonTitle: 'Upload',
    fileToUploadButtonSectionTitle: 'File type to upload',
    additionalOptionsTitle: 'Additional options',
    bulkUploadSuccess: '{numberBills} new bills are being created. Refresh this page shortly if you don`t see them',
    multipleAttachmentsWarning: 'NOTE: ApprovalMax will create multiple Xero Bills.',
});
