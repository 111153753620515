import { domain, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { api } from 'services/api';
import { routingService } from 'services/routing';

import { ReceiptBankAuthModuleData } from '../reducers/page/moduleReducer';
import { getModule } from '../selectors/pageSelectors';
import { getAllowAccessUrl } from '../selectors/receiptBankSelectors';

export const RECEIPT_BANK_ALLOW_ACCESS = 'AUTH/RECEIPT_BANK_ALLOW_ACCESS';
export const RECEIPT_BANK_ALLOW_ACCESS_RESPONSE = 'AUTH/RECEIPT_BANK_ALLOW_ACCESS_RESPONSE';
export const RECEIPT_BANK_ALLOW_ACCESS_FAILURE = 'AUTH/RECEIPT_BANK_ALLOW_ACCESS_FAILURE';
export const receiptBankAllowAccess = (company: domain.Company) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(RECEIPT_BANK_ALLOW_ACCESS, {
                callbackUrl: getModule<ReceiptBankAuthModuleData>(state).callbackUrl,
                company,
            }),

        response: async (request) => {
            const response: any = await api.receiptBank.integrateCompany({ companyId: company.id });

            let accessToken = response.AccessToken;

            return createAction(RECEIPT_BANK_ALLOW_ACCESS_RESPONSE, {
                request,
                accessToken,
            });
        },

        failure: (error) => createErrorAction(RECEIPT_BANK_ALLOW_ACCESS_FAILURE, error, {}),

        didDispatchResponse: (request, response: { accessToken: string }) => {
            routingService.navigateToExternalUrl(
                getAllowAccessUrl({
                    callbackUrl: request.callbackUrl,
                    accessToken: response.accessToken,
                })
            );
        },
    });

export type Action = ExtractActions<typeof receiptBankAllowAccess>;
