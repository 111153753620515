import { cleanHTMLAttributes } from './cleanHTMLAttributes';
import { escapeExpression } from './escapeExpression';
import { escapeSpecificExpressions } from './escapeSpecificExpressions';
import { extractDigits } from './extractDigits';
import { findSubstringIndexes } from './findSubstringIndexes';
import { parseEmail } from './parseEmail';

export const stringHelpers = {
    escapeExpression,
    parseEmail,
    escapeSpecificExpressions,
    extractDigits,
    findSubstringIndexes,
    cleanHTMLAttributes,
};
