import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionNetSuiteFraudBypassing',
    {
        title: 'Fraud detection — bypassing the approval workflow',
        subtitle:
            'What is to happen if ApprovalMax detects an approved or rejected document in NetSuite, which did not pass through the approval workflow in ApprovalMax?',
        warning: 'Please select NetSuite requester in the workflow and save it to enable this option.',
        doNotPullDocumentLabel: 'Do not pull this document to ApprovalMax',
        pullDocumentLabel: 'Pull this document to ApprovalMax',
        pullDocumentDescription:
            'Select from which date onwards this control is to be enforced. All approved and open {templateName} with a date equal or later will be pulled to ApprovalMax and put under control.',
        datePlaceholder: 'Pick a date...',
        notifyCheckboxText:
            "Notify the Organisation's Administrators about documents that bypassed the ApprovalMax workflow",
    }
);
