import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { profileApiPaths } from '../paths';
import { UseFinishResetPasswordData } from './useFinishResetPassword.types';

export const useFinishResetPassword = (
    mutationOptions?: MutationOptions<never, RequestDataParams<UseFinishResetPasswordData>>
) => {
    return useMutateLegacy<RequestDataParams<UseFinishResetPasswordData>>(profileApiPaths.finishResetPassword, {
        mutationOptions,
    });
};
