import { errorHelpers } from '@approvalmax/utils';
import unionBy from 'lodash/unionBy';
import { addArrayItem, immutable, ImmutableObject, merge, removeArrayItem, set } from 'modules/immutable';
import { integrationActions } from 'modules/integration';

import {
    Action,
    APPLY_MATRIX,
    COPY_RULES_TO_SAME_STEP_USERS,
    DISCARD_OPEN_EDITING_MATRIX,
    OPEN_EDITING_MATRIX,
    SET_CONDITION,
    UPDATE_FIELD_ACCESS_TYPE_IN_ACTIVE_MATRIX,
} from '../../actions';
import { ActiveEditingMatrixData } from '../../types/activeMatrixData';
import { AccessType, MatrixType } from '../../types/matrix';
import { getEditingMatrixData, getMatrixAmountType } from '../../utils/helpers';

export type ActiveEditingMatrix = ImmutableObject<ActiveEditingMatrixData> | null;

function activeEditingMatrixReducerInternal(
    state: ActiveEditingMatrix = null,
    action: Action | integrationActions.Action
): ActiveEditingMatrix {
    switch (action.type) {
        case OPEN_EDITING_MATRIX: {
            const step = action.payload.template.steps[action.payload.stepIndex];
            const lines = getEditingMatrixData(step.editingMatrix, action.payload.participantMatrix);
            const amountType = getMatrixAmountType(lines);

            return immutable<ActiveEditingMatrixData>({
                type: MatrixType.Editing,
                modified: false,
                data: lines,
                amountType,
                requiredFieldIds: step.editPermissionsRequiredFieldIds,
                readonlyFieldIds: [],
                generalFieldOrder: step.generalFieldOrder,
                stepIndex: action.payload.stepIndex,
                highlightLineId: action.payload.highlightLineId,
            });
        }

        case SET_CONDITION: {
            if (action.payload.matrixType !== state?.type) {
                return state;
            }

            const data = state!.data.map((matrixLine, lineIndex) => {
                if (matrixLine.lineId !== action.payload.lineId || lineIndex !== action.payload.lineIndex) {
                    return matrixLine;
                }

                return {
                    ...matrixLine,
                    rules: matrixLine.rules.map((rule, ruleIndex) => {
                        if (ruleIndex !== action.payload.ruleIndex) {
                            return rule;
                        }

                        const conditions = rule.conditions
                            .filter((c) => c.fieldId !== action.payload.field.id)
                            .concat(action.payload.newCondition);

                        return {
                            ...rule,
                            conditions,
                        };
                    }),
                };
            });

            return set(state, 'data', data);
        }

        case UPDATE_FIELD_ACCESS_TYPE_IN_ACTIVE_MATRIX: {
            if (action.payload.matrixType !== state?.type) {
                return state;
            }

            let requiredFieldIds = state.requiredFieldIds;
            let readonlyFieldIds = state.readonlyFieldIds;

            switch (action.payload.newAccessType) {
                case AccessType.Mandatory:
                    requiredFieldIds = addArrayItem(requiredFieldIds, action.payload.fieldId);
                    readonlyFieldIds = removeArrayItem(readonlyFieldIds, action.payload.fieldId);
                    break;

                case AccessType.Optional:
                    requiredFieldIds = removeArrayItem(requiredFieldIds, action.payload.fieldId);
                    readonlyFieldIds = removeArrayItem(readonlyFieldIds, action.payload.fieldId);
                    break;

                case AccessType.Readonly:
                    requiredFieldIds = removeArrayItem(requiredFieldIds, action.payload.fieldId);
                    readonlyFieldIds = addArrayItem(readonlyFieldIds, action.payload.fieldId);
                    break;

                default:
                    throw errorHelpers.assertNever(action.payload.newAccessType);
            }

            return merge(state, {
                requiredFieldIds,
                readonlyFieldIds,
                data: state.data,
            });
        }

        case COPY_RULES_TO_SAME_STEP_USERS: {
            const { fromUser, toUsers, checkedColumns, matrixType } = action.payload;

            if (!state || state.type !== matrixType) {
                return state;
            }

            const fromUserData = state.data.find((matrixLine) => matrixLine.lineId === fromUser.id);

            if (!fromUserData) {
                return state;
            }

            const newData = [...state.data];
            const selectedRules = fromUserData.rules.map((rule) => {
                return {
                    ...rule,
                    conditions: rule.conditions.filter((condition) => checkedColumns.includes(condition.fieldId)),
                };
            });

            toUsers.forEach((user) => {
                const currentUserDataIndex = newData.findIndex((item) => item.lineId === user.id);

                if (currentUserDataIndex !== -1) {
                    const finalRules = selectedRules.map((rule, index) => {
                        if (newData[currentUserDataIndex].rules[index]) {
                            return {
                                ...rule,
                                conditions: unionBy(
                                    rule.conditions,
                                    newData[currentUserDataIndex].rules[index].conditions.filter(
                                        (condition) => !checkedColumns.includes(condition.fieldId)
                                    ),
                                    'fieldId'
                                ),
                            };
                        }

                        return rule;
                    });

                    newData[currentUserDataIndex] = {
                        ...newData[currentUserDataIndex],
                        rules: finalRules,
                    };
                } else {
                    newData.push({
                        lineId: user.id,
                        rules: selectedRules,
                        isBackup: false,
                    });
                }
            });

            return set(state, 'data', newData);
        }

        case DISCARD_OPEN_EDITING_MATRIX:
        case APPLY_MATRIX:
            return null;

        default:
            return state;
    }
}

export default function activeEditingMatrixReducer(
    state: ActiveEditingMatrix = null,
    action: Action
): ActiveEditingMatrix {
    const newState = activeEditingMatrixReducerInternal(state, action);

    if (state && newState && state !== newState) {
        // modification of the current object (matrix)
        return set(newState, 'modified', true);
    }

    return newState;
}
