import { domain } from 'modules/data';

import { messages } from './AmountConditionCell.messages';

export const conditionItems = [
    {
        id: domain.NumericRangeConditionType.Above,
        name: messages.overOrEqualTo,
    },
    {
        id: domain.NumericRangeConditionType.Within,
        name: messages.between,
    },
    {
        id: domain.NumericRangeConditionType.Below,
        name: messages.under,
    },
    {
        id: domain.NumericRangeConditionType.Any,
        name: messages.anyAmount,
    },
];
