import { font, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled(Popup.EmptyContent)`
    padding: 30px 60px;
    display: flex;
    word-break: break-word;
`;

export const LeftCol = styled.div`
    padding-right: 40px;
    padding-top: 10px;
`;

export const RightCol = styled.div`
    display: flex;
    flex-flow: column;
`;

export const Title = styled.div`
    ${font(20, '#333', 'semibold')}
`;

export const Description = styled.div`
    margin-top: 20px;
    ${font(14, '#333')}

    a {
        cursor: pointer;
    }
`;

export const Buttons = styled.div`
    margin-top: 60px;
    display: flex;
    justify-content: flex-end;
`;
