export interface PopupInfo {
    id: string;
    data: any;
}

export enum ToastNotificationKind {
    Info = 'info',
    Error = 'error',
}

export interface ToastNotificationItem {
    id: string;
    message: React.ReactNode;
    timeout: number;
    kind: ToastNotificationKind;
}

export interface Ui {
    toasts: ToastNotificationItem[];
    popup: PopupInfo | null;
    /**
     * The number of async operations in progress which require the global loading indicator.
     */
    showGlobalLoadingCounter: number;
    /**
     * The number of async operations in progress which should block the UI.
     */
    isBlockedCounter: number;
    /**
     * An array of ids of async operation which are now in progress. Used so that
     * the UI can detect a particular operation in progress and block the right section
     * of the UI.
     */
    asyncOperationsInProgress: string[];

    isWorkflowWizardPreview?: boolean;
}
