import { mods, theme } from '@approvalmax/theme';
import { List } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const ListItemStyled = styled(List.Item)<StyledMods<{ noRole: boolean }>>`
    display: grid;
    grid-template-columns: auto 240px 64px;
    align-items: center;
    gap: 8px;
    padding: 0 8px;
    min-height: 40px;
    border-radius: ${theme.radius.xsmall};

    ${mods('noRole', true)`
        grid-template-columns: auto max-content;
    `};

    &:hover {
        background-color: ${theme.color.silver80};
    }
`;
