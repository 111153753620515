import { Module } from 'modules/page';

import * as actions from './actions';
import * as constants from './constants';
import FieldDataProvider from './dataProviders/FieldDataProvider';
import * as factories from './factories';
import ModuleComponent from './ModuleComponent';
import entitiesReducer from './reducers/entitiesReducer';
import metaReducer from './reducers/metaReducer';
import navigationReducer from './reducers/navigationReducer';
import profileReducer from './reducers/profileReducer';
import sessionReducer from './reducers/sessionReducer';
import uiReducer from './reducers/uiReducer';
import userPreferencesReducer from './reducers/userPreferencesReducer';
import globalLoadingBarSaga from './sagas/globalLoadingBarSaga';
import loadUserProfileDataSaga from './sagas/loadUserProfileDataSaga';
import toastNotificationSaga from './sagas/toastNotificationSaga';
import * as selectors from './selectors';
import * as statics from './statics';
import * as ui from './ui';
export { default as automation } from './automation';
export * from './hooks';
import { globalQueryClient } from './query/queryClient';
import * as QueryKeys from './query/queryKeys';

const dataProviders = {
    FieldDataProvider,
};

export { actions, constants, dataProviders, factories, globalQueryClient, QueryKeys, selectors, statics, ui };

export const moduleDef: Module = {
    id: 'common',
    moduleComponent: ModuleComponent,
    reducers: {
        entities: entitiesReducer,
        profile: profileReducer,
        ui: uiReducer,
        userPreferences: userPreferencesReducer,
        meta: metaReducer,
        navigation: navigationReducer,
        session: sessionReducer,
    },
    sagas: [globalLoadingBarSaga, loadUserProfileDataSaga, toastNotificationSaga],
};
