import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionNetSuiteExpenseReportFraudChangesAfter',
    {
        title: 'Fraud detection — changes after approval',
        subtitle:
            'What changes made in NetSuite after the approval should trigger addition of mark "Changed after approval" and a notification to company Administrators?',
        optionEmployee: 'Employee',
        optionTotalAmount: 'Total amount (more than 0.1 {companyCurrencyCode} change)',
        optionDate: 'Date',
        optionPurpose: 'Purpose',
        optionExpenseAccount: 'Expense Account',
        optionCategory: 'Category',
    }
);
