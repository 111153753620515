import { isBrowser } from './isBrowser';

export const isAndroid = () => {
    if (isBrowser()) {
        const agent = navigator.userAgent.toLowerCase();
        const isWindows = /Windows/i.test(agent);
        const isNokia = /Nokia/i.test(agent);

        return /android/i.test(agent) && !(isWindows || isNokia);
    }

    return false;
};
