import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { requestsApiPaths, useGetRequest } from 'shared/data';

import {
    UseGetRequestDuplicatesXeroParams,
    UseGetRequestDuplicatesXeroPathParams,
    UseGetRequestDuplicatesXeroResponse,
} from './useGetRequestDuplicatesXero.types';

export const useGetRequestDuplicatesXero = (
    pathParams: UseGetRequestDuplicatesXeroPathParams,
    params: QueryParams<UseGetRequestDuplicatesXeroParams>,
    queryOptions?: QueryOptions<UseGetRequestDuplicatesXeroResponse>
) => {
    return useGetRequest<UseGetRequestDuplicatesXeroResponse>(requestsApiPaths.duplicatesXeroPO, {
        pathParams,
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<UseGetRequestDuplicatesXeroParams>(params, queryOptions),
        },
        apiVersion: 'v2',
    });
};
