import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionXeroBillMatching', {
    title: 'Purchase order matching',
    buttonLearnMore: 'Learn more',

    sectionManualTitle: 'Enable the manual matching to Purchase Orders?',
    optionManual: 'Allow users to match Bills to Purchase Orders',

    sectionNotMatchedApproveTitle: 'Can a Bill be approved if it is not yet matched to a Purchase Order?',
    optionNotMatchedApproveYes: 'Yes, unmatched Bills can be approved',
    optionNotMatchedApproveYesAmount: 'Yes, unmatched Bills up to the following amount can be approved',
    optionNotMatchedApproveNo: 'No, unmatched Bills cannot be approved',

    sectionOverbudgetTitle:
        'Can a Bill be approved if the Purchase Order remaining balance of any matched Purchase Order is below zero?',
    optionOverbudgetYes: 'Yes, such Bills can be approved',
    optionOverbudgetYesPercent:
        'Yes, such Bills can be approved if the Purchase Order remaining balance is not more than',
    optionOverbudgetYesAmount:
        'Yes, such Bills can be approved if the Purchase Order remaining balance is not more than',
    optionOverbudgetNo: 'No, such Bills cannot be approved',

    sectionRetrospectiveTitle: 'Can a Bill be matched to a Purchase Order which has a later date?',
    optionRetrospectiveYes: 'Yes, allow matching Bills to retrospective Purchase Orders',
    optionRetrospectiveNo: 'No, do not allow matching Bills to retrospective Purchase Orders',

    sectionAmountTypeTitle: 'Which type of amounts should be used in the matching allocations?',
    sectionAmountTypeWarning: 'Changing this setting will start the recalculation of all remaining balances.',
    sectionAmountTypeWarningBold: 'You can only change this setting once every 24 hours.',
    optionAmountTypeGross: 'Gross amounts',
    optionAmountTypeNet: 'Net amounts',

    sectionInsufficientBudgetTitle:
        'Can a Bill be approved if its Allocated amount is less than its Total amount (considering the net/gross setting)?',
    optionInsufficientBudgetAlways: 'Yes, such Bill can be approved',
    optionInsufficientBudgetWithThreshold: `Yes, Allocated amount isn't validated for Bills up to the following amount`,
    optionInsufficientBudgetWithPercentageThreshold:
        'Yes, the Bill can be approved if the difference between its Allocated amount and its Total amount is less than',
    optionInsufficientBudgetNever:
        "No, the Bill can't be approved if its Allocated amount is less than its Total amount",
    sectionAddMatchedPOsRequestersTitle:
        'Should the requester of a matched Purchase Order be added as a Bill approver in the active approval step?',
    optionAddMatchedPOsRequestersNone: 'No',
    optionAddMatchedPOsRequestersManually: 'Yes, display a list of Requesters to be added',
    optionAddMatchedPOsRequestersAll: 'Yes, automatically add all of the Requesters (if applicable)',
    percent: 'percent',
    percentBelowXero: 'percent below zero',
    belowZero: 'below zero',
    warning:
        'Changing this setting will start the recalculation of all remaining balances.You can only change this setting once every 24 hours.',
});
