import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('requestList.components.AmountInfo', {
    purchaseOrderShort: 'PO',
    description: `
        Please note: the line item price in the current {requestName} differs from
        this item's cost price in {systemName}.`,
    expectedPriceText: 'Inventory cost price:',
    actualPriceText: 'Price in this {requestName}:',
    differenceText: 'Difference:',
    xeroSystemName: 'Xero’s inventory',
    netSuiteSystemName: 'Oracle NetSuite',
    qBooksSystemName: 'QuickBooks Online inventory',
    multiCurrencyDescription: `
        Please note: the Price Checker is currently unavailable when your
        organisation's currency differs from contact's one.`,
});
