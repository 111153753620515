export enum TwoFaEnforcementType {
    None = 'None',
    Soft = 'Soft',
    Hard = 'Hard',
}

export interface BackupCode {
    code: string;
    isUsed: boolean;
}
