export const getScrollParent = (element: HTMLElement, includeHidden = false) => {
    let style = getComputedStyle(element);
    let excludeStaticParent = style.position === 'absolute';
    let overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    if (style.position === 'fixed') {
        return null;
    }

    for (let parent: HTMLElement | null = element; (parent = parent.parentElement); ) {
        style = getComputedStyle(parent);

        if (excludeStaticParent && style.position === 'static') {
            continue;
        }

        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
            return parent;
        }
    }

    return null;
};
