import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.StartOverPopup', {
    startOverDialogTitle: 'Restart all currently running requests',
    startOverDialogTitleAfterUpdate: 'Check if you need to restart the approval of existing requests',
    startOverDialogButtonTitle: 'Restart the selected requests',
    addCommentPlaceholder: 'Add a comment',
    skipButtonTitle: 'Skip this pop-up',
    skipButtonText: 'Skip',
    startOverDialogButton: 'Restart',
    startOverDialogHintLine1:
        'Select all requests that you would like to restart using the updated workflow. The Approvers who already made their decision will have to make it again.',
    startOverDialogHintLine1AfterUpdate1:
        'The workflow version update will only affect the approval of new requests. Please review the existing ones and restart the approval process if required.',
    startOverDialogHintLine2: 'You can leave a comment to let the Approvers know what’s happened.',
    workflowManagerRestart1: 'The updated version of the workflow will only affect new approvals.',
    workflowManagerRestart2:
        'You have currently {count} requests running. Do you want to restart them all? Please note that after restarting workflow all approvals will be reset.',
});
