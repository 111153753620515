import { Guid } from '@approvalmax/types';

import { domain } from '..';
import { IntegrationCode } from './Integration';
import { MatrixLine } from './Matrix';
import { DeadlineRuleType } from './Request';
import { User } from './User';

export enum TemplateStepType {
    DecisionStep = 'DecisionStep',
    AnyOfDecisionStep = 'AnyOfDecisionStep',
}

export interface TemplateStep {
    id: string;
    name: string;
    defaultDuration: string;
    deadlineRule: TemplateStepDeadlineRule | null;
    generalFieldOrder: Guid[];
    requiredFieldIds: Guid[];
    editPermissionsRequiredFieldIds: Guid[];
    readonlyFieldIds: Guid[];
    participantMatrix: MatrixLine[];
    editingMatrix: MatrixLine[];
    editorMatrix: MatrixLine[];
    type: TemplateStepType;
    approvalCount: number | null;
    createDate?: string;
    modifiedDate?: string | null;
}

export interface TemplateReviewStep {
    requiredFieldIds: Guid[];
    readonlyFieldIds: Guid[];
    deadlineRule: TemplateStepDeadlineRule | null;
    reviewers: MatrixLine[];
}

export interface TemplateStepDeadlineRule {
    calculator: DeadlineRuleType | null;
    duration: string;
}

export enum DocumentFieldState {
    Hidden = 'hidden',
    ReadOnly = 'read-only',
    Editable = 'editable',
    Mandatory = 'mandatory',
}
export interface DocumentField {
    name: string;
    state: DocumentFieldState;
    workflowVersionId: string;
    purpose: string;
    defaultOrder: number;
    defaultState: DocumentFieldState;
    availableStates: DocumentFieldState[];
}

export interface Template {
    id: Guid;
    companyId: Guid;
    templateName: string;
    enabled: boolean;
    hasOutdatedRequests: boolean;
    integrationCode: IntegrationCode | null;
    requiredFieldIds: Guid[];
    createDate?: string | null;
    modifiedDate: string | null;
    author: Guid | null;
    externalSubmitter: Guid | null;
    emailExternalSubmitter: Guid | null;
    ocrEmailAddress?: string;
    ocrRequestInitialStatus?: domain.RequestStatusV2;
    receiptBankExternalSubmitter: Guid | null;
    submitterMatrix: MatrixLine[];
    submitterRuleOrders: Guid[] | null;
    payerMatrix?: MatrixLine[];
    steps: TemplateStep[];
    reviewStep: TemplateReviewStep;
    autoApprovalRules: MatrixLine[];
    isActual?: boolean;
    isNew?: boolean;
    version?: number;
    comment?: string;
    documentFields?: DocumentField[];
}

export interface TemplateInfo {
    id: Guid;
    companyId: Guid;
    name?: string;
    integrationCode: IntegrationCode | null;
    enabled: boolean;
}

export interface WorkflowVersion {
    templateVersionId: Guid;
    version: number;
    isActual: boolean;
    createDate: string;
    author: User | null;
    comment: string;
}
