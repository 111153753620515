import { AlertIcon } from '@approvalmax/ui';
import { Alert, Button, Grid, Spacing } from '@approvalmax/ui/src/components';
import { dateTimeHelpers } from '@approvalmax/utils';
import { CompanyPlan } from 'modules/data/domain';
import { FC, memo, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { isNewUserForTrialExperiment } from 'shared/helpers/experiments';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { getAlertData } from './CompanyAlerts.helpers';
import { messages } from './CompanyAlerts.messages';
import { CompanyAlertsProps } from './CompanyAlerts.types';

const qa = bemFactory.qa('drawerAlert');

export const CompanyAlerts: FC<CompanyAlertsProps> = memo((props) => {
    const { company, profileCreatedDate } = props;

    const { licenseProductPlan, isReadonly, licenseExpires, id, flags } = company;
    const isSubscriptionExpires = licenseProductPlan === CompanyPlan.PaymentRequired && !isReadonly;
    const { text: alertText, qaId: alertQaId } = getAlertData(company);

    const buyLink = useMemo(
        () => getAccountUrl(AccountPath.buyNow, { companyId: company.id, origin: 'wa_trial_expiration_toast' }),
        [company.id]
    );

    const showBuyAfterTrialBanner =
        profileCreatedDate &&
        isNewUserForTrialExperiment(profileCreatedDate) &&
        isReadonly &&
        flags.isAccountOwner &&
        flags.isExpired &&
        flags.isTrialLicense;

    return (
        <>
            {isSubscriptionExpires && (
                <Alert color='midnight20' size='small' data-qa={qa('daysToExpire')}>
                    <div>
                        {messages.subscriptionExpires({
                            dateOfExpire: dateTimeHelpers.formatDate(licenseExpires),
                        })}
                    </div>

                    <a
                        href={getAccountUrl(AccountPath.addToSubscription, {
                            companyId: id,
                            origin: 'wa_company_alerts',
                        })}
                    >
                        {messages.subscriptionExpiredLink}
                    </a>
                </Alert>
            )}

            {showBuyAfterTrialBanner && (
                <Alert
                    alignItems='center'
                    startIcon={<AlertIcon />}
                    invalid
                    color='midnight20'
                    size='small'
                    data-qa={qa('buyAfterTrial')}
                >
                    <Grid gridTemplateColumns='1fr max-content' alignItems='center' gap={8}>
                        <span>{messages.subscribeAfterTrial}</span>

                        <Button as='a' size='xsmall' color='cobalt80' href={buyLink} target='_blank'>
                            {messages.subscribeNow}
                        </Button>
                    </Grid>
                </Alert>
            )}

            {!showBuyAfterTrialBanner && alertText && (
                <Alert invalid color='midnight20' size='small' data-qa={qa(alertQaId)}>
                    {alertText}
                </Alert>
            )}

            {isSubscriptionExpires || (alertText && <Spacing height={8} />)}
        </>
    );
});

CompanyAlerts.displayName = 'CompanyAlerts';
