import { messages } from './AccrualReportsBanner.messages';

export const featureDescriptionList = [
    {
        title: messages.netGross,
        description: messages.netGrossDescription,
    },
    {
        title: messages.poBalance,
        description: messages.poBalanceDescription,
    },
    {
        title: messages.autoPushing,
        description: messages.autoPushingDescription,
    },
    {
        title: messages.approvalsAdded,
        description: messages.approvalsAddedDescription,
    },
];
