import './subscriptionsHeader.scss';

import { Button } from '@approvalmax/ui';
import React, { FC } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getLoading } from '../../selectors/moduleSelectors';

const i18nPrefix = 'company/containers/SelectSubscriptionPopup/RB/subsciption-header';

const bem = bemFactory.block('company-select-subscription-subscriptions-header');
const qa = bemFactory.qa('company-select-subscription-subscriptions-header');

interface SubscriptionsHeaderProps {
    onNext: () => void;
    header: React.ReactNode;
    isDisabledButton: boolean;
}

const SubscriptionsHeader: FC<SubscriptionsHeaderProps> = (props) => {
    const { onNext, header, isDisabledButton } = props;

    const isLoading = useSelector(getLoading);

    return (
        <header className={bem()} data-qa={qa()}>
            <h1 className={bem('header-text')}>{header}</h1>

            <Button execute={onNext} disabled={isLoading ? true : isDisabledButton}>
                <FormattedMessage id={`${i18nPrefix}.selectButton`} defaultMessage='Select' />
            </Button>
        </header>
    );
};

export default SubscriptionsHeader;
