import { clearCacheStorage } from 'modules/data-providers';
import { integrationActions } from 'modules/integration';
import { ResponseAction } from 'modules/react-redux';
import { put, select, takeEvery } from 'redux-saga/effects';

import { getActiveTemplate } from '../selectors/pageSelectors';

type GetIntegrationCacheStatusResponseType = ResponseAction<typeof integrationActions.getIntegrationCacheStatus>;

function* resetDataProvidersCache() {
    yield put(clearCacheStorage(() => true));
}

let lastLoadingInProgress = false;

export default function* cacheManagement() {
    yield takeEvery(integrationActions.UPDATE_INTEGRATION_CACHE_RESPONSE, resetDataProvidersCache);
    yield takeEvery(
        integrationActions.GET_INTEGRATION_CACHE_STATUS_RESPONSE,
        function* (action: GetIntegrationCacheStatusResponseType) {
            const loadingInProgress = action.payload.cacheObjects.some((c) => c.loadingInProgress);

            if (!loadingInProgress && lastLoadingInProgress) {
                yield resetDataProvidersCache();
                yield put(
                    integrationActions.invalidateFields({
                        companyId: action.payload.request.companyId,
                    })
                );

                const template = getActiveTemplate(yield select());

                if (template) {
                    yield put(
                        integrationActions.loadTemplateFieldsByCode({
                            companyId: template.companyId,
                            integrationCode: template.integrationCode,
                        }) as any
                    );
                }
            }

            lastLoadingInProgress = loadingInProgress;
        }
    );
}
