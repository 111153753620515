import { enabledByParams, QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { ocrApiPaths } from '../paths';
import { UseGetOcrStatusPathParams, UseGetOcrStatusResponse } from './useGetOcrStatus.types';

export const useGetOcrStatus = (
    pathParams: UseGetOcrStatusPathParams,
    queryOptions?: QueryOptions<UseGetOcrStatusResponse>
) => {
    return useGetRequest<UseGetOcrStatusResponse>(ocrApiPaths.ocrStatus, {
        pathParams,
        apiVersion: 'v2',
        queryOptions: {
            enabled: enabledByParams<UseGetOcrStatusPathParams>(pathParams, queryOptions),
            ...queryOptions,
        },
    });
};
