/**
 * Cleans HTML represented ad plain text from HTML attributes.
 *
 * Example with remove class attribute:
 *
 * FROM: \<p class="foo">text\</p>
 *
 * TO: \<p>text\</p>
 *
 * @param removableAttrs attributes to delete
 * @param text formatting text
 * @returns string | undefined
 */
export const cleanHTMLAttributes = (removableAttrs: string[], text?: string) => {
    if (!text?.length) return text;

    let result = String(text);

    const regexpString = removableAttrs.map((current) => ` ${current}.{2,}?"`).join('|');

    const regExp = new RegExp(regexpString, 'g');

    return result.replaceAll(regExp, '');
};
