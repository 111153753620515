import { miscHelpers } from '@approvalmax/utils';
import * as Sentry from '@sentry/browser';
import { stateTree } from 'modules/data';
import { AnyAction, Middleware } from 'redux';

export const createSentryMiddleware = (options: {
    stateTransformer?: (state: stateTree.State) => any;
    lastActionTransformer?: (action: AnyAction) => any;
    breadcrumbsActionTransformer?: (action: AnyAction) => any;
}): Middleware => {
    const {
        breadcrumbsActionTransformer = () => undefined,
        lastActionTransformer = miscHelpers.identity,
        stateTransformer = miscHelpers.identity,
    } = options;

    return (store) => {
        let lastAction: AnyAction | undefined;

        Sentry.configureScope((scope) => {
            scope.addEventProcessor((event) => {
                const state = store.getState();

                event.extra = {
                    ...event.extra,
                    lastAction: lastAction ? lastActionTransformer(lastAction) : undefined,
                    state: stateTransformer(state),
                };

                return event;
            });
        });

        return (next) => (action: AnyAction) => {
            Sentry.addBreadcrumb({
                category: 'redux-action',
                message: action.type,
                level: 'info',
                data: breadcrumbsActionTransformer(action),
            });

            lastAction = action;

            return next(action);
        };
    };
};
