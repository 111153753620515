import { RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { slackApiPaths } from '../paths';
import {
    UseSlackCompleteWithoutConnectParams,
    UseSlackCompleteWithoutConnectResponse,
} from './useSlackCompleteWithoutConnect.types';

export const useSlackCompleteWithoutConnect = () => {
    return useMutate<
        RequestDataParams<UseSlackCompleteWithoutConnectParams, {}>,
        UseSlackCompleteWithoutConnectResponse
    >(slackApiPaths.completeWithoutConnect, {
        apiSource: 'slack',
    });
};
