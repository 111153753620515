import { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { UseGetTFAEnforcementInformationResponse } from './useGetTFAEnforcementInformation.types';

export const useGetTFAEnforcementInformation = (
    queryOptions?: QueryOptions<UseGetTFAEnforcementInformationResponse>
) => {
    return useGetRequestLegacy<UseGetTFAEnforcementInformationResponse>(
        twoFaApiPaths.getTFAEnforcementInformation,
        {},
        {
            method: 'get',
            queryOptions,
        }
    );
};
