import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.SelectSubscriptionPopup.Content', {
    title: 'Select subscription',
    emptySubscriptions: 'There are no applicable subscriptions.',
    otherOptions: 'Other options:',
    newSubscriptionOption: 'Add this organisation to new subscription',
    newTrialOptionForPartner: 'Create a new organisation on a trial and add it to the subscription later',
    newTrialOptionForEndUser:
        'Start with three days free of charge to set up a new organisation, and add it to a subscription later',
    confirm: 'Confirm',
    subscriptionsTitle:
        'Please select which subscription this organisation should be added to. Some subscriptions might be out of the allowed quantity. In this case you’ll need to upgrade the subscription first.',
    subscriptionNameMore: '{freeSlots} more organisations can be added {upgradeText}',
    upgraded: 'Can be upgraded',
    upgradePanelMessage:
        'This will upgrade your subscription to {qtyOfSlotsAfterUpgrade} organisations.{br}Your monthly fee will be adjusted accordingly in the next billing cycle. You won’t need to go through checkout or do a pro-rated upgrade.',
    upgradePanelMessageAmount: 'Your new monthly billing amount will be {amountStr} (before taxes).',
    confirmText: 'I understand that the checkout will be skipped',
});
