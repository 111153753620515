import { Guid } from '@approvalmax/types';
import { domain } from 'modules/data';

import AnyColumnKind from '../../config/columnKind';
import { ColumnTransfer } from '../../selectors/types/ExpandedReportConfig';
import { Filter } from '../filters';

export enum SortingDirection {
    Ascending = 'Ascending',
    Descending = 'Descending',
}

export interface ReportConfigColumn {
    kind: AnyColumnKind;
    id: string;
    name: string;
    title: string;
    visible: boolean;
    sortable: boolean;
    sorting: SortingDirection | null;
    filter: Filter;
    exactValuesToSetVisibleByDefault?: string[];
}

export interface ReportConfig {
    id: Guid;
    name: string;
    companyId: Guid;
    createdDate: string;
    reportCode: domain.ReportCode | null;
    reportType: domain.ReportType;
    lastRunDate: string | null;
    columns: ReportConfigColumn[];
    settings?: { [key: string]: ColumnTransfer | ColumnTransfer[] };
}
