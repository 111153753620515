import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import { mapData } from './useCreateNetSuiteVendor.map';
import {
    UseCreateNetSuiteVendorData,
    UseCreateNetSuiteVendorResponse,
    UseCreateNetSuiteVendorResponseBackend,
} from './useCreateNetSuiteVendor.types';

export const useCreateNetSuiteVendor = () => {
    return useMutateLegacy<
        RequestDataParams<UseCreateNetSuiteVendorData>,
        UseCreateNetSuiteVendorResponseBackend,
        UseCreateNetSuiteVendorResponse
    >(integrationsApiPaths.createNetSuiteVendor, {
        mapData,
    });
};
