import { BudgetingIcon } from '@approvalmax/ui';
import { useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { useIsBudgetingAvailable } from 'shared/hooks/useIsBudgetingAvailable';
import { getPath, Path } from 'urlBuilder';

import { messages } from './BudgetingMenu.messages';
import { BudgetingMenuProps } from './BudgetingMenu.types';

const qa = bemFactory.qa('drawerBudgeting');

export const useBudgetingLinkItems = (company: BudgetingMenuProps['company']) => {
    const isBudgetingAvailable = useIsBudgetingAvailable(company);

    return useMemo(() => {
        return [
            {
                id: Path.companyBudgetingV2,
                name: messages.budgetingName,
                available: isBudgetingAvailable,
                to: getPath(Path.companyBudgetingV2, company.id),
                startIcon: BudgetingIcon,
                dataQa: qa(),
            },
        ];
    }, [company.id, isBudgetingAvailable]);
};
