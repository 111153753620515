import {
    UseGetNetSuiteApiFilterResponse,
    UseGetNetSuiteApiFilterResponseBackend,
} from './useGetNetSuiteApiFilter.types';

export const mapData = (data: UseGetNetSuiteApiFilterResponseBackend): UseGetNetSuiteApiFilterResponse => {
    return {
        isExpandAccount: Boolean(data.ApiFilter.IsExpandAccount),
        taxCodeListsInclude: data.ApiFilter.TaxCodeListsInclude,
    };
};
