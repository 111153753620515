import { WorkflowWizardPopup } from 'app/(workspace)/[companyId]/workflows/resources/components';
import {
    sessionKeyWorkflowWizardClosedCount,
    sessionKeyWorkflowWizardClosedTime,
    storageKeyWorkflowWizardClosedCount,
    workflowWizardMaxSessionCloseCount,
} from 'app/(workspace)/[companyId]/workflows/resources/constants';
import { useCanShowWorkflowWizardPopup } from 'app/(workspace)/[companyId]/workflows/resources/hooks/useCanShowWorkflowWizardPopup';
import { memo, useCallback } from 'react';

export const WorkflowWizard = memo(() => {
    const canShowWorkflowWizardPopup = useCanShowWorkflowWizardPopup();

    const handleClose = useCallback(() => {
        const sessionCount = sessionStorage.getItem(sessionKeyWorkflowWizardClosedCount);
        const sessionClosedCount = sessionCount ? parseInt(sessionCount, 10) || 0 : 0;
        const newSessionClosedCount = sessionClosedCount + 1;

        if (newSessionClosedCount >= workflowWizardMaxSessionCloseCount) {
            sessionStorage.setItem(sessionKeyWorkflowWizardClosedTime, String(Date.now()));
            sessionStorage.removeItem(sessionKeyWorkflowWizardClosedCount);
        } else {
            sessionStorage.setItem(storageKeyWorkflowWizardClosedCount, String(newSessionClosedCount));
        }

        const storageCount = localStorage.getItem(storageKeyWorkflowWizardClosedCount);
        const closedCount = storageCount ? parseInt(storageCount, 10) || 0 : 0;

        localStorage.setItem(storageKeyWorkflowWizardClosedCount, String(closedCount + 1));
    }, []);

    return canShowWorkflowWizardPopup && <WorkflowWizardPopup onClose={handleClose} />;
});

WorkflowWizard.displayName = 'WorkflowWizard';
