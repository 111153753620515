import { Route } from 'modules/page';
import React from 'react';

const TemplateRequestsListPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/TemplateRequestsListPage')
);
const CompanyRequestsListPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/CompanyRequestsListPage')
);
const MyRequestsListPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/MyRequestsListPage')
);
const MobileDecisionPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/MobileDecisionPage')
);

const SearchRequestsListPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/SearchRequestsListPage')
);

const OldRequestsSupportingPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/OldRequestsSupportingPage')
);

const routes: Route[] = [
    {
        path: ['/requests/search', '/requests/search/:companyId'],
        component: SearchRequestsListPage,
    },
    {
        path: ['/requests/:section', '/requests/:section/:companyId'],
        component: MyRequestsListPage,
    },
    {
        path: '/:companyId/requests/:section',
        component: CompanyRequestsListPage,
    },
    {
        path: '/:companyId/requests/:workflowId/:status',
        component: TemplateRequestsListPage,
    },
    {
        path: '/mobileDecision',
        component: MobileDecisionPage,
    },
    {
        path: '/request',
        component: OldRequestsSupportingPage,
    },
];

export default routes;
