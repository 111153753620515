import isFunction from 'lodash/isFunction';

import {
    DeepPartial,
    immutable,
    ImmutableArray,
    ImmutableObject,
    isImmutable,
    merge,
    update,
    updateIn,
    without,
} from './';

export function mergeDeep<T>(state: T, data: any, merger?: (a: any, b: any, config?: any) => any): ImmutableObject<T> {
    return merge(state, data, { deep: true, merger });
}

export function mergeDeepIn<T, Data extends DeepPartial<T>>(target: T, propertyPath: string[], data: Data) {
    return updateIn(target, propertyPath, (target: T) => merge(target, data, { deep: true }));
}

export function mergeIn<T>(target: T, propertyPath: string[], data: any) {
    return updateIn(target, propertyPath, (x: any) => merge(x, data));
}

export function updateWithout<T>(target: T, propertyName: string, property: string): ImmutableObject<T>;
export function updateWithout<T>(target: T, propertyName: string, ...properties: string[]): ImmutableObject<T>;
export function updateWithout<T>(
    target: T,
    propertyName: string,
    filter: (value: any, key: string) => boolean
): ImmutableObject<T>;

export function updateWithout<T>(target: T, propertyName: string, ...args: any[]): ImmutableObject<T> {
    return update(target, propertyName, (x) => {
        if (Array.isArray(x)) {
            throw new Error('Argument in Immutable.without can only be an object.');
        }

        return without<any, any>(x, ...args);
    });
}

export function updateInWithout<T>(target: T, propertyPath: string[], property: string): ImmutableObject<T>;
export function updateInWithout<T>(target: T, propertyPath: string[], ...properties: string[]): ImmutableObject<T>;
export function updateInWithout<T>(
    target: T,
    propertyPath: string[],
    filter: (value: any, key: string) => boolean
): ImmutableObject<T>;

export function updateInWithout<T>(target: T, propertyPath: string[], ...args: any[]): ImmutableObject<T> {
    return updateIn(target, propertyPath, (x) => without<any, any>(x, ...args));
}

export function updateArrayItem<T>(
    target: T[],
    itemOrPredicate: T | ((item: T) => boolean),
    newItem: T | ((item: T) => T)
): T[] {
    if (isFunction(itemOrPredicate)) {
        if (isFunction(newItem)) {
            return target.map((x) => (itemOrPredicate(x) ? newItem(x) : x));
        } else {
            return target.map((x) => (itemOrPredicate(x) ? newItem : x));
        }
    } else {
        if (isFunction(newItem)) {
            return target.map((x) => (x === itemOrPredicate ? newItem(x) : x));
        } else {
            return target.map((x) => (x === itemOrPredicate ? newItem : x));
        }
    }
}

export function insertArrayItem<T>(target: T[], item: T, index: number): ImmutableArray<T> {
    let existingIndex = target.indexOf(item);

    if (existingIndex === index) {
        return isImmutable(target) ? target : immutable(target);
    }

    let result = [...target];

    if (existingIndex !== -1) {
        result.splice(existingIndex, 1);
    }

    result.splice(index, 0, item);

    return immutable(result);
}

export function addArrayItem<T>(target: T[], item: T): ImmutableArray<T> {
    let index = target.indexOf(item);

    if (index !== -1) {
        return isImmutable(target) ? target : immutable(target);
    }

    let result = [...target];

    result.push(item);

    return immutable(result);
}

export function removeArrayItem<T>(target: T[], item: T | ((item: T) => boolean)): ImmutableArray<T> {
    if (isFunction(item)) {
        const result = target.filter((x) => !item(x));

        if (result.length === target.length) {
            return immutable(target);
        }

        return immutable(result);
    } else {
        let index = target.indexOf(item);

        if (index === -1) {
            return isImmutable(target) ? target : immutable(target);
        }

        let result = [...target];

        result.splice(index, 1);

        return immutable(result);
    }
}
