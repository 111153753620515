import { createAction, ExtractActions } from 'modules/react-redux';

export const LOAD_PAGE_DATA = 'BUDGETING_V2/LOAD_PAGE_DATA';
export const loadPageData = (companyId: string) =>
    createAction(LOAD_PAGE_DATA, {
        companyId,
    });

export const UPDATE_BUDGET_SYNC_SETTING = 'BUDGETINGV2/UPDATE_BUDGET_SYNC_SETTING';
export const updateBudgetSyncSetting = (options: { companyId: string; isEnabled: boolean }) =>
    createAction(UPDATE_BUDGET_SYNC_SETTING, options);

export type Action = ExtractActions<typeof loadPageData | typeof updateBudgetSyncSetting>;
