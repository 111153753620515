import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';

import { useMutateLegacy } from '../../../api';
import { receiptBankPaths } from '../paths';
import {
    UseReceiptBankIntegrateCompanyRequest,
    UseReceiptBankIntegrateCompanyResponse,
} from './useReceiptBankIntegrateCompany.types';

export const useReceiptBankIntegrateCompany = (
    mutationOptions?: MutationOptions<
        UseReceiptBankIntegrateCompanyResponse,
        RequestDataParams<UseReceiptBankIntegrateCompanyRequest>
    >
) => {
    return useMutateLegacy<
        RequestDataParams<UseReceiptBankIntegrateCompanyRequest>,
        UseReceiptBankIntegrateCompanyResponse
    >(receiptBankPaths.integrateCompany, {
        mutationOptions,
        mapData: objectHelpers.pascalCaseToCamelCase,
    });
};
