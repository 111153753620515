import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, CREATE_XERO_CONTACT_RESPONSE, SHOW_XERO_CONTACT_CREATION_POPUP } from '../../../actions';
import { EditableXeroContact } from '../../../data/xero/EditableXeroContact';

export const XERO_CONTACT_CREATION_POPUP = 'XERO_CONTACT_CREATION_POPUP';

export interface XeroContactCreationPopupData {
    id: typeof XERO_CONTACT_CREATION_POPUP;
    initialContact: EditableXeroContact;
}

export type XeroContactCreationPopupType = ImmutableObject<XeroContactCreationPopupData> | null;

export default function (state: XeroContactCreationPopupType, action: Action): XeroContactCreationPopupType {
    switch (action.type) {
        case SHOW_XERO_CONTACT_CREATION_POPUP:
            return immutable<XeroContactCreationPopupData>({
                id: XERO_CONTACT_CREATION_POPUP,
                initialContact: action.payload.contact,
            });

        case CREATE_XERO_CONTACT_RESPONSE:
            return null;

        default:
            return state;
    }
}
