import { Popup as OldPopup } from '@approvalmax/ui';
import { Popup } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { cancelActivePopup } from 'modules/first-start/actions';
import CreateOrganisationPopup from 'modules/first-start/components/CreateOrganisationPopup/CreateOrganisationPopup';
import { ActivePopup } from 'modules/first-start/reducers/moduleReducer';
import { getActivePopup, getLoading, getNewOrganisationData } from 'modules/first-start/selectors/moduleSelectors';
import { removePreferredAccountingSystemSignUp } from 'modules/utils';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Content } from './components';
import { useNextStep } from './NewOrganisationPopup.hooks';

export const NewOrganisationPopup = memo(() => {
    const dispatch = useDispatch();
    const activePopup = useSelector(getActivePopup);
    const newOrgData = useSelector(getNewOrganisationData);

    const showNewOrganisationPopup = activePopup === ActivePopup.NewOrganisationPopup;
    const isFirstStart = Boolean(newOrgData?.firstStart);
    const fromNoCompanies = newOrgData?.fromNoCompanies;

    const handleCancelActivePopup = useCallback(
        (isOpen: boolean) => {
            if (!isOpen) {
                dispatch(cancelActivePopup());
            }
        },
        [dispatch]
    );

    const isLoading = useSelector(getLoading);

    const onNextClick = useNextStep(() => handleCancelActivePopup(false));

    const handleConnect = useCallback(
        (integrationType: domain.IntegrationType) => {
            if (isFirstStart) {
                removePreferredAccountingSystemSignUp();
            }

            onNextClick(integrationType);
        },
        [isFirstStart, onNextClick]
    );

    if (isFirstStart || fromNoCompanies) {
        return (
            <OldPopup
                isOpen={activePopup === ActivePopup.NewOrganisationPopup}
                onRequestClose={handleCancelActivePopup}
                disableAutoClose={isFirstStart}
            >
                <CreateOrganisationPopup
                    closable={fromNoCompanies}
                    isLoading={isLoading}
                    isLoadStepFromLocalStorage={isFirstStart}
                    onClose={() => handleCancelActivePopup(false)}
                    onConnectXero={() => handleConnect(domain.IntegrationType.Xero)}
                    onConnectQBooks={() => handleConnect(domain.IntegrationType.QBooks)}
                    onConnectNetSuite={() => handleConnect(domain.IntegrationType.NetSuite)}
                    onConnectCin7={() => handleConnect(domain.IntegrationType.Dear)}
                    onConnectStandalone={() => handleConnect(domain.IntegrationType.None)}
                />
            </OldPopup>
        );
    }

    return (
        <Popup
            open={showNewOrganisationPopup}
            onToggle={handleCancelActivePopup}
            preventAutoClose={isFirstStart}
            size='large'
        >
            <Content onNextClick={onNextClick} />
        </Popup>
    );
});

NewOrganisationPopup.displayName = 'NewOrganisationPopup';
