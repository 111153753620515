import { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data';

import { companiesApiPaths } from '../paths';
import {
    UseGetQuantityOfAllOpenRequestsWithParticipantDecisionRequiredRequestParams,
    UseGetQuantityOfAllOpenRequestsWithParticipantDecisionRequiredResponse,
} from './useGetQuantityOfAllOpenRequestsWithParticipantDecisionRequired.types';

export const useGetQuantityOfAllOpenRequestsWithParticipantDecisionRequired = (
    params: UseGetQuantityOfAllOpenRequestsWithParticipantDecisionRequiredRequestParams,
    queryOptions?: QueryOptions<UseGetQuantityOfAllOpenRequestsWithParticipantDecisionRequiredResponse>
) => {
    return useGetRequestLegacy<UseGetQuantityOfAllOpenRequestsWithParticipantDecisionRequiredResponse>(
        companiesApiPaths.getQuantityOfAllOpenRequestsWithParticipantDecisionRequired,
        params,
        { queryOptions }
    );
};
