export const linkedInService = {
    init() {
        const _linkedin_partner_id = '1373716';

        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);

        (function (l) {
            if (!l) {
                window.lintrk = function (a: unknown, b: unknown) {
                    window.lintrk.q.push([a, b]);
                };

                window.lintrk.q = [];
            }

            let s = document.getElementsByTagName('script')[0];
            let b = document.createElement('script');

            b.type = 'text/javascript';
            b.async = true;
            b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
            s.parentNode?.insertBefore(b, s);
        })(window.lintrk);
    },
    trackSignUp() {
        if (typeof window.lintrk === 'function') {
            window.lintrk('track', { conversion_id: 11085426 });
        }
    },
};
