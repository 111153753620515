import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.AddCommentPopup', {
    popupTitle: 'Add a comment about the changes made',
    description: `
        You are initiating the update of the current workflow.{br}
        To let the users know why the changes have been made, please, leave a comment here. {br}{br}
        This is optional.
    `,
    buttonText: 'Confirm',
    buttonHint: `
        By pressing this button you confirm saving all changes that have been made in the current workflow.
        The comment field is optional and might be left empty.
    `,
    addCommentPlaceholder: 'Add a comment',
});
