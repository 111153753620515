import { TextEditor } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import debounce from 'lodash/debounce';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

import * as stringFilter from '../../data/filters/stringFilter';

const i18nPrefix = 'reports.filters.StringFilter';
const messages = defineMessages({
    placeholder: {
        id: `${i18nPrefix}.placeholder`,
        defaultMessage: 'Enter value',
    },
});

const StyledTextEditor = styled(TextEditor)`
    width: 220px;
`;

interface StringFilterProps {
    filter: stringFilter.StringFilter;
    onFilterChange(filter: stringFilter.StringFilter): void;
}

const StringFilter: FC<StringFilterProps> = (props) => {
    const { filter, onFilterChange } = props;

    const [value, setValue] = useState(filter.value || '');

    const changeFilter = (value: string) => {
        onFilterChange({
            ...filter,
            value,
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedChangeFilter = useCallback(debounce(changeFilter, 200), []);

    useEffect(() => {
        debouncedChangeFilter(value);
    }, [value, debouncedChangeFilter]);

    return (
        <StyledTextEditor value={value} onChange={setValue} placeholder={intl.formatMessage(messages.placeholder)} />
    );
};

export default memo(StringFilter);
