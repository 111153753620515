/**
 * Developer: Stepan Burguchev
 * Date: 2/9/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { stateTree } from 'modules/data';
import { ImmutableObject, updateWithout } from 'modules/immutable';

import { Action, DELETE_COMPANIES_RESPONSE } from '../actions/index';

export default function (
    state: ImmutableObject<stateTree.Entities>,
    action: Action
): ImmutableObject<stateTree.Entities> {
    switch (action.type) {
        case DELETE_COMPANIES_RESPONSE:
            return updateWithout(state, 'companies', ...action.payload.request.companies);

        default:
            return state;
    }
}
