import { domain } from 'modules/data';

import {
    BackendCompaniesBillMatchingSettings,
    BackendCompaniesPurchaseOrdersMatchingSettings,
    UseGetMatchingSettingsResponseBackend,
} from './useGetMatchingSettings.types';

export const mapData = (data: UseGetMatchingSettingsResponseBackend) => ({
    billMatchingSettings: mapCompanyBillMatchingSettings(data.billMatchingSettings),
    purchaseOrderMatchingSettings: mapCompanyPurchaseOrderMatchingSettings(data.purchaseOrderMatchingSettings),
});

const mapCompanyBillMatchingSettings = (
    value: BackendCompaniesBillMatchingSettings
): domain.CompanyBillMatchingSettings => ({
    addMatchedPOsRequestersAsApproversForBill: value.addMatchedPOsRequestersAsApproversForBill,
    autoMatchingEnabled: value.autoMatchingEnabled,
    manualMatchingEnabled: value.manualMatchingEnabled,
    canMatchBillsWithRetrospectivePurchaseOrders: value.retrospectivePOMatchingAllowed || false,
    allowApprovalOfNotMatchedBills: value.notMatchedBillsApprovalAllowed,
    notMatchedBillsApprovalThreshold: value.notMatchedBillsApprovalThreshold,
    allowApprovalOfOverbudgetBills: value.overbudgetMatchBillsApprovalAllowed,
    overbudgetBillsApprovalThreshold: value.overbudgetMatchBillsApprovalThreshold,
    overbudgetBillsApprovalPercentageThreshold: value.overbudgetMatchBillsApprovalPercentageThreshold,
    amountType: value.amountType,
    insufficientBudgetMatchBillsApprovalAllowed: value.insufficientBudgetMatchBillsApprovalAllowed,
    insufficientBudgetMatchBillsApprovalThreshold: value.insufficientBudgetMatchBillsApprovalThreshold,
    insufficientBudgetMatchBillsApprovalPercentageThreshold:
        value.insufficientBudgetMatchBillsApprovalPercentageThreshold,
});

const mapCompanyPurchaseOrderMatchingSettings = (
    value: BackendCompaniesPurchaseOrdersMatchingSettings
): domain.CompanyPurchaseOrderMatchingSettings => ({
    autoMarkingPurchaseOrderAsBilledEnabled: value.autoMarkingPurchaseOrderAsBilledEnabled,
    autoUnmarkingPurchaseOrderAsBilledEnabled: value.autoUnmarkingPurchaseOrderAsBilledEnabled,
});
