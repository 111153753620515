import { QueryOptions } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetRequestLegacy } from 'shared/data/api';

import { integrationsApiPaths } from '../paths';
import { UseGetContactsBackend, UseGetContactsParams, UseGetContactsResponse } from './useGetContacts.types';

export const useGetContacts = (
    params: UseGetContactsParams,
    queryOptions?: QueryOptions<UseGetContactsBackend, UseGetContactsResponse>
) =>
    useGetRequestLegacy<UseGetContactsBackend, UseGetContactsResponse>(integrationsApiPaths.getContacts, params.data, {
        queryOptions,
        mapData: objectHelpers.pascalCaseToCamelCase,
    });

export const useGetContactsBackend = (
    params: UseGetContactsParams,
    queryOptions?: QueryOptions<UseGetContactsBackend>
) =>
    useGetRequestLegacy<UseGetContactsBackend>(integrationsApiPaths.getContacts, params.data, {
        queryOptions,
    });
