import { stateTree } from 'modules/data';
import { mergeDeep } from 'modules/immutable';

import { AnyAction } from '../types/Action';

const mergeEntitiesReducer = (state: stateTree.Entities, action: AnyAction): stateTree.Entities => {
    // Automatically merges entities when they exist in a fired action
    if (action.entities) {
        return mergeDeep(state, action.entities);
    }

    return state;
};

export default mergeEntitiesReducer;
