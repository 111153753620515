import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.profile.ProfilePopup.PasskeysSettings', {
    title: 'Passkeys and security keys',
    setup: 'Setup',
    addPassKey: 'Add passkey',
    addSecurityKey: 'Add security key',
    description:
        'With passkeys, you can securely sign in to your ApprovalMax account using just your fingerprint, face, screen lock or security key. Passkeys and security keys can also be used as a second step when signing in with your password. Make sure that you keep your screen locks private and security keys safe, so that only you can use them.',
    errorText: 'Failed to add a passkey.',
});
