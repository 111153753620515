/**
 * Developer: Stepan Burguchev
 * Date: 4/17/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { compareHelpers, errorHelpers, objectHelpers } from '@approvalmax/utils';
import map from 'lodash/map';
import { domain, stateTree } from 'modules/data';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { ExpandedUser } from './types';

export const userComparatorAsc = compareHelpers.comparatorFor<ExpandedUser>(
    compareHelpers.stringComparator2AscI,
    'displayName'
);
export const userComparatorDesc = compareHelpers.comparatorFor<ExpandedUser>(
    compareHelpers.stringComparator2DescI,
    'displayName'
);

function getAbbreviation(
    firstName: string | undefined | null,
    lastName: string | undefined | null,
    displayName: string
) {
    if (firstName && lastName) {
        return (firstName[0] + lastName[0]).toUpperCase();
    } else {
        if (!displayName) {
            return '';
        }

        const words = displayName.split(' ').filter((x) => x.length > 0);

        if (words.length === 0) {
            return '';
        }

        return (words[0][0] + (words.length > 1 ? words[1][0] : words[0].length > 1 ? words[0][1] : '')).toUpperCase();
    }
}

export function getDisplayName(
    firstName: string | undefined | null,
    lastName: string | undefined | null,
    email: string
) {
    return ((firstName || '') + ' ' + (lastName || '')).trim() || email || '';
}

export function expandUser(user: domain.User): ExpandedUser {
    const displayName = getDisplayName(user.firstName, user.lastName, user.userEmail);

    return {
        ...user,
        displayName,
        abbreviation: getAbbreviation(user.firstName, user.lastName, displayName),
    };
}

export const findUserById: (state: stateTree.State, userId: string) => ExpandedUser | null = createCachedSelector(
    (state: stateTree.State, userId: string) => state.entities.users[userId],
    (user) => {
        if (!user) {
            return null;
        }

        return expandUser(user);
    }
)((state: stateTree.State, userId: string) => userId);

export function getUserById(state: stateTree.State, userId: string): ExpandedUser {
    const user = findUserById(state, userId);

    if (!user) {
        throw errorHelpers.notFoundError(userId);
    }

    return user;
}

export const getUsers: (state: stateTree.State) => ExpandedUser[] = createSelector(
    (state: stateTree.State) => state.entities.users,
    (users) => map(users, expandUser)
);

export const getNewUsers: (state: stateTree.State, companyId: string) => ExpandedUser[] = createSelector(
    (state: stateTree.State, companyId: string) => {
        return state.entities.companies[companyId].tempUsers || objectHelpers.emptyObject();
    },
    (users) => Object.values(users).map(expandUser)
);
