import { StepBackupCodes } from './components/StepBackupCodes/StepBackupCodes';
import { StepBackupEmail } from './components/StepBackupEmail/StepBackupEmail';
import { StepBackupEmailVerify } from './components/StepBackupEmailVerify/StepBackupEmailVerify';
import { StepBackupMethods } from './components/StepBackupMethods/StepBackupMethods';
import { StepCodeScan } from './components/StepCodeScan/StepCodeScan';
import { StepCodeVerify } from './components/StepCodeVerify/StepCodeVerify';

export const fieldNameStateId = 'stateId';
export const fieldNameSetupCode = 'setupCode';
export const fieldNameSetupVerificationCode = 'setupVerificationCode';
export const fieldNameBackupMethod = 'backupMethod';
export const fieldNameCodesAreSaved = 'codesAreSaved';
export const fieldNameEmail = 'email';
export const fieldNameEmailVerificationCode = 'emailVerificationCode';

export const steps = {
    codeScan: <StepCodeScan />,
    codeVerify: <StepCodeVerify />,
    backupMethods: <StepBackupMethods />,
    backupCodes: <StepBackupCodes />,
    backupEmailEnter: <StepBackupEmail />,
    backupEmailVerify: <StepBackupEmailVerify />,
};

export enum BackupMethodOptions {
    backupCodes = 'backupCodes',
    secretQuestions = 'secretQuestions',
    backupEmailAddress = 'backupEmailAddress',
}
