import { atom, selector } from 'recoil';

import type { ConfirmationManager } from './ConfirmationManager.types';

export const confirmationManagerState = atom<Array<ConfirmationManager>>({
    key: 'confirmationManagerState',
    default: [],
});

export const activeConfirmationState = selector({
    key: 'activeConfirmationState',
    get: ({ get }) => {
        const confirmations = get(confirmationManagerState);

        return confirmations.find((confirmation) => confirmation.open);
    },
});
