import { Button, Field, TextButton } from '@approvalmax/ui';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import { useStepEmailConfirm } from './StepEmailConfirm.hooks';
import { messages } from './StepEmailConfirm.messages';
import {
    ButtonResend,
    CodeFieldEditor,
    CodeFieldRow,
    Content,
    Description,
    Email,
    EmailRow,
    Root,
    SubTitle,
} from './StepEmailConfirm.styles';
import { StepEmailConfirmProps } from './StepEmailConfirm.types';

const qa = bemFactory.qa('pro-two-fa-setup-email-confirm');

const StepEmailConfirm: FC<StepEmailConfirmProps> = memo((props) => {
    const { onPrev, title } = props;

    const { inputRef, email, emailCode, onChange, onSubmit, onResend, isError, isLoading } = useStepEmailConfirm(props);

    const canNext = Boolean(emailCode.trim()) && !isLoading;

    return (
        <Root qa={qa()} title={title}>
            <Content>
                <SubTitle>{messages.subTitle}</SubTitle>

                <EmailRow>
                    <Email>{email}</Email>

                    <TextButton execute={onPrev} disabled={isLoading} upperCased={false} underlined>
                        {messages.changeEmailButton}
                    </TextButton>
                </EmailRow>

                <Description>{messages.description}</Description>

                <Field title={messages.codeFieldTitle} errorText={isError && messages.wrongCode}>
                    <CodeFieldRow>
                        <CodeFieldEditor
                            focusOnMount
                            disabled={isLoading}
                            value={emailCode}
                            onChange={onChange}
                            placeholder={messages.emailCodePlaceholder}
                            invalid={isError}
                            ref={inputRef}
                            onEnter={onSubmit}
                        />

                        <ButtonResend execute={onResend} disabled={isLoading}>
                            {messages.resendCodeButton}
                        </ButtonResend>
                    </CodeFieldRow>
                </Field>

                <Button execute={onSubmit} disabled={!canNext || isLoading}>
                    {messages.nextButton}
                </Button>
            </Content>
        </Root>
    );
});

export default StepEmailConfirm;
