/**
 * Developer: Stepan Burguchev
 * Date: 4/1/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './avatar.scss';

import { RawAvatar } from '@approvalmax/ui';
import { errorHelpers, intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import {
    InvitationUser20x20Icon,
    InvitationUser30x30Icon,
    NotInvitedUserIcon,
    PendingUser20x20Icon,
    PendingUser30x30Icon,
    PendingUserIcon,
} from 'modules/sprites';
import React, { forwardRef } from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages } from 'react-intl';

const i18nPrefix = 'ui.avatar';
const messages = defineMessages({
    invitedUserTitle: {
        id: `${i18nPrefix}.invitedUserTitle`,
        defaultMessage: '{name} has not yet accepted the invitation to this organisation',
    },
    notInvitedUserTitle: {
        id: `${i18nPrefix}.notInvitedUserTitle`,
        defaultMessage: '{name} has not yet been invited to this organisation',
    },
    rejectedUserTitle: {
        id: `${i18nPrefix}.rejectedUserTitle`,
        defaultMessage: '{name} rejected the invitation to this organisation',
    },
});

export interface Props {
    user: selectors.types.ExpandedUser | selectors.types.ExpandedCompanyUser;
    size: number;
    className?: string;
    title?: string | null;
}

const Avatar = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { className, user, size, title } = props;

    const bem = bemFactory.block('common-ui-avatar');

    let status = domain.CompanyUserStatus.Active;

    if (selectors.company.isExpandedCompanyUser(user)) {
        status = user.status;
    }

    let computedTitle = title === undefined ? user.userEmail : title || undefined;

    switch (status) {
        case domain.CompanyUserStatus.Active:
            return (
                <RawAvatar
                    userAbbreviation={user.abbreviation}
                    userAvatarUrl={user.avatar}
                    className={className}
                    size={size}
                    title={computedTitle}
                    ref={ref}
                />
            );

        case domain.CompanyUserStatus.Invited: {
            if (title === undefined) {
                computedTitle = intl.formatMessage(messages.invitedUserTitle, {
                    name: user.displayName,
                });
            }

            let icon = <PendingUserIcon width={20} height={20} />;

            if (size <= 30) {
                icon = <PendingUser30x30Icon width={16} height={16} />;
            }

            if (size <= 20) {
                icon = <PendingUser20x20Icon width={12} height={12} />;
            }

            return (
                <div
                    ref={ref as React.Ref<HTMLDivElement>}
                    className={bem.add(className)()}
                    title={computedTitle}
                    style={{
                        width: `${size}px`,
                        height: `${size}px`,
                    }}
                >
                    {icon}
                </div>
            );
        }

        case domain.CompanyUserStatus.NotInvited:
        case domain.CompanyUserStatus.Rejected: {
            if (title === undefined) {
                computedTitle =
                    status === domain.CompanyUserStatus.NotInvited
                        ? intl.formatMessage(messages.notInvitedUserTitle, {
                              name: user.displayName,
                          })
                        : intl.formatMessage(messages.rejectedUserTitle, {
                              name: user.displayName,
                          });
            }

            let icon = <NotInvitedUserIcon width={18} height={14} />;

            if (size <= 30) {
                icon = <InvitationUser30x30Icon width={16} height={12} />;
            }

            if (size <= 20) {
                icon = <InvitationUser20x20Icon width={12} height={10} />;
            }

            return (
                <div
                    ref={ref as React.Ref<HTMLDivElement>}
                    className={bem.add(className)()}
                    title={computedTitle}
                    style={{
                        width: `${size}px`,
                        height: `${size}px`,
                    }}
                >
                    {icon}
                </div>
            );
        }

        default:
            throw errorHelpers.assertNever(status);
    }
});

export default Avatar;
