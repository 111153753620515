import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.Drawer.CompanyAlerts', {
    subscriptionExpires: 'The Organisation expires on {dateOfExpire} and will be available in read-only mode.',
    subscriptionExpiredLink: 'Please add the Organisation to the Subscription or purchase a new one.',
    retired: 'Organisation is Retired. All data is available in read-only mode.',
    trialExpiredAndReadonly: 'The organisation trial expired. All data is available in read-only mode.',
    expiredAndReadonly: 'The organisation subscription expired. All data is available in read-only mode.',
    subscribeAfterTrial: "The organisation's trial has expired. Subscribe now to restore your financial controls.",
    subscribeNow: 'Subscribe now',
});
