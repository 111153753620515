import { recoilHelpers } from '@approvalmax/utils';
import { atom, selector } from 'recoil';

export const pinDrawerStorage = atom({
    key: 'pinDrawerStorage',
    default: false,
    effects: [recoilHelpers.connectToLocalStorage('pinnedDrawer')],
});

export const pinDrawerState = selector<boolean | undefined>({
    key: 'pinDrawerState',
    get: ({ get }) => {
        const pinned = get(pinDrawerStorage);
        const open = get(openDrawerStorage);

        return pinned && !open;
    },
    set: ({ set }, newValue) => {
        if (newValue ?? true) {
            set(openDrawerStorage, false);
        }

        set(pinDrawerStorage, newValue);
    },
});

const openDrawerStorage = atom({
    key: 'openDrawerStorage',
    default: false,
});

export const openDrawerState = selector<boolean | undefined>({
    key: 'openDrawerState',
    get: ({ get }) => {
        const pinned = get(pinDrawerState);
        const open = get(openDrawerStorage);

        return open && !pinned;
    },
    set: ({ set }, newValue) => {
        if (newValue ?? true) {
            set(pinDrawerState, false);
        }

        set(openDrawerStorage, Boolean(newValue));
    },
});

export const displayingDrawerState = atom({
    key: 'displayingDrawerState',
    default: false,
});

export const collapseDrawerState = selector({
    key: 'collapseDrawerState',
    get: ({ get }) => {
        const pinned = get(pinDrawerState);
        const open = get(openDrawerState);

        return !open && !pinned;
    },
});
