import { toast } from '@approvalmax/ui/src/components';
import { useCallback, useMemo } from 'react';
import { useCopyToClipboard, useMount } from 'react-use';

import {
    useTwoFaWizardController,
    useTwoFaWizardFormContext,
    useTwoFaWizardStep,
} from '../../TwoFaEnablingWizard.hooks';
import { messages } from './StepCodeScan.messages';

export const useStepCodeScan = () => {
    const form = useTwoFaWizardFormContext();
    const copyToClipboard = useCopyToClipboard()[1];

    const { setWizardController } = useTwoFaWizardController();
    const { setWizardStep } = useTwoFaWizardStep();

    useMount(() => {
        setWizardController(() => ({
            title: messages.title,
            onNext: async () => {
                const isValid = await form.trigger();

                if (isValid) {
                    setWizardStep('codeVerify');
                }
            },
        }));
    });

    const { setupCode: setupCodeLine, stateId } = form.watch();

    const setupCode = useCodeFormatter(setupCodeLine || '');

    const handleCopyToClipboard = useCallback(() => {
        if (setupCode) {
            copyToClipboard(setupCode);

            toast.success(messages.notificationCodeCopied);
        }
    }, [copyToClipboard, setupCode]);

    return {
        stateId,
        setupCode,
        handleCopyToClipboard,
    };
};

export const useCodeFormatter = (code: string) => {
    return useMemo(() => {
        const chunks = code.match(/.{1,4}/g) || [];

        return chunks.join(' ');
    }, [code]);
};
