import { useMutation } from '@tanstack/react-query';
import { api } from 'services/api';

import { UseChangeGrnStatusOptions } from './useChangeGrnStatus.types';

export const useChangeGrnStatus = (options: UseChangeGrnStatusOptions = {}) => {
    return useMutation(
        async (params) => {
            return api.xeroGrn.changeGrnStatus(params).then((response) => ({
                data: {},
                dataBackend: response,
            }));
        },
        {
            ...options,
        }
    );
};
