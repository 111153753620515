import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, stateTree } from 'modules/data';
import { createSelector } from 'reselect';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { linksData } from '../links';
import { fieldSettingsLearnMoreLinks } from '../statics/index';
import { FieldSettingsLearnMoreLinksData, FieldSettingsLinkCollection, LinksCollection, LinksData } from './types';

type GetLinksArgument = {
    integrationType?: domain.IntegrationType;
    integrationCode?: domain.IntegrationCode | null;
    linksData: LinksData | FieldSettingsLearnMoreLinksData;
};

const getLinks = ({ integrationType, integrationCode, linksData }: GetLinksArgument) => {
    const linksDataByIntegrationType = linksData[integrationType || domain.IntegrationType.None];

    if (!linksDataByIntegrationType) {
        throw errorHelpers.notFoundError('Integration type not found');
    }

    const linksByIntegrationCode = integrationCode ? linksDataByIntegrationType[integrationCode] || {} : {};
    const commonLinks = linksDataByIntegrationType.common || {};

    return {
        ...commonLinks,
        ...linksByIntegrationCode,
    };
};

export const getLinksByIntegration = (
    integrationType?: domain.IntegrationType,
    integrationCode?: domain.IntegrationCode | null
): LinksCollection =>
    getLinks({
        linksData,
        integrationCode,
        integrationType,
    }) as LinksCollection;

export const getSettingsLinksByIntegration = (
    integrationType?: domain.IntegrationType,
    integrationCode?: domain.IntegrationCode | null
): FieldSettingsLinkCollection =>
    getLinks({
        linksData: fieldSettingsLearnMoreLinks,
        integrationCode,
        integrationType,
    }) as FieldSettingsLinkCollection;

export const getPricingLinkOfAdvancedPlan: (
    state: stateTree.State,
    company?: selectors.types.ExpandedCompany,
    isMultiple?: boolean
) => string = createSelector(
    (state: stateTree.State, company?: selectors.types.ExpandedCompany) =>
        company ? selectors.template.getTemplatesByCompanyId(state, company.id) : [],
    (state: stateTree.State) => selectors.profile.getProfileUser(state),
    (_state: stateTree.State, company?: selectors.types.ExpandedCompany) => company,
    (state: stateTree.State, company?: selectors.types.ExpandedCompany) =>
        company?.subscriptionId ? selectors.subscription.getSubscriptionById(state, company.subscriptionId) : null,
    (_state: stateTree.State, company_?: selectors.types.ExpandedCompany, isMultiple?: boolean) => Boolean(isMultiple),
    // eslint-disable-next-line max-params
    (templates, me, company, subscription, isMultiple) => {
        const usesPremiumWorkflows = templates.some((template) =>
            [null, domain.IntegrationCode.XeroBillBatchPayment].includes(template.integrationCode)
        );
        const isAdvancedPlan =
            company &&
            [domain.CompanyPlan.XeroAdvanced, domain.CompanyPlan.QBooksAdvanced].includes(company.licenseProductPlan);
        const hasDirectBillingSubscription = subscription?.billingSystem === domain.SubscriptionBillingSystem.Direct;

        if (company && company.author === me.id && !hasDirectBillingSubscription && !isMultiple) {
            const planPrivilege =
                usesPremiumWorkflows || isAdvancedPlan
                    ? domain.OrganisationPlanPrivilege.Premium
                    : domain.OrganisationPlanPrivilege.Advanced;

            return company.subscriptionId
                ? getAccountUrl(AccountPath.upgradeSubscription, {
                      subscriptionId: company.subscriptionId,
                      companyId: company.id,
                      plan: planPrivilege,
                      action: 'showUpgradeWarning',
                      origin: 'wa_pricing_link_upgrade',
                  })
                : getAccountUrl(AccountPath.buyNow, {
                      companyId: company.id,
                      plan: planPrivilege,
                      action: 'showUpgradeWarning',
                      origin: 'wa_pricing_link_buy',
                  });
        } else {
            return 'https://approvalmax.com/pricing?stay';
        }
    }
);
