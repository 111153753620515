import { Reference } from '@approvalmax/types';
import { statics } from 'modules/common';
import { backend } from 'modules/data';

export function parseQBooksCurrency(value: backend.IntegrationsCurrency): QBooksCurrency {
    return {
        ...(statics.currency.findCurrencyByCode(value.ISOCode) || {
            id: value.ISOCode,
            text: value.ISOCode,
        }),
        rateToCompanyCurrency: value.RateToCompanyCurrency!,
    };
}

export interface QBooksCurrency extends Reference {
    rateToCompanyCurrency: number;
}
