import { domHelpers } from '@approvalmax/utils';
import {
    ComponentProps,
    forwardRef,
    ForwardRefExoticComponent,
    memo,
    MemoExoticComponent,
    RefAttributes,
    useCallback,
    useMemo,
} from 'react';

import { Flex } from '../../../Flex/Flex';
import Checkbox from '../../Checkbox';
import GroupController from '../GroupController/GroupController';
import { GroupContext } from './Group.context';
import { useCheckedValues } from './Group.hooks';
import { ChildrenComponents, GroupProps } from './Group.types';

/**
 * The `Checkbox.Group` component allows to use `Checkbox` components as a group. The selected values will be provided as an array.
 * You can also pass some properties from `Checkbox` to `Checkbox.Group` to apply to all checkboxes.
 */
const Group = memo(
    forwardRef<HTMLInputElement, GroupProps>((props, ref) => {
        const { children, spacing, onChange: onChangeSrc, value, options, direction, qa, ...restProps } = props;

        const { toggleCheckedValues, getCheckedStatus } = useCheckedValues(value);

        const onChange = useCallback<Required<ComponentProps<typeof Checkbox>>['onChange']>(
            (_, event) => {
                const updatedCheckedValues = toggleCheckedValues(event.target.checked, event.target.value);

                onChangeSrc && onChangeSrc(updatedCheckedValues, event);
            },
            [onChangeSrc, toggleCheckedValues]
        );

        const contextValue = useMemo(() => {
            return {
                contextProps: {
                    ...restProps,
                    onChange,
                    getCheckedStatus,
                },
                ref,
            };
        }, [getCheckedStatus, onChange, ref, restProps]);

        return (
            <Flex
                inline
                spacing={spacing}
                direction={direction}
                data-qa={domHelpers.generateDataQa(qa, 'checkbox-group')}
            >
                <GroupContext.Provider value={contextValue}>
                    {options?.map((option) => <Checkbox {...option} key={option.value} />)}

                    {children}
                </GroupContext.Provider>
            </Flex>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<GroupProps & RefAttributes<HTMLInputElement>>> & ChildrenComponents;

Group.Controller = GroupController;

export default Group;
