import { objectHelpers } from '@approvalmax/utils';

import {
    UseGetNetSuiteCacheStatusResponse,
    UseGetNetSuiteCacheStatusResponseBackend,
} from './useGetNetSuiteCacheStatus.types';

export const mapData = (response: UseGetNetSuiteCacheStatusResponseBackend): UseGetNetSuiteCacheStatusResponse => {
    return response.data.cacheObjects.map((item) => {
        const mappedItem = objectHelpers.pascalCaseToCamelCase(item);

        return {
            ...mappedItem,
            id: mappedItem.refDataType,
        };
    });
};
