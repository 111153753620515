import { Spacing } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { Navigation } from '../Navigation/Navigation';
import { useAllCompaniesLinksItems } from './AllCompaniesMenu.hooks';
import { AllCompaniesMenuProps } from './AllCompaniesMenu.types';

export const AllCompaniesMenu: FC<AllCompaniesMenuProps> = memo((props) => {
    const { onClick } = props;

    const allCompaniesLinksItems = useAllCompaniesLinksItems();

    return (
        <>
            <Navigation items={allCompaniesLinksItems} onClick={onClick} />

            <Spacing height={8} />
        </>
    );
});

AllCompaniesMenu.displayName = 'AllCompaniesMenu';
