import { ReloadIcon } from '@approvalmax/ui';
import { Button, Dropdown } from '@approvalmax/ui/src/components';
import SyncContent from 'pages/requestList/containers/SyncIntegrationButton/components/SyncContent/SyncContent';
import { memo, useCallback, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { matchPath } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userBetaFeaturesStatusState } from 'shared/states';
import { getPath, Path } from 'urlBuilder';

import { SyncDropdownContent } from '../SyncDropdownContent/SyncDropdownContent';

const qa = bemFactory.qa('reql-page');

const Sync = memo(() => {
    const isNoCompaniesPage = matchPath(location.pathname, getPath(Path.noCompanies));

    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const togglePanelOpen = useCallback(() => {
        setIsPanelOpen((current) => !current);
    }, []);

    const closePanel = useCallback(() => {
        setIsPanelOpen(false);
    }, []);

    const { isBelgradeUISync } = useRecoilValue(userBetaFeaturesStatusState);

    if (isNoCompaniesPage) return null;

    return (
        <Dropdown
            width={isBelgradeUISync ? 472 : 600}
            maxHeight={500}
            display='inline-flex'
            open={isPanelOpen}
            onOpen={togglePanelOpen}
            activator={
                <Button icon noPadding data-qa={qa('sync-button')}>
                    <ReloadIcon size={24} />
                </Button>
            }
        >
            {isBelgradeUISync ? (
                <SyncDropdownContent onPopupClose={closePanel} />
            ) : (
                <SyncContent onPopupClose={closePanel} />
            )}
        </Dropdown>
    );
});

export default Sync;
