import uniqueId from 'lodash/uniqueId';
import { api } from 'services/api';

import { backend } from '../../../index';

export const select = async (selectTransfer: backend.transfers.TemplateSelectTransfer) => {
    let templates = await api.templates.select(selectTransfer);

    if (templates?.Templates?.length) {
        const newTemplates = templates.Templates.map((template) => ({
            ...template,
            Steps: template.Steps.map((step) => ({
                ...step,
                StepId: uniqueId('step.'),
            })),
        }));

        templates = {
            ...templates,
            Templates: newTemplates,
        };
    }

    return templates;
};
