import { addArrayItem, immutable, ImmutableArray, removeArrayItem } from 'modules/immutable';

import {
    Action,
    CLONE_XERO_LINE_ITEMS,
    CLONE_XERO_LINE_ITEMS_ALL,
    LOAD_PAGE_DATA,
    REMOVE_XERO_LINE_ITEMS,
    REMOVE_XERO_LINE_ITEMS_ALL,
} from '../../actions';

export interface XeroLineItemExtra {
    lineItemId: string;
    rootSplitItemId: string;
}

export type XeroLineItemExtraType = ImmutableArray<XeroLineItemExtra>;

const INITIAL_STATE: XeroLineItemExtraType = immutable<XeroLineItemExtra>([]);

export default function (state = INITIAL_STATE, action: Action): XeroLineItemExtraType {
    switch (action.type) {
        case CLONE_XERO_LINE_ITEMS:
        case CLONE_XERO_LINE_ITEMS_ALL: {
            return action.payload.lineItemIds.reduce<XeroLineItemExtraType>((prev, lineItemId, index) => {
                let newArray = prev;

                if (!state.some((x) => x.lineItemId === lineItemId)) {
                    // Root item, it should be added as well
                    newArray = addArrayItem<XeroLineItemExtra>(prev, {
                        lineItemId: lineItemId,
                        rootSplitItemId: lineItemId,
                    });
                }

                const rootSplitItemId = newArray.find((x) => x.lineItemId === lineItemId)!.rootSplitItemId;

                return addArrayItem<XeroLineItemExtra>(newArray, {
                    lineItemId: action.payload.newLineItems[index].id,
                    rootSplitItemId,
                });
            }, state);
        }

        case REMOVE_XERO_LINE_ITEMS: {
            let lineItems = action.payload.lineItemIds.reduce<XeroLineItemExtraType>((prev, lineItemId) => {
                return removeArrayItem(prev, (li) => li.lineItemId === lineItemId);
            }, state);

            return lineItems;
        }

        case REMOVE_XERO_LINE_ITEMS_ALL:
            return INITIAL_STATE;

        case LOAD_PAGE_DATA:
            return immutable([]);

        default:
            return state;
    }
}
