import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'allotment/dist/style.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'modules/styles/styles/index.scss';
import '@approvalmax/ui/src/components/InitialLoading/InitialLoading.styles.scss';
import 'lib';

import { createRoot } from 'react-dom/client';

import RootLayout from './app/layout';

const container = document.getElementById('app-root')!;
const root = createRoot(container);

root.render(<RootLayout />);
