import { QueryOptions } from '@approvalmax/data';
import { useGetLazyRequest } from 'shared/data/api';

import { authApiPaths } from '../paths';
import { UseFido2CredentialOptionsResponse } from './useFido2CredentialOptions.types';

export const useFido2CredentialOptions = (queryOptions?: QueryOptions<UseFido2CredentialOptionsResponse>) => {
    return useGetLazyRequest<{}, UseFido2CredentialOptionsResponse>(authApiPaths.fido2CredentialOptions, {
        apiVersion: 'v2',
        queryOptions,
    });
};
