import { Popup } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { discardOpenMatrix } from '../../actions';
import { getActiveMatrix } from '../../selectors/pageSelectors';
import MatrixPopupContent from '../MatrixPopupContent/MatrixPopupContent';

const MatrixPopup = memo(() => {
    const dispatch = useDispatch();
    const activeMatrix = useSelector(getActiveMatrix);

    const stepIndex = activeMatrix && 'stepIndex' in activeMatrix ? activeMatrix.stepIndex : null;
    const handleClose = activeMatrix?.onClose;

    const closeMatrixPopup = useCallback(
        (open: boolean) => {
            if (!open) {
                dispatch(discardOpenMatrix());

                if (typeof handleClose === 'function') {
                    handleClose(stepIndex);
                }
            }
        },
        [dispatch, handleClose, stepIndex]
    );

    return (
        <Popup open={Boolean(activeMatrix)} onToggle={closeMatrixPopup} preventAutoClose fullScreen>
            <MatrixPopupContent />
        </Popup>
    );
});

MatrixPopup.displayName = 'MatrixPopup';

export default MatrixPopup;
