import { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import { mapData } from './useGetNetSuiteAmortizationSchedules.map';
import {
    UseGetNetSuiteAmortizationSchedulesBackend,
    UseGetNetSuiteAmortizationSchedulesRequestParams,
    UseGetNetSuiteAmortizationSchedulesResponse,
} from './useGetNetSuiteAmortizationSchedules.types';

export const useGetNetSuiteAmortizationSchedules = (
    params: UseGetNetSuiteAmortizationSchedulesRequestParams,
    queryOptions?: QueryOptions<UseGetNetSuiteAmortizationSchedulesBackend, UseGetNetSuiteAmortizationSchedulesResponse>
) => {
    return useGetRequestLegacy<UseGetNetSuiteAmortizationSchedulesBackend, UseGetNetSuiteAmortizationSchedulesResponse>(
        integrationsApiPaths.getNetSuiteAmortizationSchedules,
        params,
        {
            queryOptions,
            mapData,
        }
    );
};
