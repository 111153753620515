import reduce from 'lodash/reduce';
import { domain, stateTree } from 'modules/data';
import { mergeDeep, mergeDeepIn, mergeIn, setIn, update } from 'modules/immutable';

import {
    Action,
    LOAD_INITIAL_APP_DATA,
    LOAD_USER_PROFILE_DATA_RESPONSE,
    RESPOND_TO_INVITATION,
    RESPOND_TO_INVITATION_FAILURE,
    RESPOND_TO_INVITATION_RESPONSE,
    UPDATE_ENTITIES,
    UPDATE_PROFILE,
    UPDATE_PROFILE_DELEGATES,
} from '../actions';

export default function (state: stateTree.Entities, action: Action): stateTree.Entities {
    switch (action.type) {
        case LOAD_INITIAL_APP_DATA:
            state = mergeDeep(state, action.payload.entities);
            state = {
                ...state,
                companyInvitations: action.payload.entities.companyInvitations || {},
                practiceInvitations: action.payload.entities.practiceInvitations || {},
            };

            return state;

        case RESPOND_TO_INVITATION: {
            const diff = action.payload.responses.reduce((m, res) => {
                m[res.invitation.id] = {
                    status: domain.CompanyInvitationStatus.Responding,
                    response: res.response,
                };

                return m;
            }, {} as any);

            return mergeDeepIn(state, ['companyInvitations'], diff);
        }

        case RESPOND_TO_INVITATION_RESPONSE: {
            const diff = action.payload.request.responses.reduce((m, res) => {
                m[res.invitation.id] = {
                    status: domain.CompanyInvitationStatus.Responded,
                };

                return m;
            }, {} as any);

            return mergeDeep(mergeDeepIn(state, ['companyInvitations'], diff), action.entities);
        }

        case RESPOND_TO_INVITATION_FAILURE: {
            const diff = action.payload.request.responses.reduce((m, res) => {
                m[res.invitation.id] = {
                    status: domain.CompanyInvitationStatus.Untouched,
                    response: null,
                };

                return m;
            }, {} as any);

            return mergeDeepIn(state, ['companyInvitations'], diff);
        }

        case UPDATE_PROFILE:
            return mergeIn(state, ['users', action.payload.profileUserId], action.payload.userPartial);

        case UPDATE_PROFILE_DELEGATES:
            return update(state, 'companies', (companies) => {
                return reduce(
                    companies,
                    (m, c) => {
                        let newDelegates = c.delegates.filter((d) => d.userId !== action.payload.profileUserId);

                        const delegate = action.payload.delegates.find((d) => d.companyId === c.id);

                        if (delegate && delegate.delegateUserId) {
                            newDelegates = newDelegates.concat({
                                userId: action.payload.profileUserId,
                                delegateUserId: delegate.delegateUserId,
                            });
                        }

                        m[c.id] = {
                            ...c,
                            delegates: newDelegates,
                        };

                        return m;
                    },
                    {} as any
                );
            });

        case LOAD_USER_PROFILE_DATA_RESPONSE:
            return setIn(state, ['users', action.payload.userId, 'profileInfo'], action.payload.profileInfo);

        case UPDATE_ENTITIES:
            return mergeDeep(state, action.payload.entities);

        default:
            return state;
    }
}
