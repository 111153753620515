import { stringHelpers } from '@approvalmax/utils';
import { REQUEST_NOTE_EXCLUDED_ATTRIBUTES } from 'modules/common/constants/commonConstants';
import { STANDALONE_EXCLUDED_ATTRIBUTES } from 'modules/common/constants/standaloneConstants';
import { backend, domain, State } from 'modules/data';

import { getContext } from '../pageSelectors';

export function getStandaloneRequestTransfer(state: State, request: domain.StandaloneRequest) {
    let result: backend.Transfer<
        backend.transfers.RequestEditTransfer & { templateVersion: number } // TODO: Remove backend typing stub when backend is ready
    > = {
        requestId: request.id,
        amount: request.amount,
        currency: request.currency,
        attachments: request.attachments.map((a) => a.id),
        requestNote: stringHelpers.cleanHTMLAttributes(
            REQUEST_NOTE_EXCLUDED_ATTRIBUTES,
            request.requestNote.value || ''
        ),
        templateVersion: getContext(state).templateVersion,
        companyId: request.companyId,
        standaloneDetails: {
            name: request.details.name,
            date: request.details.date,
            description: stringHelpers.cleanHTMLAttributes(
                STANDALONE_EXCLUDED_ATTRIBUTES,
                request.details.description.value
            ),
            fieldValues: request.details.fieldValues
                .filter((fv) => fv.value)
                .map((fv) => ({
                    fieldId: fv.field.id,
                    fieldValue: fv.value
                        ? {
                              fieldValueId: fv.value.id,
                              value: fv.value.text,
                          }
                        : null,
                })),
        },
    };

    return result;
}
