import { oauthHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { amplitudeService } from 'services/amplitude';
import { gaService } from 'services/ga';

import { storageService } from '../../../services/storage';
import { SsoPanelVariant } from './SsoPanel.types';

export const getSSOMethodForAnalytics = (provider: domain.OAuthProvider) => {
    switch (provider) {
        case domain.OAuthProvider.Xero:
            return 'xero_sso';

        case domain.OAuthProvider.QBooks:
            return 'qbo_sso';

        case domain.OAuthProvider.Google:
            return 'google_sso';

        case domain.OAuthProvider.Microsoft:
            return 'microsoft_sso';

        default:
            return provider;
    }
};

export const sendEvents = (oauthProvider: domain.OAuthProvider, variant: keyof typeof SsoPanelVariant) => {
    if (variant === SsoPanelVariant.register) {
        amplitudeService.sendData('signup: click on sign up button', {
            'signup type': `${oauthProvider} sso`,
        });

        void gaService.sendEvent('sign_up', {
            method: getSSOMethodForAnalytics(oauthProvider),
        });
    }
};

export const redirectToSso = (provider: domain.OAuthProvider, ssoUrl?: string, allowedEmail?: string) => {
    if (!ssoUrl) return;

    const finalRedirectUrl = oauthHelpers.encodeOAuthState<domain.OAuthUiState>(ssoUrl, {
        type: 'sso',
        provider,
        startingPoint: domain.SsoStartingPoint.ApprovalMax,
        allowedEmail,
    });

    storageService.setAuthenticated(false);
    window.location.href = finalRedirectUrl;
};
