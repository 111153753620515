import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import { UsePublishRequestData, UsePublishRequestResponse } from './usePublishRequest.types';

export const usePublishRequest = () => {
    return useMutateLegacy<RequestDataParams<UsePublishRequestData>, UsePublishRequestResponse>(
        requestsApiPaths.publish
    );
};
