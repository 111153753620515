import { domain } from 'modules/data';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, DISCARD_COPY_TEMPLATE_POPUP, OPEN_COPY_TEMPLATE_POPUP } from '../../actions';

export interface CopyTemplateData {
    template: domain.Template;
}

export type ActiveCopyTemplate = ImmutableObject<CopyTemplateData> | null;

export default function activeCopyTemplateReducer(
    state: ActiveCopyTemplate = null,
    action: Action
): ActiveCopyTemplate {
    switch (action.type) {
        case OPEN_COPY_TEMPLATE_POPUP:
            return immutable<CopyTemplateData>({
                template: action.payload.template,
            });

        case DISCARD_COPY_TEMPLATE_POPUP:
            return null;

        default:
            return state;
    }
}
