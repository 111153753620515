import styled from 'styled-components';

import { font } from '../../../styles';

export const Root = styled.div`
    background: white;
    padding: 28px 60px 50px;
`;

export const Title = styled.div`
    margin-bottom: 28px;
    ${font(18, '#333333', 'semibold')};
`;

export const Buttons = styled.div`
    display: flex;
    gap: 15px;
    margin-top: 30px;
`;
