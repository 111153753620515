import { useLayout } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { hocs as pageHocs } from 'modules/page/hocs';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { PAGE_ID } from '../../config';
import usePageData from '../../hooks/usePageData';
import PageContent from './PageContent';
import PageSkeleton from './PageSkeleton';

export const Page = memo(() => {
    const activePageId = useSelector(selectors.navigation.getActivePageId);

    useLayout({ hasHeader: true, hasDrawer: true });
    usePageData();

    return activePageId !== PAGE_ID ? <PageSkeleton /> : <PageContent />;
});

export default pageHocs.withProtected({ Page });
