import { DefaultPageLayout } from 'modules/page';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components/macro';

const Card = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    background: #fff;
`;

const Line = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 18px;
`;

const PageSkeleton = () => {
    return (
        <DefaultPageLayout documentTitle='No companies'>
            <Card>
                <Line>
                    <Skeleton width={40} height={40} borderRadius='50%' />
                </Line>

                <Line>
                    <Skeleton width={200} height={20} count={2} />
                </Line>

                <Line>
                    <Skeleton width={300} height={15} count={2} />
                </Line>

                <Line>
                    <Skeleton width={270} height={20} />
                </Line>

                <Line>
                    <Skeleton width={210} height={120} style={{ marginRight: 20 }} />

                    <Skeleton width={210} height={120} />
                </Line>
            </Card>
        </DefaultPageLayout>
    );
};

PageSkeleton.dissplayName = 'PageSkeleton';

export default memo(PageSkeleton);
