export interface RequestListProps {
    requestIds: string[];
    checkedRequests: string[];
    setCheckedRequests: (requests: string[]) => void;
}

export enum ColumnId {
    FriendlyName = 'FriendlyName',
    Date = 'Date',
    Requester = 'Requester',
    WorkflowStep = 'WorkflowStep',
    Amount = 'Amount',
    Status = 'Status',
}
