import { Action, LOAD_PAGE_DATA } from '../../actions';
import { CompanyRequestListFilter, RequestListFilter, TemplateRequestListFilter } from '../../config';

export type ActiveFilterType = TemplateRequestListFilter | CompanyRequestListFilter | RequestListFilter | null;

const INITIAL_STATE: ActiveFilterType = null;

export default function (state = INITIAL_STATE, action: Action): ActiveFilterType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return action.payload.filter;

        default:
            return state;
    }
}
