import { ConfirmationEmptyTransparentIcon, ConfirmationFullIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import { FC, memo, ReactNode, useCallback } from 'react';
import styled, { css } from 'styled-components';

interface WithCheckedProp {
    checked: boolean;
}

const Root = styled.div<WithCheckedProp>`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 70px;
    margin-bottom: 15px;
    padding-left: 17px;
    border: solid 1px #dfdfdf;
    cursor: pointer;

    ${(props) =>
        props.checked &&
        css`
            padding-left: 16px;
            border: solid 2px #77aa84;
        `}

    svg {
        fill: #777777;
    }
`;

const AnswerText = styled.div`
    margin-left: 12px;
    ${font(14, '#000')}
`;

interface AnswerProps {
    checked: boolean;
    text: ReactNode;
    value: boolean;
    onClick: (value: boolean) => void;
}

const Answer: FC<AnswerProps> = (props) => {
    const { checked, text, value, onClick } = props;

    const onClickHandler = useCallback(() => {
        onClick(value);
    }, [value, onClick]);

    return (
        <Root checked={checked} onClick={onClickHandler}>
            {checked ? (
                <ConfirmationFullIcon width={26} height={26} />
            ) : (
                <ConfirmationEmptyTransparentIcon width={26} height={26} />
            )}

            <AnswerText>{text}</AnswerText>
        </Root>
    );
};

export default memo(Answer);
