import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { SelectProps } from '../../Select.types';
import { createdItemsState } from '../MenuItems/MenuItems.states';

export const useItems = <Item extends Record<string, any>, Multiple extends boolean>(
    props: Pick<SelectProps<Item, Multiple>, 'value' | 'items'> &
        Required<Pick<SelectProps<Item, Multiple>, 'itemIdKey'>>
) => {
    const { value, items: propsItems, itemIdKey } = props;
    const createdItems = useRecoilValue<Item[]>(createdItemsState);

    const values = useMemo(() => {
        if (typeof value === 'undefined' || value === null || value === '') return [];

        if (!Array.isArray(value)) return value ? [value] : [];

        return value;
    }, [value]);

    const items = useMemo(
        () =>
            uniqBy([...propsItems, ...createdItems], itemIdKey).map((item) => ({
                ...item,
                selected: values.includes(item[itemIdKey]),
            })),
        [createdItems, itemIdKey, propsItems, values]
    );

    const selectedItems = useMemo(() => items.filter((item) => item.selected), [items]);

    return { items, selectedItems };
};
