import { QueryOptions } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetRequestLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import {
    UseGetNetSuiteSubsidiaryParams,
    UseGetNetSuiteSubsidiaryResponse,
    UseGetNetSuiteSubsidiaryResponseBackend,
} from './useGetNetSuiteSubsidiary.types';

export const useGetNetSuiteSubsidiary = (
    params: UseGetNetSuiteSubsidiaryParams,
    queryOptions: QueryOptions<UseGetNetSuiteSubsidiaryResponseBackend, UseGetNetSuiteSubsidiaryResponse>
) => {
    const { enabled = true } = queryOptions;

    return useGetRequestLegacy<UseGetNetSuiteSubsidiaryResponseBackend, UseGetNetSuiteSubsidiaryResponse>(
        integrationsApiPaths.getNetSuiteSubsidiary,
        {},
        {
            queryOptions: {
                ...queryOptions,
                enabled: !!params.companyId && enabled,
            },
            params,
            mapData: objectHelpers.pascalCaseToCamelCase,
            method: 'get',
        }
    );
};
