export const COMMENT_MAX_ATTACHMENT_COUNT = 10;

export const COMMENT_MAX_ATTACHMENT_FILESIZE = 3000000;

export const COMMENT_MAX_ATTACHMENT_FILESIZE_TEXT = '3MB';

export const COMMENT_ALLOWED_FILE_EXTENSIONS = [
    '.bmp',
    '.numbers',
    '.rtf',
    '.csv',
    '.odf',
    '.rtf',
    '.text',
    '.doc',
    '.ods',
    '.tif',
    '.docx',
    '.odt',
    '.tiff',
    '.eml',
    '.pages',
    '.txt',
    '.gif',
    '.pdf',
    '.xls',
    '.jpeg',
    '.jpg',
    '.png',
    '.xlsx',
    '.ppt',
    '.zip',
    '.keynote',
    '.pptx',
    '.7z',
    '.msg',
    '.rar',
];
