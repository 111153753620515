import { FC, memo } from 'react';

import { Navigation } from '../Navigation/Navigation';
import { useReportsLinkItems } from './ReportsMenu.hooks';
import { ReportsMenuProps } from './ReportsMenu.types';

export const ReportsMenu: FC<ReportsMenuProps> = memo((props) => {
    const { company, onClick } = props;

    const reportsLinkItems = useReportsLinkItems(company);

    return <Navigation items={reportsLinkItems} onClick={onClick} />;
});

ReportsMenu.displayName = 'ReportsMenu';
