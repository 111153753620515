export const useAnnouncementMessage = () => {
    const announcement = window.ApprovalMax.announcement || {};

    const hasMessage = Boolean(announcement.id && announcement.message);

    return {
        hasMessage,
        id: announcement.id,
        message: announcement.message,
    };
};
