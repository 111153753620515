import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { getUseCasesSignUp } from 'modules/utils';

import { mapTextByUseCases } from './PopupContent.constants';
import { messages } from './PopupContent.messages';

export const getProductPlanId = (company?: selectors.types.ExpandedCompany | null) => {
    switch (company?.integration?.integrationType) {
        case domain.IntegrationType.Xero:
            return domain.CompanyPlan.Xero;

        case domain.IntegrationType.QBooks:
            return domain.CompanyPlan.QBooks;

        case domain.IntegrationType.NetSuite:
            return domain.CompanyPlan.NetSuite;

        case domain.IntegrationType.Dear:
            return domain.CompanyPlan.Cin7CorePremium;

        default:
            return domain.CompanyPlan.Xero;
    }
};

export const getExpiredTextByUseCase = () => {
    const casesFromStorage = getUseCasesSignUp();

    if (!casesFromStorage) {
        return messages.expiredTrialUseCaseDefault;
    }

    const text = mapTextByUseCases[casesFromStorage];

    if (!text) {
        return messages.expiredTrialUseCaseDefault;
    }

    return text;
};
