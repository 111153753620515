import { domain } from 'modules/data';

export const calculateXeroLineItemExpectedPrice = (
    integrationCode: domain.IntegrationCode,
    lineItem?: domain.XeroItem
) => {
    let expectedPrice;

    if (
        integrationCode === domain.IntegrationCode.XeroQuote ||
        integrationCode === domain.IntegrationCode.XeroInvoice
    ) {
        expectedPrice = lineItem && lineItem.salesDetails ? lineItem.salesDetails.unitPrice : undefined;
    } else {
        expectedPrice = lineItem && lineItem.purchaseDetails ? lineItem.purchaseDetails.unitPrice : undefined;
    }

    return expectedPrice;
};
