import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionSupplierBankAccountRequired',
    {
        title: 'Supplier bank account',
        subtitle: 'Define which validation rule must be applied to the Supplier’s bank account field:',
        optionFilled: 'Must be filled',
        optionEmpty: 'Can be empty',
    }
);
