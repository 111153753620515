import { toast } from '@approvalmax/ui/src/components';
import { hooks, routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { selectors, statics } from 'modules/common';
import { cancelActivePopup, optimisticallySetDelegate } from 'modules/profile/actions';
import { useDispatch, useSelector } from 'modules/react-redux';
import { useCurrentUser, useMyTimeZone } from 'modules/utils';
import { useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { companiesApiPaths, useDelegations, useUpdateDelegations, type UseUpdateDelegationsData } from 'shared/data';
import { delegationHelpers } from 'shared/helpers';

import { getMyDelegatesPopupData } from '../../selectors/moduleSelectors';
import { messages } from './MyDelegatesPopup.messages';

export const useMyDelegatesPopupData = () => {
    const me = useCurrentUser();
    const myTimeZone = useMyTimeZone();

    const { data: delegatesData, isLoading } = useDelegations({
        staleTime: 60 * 1000,
    });

    const delegates = useSelector((state) => {
        const data = getMyDelegatesPopupData(state);

        return (data?.delegates || []).map((myCompanyDelegate) => {
            const company = selectors.company.getCompanyById(state, myCompanyDelegate.companyId);
            const team = selectors.company.getCompanyTeam(state, company);
            const companyDelegation = delegatesData?.items.find((delegation) => delegation.companyId === company.id);
            const delegate = companyDelegation?.delegates.find(
                (delegate) => delegate.fromUser.userId === me.databaseId
            );

            // Then data arrives, we need to recalculate dates according to the
            // TZ shift between local TZ and user profile TZ
            // Later on submit we will do a backward shift to the user profile TZ (not local)
            const myTz = statics.timeZone.findTimeZoneById(myTimeZone);
            const myTzUtcOffset = statics.timeZone.getUtcOffset(myTz);

            return {
                company,
                delegateId: delegate?.toUser?.email ?? null,
                delegate: delegate?.toUser,
                dateFrom: delegate?.dateFrom
                    ? statics.timeZone.addOffset(delegate.dateFrom, myTzUtcOffset).toDate()
                    : null,
                dateTo: delegate?.dateTo ? statics.timeZone.addOffset(delegate.dateTo, myTzUtcOffset).toDate() : null,
                originalDateFrom: delegate?.dateFrom,
                originDateTo: delegate?.dateTo,
                timeZone: myTimeZone,
                possibleDelegates: team.filter((teamUser) => teamUser.id !== me.id),
            };
        });
    });

    const filterOutUnchanged = (newDelegations: UseUpdateDelegationsData) => {
        return newDelegations.filter((delegation) => {
            const oldDelegation = delegatesData?.items.find(
                (oldDelegation) => oldDelegation.companyId === delegation.companyId
            );

            return delegation.delegates.some((delegate) => {
                const oldDelegate = oldDelegation?.delegates.find(
                    (oldDelegate) => oldDelegate.fromUser.userId === delegate.fromUser.userId
                );

                return (
                    delegate.toUser?.userId !== oldDelegate?.toUser.userId ||
                    delegationHelpers.compareDates(delegate?.dateFrom, oldDelegate?.dateFrom) ||
                    delegationHelpers.compareDates(delegate?.dateTo, oldDelegate?.dateTo)
                );
            });
        });
    };

    return {
        delegates,
        filterOutUnchanged,
        me,
        myTimeZone,
        isLoading,
    };
};

export const useDelegatesFilter = (delegates: ReturnType<typeof useMyDelegatesPopupData>['delegates']) => {
    const { filteredData, handleSearchQueryChange, searchQuery } = hooks.useFuzzySearch(delegates, {
        keys: ['company.displayName'],
    });

    return { delegates: filteredData, handleSearchQueryChange, searchQuery };
};

export const useDelegatesFormContent = ({
    filterOutUnchanged,
    delegates,
    myTimeZone,
    me,
}: Pick<ReturnType<typeof useMyDelegatesPopupData>, 'filterOutUnchanged' | 'delegates' | 'me' | 'myTimeZone'>) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const companyId = useSelector(selectors.navigation.getActiveCompanyId);

    const { mutateAsync: updateDelegations, isLoading: isLoadingUpdateDelegations } = useUpdateDelegations({
        onSuccess: () => {
            if (companyId) {
                queryClient.invalidateQueries([
                    routerHelpers.pathToUrl(companiesApiPaths.companyDelegations, { companyId }),
                ]);
            }
        },
    });

    const form = useForm({ values: { delegates } });
    const { fields } = useFieldArray({ control: form.control, name: 'delegates' });
    const watchFieldArray = form.watch('delegates');
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index],
        };
    });
    const {
        formState: { touchedFields },
    } = form;

    const handleSubmit = form.handleSubmit((values) => {
        const delegations = values.delegates
            .filter((delegate) => {
                const { company } = delegate;
                const isExpired = company.flags.isExpired && !company.flags.isGraceSubscription;
                const isDisabledCompany = company.flags.isRetired || isExpired || company.isReadonly;

                // this is a bit of extra optimization. We exclude all unset (and untouched) companies.
                // but this doesn't check companies where were set before, but this time was untouched.
                // to handle that we need to transform dates to UTC first and later do that filtration
                // before submit
                const setOrChanged = Boolean(
                    delegate.dateFrom || delegate.dateTo || delegate.originalDateFrom || delegate.originDateTo
                );

                return !isDisabledCompany && setOrChanged;
            })
            .map((delegate) => {
                const delegation: UseUpdateDelegationsData[number] = {
                    companyId: delegate.company.id,
                    delegates: [],
                };
                const possibleDelegate = delegate.possibleDelegates.find((user) => user.id === delegate.delegateId);

                if (possibleDelegate) {
                    // These are revert time zones shifting modification which may have
                    // been done earlier within `form.register(`delegates.${index}.timeZone`)`
                    const delegateTimeZone = delegate.timeZone
                        ? statics.timeZone.findTimeZoneById(delegate.timeZone)
                        : undefined;
                    const selectedTzOffset = statics.timeZone.getUtcOffset(delegateTimeZone);

                    const dateFrom = statics.timeZone.subtractOffset(delegate.dateFrom, selectedTzOffset).toISOString();
                    const dateTo = statics.timeZone.subtractOffset(delegate.dateTo, selectedTzOffset).toISOString();

                    delegation.delegates.push({
                        fromUser: { userId: me.databaseId },
                        toUser: { userId: possibleDelegate.databaseId },
                        dateFrom,
                        dateTo,
                    });
                } else {
                    delegation.delegates.push({
                        fromUser: { userId: me.databaseId },
                    });
                }

                return delegation;
            });

        const filteredDelegations = filterOutUnchanged(delegations);

        if (!filteredDelegations.length) {
            dispatch(cancelActivePopup());

            return;
        }

        void updateDelegations({ data: filteredDelegations })
            .then(() => {
                dispatch(
                    optimisticallySetDelegate({
                        me,
                        delegates: filteredDelegations.map((delegation, index) => ({
                            companyId: delegation.companyId,
                            delegateUserProfileId: values.delegates[index].delegate?.userId,
                            delegateId: values.delegates[index].delegateId,
                            delegateFrom: delegation.delegates?.[0].dateFrom ?? null,
                            delegateTo: delegation.delegates?.[0].dateTo ?? null,
                        })),
                    })
                );
                toast.success(messages.delegatesSet);
            })
            .catch(() => {});
    });

    const handleDelete = useCallback(
        (index: number) => () => {
            // additionally reset validation errors
            form.resetField(`delegates.${index}.delegateId`, { defaultValue: null });
            form.resetField(`delegates.${index}.dateFrom`, { defaultValue: null });
            form.resetField(`delegates.${index}.dateTo`, { defaultValue: null });
            form.resetField(`delegates.${index}.timeZone`, { defaultValue: undefined });
        },
        [form]
    );

    const applyDelegateToChangeSideEffects = (delegate: (typeof controlledFields)[number], index: number) => {
        form.register(`delegates.${index}.delegateId`, {
            onChange: (event) => {
                if (event.type === 'change') {
                    if (!delegate.dateFrom) {
                        // this is always your profile tz, so no need to shift
                        const myTz = statics.timeZone.findTimeZoneById(myTimeZone);
                        const myTzUtcOffset = statics.timeZone.getUtcOffset(myTz);

                        form.setValue(
                            `delegates.${index}.dateFrom`,
                            statics.timeZone.addOffset(new Date(), myTzUtcOffset).toDate()
                        );
                    }
                }
            },
        });

        form.register(`delegates.${index}.timeZone`, {
            onChange: (event) => {
                if (event.target.value) {
                    // changing time zones within this field is a read-only action. We shouldn't change
                    // the real dates because of that. The purpose of that is to show to user the time
                    // in a time zone of his delegate.
                    //
                    // We decided to not modify DatePicker for that, but do a real form modifications and
                    // later during SUBMIT do a revert modifications
                    const oldTz = delegate.timeZone ? statics.timeZone.findTimeZoneById(delegate.timeZone) : undefined;
                    const newTz = statics.timeZone.findTimeZoneById(event.target.value);
                    const oldUtcOffset = statics.timeZone.getUtcOffset(oldTz);
                    const newUtcOffset = statics.timeZone.getUtcOffset(newTz);
                    const tzShift = statics.timeZone.getOffsetShift(newUtcOffset, oldUtcOffset);

                    if (delegate.dateFrom) {
                        form.setValue(
                            `delegates.${index}.dateFrom`,
                            statics.timeZone.addOffset(delegate.dateFrom, tzShift).toDate(),
                            { shouldTouch: false }
                        );
                    }

                    if (delegate.dateTo) {
                        form.setValue(
                            `delegates.${index}.dateTo`,
                            statics.timeZone.addOffset(delegate.dateTo, tzShift).toDate(),
                            { shouldTouch: false }
                        );
                    }
                }
            },
        });

        form.register(`delegates.${index}.dateFrom`, {
            onChange: (event) => {
                // can't compare w/ original because user can change field
                // several times and eventually back to the original
                if (event.target.value && touchedFields.delegates?.[index]?.dateFrom) {
                    // reset `isTouched` state to prevent setValue on `timeZone` field change
                    form.resetField(`delegates.${index}.dateFrom`, {
                        defaultValue: event.target.value,
                    });
                }
            },
        });

        form.register(`delegates.${index}.dateTo`, {
            onChange: (event) => {
                if (event.target.value) {
                    if (event.target.value && touchedFields.delegates?.[index]?.dateTo) {
                        // reset `isTouched` state to prevent setValue on `timeZone` field change
                        form.resetField(`delegates.${index}.dateTo`, {
                            defaultValue: event.target.value,
                        });
                    }

                    if (!delegate.dateFrom) {
                        form.setValue(
                            `delegates.${index}.dateFrom`,
                            delegationHelpers.getNowAtTimeZone(delegate.timeZone)
                        );
                    }
                }
            },
        });
    };

    return {
        form,
        controlledFields,
        isLoadingUpdateDelegations,
        handleSubmit,
        handleDelete,
        applyDelegateToChangeSideEffects,
    };
};
