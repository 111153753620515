import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';

import { moduleDef } from '..';
import { Module } from '../reducers/moduleReducer';

type State = stateTree.State;

function getModule(state: State) {
    return selectors.module.getModule<Module>(state, moduleDef.id);
}

export function getActivePopupId(state: State): string | null {
    const activePopup = getModule(state).activePopup;

    return activePopup ? activePopup.id : null;
}

export function getActivePopup<TPopupData>(state: State): TPopupData {
    return getModule(state).activePopup as any as TPopupData;
}

export const getActiveCompanyConnectionErrorPopupId = (state: State): string | null => {
    const activePopup = getModule(state).companyConnectionErrorPopup;

    return activePopup?.id ?? null;
};

export const getActiveCompanyConnectionErrorPopup = (state: State) => {
    return getModule(state).companyConnectionErrorPopup;
};
