import { useCallback } from 'react';

export interface CopyToClipboardCallback<T extends string> {
    (value: string, propertyName?: T): void;
}

export interface CopyToClipboardHandler {
    <T extends string>(value: string, propertyName?: T, cb?: CopyToClipboardCallback<T>): void;
}

/**
 * Creates a memoized function that allows to copy string data to clipboard
 * provides an optional callback function to react on finished action
 */
export const useCopyToClipboard = (): CopyToClipboardHandler => {
    return useCallback((value, name, cb) => {
        const listener = (e: ClipboardEvent) => {
            e.clipboardData?.setData('text/plain', value);
            e.preventDefault();
            document.removeEventListener('copy', listener);
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');

        cb && cb(value, name);
    }, []);
};
