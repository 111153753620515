import { Button } from '@approvalmax/ui';
import { setReloginResult } from 'modules/first-start/actions/common';
import { ReloginResult } from 'modules/first-start/reducers/moduleReducer';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { routingService } from 'services/routing';

import { messages } from './ReloginAcceptInvitationPopup.messages';
import { Buttons, Root, Title } from './ReloginAcceptInvitationPopup.styles';
import { ReloginAcceptInvitationPopupProps } from './ReloginAcceptInvitationPopup.types';

const ReloginAcceptInvitationPopup: FC<ReloginAcceptInvitationPopupProps> = memo((props) => {
    const { onClose } = props;

    const dispatch = useDispatch();

    const handleStayLoggedIn = useCallback(() => {
        routingService.pushToDefaultPath();
        dispatch(setReloginResult(ReloginResult.StayLoggedIn));
        onClose();
    }, [dispatch, onClose]);

    const handleRelogin = useCallback(() => {
        dispatch(setReloginResult(ReloginResult.AllowRelogin));
        onClose();
    }, [dispatch, onClose]);

    return (
        <Root>
            <Title>{messages.title}</Title>

            <div>{messages.text}</div>

            <Buttons>
                <Button colorTheme='red' execute={handleStayLoggedIn}>
                    {messages.stayLoggedIn}
                </Button>

                <Button execute={handleRelogin}>{messages.logout}</Button>
            </Buttons>
        </Root>
    );
});

export default ReloginAcceptInvitationPopup;
