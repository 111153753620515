import { PageSlice } from 'modules/page';

import { PAGE_ID } from './config';
import entitiesReducer from './reducers/entitiesReducer';
import navigationReducer from './reducers/navigationReducer';
import pageReducer from './reducers/pageReducer';
import sessionReducer from './reducers/sessionReducer';

export const page: PageSlice = {
    id: PAGE_ID,
    pageReducer: pageReducer,
    reducers: {
        entities: entitiesReducer,
        navigation: navigationReducer,
        session: sessionReducer,
    },
};
