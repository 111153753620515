import { FC, memo } from 'react';
import styled from 'styled-components';

import BaseImage from './BaseImage';

const Party = styled(BaseImage)`
    background-position: 0 -410px;
`;

const ImageParty: FC = () => {
    return <Party />;
};

export default memo(ImageParty);
