import { Alert, Text } from '@approvalmax/ui/src/components';
import { AlertIcon } from '@approvalmax/ui/src/icons';
import { ADD_USERS, addUsers } from 'app/(workspace)/[companyId]/users/resources/actions';
import sortBy from 'lodash/sortBy';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { amplitudeService } from 'services/amplitude';
import { AddUsersList } from 'shared/components';

import { FooterButtons, HeaderTitle, ScrollableContent } from '..';
import { messages } from './UsersStep.messages';
import { UsersStepProps } from './UsersStep.types';

export const UsersStep: FC<UsersStepProps> = memo((props) => {
    const { template, amplitudeParams, onNext, onBack } = props;

    const [userEmailsWithRoles, setUserEmailsWithRoles] = useState<Map<string, domain.CompanyUserRole>>(new Map());
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, template.companyId));
    const team = useSelector((state: State) => selectors.company.getCompanyTeam(state, company));
    const profile = useSelector(selectors.profile.findProfileUser);
    const isAddUsersProgress = useSelector((state: State) => selectors.ui.isOperationInProgress(state, ADD_USERS));

    const teamItems = useMemo(
        () =>
            team.map((user) => ({
                email: user.userEmail,
                role: user.role,
            })),
        [team]
    );
    const readOnlyItems = useMemo(
        () => teamItems.filter(({ email }) => email !== profile?.id),
        [profile?.id, teamItems]
    );
    const items = useMemo(() => {
        const items = Array.from(userEmailsWithRoles, ([email, role]) => ({
            email,
            role,
        }));

        return sortBy(items, 'email');
    }, [userEmailsWithRoles]);

    const dispatch = useDispatch();

    useMount(() => {
        amplitudeService.sendData('Workflow: land on wizard: users step', amplitudeParams);
    });

    const handleNext = useCallback(async () => {
        if (userEmailsWithRoles.size) {
            await dispatch(
                addUsers({
                    company,
                    userEmailsWithRoles: Object.fromEntries(userEmailsWithRoles.entries()),
                    doInvite: false,
                })
            );
        }

        onNext(userEmailsWithRoles.size);
    }, [company, dispatch, onNext, userEmailsWithRoles]);

    const handleAddToList = useCallback(
        (validEmails: string[]) => {
            setUserEmailsWithRoles((emailsWithRoleMap) => {
                validEmails.forEach((email) => {
                    if (!emailsWithRoleMap.has(email)) {
                        const role = selectors.company.getCompanyUserRole(company, email);

                        emailsWithRoleMap.set(
                            email,
                            role === domain.CompanyUserRole.None ? domain.CompanyUserRole.Participant : role
                        );
                    }
                });

                return new Map(emailsWithRoleMap);
            });
        },
        [company]
    );

    const handleDeleteEmail = useCallback(
        (email: string) => {
            userEmailsWithRoles.delete(email);
            setUserEmailsWithRoles(new Map(userEmailsWithRoles));
        },
        [userEmailsWithRoles]
    );

    return (
        <ScrollableContent
            header={
                <>
                    <HeaderTitle spacing='32 0' title={messages.title} />

                    <Alert color='midnight20' startIcon={<AlertIcon size={20} />}>
                        {messages.alert}
                    </Alert>

                    <Text font='body' fontSize='large' color='midnight80' spacing='32 0 24'>
                        {messages.description}
                    </Text>
                </>
            }
            footer={
                <FooterButtons
                    onNext={handleNext}
                    onBack={onBack}
                    isNextDisabled={isAddUsersProgress}
                    isNextLoading={isAddUsersProgress}
                />
            }
        >
            <AddUsersList
                withStickyInput
                noRoles
                items={items}
                readOnlyItems={readOnlyItems}
                onAddEmails={handleAddToList}
                onDeleteEmail={handleDeleteEmail}
            />
        </ScrollableContent>
    );
});

UsersStep.displayName = 'UsersStep';
