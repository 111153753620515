export const loadScript = (src: string) => {
    return new Promise((resolve, reject) => {
        let s = document.createElement('script');

        s.src = src;
        s.onload = resolve;
        s.onerror = reject;
        document.head.appendChild(s);
    });
};
