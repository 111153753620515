export interface Command {
    hidden?: boolean;
    disabled: boolean;
}

/**
 * Combines given commands applying AND logic for each field.
 * All commands must be hidden to hide the result.
 * All commands must be disabled to disable the result.
 * @param commands commands to combine
 */
export const combineCommandsAnd = (...commands: Command[]): Command => {
    return {
        disabled: commands.every((c) => Boolean(c.disabled)),
        hidden: commands.every((c) => Boolean(c.hidden)),
    };
};
