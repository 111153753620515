import { ApiBaseAnswer, MutationOptions, RequestDataParams } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { budgetsApiPaths, useMutate } from 'shared/data';

import {
    UseChangeQBooksBudgetsSyncData,
    UseChangeQBooksBudgetsSyncPathParams,
} from './useChangeQBooksBudgetsSync.types';

export const useChangeQBooksBudgetsSync = (
    mutationOptions?: MutationOptions<
        ApiBaseAnswer,
        RequestDataParams<UseChangeQBooksBudgetsSyncData, UseChangeQBooksBudgetsSyncPathParams>
    >
) => {
    const queryClient = useQueryClient();

    return useMutate<RequestDataParams<UseChangeQBooksBudgetsSyncData, UseChangeQBooksBudgetsSyncPathParams>>(
        budgetsApiPaths.qBooksBudgetsSync,
        {
            method: 'patch',
            apiVersion: 'v2',
            mutationOptions: {
                ...mutationOptions,
                onSuccess: (responseData, requestData, context) => {
                    const budgetsApiPath = routerHelpers.pathToUrl(budgetsApiPaths.getQBooksBudgets, {
                        companyId: requestData.pathParams?.companyId,
                    });

                    queryClient.invalidateQueries([budgetsApiPath]);

                    mutationOptions?.onSuccess?.(responseData, requestData, context);
                },
            },
        }
    );
};
