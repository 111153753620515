import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data/api';

import { authApiPaths } from '../paths';
import { UseFido2CredentialDeleteData } from './useFido2CredentialDelete.types';

export const useFido2CredentialDelete = (
    mutationOptions?: MutationOptions<any, RequestDataParams<UseFido2CredentialDeleteData>>
) => {
    return useMutate<RequestDataParams<UseFido2CredentialDeleteData>>(authApiPaths.fido2Credentials, {
        method: 'delete',
        apiVersion: 'v2',
        mutationOptions,
    });
};
