import { font } from 'modules/styles';
import styled, { css } from 'styled-components';

import { StatusBadgeMode, StatusBadgeRootProps, StatusBadgeSize } from './StatusBadge.types';

const pickSizeProperties = (props: { size: StatusBadgeSize }) => {
    switch (props.size) {
        case StatusBadgeSize.Large:
            return css`
                padding: 6px 22px;
                ${font(13, 'auto', 'bold')}
            `;

        case StatusBadgeSize.Small:
        default:
            return css`
                padding: 1px 5px;
                ${font(10, 'auto', 'bold')}
            `;
    }
};

const pickModeColors = (props: { mode: StatusBadgeMode }) => {
    switch (props.mode) {
        case StatusBadgeMode.Error:
            return css`
                color: #cb4e30;
                border-color: #cb4e30;

                svg {
                    fill: #cb4e30;
                }
            `;

        case StatusBadgeMode.Warning:
            return css`
                color: #f0984f;
                border-color: #f0984f;

                svg {
                    fill: #f0984f;
                }
            `;

        case StatusBadgeMode.Info:
            return css`
                color: #13b4db;
                border-color: #13b4db;

                svg {
                    fill: #13b4db;
                }
            `;

        case StatusBadgeMode.Success:
            return css`
                color: #4c8a5c;
                border-color: #77aa84;

                svg {
                    fill: #4c8a5c;
                }
            `;

        case StatusBadgeMode.Pending:
            return css`
                color: #a3a3a3;
                border-color: #a3a3a3;

                svg {
                    fill: #a3a3a3;
                }
            `;

        case StatusBadgeMode.Rejected:
            return css`
                color: #bd5942;
                border-color: #bd5942;

                svg {
                    fill: #bd5942;
                }
            `;

        case StatusBadgeMode.Cancelled:
        default:
            return css`
                color: #bbbaba;
                border-color: #bbbaba;

                svg {
                    fill: #bbbaba;
                }
            `;
    }
};

export const BadgeContainer = styled.div<StatusBadgeRootProps>`
    flex: 0 0 auto;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid;
    user-select: none;
    ${(props: any) => pickModeColors(props)}
    ${(props: any) => pickSizeProperties(props)}
`;

export const BadgeLabel = styled.span`
    line-height: 1.4;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
`;

export const BadgeIcon = styled.div<{ size?: StatusBadgeSize }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${(props: any) => (props.size === StatusBadgeSize.Large ? '14px' : '10px')};
    height: ${(props: any) => (props.size === StatusBadgeSize.Large ? '14px' : '10px')};
    margin-right: ${(props: any) => (props.size === StatusBadgeSize.Large ? '5px' : '2px')};
`;
