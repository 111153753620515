import { domain } from 'modules/data';

import { messages } from './AddonsWarningBanner.messages';

export const addonWarningThresholds = [domain.AddonUsageThresholds.HighOverused];
export const canManageSubscriptionsRoles = [domain.AccountMemberRole.Administrator, domain.AccountMemberRole.Manager];

export const mapAddonTypeToName = {
    [domain.AddonTypes.Pay]: messages.addonPayTitle,
    [domain.AddonTypes.Capture]: messages.addonCaptureTitle,
};
