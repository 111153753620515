import { mapBudgetImpact } from 'shared/data/types';

import { UseGetBudgetImpactsResponse, UseGetBudgetImpactsResponseBackend } from './useGetBudgetImpacts.types';

export const mapData = (data: UseGetBudgetImpactsResponseBackend): UseGetBudgetImpactsResponse => {
    return {
        currency: data.data.currency,
        budgetImpacts: data.data.budgetImpacts.map(mapBudgetImpact),
    };
};
