/**
 * Developer: Stepan Burguchev
 * Date: 6/16/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { Guid } from '@approvalmax/types';

export enum CompanyInvitationResponse {
    Accepted = 'Accepted',
    Declined = 'Declined',
}

export enum CompanyInvitationStatus {
    Untouched = 'Untouched',
    Responding = 'Responding',
    Responded = 'Responded',
}

export interface CompanyInvitation {
    id: Guid;
    companyId: Guid;
    companyName: string;
    dispatchDate: string;
    inviter: Guid;
    status: CompanyInvitationStatus;
    response?: CompanyInvitationResponse;
    responseDate?: string;
}
