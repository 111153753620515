import { errorHelpers, oauthHelpers } from '@approvalmax/utils';
import { clearSessionNotifications } from 'modules/common';
import { domain, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { api } from 'services/api';
import { routingService } from 'services/routing';
import { storageService } from 'services/storage';
import { AccountPath, getAccountUrl, getDefaultPath, getPartnerUrl, getUrl, Path } from 'urlBuilder';

import { encodeSignInUiState, SignInPostAction } from '../helpers';
import { TwoFaLoginModuleData } from '../reducers/page/moduleReducer';
import { getModule } from '../selectors/pageSelectors';

export function finishLoginFlow(options: { app?: domain.SsoLoginApplication; postAction?: string }) {
    storageService.setAuthenticated(true);

    if (options.app) {
        let appUrl;

        switch (options.app) {
            case domain.SsoLoginApplication.Account:
                appUrl = getAccountUrl(AccountPath.root);
                break;

            case domain.SsoLoginApplication.Partner:
                appUrl = getPartnerUrl();
                break;

            default:
                throw errorHelpers.assertNever(options.app);
        }

        if (options.postAction) {
            appUrl += options.postAction.startsWith('/') ? options.postAction : `/${options.postAction}`;
        }

        routingService.navigateToExternalUrl(appUrl);
    } else {
        routingService.reloadToUrl(options.postAction || getDefaultPath());
    }
}

export const SHOW_LOGIN_MODULE = 'AUTH/SHOW_LOGIN_MODULE';
export const showLoginModule = (options: {
    innerPageId?: string;
    email?: string;
    app?: domain.SsoLoginApplication;
    postAction?: string;
}) => createAction(SHOW_LOGIN_MODULE, options);

export const LOGIN = 'AUTH/LOGIN';
export const LOGIN_RESPONSE = 'AUTH/LOGIN_RESPONSE';
export const LOGIN_FAILURE = 'AUTH/LOGIN_FAILURE';

interface PasswordLoginOptions {
    email: string;
    password: string;
    app?: domain.SsoLoginApplication;
    postAction?: string;
}

export const login = (options: PasswordLoginOptions) =>
    createAsyncAction({
        request: () =>
            createAction(LOGIN, {
                email: options.email,
            }),

        response: async (request) => {
            clearSessionNotifications();

            const { RequiresTFA, TFAStateId, IsTFAAlternativeEmailEnabled, IsTFABackupCodesEnabled } =
                await api.auth.login({
                    email: options.email,
                    pwd: options.password,
                });

            return createAction(LOGIN_RESPONSE, {
                request,
                is2faEnabled: Boolean(RequiresTFA),
                stateId: TFAStateId || null,
                isTFAAlternativeEmailEnabled: Boolean(IsTFAAlternativeEmailEnabled),
                isTFABackupCodesEnabled: Boolean(IsTFABackupCodesEnabled),
            });
        },

        failure: (error) => createErrorAction(LOGIN_FAILURE, error, {}),
        didDispatchResponse: async (request, response) => {
            if (!response.is2faEnabled) {
                finishLoginFlow(options);
            }
        },
    });

export const SIGN_IN_WITH = 'AUTH/SIGN_IN_WITH';
export const SIGN_IN_WITH_RESPONSE = 'AUTH/SIGN_IN_WITH_RESPONSE';
export const SIGN_IN_WITH_FAILURE = 'AUTH/SIGN_IN_WITH_FAILURE';
export const signInWith = (options: {
    oauthProvider: domain.OAuthProvider;
    startingPoint: domain.SsoStartingPoint;
    app?: domain.SsoLoginApplication;
    postAction?: SignInPostAction;
    afterLoginRedirectUrl?: string;
    signup?: boolean;
    tenantId?: string;
}) =>
    createAsyncAction({
        request: () =>
            createAction(SIGN_IN_WITH, {
                oauthProvider: options.oauthProvider,
                startingPoint: options.startingPoint,
            }),

        response: async (request) => {
            const uiState = encodeSignInUiState({
                app: options.app,
                postAction: options.postAction,
                afterLoginRedirectUrl: options.afterLoginRedirectUrl,
                startingPoint: options.startingPoint,
                tenantId: options.tenantId,
            });

            let redirectUrl = getUrl(Path.oauth2Redirect);

            switch (options.oauthProvider) {
                case domain.OAuthProvider.Xero: {
                    const ssoStartingPoint =
                        options.startingPoint === domain.SsoStartingPoint.ApprovalMax
                            ? 'approvalmax'
                            : 'xero-marketplace';
                    const response = await api.auth.getXeroOAuthUrl({
                        redirectUrl,
                        ssoStartingPoint,
                        uiState,
                    });

                    redirectUrl = response.data.XeroOAuthUrl;
                    break;
                }

                case domain.OAuthProvider.QBooks: {
                    const ssoStartingPoint =
                        options.startingPoint === domain.SsoStartingPoint.ApprovalMax
                            ? 'approvalmax'
                            : 'qbooks-marketplace';
                    const response = await api.auth.getQBooksOAuthUrl({
                        redirectUrl,
                        ssoStartingPoint,
                        uiState,
                    });

                    redirectUrl = response.data.QBooksOAuthUrl;
                    break;
                }

                case domain.OAuthProvider.Google: {
                    const response = await api.auth.getGoogleOAuthUrl({
                        redirectUrl,
                        uiState,
                        ssoStartingPoint: 'approvalmax',
                    });

                    redirectUrl = response.data.GoogleOAuthUrl;
                    break;
                }

                case domain.OAuthProvider.Microsoft: {
                    const response = await api.auth.getMicrosoftOAuthUrl({
                        redirectUrl,
                        uiState,
                        ssoStartingPoint: 'approvalmax',
                    });

                    redirectUrl = response.data.MicrosoftOAuthUrl;
                    break;
                }

                default:
                    throw errorHelpers.assertNever(options.oauthProvider);
            }

            redirectUrl = oauthHelpers.encodeOAuthState<domain.OAuthUiState>(redirectUrl, {
                type: 'sso',
                provider: options.oauthProvider,
                startingPoint: options.startingPoint,
            });

            return createAction(SIGN_IN_WITH_RESPONSE, {
                request,
                redirectUrl,
            });
        },

        failure: (error) => createErrorAction(SIGN_IN_WITH_FAILURE, error, {}),

        didDispatchResponse: (request, response: { redirectUrl: string }) => {
            routingService.navigateToExternalUrl(response.redirectUrl);
        },
    });

export const FINISH_TWO_FA_LOGIN = 'AUTH/FINISH_TWO_FA_LOGIN';
export const FINISH_TWO_FA_LOGIN_RESPONSE = 'AUTH/FINISH_TWO_FA_LOGIN_RESPONSE';
export const FINISH_TWO_FA_LOGIN_FAILURE = 'AUTH/FINISH_TWO_FA_LOGIN_FAILURE';
export const finishTwoFaLogin = (code: string, trustThisDevice: boolean) =>
    createAsyncAction({
        request: (state: State) => {
            const data = getModule<TwoFaLoginModuleData>(state);

            return createAction(FINISH_TWO_FA_LOGIN, {
                stateId: data.stateId,
                app: data.app,
                postAction: data.postAction,
                code,
                trustThisDevice,
            });
        },

        response: async (request) => {
            await api.auth.finishTFALogin({
                stateId: request.stateId,
                code,
                isDeviceTrusted: trustThisDevice,
            });

            return createAction(FINISH_TWO_FA_LOGIN_RESPONSE, {
                request,
            });
        },

        rejectOnFailure: true,

        failure: (error) => createErrorAction(FINISH_TWO_FA_LOGIN_FAILURE, error, {}),

        didDispatchResponse: async (request) => {
            finishLoginFlow(request);
        },
    });

export const SEND_TWO_FA_LOGIN_EMAIL_CODE = 'AUTH/SEND_TWO_FA_LOGIN_EMAIL_CODE';
export const SEND_TWO_FA_LOGIN_EMAIL_CODE_RESPONSE = 'AUTH/SEND_TWO_FA_LOGIN_EMAIL_CODE_RESPONSE';
export const SEND_TWO_FA_LOGIN_EMAIL_CODE_FAILURE = 'AUTH/SEND_TWO_FA_LOGIN_EMAIL_CODE_FAILURE';
export const sendTwoFaLoginEmailCode = () =>
    createAsyncAction({
        request: (state: State) =>
            createAction(SEND_TWO_FA_LOGIN_EMAIL_CODE, {
                stateId: getModule<TwoFaLoginModuleData>(state).stateId,
            }),

        response: async (request) => {
            const response = await api.auth.sendTFALoginEmailCode({
                stateId: request.stateId,
            });

            return createAction(SEND_TWO_FA_LOGIN_EMAIL_CODE_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error) => createErrorAction(SEND_TWO_FA_LOGIN_EMAIL_CODE_FAILURE, error, {}),

        rejectOnFailure: true,
    });

export type Action = ExtractActions<
    typeof finishTwoFaLogin | typeof login | typeof sendTwoFaLoginEmailCode | typeof showLoginModule | typeof signInWith
>;
