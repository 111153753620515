import { Reference } from '@approvalmax/types';
import { arrayHelpers, compareHelpers, errorHelpers } from '@approvalmax/utils';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { selectors } from 'modules/common';
import { backend, domain } from 'modules/data';
import { asMutable } from 'modules/immutable';

import { CopyRuleCellProps } from '../components/MatrixPopupContent/MatrixPopupContent.types';
import { ExpandedMatrixLine } from '../selectors/pageSelectors';
import { ActiveMatrixData } from '../types/activeMatrixData';
import { MatrixType } from '../types/matrix';

const possibleWorkflowTypePairs: [domain.IntegrationCode | null, domain.IntegrationCode | null][] = [
    [domain.IntegrationCode.XeroCreditNotesReceivable, domain.IntegrationCode.XeroCreditNotesPayable],
    [domain.IntegrationCode.XeroCreditNotesReceivable, domain.IntegrationCode.XeroInvoice],
    [domain.IntegrationCode.XeroCreditNotesPayable, domain.IntegrationCode.XeroInvoice],
];

export function checkCopySettingsPossibility(
    fromWorkflow: domain.Template | null,
    toWorkflow: domain.Template | null
): boolean {
    if (!fromWorkflow || !toWorkflow) {
        return false;
    }

    if (fromWorkflow?.integrationCode === toWorkflow?.integrationCode) {
        return true;
    }

    return (
        possibleWorkflowTypePairs.findIndex((pair) => {
            return pair.includes(fromWorkflow.integrationCode) && pair.includes(toWorkflow.integrationCode);
        }) !== -1
    );
}

export const prepareAutoapprovalRulesForComparison = (
    autoapprovalRules: backend.transfers.TemplateAutoApproveTransfer[]
) =>
    [...autoapprovalRules]
        .sort(compareHelpers.comparatorFor(compareHelpers.stringComparator2AscI, 'name'))
        .map((matrixLine) => ({
            ...matrixLine,
            conditions: [...matrixLine.conditions].sort(
                compareHelpers.comparatorFor(compareHelpers.stringComparator2AscI, 'fieldId')
            ),
        }));

export const prepareMatrixesForComparison = (matrixTransfer: backend.transfers.TemplateStepParticipantTransfer[]) => {
    return [...matrixTransfer]
        .sort(compareHelpers.comparatorFor(compareHelpers.stringComparator2AscI, 'email'))
        .map((matrixLine) => ({
            ...matrixLine,
            rules: matrixLine.rules.map((rule) => ({
                conditions: [...rule.conditions].sort(
                    compareHelpers.comparatorFor(compareHelpers.stringComparator2AscI, 'fieldId')
                ),
            })),
        }));
};

export const prepareStepsForComparison = (steps: backend.transfers.TemplateStepTransfer[]) =>
    steps.map((step) => ({
        ...step,
        id: undefined,
        participants: prepareMatrixesForComparison(step.participants),
        editors: prepareMatrixesForComparison(step.editors),
    }));

const statusPriorityMap = {
    [domain.CompanyUserStatus.NotInvited]: 0,
    [domain.CompanyUserStatus.Rejected]: 0,
    [domain.CompanyUserStatus.Invited]: 1,
    [domain.CompanyUserStatus.Active]: 2,
};

const statusPriorityDisregardingNotInvitedMap = {
    [domain.CompanyUserStatus.NotInvited]: 1,
    [domain.CompanyUserStatus.Rejected]: 1,
    [domain.CompanyUserStatus.Invited]: 1,
    [domain.CompanyUserStatus.Active]: 2,
};

export function createUserComparator(
    team: selectors.types.ExpandedCompanyUser[],
    options: { disregardNotInvited?: boolean } = {}
) {
    const priorityMap = options.disregardNotInvited ? statusPriorityDisregardingNotInvitedMap : statusPriorityMap;

    return (a: selectors.types.ExpandedUser, b: selectors.types.ExpandedUser): number => {
        // user A
        let priorityA;
        let statusA;

        const memberA = team.find((m) => m.id === a.id);

        if (memberA) {
            statusA = memberA.status;
        } else {
            statusA = domain.CompanyUserStatus.Active;
        }

        priorityA = priorityMap[statusA];

        let priorityB;
        // user B
        let statusB;

        const memberB = team.find((m) => m.id === b.id);

        if (memberB) {
            statusB = memberB.status;
        } else {
            statusB = domain.CompanyUserStatus.Active;
        }

        priorityB = priorityMap[statusB];

        if (priorityA !== priorityB) {
            return priorityA < priorityB ? -1 : 1;
        }

        const nameA = statusA === domain.CompanyUserStatus.Active ? a.displayName : a.userEmail;
        const nameB = statusB === domain.CompanyUserStatus.Active ? b.displayName : b.userEmail;

        return compareHelpers.stringComparator2AscI(nameA, nameB);
    };
}

export const mapExactValuesForRequesterSystemPurpose = (
    exactValues: Reference[],
    users: selectors.types.ExpandedUser[]
) => {
    return exactValues.map((val) => {
        const user = users.find((u) => u.databaseId === val.id);

        return {
            id: val.id,
            text: user?.displayName || val.text,
        };
    });
};

export const calculateSelectedSteps = (steps: CopyRuleCellProps['meta']['steps'], values: string[]) => {
    const selectedIds = values ? values.map((value) => value) : [];

    return selectedIds.reduce<CopyRuleCellProps['meta']['steps']>((total, valueId) => {
        const step = steps.find((step) => step.id === valueId);

        if (step) {
            total.push(step);
        }

        return total;
    }, []);
};

export const calculateSelectedUsers = (team: selectors.types.ExpandedCompanyUser[], values: string[]) => {
    return values.reduce<selectors.types.ExpandedCompanyUser[]>((total, id) => {
        const user = team.find((teamMember) => teamMember.id === id);

        if (user) {
            total.push(user);
        }

        return total;
    }, []);
};

export const checkInvalidCondition = (condition: domain.MatrixCondition): boolean => {
    const isExactValuesCondition =
        condition.conditionType === domain.ConditionType.ExactValuesCondition ||
        condition.conditionType === domain.ConditionType.NegativeExactValuesCondition;

    if (isExactValuesCondition && condition.exactValues.length === 0) {
        return true;
    }

    const isNumericRangeCondition = condition.conditionType === domain.ConditionType.NumericRangeCondition;

    if (isNumericRangeCondition) {
        const isInvalidBelowCondition =
            condition.numericRangeConditionType === domain.NumericRangeConditionType.Below &&
            condition.numericRangeLess === null;

        const isInvalidAboveCondition =
            condition.numericRangeConditionType === domain.NumericRangeConditionType.Above &&
            condition.numericRangeGreaterEquals === null;

        const isInvalidWithinCondition =
            condition.numericRangeConditionType === domain.NumericRangeConditionType.Within &&
            (condition.numericRangeLess === null ||
                condition.numericRangeGreaterEquals === null ||
                condition.numericRangeGreaterEquals > condition.numericRangeLess);

        if (isInvalidAboveCondition || isInvalidBelowCondition || isInvalidWithinCondition) {
            return true;
        }
    }

    return false;
};

export const checkValidMatrixConditions = (
    matrix: ActiveMatrixData<ExpandedMatrixLine> | null,
    userId?: string
): boolean => {
    if (!matrix) {
        return false;
    }

    const hasInvalidCondition = matrix.data.some((line) => {
        const shouldCheckLine = userId === undefined || userId === line.user.id;

        if (!shouldCheckLine) return false;

        return line.rules.some((rule) => rule.conditions.some(checkInvalidCondition));
    });

    return !hasInvalidCondition;
};

export const isLineWithInvalidConditions = (dataItem: domain.MatrixLine) => {
    const conditions = dataItem.rules.flatMap((rule) => rule.conditions);

    const hasNoConditions = conditions.length === 0;

    const hasUnspecifiedConditions = conditions.some((condition) => {
        const isExactValuesCondition =
            condition.conditionType === domain.ConditionType.ExactValuesCondition ||
            condition.conditionType === domain.ConditionType.NegativeExactValuesCondition;

        if (isExactValuesCondition && condition.exactValues.length === 0) {
            return true;
        }

        const isNumericRangeCondition = condition.conditionType === domain.ConditionType.NumericRangeCondition;

        if (isNumericRangeCondition) {
            const isInvalidBelowCondition =
                condition.numericRangeConditionType === domain.NumericRangeConditionType.Below &&
                condition.numericRangeLess === null;

            const isInvalidAboveCondition =
                condition.numericRangeConditionType === domain.NumericRangeConditionType.Above &&
                condition.numericRangeGreaterEquals === null;

            const isInvalidWithinCondition =
                condition.numericRangeConditionType === domain.NumericRangeConditionType.Within &&
                (condition.numericRangeLess === null ||
                    condition.numericRangeGreaterEquals === null ||
                    condition.numericRangeGreaterEquals > condition.numericRangeLess);

            if (isInvalidAboveCondition || isInvalidBelowCondition || isInvalidWithinCondition) {
                return true;
            }
        }

        return false;
    });

    const hasCorrectCondition = conditions.some((condition) => {
        const isExactValuesCondition =
            condition.conditionType === domain.ConditionType.ExactValuesCondition ||
            condition.conditionType === domain.ConditionType.NegativeExactValuesCondition;

        if (isExactValuesCondition && condition.exactValues.length) {
            return true;
        }

        const isNumericRangeCondition = condition.conditionType === domain.ConditionType.NumericRangeCondition;

        if (
            isNumericRangeCondition &&
            ((condition.numericRangeConditionType === domain.NumericRangeConditionType.Below &&
                condition.numericRangeLess !== null) ||
                (condition.numericRangeConditionType === domain.NumericRangeConditionType.Above &&
                    condition.numericRangeGreaterEquals !== null) ||
                (condition.numericRangeConditionType === domain.NumericRangeConditionType.Within &&
                    (condition.numericRangeLess !== null || condition.numericRangeGreaterEquals !== null)))
        ) {
            return true;
        }

        return false;
    });

    return !hasCorrectCondition || hasNoConditions || hasUnspecifiedConditions;
};

export const getIsValidMatrix = (matrix: ActiveMatrixData<ExpandedMatrixLine>) => {
    return matrix.type === MatrixType.AutoApproval
        ? !matrix.data.find(isLineWithInvalidConditions)
        : checkValidMatrixConditions(matrix);
};

export const getMatrixAmountType = (matrixData: domain.MatrixLine[]): domain.AmountType => {
    let amountType: domain.AmountType | null = null;

    matrixData.some((line) =>
        line.rules.some((r) =>
            r.conditions.some((c) => {
                if (c.fieldSystemPurpose === domain.FieldSystemPurpose.Amount) {
                    if (c.conditionType !== domain.ConditionType.NumericRangeCondition) {
                        throw errorHelpers.invalidOperationError();
                    }

                    amountType = c.amountType;

                    return true;
                }

                return false;
            })
        )
    );

    return amountType || domain.AmountType.Gross;
};

export const getRawMatrixData = (matrix: domain.MatrixLine[]) => {
    return matrix
        .filter((line) => !line.isBackup)
        .map((line) => {
            const rules = asMutable(line.rules)
                .filter((r) => r.conditions.length > 0)
                .map((r) => {
                    let newRule = cloneDeep(r);

                    newRule.conditions.forEach((c) => {
                        if (
                            c.conditionType === domain.ConditionType.ExactValuesCondition ||
                            c.conditionType === domain.ConditionType.NegativeExactValuesCondition
                        ) {
                            c.exactValues = uniqBy(c.exactValues, 'id');
                        }
                    });

                    return newRule;
                });

            if (rules.length === 0) {
                rules.push({ conditions: [] });
            }

            return { ...line, rules };
        });
};

export const getMatrixData = (
    matrix: domain.MatrixLine[],
    users: selectors.types.ExpandedUser[],
    team: selectors.types.ExpandedCompanyUser[]
): domain.MatrixLine[] => {
    const userComparator = createUserComparator(team, { disregardNotInvited: true });

    return arrayHelpers.arraySort(getRawMatrixData(matrix), (a, b) => {
        const userA = users.find((u) => u.id === a.lineId);
        const userB = users.find((u) => u.id === b.lineId);

        if (!userA) {
            throw errorHelpers.notFoundError(`Failed to find user ${a.lineId} in the users list.`);
        }

        if (!userB) {
            throw errorHelpers.notFoundError(`Failed to find user ${b.lineId} in the users list.`);
        }

        return userComparator(userA, userB);
    });
};

export const getEditingMatrixData = (
    editingMatrix: domain.MatrixLine[],
    participantMatrix: domain.MatrixLine[]
): domain.MatrixLine[] => {
    const editingRawMatrixData = getRawMatrixData(editingMatrix);

    return participantMatrix.map((participantMatrixLine) => {
        const editingMatrixLine = editingRawMatrixData.find((line) => line.lineId === participantMatrixLine.lineId);

        if (editingMatrixLine) return editingMatrixLine;

        const newMatrixLine = {
            lineId: participantMatrixLine.lineId,
            rules: [{ conditions: [] }],
        };

        return newMatrixLine;
    });
};

export const getAutoApprovalMatrixData = (matrix: domain.MatrixLine[]): domain.MatrixLine[] => {
    return arrayHelpers.arraySort(getRawMatrixData(matrix), (a, b) => {
        return compareHelpers.stringComparator2AscI(a.lineId, b.lineId);
    });
};
