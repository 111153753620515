import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.AfterAllFeatureTrialPopup.AfterAllFeatureTrialPopupContent', {
    titleBefore: 'All-features trial for {name} expires on {date}',
    desciptionBefore: 'Want to keep the features you tried? Upgrade your {plan} plan, or they will be deactivated.',
    titleAfter: 'All-features trial for {name} organization ended on {date}',
    descriptionAfter:
        "All the features that are not included in your current {plan} plan were deactivated, but your data and settings will be stored for when you're ready to upgrade.",
    upgrade: 'Upgrade subscription',
});
