import { Reference } from '@approvalmax/types';
import { Box } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';

import { ExactAsyncCondition } from '../ExactAsyncCondition/ExactAsyncCondition';
import { anyConditionId } from './ExactAsyncConditionCell.constants';
import { conditionTypeToConditionTypeValue, getConditionItems } from './ExactAsyncConditionCell.helpers';
import { ConditionTypeValue, ExactAsyncConditionCellProps } from './ExactAsyncConditionCell.types';

export const ExactAsyncConditionCell: FC<ExactAsyncConditionCellProps> = memo((props) => {
    const { lineId, rule, field, integrationCode, condition, onConditionChange } = props;

    const fieldName = useMemo(
        () => selectors.field.getFieldNameBySystemPurpose(field.systemPurpose, integrationCode, field.name),
        [field.name, field.systemPurpose, integrationCode]
    );
    const conditionItems = useMemo(() => getConditionItems(fieldName), [fieldName]);

    const changeConditionType = useCallback(
        (conditionType: ConditionTypeValue, exactValues: Reference[]) => {
            let newCondition: domain.AlwaysTrueCondition | domain.ExactValuesCondition;

            switch (conditionType) {
                case anyConditionId:
                    newCondition = {
                        fieldId: condition.fieldId,
                        fieldName: condition.fieldName,
                        fieldSystemPurpose: condition.fieldSystemPurpose,
                        conditionType: null,
                    };
                    break;

                case domain.ConditionType.ExactValuesCondition:
                case domain.ConditionType.NegativeExactValuesCondition:
                    newCondition = {
                        fieldId: condition.fieldId,
                        fieldName: condition.fieldName,
                        fieldSystemPurpose: condition.fieldSystemPurpose,
                        conditionType,
                        exactValues: exactValues,
                    };
                    break;
            }

            onConditionChange(lineId, rule, field, newCondition);
        },
        [condition, field, lineId, onConditionChange, rule]
    );

    const conditionTypeValue = conditionTypeToConditionTypeValue(condition.conditionType);

    return (
        <Box width='172px'>
            <ExactAsyncCondition
                {...props}
                conditionTypeItems={conditionItems}
                onConditionTypeChange={changeConditionType}
                conditionTypeValue={conditionTypeValue}
                dataQa='wfc-exact-async-condition-cell'
                conditionTypeAnyValue={anyConditionId}
                isNegativeCondition={conditionTypeValue === domain.ConditionType.NegativeExactValuesCondition}
            />
        </Box>
    );
});

ExactAsyncConditionCell.displayName = 'ExactAsyncConditionCell';
