import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SHOW_DECLINE_CUSTOMER_DECISION_POPUP } from '../../../actions';

export const DECLINE_CUSTOMER_DECISION_POPUP = 'DECLINE_CUSTOMER_DECISION_POPUP';

export interface DeclineCustomerDecisionPopupData {
    id: typeof DECLINE_CUSTOMER_DECISION_POPUP;
    requestId: Guid;
}

export type DeclineCustomerDecisionPopupType = ImmutableObject<DeclineCustomerDecisionPopupData> | null;

export default function (state: DeclineCustomerDecisionPopupType, action: Action): DeclineCustomerDecisionPopupType {
    switch (action.type) {
        case SHOW_DECLINE_CUSTOMER_DECISION_POPUP:
            return immutable<DeclineCustomerDecisionPopupData>({
                id: DECLINE_CUSTOMER_DECISION_POPUP,
                requestId: action.payload.requestId,
            });

        default:
            return state;
    }
}
