import { Box, ColumnDefinition, Link, Table, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import moment from 'moment';
import { getPath, Path } from 'urlBuilder';

import { OpacityWrapper } from '../OpacityWrapper/OpacityWrapper.styles';
import { messages } from './BillInvoiceTable.messages';

export const getColumns = (
    companyId: string,
    sortingConfig: domain.AddBillsToBatchSortingConfig
): ColumnDefinition<domain.BatchPaymentSelectableBillItem>[] => [
    {
        id: domain.AddBillsToBatchTableColumns.Name,
        name: messages.nameColumnName,
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <OpacityWrapper $shouldApplyOpacity={props.item.amount === 0}>
                    <Box maxWidth={280}>
                        <Link
                            font='body'
                            href={getPath(Path.request, props.item.xeroBillInvoiceRequestId, companyId)}
                            external
                        >
                            <Text font='body' ellipsis={1} title={props.item.friendlyName}>
                                {props.item.friendlyName}
                            </Text>
                        </Link>
                    </Box>
                </OpacityWrapper>
            </Table.Cell>
        ),
    },
    {
        id: domain.AddBillsToBatchTableColumns.Supplier,
        name: messages.supplierColumnName,
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <OpacityWrapper $shouldApplyOpacity={props.item.amount === 0}>
                    <Box maxWidth={280}>
                        <Text font='body' ellipsis={1} title={props.item.contact?.text}>
                            {props.item.contact?.text}
                        </Text>
                    </Box>
                </OpacityWrapper>
            </Table.Cell>
        ),
        sortable: true,
        sortOrder:
            sortingConfig.sortBy === domain.AddBillsToBatchTableColumns.Supplier ? sortingConfig.sortOrder : undefined,
    },
    {
        id: domain.AddBillsToBatchTableColumns.Date,
        name: messages.dateColumnName,
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <OpacityWrapper $shouldApplyOpacity={props.item.amount === 0}>
                    {moment(props.item.date).format('LL')}
                </OpacityWrapper>
            </Table.Cell>
        ),
        sortable: true,
        sortOrder:
            sortingConfig.sortBy === domain.AddBillsToBatchTableColumns.Date ? sortingConfig.sortOrder : undefined,
    },
    {
        id: domain.AddBillsToBatchTableColumns.DueDate,
        name: messages.dueDateColumnName,
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <OpacityWrapper $shouldApplyOpacity={props.item.amount === 0}>
                    {moment(props.item.dueDate).format('LL')}
                </OpacityWrapper>
            </Table.Cell>
        ),
        sortable: true,
        sortOrder:
            sortingConfig.sortBy === domain.AddBillsToBatchTableColumns.DueDate ? sortingConfig.sortOrder : undefined,
    },
    {
        id: domain.AddBillsToBatchTableColumns.PlannedDate,
        name: messages.plannedDateColumnName,
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <OpacityWrapper $shouldApplyOpacity={props.item.amount === 0}>
                    {props.item.plannedDate ? moment(props.item.plannedDate).format('LL') : '—'}
                </OpacityWrapper>
            </Table.Cell>
        ),
        sortable: true,
        sortOrder:
            sortingConfig.sortBy === domain.AddBillsToBatchTableColumns.PlannedDate
                ? sortingConfig.sortOrder
                : undefined,
    },
    {
        id: domain.AddBillsToBatchTableColumns.AmountDue,
        name: messages.amountDueColumnName,
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <OpacityWrapper $shouldApplyOpacity={props.item.amount === 0}>
                    {intl.formatCurrency(props.item.amountDue, props.item.currency)}
                </OpacityWrapper>
            </Table.Cell>
        ),
        sortable: true,
        sortOrder:
            sortingConfig.sortBy === domain.AddBillsToBatchTableColumns.AmountDue ? sortingConfig.sortOrder : undefined,
    },
];
