import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, MAKE_DECISION_RESPONSE, SHOW_REJECT_REQUEST_POPUP } from '../../../actions';

export const REJECT_REQUEST_POPUP = 'REJECT_REQUEST_POPUP';

export interface RejectRequestPopupData {
    id: typeof REJECT_REQUEST_POPUP;
    requestId: Guid;
    showResultsExplicitly: boolean;
}

export type RejectRequestPopupType = ImmutableObject<RejectRequestPopupData> | null;

export default function (state: RejectRequestPopupType, action: Action): RejectRequestPopupType {
    switch (action.type) {
        case SHOW_REJECT_REQUEST_POPUP:
            return immutable<RejectRequestPopupData>({
                id: REJECT_REQUEST_POPUP,
                requestId: action.payload.requestId,
                showResultsExplicitly: action.payload.showResultsExplicitly,
            });

        case MAKE_DECISION_RESPONSE:
            return null;

        default:
            return state;
    }
}
