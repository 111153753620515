import { errorHelpers } from '@approvalmax/utils';
import { Path } from 'urlBuilder';

import { initScript } from './helpers';

const siteId = 3303879;
const hotjarVersion = 6;

class HotjarService {
    public init() {
        try {
            const environment = window.ApprovalMax.environment;

            if (environment !== 'production') {
                return;
            }

            if (location.pathname.toLowerCase() !== `/${Path.register}`) {
                // enable only on direct navigate to register page

                return;
            }

            const scirptWithNonce = document.querySelector<HTMLScriptElement>('script[nonce]');

            initScript(siteId, hotjarVersion, {
                nonce: scirptWithNonce?.nonce || scirptWithNonce?.getAttribute('nonce'),
            });
        } catch (error) {
            errorHelpers.captureException(error);
        }
    }
}

export const hotjarService = new HotjarService();
