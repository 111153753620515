import { WarningImage } from '@approvalmax/ui';
import { FC, memo } from 'react';

import { messages } from './EmptyState.messages';
import { EmptyText, Root } from './EmptyState.styles';
import { EmptyStateProps } from './EmptyState.types';

export const EmptyState: FC<EmptyStateProps> = memo((props) => {
    const { billRequestsLength, isLoading } = props;

    if (billRequestsLength !== 0 || isLoading) {
        return null;
    }

    return (
        <Root>
            <WarningImage />

            <EmptyText>{messages.emptyText}</EmptyText>
        </Root>
    );
});

EmptyState.displayName = 'EmptyState';
