import { ToolbarButton } from '@approvalmax/ui';
import { memo } from 'react';

import { TbHelpColorlessIcon } from '../../../sprites';
import { messages } from './HelpShowButton.messages';
import { HelpShowButtonProps } from './HelpShowButton.types';

const HelpShowButton = memo<HelpShowButtonProps>((props) => {
    const { onClick, title = messages.title, size = 'small', ...restProps } = props;

    return (
        <ToolbarButton title={title} execute={onClick} size={size} {...restProps}>
            <TbHelpColorlessIcon width={20} height={20} />
        </ToolbarButton>
    );
});

export default HelpShowButton;
