import { mathService, numberHelpers } from '@approvalmax/utils';

import { NumberFieldProps } from './NumberField.types';

export const getAllowFloat = (props: { allowFloat?: boolean; precision?: number }) => {
    const { allowFloat, precision } = props;

    return allowFloat || (precision !== undefined && precision > 0);
};

const parseFromString = (props: { inputValue: string; allowCalculation?: boolean }): number | null => {
    const { inputValue, allowCalculation } = props;

    let result: number | null = null;
    let value = inputValue;

    if (typeof value === 'string' && value !== '') {
        const commaSeparatorPattern = /^(\d+,?)+(\.\d+)?$/;

        if (commaSeparatorPattern.test(value)) {
            value = value.replaceAll(',', '');
        } else {
            value = value.replaceAll(',', '.');
        }

        if (allowCalculation) {
            result = mathService.eval(value);
        } else {
            result = Number(value);
        }

        if (result === Number.POSITIVE_INFINITY) {
            result = Number.MAX_VALUE;
        } else if (result === Number.NEGATIVE_INFINITY) {
            result = Number.MIN_VALUE;
        }
    }

    return result !== null && isNaN(result) ? null : result;
};

const limitToAllowedRange = (props: { value: number; min?: number; max?: number }): number => {
    const { value, min, max } = props;

    if (max !== undefined && value > max) {
        return max;
    }

    if (min !== undefined && value < min) {
        return min;
    }

    return value;
};

const limitToAllowedRangeOrResetToPreviousValue = (
    props: Pick<NumberFieldProps, 'min' | 'max' | 'allowCalculation' | 'resetIfEvaluatedValueExceedsLimit'> & {
        inputValue: string;
        parsedValue: number;
        fallbackValue: number | null;
    }
): number | null => {
    const { min, max, allowCalculation, resetIfEvaluatedValueExceedsLimit, inputValue, parsedValue, fallbackValue } =
        props;

    if (allowCalculation && resetIfEvaluatedValueExceedsLimit) {
        const mathOperatorsPattern = /[+\-*/]/;
        const hasMathOperators = mathOperatorsPattern.test(inputValue);

        if (!hasMathOperators) {
            return limitToAllowedRange({ value: parsedValue, min, max });
        } else if ((min !== undefined && parsedValue < min) || (max !== undefined && parsedValue > max)) {
            return fallbackValue;
        }
    }

    return limitToAllowedRange({ value: parsedValue, min, max });
};

export const getNewValue = (
    inputValue: string,
    props: Pick<
        NumberFieldProps,
        'value' | 'allowFloat' | 'precision' | 'allowCalculation' | 'min' | 'max' | 'resetIfEvaluatedValueExceedsLimit'
    >
): number | null => {
    const { value, allowFloat, precision, allowCalculation, min, max, resetIfEvaluatedValueExceedsLimit } = props;
    const caluclatedAllowFloat = getAllowFloat({ allowFloat, precision });
    const fallbackValue = numberHelpers.isNumber(value) ? value : null;

    let result: number | null = null;

    if (inputValue !== '' && inputValue !== null) {
        const parsedValue = parseFromString({ inputValue, allowCalculation });

        if (parsedValue !== null) {
            result = limitToAllowedRangeOrResetToPreviousValue({
                inputValue,
                parsedValue,
                fallbackValue,
                min,
                max,
                allowCalculation,
                resetIfEvaluatedValueExceedsLimit,
            });

            if (result !== null) {
                if (!caluclatedAllowFloat) {
                    result = Math.floor(result);
                } else if (precision) {
                    result = mathService.round(result, precision);
                }
            }
        } else {
            // Incorrect value has been typed in
            result = fallbackValue;
        }
    } else {
        if (inputValue === '') {
            result = null;
        }
    }

    return result;
};

export const toStateValue = (value: number | null): string => {
    return value !== null && Number.isFinite(value) ? value.toString() : '';
};
