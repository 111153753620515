import './grayArea.scss';

import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';

const i18nPrefix = 'company/containers/SelectSubscriptionPopup/gray-area';

const bem = bemFactory.block('company-select-subscription-gray-area');
const qa = bemFactory.qa('company-select-subscription-gray-area');

const NoSubscription: FC = () => {
    return (
        <section className={bem()} data-qa={qa()}>
            <h4 className={bem('text')}>
                <FormattedMessage
                    id={`${i18nPrefix}.emptySubscriptions`}
                    defaultMessage='There are no applicable subscriptions'
                />
            </h4>
        </section>
    );
};

export default memo(NoSubscription);
