import { forwardRef, KeyboardEvent, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { Item, Name, Root } from './PlaceholderSuggestionList.styles';
import { PlaceholderSuggestionListItem, PlaceholderSuggestionListProps } from './PlaceholderSuggestionList.types';

const PlaceholderSuggestionList = forwardRef((props: PlaceholderSuggestionListProps, ref) => {
    const { command, items } = props;

    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = useCallback(
        (index: number) => {
            const item = items[index];

            if (item) {
                command({ placeholderName: item.placeholderName });
            }
        },
        [command, items]
    );

    const upHandler = useCallback(() => {
        setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    }, [items.length, selectedIndex]);

    const downHandler = useCallback(() => {
        setSelectedIndex((selectedIndex + 1) % items.length);
    }, [items.length, selectedIndex]);

    const enterHandler = useCallback(() => {
        selectItem(selectedIndex);
    }, [selectItem, selectedIndex]);

    useEffect(() => setSelectedIndex(0), [items]);

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }: { event: KeyboardEvent }) => {
            if (event.key === 'ArrowUp') {
                upHandler();

                return true;
            }

            if (event.key === 'ArrowDown') {
                downHandler();

                return true;
            }

            if (event.key === 'Enter') {
                enterHandler();

                return true;
            }

            return false;
        },
    }));

    return (
        <Root>
            {items.length ? (
                items.map((item: PlaceholderSuggestionListItem, index: number) => {
                    return (
                        <Item active={index === selectedIndex} key={index} execute={() => selectItem(index)}>
                            <Name className='email-placeholder'>{item.placeholderName}</Name>
                        </Item>
                    );
                })
            ) : (
                <Item className='item'>No result</Item>
            )}
        </Root>
    );
});

export default PlaceholderSuggestionList;
