import { Flex, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import { FeatureBadge } from 'shared/components';
import { getPath, Path } from 'urlBuilder';

import { messages } from './WorkflowListItem.messages';
import { Circle, Root } from './WorkflowListItem.styles';
import { WorkflowListItemProps } from './WorkflowListItem.types';

const qa = bemFactory.qa('wfl-xero-card');

const WorkflowListItem: FC<WorkflowListItemProps> = memo((props) => {
    const { workflow, isBeta, isPremium, icon, ...restProps } = props;

    if (!workflow) return null;

    const workflowName =
        selectors.template.getTemplateWorkflowNameByCode(workflow.integrationCode) ||
        workflow.templateName ||
        messages.unnamed;

    return (
        <Root
            to={getPath(Path.workflow, workflow.companyId, workflow.id)}
            key={workflow.integrationCode}
            data-qa={qa(`list-item-${workflow.integrationCode}`)}
            $active={workflow.enabled}
            {...restProps}
        >
            <Flex inline spacing='12' alignItems='center' wrap={false}>
                <Flex inline alignItems='center' spacing='12' grow={1} wrap={false}>
                    <Text font='label' ellipsis={1}>
                        {workflowName}
                    </Text>

                    <FeatureBadge premium={isPremium} />

                    <FeatureBadge beta={isBeta} />
                </Flex>

                {icon && (
                    <Flex inline shrink={1}>
                        {icon}
                    </Flex>
                )}

                <Flex
                    inline
                    alignItems='center'
                    shrink={0}
                    wrap={false}
                    spacing='8'
                    // min-width added specifically for "Pay" logo be located in a more beautiful way (c) Anton
                    minWidth={92}
                    justifyContent='end'
                >
                    <Text
                        font='label'
                        fontSize='small'
                        fontWeight={workflow.enabled ? 'medium' : 'regular'}
                        color={workflow.enabled ? 'green100' : 'midnight70'}
                    >
                        {workflow.enabled ? messages.activated : messages.notActivated}
                    </Text>

                    <Circle $active={workflow.enabled} />
                </Flex>
            </Flex>
        </Root>
    );
});

export default WorkflowListItem;
