import * as common from 'modules/common';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { storageService } from 'services/storage';
import { getPath, Path } from 'urlBuilder';

export const useTwoFaHardEnforcementPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isAuthenticated = storageService.authenticated();
    const isTwoFaHardEnforcementPage = history.location.pathname === getPath(Path.twoFaHardEnforcement);

    useEffect(() => {
        if (!isAuthenticated) return;

        if (isTwoFaHardEnforcementPage) {
            storageService.setIsTwoFaHardEnforcement(true);
            dispatch(common.actions.setAuthenticatedState(true));
        } else {
            storageService.resetIsTwoFaHardEnforcement();
        }
    }, [dispatch, isAuthenticated, isTwoFaHardEnforcementPage]);
};
