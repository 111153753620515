import { immutable, ImmutableObject, set } from 'modules/immutable';

import {
    Action,
    LOAD_PAGE_DATA,
    LOAD_SEARCH_RESULTS_DATA_FAILURE,
    LOAD_SEARCH_RESULTS_DATA_RESPONSE,
} from '../../actions';
import { SearchParameters } from '../../data/SearchParameters';

interface RequestSearch {
    searchParams: SearchParameters;
    loaded: boolean;
}

export type RequestSearchType = ImmutableObject<RequestSearch> | null;

const INITIAL_STATE: RequestSearchType = null;

export default function (state = INITIAL_STATE, action: Action): RequestSearchType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            if (action.payload.searchParameters) {
                return immutable({
                    searchParams: action.payload.searchParameters,
                    loaded: action.payload.initLoad || false,
                });
            }

            return state;

        case LOAD_SEARCH_RESULTS_DATA_RESPONSE:
        case LOAD_SEARCH_RESULTS_DATA_FAILURE:
            if (!state) {
                // Closed search without waiting for results
                return state;
            }

            return set(state, 'loaded', true);

        default:
            return state;
    }
}
