import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, REASSIGN_REQUEST_RESPONSE, SHOW_REQUEST_REASSIGN_POPUP } from '../../../actions';

export const REQUEST_REASSIGN_POPUP = 'REQUEST_REASSIGN_POPUP';

export interface RequestReassignPopupData {
    id: typeof REQUEST_REASSIGN_POPUP;
    requestId: Guid;
    stepId: Guid;
}

export type RequestReassignPopupType = ImmutableObject<RequestReassignPopupData> | null;

export default function (state: RequestReassignPopupType, action: Action): RequestReassignPopupType {
    switch (action.type) {
        case SHOW_REQUEST_REASSIGN_POPUP:
            return immutable<RequestReassignPopupData>({
                id: REQUEST_REASSIGN_POPUP,
                requestId: action.payload.requestId,
                stepId: action.payload.stepId,
            });

        case REASSIGN_REQUEST_RESPONSE:
            return null;

        default:
            return state;
    }
}
