import { MatrixIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { arrayHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addStepEditor, addTempUser, openEditorsMatrix, openInviteUsersPopup, removeStepEditor } from '../../actions';
import { getActiveTemplate, isPageReadonly } from '../../selectors/pageSelectors';
import { getStepEditors } from '../../selectors/stepSelectors';
import { isMatrixUnavailable } from '../../selectors/templateSelectors';
import { ExpandedTemplateUser } from '../../types/selectors';
import { addUserEvent, removeUserEvent } from '../../utils/events';
import { createUserComparator } from '../../utils/helpers';
import AddUserButton from '../AddUserButton/AddUserButton';
import StepUser from '../StepUser/StepUser';
import { messages } from './ReviewStepContent.messages';
import { ReviewStepContentProps } from './ReviewStepContent.types';

const ReviewStepContent: FC<ReviewStepContentProps> = memo((props) => {
    const { stepIndex, step } = props;

    const dispatch = useDispatch();
    const template = useSelector(getActiveTemplate)!;
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, template.companyId));
    const readonly = useSelector(isPageReadonly);
    const matrixUnavailable = useSelector(isMatrixUnavailable);
    const team = useSelector((state: State) => selectors.company.getCompanyTeam(state, company));
    const reviewers = useSelector((state: State) => getStepEditors(state, step, template));

    const onAddTempUser = useCallback(
        (user: domain.User) => {
            dispatch(addTempUser(user));

            addUserEvent('reviewer', template.integrationCode, true);
        },
        [dispatch, template.integrationCode]
    );

    const onInviteUser = useCallback(
        (user: ExpandedTemplateUser) => {
            dispatch(openInviteUsersPopup([user.id]));
        },
        [dispatch]
    );

    const onAddReviewer = useCallback(
        (user: selectors.types.ExpandedCompanyUser) => {
            dispatch(addStepEditor(stepIndex, user));

            if (user.databaseId) {
                addUserEvent('reviewer', template.integrationCode);
            }
        },
        [dispatch, stepIndex, template.integrationCode]
    );

    const handlRemoveStepEditor = useCallback(
        (user: ExpandedTemplateUser) => {
            dispatch(removeStepEditor(stepIndex, user));

            removeUserEvent('reviewer', template.integrationCode);
        },
        [dispatch, stepIndex, template.integrationCode]
    );

    const onOpenReviewerMatrix = useCallback(() => {
        if (matrixUnavailable) return;
        dispatch(openEditorsMatrix(stepIndex));
    }, [dispatch, matrixUnavailable, stepIndex]);

    const onUserOpenReviewerMatrix = useCallback(
        (userId: string) => {
            if (matrixUnavailable) return;
            dispatch(openEditorsMatrix(stepIndex, userId));
        },
        [dispatch, matrixUnavailable, stepIndex]
    );

    const sortedReviewers = useMemo(
        () => arrayHelpers.arraySort<ExpandedTemplateUser>(reviewers, createUserComparator(team)),
        [reviewers, team]
    );

    return (
        <>
            {reviewers.length === 0 ? (
                <>
                    <Text font='body' fontSize='small' color='midnight70'>
                        {messages.emptyReviewerTabStepDescription}
                    </Text>

                    <AddUserButton
                        users={team}
                        disabled={readonly}
                        onAddNew={onAddTempUser}
                        onSelect={onAddReviewer}
                        title={messages.addReviewer}
                        type='step'
                    />
                </>
            ) : (
                <>
                    <Flex height={32} spacing='8' inline alignItems='center' wrap={false}>
                        <Flex shrink={0}>
                            <AddUserButton
                                users={team}
                                excludedUsers={reviewers}
                                disabled={readonly}
                                onAddNew={onAddTempUser}
                                onSelect={onAddReviewer}
                                title={messages.addReviewer}
                                type='step'
                            />
                        </Flex>

                        <Button
                            icon
                            onClick={onOpenReviewerMatrix}
                            disabled={matrixUnavailable}
                            title={messages.openReviewerMatrixButtonTitle}
                        >
                            <MatrixIcon size={20} color={matrixUnavailable ? 'midnight80' : 'blue80'} />
                        </Button>
                    </Flex>

                    {reviewers.length > 0 && (
                        <Box spacing='8 0' width='100%'>
                            <Flex inline direction='column' spacing='8'>
                                {sortedReviewers.map((reviewer) => (
                                    <StepUser
                                        team={team}
                                        key={reviewer.id}
                                        user={reviewer}
                                        isCompanyMember={team.some((t) => t.id === reviewer.id)}
                                        onRemove={handlRemoveStepEditor}
                                        readonly={readonly}
                                        onInvite={onInviteUser}
                                        onOpenMatrix={onUserOpenReviewerMatrix}
                                    />
                                ))}
                            </Flex>
                        </Box>
                    )}
                </>
            )}
        </>
    );
});

export default ReviewStepContent;
