import { font } from 'modules/styles';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const SectionContainer = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-top: 20px;
    padding-left: 80px;
    padding-right: 60px;
`;

const Header = styled.div`
    padding: 10px 0;
    ${font(16, '#000', 'semibold')}
`;

const Body = styled.div`
    display: flex;
    flex-flow: column;
`;

interface SectionProps extends PropsWithChildren {
    header?: string;
}

const Section: FC<SectionProps> = (props) => {
    const { header, children } = props;

    return (
        <SectionContainer>
            {header && <Header>{header}</Header>}

            <Body>{children}</Body>
        </SectionContainer>
    );
};

export default Section;
