import { stateTree } from 'modules/data';
import { createAction, ExtractActions } from 'modules/react-redux';

export const SHOW_DISCARD_CHANGES_POPUP = 'COMMON/SHOW_DISCARD_CHANGES_POPUP';
export const showDiscardChangesPopup = ({
    unsavedChangesConfirmationCallback,
}: {
    unsavedChangesConfirmationCallback: (ok: boolean) => void;
}) =>
    createAction(SHOW_DISCARD_CHANGES_POPUP, {
        unsavedChangesConfirmationCallback,
    });

export const CANCEL_DISCARD_CHANGES_POPUP = 'COMMON/CANCEL_DISCARD_CHANGES_POPUP';
export const cancelDiscardChangesPopup = () => createAction(CANCEL_DISCARD_CHANGES_POPUP, {});

export const UPDATE_ENTITIES = 'COMMON/UPDATE_ENTITIES';
export const updateEntities = (entities: Partial<stateTree.Entities>) => createAction(UPDATE_ENTITIES, { entities });

export const SYNC_STARTUP_DATA = 'COMMON/SYNC_STARTUP_DATA';
export const syncStartupData = (startupData: stateTree.SessionStartupData) =>
    createAction(SYNC_STARTUP_DATA, {
        startupData,
    });

export type Action = ExtractActions<
    typeof cancelDiscardChangesPopup | typeof showDiscardChangesPopup | typeof syncStartupData | typeof updateEntities
>;
