export enum FormFieldId {
    isSuiteApp = 'isSuiteApp',
    accountId = 'accountId',
    consumerKey = 'consumerKey',
    consumerSecret = 'consumerSecret',
    tokenId = 'tokenId',
    tokenSecret = 'tokenSecret',
    subsidiaryId = 'subsidiaryId',
    timeZone = 'timeZone',
}

export interface FormValuesState {
    [FormFieldId.isSuiteApp]: boolean;
    [FormFieldId.accountId]: string;
    [FormFieldId.consumerKey]: string;
    [FormFieldId.consumerSecret]: string;
    [FormFieldId.tokenId]: string;
    [FormFieldId.tokenSecret]: string;
    [FormFieldId.subsidiaryId]: string;
    [FormFieldId.timeZone]: string;
}

export interface FormValuesTransfer
    extends Omit<FormValuesState, FormFieldId.consumerKey | FormFieldId.consumerSecret | FormFieldId.isSuiteApp> {
    [FormFieldId.consumerKey]: string | null;
    [FormFieldId.consumerSecret]: string | null;
}

export interface FormField {
    id: FormFieldId;
    required: boolean;
    isSuiteApp: boolean;
    title: string;
    type: 'switch' | 'text' | 'password' | 'select';
    tooltipText?: string;
    imgSrc?: string;
    imgAltText?: string;
    items?: {
        id: string;
        name: string;
    }[];
    qa?: string;
    placeholder?: string;
}
