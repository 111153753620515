import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UsePutOnHoldData, UsePutOnHoldPathParams, UsePutOnHoldResponse } from './usePutOnHold.types';

export const usePutOnHold = (
    mutationOptions?: MutationOptions<UsePutOnHoldResponse, RequestDataParams<UsePutOnHoldData, UsePutOnHoldPathParams>>
) => {
    return useMutate<RequestDataParams<UsePutOnHoldData, UsePutOnHoldPathParams>, UsePutOnHoldResponse>(
        requestsApiPaths.putOnHold,
        {
            apiVersion: 'v2',
            mutationOptions,
        }
    );
};
