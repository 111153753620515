import {
    SendTFAVerifyingEmailCodeBackend,
    SendTFAVerifyingEmailCodeResponse,
} from './useSendTFAVerifyingEmailCode.types';

export const mapData = (response: SendTFAVerifyingEmailCodeBackend): SendTFAVerifyingEmailCodeResponse => {
    return {
        stateId: response.StateId,
    };
};
