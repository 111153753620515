/**
 * Developer: Stepan Burguchev
 * Date: 6/4/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './userListItem.scss';

import { domHelpers } from '@approvalmax/utils';
import React from 'react';
import bemFactory from 'react-bem-factory';

import * as selectors from '../../selectors';
import Avatar from '../avatar/Avatar';

interface OwnProps {
    item: selectors.types.ExpandedUser;
    displayTextSelector: (item: selectors.types.ExpandedUser) => string;
    rootRef: (ref: HTMLLIElement) => void;
    active: boolean;
    highlightedText: string | null;
    onSelect: (item: selectors.types.ExpandedUser) => void;
    onHover: (item: selectors.types.ExpandedUser) => void;
}

class UserListItem extends React.Component<OwnProps> {
    public render() {
        const { item, displayTextSelector, active, highlightedText, rootRef } = this.props;

        const bem = bemFactory.block('form-dropdown-editor-user-list-item');
        const qa = bemFactory.qa('form-dropdown-editor-list-item'); // not a typo, qa block doesn't change

        const text = displayTextSelector(item);

        let textComponent;

        if (highlightedText) {
            textComponent = (
                <div
                    className={bem('text')}
                    dangerouslySetInnerHTML={{
                        __html: domHelpers.highlightText(text, highlightedText, true, bem('highlighted-text')),
                    }}
                />
            );
        } else {
            textComponent = <div className={bem('text')}>{text}</div>;
        }

        return (
            <li
                className={bem(null, { active })}
                onClick={this._select}
                data-qa={qa()}
                data-qa-id={item.id}
                data-qa-name={text}
                onMouseEnter={this._hover}
                ref={rootRef}
            >
                <Avatar user={item} size={30} />

                {textComponent}
            </li>
        );
    }

    private _select = () => {
        this.props.onSelect(this.props.item);
    };

    private _hover = () => {
        this.props.onHover(this.props.item);
    };
}

export default UserListItem;
