import { RequestDataParams } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useMutateLegacy } from 'shared/data/api';

import { requestsReportsApiPaths } from '../paths';
import {
    UseCreateManualJournalFromPurchaseOrderBackend,
    UseCreateManualJournalFromPurchaseOrderData,
    UseCreateManualJournalFromPurchaseOrderResponse,
} from './useCreateManualJournalFromPurchaseOrder.types';

export const useCreateManualJournalFromPurchaseOrder = () => {
    return useMutateLegacy<
        RequestDataParams<UseCreateManualJournalFromPurchaseOrderData>,
        UseCreateManualJournalFromPurchaseOrderBackend,
        UseCreateManualJournalFromPurchaseOrderResponse
    >(requestsReportsApiPaths.createManualJournalFromPurchaseOrder, {
        mapData: objectHelpers.pascalCaseToCamelCase,
    });
};
