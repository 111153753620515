import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SAVE_AND_APPROVE_REQUEST_RESPONSE, SHOW_SAVE_AND_APPROVE_POPUP } from '../../../actions';

export const SAVE_AND_APPROVE_POPUP = 'SAVE_AND_APPROVE_POPUP';

export interface SaveAndApprovePopupData {
    id: typeof SAVE_AND_APPROVE_POPUP;
}

export type SaveAndApprovePopupType = ImmutableObject<SaveAndApprovePopupData> | null;

export default function (state: SaveAndApprovePopupType, action: Action): SaveAndApprovePopupType {
    switch (action.type) {
        case SHOW_SAVE_AND_APPROVE_POPUP:
            return immutable<SaveAndApprovePopupData>({
                id: SAVE_AND_APPROVE_POPUP,
            });

        case SAVE_AND_APPROVE_REQUEST_RESPONSE:
            return null;

        default:
            return state;
    }
}
