import { constants } from 'modules/common';
import { domain } from 'modules/data';

export const getAllowedFileExtenstions = (integrationType: domain.IntegrationType) => {
    switch (integrationType) {
        case domain.IntegrationType.Xero:
            return constants.xeroConstants.xeroAllowedFileExtensions;

        case domain.IntegrationType.QBooks:
            return constants.qBooksConstants.qboAllowedFileExtensions;

        default:
            return [];
    }
};
