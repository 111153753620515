import { Grid, Text } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { InfoIconGreen, InfoIconOrange, InfoIconRed } from 'modules/sprites';
import { memo } from 'react';

import { messages } from './PaymentDetailsStatus.messages';
import { PaymentDetailsStatusProps } from './PaymentDetailsStatus.types';

export const PaymentDetailsStatus = memo<PaymentDetailsStatusProps>((props) => {
    const { status } = props;

    const renderStatus = () => {
        switch (status) {
            case domain.PaymentDetailsPaymentStatus.PaidPreviously:
                return (
                    <>
                        <InfoIconGreen />

                        <Text font='label' fontWeight='medium' fontSize='medium'>
                            {messages.paidPreviously}
                        </Text>
                    </>
                );

            case domain.PaymentDetailsPaymentStatus.NeverPaid:
                return (
                    <>
                        <InfoIconOrange />

                        <Text font='label' fontWeight='medium' fontSize='medium'>
                            {messages.neverPaid}
                        </Text>
                    </>
                );

            case domain.PaymentDetailsPaymentStatus.PaymentDetailsChanged:
                return (
                    <>
                        <InfoIconRed />

                        <Text font='label' fontWeight='medium' fontSize='medium'>
                            {messages.paymentDetailsChanged}
                        </Text>
                    </>
                );

            default:
                return null;
        }
    };

    return (
        <Grid gridTemplateColumns='max-content auto' gap={4}>
            {renderStatus()}
        </Grid>
    );
});

PaymentDetailsStatus.displayName = 'PaymentDetailsStatus';
