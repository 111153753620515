import { Divider, Spacing } from '@approvalmax/ui/src/components';
import { FC, memo, useEffect, useState } from 'react';

import { Line, Root } from './MatrixLineCellsContainer.styles';
import { MatrixLineCellsContainerProps } from './MatrixLineCellsContainer.types';

export const MatrixLineCellsContainer: FC<MatrixLineCellsContainerProps> = memo((props) => {
    const { children, divider, highlight: initialHighlight } = props;

    const [highlight, setHighlight] = useState(initialHighlight);

    useEffect(() => {
        let timerId: ReturnType<typeof setTimeout>;

        if (highlight) {
            timerId = setTimeout(() => {
                setHighlight(false);
            }, 1000);
        }

        return () => {
            timerId && clearTimeout(timerId);
        };
    }, [highlight]);

    return (
        <Root $highlight={highlight}>
            <Spacing height={16} />

            <Line inline wrap={false}>
                {children}
            </Line>

            <Spacing height={16} />

            {divider && <Divider />}
        </Root>
    );
});

MatrixLineCellsContainer.displayName = 'MatrixLineCellsContainer';
