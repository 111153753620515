import { errorHelpers } from '@approvalmax/utils';
import { store } from 'modules/store';
import { amplitudeService } from 'services/amplitude';
import { facebookService } from 'services/facebook';
import { fullstoryService } from 'services/fullstory';
import { gaService } from 'services/ga';
import { hotjarService } from 'services/hotjar';
import { linkedInService } from 'services/linkedIn';
import { storageService } from 'services/storage';

export const initAnonymousExternalServices = () => {
    try {
        const state = store.getState();

        const isProd = window.ApprovalMax.environment === 'production';
        const forceTracking = storageService.getForceTrackingState();

        amplitudeService.init(state);

        if (isProd || forceTracking) {
            facebookService.init();
            linkedInService.init();
        }

        gaService.init(state);

        fullstoryService.init(state);

        hotjarService.init();
    } catch (error) {
        errorHelpers.captureException(error);
    }
};
