import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

export const ContentBody = styled.div<StyledMods<{ zIndex: 'initial' | 0 }>>`
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;

    z-index: ${({ $zIndex }) => $zIndex};
`;

export const ContentBodyInner = styled.div<{ fixedWidth: boolean }>`
    position: relative;
    height: 100%;
    min-width: 100%;
    display: inline-block;

    ${(props) =>
        props.fixedWidth &&
        css`
            width: 100%;
        `}

    & > * {
        height: 100%;
        width: 100%;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
`;
