import { Tag } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { messages } from './FeatureBadge.messages';
import { FeatureBadgeProps } from './FeatureBadge.types';

const FeatureBadge: FC<FeatureBadgeProps> = memo((props) => {
    const { premium, beta, ...restProps } = props;

    if (!premium && !beta) return null;

    return (
        <Tag color='midnight80' size='small' outline={premium} {...restProps}>
            {premium ? messages.premium : messages.beta}
        </Tag>
    );
});

export default FeatureBadge;
