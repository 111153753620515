import { errorHelpers } from '@approvalmax/utils';
import { backend, domain } from 'modules/data';

import { StandaloneRequestDetails, StandaloneRequestSpecifics } from '../Request.Standalone';
import { mapFieldValues } from './requestSchema';

export const mapStandaloneDetails = (request: backend.RequestAnswer): StandaloneRequestDetails => {
    if (!request.StandaloneDetails) {
        throw errorHelpers.formatError('[requestSchema.Standalone] Standalone details is undefined.');
    }

    const details = request.StandaloneDetails;

    return {
        integrationCode: null,
        integrationType: domain.IntegrationType.None,
        name: details.Name || '',
        date: details.Date || '',
        description: {
            value: details.Description || '',
            errorMessages: null,
        },
        fieldValues: details.FieldValues ? mapFieldValues(details.FieldValues) : [],
    };
};

export const getStandaloneRequestSpecifics = (request: backend.RequestAnswer): StandaloneRequestSpecifics => {
    return {
        details: mapStandaloneDetails(request),
        integrationCode: null,
        integrationType: domain.IntegrationType.None,
    };
};
