import { Box, Text } from '@approvalmax/ui/src/components';
import { FC, PropsWithChildren } from 'react';

export const Header: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    return (
        <Box spacing='12 0'>
            <Text font='body' fontSize='small' fontWeight='medium' color='midnight80'>
                {children}
            </Text>
        </Box>
    );
};
