import { intl } from '../../services/localization/localization';

/**
 * Converts a number to a string with the specified precision.
 * This function provides a more accurate result than `toFixed`.
 *
 * @param {number} num - The number to convert to a string.
 * @param {number} [precision=0] - The number of decimal places to include in the output string.
 * @returns {string} The number converted to a string with the specified precision.
 */
export const toString = (num: number, precision: number = 0): string => {
    if (precision > 0) {
        const autoPrecision = intl.autoPrecision(num);
        const safePrecision = autoPrecision < precision ? autoPrecision : precision;
        const factor = Math.pow(10, safePrecision);

        return `${Math.round(num * factor) / factor}`;
    }

    return `${Math.round(num)}`;
};
