import { ExternalLink, TextButton } from '@approvalmax/ui';
import { constants } from 'modules/common';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { api } from 'services/api';

import { useTwoFaEnablingWizardContext } from '../../TwoFaEnablingWizard.context';
import { useStepCodeScan } from './StepCodeScan.hooks';
import { messages } from './StepCodeScan.messages';
import {
    ButtonNext,
    ButtonWrap,
    CodeSetup,
    CodeSetupInput,
    Content,
    Description,
    QrCode,
    QrCodeImage,
    Root,
} from './StepCodeScan.styles';

const qa = bemFactory.qa('pro-two-fa-setup-code-scan');

const StepCodeScan: FC = memo(() => {
    const { setActiveStep } = useTwoFaEnablingWizardContext();
    const { inputRef, stateId, setupCode, isCodeVisible, onCodeShow, onCopyToClipboard } = useStepCodeScan();

    const onNext = useCallback(() => {
        setActiveStep('codeEnter');
    }, [setActiveStep]);

    return (
        <Root qa={qa()} title={messages.title}>
            <Content>
                <Description>
                    {messages.description({
                        br: <br />,
                        supportlink: (chunks) => (
                            <ExternalLink href={constants.xeroConstants.XERO_TWO_FA_SUPPORT_LINK}>
                                {chunks}
                            </ExternalLink>
                        ),
                    })}
                </Description>

                <QrCode>
                    <QrCodeImage src={api.expandUrl(`auth/getTFAEnablingQrCodeImage?stateId=${stateId}`)} />
                </QrCode>

                {isCodeVisible ? (
                    <CodeSetup>
                        <CodeSetupInput ref={inputRef} value={setupCode} readOnly onClick={onCopyToClipboard} />

                        <TextButton upperCased={false} execute={onCopyToClipboard}>
                            {messages.copyCodeButton}
                        </TextButton>
                    </CodeSetup>
                ) : (
                    <CodeSetup>
                        <TextButton execute={onCodeShow}>{messages.enterManuallyButton}</TextButton>
                    </CodeSetup>
                )}

                <ButtonWrap>
                    <ButtonNext execute={onNext}>{messages.nextButton}</ButtonNext>
                </ButtonWrap>
            </Content>
        </Root>
    );
});

export default StepCodeScan;
