import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.components.WorkflowWizardPopup.ApprovalStepsStep', {
    title: 'Add as many steps as you need',
    subtitle:
        'For example, if a bill needs approval from a department manager, a head of department, and then the CEO, you will need 3 steps.',
    addStep: 'Add step',
    step: 'Step {number}',
    placeholder: 'Enter the step name...',
});
