import { Reference } from '@approvalmax/types';
import { arrayHelpers, compareHelpers } from '@approvalmax/utils';
import { domain, State } from 'modules/data';
import { createSelector } from 'reselect';

import { Context } from '../../reducers/page/contextReducer';
import { getRequest } from '../pageSelectors';

const emptyValueId = '#$EMPTY_SYNTETIC_ID_VALUE$#';

export function getInitialStandaloneRequest(state: State, context: Context, request: domain.Request) {
    return request;
}

export const getStandaloneFields: (state: State) => domain.RequestFieldEntry[] = createSelector(
    (state: State) => getRequest<domain.StandaloneRequest>(state).details,
    (details) => {
        return arrayHelpers.arraySort(
            details.fieldValues
                .filter((fv) => fv.field.systemPurpose === domain.FieldSystemPurpose.General)
                .map(
                    (fv): domain.RequestFieldEntry => ({
                        field: {
                            ...fv.field,
                            exactValues: fv.field.exactValues.filter((v: Reference) => v.id !== emptyValueId),
                        },
                        value: fv.value,
                    })
                ),
            (a: domain.RequestFieldEntry, b: domain.RequestFieldEntry) => {
                return compareHelpers.stringComparator2AscI(a.field.name, b.field.name);
            }
        );
    }
);
