import { difference } from 'lodash';
import moment from 'moment';
import { atom } from 'recoil';

export const fileUploadTimestampsStorageKey = 'fileDateUploads';

export const fileUploadTimestampsState = atom<Array<string>>({
    key: 'fileUploadTimestampsState',
    default: [],
    effects: [
        ({ setSelf, onSet }) => {
            const storedFileDates = localStorage.getItem(fileUploadTimestampsStorageKey);

            if (storedFileDates) {
                const fileUploadTimestamps: string[] = JSON.parse(storedFileDates);

                setSelf(
                    fileUploadTimestamps.filter((timestamp) => {
                        const uploadTime = moment(timestamp);
                        const currentTime = moment();
                        const secondsSinceUpload = currentTime.diff(uploadTime, 'seconds');

                        if (secondsSinceUpload <= 60) {
                            setTimeout(
                                () => {
                                    setSelf((oldValue: string[]) => oldValue.filter((upload) => upload !== timestamp));
                                },
                                (60 - secondsSinceUpload) * 1000
                            );

                            return true;
                        }

                        return false;
                    })
                );
            }

            onSet((newValue, oldValue: string[]) => {
                const recentlyUploadedFiles = difference(newValue, oldValue);

                setTimeout(() => {
                    setSelf((oldValue: string[]) =>
                        oldValue.filter((upload) => !recentlyUploadedFiles.includes(upload))
                    );
                }, 60000);
            });
        },
    ],
});
