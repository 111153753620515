import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.components.WorkflowWizardPopup.ConditionsStep', {
    title: 'Set up conditions for the approval steps',
    subtitle:
        'Next, you will create approval rules for each step in your workflow. Select the users, create any fields, and set the approval conditions. Watch this GIF to see how simple it is.',
    description: 'If you click “NOT NOW”, you will be the Approver for all the documents at all steps.',
    goToConditions: 'Go to conditions',
    notNow: 'Not now',
});
