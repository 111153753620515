import type { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { companiesApiPaths } from '../paths';
import type { UseCompanyDelegationsPathParams, UseCompanyDelegationsResponse } from './useCompanyDelegations.types';

export const useCompanyDelegations = (
    pathParams: UseCompanyDelegationsPathParams,
    queryOptions?: QueryOptions<UseCompanyDelegationsResponse>
) =>
    useGetRequest(companiesApiPaths.companyDelegations, {
        pathParams,
        queryOptions,
        apiVersion: 'v2',
    });
