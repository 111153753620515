import { Reference } from '@approvalmax/types';
import { Box, Checkbox, Spacing } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';

import { ExactAsyncCondition } from '../ExactAsyncCondition/ExactAsyncCondition';
import { conditionTypeToConditionTypeValue, getConditionItems } from './AllowEditingCell.helpers';
import { messages } from './AllowEditingCell.messages';
import { AllowEditingCellProps, ConditionTypeValue } from './AllowEditingCell.types';

export const AllowEditingCell: FC<AllowEditingCellProps> = memo((props) => {
    const { rule, field, label, readonly, lineId, condition, onConditionChange, withSelect } = props;

    const baseConditionFields = useMemo(
        () => ({
            fieldId: condition.fieldId,
            fieldName: condition.fieldName,
            fieldSystemPurpose: condition.fieldSystemPurpose,
        }),
        [condition.fieldId, condition.fieldName, condition.fieldSystemPurpose]
    );

    const onChangeAllowCreation = useCallback(
        (checked: boolean) => {
            const newCondition: domain.AlwaysTrueCondition = {
                ...baseConditionFields,
                conditionType: null,
                allowEditing: checked,
            };

            onConditionChange(lineId, rule, field, newCondition);
        },
        [baseConditionFields, field, lineId, onConditionChange, rule]
    );

    const changeConditionType = useCallback(
        (conditionType: ConditionTypeValue, exactValues: Reference[]) => {
            let newCondition: domain.ExactValuesCondition | domain.AlwaysTrueCondition;

            switch (conditionType) {
                case ConditionTypeValue.All:
                    newCondition = {
                        ...baseConditionFields,
                        conditionType: null,
                        allowEditing: true,
                    };

                    break;

                case ConditionTypeValue.ExactValues:
                    newCondition = {
                        ...baseConditionFields,
                        conditionType: domain.ConditionType.ExactValuesCondition,
                        allowEditing: true,
                        exactValues,
                    };
                    break;

                case ConditionTypeValue.NegativeExactValues:
                    newCondition = {
                        ...baseConditionFields,
                        conditionType: domain.ConditionType.NegativeExactValuesCondition,
                        allowEditing: true,
                        exactValues,
                    };
                    break;
            }

            onConditionChange(lineId, rule, field, newCondition);
        },
        [field, lineId, onConditionChange, rule, baseConditionFields]
    );

    const conditionTypeValue = useMemo(() => conditionTypeToConditionTypeValue(condition), [condition]);
    const conditionItems = useMemo(() => getConditionItems(field.name), [field.name]);
    const showExactAsyncCondition =
        withSelect &&
        condition.allowEditing &&
        (condition.conditionType === null ||
            condition.conditionType === domain.ConditionType.ExactValuesCondition ||
            condition.conditionType === domain.ConditionType.NegativeExactValuesCondition);

    return (
        <Box width='172px'>
            <Checkbox
                onChange={onChangeAllowCreation}
                size='small'
                checked={Boolean(condition.allowEditing)}
                disabled={readonly}
            >
                {label || messages.allowToEditThisField}
            </Checkbox>

            {showExactAsyncCondition && (
                <>
                    <Spacing height={4} />

                    <ExactAsyncCondition
                        {...props}
                        conditionTypeItems={conditionItems}
                        onConditionTypeChange={changeConditionType}
                        conditionTypeValue={conditionTypeValue}
                        noEmptyValue
                        conditionTypeAnyValue={ConditionTypeValue.All}
                        condition={condition}
                        isNegativeCondition={conditionTypeValue === ConditionTypeValue.NegativeExactValues}
                    />
                </>
            )}
        </Box>
    );
});

AllowEditingCell.displayName = 'AllowEditingCell';
