/**
 * Ratio of background color to text color
 */
export const colorTextByBg = <const>{
    transparent: 'midnight100',

    white100: 'midnight100',

    black100: 'white100',

    mint100: 'white100',
    mint90: 'white100',
    mint80: 'white100',
    mint70: 'midnight100',
    mint60: 'midnight100',
    mint50: 'midnight100',
    mint40: 'midnight100',

    silver100: 'midnight100',
    silver90: 'midnight100',
    silver80: 'midnight100',

    green100: 'white100',
    green90: 'white100',
    green80: 'white100',
    green70: 'white100',
    green60: 'white100',
    green50: 'midnight100',
    green40: 'midnight100',
    green30: 'midnight100',
    green20: 'midnight100',

    midnight100: 'white100',
    midnight90: 'white100',
    midnight80: 'white100',
    midnight70: 'white100',
    midnight60: 'white100',
    midnight50: 'midnight100',
    midnight40: 'midnight100',
    midnight30: 'midnight100',
    midnight20: 'midnight100',

    blue100: 'white100',
    blue90: 'white100',
    blue80: 'white100',
    blue70: 'white100',
    blue60: 'white100',
    blue50: 'white100',
    blue40: 'midnight100',
    blue30: 'midnight100',
    blue20: 'midnight100',
    blue10: 'midnight100',

    cobalt100: 'white100',
    cobalt90: 'white100',
    cobalt80: 'white100',
    cobalt70: 'white100',
    cobalt60: 'white100',
    cobalt50: 'midnight100',

    red100: 'white100',
    red90: 'white100',
    red80: 'white100',
    red70: 'white100',
    red60: 'white100',
    red50: 'midnight100',
    red40: 'midnight100',
    red30: 'midnight100',
    red20: 'midnight100',

    brandXero100: 'white100',
    brandXero90: 'white100',
    brandXero80: 'white100',
    brandXero70: 'white100',

    brandQBO100: 'white100',
    brandQBO90: 'white100',
    brandQBO80: 'white100',
    brandQBO70: 'white100',

    brandCin7100: 'white100',
    brandCin790: 'white100',
    brandCin780: 'white100',
    brandCin770: 'white100',

    brandDext100: 'white100',
    brandDext90: 'white100',

    brandDextMustard100: 'midnight100',
    brandDextMustard90: 'midnight100',
    brandDextMustard80: 'midnight100',
    brandDextMustard70: 'midnight100',

    brandAWX100: 'white100',
    brandAWX90: 'white100',
    brandAWX80: 'white100',

    brandNetSuite100: 'white100',
};
