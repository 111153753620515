import { z } from 'zod';

import { messages } from './EditPaymentDetailsPopupContent.messages';

export const schema = z.object({
    isDefault: z.boolean(),
    country: z.string().min(1, messages.requiredField).default(''),
    currency: z.string().min(1, messages.requiredField).default(''),
    name: z
        .string()
        .min(2, messages.bankAccountNameValidationError)
        .max(200, messages.bankAccountNameValidationError)
        .refine(
            (value) => {
                return !/[&<>'"/\\]/.test(value);
            },
            messages.bankAccountNameRestrictedCharsError({
                restrictedChars: '&, <, >, \', ", /, \\',
            })
        ),
    sortCode: z.string().length(6, messages.sortCodeValidationError),
    accountNumber: z.string().length(8, messages.bankAccountNumberValidationError),
});
