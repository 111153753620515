import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionFraudBypassing', {
    title: 'Fraud detection — bypassing the approval workflow',
    subtitle:
        'What is to happen if ApprovalMax detects an approved document in Xero, which did not pass through the approval workflow in ApprovalMax?',
    warning: 'To enable this option, select the Xero Requester in the workflow and save it.',
    doNotPullDocumentLabel: 'Do not pull this document to ApprovalMax',
    pullDocumentLabel: 'Pull this document to ApprovalMax',
    pullDocumentDescription:
        'Select from which date onwards this control is to be enforced. All approved and open {templateName} with a date equal or later will be pulled to ApprovalMax and put under control.',
    datePlaceholder: 'Pick a date...',
    notifyCheckboxText:
        "Notify the Organisation's Administrators about documents that bypassed the ApprovalMax workflow",
});
