import { MouseEvent, useCallback, useEffect, useState } from 'react';

import { AlertProps } from './Alert.types';

export const useClosable = (props: Pick<AlertProps, 'onClose' | 'open'>) => {
    const { onClose, open } = props;

    const [isOpen, setIsOpen] = useState(Boolean(open));

    useEffect(() => setIsOpen(Boolean(open)), [open]);

    const handleClose = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            setIsOpen(false);

            onClose && onClose(event);
        },
        [onClose]
    );

    return {
        isOpen,
        handleClose,
    };
};
