import { Link } from '@approvalmax/ui/src/components';
import { memo, MouseEvent, useCallback } from 'react';

import { Root } from './UrlReferenceCell.styles';
import { UrlReferenceCellProps } from './UrlReferenceCell.types';

export const UrlReferenceCell = memo<UrlReferenceCellProps>((props) => {
    const { value, className, onClick } = props;

    const handleClick = useCallback(
        (e: MouseEvent<HTMLAnchorElement>) => {
            if (onClick) {
                onClick();
                e.preventDefault();
            }
        },
        [onClick]
    );

    return value.url ? (
        <Root
            as={Link}
            className={className}
            ellipsis={1}
            href={value.url}
            font='body'
            target='_blank'
            rel='noopener noreferrer'
            onClick={handleClick}
            title={value.text}
        >
            {value.text}
        </Root>
    ) : (
        <Root ellipsis={1} className={className}>
            {value.text}
        </Root>
    );
});

UrlReferenceCell.displayName = 'UrlReferenceCell';
