import { AddUserIcon } from '@approvalmax/ui';

export const selectPropsMap = {
    matrix: {
        startIcon: <AddUserIcon size={24} color='blue100' />,
        textActivatorColor: 'blue100',
    },

    step: {
        uppercase: true,
        size: 'xsmall',
        buttonColor: 'blue80',
        noPadding: false,
        outline: true,
    },

    awx: {
        uppercase: true,
        size: 'xsmall',
        buttonColor: 'brandAWX100',
        noPadding: false,
        outline: true,
    },

    amaxPay: {
        uppercase: true,
        size: 'xsmall',
        buttonColor: 'cobalt80',
        noPadding: false,
        outline: true,
    },
} as const;
