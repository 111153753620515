import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.SubscribeAfterTrialPopup.PopupContent', {
    buttonText: 'Choose your plan',
    text: '{useCase} starting from {price} per month',
    description: '15,000+ businesses are doing it 40% faster with ApprovalMax ',

    expiredTrialUseCaseDefault: 'Strengthen your internal financial controls',
    expiredTrialUseCaseStreamlineAuditProcesses: 'Streamline audit processes',
    expiredTrialUseCaseEstablishInternalFinancialControls: 'Establish internal financial controls',
    expiredTrialUseCaseAutomateApprovalWorkflows: 'Automate approval workflows',
    expiredTrialUseCaseSpeedupTheApprovalProcess: 'Speed up the approval process',
    expiredTrialUseCaseStreamlineAuditProcessesAndEstablishInternalFinancialControls:
        'Streamline audits and establish financial controls',
    expiredTrialUseCaseStreamlineAuditProcessesAndAutomateApprovalWorkflows: 'Streamline audits and automate approvals',
    expiredTrialUseCaseStreamlineAuditProcessesAndSpeedupTheApprovalProcess: 'Streamline the approvals and audits',
    expiredTrialUseCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflows:
        'Establish financial controls with approval automation',
    expiredTrialUseCaseEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess:
        'Establish financial controls and speed up approvals',
    expiredTrialUseCaseAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess:
        'Speed up the approval process with automation',

    expiredTrialUseCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndAutomateApprovalWorkflows:
        'Establish financial controls by streamlining approvals and audits',
    expiredTrialUseCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess:
        'Establish financial controls, streamline audits, and automate approvals',
    expiredTrialUseCaseStreamlineAuditProcessesAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess:
        'Streamline your audits and speed up approvals with automation',
    expiredTrialUseCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess:
        'Establish financial controls and speed up approvals with automation',
});
