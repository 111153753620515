import { UseCase } from 'pages/auth/components/QualificationQuestions/QualificationQuestions.types';

import { messages } from './PopupContent.messages';

export const mapTextByUseCases = {
    [UseCase.StreamlineAuditProcesses]: messages.expiredTrialUseCaseStreamlineAuditProcesses,
    [UseCase.EstablishInternalFinancialControls]: messages.expiredTrialUseCaseEstablishInternalFinancialControls,
    [UseCase.AutomateApprovalWorkflows]: messages.expiredTrialUseCaseAutomateApprovalWorkflows,
    [UseCase.SpeedupTheApprovalProcess]: messages.expiredTrialUseCaseSpeedupTheApprovalProcess,

    [[UseCase.StreamlineAuditProcesses, UseCase.EstablishInternalFinancialControls].join(',')]:
        messages.expiredTrialUseCaseStreamlineAuditProcessesAndEstablishInternalFinancialControls,
    [[UseCase.StreamlineAuditProcesses, UseCase.AutomateApprovalWorkflows].join(',')]:
        messages.expiredTrialUseCaseStreamlineAuditProcessesAndAutomateApprovalWorkflows,
    [[UseCase.StreamlineAuditProcesses, UseCase.SpeedupTheApprovalProcess].join(',')]:
        messages.expiredTrialUseCaseStreamlineAuditProcessesAndSpeedupTheApprovalProcess,
    [[UseCase.EstablishInternalFinancialControls, UseCase.AutomateApprovalWorkflows].join(',')]:
        messages.expiredTrialUseCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflows,
    [[UseCase.EstablishInternalFinancialControls, UseCase.SpeedupTheApprovalProcess].join(',')]:
        messages.expiredTrialUseCaseEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess,
    [[UseCase.AutomateApprovalWorkflows, UseCase.SpeedupTheApprovalProcess].join(',')]:
        messages.expiredTrialUseCaseAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess,

    [[
        UseCase.StreamlineAuditProcesses,
        UseCase.EstablishInternalFinancialControls,
        UseCase.AutomateApprovalWorkflows,
    ].join(',')]:
        messages.expiredTrialUseCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndAutomateApprovalWorkflows,
    [[
        UseCase.StreamlineAuditProcesses,
        UseCase.EstablishInternalFinancialControls,
        UseCase.SpeedupTheApprovalProcess,
    ].join(',')]:
        messages.expiredTrialUseCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess,

    [[UseCase.StreamlineAuditProcesses, UseCase.AutomateApprovalWorkflows, UseCase.SpeedupTheApprovalProcess].join(
        ','
    )]: messages.expiredTrialUseCaseStreamlineAuditProcessesAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess,

    [[
        UseCase.EstablishInternalFinancialControls,
        UseCase.AutomateApprovalWorkflows,
        UseCase.SpeedupTheApprovalProcess,
    ].join(',')]:
        messages.expiredTrialUseCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess,

    [[
        UseCase.StreamlineAuditProcesses,
        UseCase.EstablishInternalFinancialControls,
        UseCase.AutomateApprovalWorkflows,
        UseCase.SpeedupTheApprovalProcess,
    ].join(',')]: messages.expiredTrialUseCaseDefault,
};
