import { Table } from '@approvalmax/ui/src/components';
import { FC, memo, useMemo } from 'react';

import { getColumns } from './BillInvoiceTableGroupedBySupplier.config';
import { mapToSelectableSupplierBillItems } from './BillInvoiceTableGroupedBySupplier.hooks';
import { Root } from './BillInvoiceTableGroupedBySupplier.styles';
import { BillInvoiceTableGroupedBySupplierProps } from './BillInvoiceTableGroupedBySupplier.types';

export const BillInvoiceTableGroupedBySupplier: FC<BillInvoiceTableGroupedBySupplierProps> = memo((props) => {
    const { selectableBills, toggleBill, toggleAllBills, toggleAllBillsBySupplierId, companyId } = props;

    const allBillsAreSelected = useMemo(
        () => !!selectableBills.length && selectableBills.every((bill) => bill.selected),
        [selectableBills]
    );

    const selectableSupplierBillItems = mapToSelectableSupplierBillItems(selectableBills);

    const columnsInScope = useMemo(
        () => getColumns({ companyId, toggleAllBills, allBillsAreSelected, toggleBill, toggleAllBillsBySupplierId }),
        [allBillsAreSelected, companyId, toggleAllBills, toggleAllBillsBySupplierId, toggleBill]
    );

    if (selectableSupplierBillItems.length === 0) return null;

    return (
        <Root>
            <Table columns={columnsInScope} data={selectableSupplierBillItems} stickyHeader />
        </Root>
    );
});

BillInvoiceTableGroupedBySupplier.displayName = 'BillInvoiceTableGroupedBySupplier';
