import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.AddBillInvoicesPopup.BillInvoiceTable', {
    nameColumnName: 'Name',
    supplierColumnName: 'Supplier',
    dateColumnName: 'Date',
    dueDateColumnName: 'Due date',
    plannedDateColumnName: 'Planned date',
    amountDueColumnName: 'Amount due',
    outstandingBalance: 'You owe: {amount}',
    overdueBalance: 'Overdue: {amount}',
});
