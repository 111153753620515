import { Field, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const StyledPopup = styled(Popup.DefaultContent)`
    width: 800px;
`;

export const Root = styled.div`
    padding: 0 60px 50px 80px;
    overflow: auto;
`;

export const FormRoot = styled.div``;

export const FormItem = styled(Field)`
    & + & {
        margin-top: 20px;
    }
`;
