import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';

import { MatrixType } from '../../types/matrix';
import { createHelpItemId, createHelpItemIdByIntegrationType, WorkflowHelpItemType } from '../../utils/helpItems';
import { messages } from './MatrixPopupContent.messages';

export const usePopupData = (
    matrixType: MatrixType,
    integrationCode: domain.IntegrationCode | null,
    stepName?: string
) => {
    const integrationType = selectors.integration.getIntegrationType(integrationCode);

    switch (matrixType) {
        case MatrixType.Approval:
            return {
                title: messages.approvalMatrixForTheStep({
                    step: stepName || undefined,
                }),
                helpItemId: createHelpItemId(WorkflowHelpItemType.Matrix, integrationCode),
            };

        case MatrixType.Editor:
            return {
                title: messages.reviewAndCodingMatrix,
                helpItemId: createHelpItemId(WorkflowHelpItemType.ApprovalMatrixReviewAndCoding, integrationCode),
            };

        case MatrixType.Requester:
            return {
                title: messages.requesterMatrix,
                helpItemId: createHelpItemId(WorkflowHelpItemType.Matrix, integrationCode, 'requester'),
            };

        case MatrixType.AutoApproval:
            return {
                title: messages.autoApprovalMatrix,
                helpItemId: createHelpItemIdByIntegrationType(WorkflowHelpItemType.AutoApprovalMatrix, integrationType),
            };

        case MatrixType.Reviewer:
            return {
                title: messages.reviewAndCodingMatrix,
                helpItemId: createHelpItemIdByIntegrationType(WorkflowHelpItemType.ReviewerMatrix, integrationType),
            };

        case MatrixType.Editing:
            return { title: messages.editingMatrix };

        default:
            throw errorHelpers.assertNever(matrixType);
    }
};
