import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.AirwallexPaymentStep', {
    addPayer: 'Add a Payer',
    bubbleText: 'Send payment via Airwallex',
    twoFaDisabled: '2FA is not enabled',
    twoFaEnabled: '2FA is active',
    twoFaDisabledTitle: '2FA is required to send payments with Airwallex',
    stepDescription:
        'Payers can check Airwallex balances and authorise the approved payments to go to Airwallex for processing.',
});
