import { domain } from 'modules/data';

import { anyConditionId } from './ExactAsyncConditionCell.constants';
import { messages } from './ExactAsyncConditionCell.messages';
import { ConditionTypeValue } from './ExactAsyncConditionCell.types';

export const getConditionItems = (fieldName: string): { id: ConditionTypeValue; name: string }[] => [
    {
        id: anyConditionId,
        name: messages.anyFieldName({ fieldName }),
    },
    {
        id: domain.ConditionType.ExactValuesCondition,
        name: messages.fieldNameMatches({ fieldName }),
    },
    {
        id: domain.ConditionType.NegativeExactValuesCondition,
        name: messages.fieldNameDoesNotMatch({ fieldName }),
    },
];

export const conditionTypeToConditionTypeValue = (conditionType: domain.ConditionType | null): ConditionTypeValue => {
    switch (conditionType) {
        case domain.ConditionType.ExactValuesCondition:
        case domain.ConditionType.NegativeExactValuesCondition:
            return conditionType;

        default:
            return anyConditionId;
    }
};
