import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.XeroPoStartStep', {
    bubbleText: 'Creation / Pulling of {pluralTemplateName}',
    title: `
        There are several methods to create or pull {pluralTemplateName} in ApprovalMax.
        Please see the options below and choose as applicable.
    `,
    createInApprovalMax: 'Create in ApprovalMax',
    description: 'Add users authorised to create {pluralTemplateName} and submit them for approval in ApprovalMax.',
    descriptionEmpty:
        'Add at least one user authorised to create {pluralTemplateName} and submit them for approval in ApprovalMax.',
    externalSubmitterDescriptionNonEmpty:
        'All "{templateName}" requests submitted directly in Xero will be created in the name of this ApprovalMax user.',
    externalSubmitterDescriptionEmpty: `
        Please select a Xero Requester.\n
        All "{templateName}" requests submitted directly in Xero will be created in the name of this ApprovalMax user.`,
});
