import { backend, domain } from 'modules/data';

export function parseQBooksPaymentAccount(value: backend.IntegrationsQBooksPaymentAccount): domain.QBooksAccount {
    const currency = value.Currency;

    return {
        id: value.Id,
        text: value.Name,
        ...(currency && {
            currency: {
                id: currency.ISOCode,
                text: currency.ISOCode,
            },
        }),
    };
}
