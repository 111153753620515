import { useCallback } from 'react';

import { feedbackPromotionStorageKey } from './FeedbackPromotion.constants';

export const useLocalStorage = () => {
    const isHideInStorage = localStorage.getItem(feedbackPromotionStorageKey) === 'true' || false;

    const setIsHiddenInStorage = useCallback((isHide: boolean) => {
        if (isHide) {
            localStorage.setItem(feedbackPromotionStorageKey, 'true');
        } else {
            localStorage.removeItem(feedbackPromotionStorageKey);
        }
    }, []);

    return {
        isHideInStorage,
        setIsHiddenInStorage,
    };
};
