import { Guid } from '@approvalmax/types';
import uniqueId from 'lodash/uniqueId';
import { backend } from 'modules/data';
import moment from 'moment';

import { Integration, IntegrationStatus, IntegrationType } from '../Integration';

export function createIntegration(options: {
    companyId: Guid;
    integrationType: IntegrationType;
    status: IntegrationStatus;
}): Integration {
    const now = moment().toISOString();

    return {
        id: uniqueId('temp.'),
        companyId: options.companyId,
        integrationType: options.integrationType,
        status: options.status,
        firstTimeConnection: true,
        isNew: true,
        integratedCompanyId: '',
        integratedCompanyName: '',
        externalUrl: '',
        createdDate: now,
        modifiedDate: now,
        lockDate: '',
        isBudgetSyncEnabled: false,
        isXeroDemo: false,
        countryCode: backend.OrganisationVersion.US,
    };
}
