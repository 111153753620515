import { Guid } from '@approvalmax/types';
import { backend } from 'modules/data';
import { stringify } from 'query-string';

import { ApiHandler } from '../types';

class CompaniesUrls {
    constructor(private _apiHandler: ApiHandler) {}

    public select(transfer: backend.transfers.CompanySelectTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}companies/select?${stringify(transfer)}`;
    }

    public getUserContext(transfer: backend.transfers.CompanySelectTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}companies/getUserContext?${stringify(transfer)}`;
    }

    public getParticipants(companyTransfer: backend.transfers.CompanyTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}companies/getParticipants?${stringify(companyTransfer)}`;
    }

    public getManagers(companyTransfer: backend.transfers.CompanyTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}companies/getManagers?${stringify(companyTransfer)}`;
    }

    public getAuditors(companyTransfer: backend.transfers.CompanyTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}companies/getAuditors?${stringify(companyTransfer)}`;
    }

    public selectFields(transfer: backend.transfers.SelectFieldsTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}companies/selectFields?${stringify(transfer)}`;
    }

    public getIntegrationSyncProgress(transfer: backend.transfers.CompaniesIntegrationSyncProgressTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}companies/getIntegrationSyncProgress?${stringify(transfer)}`;
    }
}

export default class CompaniesApi {
    public urls: CompaniesUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new CompaniesUrls(this._apiHandler);
    }

    public create(transfer: backend.transfers.CompanyCreateTransfer): Promise<backend.CompaniesSelectAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/create',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public delete(transfer: backend.transfers.CompanyTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/delete',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public edit(editTransfer: backend.transfers.CompanyEditTransfer): Promise<backend.Answer<backend.CompanyAnswer>> {
        return this._apiHandler.doApiCall({
            data: editTransfer,
            action: 'companies/edit',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public select(transfer: backend.transfers.CompanySelectTransfer): Promise<backend.CompaniesSelectAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/select',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getUserContext(
        transfer: backend.transfers.CompanySelectTransfer
    ): Promise<backend.CompaniesUserCompaniesContextAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/getUserContext',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getParticipants(
        companyTransfer: backend.transfers.CompanyTransfer
    ): Promise<backend.CompanyParticipantsAnswer> {
        return this._apiHandler.doApiCall({
            data: companyTransfer,
            action: 'companies/getParticipants',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getManagers(companyTransfer: backend.transfers.CompanyTransfer): Promise<backend.CompanyParticipantsAnswer> {
        return this._apiHandler.doApiCall({
            data: companyTransfer,
            action: 'companies/getManagers',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getAuditors(companyTransfer: backend.transfers.CompanyTransfer): Promise<backend.CompanyParticipantsAnswer> {
        return this._apiHandler.doApiCall({
            data: companyTransfer,
            action: 'companies/getAuditors',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public addParticipants(
        transfer: backend.transfers.CompaniesAddParticipantsTransfer
    ): Promise<backend.CompanyParticipantsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/addParticipants',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public editParticipants(
        transfer: backend.transfers.CompaniesEditParticipantsTransfer
    ): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/editParticipants',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public inviteParticipants(
        transfer: backend.transfers.CompaniesInviteParticipantsTransfer
    ): Promise<backend.CompanyParticipantsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/inviteParticipants',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public respondToInvitation(
        transfer: backend.transfers.CompaniesRespondToInvitationTransfer
    ): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/respondToInvitation',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public createFields(fieldsTransfer: backend.transfers.CreateFieldsTransfer): Promise<backend.FieldsAnswer> {
        return this._apiHandler.doApiCall({
            data: fieldsTransfer,
            action: 'companies/createFields',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public editFields(editTrasnfer: backend.transfers.EditFieldsTransfer): Promise<backend.FieldsAnswer> {
        return this._apiHandler.doApiCall({
            data: editTrasnfer,
            action: 'companies/editFields',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public deleteFields(deleteTrasnfer: backend.transfers.DeleteFieldsTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: deleteTrasnfer,
            action: 'companies/deleteFields',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public selectFields(transfer: backend.transfers.SelectFieldsTransfer): Promise<backend.FieldsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/selectFields',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public enableXeroOAuth1Integration(
        transfer: backend.transfers.OAuthEnableIntegrationTransfer
    ): Promise<backend.OAuthIntegrationAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/enableXeroOAuth1Integration',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public enableXeroOAuth2Integration(
        transfer: backend.transfers.OAuthEnableIntegrationTransfer
    ): Promise<backend.OAuthIntegrationAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/enableXeroOAuth2Integration',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public enableQuickBooksIntegration(
        transfer: backend.transfers.OAuthEnableIntegrationTransfer
    ): Promise<backend.OAuthIntegrationAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/enableQuickBooksIntegration',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public exchangeCodeForAccessToken(
        integrationTransfer: backend.transfers.ExchangeCodeForAccessTokenTransfer
    ): Promise<backend.ExchangeCodeForAccessTokenAnswer> {
        return this._apiHandler.doApiCall({
            data: integrationTransfer,
            action: 'companies/exchangeCodeForAccessToken',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public finishXeroIntegration(
        integrationTransfer: backend.transfers.XeroFinishIntegrationTransfer
    ): Promise<backend.CompaniesSelectAnswer> {
        return this._apiHandler.doApiCall({
            data: integrationTransfer,
            action: 'companies/finishXeroIntegration',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public finishQuickBooksIntegration(
        transfer: backend.transfers.QBooksFinishIntegrationTransfer
    ): Promise<backend.CompaniesSelectAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/finishQuickBooksIntegration',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public disableIntegration(
        integrationTransfer: backend.transfers.IntegrationTransfer
    ): Promise<backend.CompaniesSelectAnswer> {
        return this._apiHandler.doApiCall({
            data: integrationTransfer,
            action: 'companies/disableIntegration',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getIntegrationSyncProgress(
        transfer: backend.transfers.CompaniesIntegrationSyncProgressTransfer
    ): Promise<backend.IntegrationsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/getIntegrationSyncProgress',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public pullIntegrations(transfer: backend.transfers.PullIntegrationsTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/pullIntegrations',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getOffboardedParticipantContext<T>(transfer: { userId: string; companyId: string }): Promise<T> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/GetOffboardedParticipantContext',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public offboardParticipant<T>(transfer: T): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/OffboardParticipant',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getShardedIntegrationSyncProgress(
        transfer: {
            companyId: Guid;
            integrationIds: Guid[];
        }[]
    ): Promise<backend.IntegrationsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'companies/getShardedIntegrationSyncProgress',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
