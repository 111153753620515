import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.auth.QualificationQuestions', {
    title: 'Please describe your company',
    companyPartner: 'Accounting / Bookkeeping practice',
    companyBusiness: 'A business / Non-Profit organization',
    partnerStatusPlaceholder: 'Accounting platform or cloud partner status',
    numberOfClientsPlaceholder: 'Select the number of clients you have',
    productInterestPlaceholder: 'Select your accounting platform',
    numberOfEntitiesPlaceholder: 'Select the number of entities in your company',
    useCasePlaceholder: 'Choose the reasons for using ApprovalMax',
    selectedCount: '{count} selected',

    partnerStatusXeroPlatinum: 'Xero Platinum',
    partnerStatusXeroGold: 'Xero Gold',
    partnerStatusXeroOther: 'Xero Other',
    partnerStatusQBOPlatinum: 'QuickBooks Online Platinum',
    partnerStatusQBOElite: 'QuickBooks Online Elite',
    partnerStatusQBOOther: 'QuickBooks Online Other',
    partnerStatusOracleNetSuite: 'Oracle NetSuite',
    partnerStatusHybrid: 'Hybrid',
    partnerStatusOther: 'Other',

    productInterestXero: 'Xero',
    productInterestQuickBooksOnline: 'QuickBooks Online',
    productInterestOracleNetSuite: 'Oracle NetSuite',
    productInterestCin7Core: 'Cin7 Core',
    productInterestHybrid: 'Hybrid',
    productInterestOther: 'Other',

    numberOfEntitiesLess3: 'Less than 3',
    numberOfEntitiesBeetween3_10: '3 - 10',
    numberOfEntitiesMore10: '10+',

    numberOfClientsBeetween1_10: '1 - 10',
    numberOfClientsBeetween10_50: '10 - 50',
    numberOfClientsMore50: '50+',

    useCaseStreamlineAuditProcesses: 'Streamline audit processes',
    useCaseEstablishInternalFinancialControls: 'Establish internal financial controls',
    useCaseAutomateApprovalWorkflows: 'Automate approval workflows',
    useCaseSpeedupTheApprovalProcess: 'Speed up the approval process',
    useCaseOther: 'Other',
});
