import { QueryOptions } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data';

import { myAccountApiPaths } from '../paths';
import { UseCalculationPriceParams, UseCalculationPriceResponse } from './useCalculationPrice.types';

export const useCalculationPrice = (
    params: UseCalculationPriceParams,
    queryOptions?: QueryOptions<UseCalculationPriceResponse>
) => {
    return useGetRequestLegacy<UseCalculationPriceResponse>(myAccountApiPaths.calculationPrice, params, {
        params: {
            queryOptions,
            accountId: params.accountId,
        },
        apiSource: 'myAccount',
    });
};
