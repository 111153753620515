import { selectors } from 'modules/common';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { UserPickerProps } from './UserPicker.types';

export const useValue = (props: Pick<UserPickerProps, 'value' | 'onChange' | 'users'>) => {
    const { value, onChange, users } = props;

    const foundUser = useMemo(() => {
        return users.find((user) => user.id === value);
    }, [users, value]);

    const [selectedUser, setSelectedUser] = useState<selectors.types.ExpandedCompanyUser | null>(foundUser ?? null);

    useEffect(() => {
        setSelectedUser(foundUser ?? null);
    }, [foundUser]);

    const handleSelectUser = useCallback(
        (user: selectors.types.ExpandedCompanyUser | null) => {
            setSelectedUser(user);

            onChange?.(user?.id || '');
        },
        [onChange]
    );

    return {
        selectedUser,
        handleSelectUser,
    };
};
