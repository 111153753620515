import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.MatrixPopupContent.CopyRulePopup', {
    title: 'Copy workflow',
    all: 'All',
    name: 'Name',
    email: 'Email',
    role: 'Role',
    workflowSteps: 'Workflow steps',
    columnsStep: 'Step 1: Choose Columns to copy',
    usersStep: 'Step 2: Choose Users & Steps to copy to',
    copy: 'Copy',
    next: 'Next',
    back: 'Back',
    accountOwner: 'Account owner',
    errorMessage: 'Please ensure that both user(s) and step(s) are set to proceed with the copy.',
    errorMessageColumn: 'Select columns to copy rule',
});
