import { domHelpers } from '@approvalmax/utils';
import { forwardRef, memo } from 'react';

import { CloseIcon } from '../../icons';
import Button from '../Button/Button';
import Text from '../Text/Text';
import { useClosable } from './Tag.hooks';
import { messages } from './Tag.messages';
import { Root } from './Tag.styles';
import { TagProps } from './Tag.types';

/**
 * The Tag component is used for items that need to be labeled, categorized, or organized using keywords that describe them.
 */
const Tag = forwardRef<HTMLDivElement, TagProps>((props, ref) => {
    const {
        color = 'blue80',
        rounded,
        outline,
        size = 'medium',
        onClose,
        children,
        closable,
        backgroundColor,
        qa,
        title: titleProp,
        wrap,
        ...restProps
    } = props;

    const { isOpen, handleClose } = useClosable({ onClose });

    const title = titleProp || (typeof children === 'string' ? children : undefined);

    if (!isOpen) return null;

    return (
        <Root
            ref={ref}
            $color={color}
            $size={size}
            $outline={outline}
            $rounded={rounded}
            $backgroundColor={backgroundColor}
            data-qa={domHelpers.generateDataQa(qa, 'tag')}
            {...restProps}
        >
            <Text title={title} font='label' fontSize={size} ellipsis={1} wrap={wrap}>
                {children}
            </Text>

            {closable && (
                <Button onClick={handleClose} icon size={size} title={messages.remove({ title })}>
                    <CloseIcon />
                </Button>
            )}
        </Root>
    );
});

// The wrap around in the memo here is a fix for displaying the component in StoryBook
export default memo(Tag);
