import { Guid } from '@approvalmax/types';

import { IntegrationType } from './Integration';

export enum SsoStartingPoint {
    Marketplace = 'marketplace',
    ApprovalMax = 'approvalmax',
}

export enum OAuthProvider {
    Xero = 'xero',
    QBooks = 'qbooks',
    Google = 'google',
    Microsoft = 'microsoft',
}

export type OAuthIntegrationFinalPage = 'company' | 'workflow';

export type OAuthUiState =
    | {
          type: 'sso';
          provider: OAuthProvider;
          startingPoint: SsoStartingPoint;
          allowedEmail?: string;
      }
    | {
          type: 'oauth';
          integrationType: IntegrationType;
          companyId: Guid | null;
          finalPage: OAuthIntegrationFinalPage;
      };
