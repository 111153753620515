import { Module } from 'modules/page';

import ModuleComponent from './ModuleComponent';
import entitiesReducer from './reducers/entitiesReducer';
import moduleReducer from './reducers/moduleReducer';

export * as actions from './actions';

export const moduleDef: Module = {
    id: 'automation',
    moduleReducer: moduleReducer,
    moduleComponent: ModuleComponent,
    reducers: {
        entities: entitiesReducer,
    },
};
