import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UseOffboardParticipantWithAllRequestsReplacementData } from './useOffboardParticipantWithAllRequestsReplacement.types';

export const useOffboardParticipantWithAllRequestsReplacement = (
    mutationOptions?: MutationOptions<any, RequestDataParams<UseOffboardParticipantWithAllRequestsReplacementData>>
) => {
    return useMutateLegacy<RequestDataParams<UseOffboardParticipantWithAllRequestsReplacementData>>(
        companiesApiPaths.offboardParticipantWithAllRequestsReplacement,
        {
            mutationOptions,
        }
    );
};
