import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { requestsApiPaths } from '../paths';
import {
    UseGetNetSuiteBillPaymentBillsRequestParams,
    UseGetNetSuiteBillPaymentBillsRequestPathParams,
    UseGetNetSuiteBillPaymentBillsResponse,
} from './useGetNetSuiteBillPaymentBills.types';

export const useGetNetSuiteBillPaymentBills = (
    params: UseGetNetSuiteBillPaymentBillsRequestParams,
    pathParams: UseGetNetSuiteBillPaymentBillsRequestPathParams,
    queryOptions?: QueryOptions<UseGetNetSuiteBillPaymentBillsResponse>
) => {
    return useGetRequest<UseGetNetSuiteBillPaymentBillsResponse>(requestsApiPaths.netSuiteBillPaymentBills, {
        params,
        pathParams,
        queryOptions,
        apiVersion: 'v2',
    });
};
