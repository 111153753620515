import { useApiContext } from '@approvalmax/data';
import { PartyImage } from '@approvalmax/ui';
import { Button, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { constants } from '@approvalmax/utils';
import { memo, useCallback, useEffect, useRef } from 'react';
import { usePrevious, useToggle } from 'react-use';
import { storageService } from 'services/storage';

import { messages } from './NewAppVersionPopup.messages';

const NewAppVersionPopup = memo(() => {
    const isAuthenticated = storageService.authenticated();
    const [open, toggleOpen] = useToggle(false);
    const prevOpen = usePrevious(open);

    const { webAppVersion } = useApiContext();
    const lastDisplayTimeReloadPopup = useRef<Date>();
    const hasScheduledPopup = useRef<boolean>(false);
    const isCheckPopupDisplay =
        isAuthenticated &&
        constants.isProd &&
        webAppVersion &&
        webAppVersion !== window.ApprovalMax.appVersion &&
        !hasScheduledPopup.current;

    useEffect(() => {
        if (!isCheckPopupDisplay) return;

        const nextPopupTime = lastDisplayTimeReloadPopup.current
            ? new Date(lastDisplayTimeReloadPopup.current.getTime() + 48 * 60 * 60 * 1000)
            : new Date(0);

        if (new Date() > nextPopupTime) {
            lastDisplayTimeReloadPopup.current = new Date();
            hasScheduledPopup.current = true;

            setTimeout(
                () => {
                    toggleOpen(true);
                },
                Math.random() * 8 * 60 * 60 * 1000
            );
        }
    }, [isCheckPopupDisplay, toggleOpen]);

    useEffect(() => {
        if (prevOpen && !open) {
            hasScheduledPopup.current = false;
        }
    }, [open, prevOpen]);

    const reloadPage = useCallback(() => location.reload(), []);

    return (
        <Popup open={open} onToggle={toggleOpen} size='xsmall'>
            <Popup.Body spacing='16 32 32'>
                <Flex inline direction='column' alignItems='center' spacing='16'>
                    <PartyImage width={100} height={100} />

                    <Text font='headline' fontSize='small' fontWeight='medium' textAlign='center' as='h3'>
                        {messages.title}
                    </Text>

                    <Text font='body' fontSize='medium' textAlign='center'>
                        {messages.description({ br: <br /> })}
                    </Text>

                    <Text font='body' fontSize='medium' textAlign='center'>
                        {messages.reloadText}
                    </Text>

                    <Button onClick={reloadPage} size='large' color='blue80'>
                        {messages.reload}
                    </Button>
                </Flex>
            </Popup.Body>
        </Popup>
    );
});

export default NewAppVersionPopup;
