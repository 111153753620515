import { ImmutableObject } from 'modules/immutable';

import { Action } from '../actions';

interface LocalUserPreferences {}

export default function (
    state: ImmutableObject<LocalUserPreferences>,
    action: Action
): ImmutableObject<LocalUserPreferences> {
    switch (action.type) {
        default:
            return state;
    }
}
