import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    UseVerifyTFADisablingEmailCodeRequest,
    UseVerifyTFADisablingEmailCodeResponse,
} from './useVerifyTFADisablingEmailCode.types';

export const useVerifyTFADisablingEmailCode = () => {
    return useMutateLegacy<
        RequestDataParams<UseVerifyTFADisablingEmailCodeRequest>,
        UseVerifyTFADisablingEmailCodeResponse
    >(twoFaApiPaths.disablingVerifyEmailCode);
};
