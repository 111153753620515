import { selectors } from 'modules/common';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { HelpSectionWrapper, StyledVelocityTransitionGroup } from './HelpStripe.styles';
import { HelpStripeProps } from './HelpStripe.types';

const HelpStripe = memo<HelpStripeProps>((props) => {
    const { helpItemId, hideProfessionalServices } = props;

    const hiddenHelpItems = useSelector(selectors.userPreferences.getHiddenHelpItems);
    const isVisible = !hiddenHelpItems.includes(helpItemId);

    return (
        <StyledVelocityTransitionGroup
            enter={{ animation: 'slideDown', duration: 300 }}
            leave={{ animation: 'slideUp', duration: 300 }}
        >
            {isVisible && (
                <div>
                    <HelpSectionWrapper helpItemId={helpItemId} hideProfessionalServices={hideProfessionalServices} />
                </div>
            )}
        </StyledVelocityTransitionGroup>
    );
});

export default HelpStripe;
