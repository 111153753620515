import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionSafetyCatch', {
    title: 'Safety catch',
    subtitle: 'How are requests to be handled if there is no Approver added for them in the approval workflow setup?',
    optionApprove: 'Approve the request automatically',
    optionReject: "Reject the request automatically and notify the Organisation's Administrators",
    warningText:
        'Please notice, the update of this setting would be applied only after saving the settings and updating the workflow.',
});
