import './button.scss';

import { errorHelpers } from '@approvalmax/utils';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import bemFactory from 'react-bem-factory';

import { DropdownButtonElement, DropdownButtonProps, DropdownMode, DropdownSingleValueType } from './types';

type OwnProps = DropdownButtonProps<DropdownSingleValueType>;

const Button = forwardRef<DropdownButtonElement, OwnProps>((props, ref) => {
    const {
        placeholder,
        value,
        displayTextSelector,
        mode,
        disabled,
        invalid,
        warning,
        theme,
        loading: loadingFlag,
        buttonTitleSelector,
        title,
    } = props;

    const active = mode === DropdownMode.Active;
    const invisibleInput = mode === DropdownMode.Passive;
    const displayText = displayTextSelector(value);
    const buttonTitle = typeof title === 'string' ? title : buttonTitleSelector?.(value);

    let inputText;
    let showPlaceholder;

    switch (mode) {
        case DropdownMode.Active:
            inputText = props.inputText;
            showPlaceholder = !inputText;
            break;

        case DropdownMode.Passive:
            inputText = '';
            showPlaceholder = !displayText;
            break;

        case DropdownMode.Unfocused:
            inputText = displayText || '';
            showPlaceholder = !inputText;
            break;

        default:
            throw errorHelpers.assertNever(mode);
    }

    const loading = loadingFlag && mode !== DropdownMode.Unfocused;

    const bem = bemFactory.block('form-dropdown-editor-button').themed(theme);
    const qa = bemFactory.qa('form-dropdown-editor-button');

    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => {
        return {
            focus() {
                inputRef.current?.focus();
            },
            blur() {
                inputRef.current?.blur();
            },
            select() {
                inputRef.current?.select();
            },
        };
    });

    const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onTextChange(e.target.value);
    };

    const onMouseDown = (e: React.SyntheticEvent<any>) => {
        const isActive = props.mode === DropdownMode.Active;

        if (!isActive) {
            e.preventDefault();
            inputRef.current?.select();
        }
    };

    return (
        <div
            className={bem(null, {
                loading,
                focused: mode !== DropdownMode.Unfocused,
                invalid,
                warning,
            })}
            data-qa={qa()}
            onMouseDown={onMouseDown}
            title={buttonTitle || (value ? displayText : undefined)}
        >
            <div
                className={bem('display-text', {
                    invalid,
                    visible: invisibleInput,
                    focused: mode !== DropdownMode.Unfocused,
                    warning,
                })}
                aria-hidden={!invisibleInput}
            >
                {displayText}
            </div>

            <input
                className={bem('input', {
                    invisible: invisibleInput,
                    active,
                    invalid,
                    warning,
                    disabled,
                })}
                data-qa={qa('input')}
                ref={inputRef}
                type='search'
                autoComplete='off'
                value={inputText}
                onChange={onTextChange}
                disabled={disabled}
                aria-hidden={invisibleInput}
                aria-autocomplete='list'
            />

            {showPlaceholder && <div className={bem('input-placeholder', { invalid, warning })}>{placeholder}</div>}
        </div>
    );
});

export default Button;
