import { Guid } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { actions, selectors } from 'modules/common';
import { backend, domain, Entities, schemas, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions, ThunkAction } from 'modules/react-redux';
import { normalize } from 'normalizr';
import { api } from 'services/api';
import { facebookService } from 'services/facebook';
import { gaService } from 'services/ga';
import { linkedInService } from 'services/linkedIn';
import { routingService } from 'services/routing';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { getActiveCompany, getModule } from '../selectors/moduleSelectors';

export const CREATE_COMPANY = 'COMPANY/CREATE_COMPANY';
export const CREATE_COMPANY_RESPONSE = 'COMPANY/CREATE_COMPANY_RESPONSE';
export const CREATE_COMPANY_FAILURE = 'COMPANY/CREATE_COMPANY_FAILURE';
export const createCompany = (
    integrationType: domain.IntegrationType | null,
    callback: (companyCreatedInfo: backend.CompanyCreatedInfo) => void // TODO: reconsider the callback logic into something better, we need companyId
) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(CREATE_COMPANY, {
                userEmail: selectors.profile.getProfileUser(state).userEmail,
                existingAccount: selectors.profile.getProfile(state).account,
                invitations: selectors.companyInvitation.getAllCompanyInvitations(state),
            }),

        response: async (request) => {
            const response = await api.companies.create({
                ownerEmail: request.userEmail,
                timeZone: window.ApprovalMax.userTimeZone,
            });

            let newAccount;
            let userProfile: domain.Profile = {} as domain.Profile;

            let entities = normalize(response, { Companies: [schemas.companySchemaLegacy] })
                .entities as unknown as Entities;

            const company = Object.values(entities.companies)[0];

            if (!request.existingAccount) {
                const { Account: accountRaw, UserProfile } = await api.companies.getUserContext({});

                if (request.invitations.length === 0 && accountRaw) {
                    facebookService.trackSignUp();
                    linkedInService.trackSignUp();

                    gaService.setProperty('user_properties', {
                        am_role: 'account_owner',
                    });

                    let integrationName = '';

                    switch (integrationType) {
                        case domain.IntegrationType.Xero:
                            integrationName = 'xero';
                            break;

                        case domain.IntegrationType.QBooks:
                            integrationName = 'qbo';
                            break;

                        case domain.IntegrationType.NetSuite:
                            integrationName = 'netsuite';
                            break;

                        case domain.IntegrationType.Dear:
                            integrationName = 'cin7_core';
                            break;
                    }

                    await gaService.sendEvent('org_created', {
                        integration: integrationName,
                    });
                }

                (UserProfile as any).Account = accountRaw;
                userProfile = Object.values(
                    (normalize(UserProfile, schemas.profileSchema).entities as any).profiles
                )[0] as any;
            }

            // Hardcoded default matching settings, may change in the future
            company.billMatchingSettings = {
                addMatchedPOsRequestersAsApproversForBill:
                    domain.AddMatchedPOsRequestersAsApproversForBillStatuses.None,
                autoMatchingEnabled: true,
                manualMatchingEnabled: true,
                canMatchBillsWithRetrospectivePurchaseOrders: false,
                allowApprovalOfNotMatchedBills: domain.CompanyMatchingSettingsBillApprovalPolicy.Always,
                notMatchedBillsApprovalThreshold: 0,
                allowApprovalOfOverbudgetBills: domain.CompanyMatchingSettingsBillApprovalPolicy.Always,
                overbudgetBillsApprovalThreshold: 0,
                overbudgetBillsApprovalPercentageThreshold: 0,
                amountType: domain.MatchingAmountType.Gross,
                insufficientBudgetMatchBillsApprovalAllowed: domain.ApprovalAllowanceType.Always,
                insufficientBudgetMatchBillsApprovalThreshold: 0,
                insufficientBudgetMatchBillsApprovalPercentageThreshold: 0,
            };
            company.purchaseOrderMatchingSettings = {
                autoMarkingPurchaseOrderAsBilledEnabled: false,
                autoUnmarkingPurchaseOrderAsBilledEnabled: false,
            };

            return createAction(
                CREATE_COMPANY_RESPONSE,
                {
                    request,
                    companyId: company.id,
                    licenseProductPlan: company.licenseProductPlan,
                    licenseId: company.licenseId,
                    newAccount,
                    userProfile,
                },
                entities
            );
        },

        failure: (error) => createErrorAction(CREATE_COMPANY_FAILURE, error, {}),

        didDispatchResponse: (request, response, _, dispatch) => {
            callback({
                CompanyId: response.companyId,
                LicenseId: response.licenseId,
                LicenseProductPlan: response.licenseProductPlan,
            });

            if (response.userProfile.email) {
                dispatch(actions.updateProfile(response.userProfile.email, {}, response.userProfile));
            }
        },
    });

export const SHOW_ORGANISATION_POST_CREATION_WIZARD = 'COMPANY/SHOW_ORGANISATION_POST_CREATION_WIZARD';
export const SHOW_ORGANISATION_POST_CREATION_WIZARD_RESPONSE =
    'COMPANY/SHOW_ORGANISATION_POST_CREATION_WIZARD_RESPONSE';
export const SHOW_ORGANISATION_POST_CREATION_WIZARD_FAILURE = 'COMPANY/SHOW_ORGANISATION_POST_CREATION_WIZARD_FAILURE';
export const showOrganisationPostCreationWizard = (options: { companyId: string }) =>
    createAsyncAction({
        request: (state: State) => {
            const company = selectors.company.getCompanyById(state, options.companyId);

            return createAction(SHOW_ORGANISATION_POST_CREATION_WIZARD, {
                me: selectors.profile.getProfileUser(state).userEmail,
                companyId: company.id,
                licenseId: company.licenseId,
                accountingPlatform: company.integration!.integrationType,
                isXeroDemo: company.integration?.isXeroDemo,
            });
        },

        response: async (request) => {
            return createAction(SHOW_ORGANISATION_POST_CREATION_WIZARD_RESPONSE, {
                request,
            });
        },

        didDispatchResponse: (request, response, state, dispatch) => {
            if (request.isXeroDemo) {
                dispatch(showXeroDemoOrganisationPopup({ companyId: request.companyId }));
            } else {
                dispatch(fetchActionsForNewCompany(request.companyId));
            }
        },

        failure: (error) => createErrorAction(SHOW_ORGANISATION_POST_CREATION_WIZARD_FAILURE, error, {}),
    });

export const SHOW_SUBSCRIPTION_POPUP = 'COMPANY/SHOW_SUBSCRIPTION_POPUP';
export const SHOW_SUBSCRIPTION_POPUP_RESPONSE = 'COMPANY/SHOW_SUBSCRIPTION_POPUP_RESPONSE';
export const SHOW_SUBSCRIPTION_POPUP_FAILURE = 'COMPANY/SHOW_SUBSCRIPTION_POPUP_FAILURE';
export const showSubscriptionPopup = (options: { companyId: string }) =>
    createAsyncAction({
        request: (state: State) => {
            const accountId = selectors.profile.getProfileAccount(state).accountId;

            return createAction(SHOW_SUBSCRIPTION_POPUP, {
                me: selectors.profile.getProfileUser(state).userEmail,
                companyId: options.companyId,
                accountId,
            });
        },

        response: async (request) => {
            let applicableSubscriptions: string[] = [];
            let pricingOptions: domain.PricingOptions[] = [];
            let subscriptions: domain.Subscription[] = [];

            const [subscriptionsResponse, applicableSubscriptionsResponse, responsePricing] = await Promise.all([
                api.account.getSubscriptions({ accountId: request.accountId }),
                api.account.getApplicableSubscriptions({
                    companyIds: [request.companyId],
                    email: request.me,
                    accountId: request.accountId,
                }),
                api.account.getSubscriptionPricingOptions({
                    email: request.me,
                    accountId: request.accountId,
                }),
            ]);

            pricingOptions = responsePricing.options;
            applicableSubscriptions = applicableSubscriptionsResponse.companyToSubscriptions[0].subscriptionIds;
            subscriptions = subscriptionsResponse.subscriptions;

            return createAction(SHOW_SUBSCRIPTION_POPUP_RESPONSE, {
                companyId: request.companyId,
                applicableSubscriptions,
                pricingOptions,
                subscriptions,
            });
        },

        failure: (error) => createErrorAction(SHOW_SUBSCRIPTION_POPUP_FAILURE, error, {}),
    });

export const SHOW_PLAN_POPUP = 'COMPANY/SHOW_PLAN_POPUP';
export const showPlanPopup = (options: { companyId: string }) =>
    createAction(SHOW_PLAN_POPUP, {
        companyId: options.companyId,
    });

export const SELECT_TRIAL_SUBSCRIPTION = 'COMPANY/SELECT_TRIAL_SUBSCRIPTION';
export const selectTrialSubscription = () => createAction(SELECT_TRIAL_SUBSCRIPTION, {});

export const SELECT_NEW_SUBSCRIPTION = 'COMPANY/SELECT_NEW_SUBSCRIPTION';
export const selectNewSubscription = () => createAction(SELECT_NEW_SUBSCRIPTION, {});

export const SELECT_EXISTING_SUBSCRIPTION = 'COMPANY/SELECT_EXISTING_SUBSCRIPTION';
export const SELECT_EXISTING_SUBSCRIPTION_RESPONSE = 'COMPANY/SELECT_EXISTING_SUBSCRIPTION_RESPONSE';
export const SELECT_EXISTING_SUBSCRIPTION_FAILURE = 'COMPANY/SELECT_EXISTING_SUBSCRIPTION_FAILURE';
export const selectExistingSubscription = (subscriptionId: string) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(SELECT_EXISTING_SUBSCRIPTION, {
                me: selectors.profile.getProfileUser(state).userEmail,
                accountId: selectors.profile.getProfileAccount(state).accountId,
                companyId: getActiveCompany(state).id,
                subscriptionId,
            }),

        response: async (request) => {
            await api.account.setCompanySubscription({
                companiesWithSubscriptions: [
                    {
                        companyId: request.companyId,
                        subscriptionId: request.subscriptionId,
                    },
                ],
                accountId: request.accountId,
            });

            const context = await api.companies.getUserContext({});
            const { entities } = actions.getUserContextEntities(context);

            return createAction(
                SELECT_EXISTING_SUBSCRIPTION_RESPONSE,
                {
                    request,
                },
                entities
            );
        },

        failure: (error) => createErrorAction(SELECT_EXISTING_SUBSCRIPTION_FAILURE, error, {}),
    });

export const COMMIT_SUBSCRIPTION_UPGRADE = 'COMPANY/COMMIT_SUBSCRIPTION_UPGRADE';
export const COMMIT_SUBSCRIPTION_UPGRADE_RESPONSE = 'COMPANY/COMMIT_SUBSCRIPTION_UPGRADE_RESPONSE';
export const COMMIT_SUBSCRIPTION_UPGRADE_FAILURE = 'COMPANY/COMMIT_SUBSCRIPTION_UPGRADE_FAILURE';
export const commitSubscriptionUpgrade = (options: {
    subscriptionId: Guid;
    plan: domain.CompanyPlan;
    qty: number;
    billingCycle: domain.SubscriptionBillingCycle | null;
    currency: domain.NextBillingCurrency | null;
    organisationIds: string[];
}) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(COMMIT_SUBSCRIPTION_UPGRADE, {
                ...options,
                plan: options.plan,
                email: selectors.profile.getProfileUser(state).userEmail,
                accountId: selectors.profile.getProfileAccount(state).accountId,
            }),

        response: async (request) => {
            await Promise.resolve(
                api.account.subscriptionUpgradePartnerSubscription({
                    planId: request.plan,
                    subscriptionId: request.subscriptionId,
                    qty: request.qty,
                    cycle: request.billingCycle,
                    currency: request.currency,
                    email: request.email,
                    accountId: request.accountId,
                    organisationIds: request.organisationIds,
                })
            );

            const context = await api.companies.getUserContext({});
            const { entities } = actions.getUserContextEntities(context);

            return createAction(
                COMMIT_SUBSCRIPTION_UPGRADE_RESPONSE,
                {
                    request,
                },
                entities
            );
        },

        failure: (error) => createErrorAction(COMMIT_SUBSCRIPTION_UPGRADE_FAILURE, error),
    });

export const COMMIT_STRIPE_SUBSCRIPTION_UPGRADE = 'COMPANY/COMMIT_STRIPE_SUBSCRIPTION_UPGRADE';
export const COMMIT_STRIPE_SUBSCRIPTION_UPGRADE_RESPONSE = 'COMPANY/COMMIT_STRIPE_SUBSCRIPTION_UPGRADE_RESPONSE';
export const COMMIT_STRIPE_SUBSCRIPTION_UPGRADE_FAILURE = 'COMPANY/COMMIT_STRIPE_SUBSCRIPTION_UPGRADE_FAILURE';
export const commitStripeSubscriptionUpgrade = (options: {
    subscriptionId: Guid;
    plan: domain.CompanyPlan;
    qty: number;
    billingCycle: domain.SubscriptionBillingCycle | null;
    currency: domain.NextBillingCurrency | null;
    organisationIds: string[];
}) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(COMMIT_STRIPE_SUBSCRIPTION_UPGRADE, {
                ...options,
                plan: options.plan,
                email: selectors.profile.getProfileUser(state).userEmail,
                accountId: selectors.profile.getProfileAccount(state).accountId,
            }),

        response: async (request) => {
            await Promise.resolve(
                api.account.stripeSubscriptionUpgradePartnerSubscription({
                    productPlan: request.plan,
                    subscriptionId: request.subscriptionId,
                    quantity: request.qty,
                    billingCycle: request.billingCycle,
                    email: request.email,
                    accountId: request.accountId,
                    organisationIds: request.organisationIds,
                })
            );

            const context = await api.companies.getUserContext({});
            const { entities } = actions.getUserContextEntities(context);

            return createAction(
                COMMIT_STRIPE_SUBSCRIPTION_UPGRADE_RESPONSE,
                {
                    request,
                },
                entities
            );
        },

        failure: (error) => createErrorAction(COMMIT_STRIPE_SUBSCRIPTION_UPGRADE_FAILURE, error),
    });

export const SET_COMPANY_PLAN = 'COMPANY/SET_COMPANY_PLAN';
export const SET_COMPANY_PLAN_RESPONSE = 'COMPANY/SET_COMPANY_PLAN_RESPONSE';
export const SET_COMPANY_PLAN_FAILURE = 'COMPANY/SET_COMPANY_PLAN_FAILURE';

export const setCompanyPlan = (productPlanId: domain.CompanyPlan) =>
    createAsyncAction({
        request: (state: State) => {
            const company = getActiveCompany(state);

            return createAction(SET_COMPANY_PLAN, {
                email: selectors.profile.getProfileUser(state).userEmail,
                accountId: selectors.profile.getProfileAccount(state).accountId,
                organisationId: company.id,
                productPlanId,
            });
        },

        response: async (request) => {
            let response = await Promise.resolve(api.account.setReceiptBankPlan(request));

            return createAction(SET_COMPANY_PLAN_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error) => createErrorAction(SET_COMPANY_PLAN_FAILURE, error, {}),
    });

export const SHOW_XERO_DEMO_ORGANISATION_POPUP = 'COMPANY/SHOW_XERO_DEMO_ORGANISATION_POPUP';
export const showXeroDemoOrganisationPopup = (options: { companyId: string }) =>
    createAction(SHOW_XERO_DEMO_ORGANISATION_POPUP, {
        companyId: options.companyId,
    });

export const SHOW_COLLECT_ACCOUNT_NURTURING_DATA_POPUP = 'COMPANY/SHOW_COLLECT_ACCOUNT_NURTURING_DATA_POPUP';
export const showCollectAccountNurturingDataPopup = (options: { companyId: string }) =>
    createAction(SHOW_COLLECT_ACCOUNT_NURTURING_DATA_POPUP, {
        companyId: options.companyId,
    });

export const FETCH_ACTIONS_FOR_NEW_COMPANY = 'COMPANY/FETCH_ACTIONS_FOR_NEW_COMPANY';
export const FETCH_ACTIONS_FOR_NEW_COMPANY_RESPONSE = 'COMPANY/FETCH_ACTIONS_FOR_NEW_COMPANY_RESPONSE';
export const FETCH_ACTIONS_FOR_NEW_COMPANY_FAILURE = 'COMPANY/FETCH_ACTIONS_FOR_NEW_COMPANY_FAILURE';
export const fetchActionsForNewCompany = (companyId: string) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(FETCH_ACTIONS_FOR_NEW_COMPANY, {
                companyId,
                accountId: selectors.profile.getProfileAccount(state).accountId,
            }),

        response: async (request) => {
            const response = await Promise.resolve(
                api.account.getActionsForNewOrganisation({
                    organisationId: request.companyId,
                    accountId: request.accountId,
                })
            );

            return createAction(FETCH_ACTIONS_FOR_NEW_COMPANY_RESPONSE, {
                ...response,
                request,
            });
        },

        didDispatchResponse: (request, response: domain.ActionsForNewOrganisation, state, dispatch) => {
            switch (response.requiredNextAction) {
                case domain.RequiredNextActionForNewOrganisation.ImmediatelyPutOnDextLicense:
                    dispatch(showPlanPopup({ companyId: request.companyId }));
                    break;

                case domain.RequiredNextActionForNewOrganisation.ImmediatelyAddToSubscription:
                    dispatch(showSubscriptionPopup({ companyId: request.companyId }));
                    break;

                case domain.RequiredNextActionForNewOrganisation.UpdateManagedOrganisationCount:
                    dispatch(showCollectAccountNurturingDataPopup({ companyId: request.companyId }));
                    break;

                default:
                    dispatch(closeActivePopup());
            }
        },

        failure: (error) => createErrorAction(FETCH_ACTIONS_FOR_NEW_COMPANY_FAILURE, error),
    });

export enum SelectSubscriptionOption {
    ExistingSubscription = 'ExistingSubscription',
    ExistingPlan = 'ExistingPlan',
    NewSubscription = 'NewSubscription',
    Trial = 'Trial',
}

export function commitSubscriptionData(options: {
    option: SelectSubscriptionOption;
    subscriptionId?: string | null;
    planId?: domain.CompanyPlan | null;
    billingSystem?: domain.SubscriptionBillingSystem;
    upgradeSubscriptionData?: {
        subscriptionId: string;
        currency: domain.NextBillingCurrency | null;
        plan: domain.CompanyPlan;
        billingCycle: domain.SubscriptionBillingCycle | null;
        qty: number;
        organisationIds: string[];
    } | null;
    isPartnerAccount: boolean;
}): ThunkAction {
    return async (dispatch, getState) => {
        switch (options.option) {
            case SelectSubscriptionOption.Trial:
                // Close the popup and go to workflow wizard
                dispatch(selectTrialSubscription());
                break;

            case SelectSubscriptionOption.ExistingSubscription:
                // Save with new subscription, close popup & go to workflow wizard
                if (options.upgradeSubscriptionData) {
                    if (options.isPartnerAccount) {
                        switch (options.billingSystem) {
                            case domain.SubscriptionBillingSystem.Avangate:
                                await dispatch(
                                    commitSubscriptionUpgrade({
                                        ...options.upgradeSubscriptionData,
                                    })
                                );
                                break;

                            case domain.SubscriptionBillingSystem.Stripe:
                                await dispatch(
                                    commitStripeSubscriptionUpgrade({
                                        ...options.upgradeSubscriptionData,
                                    })
                                );
                                break;
                        }
                    } else {
                        routingService.navigateToExternalUrl(
                            getAccountUrl(AccountPath.upgradeSubscription, {
                                subscriptionId: options.upgradeSubscriptionData.subscriptionId,
                                companyId: options.upgradeSubscriptionData.organisationIds[0],
                                quantity: options.upgradeSubscriptionData.qty,
                                origin: 'wa_select_existing_subscription',
                            })
                        );
                    }
                } else {
                    await dispatch(selectExistingSubscription(options.subscriptionId!));
                }

                break;

            case SelectSubscriptionOption.ExistingPlan:
                await dispatch(setCompanyPlan(options.planId!));
                break;

            case SelectSubscriptionOption.NewSubscription:
                // Lock controls & navigate to myaccount
                dispatch(selectNewSubscription());
                routingService.navigateToExternalUrl(
                    getAccountUrl(AccountPath.buyNow, {
                        companyId: getModule(getState()).companyId,
                        plan: domain.OrganisationPlanPrivilege.Premium,
                        origin: 'wa_select_new_subscription',
                    })
                );
                break;

            default:
                throw errorHelpers.assertNever(options.option);
        }
    };
}

export const CLOSE_ACTIVE_POPUP = 'COMPANY/CLOSE_ACTIVE_POPUP';
export const closeActivePopup = () => createAction(CLOSE_ACTIVE_POPUP, {});

export type Action = ExtractActions<
    | typeof closeActivePopup
    | typeof commitStripeSubscriptionUpgrade
    | typeof commitSubscriptionUpgrade
    | typeof createCompany
    | typeof fetchActionsForNewCompany
    | typeof selectExistingSubscription
    | typeof selectNewSubscription
    | typeof selectTrialSubscription
    | typeof setCompanyPlan
    | typeof showCollectAccountNurturingDataPopup
    | typeof showOrganisationPostCreationWizard
    | typeof showPlanPopup
    | typeof showSubscriptionPopup
    | typeof showXeroDemoOrganisationPopup
>;
