import './profileSection.scss';

import { PptxAttachment, WebinarIcon, XlsIcon } from 'modules/sprites';
import { useUserProfile } from 'modules/utils';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { getPartnerUrl } from 'urlBuilder';

const i18nPrefix = '@NoCompanies/no-invites/partner-account';

const bem = bemFactory.block('no-inv-block-profile-section');

const ProfileSection = () => {
    const profile = useUserProfile();

    if (!profile.flags.hasAccessToPartnerPortalPage) {
        return null;
    }

    return (
        <a href={getPartnerUrl()} target='_blank' rel='noopener noreferrer' className={bem()}>
            <div className={bem('go-text')}>
                <FormattedMessage id={`${i18nPrefix}.goText`} defaultMessage='Go to' />
            </div>

            <div className={bem('partner-portal-name')}>
                <FormattedMessage id={`${i18nPrefix}.partnerPortal`} defaultMessage='Partner Portal' />
            </div>

            <div className={bem('icons')}>
                <XlsIcon className={bem('icon')} width={60} height={60} />

                <PptxAttachment className={bem('icon')} width={60} height={60} />

                <WebinarIcon className={bem('icon')} width={60} height={60} />
            </div>
        </a>
    );
};

export default ProfileSection;
