/**
 * Check if some of the item are in the source array
 *
 * Example:
 * ```
 * includesSomeOf([1,2,3,4], [0,1]) => returns true
 * includesSomeOf([1,2,3,4], [0,5]) => returns false
 * includesSomeOf([1,2,3,4], [1,3,4]) => returns true
 * ```
 */
export const includesSomeOf = <T>(source: Array<T>, itemsToFind: Array<T>): boolean => {
    return source.some((item) => itemsToFind.includes(item));
};
