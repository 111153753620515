import { BudgetImpact } from './Budgets';
import { BudgetImpactBackend } from './BudgetsBackend';

export const mapBudgetImpact = (budgetImpact: BudgetImpactBackend): BudgetImpact => {
    return {
        ...budgetImpact,
        account: {
            id: budgetImpact.account.Id,
            text: budgetImpact.account.Name,
        },
    };
};
