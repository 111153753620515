import { QueryOptions } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetRequestLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import {
    UseGetNetSuiteCountriesParams,
    UseGetNetSuiteCountriesResponse,
    UseGetNetSuiteCountriesResponseBackend,
} from './useGetNetSuiteCountries.types';

export const useGetNetSuiteCountries = (
    params: UseGetNetSuiteCountriesParams,
    queryOptions: QueryOptions<UseGetNetSuiteCountriesResponseBackend, UseGetNetSuiteCountriesResponse>
) => {
    const { enabled = true } = queryOptions;

    return useGetRequestLegacy<UseGetNetSuiteCountriesResponseBackend, UseGetNetSuiteCountriesResponse>(
        integrationsApiPaths.getNetSuiteCountries,
        {},
        {
            queryOptions: {
                ...queryOptions,
                enabled: !!params.companyId && enabled,
            },
            params,
            mapData: objectHelpers.pascalCaseToCamelCase,
            method: 'get',
        }
    );
};
