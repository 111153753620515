import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const EditBtnText = styled.div`
    ${font(13, '#4c8a5c', 'semibold')}
    cursor: pointer;
`;

export const DeleteBtnText = styled.div`
    ${font(13, '#a3341a', 'semibold')}
    cursor: pointer;
`;
