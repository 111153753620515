import { Mods, mods, theme } from '@approvalmax/theme';
import { Button } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const ChangeButton = styled(Button)<StyledMods<Mods<'active'>>>`
    opacity: 0;
    transition: opacity ${theme.duration.medium}ms linear;

    ${mods.active.true`
        opacity: 1;
    `}
`;

export const Root = styled.div`
    &:hover {
        ${ChangeButton} {
            opacity: 1;
        }
    }
`;
