import * as Sentry from '@sentry/browser';
import { ComponentClass, FC, useCallback } from 'react';
import { ComponentErrorBoundary } from 'shared/components';

import { CellComponentProps } from '../../types';

const capturedColumns = new Set<string>();

export const withCellErrorBoundary =
    (Component: ComponentClass<CellComponentProps> | FC<CellComponentProps>, columnId: string) =>
    (props: CellComponentProps) => {
        const { rowId, value } = props;

        const handleError = useCallback(
            (error: Error) => {
                if (capturedColumns.has(columnId)) {
                    return;
                }

                capturedColumns.add(columnId);

                Sentry.captureException(error, (scope) => {
                    scope.setContext('withCellErrorBoundary', {
                        columnId,
                        rowId,
                        value: JSON.stringify(value),
                    });

                    return scope;
                });
            },
            [rowId, value]
        );

        return (
            <ComponentErrorBoundary onError={handleError}>
                <Component {...props} />
            </ComponentErrorBoundary>
        );
    };
