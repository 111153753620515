import { useSelector } from 'modules/react-redux';

import * as selectors from '../selectors';

/**
 * Returns the company selected in the navigation store in Redux.
 * Throws an error if there is no company selected (e.g. we are in a global page like "Decision Required").
 */
export function useActiveCompany() {
    return useSelector(selectors.navigation.getActiveCompany);
}
