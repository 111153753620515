import { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortableElement as sortableElement } from 'react-sortable-hoc';

import { finalizeAddStep, removeStep, renameStep } from '../../actions';
import { addingNewStep, isPageReadonly } from '../../selectors/pageSelectors';
import ApprovalStepContent from '../ApprovalStepContent/ApprovalStepContent';
import DynamicStepContainer from '../DynamicStepContainer/DynamicStepContainer';
import { ApprovalStepProps } from './ApprovalStep.types';

const ApprovalStep: FC<ApprovalStepProps> = memo((props) => {
    const { stepIndex, step } = props;

    const dispatch = useDispatch();

    const readonly = useSelector(isPageReadonly);
    const renameOnMount = useSelector(addingNewStep);

    const onRename = useCallback(
        (newName: string) => {
            dispatch(renameStep(stepIndex, newName));
        },
        [dispatch, stepIndex]
    );

    const onRemove = useCallback(() => {
        dispatch(removeStep(stepIndex));
    }, [dispatch, stepIndex]);

    const onMounted = useCallback(() => {
        dispatch(finalizeAddStep());
    }, [dispatch]);

    return (
        <DynamicStepContainer
            readonly={readonly}
            onRename={onRename}
            onRemove={onRemove}
            onMounted={onMounted}
            stepName={step.name}
            renameOnMount={renameOnMount}
            color='green80'
            removable
        >
            <ApprovalStepContent step={step} stepIndex={stepIndex} />
        </DynamicStepContainer>
    );
});

export default sortableElement<ApprovalStepProps>(ApprovalStep);
