export enum MatrixType {
    Approval = 'Approval',
    Editor = 'Editor',
    Requester = 'Requester',
    AutoApproval = 'AutoApproval',
    Reviewer = 'Reviewer',
    Editing = 'Editing',
}

export enum AccessType {
    Readonly = 'Readonly',
    Mandatory = 'Mandatory',
    Optional = 'Optional',
}
