import { PropsWithChildren, useMemo } from 'react';

import { Box } from '../../../Box/Box';
import { Text } from '../../../Text/Text';
import { useStepperContext } from '../../Stepper.context';
import { useStepContext } from '../Step/Step.context';

export const Label = (props: PropsWithChildren) => {
    const { children } = props;
    const { direction } = useStepperContext();
    const { completed, disabled, first, last } = useStepContext();

    const color = completed ? 'midnight100' : disabled ? 'midnight60' : 'midnight80';

    const textAlign = useMemo(() => {
        if (direction === 'horizontal') {
            if (!first && !last) {
                return 'center';
            }

            if (last) {
                return 'right';
            }
        }

        return undefined;
    }, [direction, first, last]);

    return (
        <Box spacing={direction === 'vertical' ? '2 0' : undefined}>
            {typeof children === 'string' ? (
                <Text
                    font='body'
                    fontSize='medium'
                    textAlign={textAlign}
                    color={color}
                    ellipsis={direction === 'horizontal' ? 1 : undefined}
                >
                    {children}
                </Text>
            ) : (
                children
            )}
        </Box>
    );
};

Label.dispalyName = 'Step.Label';
