import { combineReducers } from 'redux';

import searchContext, { SearchContextType } from './module/searchContextReducer';

export interface Module {
    searchContext: SearchContextType;
}

export default combineReducers({
    searchContext,
});
