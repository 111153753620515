import { immutable, ImmutableObject, merge, set } from 'modules/immutable';

import {
    Action,
    CANCEL_ACTIVE_POPUP,
    CREATE_NEW_REPORT_RESPONSE,
    SHOW_NEW_REPORT_POPUP,
    UPDATE_DYNAMIC_REPORT_COLUMNS,
    UPDATE_FILTERS_POPUP_REPORT_CONFIG,
} from '../../actions';
import { ReportConfig, updateDynamicColumns } from '../../data/reportConfig';

export const NEW_REPORT_POPUP_ID = 'REPORTS/NEW_REPORT';

export interface NewReportPopupData {
    id: typeof NEW_REPORT_POPUP_ID;
    reportConfig: ReportConfig;
}

export type ReportFiltersPopupStateType = ImmutableObject<NewReportPopupData> | null;

export default function (state: ReportFiltersPopupStateType, action: Action): ReportFiltersPopupStateType {
    switch (action.type) {
        case CREATE_NEW_REPORT_RESPONSE:
        case CANCEL_ACTIVE_POPUP:
            return null;

        case SHOW_NEW_REPORT_POPUP:
            return immutable<NewReportPopupData>({
                id: NEW_REPORT_POPUP_ID,
                reportConfig: action.payload.reportConfig,
            });

        case UPDATE_DYNAMIC_REPORT_COLUMNS: {
            if (!state) {
                return state;
            }

            const newReportConfig = updateDynamicColumns(state.reportConfig, {
                standaloneFields: action.standaloneFields,
                qBooksCustomFields: action.qBooksCustomFields,
                xeroTrackingFields: action.xeroTrackingFields,
            });

            // commit changes
            if (state.reportConfig !== newReportConfig) {
                state = set(state, 'reportConfig', newReportConfig);
                state = set(state, 'modified', true);

                return state;
            }

            return state;
        }

        case UPDATE_FILTERS_POPUP_REPORT_CONFIG:
            if (!state) {
                return state;
            }

            return merge(state, {
                reportConfig: action.payload.reportConfig,
            });

        default:
            return state;
    }
}
