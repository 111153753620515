import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SAVE_AND_RESUBMIT_REQUEST_RESPONSE, SHOW_OPTIMISTIC_LOCKING_POPUP } from '../../../actions';

export const OPTIMISTIC_LOCKING_POPUP = 'OPTIMISTIC_LOCKING_POPUP';

export interface OptimisticLockingPopupData {
    id: typeof OPTIMISTIC_LOCKING_POPUP;
}

export type OptimisticLockingPopupType = ImmutableObject<OptimisticLockingPopupData> | null;

export default (state: OptimisticLockingPopupType, action: Action): OptimisticLockingPopupType => {
    switch (action.type) {
        case SHOW_OPTIMISTIC_LOCKING_POPUP:
            return immutable<OptimisticLockingPopupData>({
                id: OPTIMISTIC_LOCKING_POPUP,
            });

        case SAVE_AND_RESUBMIT_REQUEST_RESPONSE:
            return null;

        default:
            return state;
    }
};
