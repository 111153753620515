import { AlertCircleFilledIcon } from '@approvalmax/ui';
import { Box, Button, Flex, NumberField, Select, Spacing, Text } from '@approvalmax/ui/src/components';
import { browserHelpers, intl, numberHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';

import { conditionItems } from './AmountConditionCell.constants';
import { messages } from './AmountConditionCell.messages';
import { AmountConditionCellProps } from './AmountConditionCell.types';

const qa = bemFactory.qa('wfc-amt-condition-cell');

const AmountConditionCell: FC<AmountConditionCellProps> = memo((props) => {
    const {
        lineId,
        rule,
        field,
        condition: { numericRangeConditionType, numericRangeLess, numericRangeGreaterEquals },
        condition,
        readonly,
        companyDefaultCurrency,
        minValue,
        maxValue,
        showCurrency = true,
        onConditionChange,
    } = props;

    const [inEdit, setInEdit] = useState(numericRangeConditionType === domain.NumericRangeConditionType.Any);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const firstInputRef = useRef<HTMLInputElement | null>(null);

    const endEdit = useCallback(() => setInEdit(false), []);

    const onChangeRangeType = useCallback(
        (value: domain.NumericRangeConditionType) => {
            const newCondition = {
                ...condition,
                numericRangeConditionType: value,
            };

            onConditionChange(lineId, rule, field, newCondition);

            if (value !== domain.NumericRangeConditionType.Any) {
                setTimeout(() => {
                    firstInputRef.current?.focus();
                }, 0);
            }
        },
        [condition, field, lineId, onConditionChange, rule]
    );

    const onChangeInput1 = useCallback(
        (value: number | null) => {
            const newValue = value && numberHelpers.round(value, 2);

            const newCondition = { ...condition };

            if (numericRangeConditionType === domain.NumericRangeConditionType.Below) {
                newCondition.numericRangeLess = newValue;
                newCondition.numericRangeGreaterEquals = null;
            } else {
                newCondition.numericRangeGreaterEquals = newValue;
            }

            onConditionChange(lineId, rule, field, newCondition);
        },
        [condition, field, lineId, numericRangeConditionType, onConditionChange, rule]
    );

    const onChangeInput2 = useCallback(
        (value: number | null) => {
            const newValue = value && numberHelpers.round(value, 2);

            onConditionChange(lineId, rule, field, {
                ...condition,
                numericRangeLess: newValue,
            });
        },
        [condition, field, lineId, onConditionChange, rule]
    );

    const getFormattedValue = useCallback(
        (value: number) => {
            return showCurrency ? intl.formatCurrency(value, companyDefaultCurrency) : intl.formatNumber(value);
        },
        [companyDefaultCurrency, showCurrency]
    );

    const previewText = useMemo(() => {
        const lv = numberHelpers.isNumber(condition.numericRangeLess)
            ? getFormattedValue(condition.numericRangeLess)
            : messages.notSet;
        const gv = numberHelpers.isNumber(condition.numericRangeGreaterEquals)
            ? getFormattedValue(condition.numericRangeGreaterEquals)
            : messages.notSet;

        const textMap = {
            [domain.NumericRangeConditionType.Above]: messages.overOrEqualToGv({
                gv,
            }),
            [domain.NumericRangeConditionType.Any]: messages.anyAmount,
            [domain.NumericRangeConditionType.Below]: messages.underLv({
                lv,
            }),
            [domain.NumericRangeConditionType.Within]: messages.betweenGvLv({
                lv,
                gv,
            }),
        };

        return textMap[numericRangeConditionType];
    }, [condition.numericRangeGreaterEquals, condition.numericRangeLess, getFormattedValue, numericRangeConditionType]);

    const handleBlur = useCallback(() => {
        const delay = browserHelpers.isSafari() ? 150 : 1;

        setTimeout(() => {
            if (containerRef.current && !containerRef.current.contains(document.activeElement)) {
                setInEdit(numericRangeConditionType === domain.NumericRangeConditionType.Any);
            }
        }, delay);
    }, [numericRangeConditionType]);

    const onClick = useCallback(() => {
        setInEdit(true);

        if (numericRangeConditionType !== domain.NumericRangeConditionType.Any) {
            setTimeout(() => {
                firstInputRef.current?.focus();
            }, 0);
        }
    }, [numericRangeConditionType]);

    const onOpen = useCallback(
        (open: boolean) => {
            if (!open) {
                const delay = browserHelpers.isSafari() ? 150 : 1;

                setTimeout(() => {
                    if (containerRef.current && !containerRef.current.contains(document.activeElement)) {
                        setInEdit(numericRangeConditionType === domain.NumericRangeConditionType.Any);
                    }
                }, delay);
            }
        },
        [numericRangeConditionType]
    );

    const invalid = !selectors.matrix.isValidCondition(condition);
    const value1 =
        numericRangeConditionType === domain.NumericRangeConditionType.Below
            ? numericRangeLess
            : numericRangeGreaterEquals;
    const value2 = numericRangeLess;

    const showInput1 = numericRangeConditionType !== domain.NumericRangeConditionType.Any;
    const showInput2 = numericRangeConditionType === domain.NumericRangeConditionType.Within;

    return (
        <Box width='172px' ref={containerRef} data-qa={qa()} data-qa-id={field.id} data-qa-name={field.name}>
            {inEdit ? (
                <>
                    <Select
                        items={conditionItems}
                        value={numericRangeConditionType}
                        onChange={onChangeRangeType}
                        textActivatorColor='midnight70'
                        textActivatorHoverColor='blue90'
                        openIconOnHover
                        size='small'
                        noInput
                        dropdownWidth='max-content'
                        onOpen={onOpen}
                        disabled={readonly}
                        data-qa={qa('condition-type-dropdown')}
                    />

                    <Spacing height={4} />

                    {showInput1 && (
                        <NumberField
                            onChange={onChangeInput1}
                            value={value1}
                            size='small'
                            clearable={false}
                            min={minValue}
                            max={maxValue}
                            allowFloat
                            invalid={invalid}
                            placeholder={messages.enterValue}
                            ref={firstInputRef}
                            onBlur={handleBlur}
                            onEnter={endEdit}
                            disabled={readonly}
                            data-qa={qa('value1')}
                        />
                    )}

                    <Spacing height={4} />

                    {showInput2 && (
                        <NumberField
                            onChange={onChangeInput2}
                            value={value2}
                            size='small'
                            clearable={false}
                            min={minValue}
                            max={maxValue}
                            allowFloat
                            invalid={invalid}
                            placeholder={messages.enterValue}
                            onBlur={handleBlur}
                            onEnter={endEdit}
                            disabled={readonly}
                            data-qa={qa('value2')}
                        />
                    )}
                </>
            ) : (
                <Button
                    size='small'
                    noPadding
                    onClick={onClick}
                    uppercase={false}
                    fontWeight='regular'
                    disabled={readonly}
                    data-qa={qa('preview')}
                >
                    <Text
                        font='label'
                        fontSize='small'
                        color={invalid ? 'red100' : 'midnight70'}
                        hoverColor={invalid ? 'red100' : 'blue90'}
                    >
                        <Flex inline spacing='4' wrap={false}>
                            {previewText}

                            {invalid && <AlertCircleFilledIcon size={12} color='red100' data-qa='wfc-error-icon' />}
                        </Flex>
                    </Text>
                </Button>
            )}
        </Box>
    );
});

export default AmountConditionCell;
