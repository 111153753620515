import { selectors } from 'modules/common';
import { domain } from 'modules/data';

export const getAdjustedIntegrationState = (
    explainedState: selectors.types.IntegrationState,
    workflows: domain.Template[]
): selectors.types.IntegrationState => {
    // Integration card should treat an empty workflows list as a sign that loading is still in progress
    // It means that the template list is being reloaded after successful integration
    if (explainedState.isConnectedFirstTime && (!workflows || workflows.length === 0)) {
        explainedState = {
            ...explainedState,
            isConnecting: false,
            isConnectingFinalizing: true,
            isConnected: false,
            isConnectedFirstTime: false,
        };
    }

    return explainedState;
};
