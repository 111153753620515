import { createPageReducer } from 'modules/react-redux';

import { Action, LOAD_PAGE_DATA } from '../actions';
import activeFilter, { ActiveFilterType } from './page/activeFilterReducer';
import activePopup, { ActivePopupType } from './page/activePopupReducer';
import activeRequestId, { ActiveRequestIdType } from './page/activeRequestIdReducer';
import activeTemplateId, { ActiveTemplateIdType } from './page/activeTemplateIdReducer';
import lockedRequests, { LockedRequestsType } from './page/lockedRequestsReducer';
import pagination, { PaginationType } from './page/paginationReducer';
import requestSearch, { RequestSearchType } from './page/requestSearchReducer';
import requests, { RequestsType } from './page/requestsReducer';

export interface Page {
    activeFilter: ActiveFilterType;
    activeRequestId: ActiveRequestIdType;
    activeTemplateId: ActiveTemplateIdType;
    requests: RequestsType;
    lockedRequests: LockedRequestsType;
    pagination: PaginationType;
    activePopup: ActivePopupType;
    requestSearch: RequestSearchType;
}

export default createPageReducer<Page, Action>(
    {
        activeFilter,
        activeRequestId,
        activeTemplateId,
        pagination,
        requests,
        lockedRequests,
        activePopup,
        requestSearch,
    },
    LOAD_PAGE_DATA
);
