import './connectToPanel.scss';

import { Button, ExternalLink, LoadingBar, QBooksLogo, TransparentButton } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { integrationActions } from 'modules/integration';
import { useSelector } from 'modules/react-redux';
import { QboConnectIcon, XeroIcon } from 'modules/sprites';
import { FC, memo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getLoading } from '../../../../selectors/moduleSelectors';

const i18nPrefix = 'first-start/containers/ConnectToPanel';

const bem = bemFactory.block('first-start-connect-to-panel');

interface ConnectToPanelProps {
    className?: string;
}

const ConnectToPanel: FC<ConnectToPanelProps> = (props) => {
    const { className } = props;

    const dispatch = useDispatch();

    const isLoading = useSelector(getLoading);
    const [activeIntegrationType, setActiveIntegrationType] = useState<domain.IntegrationType | null>(null);

    function onConnect(integrationType: domain.IntegrationType) {
        setActiveIntegrationType(integrationType);
        dispatch(
            integrationActions.connectToIntegration({
                integrationType,
                companyId: null,
                finalRedirectToPage: 'workflow',
                showGlobalProgress: false,
            })
        );
    }

    const config = {
        [domain.IntegrationType.Xero]: {
            IntegratedSystemIcon: XeroIcon,
            connectButton: () => (
                <Button
                    title={intl.formatMessage({
                        id: `${i18nPrefix}.xeroConnectButtonTitle`,
                        defaultMessage: 'Connect to you Xero account',
                    })}
                    colorTheme='aqua'
                    preset='compact'
                    disabled={isLoading}
                    execute={() => onConnect(domain.IntegrationType.Xero)}
                >
                    <FormattedMessage id={`${i18nPrefix}.connectButtonText`} defaultMessage='Connect' />
                </Button>
            ),
            loadingBarTheme: 'aqua' as const,
            title: <FormattedMessage id={`${i18nPrefix}.xeroTitle`} defaultMessage='Xero' values={{ br: <br /> }} />,
            description: (
                <FormattedMessage
                    id={`${i18nPrefix}.xeroDescription`}
                    defaultMessage={
                        'Connect to your Xero account in order to setup multi-step approval workflows for your ' +
                        'Xero documents such as Purchase Orders, Bill, AP Credit Notes, Sales Invoices, AR Credit Notes'
                    }
                />
            ),
            learnMoreUrl: 'https://support.approvalmax.com/portal/kb/approvalmax-1/approvalmax-for-xero',
        },
        [domain.IntegrationType.QBooks]: {
            IntegratedSystemIcon: QBooksLogo,
            connectButton: () => (
                <TransparentButton
                    title={intl.formatMessage({
                        id: `${i18nPrefix}.qbConnectButtonTitle`,
                        defaultMessage: 'Connect to your QuickBooks Online account',
                    })}
                    disabled={isLoading}
                    execute={() => onConnect(domain.IntegrationType.QBooks)}
                >
                    {({ elementProps }) => (
                        <button className={bem('qbo-connect-button')} {...elementProps}>
                            <QboConnectIcon width={88} height={24} />
                        </button>
                    )}
                </TransparentButton>
            ),
            loadingBarTheme: 'brand' as const,
            title: (
                <FormattedMessage
                    id={`${i18nPrefix}.qbTitle`}
                    defaultMessage='QuickBooks Online'
                    values={{ br: <br /> }}
                />
            ),
            description: (
                <FormattedMessage
                    id={`${i18nPrefix}.qbDescription`}
                    defaultMessage={
                        'Connect to your QuickBooks Online account in order to setup multi-step ' +
                        'approval workflows for QuickBooks Online Purchase Order'
                    }
                />
            ),
            learnMoreUrl: 'https://support.approvalmax.com/portal/kb/approvalmax-1/approvalmax-for-quickbooks-online',
        },
    };

    function renderTile(integrationType: keyof typeof config) {
        const data = config[integrationType];

        return (
            <li className={bem('tile')}>
                <LoadingBar
                    className={bem('tile-loading-bar', {
                        visible: isLoading && activeIntegrationType === integrationType,
                    })}
                    theme={data.loadingBarTheme}
                />

                <div className={bem('tile-content')}>
                    <div className={bem('tile-title')}>
                        <data.IntegratedSystemIcon className={bem('tile-image')} width={40} height={40} />

                        <div className={bem('tile-title-text')}>{data.title}</div>

                        <div>{data.connectButton()}</div>
                    </div>

                    <div className={bem('tile-description')}>{data.description}</div>

                    <ExternalLink href={data.learnMoreUrl}>
                        <FormattedMessage id={`${i18nPrefix}.learnMoreText`} defaultMessage='Learn more' />
                    </ExternalLink>
                </div>
            </li>
        );
    }

    return (
        <section className={bem.add(className)()}>
            <h2>
                <FormattedMessage
                    id={`${i18nPrefix}.sectionHeader`}
                    defaultMessage='Connect your accounting software:'
                />
            </h2>

            <ul className={bem('list')}>
                {renderTile(domain.IntegrationType.Xero)}

                {renderTile(domain.IntegrationType.QBooks)}
            </ul>
        </section>
    );
};

export default memo(ConnectToPanel);
