const chars = /&lt;|&gt;|&amp;/g;

function returnChar(chr: string) {
    chr = chr.replace(/&amp;/g, '&');
    chr = chr.replace(/&lt;/g, '<');
    chr = chr.replace(/&gt;/g, '>');

    return chr;
}

export const returnExpressions = (text: string) => {
    return text.replace(chars, returnChar);
};
