import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.twoFa.TwoFaDisablingPopup.EmailCodePopup', {
    description: 'Enter the verification code that was sent to your alternative email address.',
    codeFieldTitle: 'Enter the verification code',
    wrongCodeErrorText: 'Wrong code',
    emailCodePlaceholder: 'Enter the verification code...',
    confirmButton: 'Confirm',
    anotherMethodButton: 'Use another authentication method',
    trustThisDevice: 'Trust this device',
});
