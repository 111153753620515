import './orSeparator.scss';

import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './OrSeparator.messages';

const bem = bemFactory.block('ui-or-separator');

interface OrSeparatorProps {
    className?: string;
}

const OrSeparator: FC<OrSeparatorProps> = (props) => {
    const { className } = props;

    return (
        <div className={bem.add(className)()}>
            <div className={bem('or-separator-line')} />

            <div className={bem('or-separator-text')}>{messages.orSeparator}</div>

            <div className={bem('or-separator-line')} />
        </div>
    );
};

export default memo(OrSeparator);
