import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.UserActionsToolbar.PracticeStaffInvitations', {
    title: 'Business Account',
    pluralInvitationDescription:
        'You have {count, number} {count, plural, one {invitation} other {invitations}} to Business Account.',
    singleInvitationDescriptionWithName:
        'You were invited to the Business Account of the {companyName} by {displayName} ({email})',
    singleInvitationDescriptionWithoutName: 'You were invited to the Business Account by {displayName} ({email})',
    chooseAndAcceptButton: 'Choose and accept',
    acceptButton: 'Accept',
    declineButton: 'Decline',
});
