import { ExternalLink, TextEditor } from '@approvalmax/ui';
import { constants } from 'modules/common';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

import { useTwoFaEnablingWizardContext } from '../../TwoFaEnablingWizard.context';
import ProgressFlow2fa from '../ProgressFlow2fa/ProgressFlow2fa';
import { useStepCodeEnter } from './StepCodeEnter.hooks';
import { messages } from './StepCodeEnter.messages';
import { ButtonNext, CodeField, Content, Description, Root, Title } from './StepCodeEnter.styles';

const qa = bemFactory.qa('pro-two-fa-setup-code-enter');

const StepCodeEnter: FC = memo(() => {
    const { setActiveStep } = useTwoFaEnablingWizardContext();

    const onNext = useCallback(() => {
        setActiveStep('backupMethods');
    }, [setActiveStep]);

    const { authenticatorAppCode, onChange, onSubmit, inputRef, isLoading, isError, canSubmit } =
        useStepCodeEnter(onNext);

    return (
        <Root qa={qa()} title={messages.popupTitle}>
            <Content>
                <ProgressFlow2fa activeStepIndex={1} />

                <Title>{messages.title}</Title>

                <Description>
                    {messages.description({
                        br: <br />,
                        supportlink: (chunks) => (
                            <ExternalLink href={constants.xeroConstants.XERO_TWO_FA_SUPPORT_LINK}>
                                {chunks}
                            </ExternalLink>
                        ),
                    })}
                </Description>

                <CodeField title={messages.codeFieldTitle} errorText={isError && messages.wrongCodeErrorText}>
                    <TextEditor
                        focusOnMount
                        disabled={isLoading}
                        value={authenticatorAppCode}
                        onChange={onChange}
                        placeholder={messages.userCodePlaceholder}
                        invalid={isError}
                        ref={inputRef}
                        onEnter={onSubmit}
                    />
                </CodeField>

                <ButtonNext execute={onSubmit} disabled={!canSubmit}>
                    {messages.nextButton}
                </ButtonNext>
            </Content>
        </Root>
    );
});

export default StepCodeEnter;
