import { createPopupReducer } from 'modules/react-redux';

import { CANCEL_ACTIVE_POPUP } from '../../actions';
import optimisticLockingPopupReducer from './activePopup/optimisticLockingPopupReducer';
import { qbooksCustomerCreationPopupReducer } from './activePopup/qBooksCustomerCreationPopupReducer';
import qbooksContactCreationPopupReducer from './activePopup/qBooksVendorCreationPopupReducer';
import resubmitRequestPopupReducer from './activePopup/resubmitRequestPopupReducer';
import saveAndApprovePopupReducer from './activePopup/saveAndApprovePopupReducer';
import xeroContactAddressSearchPopupReducer from './activePopup/xeroContactAddressSearchPopupReducer';
import xeroContactCreationPopupReducer from './activePopup/xeroContactCreationPopupReducer';

export default createPopupReducer(
    CANCEL_ACTIVE_POPUP,
    xeroContactAddressSearchPopupReducer,
    resubmitRequestPopupReducer,
    saveAndApprovePopupReducer,
    xeroContactCreationPopupReducer,
    qbooksContactCreationPopupReducer,
    qbooksCustomerCreationPopupReducer,
    optimisticLockingPopupReducer
);
