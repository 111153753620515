import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ItemProps as MenuItemProps, openMenuSubItemsDuration } from '../../../Menu/components';
import { openingDuration } from '../../Drawer.constants';
import { collapseDrawerState, openDrawerState } from '../../Drawer.states';
import { openSectionsStorageKey } from './Navigation.constants';
import { NavigationProps, SubItem } from './Navigation.types';

export const useLink = (props: Pick<NavigationProps, 'onClick' | 'getActiveStatus'>) => {
    const { onClick, getActiveStatus } = props;

    const checkIfSubItemActive = (subItems?: SubItem[]) => subItems?.some(({ to }) => getActiveStatus(to));

    const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(openDrawerState);
    const closeDrawer = useCallback(() => setIsDrawerOpen(false), [setIsDrawerOpen]);

    const routerPush = useCallback(
        (to?: string, onClickItem?: NavigationProps['items'][number]['onClick']) =>
            (event: MouseEvent<HTMLButtonElement>) => {
                event.preventDefault();

                if (!to) return;

                if (isDrawerOpen) {
                    closeDrawer();

                    /**
                     * Waiting for the Drawer and Menu subItems to open
                     */
                    setTimeout(() => {
                        onClick(to, event);
                        onClickItem?.(event);
                    }, openingDuration + openMenuSubItemsDuration);
                } else {
                    onClick(to, event);
                    onClickItem?.(event);
                }
            },
        [closeDrawer, isDrawerOpen, onClick]
    );

    return {
        routerPush,
        checkIfSubItemActive,
    };
};

export const useSaveOpenedSections = () => {
    const collapsedDrawer = useRecoilValue(collapseDrawerState);
    const [openedSections, setOpenedSections] = useState<Array<string | number>>([]);

    const checkIsOpenSection = useCallback((id: string) => openedSections.includes(id), [openedSections]);

    const toggleOpenSection = useCallback<Required<MenuItemProps>['onOpen']>(
        (status, id) => {
            if (!id) return;

            if (status) {
                const newState = [...openedSections, id];

                localStorage.setItem(openSectionsStorageKey, JSON.stringify(newState));
                !collapsedDrawer && setOpenedSections(newState);
            } else {
                const newState = openedSections.filter((sectionId: typeof id) => sectionId !== id);

                localStorage.setItem(openSectionsStorageKey, JSON.stringify(newState));
                !collapsedDrawer && setOpenedSections(newState);
            }
        },
        [collapsedDrawer, openedSections]
    );

    useEffect(() => {
        if (collapsedDrawer) {
            setOpenedSections([]);
        } else {
            setOpenedSections(JSON.parse(localStorage.getItem(openSectionsStorageKey) || '[]'));
        }
    }, [collapsedDrawer]);

    return {
        checkIsOpenSection,
        toggleOpenSection,
    };
};
