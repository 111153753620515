import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { useEffect } from 'react';
import { defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { notificationService } from 'services/notification';
import { routingService } from 'services/routing';
import { getDefaultPath } from 'urlBuilder';

const i18nPrefix = 'page.hooks.useProtectedRole';
const messages = defineMessages({
    accessDeniedToastMessage: {
        id: `${i18nPrefix}.accessDeniedToastMessage`,
        defaultMessage: 'Access denied',
    },
});

export const useProtectedRole = (allowedRoles?: domain.CompanyUserRole[]) => {
    const { companyId } = useParams<{ companyId: string }>();

    const company = useSelector((state: State) => selectors.company.findCompanyById(state, companyId));

    const isRoleAllowed = allowedRoles && company ? allowedRoles.includes(company.myRole) : true;

    useEffect(() => {
        if (!isRoleAllowed) {
            notificationService.showErrorToast(intl.formatMessage(messages.accessDeniedToastMessage));
            routingService.push(getDefaultPath());
        }
    }, [isRoleAllowed]);

    return isRoleAllowed;
};
