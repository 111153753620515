import { selectors } from 'modules/common';
import { domain } from 'modules/data';

export const useIsBudgetingAvailable = (company: selectors.types.ExpandedCompany) => {
    const isBudgetingAvailable =
        (company.integration?.integrationType === domain.IntegrationType.Xero &&
            company.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroBudgets)) ||
        (company.integration?.integrationType === domain.IntegrationType.QBooks &&
            company.betaFeatures.includes(domain.CompanyBetaFeature.QBooksBudgeting));

    return isBudgetingAvailable;
};
