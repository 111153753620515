import { domain } from 'modules/data';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, CLOSE_ACTIVE_HAPPY_ACTIVATION_POPUP, SAVE_TEMPLATE_RESPONSE } from '../../actions';

export interface HappyActivationData {
    template: domain.Template;
}

export type ActiveHappyActivation = ImmutableObject<HappyActivationData> | null;

export default function activeInviteUsersReducer(
    state: ActiveHappyActivation = null,
    action: Action
): ActiveHappyActivation {
    switch (action.type) {
        case SAVE_TEMPLATE_RESPONSE:
            if (action.payload.request.justActivated) {
                return immutable<HappyActivationData>({
                    template: action.payload.request.template,
                });
            } else {
                return state;
            }

        case CLOSE_ACTIVE_HAPPY_ACTIVATION_POPUP:
            return null;

        default:
            return state;
    }
}
