import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { Text } from '../../../Text/Text';
import { StepperProps } from '../../Stepper.types';

export const Circle = styled.circle<StyledMods<Mods<'color'>>>`
    ${mods.color(
        (value) => css`
            stroke: ${theme.color[value]};
        `
    )};
`;

export const SVGText = styled((props) => <Text as='text' {...props} />)<StyledMods<Mods<'color'>>>`
    ${mods.color(
        (value: NonNullable<StepperProps['color']>) => css`
            fill: ${theme.color[value]};
        `
    )};
`;
