import { errorHelpers, intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { ImmutableObject } from 'modules/immutable';
import { defineMessages } from 'react-intl';

import { PAGE_ID } from '../config';
import { ActiveModule, PageActiveModule } from '../reducers/page/moduleReducer';
import { Page } from '../reducers/pageReducer';

const i18nPrefix = 'auth';
const messages = defineMessages({
    receiptBankAuthPageTitle: {
        id: `${i18nPrefix}.receiptBankAuthPageTitle`,
        defaultMessage: 'Authentication',
    },
    loginPageTitle: {
        id: `${i18nPrefix}.pageTitle`,
        defaultMessage: 'Login',
    },
    passwordResetPageTitle: {
        id: `${i18nPrefix}.passwordResetPageTitle`,
        defaultMessage: 'Login',
    },
    signupPageTitle: {
        id: `${i18nPrefix}.signupPageTitle`,
        defaultMessage: 'Sign up',
    },
    emailConfirmationPageTitle: {
        id: `${i18nPrefix}.emailConfirmationPageTitle`,
        defaultMessage: 'Email Confirmation',
    },
    profileSetupModuleTitle: {
        id: `${i18nPrefix}.profileSetupModuleTitle`,
        defaultMessage: 'Profile Setup',
    },
    verifyEmailPageTitle: {
        id: `${i18nPrefix}.verifyEmailPageTitle`,
        defaultMessage: 'Login',
    },
    slackAuthPageTitle: {
        id: `${i18nPrefix}.slackAuthPageTitle`,
        defaultMessage: 'Authentication',
    },
    firstTimeSetupPageTitle: {
        id: `${i18nPrefix}.firstTimeSetupPageTitle`,
        defaultMessage: 'First Setup',
    },
});

export function getPage(state: State): Page {
    return selectors.page.getPage(state, PAGE_ID);
}

export function checkLoaded(state: State, ...moduleTypes: PageActiveModule[]): boolean {
    const isActive = selectors.navigation.getActivePageId(state) === PAGE_ID;

    if (!isActive) {
        return false;
    }

    return moduleTypes.includes(getModule(state).type);
}

export function getDocumentTitle(state: State) {
    const type = getModule(state).type;

    switch (type) {
        case PageActiveModule.ReceiptBankAuthModule:
            return intl.formatMessage(messages.receiptBankAuthPageTitle);

        case PageActiveModule.LoginModule:
        case PageActiveModule.TwoFaLoginModuleData:
            return intl.formatMessage(messages.loginPageTitle);

        case PageActiveModule.PasswordResetModule:
            return intl.formatMessage(messages.passwordResetPageTitle);

        case PageActiveModule.PasswordResetFinalStepModule:
            return intl.formatMessage({
                id: `${i18nPrefix}.passwordResetFinalStepTitle`,
                defaultMessage: 'Password reset',
            });

        case PageActiveModule.SignupModule:
            return intl.formatMessage(messages.signupPageTitle);

        case PageActiveModule.EmailConfirmationModule:
            return intl.formatMessage(messages.emailConfirmationPageTitle);

        case PageActiveModule.ProfileSetupModule:
            return intl.formatMessage(messages.profileSetupModuleTitle);

        case PageActiveModule.VerifyEmailModule:
            return intl.formatMessage(messages.verifyEmailPageTitle);

        case PageActiveModule.SlackAuthModule:
            return intl.formatMessage(messages.slackAuthPageTitle);

        case PageActiveModule.FirstTimeSetupModule:
            return intl.formatMessage(messages.firstTimeSetupPageTitle);

        case PageActiveModule.MobileInfoModule:
        case PageActiveModule.None:
            return null;

        default:
            throw errorHelpers.assertNever(type);
    }
}

export function getModule<T extends ActiveModule = ActiveModule>(state: State): ImmutableObject<T> {
    return getPage(state).module as ImmutableObject<T>;
}

export function getLoading(state: State): boolean {
    return getPage(state).loading;
}
