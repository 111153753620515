export const PAGE_ID = 'legacy_reports';

export const REPORT_PAGE_SIZE = 30;

export const PRINT_REPORT_MAX_SIZE = 10000;

export enum EQueryKeys {
    ReportsListPage = 'ReportsListPage',
    ReportPage = 'ReportPage',
    NewReportPage = 'NewReportPage',
}

export const REPORT_PAGE_ID = 'legacy_report_page';
export const REPORTS_LIST_PAGE_ID = 'legacy_reports_list_page';
export const LINE_ITEM_REPORT_PAGE_ID = 'legacy_line-item-report_page';
export const LINE_ITEM_REPORTS_LIST_PAGE_ID = 'legacy_line-item-reports_list_page';
