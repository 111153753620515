import { Button, ExternalLink } from '@approvalmax/ui';
import { actions } from 'modules/common';
import { clearPersistStore } from 'modules/store';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useGetTFAEnforcementInformation } from 'shared/data';

import { messages } from './SetupStartHardEnforcement.messages';
import SetupStartHardEnforcementSkeleton from './SetupStartHardEnforcement.skeleton';
import { Descriprtion, Header, Root } from './SetupStartHardEnforcement.styles';
import { SetupStartHardEnforcementProps } from './SetupStartHardEnforcement.types';

const SetupStartHardEnforcement = memo<SetupStartHardEnforcementProps>((props) => {
    const { onStart } = props;

    const dispatch = useDispatch();
    const { data, isLoading } = useGetTFAEnforcementInformation();
    const isDefaultEnforcement = Boolean(data?.tfaEnforcementIntegrationTypes.length);

    const logout = useCallback(() => dispatch(actions.logout(clearPersistStore)), [dispatch]);

    if (isLoading) return <SetupStartHardEnforcementSkeleton />;

    return (
        <Root>
            <Header>{messages.title}</Header>

            <Descriprtion>
                {isDefaultEnforcement
                    ? messages.descriptionAdministratorEnforcement
                    : messages.descriptionCompanyEnforcement}
            </Descriprtion>

            <Descriprtion>
                {messages.moreInformation({
                    supportlink: (chunks) => (
                        <ExternalLink href='https://support.approvalmax.com/portal/en/newticket'>{chunks}</ExternalLink>
                    ),
                })}
            </Descriprtion>

            <Button execute={onStart}>{messages.button}</Button>

            <Button execute={logout} colorTheme='black'>
                {messages.logOut}
            </Button>
        </Root>
    );
});

export default SetupStartHardEnforcement;
