import { Button } from '@approvalmax/ui';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

import { useTwoFaEnablingWizardContext } from '../../TwoFaEnablingWizard.context';
import ProgressFlow2fa from '../ProgressFlow2fa/ProgressFlow2fa';
import { messages } from './StepBackupMethods.messages';
import { Content, Description, Root, Title } from './StepBackupMethods.styles';

const qa = bemFactory.qa('pro-two-fa-setup-backup-methods');

const StepBackupMethods: FC = memo(() => {
    const { setActiveStep } = useTwoFaEnablingWizardContext();

    const goToBackupCodes = useCallback(() => {
        setActiveStep('backupCodes');
    }, [setActiveStep]);

    const goToBackupEmail = useCallback(() => {
        setActiveStep('emailEnter');
    }, [setActiveStep]);

    return (
        <Root qa={qa()} title={messages.popupTitle}>
            <Content>
                <ProgressFlow2fa activeStepIndex={2} />

                <Title>{messages.title}</Title>

                <Description>{messages.description}</Description>

                <Button execute={goToBackupCodes}>{messages.backupCodes}</Button>

                <Button execute={goToBackupEmail}>{messages.backupEmail}</Button>
            </Content>
        </Root>
    );
});

export default StepBackupMethods;
