import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseResendQuoteForCustomerDecisionRequest } from './useResendQuoteForCustomerDecision.types';

export const useResendQuoteForCustomerDecision = () => {
    return useMutateLegacy<RequestDataParams<UseResendQuoteForCustomerDecisionRequest>>(
        requestsApiPaths.resendQuoteForCustomerDecision
    );
};
