import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.FieldSettingsPopup', {
    popupTitle: '{workflowDisplayName} fields',
    fieldNameCol: 'Field Name',
    fieldTypeCol: 'Field Type',
    fieldStateCol: 'Field State',
    hiddenFieldState: 'Hidden',
    readOnlyFieldState: 'Read-only',
    editableFieldState: 'Editable',
    mandatoryFieldState: 'Mandatory',
    headerFieldsTitle: 'Header Fields',
    itemsFieldsTitle: 'Items Fields',
    expensesFieldsTitle: 'Expenses Fields',
    applyLineFieldsTitle: 'Applied To Fields',
    creditLineFieldsTitle: 'Credits Applied Fields',
    doneButton: 'Done',
    unitPriceFieldName: 'Rate',
    resetToDefaultButton: 'Reset to default',
});
