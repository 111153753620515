import { domain } from 'modules/data';
import { combineReducers } from 'redux';

import activePopup from './module/activePopupReducer';
import companyConnectionErrorPopup from './module/companyConnectionErrorPopupReducer';

export interface Module {
    activePopup: any;
    companyConnectionErrorPopup: {
        id: string;
        integrationType: domain.IntegrationType;
    } | null;
}

export default combineReducers({
    activePopup,
    companyConnectionErrorPopup,
});
