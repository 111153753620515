import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isEnoughDaysAfter } from 'shared/helpers';
import { getCanShowSubscribeAfterTrialButton } from 'shared/helpers/experiments';
import { activeCompanyIdState } from 'shared/states';

import { subscribeAfterTrialPopupOpenState } from '../SubscribeAfterTrialPopup/SubscribeAfterTrialPopup.states';
import { sessionKeyAfterTrialBigPopupShowCount, sessionKeyAfterTrialBigPopupShowTime } from './Drawer.constants';
import { messages } from './Drawer.messages';

export const useCompanies = () => {
    const activeCompanyId = useRecoilValue(activeCompanyIdState);
    const companiesOrigin = useSelector(selectors.company.getCompanies);

    /**
     * Add name to the unnamed companies
     */
    const companies = useMemo(
        () =>
            companiesOrigin.map((company) => {
                if (!company.name) {
                    return {
                        ...company,
                        name: messages.unnamedCompanyName,
                    };
                }

                return company;
            }),
        [companiesOrigin]
    );
    const company = useMemo<selectors.types.ExpandedCompany | undefined>(
        () => companies.find(({ id }) => id === activeCompanyId) || companies[0],
        [activeCompanyId, companies]
    );

    return {
        companies,
        company,
    };
};

export const useSubscribeAfterTrialPopup = (
    company?: selectors.types.ExpandedCompany,
    profile?: selectors.types.ExpandedProfile | null
) => {
    const [menuClickCount, setMenuClickCount] = useState(0);
    const setSubscribeAfterTrialPopupOpen = useSetRecoilState(subscribeAfterTrialPopupOpenState);

    const canShowSubscribeAfterTrialButton = useMemo(
        () => getCanShowSubscribeAfterTrialButton(company, profile),
        [company, profile]
    );

    const handleMenuClick = useCallback(() => {
        const countFromStorage = sessionStorage.getItem(sessionKeyAfterTrialBigPopupShowCount);

        let showInSessionCount = countFromStorage ? parseInt(countFromStorage, 10) || 0 : 0;

        if (showInSessionCount >= 3) {
            if (!isEnoughDaysAfter(2, sessionStorage.getItem(sessionKeyAfterTrialBigPopupShowTime))) {
                return;
            } else {
                showInSessionCount = 0;
                sessionStorage.removeItem(sessionKeyAfterTrialBigPopupShowCount);
            }
        }

        if (menuClickCount >= 2) {
            setMenuClickCount(0);

            setSubscribeAfterTrialPopupOpen(true);
            sessionStorage.setItem(sessionKeyAfterTrialBigPopupShowCount, String(showInSessionCount + 1));
            sessionStorage.setItem(sessionKeyAfterTrialBigPopupShowTime, String(Date.now()));

            return;
        }

        setMenuClickCount(menuClickCount + 1);
    }, [menuClickCount, setSubscribeAfterTrialPopupOpen]);

    return canShowSubscribeAfterTrialButton ? handleMenuClick : undefined;
};
