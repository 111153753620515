import { domain } from 'modules/data';

import { messages } from './SectionEmailToSupplier.messages';

export const defaultLabels = {
    section: messages.defaultSection,
    title: messages.defaultTitle,
    description: messages.defaultDescription,
    templateDescription: messages.defaultTemplateDescription,
};

export const xeroQuoteLabels = {
    section: messages.xeroQuoteSection,
    title: messages.xeroQuoteTitle,
    description: messages.xeroQuoteDescription,
    templateDescription: messages.xeroQuoteTemplateDescription,
};

export const xeroSalesInvoiceLabels = {
    section: messages.xeroSalesInvoiceSection,
    title: messages.xeroSalesInvoiceTitle,
    description: messages.xeroSalesInvoiceTemplateDescription,
    templateDescription: messages.xeroSalesInvoiceTemplateDescription,
};

export const qBooksPoLabels = {
    section: messages.qBooksPoSection,
    title: messages.qBooksPoTitle,
    description: messages.qBooksPoDescription,
    templateDescription: messages.qBooksPoTemplateDescription,
};

export const qBooksSalesInvoiceLabels = {
    section: messages.qBooksSalesInvoiceSection,
    title: messages.qBooksSalesInvoiceTitle,
    description: messages.qBooksSalesInvoiceDescription,
    templateDescription: messages.qBooksSalesInvoiceTemplateDescription,
};

export const defaultLegendItems = [
    { value: domain.SupplierEmailReplacements.poNumber, description: messages.poNumberLegendItemDescription },
    { value: domain.SupplierEmailReplacements.companyName, description: messages.companyNameLegendItemDescription },
    { value: domain.SupplierEmailReplacements.totalAmount, description: messages.poTotalAmountLegendItemDescription },
    { value: domain.SupplierEmailReplacements.supplierName, description: messages.supplierNameLegendItemDescription },
    {
        value: domain.SupplierEmailReplacements.contactPersonName,
        description: messages.contactPersonNameLegendItemDescription,
    },
];

export const xeroQuoteLegendItems = [
    { value: domain.SupplierEmailReplacements.quoteNumber, description: messages.quoteNumberLegendItemDescription },
    { value: domain.SupplierEmailReplacements.companyName, description: messages.companyNameLegendItemDescription },
    {
        value: domain.SupplierEmailReplacements.totalAmount,
        description: messages.quoteTotalAmountLegendItemDescription,
    },
    { value: domain.SupplierEmailReplacements.customerName, description: messages.customerNameLegendItemDescription },
    {
        value: domain.SupplierEmailReplacements.decisionMakingWebPageLink,
        description: messages.quoteDecisionMakingWebPageLinkLegendItemDescription,
    },

    { value: domain.SupplierEmailReplacements.terms, description: messages.quoteTermsLegendItemDescription },
    {
        value: domain.SupplierEmailReplacements.contactPersonName,
        description: messages.contactPersonNameLegendItemDescription,
    },
];

export const xeroSalesInvoiceLegendItems = [
    {
        value: domain.SupplierEmailReplacements.invoiceNumber,
        description: messages.salesInvoiceNumberLegendItemDescription,
    },
    { value: domain.SupplierEmailReplacements.companyName, description: messages.companyNameLegendItemDescription },
    {
        value: domain.SupplierEmailReplacements.totalAmount,
        description: messages.salesInvoiceTotalAmountLegendItemDescription,
    },
    { value: domain.SupplierEmailReplacements.customerName, description: messages.customerNameLegendItemDescription },
    {
        value: domain.SupplierEmailReplacements.contactPersonName,
        description: messages.contactPersonNameLegendItemDescription,
    },
];

export const qBooksPoLegendItems = [
    { value: domain.SupplierEmailReplacements.poNumber, description: messages.poNumberLegendItemDescription },
    { value: domain.SupplierEmailReplacements.companyName, description: messages.companyNameLegendItemDescription },
    { value: domain.SupplierEmailReplacements.totalAmount, description: messages.poTotalAmountLegendItemDescription },
    { value: domain.SupplierEmailReplacements.supplierName, description: messages.vendorNameLegendItemDescription },
    {
        value: domain.SupplierEmailReplacements.contactPersonName,
        description: messages.contactPersonNameLegendItemDescription,
    },
];

export const qBooksSalesInvoiceLegendItems = [
    {
        value: domain.SupplierEmailReplacements.invoiceNumber,
        description: messages.salesInvoiceNumberLegendItemDescription,
    },
    { value: domain.SupplierEmailReplacements.companyName, description: messages.companyNameLegendItemDescription },
    {
        value: domain.SupplierEmailReplacements.totalAmount,
        description: messages.salesInvoiceTotalAmountLegendItemDescription,
    },
    { value: domain.SupplierEmailReplacements.customerName, description: messages.customerNameLegendItemDescription },
    {
        value: domain.SupplierEmailReplacements.contactPersonName,
        description: messages.contactPersonNameLegendItemDescription,
    },
];

export const netSuitePoLegendItems = [
    { value: domain.SupplierEmailReplacements.poNumber, description: messages.poNumberLegendItemDescription },
    { value: domain.SupplierEmailReplacements.companyName, description: messages.companyNameLegendItemDescription },
    { value: domain.SupplierEmailReplacements.totalAmount, description: messages.poTotalAmountLegendItemDescription },
    { value: domain.SupplierEmailReplacements.supplierName, description: messages.supplierNameLegendItemDescription },
];
