import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.SingletonSteps.ReviewStepActivator', {
    enableReviewStep: 'Enable Review Step',
    billMatchingDescription:
        'Review step allows to make certain changes to Bills as well as match them with Purchase Orders.',
    description: 'Review step allows to make certain changes to {templateName}.',
    hide: 'Hide',
    enable: 'Enable',
});
