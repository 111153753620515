import ColumnKind from './columnKind';

export default [
    ColumnKind.Name,
    ColumnKind.TemplateId,
    ColumnKind.ActiveStepName,
    ColumnKind.Resolution,
    ColumnKind.FraudulentActivity,
    ColumnKind.Description,
    ColumnKind.CreationDate,
    ColumnKind.ResolutionDate,
    ColumnKind.DueDate,
    ColumnKind.DeliveryDate,
    ColumnKind.PaymentDate,
    ColumnKind.ExpectedDate,
    ColumnKind.DocumentDate,
    ColumnKind.ResolutionTime,
    ColumnKind.Amount,
    ColumnKind.Currency,
    ColumnKind.ExchangeRate,
    ColumnKind.TaxType,
    ColumnKind.DocumentNumber,
    ColumnKind.Requesters,
    ColumnKind.ParticipantsApproved,
    ColumnKind.ParticipantsRejected,
    ColumnKind.ParticipantsNonResponded,
    ColumnKind.Contact,
    ColumnKind.Location,
    ColumnKind.LineItemClasses,
    ColumnKind.Reference,
    ColumnKind.Branding,
    ColumnKind.SentToSupplier,
    ColumnKind.AuditReportStatus,
    ColumnKind.LineItemAccounts,
    ColumnKind.LineItemTrackings1,
    ColumnKind.LineItemTrackings2,
    ColumnKind.PurchaseOrderBalance,
    ColumnKind.MatchedPurchaseOrders,
    ColumnKind.MatchedBills,
    ColumnKind.IsBilled,
    ColumnKind.IsPaid,
    ColumnKind.RetrospectivePurchaseOrder,
    ColumnKind.QBooksPurchaseOrderCustomFields,
    ColumnKind.QBooksSalesInvoiceCustomFields,
    ColumnKind.GrnStatus,
    ColumnKind.CustomField,
    ColumnKind.isAccepted,
    ColumnKind.CustomerDecisionDate,
    ColumnKind.isSent,
];
