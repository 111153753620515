import { State } from 'modules/data';
import { AnyAction } from 'modules/react-redux';

import { getCompanies } from './selectors/companySelectors';
import { ExpandedCompany } from './selectors/types';

function getState(): State {
    return window.ApprovalMax.app.getStore().getState();
}

function dispatch<T extends AnyAction>(action: any) {
    return window.ApprovalMax.app.getStore().dispatch<T>(action);
}

const automation = (window.ApprovalMax.automation = {
    getState,
    dispatch,

    common: {
        getCompanyIdByName(name: string): string {
            return getCompanies(getState()).find((c) => Boolean(c.name && c.name.includes(name)))!.id;
        },
        getCompanyByName(name: string): ExpandedCompany {
            return getCompanies(getState()).find((c) => Boolean(c.name && c.name.includes(name)))!;
        },
    },
});

export default automation;
