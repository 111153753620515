import { Box, Flex, Popup, toast } from '@approvalmax/ui/src/components';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { HelpStripe } from 'shared/components';

import { applyMatrix, discardOpenEditingMatrix, discardOpenMatrix } from '../../actions';
import { useMatrixData } from '../../hooks/matrix';
import { getActiveEditingMatrix, getActiveMatrix } from '../../selectors/pageSelectors';
import { MatrixType } from '../../types/matrix';
import { getIsValidMatrix } from '../../utils/helpers';
import CopyRulePopup from './components/CopyRulePopup/CopyRulePopup';
import { DefaultApproverSection } from './components/DefaultApproverSection/DefaultApproverSection';
import { DefaultReviewerSection } from './components/DefaultReviewerSection/DefaultReviewerSection';
import { EditingMatrixPopupContent } from './components/EditingMatrixPopupContent/EditingMatrixPopupContent';
import ErrorScreen from './components/ErrorScreen/ErrorScreen';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import Matrix from './components/Matrix/Matrix';
import PopupActions from './components/PopupActions/PopupActions';
import { usePopupData } from './MatrixPopupContent.hooks';
import { messages } from './MatrixPopupContent.messages';

const MatrixPopupContent = memo(() => {
    const dispatch = useDispatch();
    const matrix = useSelector(getActiveMatrix)!;
    const editingMatrix = useSelector(getActiveEditingMatrix);
    const {
        company,
        team,
        template,
        integrationType,
        integrationErrorPreventsWork,
        readonly,
        canAssignDefaultApprover,
        canAssignDefaultReviewer,
        step,
        loaded,
        cellFields,
        loadedNoError,
        loadedWithError,
        errorMessage,
        fields,
        definition,
        submitters,
    } = useMatrixData(matrix);

    const { title, helpItemId } = usePopupData(matrix.type, template.integrationCode, step?.name);
    const showDefaultApprover = loadedNoError && canAssignDefaultApprover && !readonly;
    const showDefaultReviewer = loadedNoError && canAssignDefaultReviewer && !readonly;

    const applyAndClose = useCallback(() => {
        if (readonly) {
            dispatch(discardOpenMatrix());

            return;
        }

        const isValidMatrix = getIsValidMatrix(matrix);

        if (isValidMatrix) {
            dispatch(applyMatrix(matrix, fields));

            if (typeof matrix.onApplyAndClose === 'function') {
                matrix.onApplyAndClose('stepIndex' in matrix ? matrix.stepIndex : null);
            }
        } else {
            matrix.type === MatrixType.AutoApproval
                ? toast.error(messages.invalidAutoApprovalConditions)
                : toast.error(messages.invalidConditions);
        }
    }, [dispatch, fields, matrix, readonly]);

    const toggleEditingMatrixPopup = useCallback(
        (open: boolean) => !open && dispatch(discardOpenEditingMatrix()),
        [dispatch]
    );

    return (
        <>
            <Popup.Header
                title={title}
                actions={
                    <PopupActions
                        company={company}
                        integrationType={integrationType}
                        applyAndClose={applyAndClose}
                        readonly={readonly}
                        integrationCode={template.integrationCode}
                        matrixType={matrix.type}
                        helpStripeId={helpItemId}
                    />
                }
            />

            <Popup.Body className='fs-mask' spacing='0'>
                <Flex inline direction='column' grow={1} width='100%' height='100%' wrap={false} spacing='0'>
                    {helpItemId && (
                        <Box spacing='0 40'>
                            <HelpStripe id={helpItemId} />
                        </Box>
                    )}

                    {loadedNoError && (
                        <Matrix
                            definition={definition}
                            company={company}
                            team={team}
                            template={template}
                            integrationType={integrationType}
                            fields={fields}
                            readonly={readonly}
                            submitters={submitters}
                            cellFields={cellFields}
                            matrix={matrix}
                        />
                    )}

                    {!loaded && <LoadingScreen />}

                    {loadedWithError && (
                        <ErrorScreen
                            integrationErrorPreventsWork={integrationErrorPreventsWork}
                            integrationType={integrationType}
                            errorMessage={errorMessage}
                        />
                    )}

                    {showDefaultApprover && <DefaultApproverSection />}

                    {showDefaultReviewer && <DefaultReviewerSection />}
                </Flex>
            </Popup.Body>

            <CopyRulePopup
                matrix={matrix}
                cellFields={cellFields}
                steps={template.steps}
                company={company}
                template={template}
                team={team}
                readonly={readonly}
            />

            <Popup open={!!editingMatrix} onToggle={toggleEditingMatrixPopup} preventAutoClose size='large'>
                <EditingMatrixPopupContent />
            </Popup>
        </>
    );
});

MatrixPopupContent.displayName = 'MatrixPopupContent';

export default MatrixPopupContent;
