import { Guid } from '@approvalmax/types';
import { domain } from 'modules/data';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, EDIT_STEP_PARTICIPANTS_RESPONSE, SHOW_EDIT_REVIEWERS_POPUP } from '../../../actions';

export const EDIT_REVIEWERS_POPUP = 'EDIT_REVIEWERS_POPUP';

export interface EditReviewersPopupData {
    id: typeof EDIT_REVIEWERS_POPUP;
    requestId: Guid;
    reviewStep: domain.RequestReviewStep;
}

export type EditReviewersPopupType = ImmutableObject<EditReviewersPopupData> | null;

export default function (state: EditReviewersPopupType, action: Action): EditReviewersPopupType {
    switch (action.type) {
        case SHOW_EDIT_REVIEWERS_POPUP:
            return immutable<EditReviewersPopupData>({
                id: EDIT_REVIEWERS_POPUP,
                requestId: action.payload.requestId,
                reviewStep: action.payload.reviewStep,
            });

        case EDIT_STEP_PARTICIPANTS_RESPONSE:
            return null;

        default:
            return state;
    }
}
