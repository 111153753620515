import { HelpSection } from 'modules/common/ui';
import styled from 'styled-components';
import { VelocityTransitionGroup } from 'velocity-react';

export const StyledVelocityTransitionGroup = styled(VelocityTransitionGroup)`
    display: block;

    &:empty {
        position: relative;
        top: 1px;
        border-bottom: 1px solid #caccce;
    }
`;

export const HelpSectionWrapper = styled(HelpSection)`
    padding: 0 60px;
`;
