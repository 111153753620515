import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('application.UpgradeExpiredPlanNotification', {
    trialOfAllFeatures: 'Trial of all features for "{companyName}" has expired',
    trialOfAllFeaturesMultiple:
        'Trial of all features for {count} organisations expired, review your subscription plans',
    upgradePlan: 'Upgrade plan',
    reviewSubscriptions: 'Review subscriptions',
    hide: 'Hide',
});
