import { backend } from 'modules/data';

import { ApiHandler } from '../types';

class CustomApiUrls {
    constructor(private _apiHandler: ApiHandler) {}
}

export default class CustomApiApi {
    public urls: CustomApiUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new CustomApiUrls(this._apiHandler);
    }

    public ExportCompanyReport(transfer: backend.AMBackendControllersv1ReportTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'customApi/ExportCompanyReport',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getRequesters(
        transfer: backend.AMBackendControllersv1PurchaseOrderRequesterTransfer
    ): Promise<backend.AMBackendControllersv1PurchaseOrderRequestersAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'customApi/getRequesters',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public exportReport(transfer: backend.AMBackendControllersv1ReportTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'customApi/exportReport',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public ClimateKICExportReport(transfer: backend.AMBackendControllersv1ReportTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'customApi/ClimateKICExportReport',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public SlvdExportReport(transfer: backend.AMBackendControllersv1ReportTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'customApi/SlvdExportReport',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public BoomAeroExportReport(transfer: backend.AMBackendControllersv1ReportTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'customApi/BoomAeroExportReport',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public WBSExportReport(transfer: backend.AMBackendControllersv1ReportTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'customApi/WBSExportReport',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public WorkLifeExportReport(transfer: backend.AMBackendControllersv1ReportTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'customApi/WorkLifeExportReport',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public WealthcheckExportReport(transfer: backend.AMBackendControllersv1ReportTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'customApi/WealthcheckExportReport',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public CloudfoxGroupExportReport(transfer: backend.AMBackendControllersv1ReportTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'customApi/CloudfoxGroupExportReport',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public NPYWCPurchaseOrdersStatus(
        transfer: backend.AMBackendControllersv1PurchaseOrderSummaryStatusTransfer
    ): Promise<backend.AMBackendControllersv1PurchaseOrderSummaryStatusAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'customApi/NPYWCPurchaseOrdersStatus',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public NPYWCCreatePurchaseOrder(
        transfer: backend.AMBackendControllersv1PurchaseOrderCreateDraftTransfer
    ): Promise<backend.AMBackendControllersv1PurchaseOrderCreateDraftAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'customApi/NPYWCCreatePurchaseOrder',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
