import './whatYouCanDoNow.scss';

import { UservoiceIcon } from 'modules/sprites';
import { FC } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { getSupportSiteUrl } from 'urlBuilder';

import CreateOrganizationButton from './createOrganizationButton/CreateOrganizationButton';
import { WhatYouCanDoNowProps } from './WhatYouCanDoNow.types';

const i18nPrefix = '@NoCompanies/no-invites';

const bem = bemFactory.block('no-companies-page-actions');

const WhatYouCanDoNow: FC<WhatYouCanDoNowProps> = (props) => {
    const { openNewOrgPopopOnMount } = props;

    return (
        <>
            <div className={bem('actions-title')}>
                <FormattedMessage
                    id={`${i18nPrefix}.actionsTitleText`}
                    defaultMessage='What else you can do right now'
                />
            </div>

            <div className={bem('buttons-block')}>
                <CreateOrganizationButton openNewOrgPopopOnMount={openNewOrgPopopOnMount} />

                <a
                    className={bem('action-button')}
                    href={getSupportSiteUrl()}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <div className={bem('action-button-text')}>
                        <FormattedMessage id={`${i18nPrefix}.buttonKbText`} defaultMessage='Visit Knowledge Base' />
                    </div>

                    <div className={bem('action-button-icon')}>
                        <UservoiceIcon width={38} height={33} />
                    </div>
                </a>
            </div>
        </>
    );
};

export default WhatYouCanDoNow;
