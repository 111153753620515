import { Guid, Reference } from '@approvalmax/types';
import { createAction, ExtractActions } from 'modules/react-redux';

export const CHANGE_STANDALONE_AMOUNT = 'REQUESTFORM/CHANGE_STANDALONE_AMOUNT';
export const changeStandaloneAmount = (newAmount: number | null) =>
    createAction(CHANGE_STANDALONE_AMOUNT, {
        newAmount,
    });

export const CHANGE_STANDALONE_CURRENCY = 'REQUESTFORM/CHANGE_STANDALONE_CURRENCY';
export const changeStandaloneCurrency = (newCurrency: Reference | null) =>
    createAction(CHANGE_STANDALONE_CURRENCY, {
        newCurrency,
    });

export const CHANGE_STANDALONE_DATE = 'REQUESTFORM/CHANGE_STANDALONE_DATE';
export const changeStandaloneDate = (newDate: string | null) =>
    createAction(CHANGE_STANDALONE_DATE, {
        newDate,
    });

export const CHANGE_STANDALONE_FIELD_VALUE = 'REQUESTFORM/CHANGE_STANDALONE_FIELD_VALUE';
export const changeStandaloneFieldValue = (fieldId: Guid, newValue: Reference | null) =>
    createAction(CHANGE_STANDALONE_FIELD_VALUE, {
        fieldId,
        newValue,
    });

export type Action = ExtractActions<
    | typeof changeStandaloneAmount
    | typeof changeStandaloneCurrency
    | typeof changeStandaloneDate
    | typeof changeStandaloneFieldValue
>;
