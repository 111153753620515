import { UseChangeGrnStatusRequest, UseChangeGrnStatusResponse } from 'shared/data';

import { ApiHandler } from '../types';

export default class XeroGrnApi {
    constructor(private _apiHandler: ApiHandler) {}

    changeGrnStatus(data: UseChangeGrnStatusRequest): Promise<UseChangeGrnStatusResponse> {
        return this._apiHandler.doApiCall({
            data,
            action: 'requests/changeGrnStatus',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
