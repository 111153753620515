import { domain } from 'modules/data';

import navigationReducer from './resources/reducers/navigationReducer';

export const pageConfig = {
    access: [domain.CompanyUserRole.Manager, domain.CompanyUserRole.Auditor, domain.CompanyUserRole.WorkflowManager],
    store: {
        reducers: {
            navigation: navigationReducer,
        },
    },
};
