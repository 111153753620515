import { AmLogo, BellIcon, MenuIcon, PinIcon, PinPinnedIcon } from '@approvalmax/ui';
import Badge from '@approvalmax/ui/src/components/Badge/Badge';
import Button from '@approvalmax/ui/src/components/Button/Button';
import ConditionWrapper from '@approvalmax/ui/src/components/ConditionWrapper/ConditionWrapper';
import Dropdown from '@approvalmax/ui/src/components/Dropdown/Dropdown';
import { Flex } from '@approvalmax/ui/src/components/Flex/Flex';
import { Text } from '@approvalmax/ui/src/components/Text/Text';
import Tooltip from '@approvalmax/ui/src/components/Tooltip/Tooltip';
import { hooks } from '@approvalmax/utils';
import { FC, memo, useCallback, useEffect } from 'react';
import bemFactory from 'react-bem-factory';
import { useToggle } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';

import { collapseDrawerState, openDrawerState, pinDrawerState } from '../../Drawer.states';
import { useScreenAdaptation } from './Header.hooks';
import { messages } from './Header.messages';
import { ExpandButton, Inner, Root } from './Header.styles';
import { HeaderProps } from './Header.types';

const qa = bemFactory.qa('page-nav-bar');

const Header: FC<HeaderProps> = memo((props) => {
    const { root, notifications, notificationsCount, ...restProps } = props;

    const { sticky, stickyRef } = hooks.useSticky({ root });
    const { isHidePinButton } = useScreenAdaptation();

    const isDrawerCollapsed = useRecoilValue(collapseDrawerState);
    const [isDrawerPinned, setIsDrawerPinned] = useRecoilState(pinDrawerState);
    const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(openDrawerState);

    const pinDrawer = useCallback(() => setIsDrawerPinned(true), [setIsDrawerPinned]);
    const unpinDrawer = useCallback(() => {
        setIsDrawerPinned(false);
        setIsDrawerOpen(true);
    }, [setIsDrawerPinned, setIsDrawerOpen]);

    const [isNotificationsOpen, setIsNotificationsOpen] = useToggle(false);

    useEffect(() => {
        if (!notificationsCount) {
            setIsNotificationsOpen(false);
        }
    }, [notificationsCount, setIsNotificationsOpen]);

    return (
        <>
            <div ref={stickyRef} />

            <Root $sticky={sticky} $open={isDrawerOpen} {...restProps}>
                {isDrawerCollapsed ? (
                    <ExpandButton
                        $collapsed={isDrawerCollapsed}
                        icon
                        block
                        size='large'
                        onClick={unpinDrawer}
                        data-qa={qa('drawer-button')}
                    >
                        <ConditionWrapper
                            condition={notificationsCount}
                            wrapperIf={(children) => (
                                <Badge size={8} placement='right-start' color='red100'>
                                    {children}
                                </Badge>
                            )}
                        >
                            <MenuIcon size={24} />
                        </ConditionWrapper>
                    </ExpandButton>
                ) : (
                    <Inner $collapsed={isDrawerCollapsed}>
                        <AmLogo width='132px' height='20px' />

                        <Flex inline>
                            <Dropdown
                                open={isNotificationsOpen}
                                onOpen={setIsNotificationsOpen}
                                activator={
                                    <ConditionWrapper
                                        condition={!notificationsCount}
                                        wrapperIf={(children) => (
                                            <Tooltip activator={children}>
                                                <Text font='body' fontSize='xsmall'>
                                                    {messages.noInvitationsYet}
                                                </Text>
                                            </Tooltip>
                                        )}
                                    >
                                        <Button
                                            size='small'
                                            color='red40'
                                            disabled={!notificationsCount}
                                            startIcon={<BellIcon />}
                                        >
                                            {notificationsCount || 0}
                                        </Button>
                                    </ConditionWrapper>
                                }
                                closeOnClickInnerButton
                                disabled={!notificationsCount}
                                width={280}
                                spacing='20'
                            >
                                {notifications}
                            </Dropdown>

                            {!isHidePinButton && (
                                <>
                                    {isDrawerPinned ? (
                                        <Button
                                            icon
                                            size='large'
                                            color='midnight40'
                                            onClick={unpinDrawer}
                                            data-qa='page-nav-bar__unpin-button'
                                        >
                                            <PinPinnedIcon size={20} />
                                        </Button>
                                    ) : (
                                        <Button
                                            icon
                                            size='large'
                                            color='blue10'
                                            onClick={pinDrawer}
                                            data-qa='page-nav-bar__pin-button'
                                        >
                                            <PinIcon size={20} />
                                        </Button>
                                    )}
                                </>
                            )}
                        </Flex>
                    </Inner>
                )}
            </Root>
        </>
    );
});

export default Header;
