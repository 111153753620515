import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';

import { useGetRequestLegacy } from '../../../api';
import { reportsApiPaths } from '../paths';
import {
    UseGetReportScheduleParams,
    UseGetReportScheduleResponse,
    UseGetReportScheduleResponseBackend,
} from './useGetReportSchedule.types';

export const useGetReportSchedule = (
    params: QueryParams<UseGetReportScheduleParams>,
    queryOptions?: QueryOptions<UseGetReportScheduleResponseBackend, UseGetReportScheduleResponse>
) => {
    return useGetRequestLegacy<UseGetReportScheduleResponseBackend, UseGetReportScheduleResponse>(
        reportsApiPaths.getReportSchedule,
        params,
        {
            queryOptions: {
                ...queryOptions,
                enabled: enabledByParams<UseGetReportScheduleParams>(
                    {
                        companyId: params.companyId,
                        reportId: params.reportId,
                        type: params.type,
                    },
                    queryOptions
                ),
            },
            mapData: (response) => response.schedules,
        }
    );
};

export const useGetReportScheduleInvalidate = (params: UseGetReportScheduleParams) => {
    const queryClient = useQueryClient();

    return () => queryClient.invalidateQueries([reportsApiPaths.getReportSchedule, params]);
};
