import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SHOW_REQUEST_SEARCH_POPUP_RESPONSE } from '../../../actions';
import { SearchParameters } from '../../../data/SearchParameters';

export const REQUEST_SEARCH_POPUP = 'REQUEST_SEARCH_POPUP';

export interface RequestSearchPopupData {
    id: typeof REQUEST_SEARCH_POPUP;
    defaultSearchParams: SearchParameters | null;
}

export type RequestSearchPopupType = ImmutableObject<RequestSearchPopupData> | null;

export default function (state: RequestSearchPopupType, action: Action): RequestSearchPopupType {
    switch (action.type) {
        case SHOW_REQUEST_SEARCH_POPUP_RESPONSE:
            return immutable<RequestSearchPopupData>({
                id: REQUEST_SEARCH_POPUP,
                defaultSearchParams: action.payload.request.searchParams,
            });

        default:
            return state;
    }
}
