import { domHelpers, hooks } from '@approvalmax/utils';
import {
    forwardRef,
    ForwardRefExoticComponent,
    KeyboardEvent,
    RefAttributes,
    RefObject,
    useCallback,
    useEffect,
    useId,
    useRef,
} from 'react';

import { CloseCircleFilledIcon, LockIcon } from '../../icons';
import { Button } from '../Button/Button';
import Label from '../Label/Label';
import { Controller } from './components';
import { defaultTransform } from './TextField.helpers';
import { useClearable, useFocus, useResizeHeight, useValidate, useValue } from './TextField.hooks';
import { Clear, Control, DisabledCover, EndIcon, Hint, Input, Root, StartIcon, Textarea } from './TextField.styles';
import { ChildrenComponents, HTMLTextFieldElement, TextFieldProps } from './TextField.types';

/**
 * Text Fields let users enter and edit text.
 */
const TextField = forwardRef((props, ref) => {
    const {
        id: idProp,
        onChange,
        onClear,
        onClick,
        bordered = true,
        size = 'medium',
        clearable = true,
        startIcon,
        endIcon,
        invalid,
        focus,
        initFocus,
        onFocus,
        onBlur,
        autoComplete = 'off',
        value,
        multiline,
        required,
        disabled,
        label,
        hint,
        minLength,
        onMouseEnter,
        onMouseLeave,
        width,
        height,
        grow,
        onEnter,
        onKeyDown,
        transform = defaultTransform,
        className,
        autoHeight,
        maxHeight = multiline && autoHeight ? '400px' : undefined,
        cursor,
        qa,
        ...restProps
    } = props;

    const fieldRef = useRef<HTMLTextFieldElement>(null);
    const composedRefs = hooks.useComposedRefs(ref, fieldRef);
    const generatedId = useId();
    const id = idProp || generatedId;
    const hintId = `${id}-hint`;

    const { handleChange, fieldValue } = useValue({ value, onChange, transform });
    const { handleClear } = useClearable({ handleChange, fieldRef, onClear });
    const { handleBlur, handleFocus, isFocus } = useFocus({
        fieldRef,
        onFocus,
        onBlur,
        focus,
        initFocus,
    });
    const { invalidValue } = useValidate({ invalid, minLength, fieldValue, isFocus });

    const fieldProps = {
        ...restProps,
        value: fieldValue,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
        autoComplete,
        disabled,
        pattern: undefined,
        validate: undefined,
    };

    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onKeyDown?.(e);

            if (e.key === 'Enter') {
                onEnter?.(e);
            }
        },
        [onKeyDown, onEnter]
    );

    const resizeHeight = useResizeHeight(fieldRef);

    useEffect(() => {
        if (multiline && autoHeight) {
            resizeHeight();
        }
    }, [multiline, autoHeight, resizeHeight, fieldValue]);

    return (
        <Root $width={width} data-qa={domHelpers.generateDataQa(qa, 'text-field')}>
            {label && (
                <Label
                    htmlFor={id}
                    size={size === 'xsmall' ? size : 'small'}
                    required={required}
                    spacing={bordered ? undefined : '0'}
                >
                    {label}
                </Label>
            )}

            <Control
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                $invalid={invalidValue}
                $bordered={bordered}
                $size={size}
                $focus={isFocus}
                $disabled={disabled}
                $grow={grow}
                $multiline={multiline}
                $cursor={cursor}
            >
                {startIcon && <StartIcon>{startIcon}</StartIcon>}

                {multiline ? (
                    <Textarea
                        data-qa={domHelpers.generateDataQa(qa, 'text-field-textarea')}
                        {...fieldProps}
                        id={id}
                        aria-describedby={hint && hintId}
                        $height={height}
                        $maxHeight={maxHeight}
                        onKeyDown={handleKeyDown}
                        ref={composedRefs as RefObject<HTMLTextAreaElement>}
                    />
                ) : (
                    <Input
                        data-qa={domHelpers.generateDataQa(qa, 'text-field-input')}
                        {...fieldProps}
                        id={id}
                        aria-describedby={hint && hintId}
                        onKeyDown={handleKeyDown}
                        ref={composedRefs as RefObject<HTMLInputElement>}
                    />
                )}

                {!disabled && !multiline && clearable && fieldValue && (
                    <Clear>
                        <Button onClick={handleClear} icon noPadding tabIndex={-1}>
                            <CloseCircleFilledIcon />
                        </Button>
                    </Clear>
                )}

                {(endIcon || disabled) && <EndIcon>{disabled ? <LockIcon /> : endIcon}</EndIcon>}

                {disabled && <DisabledCover disabled />}
            </Control>

            {hint && (
                <Hint id={hintId} $invalid={invalidValue}>
                    {hint}
                </Hint>
            )}
        </Root>
    );
}) as ForwardRefExoticComponent<TextFieldProps & RefAttributes<HTMLTextFieldElement>> & ChildrenComponents;

TextField.Controller = Controller;

export default TextField;
