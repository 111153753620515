import { domain } from 'modules/data';

import entitiesReducer from './resources/reducers/entitiesReducer';
import metaReducer from './resources/reducers/metaReducer';
import navigationReducer from './resources/reducers/navigationReducer';
import pageReducer from './resources/reducers/pageReducer';
import userPreferencesReducer from './resources/reducers/userPreferencesReducer';
import cacheManagement from './resources/sagas/cacheManagement';

export const pageConfig = {
    innerActivePageId: 'workflow',
    access: [domain.CompanyUserRole.Manager, domain.CompanyUserRole.Auditor, domain.CompanyUserRole.WorkflowManager],
    store: {
        id: 'workflow',
        pageReducer,
        reducers: {
            entities: entitiesReducer,
            navigation: navigationReducer,
            meta: metaReducer,
            userPreferences: userPreferencesReducer,
        },
        sagas: [cacheManagement],
    },
};
