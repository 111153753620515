import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseSetQuoteDecisionByCustomerRequest } from './useSetQuoteDecisionByCustomer.types';

export const useSetQuoteDecisionByCustomer = (
    mutationOptions?: MutationOptions<never, RequestDataParams<UseSetQuoteDecisionByCustomerRequest>>
) => {
    const queryClient = useQueryClient();

    return useMutateLegacy<RequestDataParams<UseSetQuoteDecisionByCustomerRequest>>(
        requestsApiPaths.setQuoteDecisionByCustomer,
        {
            mutationOptions: {
                ...mutationOptions,
                onSettled: (_data, _error, requestData) => {
                    void queryClient.invalidateQueries([
                        routerHelpers.pathToUrl(requestsApiPaths.getQuoteForCustomerDecision, requestData.pathParams),
                    ]);
                },
            },
        }
    );
};
