import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.Drawer.SettingsMenu', {
    settingsName: 'Workflows and settings',
    workflowsName: 'Approval workflows',
    usersName: 'Users',
    customFieldsName: 'Fields',
    organisationSettingsName: 'Organisation Settings and Connection',
    organisationSettingsStandaloneName: 'Organisation Settings',
    bankAccounts: 'Bank Accounts',
    contactPaymentDetails: 'Contact details',
});
