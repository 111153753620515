import { Box, List, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { TrialIntegrationType } from '../../AllFeatureTrialPaywallPopup.types';
import { descriptions } from './FeatureDescription.constants';
import { Image } from './FeatureDescription.styles';
import { FeatureDescriptionProps } from './FeatureDescription.types';

export const FeatureDescription: FC<FeatureDescriptionProps> = memo((props) => {
    const { company, activeFeature } = props;

    const isXero = company.integrationType === TrialIntegrationType.Xero;

    const isWide = company?.plan === 'advanced';

    const description = descriptions[activeFeature];

    if (!description) {
        return null;
    }

    return (
        <Box
            color={isXero ? 'brandXero100' : 'brandQBO70'}
            width={isWide ? 436 : 360}
            height='100%'
            radius='small'
            spacing='32 32 48'
        >
            {description.title && (
                <Text font='headline' fontSize='small' fontWeight='medium' spacing='0 0 24'>
                    {description.title}
                </Text>
            )}

            {description.subtitle && (
                <Text font='body' fontSize='large' fontWeight={description.list ? 'bold' : 'regular'} spacing='0 0 24'>
                    {description.subtitle}
                </Text>
            )}

            {description.list && (
                <Text font='body' fontSize='large'>
                    <List listStyle='inside'>
                        {description.list.map((item, index) => (
                            <List.Item key={index}>{item}</List.Item>
                        ))}
                    </List>
                </Text>
            )}

            {description.endnote && (
                <Text font='label' fontSize='small' spacing='12 0 0'>
                    {description.endnote}
                </Text>
            )}

            {description.endnoteLarge && (
                <Text font='label' fontSize='large' spacing='24 0 0' fontWeight='medium'>
                    {description.endnoteLarge}
                </Text>
            )}

            {description.image && (
                <a href={description.image} target='_blank' rel='noreferrer'>
                    <Image src={description.image} alt={description.title} />
                </a>
            )}
        </Box>
    );
});

FeatureDescription.displayName = 'FeatureDescription';
