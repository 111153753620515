import './passwordEditor.scss';

import { EyeShowOffIcon, EyeShowOnIcon } from '@approvalmax/ui/src/old/icons';
import { FC, memo, useState } from 'react';
import bemFactory from 'react-bem-factory';

import { BaseEditorProps } from '../EditorBase';
import TextEditor from '../text/TextEditor';
import { messages } from './PasswordEditor.messages';

export const ALL_THEMES = ['form', 'auth'] as const;
export type Theme = (typeof ALL_THEMES)[number];

interface PasswordEditorProps extends BaseEditorProps<string> {
    className?: string;
    placeholder?: string;
    maxLength?: number;
    onEnter?: () => void;
    changeOnBlur?: boolean;
    onEscape?: () => void;
    onUp?: () => void;
    onDown?: () => void;
    theme?: Theme;
    autoComplete?: 'on' | 'off' | 'new-password';
}

const PasswordEditor: FC<PasswordEditorProps> = (props) => {
    const { className, theme = 'form' } = props;

    const bem = bemFactory.block('form-password-editor').themed(theme);

    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className={bem.add(className)(null, { invalid: props.invalid })}>
            <TextEditor
                {...props}
                className={bem('editor')}
                type={showPassword ? 'text' : 'password'}
                theme='transparent'
            />

            <button
                className={bem('eye')}
                aria-hidden='true'
                type='button'
                onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowPassword(!showPassword);
                }}
                title={showPassword ? messages.hidePasswordButtonTitle : messages.showPasswordButtonTitle}
            >
                {showPassword ? (
                    <EyeShowOffIcon className={bem('eye-off')} width={18} height={12} />
                ) : (
                    <EyeShowOnIcon className={bem('eye-on')} width={18} height={14} />
                )}
            </button>
        </div>
    );
};

export default memo(PasswordEditor);
