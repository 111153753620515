import { memo } from 'react';

import { Calendar } from '../../../Calendar/Calendar';
import { DigitalClock } from '../../../DigitalClock/DigitalClock';
import Divider from '../../../Divider/Divider';
import { Grid } from '../../../Grid/Grid';
import { calendarInitialView } from '../../DateTimePicker.constants';
import { PopupContentProps } from './PopupContent.types';

export const PopupContent = memo<PopupContentProps>((props) => {
    const { calendarValue, minDate, maxDate, qa, onCalendarChange, onDigitalClockChange } = props;

    return (
        <Grid gridTemplateColumns='224px repeat(2, max-content)' gap={12}>
            <Calendar
                maxDetail={calendarInitialView}
                minDate={minDate}
                maxDate={maxDate}
                value={calendarValue}
                onChange={onCalendarChange}
            />

            <Divider orientation='vertical' />

            <DigitalClock
                height={256}
                minDate={minDate}
                maxDate={maxDate}
                qa={qa}
                value={calendarValue}
                onChange={onDigitalClockChange}
            />
        </Grid>
    );
});

PopupContent.displayName = 'PopupContent';
