import type { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { companiesApiPaths } from '../paths';
import type { UseDelegationsResponse } from './useDelegations.types';

export const useDelegations = (queryOptions?: QueryOptions<UseDelegationsResponse>) =>
    useGetRequest(companiesApiPaths.delegations, {
        queryOptions,
        apiVersion: 'v2',
    });
