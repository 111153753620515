import { domain } from 'modules/data';

const getApprovalDisregardDetectionSettings = (
    settings: domain.TemplateSettings,
    integrationType: domain.IntegrationType
) => {
    switch (integrationType) {
        case domain.IntegrationType.Xero:
            return settings.approvalDisregardDetectionSettings;

        case domain.IntegrationType.QBooks:
            return settings.qBooksApprovalDisregardDetectionSettings;

        case domain.IntegrationType.NetSuite:
            return settings.netSuiteApprovalDisregardDetectionSettings;

        case domain.IntegrationType.Dear:
            return settings.dearApprovalDisregardDetectionSettings;

        default:
            return;
    }
};

export const getNewApprovalDisregardDetectionSettings = (
    savedTemplateSettings: domain.TemplateSettings,
    templateSettings: domain.TemplateSettings,
    integrationType: domain.IntegrationType
) => {
    const result = getApprovalDisregardDetectionSettings(templateSettings, integrationType);

    const templateSettingsHasEffectiveDateButDisabled = result && result.effectiveDate && !result.enabled;

    const savedSettings = getApprovalDisregardDetectionSettings(savedTemplateSettings, integrationType);

    const savedTemplateSettingsDoesNotHaveEffectiveDate = savedSettings && !savedSettings.effectiveDate;

    const shouldClearEffectiveDate =
        templateSettingsHasEffectiveDateButDisabled && savedTemplateSettingsDoesNotHaveEffectiveDate;

    if (shouldClearEffectiveDate) {
        result.effectiveDate = null;
    }

    return result;
};
