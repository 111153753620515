import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseGetRequestCurrenciesRequestParams, UseGetRequestCurrenciesResponse } from './useGetRequestCurrencies.types';

export const useGetRequestCurrencies = (
    pathParams: UseGetRequestCurrenciesRequestParams,
    queryOptions?: QueryOptions<UseGetRequestCurrenciesResponse>
) => {
    return useGetRequest<UseGetRequestCurrenciesResponse>(requestsApiPaths.currencies, {
        pathParams,
        queryOptions,
        apiVersion: 'v2',
    });
};
