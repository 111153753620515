import { progress } from '@approvalmax/ui';
import { errorHelpers, miscHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';

import { LOAD_USER_PROFILE_DATA, loadUserProfileData, loadUserProfileDataResponse } from '../actions';
import * as profileSelectors from '../selectors/profileSelectors';
import * as userSelectors from '../selectors/userSelectors';

const profilesBeingLoaded: {
    [userId: string]: boolean;
} = {};

function loadProfile(user: domain.User) {
    return api.profile.contactDetails({
        email: user.userEmail,
    });
}

export default function* () {
    yield takeEvery(LOAD_USER_PROFILE_DATA, function* (action: ReturnType<typeof loadUserProfileData>): any {
        try {
            const user: domain.User = yield select(userSelectors.getUserById, action.payload.userId);

            if (user.profileInfo) {
                // Already loaded
                return;
            }

            const hasAccess = yield select(profileSelectors.hasAccessToUserProfileData, action.payload.userId);

            miscHelpers.invariant(
                hasAccess,
                'LoadUserProfile request has to be called only ' + 'if we have proper permissions to access its data.'
            );

            profilesBeingLoaded[action.payload.userId] = true;

            let data;

            progress.inc();

            try {
                data = yield call(loadProfile, user);
            } finally {
                progress.dec();
            }

            const profileInfo: domain.UserProfileInfo = {
                skype: data.Skype,
                phone: data.Phone,
            };

            yield put(loadUserProfileDataResponse(user.id, profileInfo));
            delete profilesBeingLoaded[action.payload.userId];
        } catch (e) {
            // Catch all errors here so that the other sagas don't crash
            errorHelpers.captureException(e);
        }
    });
}
