import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { activeCompanyIdState } from 'shared/states';

export const useActiveCompanyId = () => {
    const companies = useSelector(selectors.company.getCompanies);
    const firstCompanyId = companies[0]?.id;
    const [activeCompanyId, setActiveCompanyId] = useRecoilState(activeCompanyIdState);
    const findActiveCompanyById = useMemo(
        () => companies.find(({ id }) => id === activeCompanyId),
        [activeCompanyId, companies]
    );

    /**
     * By default, set a company id a first company if it exists and not set yet
     */
    useEffect(() => {
        if (!findActiveCompanyById && firstCompanyId) {
            setActiveCompanyId(firstCompanyId);
        }
    }, [activeCompanyId, findActiveCompanyById, firstCompanyId, setActiveCompanyId]);
};
