import { addMentionExtensionTags } from './addMentionExtensionTags';
import { cleanupSubjectString } from './cleanupSubjectString';
import { escapeExpressions } from './escapeExpressions';
import { removeMentionExtensionTags } from './removeMentionExtensionTags';
import { removeRootParagraph } from './removeRootParagraph';
import { returnExpressions } from './returnSpecialChar';

export const emailToSupplierHelpers = {
    addMentionExtensionTags,
    removeMentionExtensionTags,
    removeRootParagraph,
    cleanupSubjectString,
    escapeExpressions,
    returnExpressions,
};
