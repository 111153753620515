import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, LOAD_PAGE_DATA } from '../../actions';
import { QBooksContext } from '../../data/qbooks/QBooksContext';

export type QBooksContextType = ImmutableObject<QBooksContext>;

const INITIAL_STATE: QBooksContextType = undefined as any;

export default function (state = INITIAL_STATE, action: Action): QBooksContextType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return immutable(action.payload.qbooksContext!);

        default:
            return state;
    }
}
