import { QueryOptions } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetRequestLegacy } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UseGetFieldsRequestParams, UseGetFieldsResponse } from './useGetFields.types';

export const useGetFields = (params: UseGetFieldsRequestParams, queryOptions?: QueryOptions<UseGetFieldsResponse>) => {
    return useGetRequestLegacy<UseGetFieldsResponse>(companiesApiPaths.selectFields, params, {
        queryOptions,
        mapData: objectHelpers.pascalCaseToCamelCase,
    });
};
