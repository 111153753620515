export interface CreateOrganisationPopupProps {
    closable?: boolean;
    isLoading?: boolean;
    isLoadStepFromLocalStorage?: boolean;
    onClose?: () => void;
    onConnectXero: () => void;
    onConnectQBooks: () => void;
    onConnectNetSuite: () => void;
    onConnectCin7: () => void;
    onConnectStandalone: () => void;
}

export enum CurrentStep {
    Initial = 'Initial',
    Xero = 'Xero',
    QBooks = 'QBooks',
    NetSuite = 'NetSuite',
    Cin7 = 'Cin7',
    QBooksVideo = 'QBooksVideo',
    XeroVideo = 'XeroVideo',
}
