import { backend, domain } from 'modules/data';

export function parseXeroTaxCode(value: backend.IntegrationsXeroTaxCode): domain.XeroTaxCode {
    return {
        id: value.TaxType,
        text: `${value.Name} (${value.TaxRate}%)`,
        applicableToAccountClasses: (value.ApplicableToAccountClasses as any as domain.XeroAccountClass[]) || [],
        rate: value.TaxRate!,
        rateEffective: value.TaxRateEffective!,
    };
}
