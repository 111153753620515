import { useCallback, useState } from 'react';

export const useHideDropdownOnClickItem = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleOpenDropdown = useCallback((newState?: boolean) => {
        setIsDropdownOpen((state) => (newState === undefined ? !state : newState));
    }, []);

    const handleClickMenuItem = (callback?: () => void) => () => {
        toggleOpenDropdown();
        callback && callback();
    };

    return {
        isDropdownOpen,
        toggleOpenDropdown,
        handleClickMenuItem,
    };
};
