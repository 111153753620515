import { atom } from 'recoil';

import { TwoFaEnablingWizardController, WizardStep } from './TwoFaEnablingWizard.types';

export const twoFaWizardStepState = atom<WizardStep>({
    key: 'twoFaWizardStepState',
    default: 'codeScan',
});

export const twoFaWizardControllerState = atom<TwoFaEnablingWizardController>({
    key: 'twoFaWizardControllerState',
    default: {},
});
