import { Text } from '@approvalmax/ui/src/components';
import moment from 'moment';
import { memo } from 'react';

import { DateTimeCellProps } from './DateTimeCell.types';

export const DateTimeCell = memo<DateTimeCellProps>((props) => {
    const { value, className } = props;

    return (
        <Text font='body' ellipsis={1} className={className}>
            {moment(value, 'YYYY-MM-DD HH:mm:ss').format('lll')}
        </Text>
    );
});

DateTimeCell.displayName = 'DateTimeCell';
