import { useCallback, useEffect } from 'react';

import { BackupMethodOptions, fieldNameBackupMethod } from '../../TwoFaEnablingWizard.constants';
import {
    useTwoFaWizardController,
    useTwoFaWizardFormContext,
    useTwoFaWizardStep,
} from '../../TwoFaEnablingWizard.hooks';
import { messages } from './StepBackupMethods.messages';

export const useStepBackupMethods = () => {
    const form = useTwoFaWizardFormContext();
    const { setWizardStep } = useTwoFaWizardStep();
    const { setWizardController } = useTwoFaWizardController();

    const backupMethod = form.watch(fieldNameBackupMethod);

    const handleVerify = useCallback(async () => {
        const isValid = form.trigger();

        if (!isValid) {
            return;
        }

        switch (backupMethod) {
            case BackupMethodOptions.backupCodes:
                setWizardStep('backupCodes');
                break;

            case BackupMethodOptions.backupEmailAddress:
                setWizardStep('backupEmailEnter');
                break;
        }
    }, [backupMethod, form, setWizardStep]);

    useEffect(() => {
        setWizardController({
            title: messages.title,
            onPrev: undefined,
            onNext: handleVerify,
        });
    }, [handleVerify, setWizardController]);
};
