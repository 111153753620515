import { font } from 'modules/styles';
import styled from 'styled-components';

export const Root = styled.div`
    height: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
`;

export const EmptyText = styled.div`
    margin-top: 20px;
    ${font(16, '#000', 'semibold')}
`;
