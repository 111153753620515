import { useCallback } from 'react';
import { createGlobalState } from 'react-use';

import { useGlobalProfileEnableTwoFaSetting } from './TwoFaEnablingWizard.hooks';
import { BackupOption, TwoFaEnablingWizardContext, TwoFaStep } from './TwoFaEnablingWizard.types';

/**
 * @deprecated Old Design
 */
const initialContext: TwoFaEnablingWizardContext = {
    isOpen: false,
    stateId: null,
    setupCode: null,
    email: '',
    emailCode: '',
    authenticatorAppCode: '',
    activeStep: 'codeScan',
};

/**
 * @deprecated Old Design
 */
const useContext = createGlobalState<TwoFaEnablingWizardContext>(initialContext);

/**
 * @deprecated Old Design
 */
export const useTwoFaEnablingWizardContext = () => {
    const [context, setContext] = useContext();

    const enableGlobalProfileTwoFaSetting = useGlobalProfileEnableTwoFaSetting();

    const showWizard = useCallback(
        (onSuccess?: TwoFaEnablingWizardContext['onSuccess']) => {
            setContext((prevContext) => ({ ...prevContext, onSuccess: onSuccess, isOpen: true }));
        },
        [setContext]
    );

    const hideWizard = useCallback(() => {
        setContext((prevContext) => ({ ...prevContext, onSuccess: undefined, isOpen: false }));
    }, [setContext]);

    const setActiveStep = useCallback(
        (activeStep: TwoFaStep) => {
            setContext((prevContext) => ({ ...prevContext, activeStep }));
        },
        [setContext]
    );

    const setSelectedBackupOption = useCallback(
        (selectedBackupOption: BackupOption) => {
            setContext((prevContext) => ({ ...prevContext, selectedBackupOption }));
        },
        [setContext]
    );

    const hideWithSuccessWizard = useCallback(() => {
        context.onSuccess?.(context.selectedBackupOption);

        enableGlobalProfileTwoFaSetting();

        setContext((prevContext) => ({ ...prevContext, onSuccess: undefined, isOpen: false }));
    }, [context, enableGlobalProfileTwoFaSetting, setContext]);

    const resetContext = useCallback(() => {
        setContext(initialContext);
    }, [setContext]);

    return {
        context,
        setContext,
        showWizard,
        hideWizard,
        hideWithSuccessWizard,
        setActiveStep,
        resetContext,
        setSelectedBackupOption,
    };
};
