import { messages } from './CopyWorkflowPopup.messages';
import { TabId } from './CopyWorkflowPopup.types';

export const tabItems = [
    {
        label: messages.copyToSingleWorkflowTab,
        value: TabId.SINGLE_COPY,
    },
    {
        label: messages.copyToMultipleWorkflowsTab,
        value: TabId.MULTIPLE_COPY,
    },
];
