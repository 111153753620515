import { ui } from 'modules/common';
import { font } from 'modules/styles';
import styled from 'styled-components';

export const CollapsedSection = styled.div`
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ebeaea;
    margin-top: 20px;
`;

export const ExpandedSection = styled.div`
    margin-top: 70px;
    padding-bottom: 40px;
    padding-left: 80px;
    padding-right: 60px;

    @media only screen and (max-width: 1250px) {
        margin-top: 10px;
    }
`;

export const Actions = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebeaea;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

export const ActionsText = styled.div`
    flex: 1;
    ${font(16, '#333', 'semibold')}
`;

export const Fields = styled.div`
    margin-top: 30px;
    display: flex;
    margin-right: -20px;

    & > * {
        flex: 1;
        margin-right: 20px;
    }
`;

export const ValidationSection = styled(ui.PasswordValidationSection)`
    margin-top: 30px;
`;
