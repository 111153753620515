import { useCallback, useEffect, useState } from 'react';

import { closedStandalonePromotionStorageKey } from './StandaloneUpgrade.constants';

export const useSaveToLocalStorage = (companyId: string) => {
    const [isClosedPromotion, setIsClosedPromotion] = useState(false);
    const promotionStorageName = `${closedStandalonePromotionStorageKey}.${companyId}`;

    useEffect(() => {
        const value = localStorage.getItem(promotionStorageName);

        if (value === 'true') {
            setIsClosedPromotion(true);
        }
    }, [promotionStorageName]);

    const closePromotion = useCallback(() => {
        localStorage.setItem(promotionStorageName, 'true');
        setIsClosedPromotion(true);
    }, [promotionStorageName]);

    return {
        closePromotion,
        isClosedPromotion,
    };
};
