import { Text } from '@approvalmax/ui/src/components';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useState } from 'react';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import CollapseAllButton from '../CollapseAllButton/CollapseAllButton';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import StepSubmitterSection from '../StepSubmitterSection/StepSubmitterSection';
import XeroExternalSubmitterSection from '../XeroExternalSubmitterSection/XeroExternalSubmitterSection';
import { learnMoreLink } from './XeroPoStartStep.constants';
import { messages } from './XeroPoStartStep.messages';

const XeroPoStartStep = memo(() => {
    const template = useSelector(getActiveTemplate)!;
    const pluralTemplateName = template.displayNamePlural;
    const [collapsedSubmitterSection, setCollapsedSubmitterSection] = useState<boolean>();
    const [collapsedExternalSubmitterSection, setCollapsedExternalSubmitterSection] = useState<boolean>();

    const onCollapse = useCallback((collapsed: boolean) => {
        setCollapsedSubmitterSection(collapsed);
        setCollapsedExternalSubmitterSection(collapsed);
    }, []);

    return (
        <FixedStepContainer bubbleText={messages.bubbleText({ pluralTemplateName })}>
            <Text font='body' fontSize='small' color='midnight70'>
                {messages.title({ pluralTemplateName })}
            </Text>

            <CollapseAllButton
                collapsed={Boolean(collapsedSubmitterSection && collapsedExternalSubmitterSection)}
                onCollapse={onCollapse}
            />

            <StepSubmitterSection
                cacheId='XeroPoStartStepSubmitter'
                headerText={messages.createInApprovalMax}
                description={messages.description({ pluralTemplateName })}
                descriptionEmpty={messages.descriptionEmpty({ pluralTemplateName })}
                collapsed={collapsedSubmitterSection}
                onCollapse={setCollapsedSubmitterSection}
                required
            />

            <XeroExternalSubmitterSection
                onCollapse={setCollapsedExternalSubmitterSection}
                collapsed={collapsedExternalSubmitterSection}
                cacheId='XeroPoStartStepExternalSubmitter'
                description={messages.externalSubmitterDescriptionNonEmpty({ templateName: template.displayName })}
                descriptionEmpty={messages.externalSubmitterDescriptionEmpty({ templateName: template.displayName })}
                learnMoreLink={learnMoreLink}
            />
        </FixedStepContainer>
    );
});

export default XeroPoStartStep;
