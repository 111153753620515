import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { requestsApiPaths, useGetRequest } from 'shared/data';

import {
    UseGetRequestDuplicatesQbooksBillResponse,
    UseGetRequestDuplicatesQbooksParams,
    UseGetRequestDuplicatesQbooksPathParams,
} from './useGetRequestDuplicatesQbooks.types';

export const useGetRequestDuplicatesQbooks = (
    pathParams: UseGetRequestDuplicatesQbooksPathParams,
    params: QueryParams<UseGetRequestDuplicatesQbooksParams>,
    queryOptions?: QueryOptions<UseGetRequestDuplicatesQbooksBillResponse>
) => {
    return useGetRequest<UseGetRequestDuplicatesQbooksBillResponse>(requestsApiPaths.duplicatesQbookPO, {
        pathParams,
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<UseGetRequestDuplicatesQbooksParams>(params, queryOptions),
        },
        apiVersion: 'v2',
    });
};
