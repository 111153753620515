import { Grid, Spacing } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { OverflowArea } from './ScrollableContent.styles';
import { ScrollableContentProps } from './ScrollableContent.types';

export const ScrollableContent: FC<ScrollableContentProps> = memo((props) => {
    const { header, footer, children } = props;

    return (
        <Grid
            height='calc(100vh - 220px)'
            minHeight='550px'
            gridTemplateRows='max-content auto max-content max-content'
        >
            <div>{header}</div>

            <OverflowArea>{children}</OverflowArea>

            <Spacing height={44} />

            <div>{footer}</div>
        </Grid>
    );
});

ScrollableContent.displayName = 'ScrollableContent';
