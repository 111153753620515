import { domain } from 'modules/data';

export type SsoPanelVariant = 'signin' | 'signup';

export const enum ButtonType {
    Xero,
    Intuit,
    Google,
    Microsoft,
}

export interface SsoPanelProps {
    variant: SsoPanelVariant;
    app?: domain.SsoLoginApplication;
    afterLoginRedirectUrl?: string;
    afterSignUpRedirectUrl?: string;
    shouldReturnToCurrentPage?: boolean;
    disabled?: boolean;
    className?: string;
    caption?: string;
    allowedEmail?: string;
    width?: number | string;
    vertical?: boolean;
    showOnlyButtons?: ButtonType[];
    buttonHeight?: number;
    withWhiteButtons?: boolean;
    showPasskey?: boolean;
    onSubmit?: (options: { oauthProvider: domain.OAuthProvider; startingPoint: domain.SsoStartingPoint }) => void;
    onError?: (err: unknown) => void;
}
