import { Text, TextField } from '@approvalmax/ui/src/components';
import { SearchIcon } from '@approvalmax/ui/src/icons';
import { FC, memo } from 'react';

import { Root } from './UserConditionHeader.styles';
import { UserConditionHeaderProps } from './UserConditionHeader.types';

const UserConditionHeader: FC<UserConditionHeaderProps> = memo((props) => {
    const { title, withFilter, filter, setFilter } = props;

    return (
        <Root width='236px' spacing={withFilter ? '8 12 8 40' : '12 12 12 40'}>
            {withFilter ? (
                <TextField
                    value={filter}
                    onChange={setFilter}
                    placeholder={`${title}...`}
                    size='small'
                    startIcon={<SearchIcon />}
                />
            ) : (
                <Text font='body' fontSize='small' color='midnight80' fontWeight='medium'>
                    {title}
                </Text>
            )}
        </Root>
    );
});

export default UserConditionHeader;
