/**
 * Formats a number of bytes into a human-readable string with the specified decimals.
 * Converts bytes to the largest appropriate unit (Bytes, KB, MB, GB).
 * Example: formatBytes(1024) returns '1 KB'.
 */
export const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'Kb', 'Mb', 'Gb'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
