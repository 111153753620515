import { useMemo } from 'react';
import { routingService } from 'services/routing';

import { useRoutesPaths } from './useRoutesPaths';

export const useRoutes = () => {
    const authRoutes = useRoutesPaths(routingService.authRoutes, routingService.authRoutesRedesign);
    const publicRoutes = useRoutesPaths(routingService.publicRoutes, routingService.publicRoutesRedesign);
    const workspaceRoutes = useRoutesPaths(
        routingService.workspaceRoutes,
        routingService.workspaceRoutesRedesign,
        true
    );

    const routerBasic = useMemo(() => {
        return {
            routes: [...routingService.workspaceRoutes, ...routingService.authRoutes, ...routingService.publicRoutes],
            paths: [...workspaceRoutes.pathsBasic, ...authRoutes.pathsBasic, ...publicRoutes.pathsBasic],
        };
    }, [authRoutes.pathsBasic, publicRoutes.pathsBasic, workspaceRoutes.pathsBasic]);

    const routerRedesign = useMemo(() => {
        return {
            routes: [
                ...routingService.workspaceRoutesRedesign,
                ...routingService.authRoutesRedesign,
                ...routingService.publicRoutesRedesign,
            ],
            paths: [...workspaceRoutes.pathsRedesign, ...authRoutes.pathsRedesign, ...publicRoutes.pathsRedesign],
        };
    }, [authRoutes.pathsRedesign, publicRoutes.pathsRedesign, workspaceRoutes.pathsRedesign]);

    return { routerBasic, routerRedesign };
};
