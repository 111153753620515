import { domain } from 'modules/data';

import { messages } from './CollectAccountNurturingDataPopup.messages';
import { OrganisationCountReferenceOption } from './CollectAccountNurturingDataPopup.types';

export const OrganisationCountOptions: OrganisationCountReferenceOption[] = [
    {
        id: domain.OrganisationCountOption.LessThanThree,
        text: messages.lessThanThree,
        value: domain.OrganisationCountOption.LessThanThree,
    },
    {
        id: domain.OrganisationCountOption.ThreeToTen,
        text: messages.threeToTen,
        value: domain.OrganisationCountOption.ThreeToTen,
    },
    {
        id: domain.OrganisationCountOption.MoreThanTen,
        text: messages.moreThanTen,
        value: domain.OrganisationCountOption.MoreThanTen,
    },
];
