import { RequestDataParams } from '@approvalmax/data';
import { domain } from 'modules/data';
import { mapCompany } from 'modules/data/domain/schemas/companySchema';
import { useMutateLegacy } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UseCreateCompanyBackendResponse, UseCreateCompanyData } from './useCreateCompany.types';

export const useCreateCompany = () => {
    return useMutateLegacy<RequestDataParams<UseCreateCompanyData>, UseCreateCompanyBackendResponse, domain.Company>(
        companiesApiPaths.create,
        {
            mapData: (data) => mapCompany(data.Companies[0]),
        }
    );
};
