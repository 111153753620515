import { selectors } from 'modules/common';
import { domain } from 'modules/data';

import { ActiveWorkflow } from '../../types/activeWorkflow';

export const useIgMigrationAvailable = (template: ActiveWorkflow, company: selectors.types.ExpandedCompany) => {
    const isXeroBill = template?.integrationCode === domain.IntegrationCode.XeroBill;
    const isReviewerV1Available = isXeroBill && company?.betaFeatures.includes(domain.CompanyBetaFeature.ReviewerV1);
    const hasEditingOnApprovalBeta =
        company?.betaFeatures.includes(domain.CompanyBetaFeature.EditingOnApproval) ||
        company?.licenseFeatures.includes(domain.CompanyLicenseFeature.EditingOnApproval);
    const isEditingOnApprovalAvailable = company && !company.flags.isAuditor && hasEditingOnApprovalBeta;

    return isReviewerV1Available && isEditingOnApprovalAvailable;
};
