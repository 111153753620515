import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.NetSuiteExternalSubmitterSection', {
    bubbleText: 'Pulling from Oracle NetSuite',
    description:
        '{pluralTemplateName} in the "Pending Approval" status will be automatically pulled from Oracle NetSuite.',
    externalSubmitterDescription:
        '{pluralTemplateName} will be created in ApprovalMax under the name of this User. They will be notified about approvals, rejections, and comments.',
    externalSubmitterDescriptionEmpty:
        'Enter the user who will be notified about {templateName} status changes in ApprovalMax. A generic email address, e.g. finance@mycompany.com, can also be used.',
    learnMore: 'Learn more',
    externalSubmitterRequiredValidationText: 'Please select a Requester',
    chooseRequester: 'Choose a Requester',
});
