import { immutable, ImmutableObject, merge } from 'modules/immutable';

import {
    Action,
    CANCEL_ACTIVE_POPUP,
    SHOW_PRINT_PREVIEW,
    SHOW_PRINT_PREVIEW_FAILURE,
    SHOW_PRINT_PREVIEW_RESPONSE,
} from '../../actions';
import { ReportConfig } from '../../data/reportConfig/ReportConfig';
import { ReportRowData } from '../../data/ReportData';

export const PRINT_PREVIEW_POPUP_ID = 'REPORTS/PRINT_PREVIEW';

export interface PrintPreviewPopupData {
    id: typeof PRINT_PREVIEW_POPUP_ID;
    reportConfig: ReportConfig;
    loading: boolean;
    rows: ReportRowData[];
}

export type PrintPreviewPopupStateType = ImmutableObject<PrintPreviewPopupData> | null;

export default function (state: PrintPreviewPopupStateType, action: Action): PrintPreviewPopupStateType {
    switch (action.type) {
        case SHOW_PRINT_PREVIEW:
            return immutable<PrintPreviewPopupData>({
                id: PRINT_PREVIEW_POPUP_ID,
                reportConfig: action.payload.reportConfig,
                loading: true,
                rows: [],
            });

        case SHOW_PRINT_PREVIEW_RESPONSE:
            if (!state) {
                // Loading cancelled
                return state;
            }

            return merge(state, {
                loading: false,
                rows: action.payload.rows,
            });

        case SHOW_PRINT_PREVIEW_FAILURE:
        case CANCEL_ACTIVE_POPUP:
            return null;

        default:
            return state;
    }
}
