import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('profile.ProfilePopup.BackupOptionsSection', {
    backupCodes: 'Backup codes',
    backupEmail: 'Backup email',
    edit: 'Edit',
    add: 'Add',
    addBackupEmailTitle: 'Add backup email address',
    editBackupEmailTitle: 'Update backup email address',
});
