import { RawAvatar } from '@approvalmax/ui/src/old/ui';
import { forwardRef, KeyboardEvent, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { getAbbreviation } from './MentionList.helpers';
import { Item, Name, Root } from './MentionList.styles';
import { MentionListItem, MentionListProps, MentionListRef } from './MentionList.types';

const MentionList = forwardRef<MentionListRef, MentionListProps>((props, ref) => {
    const { command, items } = props;

    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = useCallback(
        (index: number) => {
            const { userId, email, name, avatar } = items[index];

            if (userId || email) {
                command({ email, userId, name, avatar });
            }
        },
        [command, items]
    );

    const upHandler = useCallback(() => {
        setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    }, [items.length, selectedIndex]);

    const downHandler = useCallback(() => {
        setSelectedIndex((selectedIndex + 1) % items.length);
    }, [items.length, selectedIndex]);

    const enterHandler = useCallback(() => {
        selectItem(selectedIndex);
    }, [selectItem, selectedIndex]);

    useEffect(() => setSelectedIndex(0), [items]);

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }: { event: KeyboardEvent }) => {
            if (event.key === 'ArrowUp') {
                upHandler();

                return true;
            }

            if (event.key === 'ArrowDown') {
                downHandler();

                return true;
            }

            if (event.key === 'Enter') {
                enterHandler();

                return true;
            }

            return false;
        },
    }));

    return (
        <Root>
            {items.length ? (
                items.map((item: MentionListItem, index: number) => {
                    const abbreviation = getAbbreviation(item.name);

                    return (
                        <Item active={index === selectedIndex} key={index} execute={() => selectItem(index)}>
                            <RawAvatar
                                userAbbreviation={abbreviation}
                                userAvatarUrl={item.avatar}
                                size={20}
                                title={item.name}
                            />

                            <Name>{item.name}</Name>
                        </Item>
                    );
                })
            ) : (
                <Item>No result</Item>
            )}
        </Root>
    );
});

export default MentionList;
