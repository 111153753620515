import { Button, font, oPopupPadding, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled(Popup.DefaultContent)`
    width: 610px;
`;

export const Content = styled.div`
    ${oPopupPadding()}
    display: flex;
    flex-flow: column;
`;

export const ConfirmButton = styled(Button)`
    margin-top: 30px;
`;

export const Note = styled.div`
    ${font(13, '#000', 'standard')}
    margin-top: 20px;
`;
