import { domain } from 'modules/data';
import { useCallback, useState } from 'react';

export const useSelectIntegrationType = () => {
    const [integrationType, setIntegrationType] = useState<domain.IntegrationType>(domain.IntegrationType.Xero);

    const onSelectXero = useCallback(() => {
        setIntegrationType(domain.IntegrationType.Xero);
    }, []);

    const onSelectQbooks = useCallback(() => {
        setIntegrationType(domain.IntegrationType.QBooks);
    }, []);

    const onSelectNetSuite = useCallback(() => {
        setIntegrationType(domain.IntegrationType.NetSuite);
    }, []);

    const onSelectDear = useCallback(() => {
        setIntegrationType(domain.IntegrationType.Dear);
    }, []);

    const onSelectStandAlone = useCallback(() => {
        setIntegrationType(domain.IntegrationType.None);
    }, []);

    return {
        integrationType,
        onSelectXero,
        onSelectQbooks,
        onSelectNetSuite,
        onSelectDear,
        onSelectStandAlone,
    };
};
