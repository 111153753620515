import { errorHelpers } from '@approvalmax/utils';
import { schema } from 'normalizr';

import * as backend from '../../backend';
import { getBeautifiedEntity } from '../../utils';
import {
    NextBillingCurrency,
    Subscription,
    SubscriptionBillingCycle,
    SubscriptionBillingSystem,
    SubscriptionStatus,
} from '../Subscription';
import { mapCompanyPlan, mapCompanyPlanEdition } from './companySchema';
import userSchema from './userSchema';

function mapSubscriptionStatus(value: backend.AccountsSubscriptionStatus) {
    switch (value) {
        case backend.AccountsSubscriptionStatus.Active:
            return SubscriptionStatus.Active;

        case backend.AccountsSubscriptionStatus.Canceled:
            return SubscriptionStatus.Canceled;

        case backend.AccountsSubscriptionStatus.Expired:
            return SubscriptionStatus.Expired;

        case backend.AccountsSubscriptionStatus.Grace:
            return SubscriptionStatus.Grace;

        case backend.AccountsSubscriptionStatus.PendingCancellation:
            return SubscriptionStatus.PendingCancellation;

        default:
            throw errorHelpers.assertNever(value);
    }
}

function mapSubscriptionBillingCycle(value: backend.AccountsSubscriptionBillingCycle) {
    switch (value) {
        case backend.AccountsSubscriptionBillingCycle.Annually:
            return SubscriptionBillingCycle.Annually;

        case backend.AccountsSubscriptionBillingCycle.Monthly:
            return SubscriptionBillingCycle.Monthly;

        case backend.AccountsSubscriptionBillingCycle.OneTime:
            return SubscriptionBillingCycle.OneTime;

        case backend.AccountsSubscriptionBillingCycle.BiAnnually:
            return SubscriptionBillingCycle.BiAnnually;

        case backend.AccountsSubscriptionBillingCycle.Quarterly:
            return SubscriptionBillingCycle.Quarterly;

        default:
            throw errorHelpers.assertNever(value);
    }
}

function mapSubscriptionBillingSystem(value: backend.AccountsSubscriptionBillingSystem) {
    switch (value) {
        case backend.AccountsSubscriptionBillingSystem.None:
            return SubscriptionBillingSystem.None;

        case backend.AccountsSubscriptionBillingSystem.Direct:
            return SubscriptionBillingSystem.Direct;

        case backend.AccountsSubscriptionBillingSystem.Avangate:
            return SubscriptionBillingSystem.Avangate;

        case backend.AccountsSubscriptionBillingSystem.Stripe:
            return SubscriptionBillingSystem.Stripe;

        case backend.AccountsSubscriptionBillingSystem.XeroAppStore:
            return SubscriptionBillingSystem.XeroAppStore;

        default:
            throw errorHelpers.assertNever(value);
    }
}

function mapSubscriptionNextBillingCurrency(value: backend.AccountsSubscriptionNextBillingCurrency) {
    switch (value) {
        case backend.AccountsSubscriptionNextBillingCurrency.AUD:
            return NextBillingCurrency.AUD;

        case backend.AccountsSubscriptionNextBillingCurrency.EUR:
            return NextBillingCurrency.EUR;

        case backend.AccountsSubscriptionNextBillingCurrency.GBP:
            return NextBillingCurrency.GBP;

        case backend.AccountsSubscriptionNextBillingCurrency.NZD:
            return NextBillingCurrency.NZD;

        case backend.AccountsSubscriptionNextBillingCurrency.SGD:
            return NextBillingCurrency.SGD;

        case backend.AccountsSubscriptionNextBillingCurrency.USD:
            return NextBillingCurrency.USD;

        case backend.AccountsSubscriptionNextBillingCurrency.ZAR:
            return NextBillingCurrency.ZAR;

        default:
            throw errorHelpers.assertNever(value);
    }
}

function mapSubscription(value: backend.AccountsSubscriptionAnswer): Subscription {
    return {
        id: value.Id,
        systemId: value.SystemId,
        accountOwnerId: value.AccountOwner as any, // schema parsing
        addonStatistics: value.AddonStatistics.map((statistics) => getBeautifiedEntity(statistics)),
        billingCycle: mapSubscriptionBillingCycle(value.BillingCycle),
        billingSystem: mapSubscriptionBillingSystem(value.BillingSystem),
        edition: mapCompanyPlanEdition(value.Edition),
        dueDate: value.DueDate || null,
        nextBillingDate: value.NextBillingDate || null,
        nextBillingCurrency: value.NextBillingCurrency
            ? mapSubscriptionNextBillingCurrency(value.NextBillingCurrency)
            : null,
        plan: mapCompanyPlan(value.Plan),
        status: mapSubscriptionStatus(value.Status),
        quantity: value.Quantity,
        capacity: value.Capacity,
        companyIds: [], // Currently filled in externally (when parsing getUserContext), should come from server,
        isFrozenForUpgrade: value.IsFrozenForUpgrade,
    };
}

export default new schema.Entity(
    'subscriptions',
    {
        accountOwnerId: userSchema,
    },
    {
        idAttribute: 'Id',
        processStrategy: mapSubscription,
    }
);
