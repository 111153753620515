import { ExtractComponentProp } from '@approvalmax/types';
import { Box, Checkbox, Form } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { addSubmitter, removeSubmitter } from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/actions';
import { getTemplateSubmitters } from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/selectors/templateSelectors';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { FC, memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { amplitudeService } from 'services/amplitude';

import { FooterButtons, HeaderTitle, ScrollableContent } from '..';
import { requestersFieldName } from './RequestersStep.constants';
import { messages } from './RequestersStep.messages';
import { schema } from './RequestersStep.schema';
import { FormValues, RequestersStepProps } from './RequestersStep.types';

export const RequestersStep: FC<RequestersStepProps> = memo((props) => {
    const { template, defaultRequesters, amplitudeParams, onNext, onBack } = props;

    const dispatch = useDispatch();
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, template.companyId));
    const team = useSelector((state: State) => selectors.company.getCompanyTeam(state, company));
    const submitters = useSelector((state: State) => getTemplateSubmitters(state, company.id));

    const form = useForm<FormValues>({
        defaultValues: {
            [requestersFieldName]: defaultRequesters ?? [],
        },
        resolver: zodResolver(schema),
    });

    useMount(() => {
        amplitudeService.sendData('Workflow: land on wizard: requesters step', amplitudeParams);
    });

    const handleNext = form.handleSubmit((data) => {
        submitters.forEach((user) => {
            dispatch(removeSubmitter(user.id, user.databaseId));
        });

        data[requestersFieldName].forEach((user) => {
            dispatch(addSubmitter(user));
        });

        onNext(data[requestersFieldName]);
    });

    const requesters = form.watch(requestersFieldName);
    const partiallyChecked = requesters?.length > 0 && requesters?.length < team.length;
    const someChecked = requesters?.length > 0;

    const checkAllRequesters = useCallback<ExtractComponentProp<typeof Checkbox, 'onChange'>>(
        (value) => {
            form.setValue(requestersFieldName, value ? team.map((user) => user.id) : [], { shouldValidate: true });
        },
        [form, team]
    );

    return (
        <ScrollableContent
            header={<HeaderTitle spacing='32 0' title={messages.title} subtitle={messages.subtitle} />}
            footer={<FooterButtons isNextDisabled={!form.formState.isValid} onBack={onBack} onNext={handleNext} />}
        >
            {team.length > 1 && (
                <Box spacing='0 0 16'>
                    <Checkbox
                        checked={someChecked}
                        indeterminate={partiallyChecked}
                        onChange={checkAllRequesters}
                        size='large'
                    >
                        {messages.allUsers}
                    </Checkbox>
                </Box>
            )}

            <Form form={form}>
                <Checkbox.Group name={requestersFieldName} direction='column' size='large' invalid={false}>
                    {team.map((user) => (
                        <Checkbox key={user.id} value={user.id}>
                            {user.displayName}
                        </Checkbox>
                    ))}
                </Checkbox.Group>
            </Form>
        </ScrollableContent>
    );
});

RequestersStep.displayName = 'RequestersStep';
