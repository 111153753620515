import { stateTree } from 'modules/data';
import { ImmutableObject, updateWithout } from 'modules/immutable';

import { Action, ADD_REQUEST_COMMENT_RESPONSE } from '../actions';

export const COMMENT_TEXT_USER_PREFERENCE = 'requestList/AddCommentSection/commentText';

export default function (
    state: ImmutableObject<stateTree.UserPreferences>,
    action: Action
): ImmutableObject<stateTree.UserPreferences> {
    switch (action.type) {
        case ADD_REQUEST_COMMENT_RESPONSE:
            if (!state[COMMENT_TEXT_USER_PREFERENCE]) {
                return state;
            }

            return updateWithout(state, COMMENT_TEXT_USER_PREFERENCE, action.payload.requestId);

        default:
            return state;
    }
}
