import { useAccess as useCustomerFeedbackAvailable } from 'app/(workspace)/customerFeedback/resources/hooks/useAccess';
import { selectors } from 'modules/common';
import { backend, domain } from 'modules/data';
import { useUserProfile } from 'modules/utils';
import { useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useSelector } from 'react-redux';
import { amplitudeService } from 'services/amplitude';
import { useCompanies } from 'shared/components/Drawer/Drawer.hooks';
import { AccountPath, getAccountUrl, getDeveloperPortalUrl, getPartnerUrl, getPath, Path } from 'urlBuilder';

import { messages } from './UserCommonMenu.messages';

const qa = bemFactory.qa('page-nav-bar');

export const useMenuItems = () => {
    const isMyAccountAvailable = useMyAccountAvailable();
    const isPartnerAvailable = usePartnerAvailable();
    const isFeedbackAvailable = useCustomerFeedbackAvailable();
    const isPracticeStaffAvailable = usePracticeStaffAvailable();
    const isConnectionsListAvailable = useConnectionsListAvailable();

    const sendAmplitudeForFeedback = useCallback(() => {
        amplitudeService.sendData('navigation: click on canny feedback portal', {
            'action source': 'help dropdown',
        });
    }, []);

    return [
        {
            id: 'account',
            name: messages.accountName,
            available: isMyAccountAvailable,
            href: getAccountUrl(AccountPath.root, { origin: 'wa_menu_items' }),
            title: messages.accountTitle,
            amplitudeEventName: 'navigation: view billing and subscriptions',
            dataQa: qa('workspace-link'),
        },
        {
            id: 'devportal',
            name: messages.developerName,
            available: true,
            href: getDeveloperPortalUrl(),
            title: messages.developerTitle,
            amplitudeEventName: 'navigation: view developer portal',
            dataQa: qa('workspace-link'),
        },
        {
            id: 'publicapiconnectionslist',
            name: messages.publicApiConnectionsListName,
            available: isConnectionsListAvailable,
            href: getAccountUrl(AccountPath.publicApiConnectionsList),
            title: messages.publicApiConnectionsListTitle,
            amplitudeEventName: 'navigation: view public api connections list',
            dataQa: qa('workspace-link'),
        },
        {
            id: 'partner',
            name: messages.partnerName,
            available: isPartnerAvailable,
            href: getPartnerUrl(),
            title: messages.partnerTitle,
            dataQa: qa('workspace-link'),
        },
        {
            id: 'feedback',
            name: messages.customerFeedbackName,
            available: isFeedbackAvailable,
            to: getPath(Path.customerFeedback),
            onClick: sendAmplitudeForFeedback,
            title: messages.customerFeedbackTitle,
            dataQa: qa('feedback-link'),
        },
        {
            id: 'practiceStaff',
            name: messages.practiceStaffName,
            available: isPracticeStaffAvailable,
            href: getAccountUrl(AccountPath.practiceStaff),
            title: messages.practiceStaffTitle,
            dataQa: qa('feedback-link'),
        },
    ].filter(({ available }) => available ?? true);
};

const useMyAccountAvailable = () => {
    const profile = useUserProfile();

    const companies = useSelector(selectors.company.getCompanies);
    const invitations = useSelector(selectors.companyInvitation.getPendingCompanyInvitations);

    const isAccountOwnerOrManager =
        profile.account &&
        [domain.AccountMemberRole.Administrator, domain.AccountMemberRole.Manager].includes(profile.account.userRole!);
    const isMemberOrUserManager =
        profile.account?.userRole === domain.AccountMemberRole.Member ||
        profile.account?.userRole === domain.AccountMemberRole.UserManager;
    const isPotentialAccountOwner = !companies.length && !invitations.length && !isMemberOrUserManager;

    return isAccountOwnerOrManager || isPotentialAccountOwner;
};

const usePartnerAvailable = () => {
    const profile = useUserProfile();

    return profile.flags.hasAccessToPartnerPortalPage;
};

const usePracticeStaffAvailable = () => {
    const profile = useUserProfile();

    const requiredAccountTypes = [backend.AccountType.Partner, backend.AccountType.ReceiptBankPartner];
    const requiredUserRoles = [
        domain.AccountMemberRole.Administrator,
        domain.AccountMemberRole.Manager,
        domain.AccountMemberRole.UserManager,
    ];

    return Boolean(
        profile.account &&
            requiredAccountTypes.includes(profile.account.accountType) &&
            requiredUserRoles.some((userRole) => userRole === profile?.account?.userRole)
    );
};

const useConnectionsListAvailable = () => {
    const profile = useUserProfile();
    const { company } = useCompanies();

    return Boolean(profile.account?.accountId || company?.myRole === domain.CompanyUserRole.Manager);
};
