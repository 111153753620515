import { ApiProvider } from '@approvalmax/data';
import { ThemeProvider } from '@approvalmax/theme';
import { intl } from '@approvalmax/utils';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { actions, globalQueryClient } from 'modules/common';
import { persistor, store } from 'modules/store';
import { ComponentType, useCallback } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { PersistGate } from 'redux-persist/integration/react';

import { GlobalErrorBoundary, Head } from '../components';

export const withProviders = (App: ComponentType) => {
    return () => {
        const dispatch = store.dispatch;

        const getUserConfirmation = useCallback(
            (_message: string, callback: (proceed: boolean) => void) => {
                dispatch(
                    actions.showDiscardChangesPopup({
                        unsavedChangesConfirmationCallback: callback,
                    })
                );
            },
            [dispatch]
        );

        return (
            <RecoilRoot>
                <RecoilNexus />

                <ThemeProvider>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <IntlProvider locale={intl.locale} messages={window.localizationMap}>
                                <ApiProvider>
                                    <QueryClientProvider client={globalQueryClient}>
                                        <BrowserRouter getUserConfirmation={getUserConfirmation}>
                                            <Head />

                                            <GlobalErrorBoundary>
                                                <App />
                                            </GlobalErrorBoundary>
                                        </BrowserRouter>

                                        <ReactQueryDevtools initialIsOpen={false} />
                                    </QueryClientProvider>
                                </ApiProvider>
                            </IntlProvider>
                        </PersistGate>
                    </Provider>
                </ThemeProvider>
            </RecoilRoot>
        );
    };
};
