/**
 * This enum ServerConditionType is used for scenarios where there is an optional checkbox for "allow to create objects"
 * such as contacts, vendors, and AWX beneficiaries. But names like SuppliersOnly, CustomersOnly, and AllContacts
 * may not be the best names to represent these entities.
 */
export enum ServerConditionType {
    SuppliersOnly = 0,
    CustomersOnly = 1,
    AllContacts = 2,
}
