import { Button, Flex, List, Text } from '@approvalmax/ui/src/components';
import { actions } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';

import { messages } from './Invitations.messages';
import { InvitationsProps } from './Invitations.types';

const qa = bemFactory.qa('invitation-menu-panel');

export const Invitations: FC<InvitationsProps> = memo((props) => {
    const { invitations } = props;

    const dispatch = useDispatch();

    const onResponse = (invitation: domain.CompanyInvitation, response: domain.CompanyInvitationResponse) => {
        dispatch(
            actions.respondToInvitation([
                {
                    invitation,
                    response,
                },
            ])
        );
    };

    return (
        <List data-qa={qa('panel')} divider spacing='16 0' removeLastDivider removeLastSpacing>
            {invitations.map((invitation) => (
                <List.Item key={invitation.id}>
                    <Text font='body' fontWeight='bold' fontSize='small' spacing='0 0 8'>
                        {invitation.companyName}
                    </Text>

                    <Text font='body' fontSize='small' color='midnight70' spacing='0 0 16'>
                        {messages.invitedBy({
                            user: invitation.inviterUser.displayName,
                        })}
                    </Text>

                    <Flex inline>
                        <Button
                            size='small'
                            onClick={() => onResponse(invitation, domain.CompanyInvitationResponse.Declined)}
                            color='red40'
                            data-qa={qa('decline-button')}
                        >
                            {messages.declineAction}
                        </Button>

                        <Button
                            size='small'
                            onClick={() => onResponse(invitation, domain.CompanyInvitationResponse.Accepted)}
                            color='green80'
                            data-qa={qa('accept-button')}
                        >
                            {messages.acceptAction}
                        </Button>
                    </Flex>
                </List.Item>
            ))}
        </List>
    );
});

Invitations.displayName = 'Invitations';
