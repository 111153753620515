import { Box, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { messages } from './EditingPermissionHeader.messages';

export const EditingPermissionHeader = memo(() => {
    return (
        <Box width='172px' spacing='12 0'>
            <Text font='body' fontSize='small' color='midnight80' fontWeight='medium'>
                {messages.editingPermission}
            </Text>
        </Box>
    );
});

EditingPermissionHeader.displayName = 'EditingPermissionHeader';
