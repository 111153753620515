import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.TwoFaSoftEnforcementTool.SoftEnforcementStart', {
    title: 'Set up two-factor authentication for enhanced security',
    descriptionAdministratorEnforcement:
        'Due to the new Xero requirements, we need you to set up two-factor authentication on your account. It is not mandatory now, but soon you will not be able to use ApprovalMax without it. Two-factor authentication provides an extra layer of protection for your account and your organisation. We strongly recommend setting it up as soon as possible.',
    descriptionCompanyEnforcement:
        'Your organisation’s administrator has decided to enable two-factor authentication (2FA) for the whole organisation. This means you need to set up two-factor authentication on your account. It is not mandatory now, but soon you will not be able to use ApprovalMax without it. Two-factor authentication provides an extra layer of protection for your account and your organisation. We strongly recommend setting it up as soon as possible.',
    button: 'Set up Two-Factor Authentication',
    moreInformation:
        'For more information, please, contact your administrator or our <supportlink>support team</supportlink>.',
});
