import { domain } from 'modules/data';
import { ElementFactory } from 'react-bem-factory';

import { ChangeHandler } from '../../WorkflowSettingsPopupContent.types';

export interface SectionNetSuiteAmaxUrlProps {
    templateSettings: domain.TemplateSettings;
    onChange: ChangeHandler<boolean>;
    qa: ElementFactory;
    readonly: boolean;
    learnMoreLink?: string;
}

export enum NetSuiteAmaxUrlOption {
    AfterApproval = 'AfterApproval',
    AfterSync = 'AfterSync',
}
