import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { defineMessages } from 'react-intl';

import { MatrixType } from '../types/matrix';
import {
    getFilteredDefinitions,
    InternalMatrixDefinitionMap,
    MatrixDefinition,
    renderAmountCell,
    renderDefaultHeader,
    renderExactAsyncCell,
} from './matrixDefinitions.shared';

const i18nPrefix = 'app.WorkflowId.config.matrixDefinitions.dear';

const messages = defineMessages({
    approvalMatrixAmountHeaderText: {
        id: `${i18nPrefix}.approvalMatrixAmountHeaderText`,
        defaultMessage: 'Total amount',
    },
});

const PurchaseOrderFields: MatrixDefinition['columns'] = [
    {
        systemPurpose: domain.FieldSystemPurpose.Amount,
        renderHeader: (options) =>
            renderDefaultHeader(options, {
                fieldName: intl.formatMessage(messages.approvalMatrixAmountHeaderText),
            }),
        renderCell: renderAmountCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearSupplier,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearAccount,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearProduct,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearProductCategory,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearProductFamily,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearBrand,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearLocation,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
];

export function getDearMatrixDefinitions(options: {
    betaFeatures: domain.CompanyBetaFeature[];
    integrationCode: domain.IntegrationCode | null;
    matrixType: MatrixType;
}) {
    const matrixDefinitions: InternalMatrixDefinitionMap = {
        [domain.IntegrationCode.DearPo]: {
            [MatrixType.Approval]: { columns: PurchaseOrderFields },
            [MatrixType.AutoApproval]: { columns: PurchaseOrderFields },
        },
    };

    return getFilteredDefinitions(matrixDefinitions, options);
}
