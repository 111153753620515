import { Mods, mods } from '@approvalmax/theme';
import { TransparentButton } from '@approvalmax/ui/src/old/ui';
import styled from 'styled-components';

export const Root = styled.div`
    background: #fff;
    box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.05),
        0 10px 20px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
    font-size: 12px;
    overflow: hidden auto;
    position: relative;
    max-height: 300px;
`;

export const Item = styled(TransparentButton)<Mods<'active'>>`
    min-height: 40px;
    align-items: center;
    background: transparent;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    margin: 0;
    text-align: left;
    width: 100%;
    word-break: break-word;
    padding: 4px 8px;

    &:hover {
        background-color: #f7f6f6;
    }

    ${mods.active.true`
        background-color: #f3f1f1;
    `};
`;

export const Name = styled.div`
    margin-left: 8px;
`;
