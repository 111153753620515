import { AddIcon } from '@approvalmax/ui';
import { Button } from '@approvalmax/ui/src/components';
import { loadNewRequestPopup } from 'modules/request';
import { memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';

import { messages } from './NewRequest.messages';

const qa = bemFactory.qa('page-nav-bar');

const NewRequest = memo(() => {
    const dispatch = useDispatch();

    const showNewRequestPopup = useCallback(() => dispatch(loadNewRequestPopup()), [dispatch]);

    return (
        <Button
            onClick={showNewRequestPopup}
            color='blue10'
            size='small'
            startIcon={<AddIcon size='12' />}
            data-starter-guide='decision_required_create_new'
            data-qa={qa('new-request-button')}
        >
            {messages.newRequestButtonText}
        </Button>
    );
});

export default NewRequest;
