import moment, { Moment } from 'moment/moment';

import { errorHelpers } from '../error';

/**
 *
 * @param value The date to be formatted (as a moment or string). If it's a falsy value, an empty string will be returned.
 * @param format the format as defined below.
 * - `DateMonthYear`: "4 Sep 1986"
 * - `DayAndMonth` : "31 Mar"
 */
export const formatDate = (
    value: Moment | string | null | undefined | moment.MomentInput,
    format: 'DateMonthYear' | 'DayAndMonth' = 'DateMonthYear'
) => {
    if (!value) {
        return '';
    }

    let momentFormat;

    switch (format) {
        case 'DateMonthYear':
            momentFormat = 'll';
            break;

        case 'DayAndMonth':
            momentFormat = 'D MMM';
            break;

        default:
            throw errorHelpers.assertNever(format);
    }

    return moment(value).format(momentFormat);
};
