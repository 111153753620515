import { Button, QBooksLogo } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { integrationActions, IntegrationSuccessRedirectPage } from 'modules/integration';
import { useSelector } from 'modules/react-redux';
import { Cin7Logo, OracleNetsuiteLogo, XeroGreyIcon } from 'modules/sprites';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { messages } from './DisconnectedCompany.messages';
import {
    DisconnectedCompanyContent,
    DisconnectedCompanyError,
    DisconnectedCompanyIcon,
    DisconnectedCompanyItem,
    DisconnectedCompanyReconnectBlock,
    DisconnectedCompanyTitle,
} from './DisconnectedCompany.styles';
import { DisconnectedCompanyProps } from './DisconnectedCompany.types';

const DisconnectedCompany: FC<DisconnectedCompanyProps> = memo((props) => {
    const { company, onPopupClose } = props;

    const isConnectingToIntegration = useSelector((state) =>
        selectors.ui.isOperationInProgress(state, integrationActions.CONNECT_TO_INTEGRATION)
    );
    const dispatch = useDispatch();

    let IntegrationIcon;

    const integrationType = company.integration!.integrationType;

    switch (integrationType) {
        case domain.IntegrationType.Xero:
            IntegrationIcon = XeroGreyIcon;
            break;

        case domain.IntegrationType.QBooks:
            IntegrationIcon = QBooksLogo;
            break;

        case domain.IntegrationType.NetSuite:
            IntegrationIcon = OracleNetsuiteLogo;
            break;

        case domain.IntegrationType.Dear:
            IntegrationIcon = Cin7Logo;
            break;

        case domain.IntegrationType.None:
            throw errorHelpers.invalidOperationError();

        default:
            throw errorHelpers.assertNever(integrationType);
    }

    const onReconnect = useCallback(() => {
        const integrationType = company.integration?.integrationType;

        if (!integrationType) {
            return;
        }

        switch (integrationType) {
            case domain.IntegrationType.NetSuite: {
                dispatch(
                    integrationActions.showNetSuiteIntegrationPopup(company.id, IntegrationSuccessRedirectPage.Workflow)
                );

                onPopupClose();

                break;
            }

            case domain.IntegrationType.Dear: {
                dispatch(
                    integrationActions.showDearIntegrationPopup(company.id, IntegrationSuccessRedirectPage.Workflow)
                );

                onPopupClose();

                break;
            }

            default:
                dispatch(
                    integrationActions.connectToIntegration({
                        integrationType,
                        companyId: company.id,
                        finalRedirectToPage: 'workflow',
                        showGlobalProgress: true,
                    })
                );
        }
    }, [dispatch, company, onPopupClose]);

    return (
        <DisconnectedCompanyItem>
            <DisconnectedCompanyIcon>
                <IntegrationIcon width={30} height={30} />
            </DisconnectedCompanyIcon>

            <DisconnectedCompanyContent $isManager={company.flags.isManager || company.flags.isWorkflowManager}>
                <DisconnectedCompanyTitle title={company.displayName}>{company.displayName}</DisconnectedCompanyTitle>

                <DisconnectedCompanyError>
                    {messages.disconnectedFromText({
                        integrationName: company.integration?.displayName,
                    })}
                </DisconnectedCompanyError>
            </DisconnectedCompanyContent>

            {(company.flags.isManager || company.flags.isWorkflowManager) && (
                <DisconnectedCompanyReconnectBlock>
                    <Button
                        preset='compact'
                        execute={onReconnect}
                        disabled={isConnectingToIntegration || company.isReadonly}
                    >
                        {messages.reconnectButtonText}
                    </Button>
                </DisconnectedCompanyReconnectBlock>
            )}
        </DisconnectedCompanyItem>
    );
});

export default DisconnectedCompany;
