import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SHOW_MATCHING_POPUP } from '../../../actions';

export const MATCHING_POPUP = 'MATCHING_POPUP';

export interface MatchingPopupData {
    id: typeof MATCHING_POPUP;
}

export type MatchingPopupType = ImmutableObject<MatchingPopupData> | null;

export default function (state: MatchingPopupType, action: Action): MatchingPopupType {
    switch (action.type) {
        case SHOW_MATCHING_POPUP:
            return immutable<MatchingPopupData>({
                id: MATCHING_POPUP,
            });

        default:
            return state;
    }
}
