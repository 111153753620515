import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.MatrixPopupContent.XeroSupplierCreationConditionCell', {
    onlySuppliersDropdownText: 'Any Contact marked as Supplier',
    onlyCustomersDropdownText: 'Any Contact marked as Customer',
    contactsExactValuesDropdownText: 'Contact that matches',
    contactsNotExactValuesDropdownText: 'Any Contact except for',
    anyExistingContactDropdownText: 'Any existing Contact',
    ruleEditorXeroPoSupplierAllowCreateCheck: 'Allow to create new Contacts',
});
