import { FC, memo } from 'react';

import { Navigation } from '../Navigation/Navigation';
import { useBudgetingLinkItems } from './BudgetingMenu.hooks';
import { BudgetingMenuProps } from './BudgetingMenu.types';

export const BudgetingMenu: FC<BudgetingMenuProps> = memo((props) => {
    const { company, onClick } = props;

    const budgetingLinkItems = useBudgetingLinkItems(company);

    return <Navigation items={budgetingLinkItems} onClick={onClick} />;
});

BudgetingMenu.displayName = 'BudgetingMenu';
