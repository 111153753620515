import { Dropdown, font, oDropdownPanel } from '@approvalmax/ui';
import { miscHelpers } from '@approvalmax/utils';
import { CircleAlertIcon } from 'modules/sprites';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const i18nPrefix = 'request/components/xero/BankAccountCheckIndicator';

const Root = styled.div`
    display: flex;
    align-items: center;
`;

const IconContainer = styled.div`
    width: 12px;
    margin-left: 5px;
`;

const Panel = styled.div`
    ${oDropdownPanel()}
    display: flex;
    flex-flow: column;
    padding: 15px;
`;

const Description = styled.div`
    ${font(12, '#000')}
    margin-bottom: 15px;
`;

const Line = styled.div`
    display: flex;
    align-items: center;
    margin-top: 4px;
`;

const Label = styled.div`
    flex: 1;
    ${font(12, '#5e5c5c')}
`;

const ExpectedValue = styled.div`
    ${font(12, '#6c6a6a')}
`;

const ActualValue = styled.div`
    ${font(12, '#000', 'semibold')}
`;

interface BankAccountCheckIndicatorProps {
    actualBankAccount: string;
    expectedBankAccount: string;
    disabled?: boolean;
    children: (hasWarning: boolean) => React.ReactNode;
}

const BankAccountCheckIndicator: FC<BankAccountCheckIndicatorProps> = (props) => {
    const { children, actualBankAccount, expectedBankAccount, disabled } = props;

    const [isOpen, setOpen] = useState(false);

    const hasWarning = actualBankAccount !== expectedBankAccount;

    return (
        <Dropdown
            button={(ref) => (
                <Root ref={ref} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
                    {children(hasWarning)}

                    <IconContainer>{hasWarning && <CircleAlertIcon />}</IconContainer>
                </Root>
            )}
            isOpen={isOpen && !disabled && hasWarning}
            onRequestClose={miscHelpers.noop}
        >
            <Panel>
                <Description>
                    <FormattedMessage
                        id={`${i18nPrefix}.description`}
                        defaultMessage='Please note that the bank account associated with this Bill was changed in ApprovalMax'
                    />
                </Description>

                <Line>
                    <Label>
                        <FormattedMessage
                            id={`${i18nPrefix}.expectedBankAccount`}
                            defaultMessage='Contact bank account:'
                        />
                    </Label>

                    <ExpectedValue>{expectedBankAccount}</ExpectedValue>
                </Line>

                <Line>
                    <Label>
                        <FormattedMessage
                            id={`${i18nPrefix}.actualBankAccount`}
                            defaultMessage='Updated bank account:'
                        />
                    </Label>

                    <ActualValue>{actualBankAccount}</ActualValue>
                </Line>
            </Panel>
        </Dropdown>
    );
};

export default BankAccountCheckIndicator;
