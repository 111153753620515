import { selectors } from 'modules/common';

import { WorkflowSettingsPopupSectionProps } from '../../WorkflowSettingsPopupContent.types';

export interface SectionPriceCheckerProps extends WorkflowSettingsPopupSectionProps {
    links: selectors.types.LinksCollection;
}

export enum PriceCheckerOption {
    yes = 'yes',
    no = 'no',
}
