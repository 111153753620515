import { MatrixType } from '../../../../types/matrix';
import { messages } from './MatrixAddUserButton.messages';

export const useAddUserButtonTitle = (matrixType: MatrixType) => {
    switch (matrixType) {
        case MatrixType.Approval:
            return messages.addApprover;

        case MatrixType.Reviewer:
        case MatrixType.Editor:
            return messages.addReviewer;

        case MatrixType.Requester:
            return messages.addRequester;

        case MatrixType.AutoApproval:
            return messages.addAutoApprovalRule;

        default:
            return '';
    }
};
