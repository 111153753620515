import * as backend from '../../backend';
import * as domain from '../../domain';

export const mapBeneficiary = (value: backend.AirwallexBeneficiary): domain.AirwallexBeneficiary => {
    return {
        id: value.airwallexBeneficiaryId,
        text: value.name,
        paymentMethod: value.paymentMethod ? { id: value.paymentMethod, text: value.paymentMethod } : null,
        paymentFee: value.paymentFee ? { id: value.paymentFee, text: value.paymentFee } : null,
        version: value.version,
        accountCurrency: value.accountCurrency,
        bankDetails: {
            ...value.bankDetails,
            accountingRoutingType1:
                value.bankDetails.accountingRoutingType1 || value.bankDetails.accountRoutingType1 || '',
            accountingRoutingType2:
                value.bankDetails.accountingRoutingType2 || value.bankDetails.accountRoutingType2 || '',
            accountingRoutingValue1:
                value.bankDetails.accountingRoutingValue1 || value.bankDetails.accountRoutingValue1 || '',
            accountingRoutingValue2:
                value.bankDetails.accountingRoutingValue2 || value.bankDetails.accountRoutingValue2 || '',
        },
    };
};
