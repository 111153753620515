import { LazyGetRequestDataParams, QueryOptions } from '@approvalmax/data';
import { useGetLazyRequest } from 'shared/data';

import { requestsApiPaths } from '../paths';

export const useLazyGetAttachment = (queryOptions?: QueryOptions<Blob>) =>
    useGetLazyRequest<LazyGetRequestDataParams, Blob>(requestsApiPaths.getAttachment, {
        responseType: 'blob',
        queryOptions,
    });
