import { AttachmentIcon, DragFileIcon } from '@approvalmax/ui/src/icons';
import { fileHelpers } from '@approvalmax/utils';
import isError from 'lodash/isError';
import { ComponentProps, FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DropzoneProps as DropzoneExternalProps, useDropzone } from 'react-dropzone';
import { useDrop } from 'react-use';

import Attachment from '../Attachment/Attachment';
import { Box } from '../Box/Box';
import Button from '../Button/Button';
import List from '../List/List';
import { Spacing } from '../Spacing/Spacing';
import { Text } from '../Text/Text';
import { toast } from '../Toast/Toast.helpers';
import { getErrorMessage, handleDuplicateNames, prepareFiles } from './Dropzone.helpers';
import { errorMessages, messages } from './Dropzone.messages';
import { Root, Zone } from './Dropzone.styles';
import { DropzoneProps, FileRecord } from './Dropzone.types';

/*
 * Component that abstracts the logic and UI for a drag and drop file upload zone using react-dropzone
 * https://react-dropzone.js.org/
 */
const Dropzone: FC<DropzoneProps> = memo((props) => {
    const {
        hideZone,
        size = 'medium',
        disabled,
        multiple = false,
        hideErrorToast = false,
        noDuplicateNames = false,
        accept,
        children,
        maxSize,
        minSize = 0,
        maxFilesInList,
        value,
        progress,
        invalid,
        width,
        height,
        hideButton,
        hideAcceptedExtensions,
        bordered,
        dragFileMessage,
        dragFileIcon,
        onUpload,
        filterFiles,
        onDropAccepted,
        onDropRejected,
        onChange,
        onRemove,
        ...restProps
    } = props;

    const { over: isDragOnPageActive } = useDrop();
    const acceptedExtensions = useMemo(
        () =>
            accept
                ? Object.values(accept)
                      .map((ext) => ext.join(', '))
                      .join(', ')
                : '',
        [accept]
    );

    const [files, setFiles] = useState<FileRecord[]>(value || []);
    const [newFiles, setNewFiles] = useState<FileRecord[]>([]);
    const hasFiles = Boolean(files.length);
    const hasNewFiles = Boolean(newFiles.length);

    const hasMultipleFiles = multiple && (hasFiles || hasNewFiles);
    const fileWordMessageValue = { file: multiple ? messages.files : messages.file };

    useEffect(() => {
        setFiles(value || []);
    }, [value]);

    const onDropAcceptedFiles = useCallback<Required<DropzoneExternalProps>['onDropAccepted']>(
        async (acceptedFiles, event) => {
            let preparedFiles = prepareFiles(filterFiles ? filterFiles(acceptedFiles) : acceptedFiles);

            if (maxFilesInList && preparedFiles.length + files.length > maxFilesInList) {
                toast.error(errorMessages.tooManyFilesInList({ maxFilesInList }));
            } else {
                if (noDuplicateNames) {
                    preparedFiles = handleDuplicateNames(preparedFiles, files);
                }

                if (onUpload) {
                    setNewFiles((newFiles) => [...newFiles, ...preparedFiles]);

                    const uploadResults = await Promise.all(
                        preparedFiles.map((file) =>
                            onUpload(file)
                                .then(() => ({ id: file.id, success: true }))
                                .catch((error) => {
                                    if (isError(error)) {
                                        toast.error(error.message);
                                    }

                                    return { id: file.id, success: false };
                                })
                        )
                    );

                    setNewFiles((prevFiles) =>
                        prevFiles.filter((file) => preparedFiles.every((item) => item.id !== file.id))
                    );

                    preparedFiles = preparedFiles.filter((file) =>
                        uploadResults.every((item) => item.id !== file.id || item.success)
                    );
                }

                if (preparedFiles.length > 0) {
                    value === undefined &&
                        setFiles((prevFiles) => (multiple ? [...prevFiles, ...preparedFiles] : preparedFiles));
                    onDropAccepted?.(preparedFiles, event);
                }
            }
        },
        [files, filterFiles, maxFilesInList, multiple, noDuplicateNames, onDropAccepted, onUpload, value]
    );

    const onDropRejectedFiles = useCallback<Required<DropzoneExternalProps>['onDropRejected']>(
        (rejectedFiles, event) => {
            !hideErrorToast &&
                rejectedFiles.forEach(({ file, errors }) =>
                    errors.map((error) => {
                        toast.error(
                            <>
                                <Text font='label' fontSize='large' fontWeight='medium' spacing='0 0 8' ellipsis={2}>
                                    {file.name}
                                </Text>

                                {getErrorMessage(error, { maxSize: maxSize || 100000, minSize })}
                            </>
                        );
                    })
                );

            onDropRejected?.(rejectedFiles, event);
        },
        [hideErrorToast, maxSize, minSize, onDropRejected]
    );

    const onRemoveFile = useCallback<Required<ComponentProps<typeof Attachment>>['onRemove']>(
        (file, id) => {
            value === undefined && setFiles((prevFiles) => prevFiles.filter((file) => id !== file.id));

            onRemove?.({ id: id!, source: file });
        },
        [onRemove, value]
    );

    useEffect(() => {
        onChange?.(files);
    }, [files, onChange]);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        accept,
        maxSize,
        minSize,
        multiple,
        disabled,
        noClick: true,
        noKeyboard: true,
        onDropAccepted: onDropAcceptedFiles,
        onDropRejected: onDropRejectedFiles,
        ...restProps,
    });

    return (
        <Root
            {...getRootProps()}
            {...restProps}
            $size={size}
            $hide={hideZone || hasFiles || hasNewFiles}
            $drag={isDragOnPageActive}
            $height={height}
            $width={width}
            $bordered={bordered}
        >
            <input {...getInputProps()} />

            {children}

            {(hasFiles || hasNewFiles) && (
                <List spacing='4 0' removeLastSpacing>
                    {files.map((file) => (
                        <List.Item key={file.id}>
                            <Attachment file={file.source} id={file.id} onRemove={onRemoveFile} progress={progress} />
                        </List.Item>
                    ))}

                    {newFiles.map((file) => (
                        <List.Item key={file.id}>
                            <Attachment file={file.source} id={file.id} progress={true} />
                        </List.Item>
                    ))}
                </List>
            )}

            {hasMultipleFiles && <Spacing height={12} />}

            {((hideZone && !hasFiles && !hasNewFiles) || hasMultipleFiles) && !hideButton && (
                <Button
                    onClick={open}
                    color={invalid ? 'red40' : hasMultipleFiles ? 'blue80' : 'blue10'}
                    size={hasMultipleFiles ? 'xsmall' : size}
                    startIcon={<AttachmentIcon />}
                    disabled={disabled}
                >
                    {messages.selectFileButton(fileWordMessageValue)}
                </Button>
            )}

            <Zone
                $height={height}
                $width={width}
                $hide={hideZone || hasFiles || hasNewFiles}
                $active={isDragActive}
                $drag={isDragOnPageActive && !disabled}
            >
                {dragFileIcon || (
                    <DragFileIcon
                        size={size === 'medium' ? 32 : 24}
                        color={isDragActive || disabled ? 'midnight70' : 'midnight80'}
                    />
                )}

                <Box spacing={size === 'medium' ? '16 0' : '8 0'}>
                    <Text
                        font='label'
                        textAlign='center'
                        fontSize={size}
                        color={isDragActive || disabled ? 'midnight70' : 'midnight100'}
                        fontWeight='medium'
                    >
                        {isDragActive
                            ? messages.hint(fileWordMessageValue)
                            : dragFileMessage || messages.dragFileMessage}
                    </Text>

                    {(acceptedExtensions || maxSize) && (
                        <Text font='body' fontSize='small' textAlign='center' color='midnight70' spacing='8 0 0'>
                            {`${!hideAcceptedExtensions ? acceptedExtensions.toUpperCase() : ''} ${maxSize ? messages.maxSizeText({ maxSize: fileHelpers.formatBytes(maxSize) }) : ''}`}
                        </Text>
                    )}
                </Box>

                <Button
                    onClick={open}
                    size={size === 'medium' ? 'small' : 'xsmall'}
                    color='blue80'
                    startIcon={<AttachmentIcon />}
                    disabled={isDragOnPageActive || disabled}
                >
                    {messages.selectFileButton(fileWordMessageValue)}
                </Button>
            </Zone>
        </Root>
    );
});

export default Dropzone;
