import { MenuItemsProps } from './MenuItems.types';

const filterOutSelectedItems = <Item extends Record<string, any>, Multiple extends boolean>(
    item: MenuItemsProps<Item, Multiple>['items'][number],
    selectedItems: MenuItemsProps<Item, Multiple>['items'],
    itemIdKey: MenuItemsProps<Item, Multiple>['itemIdKey'],
    filterSelectedItems: MenuItemsProps<Item, Multiple>['filterSelectedItems']
) => (filterSelectedItems ? !selectedItems.some((selectedItem) => selectedItem[itemIdKey] === item[itemIdKey]) : true);

export const filterItems = <Item extends Record<string, any>, Multiple extends boolean>(
    items: MenuItemsProps<Item, Multiple>['items'],
    selectedItems: MenuItemsProps<Item, Multiple>['items'],
    inputValue: string | undefined = '',
    itemNameKey: MenuItemsProps<Item, Multiple>['itemNameKey'],
    itemIdKey: MenuItemsProps<Item, Multiple>['itemIdKey'],
    filterSelectedItems: MenuItemsProps<Item, Multiple>['filterSelectedItems']
) => {
    return items.filter(
        (item) =>
            item[itemNameKey].toLowerCase().includes(inputValue.toLowerCase()) &&
            filterOutSelectedItems(item, selectedItems, itemIdKey, filterSelectedItems)
    );
};

export const filterItemsOnPristineInputValue = <Item extends Record<string, any>, Multiple extends boolean>(
    items: MenuItemsProps<Item, Multiple>['items'],
    selectedItems: MenuItemsProps<Item, Multiple>['items'],
    itemIdKey: MenuItemsProps<Item, Multiple>['itemIdKey'],
    filterSelectedItems: MenuItemsProps<Item, Multiple>['filterSelectedItems']
) => items.filter((item) => filterOutSelectedItems(item, selectedItems, itemIdKey, filterSelectedItems));
