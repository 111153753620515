import { domain } from 'modules/data';
import { immutable, ImmutableObject, removeArrayItem, set, setIn } from 'modules/immutable';

import {
    Action,
    CHANGE_CURRENCY,
    CHANGE_CURRENCY_EXCHANGE_RATE_PARAMS,
    CHANGE_REQUEST_DESCRIPTION,
    CHANGE_REQUEST_NAME,
    EDIT_REQUEST_FIELD,
    EDIT_REQUEST_NOTE,
    LOAD_PAGE_DATA,
    REMOVE_ATTACHMENT,
    REMOVE_EMAIL_TO_CONTACT_ATTACHMENT,
    UPLOAD_ATTACHMENTS_RESPONSE,
} from '../../actions';
import { AttachmentKind } from '../../data/AttachmentKind';
import qbooksReducer from './requestReducer.qbooks';
import standaloneReducer from './requestReducer.standalone';
import xeroReducer from './requestReducer.xero';

export type RequestType = ImmutableObject<domain.Request>;

const INITIAL_STATE: RequestType = null as any;

export default (state = INITIAL_STATE, action: Action): RequestType => {
    state = standaloneReducer(qbooksReducer(xeroReducer(state, action), action), action);

    switch (action.type) {
        case LOAD_PAGE_DATA: {
            return immutable(action.payload.initialRequest);
        }

        case EDIT_REQUEST_FIELD:
            return setIn(state, ['details', action.payload.propName as string], action.payload.newValue);

        case UPLOAD_ATTACHMENTS_RESPONSE: {
            if (action.payload.attachmentKind === AttachmentKind.General) {
                const newAttachments = action.payload.accepted.map<domain.RequestAttachment>((attachment) => ({
                    ...attachment,
                    isNew: true,
                }));

                return set(state, 'attachments', state.attachments.concat(newAttachments));
            }

            if (action.payload.attachmentKind === AttachmentKind.EmailToSupplierAttachment) {
                const details = state.details as domain.RequestDetails;
                const isQBooksSalesInvoice = details.integrationCode === domain.IntegrationCode.QBooksInvoice;

                let attachments: domain.RequestAttachment[] = [];

                if ('emailToSupplier' in details) {
                    attachments = details.emailToSupplier?.attachments || [];

                    return setIn(
                        state,
                        ['details', 'emailToSupplier', 'attachments'],
                        attachments.concat(action.payload.accepted)
                    );
                }

                if (isQBooksSalesInvoice && 'emailToContact' in details) {
                    attachments = details.emailToContact.attachments || [];

                    return setIn(
                        state,
                        ['details', 'emailToContact', 'attachments'],
                        attachments.concat(action.payload.accepted)
                    );
                }
            }

            return state;
        }

        case REMOVE_ATTACHMENT:
            return set(
                state,
                'attachments',
                removeArrayItem(state.attachments, (a) => a.id === action.payload.attachmentId)
            );

        case REMOVE_EMAIL_TO_CONTACT_ATTACHMENT: {
            let details = state.details;

            if (details && 'emailToContact' in details && details.emailToContact) {
                let attachments: domain.RequestAttachment[] = removeArrayItem(
                    details.emailToContact.attachments || [],
                    ({ id }) => id === action.payload.attachmentId
                );

                return setIn(state, ['details', 'emailToContact', 'attachments'], attachments);
            }

            return state;
        }

        case CHANGE_CURRENCY:
            return set(state, 'currency', action.payload.newCurrency);

        case CHANGE_CURRENCY_EXCHANGE_RATE_PARAMS: {
            const {
                exchangeRate = null,
                exchangeRateDate = null,
                exchangeRateSource = null,
            } = action.payload.exchangeRateParams;

            return {
                ...state,
                exchangeRateDate,
                exchangeRateSource,
                exchangeRate: exchangeRate === 0 ? null : exchangeRate,
                currencyExchangeRate: exchangeRate === 0 ? null : exchangeRate,
            };
        }

        case CHANGE_REQUEST_NAME:
            return setIn(state, ['details', 'name'], action.payload.newName);

        case CHANGE_REQUEST_DESCRIPTION:
            return setIn(state, ['details', 'description'], action.payload.newDescription);

        case EDIT_REQUEST_NOTE:
            return set(state, 'requestNote', action.payload.requestNote);

        default:
            return state;
    }
};
