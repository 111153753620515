import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.ApprovalStepContent', {
    openApprovalMatrixButtonTitle: 'Define approval conditions for Approvers in the approval matrix',
    addApprover: 'Add an Approver',
    defaultApprover: 'Default Approver',
    defaultApproverDescription:
        'A Default Approver is added to a step in case there is no designated Approver specified for it.',
    errorMessage: 'Add at least one Approver, or remove this step',
    emptyStepDescription:
        'Add at least one Approver for this step and then define the respective approval conditions in the approval matrix.',
});
