export enum ErrorCode {
    XERO_ERROR = '0',
    UNKNOWN_ERROR = '0000',
    CONFLICT = 'conflict',

    E1001_INTERNAL_MODEL_ERROR = '1001',
    E1002_INTERNAL_FILE_ERROR = '1002',
    E1004_TOO_MANY_REQUESTS = '1004',

    E2001_EMAIL_NOT_FOUND = '2001',
    E2002_USER_NOT_FOUND = '2002',
    E2003_APPROVAL_NOT_FOUND = '2003',
    E2004_COMMENT_NOT_FOUND = '2004',
    E2005_ATTACHMENT_NOT_FOUND = '2005',
    E2006_ATTACHMENT_IS_INVALID = '2006',
    E2007_SUBSCRIPCTION_NOT_FOUND = '2007',
    E2008_PUSHCONSUMER_NOT_FOUND = '2008',
    E2009_TEMPLATE_NOT_FOUND = '2009',
    E2010_TEMPLATE_STEP_NOT_FOUND = '2010',
    E2011_REQUEST_NOT_FOUND = '2011',
    E2012_REQUEST_STEP_NOT_FOUND = '2012',
    E2013_REQUEST_STEP_PARTICIPANT_NOT_FOUND = '2013',
    E2014_COMPANY_NOT_FOUND = '2014',
    E2015_FIELD_NOT_FOUND = '2015',
    E2016_INTEGRATION_NOT_FOUND = '2016',
    E2017_COMPANY_MEMBER_NOT_FOUND = '2017',
    E2018_LINE_ITEM_NOT_FOUND = '2018',
    E2019_REQUEST_AUDIT_REPORT_NOT_FOUND = '2019',
    E2020_REQUEST_REPORT_CONFIG_NOT_FOUND = '2020',
    E2021_REQUEST_AUDIT_REPORTS_COLLECTION_NOT_FOUND = '2021',
    E2022_COMPANY_PARTICIPANT_INVITATION_NOT_FOUND = '2022',
    E2023_COMPANY_PARTICIPANT_ALREADY_EXISTS = '2023',
    E2024_BUDGET_NOT_FOUND = '2024',
    E2025_BUDGET_SOURCE_FILE_NOT_FOUND = '2025',

    E2035_NOT_UNIQUE_DAY_TIME = '2035',
    E2036_NOT_UNIQUE_EMAIL = '2036',

    E3001_INVALID_TOKEN = '3001',
    E3002_EMAIL_NOT_VERIFIED = '3002',
    E3003_INCORRECT_EMAIL_OR_PASSWORD = '3003',
    E3004_PASSWORD_IS_TOO_SHORT = '3004',
    E3005_PASSWORD_IS_TOO_LONG = '3005',
    E3006_PASSWORD_IS_TOO_WEAK = '3006',
    E3007_USER_LOGIN_FAILED_DUE_TO_LOCKOUT = '3007',
    E3009_INAVLID_OLD_PASSWORD = '3009',
    E3010_EMAIL_NOT_MATCH_SSO = '3010',

    E4001_PASSWORD_NOT_SET = '4001',
    E4002_WRONG_CONFIRMATION_CODE = '4002',
    E4003_CONFIRMATION_CODE_MISSING = '4003',
    E4004_EMPTY_NEW_PASSWORD = '4004',
    E4005_RESPONSE_NOT_ALLOWED = '4005',
    E4006_FILE_TOO_BIG = '4006',
    E4007_HISTORY_FILTER_PARSING_ERROR = '4007',
    E4008_EMAIL_ALREADY_EXISTS = '4008',
    E4009_EMAIL_ALREADY_VERIFIED = '4009',
    E4010_THERE_IS_APPROVAL_FOR_EMAIL = '4010',
    E4011_SINGLE_EMAIL_CANNOT_BE_DELETED = '4011',
    E4012_DRAFT_APPROVAL_CANNOT_BE_CANCELLED = '4012',
    E4013_ONLY_DRAFT_APPROVAL_CAN_BE_DELETED = '4013',
    E4014_COMMENT_TOO_OLD = '4014',
    E4015_ATTACHMENT_IS_BOUND_WITH_APPROVAL = '4015',
    E4016_DEADLINE_IN_THE_PAST = '4016',
    E4017_UNSUPPORTED_MEDIA_TYPE = '4017',
    E4018_FREE_APPROVALS_NUMBER_EXCEEDED = '4018',
    E4019_PASSWORD_CANNOT_BE_EMPTY = '4019',
    E4020_TIMEZONE_NOT_FOUND = '4020',
    E4021_UNSUPPORTED_PUSH_NOTIFICATION_PLATFORM = '4021',
    E4022_ONLY_DRAFT_REQUEST_CAN_BE_DELETED = '4022',
    E4023_DRAFT_REQUEST_CANNOT_BE_CLOSED = '4023',
    E4024_UNKNOWN_FILTER = '4024',
    E4025_REQUEST_CANNOT_BE_CANCELLED = '4025',
    E4026_ONLY_OPEN_REQUESTS_CAN_BE_RESET = '4026',
    E4027_COMMENT_CANNOT_BE_EMPTY = '4027',
    E4028_ATTACHMENT_IS_BOUND_WITH_OBJECT = '4028',
    E4029_OPERATION_VALID_FOR_DRAFT_ONLY = '4029',
    E4030_OPERATION_VALID_FOR_OPEN_ONLY = '4030',
    E4031_OPERATION_VALID_FOR_CLOSED_ONLY = '4031',
    E4032_FIELD_NAME_ALREADY_EXISTS_IN_COMPANY = '4032',
    E4033_INTEGRATED_TEMPLATE_ALREADY_EXISTS_IN_COMPANY = '4033',
    E4034_REQUEST_CANNOT_BE_CREATED_FROM_INTEGRATED_TEMPLATE = '4034',
    E4035_MANAGERS_MUST_BE_SPECIFIED = '4035',
    E4036_STEPS_MUST_BE_SPECIFIED = '4036',
    E4037_KEY_MUST_BE_SPECIFIED = '4037',
    E4038_SECRET_MUST_BE_SPECIFIED = '4038',
    E4039_SUBMITTERS_MUST_BE_SPECIFIED = '4039',
    E4040_FIELD_ID_CANNOT_BE_EMPTY = '4040',
    E4041_XERO_COMPANY_ALREADY_INTEGRATED = '4041',
    E4042_MANAGERS_CANNOT_REMOVE_THEMSELVES_FROM_COMPANY = '4042',
    E4043_INTEGRATION_MUST_BE_ACTIVE = '4043',
    E4044_OPERATION_VALID_FOR_MANAGERS_ONLY = '4044',
    E4045_NO_ACTIVE_SUBSCRIPTIONS = '4045',
    E4046_SUBSCRIPTION_EXPIRED = '4046',
    E4047_SUBSCRIPTION_COMPANY_AMOUNT_EXCEEDED = '4047',
    E4048_OPERATION_VALID_FOR_XERO_OBJECTS_ONLY = '4048',
    E4049_COMPANY_OWNER_CANNOT_BE_DELETED_FROM_MANAGERS_LIST = '4049',
    E4050_RESPONSE_OUTDATED = '4050',
    E4051_STEP_PARTICIPANTS_MUST_BE_SPECIFIED = '4051',
    E4052_ALL_REQUESTS_MUST_BE_FROM_THE_SAME_COMPANY = '4052',
    E4053_NO_ACCESS_TO_COMPANY = '4053',
    E4054_NO_ACCESS_TO_TEMPLATE = '4054',
    E4055_NO_ACCESS_TO_REQUEST = '4055',
    E4056_NOT_APPLICABLE_FOR_THIS_REQUEST_TYPE = '4056',
    E4057_UNSUPPORTED_RESPONSE_TYPE = '4057',
    E4058_CHANGES_MUST_BE_SPECIFIED = '4058',
    E4059_CHANGES_MUST_BE_APPLIED_TO_NOT_FINISHED_STEPS = '4059',
    E4060_DUPLICATE_PARTICIPANTS_IN_CHANGES = '4060',
    E4061_STEP_PARTICIPANT_ALREADY_EXISTS = '4061',
    E4062_OPERATION_NOT_ALLOWED_FOR_USER = '4062',
    E4063_UNSUPPORTED_INTEGRATION_TYPE = '4063',
    E4064_DELEGATE_MUST_BE_SPECIFIED = '4064',
    E4065_DELEGATE_AND_DELEGATOR_CANNOT_BE_THE_SAME_PERSON = '4065',
    E4066_DELEGATIONS_MUST_NOT_OVERLAP = '4066',
    E4067_DELEGATE_MUST_NOT_BE_CYCLIC = '4067',
    E4068_OPERATION_NOT_VALID_FOR_PARTICIPANTS = '4068',
    E4069_FIELD_CANNOT_BE_CHANGED = '4069',
    E4070_OPERATION_VALID_FOR_QBOOKS_OBJECTS_ONLY = '4070',
    E4071_EMAIL_CANNOT_BE_EMPTY = '4071',
    E4072_OPENID_CANNOT_BE_EMPTY = '4072',
    E4073_ONE_TIME_TOKEN_INVALID = '4073',
    E4074_NEITHER_PASSWORD_NOR_ONE_TIME_TOKEN_PASSED = '4074',
    E4075_OPERATION_FAILED_DUE_TO_EXTERNAL_REJECT = '4075',
    E4076_MAX_FILES_COUNT_PER_REQUEST_EXCEEDED = '4076',
    E4077_FILE_UPLOADING_FAILED_DUE_TO_LOCKOUT = '4077',
    E4078_AVATAR_TOO_BIG = '4078',
    E4079_UNSUPPORTED_AVATAR_FILE_TYPE = '4079',
    E4080_AVATAR_CONTAINS_VIRUS = '4080',
    E4081_CONFIRMATION_CODE_EXPIRED = '4081',
    E4082_TEMPLATE_EFFECTIVE_DATE_IS_BEYOND_THE_LIMIT = '4082',
    E4083_TEMPLATE_EFFECTIVE_DATE_CANNOT_BE_EMPTY = '4083',
    E4084_INTEGRATION_FRAUD_DETECTION_IS_ALREADY_ENABLED = '4084',
    E4085_INTEGRATION_FRAUD_DETECTION_IS_NOT_ENABLED = '4085',
    E4086_ATTACHMENT_FILENAME_IS_INVALID = '4086',
    E4087_FIELD_QUERING_APPLICABLE_ONLY_FOR_ONE_FIELD = '4087',
    E4088_FIELD_QUERING_APPLICABLE_ONLY_FOR_FIELD_VALUES = '4088',
    E4089_ATTACHMENT_COUNT_EXCEEDS_LIMIT = '4089',
    E4090_EMAIL_FORMAT_IS_INVALID = '4090',
    E4091_ATTACHMENT_MIMETYPE_IS_UNSUPPORTED = '4091',
    E4092_ATTACHMENT_FILENAME_CONTAINS_RESTRICTED_SUBSTRING = '4092',
    E4093_TEMPLATE_MUST_BE_ACTIVE = '4093',
    E4094_TEMPLATE_EXTERNAL_SUBMITTER_IS_UNDEFINED = '4094',
    E4095_OPERATION_FAILED_DUE_TO_RULES_VIOLATION = '4095',
    E4096_REQUEST_MUST_BE_APPROVED = '4096',
    E4097_REQUEST_MUST_NOT_BE_PENDING = '4097',
    E4098_REQUEST_MUST_BE_BILLABLE = '4098',
    E4099_TRIAL_SUBSCRIPTION_ALREADY_EXISTS = '4099',
    E4100_COMPAPNY_PARTICIPANT_DELEGATE_MUST_BE_SPECIFIED = '4100',
    E4101_OPERATION_VALID_FOR_DRAFT_OR_OPEN_ONLY = '4101',
    E4102_FIRST_NAME_CANNOT_BE_EMPTY = '4102',
    E4103_LAST_NAME_CANNOT_BE_EMPTY = '4103',
    E4104_AVATAR_REQUEUIRES_ONE_FILE = '4104',
    E4105_BUDGET_REQUEIRES_ONE_FILE = '4105',
    E4106_UNSUPPORTED_FILE_TYPE = '4106',
    E4107_FILE_CONTAINS_VIRUS = '4107',
    E4108_COMPANY_IS_ALREADY_INTEGRATED = '4108',
    E4109_REQUIRED_FIELD_IS_EMPTY = '4109',
    E4121_REQUEST_STEP_MUST_BE_ACTIVE = '4121',
    E4122_TEMPLATE_SUBMITTER_NOT_FOUND = '4122',
    E4123_ANOTHER_COMPANY_IS_ALREADY_INTEGRATED = '4123',
    E4124_OPERATION_VALID_FOR_REQUESTERS_ONLY = '4124',
    E4125_FILE_NOT_FOUND = '4125',
    E4127_TRIAL_EXPIRED = '4127',
    E4132_TWO_FA_ALREADY_ENABLED = '4132',
    E4181_TWO_FA_HARD_REQUIRED = '4181',
    E4154_TWO_FA_REQUIRED = '4154',

    E4113_ORGANISATION_IS_RETIRED = '4113',

    E4142_OUTDATED_OFFBOARD_DATA = '4142',

    E4148_WORKFLOW_VERSION_UPDATED = '4148',

    // Xero
    E4500_XERO_PERMISSIONS_NOT_SUFFICIENT = '4500',
    E5001_XERO_GENERAL_INTEGRATION_ERROR = '5001',
    E5002_XERO_API_CLIENT_MUST_BE_AUTHORIZED = '5002',
    E5003_XERO_OAUTH_TOKEN_EXPIRED = '5003',
    E5004_XERO_TOKEN_IS_NOT_AUTHORIZED = '5004',
    E5005_XERO_REQUEST_TOKEN_NOT_FOUND = '5005',
    E5006_XERO_REQUEST_TOKEN_KEY_DOESNOT_MATCH = '5006',
    E5007_XERO_ACCESS_TOKEN_STORE_IS_NOT_INITIALIZED = '5007',
    E5008_XERO_ACCESS_TOKEN_NOT_FOUND = '5008',
    E5009_XERO_RENEWAL_TOKEN_IS_PROHIBITED = '5009',
    E5010_XERO_CALLBACK_URL_MUST_BE_SPECIFIED = '5010',
    E5011_XERO_OAUTH_TOKEN_MUST_BE_SPECIFIED = '5011',
    E5012_XERO_OAUTH_VERIFIER_MUST_BE_SPECIFIED = '5012',
    E5013_XERO_CALL_RATE_EXCEEDED = '5013',
    E5014_XERO_OAUTH_TOKEN_REJECTED = '5014',
    E5015_XERO_GENERAL_INTEGRATION_AUTHENTICATION_ERROR = '5015',
    E5016_BOUND_COMPANY_ABOUT_TO_CHANGE = '5016',
    E5017_XERO_VALIDATION_ERROR = '5017',
    E5018_XERO_RESPONSE_OUTDATED_DUE_TO_CHANGES_IN_XERO = '5018',
    E5019_XERO_RESPONSE_PROHIBITED_DUE_TO_VALIDATION_LOCK = '5019',
    E5020_XERO_INTERNAL_ERROR = '5020',
    E5021_XERO_COUNTERPARTY_ALREADY_EXISTS = '5021',
    E5022_XERO_BRANDING_THEME_NOT_FOUND = '5022',
    E5023_XERO_ITEM_NOT_FOUND = '5023',
    E5024_XERO_TAX_RATE_NOT_FOUND = '5024',
    E5025_XERO_ACCOUNT_NOT_FOUND = '5025',
    E5026_XERO_COUNTERPARTY_NOT_FOUND = '5026',
    E5027_XERO_TRACKING_CATEGORY_NOT_FOUND = '5027',
    E5028_XERO_ACCESS_TOKEN_RENEWAL_FAILED = '5028',
    E5029_XERO_LINE_ITEM_DESCRIPTION_CANNOT_BE_LONGER_THAN_4000 = '5029',
    E5030_XERO_COMPANY_HAS_ALREADY_BEEN_INTEGRATED_BEFORE = '5030',
    E5031_XERO_BUDGET_FILE_CORRUPTED = '5031',
    E5043_XERO_QUOTE_UNAVAILABLE_FOR_CUSTOMER_DECISION = '5043',
    E4158_XERO_BILL_BATCH_PAYMENT_EXCEEDED_AMOUNT = '4158',
    E4159_XERO_BILL_BATCH_PAYMENT_DIFFERENT_CURRENCY = '4159',
    E4161_XERO_BILL_BATCH_PAYMENT_INCORRECT_STATUS = '4161',

    // Audit reports
    E6001_REQUEST_AUDIT_REPORT_NOT_GENERATED = '6001',
    E6002_REQUEST_AUDIT_REPORT_GENERATED_WITH_ERROR = '6002',
    E6003_REQUEST_AUDIT_REPORT_GENERATION_UNDERWAY = '6003',
    E6004_REQUEST_AUDIT_REPORTS_COLLECTION_UNDERWAY = '6004',
    E6005_REQUEST_AUDIT_REPORTS_COLLECTION_WITH_ERROR = '6005',
    E6006_REQUEST_AUDIT_REPORTS_COLLECTION_ACCESS_DENIED = '6006',
    E6007_REQUEST_AUDIT_REPORTS_COLLECTION_RESERVE_FAILED = '6007',
    E6009_REQUEST_AUDIT_REPORTS_COLLECTION_IS_STILL_BEING_PROCESSED = '6009',
    E6010_REQUEST_AUDIT_REPORTS_COLLECTION_HAS_BEEN_RECENTLY_PROCESSED = '6010',

    // Reports export
    E6011_REQUESTS_EXPORT_DATA_NOTHING_TO_SEND = '6011',
    E6012_EXPORTED_ATTACHMENTS_HAS_BEEN_RECENTLY_PROCESSED = '6012',
    E6013_EXPORTED_ATTACHMENTS_IS_STILL_BEING_PROCESSED = '6013',

    // Accrual reports
    E6030_ACCRUAL_REPORT_NARRATION_MUST_BE_SPECIFIED = '6030',
    E6031_ACCRUAL_REPORT_ACCOUNT_CODE_MUST_BE_SPECIFIED = '6031',

    // Request reports
    E6050_REQUEST_REPORTS_CUSTOM_DATE_FILTER_HAS_INVALID_VALUE = '6050',

    // QBooks
    E7001_QBOOKS_GENERAL_INTEGRATION_ERROR = '7001',
    E7002_QBOOKS_API_CLIENT_MUST_BE_AUTHORIZED = '7002',
    E7003_QBOOKS_VALIDATION_ERROR = '7003',
    E7029_QBOOKS_PURCHASE_ORDER_STATUS_UPDATE_FAILED_DUE_TO_LOCK_VIOLATION = '7029',
    E7030_QBOOKS_PURCHASE_ORDER_STATUS_UPDATE_UNAVAILABLE_DUE_TO_DOCUMENT_IS_UNBOUND = '7030',

    // Airwallex
    E7711_BENEFICIARY_CREATION_VALIDATION_ERROR = '7711',
    E7716_BENEFICIARY_WAS_EDITED_BEFORE_PAY = '7716',
    E7717_BENEFICIARY_WAS_EDITED_BEFORE_PUBLISH = '7717',
    E7718_BENEFICIARY_WAS_DELETED_BEFORE_PUBLISH = '7718',
    E7719_AIRWALLWEX_SCHEDULED_PAYMENT_TIME_UNAVAILABLE = '7719',

    // Matching agreements
    E8001_REQUEST_ALREADY_MATCHED = '8001',
    E8002_NOT_MATCHED_BILLS_APPROVAL_NOT_ALLOWED = '8002',
    E8003_OVERBUDGET_BILLS_APPROVAL_NOT_ALLOWED = '8003',
    E8004_MATCHING_DATA_MISMATCH = '8004',
    E8005_MATCHING_AMOUNT_IS_EXCEEDED = '8005',

    // Save workflow settings
    E8120_GROSS_NET_SETTING_CANNOT_BE_CHANGED = '8120',

    // AmaxPay
    E8302_PAYMENT_DETAILS_WERE_EDITED_BEFORE_PUBLISH = '8302',
    E8303_PAYMENT_DETAILS_WERE_DELETED_BEFORE_PUBLISH = '8303',
    E8308_PAYMENT_CANNOT_BE_CANCELLED_BECAUSE_IN_PROGRESS = '8308',

    // Email templates
    E9001_EMAIL_TEMPLATE_NAME_CANNOT_BE_LONGER_THAN_400 = '9001',
    E9002_EMAIL_TEMPLATE_SUBJECT_CANNOT_BE_LONGER_THAN_400 = '9002',
    E9003_EMAIL_TEMPLATE_NAME_SHOULD_BE_UNIQUE = '9003',

    // Transfers
    E12003_TARGET_USER_IS_XERO_REFERRAL = '12003',

    // My Account
    E2033_ACCOUNT_MEMBER_IS_NOT_FOUND = '2033',
    E4151_ADDITION_TRIAL_ALREADY_REQUESTED = '4151',
    E10001_AM_ADMIN_RIGHTS_REQUIRED = '10001',
    E10002_ACCOUNT_ALREADY_EXISTS_OR_USER_IS_MANAGER = '10002',
    E10003_ACTIVE_SUBSCRIPTION_NOT_FOUND = '10003',
    E10004_SUBSCRIPTION_CAPACITY_LIMIT_REACHED = '10004',
    E10005_ACCOUNT_OWNER_IS_SAME = '10005',
    E10006_SHORT_TEXT_FOR_SEARCH = '10006',
    E10007_SUBSCRIPTION_NOT_FOUND = '10007',
    E10008_MAX_TRIAL_LICENSES_LIMIT = '10008',
    E10009_PARTNER_ACCOUNT_REQUIRED = '10009',
    E10010_TRIAL_DATE_INVALID_OR_EMPTY = '10010',
    E10011_LICENCE_MUST_BE_TRIAL = '10011',
    E10012_ACCOUNT_NOT_FOUND = '10012',
    E10013_PAID_SUBSCRIPTION_EXISTS = '10013',
    E10014_AVANGATE_SUBSCRIPTION_IS_ALREADY_BOUND = '10014',
    E10015_NOT_RETIRED_ORGANISATION_EXISTS = '10015',
    E10021_PARTNER_ACCOUNT_IS_ALREADY_ACTIVATED = '10021',
    E11000_STRIPE_COMMON_ERROR = '11000',
    E11001_STRIPE_PAYMENT_METHOD_NOT_AUTHENTICATED = '11001',
    E11002_STRIPE_CARD_DECLINED = '11002',
    E11003_STRIPE_TAX_ID_INVALID = '11003',
    E11004_STRIPE_AMOUNT_TOO_LARGE_ERROR = '11004',
    E11005_STRIPE_PHONE_INVALID = '11005',
    E11006_STRIPE_EMAIL_INVALID = '11006',
    E12001_ORGANISATION_IS_BUSY_WITH_TRANSFER = '12001',
    E12007_TARGET_EMAIL_NOT_FOUND_ACCOUNT_TRANSFER = '12007',
    E12008_CHECK_TARGET_EMAIL_ACCOUNT_TRANSFER = '12008',
    E12009_ACCOUNT_IS_ALREADY_IN_PROCESS_OF_TRANSFER = '12009',
    E12010_ACCOUNT_HAS_TRANSFER_OF_ORGANISATIONS = '12010',
    E12011_ACCOUNT_HAS_TRANSFER_OF_SUBSCRIPTIONS = '12011',
    E12012_SOURCE_AND_TARGET_ACCOUNT_ARE_SAME = '12012',
    E12014_TARGET_USER_MUST_BE_OWNER_ACCOUNT = '12014',
    E12017_TARGET_USER_IS_NOT_FOUND = '12017',
    E13001_PREPARATION_FOR_SYNC = '13001',
    E14001_CONSENT_SOURCE_NOT_FOUND = '14001',
}
