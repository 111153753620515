import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, LOAD_PAGE_DATA } from '../../actions';
import { AirwallexContext } from '../../data/Airwallex/AirwallexContext';

export type AirwallexContextType = ImmutableObject<AirwallexContext>;

const INITIAL_STATE: AirwallexContextType = undefined as any;

export default function (state = INITIAL_STATE, action: Action): AirwallexContextType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return immutable(action.payload.airwallexContext!);

        default:
            return state;
    }
}
