import { createPopupReducer } from 'modules/react-redux';

import { CANCEL_ACTIVE_POPUP } from '../../actions';
import dearIntegrationPopupReducer from './activePopup/dearIntegrationPopupReducer';
import integrationErrorPopupReducer from './activePopup/integrationErrorPopupReducer';
import netSuiteIntegrationPopupReducer from './activePopup/netSuiteIntegrationPopupReducer';
import xeroIntegrationSelectPopupReducer from './activePopup/xeroIntegrationSelectPopupReducer';

export default createPopupReducer(
    CANCEL_ACTIVE_POPUP,
    xeroIntegrationSelectPopupReducer,
    integrationErrorPopupReducer,
    netSuiteIntegrationPopupReducer,
    dearIntegrationPopupReducer
);
