import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowsPage.IntegrationHeader', {
    connecting: 'Connecting...',
    connected: 'You are connected to: {company}',
    disconnecting: 'Disconnecting from {company}...',
    disconnected: 'Disconnected from {company}',
    disconnectButton: 'Disconnect',
    reconnectButton: 'Reconnect',
    error: 'Disconnected from {company}{br}{reason}',
    learnMore: 'Learn more',
});

export const messagesXero = defineMessages('app.WorkflowsPage.IntegrationHeader.xero', {
    title: 'Xero-connected approval workflows',
    descriptionConnecting: 'Please wait, ApprovalMax is connecting to your Xero Organisation.',
});

export const messagesQBO = defineMessages('app.WorkflowsPage.IntegrationHeader.qbo', {
    title: 'QuickBooks Online approval workflows',
    descriptionConnecting: 'Please wait, ApprovalMax is connecting to your QuickBooks Online Organisation.',
});

export const messagesNetSuite = defineMessages('app.WorkflowsPage.IntegrationHeader.netSuite', {
    title: 'Oracle NetSuite Approval Workflows',
    descriptionConnecting: 'Please wait, ApprovalMax is almost connected to your Oracle NetSuite company',
});

export const messagesCin7 = defineMessages('app.WorkflowsPage.IntegrationHeader.cin7', {
    title: 'Cin7 Core Approval Workflows',
    descriptionConnecting: 'Please wait, ApprovalMax is almost connected to your Cin7 Core company',
});
