import { Mods, mods } from '@approvalmax/theme';
import { TransparentButton } from '@approvalmax/ui/src/old/ui';
import styled from 'styled-components';

export const Root = styled.div`
    background: #fff;
    border-radius: 0.5rem;
    box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.05),
        0 10px 20px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.9rem;
    overflow: hidden;
    padding: 0.2rem;
    position: relative;
`;

export const Item = styled(TransparentButton)<Mods<'active'>>`
    background: transparent;
    border: 1px solid transparent;
    border-radius: 0.4rem;
    display: flex;
    margin: 0;
    padding: 0.2rem 0.4rem;
    text-align: left;
    width: 100%;

    ${mods.active.true`
        border-color: #000;
    `};
`;

export const Name = styled.div`
    margin-left: 5px;
`;
