import { selectors } from 'modules/common';
import { domain } from 'modules/data';

export const getUserStepsIds = (steps: domain.TemplateStep[], user: selectors.types.ExpandedCompanyUser) => {
    return steps.reduce<domain.TemplateStep['id'][]>((total, current) => {
        if (current.participantMatrix.some((line) => line.lineId === user.id)) {
            total.push(current.id);
        }

        return total;
    }, []);
};

export const convertStepsForDropdown = (
    user: selectors.types.ExpandedCompanyUser,
    userOrigin: domain.User | null,
    steps: domain.TemplateStep[],
    stepActiveId: domain.TemplateStep['id'] | null
) => {
    const isOriginUser = user.id === userOrigin?.id;

    return steps
        .filter((step) => !(isOriginUser && step.id === stepActiveId))
        .map((step) => ({ id: step.id, text: step.name }));
};
