import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.TwoFaEnablingWizard.StepBackupEmailVerify', {
    title: 'Two-factor authentication setup',
    subtitle: 'We emailed a verification code to the following address:',
    description: 'Enter the verification code that was sent to your alternative email address.',
    emailCodeLabel: 'Confirmation code',
    emailCodePlaceholder: 'Enter code',
    buttonChangeAddress: 'Change address',
    buttonResendCode: 'Resend code',
    buttonNext: 'Confirm the code',
    errorEmailRequired: 'Email address is required.',
    errorEmailInvalid: 'This email address is not valid.',
    errorCodeRequired: 'Email confirmation code is required',
    notificationCodeResend: 'Code has been sent',
});
