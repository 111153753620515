import type { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import type {
    UseAmaxPayOpenBankingBankAccountMatchXeroBankAccountData,
    UseAmaxPayOpenBankingBankAccountMatchXeroBankAccountPathParams,
} from './useAmaxPayOpenBankingMatchXeroBankAccount.types';

export const useAmaxPayOpenBankingMatchXeroBankAccount = (
    mutationOptions: MutationOptions<
        {},
        RequestDataParams<
            UseAmaxPayOpenBankingBankAccountMatchXeroBankAccountData,
            UseAmaxPayOpenBankingBankAccountMatchXeroBankAccountPathParams
        >
    >
) =>
    useMutateLegacy<
        RequestDataParams<
            UseAmaxPayOpenBankingBankAccountMatchXeroBankAccountData,
            UseAmaxPayOpenBankingBankAccountMatchXeroBankAccountPathParams
        >,
        {}
    >(paymentServicesApiPaths.amaxPayOpenBankingBankAccountMatchXeroBankAccount, { mutationOptions });
