import { ExtractComponentProp } from '@approvalmax/types';
import { Select, Table } from '@approvalmax/ui/src/components';
import { BodyCellProps } from '@approvalmax/ui/src/components/Table/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useCurrentUser } from 'modules/utils';
import { memo, type MouseEventHandler, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';
import { useCompanyBasedOnUrl } from 'shared/hooks/useCompanyBasedOnUrl';

import type { RoleCellProps } from './RoleCell.types';

export const RoleCell = memo<BodyCellProps<selectors.types.ExpandedCompanyUser> & RoleCellProps>((props) => {
    const { hovered, item, value } = props;
    const { onChangeRole, ...cellProps } = props;

    const [open, toggleOpen] = useToggle(false);

    const company = useCompanyBasedOnUrl();
    const me = useCurrentUser();
    const canChangeTeamMembersRoles = useSelector((state) =>
        selectors.company.canChangeTeamMembersRoles(state, company, me)
    );

    const roleItems = useMemo(
        () =>
            [
                domain.CompanyUserRole.Participant,
                domain.CompanyUserRole.Auditor,
                domain.CompanyUserRole.Manager,
                domain.CompanyUserRole.WorkflowManager,
            ].map((role) => ({
                id: role,
                name: selectors.company.getCompanyUserRoleText(role),
            })),
        []
    );

    const onOpenHandle = useCallback(() => {
        toggleOpen();
    }, [toggleOpen]);

    const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
        e.stopPropagation();
    }, []);

    const handleChange = useCallback<ExtractComponentProp<typeof Select, 'onChange'>>(
        (role) => {
            onChangeRole(item.id, role as domain.CompanyUserRole);
        },
        [item.id, onChangeRole]
    );

    return (
        <Table.Cell {...cellProps}>
            <Select
                size='small'
                clearable={false}
                disabled={!canChangeTeamMembersRoles(item.id)}
                hideEndIcon={!(open || hovered)}
                bordered={open || hovered}
                onOpen={onOpenHandle}
                onClick={handleClick}
                onChange={handleChange}
                items={roleItems}
                value={value}
            />
        </Table.Cell>
    );
});

RoleCell.displayName = 'RoleCell';
