import { Layout as LayoutUI } from '@approvalmax/ui/src/components';
import { Notifications } from 'modules/components';
import { FC, memo, PropsWithChildren } from 'react';

import Drawer from '../Drawer/Drawer';
import Header from '../Header/Header';

const Layout: FC<PropsWithChildren> = memo((props) => {
    const { children, ...restProps } = props;

    return (
        <LayoutUI
            drawer={<Drawer />}
            header={
                <>
                    <Notifications />

                    <Header />
                </>
            }
            {...restProps}
        >
            {children}
        </LayoutUI>
    );
});

export default Layout;
