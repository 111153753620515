import { immutable, ImmutableObject } from 'modules/immutable';

import { netSuiteConstants } from '../../../common/constants';
import { Field, FieldSystemPurpose } from '../Field';
import {
    AlwaysTrueCondition,
    AmountType,
    ConditionType,
    MatrixCondition,
    NumericRangeCondition,
    NumericRangeConditionType,
} from '../Matrix';

export function createCondition(field: Field): ImmutableObject<MatrixCondition> {
    if (field.systemPurpose === FieldSystemPurpose.Amount) {
        return immutable<NumericRangeCondition>({
            fieldId: field.id,
            fieldName: field.name,
            fieldSystemPurpose: field.systemPurpose,
            conditionType: ConditionType.NumericRangeCondition,
            numericRangeConditionType: NumericRangeConditionType.Any,
            numericRangeLess: null,
            numericRangeGreaterEquals: null,
            amountType: AmountType.Gross,
        });
    }

    if (
        field.systemPurpose === FieldSystemPurpose.NetSuiteCustom &&
        field.netSuiteField?.type &&
        netSuiteConstants.NUMERIC_CUSTOM_FIELDS.includes(field.netSuiteField.type)
    ) {
        return immutable<NumericRangeCondition>({
            fieldId: field.id,
            fieldName: field.name,
            fieldSystemPurpose: field.systemPurpose,
            conditionType: ConditionType.NumericRangeCondition,
            numericRangeConditionType: NumericRangeConditionType.Any,
            numericRangeLess: null,
            numericRangeGreaterEquals: null,
            amountType: AmountType.Gross,
        });
    }

    return immutable<AlwaysTrueCondition>({
        fieldId: field.id,
        fieldName: field.name,
        fieldSystemPurpose: field.systemPurpose,
        conditionType: null,
        exactValues: [],
    } as AlwaysTrueCondition);
}
