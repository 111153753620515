import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import {
    createAction,
    createAsyncAction,
    createErrorAction,
    createSelectingAction,
    ExtractActions,
} from 'modules/react-redux';
import { api } from 'services/api';

import { AccountInfo } from '../data/account';

export const SHOW_NEW_ORGANISATION_POPUP = 'FIRST-START/SHOW_NEW_ORGANISATION_POPUP';
export const showNewOrganisationPopup = (
    options: { oauthProvider?: domain.OAuthProvider; firstStart?: boolean; fromNoCompanies?: boolean } = {}
) =>
    createSelectingAction(SHOW_NEW_ORGANISATION_POPUP, (state) => ({
        oauthProvider: options.oauthProvider,
        hasAccount: Boolean(selectors.profile.getProfile(state).account),
        firstStart: options.firstStart,
        fromNoCompanies: options.fromNoCompanies,
    }));

export const CREATE_ACCOUNT = 'FIRST-START/CREATE_ACCOUNT';
export const CREATE_ACCOUNT_RESPONSE = 'FIRST-START/CREATE_ACCOUNT_RESPONSE';
export const CREATE_ACCOUNT_FAILURE = 'FIRST-START/CREATE_ACCOUNT_FAILURE';
export const createAccount = (accountInfo: AccountInfo) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(CREATE_ACCOUNT, {
                me: selectors.profile.getProfileUser(state).userEmail,
            }),

        response: async (request) => {
            const response = await api.account.createAccount({
                email: request.me,
                isPotentialPartner: accountInfo.isPartner,
                practiceName: accountInfo.practiceName,
                organisationCount: accountInfo.companyCount,
                clientCount: accountInfo.clientCount,
            });

            return createAction(CREATE_ACCOUNT_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error) => createErrorAction(CREATE_ACCOUNT_FAILURE, error, {}),
    });

export const SKIP_ORGANISATION_CREATION = 'FIRST-START/SKIP_ORGANISATION_CREATION';
export const skipOrganisationCreation = () => createAction(SKIP_ORGANISATION_CREATION, {});

export type Action = ExtractActions<
    typeof createAccount | typeof showNewOrganisationPopup | typeof skipOrganisationCreation
>;
