import { AlertListIcon } from 'modules/sprites';
import { FC, memo } from 'react';

import { BadgeContainer, BadgeIcon, BadgeLabel } from './StatusBadge.styles';
import { StatusBadgeProps } from './StatusBadge.types';

const StatusBadge: FC<StatusBadgeProps> = memo((props) => {
    const { mode, size, tooltip, hasIcon, qa, label, ...container } = props;

    return (
        <BadgeContainer {...container} mode={mode} title={tooltip} size={size} data-qa={qa}>
            {hasIcon && (
                <BadgeIcon size={size}>
                    <AlertListIcon />
                </BadgeIcon>
            )}

            <BadgeLabel>{label}</BadgeLabel>
        </BadgeContainer>
    );
});

export default StatusBadge;
