import { RequestDataParams } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data';

import { companiesApiPaths } from '../../companies/paths';
import { paymentServicesApiPaths } from '../paths';
import { mapData } from './useConnectToAirwallex.map';
import {
    UseConnectToAirwallexData,
    UseConnectToAirwallexResponse,
    UseConnectToAirwallexResponseBackend,
} from './useConnectToAirwallex.types';

export const useConnectToAirwallex = () => {
    const queryClient = useQueryClient();

    return useMutateLegacy<
        RequestDataParams<UseConnectToAirwallexData>,
        UseConnectToAirwallexResponseBackend,
        UseConnectToAirwallexResponse
    >(paymentServicesApiPaths.connectToAirwallex, {
        mapData,
        mutationOptions: {
            onSuccess: (_response, requestData) => {
                queryClient.invalidateQueries([companiesApiPaths.select, { companyId: requestData.data.companyId }]);
            },
        },
    });
};
