import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'shared/data';

import { paymentServicesApiPaths } from '../paths';
import type {
    UseAmaxPayXeroBatchPaymentBillPathParams,
    UseAmaxPayXeroBatchPaymentBillResponse,
} from './useAmaxPayXeroBatchPaymentBill.types';

export const useAmaxPayXeroBatchPaymentBill = (
    pathParams: UseAmaxPayXeroBatchPaymentBillPathParams,
    queryOptions?: QueryOptions<UseAmaxPayXeroBatchPaymentBillResponse>
) =>
    useGetRequest<UseAmaxPayXeroBatchPaymentBillResponse>(paymentServicesApiPaths.amaxPayXeroBatchPaymentBill, {
        pathParams,
        queryOptions,
        apiVersion: 'v2',
    });
