import { Children, cloneElement, ComponentType, memo, MemoExoticComponent, useMemo } from 'react';

import { Step } from './components';
import { StepperContext } from './Stepper.context';
import { isStepComponent } from './Stepper.helper';
import { Root } from './Stepper.styles';
import type { ChildrenComponents, StepperProps } from './Stepper.types';

export const Stepper = memo<StepperProps>((props) => {
    const { children, activeStep = 0, color = 'midnight80', direction = 'horizontal' } = props;

    const childrenArray = Children.toArray(children);
    const steps = childrenArray.filter(isStepComponent).map((step, index) => {
        return cloneElement(step, {
            ...(step.props || {}),
            last: index + 1 === childrenArray.length,
            index,
        });
    });

    const contextValue = useMemo(() => ({ activeStep, color, direction }), [activeStep, color, direction]);

    return (
        <StepperContext.Provider value={contextValue}>
            <Root $direction={direction}>{steps}</Root>
        </StepperContext.Provider>
    );
}) as MemoExoticComponent<ComponentType<StepperProps>> & { displayName: 'Stepper' } & ChildrenComponents;

Stepper.displayName = 'Stepper';
Stepper.Step = Step;
