import { memo, useCallback } from 'react';

import { TooltipImage, TooltipImageButton } from './ImagePreviewButton.styles';
import { ImagePreviewButtonProps } from './ImagePreviewButton.types';

export const ImagePreviewButton = memo((props: ImagePreviewButtonProps) => {
    const { field, openImagePreview } = props;

    const handleClick = useCallback(() => openImagePreview(field), [field, openImagePreview]);

    return (
        <TooltipImageButton onClick={handleClick} noPadding>
            <TooltipImage alt={field.imgAltText} src={field.imgSrc} />
        </TooltipImageButton>
    );
});

ImagePreviewButton.displayName = 'ImagePreviewButton';
