import { produce } from 'immer';
import { stateTree } from 'modules/data';

import { Action, DELETE_TEMPLATE_RESPONSE, SAVE_TEMPLATE_RESPONSE } from '../actions';

export default produce((draft: stateTree.Meta, action: Action): stateTree.Meta => {
    switch (action.type) {
        case SAVE_TEMPLATE_RESPONSE: {
            let templateId = action.payload.templateId;
            let index = draft.creatableTemplates.indexOf(templateId);

            if (action.payload.creatable && index === -1) {
                draft.creatableTemplates.push(templateId);
            }

            if (!action.payload.creatable && index !== -1) {
                draft.creatableTemplates.splice(index, 1);
            }

            const needToAddToViewableTemplates = !draft.viewableTemplates.some((t) => t.id === templateId);
            const needToAddToAllTemplates = !draft.allWorkflows.some((t) => t.id === templateId);

            if (action.payload.request.isNew) {
                const template = action.payload.request.template;
                const newTempleteInfo = {
                    companyId: template.companyId,
                    enabled: template.enabled,
                    id: templateId,
                    integrationCode: template.integrationCode,
                    name: template.displayName,
                };

                if (needToAddToViewableTemplates) {
                    draft.viewableTemplates.push(newTempleteInfo);
                }

                if (needToAddToAllTemplates) {
                    draft.allWorkflows.push(newTempleteInfo);
                }
            } else {
                const template = action.payload.request.template;
                const currentTemplateIndex = draft.viewableTemplates.findIndex((t) => t.id === templateId);

                if (currentTemplateIndex !== -1) {
                    draft.viewableTemplates[currentTemplateIndex] = {
                        companyId: template.companyId,
                        enabled: template.enabled,
                        id: template.id,
                        integrationCode: template.integrationCode,
                        name: template.displayName,
                    };
                }

                const currentTemplateIndexInAllWorkflows = draft.allWorkflows.findIndex((t) => t.id === templateId);

                if (currentTemplateIndexInAllWorkflows !== -1) {
                    draft.allWorkflows[currentTemplateIndexInAllWorkflows] = {
                        companyId: template.companyId,
                        enabled: template.enabled,
                        id: template.id,
                        integrationCode: template.integrationCode,
                        name: template.displayName,
                    };
                }
            }

            return draft;
        }

        case DELETE_TEMPLATE_RESPONSE:
            draft.creatableTemplates = draft.creatableTemplates.filter((x) => x !== action.payload.request.template.id);
            draft.viewableTemplates = draft.viewableTemplates.filter(
                (t) => t.id !== action.payload.request.template.id
            );
            draft.allWorkflows = draft.allWorkflows.filter((t) => t.id !== action.payload.request.template.id);

            return draft;

        default:
            return draft;
    }
});
