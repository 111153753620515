import { selectors } from 'modules/common';
import { ElementFactory } from 'react-bem-factory';

import { ChangeHandler } from '../../WorkflowSettingsPopupContent.types';

export interface SectionSupplierBankAccountRequiredProps {
    isSupplierBankAccountRequired: boolean;
    onChangeSupplierBankAccountRequired: ChangeHandler<boolean>;
    qa: ElementFactory;
    readonly: boolean;
    links: selectors.types.LinksCollection;
}

export enum SupplierBankAccountRequiredOption {
    Filled = 'Filled',
    Empty = 'Empty',
}
