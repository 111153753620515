import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

export const Root = styled.div`
    display: flex;
`;

export const ProgressLine = styled.div<StyledMods<{ width: number; isCurrent?: boolean; isPrev?: boolean }>>`
    height: 5px;
    border-radius: 4px;
    background-color: #cfe1d3;

    ${mods('isCurrent', true)`
        background-color: #384650;
    `};

    ${mods('isPrev', true)`
        background-color: #77AA84;
    `};

    ${({ $width }) => css`
        width: ${$width}px;
    `}

    & + & {
        margin-left: 5px;
    }
`;
