import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.SingletonSteps.ReviewStep', {
    header: 'Review Step',
    removeStepTitle: 'Remove review step',
    note: 'Reviewers will need to review a {templateName} and submit it for further processing.',
    reviewers: 'Reviewers',
    footerText: 'Add Reviewers who will be able to change particular fields before the approval decision is made.',
    addReviewer: 'Add a reviewer',
    defaultReviewer: 'Default Reviewer',
    defaultReviewerDescription:
        'A Default Reviewer is added to a step in case there is no designated Reviewer specified for it.',
});
