import { intl, mathService } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import moment from 'moment';
import { defineMessages } from 'react-intl';

import { getActiveStep } from './requestSelectors';
import { ExpandedTemplate } from './types';
import { ExpandedCompany } from './types/Company';
import { ExpandedRequest, RequestMatchingValidationError, RequestMatchingValidationErrorType } from './types/Request';

const i18nPrefix = 'common/selectors/requestSelectors.QBooks';
const messages = defineMessages({
    lockDateOverrideWarning: {
        id: `${i18nPrefix}.lockDateOverrideWarning`,
        defaultMessage: 'When approved, the date will change to {newDate} because the lock date is set for {lockDate}',
    },
    matchingPolicyViolation_NotMatchedToAnyPurchaseOrder: {
        id: `${i18nPrefix}.matchingPolicyViolation_NotMatchedToAnyPurchaseOrder`,
        defaultMessage: "Bill can't be approved since it is not matched to any Purchase Order.",
    },
    matchingPolicyViolation_MatchedNotAllItems: {
        id: `${i18nPrefix}.matchingPolicyViolation_MatchedNotAllItems`,
        defaultMessage: "Bill can't be approved since not all items are matched.",
    },
});

export const getLockDateInEffect = (
    details:
        | domain.QBooksPurchaseOrderDetails
        | domain.QBooksSalesInvoiceDetails
        | domain.QBooksBillDetails
        | domain.QBooksExpenseDetails
        | domain.QBooksJournalEntryDetails
) => {
    const date = moment.utc(details.date);
    const lockDate = moment.utc(details.lockDate);
    const newDate = moment(lockDate).add(1, 'd');

    return date.isBefore(newDate);
};

export const getLockDateWarningText = (
    request:
        | domain.QBooksPoRequest
        | domain.QBooksInvoiceRequest
        | domain.QBooksBillRequest
        | domain.QBooksExpenseRequest
        | domain.QBooksJournalEntryRequest
) => {
    const lockDateInEffect = getLockDateInEffect(request.details);

    if (
        [domain.RequestStatusV2.Draft, domain.RequestStatusV2.OnApproval, domain.RequestStatusV2.OnHold].includes(
            request.statusV2
        ) &&
        lockDateInEffect &&
        request.details.lockDatePolicy === domain.TemplateSettingsLockDatePolicy.ApproveWithNextDay
    ) {
        const lockDate = moment.utc(request.details.lockDate!);
        const newDate = moment(lockDate).add(1, 'd');

        return intl.formatMessage(messages.lockDateOverrideWarning, {
            newDate: newDate.format('LL'),
            lockDate: lockDate.format('LL'),
        });
    }

    return null;
};

export const getQbooksMatchingValidationError = (
    request: domain.QBooksBillRequest,
    company: domain.Company
): RequestMatchingValidationError | null => {
    const details = request.details;
    const manualMatchingEnabled = company.billMatchingSettings?.manualMatchingEnabled ?? false;
    const showManualMatching = manualMatchingEnabled && selectors.company.getQBOMatchingIsAvailable(company);
    const BillApprovalPolicy = domain.CompanyMatchingSettingsBillApprovalPolicy;

    if (
        !showManualMatching ||
        (request.statusV2 !== domain.RequestStatusV2.OnApproval && request.statusV2 !== domain.RequestStatusV2.OnHold)
    ) {
        return null;
    }

    switch (company.billMatchingSettings.allowApprovalOfNotMatchedBills) {
        case BillApprovalPolicy.WithThreshold: {
            const matchedLineItemsQuantity = details.lineItems.filter((lineItem) => lineItem.isMatched).length;
            const matchedAccountLineItemsQuantity = details.accountLineItems.filter(
                (accountLineItem) => accountLineItem.isMatched
            ).length;

            if (mathService.add(matchedLineItemsQuantity, matchedAccountLineItemsQuantity) < 1) {
                return {
                    type: RequestMatchingValidationErrorType.AtLeastOneMatchingRequired,
                    errorText: intl.formatMessage(messages.matchingPolicyViolation_NotMatchedToAnyPurchaseOrder),
                };
            }

            break;
        }

        case BillApprovalPolicy.Never: {
            const allLineItemsAreMatched = details.lineItems.every((lineItem) => lineItem.isMatched);
            const allAccountLineItemsAreMatched = details.accountLineItems.every(
                (accountLineItem) => accountLineItem.isMatched
            );

            if (!(allLineItemsAreMatched && allAccountLineItemsAreMatched)) {
                return {
                    type: RequestMatchingValidationErrorType.MatchingRequired,
                    errorText: intl.formatMessage(messages.matchingPolicyViolation_MatchedNotAllItems),
                };
            }

            break;
        }
    }

    return null;
};

const canCopyPOToBill = (request: domain.Request, companyTemplates: ExpandedTemplate[]) => {
    const integrationCodeCondition = request.integrationCode === domain.IntegrationCode.QBooksPo;
    const resolutionCondition = request.statusV2 === domain.RequestStatusV2.Approved;
    const templateCondition = companyTemplates.find(
        (template) => template.integrationCode === domain.IntegrationCode.QBooksBill && template.enabled
    );

    return integrationCodeCondition && resolutionCondition && templateCondition;
};

export const getQBooksCommands = (params: {
    request: domain.QBooksRequest;
    company: ExpandedCompany;
    hasCreatableTemplate: boolean;
    myDecisions: ExpandedRequest['myDecisions'];
    flags: ExpandedRequest['flags'];
    companyTemplates: ExpandedTemplate[];
    creatableTemplates: string[];
    me: string;
}): ExpandedRequest['commands'] => {
    const { request, company, hasCreatableTemplate, myDecisions, flags, companyTemplates, creatableTemplates, me } =
        params;

    const { isActiveApprover, isApprover, isAuthor, status, isActiveReviewer } = flags;
    const isManager = company.flags.isManager;
    const hasActiveIntegration = company.flags.hasActiveIntegration;
    const isOnHold = request.statusV2 === domain.RequestStatusV2.OnHold;
    const canForceDecision =
        isManager &&
        (request.statusV2 === domain.RequestStatusV2.OnApproval ||
            isOnHold ||
            (request.statusV2 === domain.RequestStatusV2.Rejected &&
                request.origin === domain.RequestOrigin.ApprovalMax));
    const isMyDraft = isAuthor && status.isDraft;
    const createdInApprovalMax =
        request.origin === domain.RequestOrigin.ApprovalMax || request.origin === domain.RequestOrigin.Email;
    const hasCopyToBillOptions = canCopyPOToBill(request, companyTemplates);
    const isBill = request.integrationCode === domain.IntegrationCode.QBooksBill;
    const isExpense = request.integrationCode === domain.IntegrationCode.QBooksExpense;
    const isPo = request.integrationCode === domain.IntegrationCode.QBooksPo;
    const isJournalEntry = request.integrationCode === domain.IntegrationCode.QBooksJournalEntry;

    const hasWorkflowAccessToReviewStep = selectors.template.getHasAccessToReviewStepByIntegrationCode(
        request.integrationCode
    );
    const hasReviewBetaOrLicense = selectors.company.getIsReviewStepAvailable(company);
    const isReviewer = request.reviewStep?.reviewers.some((reviewer) => reviewer.id === me);
    const canEditInReview = hasReviewBetaOrLicense && hasWorkflowAccessToReviewStep && isActiveReviewer;
    const canCompleteReview = hasWorkflowAccessToReviewStep && isActiveReviewer;
    const canForceReview =
        hasWorkflowAccessToReviewStep &&
        isManager &&
        request.statusV2 === domain.RequestStatusV2.OnReview &&
        request.reviewStep &&
        !isReviewer;
    const canReturnToReview =
        hasReviewBetaOrLicense &&
        hasWorkflowAccessToReviewStep &&
        request.statusV2 === domain.RequestStatusV2.OnApproval &&
        request.reviewStep?.isCompleted &&
        (isManager || isActiveApprover || isReviewer || isAuthor);

    const lockDatePreventsApprove =
        (isBill || isExpense || isPo || isJournalEntry) &&
        getLockDateInEffect(request.details) &&
        request.details.lockDatePolicy === domain.TemplateSettingsLockDatePolicy.LockApproval;

    const hideCopyButton =
        request.integrationCode === domain.IntegrationCode.QBooksVendor &&
        [domain.RequestStatusV2.OnApproval, domain.RequestStatusV2.Approved].includes(request.statusV2);

    const creatableBillTemplateId = companyTemplates.find((companyTemplate) => {
        return companyTemplate.integrationCode === domain.IntegrationCode.QBooksBill;
    })?.id;
    const hasCreatableTemplateBill = creatableBillTemplateId
        ? creatableTemplates.includes(creatableBillTemplateId)
        : false;

    const hideActionsForAdvancedFeatures = selectors.request.getHideActionsForAdvancedFeatures(
        company,
        request.integrationCode
    );

    const hideReverseButton = request.integrationCode !== domain.IntegrationCode.QBooksJournalEntry || hideCopyButton;

    return {
        approve: {
            hidden:
                (isOnHold ? !(isManager || isApprover) : !isActiveApprover) ||
                lockDatePreventsApprove ||
                hideActionsForAdvancedFeatures,
            disabled: lockDatePreventsApprove || isOnHold,
        },
        reject: {
            hidden: (isOnHold ? !(isManager || isApprover) : !isActiveApprover) || hideActionsForAdvancedFeatures,
            disabled: isOnHold,
        },
        forceApprove: {
            hidden: !canForceDecision || hideActionsForAdvancedFeatures,
            disabled: lockDatePreventsApprove,
        },
        forceReject: {
            hidden: !canForceDecision || hideActionsForAdvancedFeatures,
            disabled: request.statusV2 === domain.RequestStatusV2.Rejected,
        },
        revoke: {
            hidden: status.isClosed || myDecisions.length === 0 || hideActionsForAdvancedFeatures || isOnHold,
            disabled: false,
        },
        submit: {
            hidden: true,
            disabled: false,
        },
        startOver: {
            hidden:
                !(hasActiveIntegration && isManager && status.isOpen && request.templateVersionIsObsolete) ||
                hideActionsForAdvancedFeatures ||
                isOnHold,
            disabled: false,
        },
        editSecondary: {
            hidden: status.isClosed || !createdInApprovalMax || !isAuthor || hideActionsForAdvancedFeatures || isOnHold,
            disabled: false,
        },
        delete: {
            hidden: !(isMyDraft && createdInApprovalMax) || hideActionsForAdvancedFeatures || isOnHold,
            disabled: false,
        },
        cancel: {
            hidden: !(isAuthor && createdInApprovalMax && status.isOpen) || hideActionsForAdvancedFeatures || isOnHold,
            disabled: false,
        },
        clone: {
            hidden: !hasCreatableTemplate || hideCopyButton || hideActionsForAdvancedFeatures,
            disabled: false,
        },
        reverse: {
            hidden: !hasCreatableTemplate || hideReverseButton || hideActionsForAdvancedFeatures,
            disabled: false,
        },
        clonePOToBill: {
            hidden: !hasCreatableTemplateBill || !hasCopyToBillOptions,
            disabled: false,
        },
        cloneQuoteToSalesInvoice: {
            hidden: true,
            disabled: true,
        },
        editPrimary: {
            hidden: !canEditInReview || hideActionsForAdvancedFeatures || isOnHold,
            disabled: false,
        },
        completeReview: {
            hidden: !canCompleteReview || hideActionsForAdvancedFeatures,
            disabled: false,
        },
        forceReview: {
            hidden: !canForceReview || hideActionsForAdvancedFeatures,
            disabled: false,
        },
        returnToReview: {
            hidden: !canReturnToReview || hideActionsForAdvancedFeatures,
            disabled: false,
        },
    };
};

export const getQBooksMatchingCommands = (request: ExpandedRequest, profileId: string, readonly?: boolean) => {
    const isBill = request.integrationCode === domain.IntegrationCode.QBooksBill;
    const isPurchaseOrder = request.integrationCode === domain.IntegrationCode.QBooksPo;
    const isApproved = request.statusV2 === domain.RequestStatusV2.Approved;
    const isOpen = [
        domain.RequestStatusV2.OnHold,
        domain.RequestStatusV2.OnApproval,
        domain.RequestStatusV2.OnReview,
    ].includes(request.statusV2);
    const { isActiveApprover, isActiveReviewer, isAuthor, isCompanyManager } = request.flags;
    const activeStep = getActiveStep(request);
    const isActiveApproverInOnHoldRequest = activeStep?.participants.some(
        ({ userId, decision }) => userId === profileId && decision === domain.RequestStepParticipantDecision.NoResponse
    );

    const isAllowedToMatch =
        isAuthor || isCompanyManager || isActiveApprover || isActiveReviewer || isActiveApproverInOnHoldRequest;

    const canAdd = isBill && isOpen && isAllowedToMatch;
    const canDelete = !readonly && isBill && isOpen && isAllowedToMatch;
    const canSee = isBill || (isPurchaseOrder && isApproved);

    return {
        removeMatching: {
            hidden: !canDelete,
            disabled: false,
        },
        addMatching: {
            hidden: !canAdd,
            disabled: !!readonly,
        },
        showMatchingInfo: {
            hidden: !canSee,
            disabled: false,
        },
    };
};
