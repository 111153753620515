import { selectors } from 'modules/common';

import { WorkflowSettingsPopupSectionProps } from '../../WorkflowSettingsPopupContent.types';

export type SectionGoodsReceivedNoteProps = {
    links: selectors.types.LinksCollection;
} & (
    | WorkflowSettingsPopupSectionProps
    | {
          promoView: true;
          company: selectors.types.ExpandedCompany;
      }
);

export enum GoodsReceivedNoteOption {
    yes = 'yes',
    no = 'no',
}
