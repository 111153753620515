import { backend, domain } from 'modules/data';

import { ApiCallOptions, ApiHandler } from '../types';

export default class AccountApi {
    constructor(private _apiHandler: ApiHandler) {}

    private _doApiCall(
        data: any,
        action: string,
        method: ApiCallOptions['method'],
        allowsAnonymous: boolean
    ): Promise<any> {
        return this._apiHandler.doApiCall({
            apiSource: 'myAccount',
            data: data,
            action: action,
            method: method,
            allowsAnonymous: allowsAnonymous,
        });
    }

    public createAccount(transfer: {
        email: string;
        isPotentialPartner: boolean;
        practiceName: string;
        organisationCount: string;
        clientCount: string;
    }): Promise<domain.CreateAccountResponse> {
        return this._doApiCall(transfer, 'accounts/createAccount', 'POST', false);
    }

    public respondToPracticeInvitation(
        editTransfer: backend.transfers.RespondToPracticeInvitationTransfer
    ): Promise<backend.Answer<backend.BaseAnswer>> {
        return this._doApiCall(editTransfer, 'practiceStaff/respondToPracticeInvitation', 'POST', false);
    }

    public getSubscriptionPricingOptions(transfer: { email: string; accountId: string }): Promise<{
        options: Array<domain.PricingOptions>;
    }> {
        return this._doApiCall(
            transfer,
            `subscriptions/getPricingOptions?accountId=${transfer.accountId}`,
            'POST',
            false
        );
    }

    public getApplicableSubscriptions(transfer: { companyIds: string[]; email: string; accountId: string }): Promise<{
        companyToSubscriptions: Array<{ companyId: string; subscriptionIds: string[] }>;
    }> {
        return this._doApiCall(
            transfer,
            `subscriptions/getSuggestedSubscriptions?accountId=${transfer.accountId}`,
            'POST',
            false
        );
    }

    public getReceiptBankSuggestedPlans(transfer: { organisationId: string; accountId: string }): Promise<{
        suggestedPlanIds: Array<domain.CompanyPlan>;
    }> {
        return this._doApiCall(
            transfer,
            `receiptBank/getSuggestedPlans?accountId=${transfer.accountId}`,
            'POST',
            false
        );
    }

    public setReceiptBankPlan(transfer: {
        email: string;
        organisationId: string;
        productPlanId: string;
        accountId: string;
    }): Promise<{
        suggestedPlanIds: Array<domain.CompanyPlan>;
    }> {
        return this._doApiCall(
            transfer,
            `receiptBank/setOrganisationPlan?accountId=${transfer.accountId}`,
            'POST',
            false
        );
    }

    public setCompanySubscription(transfer: {
        companiesWithSubscriptions: Array<{
            companyId: string;
            subscriptionId: string;
        }>;
        accountId: string;
    }): Promise<any> {
        return this._doApiCall(
            transfer,
            `subscriptions/addCompaniesToSubscriptions?accountId=${transfer.accountId}`,
            'POST',
            false
        );
    }

    public subscriptionUpgradePartnerSubscription(transfer: {
        planId: domain.CompanyPlan;
        qty: number;
        cycle: domain.SubscriptionBillingCycle | null;
        currency: domain.NextBillingCurrency | null;
        subscriptionId: string;
        accountId: string;
        email: string;
        organisationIds: string[];
    }): Promise<any> {
        return this._doApiCall(
            transfer,
            `subscriptions/upgradePartnerSubscription?accountId=${transfer.accountId}`,
            'POST',
            false
        );
    }

    public stripeSubscriptionUpgradePartnerSubscription(transfer: {
        productPlan: domain.CompanyPlan;
        quantity: number;
        billingCycle: domain.SubscriptionBillingCycle | null;
        subscriptionId: string;
        accountId: string;
        email: string;
        organisationIds: string[];
    }): Promise<any> {
        return this._doApiCall(
            transfer,
            `stripe/upgradePartnerSubscription?accountId=${transfer.accountId}`,
            'POST',
            false
        );
    }

    public activateTrialOfAllFeatures(transfer: { accountId?: string; organisationId: string }): Promise<any> {
        return this._doApiCall(
            transfer,
            `organisations/activateTrialOfAllFeatures${transfer.accountId ? '?accountId=' + transfer.accountId : ''}`,
            'POST',
            false
        );
    }

    public getLicenseDetails(transfer: { licenseId: string; accountId: string }): Promise<domain.LicenseDetails> {
        return this._doApiCall(transfer, `licenses/getDetails?accountId=${transfer.accountId}`, 'POST', false);
    }

    public collectNurturingData(transfer: {
        accountId: string;
        organisationCountOption: domain.OrganisationCountOption;
    }): Promise<void> {
        return this._doApiCall(
            transfer,
            `accounts/collectNurturingData?accountId=${transfer.accountId}`,
            'POST',
            false
        );
    }

    public getActionsForNewOrganisation(transfer: {
        accountId: string;
        organisationId: string;
    }): Promise<domain.ActionsForNewOrganisation> {
        return this._doApiCall(
            transfer,
            `organisations/getActionsForNewOrganisation?accountId=${transfer.accountId}`,
            'POST',
            false
        );
    }

    public getSubscriptions(transfer: { accountId: string }): Promise<{ subscriptions: domain.Subscription[] }> {
        return this._doApiCall(transfer, `subscriptions/select?accountId=${transfer.accountId}`, 'GET', false);
    }
}
