import { BlockedImage } from '@approvalmax/ui';

import { AppLogo, Root } from './AppCrashedImage.styles';

export const AppCrashedImage = () => {
    return (
        <Root>
            <BlockedImage />

            <AppLogo width={32} height={32} />
        </Root>
    );
};
