import { FC, memo } from 'react';
import styled from 'styled-components';

import BaseImage from './BaseImage';

interface ImageAttentionProps {
    className?: string;
}

const Attention = styled(BaseImage)`
    background-position: 0 -134px;
    height: 37px;
`;

const ImageAttention: FC<ImageAttentionProps> = ({ className }) => {
    return <Attention className={className} />;
};

ImageAttention.displayName = 'ImageAttention';

export default memo(ImageAttention);
