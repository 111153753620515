import { LinksData } from './selectors/types';

export const linksData: LinksData = {
    QBooks: {
        'QBooks-PO': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-purchase-order-requesters',
            'line-items':
                'https://support.approvalmax.com/portal/en/kb/articles/https-support-approvalmax-com-portal-en-kb-articles-how-can-i-hide-categories-or-items-in-quickbooks-online-po',
            'email-to-supplier':
                'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-default-email-to-vendor',
            'goods-received-note':
                'https://support.approvalmax.com/portal/en/kb/articles/does-approvalmax-support-goods-received-note-for-quickbooks-online-po',
        },
        'QBooks-bill': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-quickbooks-online-bill-requesters',
            'line-items':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-hide-categories-items-for-quickbooks-online-bill',
        },
        'QBooks-expense': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-quickbooks-online-expense-requesters',
            'line-items':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-hide-categories-items-for-quickbooks-online-expense',
        },
        'QBooks-vendor': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-quickbooks-online-vendor-requesters',
        },
        'QBooks-journal-entry': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-quickbooks-online-journal-entries-requesters',
        },
        common: {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-purchase-order-requesters',
            'approval-conditions':
                'https://support.approvalmax.com/portal/en/kb/articles/approval-conditions-29-1-2021',
            'safety-catch': 'https://support.approvalmax.com/portal/en/kb/articles/what-is-the-safety-catch-15-1-2021',
            'lock-date-handling': 'https://support.approvalmax.com/portal/en/kb/articles/how-is-a-lock-date-is-handled',
            'approval-decision-policy':
                'https://support.approvalmax.com/portal/en/kb/articles/approval-decision-policy-quickbooks-online',
            'price-checker':
                'https://support.approvalmax.com/portal/en/kb/articles/how-to-enable-disable-price-checker-in-quickbooks-online-document',
        },
    },
    Xero: {
        'Xero-PO': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-an-instruction-for-purchase-order-requesters',
            'email-to-supplier':
                'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-default-email-to-supplier',
            'goods-received-note':
                'https://support.approvalmax.com/portal/en/kb/articles/does-approvalmax-support-goods-received-note-for-xero-po',
        },
        'Xero-bill': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-xero-bill-requesters',
        },
        'Xero-quote': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-xero-quotes',
            'lock-date-handling':
                'https://support.approvalmax.com/portal/en/kb/articles/how-is-a-lock-date-handled-for-xero-quotes',
            'email-to-supplier':
                'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-a-default-email-to-the-customer',
        },
        'Xero-bill-batch-payment': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-xero-batch-payment-requesters',
        },
        'Airwallex-xero-batch-payment': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-airwallex-batch-payment-requesters',
        },
        'Xero-contact': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-xero-contact-requesters',
        },
        'Xero-manual-journal': {
            'lock-date-handling':
                'https://support.approvalmax.com/portal/en/kb/articles/how-is-a-lock-date-handled-for-xero-manual-journals',
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-xero-manual-journal-requesters',
            'posting-preferences': 'https://support.approvalmax.com/portal/en/kb/articles/posting-preferences-for-xero',
        },
        common: {
            'approval-conditions': 'https://support.approvalmax.com/portal/en/kb/articles/approval-conditions',
            'safety-catch': 'https://support.approvalmax.com/portal/en/kb/articles/what-is-the-safety-catch',
            'lock-date-handling':
                'https://support.approvalmax.com/portal/en/kb/articles/how-is-a-lock-date-handled-for-xero-batch-payments',
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-batch-payment-requesters',
            'approval-decision-policy':
                'https://support.approvalmax.com/portal/en/kb/articles/approval-decision-policy#Here_is_how_this_can_be_set_up',
            'supplier-bank-account':
                'https://support.approvalmax.com/portal/en/kb/articles/what-is-the-supplier-bank-account-validation',
            'add-rejected-prefix':
                'https://support.approvalmax.com/portal/en/kb/articles/how-to-enable-adding-a-rejected-prefix-in-the-reference-field-for-xero-originated-documents',
            'capture-via-email':
                'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-approvalmax-capture-via-email',
        },
    },
    NetSuite: {
        'NetSuite-vendor-bill': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-netsuite-bill-requesters',
            'line-items':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-hide-categories-items-for-netsuite-bill',
            'approvalmax-url-field-create':
                'https://support.approvalmax.com/portal/en/kb/articles/approvalmax-url-to-netsuite-settings',
        },
        'NetSuite-PO': {
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-netsuite-purchase-order-requesters',
            'approvalmax-url-field-create':
                'https://support.approvalmax.com/portal/en/kb/articles/approvalmax-url-to-netsuite-settings',
            'email-to-supplier':
                'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-a-default-email-to-the-supplier',
            'line-items':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-hide-categories-items-for-netsuite-po',
        },
        'NetSuite-expense-report': {
            'approvalmax-url-field-create':
                'https://support.approvalmax.com/portal/en/kb/articles/approvalmax-url-to-netsuite-settings',
        },
        'NetSuite-sales-order': {
            'approvalmax-url-field-create':
                'https://support.approvalmax.com/portal/en/kb/articles/approvalmax-url-to-netsuite-settings',
        },
        'NetSuite-vendor-return-authorization': {
            'approvalmax-url-field-create':
                'https://support.approvalmax.com/portal/en/kb/articles/approvalmax-url-to-netsuite-settings',
        },
        common: {
            'approval-conditions':
                'https://support.approvalmax.com/portal/en/kb/articles/approval-conditions-31-3-2022',
            'safety-catch': 'https://support.approvalmax.com/portal/en/kb/articles/what-is-the-safety-catch-4-4-2022',
            'lock-date-handling': 'https://support.approvalmax.com/portal/en/kb/articles/how-is-a-lock-date-handled',
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-an-instruction-for-purchase-order-requesters',
            'approval-decision-policy':
                'https://support.approvalmax.com/portal/en/kb/articles/approval-decision-policy-netsuite',
            'capture-via-email':
                'https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-approvalmax-capture-via-email',
        },
    },
    Dear: {
        'Dear-PO': {},
        common: {
            'approval-conditions': 'https://support.approvalmax.com/portal/en/kb/articles/approval-conditions-dear',
            'fraud-detection-bypass':
                'https://support.approvalmax.com/portal/en/kb/articles/what-is-the-fraud-detection-bypassing-the-approval-workflow-feature-dear',
            'fraud-detection-after-approval':
                'https://support.approvalmax.com/portal/en/kb/articles/what-is-the-fraud-detection-changes-after-approval-setting-dear',
            'approval-decision-policy':
                'https://support.approvalmax.com/portal/en/kb/articles/approval-decision-policy-dear',
        },
    },
    None: {
        common: {
            'approval-conditions':
                'https://support.approvalmax.com/portal/en/kb/articles/approval-conditions-stand-alone-workflows',
            'safety-catch':
                'https://support.approvalmax.com/portal/en/kb/articles/what-is-the-safety-catch-stand-alone-workflows',
            'instructions-for-requesters':
                'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-specify-instructions-for-requesters-stand-alone-workflows',
            'approval-decision-policy':
                'https://support.approvalmax.com/portal/en/kb/articles/https-support-approvalmax-com-portal-en-kb-articles-approval-decision-policy-10-5-2021',
        },
    },
};
