import { immutable, merge, set } from 'modules/immutable';

import {
    Action,
    LOAD_MORE_REQUESTS,
    LOAD_MORE_REQUESTS_FAILURE,
    LOAD_MORE_REQUESTS_RESPONSE,
    LOAD_MORE_SEARCH_RESULTS,
    LOAD_MORE_SEARCH_RESULTS_RESPONSE,
    LOAD_PAGE_DATA,
    LOAD_SEARCH_RESULTS_DATA_RESPONSE,
    RELOAD_REQUEST_LIST,
    RELOAD_REQUEST_LIST_FAILURE,
    RELOAD_REQUEST_LIST_RESPONSE,
} from '../../actions';

export interface Pagination {
    pageIndex: number;
    hasMore: boolean;
    continuationToken?: string;
    loadingMore: boolean;
    reloading: boolean;
}

export type PaginationType = Pagination;

const INITIAL_STATE: PaginationType = immutable<Pagination>({
    pageIndex: 0,
    hasMore: false,
    loadingMore: false,
    reloading: false,
});

export default function (state = INITIAL_STATE, action: Action): PaginationType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            state = set(state, 'hasMore', action.payload.hasMore);
            state = set(state, 'continuationToken', action.payload.continuationToken);

            return state;

        case LOAD_SEARCH_RESULTS_DATA_RESPONSE:
            return merge(state, {
                hasMore: action.payload.hasMore,
                loadingMore: false,
            });

        case RELOAD_REQUEST_LIST:
            return set(state, 'reloading', true);

        case RELOAD_REQUEST_LIST_RESPONSE:
            return merge(state, {
                pageIndex: action.payload.pageIndex,
                hasMore: action.payload.hasMore,
                reloading: false,
            });

        case RELOAD_REQUEST_LIST_FAILURE:
            return set(state, 'reloading', false);

        case LOAD_MORE_REQUESTS:
        case LOAD_MORE_SEARCH_RESULTS:
            return set(state, 'loadingMore', true);

        case LOAD_MORE_REQUESTS_RESPONSE:
            return merge(state, {
                pageIndex: action.payload.request.pageIndex,
                hasMore: action.payload.hasMore,
                loadingMore: false,
                continuationToken: action.payload.continuationToken,
            });

        case LOAD_MORE_SEARCH_RESULTS_RESPONSE:
            return merge(state, {
                pageIndex: action.payload.request.pageIndex,
                hasMore: action.payload.hasMore,
                loadingMore: false,
            });

        case LOAD_MORE_REQUESTS_FAILURE:
            return set(state, 'loadingMore', false);

        default:
            return state;
    }
}
