import { FC } from 'react';

import { domain } from '../../../modules/data';
import { NetSuiteCacheManagementPanel, xero } from '../../../modules/integration';
import { IntegrationSyncDialogProps } from './IntegrationSyncDialog.types';

const IntegrationSyncDialog: FC<IntegrationSyncDialogProps> = (props) => {
    const { company } = props;

    const integrationType = company.integration?.integrationType;

    switch (integrationType) {
        case domain.IntegrationType.Xero:
            return <xero.CacheManagementPanel companyId={company.id} />;

        case domain.IntegrationType.NetSuite:
            return <NetSuiteCacheManagementPanel companyId={company.id} />;

        default:
            return null;
    }
};

export default IntegrationSyncDialog;
