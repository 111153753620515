import { useGetRequestLegacy } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import {
    UseAmaxPayOpenBankingBanksOptions,
    UseAmaxPayOpenBankingBanksRequestParams,
    UseAmaxPayOpenBankingBanksRequestPathParams,
    UseAmaxPayOpenBankingBanksResponse,
} from './useAmaxPayOpenBankingBanks.types';

export const useAmaxPayOpenBankingBanks = (
    options: Omit<UseAmaxPayOpenBankingBanksOptions, 'params' | 'pathParams' | 'method'> &
        Pick<NonNullable<UseAmaxPayOpenBankingBanksOptions>, 'queryOptions'> & {
            params?: UseAmaxPayOpenBankingBanksRequestParams;
        } & { pathParams: UseAmaxPayOpenBankingBanksRequestPathParams }
) =>
    useGetRequestLegacy<UseAmaxPayOpenBankingBanksResponse>(
        paymentServicesApiPaths.getAmaxPayOpenBankingBanks,
        options.pathParams,
        {
            ...options,
            method: 'get',
        }
    );
