import { selectors } from 'modules/common';
import { domain } from 'modules/data';

import { SourceOfDocs } from './WorkflowWizardPopup.types';

export const getPossibleSourceOfDocument = (
    template: selectors.types.ExpandedTemplate,
    company: selectors.types.ExpandedCompany
) => {
    if (
        template.integrationCode === null ||
        [domain.IntegrationCode.QBooksPo, domain.IntegrationCode.XeroQuote].includes(template.integrationCode)
    ) {
        return [SourceOfDocs.ApprovalMax];
    }

    if (
        [domain.IntegrationCode.XeroCreditNotesReceivable, domain.IntegrationCode.XeroCreditNotesPayable].includes(
            template.integrationCode
        )
    ) {
        return [SourceOfDocs.Xero];
    }

    if ([domain.IntegrationCode.XeroPo, domain.IntegrationCode.XeroInvoice].includes(template.integrationCode)) {
        return [SourceOfDocs.ApprovalMax, SourceOfDocs.Xero];
    }

    if (template.integrationCode === domain.IntegrationCode.XeroBill) {
        const possibleSourceOfDocument = [SourceOfDocs.ApprovalMax, SourceOfDocs.Xero];

        if (company.receiptBankIntegration?.isConnected) {
            possibleSourceOfDocument.push(SourceOfDocs.Dext);
        }

        return possibleSourceOfDocument;
    }

    if ([domain.IntegrationCode.QBooksBill, domain.IntegrationCode.QBooksExpense].includes(template.integrationCode)) {
        const possibleSourceOfDocument = [SourceOfDocs.ApprovalMax];

        if (company.receiptBankIntegration?.isConnected) {
            possibleSourceOfDocument.push(SourceOfDocs.Dext);
        }

        return possibleSourceOfDocument;
    }

    return [];
};
