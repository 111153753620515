import { theme } from '@approvalmax/theme';
import { Box } from '@approvalmax/ui/src/components';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
    opacity: 0;
    transition: opacity ${theme.duration.medium}ms linear;
`;

export const Root = styled(Box)`
    display: flex;
    justify-content: space-between;

    &:hover {
        ${DropdownContainer} {
            opacity: 1;
        }
    }
`;
