import { backend, domain } from 'modules/data';

import { parseQBooksTaxCode } from './QBooksTaxCode';

export function parseQBooksAccount(
    value: backend.IntegrationsQBooksAccount,
    textWithCurrency?: boolean
): domain.QBooksAccount {
    return {
        id: value.Id,
        text: `${value.Name}${textWithCurrency && value.CurrencyCode ? ` (${value.CurrencyCode})` : ''}`,
        code: value.Code,
        taxCode: value.TaxCode ? parseQBooksTaxCode(value.TaxCode) : undefined,
        type: value.Type as domain.QBooksAccountType,
        classification: value.Classification as domain.QBooksAccountType,
        currency: value.CurrencyCode ? { id: value.CurrencyCode, text: value.CurrencyCode } : undefined,
        currencyCode: value.CurrencyCode,
        fullyQualifiedName: value.FullyQualifiedName,
        parentId: value.ParentId,
    };
}
