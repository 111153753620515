import { domain } from 'modules/data';

import { messages } from './CacheManagementPanel.messages';

export const cacheTypeNames = {
    [domain.IntegrationCacheType.XeroOrganizations]: messages.cacheRowOrganisations,
    [domain.IntegrationCacheType.XeroItems]: messages.cacheRowItems,
    [domain.IntegrationCacheType.XeroAccounts]: messages.cacheRowAccounts,
    [domain.IntegrationCacheType.XeroTaxRates]: messages.cacheRowTaxRates,
    [domain.IntegrationCacheType.XeroTrackingCategories]: messages.cacheRowTrackingCategories,
    [domain.IntegrationCacheType.XeroBrandingThemes]: messages.cacheRowBranding,
    [domain.IntegrationCacheType.XeroCurrencies]: messages.cacheRowCurrencies,
    [domain.IntegrationCacheType.XeroCounterparties]: messages.cacheRowCounterparties,
};

export const lastSyncDateTimeFormat = 'D MMM, LT';
