import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { SelectProps } from '../../Select.types';

export const MenuItemsWrapper = styled.div`
    max-height: 220px;
    overflow-y: auto;
`;

export const ActionItemWrapper = styled.div`
    border-top: 2px solid ${theme.color.midnight40};
`;

export const Root = styled.div<StyledMods<Pick<SelectProps<Record<string, any>>, 'display'>>>`
    display: ${({ $display }) => $display};
    max-width: 100%;
`;

export const Hint = styled.div<StyledMods<Mods<'invalid'>>>`
    margin-top: 8px;
    color: ${theme.color.midnight70};
    ${mixins.font('body', 'xsmall', 'regular')};

    ${mods.invalid.true`
        color: ${theme.color.red100};
    `}
`;
