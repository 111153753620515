import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';

import { useGetRequestLegacy } from '../../../api';
import { reportsApiPaths } from '../paths';
import { UseGetReportsParams, UseGetReportsResponse, UseGetReportsResponseBackend } from './useGetReports.types';

export const useGetReports = (
    params: QueryParams<UseGetReportsParams>,
    queryOptions?: QueryOptions<UseGetReportsResponseBackend, UseGetReportsResponse>
) => {
    return useGetRequestLegacy<UseGetReportsResponseBackend, UseGetReportsResponse>(
        reportsApiPaths.getReports,
        params,
        {
            queryOptions: {
                ...queryOptions,
                enabled: enabledByParams<UseGetReportsParams>(
                    {
                        reportCodes: params.reportCodes,
                        companyId: params.companyId,
                    },
                    queryOptions
                ),
            },
        }
    );
};
