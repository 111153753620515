import { Box, Button, Grid, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { integrationActions, IntegrationSuccessRedirectPage } from 'modules/integration';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getIntegrationIcon } from '../../SyncDropdownContent.helpers';
import { messages } from './DisconnectedCompany.messages';
import { DisconnectedCompanyProps } from './DisconnectedCompany.types';

export const DisconnectedCompany: FC<DisconnectedCompanyProps> = memo((props) => {
    const { company, onPopupClose } = props;

    const isConnectingToIntegration = useSelector((state) =>
        selectors.ui.isOperationInProgress(state, integrationActions.CONNECT_TO_INTEGRATION)
    );
    const dispatch = useDispatch();

    const IntegrationIcon = getIntegrationIcon(company.integration?.integrationType);

    const canReconnect = company.flags.isManager || company.flags.isWorkflowManager;

    const onReconnect = useCallback(() => {
        const integrationType = company.integration?.integrationType;

        if (!integrationType) {
            return;
        }

        switch (integrationType) {
            case domain.IntegrationType.NetSuite: {
                dispatch(
                    integrationActions.showNetSuiteIntegrationPopup(company.id, IntegrationSuccessRedirectPage.Workflow)
                );

                onPopupClose();

                break;
            }

            case domain.IntegrationType.Dear: {
                dispatch(
                    integrationActions.showDearIntegrationPopup(company.id, IntegrationSuccessRedirectPage.Workflow)
                );

                onPopupClose();

                break;
            }

            default:
                dispatch(
                    integrationActions.connectToIntegration({
                        integrationType,
                        companyId: company.id,
                        finalRedirectToPage: 'workflow',
                        showGlobalProgress: true,
                    })
                );
        }
    }, [dispatch, company, onPopupClose]);

    return (
        <Box spacing='12 24'>
            <Grid gridTemplateColumns='auto 1fr auto' alignItems='center' gap={12}>
                {IntegrationIcon && <IntegrationIcon width={44} height={44} />}

                <div>
                    <Text font='body' fontSize='small' color='midnight70'>
                        {company.displayName}
                    </Text>

                    <Text font='body' fontSize='xsmall' fontWeight='medium' color='red100'>
                        {messages.disconnectedFromText({
                            integrationName: company.integration?.displayName,
                        })}
                    </Text>
                </div>

                {canReconnect && (
                    <Button
                        size='small'
                        color='blue80'
                        disabled={isConnectingToIntegration || company.isReadonly}
                        onClick={onReconnect}
                    >
                        {messages.reconnectButtonText}
                    </Button>
                )}
            </Grid>
        </Box>
    );
});

DisconnectedCompany.displayName = 'DisconnectedCompany';
