import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.twoFa.TwoFaDisablingPopup.MethodSelectPopup', {
    title: 'How would you like to authenticate your action?',
    appCodeRadio: 'Authenticator app',
    emailCodeRadio: 'Alternative email address',
    backupCodeRadio: 'Backup code',
    nextButton: 'Proceed',
    note: 'If you have problems, refer to this <supportlink>support article</supportlink>.',
});
