import { selectors } from 'modules/common';
import { domain } from 'modules/data';

export const isGrnAvailable = (company: selectors.types.ExpandedCompany, integration?: domain.IntegrationType) => {
    switch (integration) {
        case undefined:
            return company.licenseFeatures.includes(domain.CompanyLicenseFeature.GRN);

        case domain.IntegrationType.Xero:
            return company.licenseFeatures.includes(domain.CompanyLicenseFeature.GRN);

        case domain.IntegrationType.QBooks:
            return company.licenseFeatures.includes(domain.CompanyLicenseFeature.GRN);

        default:
            return false;
    }
};
