import { Checkbox } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionDearFraudChangesAfter.messages';
import { SectionDearFraudChangesAfterProps } from './SectionDearFraudChangesAfter.types';

const SectionDearFraudChangesAfter: FC<SectionDearFraudChangesAfterProps> = memo((props) => {
    const { company, templateSettings, onChange, readonly: readonly, links, qa } = props;

    const { Supplier, Amount, Date, Memo, AdditionalChargeLine, ItemLine, Location } =
        domain.TemplateSettingsDearPostApprovalChangeDetectionTarget;

    const settings = templateSettings.dearPostApprovalChangeDetectionSettings;

    const handleChange = useCallback(
        (newTargets: domain.TemplateSettingsDearPostApprovalChangeDetectionTarget[]) => {
            onChange((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                dearPostApprovalChangeDetectionSettings: prevTemplateSettings.dearPostApprovalChangeDetectionSettings
                    ? {
                          ...prevTemplateSettings.dearPostApprovalChangeDetectionSettings,
                          targets: newTargets,
                      }
                    : undefined,
            }));
        },
        [onChange]
    );

    if (!settings) {
        return null;
    }

    return (
        <SettingsSection title={messages.title} learnMoreLink={links[selectors.types.Link.FraudDetectionAfterApproval]}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <Checkbox.Group
                    name='detectionChangesAfterApproval'
                    disabled={readonly}
                    onChange={handleChange}
                    value={settings.targets}
                    block
                >
                    <Checkbox value={Supplier} data-qa={qa(`DearFraudDetectionChangesAfterSection-${Supplier}`)}>
                        {messages.optionSupplier}
                    </Checkbox>

                    <Checkbox value={Amount} data-qa={qa(`DearFraudDetectionChangesAfterSection-${Amount}`)}>
                        {messages.optionAmount({
                            companyCurrencyCode: company.defaultCurrencyText,
                        })}
                    </Checkbox>

                    <Checkbox value={Date} data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Date}`)}>
                        {messages.optionDate}
                    </Checkbox>

                    <Checkbox value={Location} data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Location}`)}>
                        {messages.optionLocation}
                    </Checkbox>

                    <Checkbox value={Memo} data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Memo}`)}>
                        {messages.optionMemo}
                    </Checkbox>

                    <Checkbox value={ItemLine} data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${ItemLine}`)}>
                        {messages.optionItemLine}
                    </Checkbox>

                    <Checkbox
                        value={AdditionalChargeLine}
                        data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${AdditionalChargeLine}`)}
                    >
                        {messages.optionAdditionalChargeLine}
                    </Checkbox>
                </Checkbox.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionDearFraudChangesAfter;
