import { backend } from 'modules/data';

import { ApiHandler } from '../types';

export default class QBooksMatchingApi {
    constructor(private _apiHandler: ApiHandler) {}

    addMatching(data: { lineItemId: string; matchedLineItemId: string; companyId: string }): Promise<any> {
        return this._apiHandler.doApiCall({
            data,
            action: 'matching/qbooks/add',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    getMatchingInfo(data: {
        billInvoiceLineItemId?: string;
        expenseInvoiceLineItemId?: string;
        purchaseOrderLineItemId?: string;
        companyId: string;
    }): Promise<backend.Answer<backend.QBOMatchingInfo>> {
        return this._apiHandler.doApiCall({
            data,
            action: 'matching/qbooks/getInfo',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    getMatchingInfoV2(data: {
        billInvoiceLineItemId?: string;
        expenseInvoiceLineItemId?: string;
        purchaseOrderLineItemId?: string;
        companyId: string;
    }): Promise<backend.Answer<backend.QBOMatchingInfo[]>> {
        return this._apiHandler.doApiCall({
            data,
            action: 'matching/qbooks/getInfoV2',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    deleteMatching(data: { lineItemId: string; companyId: string }): Promise<any> {
        return this._apiHandler.doApiCall({
            data,
            action: 'matching/qbooks/delete',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
