import { Divider } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { messages } from './PopupFooter.messages';
import { ButtonsContainter, Root, StyledTextButton } from './PopupFooter.styles';
import { PopupFooterProps } from './PopupFooter.types';

const PopupFooter: FC<PopupFooterProps> = memo((props) => {
    const { withBackButton, isLoading, onBack, onSkip } = props;

    return (
        <Root>
            {withBackButton && <Divider color='midnight50' spacing='0 0 16' />}

            <ButtonsContainter>
                <StyledTextButton $toRight={!withBackButton} disabled={isLoading} execute={onSkip} upperCased={false}>
                    {messages.skip}
                </StyledTextButton>

                {withBackButton && (
                    <StyledTextButton disabled={isLoading} $toRight execute={onBack} upperCased={false}>
                        {messages.back}
                    </StyledTextButton>
                )}
            </ButtonsContainter>
        </Root>
    );
});

export default PopupFooter;
