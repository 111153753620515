import { atom, selector } from 'recoil';

export const checkOcrStatusTimeoutIdState = atom<NodeJS.Timeout | null>({
    key: 'checkOcrStatusTimeoutIdState',
    default: null,
});

export const checkOcrStatusTimeoutIdSelector = selector({
    key: 'checkOcrStatusIdSelectorState',
    get: ({ get }) => {
        return get(checkOcrStatusTimeoutIdState);
    },
    set: ({ set, get }, newValue) => {
        const checkOcrStatusId = get(checkOcrStatusTimeoutIdState);

        set(checkOcrStatusTimeoutIdState, newValue);

        if (newValue === null && checkOcrStatusId !== null) {
            clearTimeout(checkOcrStatusId);
        }
    },
});

export const ocrIsLoadingState = atom({
    key: 'ocrIsLoadingState',
    default: false,
});
