import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SHOW_EDIT_WATCHERS_POPUP } from '../../../actions';

export const EDIT_WATCHERS_POPUP = 'EDIT_WATCHERS_POPUP';

export interface EditWatchersPopupData {
    id: typeof EDIT_WATCHERS_POPUP;
    requestId: Guid;
}

export type EditWatchersPopupType = ImmutableObject<EditWatchersPopupData> | null;

export default function (state: EditWatchersPopupType, action: Action): EditWatchersPopupType {
    switch (action.type) {
        case SHOW_EDIT_WATCHERS_POPUP:
            return immutable<EditWatchersPopupData>({
                id: EDIT_WATCHERS_POPUP,
                requestId: action.payload.requestId,
            });

        default:
            return state;
    }
}
