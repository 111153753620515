import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('auth.AuthErrorPopup', {
    title: '{name},{br}you were logged out',
    description:
        'You were inactive for a long time and we have locked your screen. Please re-enter your password to continue working.',
    descriptionWithoutPassword: 'You were inactive for a long time and we have locked your screen.',
    passwordField: 'Enter your password',
    loginButton: 'Login',
    twoFaField: 'Enter 2-Factor authentication code',
    twoFaButton: 'Confirm',
    trustThisDevice: 'Trust this device',
    goToLogin: 'Go to login screen',
});
