import { Text } from '@approvalmax/ui/src/components';
import moment from 'moment';
import { memo } from 'react';

import { DateCellProps } from './DateCell.types';

export const DateCell = memo<DateCellProps>((props) => {
    const { value, className } = props;

    return (
        <Text font='body' ellipsis={1} className={className}>
            {moment(value).format('ll')}
        </Text>
    );
});

DateCell.displayName = 'DateCell';
