import { domain } from 'modules/data';

export interface XeroApproveReviewStepProps {
    stepIndex: number;
    step: domain.TemplateStep;
}

export const enum ActiveTab {
    Approvers = 'Approvers',
    Reviewers = 'Reviewers',
}
