import { Identifiable, Reference } from '@approvalmax/types';
import { errorHelpers, numberHelpers } from '@approvalmax/utils';
import camelCase from 'lodash/camelCase';
import difference from 'lodash/difference';
import reduce from 'lodash/reduce';
import upperFirst from 'lodash/upperFirst';
import type { CamelCasedPropertiesDeep } from 'type-fest';

interface KeyMap {
    [sourcePropName: string]: string;
}

type MaybeExtractMappedKey<
    KeyMap extends Partial<Record<string, string>>,
    Key extends keyof KeyMap,
> = KeyMap[Key] extends {} ? KeyMap[Key] : Key;

type RemappedValueOf<Key extends string, T extends Record<Key, any>, KeyMap extends Partial<Record<Key, string>>> = {
    [sourcePropName in MaybeExtractMappedKey<KeyMap, Key>]: T[Key];
};

export function getBeautifiedEntity<
    T extends Record<string, any>,
    KeyMap extends Partial<Record<keyof T, string>> = {},
>(entity: T, keyMap?: KeyMap) {
    return reduce(
        entity,
        (acc, value, fieldName) => {
            const key = (keyMap?.[fieldName] || camelCase(fieldName)) as keyof typeof acc;

            acc[key] = value;

            return acc;
        },
        {} as CamelCasedPropertiesDeep<Omit<T, keyof KeyMap>> &
            RemappedValueOf<Extract<keyof KeyMap, string>, T, KeyMap>
    );
}

export function getDisfiguredEntity(entity: any, keyMap: KeyMap = {}) {
    return reduce(
        entity,
        (s, v, k) => {
            const key = keyMap[k] || upperFirst(k);

            s[key] = v;

            return s;
        },
        {} as any
    );
}

export function getForwardEnumMapper<TSource extends {}, TTarget extends {}>(sourceEnum: TSource, targetEnum: TTarget) {
    if (process.env.DEBUG) {
        const sourceKeys = Object.keys(sourceEnum).filter((k) => !numberHelpers.isNumber(Number(k)));
        const targetValues = Object.values(targetEnum);
        const missingMembers = difference(sourceKeys, targetValues);

        if (missingMembers.length > 0) {
            throw errorHelpers.formatError(
                `[Enum Mapper]: The following members of the backend enum are missing in the domain enum: ${missingMembers.join(
                    ', '
                )}. Please check if the server model has changes and correct the domain model accordingly.`
            );
        }
    }

    return (value: TSource[keyof TSource]): TTarget[keyof TTarget] => {
        return (sourceEnum as any)[value];
    };
}

export function getBackwardEnumMapper<TSource extends {}, TTarget extends {}>(
    sourceEnum: TSource,
    targetEnum: TTarget
) {
    if (process.env.DEBUG) {
        const sourceValues = Object.values(sourceEnum);
        const targetKeys = Object.keys(targetEnum).filter((k) => !numberHelpers.isNumber(Number(k)));
        const missingMembers = difference(sourceValues, targetKeys);

        if (missingMembers.length > 0) {
            throw errorHelpers.formatError(
                `[Enum Mapper]: The following members of the backend enum are missing in the domain enum: ${missingMembers.join(
                    ', '
                )}. Please check if the server model has changes and correct the domain model accordingly.`
            );
        }
    }

    return (value: TSource[keyof TSource]): TTarget[keyof TTarget] => {
        return (targetEnum as any)[value];
    };
}

export function getStringEnumMapper<TSource extends {}, TTarget extends {}>(sourceEnum: TSource, targetEnum: TTarget) {
    if (process.env.DEBUG) {
        const sourceValues = Object.values(sourceEnum);
        const targetValues = Object.values(targetEnum);
        const missingMembers = difference(sourceValues, targetValues);

        if (missingMembers.length > 0) {
            throw errorHelpers.formatError(
                `[String Enum Mapper]: The following members of the backend enum are missing in the domain enum: ${missingMembers.join(
                    ', '
                )}. Please check if the server model has changes and correct the domain model accordingly.`
            );
        }
    }

    return (value: TSource[keyof TSource]): TTarget[keyof TTarget] => {
        return (sourceEnum as any)[value];
    };
}

export function getPlainEnumMapper<TSource, TTarget>(map: { [sourceValue: string]: TTarget }) {
    return (value: TSource): TTarget => {
        const result = (map as any)[value];

        if (result == null) {
            throw errorHelpers.formatError(
                `[Plain Enum Mapper]: There is no mapping for source value ${value} in the map.`
            );
        }

        return result;
    };
}

export function parseServerReference(value: { Id: string; Name?: string; Value?: string }): Reference {
    const ref = parseServerReferenceMaybe(value);

    if (!ref) {
        throw errorHelpers.formatError(`Failed to parse reference value from the server.`);
    }

    return ref;
}

export function parseServerReferenceMaybe(
    value: { Id: string; Name?: string; Value?: string } | null | undefined
): Reference | null {
    if (!value) {
        return null;
    }

    return {
        id: value.Id,
        text: value.Name || value.Value || value.Id,
    };
}

export function getReferenceId(value: Identifiable | null | undefined) {
    return value ? value.id : undefined;
}
