import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Activator = styled.button`
    display: flex;
    cursor: pointer;
`;

export const DelegatesText = styled.div`
    white-space: nowrap;
    max-width: 150px;
    ${mixins.ellipsis(1)};
    display: inherit;
    ${mixins.font('label', 'xxsmall', 'medium', 'midnight100')};
`;

export const DelegatesInner = styled.button`
    padding: 4px 8px;
    display: block;
    cursor: pointer;
    text-align: left;
`;

export const Root = styled.div<StyledMods<Mods<'active'>>>`
    ${mods.active.true`
        background-color: ${theme.color.midnight30};
        border-radius: ${theme.radius.xsmall};
        display: flex;
    `};
`;
