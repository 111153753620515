import { Stepper } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { steps } from './StepperTwoFaWizard.config';
import { StepperTwoFaWizardProps } from './StepperTwoFaWizard.types';

export const StepperTwoFaWizard = memo<StepperTwoFaWizardProps>((props) => {
    const { activeStep } = props;

    return (
        <Stepper activeStep={activeStep} color='blue100' direction='horizontal'>
            {steps.map((step, index) => (
                <Stepper.Step label={step.text} key={index} />
            ))}
        </Stepper>
    );
});
