import { compareHelpers, errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, du } from 'modules/data';
import { schema } from 'normalizr';
import type { UseGetReportsResponse } from 'shared/data';

import lineItemVisibilityOrder from '../../config/lineItem/columnDefaultVisibilityOrder';
import columnLineItemDefinitions, {
    getLineItemColumnDefinitionByKind,
} from '../../config/lineItem/columnLineItemDefinitions';
import reportVisibilityOrder from '../../config/standard/columnDefaultVisibilityOrder';
import getColumnDefinitions, { getColumnDefinitionByKind } from '../../config/standard/columnDefinitions';
import ColumnKind from '../../config/standard/columnKind';
import { getReportTypeByCode } from '../../selectors/reportConfigSelectors';
import { FilterType } from '../filters/index';
import * as noneFilter from '../filters/noneFilter';
import { ReportConfigColumn, SortingDirection } from './ReportConfig';

function mapSortingType(sortingType: number) {
    if (sortingType === 0) {
        return SortingDirection.Ascending;
    }

    return SortingDirection.Descending;
}

function mapReportSettings(setting: any, companyId: string, isNewReport: boolean = false): ReportConfigColumn[] {
    let result: Array<{
        reportConfig: ReportConfigColumn;
        visibilityOrder: number;
    }> = [];

    const state = window.ApprovalMax.app.getStore().getState();

    let company: selectors.types.ExpandedCompany;

    try {
        company = selectors.navigation.getActiveCompany(state);
    } catch {
        const selectedCompanyId = selectors.userPreferences.getUserPreference(state, '  ');
        const companies = selectors.company.getCompanies(state);
        const firstCompany = companies[0];
        // this logic is taken from drawer context, need to get rid of preference "last-selected-company-id" and use routing params

        company = selectors.company.findCompanyById(state, selectedCompanyId) || firstCompany;
    }

    getColumnDefinitions(company).forEach((columnDef) => {
        let column = setting[columnDef.kind];

        if (column) {
            result.push({
                reportConfig: {
                    id: columnDef.id,
                    kind: columnDef.kind,
                    name: columnDef.name,
                    title: columnDef.title,
                    sortable: columnDef.sortable,
                    // sortingOrder is ignored since we sort by one column only
                    sorting:
                        Number.isInteger(column.sortingOrder) && Number.isInteger(column.sortingType)
                            ? mapSortingType(column.sortingType)
                            : null,
                    // visibility order is equal to columns order in the resulting array
                    visible: Boolean(column.viewable),
                    filter: columnDef.parseFilter
                        ? columnDef.parseFilter(column.filteringValue, state, companyId)
                        : {
                              type: FilterType.None,
                          },
                },
                visibilityOrder: Number.isInteger(column.viewingOrder) ? column.viewingOrder : Number.MAX_VALUE,
            });
        } else {
            const visibilityIndex = reportVisibilityOrder.indexOf(columnDef.kind);

            result.push({
                reportConfig: {
                    id: columnDef.id,
                    kind: columnDef.kind,
                    name: columnDef.name,
                    title: columnDef.title,
                    sortable: columnDef.sortable,
                    sorting: null,
                    visible: isNewReport ? columnDef.visibleByDefault : false,
                    filter: columnDef.parseFilter
                        ? columnDef.parseFilter(null, state, companyId)
                        : {
                              type: FilterType.None,
                          },
                    exactValuesToSetVisibleByDefault: columnDef.exactValuesToSetVisibleByDefault,
                },
                visibilityOrder: visibilityIndex !== -1 ? visibilityIndex : Number.MAX_VALUE,
            });
        }
    });

    if (setting[ColumnKind.CustomField]) {
        const columnDef = getColumnDefinitionByKind(ColumnKind.CustomField);

        setting[ColumnKind.CustomField].forEach((column: any) => {
            result.push({
                reportConfig: {
                    id: column.fieldId || column.id,
                    kind: ColumnKind.CustomField,
                    name: column.name,
                    title: column.name,
                    sortable: columnDef.sortable,
                    // sortingOrder is ignored since we sort by one column only
                    sorting:
                        Number.isInteger(column.sortingOrder) && Number.isInteger(column.sortingType)
                            ? mapSortingType(column.sortingType)
                            : null,
                    // visibility order is equal to columns order in the resulting array
                    visible: Boolean(column.viewable),
                    filter: columnDef.parseFilter!(column.filteringValue, state, companyId),
                },
                visibilityOrder: Number.isInteger(column.viewingOrder) ? column.viewingOrder : Number.MAX_VALUE,
            });
        });
    }

    if (setting[ColumnKind.QBooksCustomField]) {
        const columnDef = getColumnDefinitionByKind(ColumnKind.QBooksCustomField);

        setting[ColumnKind.QBooksCustomField].forEach((column: any) => {
            result.push({
                reportConfig: {
                    id: column.fieldId || column.id,
                    kind: ColumnKind.QBooksCustomField,
                    name: column.name,
                    title: column.name,
                    sortable: columnDef.sortable,
                    // sortingOrder is ignored since we sort by one column only
                    sorting:
                        Number.isInteger(column.sortingOrder) && Number.isInteger(column.sortingType)
                            ? mapSortingType(column.sortingType)
                            : null,
                    // visibility order is equal to columns order in the resulting array
                    visible: Boolean(column.viewable),
                    filter: noneFilter.createAlwaysTrueFilter(),
                },
                visibilityOrder: Number.isInteger(column.viewingOrder) ? column.viewingOrder : Number.MAX_VALUE,
            });
        });
    }

    if (setting[ColumnKind.QBooksInvoiceCustomField]) {
        const columnDef = getColumnDefinitionByKind(ColumnKind.QBooksInvoiceCustomField);

        setting[ColumnKind.QBooksInvoiceCustomField].forEach((column: any) => {
            result.push({
                reportConfig: {
                    id: column.fieldId || column.id,
                    kind: ColumnKind.QBooksInvoiceCustomField,
                    name: column.name,
                    title: column.name,
                    sortable: columnDef.sortable,
                    // sortingOrder is ignored since we sort by one column only
                    sorting:
                        Number.isInteger(column.sortingOrder) && Number.isInteger(column.sortingType)
                            ? mapSortingType(column.sortingType)
                            : null,
                    // visibility order is equal to columns order in the resulting array
                    visible: Boolean(column.viewable),
                    filter: noneFilter.createAlwaysTrueFilter(),
                },
                visibilityOrder: Number.isInteger(column.viewingOrder) ? column.viewingOrder : Number.MAX_VALUE,
            });
        });
    }

    return result
        .sort(compareHelpers.comparatorFor<(typeof result)[0]>(compareHelpers.numberComparator2Asc, 'visibilityOrder'))
        .map((x) => x.reportConfig);
}

function mapLineItemSettings(
    setting: any,
    companyId: string,
    isNewReport: boolean = false,
    reportCode?: domain.ReportCode
): ReportConfigColumn[] {
    let result: Array<{
        reportConfig: ReportConfigColumn;
        visibilityOrder: number;
    }> = [];

    const state = window.ApprovalMax.app.getStore().getState();

    if (!reportCode) return [];

    columnLineItemDefinitions
        .map((columnDef) => getLineItemColumnDefinitionByKind(columnDef.kind, setting.type || reportCode))
        .forEach((columnDef) => {
            let column = setting[columnDef.kind];

            if (column) {
                result.push({
                    reportConfig: {
                        id: columnDef.id,
                        kind: columnDef.kind,
                        name: columnDef.name,
                        title: columnDef.title,
                        sortable: columnDef.sortable,
                        // sortingOrder is ignored since we sort by one column only
                        sorting:
                            Number.isInteger(column.sortingOrder) && Number.isInteger(column.sortingType)
                                ? mapSortingType(column.sortingType)
                                : null,
                        // visibility order is equal to columns order in the resulting array
                        visible: Boolean(column.viewable),
                        filter: columnDef.parseFilter
                            ? columnDef.parseFilter(column.filteringValue, state, companyId)
                            : {
                                  type: FilterType.None,
                              },
                    },
                    visibilityOrder: Number.isInteger(column.viewingOrder) ? column.viewingOrder : Number.MAX_VALUE,
                });
            } else {
                const visibilityIndex = lineItemVisibilityOrder.indexOf(columnDef.kind);

                result.push({
                    reportConfig: {
                        id: columnDef.id,
                        kind: columnDef.kind,
                        name: columnDef.name,
                        title: columnDef.title,
                        sortable: columnDef.sortable,
                        sorting: null,
                        visible: isNewReport ? columnDef.visibleByDefault : false,
                        filter: columnDef.parseFilter
                            ? columnDef.parseFilter(null, state, companyId)
                            : {
                                  type: FilterType.None,
                              },
                    },
                    visibilityOrder: visibilityIndex !== -1 ? visibilityIndex : Number.MAX_VALUE,
                });
            }
        });

    return result
        .sort(compareHelpers.comparatorFor<(typeof result)[0]>(compareHelpers.numberComparator2Asc, 'visibilityOrder'))
        .map((x) => x.reportConfig);
}

function mapReportConfig(
    value: UseGetReportsResponse['data'][number],
    { companyId, reportType }: { companyId: string; reportType: domain.ReportType }
) {
    const reportConfig = du.getBeautifiedEntity(value);

    return {
        ...reportConfig,
        companyId,
        lastRunDate: reportConfig.lastRunAt ?? undefined,
        createdDate: reportConfig.createdAt,
        reportType: getReportTypeByCode(reportConfig.settings.type),
        reportCode: reportConfig.settings.type,
        columns:
            reportType === domain.ReportType.Request
                ? mapReportSettings(reportConfig.settings, companyId!)
                : mapLineItemSettings(reportConfig.settings, companyId!, false, reportConfig.settings.type),
    };
}

const reportConfigSchema = new schema.Entity(
    'reportConfigs',
    {},
    {
        idAttribute: 'id',
        processStrategy: mapReportConfig,
    }
);

export const createDefaultColumns = (
    companyId: string,
    reportType: domain.ReportType,
    reportCode?: domain.ReportCode
) => {
    switch (reportType) {
        case domain.ReportType.Request:
            return mapReportSettings({}, companyId, true);

        case domain.ReportType.LineItem:
            return mapLineItemSettings({}, companyId, true, reportCode);

        default:
            throw errorHelpers.assertNever(reportType);
    }
};

export default reportConfigSchema;
