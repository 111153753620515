export const combineComparators = <T>(
    comparatorFn: (a: T, b: T) => number,
    fallbackComparatorFn: (a: T, b: T) => number
) => {
    return (a: T, b: T) => {
        let result = comparatorFn(a, b);

        if (result !== 0) {
            return result;
        }

        return fallbackComparatorFn(a, b);
    };
};
