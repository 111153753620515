import { CircleFilledIcon } from '@approvalmax/ui';
import { Box, Collapse, Flex, Link, Text, Tooltip } from '@approvalmax/ui/src/components';
import { domHelpers, errorHelpers } from '@approvalmax/utils';
import { constants, selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { useDispatch } from 'modules/react-redux';
import { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { addRBSubmitter, addTempUser, openInviteUsersPopup, removeRBSubmitter } from '../../actions';
import { getActiveTemplate, isPageReadonly, showCardValidationErrors } from '../../selectors/pageSelectors';
import { getTemplateReceiptBankExternalSubmitter } from '../../selectors/templateSelectors';
import { ExpandedTemplateUser } from '../../types/selectors';
import AddUserButton from '../AddUserButton/AddUserButton';
import StepUser from '../StepUser/StepUser';
import { messages } from './SelectRBSubmitter.messages';
import { SelectRBSubmitterProps } from './SelectRBSubmitter.types';

const { xeroConstants, qBooksConstants } = constants;

const SelectRBSubmitter: FC<SelectRBSubmitterProps> = memo((props) => {
    const { collapsed, onCollapse, cacheId } = props;

    const dispatch = useDispatch();
    const template = useSelector(getActiveTemplate)!;
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, template.companyId));
    const integrationType = company.integration!.integrationType;
    const receiptBankExternalSubmitter = useSelector(getTemplateReceiptBankExternalSubmitter);
    const team = useSelector((state: State) => selectors.company.getCompanyTeam(state, company));
    const readonly = useSelector(isPageReadonly);
    const showValidationErrors = useSelector(showCardValidationErrors);

    const onAddTempUser = useCallback(
        (value: domain.User) => {
            dispatch(addTempUser(value));
        },
        [dispatch]
    );

    const onAddSubmitter = useCallback(
        (user: domain.User) => {
            dispatch(addRBSubmitter(user.id));
        },
        [dispatch]
    );

    const onRemoveSubmitter = useCallback(() => {
        dispatch(removeRBSubmitter());
    }, [dispatch]);

    const onInviteUser = useCallback(
        (user: ExpandedTemplateUser) => {
            dispatch(openInviteUsersPopup([user.id]));
        },
        [dispatch]
    );

    let integrationName;
    let link;

    switch (integrationType) {
        case domain.IntegrationType.QBooks:
            integrationName = messages.qboCompany;
            link = qBooksConstants.QBO_DEXT_LEARN_MORE_LINK;
            break;

        case domain.IntegrationType.Xero:
            integrationName = messages.xeroCompany;
            link = xeroConstants.XERO_DEXT_LEARN_MORE_LINK;
            break;

        case domain.IntegrationType.NetSuite:
        case domain.IntegrationType.Dear:
        case domain.IntegrationType.None:
            throw errorHelpers.notSupportedError();

        default:
            throw errorHelpers.assertNever(integrationType);
    }

    const invalid = showValidationErrors && !receiptBankExternalSubmitter;
    const collapseColor = useMemo(() => {
        if (readonly) return 'silver80';

        if (invalid) return 'red30';

        return undefined;
    }, [invalid, readonly]);

    return (
        <Collapse
            name={messages.bubbleText}
            spacing='0 8'
            localStorageKey={cacheId}
            radius='xsmall'
            alignCollapseIcon='right'
            size='small'
            collapsed={collapsed && !invalid}
            onCollapse={onCollapse}
            bordered
            startIcon={
                receiptBankExternalSubmitter ? (
                    <CircleFilledIcon size={12} color={readonly ? 'silver100' : 'brandDext90'} />
                ) : undefined
            }
            headerContent={
                <>
                    <Text font='body' fontSize='small' color='midnight100'>
                        {receiptBankExternalSubmitter
                            ? messages.stepDescription({
                                  integrationName,
                                  templateName: selectors.template.getTemplateDisplayNameByCode(
                                      template.integrationCode,
                                      true
                                  ),
                              })
                            : messages.emptyStepDescription({
                                  integrationName,
                                  templateName: selectors.template.getTemplateDisplayNameByCode(
                                      template.integrationCode,
                                      true
                                  ),
                              })}
                    </Text>

                    <Link
                        font='label'
                        fontSize='small'
                        href={link}
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={domHelpers.stopPropagation}
                    >
                        {messages.learnMore}
                    </Link>
                </>
            }
            color={collapseColor}
        >
            {receiptBankExternalSubmitter ? (
                <Box spacing='8 0'>
                    <Flex inline direction='column' spacing='8'>
                        <StepUser
                            team={team}
                            user={receiptBankExternalSubmitter}
                            onRemove={onRemoveSubmitter}
                            readonly={readonly}
                            onInvite={onInviteUser}
                            isCompanyMember={team.some((t) => t.id === receiptBankExternalSubmitter.id)}
                        />
                    </Flex>
                </Box>
            ) : (
                <Flex height={40} spacing='8' inline alignItems='center'>
                    <Tooltip
                        open={invalid && !collapsed}
                        color='cobalt100'
                        activator={
                            <AddUserButton
                                users={team}
                                disabled={readonly}
                                onAddNew={onAddTempUser}
                                onSelect={onAddSubmitter}
                                type='step'
                                title={messages.addRequester}
                                invalid={invalid}
                            />
                        }
                    >
                        {messages.addRequester}
                    </Tooltip>
                </Flex>
            )}
        </Collapse>
    );
});

export default SelectRBSubmitter;
