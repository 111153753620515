import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowsPage.StandaloneWorkflowList', {
    title: 'Stand-alone workflows',
    descriptionTrial:
        'ApprovalMax allows to set up stand-alone approval workflows without connecting to an accounting platform. Such workflows could be used for the approval of legal contracts, CapEx decisions, expenses and reimbursements, sales quotes, etc.',
    descriptionNormal:
        'ApprovalMax allows to set up stand-alone approval workflows without connecting to an accounting platform. Such workflows could be used for the approval of legal contracts, CapEx decisions, expenses and reimbursements, sales quotes, etc.',
    learnMore: 'Learn more',
    buttonCreateTip: 'Stand-alone workflow',
    buttonCreateText: 'Create',
    noFound: 'No approval workflow found, please refine the search',
    searchPlaceholder: 'Type workflow name',
});
