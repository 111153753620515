import { domain } from 'modules/data';

import entitiesReducer from './resources/reducers/entitiesReducer';
import navigationReducer from './resources/reducers/navigationReducer';

export const pageConfig = {
    access: [domain.CompanyUserRole.Manager, domain.CompanyUserRole.Auditor],
    integrations: [domain.IntegrationType.Xero, domain.IntegrationType.QBooks],
    store: {
        id: 'budgetingV2',
        reducers: {
            entities: entitiesReducer,
            navigation: navigationReducer,
        },
    },
};
