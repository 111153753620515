import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import {
    UseAirwallexSchedulePaymentData,
    UseAirwallexSchedulePaymentResponse,
} from './useAirwallexSchedulePayment.types';

export const useAirwallexSchedulePayment = () => {
    return useMutateLegacy<RequestDataParams<UseAirwallexSchedulePaymentData>, UseAirwallexSchedulePaymentResponse>(
        requestsApiPaths.airwallexSchedulePayment
    );
};
