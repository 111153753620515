import { Reference } from '@approvalmax/types';

export interface EditableQBooksCounterparty {
    billingAddress: EditableQBooksCounterpartyAddress;
    companyName: string;
    currency: Reference;
    displayName: string;
    email: string;
    familyName: string;
    fax: string;
    givenName: string;
    middleName: string;
    mobilePhone: string;
    primaryPhone: string;
    suffix: string;
    title: string;
    website: string;
}

export interface EditableQBooksCustomer extends EditableQBooksCounterparty {
    shippingAddress: EditableQBooksCounterpartyAddress;
}

export interface EditableQBooksVendor extends EditableQBooksCounterparty {
    acctNum: string;
}

export interface EditableQBooksCounterpartyAddress {
    street: string;
    city: string;
    postalCode: string;
    country: string;
    state: string;
}

export const createEditableQBookCustomer = (): EditableQBooksCustomer => {
    return {
        billingAddress: {
            street: '',
            city: '',
            postalCode: '',
            country: '',
            state: '',
        },
        companyName: '',
        currency: {
            id: '',
            text: '',
        },
        displayName: '',
        email: '',
        familyName: '',
        fax: '',
        givenName: '',
        middleName: '',
        mobilePhone: '',
        primaryPhone: '',
        shippingAddress: {
            street: '',
            city: '',
            postalCode: '',
            country: '',
            state: '',
        },
        suffix: '',
        title: '',
        website: '',
    };
};

export const createEditableQBookContact = (): EditableQBooksVendor => {
    return {
        displayName: '',
        companyName: '',
        title: '',
        givenName: '',
        middleName: '',
        familyName: '',
        suffix: '',
        primaryPhone: '',
        mobilePhone: '',
        fax: '',
        email: '',
        website: '',
        acctNum: '',
        currency: {
            id: '',
            text: '',
        },
        billingAddress: {
            city: '',
            street: '',
            state: '',
            postalCode: '',
            country: '',
        },
    };
};

export const isQBooksCustomer = (
    counterparty: EditableQBooksCustomer | EditableQBooksVendor
): counterparty is EditableQBooksCustomer => {
    return 'shippingAddress' in counterparty;
};

export const isQBooksVendor = (
    counterparty: EditableQBooksCustomer | EditableQBooksVendor
): counterparty is EditableQBooksVendor => {
    return 'acctNum' in counterparty;
};
