import { atom } from 'recoil';

export const menuItemsState = atom({
    key: 'menuItemsState',
    default: [],
});

export const createdItemsState = atom({
    key: 'createdItemsState',
    default: [],
});
