const appendScript = (scriptText: string, scriptId: string, nonce: undefined | string = undefined) => {
    const existingScript = document.getElementById(scriptId) as HTMLScriptElement | null;
    const script = existingScript || document.createElement('script');

    script.id = scriptId;
    script.nonce = nonce;
    script.innerText = scriptText;
    script.crossOrigin = 'anonymous';

    document.head.appendChild(script);
};

export const initScript = (
    hotjarId: number,
    hotjarVersion: number,
    opts?: {
        debug?: boolean;
        nonce?: string | null;
    }
) => {
    const isDebug = opts?.debug || false;
    const nonce = opts?.nonce || '';

    const hotjarScriptCode = `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${hotjarId},hjsv:${hotjarVersion},hjdebug:${isDebug}};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;r.nonce="${nonce}";a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

    appendScript(hotjarScriptCode, 'hotjar-init-script', nonce);
};
