import { Mods, mods, theme } from '@approvalmax/theme';
import { drawerHeaderHeight, drawerOpeningDuration } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.div<StyledMods<Mods<'sticky' | 'open'>>>`
    position: sticky;
    top: ${drawerHeaderHeight}px;
    z-index: 2;
    transition: ${drawerOpeningDuration}ms ease-in-out;
    padding-bottom: 8px;
    padding-right: 12px;
    padding-left: 12px;
    margin-right: -12px;
    margin-left: -12px;

    > * {
        position: relative;
    }

    &,
    &:before {
        background-color: ${theme.color.midnight20};
    }

    &:before {
        content: '';
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        height: 8px;
        filter: blur(4px);
        pointer-events: none;
    }

    ${mods.open.true`
        &,
        &:before {
            background-color: ${theme.color.white100};
        }
    `};

    ${mods.sticky.true`
        box-shadow: ${theme.shadow.small};

        &:before {
            display: block;
        }
    `};
`;
