import { CloseIcon } from '@approvalmax/ui';
import { Box, Button, Divider, Flex, Spacing, Text } from '@approvalmax/ui/src/components';
import { dateTimeHelpers, hooks } from '@approvalmax/utils';
import { actions } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { hideHelpStripeState } from 'shared/components/HelpStripe/HelpStripe.states';

import {
    clearVersionHistory,
    closeVersionHistoryPanel,
    fetchVersionHistory,
    fetchWorkflowWithVersion,
} from '../../actions';
import { getActiveTemplate, getVersionHistory } from '../../selectors/pageSelectors';
import { getHasChanges } from '../../selectors/templateSelectors';
import VersionItem from './components/VersionItem/VersionItem';
import VersionHistoryLoading from './VersionHistory.loading';
import { messages } from './VersionHistory.messages';
import { Root } from './VersionHistory.styles';
import { VersionHistoryProps } from './VersionHistory.types';

const VersionHistory: FC<VersionHistoryProps> = memo((props) => {
    const { helpStripeId } = props;

    const dispatch = useDispatch();
    const template = useSelector(getActiveTemplate);
    const prevTemplateVersion = hooks.usePrevious(template?.version);
    const versionHistory = useSelector(getVersionHistory);
    const [hideHelpStripeIds] = useRecoilState(hideHelpStripeState);
    const isHideHelpStripe = useMemo(() => hideHelpStripeIds.includes(helpStripeId), [hideHelpStripeIds, helpStripeId]);
    const [checkedTemplateVersion, setCheckedTemplateVersion] = useState(template?.version);
    const hasChangesInTemplate = useSelector(getHasChanges);

    const handleChangeTemplateVersion = useCallback(
        (version: number) => {
            if (version === checkedTemplateVersion) {
                return;
            }

            if (hasChangesInTemplate) {
                dispatch(
                    actions.showDiscardChangesPopup({
                        unsavedChangesConfirmationCallback: (discardChanges) => {
                            if (discardChanges) {
                                setCheckedTemplateVersion(version);

                                dispatch(fetchWorkflowWithVersion(version));
                            }
                        },
                    })
                );

                return;
            }

            setCheckedTemplateVersion(version);

            dispatch(fetchWorkflowWithVersion(version));
        },
        [checkedTemplateVersion, dispatch, hasChangesInTemplate]
    );

    useEffect(() => {
        const isJustUpdatedTemplate =
            template?.version !== undefined &&
            prevTemplateVersion !== undefined &&
            template.version > prevTemplateVersion &&
            template.isActual &&
            (versionHistory.templates.length === 0 || versionHistory.templates[0]?.version < template.version);

        if (isJustUpdatedTemplate) {
            dispatch(clearVersionHistory());

            setCheckedTemplateVersion(template?.version);

            if (versionHistory.isOpen) {
                dispatch(fetchVersionHistory());
            }
        }
    }, [dispatch, prevTemplateVersion, template, versionHistory.isOpen, versionHistory.templates]);

    const onLoadMoreClick = useCallback(() => {
        dispatch(fetchVersionHistory());
    }, [dispatch]);

    const close = useCallback(() => {
        dispatch(closeVersionHistoryPanel());
    }, [dispatch]);

    const getItemTitle = useCallback((template: domain.WorkflowVersion) => {
        return template.author
            ? `${messages.version} #${template.version} ${messages.by} ${template.author.firstName} ${template.author.lastName}`
            : `${messages.version} #${template.version}`;
    }, []);

    return (
        <Root $active={versionHistory.isOpen} $bigTopOffset={!isHideHelpStripe}>
            <Box spacing='16 24'>
                <Flex inline justifyContent='space-between' alignItems='center'>
                    <Text font='headline' fontSize='xxsmall' fontWeight='medium'>
                        {messages.title}
                    </Text>

                    <Button icon size='medium' onClick={close} noPadding>
                        <CloseIcon />
                    </Button>
                </Flex>
            </Box>

            <Divider />

            <Box spacing='16 24'>
                {!versionHistory.templates.length && <VersionHistoryLoading />}

                {versionHistory.templates.map((template, index) => (
                    <VersionItem
                        key={template.templateVersionId}
                        title={getItemTitle(template)}
                        date={dateTimeHelpers.formatDateTime(template.createDate, 'DateAndTime')}
                        version={template.version}
                        active={template.isActual}
                        checked={checkedTemplateVersion === template.version}
                        isFirstInList={index === 0}
                        isLastInList={index === versionHistory.templates.length - 1}
                        comment={template.comment}
                        setCheckedTemplateVersion={handleChangeTemplateVersion}
                    />
                ))}

                {versionHistory.hasMore && versionHistory.templates.length > 0 && (
                    <>
                        <Spacing height={16} />

                        <Flex inline justifyContent='center'>
                            <Button
                                color='blue10'
                                size='xsmall'
                                onClick={onLoadMoreClick}
                                disabled={versionHistory.isFetching}
                            >
                                {messages.buttonLoadMoreText}
                            </Button>
                        </Flex>
                    </>
                )}
            </Box>
        </Root>
    );
});

export default VersionHistory;
