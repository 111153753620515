import { intl } from '@approvalmax/utils';
import { actions, selectors } from 'modules/common';
import { backend, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions, ThunkAction } from 'modules/react-redux';
import { api } from 'services/api';
import { storageService } from 'services/storage';

import { cancelActivePopup } from './common';

export const RESPOND_TO_PRACTICE_INVITATION = 'PROFILE/RESPOND_TO_PRACTICE_INVITATION';
export const RESPOND_TO_PRACTICE_INVITATION_RESPONSE = 'PROFILE/RESPOND_TO_PRACTICE_INVITATION_RESPONSE';
export const RESPOND_TO_PRACTICE_INVITATION_FAILURE = 'PROFILE/RESPOND_TO_PRACTICE_INVITATION_FAILURE';
export const respondToPracticeInvitation = (selectedAccountId?: string) =>
    createAsyncAction({
        request: (state: State) => {
            const invitations = selectors.practiceInvitations.getPracticeInvitations(state);
            const responseData = invitations.map((invite) => {
                const response =
                    invite.id === selectedAccountId
                        ? backend.transfers.PracticeInvitationResponse.Accepted
                        : backend.transfers.PracticeInvitationResponse.Rejected;

                return {
                    accountId: invite.id,
                    response,
                };
            });

            return createAction(RESPOND_TO_PRACTICE_INVITATION, {
                responseData,
            });
        },

        response: async (request) => {
            await api.account.respondToPracticeInvitation({ invitations: request.responseData });

            const context = await api.companies.getUserContext({});

            return createAction(RESPOND_TO_PRACTICE_INVITATION_RESPONSE, {
                request,
                context,
            });
        },

        failure: (error, request) =>
            createErrorAction(RESPOND_TO_PRACTICE_INVITATION_FAILURE, error, {
                request,
            }),

        successToast: intl.formatMessage({
            id: 'profile/answerOnPracticeInvitationSent',
            defaultMessage: 'Answer sent',
        }),
        didDispatchResponse: (request, response, state, dispatch) => {
            dispatch(
                actions.loadInitialAppData({
                    context: response.context,
                })
            );
            dispatch(cancelActivePopup());
        },
        rejectOnFailure: true,
    });

export const SHOW_PENDING_PRACTICE_INVITATION_POPUP = 'PROFILE/SHOW_PENDING_PRACTICE_INVITATION_POPUP';
export const showPracticeInvitationPopup = () => createAction(SHOW_PENDING_PRACTICE_INVITATION_POPUP);

export function closeOrSkipPracticeInvitation(): ThunkAction {
    return (dispatch, getState) => {
        dispatch(cancelActivePopup());

        const invitations = selectors.practiceInvitations.getPracticeInvitations(getState());

        storageService.setViewedPracticeInvitationKeys(invitations.map((invite) => invite.id + invite.dispatchDate));
    };
}

export type Action = ExtractActions<typeof respondToPracticeInvitation | typeof showPracticeInvitationPopup>;
