/**
 * Developer: Stepan Burguchev
 * Date: 2/25/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import email, { isEmail } from './validators/email';
import password from './validators/password';
import phone from './validators/phone';
import required from './validators/required';

export * from './const';
export { default as CheckboxEditor } from './editors/checkbox/CheckboxEditor';
export { default as DateTimeEditor } from './editors/dateTime/DateTimeEditor';
export { default as DropdownEditor } from './editors/dropdown/DropdownEditor';
export * from './editors/dropdown/DropdownEditor.helpers';
export { ListItem } from './editors/dropdown/ListItem';
export * from './editors/dropdown/types';
export * from './editors/EditorBase';
export { default as NumberEditor } from './editors/number/NumberEditor';
export { default as PasswordEditor } from './editors/password/PasswordEditor';
export { default as PhoneEditor } from './editors/phone/PhoneEditor';
export { default as RadioGroupEditor } from './editors/radioGroup/RadioGroupEditor';
export { default as TextEditor } from './editors/text/TextEditor';
export * from './editors/textArea/TextAreaEditor';
export { default as TextAreaEditor } from './editors/textArea/TextAreaEditor';
export { default as TimeEditor } from './editors/time/TimeEditor';
export { default as Field } from './field/Field';
export { default as MonthPicker } from './MonthPicker/MonthPicker';

export const validators = {
    required,
    email,
    isEmail,
    password,
    phone,
};
