import { Reference } from '@approvalmax/types';
import { DropdownEditor, Field, PhoneEditor, TextEditor, validators } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { statics } from 'modules/common';
import { domain } from 'modules/data';
import { PlainDataProvider } from 'modules/data-providers';
import { font } from 'modules/styles';
import { FC, memo, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

import EditableAvatar from '../../../EditableAvatar';
import { useProfileContext } from '../../ProfileContext';

const i18nPrefix = 'profile/containers/ProfilePopup/ProfileInformationSection/ProfileInformationSection';
const messages = defineMessages({
    avatarLabel: {
        id: `${i18nPrefix}.avatarLabel`,
        defaultMessage: 'Avatar',
    },
    firstNameLabel: {
        id: `${i18nPrefix}.firstNameLabel`,
        defaultMessage: 'First name',
    },
    firstNamePlaceholder: {
        id: `${i18nPrefix}.firstNamePlaceholder`,
        defaultMessage: 'Enter first name',
    },
    lastNamePlaceholder: {
        id: `${i18nPrefix}.lastNamePlaceholder`,
        defaultMessage: 'Enter last name',
    },
    lastNameLabel: {
        id: `${i18nPrefix}.lastNameLabel`,
        defaultMessage: 'Last name',
    },
    phonePlaceholder: {
        id: `${i18nPrefix}.phonePlaceholder`,
        defaultMessage: 'Enter phone',
    },
    phoneLabel: {
        id: `${i18nPrefix}.phoneLabel`,
        defaultMessage: 'Phone',
    },
    timeZonePlaceholder: {
        id: `${i18nPrefix}.timeZonePlaceholder`,
        defaultMessage: 'Enter time zone',
    },
    timeZoneLabel: {
        id: `${i18nPrefix}.timeZoneLabel`,
        defaultMessage: 'Time zone',
    },
});

const Fields = styled.div`
    display: flex;
    margin-top: 10px;
    margin-bottom: 30px;
    padding-left: 80px;
    padding-right: 60px;

    @media only screen and (max-width: 1250px) {
        margin-bottom: 10px;
    }
`;

const LeftFields = styled.div`
    margin-right: 30px;
    display: flex;
    flex-flow: column;
    align-items: center;
`;

const RightFields = styled.div`
    flex: 1;
    display: flex;
    flex-flow: column;
    overflow: hidden;
`;

const RowFields = styled.div`
    display: flex;
    margin-right: -20px;
`;

const StyledField = styled(Field)`
    flex: 1;
    margin-right: 20px;
    overflow: hidden;
`;

const EmailField = styled.div`
    ${font(13, '#565656')}
    font-style: italic;
    margin: 5px 0 20px 0;
`;

const HiddenEmailField = styled.input`
    display: none;
`;

const qa = bemFactory.qa('profile-popup-profile-information-section');

const ProfileInformationSection: FC = () => {
    const { profile, editProfile, showErrors } = useProfileContext();

    const onChangeFirstName = (firstName: string) => editProfile('firstName', firstName);
    const onChangeLastName = (lastName: string) => editProfile('lastName', lastName);
    const onChangePhone = (phone: Parameters<typeof editProfile<'phone'>>[1]) => editProfile('phone', phone);

    const onChangeTimeZone = (value: Reference) => {
        if (!value) {
            return;
        }

        editProfile('timeZoneForEmail', value.id);
    };

    const timezone = useMemo(
        () => statics.timeZone.findTimeZoneById(profile.timeZoneForEmail) || null,
        [profile.timeZoneForEmail]
    );

    const isMandatoryPhoneNumber = profile.account?.userRole === domain.AccountMemberRole.Administrator;

    const isInvalidFirstName = !!(!profile.firstName?.trim() || (profile.firstName && profile.firstName.length > 500));
    const isInvalidLastName = !!(!profile.lastName?.trim() || (profile.lastName && profile.lastName.length > 500));
    const isinValidPhoneNumber =
        (isMandatoryPhoneNumber && !profile.phone) || (!!profile.phone && !validators.phone(profile.phone));

    return (
        <Fields>
            <LeftFields>
                <Field title={intl.formatMessage(messages.avatarLabel)}>
                    <div />
                </Field>

                <EditableAvatar />
            </LeftFields>

            <RightFields>
                <RowFields>
                    <StyledField title={intl.formatMessage(messages.firstNameLabel)} required>
                        <TextEditor
                            qa={qa('first-name-editor')}
                            placeholder={intl.formatMessage(messages.firstNamePlaceholder)}
                            value={profile.firstName || ''}
                            onChange={onChangeFirstName}
                            invalid={showErrors && isInvalidFirstName}
                            maxLength={500}
                        />
                    </StyledField>

                    <StyledField title={intl.formatMessage(messages.lastNameLabel)} required>
                        <TextEditor
                            qa={qa('last-name-editor')}
                            placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
                            value={profile.lastName || ''}
                            onChange={onChangeLastName}
                            invalid={showErrors && isInvalidLastName}
                            maxLength={500}
                        />
                    </StyledField>
                </RowFields>

                <EmailField>{profile.email}</EmailField>

                <HiddenEmailField
                    type='text'
                    name='username'
                    id='username'
                    autoComplete='username'
                    value={profile.email}
                    tabIndex={-1}
                    readOnly
                />

                <RowFields>
                    <StyledField title={intl.formatMessage(messages.phoneLabel)} required={isMandatoryPhoneNumber}>
                        <PhoneEditor
                            qa={qa('phone-editor')}
                            placeholder={intl.formatMessage(messages.phonePlaceholder)}
                            value={profile.phone}
                            onChange={onChangePhone}
                            invalid={showErrors && isinValidPhoneNumber}
                        />
                    </StyledField>

                    <StyledField title={intl.formatMessage(messages.timeZoneLabel)} required>
                        <PlainDataProvider items={statics.timeZone.timeZoneReferences}>
                            <DropdownEditor
                                qa={qa('timezone-editor')}
                                value={timezone}
                                onChange={onChangeTimeZone}
                                placeholder={intl.formatMessage(messages.timeZonePlaceholder)}
                                invalid={showErrors && !profile.timeZoneForEmail}
                            />
                        </PlainDataProvider>
                    </StyledField>
                </RowFields>
            </RightFields>
        </Fields>
    );
};

export default memo(ProfileInformationSection);
