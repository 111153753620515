import { stateTree } from 'modules/data';

export function authenticated(state: stateTree.State): boolean {
    return state.session.authenticated;
}

export function getStartupData(state: stateTree.State): stateTree.SessionStartupData {
    return state.session.startup;
}

export function contextInitializationCompleted(state: stateTree.State): boolean {
    return state.session.contextInitializationCompleted;
}
