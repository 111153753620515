import Button from '@approvalmax/ui/src/components/Button/Button';
import Dropdown from '@approvalmax/ui/src/components/Dropdown/Dropdown';
import Menu from '@approvalmax/ui/src/components/Menu/Menu';
import { ChevronDownIcon } from '@approvalmax/ui/src/icons';
import { FC, memo } from 'react';

import { getUrlProps } from '../../Breadcrumbs.helpers';
import { getActivatorName } from './DropdownSections.helpers';
import { DropdownSectionsProps } from './DropdownSections.types';

const DropdownSections: FC<DropdownSectionsProps> = memo((props) => {
    const { items, as, value, ...restProps } = props;

    if (!items?.length) return null;

    return (
        <Dropdown
            display='flex'
            activator={
                <Button
                    color='transparent'
                    noPadding
                    uppercase={false}
                    endIcon={<ChevronDownIcon size={12} />}
                    size='small'
                >
                    {getActivatorName(value, items)}
                </Button>
            }
            hasArrow
            width='max-content'
            {...restProps}
        >
            <Menu divider size='small'>
                {items.map(({ name, to }, index) => (
                    <Menu.Item name={name} {...getUrlProps(as, to)} key={`${to}-${index}`} />
                ))}
            </Menu>
        </Dropdown>
    );
});

export default DropdownSections;
