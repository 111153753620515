import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import {
    UseEditXeroCounterpartyPaymentDetailsData,
    UseEditXeroCounterpartyPaymentDetailsPathParams,
    UseEditXeroCounterpartyPaymentDetailsResponse,
    UseEditXeroCounterpartyPaymentDetailsResponseBackend,
} from './useEditXeroCounterpartyPaymentDetails.types';

export const useEditXeroCounterpartyPaymentDetails = (
    mutationOptions?: MutationOptions<
        UseEditXeroCounterpartyPaymentDetailsResponse,
        RequestDataParams<UseEditXeroCounterpartyPaymentDetailsData, UseEditXeroCounterpartyPaymentDetailsPathParams>
    >
) => {
    return useMutateLegacy<
        RequestDataParams<UseEditXeroCounterpartyPaymentDetailsData, UseEditXeroCounterpartyPaymentDetailsPathParams>,
        UseEditXeroCounterpartyPaymentDetailsResponseBackend,
        UseEditXeroCounterpartyPaymentDetailsResponse
    >(integrationsApiPaths.updateXeroCounterpartyPaymentDetails, {
        method: 'put',
        mutationOptions,
    });
};
