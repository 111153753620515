import './referenceListFilter.scss';

import { Reference } from '@approvalmax/types';
import { DropdownBoxItemProps, DropdownEditor } from '@approvalmax/ui';
import { errorHelpers, intl } from '@approvalmax/utils';
import { constants, dataProviders } from 'modules/common';
import { domain } from 'modules/data';
import { PlainDataProvider } from 'modules/data-providers';
import React, { FC } from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages } from 'react-intl';

import { DataProviderProps } from '../../config/standard/columnDefinitions';
import * as referenceListFilter from '../../data/filters/referenceListFilter';

const i18nPrefix = 'reports.filters.ReferenceListFilter';
const messages = defineMessages({
    placeholder: {
        id: `${i18nPrefix}.placeholder`,
        defaultMessage: 'Any value',
    },
});

interface Props {
    options?: Reference[];
    field?: domain.Field;
    dataProvider?: React.ComponentClass<DataProviderProps> | FC<DataProviderProps>;
    filter: referenceListFilter.ReferenceListFilter;

    onFilterChange(filter: referenceListFilter.ReferenceListFilter): void;
}

interface State {}

class ReferenceListFilter extends React.Component<Props, State> {
    public render() {
        const { filter, options, field, dataProvider } = this.props;
        const bem = bemFactory.block('rpt-reference-list-filter');

        let editor = (
            <DropdownEditor
                value={filter.values}
                multiple
                theme='matrix'
                placeholder={intl.formatMessage(messages.placeholder)}
                boxItem={this._renderBoxItem}
                filterPlaceholder=''
                className={bem('dropdown')}
                onChange={this._onChange}
            />
        );

        let content;

        if (dataProvider) {
            const DataProviderClass = dataProvider;

            content = <DataProviderClass>{editor}</DataProviderClass>;
        } else if (options) {
            content = <PlainDataProvider items={options}>{editor}</PlainDataProvider>;
        } else if (field) {
            content = <dataProviders.FieldDataProvider field={field}>{editor}</dataProviders.FieldDataProvider>;
        } else {
            throw errorHelpers.invalidOperationError(
                'Neither dataProvider, options or field properties are provided. ' +
                    'It is likely that getOptions() property has not been defined in column definitions.'
            );
        }

        return <div className={bem()}>{content}</div>;
    }

    private _renderBoxItem = (boxItemProps: DropdownBoxItemProps) => {
        const emptyItem = boxItemProps.item.id === constants.commonConstants.EMPTY_VALUE_ID;

        return (
            <DropdownEditor.BoxItem
                {...boxItemProps}
                theme={emptyItem ? DropdownEditor.BoxItemTheme.Grey : DropdownEditor.BoxItemTheme.Green}
            />
        );
    };

    private _onChange = (value: Reference[]) => {
        this.props.onFilterChange({
            ...this.props.filter,
            values: value,
        });
    };
}

export default ReferenceListFilter;
