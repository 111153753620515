import { errorHelpers } from '@approvalmax/utils';
import { clearSessionNotifications } from 'modules/common';
import { domain, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { api } from 'services/api';
import { routingService } from 'services/routing';
import { storageService } from 'services/storage';
import { AccountPath, getAccountUrl, getDefaultPath, getPartnerUrl } from 'urlBuilder';

import { TwoFaLoginModuleData } from '../reducers/page/moduleReducer';
import { getModule } from '../selectors/pageSelectors';

export const finishLoginFlow = (options: { app?: domain.SsoLoginApplication; postAction?: string }) => {
    storageService.setAuthenticated(true);

    if (options.app) {
        let appUrl;

        switch (options.app) {
            case domain.SsoLoginApplication.Account:
                appUrl = getAccountUrl(AccountPath.root);
                break;

            case domain.SsoLoginApplication.Partner:
                appUrl = getPartnerUrl();
                break;

            default:
                throw errorHelpers.assertNever(options.app);
        }

        if (options.postAction) {
            appUrl += options.postAction.startsWith('/') ? options.postAction : `/${options.postAction}`;
        }

        routingService.navigateToExternalUrl(appUrl);
    } else {
        routingService.reloadToUrl(options.postAction || getDefaultPath());
    }
};

export const SHOW_LOGIN_MODULE = 'AUTH/SHOW_LOGIN_MODULE';
export const showLoginModule = (options: {
    innerPageId?: string;
    email?: string;
    app?: domain.SsoLoginApplication;
    postAction?: string;
}) => createAction(SHOW_LOGIN_MODULE, options);

export const LOGIN = 'AUTH/LOGIN';
export const LOGIN_RESPONSE = 'AUTH/LOGIN_RESPONSE';
export const LOGIN_FAILURE = 'AUTH/LOGIN_FAILURE';

interface PasswordLoginOptions {
    email: string;
    password: string;
    app?: domain.SsoLoginApplication;
    postAction?: string;
}

export const login = (options: PasswordLoginOptions) =>
    createAsyncAction({
        request: () =>
            createAction(LOGIN, {
                email: options.email,
            }),

        response: async (request) => {
            clearSessionNotifications();

            const { RequiresTFA, TFAStateId, IsTFAAlternativeEmailEnabled, IsTFABackupCodesEnabled } =
                await api.auth.login({
                    email: options.email,
                    pwd: options.password,
                });

            return createAction(LOGIN_RESPONSE, {
                request,
                is2faEnabled: Boolean(RequiresTFA),
                stateId: TFAStateId,
                isTFAAlternativeEmailEnabled: Boolean(IsTFAAlternativeEmailEnabled),
                isTFABackupCodesEnabled: Boolean(IsTFABackupCodesEnabled),
            });
        },

        failure: (error) => createErrorAction(LOGIN_FAILURE, error, {}),
        didDispatchResponse: async (_, response) => {
            if (!response.is2faEnabled) {
                finishLoginFlow(options);
            }
        },
    });

export const FINISH_TWO_FA_LOGIN = 'AUTH/FINISH_TWO_FA_LOGIN';
export const FINISH_TWO_FA_LOGIN_RESPONSE = 'AUTH/FINISH_TWO_FA_LOGIN_RESPONSE';
export const FINISH_TWO_FA_LOGIN_FAILURE = 'AUTH/FINISH_TWO_FA_LOGIN_FAILURE';
export const finishTwoFaLogin = (code: string, trustThisDevice: boolean) =>
    createAsyncAction({
        request: (state: State) => {
            const data = getModule<TwoFaLoginModuleData>(state);

            return createAction(FINISH_TWO_FA_LOGIN, {
                stateId: data.stateId,
                app: data.app,
                postAction: data.postAction,
                code,
                trustThisDevice,
            });
        },

        response: async (request) => {
            await api.auth.finishTFALogin({
                stateId: request.stateId,
                code,
                isDeviceTrusted: trustThisDevice,
            });

            return createAction(FINISH_TWO_FA_LOGIN_RESPONSE, {
                request,
            });
        },

        rejectOnFailure: true,

        failure: (error) => createErrorAction(FINISH_TWO_FA_LOGIN_FAILURE, error, {}),

        didDispatchResponse: async (request) => {
            finishLoginFlow(request);
        },
    });

export const SEND_TWO_FA_LOGIN_EMAIL_CODE = 'AUTH/SEND_TWO_FA_LOGIN_EMAIL_CODE';
export const SEND_TWO_FA_LOGIN_EMAIL_CODE_RESPONSE = 'AUTH/SEND_TWO_FA_LOGIN_EMAIL_CODE_RESPONSE';
export const SEND_TWO_FA_LOGIN_EMAIL_CODE_FAILURE = 'AUTH/SEND_TWO_FA_LOGIN_EMAIL_CODE_FAILURE';
export const sendTwoFaLoginEmailCode = () =>
    createAsyncAction({
        request: (state: State) =>
            createAction(SEND_TWO_FA_LOGIN_EMAIL_CODE, {
                stateId: getModule<TwoFaLoginModuleData>(state).stateId,
            }),

        response: async (request) => {
            const response = await api.auth.sendTFALoginEmailCode({
                stateId: request.stateId,
            });

            return createAction(SEND_TWO_FA_LOGIN_EMAIL_CODE_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error) => createErrorAction(SEND_TWO_FA_LOGIN_EMAIL_CODE_FAILURE, error, {}),

        rejectOnFailure: true,
    });

export type Action = ExtractActions<
    typeof finishTwoFaLogin | typeof login | typeof sendTwoFaLoginEmailCode | typeof showLoginModule
>;
