import { Reference } from '@approvalmax/types';
import { intl } from '@approvalmax/utils';

const i18nPrefix = 'first-start/data/account';

export const allCompanyCountOptions: Reference[] = [
    {
        id: 'companyCount_1',
        text: intl.formatMessage({
            id: `${i18nPrefix}.companyCount1`,
            defaultMessage: '1',
        }),
    },
    {
        id: 'companyCount_2-5',
        text: intl.formatMessage({
            id: `${i18nPrefix}.companyCount2_5`,
            defaultMessage: '2 - 5',
        }),
    },
    {
        id: 'companyCount_6-10',
        text: intl.formatMessage({
            id: `${i18nPrefix}.companyCount6_10`,
            defaultMessage: '6 - 10',
        }),
    },
    {
        id: 'companyCount_11-',
        text: intl.formatMessage({
            id: `${i18nPrefix}.companyCount11-`,
            defaultMessage: '11+',
        }),
    },
];

export interface AccountInfo {
    isPartner: boolean;
    practiceName: string;
    companyCount: string;
    clientCount: string;
}
