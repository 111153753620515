import { FC, memo } from 'react';

import { Button, ExternalLink, Popup } from '../../index';
import { messages } from './TwoFaSoftEnforcementPopup.messages';
import TwoFaSoftEnforcementPopupSkeleton from './TwoFaSoftEnforcementPopup.skeleton';
import { Content, Description, Root, Title } from './TwoFaSoftEnforcementPopup.styles';
import { TwoFaSoftEnforcementPopupProps } from './TwoFaSoftEnforcementPopup.types';

/**
 * @deprecated Old Design
 */
const TwoFaSoftEnforcementPopup: FC<TwoFaSoftEnforcementPopupProps> = memo((props) => {
    const { onSetUpTwoFa, isDefaultEnforcement, isLoading, isOpen, onClose } = props;

    if (isLoading)
        return (
            <Popup isOpen={isOpen} onRequestClose={onClose}>
                <TwoFaSoftEnforcementPopupSkeleton />;
            </Popup>
        );

    return (
        <Popup isOpen={isOpen} onRequestClose={onClose}>
            <Root title={<Title>{messages.title}</Title>}>
                <Content>
                    <Description>
                        {isDefaultEnforcement
                            ? messages.descriptionAdministratorEnforcement
                            : messages.descriptionCompanyEnforcement}
                    </Description>

                    <Description>
                        {messages.moreInformation({
                            supportlink: (chunks) => (
                                <ExternalLink href='https://support.approvalmax.com/portal/en/newticket'>
                                    {chunks}
                                </ExternalLink>
                            ),
                        })}
                    </Description>

                    <Button execute={onSetUpTwoFa}>{messages.setUpTwoFa}</Button>
                </Content>
            </Root>
        </Popup>
    );
});

export default TwoFaSoftEnforcementPopup;
