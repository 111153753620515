import { mods } from '@approvalmax/theme';
import styled from 'styled-components';

import { CalendarIcon } from '../../icons';
import TextField from '../TextField/TextField';
import { DatePickerProps } from './DatePicker.types';

export const Icon = styled(CalendarIcon)`
    cursor: pointer;
`;

export const Activator = styled(TextField)<Pick<DatePickerProps, 'view'>>`
    ${mods('view', 'year')`
        cursor: pointer;
    `};
`;
