import { domain } from 'modules/data';

export const AvailableBillingSystems = [
    domain.SubscriptionBillingSystem.Avangate,
    domain.SubscriptionBillingSystem.Stripe,
];

export const subscriptionFieldName = 'subscriptionId';
export const optionFieldName = 'option';
export const confirmWarningUpgradeSubscriptionFieldName = 'confirmWarningUpgradeSubscription';
