import { produce } from 'immer';
import { Entities } from 'modules/data';

import { Action, UPDATE_BUDGET_SYNC_SETTING } from '../actions';

export default produce((draft: Entities, action: Action): Entities => {
    switch (action.type) {
        case UPDATE_BUDGET_SYNC_SETTING: {
            const integration = Object.values(draft.integrations).find(
                (x) => x.companyId === action.payload.companyId
            )!;

            integration.isBudgetSyncEnabled = action.payload.isEnabled;

            return draft;
        }

        default:
            return draft;
    }
});
