import React from 'react';

const TwoFaHardEnforcementPage = React.lazy(
    () =>
        import(/* webpackChunkName: "manager-pages" */ './components/TwoFaHardEnforcementPage/TwoFaHardEnforcementPage')
);

export default [
    {
        path: '/2fa-hard-enforcement',
        component: TwoFaHardEnforcementPage,
    },
];
