import { Field, font, oPopupPadding, oPopupPaddingHorizontal } from '@approvalmax/ui';
import styled from 'styled-components';

export const Content = styled.div`
    ${oPopupPadding()}
`;

export const Greeting = styled.div`
    ${font(13)}
`;

export const MainMessage = styled.div`
    ${font(13)}
`;

export const OrganisationCountField = styled(Field)`
    margin-top: 10px;
`;

export const Header = styled.header`
    height: 80px;
    display: flex;
    align-items: center;
    ${oPopupPaddingHorizontal()}
`;

export const Title = styled.header`
    flex: 1;
    ${font(18, '#333', 'semibold')}
`;
