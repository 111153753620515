import { progress } from '@approvalmax/ui';
import { FC, memo, useEffect } from 'react';

interface GlobalLoadingBarProps {
    isLoading: boolean;
}

const GlobalLoadingBar: FC<GlobalLoadingBarProps> = (props) => {
    const { isLoading } = props;

    useEffect(() => {
        if (isLoading) {
            progress.inc();
        }

        return () => {
            if (isLoading) {
                progress.dec();
            }
        };
    }, [isLoading]);

    return null;
};

export default memo(GlobalLoadingBar);
