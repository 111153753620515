import { constants } from '@approvalmax/utils';
import { useMemo } from 'react';

type ExtensionsType = typeof constants.extensions;

export const useExtensions = <Extension extends keyof ExtensionsType>(extensionTypes: Extension[]) => {
    return useMemo(
        () =>
            extensionTypes.reduce(
                (acc, item) => {
                    acc = {
                        ...acc,
                        ...constants.extensions[item],
                    };

                    return acc;
                },
                {} as ExtensionsType[Extension]
            ),
        [extensionTypes]
    );
};
