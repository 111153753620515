import { useApiContext } from '@approvalmax/data';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { getNowMs } from './AuthModule.helpers';

export const useGlobalClick = (onClick: () => void) => {
    useEffect(() => {
        document.addEventListener('click', onClick, { capture: true });

        return () => document.removeEventListener('click', onClick, { capture: true });
    }, [onClick]);
};

export const useTime = (startCount: boolean) => {
    const [now, setNow] = useState(getNowMs());

    useEffect(() => {
        let handler: undefined | ReturnType<typeof setInterval>;

        if (startCount) {
            handler = setInterval(() => {
                setNow(getNowMs());
            }, 1000);
        }

        return () => handler && clearInterval(handler);
    }, [startCount]);

    return now;
};

export const useAuthDeadlines = () => {
    const { sessionExpirationDate } = useApiContext();

    const authErrorDeadlineMs = sessionExpirationDate ? moment(new Date(sessionExpirationDate)).utc().valueOf() : 0;
    const millisecondsBeforeWarning = moment.duration(5, 'minutes').asMilliseconds();
    const authWarningDeadlineMs = useMemo(
        () => (authErrorDeadlineMs ? authErrorDeadlineMs - millisecondsBeforeWarning : 0),
        [authErrorDeadlineMs, millisecondsBeforeWarning]
    );

    return {
        authErrorDeadlineMs,
        authWarningDeadlineMs,
    };
};
