import { useSelector } from 'modules/react-redux';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from '../actions';
import * as selectors from '../selectors';

export function useUserPreferences<TValue = any>(
    userPreferenceKey: string
): [TValue | null, (newValue: TValue) => void];
export function useUserPreferences<TValue = any>(
    userPreferenceKey: string,
    fallbackValue: TValue
): [TValue, (newValue: TValue) => void];

export function useUserPreferences(userPreferenceKey: string, fallbackValue?: any) {
    const preferenceValue = useSelector((state) =>
        selectors.userPreferences.getUserPreference(state, userPreferenceKey, fallbackValue)
    );

    const dispatch = useDispatch();

    const setPreferenceValue = useCallback(
        (newValue: any) => {
            return dispatch(actions.updateUserPreference(userPreferenceKey, newValue));
        },
        [dispatch, userPreferenceKey]
    );

    return [preferenceValue, setPreferenceValue];
}
