import { ApiBaseAnswer, MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { ocrApiPaths } from '../paths';
import { UseBulkUploadToOcrData, UseBulkUploadToOcrPathParams } from './useBulkUploadToOcr.types';

export const useBulkUploadToOcr = (
    mutationOptions?: MutationOptions<
        ApiBaseAnswer,
        RequestDataParams<UseBulkUploadToOcrData, UseBulkUploadToOcrPathParams>
    >
) => {
    return useMutate<RequestDataParams<UseBulkUploadToOcrData, UseBulkUploadToOcrPathParams>>(
        ocrApiPaths.bulkUploadToOcr,
        {
            apiVersion: 'v2',
            mutationOptions,
        }
    );
};
