import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('profile.AllFeatureTrialPaywallPopup.FeatureDescription', {
    xeroAdvancedBillToPOMatchingTitle: 'Strengthen spend control and improve bill approvals with Bill-to-PO matching',
    xeroAdvancedBillToPOMatchingSubtitle: 'Match bills with POs to ensure actual spend is the same as committed spend.',
    xeroAdvancedBillToPOMatchingList1: 'Keep track of the remaining balance for each PO.',
    xeroAdvancedBillToPOMatchingList2: 'Create controls so your team doesn’t overspend.',
    xeroAdvancedBillToPOMatchingList3: 'Make sure expectations align with reality.',

    xeroAdvancedBudgetCheckingTitle: 'Check costs against your budget before approving',
    xeroAdvancedBudgetCheckingList1: 'A “traffic light” system will tell you if you’re likely to go over budget.',
    xeroAdvancedBudgetCheckingList2:
        'Make informed approval decisions by checking pending POs and bills to the relevant budgets.',
    xeroAdvancedBudgetCheckingList3: 'Spend less time searching for budgets in your accounting software.',

    xeroPremiumBatchPaymentApprovalsTitle:
        'Speed up authorisation and establish a cash flow management process with Xero batch payment approvals',
    xeroPremiumBatchPaymentApprovalsList1: 'Prepare payment runs more efficiently.',
    xeroPremiumBatchPaymentApprovalsList2: 'Let decision-makers review Xero batch payments and individual invoices.',
    xeroPremiumBatchPaymentApprovalsList3:
        'Remove the need to log into internet banking by segregating duties – ideal for accounting firms.',

    xeroAdvancedContactApprovalsTitle: 'Approve new contacts before paying them to prevent potential fraud',
    xeroAdvancedContactApprovalsList1: 'Use smart automation and rules to vet contacts and suppliers.',
    xeroAdvancedContactApprovalsList2: 'Get peace of mind knowing that every contact you’re paying is legitimate.',
    xeroAdvancedContactApprovalsList3:
        'An automatic audit trail is created each time a contact or supplier is fully authorised.',

    xeroPremiumPaymentsWithAirwallexTitle: 'Make secure payments directly in ApprovalMax with Airwallex*',
    xeroPremiumPaymentsWithAirwallexList1: 'Make international payments to over 150 countries in over 40 currencies.',
    xeroPremiumPaymentsWithAirwallexList2: 'Reduce the need to log into a separate banking platform to make payments.',
    xeroPremiumPaymentsWithAirwallexList3: 'Get a complete audit trail and automatic report after every payment.',
    xeroPremiumPaymentsWithAirwallexEndnote: '*Available in the United Kingdom, Australia, and New Zealand',

    xeroAdvancedAutoApprovalsTitle: 'Reduce your workload with auto-approvals',
    xeroAdvancedAutoApprovalsList1:
        'Set up auto-approvals for small and regular recurring expenses so decision-makers can focus on more important matters.',
    xeroAdvancedAutoApprovalsList2:
        'Use criteria like contact, amount or  tracking categories in your auto-approval rules.',

    xeroAdvancedSalesQuoteApprovalsTitle: 'Approve quotes before sending them to customers to ensure consistency',
    xeroAdvancedSalesQuoteApprovalsList1: 'Avoid back and forth that slows sales down.',
    xeroAdvancedSalesQuoteApprovalsList2: 'Create, approve, and send sales quotes directly in ApprovalMax.',
    xeroAdvancedSalesQuoteApprovalsList3:
        'Check if the quotes are accepted by the customer and copy the accepted ones to sales invoices.',

    xeroAdvancedManualJournalApprovalsTitle:
        'Prevent errors and unauthorised General Ledger entries with manual journal approvals',
    xeroAdvancedManualJournalApprovalsList1:
        'Ensure your accounting system data is accurate with an approval workflow for manual journal approval entries.',
    xeroAdvancedManualJournalApprovalsList2:
        'Ensure the appropriate people review and approve manual journals before they are posted to the general ledger.',

    xeroPremiumStandAloneWorkflowsTitle: 'Set up approvals for any documents with stand-alone workflows',
    xeroPremiumStandAloneWorkflowsSubtitle:
        'Approve specific expenses, CapEx, HR documents, contracts, payroll, and more.',

    xeroPremiumPublicAPITitle: 'Use public API to connect ApprovalMax data to another platform',
    xeroPremiumPublicAPISubtitle:
        'Connect ApprovalMax with other apps to create personalised reports that speak directly to your needs.',

    xeroAdvancedGoodsReceivedNotesTitle: 'Check goods or services have been delivered before paying',
    xeroAdvancedGoodsReceivedNotesList1: 'Manage and track the status of your deliverables alongside relevant POs.',
    xeroAdvancedGoodsReceivedNotesList2: 'Add important notes and documents for smooth team communication.',
    xeroAdvancedGoodsReceivedNotesList3:
        'Let your Accounts Payable team know precisely when bills or invoices are okay to be paid.',

    xeroAdvancedWorkflowVersionHistoryTitle: 'Track changes with Workflow Version History',
    xeroAdvancedWorkflowVersionHistoryList1: 'Review changes to past workflows.',
    xeroAdvancedWorkflowVersionHistoryList2:
        'Streamline your audit preparation by examining workflows that have a clear trail.',
    xeroAdvancedWorkflowVersionHistoryList3: 'Prevent opportunities for internal fraud by seeing what’s changed.',

    xeroAdvancedRequestReviewStepTitle:
        'Edit the request before the approval workflow starts to reduce errors and avoid unnecessary back-and-forth',
    xeroAdvancedRequestReviewStepList1: 'Reviewers can edit the requests before submitting them for approval.',
    xeroAdvancedRequestReviewStepList2: 'Requests are routed to Reviewers based on the predefined rules.',

    xeroAdvancedAccrualReportsTitle: 'P&L accuracy keeping you awake? Sleep soundly with Accrual Reports.',
    xeroAdvancedAccrualReportsList1: 'Sync directly with your accounting software to reduce errors.',
    xeroAdvancedAccrualReportsList2: 'Use multi-level approval processes for accruals.',
    xeroAdvancedAccrualReportsList3: 'Review and edit accrual journals before posting.',
    xeroAdvancedAccrualReportsList4:
        'Automatically calculate PO balances and switch between net and gross views for detailed insights.',

    xeroPremiumPrioritySupportTitle: 'Get answers and resolve problems faster with Priority Support',
    xeroPremiumPrioritySupportSubtitle: 'Enjoy wider support options, such as phone calls and scheduled Zoom meetings.',

    qboAdvancedBillToPOMatchingTitle: 'Strengthen spend control and improve bill approvals with Bill-to-PO matching',
    qboAdvancedBillToPOMatchingSubtitle:
        'Match bills with purchase orders to ensure actual spending is the same as committed.',
    qboAdvancedBillToPOMatchingList1: 'Keep track of the remaining balance for each purchase order.',
    qboAdvancedBillToPOMatchingList2: 'Create controls so your team doesn’t overspend.',
    qboAdvancedBillToPOMatchingList3: 'Make sure expectations align with reality.',

    qboAdvancedVendorApprovalsTitle: 'Approve new vendors before paying them to prevent potential fraud',
    qboAdvancedVendorApprovalsList1: 'Use smart automation and rules to vet vendors and suppliers.',
    qboAdvancedVendorApprovalsList2: 'Get peace of mind knowing that every vendor and supplier is legitimate.',
    qboAdvancedVendorApprovalsList3: 'An automatic audit trail is created each time a vendor is fully authorised.',

    qboAdvancedAutoApprovalsTitle: 'Reduce your workload with auto-approvals',
    qboAdvancedAutoApprovalsList1:
        'Set up auto-approvals for small and regular recurring expenses so decision-makers can focus on more important matters.',
    qboAdvancedAutoApprovalsList2:
        'Use criteria like contact, amount or  tracking categories in your auto-approval rules.',

    qboAdvancedJournalEntryApprovalsTitle:
        'Prevent errors and unauthorised General Ledger entries with journal entry approvals',
    qboAdvancedJournalEntryApprovalsList1:
        'Create a structured and customisable approval workflow for journal entries.',
    qboAdvancedJournalEntryApprovalsList2:
        'Ensure journal entries are reviewed and approved by the appropriate decision-maker before they are posted to the general ledger.',

    qboPremiumStandAloneWorkflowsTitle: 'Set up approvals for any documents with stand-alone workflows',
    qboPremiumStandAloneWorkflowsSubtitle:
        'Approve specific expenses, CapEx, HR documents, contracts, payroll, and more.',

    qboAdvancedGoodsReceivedNotesTitle: 'Check goods or services have been delivered before paying',
    qboAdvancedGoodsReceivedNotesList1: 'Manage and track the status of your deliverables alongside relevant POs.',
    qboAdvancedGoodsReceivedNotesList2: 'Add important notes and documents for smooth team communication.',
    qboAdvancedGoodsReceivedNotesList3:
        'Let your Accounts Payable team know precisely when bills or invoices are okay to be paid.',

    qboAdvancedWorkflowVersionHistoryTitle: 'Track changes with Workflow Version History',
    qboAdvancedWorkflowVersionHistoryList1: 'Review changes to past workflows.',
    qboAdvancedWorkflowVersionHistoryList2:
        'Streamline your audit preparation by looking at workflows with a clear trail.',
    qboAdvancedWorkflowVersionHistoryList3: 'Prevent opportunities for internal fraud by seeing what’s changed.',

    qboAdvancedRequestReviewStepTitle:
        'Edit the request before the approval workflow starts to reduce errors and avoid unnecessary back-and-forth',
    qboAdvancedRequestReviewStepList1: 'Reviewers can edit the requests before submitting them for approval.',
    qboAdvancedRequestReviewStepList2: 'Requests are routed to Reviewers based on the predefined rules.',

    qboPremiumPrioritySupportTitle: 'Get answers and resolve problems faster with Priority Support',
    qboPremiumPrioritySupportSubtitle: 'Enjoy wider support options, such as phone calls and scheduled Zoom meetings.',

    addOnsCaptureTitle: 'Import bills automatically using ApprovalMax Capture',
    addOnsCaptureSubTitle:
        'Leave the data entry to ApprovalMax. Review and approve the bills after we complete the repetitive entry work for you.',
    addOnCaptureList1: 'Upload a file during single bill creation',
    addOnCaptureList2: 'Bulk upload multiple bills at once',
    addOnCaptureList3: 'Enjoy automatic bill data pre-population',
    addOnCaptureEndNote: 'Try ApprovalMax Capture with 100 free documents',

    addOnsPayTitle: 'Want a smarter, faster, safer way to pay bills?',
    addOnsPaySubTitle: 'Let ApprovalMax Pay take care of it',
    addOnPayList1: 'Process payments directly in ApprovalMax',
    addOnPayList2: 'Connect to your bank securely using open banking',
    addOnPayList3: 'Route payments accurately every time',
    addOnPayList4: 'Track invoices and payments in real-time',
    addOnPayList5: 'Automate reconciliation tasks',
});
