import { useGetRequest } from 'shared/data/api';

import { slackApiPaths } from '../paths';
import { UseSlackConnectUserQueryParams, UseSlackConnectUserResponse } from './useSlackConnectUser.types';

export const useSlackConnectUser = (
    queryParams: UseSlackConnectUserQueryParams,
    isAuthenticated: boolean,
    onError?: () => void
) => {
    return useGetRequest<UseSlackConnectUserResponse>(slackApiPaths.connectUser, {
        params: queryParams,
        apiSource: 'slack',
        queryOptions: {
            enabled: isAuthenticated,
            onError,
        },
    });
};
