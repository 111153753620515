import { RequestDataParams } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { UseCreateXeroBudgetData, UseCreateXeroBudgetResponse } from './useCreateXeroBudget.types';

export const useCreateXeroBudget = () => {
    const queryClient = useQueryClient();

    return useMutateLegacy<RequestDataParams<UseCreateXeroBudgetData>, UseCreateXeroBudgetResponse>(
        budgetsApiPaths.createXeroBudget,
        {
            mutationOptions: {
                onSettled: async (_data, _error, requestData) => {
                    await queryClient.invalidateQueries([
                        routerHelpers.pathToUrl(budgetsApiPaths.select, requestData.pathParams),
                        { companyId: requestData.data.companyId },
                    ]);
                },
            },
        }
    );
};
