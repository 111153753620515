import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SHOW_OPERATION_RESULT_POPUP } from '../../../actions';

export const OPERATION_RESULT_POPUP = 'OPERATION_RESULT_POPUP';

export enum OperationResult {
    SuccessApproved = 'SuccessApproved',
    SuccessRejected = 'SuccessRejected',
    FailedRequestNotFound = 'FailedRequestNotFound',
    FailedNotAnApprover = 'FailedNotAnApprover',
    FailedRequestExternallyUpdated = 'FailedRequestExternallyUpdated',
    FailedRequestClosedApproved = 'FailedRequestClosedApproved',
    FailedRequestClosedRejected = 'FailedRequestClosedRejected',
    FailedRequestCancelled = 'FailedRequestCancelled',
    FailedRequestAlreadyApproved = 'FailedRequestAlreadyApproved',
    FailedRequestAlreadyRejected = 'FailedRequestAlreadyRejected',
    FailedRequestStepNotStarted = 'FailedRequestStepNotStarted',
    FailedRequestRequiresEditing = 'FailedRequestRequiresEditing',
    FailedRequestHasMatchingError = 'FailedRequestHasMatchingError',
    FailedRequestStepDone = 'FailedRequestStepDone',
    FailedRequestMatchedPOsBalanceIsExceeded = 'FailedRequestMatchedPOsBalanceIsExceeded',
    FailedRequestAllAllocationsExceedBillAmount = 'FailedRequestAllAllocationsExceedBillAmount',
}

export interface OperationResultPopupData {
    id: typeof OPERATION_RESULT_POPUP;
    requestId: Guid;
    stepId: Guid | null;
    result: OperationResult;
}

export type OperationResultPopupType = ImmutableObject<OperationResultPopupData> | null;

export default function (state: OperationResultPopupType, action: Action): OperationResultPopupType {
    switch (action.type) {
        case SHOW_OPERATION_RESULT_POPUP:
            return immutable<OperationResultPopupData>({
                id: OPERATION_RESULT_POPUP,
                requestId: action.payload.requestId,
                stepId: action.payload.stepId,
                result: action.payload.result,
            });

        default:
            return state;
    }
}
