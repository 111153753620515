// Advanced plan
export const NS_AUTO_APPROVAL_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/what-is-auto-approval-step-netsuite';

export const NETSUITE_NAVIGATION_ADVANCED_FEATURES = [];

export const NETSUITE_BILL_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/how-do-i-set-up-the-pull-from-oracle-netsuite-step-for-bills';

export const NETSUITE_PO_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/how-do-i-set-up-the-pulling-from-oracle-netsuite-step-for-purchase-orders';

export const NETSUITE_BILL_PULLING_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/how-to-pull-bills-from-netsuite';

export const NETSUITE_PO_PULLING_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/how-do-i-set-up-the-pulling-from-oracle-netsuite-step-for-purchase-orders';

// Numeric
export const MAX_TOTAL_AMOUNT = 999999999999999;
export const MAX_LINE_ITEM_AMOUNT = 9999999999999;
export const EXCHANGE_RATE_MAX_VALUE = 999999999;
export const QUANTITY_MAX_VALUE = 9999999999;
export const UNIT_PRICE_MAX_VALUE = 999999999;
export const DISCOUNT_AMOUNT_MAX_VALUE = 999999999999999;
export const TAX_AMOUNT_MAX_VALUE = 999999999999999;
export const TAX_AMOUNT_MAX_DIGITS_COUNT = 15;
export const REFERENCE_MAX_LENGTH = 45;
export const DESCRIPTION_MAX_LENGTH = 4000;
export const MEMO_MAX_LENGTH = 999;
export const PURPOSE_MAX_LENGTH = 4000;
export const MAX_CUSTOM_NUMERIC_FIELD_AMOUNT = 999999999999999;
export const ADVANCE_MAX_VALUE = 999999999999999;
export const MAX_CREDIT_LIMIT = 99999999.99;

// Vendor creation
export const COMPANY_NAME_MAX_LENGTH = 83;
export const WEB_ADDRESS_MAX_LENGTH = 99;
export const SALUTATION_MAX_LENGTH = 15;
export const FIRTS_NAME_MAX_LENGTH = 32;
export const MIDDLE_NAME_MAX_LENGTH = 32;
export const LAST_NAME_MAX_LENGTH = 32;
export const JOB_TITLE_MAX_LENGTH = 99;
export const COMMENTS_MAX_LENGTH = 999;
export const EMAIL_MAX_LENGTH = 254;
export const PHONE_MAX_LENGTH = 32;
export const ALT_PHONE_MAX_LENGTH = 32;
export const FAX_MAX_LENGTH = 32;
export const LEGAL_NAME_MAX_LENGTH = 83;
export const ACCOUNT_NUMBER_MAX_LENGTH = 99;
export const VAT_REGISTRATION_NUMBER_MAX_LENGTH = 25;

// Custom fields related
export const IGNORED_CUSTOM_FIELDS = ['Password', 'Image', 'Help', 'InlineHtml', 'Document'];
export const NUMERIC_CUSTOM_FIELDS = ['IntegerNumber', 'DecimalNumber', 'Currency', 'Percent'];
export const BOOLEAN_CUSTOM_FIELDS = ['CheckBox'];
export const REFERENCE_CUSTOM_FIELDS = ['ListRecord', 'MultipleSelect'];

export const SKIPPED_EXTERNAL_STATUSES = ['unknown', 'pending supervisor approval'];
