import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.AllFeatureTrialPaywallPopup', {
    xeroAdvancedBillToPOMatching: 'Bill-to-PO matching',
    xeroAdvancedBudgetChecking: 'Budget checking',
    xeroAdvancedContactApprovals: 'Contact approvals',
    xeroAdvancedAutoApprovals: 'Auto-approvals',
    xeroAdvancedSalesQuoteApprovals: 'Sales quote approvals',
    xeroAdvancedManualJournalApprovals: 'Manual journal approvals',
    xeroAdvancedGoodsReceivedNotes: 'Goods Received Notes',
    xeroAdvancedWorkflowVersionHistory: 'Workflow version history',
    xeroAdvancedRequestReviewStep: 'Request Review Step',
    xeroAdvancedAccrualReports: 'Accrual reports',

    xeroPremiumBatchPaymentApprovals: 'Xero batch payment approvals',
    xeroPremiumPaymentsWithAirwallex: 'Payments with Airwallex',
    xeroPremiumStandAloneWorkflows: 'Stand-alone workflows',

    xeroPremiumPublicAPI: 'Public API',
    xeroPremiumPrioritySupport: 'Priority support',

    qboAdvancedBillToPOMatching: 'Bill-to-PO matching',
    qboAdvancedVendorApprovals: 'Vendor approvals',
    qboAdvancedAutoApprovals: 'Auto-approvals',
    qboAdvancedJournalEntryApprovals: 'Journal entry approvals',
    qboAdvancedGoodsReceivedNotes: 'Goods Received Notes',
    qboAdvancedWorkflowVersionHistory: 'Workflow version history',
    qboAdvancedRequestReviewStep: 'Request Review Step',

    qboPremiumStandAloneWorkflows: 'Stand-alone workflows',
    qboPremiumPrioritySupport: 'Priority support*',

    addOnCaptureTitle: 'ApprovalMax Capture',
    addOnPayTitle: 'ApprovalMax Pay',
});
