import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import {
    UseDeleteXeroCounterpartyPaymentDetailsPathParams,
    UseDeleteXeroCounterpartyPaymentDetailsResponse,
    UseDeleteXeroCounterpartyPaymentDetailsResponseBackend,
} from './useDeleteXeroCounterpartyPaymentDetails.types';

export const useDeleteXeroCounterpartyPaymentDetails = (
    mutationOptions?: MutationOptions<
        UseDeleteXeroCounterpartyPaymentDetailsResponse,
        RequestDataParams<{}, UseDeleteXeroCounterpartyPaymentDetailsPathParams>
    >
) => {
    return useMutateLegacy<
        RequestDataParams<{}, UseDeleteXeroCounterpartyPaymentDetailsPathParams>,
        UseDeleteXeroCounterpartyPaymentDetailsResponseBackend,
        UseDeleteXeroCounterpartyPaymentDetailsResponse
    >(integrationsApiPaths.deleteXeroCounterpartyPaymentDetails, {
        method: 'delete',
        mutationOptions,
    });
};
