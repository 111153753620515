export const findSubstringIndexes = (inputString: string, substring: string | RegExp, n = 0) => {
    const indexes = [];

    let match;

    const reg = new RegExp(substring, 'g');

    while ((match = reg.exec(inputString))) {
        indexes.push(match.index);

        if (indexes.length === n) {
            break;
        }
    }

    return indexes;
};
