import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    FinishTFAEnablingWithBackupCodesRequest,
    FinishTFAEnablingWithBackupCodesResponse,
} from './useFinishTFAEnablingWithBackupCodes.types';

export const useFinishTFAEnablingWithBackupCodes = (
    mutationOptions?: MutationOptions<
        FinishTFAEnablingWithBackupCodesResponse,
        RequestDataParams<FinishTFAEnablingWithBackupCodesRequest>
    >
) => {
    return useMutateLegacy<
        RequestDataParams<FinishTFAEnablingWithBackupCodesRequest>,
        FinishTFAEnablingWithBackupCodesResponse
    >(twoFaApiPaths.finishTFAEnablingWithBackupCodes, { mutationOptions });
};
