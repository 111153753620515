import { selectors } from 'modules/common';
import { State } from 'modules/data';

import { pageConfig } from '../../config';
import { ActivePopupData } from '../reducers/page/activePopupReducer';
import { Page } from '../reducers/pageReducer';
import { ReportConfigExpanded } from '../types/ReportConfig';
import { expandReportConfig } from './reportConfigSelectors';

export function getPage(state: State): Page {
    return selectors.page.getPage(state, pageConfig.store.id);
}

export function getPageSafe(state: State): Page | undefined {
    return selectors.page.getPageSafe(state, pageConfig.store.id);
}

export function getActiveReportConfig(state: State): ReportConfigExpanded | null {
    const reportConfig = getPage(state).activeReportConfig;

    if (!reportConfig) {
        return null;
    }

    const company = selectors.navigation.getActiveCompany(state);

    const team = selectors.navigation.getActiveCompany(state).allMembers;

    return expandReportConfig(reportConfig, team, company);
}

export function hasChanges(state: State): boolean {
    return getPage(state).modified;
}

export function getActivePopup<T extends ActivePopupData = ActivePopupData>(state: State): T | undefined {
    return getPage(state).activePopup as T;
}

export function getActiveReport(state: State) {
    return getPage(state).activeReport;
}

export function getReportType(state: State) {
    return getPage(state).reportType;
}

export function getDocumentPreview(state: State) {
    return getPage(state).documentPreview;
}
