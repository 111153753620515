import { Module } from 'modules/page';

import AuthModule from './AuthModule';
import SsoPanel from './components/SsoPanel/SsoPanel';

export { SsoPanel };

export const moduleDef: Module = {
    id: 'common',
    moduleComponent: AuthModule,
};
